import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const CircularProgressLabelCustom = ({ value }) => (
  <Box sx={{
    position: 'relative', display: 'inline-flex', width: 86, height: 86,
  }}
  >
    <CircularProgress
      variant="determinate"
      sx={{
        color: theme => theme.palette.grey[200],
      }}
      size="86px"
      thickness={6}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      disableShrink
      sx={{
        color: theme => theme.palette.success.main,
        animationDuration: '550ms',
        position: 'absolute',
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'square',
        },
      }}
      size="86px"
      thickness={6}
      value={value}
    />
    <Box
      sx={{
        top: 0,
        left: 8,
        bottom: 0,
        right: 8,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="subtitle3" component="div" color="success.main">
        {`${parseFloat(value).toFixed(2)}%`}
      </Typography>
    </Box>
  </Box>
);

CircularProgressLabelCustom.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressLabelCustom;
