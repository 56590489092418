import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import RadioCustom from '../../../shared/components/RadioCustom'
import { SW_PRJ_TYPES } from '../../../shared/utils/constants'

const ExportConceptsDialog = ({
  headerText,
  open,
  handleClose,
  handleConfirmClick,
  radioSelected,
  handleRadioChange,
  showLabel,
  loading,
}) => {
  const dialogActions = [
    {
      children: 'Cancel',
      onclick: handleClose,
      variant: 'outlined',
    },
    {
      children: (
        <>
          {loading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            'Export Concepts'
          )}
        </>
      ),
      onclick: handleConfirmClick,
      variant: 'contained',
      disabled:
        (showLabel || loading) && radioSelected === SW_PRJ_TYPES[1].value,
    },
  ]

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          width: '100%',
        }}
      >
        <Box display="flex">
          <ClearIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
            }}
            fontSize="0.75rem"
          />
        </Box>
        {headerText && <Box>{headerText}</Box>}
      </DialogTitle>

      <DialogContent
        sx={{
          py: '32px !important',
          px: 1,
          m: 3,
          mt: 0,
          textAlign: 'center',
          border: ({ palette }) => `1px solid ${palette.primary.main}`,
          borderRadius: 1.5,
        }}
      >
        <Box sx={{ maxWidth: '320px', margin: 'auto' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Add the selected concepts to:
          </Typography>
          <FormControl sx={{ width: '100%', py: 2, gap: 1 }}>
            <RadioGroup
              sx={{ gap: 3.75 }}
              aria-labelledby="project-type-new_or_old"
              name="project-new_or_old"
              value={radioSelected}
              onChange={(e) => handleRadioChange(e.target.value)}
            >
              {SW_PRJ_TYPES.map((obj) => (
                <FormControlLabel
                  labelPlacement="start"
                  sx={{
                    justifyContent: 'space-between',
                    p: 1.25,
                    border: ({ palette }) => `1px solid ${palette.divider}`,
                    borderRadius: 1.5,
                  }}
                  key={obj.value}
                  control={<RadioCustom variant="tickMark" />}
                  {...obj}
                />
              ))}
            </RadioGroup>
            {showLabel && radioSelected === SW_PRJ_TYPES[1] && (
              <Typography
                variant="caption"
                color="error"
                sx={{ fontWeight: 500, textAlign: 'start' }}
              >
                Note: No Existing Project
              </Typography>
            )}
          </FormControl>
          <DialogActions
            sx={{
              p: 0,
              pt: 2,
              justifyContent: 'space-between',
            }}
          >
            {dialogActions.map((obj) => (
              <Button
                sx={{
                  width: `calc(100% / 2)`,
                }}
                variant={obj.variant}
                onClick={obj.onclick}
                disabled={obj.disabled}
              >
                {obj.children}
              </Button>
            ))}
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default ExportConceptsDialog
