import React, { useState, useEffect } from 'react'
import OtherFavUI from './OtherFavUI'
import { TableCell, Typography, Link, Tooltip, Box } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import _ from 'lodash'
import RemoveFavPopUp from './RemoveFavPopup'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  removeFromFavoritesTrends,
} from '../../../store/actions'
import TooltipOnOverflow from '../../../components/Tooltip/TooltipOnOverflow'
import DashboardCAGRLabel from '../DashboardCAGRLabel'
import EmptyIngredients from '../EmptyIngredientsCard'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(a, b, order, orderBy) {
  return order === 'desc'
    ? descendingComparator(a, b, orderBy)
    : -descendingComparator(a, b, orderBy)
}

const TIMEFRAMES_TO_HIDE_FS_AND_RETAIL = ['Month', 'Quarter']

const OtherFavorites = ({
  otherFavoritesData,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  timeframeToCheck,
  selectedTimeFrame,
  filteredFavorites,
  removeFromFavoritesTrends,
  setShowLoader,
  email,
  amplitudeFn,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState({})
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [otherFavData, setOtherFavData] = useState(filteredFavorites)

  useEffect(() => {
    if (orderBy) {
      const ampiEvent = 'Clicked_MW_Favorites_OtherFav_Sort'
      const ampiUserData = {
        User: email,
        Sort_By: orderBy,
        Order: order === 'asc' ? 'desc' : 'asc',
      }
      amplitudeFn(ampiEvent, ampiUserData)
    }
  }, [order, orderBy])

  useEffect(() => {
    fetchOtherFavoritesList(setShowLoader)
  }, [])
  const handleSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setOtherFavData(sortedList(isAsc ? 'desc' : 'asc', property))
  }

  const sortedList = (order, orderBy) => {
    return filteredFavorites.sort((a, b) => getComparator(b, a, order, orderBy))
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    const ampiEvent = 'Clicked_MW_Favorites_OtherFav_Star'
    const ampiUserData = {
      User: email,
      Fav_Name: selectedFeature?.name,
      Action: 'Cancel',
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setOpen(false)
  }

  const reNamedselectedFeature = selectedFeature && {
    name: selectedFeature.name,
    lens: selectedFeature.lens,
    favouriteType: selectedFeature.favourite_type,
    favouriteId: selectedFeature.favourite_id,
    url: selectedFeature.url,
    projectId: selectedFeature.project_id,
  }

  const handleConfirmClick = () => {
    const {
      name,
      lens,
      favouriteType,
      favouriteId,
      url,
      projectId,
    } = reNamedselectedFeature

    if (reNamedselectedFeature.favouriteType == 'TREND') {
      removeFromFavoritesTrends(favouriteId, name, lens, projectId)
        .then(() => {
          fetchOtherFavoritesList(setShowLoader)
        })
        .catch(() => {})
    } else {
      removeFromOtherFavorites(
        name,
        lens,
        favouriteType,
        favouriteId,
        url,
        setShowLoader,
      )
        .then(() => {
          fetchOtherFavoritesList(setShowLoader)
        })
        .catch(() => {})
    }
    const ampiEvent = 'Clicked_MW_Favorites_OtherFav_Star'
    const ampiUserData = {
      User: email,
      Fav_Name: name,
      Action: 'Confirm',
    }
    amplitudeFn(ampiEvent, ampiUserData)

    setOpen(false)
  }

  const handleFavoriteClick = (filteredFavorites) => {
    handleOpen()
    const selectedFav = { ...filteredFavorites }
    if (filteredFavorites.favourite_type === 'ADVANCE_SEARCH') {
      selectedFav.name = selectedFav.name.replace('Smart Filters: ', '')
    } else if (filteredFavorites.favourite_type === 'TREND') {
      selectedFav.name = selectedFav.name.replace('Trend: ', '')
    }
    setSelectedFeature(selectedFav)
  }

  const handleOtherFavRowClick = (rowObj) => {
    const ampiEvent = 'Clicked_MW_Favorites_OtherFav_Section'
    const name = rowObj.name.split(': ')[1]
    const section = rowObj.name.split(':')[0]
    const ampiUserData = {
      User: email,
      Fav_Name: name,
      Section: section,
    }
    amplitudeFn(ampiEvent, ampiUserData)
  }

  const otherFavoritesHeadCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      sorting: true,
      marginLeft: '0px',
      func: (filteredFavorites) => (
        <>
          <TableCell
            sx={{
              fontWeight: 500,
            }}
          >
            {' '}
            <TooltipOnOverflow
              title={decodeURIComponent(filteredFavorites.name)}
              text={decodeURIComponent(filteredFavorites.name)}
              extraSpace={50}
            >
              <Typography
                sx={{
                  maxWidth: '400px !important',
                  overflow: 'hidden !important',
                  textOverflow: 'ellipsis !important',
                  whiteSpace: 'nowrap',
                }}
              >
                <Link
                  target="_blank"
                  underline="none"
                  variant="body2"
                  fontWeight="500 !important"
                  href={
                    filteredFavorites.favourite_type === 'ADVANCE_SEARCH'
                      ? decodeURIComponent(filteredFavorites.url)
                      : '/mui/details/' + filteredFavorites.url.substring(1)
                  }
                  onClick={() => handleOtherFavRowClick(filteredFavorites)}
                >
                  {decodeURIComponent(filteredFavorites.name)}{' '}
                </Link>
              </Typography>
            </TooltipOnOverflow>
          </TableCell>
        </>
      ),
    },
    {
      id: 'lens',
      numeric: false,
      label: 'Lens',
      sorting: true,
      func: (filteredFavorites) => (
        <>
          <TableCell>{filteredFavorites.lens}</TableCell>
        </>
      ),
    },
    {
      id: 'navigation',
      numeric: false,
      label: 'Navigation',
      func: (filteredFavorites) => (
        <>
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {' '}
              {filteredFavorites.navigation.replace(/,/g, '>')}
              <Typography
                variant="subtitle2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '300px',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {filteredFavorites.favourite_type === 'PAIRING' ? (
                    `Total Paired Ingredients: ${filteredFavorites.analytical_data[timeframeToCheck].total_paired_ingredients}`
                  ) : filteredFavorites.favourite_type === 'FS_PRODUCTS' ? (
                    TIMEFRAMES_TO_HIDE_FS_AND_RETAIL.includes(
                      selectedTimeFrame,
                    ) ? (
                      '--'
                    ) : (
                      `#FS Products: ${filteredFavorites.analytical_data[timeframeToCheck].products.food_service}`
                    )
                  ) : filteredFavorites.favourite_type === 'RETAIL_PRODUCTS' ? (
                    TIMEFRAMES_TO_HIDE_FS_AND_RETAIL.includes(
                      selectedTimeFrame,
                    ) ? (
                      '--'
                    ) : (
                      `#Retail Products: ${filteredFavorites.analytical_data[timeframeToCheck].products.retail}`
                    )
                  ) : (
                    <>
                      {!_.isNil(
                        filteredFavorites.analytical_data?.overall
                          ?.total_two_year_cagr,
                      ) ? (
                        <>
                          <Typography variant="caption2">
                            {' '}
                            2 Year CAGR
                            {filteredFavorites.favourite_type === 'TREND' &&
                              filteredFavorites.analytical_data[
                                timeframeToCheck
                              ]?.two_year_cagr &&
                              ` (Change)`}
                            :{' '}
                          </Typography>
                          <DashboardCAGRLabel
                            twoYearCAGR={filteredFavorites.analytical_data.overall.total_two_year_cagr.toFixed(
                              2,
                            )}
                            twoYearCAGRChange={
                              filteredFavorites.favourite_type === 'TREND' &&
                              filteredFavorites.analytical_data[
                                timeframeToCheck
                              ]?.two_year_cagr?.toFixed(2)
                            }
                            hideTooltip
                          />
                        </>
                      ) : (
                        <>--</>
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  {' '}
                  <StarIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '26px',
                      height: '25px',
                      color: (theme) => theme.palette.primary.main,
                      marginLeft: '15px',
                    }}
                    onClick={() => handleFavoriteClick(filteredFavorites)}
                  />
                </Box>
              </Typography>
            </Box>
          </TableCell>
        </>
      ),
    },
  ]
  return (
    <>
      {filteredFavorites?.length ? (
        <>
          <Box sx={{ p: 2 }}>
            <OtherFavUI
              otherFavoritesHeadCells={otherFavoritesHeadCells}
              data={filteredFavorites}
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleSort}
            />
          </Box>
          <RemoveFavPopUp
            text={selectedFeature && decodeURIComponent(selectedFeature.name)}
            open={open}
            handleClose={handleClose}
            handleConfirmClick={handleConfirmClick}
          />
        </>
      ) : (
        <EmptyIngredients
          text={`Click on "Add to Favorites" on the pages to add them to Favorites`}
        />
      )}
    </>
  )
}
// export default OtherFavorites
const mapStateToProps = (state) => {
  const { myDashboard } = state
  return { otherFavoritesData: myDashboard.otherFavoritesData }
}

const mapDispatchToProps = (dispatch) => ({
  removeFromOtherFavorites: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
  ) =>
    dispatch(
      removeFromOtherFavorites(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
      ),
    ),
  fetchOtherFavoritesList: (setShowLoader) =>
    dispatch(fetchOtherFavoritesList(setShowLoader)),

  removeFromFavoritesTrends: (trendId, trendName, trendType, projectId) =>
    dispatch(
      removeFromFavoritesTrends(trendId, trendName, trendType, projectId),
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtherFavorites),
)
