import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Divider, FormControl, Grid, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import LangMenus from '../LangMenus'
import ConsumerCard from '../ConsumerCard'
import DownloadButton from '../DownloadButton'

import { amplify } from './../../../store/utility'
import { ENG_LANG } from '../../../shared/utils/constants'

import DetailedConsumerInsightsContainer from './styles'

const DetailedConsumerInsights = (props) => {
  const {
    projectid,
    lensSelected,
    productid,
    categoryData,
    name,
    country,
    category,
    email,
    expand,
    type,
    region,
    isBPC = false,
  } = props
  
  if (isEmpty(categoryData)) return null

  const [selectedLang, setSelectedLang] = useState(ENG_LANG)
  const handleLangChange = (event) => {
    setSelectedLang(event.target.value)
    const ampiEvent = 'Clicked_Trend_Level_Consumer_Insights_DisplayLanguage'
    const ampiUserData = {
      User: props.email,
      Language_Name: event.target.value,
      Trend_Name: props.name,
      Country_Name: props.country,
      Category_Name: props.category,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }
  if (!categoryData || categoryData.length === 0) return null
  let categoryDataVisible = [
    'Driver',
    'Product Attribute',
    'Occasion',
    'Expressed Persona',
  ]
  if(isBPC){
    categoryDataVisible.unshift('Concerns')
  }
  // Hide Expressed Persona for Baby Care
  if (category === 'Baby Care') {
    categoryDataVisible = categoryDataVisible.filter(
      (data) => data !== 'Expressed Persona',
    )
  }

  const sortedcategoryData = categoryDataVisible.reduce((acc, curr) => {
    const catData = categoryData.find(({ category }) => category === curr)
    return catData ? [...acc, catData] : acc
  }, [])

  const hasNativeName = sortedcategoryData.some((cat) => {
    return cat?.needs?.some((need) => {
      return Boolean(need.native_name?.length)
    })
  })

  return (
    <DetailedConsumerInsightsContainer>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Box>
            <Typography variant="h3">Consumer Insights</Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ mt: 0.5 }}
            >
              Category level consumer insights based on the organic
              conversations online
            </Typography>
          </Box>
          {!props.demographyComponent && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2">Display Language:</Typography>
              <FormControl variant="outlined" size="large">
                <LangMenus
                  hasNativeLang={hasNativeName}
                  country={country}
                  handleChange={handleLangChange}
                />
              </FormControl>
              <DownloadButton
                handleDownloadExcel={props.cIExcelDownload}
                lensSelected={lensSelected}
                title="_Trend_Level_Consumer_Insights"
                toggleImageDownload={props.toggleImageDownload}
                dowImageType="Cati"
                dowPptType={props.productType}
                togglePptDownload={props.togglePptDownload}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Grid
          container
          spacing={1}
          sx={
            expand
              ? {
                  maxHeight: 'fit-content !important',
                  height: 'fit-content !important',
                  overflowY: 'auto !important',
                }
              : {}
          }
        >
          {sortedcategoryData.map(
            ({ category, needs, data_point: dataPoint }) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category}>
                <ConsumerCard
                  expand={expand}
                  projectid={projectid}
                  lensSelected={lensSelected}
                  productid={productid}
                  selectedLang={selectedLang}
                  category={category}
                  dataPoint={dataPoint}
                  needs={needs}
                  trendName={name}
                  title="_Trend_Level_Consumer_Insights"
                  country={country}
                  email={email}
                  displayCategory={props.category}
                  type={type}
                  region={region}
                  demographyComponent={props.demographyComponent}
                  isBPC={isBPC}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Box>
    </DetailedConsumerInsightsContainer>
  )
}

DetailedConsumerInsights.propTypes = {}

export default DetailedConsumerInsights
