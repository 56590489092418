import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const CommonSectionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 1,
  boxShadow: `0px 4px 12px ${theme.palette.shadow.main}`,
  padding: theme.spacing(2),
  height: '100%',
}))

export const SectionHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  paddingBottom: theme.spacing(2),
}))

export const SectionFiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}))
