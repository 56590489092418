import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/logo-white.svg'
import LoginPageImg from '../../assets/loginPageImgV2.png'

const LoginLayoutV2 = ({ children }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      background: `url(${LoginPageImg}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      bgcolor: 'background.default',
      height: '100vh',
      width: '100%',
    }}
  >
    <Box
      sx={{
        position: 'relative',
        bgcolor: 'background.paper',
        boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 4,
        p: 12.5,
      }}
    >
      <Box
        sx={{
          pb: 4,
          display: 'flex',
          alignItems: 'flex-end',
          position: 'absolute',
          top: 0,
          transform: 'translateY(-100%)',
        }}
      >
        <Logo />
        <Box sx={{ mx: 1, height: '18px' }}>
          <Divider orientation="vertical" />
        </Box>
        <Typography variant="subtitle2" sx={{ color: 'primary.contrastText' }}>
          Foresight Engine
        </Typography>
      </Box>
      {children}
    </Box>
  </Stack>
)

LoginLayoutV2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoginLayoutV2
