import styled from '@emotion/styled'
import { TreeItem } from '@mui/lab'
import { TableContainer, Grid, TextField } from '@mui/material'

const pillarBorder = (depth, theme) => {
  if (depth === 0) {
    return `2px solid ${theme.palette.primary.main}`
  } else if (depth === 1) {
    return `2px dashed ${theme.palette.primary.main}`
  } else {
    return `2px dotted ${theme.palette.primary.main}`
  }
}

export const StyledTable = styled(TableContainer)(({ theme }) => ({
  // height: '575px',
  border: 'none !important',
  overflowX: 'hidden !important',
  marginTop: '5px',
  marginLeft: '10px',
  marginRight: '10px',

  // 'td': {
  // 	paddingLeft: '5px',
  // 	paddingRight: '22px',
  // 	textAlign: 'left',
  // },
  'table > thead:first-child tr th': {
    border: `2px solid ${theme.palette.divider}`,
    borderLeft: 'none',
    borderRight: 'none',
    // height: '4px !important',
  },
  'table > thead:first-child tr th:first-child': {
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  'table > thead:first-child tr th:last-child': {
    borderRight: `2px solid ${theme.palette.divider}`,
  },
  'table tbody tr td': {
    background: 'white',
    borderBottom: 'transparent !important',
    borderTop: `8px solid ${theme.palette.background.default}`,
  },
  'table tbody tr': {
    backgroundColor: theme.palette.white,
  },
  'td: first-child': {
    // paddingLeft: '2px',
    // paddingRight: '2px',
  },
  td: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',

    textAlign: 'left',
  },
  th: {
    padding: '0px !important',
  },

  '& .MuiTableCell-head': {
    '.MuiTypography-root': {
      width: 'min-content',
    },
  },
  '& .MuiTableCell-head: first-child': {
    marginLeft: '5px !important',
    '.MuiTypography-root': {
      width: '7px',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
  },
  '& .MuiSvgIcon-root ': {
    marginRight: '10px !important',
  },
  '.MuiButton-root': {
    fontSize: '12px',
    svg: {
      width: '20px',
      marginRight: '15px !important',
    },
    '&:first-child': {
      color: theme.palette.error.main,
    },
  },
  'table table thead th': {
    color: theme.palette.primary.main,
    height: '70px',
  },
  'table table thead th:first-child': {
    paddingLeft: '43px !important',
  },
  'table table tbody tr td:first-child': {
    paddingLeft: '43px !important',
    border: 'none',
  },
  /* 'table table thead th:nth-child(2)': {
		paddingLeft: '75px !important'
	},
	'table table tbody td:nth-child(2)': {
		paddingLeft: '75px !important',
		border: 'none'
	},
	'table table thead th:nth-child(4)': {
		paddingLeft: '75px !important'
	},
	'table table tbody td:nth-child(4)': {
		paddingLeft: '75px !important',
		border: 'none'
	}, */
  'table table tbody td': {
    border: 'none',
  },
  '& .MuiBox-root': {
    marginLeft: '-5px !important',
    marginRight: '-6px !important',
  },
}))

export const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  paddingTop: '10px',
  paddingBottom: '10px',
  marginLeft: '5px',
  '.MuiGrid-root': {
    padding: '3px',
    marginRight: '-15px',
  },
  '.MuiAutocomplete-root': {
    width: '100%',
  },
}))

export const SearchBox = styled(TextField)(() => ({
  width: '172px',
  '.MuiOutlinedInput-input': {
    padding: '10px !important',
    textTransform: 'capitalize',
  },
}))

export const StyledTreeItem = styled(TreeItem)(({ theme, styledProps }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  background: theme.palette.background.paper,
  minHeight: '58px',
  '& .MuiTreeItem-content': {
    padding: '0px',
    paddingLeft: '20px',
  },
  '& .MuiTreeItem-content:hover': {
    background: `${theme.palette.action.hover} !important`,
  },
  '& .Mui-selected': {
    background: `${theme.palette.white} !important`,
  },
  '& .Mui-focused': {
    background: `${theme.palette.white} !important`,
  },
  '& .Mui-expanded': {
    background: theme.palette.background.paper,
    minHeight: '58px',
    boxShadow: `0 10px 6px -6px ${theme.palette.shadow.pillar}`,
  },
  '& .MuiTreeItem-iconContainer': {
    color: theme.palette.icon,
  },

  boxShadow: `0 10px 6px -6px ${theme.palette.shadow.pillar}`,

  border:
    styledProps.expandedNodes.includes(`${styledProps.childId}`) &&
    pillarBorder(styledProps.allPillars[styledProps.childId]?.depth, theme),
}))
