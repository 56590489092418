import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import axios from '../../services/networking/swAxios'
import foresightAxios from '../../services/networking/axios'

import { addRequest, doneRequest } from '../../store/actions/downloadSections'

import NetworkingUtil from '../../services/networking/SWNetworkingUtil'
import ForesightNetworkUtil from '../../services/networking/NetworkingUtil'
import { addHighlight, setCGSelectedConcepts } from '../../store/actions/index'
import { formatString, amplify } from '../../store/utility'
import {
  fetchProjectsList,
  deleteProject,
  notify,
} from '../../store/actions/screenWinnerActions'
import {
  notifications,
  subCategory,
  screenWinnerMessages as swMsgs,
} from '../../const'

import SearchOptsMultiSelect from '../SearchOptionsDropdown/SearchOptsMultiSelect'
import ButtonCard from '../Buttons/ButtonCard'
import Button from '../Buttons/ButtonSW'
import ProjectCreate from '../ProjectCreate/ProjectCreate'
import ProjectCard from '../ProjectCard/ProjectCard'
import HighLighter from '../HighLighter/HighLighter'
import DeleteModal from '../SWDeleteModal/SWDeleteModal'

import addProjectIcon from '../../assets/images/screenWinner/add_project.svg'
import trendPhasesImg from '../../assets/images/screenWinner/trend_phase_chart1.png'
import trendPhasesImg2 from '../../assets/images/screenWinner/trend_phase_chart2.png'
import plusBtnIcon from '../../assets/images/screenWinner/sw-add-btn-icon.svg'
import subscribeImg from '../../assets/images/screenWinner/sw_subscribe1.svg'

import './ProjectsListScreen.scss'

const DEFAULT_CONCEPT_LIST = [
  {
    id: 0,
    title: '',
    description: { raw: '', text: '' },
    attachments: [],
  }, // attachments : [{name:'', file:{},}]
]

const getSelectedCGConcepts = (conceptsListCG) => {
  return [
    ...conceptsListCG.map((obj, idx) => ({
      id: idx,
      title: obj.title,
      description: { raw: '', text: obj.generated_concept },
      attachments: [],
    })),
  ]
}

const ProjectsListScreen = (props) => {
  const {
    projectsList = [],
    notify,
    fetchProjectsList,
    screenWinnerSubscription,
    addHighlight,
    addRequest,
    doneRequest,
    deleteProject,
    email,
    selectedCGConcepts,
    resetCGSelectedConcepts,
    isLoading,
  } = props
  const history = useHistory()
  const hasSelectedConcepts = Boolean(selectedCGConcepts?.length)

  const [createProject, setCreateProject] = useState(hasSelectedConcepts)
  const [countries, setCountries] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState({
    // USASnacks: [
    //   { id: 0, name: 'A really huge name for a sub category', img: '' },
    //   { id: 1, name: 'tiny', img: '' },
    // ],
  })
  const [projectTitle, setProjectTitle] = useState('')
  const [projectDescription, setProjectDescription] = useState('')
  const [brand, setBrand] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [countrySelected, setCountrySelected] = useState('')
  const [subCategorySelected, setSubCategorySelected] = useState(null)
  const [error, setError] = useState(false)
  const [filterCountry, setFilterCountry] = useState('')
  const [filterCategory, setFilterCategory] = useState('')
  const [projectToDelete, setProjectToDelete] = useState(null)

  const availableCountries = useMemo(() => {
    const countriesSet = Array.from(
      new Set(projectsList.map(({ country }) => country)),
    )
    return countries.filter(({ name }) => countriesSet.includes(name))
  }, [countries, projectsList])
  const availableCategories = useMemo(() => {
    const categoriesSet = Array.from(
      new Set(projectsList.map(({ category }) => category)),
    )
    return categories.filter(({ name }) => categoriesSet.includes(name))
  }, [categories, projectsList])

  const [concepts, setConcepts] = useState(
    hasSelectedConcepts
      ? getSelectedCGConcepts(selectedCGConcepts)
      : DEFAULT_CONCEPT_LIST,
  )

  const logNewProjectCreate = (action) => {
    try {
      const nonEmptyConcepts = concepts.filter(
        (cncpt) => !isConceptEmpty(cncpt),
      )
      const concetpsData = nonEmptyConcepts.map(({ title }) => title)
      const ampiEvent = `Clicked_SW_New_Project_Action`
      const ampiUserData = {
        User: email,
        ...(concetpsData?.length && { Concept_Name: concetpsData }),
        ...(countrySelected && { Country: countrySelected }),
        ...(categorySelected && { Category: categorySelected }),
        Subcategory: subCategorySelected?.name,
        ...(projectTitle && { Project_Title: projectTitle }),
        Action: action,
      }
      amplify(ampiEvent, ampiUserData, email)
    } catch (err) {
      console.log('Error in ProjectsListScreen', err)
    }
  }

  const handleSubscribe = () => {
    window.location.href = 'mailto:info@aipalette.com'
  }

  const isConceptsValid = (concepts) => {
    return concepts.every((concept) => {
      const { title, description, attachments } = concept
      if (attachments.length && (!title || !description.text)) {
        return false
      }
      if (title && description.text) {
        return true
      }
      return false
    })
  }

  const isConceptEmpty = (concept) => {
    const { title, description, attachments } = concept
    return !title && !description.text && !attachments.length
  }

  const handleCreateProjectClose = () => {
    if (hasSelectedConcepts) {
      resetCGSelectedConcepts()
    }
    setConcepts(DEFAULT_CONCEPT_LIST)
    setCreateProject(false)
  }

  const validateConcepts = (concepts) => {
    // if (!(concepts.length === 1 && isConceptEmpty(concepts[0]))) {
    const nonEmptyConcepts = concepts.filter((cncpt) => !isConceptEmpty(cncpt))
    return !!nonEmptyConcepts.length && isConceptsValid(nonEmptyConcepts)
    // }
    // return true;
  }

  const onSubmit = (ev) => {
    ev.preventDefault()
    if (
      countrySelected &&
      categorySelected &&
      projectTitle &&
      validateConcepts(concepts)
    ) {
      logNewProjectCreate('Save Project')
      const subcategory = subCategorySelected?.name || subCategory.ALL
      const projectDetails = {
        country: countrySelected,
        category: categorySelected,
        title: projectTitle,
        description: projectDescription,
        brand: brand,
        subcategory,
      }
      const projectCRUD = NetworkingUtil.projectCRUD()
      const prjCrtRequestFrom = {
        name: 'createProject',
        requestTime: new Date(),
      }
      addRequest(prjCrtRequestFrom)
      axios
        .post(projectCRUD, projectDetails)
        .then(async ({ data }) => {
          doneRequest(prjCrtRequestFrom)
          const { id } = data.data
          const cncptRequestFrom = {
            name: 'cncptRequestFrom',
            requestTime: new Date(),
          }
          const nonEmptyConcepts = concepts.filter(
            (cncpt) => !isConceptEmpty(cncpt),
          )
          const concetpsData = nonEmptyConcepts.map(
            ({ title, description }) => ({
              title,
              description: description.text,
              description_html: description.raw,
            }),
          )
          try {
            addRequest(cncptRequestFrom)
            const cncptData = await axios.post(NetworkingUtil.conceptCRUD(id), {
              concepts: concetpsData,
            })
            // console.log(cncptData.data.data.message, "cncd");
            doneRequest(cncptRequestFrom)
          } catch (err) {
            doneRequest(cncptRequestFrom)
            notify({
              type: notifications.ERROR,
              message: err.response.data.message,
            })
          }
          notify({
            type: notifications.SUCCESS,
            message: data.data.message,
          })
          fetchProjectsList()
          handleCreateProjectClose()
          addHighlight({ route: '/screen-winner', highlightId: id })
        })
        .catch((err) => {
          doneRequest(prjCrtRequestFrom)
          notify({
            type: notifications.ERROR,
            message: err.response.data.message,
          })
          console.log(err)
        })
    } else {
      setError(true)
    }
  }

  const handleConceptInput = (id, key, val) => {
    setConcepts((cncpts) => {
      const newCncpts = cncpts.map((cncpt) => ({ ...cncpt }))
      newCncpts.splice(id, 1, { ...newCncpts[id], [key]: val })
      return newCncpts
    })
  }

  const handleCncptDescChange = (id, key, val) => {
    setConcepts((cncpts) => {
      const newCncpts = cncpts.map((cncpt) => ({ ...cncpt }))
      newCncpts.splice(id, 1, {
        ...newCncpts[id],
        description: { ...newCncpts[id].description, [key]: val },
      })
      return newCncpts
    })
  }

  const handleCountrySelect = ({ name }) => setCountrySelected(name)

  const handleFilterCountry = (countries) =>
    setFilterCountry(countries.map(({ name }) => name))

  const handleFilterCategory = (categories) =>
    setFilterCategory(categories.map(({ name }) => name))

  const handleCategorySelect = ({ name }) => setCategorySelected(name)

  const handleSubCategorySelect = (subCat) => setSubCategorySelected(subCat)

  const handleScreenWinnerCTAClick = () => {
    const ampiEvent = `Clicked_Screen_Winner_CTA`
    const ampiUserData = {
      User: email,
    }
    amplify(ampiEvent, ampiUserData, email)
  }

  const handleDeleteProject = (projectId, prjScreening) => {
    if (prjScreening) {
      notify({
        type: notifications.ERROR,
        message: swMsgs.PROJECT_DELETE_DISABLED,
      })
    } else {
      setProjectToDelete(projectId)
    }
  }

  const confirmDelete = () => {
    deleteProject(projectToDelete)
    setProjectToDelete(null)
  }

  const goBackToPreviousScreen = hasSelectedConcepts && (
    <Button
      type="button"
      name="Go back to Previous Screen"
      textOnly
      onClick={() => {
        logNewProjectCreate('Go Back')
        history.goBack()
      }}
    />
  )

  const createProjectElement = createProject && (
    <ProjectCreate
      handleCreateCancel={() => {
        logNewProjectCreate('Cancel')
        handleCreateProjectClose()
      }}
      submitError={error}
      revalidateCallback={() => setError(false)}
      handleSelectCountry={handleCountrySelect}
      handleSelectCategory={handleCategorySelect}
      handleSelectSubCategory={handleSubCategorySelect}
      countrySelected={countrySelected}
      categorySelected={categorySelected}
      handleSubmit={onSubmit}
      brand={brand}
      projectTitle={projectTitle}
      handleProjectTitleInput={(val) =>
        setProjectTitle(formatString(val, true))
      }
      handleProjectDescriptionInput={(val) =>
        setProjectDescription(formatString(val, true))
      }
      handleBrandInput={(val) => setBrand(formatString(val))}
      handleConceptTitleChange={(id, val) =>
        handleConceptInput(id, 'title', val)
      }
      handleConceptDescriptionChange={(id, val) =>
        handleCncptDescChange(id, 'text', val)
      }
      handleCncptRawDescChange={(id, val) =>
        handleCncptDescChange(id, 'raw', val)
      }
      handleConceptFileChange={(id, val) =>
        handleConceptInput(id, 'attachments', val)
      }
      countries={countries}
      categories={categories}
      subCategories={
        subCategories[`${countrySelected}${categorySelected}`] || []
      }
      subCategorySelected={subCategorySelected}
      handleClose={() => {
        logNewProjectCreate('Cancel')
        handleCreateProjectClose()
      }}
      concepts={concepts}
      handleAddConcept={() => {
        logNewProjectCreate('Add more Concept')
        setConcepts((cncpt) => [
          ...cncpt,
          {
            id: cncpt.length,
            title: '',
            description: '',
            attachments: [],
          },
        ])
      }}
      goBackBtn={goBackToPreviousScreen}
      isLoading={isLoading}
    />
  )

  useEffect(() => {
    if (screenWinnerSubscription) {
      {
        const url = NetworkingUtil.getCountries()
        const requestFrom = {
          name: 'fetchSWCountries',
          requestTime: new Date(),
        }
        addRequest(requestFrom)
        axios
          .get(url)
          .then(({ data }) => {
            doneRequest(requestFrom)
            setCountries(
              data.data.countries.map(({ id, name, image_url: imageUrl }) => ({
                id,
                name,
                img: imageUrl,
              })),
            )
          })
          .catch((err) => {
            doneRequest(requestFrom)
            console.log(err)
          })
      }
      {
        const url = NetworkingUtil.getCategories()
        const requestFrom = {
          name: 'fetchSWCategories',
          requestTime: new Date(),
        }
        addRequest(requestFrom)
        axios
          .get(url)
          .then(({ data }) => {
            doneRequest(requestFrom)
            setCategories(
              data.data.categories.map(({ id, name, image_url: imageUrl }) => ({
                id,
                name,
                img: imageUrl,
              })),
            )
          })
          .catch((err) => {
            doneRequest(requestFrom)
            console.log(err)
          })
      }
      fetchProjectsList()
      if (hasSelectedConcepts) {
        setConcepts(getSelectedCGConcepts(selectedCGConcepts))
      }
    }
  }, [screenWinnerSubscription])

  useEffect(() => {
    setSubCategorySelected(null)
    if (categorySelected && countrySelected) {
      const marketKey = `${countrySelected}${categorySelected}`
      if (!subCategories[marketKey]) {
        const requestUrl = ForesightNetworkUtil.getSubcategoriesSW()
        const postBody = {
          country: countrySelected,
          category: categorySelected,
        }
        const requestFrom = {
          name: 'fetchSWSubCategories',
          requestTime: new Date(),
        }
        addRequest(requestFrom)
        foresightAxios
          .post(requestUrl, postBody)
          .then(({ data }) => {
            const oldSubCategories = { ...subCategories }
            oldSubCategories[marketKey] = data.data
              .map((subCat, i) => ({
                id: i,
                name: subCat,
              }))
              .sort(({ name: a }, { name: b }) => a.localeCompare(b))
            oldSubCategories[marketKey].unshift({
              id: -1,
              name: subCategory.ALL,
            })
            setSubCategories(oldSubCategories)
            doneRequest(requestFrom)
          })
          .catch((err) => {
            notify({
              type: notifications.ERROR,
              message: 'Error fetching Subcategories',
            })
            doneRequest(requestFrom)
            console.log(err)
          })
      }
    }
  }, [categorySelected, countrySelected])

  return (
    <>
      <div
        className={`projects-list-screen${
          projectsList.length ? '' : ' sw-flex-row'
        }`}
      >
        {projectsList.length ? (
          <>
            {projectToDelete !== null && (
              <DeleteModal
                modalTitle="Delete Project"
                modalBody={
                  <>
                    <span className="projects-list-screen__delete-modal">
                      Are you sure you want to delete this project?
                    </span>
                    <span>
                      Deleting the project, will delete all the information
                      inside it.
                    </span>
                  </>
                }
                onCancelClick={() => setProjectToDelete(null)}
                onDeleteClick={confirmDelete}
              />
            )}
            <div className="projects-list-screen__heading__block">
              <h1 className="projects-list-screen__heading">Projects</h1>
              <div className="projects-list-screen__filter__block">
                <div className="projects-list-screen__filter--country">
                  <SearchOptsMultiSelect
                    data={availableCountries}
                    // data={countries}
                    handleSelect={handleFilterCountry}
                    placeholder="Select Country"
                    defaultValues={availableCountries}
                    // defaultValues={countries}
                  />
                </div>
                <div className="projects-list-screen__filter--category">
                  <SearchOptsMultiSelect
                    data={availableCategories}
                    // data={categories}
                    handleSelect={handleFilterCategory}
                    placeholder="Select Category"
                    defaultValues={availableCategories}
                    // defaultValues={categories}
                  />
                </div>
              </div>
            </div>
            <div className="projects-list-screen__projects-list">
              <div className="projects-list-screen__add-new-card">
                <ButtonCard
                  img={addProjectIcon}
                  leftImage={plusBtnIcon}
                  imgAlt="Add new project"
                  name="Add New Project"
                  imgClassName="projects-list-screen__add-new-card__btn-img"
                  className="projects-list-screen__add-new-card__btn"
                  onClick={() => setCreateProject(true)}
                />
              </div>
              {projectsList
                .filter(
                  ({ country, category }) =>
                    filterCountry.includes(country) &&
                    filterCategory.includes(category),
                )
                .map(({ id, ...projectsProps }) => {
                  return (
                    <div
                      className="projects-list-screen__projects-list-card"
                      key={id}
                    >
                      <HighLighter
                        highlighterId={id}
                        className="projects-list-screen__highlight"
                      >
                        <ProjectCard
                          id={id}
                          {...projectsProps}
                          deleteProject={handleDeleteProject}
                        />
                      </HighLighter>
                    </div>
                  )
                })}
            </div>

            {createProjectElement}
          </>
        ) : (
          <>
            {screenWinnerSubscription ? (
              <div className="projects-list-screen__create-new-project_create bg-dark-blue-light">
                <img
                  className="add_project_illst"
                  src={addProjectIcon}
                  alt="Add new project"
                />
                <p className="sw-white title--22">
                  You have not created any project yet
                </p>
                <p className="para--16 sw-white projects-list-screen__create-new-project__sub-text">
                  Add projects to see where your ideas stand in the market so
                  you can take the best one forward.
                </p>
                <Button
                  leftImage={plusBtnIcon}
                  name="Create New Project"
                  type="button"
                  solid
                  onClick={() => setCreateProject(true)}
                />
              </div>
            ) : (
              <div className="projects-list-screen__create-new-project bg-white">
                <p className="title--22 projects-list-screen__unsub-txt">
                  Got a product to launch?
                  <br />
                  <p className="title--14 projects-list-screen__unsub-txt_sub">
                    Find your winning concept in just two steps
                  </p>
                </p>
                <img
                  className="subscribe_project_illst"
                  src={subscribeImg}
                  alt="Add new project"
                />
                <p className="title--22 projects-list-screen__unsub-txt">
                  Save time on the testing, spend more time on the winning
                  <br />
                  <p className="title--14 projects-list-screen__unsub-txt_sub">
                    Whether you're looking for originality, relevance or both,
                    Screen Winner allows you to test the potential success of
                    your concepts in real time.
                  </p>
                </p>
                <Button
                  name="Request Screen Winner Demo"
                  type="button"
                  className="projects-list-screen__unsub__btn"
                  solid
                  onClick={handleSubscribe}
                />
              </div>
            )}
            <div className="projects-list-screen__explore-section">
              <div className="projects-list-screen__explore-card">
                <Link
                  to="/mui/main-system"
                  className="projects-list-screen__explore-card prj-exp-card--2"
                  onClick={() => {
                    handleScreenWinnerCTAClick()
                  }}
                >
                  <p className="sw__prj-list-screen__sub-txt">
                    Don’t know where to get started?
                  </p>
                  <h2 className="sw__prj-list-screen__txt">
                    Explore Foresight Engine now
                  </h2>
                  <img
                    className="projects-list-screen__explore-card__img"
                    src={trendPhasesImg}
                  />
                  <img
                    className="projects-list-screen__explore-card__img"
                    src={trendPhasesImg2}
                  />
                </Link>
              </div>
            </div>
            {createProjectElement}
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { screenWinner, conceptGenie } = state
  const {
    isShowSpinnerDownload,
    loadingRequests,
    showSingleLoader,
  } = state.downloadSections
  return {
    projectsList: screenWinner.projectsList,
    screenWinnerSubscription: screenWinner.subscribed,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    selectedCGConcepts: conceptGenie.selectedCGConcepts,
    isLoading: showSingleLoader && (isShowSpinnerDownload || loadingRequests),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectsList: () => dispatch(fetchProjectsList()),
    notify: (notification) => dispatch(notify(notification)),
    addHighlight: (id) => dispatch(addHighlight(id)),
    addRequest: (from) => dispatch(addRequest(from)),
    doneRequest: (from) => dispatch(doneRequest(from)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    resetCGSelectedConcepts: () => dispatch(setCGSelectedConcepts([], true)),
  }
}

ProjectsListScreen.propTypes = {
  projectsList: PropTypes.arrayOf([]),
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListScreen)
