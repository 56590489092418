import React, { useState } from 'react'
import {
  Button,
  Box,
  TextField,
  Modal,
  Typography,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function GenerateWheelModal({
  open,
  nameError,
  handleModalClose,
  handleWheelSave,
}) {
  const [newWheelName, setNewWheelName] = useState('')

  const handleNameChange = (e) => {
    setNewWheelName(e.target.value)
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          background: ({ palette }) => palette.white,
          boxShadow: 24,
          borderRadius: '5px',
          p: 4,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: ({ palette }) => palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
          }}
          component="h2"
        >
          Save Your Wheel
        </Typography>
        <Typography sx={{ mt: 2, mb: 1, fontSize: '14px' }}>
          Wheel Name:
        </Typography>
        <TextField
          variant="outlined"
          sx={{ width: '100%' }}
          onChange={handleNameChange}
          value={newWheelName}
        />
        {nameError && <Typography variant="error">{nameError}</Typography>}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} pt={3}>
          <Button
            variant="outlined"
            onClick={handleModalClose}
            sx={{
              height: 'auto',
              padding: '10px',
              minWidth: '104px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
              fontWeight: 600,
              color: ({ palette }) => palette.text.hint,
            }}
          >
            Cancel
          </Button>
          <Button
            variant={newWheelName === '' ? 'outlined' : 'contained'}
            onClick={() => handleWheelSave(newWheelName)}
            disabled={newWheelName === ''}
            sx={{
              height: 'auto',
              padding: '10px',
              minWidth: '104px',
              display: 'flex',
              fontWeight: 600,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Save
          </Button>
        </Box>
        <IconButton
          pl={1}
          size="small"
          aria-label="close"
          color="inherit"
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={handleModalClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  )
}
