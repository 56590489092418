import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import { fetchSharingUsers } from './actionTypes'
import { sendSharingUsers } from './actionTypes'


export const fetchSharingUsersList = () => {
    return (dispatch, getState) => {
      dispatch(fetchSharingUsersStart())
      const request = NetworkUtil.getSharingUsers()
      axios
        .get(request)
        .then(response => {
          dispatch(fetchSharingUsersSuccess(response.data))
        })
        .catch(error => {
          dispatch(fetchSharingUsersFailed(error))
        })
    }
  }


export const sendSharingUsersList = (data) => {
    return async(dispatch, getState) => {
      dispatch(sendSharingUsersStart())
      const request = NetworkUtil.postSharingUsers(data)
      return axios
        .post(request,data)
        .then(response => {
          dispatch(sendSharingUsersSuccess(response.data))
          return true
        })
        .catch(error => {
          dispatch(sendSharingUsersFailed(error))
          return false
        })
    }
  }
  
  
const fetchSharingUsersStart = () => {
    return {
      type: fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_START],
    }
  }
  
  const fetchSharingUsersSuccess = sharingUserProfiles => {
    return {
      type: fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_SUCCESS],
      sharingUserProfiles: sharingUserProfiles,
    }
  }
  
  const fetchSharingUsersFailed = error => {
    return {
      type: fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_FAILED],
      error: error,
    }
  }

    
const sendSharingUsersStart = () => {
  return {
    type: sendSharingUsers.type[sendSharingUsers.SU_SEND_LIST_START],
  }
}

const sendSharingUsersSuccess = sharingUserProfiles => {
  return {
    type: sendSharingUsers.type[sendSharingUsers.SU_SEND_LIST_SUCCESS],
  }
}

const sendSharingUsersFailed = error => {
  return {
    type: sendSharingUsers.type[sendSharingUsers.SU_SEND_LIST_FAILED],
    error: error,
  }
}