import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Chip,
  CircularProgress,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import React from 'react'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { isEmpty } from 'lodash'
import { Add, CloseRounded } from '@mui/icons-material'
import SearchTrendCard from '../../shared/components/GlobalSearch/SearchTrendCard'
import TrendReportItem from '../../shared/components/TrendReportItem'
import FoodServiceCard from '../../shared/components/FoodServiceCard'
import RelatedProductCard from '../../shared/components/RelatedRetailProducts/partials/RelatedProductCard'
import InformationIcon from '../../shared/components/InformationIcon'
import NoResultFound from '../../shared/components/NoResultFound/NoResultFound'
import SearchResultPageNoResult from './../../assets/images/EmptyState/SearchResultPageNoResult.svg'

const countFormat = (count) => {
  if (count < 10) {
    return `0${count}`
  } else {
    return `${count}`
  }
}

const SearchResultPageUI = ({
  project,
  searchedKeyword,
  handleChipClick,
  availableChips,
  activeChips,
  activeId,
  history,
  openReport,
  closeReport,
  email,
  firstName,
  lastName,
  ingredientSearchResult,
  productSearchResult,
  themeSearchResult,
  reportSearchResult,
  searchTermCorrection,
  fsProductResult,
  retailProductResult,
  ingredientLoader,
  productLoader,
  themeLoader,
  reportLoader,
  fsProductLoader,
  retailProductLoader,
  resultExistenceCheck,
  isPageLoading,
  logRetailProductClicked,
  logFoodServiceClicked,
  handleTrendCardClick,
  logReportClicked,
}) => {
  const chipCount = (activeChip) => {
    switch (activeChip) {
      case 'Ingredients':
        return ingredientSearchResult.length
      case 'Products':
        return productSearchResult.length
      case 'Themes':
        return themeSearchResult.length
      case 'Trend Reports':
        return reportSearchResult.length
      case 'Food Service Products':
        return fsProductResult.length
      case 'Retail Products':
        return retailProductResult.length
    }
  }
  const chipLoading = (activeChip) => {
    switch (activeChip) {
      case 'Ingredients':
        return ingredientLoader
      case 'Products':
        return productLoader
      case 'Themes':
        return themeLoader
      case 'Trend Reports':
        return reportLoader
      case 'Food Service Products':
        return fsProductLoader
      case 'Retail Products':
        return retailProductLoader
    }
  }

  const checkActiveChips = () => {
    return activeChips.find((chip) => {
      return chipCount(chip) !== 0
    })
  }

  const buildLink = (p, l, i) => {
    return `/mui/details/${p}/${l.toLowerCase()}/${i}`
  }

  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/product-category/${project.id}`}
    >
      {project.country} {project.category}
    </Link>,
    <Typography key="3" variant="subtitle2">
      Search Results for {searchedKeyword}
    </Typography>,
  ]

  const activeChipSection = (activeChip) => {
    if (activeChip === 'Ingredients') {
      return ingredientLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        ingredientSearchResult.map((ingredient) => {
          return (
            <Box
              key={`Ingredient-${ingredient.id}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <Link
                to={() => buildLink(project.id, 'ingredient', ingredient.id)}
                underline="none"
                onClick={() => {
                  handleTrendCardClick(ingredient, 'ingredient')
                }}
              >
                <SearchTrendCard
                  cardData={ingredient}
                  country={project.country}
                  category={project.category}
                />
              </Link>
            </Box>
          )
        })
      )
    } else if (activeChip === 'Products') {
      return productLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        productSearchResult.map((product) => {
          return (
            <Box
              key={`Product-${product.id}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <Link
                to={() => buildLink(project.id, 'product', product.id)}
                underline="none"
                onClick={() => {
                  handleTrendCardClick(product, 'product')
                }}
              >
                <SearchTrendCard cardData={product} />
              </Link>
            </Box>
          )
        })
      )
    } else if (activeChip === 'Themes') {
      return themeLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        themeSearchResult.map((theme) => {
          return (
            <Box
              key={`Theme-${theme.id}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <Link
                to={() => buildLink(project.id, 'theme', theme.id)}
                underline="none"
                onClick={() => {
                  handleTrendCardClick(theme, 'theme')
                }}
              >
                <SearchTrendCard cardData={theme} />
              </Link>
            </Box>
          )
        })
      )
    } else if (activeChip === 'Trend Reports') {
      return reportLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        reportSearchResult.map((report) => {
          return (
            <Box
              key={`Report-${report.id}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <TrendReportItem
                id={report.id}
                date={report.date}
                name={report.name}
                open={activeId === `${report.id}`}
                filter={0}
                email={email}
                reportPPTUrl={report.reportPPTURL}
                firstName={firstName}
                lastName={lastName}
                filters={{ ALL: 'All', CUSTOM: 'Custom', GLOBAL: 'Global' }}
                openReport={() => openReport(report)}
                closeReport={closeReport}
                report={report.report}
                reportType="Global"
                thumbnailUrl={report.thumbnailURL || report.imageURL}
                type="searchResult"
                logReportClicked={logReportClicked}
              />
            </Box>
          )
        })
      )
    } else if (activeChip === 'Food Service Products') {
      return fsProductLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        fsProductResult.map((fsProduct) => {
          return (
            <Box
              key={`FsProduct-${fsProduct.title}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <FoodServiceCard
                image={fsProduct.img}
                price={fsProduct.price}
                restaurantName={fsProduct.restaurantName}
                title={fsProduct.title}
                url={fsProduct.url}
                currency={fsProduct.currency}
                type="searchResult"
                logFoodServiceClicked={logFoodServiceClicked}
                esId={fsProduct?._id || fsProduct?.es_id}
              />
            </Box>
          )
        })
      )
    } else if (activeChip === 'Retail Products') {
      return retailProductLoader ? (
        <Box
          sx={{
            height: '350px',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        retailProductResult.map((retailProduct) => {
          return (
            <Box
              key={`RetailProduct-${retailProduct.name}`}
              sx={{
                width: '420px',
                height: '130px',
                margin: '10px',
              }}
            >
              <RelatedProductCard
                image={retailProduct.img}
                name={retailProduct.name}
                brand={retailProduct.brand}
                price={retailProduct.price}
                currency={retailProduct.currency}
                url={retailProduct.url}
                country={retailProduct.country}
                category={retailProduct.category}
                ingredientName={retailProduct.ingredientName}
                type="searchResult"
                logRetailProductClicked={logRetailProductClicked}
                esId={retailProduct?._id || retailProduct?.es_id}
              />
            </Box>
          )
        })
      )
    }
  }

  const chipSection = availableChips.map((chip) => {
    if (!chipCount(chip)) {
      return null
    }
    const active = activeChips.includes(chip)
    return (
      <Chip
        key={`Chip-${chip}]`}
        size="small"
        variant={'outlined'}
        sx={{
          padding: '10px',
          background: 'transparent',
          '& .MuiChip-label': {
            color: ({ palette }) =>
              active ? palette.text.primary : palette.text.secondary,
          },
        }}
        deleteIcon={
          active ? (
            <CloseRounded
              style={{
                color: (palette) => palette.icon,
                marginLeft: '10px',
              }}
            />
          ) : (
            <Add style={{ marginLeft: '10px' }} />
          )
        }
        label={chip}
        onClick={() => handleChipClick(chip)}
        onDelete={() => handleChipClick(chip)}
      />
    )
  })

  const resultSection = activeChips.map((activeChip) => {
    return chipCount(activeChip) !== 0 || chipLoading(activeChip) ? (
      <Box sx={{ padding: '10px' }} key={`ActiveChip-${activeChip}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '10px',
            margin: '10px',
          }}
        >
          <Typography sx={{ fontSize: '26px' }}>{activeChip}</Typography>
          <Typography
            sx={{
              color: ({ palette }) => palette.secondary.dark,
              fontSize: '16px',
              paddingLeft: '10px',
            }}
          >
            ({countFormat(chipCount(activeChip))})
          </Typography>
          {(activeChip === 'Food Service Products' ||
            activeChip === 'Retail Products') && (
            <InformationIcon
              tooltipText={`${activeChip} based upon the entered keyword.`}
              sx={{
                color: ({ palette }) => palette.primary.dark,
                marginLeft: '10px',
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            height: chipCount(activeChip) > 6 ? '350px' : 'auto',
            overflow: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {activeChipSection(activeChip)}
        </Box>
      </Box>
    ) : null
  })

  if (isEmpty(project) || isPageLoading) {
    return <OverlayLoader />
  }

  return (
    <>
      <Box pt={3} pb={3}>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      {resultExistenceCheck() ? (
        <>
          {searchTermCorrection.type === 'synonym' && (
            <Box
              sx={{
                paddingTop: '10px',
                paddingBottom: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  color: ({ palette }) => palette.text.secondary,
                }}
              >
                {`Showing Results for ${searchTermCorrection.searchTerm} `}
              </Typography>
              <InformationIcon
                tooltipText="Keyword entered is synonymous to the results."
                sx={{
                  color: ({ palette }) => palette.primary.dark,
                  marginLeft: '10px',
                }}
              />
            </Box>
          )}
          {searchTermCorrection.type === 'translation' && (
            <Box sx={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  color: ({ palette }) => palette.text.secondary,
                }}
              >
                {`Showing Results for ${searchTermCorrection.searchTerm} translated from ${searchTermCorrection.language}`}
              </Typography>
            </Box>
          )}
          {searchTermCorrection.correction && (
            <Box sx={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  color: ({ palette }) => palette.text.secondary,
                }}
              >
                {`Showing Results for ${searchTermCorrection.searchTerm} instead of ${searchTermCorrection.originalTerm}`}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography sx={{ color: ({ palette }) => palette.text.secondary }}>
              Results found across
            </Typography>
            {chipSection}
          </Box>
          {checkActiveChips() ? (
            <Box
              sx={{
                paddingTop: '20px',
                marginTop: '20px',
                background: ({ palette }) => palette.white,
              }}
            >
              {resultSection}
            </Box>
          ) : (
            <Box sx={{ height: '500px' }}>
              <NoResultFound
                imgSrc={SearchResultPageNoResult}
                message={'Please select some trend section to see the results'}
              />
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ height: '500px' }}>
          <NoResultFound
            imgSrc={SearchResultPageNoResult}
            message={'No Result found for the entered keyword'}
          />
        </Box>
      )}
    </>
  )
}

export default SearchResultPageUI
