import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import { ReactComponent as MessageImg } from '../../../../../assets/social-icons/message.svg'
import { ReactComponent as SendImg } from '../../../../../assets/social-icons/send.svg'
import { ReactComponent as InstagramColorImg } from '../../../../../assets/social-icons/instagramColor.svg'
import { ReactComponent as BookmarkImg } from '../../../../../assets/social-icons/bookmark.svg'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { format } from 'date-fns'
import ConsumerQuotesContainer from './styles'
import fallbackImg from '../../../../../assets/emptySocialImage.png'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Likes: ampiUserData.likes,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesInstagram')
  }
}

const ConsumerQuotesInstagram = ({
  timestamp,
  content,
  likes,
  imagePath,
  link,
  lens,
  logConsumerQuotesClicked,
  type,
}) => {
  const linkHandler = () => {
    window.open(link, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = `Clicked_Consumer_Quotes`
      const ampiUserData = {
        platform: 'Instagram',
        likes: likes,
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      {/* You need to use the classname to adjust the size of the picture. */}
      {imagePath ? (
        <Box
          sx={{
            height: (theme) => theme.spacing(21.25),
            overflow: 'hidden',
          }}
        >
          <img
            src={imagePath}
            alt="product"
            className="image"
            onError={(e) => {
              e.currentTarget.src = fallbackImg
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: (theme) => theme.spacing(1),
            overflow: 'hidden',
          }}
        ></Box>
      )}
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FavoriteIcon
            sx={{
              color: (theme) =>
                likes !== -1 ? theme.palette.error.main : 'transparent',
              stroke: (theme) =>
                likes !== -1 ? 'transparent' : theme.palette.black,
              height: 17,
              width: 17,
              mr: 0.5,
            }}
          />
          <MessageImg />
          <Box
            sx={{
              ml: 0.5,
              mr: '10px',
            }}
          >
            <SendImg />
          </Box>
          <Typography variant="caption">
            {format(new Date(timestamp), 'MMM dd, yyyy')}
          </Typography>
        </Box>
        <Box>
          <BookmarkImg />
        </Box>
      </Box>
      <Typography
        variant="caption"
        sx={{
          px: 2,
        }}
      >
        {likes !== -1 ? `${likes} ${'\xa0'} likes` : '\xa0'}
      </Typography>
      <Typography
        variant="body3"
        sx={{
          flexGrow: 1,
          pt: 2,
          mb: 3,
          px: 2,
          maxHeight: imagePath ? 68 : '80%',
          overflow: 'auto',
          overflowX: 'hidden',
        }}
      >
        {content}
      </Typography>
      <Divider />
      <Box>
        <InstagramColorImg />
        {/* <Typography
        width="100%"
        textAlign="center"
        variant="subtitle2"
        color="textSecondary"
        px={2}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        @
        {username}
      </Typography> */}
        <MoreHorizIcon
          sx={{
            height: 18,
            width: 20,
          }}
        />
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesInstagram.propTypes = {
  timestamp: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  imagePath: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesInstagram
