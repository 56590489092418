import es6Promise from 'es6-promise'
import * as AC from './actionConstants'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

es6Promise.polyfill()

export const showRoleForm = () => ({
  type: AC.SHOW_ROLE_FORM,
})
export const hideRoleForm = () => ({
  type: AC.HIDE_ROLE_FORM,
})
export const showRoleList = () => ({
  type: AC.SHOW_ROLE_LIST,
})
export const hideRoleList = () => ({
  type: AC.HIDE_ROLE_LIST,
})
export const addAllRole = (roles) => ({
  type: AC.ADD_ALL_ROLES,
  roles,
})
export const addRole = (role) => ({
  type: AC.ADD_ROLE,
  role,
})
export const editRole = (role) => ({
  type: AC.EDIT_ROLE,
  role,
})
export const deleteRole = (roleID) => ({
  type: AC.DELETE_ROLE,
  roleID,
})

export const getAllRolesFromServer = (formInput) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddRole()
    return axios.get(request).then(function (response) {
      dispatch(addAllRole(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addRoleOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddRole()
    return axios.post(request, data).then(function (response) {
      dispatch(addRole(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const updateRoleOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddRole()
    return axios.put(request, data).then(function (response) {
      dispatch(editRole(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteRoleOnServer = (roleID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddRole()
    return axios.delete(request + '/' + roleID).then(function (response) {
      dispatch(deleteRole(roleID))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}
