// Default imports
import React, { useState, useEffect, useRef } from 'react'
import { Tab, Grid } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'

// Component imports
import TourProvider from '../TourProvider'

// Style imports
import { StyledTab, TabLabel, TopBox } from './styled'

// Other imports
import { amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { NAV_TABS } from '../../utils/constants'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in MainCommonTabs')
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const MainCommonTabs = ({ subscriptionDetails }) => {
  const accessibleTabKeys = [
    'personalised_dashboard_access',
    'whitespace',
    'common_trends_analysis',
    'universal_search',
    'regional_analytics',
    'demographic_analytics',
    'show_two_by_two_visualization',
  ]
  const [tab, setTab] = useState('')
  const [tabsToShow, setTabsToShow] = useState([])
  const history = useHistory()
  const path = useLocation().pathname
  const highlightRef = useRef(null)

  const handleTabChange = (event, newValue) => {
    let ampiEvent = 'Clicked_My_Workspace'
    switch (newValue) {
      case 'My Workspace':
        ampiEvent = 'Clicked_My_Workspace'
        break
      case 'Explore Trends':
        ampiEvent = 'Clicked_Explore_Trends'
        break
      case 'Common Trends Analysis':
        ampiEvent = 'Clicked_Common_Trends_Analysis'
        break
      case 'White Space Opportunity':
        ampiEvent = 'Clicked_WSO'
        break
      case 'Universal Trend Search':
        ampiEvent = 'Clicked_Universal_Trend_Search'
        break
      case 'Demographic Analytics':
        ampiEvent = 'Clicked_Demographic_Analytics'
        break
      case 'Regional Analytics':
        ampiEvent = 'Clicked_Regional_Analytics'
        break
    }
    history.push(NAV_TABS.find(({ name }) => name === newValue).url)
    amp(ampiEvent)
  }

  const getTabLabel = (label, isInBeta) => (
    <TabLabel badgeContent="BETA" invisible={!isInBeta}>
      {label}
    </TabLabel>
  )

  const tabs = tabsToShow.map(({ name, isInBeta }, i) => {
    return name === 'White Space Opportunity' ? (
      <Tab
        ref={highlightRef}
        key={name}
        value={name}
        label={getTabLabel(name, isInBeta)}
        {...a11yProps(i)}
      />
    ) : (
      <Tab
        key={name}
        value={name}
        label={getTabLabel(name, isInBeta)}
        {...a11yProps(i)}
      />
    )
  })

  useEffect(() => {
    setTab(NAV_TABS.find(({ url }) => path.includes(url)).name)
  }, [path, tabsToShow])

  useEffect(() => {
    if (subscriptionDetails) {
      // Initialize default tab
      const tabsAllowed = [
        NAV_TABS.find(({ name }) => name === 'Explore Trends'),
      ]
      accessibleTabKeys.forEach((subscriptionKey) => {
        if (subscriptionDetails[subscriptionKey]) {
          subscriptionKey =
            subscriptionKey === 'show_two_by_two_visualization'
              ? 'engagement_visualization'
              : subscriptionKey
          tabsAllowed.push(NAV_TABS.find(({ key }) => subscriptionKey === key))
        }
      })
      tabsAllowed.sort(({ p: pA }, { p: pB }) => pA - pB)
      setTabsToShow(tabsAllowed)
    }
  }, [subscriptionDetails])

  return (
    <Grid container spacing={2} columns={12}>
      <TopBox item xs={11}>
        <StyledTab
          value={tab}
          onChange={handleTabChange}
          aria-label="Navigation tabs"
        >
          {tabs}
        </StyledTab>
      </TopBox>
      {highlightRef.current && (
        <TourProvider feat="WSO" modalRef={highlightRef} />
      )}
    </Grid>
  )
}
export default MainCommonTabs
