import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { withRouter, Link, useHistory } from 'react-router-dom'
import { useLoader } from '../../../hooks'
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  CircularProgress,
  Stack,
  IconButton,
  ToggleButton,
  Checkbox,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import { Search } from '@mui/icons-material'
import { FixedSizeList } from 'react-window'

import { connect } from 'react-redux'
import ToggleButtonGroup from '../ToggleButtonGroup'
import SingleSelect from '../SingleSelect'
import { SearchBox } from '../../../containers/WhiteSpace/Styled'
import StyledTextFeild from '../StyledTextFeild'
import CloseIcon from '@mui/icons-material/Close'
import Checkingbox from '../checkbox'
import TrendCheckBoxList from '../TrendCheckBoxList'
import NoResultsFound from '../AdvancedTrendAnalysisComponents/partials/NoResultsFound'
import DiscardBucketPopUp from './../PopUp/DiscardBucketPopUp'
import { fetchAllTrends } from './../../../../src/store/actions/advancedSearchActions'
import {
  createPillar,
  updatedTrendbucket,
  pillarBasicDetails,
} from './../../../../src/store/actions/createPillarActions'
import * as actions from './../../../store/actions'

import {
  LENSES,
  pillarNameExistsError,
  pillarNameLimitError,
  pillarNameRequiredError,
} from '../../../shared/utils/constants'
import OverlayLoader from '../OverlayLoader'
import useDebounce from '../../hooks/useDebounce'
import { amplify } from './../../../store/utility'
import NestedMultiSelectDropdownContainer from '../NestedMultiSelectDropdownContainer'
import DropdownSelectedFiltersTags from '../DropdownSelectedFiltersTags'
import _ from 'lodash'
import ButtonMultiSelectDrowpdown from '../ButtonMultiSelectDropdown'
import TextOverflowTooltip from '../TextOverflowTooltip'

const TRENDS_LENGTH = 7
const TREND_SELECTION_LIMIT = 300

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const getSelectedFilterLabel = (ingredientFamilyList, depth) => {
      const ingFamilyFilterKeys = Object.keys(ingredientFamilyList)
      return ingFamilyFilterKeys
        .filter(
          (filterApplied) =>
            ingredientFamilyList[filterApplied].depth === depth,
        )
        .map(
          (filteredIngredientFilter) =>
            ingredientFamilyList[filteredIngredientFilter].label,
        )
    }
    const getTrendSelected = (trendsSelectedArr) =>
      trendsSelectedArr.map((trend) => {
        return trend.name
      })
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
    }
    switch (ampiEvent) {
      case 'Clicked_TP_Ing_Fam':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Pillar_Name: ampiUserData.pillarName,
        }
        break
      case 'Clicked_TP_Ing_Fam_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keyword: ampiUserData.searchKeyword,
          Trend_Pillar_Name: ampiUserData.pillarName,
        }
        break
      case 'Clicked_TP_Ing_Fam_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Ingredient_Family: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            0,
          ),
          Ingredient_SubFamily: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            1,
          ),
        }
        break
      case 'Clicked_TP_Create_Save_Pillar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Pillar_Name: ampiUserData.pillarName,
          Lens: ampiUserData.lens,
          Trends_Selected: getTrendSelected(ampiUserData.trendsSelected),
        }
        break
      case 'Clicked_TP_Import_Save_Pillar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Pillar_Name_Change: ampiUserData.pillarName,
          Lens: ampiUserData.lens,
          Trends_Selected: getTrendSelected(ampiUserData.trendsSelected),
        }
        break
      case 'Clicked_TP_Cancel':
        amplitudeUserData = {
          ...amplitudeUserData,
          Pillar_Name_Change: ampiUserData.pillarName,
          Lens: ampiUserData.lens,
          Trends_Selected: getTrendSelected(ampiUserData.trendsSelected),
          Cross: ampiUserData.action,
        }
        break
      case 'Clicked_TP_Import_Cross':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Pillar_Name_Change: ampiUserData.pillarName,
          Trends_Selected: getTrendSelected(ampiUserData.trendsSelected),
          Import_Cross: ampiUserData.importCross,
        }
        break
      case 'Clicked_TP_Import_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Pillar_Name_Change: ampiUserData.pillarName,
          Search_Term: ampiUserData.searchTerm,
        }
        break
      case 'Clicked_TP_Trend_Add_View':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Pillar_Name_Change: ampiUserData.pillarName,
          View: ampiUserData.view ? 'More' : 'Less',
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Trend Edit')
  }
}

const TrendEdit = ({
  pillarDetails,
  fetchAllTrends,
  projectsList,
  pillarBasicDetails,
  updatedTrendbucket,
  createPillar,
  suggestedTrendsList,
  allTrendsList,
  clearPillarDetailsData,
  handleFetchTrendPillars,
  importPillarInfo,
  fetchSuggestedTrends,
  fetchPillarTrends,
  setImportPillarInfo,
  importPillar,
  getTrendPillarforImporting,
  removeSuggestedTrends,
  email,
  userId,
  handleSnackBar,
}) => {
  const [showLoader, setShowLoader] = useLoader()
  const [selectedList, setSelectedList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 300)
  const [showMore, setShowMore] = useState(false)
  const [messageShow, setMessageShow] = useState([])
  const [lensSelected, setLensSelected] = useState(
    pillarDetails[0]?.lens || importPillarInfo[0]?.lens,
  )
  const [disableEdit, setDisableEdit] = useState(true)
  const [showDiscardChangesPopUp, setShowDiscardChangesPopUp] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    pillarDetails[0]?.country || importPillarInfo[0]?.country,
  )

  const [selectedCategory, setSelectedCategory] = useState(
    pillarDetails[0]?.category || importPillarInfo[0]?.category,
  )
  const [pillarName, setPillarName] = useState(
    pillarDetails[0]?.trendPillarName || importPillarInfo[0]?.pillarName,
  )
  const [pillarNameError, setPillarNameError] = useState('')

  const [ingFamilyFilterAPIData, setIngFamilyFilterAPIData] = useState({})
  const [ingFamilyFilter, setIngFamilyFilter] = useState({})
  const [updatedAppliedIngFamily, setUpdatedAppliedIngFamily] = useState({})
  const [isAllTrendSelected, setIsAllTrendSelected] = useState(false)
  const [themeSubCategoryList, setThemeSubCategoryList] = useState([])

  const logDropdownOpened = () => {
    const ampiEvent = 'Clicked_TP_Ing_Fam'
    const ampiUserData = {
      email,
      country: selectedCountry,
      category: selectedCategory,
      pillarName,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logSearchKeywordModified = (searchKeyword) => {
    const ampiEvent = 'Clicked_TP_Ing_Fam_Search'
    const ampiUserData = {
      email,
      country: selectedCountry,
      category: selectedCategory,
      searchKeyword,
      pillarName,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logActionClicked = (ingredientFamilyList, action) => {
    const ampiEvent = 'Clicked_TP_Ing_Fam_Action'
    const ampiUserData = {
      email,
      country: selectedCountry,
      category: selectedCategory,
      ingredientFamilyList,
      action,
    }
    amp(ampiEvent, ampiUserData)
  }

  const subCategoryItems = useMemo(
    () => (type) => {
      if (allTrendsList) {
        const subCategory = allTrendsList[`${type}_sub_category`]
        return subCategory
      } else {
        return []
      }
    },
    [
      allTrendsList?.ingredient_sub_category,
      allTrendsList?.theme_sub_category,
      allTrendsList?.product_sub_category,
    ],
  )

  const ingFamilyAppliedTrendIds = useMemo(() => {
    if (_.isEmpty(ingFamilyFilter)) {
      return []
    }
    return Object.values(ingFamilyFilter.applied).reduce(
      (acc, item) => [...acc, ...item.trends],
      [],
    )
  }, [ingFamilyFilter.applied])

  const addLens = (pillarArray, lens) => {
    return pillarArray.map((pillar) => {
      return { ...pillar, lens: lens }
    })
  }

  const allPillarsList = useMemo(() => {
    const filterCurrentEditingPillar = (arr) => {
      if (pillarDetails[0]?.id) {
        return arr.filter(({ id }) => pillarDetails[0].id !== id)
      }
      return arr
    }
    let allPillars = []
    if (importPillar[0]) {
      const {
        ingredient_pillars: ingPillars,
        theme_pillars: themePillars,
        product_pillars: prodPillars,
      } = importPillar[0]
      if (ingPillars?.length) {
        const ingredientPillarList = addLens([...ingPillars], 'ingredient')

        allPillars = [
          ...(lensSelected === LENSES[0]
            ? filterCurrentEditingPillar(ingredientPillarList)
            : ingredientPillarList),
        ]
      }
      if (themePillars?.length) {
        const themePillarList = addLens([...themePillars], 'theme')
        allPillars = [
          ...allPillars,
          ...(lensSelected === LENSES[2]
            ? filterCurrentEditingPillar(themePillarList)
            : themePillarList),
        ]
      }
      if (prodPillars?.length) {
        const productPillarList = addLens([...prodPillars], 'product')
        allPillars = [
          ...allPillars,
          ...(lensSelected === LENSES[1]
            ? filterCurrentEditingPillar(productPillarList)
            : productPillarList),
        ]
      }
    }
    return allPillars
  }, [importPillar, pillarDetails[0]?.id])

  const pillarNameExists = () => {
    return allPillarsList.some((pillar) => {
      return (
        pillar.name === (pillarName ? pillarName.trim() : pillarName) &&
        pillar.country === selectedCountry &&
        pillar.category === selectedCategory &&
        pillar.lens === lensSelected.toLowerCase() &&
        pillar.user === userId
      )
    })
  }

  const disableNameEdit =
    disableEdit &&
    !(pillarDetails[0]?.trends.length || importPillarInfo[0]?.trends?.length)

  const formatOpts = (list, setGenerator) =>
    Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
      label: item,
      id,
    }))

  const matchingProject = projectsList?.filter((project) => {
    return (
      project._criteria === selectedCountry &&
      project._name === selectedCategory
    )
  })
  const projectId = matchingProject && matchingProject[0]?._id

  const categories = useMemo(() => {
    const prjList = projectsList
    return formatOpts(prjList, (prj) => prj.name)
  }, [projectsList])

  const countries = useMemo(
    () =>
      formatOpts(projectsList, (prj) => prj.criteria).map(({ label, id }) => {
        return {
          label,
          id,
        }
      }),
    [projectsList],
  )

  const allTrends = useMemo(() => {
    let trendsList = []
    if (lensSelected == LENSES[0]) {
      trendsList = allTrendsList && allTrendsList.ingredient
    }
    if (lensSelected == LENSES[1]) {
      trendsList = allTrendsList && allTrendsList.product
    }
    if (lensSelected == LENSES[2]) {
      trendsList = allTrendsList && allTrendsList.theme
    }
    return trendsList
  }, [allTrendsList])

  const trends = useMemo(() => {
    let trendsList = []
    if (!_.isEmpty(allTrends)) {
      trendsList = [...allTrends]
    }
    if (lensSelected == LENSES[0]) {
      // Filtering based on Ingredient Family filter
      if (!_.isEmpty(ingFamilyFilter.applied) && !_.isEmpty(trendsList)) {
        trendsList = trendsList.filter((obj) =>
          ingFamilyAppliedTrendIds.includes(obj.id),
        )
      }
    }

    if (lensSelected == LENSES[2]) {
      // Filtering based on themesubcategory
      if (!_.isEmpty(themeSubCategoryList) && !_.isEmpty(trendsList)) {
        trendsList = trendsList.filter((trend) => {
          return themeSubCategoryList.find(
            (subCategory) =>
              subCategory.label === trend.sub_category &&
              subCategory.checked === true,
          )
        })
      }
    }

    return trendsList
      ? trendsList.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        )
      : []
  }, [allTrends, ingFamilyAppliedTrendIds, themeSubCategoryList])

  const filteredTrendsList = useMemo(() => {
    return trends.filter((val) => {
      return val.name.toLowerCase().includes(debouncedSearchValue.toLowerCase())
    })
  }, [debouncedSearchValue, trends])

  const handleAllSelection = () => {
    if (isAllTrendSelected) {
      setSelectedList([])
    } else {
      const newList = selectedList.concat(
        filteredTrendsList.filter(
          (item) => !selectedList.some((other) => item.id === other.id),
        ),
      )
      setSelectedList(
        newList.length > TREND_SELECTION_LIMIT
          ? newList.slice(0, TREND_SELECTION_LIMIT)
          : newList,
      )
    }
    setIsAllTrendSelected(!isAllTrendSelected)
  }

  const importTrendValues = importPillarInfo[0]?.trends

  useEffect(() => {
    if (filteredTrendsList?.length && importTrendValues) {
      const isSameTrend = (a, b) =>
        a.name?.toLowerCase() === b.name?.toLowerCase()

      const commonTrends = []
      const missingTrends = []
      importTrendValues.forEach((existingTrend) => {
        const matchedTrend = filteredTrendsList.find((trend) =>
          isSameTrend(existingTrend, trend),
        )
        if (matchedTrend) {
          commonTrends.push(matchedTrend)
        } else {
          missingTrends.push(existingTrend)
        }
      })
      if (missingTrends.length) {
        setMessageShow(missingTrends)
      }
      setSelectedList(commonTrends)
    }
  }, [allTrendsList, importPillarInfo])

  useEffect(() => {
    if (
      importPillarInfo[0] &&
      !importPillarInfo[0].trends &&
      importPillarInfo[0].pillarId
    ) {
      fetchPillarTrends(getLens(), importPillarInfo[0].pillarId)
    }
  }, [importPillarInfo])

  const suggestedTrends = useMemo(() => {
    if (pillarName || debouncedSearchValue) {
      const { theme } = suggestedTrendsList
      if (theme) {
        const valsToMatch = [
          ...new Set((debouncedSearchValue || pillarName).split(' ')),
        ]
        return valsToMatch
          .reduce(
            (acc, subTxt) => [...acc, ...(theme[subTxt.toUpperCase()] || [])],
            [],
          )
          .reduce((matchedArr, trend) => {
            const matchedTrend = (trends || []).find(
              ({ id }) => id === trend.id,
            )
            if (matchedTrend) {
              return [...matchedArr, matchedTrend]
            }
            return matchedArr
          }, [])
      }
    }
    return []
  }, [pillarName, allTrendsList, suggestedTrendsList, debouncedSearchValue])

  const selectedTrends =
    showMore || selectedList.length < TRENDS_LENGTH
      ? selectedList
      : selectedList.slice(0, TRENDS_LENGTH)

  const handleCheckBoxClick = useCallback(
    (option) => {
      const isTrendSelected = selectedList.some((opt) => opt.id === option.id)
      if (isTrendSelected) {
        setSelectedList(selectedList.filter((opt) => opt.id !== option.id))
      } else {
        if (selectedList.length >= TREND_SELECTION_LIMIT) {
          const snackBarMessage = `Only ${TREND_SELECTION_LIMIT} trends can be added to a pillar`
          handleSnackBar(snackBarMessage, 'info')
        } else {
          setSelectedList([...selectedList, option])
        }
      }
    },
    [selectedList],
  )

  const handleToggleSelection = () => (event, toggle) => {
    if (toggle !== null) {
      setLensSelected(toggle)
    }
  }

  const handleEditPillarName = () => {
    if (disableNameEdit) {
      setShowDiscardChangesPopUp(true)
      cancelHandler()
    }
  }

  const logDiscard = (action) => {
    const ampiEvent = 'Clicked_TP_Cancel'
    const ampiUserData = {
      email,
      lens: lensSelected,
      pillarName,
      country: selectedCountry,
      category: selectedCategory,
      trendsSelected: selectedTrends,
      action,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleDiscardBucketCancel = () => {
    setShowDiscardChangesPopUp(false)
    logDiscard('Cancel')
  }

  const handleDiscard = () => {
    setDisableEdit(false)
    setShowDiscardChangesPopUp(false)
    setSelectedList([])
    setPillarName('')
    setSelectedCategory('')
    setSelectedCountry('')
    pillarBasicDetails('', '', '', '', [], -1)
    setImportPillarInfo()
    logDiscard('Discard')
  }

  const bucketNameHandler = (val) => {
    setPillarName(val)
  }

  const getLens = () => {
    return lensSelected.toLowerCase()
  }

  const createEditPillarDetailsResolver = () => {
    clearPillarDetailsData()
    handleFetchTrendPillars(lensSelected)
  }

  const handleSavePillarClick = () => {
    if (!pillarNameExists()) {
      let ampiEvent
      if (pillarDetails[0]?.trends.length) {
        ampiEvent = 'Clicked_TP_Create_Save_Pillar'
        const data = {
          id: pillarDetails[0].id,
          trends: selectedList.map((trend) => {
            return trend.id
          }),
          name: pillarName.trim(),
        }
        setShowLoader(true)
        updatedTrendbucket(data, getLens()).then((success) => {
          if (success) {
            const snackBarMessage = `Changes in Trend Pillar 'L1 ${pillarName}' has been saved successfully.`
            handleSnackBar(snackBarMessage, 'success')
            createEditPillarDetailsResolver()
          }
          setShowLoader(false)
        })
      } else {
        ampiEvent = 'Clicked_TP_Import_Save_Pillar'
        const data = {
          project_id: projectId,
          trends: selectedList.map((trend) => {
            return trend.id
          }),
          name: pillarName.trim(),
        }
        setShowLoader(true)
        createPillar(data, getLens()).then((success) => {
          if (success) {
            const snackBarMessage = `'L1 ${pillarName}' created successfully.`
            handleSnackBar(snackBarMessage, 'success')
            createEditPillarDetailsResolver()
          }
          setShowLoader(false)
        })
      }
      const ampiUserData = {
        email,
        lens: lensSelected,
        pillarName,
        country: selectedCountry,
        category: selectedCategory,
        trendsSelected: selectedTrends,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  const cancelHandler = () => {
    const pillarLen = pillarDetails[0]?.trends.length
    if (pillarLen) {
      const sameTrendSelected = pillarDetails[0].trends.every(
        ({ id: existingId }) =>
          selectedList.some(({ id }) => existingId === id),
      )
      if (
        !sameTrendSelected ||
        pillarLen !== selectedList.length ||
        pillarDetails[0].trendPillarName !== pillarName
      ) {
        setShowDiscardChangesPopUp(true)
      } else {
        clearPillarDetailsData()
      }
    }
    const pillarLength = importPillarInfo[0]?.trends.length
    if (pillarLength) {
      const sameTrendSelect = importPillarInfo[0].trends.every(
        ({ id: existingId }) =>
          selectedList.some(({ id }) => existingId === id),
      )
      if (
        !sameTrendSelect ||
        pillarLen !== selectedList.length ||
        pillarDetails[0].trendPillarName !== pillarName
      ) {
        setShowDiscardChangesPopUp(true)
      } else {
        setImportPillarInfo()
      }
    } else {
      setShowDiscardChangesPopUp(true)
    }
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    if (!val) {
      setSelectedCategory('')
    }
  }

  const handleProceed = () => {
    setDisableEdit(true)
    pillarBasicDetails(
      pillarName,
      selectedCountry,
      selectedCategory,
      lensSelected,
      [],
      -1,
    )
    fetchAllTrends(matchingProject && matchingProject[0]?._id)
  }

  useEffect(() => {
    if (pillarDetails[0]?.trends.length) {
      setSelectedList(
        pillarDetails[0].trends.map((trend) => {
          return { name: trend.trend_Name, id: trend.id, image: trend.image }
        }),
      )
    }
    if (!importPillar[0]) {
      getTrendPillarforImporting()
    }
  }, [])

  useEffect(() => {
    getTrendPillarforImporting()
  }, [importPillar?.ingredient_pillars]) //eslint-disable-line

  useEffect(() => {
    if (projectId) {
      setShowLoader(true)
      fetchAllTrends(projectId).then(() => setShowLoader(false))
    }
  }, [projectId])

  useEffect(() => {
    if (pillarDetails[0]?.trends?.length && trends?.length) {
      setSelectedList(
        pillarDetails[0].trends.map((trend) => {
          return {
            name: trend.trend_Name,
            id: trend.id,
            image: trends.find((data) => data.id === trend.id)?.image,
          }
        }),
      )
    }
  }, [pillarDetails[0]?.trends?.length, trends])

  useEffect(() => {
    if (selectedCategory && selectedCountry && lensSelected) {
      if (lensSelected === LENSES[2]) {
        setShowLoader(true)
        fetchSuggestedTrends(selectedCountry, selectedCategory, getLens()).then(
          () => {
            setShowLoader(false)
          },
        )
      }
    }
  }, [selectedCategory, selectedCountry, lensSelected])

  useEffect(() => {
    if (projectId && lensSelected === LENSES[0] && !_.isEmpty(allTrends)) {
      setShowLoader(true)
      actions
        .fetchTrendFamilyFilterItems(
          allTrends.map(({ id }) => id),
          `${lensSelected}s`,
          projectId,
        )
        .then((result) => {
          setIngFamilyFilterAPIData(result)
          setShowLoader(false)
        })
        .catch((error) => {
          console.log(error)
          setShowLoader(false)
        })
    }
  }, [projectId, lensSelected, allTrends])

  useEffect(() => {
    return () => removeSuggestedTrends()
  }, [])

  const noResults = (text) => (
    <Box
      sx={{
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={3}
      >
        <Typography sx={{ fontSize: '14px' }}>No {text} Trends</Typography>
      </Grid>
    </Box>
  )

  const checkBoxList = (optionsList) => (
    <TrendCheckBoxList
      selectedList={selectedList}
      data={optionsList}
      handleCheckBoxClick={handleCheckBoxClick}
    />
  )

  const handleThemeSubCategorySelection = (selectedSubCategory) => {
    const newThemeSubCategory = themeSubCategoryList.map((subCategory) => {
      if (subCategory.label === selectedSubCategory.label) {
        return { ...subCategory, checked: !selectedSubCategory.checked }
      }
      return subCategory
    })
    setThemeSubCategoryList(newThemeSubCategory)
  }

  const sectionHeadingContainer = (text) => (
    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
      <Typography sx={{ fontSize: '14px', color: '#A2B6CA' }}>
        {text}
      </Typography>
    </Grid>
  )

  const searchHeadingText = () => {
    let headingText = `All ${lensSelected}s (${filteredTrendsList.length}) (Select the desired ${lensSelected}s)`
    if (lensSelected === LENSES[2] && suggestedTrends.length > 0) {
      headingText = 'Suggested Themes (Select the desired themes)'
    }
    if (debouncedSearchValue) {
      headingText = `Search Result (${filteredTrendsList.length})`
    }
    return sectionHeadingContainer(headingText)
  }

  const suggestedTrendsHeadingText = () => {
    let headingText = ''
    const suggestedTrendsLength = suggestedTrends.length
    if (debouncedSearchValue) {
      if (suggestedTrendsLength) {
        headingText = `${lensSelected}s related to "${debouncedSearchValue}" (${suggestedTrendsLength})`
      }
    } else {
      if (suggestedTrendsLength) {
        headingText = `Other ${lensSelected}s (${filteredTrendsList.length -
          suggestedTrendsLength})`
      }
    }
    return headingText ? sectionHeadingContainer(headingText) : null
  }

  const themesComponent = () => {
    const suggestedTrendsLength = suggestedTrends.length

    // Get trends list based on search value
    let filteredTrendsComp = (
      <Box sx={{ pt: 1, mb: 2 }}>
        {filteredTrendsList.length
          ? checkBoxList(filteredTrendsList)
          : noResults('Matched')}
      </Box>
    )
    if (suggestedTrendsLength > 0) {
      const suggestionExcludedList = filteredTrendsList.filter(
        ({ id }) => !suggestedTrends.some((trend) => trend.id === id),
      )
      filteredTrendsComp = (
        <Box sx={{ pt: 1, mb: 2 }}>
          {suggestionExcludedList.length
            ? checkBoxList(suggestionExcludedList)
            : noResults('Matched')}
        </Box>
      )
    }
    const suggestedTrendsComp = suggestedTrendsLength > 0 && (
      <Box sx={{ pt: 1, pb: 3 }}>{checkBoxList(suggestedTrends)}</Box>
    )
    if (debouncedSearchValue) {
      return (
        <>
          {filteredTrendsComp}
          {suggestedTrendsHeadingText()}
          {suggestedTrendsComp}
        </>
      )
    }
    return (
      <>
        {suggestedTrendsComp}
        {suggestedTrendsHeadingText()}
        {filteredTrendsComp}
      </>
    )
  }

  const themeTrends = useMemo(
    () => (lensSelected === LENSES[2] ? themesComponent() : null),
    [suggestedTrends, debouncedSearchValue, filteredTrendsList, selectedList],
  )

  const logShowMore = () => {
    const ampiEvent = 'Clicked_TP_Trend_Add_View'
    const ampiUserData = {
      email,
      lens: lensSelected,
      pillarName,
      country: selectedCountry,
      category: selectedCategory,
      view: !showMore,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleShowMore = () => {
    logShowMore()
    setShowMore(!showMore)
  }

  const logMessageClose = () => {
    const ampiEvent = 'Clicked_TP_Import_Cross'
    const ampiUserData = {
      email,
      lens: lensSelected,
      pillarName,
      country: selectedCountry,
      category: selectedCategory,
      trendsSelected: selectedTrends,
      importCross: true,
    }
    amp(ampiEvent, ampiUserData)
  }

  const messageCloseHandler = () => {
    logMessageClose()
    setMessageShow([])
  }

  const ingFamilyStateChange = (state) => {
    setIngFamilyFilter(state)
  }
  const handleRemoveAppliedIngFamily = (idsToRemove) => {
    setUpdatedAppliedIngFamily(_.omit(ingFamilyFilter.applied, idsToRemove))
  }

  const ingProdTrendsComponent = () => {
    return (
      <Box sx={{ pt: 1, mb: 2 }}>
        {filteredTrendsList.length
          ? checkBoxList(filteredTrendsList)
          : noResults('Searched')}
      </Box>
    )
  }
  const ingProdTrends = useMemo(() => ingProdTrendsComponent(), [
    filteredTrendsList,
    selectedList,
    debouncedSearchValue,
  ])

  useEffect(() => {
    if (pillarName.trim().length > 64) {
      setPillarNameError(pillarNameLimitError)
    } else if (pillarNameExists()) {
      setPillarNameError(pillarNameExistsError)
    } else if (!pillarName) {
      setPillarNameError(pillarNameRequiredError)
    } else {
      setPillarNameError('')
    }
  }, [pillarName])

  const logSearch = () => {
    if (searchValue) {
      const ampiEvent = 'Clicked_TP_Import_Search'
      const ampiUserData = {
        email,
        lens: lensSelected,
        pillarName,
        country: selectedCountry,
        category: selectedCategory,
        searchTerm: searchValue,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  const getSelectedSubCategoryLabel = () => {
    let selectedLabel = ''
    let count = 0
    themeSubCategoryList.forEach((subCategory) => {
      if (subCategory.checked == true) {
        count++
        selectedLabel += `${subCategory.label},`
      }
    })
    if (count === themeSubCategoryList.length) {
      selectedLabel = 'All'
    } else {
      if (selectedLabel !== '') {
        selectedLabel = selectedLabel.slice(0, -1)
      }
    }

    return selectedLabel
  }

  useEffect(() => {
    logSearch()
  }, [searchValue])

  useEffect(() => {
    const themeSubCategoryList = allTrendsList?.theme_sub_category
      .map((obj) => {
        return {
          key: obj.name,
          label: obj.name,
          checked: true,
        }
      })
      .filter((trend) => trend.label !== 'All')
    setThemeSubCategoryList(themeSubCategoryList)
  }, [allTrendsList])

  return (
    trends && (
      <>
        {showLoader && <OverlayLoader />}
        {showDiscardChangesPopUp && (
          <DiscardBucketPopUp
            text="Are you sure you want to discard the changes?"
            handleDiscardBucketCancel={handleDiscardBucketCancel}
            handleDiscard={handleDiscard}
          />
        )}
        <Box>
          <Grid
            container
            columns={18}
            columnSpacing={3}
            mt={2}
            pt={2}
            pb={2}
            display={'flex'}
            flexWrap={'nowrap'}
            sx={{
              backgroundColor: ({ palette }) => palette.primary.contrastText,
            }}
          >
            <Grid item xs={15} display={'flex'} gap={2}>
              <StyledTextFeild
                text="Pillar:"
                disabled={disableNameEdit}
                value={pillarName}
                onChange={(e) => bucketNameHandler(e.target.value)}
                handleClick={handleEditPillarName}
                // TODO: Add error handler controller
                error={pillarNameError}
              />
              <SingleSelect
                options={countries}
                text="Country: "
                tooltipShow={true}
                disabled={disableEdit}
                value={selectedCountry}
                sx={{
                  width: '200px',
                  fontSize: '14px',
                  backgroundColor: 'white',
                }}
                onChange={(ev, val) => handleCountryChange(val?.label)}
              />
              <SingleSelect
                options={categories}
                text="Category: "
                tooltipShow={true}
                disabled={disableEdit}
                value={selectedCategory}
                sx={{
                  width: '200px',
                  fontSize: '14px',
                  backgroundColor: 'white',
                }}
                onChange={(ev, val) => setSelectedCategory(val?.label)}
              />
              <Stack spacing={1}>
                <Stack direction="row">
                  <ToggleButtonGroup
                    value={lensSelected}
                    disabled={disableEdit}
                    exclusive
                    onChange={handleToggleSelection()}
                    aria-label="lens"
                  >
                    {LENSES.map((lens) => (
                      <ToggleButton
                        value={lens}
                        disabled={disableEdit}
                        aria-label={`${lens} lens`}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {`${lens}s`}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              </Stack>
              {lensSelected === LENSES[0] && (
                <Box>
                  <NestedMultiSelectDropdownContainer
                    updatedFilterData={ingFamilyFilterAPIData}
                    filterTagsAppliedFilters={updatedAppliedIngFamily}
                    stateChangeCallback={ingFamilyStateChange}
                    dimensions={{
                      width: 250,
                      maxItemsToFitInHeight: 6.5,
                    }}
                    placeHolder="Select Ingredient Family"
                    isLoggingRequired
                    logDropdownOpened={logDropdownOpened}
                    logActionClicked={logActionClicked}
                    logSearchKeywordModified={logSearchKeywordModified}
                  />
                </Box>
              )}
              {lensSelected === LENSES[2] && themeSubCategoryList?.length > 0 && (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      width: '100px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Themes Filter:{' '}
                  </Typography>
                  <ButtonMultiSelectDrowpdown
                    searchBarEnabled={false}
                    buttonProps={{
                      variant: 'outlined',
                      sx: { height: '35px' },
                    }}
                    optWidth={300}
                    shouldSortOpts={false}
                    useParentAnchor
                    options={themeSubCategoryList}
                    buttonContent={{
                      content: (
                        <TextOverflowTooltip
                          checkWidth={true}
                          maxLineCount={1}
                          title={getSelectedSubCategoryLabel()}
                        >
                          <Typography
                            sx={{
                              maxWidth: '100px',
                              minWidth: '100px',
                              maxHeight: '20px',
                            }}
                          >
                            {getSelectedSubCategoryLabel()}
                          </Typography>
                        </TextOverflowTooltip>
                      ),
                      hasDropDownIcon: true,
                      showNumOfFiltersApplied: false,
                    }}
                    dynamicApply={true}
                    onOptClick={(data) => handleThemeSubCategorySelection(data)}
                    selectAllOption={{
                      enabled: true,
                      updateParentOptions: (checked) => {
                        setThemeSubCategoryList(
                          themeSubCategoryList.map((data) => ({
                            ...data,
                            checked: checked,
                          })),
                        )
                      },
                    }}
                  />
                </>
              )}
              {!disableEdit && (
                <Button
                  sx={{
                    marginLeft: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '35px',
                  }}
                  onClick={handleProceed}
                  variant="contained"
                >
                  Proceed
                </Button>
              )}
            </Grid>

            {disableEdit && (
              <Grid
                item
                xs={3}
                justifyContent="flex-end"
                display="flex"
                alignItems="end"
                sx={{ height: '35px' }}
              >
                <Button
                  sx={{
                    height: '35px',
                    border: ({ palette }) =>
                      `1px solid ${palette.primary.main}`,
                  }}
                  variant="outlined"
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    marginLeft: '10px',
                    backgroundColor: ({ palette }) =>
                      selectedList.length && pillarName && !pillarNameError
                        ? palette.primary.main
                        : `${palette.grey[550]} !important`,
                    color: 'white !important',
                    height: '35px',
                  }}
                  variant="contained"
                  disabled={
                    !(selectedList.length && pillarName) ||
                    pillarNameError !== ''
                  }
                  onClick={handleSavePillarClick}
                >
                  Save Pillar
                </Button>
              </Grid>
            )}
          </Grid>
          <Box>
            <DropdownSelectedFiltersTags
              maxFilterTagsToShow={5}
              filterState={ingFamilyFilter}
              handleRemoveAppliedFilters={handleRemoveAppliedIngFamily}
              headerText="Ingredient Family:"
            />
          </Box>
          {!disableEdit && <NoResultsFound title="the above selected data" />}
          {disableEdit && (
            <Box>
              <Box>
                <Box>
                  <Grid
                    container
                    columns={16}
                    pt={1}
                    pb={1}
                    display={'flex'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                  >
                    {selectedTrends.map((option) => (
                      <Box m={1}>
                        <Checkingbox
                          isChecked={true}
                          option={option}
                          handleCheckBoxClick={handleCheckBoxClick}
                        />
                      </Box>
                    ))}
                    {selectedList.length > TRENDS_LENGTH && (
                      <Button
                        sx={{
                          height: 'fit-content',
                          border: ({ palette }) =>
                            `1px solid ${palette.primary.main}`,
                        }}
                        variant="outlined"
                        onClick={handleShowMore}
                        data-testid="a"
                      >
                        {showMore
                          ? 'View Less'
                          : `+${selectedList.length - TRENDS_LENGTH} More`}
                      </Button>
                    )}
                  </Grid>
                </Box>
                {messageShow.length > 0 && (
                  <Grid
                    container
                    columns={16}
                    pt={2}
                    pb={2}
                    display={'flex'}
                    flexWrap={'nowrap'}
                    sx={{ backgroundColor: 'rgba(2, 116, 202, 0.03)' }}
                  >
                    <Grid
                      item
                      xs={15}
                      display={'flex'}
                      alignItems={'center'}
                      gap={3}
                    >
                      <Typography sx={{ fontSize: '14px' }}>
                        {`${
                          messageShow.length === 1
                            ? 'This trend does'
                            : 'These trends do'
                        } not exist in the selected Dashboard - `}
                        {messageShow.map((trend, index) => {
                          return ` ${trend.name}${
                            index == messageShow.length - 1 ? '.' : ','
                          }`
                        })}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="close"
                        onClick={messageCloseHandler}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box>
                <Grid
                  container
                  columns={16}
                  pt={1}
                  pb={0}
                  display={'flex'}
                  flexWrap={'nowrap'}
                >
                  {searchHeadingText()}
                  <Grid
                    item
                    xs={4}
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="end"
                  >
                    <SearchBox
                      variant="outlined"
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder={`Search`}
                      value={searchValue}
                      size="large"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchValue && (
                              <ClearIcon
                                cursor="pointer"
                                onClick={() => {
                                  setSearchValue('')
                                }}
                                color="secondary"
                                fontSize="small"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={isAllTrendSelected}
                  onChange={handleAllSelection}
                />
                <Typography>Select All</Typography>
              </Box>
              {filteredTrendsList.length || suggestedTrends.length ? (
                lensSelected === LENSES[2] ? (
                  themeTrends
                ) : (
                  ingProdTrends
                )
              ) : (
                <NoResultsFound
                  title={
                    debouncedSearchValue
                      ? 'the searched data'
                      : 'the selected filter'
                  }
                />
              )}
            </Box>
          )}
        </Box>
      </>
    )
  )
}

const mapStateToProps = (state) => {
  const { projectListBuilder } = state
  return {
    productsList: projectListBuilder.productList,
    pillarDetails: state.trendPillar.pillarDetails,
    allTrendsList: projectListBuilder.allTrendsList,
    projectsList: state.savedTrendRequester.savedTrendList?._list || [],
    importPillarInfo: state.trendPillar.importPillarInfo,
    suggestedTrendsList: state.trendPillar.suggestedTrends,
    importPillar: state.trendPillar.importDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    userId: state.user?.loggedIn?.user?.user_id,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllTrends: (projectId) =>
    dispatch(fetchAllTrends(projectId, true, true)),
  createPillar: (data, lens) => dispatch(createPillar(data, lens)),
  fetchLensesAvailable: (projectId) =>
    dispatch(actions.fecthFeaturesDetails(projectId)),
  updatedTrendbucket: (data, lens) => dispatch(updatedTrendbucket(data, lens)),
  pillarBasicDetails: (
    pillarName,
    countrySelected,
    categorySelected,
    lensSelected,
    trends,
    id,
  ) => {
    dispatch(
      pillarBasicDetails(
        pillarName,
        countrySelected,
        categorySelected,
        lensSelected,
        trends,
        id,
      ),
    )
  },
  clearPillarDetailsData: () => dispatch(actions.clearPillarDetailsData()),
  fetchSuggestedTrends: (country, category, lens) =>
    dispatch(actions.fetchSuggestedTrends(country, category, lens)),
  fetchPillarTrends: (lens, pillarId) =>
    dispatch(actions.fetchPillarTrends(lens, pillarId)),
  setImportPillarInfo: () => dispatch(actions.setImportPillarInfo()),
  getTrendPillarforImporting: () =>
    dispatch(actions.getTrendPillarforImporting()),
  removeSuggestedTrends: () => dispatch(actions.removeSuggestedTrends()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrendEdit),
)
