import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Tooltip } from '@mui/material'
import { calcDisplayedWidth } from '../../store/utility'

export default function TooltipOnOverflow({
  title,
  text = null,
  children,
  extraSpace = 0,
}) {
  const [boxLength, setBoxLength] = useState(150)

  const childrenWithProps = React.cloneElement(children, {
    onMouseEnter: (e) => {
      setBoxLength(e.currentTarget.offsetWidth)
    },
  })

  const valueWidth = (val, text) => {
    return val &&
      calcDisplayedWidth(
        `${text || ''}   ${val}`,
        16,
        'Roboto',
        400,
        'normal',
      ) <
        boxLength - extraSpace
      ? ''
      : val
  }

  return (
    <Box>
      <Tooltip
        title={valueWidth(title, text)}
        // open={false}
        disableFocusListener={true}
        disableHoverListener={
          calcDisplayedWidth(
            `${text || ''}   ${title}`,
            16,
            'Roboto',
            400,
            'normal',
          ) <
          boxLength - extraSpace
        }
      >
        {childrenWithProps}
      </Tooltip>
    </Box>
  )
}
