import { styled } from '@mui/system';
import { Grid, Paper, Box } from '@mui/material';

export const FoodServiceProductsContainer = styled(Paper)(() => ({
  boxShadow: '0px 4px 12px 0px #272B3108',
}));

export const ProductCardsContainer = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  maxHeight: '430px',
  alignItems: 'stretch',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: 4,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '430px',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 4,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  },
}));
