import { SavedTrendsActionTypes } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

import SavedProjectsList from '../../services/networking/Models/SavedProjectsList'
import { addRequest, doneRequest } from './downloadSections'

export const fetchSavedTrends = () => {
  return (dispatch, getState) => {
    const requestFrom = {
      name: 'fetchSavedTrends',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    dispatch(fetchSavedTrendsStart())
    const request = NetworkUtil.formSavedProjectListRequest()
    return axios
      .get(request)
      .then((response) => {
        const responseObject = new SavedProjectsList(response.data)
        dispatch(fetchSavedTrendsSuccess(responseObject))
        dispatch(doneRequest(requestFrom))
        return true
      })
      .catch((error) => {
        dispatch(fetchSavedTrendsfailed(error))
        dispatch(doneRequest(requestFrom))
        return false
      })
  }
}

const fetchSavedTrendsStart = () => {
  return {
    type:
      SavedTrendsActionTypes.type[
        SavedTrendsActionTypes.FETCH_SAVED_TRENDS_START
      ],
  }
}

const fetchSavedTrendsSuccess = (savedTrends) => {
  return {
    type:
      SavedTrendsActionTypes.type[
        SavedTrendsActionTypes.FETCH_SAVED_TRENDS_SUCCESS
      ],
    savedTrends: savedTrends,
  }
}

const fetchSavedTrendsfailed = (error) => {
  return {
    type:
      SavedTrendsActionTypes.type[
        SavedTrendsActionTypes.FETCH_SAVED_TRENDS_FAIL
      ],
    error: error,
  }
}
