import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import {
  Divider,
  FormControl,
  Grid,
  Typography,
  Button,
  Alert,
  Collapse,
  IconButton,
} from '@mui/material'
import LangMenus from '../LangMenus'

import {
  ProductCardsContainer,
  FoodServiceProductsContainer,
  StyledBox,
} from './styles'
import NeedLinearProgress from '../NeedLinearProgress'
import FoodServiceCard from '../FoodServiceCard'
import DownloadButton from '../DownloadButton'
import { amplify } from './../../../store/utility'
import { ErrorOutlineRounded, ErrorRounded, Close } from '@mui/icons-material'
import DialogWithCheckboxOptions from '../MarkIrrelevant/DialogWithCheckboxOptions'
import OverlayLoader from '../OverlayLoader'

const FoodServiceProducts = (props) => {
  const { cafeProducts, noSide, expand, showCount, isProductPage } = props
  const { urlLensSelected } = useParams()
  const [selectedLang, setSelectedLang] = useState('English')
  const [imgErrorIds, setImgErrorIds] = useState([])
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedIrrelevantDocs, setSelectedIrrelevantDocs] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [loader, setLoader] = useState(false)
  const [irrelevantCount, setIrrelevantCount] = useState(0)
  const dialogElement = useRef(null)

  const showLoader = () => {
    setLoader(true)
  }

  const hideLoader = () => {
    setLoader(false)
    setIsBackdropOpen(false)
    setSelectedIrrelevantDocs([])
  }

  const toggleShowAlert = () => {
    setIrrelevantCount(selectedIrrelevantDocs.length)
    setShowAlert(!showAlert)
  }

  const toggleMarkIrrelevantBackdrop = () => {
    setIsBackdropOpen(!isBackdropOpen)
    closeDialog()
    if (showAlert && selectedIrrelevantDocs.length >= 1) {
      setShowAlert(false)
    } else {
      setSelectedIrrelevantDocs([])
    }
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const hideAlert = () => {
    setShowAlert(false)
    setTimeout(() => {
      setIrrelevantCount(0)
    }, 1500)
  }

  const handleEsDocSelection = (e) => {
    if (
      e.target.checked &&
      selectedIrrelevantDocs.indexOf(e.target.value) === -1
    ) {
      setSelectedIrrelevantDocs([...selectedIrrelevantDocs, e.target.value])
    } else {
      setSelectedIrrelevantDocs(
        selectedIrrelevantDocs.filter((doc) => doc !== e.target.value),
      )
    }
  }

  useEffect(() => {
    if (isDialogOpen && dialogElement.current) {
      dialogElement.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      })
    }
  }, [isDialogOpen])

  const handleLangChange = (event) => {
    setSelectedLang(event.target.value)
    const ampiEvent = 'Clicked_Food_Service_Product_DisplayLanguage'
    const ampiUserData = {
      User: props.email,
      Language_Name: event.target.value,
      Ingredient_Name: props.name,
      Country_Name: props.country,
      Category_Name: props.category,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const validCafeProducts = useMemo(
    () =>
      cafeProducts.filter(
        // eslint-disable-next-line
        ({ name, image_url, image_uri }) =>
          !imgErrorIds.find(
            // eslint-disable-next-line
            (id) => `${name}##${image_url || image_uri}` === id,
          ),
      ),
    [imgErrorIds, cafeProducts],
  )
  const hasNativeName = cafeProducts.some((product) => product.native_name)

  useEffect(() => {
    // eslint-disable-next-line
    cafeProducts.forEach(({ image_url, image_uri, name }) => {
      const img = new Image()
      // eslint-disable-next-line
      const validImgUrl = image_url || image_uri
      img.onerror = () => {
        img.onerror = null
        setImgErrorIds((imgErrorIds) => [
          ...imgErrorIds,
          `${name}##${validImgUrl}`,
        ])
      }
      img.src = validImgUrl
    })
  }, [cafeProducts])

  if (!validCafeProducts.length && noSide) {
    return null
  }

  return (
    <FoodServiceProductsContainer>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Box>
            <Typography variant="h3">Food Service Products</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="subtitle2">Display Language:</Typography>
            <FormControl variant="outlined" size="large">
              <LangMenus
                hasNativeLang={hasNativeName}
                country={props.country}
                handleChange={handleLangChange}
              />
            </FormControl>
            {showCount && (
              <Box>
                <Typography
                  component="div"
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{
                    width: 'max-content',
                  }}
                >
                  {`Count: ${props.cafeDataPoint}`}
                </Typography>
              </Box>
            )}
            {!noSide && (
              <DownloadButton
                dowImageType="Cafe"
                dowPptType={props.productType}
                toggleImageDownload={props.toggleImageDownload}
                handleDownloadExcel={props.fSExcelDownload}
                togglePptDownload={props.togglePptDownload}
                lensSelected={urlLensSelected}
                title="_Food_Service_Product"
              />
            )}
            {isBackdropOpen ? (
              <Box>
                <Button
                  variant="grayishblue"
                  onClick={toggleMarkIrrelevantBackdrop}
                  sx={{ marginRight: '8px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant={
                    selectedIrrelevantDocs.length ? 'contained' : 'grayishblue'
                  }
                  size="small"
                  onClick={openDialog}
                  disabled={selectedIrrelevantDocs.length <= 0}
                >
                  Confirm
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                size="small"
                startIcon={<ErrorOutlineRounded />}
                onClick={toggleMarkIrrelevantBackdrop}
              >
                Mark Irrelevant
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Grid
        container
        spacing={1}
        id="food-products-dialog-container"
        sx={
          expand
            ? {
                maxHeight: 'fit-content !important',
                height: 'fit-content !important',
                overflowY: 'auto !important',
                position: 'relative',
              }
            : { position: 'relative' }
        }
      >
        <Box
          sx={{
            width: 'auto',
            position: 'absolute',
            top: 10,
            right: 25,
          }}
        >
          <Collapse in={showAlert}>
            <Alert
              severity="info"
              icon={<ErrorRounded fontSize="inherit" />}
              action={
                <IconButton
                  aria-label="close"
                  color="info"
                  size="small"
                  onClick={() => {
                    hideAlert()
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {irrelevantCount}{' '}
              {irrelevantCount > 1
                ? 'Food Service Products have '
                : 'Food Service Product has '}
              been marked as irrelevant.
            </Alert>
          </Collapse>
        </Box>
        {loader ? <OverlayLoader /> : null}
        <DialogWithCheckboxOptions
          isOpen={isDialogOpen}
          toggleAlert={() => {
            toggleShowAlert()
          }}
          closeDialog={() => closeDialog()}
          isOtherOptionSelected={false}
          dialogElement={dialogElement}
          selectedIrrelevantDocs={selectedIrrelevantDocs}
          lens={urlLensSelected || props.lensSelected || props.lens}
          country={props.country}
          category={props.category}
          name={props.name || props.trendName}
          showLoader={() => showLoader()}
          hideLoader={() => hideLoader()}
          containerId={'food-products-dialog-container'}
          type="food"
          onRefresh={() => {
            props.onRefresh()
          }}
        />

        {!isProductPage
          ? !noSide && (
              <Grid item xs={3}>
                <Box p={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h4">Formats</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      Products &nbsp;
                      <Typography component="span" variant="subtitle2">
                        {props.cafeDataPoint}
                      </Typography>
                    </Typography>
                  </Box>
                  <StyledBox sx={{ mt: 1, mx: -1 }}>
                    {Object.entries(props.cafeProductFormats || []).map(
                      ([title, value]) => (
                        <NeedLinearProgress
                          {...{
                            title,
                            value,
                          }}
                          link={`/mui/details/productFormat/${
                            props.projectid
                          }/${urlLensSelected}/${props.productid}/${
                            props.productType
                          }/${encodeURIComponent(title)}`}
                          country={props.country}
                          category={props.category}
                          ingredientName={props.name}
                          email={props.email}
                          displayTitle="Food_Service_Product"
                          heading="Format_Name"
                        />
                      ),
                    )}
                  </StyledBox>
                </Box>
              </Grid>
            )
          : null}

        <Grid item xs>
          <Box p={2}>
            <Box>
              {!noSide && <Typography variant="h4">Sample Products</Typography>}
            </Box>
            <ProductCardsContainer
              sx={
                expand
                  ? {
                      maxHeight: 'fit-content !important',
                      height: 'fit-content !important',
                      overflowY: 'auto !important',
                    }
                  : props.isTrendInfoPage
                  ? {
                      height: 320,
                    }
                  : {}
              }
              mt={1}
              container
              spacing={1}
              pt={2}
              columns={props.noSide ? 10 : 12}
            >
              {selectedLang === 'English' &&
                validCafeProducts.map((item) => (
                  <Grid
                    item
                    xs={props.isTrendInfoPage ? 5 : !noSide ? 3 : 2}
                    key={item.es_id}
                  >
                    <FoodServiceCard
                      title={item.name}
                      description={item.description}
                      price={item.price}
                      currency={item.currency}
                      // eslint-disable-next-line
                      image={item.image_url}
                      restaurantName={item.restaurant_name}
                      url={item.restaurant_url ? item.restaurant_url : ''}
                      email={props.email}
                      isBackdropOpen={isBackdropOpen}
                      esId={item?._id || item?.es_id}
                      selectedIrrelevantDocs={selectedIrrelevantDocs}
                      handleEsDocSelection={handleEsDocSelection}
                    />
                  </Grid>
                ))}
              {selectedLang !== 'English' &&
                validCafeProducts.map((item) => (
                  <Grid item xs={!noSide ? 3 : 2} key={item.es_id}>
                    <FoodServiceCard
                      title={item.native_name}
                      description={item.native_description}
                      price={item.price}
                      currency={item.currency}
                      // eslint-disable-next-line
                      image={item.image_url}
                      restaurantName={item.restaurant_name}
                      url={item.restaurant_url ? item.restaurant_url : ''}
                      email={props.email}
                      isBackdropOpen={isBackdropOpen}
                      esId={item?._id || item?.es_id}
                      selectedIrrelevantDocs={selectedIrrelevantDocs}
                      handleEsDocSelection={handleEsDocSelection}
                    />
                  </Grid>
                ))}
            </ProductCardsContainer>
          </Box>
        </Grid>
      </Grid>
    </FoodServiceProductsContainer>
  )
}

FoodServiceProducts.propTypes = {
  noSide: PropTypes.bool,
}

FoodServiceProducts.defaultProps = {
  noSide: false,
}

export default FoodServiceProducts
