import { Paper } from '@mui/material';
import { Box, styled } from '@mui/system';

export const IngredientCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '& img': {
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
}));

export const DetailsContainer = styled(Paper)(() => ({
  flexGrow: 1,
  display: 'flex',
  boxShadow: '0px 4px 12px 0px #272B3108',
  overflowX: 'auto',
}));


export const BoxScroll = styled(Box)(({theme}) => ({
  '&::-webkit-scrollbar': {
    height: '4px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));
