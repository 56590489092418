import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/logo-wafer.svg'
import Frame from '../../assets/Frame.svg'
import LoginBg from '../../assets/LoginBackground.svg'
import HeroAnimation from '../../assets/images/HeroAnimation/HeroAnimation .gif'
import { Header } from 'react-bootstrap/lib/Modal'

const LoginLayoutV2 = ({ children }) => (
  <Stack
    display="inline-block"
    justifyContent="center"
    sx={{
      background: `url(${Frame}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      bgcolor: 'background.default',
      height: '100vh',
      width: '100%',
    }}
  >
    <Box
      sx={{
        padding: '20px',
      }}
    >
      <Logo />
    </Box>
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-evenly"
    >
      {/* <Box>
        <Box align="center">
          <Box
            component="img"
            src={HeroAnimation}
            sx={{
              height: '400px',
              width: '400px',
              borderRadius: '50%',
            }}
          />
        </Box>
        <Box pt="25px">
          <Typography
            variant="h2"
            fontWeight="bold"
            color="#FFFFFF"
            align="center"
          >
            Discover growth <br />
            opportunities faster.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#FFFFFF"
            pt="25px"
            align="center"
          >
            Research ideas, develop concepts, and screen products your consumers
            <br />
            will love. All in a single day with a few clicks.
          </Typography>
        </Box>
      </Box> */}
      {/* <Box
        sx={{
          position: 'relative',
          background:
            'linear-gradient(138deg, rgba(223, 223, 223, 0.34) 0%, rgba(223, 223, 223, 0.00) 99.25%)',
          boxShadow: '6px 6px 40px 0px rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(3.5px)',
          // opacity: '75%',
          // WebkitBackdropFilter: 'blur(10px)',
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          paddingTop: '152px',
          paddingLeft: '1028px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: 4,
          p: 12.5,
        }}
      > */}
      <Box
        sx={{
          pb: 4,
          display: 'flex',
          alignItems: 'flex-end',
          position: 'absolute',
          top: 0,
          transform: 'translateY(-100%)',
        }}
      ></Box>
      {children}
    </Box>
    {/* </Box> */}
  </Stack>
)

LoginLayoutV2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoginLayoutV2
