import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'

const SearchInput = ({ value, onChange, onClick, txtProps }) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      onClick={onClick}
      placeholder="Search"
      size="large"
      {...txtProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <ClearIcon
                cursor="pointer"
                onClick={(e) => {
                  onChange({ target: { value: '' } })
                }}
                color="secondary"
                fontSize="small"
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

SearchInput.defaultProps = {
  value: '',
  onChange: () => {},
}

export default SearchInput
