class SavedProjectsList {
  constructor(response) {
    this._list = response.results.map((project) => {
      return new SavedProjectsResponse(project)
    })
  }

  get list() {
    return this._list
  }
}

class SavedProjectsResponse {
  constructor(response) {
    this._name = response.category.name
    this._status = 'A'
    this._id = response.id
    this._criteria = response.country.name
    this._projectImage = response.thumbnail_url
    this._projectVariant = response.variant
    this._enableWSOSubCat = response.enable_wso_subcategories
    this._isHistoricalDataAvailable = response.is_historical_data_available
  }

  get name() {
    return this._name
  }

  get status() {
    return this._status
  }

  get id() {
    return this._id
  }

  get criteria() {
    return this._criteria
  }

  get projectImage() {
    return this._projectImage
  }

  get projectVariant() {
    return this._projectVariant
  }

  get enableWSOSubCat() {
    return this._enableWSOSubCat
  }

  get isHistoricalDataAvailable() {
    return this._isHistoricalDataAvailable
  }
}

export default SavedProjectsList
