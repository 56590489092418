import React from 'react'

import { Box, Typography } from '@mui/material'

import { ReactComponent as EmptyIngredientsSvg } from '../../../assets/emptyIngredients.svg'

const EmptyIngredients = ({ lens = '', text, imgComp, textStyles }) => (
  <Box
    sx={{
      height: 440,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 2,
    }}
  >
    {imgComp || <EmptyIngredientsSvg />}
    <Typography variant="h3" color="textSecondary" sx={textStyles}>
      {text || `No ${lens}s found`}
    </Typography>
  </Box>
)

export default EmptyIngredients
