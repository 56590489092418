import palette from '../palette';

export default {
  styleOverrides: {
    positionEnd: {
      color: palette.icon,
    },
    positionStart: {
      color: palette.icon,
    },
  },
};
