import typography from '../typography';
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginBottom: 8,
      position: 'static',
      ...typography.caption,
      color: palette.text.secondary,
      '&:hover': {
        color: palette.text.secondary,
      },
      '&:focus': {
        color: palette.primary.main,
      },
      '&:error': {
        color: palette.error.main,
      },
      '&:disabled': {
        color: palette.text.disabled,
      },
    },
    asterisk: {
      color: palette.primary.main,
    },
  },
  defaultProps: {
    shrink: true,
  },
};
