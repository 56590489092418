import React, { useState } from 'react'

import { Typography } from '@mui/material'

import DashboardCommonCardsList from '../DashboardCommonCardsList'
import DashboardFavoritesTrendsSummaryCard from '../DashboardFavoritesTrendsSummaryCard'

import { ReactComponent as IngredientsIcon } from '../../../assets/icons/ingredients.svg'
import { ReactComponent as BPCIngredientsIcon } from '../../../assets/icons/bpc-ingredient.svg'
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../../assets/icons/themes.svg'
import RemoveFavPopUp from '../OtherFavorites/RemoveFavPopup'

export const getColoredMaturityPhase = (maturityPhase) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        color: ({ palette }) => palette.colors[maturityPhase.toLowerCase()],
        display: 'inline',
      }}
    >
      {maturityPhase}
    </Typography>
  )
}
export const getColoredLabelFromPalette = (label, color, variant) => {
  return (
    <Typography
      variant={variant || 'subtitle2'}
      sx={{
        color: color,
        display: 'inline',
      }}
    >
      {label}
    </Typography>
  )
}
export const getColoredLabel = (label, errorIncludedStr) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        color:
          ((!errorIncludedStr || typeof label === 'number') && label >= 0) ||
          (errorIncludedStr && !label.includes(errorIncludedStr))
            ? ({ palette }) => palette.success.dark
            : ({ palette }) => palette.error.dark,
        display: 'inline',
      }}
    >
      {label}
    </Typography>
  )
}

const DashboardFavoritesTrendsCardsList = (props) => {
  const {
    trendsData,
    selectedView,
    timeframeToCheck,
    removeFromFavoritesTrends,
    selectedTimeFrame,
    email,
    amplitudeFn,
  } = props
  // TODO Should change for BPC
  const lensIconMap = {
    ingredient: <IngredientsIcon fill="#06B0F1" height={30} width={30} />,
    product: <ProductsIcon fill="#06B0F1" height={30} width={30} />,
    theme: <ThemesIcon fill="#06B0F1" height={30} width={30} />,
  }

  const [trendToDelete, setTrendToDelete] = useState(null)

  const handleClose = () => {
    const ampiEvent = 'Clicked_MW_Favorites_Trends_Star'
    const ampiUserData = {
      User: email,
      Lens: trendToDelete?.trend_type,
      Country_Name: trendToDelete?.trend_country,
      Category_Name: trendToDelete?.trend_category,
      Trend_Name: trendToDelete?.trend_name,
      Action: 'Cancel',
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setTrendToDelete(null)
  }

  const handleConfirmClick = () => {
    removeFromFavoritesTrends(
      trendToDelete.trend_id,
      trendToDelete.trend_name,
      trendToDelete.trend_type,
      trendToDelete.project_id,
    )
    const ampiEvent = 'Clicked_MW_Favorites_Trends_Star'
    const ampiUserData = {
      User: email,
      Lens: trendToDelete?.trend_type,
      Country_Name: trendToDelete?.trend_country,
      Category_Name: trendToDelete?.trend_category,
      Trend_Name: trendToDelete?.trend_name,
      Action: 'Confirm',
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setTrendToDelete(null)
  }

  const handleRemoveBookmarkClick = (e, trendData) => {
    e.preventDefault()
    setTrendToDelete(trendData)
  }

  return (
    <DashboardCommonCardsList gridStyles={{ p: 2 }}>
      {trendsData.map((obj) =>
        selectedView === 'Summary View' ? (
          <DashboardFavoritesTrendsSummaryCard
            trendData={obj}
            trendImg={obj.trend_image_url}
            trendType={obj.trend_type}
            trendName={obj.trend_name}
            trendCountry={obj.trend_country}
            trendCategory={obj.trend_category}
            trendRedirectionUrl={obj.redirection_url}
            trendAnalytics={obj.analytical_data[timeframeToCheck]}
            lensIconMap={lensIconMap}
            handleRemoveBookmarkClick={handleRemoveBookmarkClick}
            selectedTimeFrame={selectedTimeFrame}
            twoYearCAGRChange={obj.analytical_data[
              timeframeToCheck
            ].two_year_cagr?.toFixed(2)}
            email={email}
            amplitudeFn={amplitudeFn}
            twoYearCAGR={obj.analytical_data.overall.total_two_year_cagr?.toFixed(
              2,
            )}
          />
        ) : (
          <></>
        ),
      )}
      <RemoveFavPopUp
        open={trendToDelete}
        text={trendToDelete?.trend_name}
        sectionName="Trend Favorites"
        handleClose={handleClose}
        handleConfirmClick={handleConfirmClick}
      />
    </DashboardCommonCardsList>
  )
}

export default DashboardFavoritesTrendsCardsList
