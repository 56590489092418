import { SubscriptionDetailsAction } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchSubscriptions = () => {
  return (dispatch) => {
    // Get subscriptions details
    const url = NetworkUtil.fetchSubscriptionsDetailsRequest()
    axios
      .get(url)
      .then((response) => {
        const subscription = response.data
        dispatch(fetchSubscriptionsDetailsSuccess(subscription.data))
      })
      .catch((error) => {
        dispatch(fetchSubscriptionsDetailsFailed(error))
      })
  }
}

const fetchSubscriptionsDetailsSuccess = (subscriptionDetails) => {
  return {
    type:
      SubscriptionDetailsAction.type[
        SubscriptionDetailsAction.FETCH_SUBSCRIPTION_DETAILS_SUCCESS
      ],
    subscriptionDetails,
  }
}
const fetchSubscriptionsDetailsFailed = () => {
  return {
    type:
      SubscriptionDetailsAction.type[
        SubscriptionDetailsAction.FETCH_SUBSCRIPTION_DETAILS_FAIL
      ],
    subscriptionDetails: null,
  }
}
