import React from 'react'
import {
  Box,
} from '@mui/material'
import { ReactComponent as EmptyChart2 } from '../../../../assets/emptyChart.svg'


const EmptyChart = () => {
  const d = ''
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: '-3px 6px 26px rgba(149, 149, 149, 0.1)',
        borderRadius: 1,
        p: 2,
        my: 3,
        minHeight: 'calc(100vh - 450px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <EmptyChart2/>



    </Box>
  )}

export default EmptyChart;
