import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'

import {
  Box,
  Paper,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  Chip,
  Divider,
  IconButton,
  Switch,
  Badge,
  Modal,
} from '@mui/material'

import { ReactComponent as IngredientsIcon } from '../../../assets/icons/ingredients.svg'
import { ReactComponent as BPCIngredientsIcon } from '../../../assets/icons/bpc-ingredient.svg'
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../../assets/icons/themes.svg'

import TabPanel from '../TabPanel'
import TrendsDistributionChart from '../TrendsDistributionChart'
import TrendsDistributionTable from '../TrendsDistributionTable'
import SelectedFiltersTags from '../SelectedFiltersTags'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { amplify, convertToNearest10s100s } from './../../../store/utility'
import { chipKeys, LENSES } from '../../utils/constants'
import { StyledLink } from './style'
import SingleSelectDropdown from '../SingleSelectDropdown'
import NestedMultiSelectDropdownContainer from '../NestedMultiSelectDropdownContainer'
import DropdownSelectedFiltersTags from '../DropdownSelectedFiltersTags'
import axios from './../../../services/networking/axios'
import Feedback from '../Feedback/Feedback'
import { useSingleSnackBar } from '../../../hooks'
import CloseIcon from '@mui/icons-material/Close'
import NewUpdateCard from '../../../components/NewUpdateCard/NewUpdateCard'
import newVisualisation from './../../../assets/newVisualisation.svg'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import TrendFilter from '../TrendsTable/TrendsTableUI/TrendFilter'

const defaultOptions = [0, 5, 10, 20, 30, 40]
const cagrOptions = [5, 10, 20, 30]

const generateSegments = (min, max, count) => {
  const options = []

  const stepSize = (max - min) / (count - 1)
  for (let i = 0; i < count; i++) {
    const value = min + i * stepSize
    options.push(convertToNearest10s100s(value))
  }
  options.pop()
  return options
}

const amp = (ampiEvent, ampiUserData) => {
  try {
    const getSelectedFilterLabel = (ingredientFamilyList, depth) => {
      const ingFamilyFilterKeys = Object.keys(ingredientFamilyList)
      return ingFamilyFilterKeys
        .filter(
          (filterApplied) =>
            ingredientFamilyList[filterApplied].depth === depth,
        )
        .map(
          (filteredIngredientFilter) =>
            ingredientFamilyList[filteredIngredientFilter].label,
        )
    }
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.props.email,
    }
    switch (amplitudeEvent) {
      case 'Clicked_Classification_Doc':
        break
      case 'Clicked_Explore_Category_Ing_Fam':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
        }
        break
      case 'Clicked_Explore_category_List_View_Filter':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
        }
        break
      case 'Clicked_Explore_category_List_View_Filter_Applied':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          '4 Year Datapoints': ampiUserData.filterApplied.four_year_dp,
          '2 Year Datapoints': ampiUserData.filterApplied.two_year_dp,
          '4 Year CAGR': ampiUserData.filterApplied.two_year_cagr || 'ALL',
          '2 Year CAGR': ampiUserData.filterApplied.two_year_cagr || 'ALL',
          'Retail Slider': ampiUserData.filterApplied.retail_products_count,
          'FS slider': ampiUserData.filterApplied.food_products_count,
        }
        break
      case 'Clicked_Explore_Subcategory':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
        }
        break
      case 'Clicked_Explore_Category_New_Visualisation':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Action:
            ampiUserData.updatedVisualisationMode === 'new' ? 'ON' : 'OFF',
        }
        break
      case 'Clicked_Explore_Category_New_Visualisation_Feedback':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Feedback: ampiUserData.selectedFeedback - 1,
          Text: ampiUserData.remarkFeedback,
        }
        break
      case 'Clicked_Explore_Category_Ing_Fam_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Keyword: ampiUserData.searchKeyword,
        }
        break
      case 'Clicked_Explore_Category_Ing_Fam_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Action: ampiUserData.action,
          Ingredient_Family: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            0,
          ),
          Ingredient_SubFamily: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            1,
          ),
        }
        break
      case 'Clicked_Explore_Category_New_Visualisation_Trends':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Name_Visualisation_Phase: ampiUserData.trendDetail.type,
          'Trend Name': ampiUserData.trendDetail.name,
          Lens: ampiUserData.lensSelected,
          'Redirected From': ampiUserData.redirectedFrom,
        }
        break
      default:
        const {
          ingredient,
          product,
          theme,
          ingredientSubCategory,
          themeSubCategory,
          productSubCategory,
        } = ampiUserData.props.appliedFilters
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
          Maturity_Phase: ampiUserData.phase,
          Lens: ampiUserData.props.lensSelected,
          Filter:
            !ingredient.length &&
            !product.length &&
            !theme.length &&
            !ingredientSubCategory &&
            !themeSubCategory &&
            !productSubCategory
              ? 'No'
              : 'Yes',
        }
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in DownloadListView')
  }
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

let filters = null
let subFilter = null
let subFilterLabel = null
const FOUR_YEAR_CAGR = 4

const FEEDBACK_SX = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  height: 'auto',
  width: '400px',
  background: 'white',
  padding: '20px',
  borderRadius: '15px',
}

const TrendsCard = (props) => {
  const { vizMode } = props
  const [maturityPhase, setMaturityPhase] = React.useState('Emerging')
  const childRef = useRef()
  const [viewMode, setViewMode] = useState('chart')
  const [trendPhases, setTrendPhases] = useState(chipKeys)
  const [visualisationMode, setVisualisationMode] = useState(
    vizMode?.default || 'old',
  )
  const [selectedFeedback, setSelectedFeedback] = useState(-1)
  const [remarkFeedback, setRemarkFeedback] = useState('')
  const [showFeedbackForm, setShowFeedbackForm] = useState(false)
  const [feedbackFormFilled, setFeedbackFormFilled] = useState(null)
  const [showUpdateFeature, setShowUpdateFeature] = useState(false)
  const [updateFeatureDetails, setUpdateFeatureDetails] = useState({})
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const [trendFilters, setTrendFilters] = useState({})
  const [selectedTwoYearDP, setSelectedTwoYearDP] = useState('')
  const [selectedFourYearDP, setSelectedFourYearDP] = useState('')
  const [selectedTwoYearCagr, setSelectedTwoYearCagr] = useState('')
  const [selectedFourYearCagr, setSelectedFourYearCagr] = useState('')
  const [filterRetailValues, setFilterRetailValues] = useState(null)
  const [filterFsValues, setFilterFSValues] = useState(null)
  const [selectedChartFsValue, setSelectedChartFsValues] = useState(null)
  const [chartFsValueOption, setChartFsValueOption] = useState(defaultOptions)
  const [selectedChartRetailValue, setSelectedChartRetailValues] = useState(
    null,
  )
  const [chartRetailValueOption, setChartRetailValueOption] = useState(
    defaultOptions,
  )
  const [twoYearCagrOptions, setTwoYearCagrOptions] = React.useState([])
  const [fourYearCagrOptions, setFourYearCagrOptions] = React.useState([])
  const [twoYearDpOptions, setTwoYearDpOptions] = React.useState()
  const [fourYearDpOptions, setFourYearDpOptions] = React.useState()
  const [fsValueOption, setFsValueOption] = React.useState(defaultOptions)
  const [retailValueOption, setRetailValueOption] = React.useState(
    defaultOptions,
  )

  const {
    projectid,
    lensToShow,
    ingredientList,
    themeList,
    productList,
    setMaxDataPoints,
    email,
    userName,
    country,
    category,
    lensSelected,
    handleChangeTrendLens,
    trends,
    excelDownloadCount,
    projectVariant,
    showTransitionIcon,
    allCategoriesList,
    handleChangeActiveSubCategories,
    activeSubCategories,
    onIngFamilyStateChange,
    handleRemoveAppliedIngFamily,
    ingFamilyFilterAPIData,
    updatedAppliedIngFamily,
    ingFamilyFilter,
    ingFamilyFilterInitialState,
    newVisualisationTrends,
    fetchTrendsV2,
    selectedCagr,
    handleCagrChange,
  } = props

  const logFeedback = () => {
    const ampiEvent = 'Clicked_Explore_Category_New_Visualisation_Feedback'
    const ampiUserData = {
      selectedFeedback,
      remarkFeedback,
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const ingredientSubCategory = allCategoriesList.ingredient
  const themeSubCategory = allCategoriesList.theme
  const productSubCategory = allCategoriesList.product

  useEffect(() => {
    let defaultPhase = 'Emerging'
    if (visualisationMode === 'new') {
      defaultPhase = 'Opportunity'
    }
    setMaturityPhase(defaultPhase)
  }, [visualisationMode])

  const onApplyButtonClick = () => {
    props.handleSubCategoryApply(lensSelected.toLowerCase())
  }
  const subCategoryItems = useMemo(
    () => (type) => {
      const subCategory =
        type === 'theme'
          ? themeSubCategory
          : type === 'product'
          ? productSubCategory
          : ingredientSubCategory
      return subCategory
    },
    [
      allCategoriesList.theme,
      allCategoriesList.ingredient,
      allCategoriesList.product,
    ],
  )
  const subCategoryList = subCategoryItems(`${lensSelected.toLowerCase()}`)
  const lensIconMap = {
    Ingredient: projectVariant === 'bpc' ? BPCIngredientsIcon : IngredientsIcon,
    Product: ProductsIcon,
    Theme: ThemesIcon,
  }

  const logDropdownOpened = () => {
    const ampiEvent = 'Clicked_Explore_Category_Ing_Fam'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logSearchKeywordModified = (searchKeyword) => {
    const ampiEvent = 'Clicked_Explore_Category_Ing_Fam_Search'
    const ampiUserData = {
      props,
      searchKeyword,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logActionClicked = (ingredientFamilyList, action) => {
    const ampiEvent = 'Clicked_Explore_Category_Ing_Fam_Action'
    const ampiUserData = {
      props,
      ingredientFamilyList,
      action,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logSubCategoryOpen = () => {
    const ampiEvent = 'Clicked_Explore_Subcategory'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logVisualisationUpdate = (updatedVisualisationMode) => {
    const ampiEvent = 'Clicked_Explore_Category_New_Visualisation'
    const ampiUserData = {
      props,
      updatedVisualisationMode,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logFilterClick = () => {
    const ampiEvent = 'Clicked_Explore_category_List_View_Filter'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logFilterApplied = (filterApplied) => {
    const ampiEvent = 'Clicked_Explore_category_List_View_Filter_Applied'
    const ampiUserData = {
      props,
      filterApplied,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logTrendRedirectionNewVis = (trendDetail, redirectedFrom = 'Graph') => {
    const ampiEvent = 'Clicked_Explore_Category_New_Visualisation_Trends'
    const ampiUserData = {
      props,
      trendDetail,
      lensSelected,
      redirectedFrom,
    }
    amp(ampiEvent, ampiUserData)
  }

  const sendVisualisation = (newVisualisation) => {
    const req = NetworkUtil.fetchVisualisationPreference(projectid)
    const reqBody = {
      classification: newVisualisation,
    }
    axios
      .put(req, reqBody)
      .then((res) => {})
      .catch((err) => {
        console.log('error in updating visualisation', err)
      })
  }

  const fetchFeedbackStatus = () => {
    if (!feedbackFormFilled || feedbackFormFilled === null) {
      const featureCode = 'NEWCLASSIFICATION'
      const feedbackStatusReq = NetworkUtil.feedbackStatus(featureCode)
      props.handleLoaderStateChange(true)
      axios
        .get(feedbackStatusReq)
        .then((res) => {
          setFeedbackFormFilled(res.data.data.is_filled)
          props.handleLoaderStateChange(false)
        })
        .catch(() => {
          props.handleLoaderStateChange(false)
          console.log('Error in fetching feedback form status')
        })
    }
  }

  const resetFilterState = () => {
    setSelectedTwoYearDP('')
    setSelectedFourYearDP('')
    setSelectedTwoYearCagr('')
    setSelectedFourYearCagr('')
    setFilterRetailValues(null)
    setFilterFSValues(null)
    setTrendFilters({})
    setChartFsValueOption(defaultOptions)
    setChartRetailValueOption(defaultOptions)
    setSelectedChartFsValues(null)
    setSelectedChartRetailValues(null)
  }

  const handleVisualisationChange = (e) => {
    fetchFeedbackStatus()
    const updatedVisualisationMode = e.target.checked ? 'new' : 'old'
    logVisualisationUpdate(updatedVisualisationMode)
    sendVisualisation(updatedVisualisationMode)
    setVisualisationMode(updatedVisualisationMode)
  }

  const handleTrendClick = (trendDetail) => {
    logTrendRedirectionNewVis(trendDetail)
    const redirectLink = `/mui/details/${encodeURIComponent(
      projectid,
    )}/${encodeURIComponent(lensSelected.toLowerCase())}/${encodeURIComponent(
      trendDetail.id,
    )}`
    window.open(redirectLink, '_blank')
  }

  const handleCloseFeedback = () => {
    setShowFeedbackForm(false)
  }

  const handleSnackBar = (message, status) => {
    showSnackBar(message, status, action)
  }

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" color="action" />
    </IconButton>
  )

  const handleSaveFeedback = () => {
    logFeedback()
    const featureCode = 'NEWCLASSIFICATION'
    const feedbackReq = NetworkUtil.feedback(featureCode)
    const reqData = {
      remark: remarkFeedback,
      rating: selectedFeedback,
    }
    props.handleLoaderStateChange(true)
    axios
      .post(feedbackReq, reqData)
      .then((res) => {
        const snackBarMessage = `Your feedback has been saved!`
        handleSnackBar(snackBarMessage, 'success')
        props.handleLoaderStateChange(false)
      })
      .catch((err) => {
        const snackBarMessage = `Feedback could not be saved. Please try again.`
        handleSnackBar(snackBarMessage, 'error')
        props.handleLoaderStateChange(false)
        console.log('Feedback Failed')
      })

    handleCloseFeedback()
  }

  const handleDownload = () => {
    childRef.current.handleDownload()
    const ampiEvent = `Clicked_Download_Chart_View`
    const ampiUserData = {
      props,
      phase: maturityPhase,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleListDownload = (data, phase) => {
    if (newVisualisationTrends?.trends?.length) {
      props.handleDownloadExcel(
        lensSelected,
        {
          ...newVisualisationTrends,
          trends: data,
        },
        phase,
      )
    } else {
      props.handleDownloadExcel(lensSelected)
      const ampiEvent = `Clicked_Download_List_View`
      const ampiUserData = {
        props,
        phase: maturityPhase,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  const handleChange = (event, newValue) => {
    handleChangeTrendLens({
      list: [
        lensToShow.find(
          (lens) => lens.displayValue === lensToShow[newValue].name,
        ),
      ],
    })
    const ampiEvent = 'Clicked_Lens'
    const ampiUserData = {
      User: email,
      Lens_Name: lensToShow[newValue].name,
      Country_Name: country,
      Category: category,
      Project: projectid,
    }
    amplify(ampiEvent, ampiUserData, email)
  }
  const logLinkClick = () => {
    const ampiEvent = 'Clicked_Classification_Doc'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }
  switch (lensSelected) {
    case 'Ingredient':
      subFilter = props.appliedFilters.ingredientSubCategory
      subFilterLabel = 'Sub-category: '
      break
    case 'Product':
      subFilter = props.appliedFilters.productSubCategory
      subFilterLabel = 'Product format: '
      break
    case 'Theme':
      subFilter = props.appliedFilters.themeSubCategory
      subFilterLabel = 'Theme type: '
      break
  }
  filters = [
    ...props.appliedFilters.ingredient.map(({ id, name }) => ({
      id,
      name,
      type: 'ingredient',
    })),
    ...props.appliedFilters.product.map(({ id, name }) => ({
      id,
      name,
      type: 'product',
    })),
    ...props.appliedFilters.theme.map(({ id, name }) => ({
      id,
      name,
      type: 'theme',
    })),
  ]

  useEffect(() => {
    const trendsList =
      visualisationMode === 'new' ? newVisualisationTrends?.trends : trends
    if (trendsList) {
      setMaxDataPoints(
        trendsList.reduce(
          (acc, curr) => Math.max(curr.allTrendResults.length, acc),
          0,
        ),
      )
    }
  }, [
    trends,
    newVisualisationTrends,
    visualisationMode,
    ingredientList,
    themeList,
    productList,
    lensSelected,
  ])

  const viewModeAmplitude = (viewMode) => {
    const ampiEvent = 'Clicked_Select_View'
    const ampiUserData = {
      User: email,
      Selected_View: viewMode,
      Country_Name: country,
      Category_Name: category,
    }
    amplify(ampiEvent, ampiUserData, email)
  }

  const hideListDownload =
    trends && trends.every(({ allTrendResults }) => !allTrendResults.length)
  const setTable = () => {
    setViewMode('table')
    viewModeAmplitude('table')
  }
  const setChart = () => {
    setViewMode('chart')
    viewModeAmplitude('chart')
  }
  const getSubCategoryText = (lens) => {
    switch (lens) {
      case LENSES[1]:
        return `Products Filter`
      case LENSES[2]:
        return `Themes Filter`
      default:
        return `Subcategory`
    }
  }

  const fetchVisualisationPreferences = () => {
    const visualisationPreferenceReq = NetworkUtil.fetchVisualisationPreference(
      projectid,
    )
    props.handleLoaderStateChange(true)
    axios
      .get(visualisationPreferenceReq)
      .then((res) => {
        const preference = res.data.data[0]?.preferences?.classification
        setVisualisationMode(preference)
        props.handleLoaderStateChange(false)
      })
      .catch((err) => {
        props.handleLoaderStateChange(false)
        console.log('Error while fetching viusalisation view preference', err)
      })
  }

  const fetchFeedbackUpdate = () => {
    const fetchHighlightUpdateReq = NetworkUtil.featuresToHighlightUpdate()
    props.handleLoaderStateChange(true)
    axios
      .get(fetchHighlightUpdateReq)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.message !== 'Nothing to highlight') {
            setUpdateFeatureDetails(res.data.data)
            setShowUpdateFeature(true)
          }
        }
        props.handleLoaderStateChange(false)
      })
      .catch(() => {
        props.handleLoaderStateChange(false)
        console.log('Error fetching new update feature highlight')
      })
  }

  const handleFeedbackUpdate = () => {
    const HighlightUpdateReq = NetworkUtil.featuresToHighlightUpdate()
    const reqData = {
      code: 'NEWCLASSIFICATION',
      seen_by_user: true,
      accepted_by_user: true,
    }
    props.handleLoaderStateChange(true)
    axios
      .put(HighlightUpdateReq, reqData)
      .then((res) => {
        props.handleLoaderStateChange(false)
      })
      .catch(() => {
        props.handleLoaderStateChange(false)
        console.log('Error sending new update feature highlight')
      })
  }

  const handleUpdateAccepted = () => {
    setShowUpdateFeature(false)
    handleFeedbackUpdate()
  }

  useEffect(() => {
    if (projectid && !vizMode?.isOverride) {
      fetchVisualisationPreferences()
    }
  }, [projectid])

  useEffect(() => {
    const data =
      newVisualisationTrends?.trends?.find(
        (trend) =>
          trend.type.toLowerCase() ===
          (maturityPhase === 'Emerging'
            ? 'Upcoming'
            : maturityPhase
          ).toLowerCase(),
      )?.allTrendResults || []

    if (data && data.length) {
      let min4YearCagr = Number.POSITIVE_INFINITY
      let max4YearCagr = Number.NEGATIVE_INFINITY
      let min2YearCagr = Number.POSITIVE_INFINITY
      let max2YearCagr = Number.NEGATIVE_INFINITY
      let min4YearDp = Number.POSITIVE_INFINITY
      let max4YearDp = Number.NEGATIVE_INFINITY
      let min2YearDp = Number.POSITIVE_INFINITY
      let max2YearDp = Number.NEGATIVE_INFINITY

      data.length &&
        data.forEach((item) => {
          if (parseFloat(item.four_year_cagr) < parseFloat(min4YearCagr)) {
            min4YearCagr = item.four_year_cagr
          }
          if (parseFloat(item.four_year_cagr) > parseFloat(max4YearCagr)) {
            max4YearCagr = item.four_year_cagr
          }

          if (parseFloat(item.two_year_cagr) < parseFloat(min2YearCagr)) {
            min2YearCagr = item.two_year_cagr
          }
          if (parseFloat(item.two_year_cagr) > parseFloat(max2YearCagr)) {
            max2YearCagr = item.two_year_cagr
          }

          if (parseFloat(item.four_year_data_points) < parseFloat(min4YearDp)) {
            min4YearDp = item.four_year_data_points
          }
          if (parseFloat(item.four_year_data_points) > parseFloat(max4YearDp)) {
            max4YearDp = item.four_year_data_points
          }
          if (parseFloat(item.two_year_data_points) < parseFloat(min2YearDp)) {
            min2YearDp = item.two_year_data_points
          }
          if (parseFloat(item.two_year_data_points) > parseFloat(max2YearDp)) {
            max2YearDp = item.two_year_data_points
          }
        })
      setFourYearCagrOptions(cagrOptions)
      setTwoYearCagrOptions(cagrOptions)
      const twoYearSegments = generateSegments(min2YearDp, max2YearDp, 6)
      const fourYearSegments = generateSegments(min4YearDp, max4YearDp, 6)
      setTwoYearDpOptions(['All', ...twoYearSegments.filter((val) => val > 0)])
      setFourYearDpOptions([
        'All',
        ...fourYearSegments.filter((val) => val > 0),
      ])
    }
  }, [newVisualisationTrends?.trends, visualisationMode])

  useEffect(() => {
    fetchTrendsV2()
    resetFilterState()
  }, [projectid, lensSelected])

  useEffect(() => {
    if (visualisationMode === 'old' && feedbackFormFilled === false) {
      setShowFeedbackForm(true)
    }
    let defaultPhase = 'Emerging'
    if (visualisationMode === 'new') {
      defaultPhase = 'Opportunity'
    }
    resetFilterState()
    setMaturityPhase(defaultPhase)
  }, [visualisationMode])

  useEffect(() => {
    // Api call to get status of feedback
    fetchFeedbackStatus()
    fetchFeedbackUpdate()
  }, [])

  return (
    <Paper sx={{ boxShadow: '0px 4px 12px 0px #272B3108' }}>
      {/* <Modal open={showFeedbackForm} onClose={() => {}}>
        <Feedback
          boxSx={FEEDBACK_SX}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
          remarkFeedback={remarkFeedback}
          setRemarkFeedback={setRemarkFeedback}
          handleCloseFeedback={handleCloseFeedback}
          handleSaveFeedback={handleSaveFeedback}
        />
      </Modal> */}
      {/* <Modal open={showUpdateFeature} onClose={() => {}}>
        <NewUpdateCard
          data={updateFeatureDetails}
          heading="New Update!"
          subHeading="New Visualisation for Trends"
          acceptedButtontext="OK"
          handleAccepted={handleUpdateAccepted}
          imgSrc={newVisualisation}
          userName={userName}
        />
      </Modal> */}
      <Box
        sx={{
          px: 2,
          pt: 1.5,
          pb: 0.5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Trends</Typography>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            badgeContent={'New'}
            sx={{
              '.MuiBadge-badge': {
                background: ({ palette }) => palette.colors.badge,
                color: ({ palette }) => palette.white,
                top: -10,
                left: -15,
              },
            }}
          >
            <Typography>Growth vs Engagement View</Typography>
          </Badge>
          <Switch
            onChange={handleVisualisationChange}
            checked={visualisationMode === 'new'}
          />
        </Box> */}
      </Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent:
              visualisationMode === 'new' ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={lensToShow.findIndex(({ name }) => lensSelected === name)}
            onChange={handleChange}
            aria-label="Lens Tabs"
          >
            {lensToShow.map(({ name }, index) => (
              <Tab
                key={name}
                icon={<SvgIcon component={lensIconMap[name]} />}
                label={`${name}s`}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {props.projectVariant === 'food' && visualisationMode !== 'new' && (
            <StyledLink
              href={'/mui/docs?RedirectPath=/docs/classification-graph'}
              target="_blank"
              onClick={logLinkClick}
            >
              Know more about Classification
            </StyledLink>
          )}
        </Box>
        {(!!filters?.length || props.showAssocLvl) && <Divider />}
        {lensToShow.map(({ name }, id) => (
          <TabPanel
            key={name}
            value={lensToShow.findIndex(({ name }) => lensSelected === name)}
            index={id}
          >
            <SelectedFiltersTags
              handleListDownload={handleListDownload}
              handleDownload={handleDownload}
              isChartView={viewMode === 'chart'}
              assocLvl={props.assocLvl}
              setAssocStrength={props.setAssocStrength}
              lens={name}
              handleRemoveSelectedTrend={props.handleRemoveSelectedTrend}
              selectedFilters={filters}
              showAssocLvl={props.showAssocLvl}
              selectedSubFilter={subFilter}
              subFilterLabel={subFilterLabel}
              handleRemoveCategory={props.handleRemoveCategory}
              hideListDownload={hideListDownload}
              viewMode={viewMode}
              setTable={setTable}
              setChart={setChart}
              trends={trends}
              projectId={projectid}
              country={country}
              category={category}
              showAddToFav={visualisationMode !== 'new'}
              hideSubcategoryChips
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {(lensSelected === 'Ingredient' ||
                    (allCategoriesList[lensSelected.toLowerCase()] &&
                      !!allCategoriesList[lensSelected.toLowerCase()]
                        .length)) && (
                    <Box
                      sx={{
                        height: '80px',
                        display: 'flex',
                        paddingLeft: '10.5px !important',
                        width: '100%',
                        borderRadius: '5px',
                      }}
                    >
                      {allCategoriesList[lensSelected.toLowerCase()] &&
                        !!allCategoriesList[lensSelected.toLowerCase()]
                          .length && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <SingleSelectDropdown
                                onApply={onApplyButtonClick}
                                headerText={`${getSubCategoryText(
                                  lensSelected,
                                )}:`}
                                placeHolderText={`Select ${getSubCategoryText(
                                  lensSelected,
                                )}`}
                                onSelect={props.handleSubCategorySelect}
                                dropDownListItems={subCategoryList}
                                logSubCategoryOpen={logSubCategoryOpen}
                              />
                            </Box>
                          </>
                        )}

                      {subFilter && (
                        <Box
                          ml={1}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Chip
                            key={subFilter.id}
                            label={subFilter.displayValue}
                            variant="outlinedTag"
                            onDelete={() =>
                              props.handleRemoveCategory(
                                lensSelected,
                                subFilter.id,
                              )
                            }
                          />
                        </Box>
                      )}
                     {projectVariant === 'food' &&
                        lensSelected === 'Ingredient' && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginLeft: allCategoriesList[
                                lensSelected.toLowerCase()
                              ].length
                                ? '20px'
                                : '0px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <NestedMultiSelectDropdownContainer
                                initialFilterState={ingFamilyFilterInitialState}
                                updatedFilterData={ingFamilyFilterAPIData}
                                filterTagsAppliedFilters={
                                  updatedAppliedIngFamily
                                }
                                stateChangeCallback={onIngFamilyStateChange}
                                dimensions={{
                                  width: 250,
                                  maxItemsToFitInHeight: 6.5,
                                }}
                                placeHolder="Select Ingredient Family"
                                isLoggingRequired
                                logDropdownOpened={logDropdownOpened}
                                logActionClicked={logActionClicked}
                                logSearchKeywordModified={
                                  logSearchKeywordModified
                                }
                                preLabelText="Ingredient Family:"
                              />
                            </Box>

                            <Box
                              sx={{
                                ml: 1,
                                overflow: 'auto',
                                display: 'flex',
                              }}
                            >
                              <DropdownSelectedFiltersTags
                                containerProps={{ sx: { margin: 'auto' } }}
                                maxFilterTagsToShow={8}
                                filterState={ingFamilyFilter}
                                handleRemoveAppliedFilters={
                                  handleRemoveAppliedIngFamily
                                }
                              />
                            </Box>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100px',
                  marginLeft: allCategoriesList[lensSelected.toLowerCase()]
                    .length
                    ? '20px'
                    : '0px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {viewMode !== 'chart' && visualisationMode === 'new' && (
                    <TrendFilter
                      trends={newVisualisationTrends?.trends}
                      phase={maturityPhase}
                      onFilter={(e) => {
                        setTrendFilters(e)
                        logFilterApplied(e)
                      }}
                      onReset={(e) => {
                        setTrendFilters(e)
                      }}
                      projectVariant={projectVariant}
                      lens={lensSelected}
                      logFilterClick={logFilterClick}
                      selectedTwoYearDP={selectedTwoYearDP}
                      setSelectedTwoYearDP={setSelectedTwoYearDP}
                      selectedFourYearDP={selectedFourYearDP}
                      setSelectedFourYearDP={setSelectedFourYearDP}
                      selectedTwoYearCagr={selectedTwoYearCagr}
                      setSelectedTwoYearCagr={setSelectedTwoYearCagr}
                      selectedFourYearCagr={selectedFourYearCagr}
                      setSelectedFourYearCagr={setSelectedFourYearCagr}
                      filterRetailValues={filterRetailValues}
                      setFilterRetailValues={setFilterRetailValues}
                      filterFsValues={filterFsValues}
                      setFilterFSValues={setFilterFSValues}
                      visualisationMode={visualisationMode}
                      twoYearCagrOptions={twoYearCagrOptions}
                      setTwoYearCagrOptions={setTwoYearCagrOptions}
                      fourYearCagrOptions={fourYearCagrOptions}
                      setFourYearCagrOptions={setFourYearCagrOptions}
                      twoYearDpOptions={twoYearDpOptions}
                      setTwoYearDpOptions={setTwoYearDpOptions}
                      fourYearDpOptions={fourYearDpOptions}
                      setFourYearDpOptions={setFourYearDpOptions}
                      fsValueOption={fsValueOption}
                      setFsValueOption={setFsValueOption}
                      retailValueOption={retailValueOption}
                      setRetailValueOption={setRetailValueOption}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            {viewMode === 'chart' && trends ? (
              <TrendsDistributionChart
                visualisationMode={visualisationMode}
                newVisualisationTrends={newVisualisationTrends}
                wrappedComponentRef={(ref) => (childRef.current = ref)}
                projectId={projectid}
                lensSelected={lensSelected}
                maxTrendsShown={props.maxTrendsShown}
                trends={trends}
                country={country}
                category={category}
                email={email}
                amplitudeView="Graph View"
                initTrendPhases={trendPhases}
                trendPhasesChangeCallback={setTrendPhases}
                handleListDownload={handleListDownload}
                handleDownload={handleDownload}
                isChartView={viewMode === 'chart'}
                hideListDownload={hideListDownload}
                viewMode={viewMode}
                setTable={setTable}
                setChart={setChart}
                selectedSubFilter={subFilter}
                selectedFilters={filters}
                showChartviewListviewBtns={true}
                setMaturityPhase={setMaturityPhase}
                handleTrendClick={handleTrendClick}
                selectedCagr={selectedCagr}
                handleCagrChange={handleCagrChange}
                selectedChartFsValue={selectedChartFsValue}
                setSelectedChartFsValues={setSelectedChartFsValues}
                chartFsValueOption={chartFsValueOption}
                setChartFsValueOption={setChartFsValueOption}
                selectedChartRetailValue={selectedChartRetailValue}
                setSelectedChartRetailValues={setSelectedChartRetailValues}
                chartRetailValueOption={chartRetailValueOption}
                setChartRetailValueOption={setChartRetailValueOption}
              />
            ) : (
              <TrendsDistributionTable
                showTransitionIcon
                projectid={projectid}
                visualisationMode={visualisationMode}
                trends={trends}
                newVisualisationTrends={newVisualisationTrends?.trends}
                lensSelected={lensSelected}
                country={country}
                category={category}
                email={email}
                amplitudeView="List View"
                handleListDownload={handleListDownload}
                excelDownloadCount={excelDownloadCount}
                handleDownload={handleDownload}
                isChartView={viewMode === 'chart'}
                hideListDownload={hideListDownload}
                viewMode={viewMode}
                setTable={setTable}
                setChart={setChart}
                setMaturityPhase={setMaturityPhase}
                projectVariant={projectVariant}
                appliedFilters={props.appliedFilters}
                phase={maturityPhase}
                trendFilters={trendFilters}
                logTrendRedirectionNewVis={logTrendRedirectionNewVis}
              />
            )}
          </TabPanel>
        ))}
      </Box>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    projectVariant: state.projectListBuilder.projectList?.projectVariant,
  }
}

export default withRouter(connect(mapStateToProps, null)(TrendsCard))
