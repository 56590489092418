import React from "react";

import ProjectCard from "../ProjectCard/ProjectCard";
import ConceptCard from "../ConceptCard/ConceptCard";

import noResFound from "../../assets/images/screenWinner/sw_no_results_found.svg";

import "./SWSearchResults.scss";

const SWSearchResults = ({
  query = "",
  projectsList = [],
  conceptsList = [],
  handleResultsClick,
}) => {
  return (
    <div className="sw-search-results__block">
      {!projectsList.length && !conceptsList.length ? (
        <div className="sw-search-results__no-results__block">
          <div className="sw-search-results__no-results">
            <img src={noResFound} alt="No results found" />
            <h1 className="sw-search-results__no-results__head">
              no results found for{" "}
              <span className="sw-search-results__topic">{query}</span>
            </h1>
          </div>
        </div>
      ) : (
        <>
          <h1 className="sw-search-results__heading">
            results for{" "}
            <span className="sw-search-results__topic">{query}</span>
          </h1>
          <div className="sw-search-results__results">
            {projectsList.map(({ id, ...projProps }) => (
              <div
                onClick={() =>
                  handleResultsClick(
                    `/screen-winner/projects/${id}/concepts`,
                    id
                  )
                }
                key={id}
                className="sw-search-results__results__cards"
              >
                <ProjectCard key={id} id={id} {...projProps} />
              </div>
            ))}
          </div>
          <div className="sw-search-results__results">
            {conceptsList.map(({ id, project, ...cncptProps }) => (
              <div
                onClick={() =>
                  handleResultsClick(
                    `/screen-winner/projects/${project}/concepts?activeCncpt=${id}`,
                    id
                  )
                }
                key={id}
                className="sw-search-results__results__cards"
              >
                <ConceptCard key={id} id={id} {...cncptProps} hideOpts />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SWSearchResults;
