import {
  trendPillarActionTypes,
  FetchTrendsPillar,
  DeleteTrendsPillar,
  DownloadTrendsPillar,
} from './../actions/actionTypes'
import { updateObject, mergeContextMaps } from '../utility'

const initialState = {
  pillarDetails: [],
  importPillarInfo: [],
  importDetails: [],
  bucketList: {},
  suggestedTrends: {
    ingredient: null,
    theme: null,
    product: null,
  },
  trendsDetails: [],
}

const recursiveTitleAddition = (allPillars, pillar, searchKey) => {
  const searchTilte = []
  pillar.childIds.forEach((child) => {
    searchTilte.push(
      ...recursiveTitleAddition(allPillars, allPillars[child], searchKey),
    )
  })
  return [...searchTilte, pillar.pillarName.toLowerCase()]
}

const addSearchKey = (allPillars) => {
  const pillarKeys = Object.keys(allPillars)

  let newAllPillars = {}

  for (let i = 0; i < pillarKeys.length; i++) {
    const pillar = allPillars[pillarKeys[i]]
    const searchKey = []

    const newSearchKey = pillarKeys[i]
      ? recursiveTitleAddition(allPillars, pillar, searchKey)
      : []

    const newPillar = { ...pillar, searchKey: newSearchKey }
    newAllPillars = { ...newAllPillars, [pillar.id]: newPillar }
  }

  return newAllPillars
}

const formatPillarToCamelCase = (allPillars) => {
  const pillarList = { ...allPillars }

  const pillarKeys = Object.keys(pillarList)

  let newAllPillars = {}

  for (let i = 0; i < pillarKeys.length; i++) {
    const key = pillarKeys[i]
    const pillar = allPillars[pillarKeys[i]]
    const newPillar = {
      id: pillar.id,
      pillarName: pillar.name,
      childIds: pillar.child_ids,
      projectId: pillar.project_id,
      level: pillar.level,
      trendDetails: [],
      trends: pillar.trends_count,
      dataPoints: pillar.data_point,
      fourYearCagr: pillar.four_year_cagr,
      twoYearCagr: pillar.two_year_cagr,
      noOfRelatedIngredients:
        pillar.food_service_products_count === undefined
          ? pillar.related_ingredients_count
          : pillar.food_service_products_count,
      noOfRelatedProducts:
        pillar.retail_products_count === undefined
          ? pillar.related_products_count
          : pillar.retail_products_count,
      depth: pillar.depth,
    }
    newAllPillars = { ...newAllPillars, [key]: newPillar }
  }

  return newAllPillars
}

const formattedObj = (pillarList) => {
  const formattedPillars = formatPillarToCamelCase(pillarList)

  const pillars = addSearchKey(formattedPillars)

  return pillars
}

const formattedTrendDetail = (trendsDetail) => {
  const trends = trendsDetail.map((trend) => {
    const trendObj = {
      four_year_cagr: trend.four_year_cagr,
      maturity_Phase: trend.maturity_phase,
      two_year_cagr: trend.two_year_cagr,
      trend_Name: trend.name,
      data_points: trend.data_point,
      rank: trend.rank,
      id: trend.id,
      year_on_year_growth: trend.two_year_cagr,
      image: trend.image,
      retailProduct: trend.retail_products,
      fsProducts: trend.fs_products,
      relatedIngredients: trend.related_ingredients,
      relatedProducts: trend.related_products,
      projectId: trend.project_id,
    }
    return trendObj
  })
  return trends
}

const createpillarReducer = (state = initialState, action) => {
  switch (action.type) {
    case trendPillarActionTypes.type[
      trendPillarActionTypes.GET_TREND_PILLAR_TO_EDIT
    ]:
      const element = state.bucketList.filter((i) => i.id == 8)
      return updateObject(state, { bucketList: element })

    case FetchTrendsPillar.type[FetchTrendsPillar.FETCH_TRENDS_PILLAR_SUCCESS]:
      return updateObject(state, {
        bucketList: formattedObj(action.trendList),
      })
    case FetchTrendsPillar.type[FetchTrendsPillar.FETCH_TRENDS_PILLAR_FAILED]:
      return updateObject(state, {
        error: '',
      })
    case DownloadTrendsPillar.type[
      DownloadTrendsPillar.DOWNLOAD_TRENDS_PILLAR_SUCCESS
    ]:
      return updateObject(state, {
        error: false,
      })
    case DownloadTrendsPillar.type[
      DownloadTrendsPillar.DOWNLOAD_TRENDS_PILLAR_FAILED
    ]:
      return updateObject(state, {
        error: true,
      })
    case DeleteTrendsPillar.type[
      DeleteTrendsPillar.DELETE_TRENDS_PILLAR_SUCCESS
    ]:
      return updateObject(state, {
        bucketList: state.bucketList.filter(
          (trend) => trend.id !== action.trendId,
        ),
        error: false,
      })
    case DeleteTrendsPillar.type[
      DeleteTrendsPillar.DELETE_TRENDS_PILLAR_FAILED
    ]:
      return updateObject(state, {
        error: true,
      })

    case trendPillarActionTypes.type[
      trendPillarActionTypes.INFO_ABOUT_TREND_PILLAR
    ]:
      return {
        ...state,
        pillarDetails: [action.payload],
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.IMPORT_TREND_PILLAR_SUCCESS
    ]:
      return {
        ...state,
        importDetails: [action.payload],
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.CLEAR_PILLAR_DETAILS_DATA
    ]:
      return {
        ...state,
        pillarDetails: [],
        importDetails: [],
        importPillarInfo: [],
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.RESET_TREND_PILLAR_DATA
    ]:
      return {
        ...initialState,
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.TREND_PILLAR_TREND_DETAILS_SUCCESS
    ]:
      return {
        ...state,
        trendsDetails: formattedTrendDetail(action.payload),
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.IMPORT_TREND_PILLAR_DETAILS
    ]:
      return {
        ...state,
        importPillarInfo: [action.payload],
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.GET_SUGGESTED_TRENDS_SUCCESS
    ]:
      const { suggestedTrends } = state
      const newSuggestedTrends = Object.entries(action.suggestedTrends).reduce(
        (acc, [key, value]) => ({ ...acc, [key.toUpperCase()]: value }),
        {},
      )
      return {
        ...state,
        suggestedTrends: {
          ...suggestedTrends,
          [action.lens]: mergeContextMaps([
            suggestedTrends[action.lens],
            newSuggestedTrends,
          ]),
        },
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.GET_TREND_PILLAR_TRENDS
    ]:
      return {
        ...state,
        importPillarInfo: [
          { ...state.importPillarInfo[0], trends: action.trends },
        ],
      }
    case trendPillarActionTypes.type[
      trendPillarActionTypes.REMOVE_SUGGESTED_TRENDS
    ]:
      return {
        ...state,
        suggestedTrends: { theme: null, ingredient: null, product: null },
      }
    default:
      return { ...state }
  }
}
export default createpillarReducer
