import React from "react";
import { Link } from "react-router-dom";

import "./SWBreadCrumbs.scss";

const SWBreadCrumbs = ({ breadCrumbs = [] }) => {
  return (
    <div className="sw-bread-crumb__block">
      {breadCrumbs.map(({ name, route }, i) => (
        <Link
          key={name}
          className={`sw-bread-crumb${
            i === breadCrumbs.length - 1 ? " sw-bread-crumb--active" : ""
          }`}
          to={route}
        >{`${i !== 0 ? " / " : ""}${name}`}</Link>
      ))}
    </div>
  );
};

export default SWBreadCrumbs;
