import _ from 'lodash'
import React from 'react'

import { Box, Button, Link, List, ListItem, Typography } from '@mui/material'
import StarRoundedIcon from '@mui/icons-material/StarRounded'

import DashboardCommonCard from '../DashboardCommonCard'
import { formatNumber } from '../../../store/utility'
import palette from '../../../theme/palette'
import {
  getColoredLabel,
  getColoredLabelFromPalette,
  getColoredMaturityPhase,
} from '../DashboardFavoritesTrendsCardsList'
import TextOverflowTooltip from '../TextOverflowTooltip'
import UnorderedListRound from '../UnorderedListRound'
import DashboardTooltip from '../DashboardTooltip'

const FAVORITES_TRENDS_HIDE_FS_AND_RETAIL = {
  TIMEFRAMES: ['Month', 'Quarter'],
  LENSES: ['Theme'],
}

const DashboardFavoritesTrendsSummaryCard = (props) => {
  const {
    trendData,
    trendImg,
    trendType,
    trendName,
    trendCountry,
    trendCategory,
    trendAnalytics,
    trendRedirectionUrl,
    lensIconMap,
    handleRemoveBookmarkClick,
    selectedTimeFrame,
    twoYearCAGRChange,
    email,
    amplitudeFn,
    twoYearCAGR,
  } = props
  const handleFavTrendCardClick = (e) => {
    if (!e.defaultPrevented) {
      const ampiEvent = 'Clicked_MW_Favorites_Trends_TrendName'
      const ampiUserData = {
        User: email,
        Lens: trendType,
        Country_Name: trendCountry,
        Category_Name: trendCategory,
        Trend_Name: trendName,
      }
      amplitudeFn(ampiEvent, ampiUserData)
    }
  }

  return (
    <DashboardCommonCard
      boxStyles={{ p: 0, overflow: 'hidden' }}
      cardProps={{
        xs: 6,
      }}
    >
      <Link
        target="_blank"
        underline="none"
        href={`/mui/details${trendRedirectionUrl}`}
        onClick={(e) => handleFavTrendCardClick(e)}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            height: 250,
          }}
        >
          <Box
            sx={{
              height: '100%',
              aspectRatio: '1 / 1',
            }}
          >
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={trendImg}
              alt={trendName}
            />
          </Box>
          <Box
            display="flex"
            sx={{
              p: 2,
              height: '100%',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <DashboardTooltip title={trendType}>
                  <Box>{lensIconMap[trendType.toLowerCase()]}</Box>
                </DashboardTooltip>
                <TextOverflowTooltip title={trendName} maxLineCount={1}>
                  <Typography
                    variant="h3"
                    sx={{
                      ml: 0.5,
                    }}
                  >
                    {trendName}
                  </Typography>
                </TextOverflowTooltip>
              </Box>
              <Button onClick={(e) => handleRemoveBookmarkClick(e, trendData)}>
                <StarRoundedIcon color="primary" sx={{ fontSize: '2em' }} />
              </Button>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: ({ palette }) => palette.text.secondary,
              }}
            >
              {trendCountry}, {trendCategory}{' '}
              {trendData.dashboard_updated_timestamp &&
                `(${new Date(
                  trendData.dashboard_updated_timestamp,
                ).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                })})`}
            </Typography>
            <Box sx={{ overflow: 'auto' }}>
              <UnorderedListRound>
                <ListItem>
                  {!trendAnalytics.classification.previous ||
                  trendAnalytics.classification.current ===
                    trendAnalytics.classification.previous ? (
                    <Typography variant="subtitle2">
                      Maturity Phase:{' '}
                      {getColoredMaturityPhase(
                        _.startCase(
                          trendAnalytics.classification.current.toLowerCase(),
                        ),
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      Maturity Phase: Moved from{' '}
                      {getColoredMaturityPhase(
                        _.startCase(
                          trendAnalytics.classification.previous.toLowerCase(),
                        ),
                      )}{' '}
                      to{' '}
                      {getColoredMaturityPhase(
                        _.startCase(
                          trendAnalytics.classification.current.toLowerCase(),
                        ),
                      )}
                    </Typography>
                  )}
                </ListItem>
                {!_.isNil(twoYearCAGR) && !_.isNil(twoYearCAGRChange) && (
                  <ListItem>
                    <Typography variant="subtitle2">
                      2 Year CAGR (Change):{' '}
                      {twoYearCAGR >= 0
                        ? getColoredLabelFromPalette(
                            `${twoYearCAGR}%`,
                            palette.success.dark,
                            'subtitle3',
                          )
                        : getColoredLabelFromPalette(
                            `-${Math.abs(twoYearCAGR)}%`,
                            palette.error.dark,
                            'subtitle3',
                          )}{' '}
                      (
                      {twoYearCAGRChange >= 0
                        ? getColoredLabelFromPalette(
                            `+${twoYearCAGRChange}%`,
                            palette.success.dark,
                            'subtitle3',
                          )
                        : getColoredLabelFromPalette(
                            `-${Math.abs(twoYearCAGRChange)}%`,
                            palette.error.dark,
                            'subtitle3',
                          )}
                      )
                    </Typography>
                  </ListItem>
                )}
                {!_.isNil(trendAnalytics.data_points) &&
                  trendAnalytics.data_points >= 0 && (
                    <ListItem>
                      <Typography variant="subtitle2">
                        Data Points added:{' '}
                        {getColoredLabel(
                          formatNumber(trendAnalytics.data_points),
                          '-',
                        )}
                      </Typography>
                    </ListItem>
                  )}
                {!FAVORITES_TRENDS_HIDE_FS_AND_RETAIL.LENSES.includes(trendType) &&
                  !FAVORITES_TRENDS_HIDE_FS_AND_RETAIL.TIMEFRAMES.includes(
                    selectedTimeFrame,
                  ) && (
                    <>
                      {!_.isNil(trendAnalytics.products.food_service) &&
                        trendAnalytics.products.food_service >= 0 && (
                          <ListItem>
                            <Typography variant="subtitle2">
                              New Food Service Product added:{' '}
                              {getColoredLabel(
                                trendAnalytics.products.food_service,
                              )}
                            </Typography>
                          </ListItem>
                        )}
                      {!_.isNil(trendAnalytics.products.retail) &&
                        trendAnalytics.products.retail >= 0 && (
                          <ListItem>
                            <Typography variant="subtitle2">
                              New Retail Products added:{' '}
                              {getColoredLabel(trendAnalytics.products.retail)}
                            </Typography>
                          </ListItem>
                        )}
                    </>
                  )}
              </UnorderedListRound>
            </Box>
          </Box>
        </Box>
      </Link>
    </DashboardCommonCard>
  )
}

export default DashboardFavoritesTrendsSummaryCard
