import React, { useEffect, useState } from 'react'

import './Tab.scss'

const Tab = ({ tabs = [], setSelectedTab, currActiveTab = '' }) => {
  const [activeTab, setActiveTab] = useState(currActiveTab)

  useEffect(() => {
    if (!currActiveTab) {
      setActiveTab(tabs[0].id)
    } else {
      setActiveTab(currActiveTab)
    }
  }, [currActiveTab])

  useEffect(() => {
    setSelectedTab && setSelectedTab(activeTab)
  }, [activeTab])

  return (
    <div className="sw-tab">
      <div className="sw-tab__header">
        {tabs.map(({ heading, id }) => (
          <div
            key={id}
            className={`sw-tab__header__item ${
              activeTab === id ? 'sw-tab__header__item--active' : ''
            }`}
            onClick={() => setActiveTab(id)}
            role="button"
          >
            <span>{heading}</span>
          </div>
        ))}
      </div>
      <div className="sw-tab__content">
        {(tabs.find(({ id }) => id === activeTab) || { content: '' }).content}
      </div>
    </div>
  )
}

export default Tab
