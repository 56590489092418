import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  Backdrop,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { ErrorRounded, Close } from '@mui/icons-material'
import store from '../../../indexStore'
import axios from '../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import { OTHER_REASON_MAX_LENGTH } from '../../utils/constants'

const options = [
  [
    'Wrong Trend Name',
    'Wrong Translation',
    'Wrong Category',
    'Wrong Image or Product link',
    'Wrong Currency/Location',
  ],
  [
    'Non Food and Beverage related',
    'Duplicated/ Repeated',
    'Wrong Format',
    'Others',
  ],
]

/*
  This is only specific to mark irrelevant feature
*/
const DialogWithCheckboxOptions = (props) => {
  const [reasons, setReasons] = useState([])
  const [isOtherOptionSelected, setIsOtherOptionSelected] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    setIsOtherOptionSelected(false)
    setReasons([])
  }, [props.isOpen])

  const onCheckboxValueChange = (e) => {
    if (e.target.value === 'Others' && e.target.checked) {
      setIsOtherOptionSelected(true)
    }
    if (e.target.checked) {
      setReasons([...reasons, e.target.value])
    } else {
      if (e.target.value === 'Others') {
        setIsOtherOptionSelected(false)
      }
      setReasons(reasons.filter((r) => r !== e.target.value))
    }
  }

  const updateOtherReason = (value) => {
    if (value.length > OTHER_REASON_MAX_LENGTH) {
      setError(true)
    }
    if (value.length <= OTHER_REASON_MAX_LENGTH) {
      setOtherReason(value)
      setError(false)
    }
  }

  const updateIrrelevantDocs = () => {
    props.closeDialog()
    props.showLoader()
    const productDetails = store.getState()?.productDetails?.productDetails
    let name = props.name
    if (productDetails && productDetails.name) {
      name = productDetails.name
    }
    const email = store.getState().user.loggedIn.user.email
    const data = {
      es_ids: props.selectedIrrelevantDocs,
      country: props.country,
      category: props.category,
      trend: name,
      lens: props.lens,
      marked_by: email,
      reasons: reasons,
      other_reason: otherReason,
      type: 'fsr',
    }
    axios
      .post(NetworkUtil.getMarkIrrelevantCQUrl(), data)
      .then((response) => {
        props.toggleAlert()
        props.hideLoader()
        props.onRefresh()
      })
      .catch((error) => {
        console.error(error)
        props.hideLoader()
      })
  }

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      ref={props.dialogElement}
      scroll="paper"
      fullWidth={true}
      PaperProps={{
        sx: {
          width: '650px',
          maxWidth: '650px',
          minWidth: '650px',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
          position: 'absolute',
          height: document.getElementById(props.containerId)?.scrollHeight,
        },
      }}
      sx={{
        paper: {
          position: 'absolute',
          width: '75%',
        },
      }}
      open={props.isOpen}
      onClose={props.closeDialog}
      aria-labelledby="mark-irrelevant-title"
      aria-describedby="mark-irrelevant-description"
    >
      <DialogTitle id="mark-irrelevant-title">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
            onClick={props.closeDialog}
          >
            <ErrorRounded
              fontSize="medium"
              variant="contained"
              color="primary"
              sx={{
                marginRight: '16px',
              }}
            />
            <Box>
              <Typography variant="h3">Send feedback</Typography>
              <Typography color="textSecondary" variant="subtitle2">
                Tell us what's wrong?
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'flex-end',
              cursor: 'pointer',
            }}
          >
            <Close onClick={props.closeDialog} />
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            minWidth: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup>
                {options[0].map((r) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox value={r} onChange={onCheckboxValueChange} />
                      }
                      label={r}
                    />
                  )
                })}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                {options[1].map((r) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox value={r} onChange={onCheckboxValueChange} />
                      }
                      label={r}
                    />
                  )
                })}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ paddingLeft: '16px' }}>
                {props.selectedIrrelevantDocs.length}{' '}
                {props.selectedIrrelevantDocs.length > 1
                  ? `${
                      props.type === 'food' ? 'Food' : 'Retail'
                    } Service Products `
                  : `${
                      props.type === 'food' ? 'Food' : 'Retail'
                    } Service Product `}
                will be reported.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isOtherOptionSelected && (
                <Box sx={{ paddingLeft: '8px' }}>
                  <TextField
                    error={error}
                    helperText={
                      error
                        ? `Max ${OTHER_REASON_MAX_LENGTH} characters allowed.`
                        : ''
                    }
                    inputProps={{
                      maxLength: OTHER_REASON_MAX_LENGTH + 1,
                    }}
                    sx={{
                      width: '100%',
                    }}
                    variant="outlined"
                    size="large"
                    placeholder={'Write your reason here *'}
                    onChange={(e) => updateOtherReason(e.target.value)}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="grayishblue" onClick={props.closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={reasons.length <= 0}
          onClick={updateIrrelevantDocs}
          size="small"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogWithCheckboxOptions
