import { Box, Grid } from '@mui/material'

import React from 'react'

const DashboardCommonCard = (props) => {
  const { cardProps, boxStyles } = props
  return (
    <Grid item xs={4} {...cardProps}>
      <Box
        sx={{
          borderRadius: 0.5,
          p: 2,
          boxShadow: '0px 2px 4px rgba(0,0,0, 0.1)',
          height: '100%',
          ...boxStyles,
        }}
      >
        {props.children}
      </Box>
    </Grid>
  )
}

export default DashboardCommonCard
