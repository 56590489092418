import React, { useState, useEffect } from 'react'
import axios from '../../services/networking/axios'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import CategoryInsightDetailsTop from '../../shared/components/CategoryInsightDetailsTop'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import { downloadGraph, downloadSection, downloadFullPage } from '../../const'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import ProductNeedsDetails from '../../services/networking/Models/ProductNeedsDetails'
import SavedProjectsList from '../../services/networking/Models/SavedProjectsList'
import { useLoader } from '../../hooks'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { AmplitudeValues } from '../../store/amplitudeValues'
import { amplify } from '../../store/utility'
import {
  INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
  INSIGNIFICANT_SOCIAL_MENTION_COUNT,
} from './../../shared/utils/constants'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      Lens: ampiUserData.lens,
      Trend_Name: ampiUserData.trendName,
      Region_Name: ampiUserData.regionName,
      Consumer_Need: ampiUserData.consumerNeed,
    }
    amplify(ampiEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in RegionalAnalyticsCI')
  }
}

const RegionalAnalyticsCI = ({ subscriptionDetails }) => {
  const [loader, setLoader] = useLoader()
  const [needDetails, setNeedDetails] = useState()
  const [needQuotes, setNeedQuotes] = useState()
  const [country, setCountry] = useState()
  const [category, setCategory] = useState()
  const queryParams = new URLSearchParams(window.location.search)
  const projectId = queryParams.get('projectId')
  const lens = queryParams.get('lens')?.toLowerCase()
  const [isDataInsignificant, setIsDataInsignificant] = useState(false)

  const region = queryParams.get('region')
  const needId = queryParams.get('needId')
  const trendId = queryParams.get('trendId')

  const handleSnackBarRemove = () => {
    setIsDataInsignificant(false)
  }

  const imageDownload = (section) => {
    setLoader(true)
    const name = needDetails.keyword
    const [, country, category] = needDetails.searchCriteria[0].split(';')
    if (section === 'Chart') {
      downloadGraph(
        document.getElementById('_dowSectionChart'),
        `${name}_Monthly_Trend_Graph.jpg`,
      )
        .then(() => {
          setLoader(false)
        })
        .catch((er) => {
          console.log(er)
          setLoader(false)
        })
    } else {
      let dowSection = document.getElementById('_dowSectionDesc')
      let fileName = `${name}_${country}_${category}_Complete_Report.jpg`
      if (!section) {
        downloadFullPage(
          dowSection,
          '#_dowSectionChart',
          { top: 300, left: 0 },
          document.getElementById('downloadCanvas'),
          `height: 500px; background: white;`,
          fileName,
        )
          .then(() => {
            setLoader(false)
          })
          .catch((er) => {
            setLoader(false)
            console.log(er)
          })
      }

      if (section === 'RelatedIngredients') {
        dowSection = document.getElementById('_dowSectionIngredientBlock')
        fileName = `${name}_Related_Ingredients.jpg`
        downloadSection(dowSection, fileName)
          .then(() => {
            setLoader(false)
          })
          .catch(() => {
            setLoader(false)
          })
      }
    }
  }

  const fetchConsumerNeedQuotes = () => {
    setLoader(true)

    let request
    if (trendId) {
      switch (lens) {
        case 'ingredient':
          request = NetworkingUtil.fetchRegionalIngredientFilterNeedQuotes(
            needId,
            region,
          )
          break
        case 'product':
          request = NetworkingUtil.fetchRegionalProductFilterNeedQuotes(
            needId,
            region,
          )
          break
        case 'theme':
          request = NetworkingUtil.fetchRegionalThemeFilterNeedQuotes(
            needId,
            region,
          )
          break
      }
    } else {
      request = NetworkingUtil.formRequestForRegionalConsumerNeedQuotes(
        needId,
        region,
      )
    }

    axios
      .get(request)
      .then((res) => {
        const needQuoteResponse = res.data
        setNeedQuotes(needQuoteResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional cn quote data')
      })
  }

  const fetchConsumerNeed = () => {
    setLoader(true)
    let request
    if (trendId) {
      switch (lens) {
        case 'ingredient':
          request = NetworkingUtil.fetchRegionalIngredientFilterNeedDetails(
            needId,
            region,
          )
          break
        case 'product':
          request = NetworkingUtil.fetchRegionalProductFilterNeedDetails(
            needId,
            region,
          )
          break
        case 'theme':
          request = NetworkingUtil.fetchRegionalThemeFilterNeedDetails(
            needId,
            region,
          )
          break
      }
    } else {
      request = NetworkingUtil.formRequestForRegionalConsumerNeeds(
        projectId,
        needId,
        region,
      )
    }

    axios
      .get(request)
      .then((res) => {
        const needDetailsResponse = res.data

        const formattedNeedDetailResponse = new ProductNeedsDetails(
          needDetailsResponse,
        )
        const nonZeroEngagementScoreDataPercent =
          (formattedNeedDetailResponse.needChart.filter(
            ({ engagementScore }) => engagementScore !== 0,
          ).length /
            formattedNeedDetailResponse.needChart.length) *
          100

        setIsDataInsignificant(
          formattedNeedDetailResponse.uniqueMentionCount <=
            INSIGNIFICANT_SOCIAL_MENTION_COUNT ||
            nonZeroEngagementScoreDataPercent <=
              INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
        )
        setNeedDetails(formattedNeedDetailResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional cn data')
      })
  }

  const fetchProjectList = () => {
    setLoader(true)
    const request = NetworkingUtil.formSavedProjectListRequest()
    return axios
      .get(request)
      .then((response) => {
        setLoader(false)
        const projects = new SavedProjectsList(response.data)
        const projectList = projects.list
        const project = projectList?.find((project) => {
          return project._id === parseInt(projectId)
        })
        setCategory(project._name)
        setCountry(project._criteria)
      })
      .catch((error) => {
        setLoader(false)
        console.log('error in fetching project list')
      })
  }

  const logConsumerQuotesClicked = () => {
    const ampiEvent = 'Clicked_Regional_CN_Quotes'
    const ampiUserData = {
      country: country,
      category: category,
      lens: lens,
      trendName: needDetails?.productName,
      regionName: region,
      consumerNeed: needDetails?.keyword,
    }
    amp(ampiEvent, ampiUserData)
  }

  useEffect(() => {
    fetchProjectList()

    fetchConsumerNeed()
    fetchConsumerNeedQuotes()
  }, [])

  return (
    <FeatureAuthorizer
      authAttr="regional_analytics"
      disableForFeat="cognitive_search"
    >
      <Stack spacing={3} sx={{ my: 3 }}>
        <Box
          sx={{
            position: 'fixed',
            top: '50px',
            zIndex: 100,
            width: '100%',
            background: 'white',
          }}
        >
          <MainCommonTabs subscriptionDetails={subscriptionDetails} />
        </Box>
        {loader ? (
          <OverlayLoader />
        ) : (
          <>
            <Box id="_dowSectionDesc">
              <Stack spacing={3}>
                {needDetails && (
                  <CategoryInsightDetailsTop
                    expand={loader}
                    hideDownload
                    showGrowthChip={true}
                    needChart={true}
                    catInsight={true}
                    projectid={projectId}
                    disableTopicsPointerEvents
                    needid={needId}
                    details={needDetails}
                    positiveSentiment={needDetails.positiveScore}
                    neutralSentiment={needDetails.negativeScore}
                    negativeSentiment={needDetails.neutralScore}
                    imageDownload={imageDownload}
                    country={country}
                    category={category}
                    chartLargeView
                    type="regionalAnalytics"
                    lens={lens}
                    region={region}
                    trendId={trendId}
                    isDataInsignificant={isDataInsignificant}
                    handleSnackBarRemove={handleSnackBarRemove}
                  />
                )}
                <Box id="_dowSectionIngredientBlock"></Box>
              </Stack>
            </Box>
            {needQuotes && needDetails && (
              <ConsumerQuotesCardComponent
                quotesOptions={needDetails.quotesOptions}
                consumerQuotes={needQuotes}
                sourceShow
                onRefresh={() => {
                  this.fetchConsumerNeedQuotes()
                }}
                country={country}
                category={category}
                name={needDetails.keyword}
                lens={lens}
                markIrrelevantDisabled={false}
                details={{
                  type: 'Regional_CN',
                  region: region,
                  trendName: needDetails?.productName,
                }}
                type="regionalAnalytics"
                logConsumerQuotesClicked={logConsumerQuotesClicked}
              />
            )}
            <Box id="downloadCanvas"></Box>
          </>
        )}
      </Stack>
    </FeatureAuthorizer>
  )
}
// }

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

export default connect(mapStateToProps, null)(RegionalAnalyticsCI)
