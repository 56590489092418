import React, { useEffect } from 'react'
import { Breadcrumbs, Grid, Link, Typography, Box, Stack } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DownloadButton from '../DownloadButton'
import VolumeCard from '../VolumeCard'
import TrendGrowthCard from '../TrendGrowthCard'
import TopicsCard from '../TopicsCard'
import HideOnScrollAppbar from './../HideOnScrollAppbar'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'
import { useLocation, useHistory } from 'react-router-dom'
import AddFavoriteButtonParing from '../OtherFavorites/AddFavoriteButtonParing'

const ProductInsightDetailsTop = (props) => {
  const location = useLocation()
  const history = useHistory()
  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/product-category/${props.projectid}`}
    >
      {props.country} {props.category}
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="3"
      href={`/mui/details/${props.projectid}/category/${props.needid}`}
    >
      {props.details.needName}
    </Link>,
    <Typography key="4" variant="subtitle2">
      {props.details.keyword}
    </Typography>,
  ]

  if (props.topicChart) {
    const trendNeedBreadCrumb = (
      <Link
        variant="breadcrumbsLink"
        key="3"
        href={`/mui/details/${props.projectid}/${props.urllens}/${props.productid}/need/${props.needid}`}
      >
        {props.details.needName}
      </Link>
    )
    breadcrumbs.splice(
      2,
      0,
      <Link
        variant="breadcrumbsLink"
        key="3"
        href={`/mui/details/${props.projectid}/${props.urllens}/${props.productid}`}
      >
        {props.details.productName}
      </Link>,
    )
    breadcrumbs.splice(3, 1, trendNeedBreadCrumb)
  }

  useEffect(() => {
    let title = ''
    if (props.country && props.category) {
      title = `Explore Trends>${props.country} ${props.category}>`
    }
    if (props.topicChart) {
      if (
        title &&
        props.details.productName &&
        props.details.needName &&
        props.details.keyword
      ) {
        shouldLogRecentActivity(
          `${title}${props.details.productName}>${props.details.needName}>${props.details.keyword}`,
          'Platform',
          history.location.pathname,
        )
      }
    } else {
      if (title && props.details.needName && props.details.keyword) {
        shouldLogRecentActivity(
          `${title}${props.details.needName}>${props.details.keyword}`,
          'Platform',
          history.location.pathname,
        )
      }
    }
  }, [
    props.topicChart,
    props.country,
    props.category,
    props.details.productName,
    props.details.needName,
    props.details.keyword,
  ])

  return (
    <Stack gap={2}>
      <HideOnScrollAppbar>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </HideOnScrollAppbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '20px',
        }}
      >
        <Typography variant="h4" color="textSecondary">
          Consumer Insight{' '}
          {props.details.needFilter &&
            (props.details.needFilter !== 'All' ? (
              <Typography component="span" variant="h4">
                for {props.details.needFilter}
              </Typography>
            ) : (
              <Typography component="span" variant="h4">
                for {props.category}
              </Typography>
            ))}
        </Typography>
        <>
          <Box>
            <AddFavoriteButtonParing
              additionalInfo={null}
              projectId={props.projectid}
              trendName={
                !props.details.needFilter
                  ? `Trend Consumer Insight(Sub-Consumer Need): ${props.country} ${props.category}_ ${props.details.productName}_ ${props.details.needName}_${props.details.keyword}`
                  : props.details.keyword
              }
              favoriteType={
                !props.details.needFilter
                  ? 'TREND_SUB_CONSUMER_INSIGHT'
                  : 'CATEGORY_SUB_CONSUMER_INSIGHT'
              }
              productId={
                !props.details.needFilter
                  ? props.needid
                  : history.location.pathname.split('/').pop()
              }
              trendType={
                (props.urllens &&
                  props.urllens.charAt(0).toUpperCase() +
                    props.urllens.slice(1)) ||
                'Ingredient'
              }
              reDirectionUrl={location.pathname}
              breadcrumbs={
                !props.details.needFilter
                  ? `Foresight Engine, ${props.country} ${props.category}, ${props.details.productName}, ${props.details.needName}, ${props.details.keyword}`
                  : `Foresight Engine, ${props.country} ${props.category}, ${props.details.needName}, ${props.details.keyword}`
              }
              consumerInsightSection
              favoriteSectionType={'Other Favorites'}
            />

            <DownloadButton
              dowImageType={''}
              toggleImageDownload={props.imageDownload}
            />
          </Box>
        </>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            '& hr': {
              height: 20,
            },
          }}
        >
          <Typography variant="h2">{props.details.keyword}</Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div id="_dowSectionChart">
              <TrendGrowthCard
                topicChart
                dowImageType="Chart"
                toggleImageDownload={props.imageDownload}
                details={props.details}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={3}>
              <VolumeCard value={props.details.score} />
              {!!props.details.relatedTerms && (
                <TopicsCard
                  expand={props.expand}
                  projectid={props.projectid}
                  needid={props.needid}
                  productid={props.productid}
                  urllens={props.urllens}
                  catInsight={props.catInsight}
                  languageSelection={false}
                  maxHeight={props.catInsight ? '615' : '185'}
                  email={props.email}
                  country={props.country}
                  category={props.category}
                  heading="Topic"
                  topics={props.details.relatedTerms.map((item) => ({
                    name: item.name,
                    id: item.id,
                    percentage_score_current:
                      parseFloat(item.percentage_score_current) || 0,
                  }))}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default ProductInsightDetailsTop
