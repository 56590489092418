import { Box, styled } from '@mui/system'
import { Table } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark'

export const TableStyled = styled(Table)(({theme}) => ({
  backgroundColor: `${theme.palette.white}`,
  width: '100%',
  display: "inline-table",
  '& thead': {
    backgroundColor: `${theme.palette.white}`,
    width: '1372px'
  },
  '& .MuiTableCell-head': {
    color: `${theme.palette.text.primary} !important`,
    fontSize: '14px !important'
  },

  '& tr' : {
    '& td, th': {
      borderBottom: `10px solid ${theme.palette.background.default} !important`,
      textAlign: 'left',
      fontSize: '14px'
    }
  },
  '& .MuiTableCell-body: nth-child(3)': {
    textTransform: 'capitalize',
  },
  '& .MuiTableCell-body: nth-child(2)': {
    color: theme.palette.colors.hoverLink,
  },
}));

export const Image = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
    '& img' :{
          marginRight: '5px'
      }
}))

export const Main = styled(Box)(() => ({
  height: 'calc(92vh - 245px)',
  display: 'inline-grid',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BookmarkIconStyled = styled(BookmarkIcon)(({theme}) => ({
  color: theme.palette.info.main,
  cursor: 'pointer'
}));
