import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { ReactComponent as MomsJunctionColorImg } from '../../../../../assets/social-icons/MomsJunctionColorImage.svg'
import ConsumerQuotesContainer from './styles'
import { amplify } from '../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'
import TextOverflowTooltip from '../../../TextOverflowTooltip'
import BabyCenter from './../../../../../assets/babyCenter.png'
import { ReactComponent as Vogue } from '../../../../../assets/icons/vogue.svg'
import { ReactComponent as StyleCraze } from '../../../../../assets/icons/styleCraze.svg'
import pinkVilla from './../../../../../assets/icons/pinkvilla.png'
import mensXP from './../../../../../assets/icons/MensXP.png'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Likes: ampiUserData.likes,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesBlogsAndWebsites')
  }
}

const getImgComponent = (platform) => {
  switch (platform.toLowerCase()) {
    case 'momjunction':
      return <MomsJunctionColorImg />
    case 'babycenter':
      return (
        <Box sx={{ transform: 'scale(0.9)' }}>
          <img src={BabyCenter} height="inherit" width="100px" />
        </Box>
      )
    case 'vouge':
      return <Vogue />
    case 'mensxp':
      return (
        <Box sx={{ transform: 'scale(0.9)' }}>
          <img src={mensXP} height="inherit" width="100px" />
        </Box>
      )
    case 'stylecraze':
      return (
        <Box sx={{ transform: 'scale(0.7)' }}>
          <StyleCraze />
        </Box>
      )
    case 'pinkvilla':
      return (
        <Box>
          <img src={pinkVilla} height="inherit" width="80px" />
        </Box>
      )
    default:
      return platform
  }
}

const ConsumerQuotesBlogsAndWebsites = ({
  headline,
  content,
  image,
  platform,
  link,
  lens,
}) => {
  const linkHandler = () => {
    window.open(link, '_blank')
  }
  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      {image && (
        <Box
          sx={{
            borderRadius: '5px',
          }}
        >
          <img
            src={image}
            width={'363.46px'}
            height={'200px'}
            style={{
              borderRadius: '5px',
              objectFit: 'contain',
            }}
          />
        </Box>
      )}
      <TextOverflowTooltip title={headline} maxLineCount={2}>
        <Typography
          variant="h3"
          sx={{
            flexGrow: 1,
            pt: 2,
            px: 2,
            maxHeight: 70,
            overflow: 'auto',
          }}
        >
          {headline}
        </Typography>
      </TextOverflowTooltip>

      <Box
        height="auto"
        maxHeight="235px"
        pt={1}
        px={2}
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Typography
          variant="body3"
          sx={{
            flexGrow: 1,
            pt: 2,
            mb: 3,
            marginBottom: '50px',
          }}
        >
          {content}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingTop: '10px',
          marginTop: '5px',
          marginLeft: '20px',
          display: 'flex',
          justifyContent: 'flex-end !important',
          height: '40px',
        }}
      >
        {getImgComponent(platform)}
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesBlogsAndWebsites.propTypes = {
  link: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesBlogsAndWebsites
