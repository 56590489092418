import React, { useMemo } from 'react'
import {
  Box,
  Button,
  List,
  Typography,
  Link,
  IconButton,
  Tooltip,
} from '@mui/material'
import Popper from '@mui/material/Popper'
import { StyledPopper, StyledArrow } from './styles'
import CloseIcon from '@mui/icons-material/Close'

const TourProviderUI = ({
  modalRef,
  content,
  pathname,
  useCaseList,
  crossClicked,
  popperRef,
  popperPosition,
  arrowRef,
  setArrowRef,
  clickedOK,
}) => {
  const height = document.documentElement.scrollHeight
  return (
    <>
      <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1350 }}>
        <svg
          version="1.1"
          baseProfile="full"
          height={height}
          width="100vw"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <mask id="masked-area">
              <rect width="100%" height={height} fill="white" />
              <rect
                x={useMemo(
                  () => (modalRef ? modalRef.getBoundingClientRect().left : 0),
                  [modalRef],
                )}
                // This calculation is required to be done in this component so that it position popper on basis of latest modal position (In case of dropdowns)
                y={useMemo(
                  () => (modalRef ? modalRef.getBoundingClientRect().top : 0),
                  [modalRef],
                )}
                width={useMemo(() => (modalRef ? modalRef.clientWidth : 0), [
                  modalRef,
                ])}
                height={useMemo(() => (modalRef ? modalRef.clientHeight : 0), [
                  modalRef,
                ])}
                fill="black"
              />
            </mask>
          </defs>
          <rect
            width="100%"
            height={height}
            fill="black"
            fill-opacity="0.7"
            mask="url(#masked-area)"
          />
        </svg>

        <StyledPopper
          id="popper"
          open={true}
          anchorEl={modalRef}
          placement={popperPosition()}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                fallbackPlacements: ['top', 'bottom', 'left'],
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          <StyledArrow component="span" className="arrow" ref={setArrowRef} />
          <Box ref={popperRef}>
            <Box
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'white',
                margin: 3,
                borderRadius: 2,
                zIndex: 1350,
              }}
            >
              <Box sx={{ position: 'absolute', right: '20px', top: '20px' }}>
                <Tooltip
                  title="This pop-up will appear again in next log-in."
                  sx={{ zIndex: 2 }}
                  placement="top-end"
                >
                  <IconButton onClick={crossClicked}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                <Typography variant="h3">Hi {content.name},</Typography>
              </Box>
              <Box>
                <Typography>
                  Have you tried {content.featureName} yet?
                </Typography>
              </Box>
              {useCaseList && (
                <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                  <Typography>
                    You can do the following things using {content.featureName}:
                  </Typography>
                </Box>
              )}
              <List sx={{ marginLeft: '20px' }}>{useCaseList}</List>
              <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                <Typography>Excited? Explore it NOW!!</Typography>
              </Box>
              <Box>
                <Typography>
                  Click here to{' '}
                  <Link
                    href={`/mui/docs?RedirectPath=${pathname}`}
                    onClick={() => clickedOK('Know More')}
                  >
                    Know More
                  </Link>
                </Typography>
              </Box>
              <Button
                sx={{ marginTop: 2, marginBottom: 1 }}
                variant="contained"
                onClick={() => clickedOK('OK')}
              >
                OK
              </Button>
            </Box>
          </Box>
        </StyledPopper>
      </Box>
    </>
  )
}

export default TourProviderUI
