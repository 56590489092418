import { fetchHighlightFeatureList } from './../actions/actionTypes'
import { updateObject, snakeToCamelObjKeys } from '../utility'
import { SET_USER_LOGGED_OUT } from '../actions/actionConstants'

const initialState = {
  highlightList: {},
}
const highlightFeature = (state = initialState, action) => {
  switch (action.type) {
    case fetchHighlightFeatureList.type[
      fetchHighlightFeatureList.FETCH_HIGHLIGHT_FEATURE_LIST_SUCCESS
    ]:
      return updateObject(state, {
        highlightList: snakeToCamelObjKeys({
          ...action.highlightFeatureList,
          featureDetails: action.highlightFeatureList.feature_details.sort(
            (a, b) => a.rank - b.rank,
          ),
        }),
      })
    case fetchHighlightFeatureList.type[
      fetchHighlightFeatureList.FETCH_HIGHLIGHT_FEATURE_LIST_FAILED
    ]:
      return updateObject(state, {
        error: '',
      })
    case SET_USER_LOGGED_OUT:
      return updateObject(state, initialState)
    default:
      return state
  }
}

export default highlightFeature
