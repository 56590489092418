import { connect } from 'react-redux'
import { PermissionList } from '../../components'
import * as A from '../../store/actions'
import { initialize } from 'redux-form'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showPermissionForm: () => {
      dispatch(A.showPermissionForm())
      dispatch(A.hidePermissionList())
    },
    showPermissionList: () => {
      dispatch(A.hidePermissionForm())
      dispatch(A.showPermissionList())
    },
    getAllPermission: () => {
      return dispatch(A.getAllPermissionsFromServer()).then(() => {

      })
    },

    addPermission: (values) => {
      if (!values.id) {
        dispatch(A.showSppiner())
        return dispatch(A.addPermissionOnServer(values)).then(() => {
          dispatch(A.hidePermissionForm())
          dispatch(A.showPermissionList())
          dispatch(A.hideSppiner())
        })
      } else {
        dispatch(A.showSppiner())
        return dispatch(A.updatePermissionOnServer(values)).then(() => {
          dispatch(A.hidePermissionForm())
          dispatch(A.showPermissionList())
          dispatch(A.hideSppiner())
        })
      }
    },
    editPermission: (permission) => {
      dispatch(A.showPermissionForm())
      dispatch(A.hidePermissionList())
      dispatch(initialize('permission', permission))
    },
    deletePermission: (permissionId) => dispatch(A.deletePermissionOnServer(permissionId)),
    hideForm: () => {
      dispatch(A.hidePermissionForm())
      dispatch(A.showPermissionList())
    },
  }
}

const mapStateToProps = (state) => {
  return {
    showSpinner: state.application.showSpinner,
    permissions: state.permission.all,
    permissionForm: state.permission.permissionForm,
    permissionList: state.permission.permissionList,
    loggedInUser: state.user.loggedIn && state.user.loggedIn.access_token ? state.user.loggedIn.access_token : null,
  }

  // let childProps = {
  //     loggedInUser: state.user.loggedIn,
  //     permissions: state.permission.all
  // }
  // return childProps
}

const PermissionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionList)

export default PermissionListContainer
