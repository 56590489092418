import React, { useState } from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { Chip, Tooltip } from '@mui/material';
import { calcDisplayedWidth } from "../../../store/utility";
import { StyledChip } from "./styled";

const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: 3,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'grey.100',
    borderRadius: 1,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'grey.500',
    borderRadius: 1,
  },
};

const ExpandableChipsGroup = ({ trends, maxHeight, addTooltip }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const chips = isExpanded ? trends : trends.slice(0, 3);

  const ctxTitleButton = () => {
    const listItme = chips && chips.map(item => {
      let textWidth = 0;
      textWidth = calcDisplayedWidth(item, 14)
      const ctxListItemBtn = (
        textWidth > 150  ?
        <Tooltip title={item}>
          <StyledChip
            variant="outlined"
            label={item}
            addTooltip
          />
        </Tooltip> :
        <StyledChip
          variant="outlined" 
          label={item}
          noPointerEvent
          onClick={(e)=>{
            e.preventDefault()
          }}
        />
      )
        return ctxListItemBtn
      })
      return listItme
    }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        ...(maxHeight && {
          maxHeight,
          overflowY: 'auto',
          ...scrollbarStyles,
        }),
      }}
    >
      {addTooltip ? ctxTitleButton()  : chips.map(item => 
        <Chip
          variant="outlined" 
          label={item}
          sx={{
            cursor: 'default',
            pointerEvents: 'none',
            textDecoration: 'none',
          }}
          onClick={(e)=>{
            e.preventDefault()
          }}
        />
      )}
      {!isExpanded && trends.length > 3 && (
        <Chip
          variant="outlined"
          label={`+${trends.length - 3}`}
          onClick={() => setIsExpanded(true)}
        />
      )}
      </Box>
  );
};

ExpandableChipsGroup.propTypes = {
  maxHeight: PropTypes.number,
  trends: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ExpandableChipsGroup.defaultProps = {
  maxHeight: undefined,
};

export default ExpandableChipsGroup;
