import React from 'react'
import { Grid } from '@mui/material'
import TrendPageRegionalDistributionChart from '../../TrendPageRegionalDistributionChart'
import TrendPageAgeDistributionChart from '../../TrendPageAgeDistributionChart'
const RegionalAndAgeGroupDistributionGraphUI = ({
  projectId,
  themeId,
  lens,
  country,
  category,
  ageGroupTab,
  trendName,
  hasDemographyData,
  hasRegionalData,
  demographyDataCallback,
  regionalDataCallback,
}) => {
  return (
    <>
      {(hasDemographyData || hasRegionalData) && (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
          container
        >
          <TrendPageRegionalDistributionChart
            trendName={trendName}
            country={country}
            category={category}
            projectId={projectId}
            lens={lens}
            productId={themeId}
            onDataFetchCallback={regionalDataCallback}
          />
          <TrendPageAgeDistributionChart
            country={country}
            trendName={trendName}
            category={category}
            ageGroupTab={ageGroupTab}
            projectId={projectId}
            lens={lens}
            themeId={themeId}
            onDataFetchCallback={demographyDataCallback}
          />
        </Grid>
      )}
    </>
  )
}
export default RegionalAndAgeGroupDistributionGraphUI
