import { SavedTrendsActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  savedTrendList: null,
  error: false,
  loading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SavedTrendsActionTypes.type[SavedTrendsActionTypes.FETCH_SAVED_TRENDS_START]: return updateObject(state, {
      loading: true,
    })
    case SavedTrendsActionTypes.type[SavedTrendsActionTypes.FETCH_SAVED_TRENDS_SUCCESS]: return updateObject(state, {
      savedTrendList: action.savedTrends,
      loading: false,
      error: false,
    })
    case SavedTrendsActionTypes.type[SavedTrendsActionTypes.FETCH_SAVED_TRENDS_FAIL]: return updateObject(state, {
      loading: false,
      error: true,
      savedTrendList: null,
    })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        savedTrendList: null,
        error: false,
        loading: false,
      })
    default:
      return state
  }
}

export default reducer
