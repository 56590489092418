import React, { useLayoutEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import './SWTooltip_v2.scss'

const TooltipTitle = ({ children, title }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [pos, setPos] = useState({ left: 0, top: 0 })
  const ref = useRef(null)
  const cloneRef = useRef(null)

  const handleMouseEnter = (e) => {
    cloneRef.current = e.target
    const textExceedsElement = e.target.clientHeight < e.target.scrollHeight
    setShowTooltip(textExceedsElement)
  }
  const handleMouseLeave = () => {
    setShowTooltip(false)
  }
  const clonedElement = React.cloneElement(children, {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  })

  useLayoutEffect(() => {
    if (showTooltip && cloneRef && ref) {
      const arrowOffset = 10
      const { x, y } = cloneRef.current.getBoundingClientRect()
      const { width } = ref.current.getBoundingClientRect()
      setPos({ left: x - width - arrowOffset, top: y })
    }
  }, [showTooltip])

  return (
    <>
      {showTooltip && (
        <div
          className="tooltip-title_v2__content"
          ref={ref}
          style={{
            left: pos.left,
            top: pos.top,
            display: 'block',
          }}
        >
          <div className="tooltip-title_v2__txt  tooltip-title_v2__txt--left">
            {title}
          </div>
        </div>
      )}
      {clonedElement}
    </>
  )
}

TooltipTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default TooltipTitle
