import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon, Box, Container, Divider, Button } from '@mui/material'
import { PublicHeader, PublicFooter } from '../../shared/components'
import { ReactComponent as newVersion } from '../../assets/newVersion.svg'
import { amplify } from '../../store/utility'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { handleNewUIRedirect } from '../../services/networking/commonRequests'

const amp = (email, ampiEvent) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: email,
    }
    amplify(amplitudeEvent, amplitudeUserData, email)
  } catch (err) {
    console.log(err, 'Amplitude error in LayoutHeaderFooter')
  }
}

const LayoutHeaderFooter = (props) => {
  const { children, email, subscriptionDetails } = props

  const hanldeNewUIRedirectLog = ( ) => {
    handleNewUIRedirect()
    amp(email, 'Clicked_Try_New_UI')
  }

  return (
    <Box sx={{ bgcolor: 'background.default' }}>
      <PublicHeader />
      <Container maxWidth="xl" style={{ minHeight: 'calc(100vh - 132px)' }}>
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      </Container>
      {subscriptionDetails?.switch_to_new_platform_enabled && (
        <Button
          sx={{
            transform: 'rotate(-90deg)',
            position: 'absolute',
            right: '-55px',
            top: '450px',
            backgroundColor: '#211F33',
            color: 'white',
          }}
          onClick={hanldeNewUIRedirectLog}
        >
          <SvgIcon sx={{ marginTop: '10px' }} component={newVersion} />
          Try new version!
        </Button>
      )}
      <Divider />

      <Container maxWidth="xl" sx={{ p: 2 }}>
        <PublicFooter />
      </Container>
    </Box>
  )
}

LayoutHeaderFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => {
  return {
    email: state.user?.loggedIn?.user?.email || '',
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

export default withRouter(connect(mapStateToProps, null)(LayoutHeaderFooter))
