import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { ReactComponent as IngredientsIcon } from '../../../assets/icons/ingredients.svg'
import { ReactComponent as BPCIngredientsIcon } from '../../../assets/icons/bpc-ingredient.svg'
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../../assets/icons/themes.svg'

import TabPanel from '../TabPanel'
import EmergingTrendsCard from '../EmergingTrendsCard'
import TrendsDistributionChart from '../TrendsDistributionChart'
import { chipKeys } from '../../utils/constants'

const EMERGING = 'Emerging Trends'
const GROWING = 'Growing Trends'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CategoryPreview = ({
  projectId,
  lensToShow,
  ingredientsList,
  themesList,
  productsList,
  isProductCategory,
  handleCanvas,
  countrySelected,
  categorySelected,
  projectVariant,
  amplitudeFn,
}) => {
  const [tab, setTab] = useState('')
  const [trendPhases, setTrendPhases] = useState(chipKeys)

  const ampiCountryCategory = {
    country: countrySelected,
    category: categorySelected,
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
    const ampiEvent = 'Clicked_Lens'
    const ampiUserData = {
      lens: newValue,
      country: countrySelected,
      category: categorySelected,
      projectId: projectId,
    }
    amplitudeFn(ampiEvent, ampiUserData)
  }

  let trends = null
  const category = (
    ingredientsList ||
    themesList ||
    productsList || { searchCriteria: [''] }
  ).searchCriteria[0].split(';')[2]
  switch (tab) {
    case 'Ingredient':
      trends = ingredientsList ? ingredientsList.trends : null
      break
    case 'Product':
      trends = productsList ? productsList.trends : null
      break
    case 'Theme':
      trends = themesList ? themesList.trends : null
      break
    default:
      trends = null
      break
  }
  const tabs = lensToShow.map((lens, i) => {
    let icon = IngredientsIcon
    if (projectVariant === 'bpc') {
      icon = BPCIngredientsIcon
    }
    if (lens === 'Product') {
      icon = ProductsIcon
    }
    if (lens === 'Theme') {
      icon = ThemesIcon
    }
    return (
      <Tab
        key={lens}
        value={lens}
        icon={<SvgIcon component={icon} />}
        label={`${lens}s`}
        {...a11yProps(i)}
      />
    )
  })
  const tabsPanel = lensToShow.map((lens, i) => {
    return (
      <TabPanel key={lens} value={tab} index={lens}>
        {trends && (
          <TrendsDistributionChart
            compactView
            projectId={projectId}
            initTrendPhases={trendPhases}
            trendPhasesChangeCallback={setTrendPhases}
            lensSelected={lens}
            trends={trends}
            country={countrySelected}
            category={categorySelected}
            amplitudeView="Category Preview"
            maxTrendsShown={trends.reduce(
              (acc, curr) => curr.allTrendResults.length + acc,
              0,
            )}
          />
        )}
      </TabPanel>
    )
  })

  useEffect(() => {
    if (lensToShow.length) {
      setTab(lensToShow[0])
    }
  }, [lensToShow])
  return (
    <Paper sx={{ boxShadow: '0px 4px 12px 0px #272B3108', my: 3 }}>
      {!isProductCategory ? (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
            <Typography variant="h3">Category Preview</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Top level information of the category. Click on the “Explore
              Category” to view more
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
            <Typography variant="h3">Trends</Typography>
          </Box>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            justifyContent="right"
          >
            <Button
              mb={3}
              variant="contained"
              size="small"
              onClick={handleCanvas}
            >
              List
            </Button>
            <Button
              mb={3}
              variant="contained"
              size="small"
              onClick={handleCanvas}
            >
              Chart
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs}
        </Tabs>
        {tabsPanel}
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {trends && !isProductCategory && (
          <>
            <EmergingTrendsCard
              tab={tab}
              projectid={projectId}
              title={`Emerging Trends in the ${category}`}
              ampiCountryCategory={ampiCountryCategory}
              trends={
                trends.find(({ type }) => type === EMERGING).allTrendResults
              }
            />
            <EmergingTrendsCard
              tab={tab}
              projectid={projectId}
              title={`Growing Trends in the ${category}`}
              ampiCountryCategory={ampiCountryCategory}
              trends={
                trends.find(({ type }) => type === GROWING).allTrendResults
              }
            />
          </>
        )}
      </Box>
    </Paper>
  )
}

CategoryPreview.defaultProps = {
  isProductCategory: false,
}

export default CategoryPreview
