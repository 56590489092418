import { ToggleButtonGroup } from '@mui/material'
import styled from '@emotion/styled'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      color: theme.palette.black,
    },
    '& .Mui-selected': {
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  }),
)
