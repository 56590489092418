import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderRadius: 4,
      backgroundColor: palette.background.paper,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.divider,
        borderWidth: 1,
        '& legend': {
          maxWidth: '0.01px',
        },
      },
      '&:hover:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused)': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[400],
        },
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            opacity: 1,
            color: palette.text.secondary,
          },
          '::-ms-input-placeholder': {
            opacity: 1,
            color: palette.text.secondary,
          },
          ':-ms-input-placeholder': {
            opacity: 1,
            color: palette.text.secondary,
          },
        },
        '& .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
          color: palette.grey[600],
        },
      },
      '&.Mui-error': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.error.main,
          borderWidth: 1,
        },
      },
      '&.Mui-focused:not(.Mui-error)': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
          borderWidth: 1,
        },
        '& .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
          color: palette.primary.main,
        },
      },
    },
    notchedOutline: {
      borderWidth: 1,
    },
    disabled: {
      pointerEvents: 'none',
      border: 'none',
      backgroundColor: palette.background.default,
      color: palette.divider,
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          opacity: 1,
          color: palette.text.disabled,
        },
        '::-ms-input-placeholder': {
          opacity: 1,
          color: palette.text.disabled,
        },
        ':-ms-input-placeholder': {
          opacity: 1,
          color: palette.text.disabled,
        },
      },
    },
  },
};

