import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  CssBaseline,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Box,
  Typography,
} from '@mui/material'
import * as A from '../../store/actions'
import LoginLayoutV2 from '../../layouts/LayoutLoginV2'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { formatString, amplify } from '../../store/utility'
import * as yup from 'yup'
import HeroAnimation from '../../assets/images/HeroAnimation/HeroAnimation .gif'
import '../muiStyle.css'
import { ReactComponent as WhiteSmallLogo } from '../../assets/logo_v2_white_small.svg'

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .required('Please enter correct email'),
    password: yup
      .string()
      .min(3)
      .required(),
  })
  .required()

const logSignIn = (email) => {
  amplify('Clicked_Sign_In', { User: email }, email)
}

const AuthPage = (props) => {
  const { loginError, showSpinner } = props
  const [showPassword, setShowPassword] = useState(false)
  const [resetInfoSent] = useState(localStorage.getItem('__ai_pass_sent_notif'))

  localStorage.removeItem('__ai_pass_sent_notif')
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit = async (data) => {
    props.onSubmit(data).then((loggedIn) => {
      if (loggedIn) {
        logSignIn(data.email)
      }
    })
  }

  return (
    <LoginLayoutV2>
      <CssBaseline />
      <Stack>
        <Box>
          <Box align="center">
            <Box
              component="img"
              src={HeroAnimation}
              sx={{
                height: '400px',
                width: '400px',
                borderRadius: '50%',
              }}
            />
          </Box>
          <Box pt="25px">
            <Typography
              variant="h2"
              fontWeight="bold"
              color="#FFFFFF"
              align="center"
            >
              Discover growth <br />
              opportunities faster.
            </Typography>
            <Typography
              variant="subtitle2"
              color="#FFFFFF"
              pt="25px"
              align="center"
            >
              Research ideas, develop concepts, and screen products your
              consumers
              <br />
              will love. All in a single day with a few clicks.
            </Typography>
          </Box>
        </Box>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Stack alignItems="start" justifyContent="center" spacing={4}>
            <Box
              sx={{
                position: 'relative',
                background:
                  'linear-gradient(138deg, rgba(223, 223, 223, 0.34) 0%, rgba(223, 223, 223, 0.00) 99.25%)',
                boxShadow: '6px 6px 40px 0px rgba(0, 0, 0, 0.30)',
                backdropFilter: 'blur(3.5px)',
                borderRadius: '16px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
                width: '500px',
                height: '550px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                my: 4,
                pt: '30px',
                pl: '50px',
                pb: '7.5px',
              }}
            >
              <Stack>
                <Stack spacing={1} textAlign="start">
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    fontSize="16px"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Welcome to the new look of
                    <WhiteSmallLogo
                      style={{ marginLeft: '5px' }}
                      width="80px"
                    />
                  </Typography>
                  <Typography
                    sx={{ marginTop: '25px !important' }}
                    variant="h2"
                    color="#FFFFFF"
                    fontSize="36px"
                  >
                    Login
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    fontSize="20px"
                  >
                    Enter your credentials!
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  {loginError && (
                    <Typography
                      sx={{ paddingTop: 1, color: '#FF5757' }}
                      variant="subtitle2"
                    >
                      {loginError}
                    </Typography>
                  )}
                  {resetInfoSent && (
                    <Typography
                      sx={{ paddingTop: 1 }}
                      color="success.main"
                      variant="subtitle2"
                    >
                      {resetInfoSent}
                    </Typography>
                  )}
                  <Controller
                    control={control}
                    name="email"
                    render={({
                      field: { onChange, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        inputRef={ref}
                        name={name}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={
                          error ? formatString(error.message, true) : ''
                        }
                        variant="outlined"
                        size="large"
                        label="Username"
                        placeholder="Enter your username"
                        sx={{
                          width: '400px',
                          pt: '40px',
                          borderRadius: '8px',
                          '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                          },
                          '& .MuiInputBase-input': {
                            color: 'white',
                          },
                          '& .MuiInputBase-root,': {
                            background: 'transparent !important',
                            '&:hover, &:active, &:focus': {
                              background: 'transparent !important',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            fontSize: '18px',
                            color: '#FFFFFF !important',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(255, 255, 255, 0.5) !important',
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="password"
                    render={({
                      field: { onChange, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        inputRef={ref}
                        name={name}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={
                          error ? formatString(error.message, true) : ''
                        }
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        size="large"
                        label="Password"
                        placeholder="Enter your password"
                        sx={{
                          width: '100%',
                          pt: '10px',
                          borderRadius: '8px',
                          '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                          },
                          '& .MuiInputBase-input': {
                            '&:focus, &:active, &: hover': {
                              borderColor: '#FFFFFF !important',
                              color: 'white',
                            },
                          },
                          '& .MuiInputBase-input[type="password"]': {
                            color: 'white',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(255, 255, 255, 0.5) !important',
                          },
                          '& .MuiInputBase-root,': {
                            background: 'transparent !important',
                            '&:hover, &:active, &:focus': {
                              background: 'transparent !important',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            fontSize: '18px',
                            color: '#FFFFFF',
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sxc={{
                                  color: '#FFFFFF',
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Stack alignItems="flex-end">
                    <Button
                      href="/forgot-password"
                      size="medium"
                      sx={{
                        color: '#FFFFFF',
                        pr: '2px',
                        '&:hover': {
                          color: '#EEEEEE !important',
                        },
                      }}
                    >
                      Forgot password?
                    </Button>
                  </Stack>
                  <Button
                    variant="contained"
                    size="large"
                    disabled={showSpinner}
                    type="submit"
                    sx={{
                      backgroundColor: '#16C7A2',
                      width: '100%',
                      marginTop: '30px !important',
                      '&:hover': {
                        background: 'rgba(22, 199, 162, 0.8)',
                      },
                    }}
                  >
                    {!showSpinner ? 'LOGIN' : <CircularProgress size={16} />}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </form>
    </LoginLayoutV2>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values) => {
    dispatch(A.showSppiner())
    const loginStatus = dispatch(A.loginUserOnServer(values))
    dispatch(A.addLoginUserDetail(values))
    return loginStatus
  },
})

const mapStateToProps = (state) => ({
  showSpinner: state.application.showSpinner,
  loginError: state.user.loginError,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthPage),
)
