import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import InformationIcon from '../InformationIcon'
import NeedLinearProgress from '../NeedLinearProgress'
import TopicsContainer from './styles'

const TopicsCard = (props) => {
  const { productType, subProductType } = useParams()
  const {
    catInsight,
    productFormat,
    projectid,
    productid,
    urllens,
    needid,
    keyword,
    maxHeight,
    languageSelection,
    topics,
    email,
    country,
    category,
    type,
    disableTopicsPointerEvents,
  } = props
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        maxHeight: `${maxHeight}px`,
        ...(props.expand ? { maxHeight: 'fit-content' } : {}),
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography variant="h3" display="inline">
          Topics
        </Typography>
        <Box sx={{ ml: 1 }}>
          <InformationIcon tooltipText="The different topics falling under this particular trend" />
        </Box>
      </Box>

      <TopicsContainer
        sx={
          props.expand
            ? { height: 'fit-content !important', overflowY: 'auto !important' }
            : {}
        }
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {languageSelection && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography mr={1} width="100%" variant="subtitle2">
                Display Language:
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Select fullWidth defaultValue="English">
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Spanish">Spanish</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        <Box>
          {topics
            .sort(
              (a, b) =>
                (parseFloat(b.percentage_score_current) || 0) -
                (parseFloat(a.percentage_score_current) || 0),
            )
            .map((el) =>
              el.percentage_score_current &&
              parseFloat(el.percentage_score_current).toFixed(2) > 0 ? (
                <NeedLinearProgress
                  disableTopicsPointerEvents={disableTopicsPointerEvents}
                  title={el.name}
                  value={parseFloat(el.percentage_score_current) || 0}
                  email={email}
                  country={country}
                  category={category}
                  heading="Topic"
                  displayTitle="Sub_Consumer_Insights"
                  link={
                    catInsight
                      ? `/mui/details/${projectid}/category/${needid}/topic/${el.id}`
                      : productFormat
                      ? `/mui/details/productFormat/${projectid}/${urllens}/${productid}/${productType}/${encodeURIComponent(
                          keyword,
                        )}/subFormat/${encodeURIComponent(el.name)}`
                      : `/mui/details/${projectid}/${urllens}/${productid}/need/${needid}/topic/${el.id}`
                  }
                  type={type}
                />
              ) : null,
            )}
        </Box>
      </TopicsContainer>
    </Paper>
  )
}

TopicsCard.defaultProps = {
  maxHeight: 290,
  languageSelection: false,
}

TopicsCard.propTypes = {
  maxHeight: PropTypes.number,
  languageSelection: PropTypes.bool,
}

export default TopicsCard
