import { advancedTrendAnalysis } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import ProductDetailResponse from '../../services/networking/Models/ProductDetailResponse'
import AdvancedTrendResponse from '../../services/networking/Models/AdvancedTrendResponse'

export const fetchAdvancedTrendAnalysisList = (
  lens = 'Ingredient',
  maturityPhases = [],
  countries = [],
  category = '',
  subCategory,
  cagr4Year,
  twoYearCAGR,
  analysisType,
  cancelToken,
) => {
  return (dispatch) => {
    dispatch(fetchATAListStart())
    let requestURI = ''
    requestURI += countries.reduce(
      (acc, curr) => `${acc}country=${encodeURIComponent(curr)}&`,
      '',
    )
    requestURI += maturityPhases.reduce(
      (acc, curr) => `${acc}trend_type=${curr.toUpperCase()}&`,
      '',
    )
    requestURI += cagr4Year ? `${cagr4Year}&` : ''
    requestURI += twoYearCAGR ? `${twoYearCAGR}&` : ''
    // requestURI += consumerNeeds.reduce(
    //   (acc, curr) => `${acc}consumer_need_category=${curr}&`,
    //   ''
    // )
    requestURI +=
      analysisType && twoYearCAGR && cagr4Year
        ? `analysis_type=${analysisType.toUpperCase()}&`
        : ''
    requestURI += subCategory
      ? `subcategory=${encodeURIComponent(subCategory)}&`
      : ''
    const request = NetworkUtil.advancedTrendAnalysis(
      lens,
      encodeURIComponent(category),
      requestURI,
    )
    return axios
      .get(request, cancelToken)
      .then((response) => {
        dispatch(
          fetchATAListSuccess(new AdvancedTrendResponse(response.data.data)),
        )
        return true
      })
      .catch((error) => {
        dispatch(fetchATAListFailed(error))
        return true
      })
  }
}

export const fetchATAListSuccess = (advancedTrend) => {
  return {
    type:
      advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_SUCCESS],
    advancedTrend,
  }
}

const fetchATAListFailed = (error) => {
  return {
    type:
      advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_FAILED],
    error: error,
  }
}

const fetchATAListStart = () => {
  return {
    type:
      advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_START],
  }
}

export const getSubcategoriesList = (category, countries = [], lens) => {
  return (dispatch) => {
    const request = NetworkUtil.getSubcategories(
      encodeURIComponent(category),
      countries.reduce(
        (acc, curr) => `${acc}&country=${encodeURIComponent(curr)}`,
        '',
      ),
      lens,
    )
    return axios
      .get(request)
      .then(({ data }) => {
        dispatch(fetchSubcategoriesListSuccess(data.data.subCategories))
        return true
      })
      .catch((err) => {
        console.log(err, 'err fetch subcat')
        dispatch(fetchSubcategoriesListFailed(err))
        return true
      })
  }
}

export const fetchSubcategoriesListSuccess = (subCategories) => ({
  type:
    advancedTrendAnalysis.type[
      advancedTrendAnalysis.ATA_FETCH_SUB_CATEGORIES_SUCCESS
    ],
  subCategories,
})

export const fetchSubcategoriesListFailed = (error) => ({
  type:
    advancedTrendAnalysis.type[
      advancedTrendAnalysis.ATA_FETCH_SUB_CATEGORIES_FAILED
    ],
  error,
})

export const resetATAComparisonList = () => {
  return {
    type:
      advancedTrendAnalysis.type[
        advancedTrendAnalysis.ATA_RESET_COMPARISON_LIST
      ],
  }
}

export const fetchProductDetailsForAdvancedTrend = (
  trend,
  lensSelected = 'Ingredient',
  fetchCN,
  subCategory,
  cancelToken,
) => {
  const { trendID, trendName } = trend
  return async (dispatch) => {
    let request, cnRequest, pairingRequest, relatedIngProRequest
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.formSearchRequestForProductFilterDetailForCommonTrend(
          trendID,
          !!fetchCN.length,
          true,
        )
        cnRequest = (needID) =>
          NetworkUtil.fetchProductFilterNeedDetails(null, needID)
        break
      case 'Theme':
        request = NetworkUtil.formSearchRequestForThemeFilterDetailForCommonTrend(
          trendID,
          !!fetchCN.length,
        )
        cnRequest = (needID) =>
          NetworkUtil.fetchThemeFilterNeedDetails(null, needID)
        relatedIngProRequest = NetworkUtil.fetchRelatedTrendsForThemes(trendID)
        const limit = 3
        const sortBy = 'rank'
        relatedIngProRequest += `?limit=${limit}&sort_by=${sortBy}`
        break
      case 'Ingredient':
        request = NetworkUtil.formSearchRequestForProductForCommonTrend(
          trendID,
          !!fetchCN.length,
          false,
          subCategory,
        )
        cnRequest = (needID) =>
          NetworkUtil.fetchProductNeedDetails(null, needID)
        pairingRequest = NetworkUtil.fetchCountrySpecificPairingDetails(trendID)
        break
      default:
        return
    }
    try {
      // Fetch ingredient details and then fetch SCN
      const response = await axios.get(request, cancelToken)
      response.data.category_data = fetchCN
        .reduce((acc, cn) => {
          const matchedCN = response.data.category_data.find(
            ({ category }) => category === cn,
          )
          return matchedCN ? [...acc, matchedCN] : acc
        }, [])
        .map(({ needs, ...rest }) => ({
          ...rest,
          needs: needs
            .sort((a, b) => b.percentage_value - a.percentage_value)
            .slice(0, 3),
        }))
      const responseObject = new ProductDetailResponse(response.data)
      const { country, category } = responseObject
      const cnRequestsList = []
      fetchCN.forEach((cn) => {
        const matchedCN = responseObject.category_data.find(
          ({ category }) => category === cn,
        )
        if (matchedCN) {
          matchedCN.needs.forEach(({ id: needID }) => {
            // Fetch SCN
            cnRequestsList.push(axios.get(cnRequest(needID), cancelToken))
          })
        }
      })
      // Fetch pairing data
      const pairingResponse =
        pairingRequest &&
        (await axios.post(pairingRequest, {
          trend: trendName,
          country: [country],
          category,
        }))
      // Fetch top three related Ingredient and Related Product
      const relatedIngProResponse =
        relatedIngProRequest && (await axios.get(relatedIngProRequest))

      // Wait for fetch of SCN to complete
      const cnResponses = await Promise.all(cnRequestsList)
      fetchCN.forEach((cn) => {
        const matchedCN = responseObject.category_data.find(
          ({ category }) => category === cn,
        )
        if (matchedCN) {
          matchedCN.scn = {}
          cnResponses
            .filter(({ data }) => {
              return data.need_category === cn
            })
            .forEach(({ data: { keyword, relatedTerms } }) => {
              matchedCN.scn[keyword] = relatedTerms
                .sort(
                  (a, b) =>
                    b.percentage_score_current - a.percentage_score_current,
                )
                .slice(0, 3)
            })
        }
      })
      responseObject.pairing =
        (pairingResponse &&
          pairingResponse.data &&
          pairingResponse.data.data &&
          pairingResponse.data.data.map(
            ({
              name,
              two_year_cagr: twoYearCAGR,
              probability_percentage: strength,
            }) => ({
              name,
              strength,
              twoYearCAGR,
            }),
          )) ||
        []

      responseObject.relatedIngProResponse =
        relatedIngProResponse?.data?.data || {}

      dispatch(fetchATAProductDetailsSuccess(responseObject))
    } catch (error) {
      dispatch(fetchATAProductDetailsfailed(error))
    }
  }
}

const fetchATAProductDetailsSuccess = (advancedTrend) => {
  return {
    type:
      advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_CMP_LIST_SUCCESS],
    advancedTrend,
  }
}

const fetchATAProductDetailsfailed = (error) => {
  return {
    type: advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_CMP_LIST_FAILED],
    error: error,
  }
}

export const setCurrentTrend = (currentTrend) => {
  return {
    type:
      advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_SET_CURRENT_TREND],
    currentTrend,
  }
}
