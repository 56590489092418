import { AdvancedSearch } from './actionTypes'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/axios'
import { saveFilter } from './chartActions'
import { addRequest, doneRequest } from './downloadSections'

export const fetchAllTrends = (projectId, image, subCategory) => {
  return (dispatch) => {
    const requestFrom = {
      name: 'fetchAllTrends',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    const url = NetworkUtil.getAllTrends(projectId, image, subCategory)
    return axios
      .get(url)
      .then((res) => {
        dispatch(fetchAllTrendsSuccess(res.data))
        // eslint-disable-next-line
        const ingredient_filters = (res.data.ingredient_sub_category || []).map(
          (s) => {
            s.displayValue = s.name
            s.value = s.name
            return s
          },
        )
        // eslint-disable-next-line
        const theme_filters = (res.data.theme_sub_category || []).map((s) => {
          s.displayValue = s.name
          s.value = s.name
          return s
        })
        // eslint-disable-next-line
        const product_filters = (res.data.product_sub_category || []).map(
          (s) => {
            s.displayValue = s.name
            s.value = s.name
            return s
          },
        )
        dispatch(
          saveFilter({
            allFilter: ingredient_filters,
            allThemeFilter: theme_filters,
            allProductFilter: product_filters,

            filter: ingredient_filters.filter((x) => x.name === 'All'),
            theme_filter: theme_filters.filter((x) => x.name === 'All'),
            product_filter: product_filters.filter((x) => x.name === 'All'),
          }),
        )
        dispatch(doneRequest(requestFrom))
        return true
      })
      .catch((err) => {
        dispatch(fetchAllTrendsFailed())
        dispatch(doneRequest(requestFrom))
        return false
      })
  }
}

const fetchAllTrendsSuccess = (data) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.FETCH_ALL_TRENDS_SUCCESS],
    allTrends: data,
  }
}

const fetchAllTrendsFailed = (error) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.FETCH_ALL_TRENDS_FAILED],
    error: error,
  }
}

export const fetchFilteredTrends = (requestBody) => {
  return (dispatch) => {
    const requestFrom = {
      name: 'fetchFilteredTrends',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    const trendsList = NetworkUtil.getFilteredTrends()
    axios
      .post(trendsList, requestBody)
      .then((res) => {
        dispatch(fetchFilteredTrendsSuccess(res.data))
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        console.log(err)
        dispatch(fetchFilteredTrendsFailed())
        dispatch(doneRequest(requestFrom))
      })
  }
}

const fetchFilteredTrendsSuccess = (data) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.FETCH_FILTERED_TRENDS_SUCCESS],
    filteredTrends: data,
  }
}

export const resetFilteredTrends = () => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.RESET_FILTERED_TRENDS],
  }
}

const fetchFilteredTrendsFailed = (error) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.FETCH_FILTERED_TRENDS_FAILED],
    error: error,
  }
}

export const saveAdvancedSearch = (projectId, requestBody) => {
  return (dispatch) => {
    const requestFrom = {
      name: 'saveAdvancedSearch',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    const saveAdvvancedSearchUrl = NetworkUtil.saveAdvancedSearch(projectId)
    axios
      .post(saveAdvvancedSearchUrl, requestBody)
      .then((res) => {
        dispatch(saveAdvancedSearchSuccess(res.data))
        dispatch(viewSavedAdvancedSearch(projectId))
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        dispatch(saveAdvancedSearchFailed())
        dispatch(doneRequest(requestFrom))
      })
  }
}

const saveAdvancedSearchSuccess = (data) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.SAVE_ADVANCED_SEARCH_SUCCESS],
    filteredTrends: data,
  }
}

const saveAdvancedSearchFailed = (error) => {
  return {
    type: AdvancedSearch.type[AdvancedSearch.SAVE_ADVANCED_SEARCH_FAILED],
    error: error,
  }
}

export const viewSavedAdvancedSearch = (projectId) => {
  return (dispatch) => {
    const requestFrom = {
      name: 'viewSavedAdvancedSearch',
      requestTime: new Date(),
    }
    const saveAdvvancedSearchUrl = NetworkUtil.saveAdvancedSearch(projectId)
    dispatch(addRequest(requestFrom))
    axios
      .get(saveAdvvancedSearchUrl)
      .then((res) => {
        dispatch({
          type:
            AdvancedSearch.type[AdvancedSearch.VIEW_ADVANCED_SEARCH_SUCCESS],
          advSearchFilters: res.data,
        })
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        dispatch(doneRequest(requestFrom))
        dispatch({
          type: AdvancedSearch.type[AdvancedSearch.VIEW_ADVANCED_SEARCH_FAILED],
          error: err,
        })
      })
  }
}
