import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'

import { Box, Card } from '@mui/material'

import _ from 'lodash'

import InformationIcon from '../../InformationIcon'

import {
  getTextDimensions,
  dataOverlaps,
  signedFormattedNumber,
} from './../../../../store/utility'
import HoverCard from '../../ExploreCategoryDistribution/HoverCard'
import { THEME } from '../../../utils/constants'

function unscaleSingleNum(scaledNum) {
  if (scaledNum > 1) {
    return Math.exp(scaledNum - 1)
  } else if (scaledNum < -1) {
    return -Math.exp(-scaledNum - 1)
  } else {
    return scaledNum
  }
}

let appliedZoom = []
const TWO_YEAR_CAGR = 2

const graphDataFormatter = (data, xScale, yScale) => {
  return data?.length
    ? data.map(({ xScaleData, yScaleData, ...rest }) => {
        return {
          ...rest,
          x: xScale(xScaleData),
          y: yScale(yScaleData),
        }
      })
    : []
}

const getNextPowerOfTenTickValue = (number) => {
  if (number > -10 && number <= 1) {
    return 0
  }
  const absNum = Math.abs(number)
  const log = Math.log10(absNum)
  const roundedLog = number > 0 ? Math.ceil(log) : Math.floor(log)
  const result = Math.pow(10, roundedLog)
  return number < 0 && result !== 1 ? -result : result
}
const getPrevPowerOfTenTickValue = (number) => {
  if (number < 10 && number >= -1) {
    return 0
  }
  const absNum = Math.abs(number)
  const log = Math.log10(absNum)
  const roundedLog = number > 0 ? Math.floor(log) : Math.ceil(log)
  const result = Math.pow(10, roundedLog)
  return number < 0 ? -result : result
}

const getFactorChange = (i) => {
  if (i > 0) {
    return i * 10
  } else if (i < 0) {
    if (i / 10 !== -1) {
      return i / 10
    }
    return 0
  } else {
    return i + 10
  }
}

const generatePowOfTenNumList = (start, end) => {
  const arr = []
  const startPow = getNextPowerOfTenTickValue(start)
  const endPow = getPrevPowerOfTenTickValue(end)

  for (let i = startPow; i <= endPow; i = getFactorChange(i)) {
    arr.push(i)
  }
  return arr
}

const generateLogNumList = (start, end, base) => {
  const arr = []
  let startPow = Math.floor(
    ((start / Math.abs(start)) * Math.log(Math.abs(start))) / Math.log(base),
  )
  let endPow = Math.ceil(
    ((end / Math.abs(end)) * Math.log(Math.abs(end))) / Math.log(base),
  )
  let mulFactor = 1
  if (startPow > endPow) {
    ;[startPow, endPow] = [endPow, startPow]
    mulFactor = -1
  }
  for (let i = startPow; i <= endPow; i += mulFactor) {
    if (i === 0) {
      arr.push(0)
    } else {
      let jStart = 1
      let jEnd = 10
      if (i < 0) {
        ;[jStart, jEnd] = [-jEnd, -jStart]
      }
      for (let j = jStart; j < jEnd; j += 1) {
        const num = j * base ** Math.abs(i)
        if (start <= num && end >= num) {
          arr.push(num)
        }
      }
    }
  }
  return arr
}

const getTickValuesWithMedian = (
  posFunc,
  fontSize,
  fontStyle,
  axisName,
  zoomedIn,
  medianVal,
  generatedArr,
) => {
  const ticksArr = [...generatedArr, medianVal].sort((a, b) => a - b)
  const medianIndex = ticksArr.indexOf(medianVal)
  if (axisName === 'x' && !zoomedIn) {
    const getTextSize = (text) =>
      getTextDimensions(getAxesLabel(text), {
        size: fontSize,
        family: fontStyle,
      })
    const prevPoint = ticksArr[medianIndex - 1]
    const nextPoint = ticksArr[medianIndex + 1]
    const { width: prevW } = getTextSize(prevPoint)
    const padding = 5
    let itemsToRemove = []
    if (posFunc(prevPoint) + prevW + padding > posFunc(medianVal)) {
      itemsToRemove = [...itemsToRemove, prevPoint]
    } else if (posFunc(medianVal) + prevW + padding > posFunc(nextPoint)) {
      itemsToRemove = [...itemsToRemove, nextPoint]
    }
    return ticksArr.filter((value) => !itemsToRemove.includes(value))
  }
  const itemsToRemove = [ticksArr[medianIndex - 1], ticksArr[medianIndex + 1]]
  return ticksArr.filter((value) => !itemsToRemove.includes(value))
}

const generateTicks = (
  arr,
  posFunc,
  fontSize,
  fontStyle,
  crossAxis = false,
) => {
  const getTextSize = (text) =>
    getTextDimensions(getAxesLabel(text), { size: fontSize, family: fontStyle })

  const generatedArr = arr.reduce((acc, curr, i) => {
    if (acc.length > 0) {
      const prevPoint = acc[acc.length - 1]
      const { width: prevW } = getTextSize(prevPoint)
      const { actualBoundingBoxAscent, actualBoundingBoxDescent } = getTextSize(
        curr,
      )
      const currHeight = actualBoundingBoxAscent - actualBoundingBoxDescent
      const padding = 5
      if (crossAxis) {
        if (posFunc(prevPoint) - padding >= posFunc(curr) + currHeight) {
          return [...acc, curr]
        } else {
          return acc
        }
      } else {
        if (posFunc(prevPoint) + prevW + padding <= posFunc(curr)) {
          return [...acc, curr]
        } else {
          return acc
        }
      }
    } else {
      return [curr]
    }
  }, [])
  return generatedArr
}

const generateAllTicks = (
  axisName,
  zoomedIn,
  medianVal = null,
  arr,
  posFunc,
  fontSize,
  fontStyle,
  crossAxis = false,
) => {
  const generatedArr = generateTicks(
    arr,
    posFunc,
    fontSize,
    fontStyle,
    crossAxis,
  )
  if (
    !_.isNil(medianVal) &&
    !generatedArr.filter((item) => item === medianVal).length
  ) {
    return getTickValuesWithMedian(
      posFunc,
      fontSize,
      fontStyle,
      axisName,
      zoomedIn,
      medianVal,
      generatedArr,
    )
  }
  return generatedArr
}

const getAxesLabel = (num) => {
  return num < 1e3 ? Number(Number(num).toFixed(2)) : signedFormattedNumber(num)
}

const ScatteredGraphUI = ({
  graphTrendData,
  phaseColor,
  lensSelected,
  maturityPhase,
  graphHelperData,
  scatteredPlotTooltip,
  handleHover,
  isBrushUsed,
  setIsBrushUsed,
  viewDetailsClicked,
  medianXVal,
  medianYVal,
  demographyComponent,
  resetZoomOnGraphDataUpdate = true,
  resetBtn,
  setResetBtn,
  resetBtnId,
}) => {
  const [tooltipShow, setToolTipShow] = useState(false)

  const [data, setData] = useState([])
  const svgRef = useRef()
  const timeOutRef = useRef()

  const handleTooltipShow = (data) => {
    setToolTipShow(data)
  }

  const hoverCardSx = {
    imgSx: {
      width: '100%',
      height: '130px',
      p: lensSelected !== THEME ? 1 : 0,
      borderRadius: '20px',
      transform: lensSelected === THEME && 'scale(0.85)',
      objectFit: lensSelected === THEME ? 'contain' : 'cover',
      background: ({ palette }) => lensSelected === THEME && palette.white,
    },
    typographySx: {},
    cardContentSx: { color: ({ palette }) => palette.white, width: '100%' },
    nameSx: { color: ({ palette }) => palette.white, fontWeight: 600 },
    labelSx: { color: ({ palette }) => palette.white },
    valSx: { color: ({ palette }) => palette.white },
  }

  const scatteredPlotTooltipNewVis = (graphPointData) => {
    const {
      id,
      image_url: image,
      name,
      four_year_cagr: fourYearCagr,
      four_year_data_points: fourYearEngagementScore,
    } = graphPointData
    const { selectedCagr } = graphHelperData
    const tooltip = {
      id,
      image,
      name,
      firstLabel:
        selectedCagr === TWO_YEAR_CAGR ? '2 Year CAGR' : '4 Year CAGR:',
      firstValue:
        selectedCagr === TWO_YEAR_CAGR
          ? graphPointData?.two_year_cagr
          : graphPointData?.four_year_cagr,
      secondLabel:
        selectedCagr === TWO_YEAR_CAGR
          ? '2 Year Engagement'
          : '4 Year Engagement:',
      secondValue:
        selectedCagr === TWO_YEAR_CAGR
          ? graphPointData?.two_year_data_points
          : graphPointData?.four_year_data_points,
    }
    return (
      <HoverCard
        data={tooltip}
        viewDetailsHandle={() => viewDetailsClicked(tooltip)}
        hoverCardSx={hoverCardSx}
      />
    )
  }
  const handleGraphData = () => {
    if (graphTrendData) {
      if (JSON.stringify(graphTrendData) !== JSON.stringify(data)) {
        setData(graphTrendData)
        setResetBtn(false)
      }
    } else {
      setData([])
    }
  }

  useEffect(() => {
    if (resetZoomOnGraphDataUpdate) {
      appliedZoom = []
    }
    handleGraphData()
  }, [graphTrendData])

  useEffect(() => {
    return () => {
      appliedZoom = []
    }
  }, [])

  useEffect(() => {
    const height = graphHelperData?.newVisualisation ? 500 : 425
    const paddingH = 50
    const paddingV = 10
    const titleSpace = 40
    const radius = 6
    const fontSize = 14

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', '#ffffff')
    // .style('overflow', 'visible')
    // .style('margin-left', '20px')

    const width = svg.node().getBoundingClientRect().width

    const drawingRange = {
      xAxis: [paddingH + 20, width - 20],
      x1Axis: [paddingH + 20, width],
      yAxis: [height - paddingV - titleSpace, paddingV + titleSpace],
      y1Axis: [height - paddingV + 15 - titleSpace, paddingV + titleSpace],
    }

    const labelOffsetFromCirc = {
      offset: {
        x: radius,
        y: 5,
      },
      height: 18, // Pick height of label from browser and add error margin
    }

    const overlapDrawingRange = {
      xAxis: drawingRange.xAxis,
      yAxis: [...drawingRange.yAxis].reverse(),
    }

    const overlapResolver = (
      data,
      labelProps,
      drawingRange,
      circRadius,
      fontSize,
    ) => {
      const labelArray = data.map((trend) => {
        const { x, y, name } = trend
        const textDimensions = getTextDimensions(name, {
          family: 'Roboto',
          size: fontSize,
        })
        const xPos = x + circRadius
        let yPos = labelProps.offset.y + y
        if (
          y < drawingRange.yAxis[0] + circRadius &&
          y > drawingRange.yAxis[0] - circRadius
        ) {
          yPos = drawingRange.yAxis[0] + fontSize - 3
        } else if (
          y > drawingRange.yAxis[1] - circRadius &&
          y < drawingRange.yAxis[1] + circRadius
        ) {
          yPos = drawingRange.yAxis[1] - textDimensions.fontBoundingBoxDescent // need to offset by height of rendered text
        }
        const textWidth = textDimensions.width
        return {
          ...trend,
          x: xPos,
          y: yPos,
          name,
          width: textWidth,
          height: labelProps.height,
          dir: {
            left: true,
            right: true,
          },
        }
      })

      for (let i = 0; i < data.length; i++) {
        const { x, y, width, height } = labelArray[i]
        const leftPos = data[i].x - circRadius - width
        for (let j = 0; j < data.length; j++) {
          if (!labelArray[i].dir.left && !labelArray[i].dir.right) {
            break
          }
          if (i !== j) {
            for (let dir = labelArray[i].dir.left ? 0 : 1; dir < 2; dir++) {
              // dir 0 is left and 1 is right
              const { left } = labelArray[i].dir
              const iRectX1 = dir === 0 ? leftPos : x
              if (iRectX1 < drawingRange.xAxis[0]) {
                labelArray[i].dir.left = false
              }

              const iRectX2 = iRectX1 + width
              const iRectY2 = y + 3 // Add 3 to give extra space below
              const iRectY1 = iRectY2 - height

              // Check overlap of left label with the datapoint and label,
              // if label is already applied to its
              let jRectX1 = data[j].x - circRadius
              let jRectX2 = jRectX1 + 2 * circRadius
              let jRectY2 = labelArray[j].y + circRadius
              let jRectY1 = jRectY2 - 2 * circRadius
              if (
                (labelArray[j].dir.left || labelArray[j].dir.right) &&
                i > j
              ) {
                const correction = 2 * circRadius
                jRectX1 =
                  labelArray[j].x - (labelArray[j].dir.left ? 0 : correction)
                jRectX2 = jRectX1 + correction + labelArray[j].width
                jRectY2 = labelArray[j].y + 3 // Add 3 to give extra space below
                jRectY1 = jRectY2 - height
              }

              const overlaps = dataOverlaps(
                {
                  x: [iRectX1, iRectX2],
                  y: [iRectY1, iRectY2],
                },
                {
                  x: [jRectX1, jRectX2],
                  y: [jRectY1, jRectY2],
                },
              )

              if (overlaps) {
                if (dir === 0) {
                  labelArray[i].dir.left = false
                } else {
                  labelArray[i].dir.right = false
                }
              }
            }
          }
        }

        // Check edge cases
        if (graphHelperData.newVisualisation) {
          if (xScale(medianXVal) > leftPos && xScale(medianXVal) < x) {
            labelArray[i].dir.left = false
          }
          if (xScale(medianXVal) > x && xScale(medianXVal) < x + width) {
            labelArray[i].dir.right = false
          }
          if (
            (yScale(medianYVal) - 10 < y &&
              yScale(medianYVal) - 10 > y + height) ||
            (yScale(medianYVal) + 10 > y &&
              yScale(medianYVal) + 10 < y + height)
          ) {
            labelArray[i].dir.left = false
            labelArray[i].dir.right = false
          }
        }

        if (
          leftPos < drawingRange.xAxis[0] ||
          data[i].x - circRadius >= drawingRange.xAxis[1]
        ) {
          labelArray[i].dir.left = false
        }
        if (x + width > drawingRange.xAxis[1] || x < drawingRange.xAxis[0]) {
          labelArray[i].dir.right = false
        }
        const { left } = labelArray[i].dir
        if (left) {
          labelArray[i].x = leftPos
        }
      }
      return labelArray
    }

    svg.selectAll('g').remove()
    svg
      .selectAll(function() {
        return this.childNodes
      })
      .remove()

    //  Bottom X Scale
    const xScale = d3[graphHelperData.scaleX]()
      .domain(graphHelperData.domain)
      .range(drawingRange.xAxis)

    //  Upper X Scale
    const x1Scale = d3[graphHelperData.scaleX]()
      .domain(graphHelperData.domain)
      .range(drawingRange.x1Axis)

    /* const x = svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale)); */

    // Setup y-scale
    const xTickVals = generatePowOfTenNumList(...graphHelperData.domain)
    const yTickVals = generateLogNumList(...graphHelperData.yDomain, 10)

    //  Left Y scale
    const yScale = d3[graphHelperData.scaleY]()
      .domain(graphHelperData.yDomain)
      .range(drawingRange.yAxis)

    //  Right Y Scale
    const y1Scale = d3[graphHelperData.scaleY]()
      .domain(graphHelperData.yDomain)
      .range(drawingRange.yAxis)

    // Bottom X Axis
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(graphHelperData.xTicks || 20)
      .tickValues(
        graphHelperData.customXTicks ||
          generateAllTicks(
            'x',
            false,
            medianXVal,
            xTickVals,
            xScale,
            10,
            'sans-serif',
          ),
      )
      .tickFormat((x) =>
        graphHelperData.newVisualisation
          ? `${Math.floor(unscaleSingleNum(x))}%`
          : getAxesLabel(x),
      )

    // Upper X Axis
    const x1Axis = d3.axisTop(x1Scale).ticks(0)

    //  Left Y Axis
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(graphHelperData.yTicks || 20)
      .tickPadding([11])
      .tickValues(
        graphHelperData.customYTicks ||
          generateAllTicks(
            'y',
            false,
            medianYVal,
            yTickVals,
            yScale,
            10,
            'sans-serif',
            true,
          ),
      )
      .tickFormat((x) => {
        return getAxesLabel(
          graphHelperData.newVisualisation ? unscaleSingleNum(x) : x,
        )
      })

    //  Right Y Axis
    const y1Axis = d3.axisRight(y1Scale).ticks(0)

    const brushLayer = svg.append('g')
    const circlesLayer = svg.append('g')

    //  Grid Addition
    const grid = (g, zoom = false) => {
      //  Remove previous grid
      svg.selectAll('.grid--x').remove()
      svg.selectAll('.grid--y').remove()

      return g
        .attr('stroke', 'currentColor')
        .attr('stroke-opacity', 0.1)
        .call((g) =>
          g
            .append('g')
            .classed('grid--x', true)
            .selectAll('line')
            .data(
              zoom
                ? generateAllTicks(
                    'x',
                    true,
                    medianXVal,
                    xScale.ticks(),
                    xScale,
                    10,
                    'sans-serif',
                  )
                : graphHelperData.newVisualisation
                ? graphHelperData.customXTicks
                : generateAllTicks(
                    'x',
                    false,
                    medianXVal,
                    xTickVals,
                    xScale,
                    10,
                    'sans-serif',
                  ),
            )
            .join('line')
            .attr('x1', (d) => xScale(d))
            .attr('x2', (d) => xScale(d))
            .attr('y1', yScale(graphHelperData.yDomain[0]))
            .attr('y2', yScale(graphHelperData.yDomain[1])),
        )
        .call((g) =>
          g
            .append('g')
            .classed('grid--y', true)
            .selectAll('line')
            .data(
              zoom
                ? generateAllTicks(
                    'y',
                    true,
                    medianYVal,
                    yScale.ticks(),
                    yScale,
                    10,
                    'sans-serif',
                    true,
                  )
                : graphHelperData.newVisualisation
                ? graphHelperData.customYTicks
                : generateAllTicks(
                    'y',
                    false,
                    medianYVal,
                    yTickVals,
                    yScale,
                    10,
                    'sans-serif',
                    true,
                  ),
            )
            .join('line')
            .attr('y1', (d) => yScale(d))
            .attr('y2', (d) => yScale(d))
            .attr('x1', xScale(graphHelperData.domain[0]))
            .attr('x2', xScale(graphHelperData.domain[1])),
        )
        .attr('clip-path', 'url(#clip)')
    }
    // svg.append('g').call(xAxis).attr('transform', `translate(0,${height})`)
    // svg.append('g').call(yAxis)
    svg.append('g').call((g) => grid(g))

    // Clip-path for drawing range
    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', drawingRange.xAxis[1] - drawingRange.xAxis[0])
      .attr('height', drawingRange.yAxis[0] - drawingRange.yAxis[1])
      .attr(
        'transform',
        `translate(${drawingRange.xAxis[0]},${drawingRange.yAxis[1]})`,
      )

    svg.selectAll('circle').remove()
    svg.selectAll('text').remove()
    svg.selectAll('.axis--dashed').remove()

    //  Adding X axis Label
    svg
      .append('text')
      .attr('x', width / 2 - 50)
      .attr('y', height)
      .text(graphHelperData.xAxisText)
      .style('fill', '#525252')

    //  Adding Y Axis Label
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 20)
      .attr('x', -height / 2 - 50)
      .text(graphHelperData.yAxisText)
      .style('fill', '#525252')

    // // Adding Niche Label
    // graphHelperData.newVisualisation &&
    //   svg
    //     .append('text')
    //     .attr('class', 'niche-text')
    //     .attr('y', height - 20)
    //     .attr('x', xScale(medianXVal) / 2 < 100 ? 0 : xScale(medianXVal) / 2)
    //     .text('Niche')
    //     .style('fill', phaseColor.Niche)

    graphHelperData.newVisualisation &&
      svg
        .append('text')
        .attr('class', 'median-text')
        .attr('y', yScale(medianYVal) - 10)
        .attr('x', 30)
        .text('Median')
        .attr('font-size', '12px')

    // // Adding Safe bet Label
    // graphHelperData.newVisualisation &&
    //   svg
    //     .append('text')
    //     .attr('class', 'Safe-bet-text')
    //     .attr('y', 30)
    //     .attr('x', xScale(medianXVal) / 2 < 100 ? 0 : xScale(medianXVal) / 2)
    //     .text('Safe Bet')
    //     .style('fill', phaseColor['Safe bet'])

    // // Adding Upcoming Label
    // graphHelperData.newVisualisation &&
    //   svg
    //     .append('text')
    //     .attr('class', 'upcoming-text')
    //     .attr('y', height - 20)
    //     .attr(
    //       'x',
    //       xScale(medianXVal) + (width - xScale(medianXVal)) / 2 > width - 100
    //         ? width - 100
    //         : xScale(medianXVal) + (width - xScale(medianXVal)) / 2,
    //     )
    //     .text('Upcoming')
    //     .style('fill', phaseColor.Upcoming)

    // // Adding Opportunity Label
    // graphHelperData.newVisualisation &&
    //   svg
    //     .append('text')
    //     .attr('class', 'opportunity-text')
    //     .attr('y', 30)
    //     .attr(
    //       'x',
    //       xScale(medianXVal) + (width - xScale(medianXVal)) / 2 > width - 100
    //         ? width - 100
    //         : xScale(medianXVal) + (width - xScale(medianXVal)) / 2,
    //     )
    //     .text('Opportunity')
    //     .style('fill', phaseColor.Opportunity)

    // svg.append('g').classed('pairing-data-points',true).attr("clip-path", "url(#clip)"),

    const medianCircle = (x, y) => {
      if (_.isNil(x) || _.isNil(y) || graphHelperData.medianCircleDisabled) {
        return
      }
      const circleSVG = svg
        .append('circle')
        .attr('r', 8) // Radius size, could map to another dimension
        .attr('class', 'medianCircle')
        .attr('fill', 'none')
        .style('stroke', '#0D86D4')
        .style('stroke-width', 2)

      circleSVG.attr('cx', x)
      circleSVG.attr('cy', y - 10)

      return circleSVG
    }
    const medianYCircle = (x, y) => {
      if (_.isNil(x) || _.isNil(y) || graphHelperData.medianCircleDisabled) {
        return
      }
      const circleSVG = svg
        .append('circle')
        .attr('r', 8) // Radius size, could map to another dimension
        .attr('class', 'medianYCircle')
        .attr('fill', 'none')
        .style('stroke', '#0D86D4')
        .style('stroke-width', 2)

      circleSVG.attr('cx', x + 10)
      circleSVG.attr('cy', y)

      return circleSVG
    }

    const drawMedianYLineOnZoom = (
      svg,
      startingYPoint,
      medianYVal,
      endYPoint,
      drawingRange,
    ) => {
      if (!_.isNil(medianYVal)) {
        if (startingYPoint > medianYVal) {
          svg.call(() => medianYRect(drawingRange.yAxis[0]))
          svg.call(() => medianYLine(drawingRange.yAxis[0]))
          svg.call(() =>
            medianYCircle(drawingRange.xAxis[1], drawingRange.yAxis[0]),
          )
        } else if (endYPoint < medianYVal) {
          svg.call(() => medianYRect(drawingRange.yAxis[1]))
          svg.call(() => medianYLine(drawingRange.yAxis[1]))
          svg.call(() =>
            medianYCircle(drawingRange.xAxis[1], drawingRange.yAxis[1]),
          )
        } else {
          svg.call(() => medianLineWithScale(medianYRect, yScale, medianYVal))
          svg.call(() => medianLineWithScale(medianYLine, yScale, medianYVal))
          if (!_.isNil(medianYVal)) {
            svg.call(() =>
              medianYCircle(drawingRange.xAxis[1], yScale(medianYVal)),
            )
          }
        }
      }
    }

    const drawMedianLinesOnZoom = (
      svg,
      medianXVal,
      medianYVal,
      startingXPoint,
      endXPoint,
      startingYPoint,
      endYPoint,
      drawingRange,
    ) => {
      if (!_.isNil(medianXVal)) {
        if (medianXVal < startingXPoint) {
          svg.call(() => medianXRect(drawingRange.xAxis[0]))
          svg.call(() => medianXLine(drawingRange.xAxis[0]))
          drawMedianYLineOnZoom(
            svg,
            startingYPoint,
            medianYVal,
            endYPoint,
            drawingRange,
          )
          svg.call(() =>
            medianCircle(drawingRange.xAxis[0], drawingRange.yAxis[1]),
          )
        } else if (medianXVal > endXPoint) {
          svg.call(() => medianXRect(drawingRange.xAxis[1]))
          svg.call(() => medianXLine(drawingRange.xAxis[1]))
          drawMedianYLineOnZoom(
            svg,
            startingYPoint,
            medianYVal,
            endYPoint,
            drawingRange,
          )
          svg.call(() =>
            medianCircle(drawingRange.xAxis[1], drawingRange.yAxis[1]),
          )
        } else {
          svg.call(() => medianLineWithScale(medianXRect, xScale, medianXVal))
          svg.call(() => medianLineWithScale(medianXLine, xScale, medianXVal))
          if (!_.isNil(medianXVal)) {
            svg.call(() =>
              medianCircle(xScale(medianXVal), drawingRange.yAxis[1]),
            )
          }
          drawMedianYLineOnZoom(
            svg,
            startingYPoint,
            medianYVal,
            endYPoint,
            drawingRange,
          )
        }
      }
    }

    const medianXLine = (x) => {
      svg.selectAll('.axis--Xmedian').remove()
      if (_.isNil(x)) {
        return
      }
      const newSVG = svg
        .append('line')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--Xmedian')
        .style('stroke', graphHelperData.medianDashColor || '#1D3650')
        .style('stroke-width', 2)
        .style('stroke-dasharray', '8,8')
        .attr('stroke-opacity', 0.5)
        .attr('y1', yScale(graphHelperData.yDomain[0]))
        .attr('y2', yScale(graphHelperData.yDomain[1]))

      newSVG.attr('x1', x).attr('x2', x)

      return newSVG
    }

    const medianXRect = (x) => {
      svg.selectAll('.axis--Xmedian-rect').remove()
      if (_.isNil(x) || !graphHelperData.showMedianRectangle) {
        return
      }
      const newSVG = svg
        .append('rect')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--Xmedian-rect')
        .attr('x', x - 5)
        .attr('y', paddingH)
        .attr('width', '10px')
        .attr(
          'height',
          -yScale(graphHelperData.yDomain[1]) +
            yScale(graphHelperData.yDomain[0]),
        )
        .style('fill', '#CDE0F8')
        .style('opacity', 0.5)
        .append('title')
        .text(`Median`)

      return newSVG
    }

    const medianLineWithScale = (fn, scale, medianVal) => {
      if (_.isNil(medianVal)) {
        return
      }
      fn(scale(medianVal))
    }
    const medianYLine = (y) => {
      svg.selectAll('.axis--Ymedian').remove()
      if (_.isNil(y)) {
        return
      }
      const newSVG = svg
        .append('line')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--Ymedian')
        .style('stroke', graphHelperData.medianDashColor || '#1D3650')
        .style('stroke-width', 2)
        .style('stroke-dasharray', '8,8')
        .attr('stroke-opacity', 0.5)
        .attr('x1', xScale(graphHelperData.domain[0]))
        .attr('x2', xScale(graphHelperData.domain[1]))

      newSVG.attr('y1', y).attr('y2', y)

      return newSVG
    }
    const medianYRect = (y) => {
      svg.selectAll('.axis--Ymedian-rect').remove()
      if (_.isNil(y) || !graphHelperData.showMedianRectangle) {
        return
      }
      const newSVG = svg
        .append('rect')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--Ymedian-rect')
        .attr('x', 70) // Distance from left side
        .attr('y', y - 5)
        .attr('height', '10px')
        .attr(
          'width',
          xScale(graphHelperData.domain[1]) - xScale(graphHelperData.domain[0]),
        )
        .style('fill', '#CDE0F8')
        .style('opacity', 0.5)
        .attr('stroke-opacity', 0.5)

      return newSVG
    }

    svg.call(() => medianLineWithScale(medianYRect, yScale, medianYVal))
    svg.call(() => medianLineWithScale(medianXRect, xScale, medianXVal))
    svg.call(() => medianLineWithScale(medianYLine, yScale, medianYVal))
    svg.call(() => medianLineWithScale(medianXLine, xScale, medianXVal))

    if (!_.isNil(medianXVal)) {
      svg.call(() => medianCircle(xScale(medianXVal), drawingRange.yAxis[1]))
    }
    if (!_.isNil(medianYVal)) {
      svg.call(() => medianYCircle(drawingRange.xAxis[1], yScale(medianYVal)))
    }

    // diagonal line
    const diagonalLine = () =>
      svg
        .append('line')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--dashed')
        .style('stroke', '#06B0F1')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '10,3')
        .attr('x1', xScale(0))
        .attr('x2', xScale(100))
        .attr('y1', yScale(0))
        .attr('y2', yScale(100))

    if (!demographyComponent) {
      svg.call(diagonalLine)
    }

    circlesLayer
      .attr('clip-path', 'url(#clip)')
      .selectAll()
      .data(data)
      .enter()
      .append('circle')
      .attr('r', radius) // Radius size, could map to another dimension
      .attr('class', 'dot')
      .style('stroke', 'white')
      .style('stroke-width', 0.5)
      .attr('cx', (d) => xScale(d.xScaleData))
      .attr('cy', (d) => yScale(d.yScaleData))
      .style('fill', (d) => {
        if (graphHelperData.newVisualisation) {
          return phaseColor[d.type]
        }
        return phaseColor[d.classification] || '#387AC4'
      })
      .style('cursor', 'pointer')
      .on('mouseover', (e, index, listCyrcle) => {
        // Calculate the coordinates for the tooltip.
        const cy = listCyrcle[index].getAttribute('cy')
        const cx = listCyrcle[index].getAttribute('cx')
        let top = Math.floor(Number(cy))
        let left = Math.floor(cx) + 30

        const { width: divWidth, height: divHeight } = document
          .getElementById('scattered-graph')
          .getBoundingClientRect()
        if (left + 300 > divWidth) {
          left = left - 310
        }
        if (top + 300 > divHeight) {
          top = top - 110
        }

        // Updating the Tooltip State
        const uptTooltip = e
        uptTooltip.left = left
        uptTooltip.top = top >= 120 ? 120 : top <= 50 ? 50 : top
        uptTooltip.tooltipShow = true
        handleTooltipShow(uptTooltip)
        if (!graphHelperData.newVisualisation) {
          handleHover(uptTooltip)
        }
        clearInterval(timeOutRef.current)
        timeOutRef.current = setTimeout(
          () => {
            handleTooltipShow(false)
          },
          graphHelperData.newVisualisation ? 6000 : 5000,
        )
      })
      .on('click', (e) => {
        viewDetailsClicked(e)
      })

    svg.select('.data-point-names-x').remove()
    svg
      .append('g')
      .classed('data-point-names-x', true)
      .attr('clip-path', 'url(#clip)')
      .selectAll('text')
      .data(
        overlapResolver(
          graphDataFormatter(data, xScale, yScale),
          labelOffsetFromCirc,
          overlapDrawingRange,
          radius,
          fontSize,
        ),
      )
      .enter()
      .append('text')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
      .attr(
        'style',
        `color: black; fill: black; font-size: ${fontSize}px; letter-spacing: 0px;`,
      )
      .style('display', (d) => (d.dir.left || d.dir.right ? 'block' : 'none'))
      .style('cursor', 'pointer')
      .on('click', (d) => {
        viewDetailsClicked(d)
      })
      .text(({ name }) => (graphHelperData.labelDisabled ? '' : name))

    svg
      .append('g')
      .attr('class', 'xAxis axis--x')
      .attr('transform', `translate(0,${drawingRange.yAxis[0]})`)
      .style('color', '#e0e1e3')
      .call(xAxis)

    svg
      .append('g')
      .attr('class', 'xAxis axis--x')
      .attr('transform', `translate(0, 49)`)
      .style('color', '#e0e1e3')
      .call(x1Axis)

    svg
      .append('g')
      .attr('class', 'yAxis axis--y')
      .attr('transform', `translate(${drawingRange.xAxis[0]},0)`)
      .style('color', '#e0e1e3')
      .call(yAxis)

    svg
      .append('g')
      .attr('class', 'yAxis axis--y')
      .attr('transform', `translate(${drawingRange.xAxis[1]},0)`)
      .style('color', '#e0e1e3')
      .call(y1Axis)

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#f6c3d0')
      .attr('stroke-width', 4)

    svg
      .select('.axis--x')
      .selectAll('.tick')
      .each(function(d) {
        if (d === 0 && !graphHelperData.newVisualisation) {
          this.remove()
        }
      })

    const axisY = svg.select('.axis--y')
    const gridsY = svg.select('.axis-grid--y')

    // const xAxisGrid = d3.axisBottom(xAxis).tickSize(-(height - 40) + 100).tickFormat('').ticks(10);
    // const yAxisGrid = d3.axisRight(yAxis).tickSize(-width + 170).tickFormat('').ticks(10);

    let idleTimeout
    const idleDelay = 350

    var idled = () => {
      idleTimeout = null
    }

    const zoomIn = (selectionArea, noAnimate) => {
      if (noAnimate) {
        selectionArea.forEach((area) => {
          xScale.domain(
            [
              Math.max(drawingRange.xAxis[0], area[0][0]),
              Math.min(drawingRange.xAxis[1], area[1][0]),
            ].map(xScale.invert, xScale),
          )

          yScale.domain(
            [
              Math.min(drawingRange.yAxis[0], area[1][1]),
              Math.max(drawingRange.yAxis[1], area[0][1]),
            ].map(yScale.invert, yScale),
          )
        })
      } else {
        xScale.domain(
          [
            Math.max(drawingRange.xAxis[0], selectionArea[0][0]),
            Math.min(drawingRange.xAxis[1], selectionArea[1][0]),
          ].map(xScale.invert, xScale),
        )
        yScale.domain(
          [
            Math.min(drawingRange.yAxis[0], selectionArea[1][1]),
            Math.max(drawingRange.yAxis[1], selectionArea[0][1]),
          ].map(yScale.invert, yScale),
        )
      }
      svg.append('g').call((g) => grid(g, true))
      zoom(true, noAnimate)

      if (!demographyComponent) {
        diagonalLine()
      }

      // Record user made zoom actions
      if (!noAnimate) {
        appliedZoom.push(selectionArea)
      }
    }
    // Brush Zoom
    var brushended = (reset) => {
      var selectionArea = d3.event?.selection
      if (!selectionArea || reset) {
        if (!idleTimeout && !reset) {
          return (idleTimeout = setTimeout(idled, idleDelay))
        }
        xScale.domain(graphHelperData.domain)
        yScale.domain(graphHelperData.yDomain)
        setResetBtn(false)
        zoom()
        svg.append('g').call((g) => grid(g))
        appliedZoom = []
        svg.selectAll('.axis--Ymedian').remove()
        svg.selectAll('.axis--Xmedian').remove()
        svg.selectAll('.axis--Ymedian-rect').remove()
        svg.selectAll('.axis--Xmedian-rect').remove()
        svg.selectAll('.medianCircle').remove()
        svg.selectAll('.medianYCircle').remove()
        svg.call(() => medianLineWithScale(medianXRect, xScale, medianXVal))
        svg.call(() => medianLineWithScale(medianXLine, xScale, medianXVal))
        if (!_.isNil(medianXVal)) {
          svg.call(() =>
            medianCircle(xScale(medianXVal), drawingRange.yAxis[1]),
          )
        }
        svg.call(() => medianLineWithScale(medianYRect, yScale, medianYVal))
        svg.call(() => medianLineWithScale(medianYLine, yScale, medianYVal))
        if (!_.isNil(medianYVal)) {
          svg.call(() =>
            medianYCircle(drawingRange.xAxis[1], yScale(medianYVal)),
          )
        }
      } else {
        // xScale.domain([selectionArea[0][0], selectionArea[1][0]].map(xScale.invert, xScale));
        // yScale.domain([selectionArea[1][1], selectionArea[0][1]].map(yScale.invert, yScale));
        zoomIn(selectionArea)
        svg.select('.brush').call(brush.move, null)
      }
      setIsBrushUsed(false)
      svg.selectAll('.axis--dashed').remove()
      if (!demographyComponent) {
        diagonalLine()
      }

      if (selectionArea) {
        svg.selectAll('.axis--Xmedian').remove()
        svg.selectAll('.axis--Ymedian').remove()
        svg.selectAll('.axis--Xmedian-rect').remove()
        svg.selectAll('.axis--Ymedian-rect').remove()
        svg.selectAll('.medianCircle').remove()
        svg.selectAll('.medianYCircle').remove()
        const startingXPoint = xScale.domain()[0]
        const endXPoint = xScale.domain().reverse()[0]
        const startingYPoint = yScale.domain()[0]
        const endYPoint = yScale.domain().reverse()[0]
        drawMedianLinesOnZoom(
          svg,
          medianXVal,
          medianYVal,
          startingXPoint,
          endXPoint,
          startingYPoint,
          endYPoint,
          drawingRange,
        )
      }

      // svg.call(() => medianLine(medianVal))

      // medianLine()
      svg
        .select('.axis--x')
        .selectAll('.tick')
        .each(function(d) {
          if (d === 0 && !graphHelperData.newVisualisation) {
            this.remove()
          }
        })
    }

    var zoom = (drawBtn, noAnimate) => {
      handleTooltipShow(false)
      const bordercolor = '#0274CA'
      if (drawBtn && !resetBtn) {
        // Draw reset Zoom
        setResetBtn(true)
        const zoomBtn = document.getElementById(resetBtnId)
        if (zoomBtn) {
          zoomBtn.onclick = () => brushended(true)
        }
      }
      svg
        .selectAll('.opportunity-text')
        .attr(
          'x',
          xScale(medianXVal) > 0
            ? xScale(medianXVal) + (width - xScale(medianXVal)) / 2 >
              width - 100
              ? width - 5000000
              : xScale(medianXVal) + (width - xScale(medianXVal)) / 2
            : width / 2,
        )
        .attr('transform', `translate(-50,0)`)
        .attr(
          'display',
          xScale(medianXVal) + (width - xScale(medianXVal)) / 2 > width - 100 ||
            yScale(medianYVal) < 0
            ? 'none'
            : 'block',
        )

      svg
        .selectAll('.Safe-bet-text')
        .attr(
          'x',
          xScale(medianXVal) < width
            ? xScale(medianXVal) / 2 < 100
              ? 100000
              : xScale(medianXVal) / 2
            : width / 2,
        )
        .attr('transform', `translate(-50,0)`)
        .attr(
          'display',
          xScale(medianXVal) / 2 < 100 || yScale(medianYVal) < 0
            ? 'none'
            : 'block',
        )

      svg
        .selectAll('.median-text')
        .attr('y', yScale(medianYVal) - 10)
        .attr(
          'display',
          yScale(medianYVal) - 35 >
            drawingRange.yAxis[0] - drawingRange.yAxis[1] + paddingH ||
            yScale(medianYVal) < 35
            ? 'none'
            : 'block',
        )

      svg
        .selectAll('.niche-text')
        .attr(
          'x',
          xScale(medianXVal) < width
            ? xScale(medianXVal) / 2 < 100
              ? 1000000
              : xScale(medianXVal) / 2
            : width / 2,
        )
        .attr('transform', `translate(-50,0)`)
        .attr(
          'display',
          xScale(medianXVal) / 2 < 100 ||
            yScale(medianYVal) >
              drawingRange.yAxis[0] - drawingRange.yAxis[1] + paddingH
            ? 'none'
            : 'block',
        )

      svg
        .selectAll('.upcoming-text')
        .attr(
          'x',
          xScale(medianXVal) > 0
            ? xScale(medianXVal) + (width - xScale(medianXVal)) / 2 >
              width - 100
              ? width - 5000000
              : xScale(medianXVal) + (width - xScale(medianXVal)) / 2
            : width / 2,
        )
        .attr('transform', `translate(-50,0)`)
        .attr(
          'display',
          xScale(medianXVal) + (width - xScale(medianXVal)) / 2 > width - 100 ||
            yScale(medianYVal) >
              drawingRange.yAxis[0] - drawingRange.yAxis[1] + paddingH
            ? 'none'
            : 'block',
        )

      const transition = svg.transition().duration(noAnimate ? 0 : 150)
      svg
        .select('.axis--x')
        .transition(transition)
        .call(
          drawBtn
            ? xAxis.tickValues(
                generateAllTicks(
                  'x',
                  true,
                  medianXVal,
                  xScale.ticks(),
                  xScale,
                  10,
                  'sans-serif',
                ),
              )
            : xAxis.tickValues(
                graphHelperData.newVisualisation
                  ? graphHelperData.customXTicks
                  : generateAllTicks(
                      'x',
                      false,
                      medianXVal,
                      xTickVals,
                      xScale,
                      10,
                      'sans-serif',
                    ),
              ),
        )
      svg
        .select('.axis--y')
        .transition(transition)
        .call(
          drawBtn
            ? yAxis.tickValues(
                generateAllTicks(
                  'y',
                  true,
                  medianYVal,
                  yScale.ticks(),
                  yScale,
                  10,
                  'sans-serif',
                  true,
                ),
              )
            : yAxis.tickValues(
                graphHelperData.newVisualisation
                  ? graphHelperData.customYTicks
                  : generateAllTicks(
                      'y',
                      false,
                      medianYVal,
                      yTickVals,
                      yScale,
                      10,
                      'sans-serif',
                      true,
                    ),
              ),
        )
      // svg.select(".axis-grid--y").transition(transition).call(yAxis);
      // svg.select(".axis-grid--x").transition(transition).call(xAxis);
      // svg.selectAll("line").style("display", "block");

      // // Remove redundant y axis lines after redraw
      // // axisY.select("path").remove()
      // // axisY.selectAll("line").remove()
      // // gridsY.select('path').remove()

      // // svg.selectAll(".drawn-data-points").transition().duration(150)
      // //     .attr("cx", (d)=> { return Math.round(xAxis(d['xScaleData'])) })
      // //     .attr("cy", (d) => { return Math.round(yAxis(d['yScaleData'])) });

      // // Zoom horizontal names

      const dataPoints = overlapResolver(
        graphDataFormatter(data, xScale, yScale),
        labelOffsetFromCirc,
        overlapDrawingRange,
        radius,
        fontSize,
      )
      const dataLabels = svg.select('.data-point-names-x').selectAll('text')
      dataLabels
        .transition(transition)
        .attr('x', (d, i) => {
          return dataPoints[i].x
        })
        .attr('y', (d, i) => {
          return dataPoints[i].y
        })
        .style('display', (d, i) => {
          return dataPoints[i].dir.left || dataPoints[i].dir.right
            ? 'block'
            : 'none'
        })

      svg
        .selectAll('circle')
        .filter(function() {
          return (
            !this.classList.contains('medianCircle') &&
            !this.classList.contains('medianYCircle')
          )
        })
        .transition()
        .duration(0)
        .attr('cx', (d) => xScale(d.xScaleData))
        .attr('cy', (d) => yScale(d.yScaleData))
    }

    const brush = d3
      .brush()
      .on('start', () => setIsBrushUsed(true))
      .on('end', brushended)

    if (appliedZoom.length) {
      zoomIn(appliedZoom, true)
    }
    // brushLayer
    !graphHelperData.zoomDisabled &&
      brushLayer
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'brush')
        .call(brush)

    d3.select('#hover-tooltip-x').attr(
      'style',
      `left: ${width / 2 + 45}px; top: ${height - 15}px; position: absolute;`,
    )
    const yTooltip = d3
      .select('#hover-tooltip-y')
      .attr(
        'style',
        `left: ${8}px; top: ${height / 2 -
          70}px; position: absolute; transform: rotate(270deg);`,
      )
    yTooltip.select('.tooltiptext').attr('style', 'transform: rotate(90deg);')
  }, [data])

  return (
    <Box id="chart" sx={{ position: 'relative' }}>
      {'xAxisTextis' in graphHelperData && (
        <div id="hover-tooltip-x">
          <InformationIcon tooltipText={graphHelperData.xAxisTextis} />
        </div>
      )}
      {'yAxisTextis' in graphHelperData && (
        <div id="hover-tooltip-y" className="tooltip">
          <InformationIcon tooltipText={graphHelperData.yAxisTextis} />
        </div>
      )}
      <svg
        id="scattered-graph"
        ref={svgRef}
        onClick={() => handleTooltipShow(false)}
      ></svg>
      {tooltipShow && (
        <Card
          sx={{
            position: 'absolute',
            left: tooltipShow.left,
            top: tooltipShow.top,
            width: graphHelperData.newVisualisation ? 200 : 240,
            zIndex: 100,
            background: graphHelperData.newVisualisation ? '#0274CA' : 'white',
            '& .MuiPaper-root': {
              boxShadow: '0px 0px 5px 0px #00000029',
              background: graphHelperData.newVisualisation
                ? '#0274CA'
                : 'white',
              borderRadius: graphHelperData.newVisualisation ? '20px' : 0,
            },
          }}
        >
          {graphHelperData.newVisualisation
            ? scatteredPlotTooltipNewVis(tooltipShow)
            : scatteredPlotTooltip}
        </Card>
      )}
    </Box>
  )
}

export default ScatteredGraphUI
