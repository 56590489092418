import * as React from 'react'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import TooltipOnOverflow from '../../../components/Tooltip/TooltipOnOverflow'

import { StyledFormControlLabel } from './style'

export default function Checkingbox({
  isChecked,
  option,
  handleCheckBoxClick,
}) {
  const text = option ? option.name : ''

  const checkBoxClickHandler = () => {
    handleCheckBoxClick(option)
  }

  const imgStyle = {
    borderRadius: 2,
    minWidth: 20,
    objectFit: 'cover',
  }

  const image = (
    <img style={imgStyle} src={option?.image} width="20px" height="20px" />
  )
  return (
    <FormControl component="fieldset" sx={{ borderRadius: '4px' }}>
      <TooltipOnOverflow title={text} extraSpace={75}>
        <FormGroup aria-label="position" row>
          <StyledFormControlLabel
            value={text}
            onChange={checkBoxClickHandler}
            sx={{
              border: ({ palette }) =>
                `1px solid ${
                  !isChecked === true ? palette.divider : palette.secondary.main
                }`,
              fontSize: 14,
            }}
            control={
              <Checkbox
                sx={{
                  padding: '1px',
                  transform: 'scale(0.8)',
                }}
                data-testid="checkbox"
                icon={image}
                checkedIcon={image}
              />
            }
            label={text}
            labelPlacement="end"
          />
        </FormGroup>
      </TooltipOnOverflow>
    </FormControl>
  )
}
