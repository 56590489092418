import {
  ProductDetailsViewActions,
  ResetActions,
  IngredientQuotesActionTypes,
} from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  productDetails: null,
  ingredientQuotes: null,
  cafeProducts: [],
  retailProducts: [],
  pairingDetails: null,
  relatedTrendsDetails: null,
  error: null,
  loading: false,
  loader: {
    relatedTrendsV2: false,
  },
  relatedTrendsV2: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ResetActions.type[ResetActions.RESET_PRODUCT_DETAILS]:
      return updateObject(state, initialState)
    case ProductDetailsViewActions.type[
      ProductDetailsViewActions.FETCH_PRODUCT_START
    ]:
      return updateObject(state, {
        loading: true,
      })
    case ProductDetailsViewActions.type[
      ProductDetailsViewActions.FETCH_PRODUCT_SUCCESS
    ]:
      return updateObject(state, {
        productDetails: action.productDetails,
        error: false,
        loading: false,
      })
    case ProductDetailsViewActions.type[
      ProductDetailsViewActions.FETCH_PRODUCT_FAIL
    ]:
      return updateObject(state, {
        error: true,
        productDetails: null,
        loading: false,
      })
    case AC.ADD_CAFE_PRODUCTS:
      return Object.assign({}, state, {
        cafeProducts: [...action.cafeProductsList],
      })
    case AC.ADD_RETAIL_PRODUCTS:
      return Object.assign({}, state, {
        retailProducts: [...action.retailProductsList],
      })
    case AC.ADD_RETAIL_PRODUCT_FORMATS:
      return Object.assign({}, state, {
        retailProductFormats: { ...action.retailProductFormats.formats },
      })
    case AC.ADD_CAFE_PRODUCT_FORMATS:
      return Object.assign({}, state, {
        cafeProductFormats: { ...action.cafeProductFormats.formats },
      })
    case AC.ADD_PAIRING_DETAILS:
      return Object.assign({}, state, {
        pairingDetails: { ...action.pairingDetails },
      })
    case AC.ADD_RELATED_TRENDS_DETAILS:
      return Object.assign({}, state, {
        relatedTrendsDetails: { ...action.relatedTrendsDetails },
      })
    case AC.ADD_RELATED_TRENDS_V2_LOADING:
      return updateObject(state, {
        loader: { ...state.loader, relatedTrendsV2: true },
      })
    case AC.ADD_RELATED_TRENDS_V2_FAILURE:
      return updateObject(state, {
        loader: { ...state.loader, relatedTrendsV2: false },
      })
    case AC.ADD_RELATED_TRENDS_V2:
      const relatedTrends = { ...action.relatedTrendsV2.data }
      const requestDetails = { ...action.relatedTrendsV2.requestDetails }

      let relatedTrendsData = {}
      if (requestDetails.cacheType === 'update') {
        relatedTrendsData = { ...state.relatedTrendsV2 }
        requestDetails.include.forEach((value) => {
          relatedTrendsData = {
            ...relatedTrendsData,
            [value]: {
              ...relatedTrendsData[value],
              [requestDetails.subCategories[value]]:
                relatedTrends[`related_${value}`] || {},
            },
          }
        })
      } else {
        relatedTrendsData = {
          trendId: requestDetails.trendId,
          lens: requestDetails.lens,
          ingredient: {},
          product: {},
          theme: {},
        }
        requestDetails.include.forEach((value) => {
          relatedTrendsData = {
            ...relatedTrendsData,
            [value]: {
              [requestDetails.subCategories[value]]:
                relatedTrends[`related_${value}`] || {},
            },
          }
        })
      }
      return updateObject(state, {
        relatedTrendsV2: relatedTrendsData,
        loader: { ...state.loader, relatedTrendsV2: false },
      })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        productDetails: null,
        error: null,
      })
    case IngredientQuotesActionTypes.type[
      IngredientQuotesActionTypes.FETCH_INGREDIENT_QUOTES_SUCCESS
    ]:
      return updateObject(state, {
        ingredientQuotes: action.ingredientQuotes,
      })
    case IngredientQuotesActionTypes.type[
      IngredientQuotesActionTypes.FETCH_INGREDIENT_QUOTES_FAILED
    ]:
      return updateObject(state, {
        ingredientQuotes: null,
      })

    default:
      return state
  }
}

export default reducer
