import * as AC from './actionConstants'
import axios from '../../services/networking/axios'
import swAxios from '../../services/networking/swAxios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import * as A from '../../store/actions'
import { ScreenWinner, GetUserProfileActionTypes } from './actionTypes'

export const addUser = (user) => ({
  type: AC.ADD_USER,
  user: user,
})

export const addLoginUser = (user) => ({
  type: AC.ADD_LOGIN_USER,
  user: user,
})
export const showLogoutIcon = () => ({
  type: AC.SHOW_LOGOUT_ICON,
})

export const setUserLoggedOut = () => {
  NetworkUtil.clearAuthKey()
  NetworkUtil.clearSWAuthKey()
  NetworkUtil.clearOptOut()
  return {
    type: AC.SET_USER_LOGGED_OUT,
  }
}
export const addLoginUserDetail = (loginUserDetail) => ({
  type: AC.ADD_LOGIN_USER_DETAIL,
  loginUserDetail,
})
export const editUser = (user) => ({
  type: AC.ADD_LOGIN_USER_PROFILE,
  user,
})
export const showRedirectVisibility = () => ({
  type: AC.REDIRECT_VISIBILITY,
})

export const loginFailed = (error) => ({
  type: AC.LOGIN_FAILED,
  error: error,
})

export const loginUserOnServer = (formInput) => {
  return (dispatch) => {
    const request = NetworkUtil.formLoginUser()
    // Deleting existing authorization header
    delete axios.defaults.headers.common.Authorization
    return axios
      .post(request, formInput)
      .then((response) => {
        if (response.data && response.data && response.data.access_token) {
          axios.defaults.headers.common.Authorization =
            'Bearer ' + response.data.access_token
          fetchSWSubscription(dispatch)
          fetchBWSubscription(dispatch)
          NetworkUtil.saveAuthKey('Bearer ' + response.data.access_token)
        }
        dispatch(addLoginUser(response.data))
        dispatch(A.showLogoutIcon())
        dispatch(A.hideSppiner())
        return true
      })
      .catch((error) => {
        dispatch(A.hideSppiner())
        dispatch(screenWinnerStatus(false))
        dispatch(fetchingSWStatus(false))
        dispatch(loginFailed('Invalid Email or Password'))
        return false
      })
  }
}

export const fetchSecretAccessToken = () => {
  const tokenFromUrl = new URLSearchParams(location.search).has('token')
    ? new URLSearchParams(location.search).get('token')
    : ''
  return (dispatch) => {
    // Ensure the user profile is still fetched even if the token isn't present
    // in the dependent function
    if (!tokenFromUrl) {
      return new Promise((resolve) => {
        resolve()
      })
    }
    const request = NetworkUtil.fetchValidatedToken()
    return axios
      .get(request, {
        headers: { Authorization: `${tokenFromUrl}` },
      })
      .then((response) => {
        if (response.data && response.data.access_token) {
          axios.defaults.headers.common.Authorization =
            'Bearer ' + response.data.access_token
          fetchSWSubscription(dispatch)
          fetchBWSubscription(dispatch)
          NetworkUtil.saveAuthKey('Bearer ' + response.data.access_token)
          NetworkUtil.optOutRedirect()
          dispatch(addLoginUser(response.data))
          dispatch(A.showLogoutIcon())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const loginUserGet = () => {
  return (dispatch) => {
    const request = NetworkUtil.formLoginUserGet()
    return axios
      .get(request)
      .then((response) => {
        if (response.data && response.data.access_token) {
          axios.defaults.headers.common.Authorization =
            'Bearer ' + response.data.access_token
          fetchSWSubscription(dispatch)
          fetchBWSubscription(dispatch)
          NetworkUtil.saveAuthKey('Bearer ' + response.data.access_token)
          dispatch(addLoginUser(response.data))
          dispatch(A.showLogoutIcon())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const updateUserProfileOnServer = (data) => {
  return function(dispatch, getState) {
    const request = NetworkUtil.formUserProfile()
    return axios
      .put(request, data)
      .then(function(response) {
        dispatch(editUser(response.data))
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}

export const getUserProfileOnServer = () => {
  return (dispatch, getState) => {
    dispatch(getUserProfileStart())
    const request = NetworkUtil.getUserProfileRequest()
    axios
      .get(request)
      .then((response) => {
        dispatch(getUserProfileSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUserProfileFailed(error))
      })
  }
}

const getUserProfileStart = () => {
  return {
    type:
      GetUserProfileActionTypes.type[
        GetUserProfileActionTypes.FETCH_USER_PROFILE_START
      ],
  }
}

const getUserProfileSuccess = (userProfile) => {
  return {
    type:
      GetUserProfileActionTypes.type[
        GetUserProfileActionTypes.FETCH_USER_PROFILE_SUCCESS
      ],
    userProfile: userProfile,
  }
}

const getUserProfileFailed = (error) => {
  return {
    type:
      GetUserProfileActionTypes.type[
        GetUserProfileActionTypes.FETCH_USER_PROFILE_FAILED
      ],
    error: error,
  }
}

export const setLoadingProfile = () => {
  return {
    type:
      GetUserProfileActionTypes.type[
        GetUserProfileActionTypes.SET_LOADING_PROFILE
      ],
  }
}

export const setRedirectionUrl = (url) => ({
  type:
    GetUserProfileActionTypes.type[
      GetUserProfileActionTypes.SET_REDIRECTION_URL
    ],
  url,
})

export const forgetPasswordOnServer = (data) => {
  return function(dispatch, getState) {
    const request = NetworkUtil.formForgetPassword()
    return axios
      .post(request, data)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        console.log(error)
        return error
      })
  }
}

export const resetPasswordOnServer = (data) => {
  return function(dispatch, getState) {
    const request = NetworkUtil.formResetPassword()
    return axios
      .post(request, data)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        console.log(error)
        return error
      })
  }
}

export const updatePasswordOnServer = (data) => {
  return function(dispatch) {
    const request = NetworkUtil.updateUserPassword()
    return axios
      .post(request, data)
      .then(function(response) {
        dispatch(editUser(response.data))
        return response
      })
      .catch(function(error) {
        return error
      })
  }
}

export const checkTokenExpiry = (data) => {
  return function(dispatch, getState) {
    const request = NetworkUtil.checkTokenExpiry()
    return axios.post(request, data)
  }
}

export const setPolicy = (payload) => {
  return function(dispatch) {
    const request = NetworkUtil.setPolicy()
    return axios
      .post(request, payload)
      .then(function(response) {
        dispatch(setAcceptedPolicy())
      })
      .catch(function(error) {
        console.log('response', error)
      })
  }
}

const fetchSWSubscription = (dispatch) => {
  dispatch(fetchingSWStatus(true))
  axios
    .get(NetworkUtil.microServicesSubscriptionCheck('screenwinner'))
    .then((swResponse) => {
      if (swResponse.data && swResponse.data.data) {
        const { auth_key: authKey, access_to: accessTo } = swResponse.data.data
        if (authKey) {
          swAxios.defaults.headers.common.ScreenwinnerAuthKey = authKey
          NetworkUtil.saveSWAuthKey(authKey)
          dispatch(screenWinnerStatus(accessTo.screenwinner))
          dispatch(fetchingSWStatus(false))
        } else {
          dispatch(screenWinnerStatus(false))
          dispatch(fetchingSWStatus(false))
        }
      }
    })
    .catch((err) => {
      console.log(err, 'screen-winner error')
      dispatch(fetchingSWStatus(false))
    })
}

const screenWinnerStatus = (status) => ({
  type: ScreenWinner.type[ScreenWinner.SUBSCRIPTION_EXISTS],
  status,
})

const fetchingSWStatus = (fetching) => ({
  type: ScreenWinner.type[ScreenWinner.FETCHING_STATUS],
  fetching,
})

const setAcceptedPolicy = () => ({
  type: AC.ACCEPT_POLICY,
})


const fetchBWSubscription = (dispatch) => {
  axios
    .get(NetworkUtil.microServicesSubscriptionCheck('buildwinner'))
    .then((bwResponse) => {
      if (bwResponse.data && bwResponse.data.data) {
        const { auth_key: authKey, access_to: accessTo } = bwResponse.data.data
        if (authKey) {
          if(accessTo.buildwinner){
            NetworkUtil.saveBWAuthKey(authKey)
          }
        } else {
          // Do nothing
        }
      }
    })
    .catch((err) => {
      console.log(err, 'build-winner error')
    })
}