import React, { useState } from 'react'
import { Button, MenuItem, Menu, Typography, Box, Tooltip } from '@mui/material'
import stars from './../../../assets/icons/stars.svg'
import { ReactComponent as Marketing } from './../../../assets/icons/marketing.svg'
import { ReactComponent as Generic } from './../../../assets/icons/generic.svg'
import { ReactComponent as SpaceWidth } from './../../../assets/icons/space-width.svg'
import { ReactComponent as WhiteStar } from './../../../assets/icons/white-star.svg'
import { ReactComponent as AlignVerticalCenter } from './../../../assets/icons/align-vertical-center.svg'
import { StyledMenuItem } from './styles'

const LESS_DESCRIPTIVE = 'LESSDESCRIPTIVE'
const MORE_DESCRIPTIVE = 'MOREDESCRIPTIVE'
const DEFAULT = 'DEFAULT'
const MARKETING = 'MARKETING'
const MESSAGE =
  'The new concept will be created based on the Default generated concept.'

const ModifyResult = ({ sendModifyData, setToneSelected, toneSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleModifierSelection = (modifier) => {
    sendModifyData(modifier)
    setToneSelected(modifier)
    handleClose()
  }
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          padding: '8px 16px',
          mb: 0.5,
          color: ({ palette }) => palette.primary.main,
          border: ({ palette }) => `1px solid ${palette.primary.main}`,
        }}
        onClick={handleClick}
      >
        <img
          src={stars}
          height={16}
          width={16}
          style={{
            marginRight: '8px',
            boxSizing: 'content-box',
          }}
        />
        Modify Concept
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        sx={{
          width: '270px',
          padding: 0,
          '& .MuiList-root': {
            padding: '0px !important',
          },
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          //   disabled={true}
          sx={{
            background: ({ palette }) => `${palette.primary.main} !important`,
            color: 'white',
            padding: '15px',
          }}
          onClick={() => {}}
        >
          <WhiteStar style={{ marginRight: '10px' }} />
          Modify Concept
        </MenuItem>
        <StyledMenuItem
          sx={
            toneSelected === LESS_DESCRIPTIVE
              ? { background: ({ palette }) => palette.option.selected }
              : {}
          }
          onClick={() => handleModifierSelection(LESS_DESCRIPTIVE)}
        >
          <Tooltip title={MESSAGE}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '34px' }}>
                <AlignVerticalCenter />
              </Box>
              <Box sx={{ marginLeft: '10px', width: '140px' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
                  Less Descriptive
                </Typography>
                <Typography
                  sx={{ fontSize: '10px', whiteSpace: 'break-spaces' }}
                >
                  The generated concept will be concise.
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          sx={
            toneSelected === MORE_DESCRIPTIVE
              ? { background: ({ palette }) => palette.option.selected }
              : {}
          }
          onClick={() => handleModifierSelection(MORE_DESCRIPTIVE)}
        >
          <Tooltip title={MESSAGE}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '34px' }}>
                <SpaceWidth style={{ marginRight: '10px' }} />
              </Box>
              <Box sx={{ marginLeft: '10px', width: '140px' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
                  More Descriptive
                </Typography>
                <Box>
                  <Typography
                    sx={{ fontSize: '10px', whiteSpace: 'break-spaces' }}
                  >
                    The generated concept will be more descriptive and lengthy.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          sx={
            toneSelected === DEFAULT
              ? { background: ({ palette }) => palette.option.selected }
              : {}
          }
          onClick={() => handleModifierSelection(DEFAULT)}
        >
          <Tooltip title={MESSAGE}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '34px' }}>
                <Generic style={{ marginRight: '10px' }} />
              </Box>
              <Box sx={{ marginLeft: '10px', width: '140px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
                    Tone: Generic{' '}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      color: '#C7C7C7',
                      fontWeight: 500,
                      ml: 1,
                    }}
                  >
                    (default)
                  </Typography>
                </Box>
                <Typography
                  sx={{ fontSize: '10px', whiteSpace: 'break-spaces' }}
                >
                  The generated concept will be generic in nature.
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          sx={
            toneSelected === MARKETING
              ? { background: ({ palette }) => palette.option.selected }
              : {}
          }
          onClick={() => handleModifierSelection(MARKETING)}
        >
          <Tooltip title={MESSAGE}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '34px' }}>
                <Marketing style={{ marginRight: '10px' }} />
              </Box>
              <Box sx={{ marginLeft: '10px', width: '140px' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
                  Tone: Marketing
                </Typography>
                <Typography
                  sx={{ fontSize: '10px', whiteSpace: 'break-spaces' }}
                >
                  The generated concept will have Marketing Language.
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </StyledMenuItem>
      </Menu>
    </>
  )
}

export default ModifyResult
