import React, { useState } from 'react'
import { Button, Box, Typography, IconButton } from '@mui/material'
import { ReactComponent as DeleteIcon } from './../../../assets/icons/DeleteIcon.svg'
import { useHistory } from 'react-router-dom'
import TextOverflowTooltip from '../TextOverflowTooltip'

export default function WheelCard({ wheelDetail, onDeleteOptionClick, wheel }) {
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const history = useHistory()

  return (
    <Box
      sx={{
        margin: '10px',
        position: 'relative',
        width: '310px',
        borderRadius: '4px',
        border: isDeleteVisible
          ? ({ palette }) => ` 1px solid ${palette.primary.main}`
          : 'none',
        boxShadow: ({ palette }) =>
          `0px 4px 8px 0px ${palette.shadow.secondary}`,
      }}
      onClick={() => {
        history.push(`/mui/trend-pillar/saved-wheels/${wheelDetail.id}`)
      }}
      onMouseEnter={() => setIsDeleteVisible(true)}
      onMouseLeave={() => setIsDeleteVisible(false)}
    >
      <Box
        sx={{
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          background: ({ palette }) => palette.grey[50],
          alignItems: 'center',
        }}
      >
        <Box
          sx={{ height: '170px', width: '170px' }}
          component="img"
          src={wheel}
          alt={'wheel image'}
        />
      </Box>
      <Box sx={{ background: ({ palette }) => palette.white, padding: '30px' }}>
        <TextOverflowTooltip title={wheelDetail.name} checkWidth={true}>
          <Typography sx={{ fontSize: '20px' }}>{wheelDetail.name}</Typography>
        </TextOverflowTooltip>
        <Box my={1.5} sx={{ display: 'flex' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '80px',
              color: ({ palette }) => palette.grey[800],
            }}
          >
            Country:
          </Typography>
          <Typography
            sx={{ fontSize: '14px', color: ({ palette }) => palette.grey[800] }}
          >
            {wheelDetail.country}
          </Typography>
        </Box>
        <Box my={1.5} sx={{ display: 'flex', fontSize: '11px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '80px',
              color: ({ palette }) => palette.grey[800],
            }}
          >
            Category:
          </Typography>
          <Typography
            sx={{ fontSize: '14px', color: ({ palette }) => palette.grey[800] }}
          >
            {wheelDetail.category}
          </Typography>
        </Box>
      </Box>
      {isDeleteVisible && (
        <IconButton
          pl={1}
          size="small"
          aria-label="close"
          color="inherit"
          sx={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={(e) => onDeleteOptionClick(e, wheelDetail.id)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  )
}
