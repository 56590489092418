import { Box, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CommonCardComponent from '../../shared/components/CommonCardComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { REGIONAL_ANALYTICS_COLORS } from '../../shared/utils/constants'

const RegionalAnalyticsGraphLegend = (props) => {
  const { regionalDistributionData, trendName } = props
  const sortedRegionalDistributionData = regionalDistributionData?.sort(
    (a, b) => {
      return b.distribution - a.distribution
    },
  )

  const colorScheme = {}
  const updatedRegionalDistributionData = sortedRegionalDistributionData?.forEach(
    (region, i) => {
      colorScheme[region.name] = REGIONAL_ANALYTICS_COLORS[i]
    },
  )

  const getDistribution = (region) => {
    const distributionSum = sortedRegionalDistributionData?.reduce(
      (acc, { distribution }) => acc + distribution,
      0,
    )
    return (
      (sortedRegionalDistributionData?.find((data) => data.name === region)
        .distribution /
        distributionSum) *
      100
    ).toFixed(2)
  }

  let regionWisePercentage = [
    {
      code: 'West',
    },
    {
      code: 'Midwest',
    },
    {
      code: 'South',
    },
    {
      code: 'Northeast',
    },
  ]
  regionWisePercentage = regionWisePercentage.map((obj, index) => ({
    ...obj,
    regionName: obj.code.concat('ern Region'),
    percentage: getDistribution(obj.code),
    color: colorScheme[obj.code],
  }))
  const percentageUnderlineStyles = (obj) => {
    return {
      width: 50,
      height: 5,
      background: obj.color,
      borderRadius: 6,
      mt: 0.5,
    }
  }
  return (
    <CommonCardComponent
      boxStyles={{
        width: '100%',
      }}
    >
      <Grid container sx={{ py: 2.5, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 0, right: 0, m: 0.5 }}>
          <Tooltip
            title={`This is the social presence of ${trendName} in different regions`}
            placement="top-end"
          >
            <InfoOutlinedIcon
              sx={{ color: ({ palette }) => palette.text.secondary }}
            />
          </Tooltip>
        </Box>
        {regionWisePercentage.map((obj) => (
          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 3, px: 2 }}
            key={obj.code}
          >
            <Box>
              <Typography variant="body2">{obj.regionName}</Typography>
              <Typography
                sx={{
                  color: obj.color,
                  mt: 1,
                }}
              >
                {obj.percentage}%
              </Typography>
              <Box sx={percentageUnderlineStyles(obj)} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </CommonCardComponent>
  )
}

export default RegionalAnalyticsGraphLegend
