class AdvancedTrendResponse {
  constructor(response) {
    this._commonTrends = response.order.reduce((acc, name) => {
      return {
        ...acc,
        [name]: response.commonTrends[name].map(
          ({
            ingredient_type: ingredientType,
            product_type: productType,
            theme_type: themeType,
            yoy_growth: yoyGrowth,
            image_url: imageUrl,
            data_points: dataPoints,
            four_year_cagr: fourYearCagr,
            ...rest
          }) => ({
            ...rest,
            trendType:
              (ingredientType || productType || themeType)
                .slice(0, 1)
                .toUpperCase() +
              (ingredientType || productType || themeType)
                .slice(1)
                .toLowerCase(),
            yoyGrowth,
            imageUrl,
            dataPoints,
            fourYearCagr,
            ingredient_type: ingredientType,
            product_type: productType,
            theme_type: themeType,
            yoy_growth: yoyGrowth,
            image_url: imageUrl,
            data_points: dataPoints,
            four_year_cagr: fourYearCagr,
          }),
        ),
      }
    }, {})
    this._order = response.order
  }

  get commonTrends() {
    return this._commonTrends
  }

  get order() {
    return this._order
  }
}

export default AdvancedTrendResponse
