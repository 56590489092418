import React, { useState, useEffect } from "react";

import { formatString } from "../../store/utility";

import "./Input.scss";

const Input = ({
  errorMessage = "",
  className = "",
  inputClassName = "",
  onChange,
  required,
  revalidate,
  revalidateCallback,
  capitalize = false,
  type = "text",
  initialValue,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue || "");
  const [error, setError] = useState(false);

  const errorCheck = () => {
    if (!value && required) {
      setError(true);
    }
  };

  const handleValueChange = ({ target }) => {
    setValue(formatString(target.value, capitalize));
  };

  const handleInputBlur = ({ target }) => {
    setValue(formatString(target.value, capitalize).trim());
    errorCheck();
  };

  useEffect(() => {
    onChange && onChange(value);
    if (value) {
      setError(false);
    }
  }, [value]);

  useEffect(() => {
    if (revalidate) {
      errorCheck();
    }
  }, [revalidate]);

  useEffect(() => {
    if (error && revalidate) {
      revalidateCallback && revalidateCallback();
    }
  }, [error]);

  return (
    <>
      <input
        className={`sw-input-cmpnt sw-input ${inputClassName} ${
          error ? "sw-input--error" : ""
        }`}
        type={type}
        value={value}
        onChange={handleValueChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      {error && errorMessage && (
        <span className="sw-error para--12">{errorMessage}</span>
      )}
    </>
  );
};

export default Input;
