import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Link, Typography } from '@mui/material'

import DemographicThemePageUI from './DemographicThemePageUI'

import { NeedChart } from '../../../services/networking/Models/SubNeedDetailResponse'

import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { amplify } from '../../../store/utility'
import { shouldLogRecentActivity } from '../../../shared/components/DashboardRecentActivity'
import {
  INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
  INSIGNIFICANT_SOCIAL_MENTION_COUNT,
} from './../../../shared/utils/constants'

const amp = (ampiEvent, ampiUserData = {}) => {
  const amplitudeEvent = ampiEvent
  let amplitudeUserData = {
    User: ampiUserData.User,
    Country_Name: ampiUserData.country,
    Category_Name: ampiUserData.category,
    Lens: ampiUserData.lens,
    Trend_Name: ampiUserData.trendName,
  }
  switch (ampiEvent) {
    case 'Clicked_Demography_Quotes':
      amplitudeUserData = {
        ...amplitudeUserData,
        Agegroup_Size: ampiUserData.ageGroupTab,
      }
      break
    case 'Clicked_Trend_Lens':
      amplitudeUserData = {
        ...amplitudeUserData,
        Redirected_From: ampiUserData.redirectedFrom,
      }
  }
  amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
}

const ampEventClickOnPieChartSlice = (
  email,
  lens,
  country,
  category,
  ageGroupTab,
) => {
  const ampiEvent = `Clicked_Demography_Agegroup_size`
  const ampiUserData = {
    User: email,
    Lens: lens,
    Country_Name: country,
    Category_Name: category,
    SubCategory_Name: 'null',
    Agegroup_Size: ageGroupTab,
  }
  amplify(ampiEvent, ampiUserData, email)
}
const DemographicThemePage = ({
  projectId,
  email,
  country,
  category,
  themeId,
  trendName,
  setShowLoader,
  showLoader,
  ageGroupTab,
  lens,
  setAgeGroupTab,
  setCategorySelected,
  ageGroupGenX,
  ageGroupGenZ,
  ageGroupMillanials,
  // getDemographyFsProductsForProducts,
  // getDemographyRetailProductsforProduct,
  // getDemographyRetailProductsforIngredient,
  // getDemographyFsProductsForIngredients,
  // fsProducts,
  // retailProducts,
  // getDemographyRetailProductsFormat,
  // getDemographyFsProductsFormat,
  // fsProductFormat,
  // retailProductsFormat,
}) => {
  const [consumerQuotesData, setConsumerQuotesData] = useState(null)
  const [relatedTrends, setRelatedTrends] = useState(null)
  const [trendDistributionData, setTrendDistributionData] = useState(null)
  const [trendData, setTrendData] = useState([])
  const [sentimentData, setSentimentData] = useState([])
  const [consumerInsightData, setConsumerInsightData] = useState([])
  const [engagementScore, setEngagementScore] = useState([])
  const [ageGroupData, setAgeGroupData] = useState(ageGroupTab)
  const [dataPoint, setDataPoint] = useState(null)
  const [ageGroupHovered, setAgeGroupHovered] = useState('')
  const [isDataInsignificant, setIsDataInsignificant] = useState(false)
  const history = useHistory()

  const logTrendClick = (trendName, redirectedFrom) => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      User: email,
      country,
      category,
      lens,
      trendName,
      redirectedFrom,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logConsumerQuotesClicked = () => {
    const ampiEvent = 'Clicked_Demography_Quotes'
    const ampiUserData = {
      User: email,
      trendName,
      lens,
      country,
      category,
      ageGroupTab,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleSnackBarRemove = () => {
    setIsDataInsignificant(false)
  }

  // useEffect(() => {
  //   if (lens === 'Product') {
  //     getDemographyFsProductsForProducts(themeId, ageGroupTab)
  //     getDemographyRetailProductsforProduct(themeId, ageGroupTab)
  //   }
  //   if (lens === 'Ingredient') {
  //     getDemographyRetailProductsforIngredient(themeId, ageGroupTab)
  //     getDemographyFsProductsForIngredients(themeId, ageGroupTab)
  //   }

  //   getDemographyRetailProductsFormat(themeId, ageGroupTab)
  //   getDemographyFsProductsFormat(themeId, ageGroupTab)
  // }, [ageGroupTab])
  function removeZerosAndCheckLengthOfEngScoreArr(engScore) {
    const newEngScore = engScore.slice()
    for (let i = newEngScore.length - 1; i >= 0; i--) {
      if (newEngScore[i].engagementScore > 0) {
        break
      } else {
        newEngScore.splice(-1, 1)
      }
    }
    return newEngScore.length > 2
  }
  const engagementScoreAvailable = removeZerosAndCheckLengthOfEngScoreArr(
    engagementScore,
  )
  const getDemographicTrendDistributionData = (
    countrySelected,
    categorySelected,
    trend,
    lens,
  ) => {
    const request = NetworkUtil.demographicTrendDistribution(
      countrySelected,
      categorySelected,
      trend,
      lens,
    )
    setShowLoader(true)
    axios
      .get(request)
      .then((res) => {
        setShowLoader(false)

        const sum = res.data.data.distribution.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.value
          },
          0,
        )

        const AgeDistributionData = res.data.data.distribution.map((data) => {
          return {
            name: data.name,
            value: ((data.value / sum) * 100).toFixed(2),
          }
        })

        const filteredData = AgeDistributionData?.filter(
          (ageGroup) => ageGroup.value !== '0.00',
        )
        setTrendDistributionData(filteredData)
      })
      .catch(() => {
        setShowLoader(false)
      })
  }

  const getTrendData = (
    countrySelected,
    categorySelected,
    trend,
    ageGroupData,
    lens,
  ) => {
    const request = NetworkUtil.demographicAnalyticsTrendData(
      countrySelected,
      categorySelected,
      trend,
      ageGroupData,
      lens,
    )
    setShowLoader(true)
    axios
      .get(request)
      .then((res) => {
        setShowLoader(false)
        setDataPoint(res.data.data[0].data_point || 0)
        setTrendData(res.data.data[0].cagr)
        setSentimentData(res.data.data[0].sentiment_score)
        const output = Object.entries(res.data.data[0].engagement_score).map(
          ([date, score]) => {
            return new NeedChart({ period: date, engagementScore: score })
          },
        )
        setEngagementScore(output)
        const nonZeroEngagementScoreDataPercent =
          (output.filter(({ engagementScore }) => engagementScore !== 0)
            .length /
            output.length) *
          100
        setIsDataInsignificant(
          res.data.data[0].unique_mention_count <=
            INSIGNIFICANT_SOCIAL_MENTION_COUNT ||
            nonZeroEngagementScoreDataPercent <=
              INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
        )
      })
      .catch(() => {
        setShowLoader(false)
      })
  }

  const getDemographicConsumerInsights = (themeId, ageGroupData, lens) => {
    const request = NetworkUtil.demographicConsumerInsights(
      themeId,
      ageGroupData,
      lens,
    )
    setShowLoader(true)
    axios
      .get(request)
      .then((res) => {
        setShowLoader(false)
        setConsumerInsightData(res.data.data)
      })
      .catch(() => {
        console.log('error')
      })
  }

  const getDemographyQuotes = (themeId, ageGroupData, lens) => {
    let consumerQuotesRequest
    switch (lens) {
      case 'Ingredient':
        consumerQuotesRequest = NetworkUtil.demographicAnalyticsConsumerQuotesForIngredients(
          themeId,
          ageGroupData,
          lens,
        )
        break
      case 'Product':
        consumerQuotesRequest = NetworkUtil.demographicAnalyticsConsumerQuotesForProducts(
          themeId,
          ageGroupData,
          lens,
        )
        break
      case 'Theme':
        consumerQuotesRequest = NetworkUtil.demographicAnalyticsConsumerQuotesForThemes(
          themeId,
          ageGroupData,
          lens,
        )

        break
    }

    axios
      .get(consumerQuotesRequest)
      .then((res) => {
        setConsumerQuotesData(res.data)
      })
      .catch(() => {})
  }

  const handleArcClick = (name) => {
    ampEventClickOnPieChartSlice(email, lens, country, category, name)
    setAgeGroupData(name)
    setAgeGroupTab(name)
  }

  const handleArcHover = (name) => {
    setAgeGroupHovered(name)
  }

  useEffect(() => {
    if (ageGroupTab !== null) {
      setAgeGroupData(ageGroupTab)
    }
  }, [ageGroupTab])

  useEffect(() => {
    if (ageGroupData !== null) {
      history.push(
        `?trend=${encodeURIComponent(trendName)}&projectId=${encodeURIComponent(
          projectId,
        )}&country=${encodeURIComponent(country)}&category=${encodeURIComponent(
          category,
        )}&lens=${encodeURIComponent(lens)}&themeId=${encodeURIComponent(
          themeId,
        )}&demography=${encodeURIComponent(ageGroupData)}`,
      )
    }
  }, [ageGroupData, themeId, lens, country, category])

  useEffect(() => {
    getDemographicTrendDistributionData(country, category, trendName, lens)
  }, [country, category, trendName, lens])

  useEffect(() => {
    getTrendData(country, category, trendName, ageGroupData, lens.toLowerCase())
  }, [country, category, trendName, ageGroupData, lens])

  useEffect(() => {
    getDemographicConsumerInsights(themeId, ageGroupData, lens)
  }, [ageGroupData, lens])

  const getRelatedTrends = () => {
    let relatedTrendRequest
    switch (lens) {
      case 'Ingredient':
        relatedTrendRequest = NetworkUtil.fetchDemographyRelatedTrendsForIngredients(
          themeId,
          ageGroupData,
        )
        break
      case 'Product':
        relatedTrendRequest = NetworkUtil.fetchDemographyRelatedTrendsForProducts(
          themeId,
          ageGroupData,
        )
        break
      case 'Theme':
        relatedTrendRequest = NetworkUtil.demographicRelatedTrends(
          themeId,
          ageGroupData,
        )

        break
    }
    axios
      .get(relatedTrendRequest)
      .then((res) => {
        setRelatedTrends(res.data.data)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getRelatedTrends(themeId, ageGroupData)
  }, [ageGroupData])

  useEffect(() => {
    getDemographyQuotes(themeId, ageGroupData, lens)
  }, [ageGroupData, lens])

  useEffect(() => {
    if (country && category && ageGroupData && trendName) {
      shouldLogRecentActivity(
        `Demographic analytics>${country} ${category} (${ageGroupData})>${trendName}`,
        'Platform',
        `${history.location.pathname}${history.location.search}`,
      )
    }
  }, [country, category, ageGroupData, trendName])

  const breadcrumbs = [
    <Link
      variant="breadcrumbsLink"
      key="1"
      href="/mui/demographic-analytics"
      onClick={() => setCategorySelected(null)}
    >
      Demographic analysis
    </Link>,

    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/demographic-analytics?country=${country}&category=${category}&demography=${ageGroupData}`}
    >
      {country} {category} ({ageGroupData})
    </Link>,
    <Typography variant="subtitle3" key="3">
      {trendName}
    </Typography>,
  ]

  return (
    <>
      <DemographicThemePageUI
        ageGroupGenX={ageGroupGenX}
        ageGroupGenZ={ageGroupGenZ}
        ageGroupMillanials={ageGroupMillanials}
        country={country}
        category={category}
        ageGroupTab={ageGroupTab}
        projectId={projectId}
        lens={lens}
        themeId={themeId}
        engagementScoreAvailable={engagementScoreAvailable}
        trendName={trendName}
        setAgeGroupTab={setAgeGroupTab}
        consumerQuotesData={consumerQuotesData}
        relatedTrends={relatedTrends}
        trendDistributionData={trendDistributionData}
        showLoader={showLoader}
        trendData={trendData}
        sentimentData={sentimentData}
        consumerInsightData={consumerInsightData}
        engagementScore={engagementScore}
        ageGroupData={ageGroupData}
        dataPoint={dataPoint}
        breadcrumbs={breadcrumbs}
        handleArcClick={handleArcClick}
        handleArcHover={handleArcHover}
        ageGroupHovered={ageGroupHovered}
        logTrendClick={logTrendClick}
        logConsumerQuotesClicked={logConsumerQuotesClicked}
        getRelatedTrends={getRelatedTrends}
        isDataInsignificant={isDataInsignificant}
        handleSnackBarRemove={handleSnackBarRemove}
        // fsProducts={fsProducts}
        // retailProducts={retailProducts}
        // fsProductFormat={fsProductFormat}
        // retailProductsFormat={retailProductsFormat}
      />
    </>
  )
}

export default DemographicThemePage
