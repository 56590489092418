import React, { useState, useEffect } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import cafe from '../../assets/mock/cafe-products.json'
import ProductFormatDetailsTop from '../../shared/components/ProductFormatTop'
import FoodServiceProducts from '../../shared/components/FoodServiceProducts'
import RelatedRetailProducts from '../../shared/components/RelatedRetailProducts'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import { downloadSection } from '../../const'
import OverlayLoader from '../../shared/components/OverlayLoader'
import '../muiStyle.css'

const ProductFormatPage = (props) => {
  const { country, category } = props
  const [data, setData] = useState(null)
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const result = await axios(
      NetworkUtil.fetchProductFormatDetails(
        props.match.params.productId,
        props.match.params.productType,
        encodeURIComponent(decodeURIComponent(props.match.params.formatName)),
      ),
    )

    setData(result.data)
  }

  const imageDownload = () => {
    setLoader(true)
  }

  useEffect(() => {
    if (loader) {
      const { keyword, country, category, project_variant } = data
      const dowSection = document.getElementById('_dowSectionDesc')
      const fileName = `${keyword}_${country}_${category}_Complete_Report.jpg`
      downloadSection(dowSection, fileName)
        .then(() => {
          setLoader(false)
        })
        .catch((er) => {
          setLoader(false)
          console.log(er)
        })
    }
  }, [loader])
  if (!data)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Box
          sx={{
            mt: 2,
            pt: 32,
            px: 88,
            flexGrow: 2,
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgressCustom />
        </Box>
      </Grid>
    )
  return (
    <Stack spacing={3} sx={{ my: 3 }}>
      {loader && <OverlayLoader />}
      <Box id="_dowSectionDesc">
        <Stack spacing={3}>
          <ProductFormatDetailsTop
            expand={loader}
            projectid={props.match.params.projectId}
            productid={data.product_id}
            productName={data.product_name}
            lensSelected={props.match.params.urlLensSelected}
            keyword={data.keyword}
            formats={data.formats}
            subFormats={data.sub_formats}
            imageDownload={imageDownload}
            productType={props.match.params.productType}
            country={data.country}
            category={data.category}
          />
          {props.match.params.productType === 'cafe_product' &&
            data.products.length != 0 && (
              <FoodServiceProducts
                noSide
                projectid={props.match.params.projectId}
                productid={data.product_id}
                cafeDataPoint={data.products.length}
                cafeProductFormats={data.sub_formats}
                cafeProducts={data.products}
                country={data.country}
                category={data.category}
                name={data.product_name}
                onRefresh={() => {
                  fetchData()
                }}
                lensSelected={props.match.params.urlLensSelected}
              />
            )}
          {props.match.params.productType === 'retail_product' &&
            data.products.length != 0 && (
              <RelatedRetailProducts
                noSide
                projectid={props.match.params.projectId}
                productid={data.product_id}
                projectVariant={data.project_variant}
                retailDataPoint={data.products.length}
                retailProductFormats={data.sub_formats}
                retailProducts={data.products}
                onRefresh={() => {
                  fetchData()
                }}
                country={data.country}
                category={data.category}
                name={data.product_name}
                lens={props.match.params.urlLensSelected}
              />
            )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default ProductFormatPage
