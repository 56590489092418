import * as AC from '../actions/actionConstants'
import { fromJS, List } from 'immutable'

// const saveDownloadList = window.sessionStorage.getItem('downloadSections')

// const initialState = {
//   list: saveDownloadList ? fromJS(JSON.parse(saveDownloadList).list) : fromJS({}) ,
//   selectAll: saveDownloadList ? JSON.parse(saveDownloadList).selectAll : true,
//   isShowSpinnerDownload: false,
// }

const initialState = {
  pendingRequests: [],
  list: fromJS({}) ,
  selectAll: true,
  isShowSpinnerDownload: false,
  loadingRequests: false,
  showSingleLoader: true,
}

// console.log(uptList.valueSeq().toJS())
/**
 * Checks the status of all checkboxes.
 * @param state
 * @returns {boolean}
 */
const checkAllStatus = (list) => {
  if (list.size === 0) return false

  let selectAll = false
  let allSelected = 0

  list.forEach(section => {
    if(section.get('isDownload') === true){
      allSelected++
    }
  })

  if (allSelected === list.size){
    selectAll = true
  }
  return selectAll
}

/**
 * Adds a file to the page list.
 * @param state
 * @param productName
 * @param section
 * @returns {{selectAll: *, list: *}}
 */
const sectionAdd = (state, { productName, section }) => {
  let uptList = state.list

  if (uptList.has(productName)){
    uptList = uptList.updateIn([productName, 'files'], (files) => files.concat(List.of(fromJS(section))))
  } else {
    const newFile = {
      isDownload: true,
      name: productName,
      files: List.of(fromJS(section)),
    }
    uptList = uptList.set(productName, fromJS(newFile))
  }
  uptList = uptList.sort(function (a, b){
    var nameA=a.get('name').toLowerCase(); var nameB=b.get('name').toLowerCase()
    if (nameA < nameB)
      return -1
    if (nameA > nameB)
      return 1
    return 0
  })
  return {
    ...state,
    list: uptList,
    selectAll: checkAllStatus(uptList),
  }
}

const sectionClear = (state, action) => {
  return {
    ...state,
  }
}
/**
 * Removes a page from the download list.
 * @param state
 * @param nameSection
 * @returns {{list: *}}
 */
const sectionRemove = (state, { nameSection }) => {
  const uptList = state.list.delete(nameSection)
  return {
    ...state,
    list: uptList,
  }
}
/**
 * Adds or removes a page from the download queue.
 * @param state
 * @param nameSection
 * @param status
 * @returns {{selectAll: *, list: *}}
 */
const sectionDownloadStatus = (state, { nameSection, status }) => {
  const uptList = state.list.updateIn([nameSection, 'isDownload'], (isDownload) => status)

  return {
    ...state,
    list: uptList,
    selectAll: checkAllStatus(uptList),
  }
}
/**
 * Selects all files selected for download or not.
 * @param state
 * @param status
 * @returns {{selectAll: *, list: *}}
 */
const sectionDownloadSelectAll = (state, { status }) => {
  let uptList = state.list
  const selectAll = !state.selectAll

  state.list.forEach((nameSection, key) => {
    uptList = uptList.updateIn(
      [key, 'isDownload'],
      (isDownload) => selectAll
    )
  })

  return {
    ...state,
    list: uptList,
    selectAll,
  }
}
/**
 * Download all files.
 * @param state
 * @param action
 */
const sectionDownload = (state, action) => {
  state.list.forEach((section, sectionName)=>{
    if (section.get('isDownload')) {
      section.get('files').forEach(f => {
        const file = f.toJS()
        window.saveAs(file.data, file.name)
      })
    }
  })
  return { ...state }
}

/**
 * Download single file.
 * @param state
 * @param action
 */
const singleDownload = (state, action) => {
  state.list.forEach((section)=>{
    if (section.get('name') === action.name) {
      section.get('files').forEach(f => {
        const file = f.toJS()
        window.saveAs(file.data, file.name)
      })
    }
  })
  return { ...state }
}

const downloadAll = (state) => {
  state.list.forEach((section)=>{
    section.get('files').forEach(f => {
      const file = f.toJS()
      window.saveAs(file.data, file.name)
    })
  })
  return { ...state }
}

const spinnerDownload = (state, action) => {
  const { isShow } = action.payload
  return {
    ...state,
    isShowSpinnerDownload: isShow,
  }
}

const logOut = (state, action) => {
  window.sessionStorage.removeItem('downloadSections')
  localforage.removeItem('downloadSections')
  return {
    ...state,
    list: fromJS({}) ,
    selectAll: true,
    isShowSpinnerDownload: false,
  }
}

const downloadSections = (state = initialState, action) => {
  switch (action.type) {
    case AC.DOWNLOAD_SECTION_ADD: return sectionAdd(state,action)
    case AC.DOWNLOAD_SECTION_CLEAR: return sectionClear(state,action)
    case AC.DOWNLOAD_SECTION_REMOVE: return sectionRemove(state,action)
    case AC.DOWNLOAD_SECTION_DOWNLOAD: return sectionDownload(state,action)
    case AC.DOWNLOAD_SINGLE_DOWNLOAD: return singleDownload(state,action)
    case AC.DOWNLOAD_SECTION_STATUS: return sectionDownloadStatus(state,action)
    case AC.DOWNLOAD_SECTION_SELECT_ALL: return sectionDownloadSelectAll(state,action)
    case AC.DOWNLOAD_ALL: return downloadAll(state)
    case AC.SPINNER_DOWNLOAD: return spinnerDownload(state,action)
    case AC.SET_USER_LOGGED_OUT: return logOut(state,action)
    case AC.ADD_REQUEST :
      return {...state, pendingRequests: [...state.pendingRequests,action.request], loadingRequests: true}
    case AC.DONE_REQUEST :
      const newPendingRequests = state.pendingRequests.filter(reqs => reqs.name !== action.request.name)
      return newPendingRequests.length === 0 ? 
      {...state, pendingRequests:[], loadingRequests: false } :
      {...state, pendingRequests: newPendingRequests}
    default:
      return state
    case AC.SINGLE_SPINNER_DOWNLOAD: return {...state, showSingleLoader: action.show}
  }
}

export default downloadSections
