import axios from '../../services/networking/swAxios'
import NetworkUtil from '../../services/networking/SWNetworkingUtil'
import {
  notifications,
  loadingStatus,
  screeningStatus as swStatus,
} from '../../const'
import { ScreenWinner } from './actionTypes'
import { addRequest, doneRequest } from './downloadSections'

const parsePrjDetails = (prj) => {
  const {
    concepts_count: conceptsCount,
    screening_status: screeningStatus,
    last_screened_at: lastScreened,
  } = prj
  return { ...prj, conceptsCount, screeningStatus, lastScreened }
}

const parseCncptDetails = (cncpt) => {
  const {
    screening_status: screeningStatus,
    keywords_analysed: keywordsAnalysed,
    originality_rank: originalityRank,
    originality_score: originalityScore,
    relevance_rank: relevanceRank,
    relevance_score: relevanceScore,
    final_rank: finalRank,
    description_html: descriptionHTML,
  } = cncpt
  const origScore = parseInt(originalityScore)
  const relScore = parseInt(relevanceScore)
  return {
    ...cncpt,
    screeningStatus,
    originalityRank,
    originalityScore: origScore,
    relevanceRank,
    relevanceScore: relScore,
    keywordsAnalysed,
    finalRank,
    descriptionHTML,
  }
}

export const fetchProjectsList = () => {
  return (dispatch) => {
    const url = NetworkUtil.projectCRUD()
    const requestFrom = {
      name: 'fetchProjectsList',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then(({ data }) => {
        const { data: projectsData } = data
        dispatch(
          fetchProjectsListSuccess(projectsData.projects.map(parsePrjDetails)),
        )
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        console.log(err)
        dispatch(fetchProjectsListFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const fetchProjectsListSuccess = (projectsList) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_SUCCESS],
  projectsList,
})

export const fetchProjectsListFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_FAILED],
  error,
})

export const fetchProjectDetails = (projectId, successCallback) => {
  return (dispatch) => {
    const url = NetworkUtil.projectDetails(projectId)
    const requestFrom = {
      name: 'fetchProjectDetails',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then(({ data }) => {
        const { data: projectDetails } = data
        dispatch(
          fetchProjectDetailsSuccess(parsePrjDetails(projectDetails.project)),
        )
        dispatch(doneRequest(requestFrom))
        successCallback && successCallback()
      })
      .catch((err) => {
        dispatch(fetchProjectDetailsFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const fetchProjectDetailsSuccess = (projectDetails) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_DETAILS_SUCCESS],
  projectDetails,
})

export const fetchProjectDetailsFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_DETAILS_FAILED],
  error,
})

export const fetchConcepts = (projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.conceptCRUD(projectId)
    const requestFrom = {
      name: 'fetchConcepts',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then(({ data }) => {
        const { data: conceptsList } = data
        dispatch(fetchConceptsSuccess(conceptsList.map(parseCncptDetails)))
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        console.log(err)
        dispatch(fetchConceptsFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const fetchConceptsSuccess = (conceptsList) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_SUCCESS],
  conceptsList,
})

export const fetchConceptsFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_FAILED],
  error,
})

export const updatingConcetpsFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.UPDATING_CONCEPTS_FAILED],
  status: loadingStatus.ERROR,
})

export const notify = ({ type, message, action }) => {
  return (dispatch) => {
    const timeId = new Date().getTime()
    let notification = { type, message, timeStamp: timeId, id: timeId }
    if (action) {
      notification = { ...notification, action }
    }
    dispatch(queueNotification(notification))
    setTimeout(() => dispatch(dequeNotification(timeId)), 3000)
  }
}

const queueNotification = (notification) => ({
  type: ScreenWinner.type[ScreenWinner.NOTIFICATION_ADD],
  notification,
})

export const dequeNotification = (id) => ({
  type: ScreenWinner.type[ScreenWinner.NOTIFICATION_REMOVE],
  id,
})

export const updateProjectDetails = (projectDetails, projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.projectDetails(projectId)
    const requestFrom = {
      name: 'updateProjectDetails',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    return axios
      .put(url, projectDetails)
      .then(({ data }) => {
        const { data: projectDetails } = data
        dispatch(
          fetchProjectDetailsSuccess(parsePrjDetails(projectDetails.project)),
        )
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
        return true
      })
      .catch((err) => {
        console.log(err)
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
        dispatch(fetchProjectDetailsFailed(err))
        dispatch(doneRequest(requestFrom))
        return false
      })
      .catch((e) => console.log(e))
  }
}

export const updateConceptDetails = (conceptDetails, conceptId, projectId) => {
  return (dispatch) => {
    const url = `${NetworkUtil.conceptCRUD(projectId)}${conceptId}/`
    const requestFrom = {
      name: 'updateConceptDetails',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .put(url, conceptDetails)
      .then(({ data }) => {
        fetchConcepts(projectId)(dispatch)
        fetchProjectDetails(projectId)(dispatch)
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
      })
      .catch((err) => {
        console.log(err)
        // dispatch(updatingConcetpsFailed(err))
        dispatch(fetchConceptsFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const saveConceptDetails = (conceptDetails, projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.conceptCRUD(projectId)
    const requestFrom = {
      name: 'saveConceptDetails',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .post(url, conceptDetails)
      .then(({ data }) => {
        fetchConcepts(projectId)(dispatch)
        fetchProjectDetails(projectId)(dispatch)
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
      })
      .catch((err) => {
        dispatch(fetchConceptsFailed(err))
        // dispatch(updatingConcetpsFailed(err))
        if (err.response?.data?.status === 409) {
          fetchConcepts(projectId)(dispatch)
          fetchProjectDetails(projectId)(dispatch)
        }
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const searchProjects = (term) => {
  return (dispatch) => {
    const url = NetworkUtil.search()
    const requestFrom = {
      name: 'searchProjects',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .post(url, {
        term,
        // search_type: "projects",
      })
      .then((res) => {
        const { concepts, projects } = res.data.data.search_result
        dispatch(
          searchProjectsSuccess(
            projects.map(parsePrjDetails),
            concepts.map(parseCncptDetails),
          ),
        )
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        dispatch(searchProjectsFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const searchConcepts = (projectId, term) => {
  return (dispatch) => {
    const url = NetworkUtil.search()
    const requestFrom = {
      name: 'searchConcepts',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .post(url, {
        term,
        project_id: Number(projectId),
        // search_type: "concepts",
      })
      .then((res) => {
        dispatch(
          searchConceptsSuccess(
            res.data.data.search_result.concepts.map(parseCncptDetails),
          ),
        )
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        dispatch(searchConceptsFailed(err))
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

const searchProjectsSuccess = (projectsList, conceptsList) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_SEARCH_QUERY_SUCCESS],
  projectsList,
  conceptsList,
})

const searchProjectsFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_SEARCH_QUERY_FAILED],
  error,
})

const searchConceptsSuccess = (conceptsList) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_SEARCH_QUERY_SUCCESS],
  conceptsList,
})

const searchConceptsFailed = (error) => ({
  type: ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_SEARCH_QUERY_FAILED],
  error,
})

export const clearSearchResults = () => {
  return (dispatch) =>
    dispatch({
      type: ScreenWinner.type[ScreenWinner.CLEAR_SEARCH_RESULTS],
    })
}

const screeningError = (message) => ({
  type: ScreenWinner.type[ScreenWinner.SCREENING_ERROR],
  message,
})

export const startScreeningProject = (projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.screenProject(projectId)
    const requestFrom = {
      name: 'startScreeningProject',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then(({ data }) => {
        fetchProjectDetails(projectId, () =>
          fetchConcepts(projectId)(dispatch),
        )(dispatch)
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
      })
      .catch((err) => {
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const checkScreeningStatus = (projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.checkScreeningStatus(projectId)
    const handleScreeningFailed = () => {
      notify({
        type: notifications.ERROR,
        message: 'Screening has failed. Please try again.',
      })(dispatch)
      fetchProjectDetails(projectId)(dispatch)
      fetchConcepts(projectId)(dispatch)
    }
    axios
      .get(url)
      .then(({ data }) => {
        const { status } = data.data.screening
        if (status === swStatus.FAILED) {
          // dispatch(screeningError("Screening Failed"));
          handleScreeningFailed()
        } else if (status === swStatus.SCREENED) {
          fetchProjectDetails(projectId)(dispatch)
          fetchConcepts(projectId)(dispatch)
        } else if (!status) {
          handleScreeningFailed()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const cloneConcept = (projectId, conceptId) => {
  return (dispatch) => {
    const url = `${NetworkUtil.conceptClone(projectId, conceptId)}`
    const requestFrom = {
      name: 'cloneConcept',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .post(url)
      .then(() => {
        fetchConcepts(projectId)(dispatch)
        fetchProjectDetails(projectId)(dispatch)
        dispatch(doneRequest(requestFrom))
      })
      .catch((err) => {
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const deleteConcept = (conceptId, projectId) => {
  return (dispatch) => {
    const url = `${NetworkUtil.conceptCRUD(projectId)}${conceptId}/?active=0`
    const requestFrom = {
      name: 'deleteConcept',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .delete(url)
      .then(({ data }) => {
        fetchConcepts(projectId)(dispatch)
        fetchProjectDetails(projectId)(dispatch)
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
      })
      .catch((err) => {
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}

export const deleteProject = (projectId) => {
  return (dispatch) => {
    const url = `${NetworkUtil.projectCRUDD(projectId)}`
    const requestFrom = {
      name: 'deleteProject',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .delete(url)
      .then(({ data }) => {
        fetchProjectsList()(dispatch)
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.SUCCESS,
          message: data.message,
        })(dispatch)
      })
      .catch((err) => {
        dispatch(doneRequest(requestFrom))
        notify({
          type: notifications.ERROR,
          message: err.response.data.message,
        })(dispatch)
      })
      .catch((e) => console.log(e))
  }
}
