import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import ConsumerQuotesContainer from './styles'
const ConsumerQuotesReview = ({
  timestamp,
  content,
  likes,
  link,
  lens,
  logConsumerQuotesClicked,
  platform,
}) => {
  const linkHandler = () => {
    window.open(link, '_blank')
 
  }
  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      <Box pt={2} px={2} mb={1} height={'290px'} overflow="auto">
        <Typography
          variant="body3"
          sx={{
            flexGrow: 1,
            overflowX: 'hidden',
          }}
        >
          {content}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          marginTop: '10px',
          marginLeft: '20px',
          display: 'flex',
          justifyContent: 'flex-end !important',
        }}
      >
        
        <Typography variant="body1">{platform}</Typography>
      </Box>
    </ConsumerQuotesContainer>
  )
}
ConsumerQuotesReview.propTypes = {
  timestamp: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}
export default ConsumerQuotesReview