import React from "react";
import PropTypes from "prop-types";

import closeIcon from "../../assets/images/screenWinner/swClose.svg";

import "./SWNotifications.scss";

const SWNotifications = ({ img, imgAlt, style, message, action, onClear }) => {
  return (
    <div className={`sw-notifications sw-notifications--${style}`}>
      <img className="sw-notifications__img" src={img} alt={imgAlt} />
      <span className="sw-notifications__message">{message}</span>
      {action && (
        <span role="button" tabIndex="0" onClick={action.onClick}>
          {action.text}
        </span>
      )}
      <img
        className="sw-notifications__clear"
        role="button"
        tabIndex="0"
        onClick={onClear}
        src={closeIcon}
        title="Clear notification"
        alt="Clear notification"
      />
    </div>
  );
};

SWNotifications.propTypes = {
  img: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  onClear: PropTypes.func.isRequired,
};

export default SWNotifications;
