import React from 'react'
import AgeGroupButtonsUI from './AgeGroupButtonsUI'

const AgeGroupButtons = ({
  viewMode,
  setChart,
  setTable,
  ageGroupTab,
  handleClickMillennials,
  handleClickGenZ,
  handleClickGenX,
  handleChange,
  handleSelectChange,
  allDropdownsList,
  applySelectedFilters,
  resetSelectedFilterValues,
  handleFilterClickOpen,
  handleCloseFilter,
  open,
  anchorEl,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
  handleSearch,
  ageGroupGenX,
  ageGroupGenZ,
  ageGroupMillanials,
}) => {
  return (
    <>
      <AgeGroupButtonsUI
        ageGroupGenX={ageGroupGenX}
        ageGroupGenZ={ageGroupGenZ}
        ageGroupMillanials={ageGroupMillanials}
        handleSearch={handleSearch}
        handleChange={handleChange}
        handleClickMillennials={handleClickMillennials}
        handleClickGenZ={handleClickGenZ}
        handleClickGenX={handleClickGenX}
        ageGroupTab={ageGroupTab}
        viewMode={viewMode}
        setChart={setChart}
        setTable={setTable}
        handleSelectChange={handleSelectChange}
        allDropdownsList={allDropdownsList}
        applySelectedFilters={applySelectedFilters}
        resetSelectedFilterValues={resetSelectedFilterValues}
        handleFilterClickOpen={handleFilterClickOpen}
        handleCloseFilter={handleCloseFilter}
        open={open}
        anchorEl={anchorEl}
        selectedDataPoints={selectedDataPoints}
        selected2YearCAGR={selected2YearCAGR}
        selected4YearCAGR={selected4YearCAGR}
      />
    </>
  )
}
export default AgeGroupButtons
