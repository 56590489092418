import React, { useState, useEffect } from "react";

import SearchOptionsDropdown from "./SearchOptionsDropdown";
import ImageSafe from "../Image/ImageSafe";

import downArrow from "../../assets/images/icons/downArrow.svg";

import "./SearchOptionsDropdown.scss";
import "./SearchOptsMultiSelect.scss";

const SearchOptsMultiSelect = ({
  data = [],
  placeholder = "",
  setDefaultVal,
  handleSearchInput,
  handleSelect,
  required,
  errorMessage = "",
  revalidate,
  revalidateCallback,
  defaultValues,
}) => {
  const allId = "sw-opts_all";
  const [searchInput, setSearchInput] = useState("");
  const [optSelected, setOptSelected] = useState([]);
  const [error, setError] = useState(false);

  const handleSelection = (selId) => {
    if (optSelected.find(({ id }) => id === selId)) {
      setOptSelected(optSelected.filter(({ id }) => id !== selId));
    } else {
      setOptSelected([...optSelected, data.find(({ id }) => id === selId)]);
    }
  };

  const selectAll = () => {
    if (optSelected.length === data.length) {
      setOptSelected([]);
    } else {
      setOptSelected(data);
    }
  };

  const optionItem = (id, name, img) => {
    const selected =
      !!optSelected.length && optSelected.some(({ id: optId }) => optId === id);
    const activeCls = "sw-option--active";
    const active =
      allId === id
        ? optSelected.length === data.length
          ? activeCls
          : ""
        : selected
        ? activeCls
        : "";
    return (
      <div
        key={id}
        className={`options-text imgtitle vertical-center ${active}`}
        style={{ width: "100%", height: 40 }}
        onClick={id === allId ? () => selectAll() : () => handleSelection(id)}
      >
        <span className="sopts__container">
          <input
            readOnly
            name={name}
            checked={active === activeCls}
            type="checkbox"
            value={name}
          />
          <span className="sopts__checkmark"></span>
        </span>
        <div className="sw-options--multi">
          <div>
            {id === allId ? null : (
              <ImageSafe
                className="option-element__img-blk"
                src={img}
                alt={name}
              />
            )}
          </div>
          <span className="options-text">{name}</span>
        </div>
      </div>
    );
  };

  const optionElem = (
    <div
      className={`option-element options-text imgtitle vertical-center ${
        error ? "sw-options--error" : ""
      }`}
    >
      <span className="sw-options--multi-selections">
        {!!optSelected.length
          ? optSelected.length === data.length ? 'All' : optSelected.map(({ name }) => name).join(", ")
          : placeholder}
      </span>
      <ImageSafe
        className="option-element__down-arrow"
        alt="down-arrow"
        src={downArrow}
      />
    </div>
  );

  const optionsList = !!data.length ? [{ id: allId, name: "All", img: "" }, ...data]
    .filter(({ name }) =>
      name.toLowerCase().includes(searchInput.toLowerCase())
    )
    .map(({ id, name, img }) => optionItem(id, name, img)) : []

  const errorCheck = () => {
    if (!optSelected && required) {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    handleSearchInput && handleSearchInput(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (setDefaultVal) {
      data && !!data.length && setOptSelected([data[0]]);
    }
  }, [setDefaultVal, data]);

  useEffect(() => {
    if (optSelected) {
      handleSelect && handleSelect(optSelected);
      errorCheck();
    }
  }, [optSelected]);

  useEffect(() => {
    if (revalidate) {
      errorCheck(revalidateCallback);
    }
  }, [revalidate]);

  useEffect(() => {
    if (error && revalidate) {
      revalidateCallback && revalidateCallback();
    }
  }, [error]);

  useEffect(() => {
    if (defaultValues && defaultValues.length) {
      setOptSelected(defaultValues);
    }
  }, [defaultValues]);

  return (
    <SearchOptionsDropdown
      inputStyle={"sw-input option-element"}
      optionElem={optionElem}
      options={optionsList}
      searchInputCallback={(val) => setSearchInput(val)}
      required
      errorMessage={error && errorMessage}
      inputInActiveCallback={errorCheck}
      multiSelect
    />
  );
};

export default SearchOptsMultiSelect;
