import React, { createContext, useState } from "react";

const DataContext = createContext();

let init_data = {
  all_conepts: [],
  current_concept: undefined,
  isLoading: false,
  errors: []
};

const DataContextProvider = (props) => {
  const [data, setData] = useState(init_data);

  const handleUpdateData = (new_data, key) => {
    let updated_data = { ...data };
    updated_data[key] = new_data;
    setData(updated_data);
  };

  const handleLoading = (isLoading = false) => {
    if (isLoading) {
      setData((prev_data) => ({
        ...prev_data,
        isLoading: true,
      }));
    } else {
      setData((prev_data) => ({
        ...prev_data,
        isLoading: false,
      }));
    }
  };

  const handleErrors = (errors) => {
    let updated_data = { ...data };
    updated_data["errors"] = errors;
    setData(updated_data);
  };

  console.log("__INIT__", data);

  return (
    <DataContext.Provider value={{ data, handleUpdateData, handleLoading, handleErrors }}>
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContextProvider, DataContext };
