import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import palette from '../palette';
import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        borderColor: palette.divider,
        background: palette.background.default,
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            opacity: 1,
          },
          '::-ms-input-placeholder': {
            opacity: 1,
          },
          ':-ms-input-placeholder': {
            opacity: 1,
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        padding: '0 8px',
        '& .MuiAutocomplete-input': {
          padding: '6px 3px',
        },
      },
    },
    popupIndicator: {
      color: palette.icon,
    },
    listbox: {
      padding: '8px 0',
      '& .MuiAutocomplete-option': {
        padding: 8,
        ...typography.subtitle2,
        '&:hover': {
          background: palette.background.default,
          color: palette.primary.dark,
        },
        '&:focus': {
          background: palette.grey[200],
          color: palette.primary.dark,
        },
        '&.Mui-focused': {
          background: palette.background.default,
        },
      },
    },
    paper: {
      border: `1px solid ${palette.divider}`,
      boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
      background: palette.background.paper,
    },
  },
  defaultProps: {
    popupIcon: <ExpandMoreIcon />,
  },
};
