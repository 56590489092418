import React, { useEffect, useState } from 'react'
import { ReactComponent as MinusIcon } from '../../assets/icons/feather-minus-circle.svg'
import Card from './CommonTrendAnalysisCard'
import { Typography, Box, CircularProgress, Grid, SvgIcon } from '@mui/material'
import { StyledCarousel, StyledGrid } from './Styled'

const CommonTrendAnalysisExpanded = props => {
  const { handleShrinkClick, 
    productComparisonData, 
    imageUrl, productName, 
    acrossSelected, 
    emergingInCategories, 
    trendType,
    lens
  } = props
  const [filterProduct, setFilterProduct] = useState([])

  useEffect(() => {
    let result = productComparisonData.filter(d => {
      return d.productDetails._name === productName
    })

    result &&
      result.sort((a, b) => {
        return a.productDetails._rank - b.productDetails._rank
    })

    if (acrossSelected === 'category') {
      result = result.filter(
        (value, index, self) =>
          index === self.findIndex(t => t.productDetails._category === value.productDetails._category)
      )
    } else if (acrossSelected === 'country') {
      result = result.filter(
        (value, index, self) =>
          index === self.findIndex(t => t.productDetails._country === value.productDetails._country)
      )
    }

    let i = 0
    let elementToRender = []
    while (i < result.length) {
      let slicedData = result.slice(i, i + 5)
      i += 5
      elementToRender.push(slicedData)
    }
    setFilterProduct(elementToRender)
  }, [productComparisonData, productName])

  const shrinkTrendCard = () => {
    handleShrinkClick()
    setFilterProduct([])
  }

  return (
    <>
      <StyledGrid
        container
        spacing={2}
        columns={16}
        my={2}
        backgroundColor={(theme) => theme.palette.white}
        onClick={ shrinkTrendCard }>
        <Grid item xs={1} textAlign="left" mt={1}>
          <SvgIcon component={MinusIcon} width="16" height="16" onClick={ shrinkTrendCard } cursor="pointer" />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={7}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={4} />
            <Grid item xs={3}>
              <Box component="img" mt={-1} src={imageUrl} alt="image not found" width="61px" height="49px" />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="subtitle1">
                {productName}
                <br />
                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                  ({trendType} in {emergingInCategories} categories)
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} />
      </StyledGrid>
      <StyledCarousel filterProductLength={filterProduct.length > 1}>
        {filterProduct.length > 0 ? (
          filterProduct.map(products => {
            return(
              <Card
              productComparisonData={products}
              productName={productName}
              acrossSelected={acrossSelected}
              lens={lens}
            />
            )
          })
        ) : (
          <Grid container height='440px' columns={12} alignContent='center'>
						<Grid item xs={6} />
						<Grid item xs={6}>
            	<CircularProgress />
						</Grid>
          </Grid>
        )}
      </StyledCarousel>
    </>
  )
}

export default CommonTrendAnalysisExpanded
