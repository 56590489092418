import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { useLoader } from '../../../hooks'
import { useHistory } from 'react-router'
import OverlayLoader from '../../../shared/components/OverlayLoader'
import TrendPageRegionalDistributionChartUI from './TrendPageRegionalDistributionChartUI'

import { amplify } from '../../../store/utility'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
      Lens: ampiUserData.lens,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
    }
    switch (ampiEvent) {
      case 'Clicked_Regional_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Region_Name: ampiUserData.region,
          Redirected_From: ampiUserData.redirectFrom,
          Trend_Name: ampiUserData.trendName,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch {
    console.log('Amplitude error in Demography Analysis')
  }
}

const TrendPageRegionalDistributionChart = (props) => {
  const history = useHistory()
  const {
    trendName,
    country,
    category,
    projectId,
    lens,
    productId,
    email,
  } = props
  const [regionalDistributionData, setRegionalDistributionData] = useState()
  const [loader, setLoader] = useLoader()

  const fetchRegionalDistribution = () => {
    setLoader(true)
    const regionalDistributionRequest = NetworkingUtil.regionalDistributionForCountryGraph(
      country,
      category,
      trendName,
      lens?.toLowerCase(),
    )
    axios
      .get(regionalDistributionRequest)
      .then((res) => {
        const regionalDistributionResponse = res.data.data.distribution
        props.onDataFetchCallback(regionalDistributionResponse)
        setRegionalDistributionData(regionalDistributionResponse)
        setLoader(false)
      })
      .catch(() => {
        props.onDataFetchCallback([])
        setLoader(false)
        console.log(
          'error in getting regional distribution for country graph data',
        )
      })
  }

  const logRegionClick = (region) => {
    const ampiEvent = 'Clicked_Regional_Trend'
    const ampiUserData = {
      email,
      lens,
      country,
      category,
      region,
      redirectFrom: 'Trend Page',
      trendName,
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }

  const handleRegionClick = (selectedRegion) => {
    window.open(
      `/mui/regional-analytics/?projectId=${encodeURIComponent(
        projectId,
      )}&lens=${encodeURIComponent(lens)}&country=${encodeURIComponent(
        country,
      )}&category=${encodeURIComponent(category)}&region=${encodeURIComponent(
        selectedRegion.label,
      )}&trend=${encodeURIComponent(trendName)}&trendId=${encodeURIComponent(
        productId,
      )}`,
      '_blank',
    )
    logRegionClick(selectedRegion.label)
  }

  useEffect(() => {
    fetchRegionalDistribution()
  }, [])

  return (
    <>
      {loader && <OverlayLoader />}
      <TrendPageRegionalDistributionChartUI
        regionalDistributionData={regionalDistributionData}
        trendName={trendName}
        country={country}
        category={category}
        handleRegionClick={handleRegionClick}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default connect(mapStateToProps)(TrendPageRegionalDistributionChart)
