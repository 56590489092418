import React, { Fragment, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CommonSectionContainer,
  SectionFiltersContainer,
  SectionHeaderContainer,
} from './styles'

import _ from 'lodash'
import {
  Box,
  Button,
  Divider,
  Link,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { useLoader } from '../../../hooks'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { amplify, getSignedFloat } from '../../../store/utility'
import axios from '../../../services/networking/axios'
import OverlayLoader from '../OverlayLoader'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import formatNumber from '../../utils/formatNumber'
import TextOverflowTooltip from '../TextOverflowTooltip'
import GenericTableComponent from '../GenericTableComponent'
import MultiSelectCheckboxGroup from '../MultiSelectCheckboxGroup'
import ChipFilters from './ChipFilters'
import SingleSelectFilter from './SingleSelectFilter'
import { INGREDIENT, PHASES } from '../../utils/constants'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const { email, country, category } = ampiInstance
    const { name, lens } = ampiUserData
    const amplitudeUserData = {
      User: email,
      Country: country,
      Category: category,
      Trend_Name: name,
      Lens: lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, email)
  } catch (err) {
    console.log(err, 'Amplitude error in RelatedTrends')
  }
}

const ASSOCIATION_LEVELS = ['Medium', 'High']

function descendingComparator(a, b, orderBy) {
  if (a[orderBy] < b[orderBy]) {
    return -1
  }
  if (a[orderBy] > b[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(a, b, order, orderBy) {
  return order === 'desc'
    ? -descendingComparator(a, b, orderBy)
    : descendingComparator(a, b, orderBy)
}

const RelatedTrends = (props) => {
  const {
    relatedTrends,
    defaults,
    parentTrendName,
    lens,
    blockName,
    disableDownload,
    disableTrendClick,
    tooltip,

    // Sub category props
    disableSubCategory,
    selectedSubCategory,
    onSubCategoryChange,
    subCategoriesList,

    showAssocLvl,
    subtitle,
    getRedirectionUrl,

    type,
    logTrendClick,
  } = props
  const { defaultSortOrder, defaultOrderKey, defaultMaturityPhase } = defaults

  const [showLoader, setShowLoader] = useLoader()
  const [order, setOrder] = useState(defaultSortOrder || 'asc')
  const [orderBy, setOrderBy] = useState(defaultOrderKey || '')
  const [maturityPhase, setMaturityPhase] = useState(
    defaultMaturityPhase || PHASES[2],
  )
  const [assocLvl, setAssocLvl] = useState(ASSOCIATION_LEVELS)

  // Get specific maturity phase Association level filtered trends list
  const getAssocLvlFilteredTrends = (
    relatedTrends,
    allAssocLvls,
    maturityPhase,
  ) => {
    if (!_.isEmpty(relatedTrends)) {
      const keysSelected = Object.keys(relatedTrends).filter((assocLvlKey) =>
        allAssocLvls.includes(_.startCase(assocLvlKey)),
      )
      if (keysSelected?.length) {
        const assocLvlFilteredTrends = keysSelected.reduce(
          (acc, keyOfAssocLvl) => [
            ...acc,
            ...relatedTrends[keyOfAssocLvl].find(
              (trend) => trend.type === maturityPhase + ' Trends',
            ).allTrendResults,
          ],
          [],
        )
        return assocLvlFilteredTrends
      }
    }
    return []
  }

  // Get association levels accumulated Related Trends List
  const getAssocLvlAccumulatedTrends = (keysOfAssocLvl, relatedTrends) => {
    const relatedTrendsList = []
    for (const assocLvlKey of keysOfAssocLvl) {
      const trendsListOfLvl = relatedTrends[assocLvlKey]
      trendsListOfLvl.forEach((obj) => {
        const maturityPhaseFilteredData = relatedTrendsList.find(
          (maturityPhaseData) => maturityPhaseData.type === obj.type,
        )
        if (maturityPhaseFilteredData) {
          maturityPhaseFilteredData.allTrendResults.push(...obj.allTrendResults)
        } else {
          relatedTrendsList.push({
            type: obj.type,
            allTrendResults: [...obj.allTrendResults],
          })
        }
      })
    }
    return relatedTrendsList
  }

  // Selected Maturity Phase Sorted Trends List(After filtering based on association levels)
  const sortedTrendsList = useMemo(() => {
    if (showAssocLvl) {
      // Based on Association Levels
      return getAssocLvlFilteredTrends(
        relatedTrends,
        assocLvl,
        maturityPhase !== PHASES[3] ? maturityPhase : 'Mature',
      )
        ?.slice()
        .sort((a, b) => getComparator(a, b, order, orderBy))
    }
    return relatedTrends
      ?.find(
        (trend) =>
          trend.type ===
          (maturityPhase !== PHASES[3] ? maturityPhase : 'Mature') + ' Trends',
      )
      .allTrendResults?.slice()
      .sort((a, b) => getComparator(a, b, order, orderBy))
  }, [order, orderBy, maturityPhase, assocLvl, relatedTrends])

  const handleAssocLvlChange = (selectedItems) => {
    setAssocLvl(Object.values(selectedItems).map((obj) => obj.label))
  }

  const handleMaturityPhaseClick = (phase) => {
    setMaturityPhase(phase)
  }

  const logDownload = () => {
    const ampiEvent = `Download_Related_${blockName}s`
    const ampiUserData = {
      name: parentTrendName,
      lens: lens,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const onDownloadClick = () => {
    logDownload()
    let relatedTrendsList = []
    if (showAssocLvl) {
      const assocLvlKeys = Object.keys(relatedTrends)
      relatedTrendsList = [
        ...getAssocLvlAccumulatedTrends(assocLvlKeys, relatedTrends),
      ]
    } else {
      relatedTrendsList = [...relatedTrends]
    }
    const relatedTrendsPayload = {
      trends: relatedTrendsList.map(({ type, allTrendResults }) => ({
        type,
        allTrendResults: allTrendResults.map(
          ({
            name,
            data_point: dataPoint,
            two_year_cagr: twoYearCagr,
            four_year_cagr: fourYearCagr,
          }) => ({
            name,
            data_point: dataPoint,
            two_year_cagr: Number(twoYearCagr.toFixed(2)),
            four_year_cagr: Number(fourYearCagr.toFixed(2)),
          }),
        ),
      })),
      lens: blockName.toLowerCase(),
    }
    // TODO: Should change name of below
    const request = NetworkUtil.downloadRelatedThemes()
    setShowLoader(true)
    const saveAs = `Related ${blockName}s - ${parentTrendName}.xlsx`
    axios
      .post(request, relatedTrendsPayload, { responseType: 'blob' })
      .then(({ data }) => {
        window.saveAs(data, saveAs)
        setShowLoader(false)
      })
      .catch((err) => {
        // TODO: Should change it to snackbar(Like we do in Pairing IFF)
        console.log(err)
        setShowLoader(false)
      })
  }

  const handleTrendNameClick = (trendDetail) => {
    if (type) {
      logTrendClick(
        trendDetail,
        `Related ${_.startCase(
          blockName === INGREDIENT ? `${blockName}s` : blockName,
        )} from ${lens}`,
      )
    }
  }

  // Get First(Item) Column Cell Node in the table
  const getTrendNameNodeChild = (data) => (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '200px' }}>
      <img
        src={data.image}
        height={36}
        width={36}
        style={{ borderRadius: 4, objectFit: 'cover' }}
      />
      <TextOverflowTooltip title={data.name} maxLineCount={1}>
        <Typography
          variant="subtitle3"
          sx={{
            display: 'inline',
            alignItems: 'center',
            marginLeft: '10px',
            color: 'primary.main',
          }}
        >
          {data.name}
        </Typography>
      </TextOverflowTooltip>
    </Box>
  )
  // Get First(Item) Column Cell Node in the table based on disableTrendClick(types: Clickable, Non Clickable)
  const getTrendNameNode = (data, disableTrendClick) => {
    if (disableTrendClick) {
      return getTrendNameNodeChild(data)
    }
    return (
      <Link
        onClick={() => handleTrendNameClick(data)}
        target="_blank"
        underline="none"
        variant="body2"
        href={() => getRedirectionUrl(blockName, data)}
      >
        {getTrendNameNodeChild(data)}
      </Link>
    )
  }

  const getRenderNode = (innerHTML) => <TableCell>{innerHTML}</TableCell>

  const commonTableHeaderCellItems = {
    disablePadding: true,
    width: '100px',
    sorting: true,
  }
  const headerCellsTooltips = {
    data_point: 1,
    two_year_cagr: 2,
    four_year_cagr: 2,
    tooltip: {
      1: 'Based on consumer engagement from social platforms',
      2: 'Based on social and search data at category level, and social data at sub category level',
    },
  }
  const headerCells = [
    {
      ...commonTableHeaderCellItems,
      id: 'name',
      numeric: false,
      label: 'Item',
      func: (data) => getRenderNode(getTrendNameNode(data, disableTrendClick)),
    },
    {
      ...commonTableHeaderCellItems,
      id: 'data_point',
      numeric: true,
      label: 'Data Points',
      tooltipText: headerCellsTooltips.tooltip[headerCellsTooltips.data_point],
      func: (data) => getRenderNode(formatNumber(data.data_point)),
    },
    {
      ...commonTableHeaderCellItems,
      id: 'two_year_cagr',
      numeric: true,
      label: '2 Year CAGR',
      tooltipText:
        headerCellsTooltips.tooltip[headerCellsTooltips.two_year_cagr],
      func: (data) =>
        getRenderNode(`${getSignedFloat(data.two_year_cagr, 2)}%`),
    },
    {
      ...commonTableHeaderCellItems,
      id: 'four_year_cagr',
      numeric: true,
      label: '4 Year CAGR ',
      tooltipText:
        headerCellsTooltips.tooltip[headerCellsTooltips.four_year_cagr],
      func: (data) =>
        getRenderNode(`${getSignedFloat(data.four_year_cagr, 2)}%`),
    },
  ]

  const genericTableComponentProps = {
    tableContainerProps: {
      sx: {
        height: sortedTrendsList?.length > 0 && 400,
        marginTop: '10px',
      },
    },
    tableProps: {
      stickyHeader: true,
      sx: { minWidth: 650 },
      'aria-label': `related ${blockName.toLowerCase()} section`,
    },
    genericTableHeaderProps: {
      headCells: headerCells,
      orderBy: orderBy,
      order: order,
      handleRequestSort: handleSort,
    },
    listOfRows: sortedTrendsList,
    tableRowProps: {
      sx: {
        cursor: !disableTrendClick && 'pointer',
      },
    },
    genericTableCellProps: {
      tableHeadCells: headerCells,
    },
    emptyStateText: `Related ${blockName}`,
  }

  return (
    <Fragment>
      {showLoader && <OverlayLoader />}
      <CommonSectionContainer>
        <SectionHeaderContainer>
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h3">Related {blockName}s&nbsp;</Typography>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <InfoOutlinedIcon
                    sx={{ color: ({ palette }) => palette.text.secondary }}
                    fontSize="small"
                  />
                </Tooltip>
              )}
            </Box>
            <Typography variant="subtitle2" color="textSecondary" pt={0.5}>
              {subtitle}
            </Typography>
          </Box>
          <Box display="flex">
            {!disableDownload && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadRoundedIcon />}
                onClick={onDownloadClick}
              >
                Download
              </Button>
            )}
          </Box>
        </SectionHeaderContainer>
        <Box
          sx={{
            mx: -2,
          }}
        >
          <Divider />
        </Box>
        <SectionFiltersContainer>
          <ChipFilters
            headerText="Trend Maturity Phase:"
            chipButtons={PHASES}
            selectedChip={maturityPhase}
            onClick={handleMaturityPhaseClick}
          />
          <Box display="flex">
            {!disableSubCategory && (
              <SingleSelectFilter
                label={`${blockName}s Filter`}
                type="subcategory"
                selectedItem={selectedSubCategory}
                onChange={onSubCategoryChange}
                items={subCategoriesList}
              />
            )}
            {showAssocLvl && (
              <MultiSelectCheckboxGroup
                headerText="Association level of results"
                allCheckboxes={ASSOCIATION_LEVELS.map((label) => ({
                  label: label,
                  checked: true,
                }))}
                onChange={handleAssocLvlChange}
              />
            )}
          </Box>
        </SectionFiltersContainer>
        <GenericTableComponent {...genericTableComponentProps} />
      </CommonSectionContainer>
    </Fragment>
  )
}

RelatedTrends.propTypes = {
  relatedTrends: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          allTrendResults: PropTypes.array,
        }),
      ),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        allTrendResults: PropTypes.array,
      }),
    ),
  ]),
  defaults: PropTypes.shape({
    defaultSortOrder: PropTypes.string,
    defaultOrderKey: PropTypes.string,
    defaultMaturityPhase: PropTypes.string,
  }),
  parentTrendName: PropTypes.string,
  lens: PropTypes.string,
  blockName: PropTypes.string,
  disableDownload: PropTypes.bool,
  disableTrendClick: PropTypes.bool,
  tooltip: PropTypes.string,

  // Sub category props
  disableSubCategory: PropTypes.bool,
  selectedSubCategory: PropTypes.string,
  onSubCategoryChange: PropTypes.func,
  subCategoriesList: PropTypes.arrayOf(PropTypes.object),

  showAssocLvl: PropTypes.bool,
  subtitle: PropTypes.string,
  getRedirectionUrl: PropTypes.func,
  logTrendClick: PropTypes.func,
}

RelatedTrends.defaultProps = {
  defaults: {
    defaultSortOrder: 'asc',
    defaultOrderKey: '',
    defaultMaturityPhase: 'Growing',
  },
  blockName: 'Ingredient',
  subtitle:
    'Trends associated with this particular topic based on consumer conversations',
}

export default RelatedTrends
