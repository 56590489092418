import React from 'react'
import {
  TextField,
  ToggleButton,
  Typography,
  Stack,
  DialogContent,
  Divider,
} from '@mui/material'
import ToggleButtonGroup from '../../../shared/components/ToggleButtonGroup'
import {
  StyledDialog,
  StyledDialogTitle,
  CreateTabStyling,
  StyledStack,
  StyledButton,
} from '../../../shared/components/TrendBucket/partials/CreateImportDialog/style'
import { LENSES } from '../../../shared/utils/constants'

const MergeTrendPillarPopUp = ({
  open,
  setPopUp,
  lensSelected,
  selectedCategoryForMerging,
  selectedCountryForMerging,
  handleBucketNameChange,
  handleSubmitClick,
  bucketName,
  setBucketName,
  mergePillarPopupError,
  logMergePillarPopupActionClicked,
}) => {
  const handleCancelClick = () => {
    logMergePillarPopupActionClicked('Cancel')
    setPopUp(false)
    setBucketName('')
  }
  return (
    <>
      <StyledDialog open={open}>
        <StyledDialogTitle>Merge Trend Pillars</StyledDialogTitle>
        <Divider />
        <DialogContent sx={CreateTabStyling}>
          {' '}
          <StyledStack spacing={'23px'}>
            <TextField
              label="New Pillar Name"
              placeholder="e.g. Taste"
              onChange={handleBucketNameChange}
              value={bucketName}
            />
            {mergePillarPopupError && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.error.main,
                  marginLeft: '200px',
                  marginTop: '5px',
                }}
              >
                {mergePillarPopupError}
              </Typography>
            )}
            <TextField
              disabled
              label="Country"
              placeholder="e.g. China"
              defaultValue=""
              value={selectedCountryForMerging}
            />
            <TextField
              label="Category"
              placeholder="e.g. Snacks"
              disabled
              value={selectedCategoryForMerging}
            />
            <Typography
              disabled
              marginRight="auto"
              mb={1}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                color: ({ palette }) => palette.text.disabled,
              }}
            >
              Lens
            </Typography>
            <ToggleButtonGroup
              exclusive
              disabled={true}
              ariaLabel="Lens"
              sx={{
                '& .MuiButtonBase-root': {
                  width: '124px',
                  marginLeft: '0px',
                },
                '& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#B1C1D2 !important',
                },
              }}
              value={lensSelected}
            >
              {LENSES.map((lens) => (
                <ToggleButton value={lens} aria-label={`${lens}s`}>
                  {`${lens}s`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Stack justifyContent="flex-end" direction="row" spacing={1.25}>
              <StyledButton
                width="120px !important"
                variant="outlined"
                onClick={handleCancelClick}
              >
                Cancel
              </StyledButton>
              <StyledButton
                width="120px !important"
                variant="contained"
                onClick={handleSubmitClick}
                disabled={mergePillarPopupError}
              >
                Merge
              </StyledButton>
            </Stack>
          </StyledStack>
        </DialogContent>
      </StyledDialog>
    </>
  )
}
export default MergeTrendPillarPopUp
