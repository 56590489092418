import { Box, SvgIcon, Typography } from '@mui/material'

import React from 'react'
import DashboardTooltip from '../DashboardTooltip'
import { ReactComponent as MaturityPhaseTransition } from '../../../assets/icons/maturity-phase-transition.svg'

const DashboardClassificationLabel = (props) => {
  const {
    boxProps,
    title,
    classification,
    classificationPrevious,
    hasAmplitudeEvent,
  } = props
  const handleHoverOpen = () => {
    if (hasAmplitudeEvent) {
      hasAmplitudeEvent.handleHoverOpen('Maturity Phase')
    }
  }
  return (
    <Box {...boxProps}>
      {title && (
        <Typography
          variant="subtitle2"
          sx={{
            color: ({ palette }) => palette.text.secondary,
          }}
        >
          {title}
        </Typography>
      )}
      <DashboardTooltip
        title={
          classificationPrevious && classification !== classificationPrevious
            ? `Moved from ${classificationPrevious} to ${classification}`
            : `is in the ${classification} Maturity Phase`
        }
        handleOpen={handleHoverOpen}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            color: ({ palette }) =>
              palette.colors[classification.toLowerCase()],
            gap: 0.5,
          }}
        >
          <SvgIcon component={MaturityPhaseTransition} fontSize="1rem" />
          <Typography
            sx={{
              color: ({ palette }) =>
                palette.colors[classification.toLowerCase()],
            }}
          >
            {classification}
          </Typography>
        </Box>
      </DashboardTooltip>
    </Box>
  )
}

export default DashboardClassificationLabel
