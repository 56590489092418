import React from 'react'
import { connect } from 'react-redux'

import {
  Box,
  Breadcrumbs,
  Typography,
  SvgIcon,
  Grid,
  Divider,
  Link,
} from '@mui/material'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RelatedIngredients from '../../../../shared/components/RelatedIngredients'
import PieChart from '../../PieChart'
import SentimentCard from '../../../../shared/components/SentimentCard'
import MonthlyTrendsMui from '../../../../components/Products/ProductDetailsPage/ProductDetailsInfo/Charts/MonthlyTrendsMui/MonthlyTrendsMui'
import DetailedConsumerInsights from '../../../../shared/components/DetailedConsumerInsights'
import ConsumerQuotesCardComponent from '../../../../shared/components/ConsumerQuotesCardComponent'
import OverlayLoader from '../../../../shared/components/OverlayLoader'
import MainCommonTabs from '../../../../shared/components/MainCommonTabs/MainCommonTabs'
import { ReactComponent as LineChartIcon } from '../../../../assets/icons/line-chart.svg'
import { ReactComponent as GenX } from '../../../../assets/icons/gen_x.svg'
import { ReactComponent as Millennials } from '../../../../assets/icons/gen_z.svg'
import { ReactComponent as GenZ } from '../../../../assets/icons/millennials.svg'
import formatNumber from '../../../../shared/utils/formatNumber'
// import FoodServiceProducts from '../../../../shared/components/FoodServiceProducts'
// import RelatedRetailProducts from '../../../../shared/components/RelatedRetailProducts'
import RelatedTrends from '../../../../shared/components/RelatedTrends'
import {
  DEMOGRAPHIC_AGE_RANGES,
  AGE_GROUP_GEN_X,
  AGE_GROUP_GEN_Z,
  AGE_GROUP_MILLENNIALS,
  INSIGNIFICANT_DATA_MESSAGE,
} from '../../../../shared/utils/constants'

import InformationIcon from '../../../../shared/components/InformationIcon'
import FixedSnackbar from '../../../../components/FixedSnackbar/FixedSnackbar'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

const RELATED_TRENDS_TOOLTIP = `The data shown represents the overall age group distribution across maturity phases.`

const DempgraphicThemePageUI = ({
  engagementScoreAvailable,
  subscriptionDetails,
  trendName,
  consumerQuotesData,
  relatedTrends,
  trendDistributionData,
  showLoader,
  trendData,
  sentimentData,
  consumerInsightData,
  engagementScore,
  ageGroupData,
  dataPoint,
  breadcrumbs,
  handleArcHover,
  handleArcClick,
  ageGroupHovered,
  projectId,
  themeId,
  lens,
  country,
  category,
  ageGroupTab,
  ageGroupGenX,
  ageGroupGenZ,
  ageGroupMillanials,
  logTrendClick,
  logConsumerQuotesClicked,
  getRelatedTrends,
  isDataInsignificant = false,
  handleSnackBarRemove,
  dashboardList,
  // fsProducts,
  // retailProducts,
  // fsProductFormat,
  // retailProductsFormat,
}) => {
  const projectVariant = dashboardList?.find(
    (dashboard) =>
      dashboard.criteria === country && dashboard.name === category,
  )?._projectVariant

  const ageGroupIcons = {
    Millennials: Millennials,
    'Gen Z': GenZ,
    'Gen X': GenX,
  }

  const getColor = (name, ageGroupData, ageGroupHovered) => {
    if (name === ageGroupData || name === ageGroupHovered) {
      switch (name) {
        case ageGroupMillanials:
          return (theme) => theme.palette.primary.light
        case ageGroupGenZ:
          return (theme) => theme.palette.secondary.light
        case ageGroupGenX:
          return (theme) => theme.palette.primary.dark
        default:
          break
      }
    }
    switch (name) {
      case ageGroupGenZ:
        return (theme) => theme.palette.action.selected
      case ageGroupGenX:
        return (theme) => theme.palette.grey[800]
      case ageGroupMillanials:
        return (theme) => theme.palette.grey[600]
      default:
        return ''
    }
  }
  return (
    <Box>
      <MainCommonTabs subscriptionDetails={subscriptionDetails} />
      {showLoader && <OverlayLoader />}

      <Breadcrumbs
        sx={{ marginTop: '15px' }}
        aria-label="breadcrumb"
        separator={<NavigateNextIcon />}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Box
        sx={{
          marginTop: '30px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4">{lens} Details</Typography>
        {isDataInsignificant && (
          <FixedSnackbar
            preIcon={
              <InfoIcon
                fontSize="small"
                sx={{ color: ({ palette }) => palette.warning.light }}
              />
            }
            postIcon={<CloseIcon fontSize="small" color="action" />}
            message={INSIGNIFICANT_DATA_MESSAGE}
            postIconAction={handleSnackBarRemove}
            postIconSx={{ paddingLeft: '30px' }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ marginRight: '30px' }}>
          <Typography variant="h3">
            <Link
              onClick={() => logTrendClick(trendName, 'Demography Trend Page')}
              sx={{
                color: ({ palette }) =>
                  `${palette.colors.hoverLink} !important`,
                '&:visited': {
                  color: ({ palette }) =>
                    `${palette.colors.hoverLink} !important`,
                },
                '&:focus': {
                  color: ({ palette }) =>
                    `${palette.colors.hoverLink} !important`,
                  textDecorationColor: ({ palette }) =>
                    `${palette.colors.hoverLink} !important`,
                  textDecorationLine: 'underline !important',
                },

                '&:hover': {
                  color: ({ palette }) => `${palette.black} !important`,
                  textDecorationColor: 'none !important',
                  textDecorationLine: 'none !important',
                },
              }}
              fontSize={'22px !important'}
              target="_blank"
              to={`/mui/details/${projectId}/${lens.charAt(0).toLowerCase() +
                lens.slice(1)}/${themeId}`}
            >
              {trendName}
            </Link>{' '}
            in {ageGroupData}
          </Typography>
        </Box>
        <Divider sx={{ height: '20px' }} orientation="vertical" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginLeft: '30px',
            flex: 1,
            marginRight: '10px',
          }}
        >
          <SvgIcon component={LineChartIcon} />
          <Typography variant="subtitle1">
            {formatNumber(dataPoint)} Data Points
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            gap: '20px',
            marginTop: '15px',
          }}
        >
          <Box
            sx={{
              background: 'white',
              boxShadow: '0px 4px 12px 0px #272B3108',
              width: '1400px',
              p: 2,
            }}
          >
            {engagementScore.length > 0 && engagementScoreAvailable ? (
              <MonthlyTrendsMui
                noConf={true}
                topicChart={true}
                informationBox={true}
                trendData={engagementScore}
                yLabel={'Engagement score'}
                trendCoefficient={trendData.four_year_coefficient}
                trendIntercept={trendData.four_year_intercept}
                shouldModifyData={true}
                twoYearCagr={trendData.two_year_cagr}
                showGrowthChip
              />
            ) : (
              !showLoader && (
                <Box sx={{ position: 'relative', top: '45%', left: '15%' }}>
                  <Typography variant="h4">
                    The graph is currently unavailable due to insufficient
                    consumer conversations
                  </Typography>
                </Box>
              )
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ height: '150px', width: '350px' }}>
              <SentimentCard
                positiveSentiment={sentimentData.positive_sentiment}
                neutralSentiment={sentimentData.neutral_sentiment}
                negativeSentiment={sentimentData.negative_sentiment}
                paperStyles={{
                  justifyContent: 'flex-end',
                  gap: 3.25,
                }}
                flippedSentimentCard
              />
            </Box>
            <Box
              sx={{
                width: '350px',
                height: '170px',
                boxShadow: '0px 4px 12px 0px #272B3108',
                backgroundColor: (theme) => theme.palette.white,
                pl: 1,
                marginTop: '20px',
              }}
            >
              {!!trendDistributionData?.length && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pt: 1,
                  }}
                >
                  <Box mt={5.5}>
                    <PieChart
                      fontSizeValue={'14px'}
                      fontSizeText={'10px'}
                      fontSize={'9px'}
                      thickness={'16'}
                      arcSelected={ageGroupData}
                      width="98"
                      height="98"
                      data={trendDistributionData && trendDistributionData}
                      clickable={true}
                      handleArcHover={handleArcHover}
                      handleArcClick={handleArcClick}
                      arcHighlighted={ageGroupHovered}
                    />
                  </Box>
                  <Grid mt={2} ml={2}>
                    <Typography
                      sx={{ display: 'flex', mb: 1.5 }}
                      variant="subtitle1"
                    >
                      Age group distribution
                      <InformationIcon
                        sx={{ ml: 0.5 }}
                        tooltipText={
                          <>
                            <Box>{`${AGE_GROUP_GEN_X}: ${DEMOGRAPHIC_AGE_RANGES.ageGroupGenX} years old`}</Box>
                            <Box>{`${AGE_GROUP_MILLENNIALS}: ${DEMOGRAPHIC_AGE_RANGES.ageGroupMillennials} years old`}</Box>
                            <Box>{`${AGE_GROUP_GEN_Z}: ${DEMOGRAPHIC_AGE_RANGES.ageGroupGenZ} years old`}</Box>
                          </>
                        }
                      />
                    </Typography>
                    {trendDistributionData &&
                      trendDistributionData.map((i) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <SvgIcon
                              component={ageGroupIcons[i.name]}
                              sx={{
                                fontSize: '30px',
                                color: getColor(
                                  i.name,
                                  ageGroupData,
                                  ageGroupHovered,
                                ),

                                opacity:
                                  i.name === ageGroupHovered ? '0.85' : '1',
                                marginRight: '5px',
                              }}
                            />
                          </Box>

                          <Typography
                            variant="subtitle2"
                            sx={{
                              display: 'inline',
                              textAlign: 'center',
                              cursor: 'pointer',
                              color: getColor(
                                i.name,
                                ageGroupData,
                                ageGroupHovered,
                              ),
                            }}
                            onClick={() => {
                              handleArcClick(i.name)
                            }}
                            onMouseEnter={() => {
                              handleArcHover(i.name)
                            }}
                            onMouseOut={() => {
                              handleArcHover()
                            }}
                          >
                            {i.name} - {i.value}%
                          </Typography>
                        </Box>
                      ))}
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: '30px',
          }}
        >
          <DetailedConsumerInsights
            demographyComponent
            categoryData={consumerInsightData}
            lensSelected="theme"
            productType="consumerNeeds"
          />
        </Box>

        {lens === 'Theme' ? (
          <>
            <Box sx={{ marginTop: '2rem' }}>
              <RelatedIngredients
                dowImageType="RelatedIngredients"
                blockName="Ingredients"
                lens="Theme"
                productType="relatedIngredients"
                relatedTrends={relatedTrends?.related_ingredients}
                demographyComponent
                tooltip={RELATED_TRENDS_TOOLTIP}
              />
            </Box>
            <Box
              sx={{
                marginTop: '2rem',
                marginBottom: '2rem',
              }}
            >
              <RelatedIngredients
                dowImageType="RelatedIngredients"
                blockName="Products"
                lens="Theme"
                productType="relatedProducts"
                relatedTrends={relatedTrends?.related_products}
                demographyComponent
                tooltip={RELATED_TRENDS_TOOLTIP}
              />
            </Box>
          </>
        ) : (
          <>
            <Box marginTop={'30px'}>
              <RelatedTrends
                relatedTrends={relatedTrends?.related_themes}
                parentTrendName={trendName}
                lens={lens}
                blockName="Theme"
                disableDownload
                disableTrendClick
                disableSubCategory
                tooltip={RELATED_TRENDS_TOOLTIP}
              />
            </Box>
          </>
        )}

        {/* {lens != 'Theme' && fsProducts && fsProducts?.length != 0 && (
          <FoodServiceProducts
            projectid={projectId}
            productid={themeId}
            productType="cafe_product"
            name={trendName}
            country={country}
            // email={this.props.email}
            category={category}
            // cafeDataPoint={this.props.productDetails.cafeDataPoint}
            cafeProductFormats={fsProductFormat}
            cafeProducts={fsProducts}
          />
        )} */}

        {/* {lens != 'Theme' && retailProducts && retailProducts?.length != 0 && (
          <RelatedRetailProducts
            projectid={projectId}
            productid={themeId}
            name={trendName}
            country={country}
            category={category}
            productType="retail_product"
            // retailDataPoint={this.props.productDetails.retailDataPoint}
            retailProductFormats={retailProductsFormat}
            retailProducts={retailProducts}
          />
        )} */}

        <Box sx={{ mt: '2rem' }}>
          <ConsumerQuotesCardComponent
            consumerQuotes={consumerQuotesData}
            projectVariant={projectVariant}
            type="demographyAnalytics"
            logConsumerQuotesClicked={logConsumerQuotesClicked}
            onRefresh={() => {
              getRelatedTrends()
            }}
            country={country}
            category={category}
            name={trendName}
            lens={lens}
            markIrrelevantDisabled={false}
            details={{
              type: 'Demography',
              ageGroup: ageGroupData,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}

export default connect(mapStateToProps)(DempgraphicThemePageUI)
