import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@mui/material'
import { Circle } from '@mui/icons-material'
import React from 'react'
import { getConciseCount } from '../../../store/utility'
import _ from 'lodash'
import TextOverflowTooltip from '../TextOverflowTooltip'
import Link from 'react-router-dom/Link'

const SWTrendCard = (props) => {
  const { relevanceScore, subscription, trendDetails, lens } = props

  const cardColumns = [
    {
      name: 'Relevance Score:',
      hide: !relevanceScore,
      value: relevanceScore,
    },
    {
      name: 'Data Points:',
      hide: !trendDetails.data_points && trendDetails.data_points !== 0,
      value: getConciseCount(trendDetails.data_points),
    },
    {
      name: '2 Year CAGR:',
      hide: !trendDetails.two_year_cagr,
      value: `${trendDetails.two_year_cagr}%`,
    },
  ]

  let link = null
  const projectID = Number(trendDetails.project)
  if (projectID) {
    link = `/mui/details/${projectID}`
  }
  link += `/${lens.toLowerCase()}/${trendDetails.id}`

  return (
    <Box sx={{ width: 250 }}>
      <CardMedia
        component="img"
        height={130}
        image={trendDetails.image}
        alt={trendDetails.name}
        sx={{ borderRadius: 1 }}
      />

      <CardContent sx={{ p: 0, pt: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextOverflowTooltip title={trendDetails.name} maxLineCount={1}>
            <Typography variant="subtitle3" sx={{ fontWeight: 600, flex: 1 }}>
              {trendDetails.name}
            </Typography>
          </TextOverflowTooltip>
          <Chip
            size="small"
            variant="outlined"
            sx={{
              pointerEvents: 'none',
              '.MuiChip-icon': {
                mr: 0,
                color: ({ palette }) =>
                  palette.colors[trendDetails.type.toLowerCase()],
              },
              '.MuiChip-label': {
                pl: 0.5,
                pr: 1,
              },
            }}
            icon={<Circle />}
            label={_.startCase(trendDetails.type.toLowerCase())}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 1,
          }}
        >
          {cardColumns.map((item) => (
            <Box key={`${trendDetails.id}-${item.name}`}>
              {!item.hide && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="caption">{item.name}</Typography>
                  <Typography variant="caption">{item.value}</Typography>
                </Box>
              )}
            </Box>
          ))}
          {!!subscription && (
            <Link target="_blank" to={link}>
              <Button
                variant="outlined"
                sx={{ width: '100%', borderRadius: 1, mb: -3 }}
                size="small"
              >
                View Details
              </Button>
            </Link>
          )}
        </Box>
      </CardContent>
    </Box>
  )
}

export default SWTrendCard
