import { SearchProjectActionTypes, SearchQuotesActionTypes, LoadSearchKeywordsActionTypes } from './actionTypes'

import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import _ from 'lodash'

export const fetchProjectSearchData = (projectId, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(fetchProjectSearchDataStart())
    const request = NetworkUtil.fetchProjectSearchDataRequest()
    if(projectId === 0){
      projectId = null
    }
    const data = {
      "project_id" : projectId,
      "search_term" : searchTerm,
    }
    axios.post(request, data)
      .then(response => {
        for(let x=0; x < response.data.length; x ++) {
          const productTypes = response.data[x].product_types.map(product => (
            {
              "id" : product,
              "displayValue" : _.capitalize(product),
              "value" : product,
            }
          ))
          response.data[x].product_types = productTypes
        }
        dispatch(fetchProjectSearchDataSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchProjectSearchDataFailed(error))
      })
  }
}
  

const fetchProjectSearchDataStart = () => {
  return {
    type: SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_START],
  }
}
  
const fetchProjectSearchDataSuccess = (projectSearchData) => {
  return {
    type: SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_SUCCESS],
    projectSearchData: projectSearchData,
  }
}
  
const fetchProjectSearchDataFailed = (error) => {
  return {
    type: SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_FAILED],
    error: error,
  }
}

export const resetProjectSearchData = () => {
  return {
    type: SearchProjectActionTypes.type[SearchProjectActionTypes.RESET_PROJECT_SEARCH_DATA],
  }
}


export const fetchProjectSearchQuotes = (projectId, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(fetchProjectSearchQuotesStart())
    const request = NetworkUtil.fetchProjectSearchQuotesRequest()
    if(projectId === 0){
      projectId = null
    }
    const data = {
      "project_id" : projectId,
      "search_term" : searchTerm,
    }
    axios.post(request, data)
      .then(response => {
        // for(let x=0; x < response.data.length; x ++) {
        //   const product_types = response.data[x].product_types.map(product => (
        //     {
        //       "id" : product,
        //       "displayValue" : _.capitalize(product),
        //       "value" : product,
        //     }
        //   ))
        //   response.data[x].product_types = product_types
        // }
        dispatch(fetchProjectSearchQuotesSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchProjectSearchQuotesFailed(error))
      })
  }
}
  

const fetchProjectSearchQuotesStart = () => {
  return {
    type: SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_START],
  }
}
  
const fetchProjectSearchQuotesSuccess = (projectSearchQuotes) => {
  return {
    type: SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_SUCCESS],
    projectSearchQuotes: projectSearchQuotes,
  }
}
  
const fetchProjectSearchQuotesFailed = (error) => {
  return {
    type: SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_FAILED],
    error: error,
  }
}

export const fetchSearchKeywordsData = (projectId = '0') => {
  return (dispatch) => {
    if(projectId === null)
        return

    // Fetch keywords!
    dispatch(fetchSearchKeywordsDataStart())
    projectId = projectId || '0' // To tackle cases for `undefined`
    const request = NetworkUtil.fetchSearchKeywords()  + `?projectId=${projectId}`
    axios.get(request)
      .then(response => {
        const { data } = response.data
        const keywordsList = []
        const lens = ['ingredients', 'themes', 'products']
        
        lens.map(lens => {

          if(data[lens]){
            keywordsList.push({
              name: lens[0].toUpperCase() + lens.slice(1, lens.length - 1),
              list:
                data[lens].map((keyword, index) => ({
                  "id" : lens + index,
                  "displayValue" : keyword,
                  "value" : keyword,
                  "name" : keyword,
                })),
            })
          }
        })
        dispatch(fetchSearchKeywordsDataSuccess(keywordsList))
      })
      .catch(error => {
        dispatch(fetchSearchKeywordsDataFailed(error))
      })
  }
}

const fetchSearchKeywordsDataStart = () => {
  return {
    type: LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_START],
  }
} 

const fetchSearchKeywordsDataSuccess = (searchKeywordsList) => {
  return {
    type: LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_SUCCESS],
    searchKeywordsList: searchKeywordsList,
  }
}

const fetchSearchKeywordsDataFailed = (error) => {
  return {
    type: LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_FAILED],
    error: error,
  }
}