import { SearchCategoriesActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  searchCategoryList : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case SearchCategoriesActionTypes.type[SearchCategoriesActionTypes.FETCH_PROJECT_SEARCH_CATEGORY_START]: return updateObject(state, {
      loading: true,
    })
    case SearchCategoriesActionTypes.type[SearchCategoriesActionTypes.FETCH_PROJECT_SEARCH_CATEGORY_SUCCESS]: return updateObject(state, {
      searchCategoryList : action.searchCatetgories,
      error : false,
      loading : false,
    })
    case SearchCategoriesActionTypes.type[SearchCategoriesActionTypes.FETCH_PROJECT_SEARCH_CATEGORY_FAILED]: return updateObject(state, {
      searchCategoryList : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer