import { Box, Link, Typography } from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import DashboardGraphPreview from '../DashboardGraphPreview'
import DashboardCAGRLabel from '../DashboardCAGRLabel'
import DashboardDataPointsLabel from '../DashboardDataPointsLabel'
import DashboardClassificationLabel from '../DashboardClassificationLabel'
import DashboardCommonCardsList from '../DashboardCommonCardsList'
import DashboardCommonCard from '../DashboardCommonCard'
import EmptyIngredients from '../EmptyIngredientsCard'
import TextOverflowTooltip from '../TextOverflowTooltip'
import FloatingActionButton from '../FloatingActionButton'
import { ReactComponent as EmptyTrendHighlight } from '../../../assets/emptyTrendHighlight.svg'
import DashboardTooltip from '../DashboardTooltip'
import moment from 'moment'

const DEFAULT_CARDS_TO_SHOW = 6
let MAX_CARDS_TO_SHOW = 10
const COUNTRIES_WITH_NO_HISTORIC_DATA = ['Kingdom of Saudi Arabia']

const DashboardTrendHighlightCardsList = (props) => {
  const {
    trendHighlightData,
    lensSelected,
    projectId,
    email,
    country,
    category,
    subCategory,
    countrySelected,
  } = props
  const [cardsToShow, setCardsToShow] = useState(DEFAULT_CARDS_TO_SHOW)

  const handleViewMoreClick = () => {
    const ampiEvent = 'Clicked_MW_TrendHighlight_ViewMore'
    const amplitudeUserData = {
      User: email,
      Lens: lensSelected,
      Country_Name: country,
      Category_Name: category,
      SubCategory_Name: subCategory || '',
    }
    props.amplitudeFn(ampiEvent, amplitudeUserData)
    setCardsToShow((cardsLength) =>
      cardsLength === DEFAULT_CARDS_TO_SHOW
        ? MAX_CARDS_TO_SHOW
        : DEFAULT_CARDS_TO_SHOW,
    )
  }

  const handleTrendClick = (obj) => {
    const ampiEvent = 'Clicked_MW_TrendHighlight_Trend'
    const amplitudeUserData = {
      User: email,
      Lens: lensSelected,
      Country_Name: country,
      Category_Name: category,
      SubCategory_Name: subCategory || '',
      Trend_Name: obj.trend_name,
    }
    props.amplitudeFn(ampiEvent, amplitudeUserData)
  }

  const handleHoverOpen = (hoverName) => {
    const ampiEvent = 'Clicked_MW_TrendHighlight_Hover'
    const ampiUserData = {
      User: email,
      Hover: hoverName,
    }
    props.amplitudeFn(ampiEvent, ampiUserData)
  }

  useEffect(() => {
    setCardsToShow(DEFAULT_CARDS_TO_SHOW)
  }, [trendHighlightData])

  // set the max cards to show to length of trendhighlight data or default to init max
  MAX_CARDS_TO_SHOW = trendHighlightData && trendHighlightData.length || MAX_CARDS_TO_SHOW


  return (
    <React.Fragment>
      <DashboardCommonCardsList
        gridStyles={{
          mt: 1,
          height: cardsToShow !== DEFAULT_CARDS_TO_SHOW && 450,
          overflow: cardsToShow !== DEFAULT_CARDS_TO_SHOW && 'auto',
          pr: cardsToShow !== DEFAULT_CARDS_TO_SHOW && 2,
          pb: cardsToShow !== DEFAULT_CARDS_TO_SHOW && 1,
        }}
      >
        {trendHighlightData.slice(0, cardsToShow).map((obj) => (
          <DashboardCommonCard
            cardProps={{
              xs: 4,
            }}
            boxStyles={{
              p: 0,
            }}
          >
            <Link
              target="_blank"
              underline="none"
              href={`/mui/details/${projectId}/${lensSelected}/${obj.id}`}
              onClick={() => handleTrendClick(obj)}
            >
              <Box sx={{ p: 2 }}>
                <TextOverflowTooltip title={obj.trend_name} maxLineCount={1}>
                  <Typography
                    variant="h3"
                    sx={{
                      mb: 1,
                    }}
                  >
                    {obj.trend_name}
                  </Typography>
                </TextOverflowTooltip>
                <DashboardCAGRLabel
                  title="2 Yr CAGR (Change):"
                  twoYearCAGR={obj.two_year_cagr}
                  twoYearCAGRChange={
                    !_.isNil(obj.previous_month_two_year_cagr) &&
                    Number(obj.previous_month_two_year_cagr).toFixed(2)
                  }
                  hasAmplitudeEvent={{
                    email: email,
                    handleHoverOpen: handleHoverOpen,
                  }}
                />
                <Box display="flex">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                  >
                    <DashboardDataPointsLabel
                      dataPoints={String(obj.total_data_points)}
                      dataPointsChange={String(obj.previous_month_data_points)}
                      hasAmplitudeEvent={{
                        email: email,
                        handleHoverOpen: handleHoverOpen,
                      }}
                    />
                    <DashboardClassificationLabel
                      classification={_.startCase(
                        obj.current_month_classification.toLowerCase(),
                      )}
                      classificationPrevious={_.startCase(
                        obj.previous_month_classification.toLowerCase(),
                      )}
                      hasAmplitudeEvent={{
                        email: email,
                        handleHoverOpen: handleHoverOpen,
                      }}
                    />
                  </Box>
                  {/* TODO Should Uncomment in next Release */}
                  {/* {obj.number_of_months_gone_back_to !== 1 ? (
                    <DashboardTooltip
                      title={`Last Updated: ${moment(obj.last_updated_timestamp)
                        .subtract(obj.number_of_months_gone_back_to, 'months')
                        .format('MMM YYYY')}`}
                    >
                      <Box>
                        <DashboardGraphPreview graphData={obj.graph_data} />
                      </Box>
                    </DashboardTooltip>
                  ) : ( */}
                  <Box>
                    <DashboardGraphPreview graphData={obj.graph_data} />
                  </Box>
                  {/* )} */}
                </Box>
              </Box>
            </Link>
          </DashboardCommonCard>
        ))}
        {trendHighlightData &&
          trendHighlightData.length === 0 &&
          (COUNTRIES_WITH_NO_HISTORIC_DATA.includes(countrySelected) ? (
            <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
              <EmptyIngredients
                text="We would like to inform you that the data you requested will be made available to you at the end of the quarter."
                imgComp={<EmptyTrendHighlight height={250} />}
                textStyles={{
                  fontSize: 'medium',
                }}
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
              <EmptyIngredients text="No Trend Highlights Available" />
            </Box>
          ))}
      </DashboardCommonCardsList>
      {trendHighlightData &&
        trendHighlightData.length > DEFAULT_CARDS_TO_SHOW &&
        cardsToShow === DEFAULT_CARDS_TO_SHOW && (
          <Box display="flex" sx={{ width: '100%', mt: 2 }}>
            <FloatingActionButton
              fabStyles={{
                pr: 0.75,
              }}
              handleClick={handleViewMoreClick}
              fabContent={{
                text: `View ${
                  cardsToShow === DEFAULT_CARDS_TO_SHOW ? `More` : `Less`
                }`,
                icon: (
                  <Box
                    display="flex"
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      border: ({ palette }) => `1px solid ${palette.divider}`,
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{
                        margin: 'auto',
                        transform:
                          cardsToShow !== DEFAULT_CARDS_TO_SHOW &&
                          'rotate(180deg)',
                      }}
                    />
                  </Box>
                ),
              }}
            />
          </Box>
        )}
    </React.Fragment>
  )
}

export default DashboardTrendHighlightCardsList
