import * as React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ClickAwayListener, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { Search } from '@mui/icons-material';
import {
  PopperComponent, StyledMainTextField, StyledTextField, StyledPopper,
} from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const popperModifiers = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: true,
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: false,
    options: {
      altAxis: false,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
      padding: 8,
    },
  },
];

const Multiselect = ({
  placeholder, searchPlaceholder, options, value, onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  const handleChange = (event, newValue, reason) => {
    if (
      event.type === 'keydown'
      && event.key === 'Backspace'
      && reason === 'removeOption'
    ) {
      return;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-id' : undefined;

  return (
    <>
      <StyledMainTextField
        placeholder={placeholder}
        open={open}
        contentEditable={false}
        value={value.length === options.length ? 'All' : 
          value
          .filter(({label}) => label !== 'All')
          .map(v => v.label).join(', ')
        }
        onClick={handleClick}
      />
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={popperModifiers}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              value={value}
              options={options}
              isOptionEqualToValue={(option, val) => option.key === val.key}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No results"
              getOptionLabel={option => option.label}
              onClose={(event, reason) => reason === 'escape' && handleClose()}
              onChange={handleChange}
              renderOption={(props, option, { selected }) => (
                <li {...props} onClick={() => onChange(option)}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    sx={{ padding: 0 }}
                  />
                  {option.label}
                </li>
              )}
              renderInput={params => (
                <StyledTextField
                  autoFocus
                  ref={params.InputProps.ref}
                  placeholder={searchPlaceholder}
                  inputProps={params.inputProps}
                  size="large"
                  startAdornment={(
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  )}
                />
              )}
            />
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

Multiselect.defaultProps = {
  placeholder: 'Select options',
  searchPlaceholder: 'Search for somthing',
};

Multiselect.propTypes = {
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Multiselect;

