import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { Box } from '@mui/material'

export default function PieChart({
  data,
  width,
  height,
  clickable,
  handleArcHover,
  handleArcClick,
  arcSelected,
  arcHighlighted,
  fontSize,
  thickness,
  handleArcClickTrendPage,
  trendPage,
  fontSizeText,
  fontSizeValue,
}) {
  const svgRef = useRef()

  const getId = (name) => {
    return `svg-PieChart-${name.split(' ').join('')}`
  }

  const onArcHover = (name) => {
    const svg = d3.select(svgRef.current)
    if (name) {
      svg
        .select(`#${getId(name)}`)
        .select('path')
        .transition()
        .duration('50')
        .attr('opacity', '.85')
        .style('stroke', '#FFD600')
        .style('stroke-width', '1px')
        .style('cursor', 'pointer')
    } else {
      svg
        .selectAll('.arc > path')
        .transition()
        .duration('50')
        .attr('opacity', '1')
        .style('stroke', 'none')
    }
  }

  const showValueAndLabelOnHover = (name, value) => {
    const g = d3
      .select(`#${getId(name)}`)
      .style('cursor', 'pointer')
      .style('fill', (d) =>
        d.data.name === 'Millennials'
          ? '#4E8CE0'
          : d.data.name === 'Gen Z'
          ? '#29C0FA'
          : d.data.name === 'Gen X'
          ? '#0060A9'
          : d.color,
      )
      .append('g')
      .attr('class', 'text-group')

    g.append('text')
      .attr('class', 'value-text')
      .text(`${value}%`)
      .style('font-size', fontSizeValue)
      .style('fill', 'black')
      .attr('text-anchor', 'middle')
      .attr('dy', '-0.1em')

    g.append('text')
      .attr('class', 'name-text')
      .text(`${name}`)
      .style('font-size', fontSizeText)
      .attr('text-anchor', 'middle')
      .attr('dy', '1em')
  }

  const onArcClick = (name) => {
    if (name) {
      const svg = d3.select(svgRef.current)
      svg
        .selectAll('.arc > path')
        .attr('fill', (d) =>
          d.data.name === 'Millennials'
            ? '#D7DDEC'
            : d.data.name === 'Gen Z'
            ? '#C1CCE5'
            : d.data.name === 'Gen X'
            ? ' #9AA7C3'
            : '',
        )
        .style('z-index', 'auto')
        .style('stroke', 'none')
        .style('stroke-width', '1px')
      svg
        .select(`#${getId(name)}`)
        .select('path')
        .attr('fill', (d) =>
          d.data.name === arcSelected && d.data.name === 'Millennials'
            ? '#4E8CE0'
            : d.data.name === arcSelected && d.data.name === 'Gen Z'
            ? '#29C0FA'
            : d.data.name === arcSelected && d.data.name === 'Gen X'
            ? '#0060A9'
            : d.color,
        )
        .style('z-index', 1)
        .style('stroke', '#3D6DCA')
        .style('stroke-width', '1px')
    }
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = svg.attr('width')
    const height = svg.attr('height')

    const radius = Math.min(width - 10, height - 10) / 2
    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`)
    const color = d3.scaleOrdinal(
      clickable
        ? ['#9AA7C3 ', '#C1CCE5', '#D7DDEC']
        : ['#0060A9', '#4E8CE0', '#29C0FA'],
    )
    const pie = d3.pie().value(function(d) {
      return d.value
    })
    var text = ''
    const pieChartData = pie(data)

    const arc = d3
      .arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius)

    const arcs = g
      .selectAll('.arc')
      .data(pieChartData)
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('id', function(d) {
        return getId(d.data.name)
      })
      .each(function(d) {
        d.color = color(d.index)
      })
      .on('mouseover', function(d) {
        onArcHover(d.data.name)
        showValueAndLabelOnHover(d.data.name, d.data.value)
      })
      .on('mouseout', function(d) {
        onArcHover()
        d3.select(this)
          .style('cursor', 'none')
          .style('fill', color(this._current))
          .select('.text-group')
          .remove()
      })
      .on('click', function(d) {
        if (trendPage) {
          handleArcClickTrendPage(d.data.name)
        }
      })

    if (clickable) {
      arcs
        .append('path')
        .attr('fill', (d) =>
          d.data.name === arcSelected && d.data.name === 'Millennials'
            ? '#4E8CE0'
            : d.data.name === arcSelected && d.data.name === 'Gen Z'
            ? '#29C0FA'
            : d.data.name === arcSelected && d.data.name === 'Gen X'
            ? '#0060A9'
            : d.color,
        )
        .attr('d', arc)
        .on('mouseover', function(d, i) {
          handleArcHover(d.data.name)
          onArcHover(d.data.name, true)
        })
        .on('mouseout', function(d, i) {
          handleArcHover()
          onArcHover()
        })
        .on('click', function(d) {
          handleArcClick(d.data.name)
        })
        .style('z-index', 1)
        .style('stroke', 'none')
    } else {
      arcs
        .append('path')
        .attr('fill', (d) =>
          d.data.name === arcSelected && d.data.name === 'Millennials'
            ? '#4E8CE0'
            : d.data.name === arcSelected && d.data.name === 'Gen Z'
            ? '#29C0FA'
            : d.data.name === arcSelected && d.data.name === 'Gen X'
            ? '#0060A9'
            : d.color,
        )
        .attr('d', arc)
    }
  }, [])

  useEffect(() => {
    onArcClick(arcSelected)
  }, [arcSelected])

  useEffect(() => {
    onArcHover(arcHighlighted)
  }, [arcHighlighted])

  return <svg ref={svgRef} width={width} height={height} padding="30px" />
}
