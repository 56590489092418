import React from 'react'
import {
  Field, reduxForm,
} from 'redux-form'
import {
  renderField, renderTextArea,
} from '../UI/form/index'
import classes from './organizationPage.css'
import { required, email, phoneNumber, number } from '../../validation'
import { reduxFormConst } from '../../const'

class OrganizationForm extends React.Component {
  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="col-md-12"><h3>Organization</h3></div>
        <div className="col-md-12">
          <Field name="name" label="Name" placeholder="Enter Organization Name"
            component={renderField} validate={required}
          />
        </div>
        <div className={'col-md-6 ' + classes.OrgFormSection}>
          <h3>Billing</h3>
          <div className={classes.billingShippingForm}>
            <div className="col-md-12">
              <Field name="billing.email" label="Email" placeholder="Enter Your Email"
                component={renderField} validate={[email]}
              />
            </div>
            <div className="col-md-12">
              <Field name="billing.addressLine1" label="Address1"
                component={renderTextArea} placeholder="Enter Your Address"
                className={classes.orgTextarea}/>
            </div>
            <div className="col-md-12">
              <Field name="billing.addressLine2" label="Address2"
                component={renderTextArea} placeholder="Enter Your Address"
                className={classes.orgTextarea}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.country" label="Country"
                placeholder="Select Your Country"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.state" label="State" placeholder="Select Your State"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.city" label="City" placeholder="Enter Your City"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.street" label="Street" placeholder="Enter Your Street"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.phone1" label="Phone1" placeholder="Enter Your Phone"
                validate={[number, phoneNumber]} component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.zipcode" label="Zip Code"
                placeholder="Enter Your Zip Code"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.phone2" label="Phone2" placeholder="Enter Your Phone"
                validate={[number, phoneNumber]} component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="billing.fax" label="Fax" placeholder="Enter Your Fax"
                component={renderField}/>
            </div>
          </div>
        </div>

        <div className={'col-md-6 ' + classes.OrgFormSection}>
          <h3>Shipping</h3>
          <div className={classes.billingShippingForm}>
            <div className="col-md-12">
              <Field name="shipping.email" label="Email" placeholder="Enter Your Email"
                validate={[email]} component={renderField}/>
            </div>
            <div className="col-md-12">
              <Field name="shipping.addressLine1" label="Address1"
                component={renderTextArea} placeholder="Enter Your Address"
                className={classes.orgTextarea}/>
            </div>
            <div className="col-md-12">
              <Field name="shipping.addressLine2" label="Address2"
                component={renderTextArea} placeholder="Enter Your Address"
                className={classes.orgTextarea}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.country" label="Country"
                placeholder="Select Your Country"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.state" label="State" placeholder="Select Your State"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.city" label="City" placeholder="Enter Your City"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.street" label="Street" placeholder="Enter Your Street"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.phone1" label="Phone1" placeholder="Enter Your Phone"
                validate={[number, phoneNumber]} component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.zipcode" label="Zip Code"
                placeholder="Enter Your Zip Code"
                component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.phone2" label="Phone2" placeholder="Enter Your Phone"
                validate={[number, phoneNumber]} component={renderField}/>
            </div>
            <div className="col-md-6">
              <Field name="shipping.fax" label="Fax" placeholder="Enter Your Fax"
                component={renderField}/>
            </div>
          </div>
        </div>
        <div className={'col-md-12 clearfix text-center ' + classes.organizationBtn}>
          <button type="submit" className={'btn ' + classes.btnCustom}>Save</button>
        </div>
      </form>

    )
  }
}

OrganizationForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.organizationPage,
})(OrganizationForm)
export default OrganizationForm
