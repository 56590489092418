import * as AC from './actionConstants'

export const showSppiner = () => ({
  type: AC.SHOW_SPINNER,
})

export const hideSppiner = () => ({
  type: AC.HIDE_SPINNER,
})

export const addNewBreadCrumbs = (newBreadCrumbs) => ({
  type: AC.ADD_NEW_BREADCRUMBS,
  newBreadCrumbs,
})

export const arrangeBreadcrumbsValues = (breadcrumbValues) => ({
  type: AC.ARRANGE_BREADCRUMBS_VALUES,
  breadcrumbValues,
})

export const addBreadCrumb = (breadCrumb) => ({
  type: AC.BREADCRUMBS_ADD,
  breadCrumb,
})

export const replaceBreadCrumb = (breadCrumb, index) => ({
  type: AC.BREADCRUMBS_REPLACE,
  breadCrumb,
  index,
})

export const removeAfterBreadCrumb = (index) => ({
  type: AC.BREADCRUMBS_REMOVE_AFTER,
  index,
})

export const resetBreadCrumb = () => ({
  type: AC.RESET_PROJECT_DETAILS,
})
export const saveBreadCrumbData = (breadCrumb) => ({
  type:  AC.SAVE_BREAD_CRUMBS_DATA,
  breadCrumb,
})


export const showFilterPanel = (show) => ({
  type: AC.SHOW_FILTER_PANEL,
  show
})

export const addHighlight = (highlight) => ({
  type: AC.ADD_HIGHLIGHT,
  highlight
})

export const removeHighlight = (highlightId) => ({
  type: AC.REMOVE_HIGHLIGHT,
  highlightId
})
