import {
  Box,
  Switch,
  Button,
  Typography,
  FormControl,
  Select,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material'
import React, { useState, useMemo } from 'react'
import ScatteredGraph from '../Graphs/ScatteredGraph'
import EmptyIngredients from '../EmptyIngredientsCard'
import { INGREDIENT, THEME } from '../../utils/constants'
import ClearIcon from '@mui/icons-material/Clear'

import useDebounce from '../../../shared/hooks/useDebounce'

const TWO_YEAR_CAGR = 2
const FOUR_YEAR_CAGR = 4

const placeHolderColor = {
  color: '#8997A6',
}

function scaleSingleNum(element) {
  if (element > 1) {
    return Math.log(element) + 1
  } else if (element < -1) {
    return -Math.log(Math.abs(element)) - 1
  } else {
    return element
  }
}

function divideNumbers(start, end, median) {
  if (median < end && median > start) {
    return [start, median, end]
  }
  return [start, end]
}

const ExploreCategoryDistribution = ({
  viewMode,
  setTable,
  setChart,
  newVisualisationData,
  handleTrendClick,
  oldTrends,
  lensSelected,
  selectedCagr,
  handleCagrChange,
  maxTrendsShown,
  selectedChartFsValue: selectedFsValue,
  setSelectedChartFsValues: setSelectedFsValues,
  chartFsValueOption: fsValueOption,
  setChartFsValueOption: setFsValueOption,
  selectedChartRetailValue: selectedRetailValue,
  setSelectedChartRetailValues: setSelectedRetailValues,
  chartRetailValueOption: retailValueOption,
  setChartRetailValueOption: setRetailValueOption,
  displayEmptyTrend,
  projectVariant,
}) => {
  const [resetBtn, setResetBtn] = useState(false)

  const resetBtnId = 'zoom-reset-btn'
  const medianYVal = scaleSingleNum(
    selectedCagr === TWO_YEAR_CAGR
      ? newVisualisationData?.two_year_data_points_median
      : newVisualisationData?.four_year_data_points_median,
  )
  const medianXVal = scaleSingleNum(
    selectedCagr === TWO_YEAR_CAGR
      ? newVisualisationData?.two_year_cagr_median
      : newVisualisationData?.four_year_cagr_median,
  )

  const getOldTrendsId = (oldTrends) => {
    if (!oldTrends) return []
    let allTrendList = []
    for (const trend of oldTrends) {
      allTrendList = [...allTrendList, ...trend.allTrendResults]
    }
    return allTrendList.map((trend) => trend.id)
  }

  const modifiedData = useMemo(() => {
    let trendsData = []

    const trends = newVisualisationData?.trends || []

    const addType = (arr, type) => {
      return arr.map((val) => {
        return { ...val, type }
      })
    }

    for (const trend of trends) {
      trendsData = [
        ...trendsData,
        ...addType(trend.allTrendResults, trend.type),
      ]
    }

    return trendsData?.map((trend) => {
      return {
        ...trend,
        xScaleData: scaleSingleNum(
          selectedCagr === TWO_YEAR_CAGR
            ? trend?.two_year_cagr
            : trend?.four_year_cagr,
        ),
        yScaleData: scaleSingleNum(
          selectedCagr === TWO_YEAR_CAGR
            ? trend?.two_year_data_points
            : trend?.four_year_data_points,
        ),
      }
    })
  }, [
    oldTrends,
    newVisualisationData?.trends,
    newVisualisationData?.four_year_cagr_median,
    selectedCagr,
  ])

  const minYDomain =
    modifiedData &&
    modifiedData?.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.yScaleData < curr.yScaleData ? prev : curr
    })

  const maxYDomain =
    modifiedData &&
    modifiedData?.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.yScaleData > curr.yScaleData ? prev : curr
    })

  const minXDomain =
    modifiedData &&
    modifiedData.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.xScaleData < curr.xScaleData ? prev : curr
    })

  const maxXDomain =
    modifiedData &&
    modifiedData?.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.xScaleData > curr.xScaleData ? prev : curr
    })

  const sliderFilteredData = useMemo(() => {
    if (lensSelected !== INGREDIENT) {
      return modifiedData
    } else {
      return modifiedData.filter((data) => {
        return (
          data.retail_products >= selectedRetailValue &&
          data.fs_products >= selectedFsValue
        )
      })
    }
  }, [lensSelected, modifiedData, selectedRetailValue, selectedFsValue])

  const graphHelperData = {
    xAxisText: 'Growth Rate',
    yAxisText: 'Engagement',
    scaleX: 'scaleLinear',
    scaleY: 'scaleLinear',
    domain: [minXDomain?.xScaleData, maxXDomain?.xScaleData],
    xAxisTextis:
      'This is calculated by considering both Search and Social Data.',
    yAxisTextis: 'This is calculated by considering only Social Data.',
    yDomain: [minYDomain?.yScaleData, maxYDomain?.yScaleData],
    xTicks: 3,
    yTicks: 3,
    selectedCagr,
    newVisualisation: true,
    customXTicks: divideNumbers(
      minXDomain?.xScaleData,
      maxXDomain?.xScaleData,
      medianXVal,
    ),
    customYTicks: divideNumbers(
      minYDomain?.yScaleData,
      maxYDomain?.yScaleData,
      medianYVal,
    ),
    medianCircleDisabled: true,
    showMedianRectangle: true,
    medianDashColor: '#9AC4F9',
    labelDisabled: false,
    zoomDisabled: false,
  }

  const formattedOldTrendsId = getOldTrendsId(oldTrends)

  const filteredTrends = sliderFilteredData
    .filter((trend) => {
      return formattedOldTrendsId.includes(trend.id)
    })
    .sort((a, b) => {
      const comparisonKey =
        selectedCagr === FOUR_YEAR_CAGR
          ? 'four_year_data_points'
          : 'two_year_data_points'
      return a[comparisonKey] > b[comparisonKey] ? -1 : 1
    })

  function getUnique(arr, limit, key) {
    return arr.reduce(
      function(p, c) {
        var id = c[key]
        if (p.indices.indexOf(id) === -1) {
          p.result.push(
            arr
              .filter(function(el) {
                return el[key] === id
              })
              .slice(0, limit),
          )
          p.indices.push(id)
        }
        return p
      },
      { result: [], indices: [] },
    ).result
  }

  const updatedFilteredData = getUnique(
    filteredTrends,
    maxTrendsShown,
    'type',
  ).flat()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent:
            lensSelected === INGREDIENT && modifiedData?.length > 1
              ? 'space-between'
              : 'flex-end',
          alignItems: 'center',
          pb: 2,
          pl: 1,
        }}
      >
        {lensSelected === INGREDIENT && modifiedData?.length > 1 ? (
          <Box sx={{ display: 'flex' }}>
            {projectVariant !== 'bpc' && (
              <Box sx={{ width: '250px' }}>
                <FormControl
                  variant="outlined"
                  sx={{
                    m: 1,
                    flex: 1,
                    minWidth: 250,
                  }}
                >
                  <Typography sx={{ paddingBottom: 1 }}>
                    #Food Service Product
                  </Typography>
                  <Select
                    size={'large'}
                    displayEmpty
                    sx={{
                      '.MuiSelect-select': {
                        marginRight: selectedFsValue && '-50px',
                      },
                    }}
                    endAdornment={
                      selectedFsValue ? (
                        <IconButton
                          onClick={() => setSelectedFsValues(null)}
                          size="small"
                          sx={{ height: '10px', width: '10px', mr: 2 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : null
                    }
                    defaultValue={selectedFsValue}
                    value={selectedFsValue}
                    onChange={(e) => {
                      if (e.target.value || e.target.value === 0) {
                        setSelectedFsValues(e.target.value)
                      }
                    }}
                  >
                    <MenuItem value={null} style={{ display: 'none' }}>
                      <em style={placeHolderColor}>
                        Filter By #Food Service Product
                      </em>
                    </MenuItem>
                    <MenuItem value={''} onKeyDown={(e) => e.stopPropagation()}>
                      <TextField
                        type="number"
                        fullWidth
                        placeholder="Hit ⏎ Enter to add value"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            let val = e.target.value
                            if (val || val === 0) {
                              if (val < 0) {
                                val = 0
                              }
                              setSelectedFsValues(val)
                              setFsValueOption([
                                ...fsValueOption,
                                parseInt(val),
                              ])
                            }
                          }
                        }}
                      />
                    </MenuItem>
                    {[...new Set(fsValueOption)].map((fsValue) => {
                      return (
                        <MenuItem value={fsValue} key={fsValue + 'fs-value'}>
                          {fsValue ? `>=${fsValue}` : 'All'}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box sx={{ width: '250px', ml: '20px' }}>
              <FormControl
                variant="outlined"
                sx={{
                  m: 1,
                  flex: 1,
                  minWidth: 250,
                }}
              >
                <Typography sx={{ paddingBottom: 1 }}>
                  #Retail Product
                </Typography>
                <Select
                  size={'large'}
                  displayEmpty
                  endAdornment={
                    selectedRetailValue ? (
                      <IconButton
                        onClick={() => setSelectedRetailValues(null)}
                        size="small"
                        sx={{ height: '10px', width: '10px', mr: 2 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : null
                  }
                  defaultValue={selectedRetailValue}
                  value={selectedRetailValue}
                  sx={{
                    '.MuiSelect-select': {
                      marginRight: selectedRetailValue && '-50px',
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value || e.target.value === 0) {
                      setSelectedRetailValues(e.target.value)
                    }
                  }}
                >
                  <MenuItem value={null} style={{ display: 'none' }}>
                    <em style={placeHolderColor}>Filter By #Retail Product</em>
                  </MenuItem>
                  <MenuItem value={''} onKeyDown={(e) => e.stopPropagation()}>
                    <TextField
                      type="number"
                      fullWidth
                      placeholder="Hit ⏎ Enter to add value"
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          let val = e.target.value
                          if (val || val === 0) {
                            if (val < 0) {
                              val = 0
                            }
                            setSelectedRetailValues(val)
                            setRetailValueOption([
                              ...retailValueOption,
                              parseInt(val),
                            ])
                          }
                        }
                      }}
                    />
                  </MenuItem>
                  {[...new Set(retailValueOption)].map((retailValue) => {
                    return (
                      <MenuItem
                        value={retailValue}
                        key={retailValue + 'retail-value'}
                      >
                        {retailValue ? `>=${retailValue}` : 'All'}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        ) : null}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <>
            <Typography>2 Year CAGR</Typography>
            <Switch
              onChange={handleCagrChange}
              checked={selectedCagr === FOUR_YEAR_CAGR}
              sx={{
                '& .MuiButtonBase-root': {
                  color: ({ palette }) => palette.primary.main,
                },
                '& .MuiSwitch-track': {
                  backgroundColor: ({ palette }) => palette.primary.main,
                },
              }}
            />
            <Typography mr={3}>4 Year CAGR</Typography>
          </>
        </Box>
      </Box>
      {resetBtn && !displayEmptyTrend ? (
        <Box
          sx={{ width: '100%', mt: 0, position: 'relative' }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            sx={{ mx: 2, position: 'absolute', top: '10px', zIndex: 10 }}
            id={resetBtnId}
            variant="grey"
          >
            Reset Zoom
          </Button>
        </Box>
      ) : null}
      {!!medianYVal &&
      sliderFilteredData?.length > 0 &&
      !displayEmptyTrend &&
      updatedFilteredData?.length > 0 ? (
        <ScatteredGraph
          filteredData={updatedFilteredData}
          lensSelected={lensSelected}
          graphHelperData={graphHelperData}
          viewDetailsClicked={handleTrendClick}
          resetZoomOnGraphDataUpdate={true}
          searchValue={''}
          medianXVal={medianXVal}
          medianYVal={medianYVal}
          resetBtn={resetBtn}
          setResetBtn={setResetBtn}
          resetBtnId={resetBtnId}
        />
      ) : (
        <EmptyIngredients text={`No ${lensSelected} found.`} />
      )}
    </>
  )
}

export default ExploreCategoryDistribution
