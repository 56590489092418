import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const FilterClassificationBox = styled(Box)(({ theme }) => ({
  height: 220,
  marginTop: '16px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: theme.spacing(0.5),
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}))

