import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.paper,
      border: `1px solid ${palette.divider}`,
      height: 34,
      width: 34,
      '&:hover': {
        border: `1px solid ${palette.grey[500]}`,
      },
      '& .MuiAvatar-fallback': {
        color: palette.icon,
        padding: 6,
        '&:hover': {
          color: palette.info.main,
        },
      },
    },
  },
  variants: [
    {
      props: { isSelected: true },
      style: {
        backgroundColor: palette.background.default,
        '& .MuiAvatar-fallback': {
          color: palette.primary.main,
        },
      },
    },
  ],
};
