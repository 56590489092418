import React from 'react'
import { Box } from '@mui/material'
import WheelCard from './WheelCard'

export default function WheelCardList({
  wheelList,
  onDeleteOptionClick,
  wheel,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        cursor: 'pointer',
      }}
    >
      {wheelList.map((wheelDetail) => {
        return (
          <WheelCard
            key={wheelDetail.id}
            wheelDetail={wheelDetail}
            onDeleteOptionClick={onDeleteOptionClick}
            wheel={wheel}
          />
        )
      })}
    </Box>
  )
}
