import React from 'react'
import Notfound from '../../../../../assets/images/testImages/Notfound.svg'
import { Box, Grid, Typography, Stack } from '@mui/material'

const NoResultsFound = ({ title, height = 456 }) => {
  return (
    <Grid
      container
      mt={1}
      justifyContent="center"
      sx={{
        height: height,
        bgcolor: ({ palette }) => palette.background.paper,
      }}
    >
      <Stack spacing={3.75} justifyContent="center" alignItems="center">
        <Box component="img" src={Notfound} alt="data is not available" />
        <Typography variant="subtitle1">
          No results found for {title}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default NoResultsFound
