const white = '#FFFFFF'
const black = '#171717'
const palette = {
  black,
  white,
  shadow: {
    light: 'rgba(149, 149, 149, 0.16)',
    main: 'rgba(39, 43, 49, 0.03)',
    pillar: 'rgba(0, 0, 0, 0.069193)',
    primary: 'rgba(0, 0, 0, 0.5)',
    secondary: 'rgba(0, 0, 0, 0.1)',
    overlay: 'rgba(255, 255, 255, 0.9)',
    fab: {
      main: 'rgba(227,227,227, 0.5)',
      active: 'rgba(227,227,227, 1)',
    },
  },
  colors: {
    facebookHover: '#1877F2',
    instagramHover: '#E1306C',
    linkedinHover: '#0073B1',
    hoverLink: '#2ac0fa',
    chip: 'rgba(40, 167, 194, 0.12)',
    chipHover: 'rgba(40, 167, 194, 0.24)',
    chipActive: 'rgba(40, 167, 194, 0.32)',
    chipKeyword: '#F1F1F1',
    tagActive: 'rgba(40, 167, 194, 0.16)',
    dormant: '#ECE55F',
    emerging: '#A7DE4C',
    growing: '#6EB647',
    matured: '#E2873D',
    declining: '#DD7066',
    fading: '#EABAB6',
    alertInfoBackground: '#CCE5FC',
    alertSuccessBackground: '#D5EAD4',
    chipBorder: '#E5EAE5',
    snackbarBackground: '#FBF2E4',
    newUpdateHeading: '#E99A00',
    badge: '#FF4C51',
    switchOff: '#4D5761',
    safeBet: '#534582',
    opportunity: '#F5BA41',
    niche: '#2FC24F',
    upcoming: '#387AC4',
    medianDash: '#9AC4F9',
    greyBackground: '#EFEFEF',
  },
  primary: {
    contrastText: white,
    dark: '#0060A9',
    main: '#0274CA',
    light: '#4E8CE0',
  },
  secondary: {
    contrastText: white,
    dark: '#079CD6',
    main: '#06B0F1',
    light: '#29C0FA',
  },
  success: {
    contrastText: white,
    dark: '#1B9874',
    main: '#22B58B',
    light: '#6FCFB4',
  },
  info: {
    contrastText: white,
    dark: '#0274CA',
    main: '#1E8FE4',
    light: '#78BCEF',
  },
  warning: {
    contrastText: white,
    dark: '#D04A19',
    main: '#DC6D45',
    light: '#F28760',
  },
  error: {
    contrastText: white,
    dark: '#C91628',
    main: '#f44336',
    light: '#EE7481',
  },
  text: {
    primary: '#272B31',
    secondary: '#8A97A7',
    disabled: '#B1C1D2',
    hint: '#A6B6C8',
  },
  background: {
    default: '#FAFBFD',
    paper: white,
  },
  icon: '#BDC9DF',
  divider: '#E5EAF0',
  action: {
    active: '#BCC7DF',
    hover: '#E4E9F2',
    selected: '#D7DDEC',
    disabled: '#EBEEF5',
  },
  option: {
    selected: '#eef4fa',
  },
  grey: {
    50: '#F4F7FC',
    100: '#F1F5FB',
    200: '#EFF2F6',
    300: '#DEE4EE',
    350: '#C2C9D2',
    400: '#CDD4DF',
    500: '#C7CEDE',
    550: '#C7C7C7',
    600: '#C1CCE5',
    700: '#AFBDDC',
    800: '#9AA7C3',
    900: '#8F9BB6',
  },
}

export default palette
