import {
  globalTrendSearch,
  trendListForGlobalSearch,
} from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  results: [],
  error: false,
  searchKeywordsList: [],
  loading: false,
  loadingList: true,
}

const globalSearchTrends = (state = initialState, action) => {
  switch (action.type) {
    case globalTrendSearch.type[
      globalTrendSearch.GLOBAL_TREND_SEARCH_RESULTS_SUCCESS
    ]:
      const results = action.res
        .map((country) => ({
          ...country,
          categories: country.categories
            .map((d) => {
              let maturityPhase = d.maturity_phase
              maturityPhase =
                maturityPhase[0].toUpperCase() +
                maturityPhase.slice(1).toLowerCase()
              return {
                projectId: d.project_id,
                trendId: d.trend_id,
                categoryName: d.name,
                dataPoints: d.datapoint,
                maturityPhase,
                fourYearCagr: d.four_year_cagr,
              }
            })
            .sort((categoryA, categoryB) => {
              return categoryA.categoryName
                .toLowerCase()
                .localeCompare(categoryB.categoryName.toLowerCase())
            }),
        }))
        .sort((a, b) => {
          const countryA = a.country.toLowerCase()
          const countryB = b.country.toLowerCase()
          return countryA.localeCompare(countryB)
        })
      return updateObject(state, {
        results,
        error: false,
        loading: false,
        loadingList: state.loadingList,
      })
    case globalTrendSearch.type[
      globalTrendSearch.GLOBAL_TREND_SEARCH_RESULTS_FAILURE
    ]:
      return updateObject(state, {
        results: [],
        error: true,
        loading: false,
        loadingList: state.loadingList,
      })
    case trendListForGlobalSearch.type[
      trendListForGlobalSearch.FETCH_GLOBAL_SEARCH_TREND_LIST_SUCCESS
    ]:
      const { trends } = action
      let keywordsList = []
      const lenses = ['ingredients', 'themes', 'products']
      lenses.map((lens) => {
        if (trends[lens]) {
          keywordsList = [
            ...keywordsList,
            ...trends[lens]
              .map((keyword, index) => ({
                bucket: lens[0].toUpperCase() + lens.slice(1, lens.length - 1),
                id: lens + index,
                displayValue: keyword,
                value: keyword,
                name: keyword,
              }))
              .sort((keyA, keyB) =>
                keyA.name.toLowerCase().localeCompare(keyB.name.toLowerCase()),
              ),
          ]
        }
      })
      return updateObject(state, {
        searchKeywordsList: keywordsList,
        loading: state.loading,
        loadingList: false,
      })
    case trendListForGlobalSearch.type[
      trendListForGlobalSearch.FETCH_GLOBAL_SEARCH_TREND_LIST_FAILURE
    ]:
      return updateObject(state, {
        searchKeywordsList: [],
        error: true,
        loading: state.loading,
        loadingList: false,
      })
    case globalTrendSearch.type[
      globalTrendSearch.RESET_GLOBAL_TREND_SEARCH_RESULTS
    ]:
      return updateObject(state, {
        results: [],
        error: false,
      })
    case globalTrendSearch.type[
      globalTrendSearch.SET_GLOBAL_TREND_SEARCH_RESULTS_LOADING
    ]:
      return updateObject(state, {
        loading: action.loading,
      })
    case globalTrendSearch.type[
      globalTrendSearch.FETCH_GLOBAL_SEARCH_TREND_LIST_LOADING
    ]:
      return updateObject(state, {
        loadingList: action.loadingList,
      })
    default:
      return state
  }
}

export default globalSearchTrends
