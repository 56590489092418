import React from 'react'
import { Button, Stack, Typography, Box, Container } from '@mui/material'
import { useHistory } from 'react-router-dom'
import errorImage1 from '../../assets/error-1.png'
import errorImage2 from '../../assets/error-2.png'

const ErrorPage = ({ text }) => {
  const history = useHistory()
  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ minHeight: 'calc(100vh - 132px)' }}
      >
        <Stack spacing={2} alignItems="center" m="auto">
          <img src={errorImage1} alt="error" width={277} />

          {text ? (
            <Typography>{text}</Typography>
          ) : (
            <>
              <Typography variant="h2">Oops! You’re lost...</Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                The page you are looking for didn’t load properly,
                <br />
                Please refresh the page
                <br />
                Or go back by clicking the below button.
              </Typography>
            </>
          )}
          <Button variant="contained" onClick={history.goBack}>
            Go back
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default ErrorPage
