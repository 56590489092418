import * as React from 'react'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import Moment from 'moment'
import _ from 'lodash'
import './ChartTrendOverMui.scss'
import { amplify } from './../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'

import * as d3 from 'd3'

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Lens_Name: props.lens,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ChartTrendOverMui')
  }
}

export class ChartTrendOverMui extends React.Component {
  constructor(props) {
    super(props)
    this.width = 1340
    this.chartRef = React.createRef(null)
    this.errorMarginTimeout = null
    this.animationTimeout = 10
    this.state = {
      series: [
        {
          name: '',
          data: [[1527811200000, 1]],
        },
      ],
    }
  }

  setWidth() {
    const svgContainer = document.querySelector('.chart-8888')
    if (svgContainer) {
      this.width = svgContainer.getBoundingClientRect().width
    }
  }

  componentDidMount() {
    const nodesList = document.querySelectorAll(
      '.chart-8888 svg .apexcharts-xaxis text',
    )
    this.setWidth()
    window.addEventListener('resize', this.setWidth)
    if (
      typeof NodeList !== 'undefined' &&
      NodeList.prototype &&
      !NodeList.prototype.forEach
    ) {
      // Yes, there's really no need for `Object.defineProperty` here
      NodeList.prototype.forEach = Array.prototype.forEach
    }

    // If the year mark, then make it bold
    nodesList.forEach((el) => {
      if (el.getAttribute('font-weight') == 600) {
        el.setAttribute('fill', '#192b8d')
      } else {
        el.setAttribute('fill', '#959595')
      }
    })
  }

  // Refer to https://aipalette.atlassian.net/wiki/spaces/~662973728/pages/1014202380/Reverse+SVG+path
  // for more path commands formatting
  reverseSVGPath = (d) => {
    // The d attribute of a path with multiples M or m commands
    const Ry = d
      .replace(/\r?\n|\r|\t|  +/g, '') // remove breaklines and tabs and spaces
      .split(/(M)/gi) // split by M or m and remember the separator
      .filter(Boolean) // remove empty strings
    // an array to save all the subpaths from one m to another
    const subpaths = []
    // in the Ry "M" or "m" are different itens. I'm joining M-s with the paths they belong to
    for (let i = 0; i < Ry.length; i += 2) {
      subpaths.push(Ry[i] + Ry[i + 1])
    }
    const allReversedPathsArrays = []
    for (let i = 0; i < subpaths.length; i++) {
      // Group commands with correct co-ordinates
      const lcDRy = [...subpaths[i].matchAll(/[A-Za-z](?:\d|\.|,| )*/g)].map(
        ([d]) => [
          d[0],
          ...d
            .slice(1)
            .split(/(?:,| )/)
            .filter(Boolean),
        ],
      )
      allReversedPathsArrays.unshift(getReversedDRy(lcDRy))
    }
    return allReversedPathsArrays.join('')

    // Reverse the path array
    function getReversedDRy(commands) {
      let newD = 'M'
      let c, lastX, lastY, lastUC
      for (let i = commands.length - 1; i >= 0; i--) {
        c = commands[i]
        lastX = c[c.length - 2]
        lastY = c[c.length - 1]
        newD += `${lastX},${lastY}` // M200,55L
        lastUC = c[0]
        newD += lastUC
        if (lastUC == 'C') {
          newD += `${c[3]},${c[4]} ${c[1]},${c[2]} `
        }
      }
      // remove the last M from the newD string
      if (newD.charAt(newD.length - 1) == 'M') {
        newD = newD.substring(0, newD.length - 1)
      }
      return newD
    }
  }

  drawErrorMargin = () => {
    if (this.props.errorMargin) {
      if (this.errorMarginTimeout) {
        clearTimeout(this.errorMarginTimeout)
      }
      this.errorMarginTimeout = setTimeout(() => {
        const uppers = document.querySelector(
          'g[seriesName="Topxerrorxmargin"] > path',
        )
        const lowers = document.querySelector(
          'g[seriesName="Bottomxerrorxmargin"] > path',
        )
        const predictionGroup = document.querySelector(
          'g[seriesName="PredictedxGrowth"]',
        )
        const plotGroup = document.querySelector(
          '.apexcharts-line-series.apexcharts-plot-series',
        )
        const errorBand = document.getElementById('joinxarr')
        if (errorBand) {
          errorBand.remove()
        }
        if (uppers && lowers) {
          const pathUp = uppers.getAttribute('d')
          const pathDown = lowers.getAttribute('d')
          // Reverse lower margin path
          const reversePathDown = this.reverseSVGPath(pathDown)
          const bottomCanvas = document.querySelector(
            'g[seriesName="Bottomxerrorxmargin"]',
          )
          const path = document.createElement('path')
          path.setAttribute('id', 'joinxarr')
          path.setAttribute('clip-path', uppers.getAttribute('clip-path'))
          path.setAttribute('fill', '#c8e1fb')
          path.setAttribute(
            'd',
            `${pathUp} ${reversePathDown.replace('M', 'L')}`,
          )
          bottomCanvas.prepend(path)
          // Move prediction to the end to draw it over the error margin
          plotGroup.appendChild(predictionGroup)
          // eslint-disable-next-line
          bottomCanvas.innerHTML = bottomCanvas.innerHTML
        }
      }, this.animationTimeout + 100)
    }
  }

  ampHistoricalGrowth = () => {
    const ampiEvent = 'Clicked_Historical_Growth_TL'
    amp(this.props, ampiEvent)
  }

  ampPredictedGrowth = () => {
    const ampiEvent = 'Clicked_Predicted_Growth_TL'
    amp(this.props, ampiEvent)
  }

  ampErrorMargin = () => {
    const ampiEvent = 'Clicked_Error_Margin_TL'
    amp(this.props, ampiEvent)
  }

  ampCAGR = () => {
    const ampiEvent = 'Clicked_4yCAGR_TL'
    amp(this.props, ampiEvent)
  }

  onChartUpdate = () => {
    const historicalGrowthLegend = document.querySelector(
      '.apexcharts-legend > div[rel="1"]',
    )
    if (historicalGrowthLegend) {
      historicalGrowthLegend.removeEventListener(
        'click',
        this.ampHistoricalGrowth,
      )
      historicalGrowthLegend.addEventListener('click', this.ampHistoricalGrowth)
    }
    const predictedGrowthLegend = document.querySelector(
      '.apexcharts-legend > div[rel="2"]',
    )
    if (predictedGrowthLegend) {
      predictedGrowthLegend.removeEventListener(
        'click',
        this.ampPredictedGrowth,
      )
      predictedGrowthLegend.addEventListener('click', this.ampPredictedGrowth)
    }
    const errorMarginLegend = document.querySelector(
      '.apexcharts-legend > div[rel="4"]',
    )
    if (errorMarginLegend) {
      errorMarginLegend.removeEventListener('click', this.ampErrorMargin)
      errorMarginLegend.addEventListener('click', this.ampErrorMargin)
    }
    const CAGRLegend = document.querySelector(
      '.apexcharts-legend > div[rel="5"]',
    )
    if (CAGRLegend) {
      CAGRLegend.removeEventListener('click', this.ampCAGR)
      CAGRLegend.addEventListener('click', this.ampCAGR)
    }
    this.drawErrorMargin()
    if (!this.props.data.find((d) => d.name === 'Predicted Growth')) {
      const marker = document.querySelector(
        '.apexcharts-legend > div[rel="2"] > .apexcharts-legend-marker',
      )
      if (marker) {
        marker.style.height = '2px'
      }
    } else {
      const markerNumber = this.props.errorMargin ? 5 : 3
      const marker = document.querySelector(
        `.apexcharts-legend > div[rel="${markerNumber}"] > .apexcharts-legend-marker`,
      )
      if (marker) {
        marker.style.height = '2px'
        marker.style.width = '18px'
      }
    }
    if (this.props.errorMargin) {
      const topErrorMarginLegend = document.querySelector(
        '.apexcharts-legend > div[rel="3"]',
      )
      const bottomErrorMargin = document.querySelector(
        '.apexcharts-legend > div[rel="4"]',
      )
      if (topErrorMarginLegend) {
        topErrorMarginLegend.remove()
      }
      if (bottomErrorMargin) {
        bottomErrorMargin.querySelector('.apexcharts-legend-text').textContent =
          'Error Margin'
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.onChartUpdate()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }

  /**
   * Get options chart
   * @returns {{dataLabels: {enabled: boolean}, xaxis: {tooltip: {enabled: boolean}, type: string, title: {text: *}, lines: {show: boolean}, axisBorder: {show: boolean}, labels: {show: boolean, style: {fontFamily: string, color: string, cssClass: string, fontSize: string}}}, grid: {xaxis: {lines: {show: boolean}}, borderColor: string, strokeDashArray: number, column: {opacity: number, colors: [string, string]}, row: {opacity: number, colors: [string, string]}}, tooltip: {custom: (function({series: *, seriesIndex: *, dataPointIndex: *, w: *}): string), fixed: {position: string, enabled: boolean}}, title: {text: *}, markers: {strokeWidth: number, hover: {size: number}, size: number, opacity: number, strokeColor: string, radius: number, colors: [string]}, fill: {gradient: {inverseColors: boolean, gradientToColors: *, shadeIntensity: number, shade: string, stops: number[], colorStops: [], type: string, opacityTo: number, opacityFrom: number}, opacity: number, type: string, colors: [string]}, stroke: {lineCap: string, curve: string, show: boolean, width: number, dashArray: number, colors: *}, chart: {toolbar: {autoSelected: string, show: boolean, tools: {download: boolean, selection: boolean, customIcons: [], zoomin: boolean, reset: boolean, zoom: boolean, zoomout: boolean, pan: boolean}}, zoom: {enabled: boolean}}, yaxis: {tickAmount: number, min: number, decimalsInFloat: number, max: number, title: {text: string}, lines: {color: string, show: boolean}, labels: {show: boolean, style: {fontFamily: string, color: string, cssClass: string, fontSize: string}}}, colors: [string]}}
   */
  getOptions = (color, prediction, predictedCagr, errorMargin) => {
    // const fourYearLine = document.querySelector('.apexcharts-line-series > g[seriesName="4xyearxCAGR"] > path')
    //   if(fourYearLine){
    //     fourYearLine.setAttribute('stroke', color)
    //   }
    const { data, yLabel, largeView } = this.props
    let maxY = 0
    for (let i = 0; i < data.length; i++) {
      if (data[i].data.length === 0) continue
      const max = _.maxBy(data[i].data, (item) => item[1])

      if (max && max.length >= 2 && max[1] >= maxY)
        // max && (max.length >= 2) &&  => otherwise => TypeError: Cannot read property '1' of undefined
        maxY = max[1]
    }

    const colors = ['#0274CA', color, predictedCagr]
    const curve = ['smooth', 'straight', 'straight']
    const fillColor = ['#fff', color, predictedCagr]
    const widthArr = [2, 2, 2]
    if (prediction) {
      colors.splice(1, 0, '#83c1ff')
      // colors.splice(1,0,'#3C2E1F')
      curve.splice(1, 0, 'smooth', 'smooth', 'smooth')
      fillColor.splice(1, 0, '#fff', '#fff', '#0274CA')
    }

    if (errorMargin) {
      colors.splice(2, 0, '#0274CA', '#c8e1fb')
      widthArr.splice(2, 0, 0, 0)
    }
    const options = {
      stroke: {
        show: true,
        curve,
        lineCap: 'butt',
        colors: undefined,
        width: widthArr,
        dashArray: [0, prediction ? 5 : 0],
      },
      legend: {
        fontSize: largeView ? 16 : 12,
      },
      chart: {
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: this.animationTimeout,
          animateGradually: {
            enabled: false,
            // delay: 150
          },
          // dynamicAnimation: {
          //     enabled: true,
          //     speed: 350
          // }
        },
        events: {
          mounted: this.onChartUpdate,
          updated: this.onChartUpdate,
          animationEnd: this.onChartUpdate,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: 'pan',
        },
        zoom: {
          enabled: true,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
          position: 'bottomLeft',
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const percent = parseFloat(
            series[seriesIndex][dataPointIndex],
          ).toFixed(2)
          const date = w.globals.seriesX[seriesIndex][dataPointIndex]
          const format = this.props.intervalMonths ? "MMM 'YY" : 'YYYY'
          const textDate = Moment(date).format(format)
          return `
              <div class="marker${largeView ? '' : ' marker--sm'}">
                <div class="arrow_box">                    
                  <span class="arrow_box__title">${textDate}</span>
                  <div class="progress_bar">
                    <span class="progress_bar__percent">${percent}%</span>
                    <span class="progress_bar__slide" style="width: ${percent}%"/>
                  </div>
                </div>
              </div>
            `
        },
      }, // when touch cursor on marker
      // annotations: {
      //   yaxis: [{
      //     y: 30,
      //     borderColor: '#999',
      //     label: {
      //       show: true,
      //       text: 'Support',
      //       style: {
      //         color: "#fff",
      //         background: '#00E396'
      //       }
      //     }
      //   }],
      //   xaxis: [{
      //     x: new Date('14 Nov 2012').getTime(),
      //     borderColor: '#999',
      //     yAxisIndex: 0,
      //     label: {
      //       show: true,
      //       text: 'Rally',
      //       style: {
      //         color: "#fff",
      //         background: '#775DD0'
      //       }
      //     }
      //   }]
      // },
      colors,
      // chart: {
      //   zoom: {
      //     enabled: true
      //   }
      // },
      dataLabels: {
        enabled: false,
      },
      // stroke: {
      //   curve: 'straight'
      // },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#28283021',
        strokeDashArray: 4,
        row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0,
        },
        column: {
          colors: ['#f8f8f8', 'transparent'],
          opacity: 0,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Sep','Sep'],
        type: 'datetime',
        // min: new Date('01 Mar 2009').getTime(),
        // tickAmount: 3,
        title: {
          text: undefined,
        },
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: largeView ? '16px' : '13px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        min: 0,
        max: maxY,
        title: {
          text: yLabel, // 'Engagement score' or 'Retail count'
        },
        lines: {
          show: false,
          color: '#0f0',
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: largeView ? '16px' : '13px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-yaxis-label',
          },
        },
      },
      markers: {
        size: 0,
        opacity: 0.9,
        colors: ['#fff'],
        strokeColor: '#e06638',
        strokeWidth: 2,
        radius: 7,
        hover: {
          size: 5,
        },
      },
      fill: {
        colors: fillColor,
        // opacity: [1, 1,0.8,0.8,1,1,1],
        // type: 'gradient',
        // gradient: {
        //   shade: 'dark',
        //   type: 'vertical',
        // shadeIntensity: 0.5,
        // inverseColors: true,
        // gradientToColors: colors,
        // opacityFrom: 0,
        // opacityTo: 1,
        // stops: [0, 50, 100],
        // colorStops: [100, 50],
        // shadeIntensity: 1,
        // opacityFrom: 1,
        // opacityTo: 0.5,
        // stops: [0, 100]
        // },
      },
    }

    return options
  }

  render() {
    const {
      data,
      trendCoefficient,
      trendIntercept,
      xMax,
      xMin,
      selectedChart,
      cagr,
      yMin,
      yMax,
      predictedCoefficient,
      predictedIntercept,
      largeView,
      hasPadding,
      errorMargin,
      lens,
    } = this.props
    let trendData
    let predictedData
    let wholeXDomain
    let xDomain
    let yDomain
    let xDMin = 0
    let xDMax = 0
    let yDMin = 0
    let yDMax = 0
    let wholeYDomain
    let show = false
    let prediction = false
    let yPredictedDMax = 0
    let yPredictedDMin = 0
    let xPredictedDMax = 0
    let xPredictedDMin = 0
    let predictedCagr = 0
    let showPredicted = false
    let xPredictedDomain
    let lastThreePoints = []
    this.setWidth()

    try {
      trendData = data.find((d) => d.name === 'Historical Growth')
      predictedData = data.find((d) => d.name === 'Predicted Growth')
        if (trendCoefficient && trendIntercept) {
          // if(xMin >= trendData.data[0][0] && xMax <= trendData.data[trendData.data.length -1][0]){
          if (xMax <= trendData.data[trendData.data.length - 1][0]) {
            const trData = trendData.data.slice(-49)
            const calcData = {
              name: '4 year CAGR',
              // data:[[xMin, yMin],[xMax, yMax]],
              data: trData.map((d, i) => [
                d[0],
                trendIntercept + trendCoefficient * (49 - trData.length + i),
              ]),
              type: 'line',
            }
            const positiveData = calcData.data.filter((d) => d[1] >= 0)
            lastThreePoints = positiveData.slice(-3)
            data.push({ ...calcData, data: positiveData })
            xDMin = positiveData[0][0]
            xDMax = positiveData[positiveData.length - 1][0]
            yDMin = positiveData[0][1]
            yDMax = positiveData[positiveData.length - 1][1]
            show = true
            wholeXDomain = d3
              .scaleLinear()
              .domain([
                trendData.data[0][0],
                (predictedData || trendData).data[
                  (predictedData || trendData).data.length - 1
                ][0],
              ])
              .range([85, this.width])
            // xDomain = d3.scaleLinear().domain([xMin,xMax]).range([wholeXDomain(xMin), wholeXDomain(xMax)])
            xDomain = d3
              .scaleLinear()
              .domain([xDMin, xDMax])
              .range([wholeXDomain(xDMin), wholeXDomain(xDMax)])
            wholeYDomain = d3
              .scaleLinear()
              .domain([0, 100])
              .range([largeView ? 246 : 136, 0])
            // yDomain =  d3.scaleLinear().domain([yMin,yMax]).range([largeView ? 246 : 116, 0])
            yDomain = d3
              .scaleLinear()
              .domain([yDMin, yDMax])
              .range([largeView ? 246 : 136, 0])
          }
        }
        if (predictedData) {
          prediction = true
          // eslint-disable-next-line
          if (false && (predictedCoefficient || predictedIntercept)) {
            const trData = predictedData.data.slice(-13)
            const calcData = {
              name: 'Predicted CAGR',
              // data:[[xMin, yMin],[xMax, yMax]],
              data: trData.map((d, i) => [
                d[0],
                predictedIntercept + predictedCoefficient * i,
              ]),
              type: 'line',
            }
            const positiveData = calcData.data.filter((d) => d[1] >= 0)
            data.push({
              ...calcData,
              data: positiveData,
            })
            xPredictedDMin = positiveData[0][0]
            xPredictedDMax = positiveData[positiveData.length - 1][0]
            yPredictedDMin = positiveData[0][1]
            yPredictedDMax = positiveData[positiveData.length - 1][1]
            predictedCagr = (
              (Math.pow(
                yPredictedDMax / yPredictedDMin,
                12 / positiveData.length,
              ) -
                1) *
              100
            ).toFixed(2)
            showPredicted = true
            // xDomain = d3.scaleLinear().domain([xMin,xMax]).range([wholeXDomain(xMin), wholeXDomain(xMax)])
            xPredictedDomain = d3
              .scaleLinear()
              .domain([xPredictedDMin, xPredictedDMax])
              .range([
                wholeXDomain(xPredictedDMin),
                wholeXDomain(xPredictedDMax),
              ])
            // yDomain =  d3.scaleLinear().domain([yMin,yMax]).range([largeView ? 246 : 116, 0])
            // eslint-disable-next-line
            yPredictedDomain = d3
              .scaleLinear()
              .domain([yPredictedDMin, yPredictedDMax])
              .range([largeView ? 246 : 116, 0])
          }
        }
      // }
    } catch (er) {
      // console.log(er)
    }
    const color = cagr >= 0 ? '#7CFC00' : '#ff0000'
    const predictionColor = predictedCagr >= 0 ? '#7DED00' : '#FFB2B2'
    const options = this.getOptions(
      color,
      prediction,
      predictionColor,
      errorMargin,
    )

    const leftPosPredictionTooltip = xPredictedDomain
      ? xPredictedDomain((xPredictedDMin + xPredictedDMax) / 2) - 50
      : 0
    const topPosPredictionTooltip = wholeYDomain
      ? wholeYDomain((yPredictedDMin + yPredictedDMax) / 2)
      : 0

    const predictedToolTipPos = {
      left: leftPosPredictionTooltip,
      top: topPosPredictionTooltip,
    }
    const toolTipPos = {
      left: xDomain ? xDomain((xDMin + xDMax) / 2) - 50 : 0,
      top: wholeYDomain ? wholeYDomain((yDMin + yDMax) / 2) - 5 : 0,
    }

    if (
      xDomain &&
      lastThreePoints
        .reverse()
        .find((d) => xDomain(d[0]) > predictedToolTipPos.left)
    ) {
      predictedToolTipPos.top = predictedToolTipPos.top + 45
    }

    ;[toolTipPos, predictedToolTipPos].forEach((pos) => {
      if (pos.top < 10) {
        pos.top = 10
      } else if (pos.top > 240) {
        pos.top = 240
      }
      if (pos.left < 10) {
        pos.left = 10
      } else if (pos.left > this.width - 200) {
        pos.left = this.width - 200
      }
    })
    if (this.props.saveFilteredTrendData) {
      this.props.saveFilteredTrendData(data, cagr)
    }

    return (
      <>
        <div
          className={'chart-8888'}
          id="download-chart"
          style={
            largeView
              ? { position: 'relative', padding: hasPadding && "16px" }
              : { position: 'relative', marginTop: -15 }
          }
        >
          {show && (
            <div
              id="ChartTrendOverMui-4yrgr-tooltip"
              className="trend-growth-rate__tooltip"
              style={toolTipPos}
            >
              <span className="tooltip__growth-rate-title">
                {' '}
                4 year CAGR{' '}
                <span className="tooltip__growth-rate">
                  {Number(cagr).toFixed(2)}%
                </span>
              </span>
            </div>
          )}
          {showPredicted && (
            <div
              id="ChartTrendOverMui-predictedgr-tooltip"
              className="trend-growth-rate__tooltip"
              style={predictedToolTipPos}
            >
              <span className="tooltip__growth-rate-title">
                {' '}
                Predicted CAGR{' '}
                <span className="tooltip__growth-rate">{predictedCagr}%</span>
              </span>
            </div>
          )}
          <Chart
            ref={this.chartRef}
            options={options}
            series={data}
            type="line"
            height={largeView ? 350 : 235}
          />
        </div>
      </>
    )
  }
}

ChartTrendOverMui.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default ChartTrendOverMui
