import { LookForActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  searchLookForList : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case LookForActionTypes.type[LookForActionTypes.FETCH_LOOK_FOR_START]: return updateObject(state, {
      loading: true,
    })
    case LookForActionTypes.type[LookForActionTypes.FETCH_LOOK_FOR_SUCCESS]: return updateObject(state, {
      searchLookForList : action.lookForList,
      error : false,
      loading : false,
    })
    case LookForActionTypes.type[LookForActionTypes.FETCH_LOOK_FOR_FAILED]: return updateObject(state, {
      searchLookForList : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer