import React, { useEffect, useMemo, useRef, useState } from 'react'
// import * as d3 from 'd3'
// import { Box } from '@mui/material'
// import { getTextDimensions, dataOverlaps } from '../../store/utility'
// import InformationIcon from '../../shared/components/InformationIcon'
// import TooltipHoverCard from './Tooltip'
// import { IconBox } from './Styled'
// import { amplify } from '../../store/utility'
import ScatteredGraphUI from './ScatteredGraphUI'
import { phaseColor } from '../../../utils/constants'

const isTrendCheckedInitial = {
  DORMANT: true,
  EMERGING: true,
  GROWING: true,
  MATURED: true,
  DECLINING: true,
  FADING: true,
}

const ScatteredGraph = ({
  filteredData,
  lensSelected,
  graphHelperData,
  viewDetailsClicked,
  resetZoomOnGraphDataUpdate,
  scatteredPlotTooltip,
  handleHover,
  searchValue,
  demographyComponent,
  medianXVal,
  medianYVal,
  resetBtn,
  setResetBtn,
  resetBtnId,
}) => {
  const [isTrendChecked, setIsTrendChecked] = useState(isTrendCheckedInitial)
  const [isBrushUsed, setIsBrushUsed] = useState(false)

  return (
    <ScatteredGraphUI
      graphTrendData={filteredData}
      resetZoomOnGraphDataUpdate={resetZoomOnGraphDataUpdate}
      phaseColor={phaseColor}
      lensSelected={lensSelected}
      searchValue={searchValue}
      maturityPhase={isTrendChecked}
      graphHelperData={graphHelperData}
      scatteredPlotTooltip={scatteredPlotTooltip}
      handleHover={handleHover}
      isBrushUsed={isBrushUsed}
      setIsBrushUsed={setIsBrushUsed}
      viewDetailsClicked={viewDetailsClicked}
      medianXVal={medianXVal}
      medianYVal={medianYVal}
      demographyComponent
      resetBtn={resetBtn}
      setResetBtn={setResetBtn}
      resetBtnId={resetBtnId}
    />
  )
}

export default ScatteredGraph
