import * as AC from '../actions/actionConstants'
import { GetUserProfileActionTypes } from '../actions/actionTypes'

const userReducer = (state = {
  all: [],
  loggedIn: {},
  loginError: '',
  loginIcon: true,
  redirect: false,
  redirectUrl: '',
  isUserLoggedOut: true,
  loginUserDetail: {},
  userProfile: null,
  loading : false,
  loadingProfile: true,
}, action) => {
  switch (action.type) {
    case AC.ADD_LOGIN_USER:
      if (action.user) {
        return Object.assign({}, state, {
          loggedIn: Object.assign({}, action.user),
          isUserLoggedOut: Object.assign({}, state, { isUserLoggedOut: false }),
          userProfile: Object.assign({}, action.user),
        })
      } else { return state }

    case AC.SHOW_LOGOUT_ICON:
      return Object.assign({}, state, { loginIcon: false })

    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, { isUserLoggedOut: true, loggedIn: '' })
    case AC.ADD_LOGIN_USER_DETAIL:
      return Object.assign({}, state, {
        loginUserDetail: action.loginUserDetail,
        loggedIn: Object.assign({}, state.loggedIn, {
          userDetails: action.loginUserDetail,
        }),
        loginError: '',
      })
    case AC.ADD_LOGIN_USER_PROFILE:
      if (action.user.data) {
        return {
          ...state,
          loggedIn: {
            ...state.loggedIn,
            user: {
              ...state.loggedIn.user,
              firstName: action.user.data.first_name,
              lastName: action.user.data.last_name,
            },
          },
          userProfile: {
            ...state.userProfile,
            user: {
              ...state.userProfile.user,
              firstName: action.user.data.first_name,
              lastName: action.user.data.last_name,
            }
          }
        }
      }
      return state

    case AC.REDIRECT_VISIBILITY:
      return Object.assign({}, state, { redirect: true })
    case AC.LOGIN_FAILED:
      return Object.assign({}, state, {
        loginError: action.error,
      })
    case AC.ACCEPT_POLICY:
      const loggedIn = { ...state.loggedIn }
      loggedIn.accepted_policy = true
      loggedIn.accepted_updated_policy = true
      return {
        ...state,
        loggedIn,
      }
    case GetUserProfileActionTypes.type[GetUserProfileActionTypes.FETCH_USER_PROFILE_START]:
      return Object.assign({}, state, {
        loading: true,
      })
    case GetUserProfileActionTypes.type[GetUserProfileActionTypes.FETCH_USER_PROFILE_SUCCESS]:
      return Object.assign({}, state, {
        userProfile : action.userProfile,
        loginError : '',
        loading : false,
      })

    case GetUserProfileActionTypes.type[GetUserProfileActionTypes.FETCH_USER_PROFILE_FAILED]:
      return Object.assign({}, state, {
        userProfile : null,
        loginError : action.error,
        loading : false,
      })

    case GetUserProfileActionTypes.type[GetUserProfileActionTypes.SET_LOADING_PROFILE]:
      return {...state, loadingProfile: action.loading}
    
    case GetUserProfileActionTypes.type[GetUserProfileActionTypes.SET_REDIRECTION_URL]:
      return {...state, redirectUrl: action.url}
      
    default:
      return state
  }
}

export default userReducer
