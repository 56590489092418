// Application common constant

export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'
export const ARRANGE_BREADCRUMBS_VALUES = 'ARRANGE_BREADCRUMBS_VALUES'
export const ADD_NEW_BREADCRUMBS = 'ADD_NEW_BREADCRUMBS'
export const BREADCRUMBS_REMOVE_AFTER = 'BREADCRUMBS_REMOVE_AFTER'
export const BREADCRUMBS_ADD = 'BREADCRUMBS_ADD'
export const BREADCRUMBS_REPLACE = 'BREADCRUMBS_REPLACE'
export const SAVE_BREAD_CRUMBS_DATA = 'SAVE_BREAD_CRUMBS_DATA'
export const SHOW_FILTER_PANEL = 'SHOW_FILTER_PANEL'
export const ADD_HIGHLIGHT = 'ADD_HIGHLIGHT'
export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT'
// Login action constant
export const ADD_LOGIN_USER = 'ADD_LOGIN_USER'
export const SHOW_LOGOUT_ICON = 'SHOW_LOGOUT_ICON'
export const SET_USER_LOGGED_OUT = 'SET_USER_LOGGED_OUT'
export const ADD_LOGIN_USER_DETAIL = 'ADD_LOGIN_USER_DETAIL'
export const ADD_LOGIN_USER_PROFILE = 'ADD_LOGIN_USER_PROFILE'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const REDIRECT_VISIBILITY = 'REDIRECT_VISIBILITY'

// registration action constants
export const ADD_USER = 'ADD_USER'
export const ADD_ORG_ADMIN = 'ADD_ORG_ADMIN'
export const ADD_ORG_USER = 'ADD_ORG_USER'
export const EDIT_ORGANIZATION_ADMIN = 'EDIT_ORGANIZATION_ADMIN'
export const DELETE_ORGANIZATION_ADMIN = 'DELETE_ORGANIZATION_ADMIN'
export const EDIT_ORGANIZATION_USER = 'EDIT_ORGANIZATION_USER'
export const DELETE_ORGANIZATION_USER = 'DELETE_ORGANIZATION_USER'
export const ACCEPT_POLICY = 'ACCEPT_POLICY'

export const RESET_PROJECT_DETAILS = 'RESET_PROJECT_DETAILS'

// build-winner action constants
export const ADD_BUILD_WINNER = 'ADD_BUILD_WINNER'
export const ADD_FOOD_CATEGORIES = 'ADD_FOOD_CATEGORIES'
export const ADD_WINNING_IDEAS = 'ADD_WINNING_IDEAS'
export const RESET_WINNING_IDEAS = 'RESET_WINNING_IDEAS'

// organization action
export const ADD_ALL_PROJECTS = 'ADD_ALL_PROJECTS'
export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'
export const ADD_ALL_ORGANIZATIONS = 'ADD_ALL_ORGANIZATIONS'
export const ADD_ORGANIZATION_VIEW_DETAILS = 'ADD_ORGANIZATION_VIEW_DETAILS'
export const SHOW_ORGANIZATION_FORM = 'SHOW_ORGANIZATION_FORM'
export const SHOW_ORGANIZATION_LIST = 'SHOW_ORGANIZATION_LIST'
export const HIDE_ORGANIZATION_FORM = 'HIDE_ORGANIZATION_FORM'
export const HIDE_ORGANIZATION_LIST = 'HIDE_ORGANIZATION_LIST'
export const SHOW_ORGANIZATION_VIEW = 'SHOW_ORGANIZATION_VIEW'
export const HIDE_ORGANIZATION_VIEW = 'HIDE_ORGANIZATION_VIEW'
export const SHOW_REGISTRATION_FORM = 'SHOW_REGISTRATION_FORM'
export const SHOW_USER_REGISTRATION_FORM = 'SHOW_USER_REGISTRATION_FORM'
export const SHOW_ORGANIZATION_ADMIN_LIST = 'SHOW_ORGANIZATION_ADMIN_LIST'
export const SHOW_ORGANIZATION_USER_LIST = 'SHOW_ORGANIZATION_USER_LIST'
export const HIDE_ORGANIZATION_ADMIN_LIST = 'HIDE_ORGANIZATION_ADMIN_LIST'
export const HIDE_ORGANIZATION_USER_LIST = 'HIDE_ORGANIZATION_USER_LIST'
export const HIDE_REGISTRATION_FORM = 'HIDE_REGISTRATION_FORM'
export const HIDE_USER_REGISTRATION_FORM = 'HIDE_USER_REGISTRATION_FORM'
export const GET_ALL_USERS_OF_ORGANIZATION = 'GET_ALL_USERS_OF_ORGANIZATION'
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const GET_ALL_SUB_USERS = 'GET_ALL_SUB_USERS'
export const ADD_ORG_SUB_USER = 'ADD_ORG_SUB_USER'
export const EDIT_ORGANIZATION_SUB_USER = 'EDIT_ORGANIZATION_SUB_USER'
export const DELETE_ORGANIZATION_SUB_USER = 'DELETE_ORGANIZATION_SUB_USER'
export const SHOW_ORGANIZATION_PROJECT_LIST = 'SHOW_ORGANIZATION_PROJECT_LIST'
export const HIDE_ORGANIZATION_PROJECT_LIST = 'HIDE_ORGANIZATION_PROJECT_LIST'
export const SHOW_ORGANIZATION_PROJECT_FORM = 'SHOW_ORGANIZATION_PROJECT_FORM'
export const HIDE_ORGANIZATION_PROJECT_FORM = 'HIDE_ORGANIZATION_PROJECT_FORM'
export const GET_ALL_PROJECTS_OF_ORGANIZATION =
  'GET_ALL_PROJECTS_OF_ORGANIZATION'
export const DELETE_ORGANIZATION_PROJECT = 'DELETE_ORGANIZATION_PROJECT'
export const ADD_ORG_PROJECT = 'ADD_ORG_PROJECT'
export const SHOW_ORGANIZATION_FEATURE_LIST = 'SHOW_ORGANIZATION_FEATURE_LIST'
export const HIDE_ORGANIZATION_FEATURE_LIST = 'HIDE_ORGANIZATION_FEATURE_LIST'
export const SHOW_ORGANIZATION_FEATURE_FORM = 'SHOW_ORGANIZATION_FEATURE_FORM'
export const HIDE_ORGANIZATION_FEATURE_FORM = 'HIDE_ORGANIZATION_FEATURE_FORM'
export const GET_ALL_FEATURES_OF_ORGANIZATION =
  'GET_ALL_FEATURES_OF_ORGANIZATION'
export const DELETE_ORGANIZATION_FEATURE = 'DELETE_ORGANIZATION_FEATURE'
export const ADD_ORG_FEATURE = 'ADD_ORG_FEATURE'
export const GET_ALL_FEATURES = 'GET_ALL_FEATURES'

// comparision constants

export const STORE_PRODUCT_DETAILS_FOR_COMPARISION =
  'STORE_PRODUCT_DETAILS_FOR_COMPARISION'
export const REMOVE_PRODUCT_FROM_COMAPRISION_LIST =
  'REMOVE_PRODUCT_FROM_COMAPRISION_LIST'
export const SHOW_COMPARISION_NOTIFICATION = 'SHOW_COMPARISION_NOTIFICATION'
export const HIDE_COMPARISION_NOTIFICATION = 'HIDE_COMPARISION_NOTIFICATION'
export const CLEAR_COMPARISION_LIST = 'CLEAR_COMPARISION_LIST'
export const GET_PRODUCT_LIST_FOR_COMPARE_DROPDOWN =
  'GET_PRODUCT_LIST_FOR_COMPARE_DROPDOWN'
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT'

// permission

export const SHOW_PERMISSION_FORM = 'SHOW_PERMISSION_FORM'
export const HIDE_PERMISSION_FORM = 'HIDE_PERMISSION_FORM'
export const SHOW_PERMISSION_LIST = 'SHOW_PERMISSION_LIST'
export const HIDE_PERMISSION_LIST = 'HIDE_PERMISSION_LIST'
export const ADD_ALL_PERMISSIONS = 'ADD_ALL_PERMISSIONS'
export const ADD_PERMISSION = 'ADD_PERMISSION'
export const EDIT_PERMISSION = 'EDIT_PERMISSION'
export const DELETE_PERMISSION = 'DELETE_PERMISSION'

// role
export const SHOW_ROLE_FORM = 'SHOW_ROLE_FORM'
export const HIDE_ROLE_FORM = 'HIDE_ROLE_FORM'
export const SHOW_ROLE_LIST = 'SHOW_ROLE_LIST'
export const HIDE_ROLE_LIST = 'HIDE_ROLE_LIST'
export const ADD_ALL_ROLES = 'ADD_ALL_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

// Project list
export const SET_PROJECT_LIST_HIDDEN_CONF = 'SET_PROJECT_LIST_HIDDEN_CONF'

// needs action
export const CONSUMERNEED_SCORES_FETCH_SUCCESS =
  'CONSUMERNEED_SCORES_FETCH_SUCCESS'
export const CONSUMERNEED_SCORES_FETCH_FAILED =
  'CONSUMERNEED_SCORES_FETCH_FAILED'
export const TRENDS_PHASES_DATA_FETCH_SUCCESS =
  'TRENDS_PHASES_DATA_FETCH_SUCCESS'

// Cafe and retail products
export const ADD_CAFE_PRODUCTS = 'ADD_CAFE_PRODUCTS'
export const ADD_RETAIL_PRODUCTS = 'ADD_RETAIL_PRODUCTS'

// Cafe & Retail Product Formats
export const ADD_CAFE_PRODUCT_FORMATS = 'ADD_CAFE_PRODCUT_FORMATS'
export const ADD_RETAIL_PRODUCT_FORMATS = 'ADD_RETAIL_PRODUCT_FORMATS'

// Pairing
export const ADD_PAIRING_DETAILS = 'ADD_PAIRING_DETAILS'

// Related Trends
export const ADD_RELATED_TRENDS_DETAILS = 'ADD_RELATED_TRENDS_DETAILS'
export const ADD_RELATED_TRENDS_V2 = 'ADD_RELATED_TRENDS_V2'
export const ADD_RELATED_TRENDS_V2_LOADING = 'ADD_RELATED_TRENDS_V2_LOADING'
export const ADD_RELATED_TRENDS_V2_FAILURE = 'ADD_RELATED_TRENDS_V2_FAILURE'

// Chart
export const SAVE_FILTER = 'SAVE_FILTER'

// Download sections on the product page
export const DOWNLOAD_SECTION_ADD = 'DOWNLOAD_SECTION_ADD'
export const DOWNLOAD_SECTION_CLEAR = 'DOWNLOAD_SECTION_CLEAR'
export const DOWNLOAD_SECTION_REMOVE = 'DOWNLOAD_SECTION_REMOVE'
export const DOWNLOAD_SECTION_DOWNLOAD = 'DOWNLOAD_SECTION_DOWNLOAD'
export const DOWNLOAD_SECTION_STATUS = 'DOWNLOAD_SECTION_STATUS'
export const DOWNLOAD_SECTION_SELECT_ALL = 'DOWNLOAD_SECTION_SELECT_ALL'
export const DOWNLOAD_SINGLE_DOWNLOAD = 'DOWNLOAD_SINGLE_DOWNLOAD'
export const DOWNLOAD_ALL = 'DOWNLOAD_ALL'

export const SPINNER_DOWNLOAD = 'SPINNER_DOWNLOAD'
export const ADD_REQUEST = 'ADD_REQUEST'
export const DONE_REQUEST = 'DONE_REQUEST'
export const SINGLE_SPINNER_DOWNLOAD = 'SINGLE_SPINNER_DOWNLOAD'
// Advanced Search
export const ADVANCED_SEARCH_SAVE = 'ADVANCED_SEARCH_SAVE'
export const ADVANCED_SEARCH_UPDATE = 'ADVANCED_SEARCH_UPDATE'

export const EDIT_TREND_PILLAR_SUCCESS = 'EDIT_TREND_PILLAR_SUCCESS'
export const EDIT_TREND_PILLAR_PENDING = 'EDIT_TREND_PILLAR_PENDING'
export const EDIT_TREND_PILLAR_FAILURE = 'EDIT_TREND_PILLAR_FAILURE'

export const CREATE_PILLAR_SUCCESS = 'CREATE_PILLAR_SUCCESS'
export const CREATE_PILLAR_PENDING = 'CREATE_PILLAR_PENDING'
export const CREATE_PILLAR_FAILURE = 'CREATE_PILLAR_FAILURE'

export const GET_TREND_PILLAR_TO_EDIT = 'GET_TREND_PILLAR_TO_EDIT'

export const INFO_ABOUT_TREND_PILLAR = 'INFO_ABOUT_TREND_PILLAR'

export const IMPORT_TREND_PILLAR_SUCCESS = 'IMPORT_TREND_PILLAR_SUCCESS'
export const IMPORT_TREND_PILLAR_FAILURE = 'IMPORT_TREND_PILLAR_FAILURE'

export const FETCH_TRENDS_PILLAR_SUCCESS = 'FETCH_TRENDS_SUCCESS'
export const FETCH_TRENDS_PILLAR_FAILURE = 'FETCH_TRENDS_FAILURE'

export const DELETE_TRENDS_PILLAR_SUCCESS = 'DELETE_TRENDS_SUCCESS'
export const DELETE_TRENDS_PILLAR_FAILURE = 'DELETE_TRENDS_FAILURE'

export const DOWNLOAD_TRENDS_PILLAR_SUCCESS = 'DOWNLOAD_TRENDS_SUCCESS'
export const DOWNLOAD_TRENDS_PILLAR_FAILURE = 'DOWNLOAD_TRENDS_FAILURE'

export const IMPORT_TREND_PILLAR_DETAILS = 'IMPORT_TREND_PILLAR_DETAILS'

export const TREND_PILLAR_TREND_DETAILS_SUCCESS =
  'TREND_PILLAR_TREND_DETAILS_SUCCESS'
export const TREND_PILLAR_TREND_DETAILS_FAILURE =
  'TREND_PILLAR_TREND_DETAILS_FAILURE'
