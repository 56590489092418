import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import OutsideClickHandler from 'react-outside-click-handler'

import {
  fetchProjectDetails,
  fetchConcepts,
  updateProjectDetails,
  updateConceptDetails,
  saveConceptDetails,
  startScreeningProject,
  deleteConcept,
  checkScreeningStatus,
  cloneConcept,
  notify,
} from '../../store/actions/screenWinnerActions'
import {
  formatString,
  getQueryParams,
  isEnterClicked,
} from '../../store/utility'
import {
  notifications,
  screeningStatus as swStatus,
  screenWinnerMessages as swMsgs,
} from '../../const'

import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'

import ButtonCard from '../Buttons/ButtonCard'
import Button from '../Buttons/ButtonSW'
import ConceptCard from '../ConceptCard/ConceptCard'
import ConceptCreateEdit from '../ConceptCreateEdit/ConceptCreateEdit'
import HighLighter from '../HighLighter/HighLighter'
import Tooltip from '../SWTooltip/SWTooltip'
import LinkButton from '../LinkButton/LinkButton'
import SWAlert from '../SWAlert/SWAlert'

import addConcept from '../../assets/images/screenWinner/add_concept.svg'
import calendarIcon from '../../assets/images/screenWinner/sw-calendar-icon.svg'
import plusBtnIcon from '../../assets/images/screenWinner/sw-add-btn-icon.svg'

import './ProjectScreen.scss'

const maxVisbleStringLength = 250
const MIN_MUST_CNCPTS = 10
const MIN_GOOD_TO_HAVE_CNCPTS = 15

const ProjectScreen = ({
  match,
  history,
  projectDetails,
  conceptsList = [],
  fetchConcepts,
  fetchProjectDetails,
  updateProjectDetails,
  updateConceptDetails,
  saveConceptDetails,
  checkScreeningStatus,
  startScreeningProject,
  deleteConcept,
  cloneConcept,
  notify,
  screenWinnerSubscription,
}) => {
  const {
    title = '',
    country = '',
    category = '',
    brand = '',
    description = '',
    lastScreened,
    screening_status: projectScreeningStatus,
  } = projectDetails || {}
  const firstRender = useRef(null)
  const { projectId } = match.params
  const [projectTitle, setProjectTitle] = useState(title)
  const [projectDescription, setProjectDescription] = useState(description)
  const [projectBrand, setProjectBrand] = useState(brand)
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [createConcept, setCreateConcept] = useState(false)
  const [selectedConceptId, setSelectedConceptId] = useState('')
  const [editBrand, setEditBrand] = useState(false)
  const [editProjectTitle, setEditProjectTitle] = useState(false)
  const [conceptCrudWarning, setConceptCrudWarning] = useState(false)
  const [acknowledgedWarning, setacknowledgedWarning] = useState(false)
  const [groupedDuplicateConcepts, setGroupedDuplicateConcepts] = useState([])
  const prjScreening = projectScreeningStatus === swStatus.IN_PROGRESS
  const prjScreened = projectScreeningStatus === swStatus.SCREENED
  const [hasConceptsScreened, setHasConceptsScreened] = useState(true)
  const conceptDuplicatetMsg = groupedDuplicateConcepts.reduce(
    (acc, cncptGrp) => {
      return [
        ...acc,
        <div className="projects-screen__duplicate">
          {[...cncptGrp]
            .sort(({ id: a }, { id: b }) => a - b)
            .map(({ id, title }, i) => {
              const conceptTxt = ` '${title} [${id}]'`
              return i === 0 ? (
                <span className="projects-screen__duplicate_concept_msg duplicate_concept_msg--orig">
                  <span className="projects-screen__duplicate_concept_msg__title">
                    Original:
                  </span>
                  {conceptTxt}
                </span>
              ) : (
                <span className="projects-screen__duplicate_concept_msg duplicate_concept_msg--copy">
                  {i === 1 && (
                    <span className="projects-screen__duplicate_concept_msg__title">
                      Copy:
                    </span>
                  )}
                  {conceptTxt}
                </span>
              )
            })}
        </div>,
      ]
    },
    [],
  )

  const selectedConcept = conceptsList.find(
    ({ id }) => id === selectedConceptId,
  )

  const conceptInvalid = conceptsList.some(({ keywordsAnalysed }) => {
    for (const [, value] of Object.entries(keywordsAnalysed)) {
      return !value.length
    }
  })

  const getDuplicateConcepts = () => {
    const groupedConceptIds = new Map()
    conceptsList.forEach(({ id, title, description }) => {
      description = description.toLowerCase()
      description = description.replace(/[^A-Za-z0-9]+/gi, ' ')
      if (groupedConceptIds.has(description)) {
        groupedConceptIds.set(description, [
          { id, title },
          ...groupedConceptIds.get(description),
        ])
      } else {
        groupedConceptIds.set(description, [{ id, title }])
      }
    })
    return groupedConceptIds
  }

  const handleViewScreenedResults = () => {
    history.push(`/screen-winner/projects/${projectId}/screened-results`)
  }

  const handleStartScrenPrj = () => {
    const groupedConceptIds = getDuplicateConcepts()
    const conceptDuplicates = []
    for (const [id, conceptGroups] of groupedConceptIds) {
      if (conceptGroups.length > 1) {
        conceptDuplicates.push(conceptGroups)
      }
    }
    if (conceptDuplicates.length) {
      setGroupedDuplicateConcepts(conceptDuplicates)
    } else if (
      !prjScreening &&
      !conceptInvalid &&
      conceptsList?.length >= MIN_MUST_CNCPTS
    ) {
      startScreeningProject(projectId)
    }
  }

  useEffect(() => {
    const screenedConcepts = conceptsList.filter(
      (cncpt) => cncpt.screeningStatus === swStatus.SCREENED,
    )
    setHasConceptsScreened(screenedConcepts?.length > 0)
  }, [conceptsList])

  const screenButton = prjScreening ? (
    <Tooltip
      ignoreTextWidth
      relChildPos
      offsetLeft={20}
      text={swMsgs.PROJECT_SCREENING_MSG}
    >
      <Button
        className="projects-screen__screen-btns__screen"
        solid
        disabled={true}
        name="Screening in Progress"
        onClick={handleStartScrenPrj}
      />
    </Tooltip>
  ) : (
    <Button
      className="projects-screen__screen-btns__screen"
      solid
      disabled={conceptInvalid || conceptsList?.length < MIN_MUST_CNCPTS}
      name="Screen Project"
      onClick={handleStartScrenPrj}
    />
  )

  const removeSelectedConcept = () => {
    setSelectedConceptId('')
    setCreateConcept(false)
  }

  const handleSaveProjectTitle = () => {
    setEditProjectTitle(false)
    if (projectTitle && projectTitle !== title) {
      updateProjectDetails(
        { ...projectDetails, title: projectTitle },
        projectId,
      ).then((success) => {
        if (!success) {
          setProjectTitle(title)
        }
      })
    } else {
      setProjectTitle(title)
    }
  }

  const handleSaveProjectTitleOnEnter = (ev) => {
    if (isEnterClicked(ev)) {
      handleSaveProjectTitle()
    }
  }

  const handleBrandBlur = () => {
    setEditBrand(false)
    if (projectBrand !== brand) {
      updateProjectDetails(
        { ...projectDetails, brand: projectBrand },
        projectId,
      )
    }
  }

  const handleDescriptionBlur = () => {
    setEditDescription(false)
    if (projectDescription !== description) {
      updateProjectDetails(
        { ...projectDetails, description: projectDescription },
        projectId,
      )
    }
  }

  const handleDescriptionEdit = () => {
    setProjectDescription(description)
    setEditDescription(true)
  }

  const handleSaveProjectBrandOnEnter = (ev) => {
    if (isEnterClicked(ev)) {
      handleBrandBlur()
    }
  }

  const handleAddConcept = () => {
    if (prjScreening) {
      notify({
        type: notifications.ERROR,
        message: swMsgs.CONCEPT_ADD_DISABLED,
      })
    } else if (prjScreened && !acknowledgedWarning) {
      setConceptCrudWarning(true)
    } else {
      setCreateConcept(true)
    }
  }

  const handleDeleteConcept = (conceptId) => {
    if (prjScreening) {
      notify({
        type: notifications.ERROR,
        message: swMsgs.CONCEPT_DELETE_DISABLED,
      })
    } else if (prjScreened && !acknowledgedWarning) {
      setConceptCrudWarning(true)
    } else {
      deleteConcept(conceptId, projectId)
    }
  }

  const handleViewMore = (ev) => {
    ev.stopPropagation()
    setShowFullDescription((showFullDescription) => !showFullDescription)
  }

  const handleCloneConcept = (conceptId) => {
    if (prjScreening) {
      notify({
        type: notifications.ERROR,
        message: swMsgs.CONCEPT_COPY_DISABLED,
      })
    } else if (prjScreened && !acknowledgedWarning) {
      setConceptCrudWarning(true)
    } else {
      cloneConcept(projectId, conceptId)
    }
  }

  useEffect(() => {
    if (showFullDescription) {
      setProjectDescription(description)
    } else {
      setProjectDescription(
        description.slice(0, maxVisbleStringLength).trim() + '...',
      )
    }
  }, [showFullDescription])

  useEffect(() => {
    if (description.length > maxVisbleStringLength && !showFullDescription) {
      setShowFullDescription(false)
    } else {
      setShowFullDescription(true)
    }
  }, [description])

  useEffect(() => {
    if (editDescription) {
      setProjectDescription(description)
    }
  }, [editDescription])

  useEffect(() => {
    if (screenWinnerSubscription) {
      const cncptId = getQueryParams().get('activeCncpt')
      if (cncptId && cncptId > 0) {
        setSelectedConceptId(Number(cncptId))
      }
      if (projectId) {
        fetchProjectDetails(projectId)
        fetchConcepts(projectId)
      }
    }
  }, [screenWinnerSubscription])

  useEffect(() => {
    if (projectId && prjScreening) {
      const checkStatusIntId = setInterval(
        () => checkScreeningStatus(projectId),
        10000,
      )
      return () => clearInterval(checkStatusIntId)
    }
  }, [prjScreening])

  useEffect(() => {
    const queryParams = getQueryParams()
    if (selectedConceptId) {
      queryParams.set('activeCncpt', selectedConceptId)
    } else if (firstRender.current) {
      queryParams.delete('activeCncpt')
    }
    history.replace(`?${queryParams.toString()}`)
  }, [selectedConceptId])

  useEffect(() => {
    if (projectDetails) {
      const { title = '', brand = '', description = '' } = projectDetails || {}
      setProjectTitle(title)
      setProjectDescription(description)
      setProjectBrand(brand)
    }
  }, [projectDetails])

  useEffect(() => {
    if (acknowledgedWarning) {
      setConceptCrudWarning(false)
    }
  }, [acknowledgedWarning])

  useEffect(() => {
    firstRender.current = true
  }, [])

  const getScreenProjectBtn = () => {
    if (conceptInvalid) {
      return (
        <Tooltip
          ignoreTextWidth
          relChildPos
          offsetLeft={20}
          text={swMsgs.SCREEN_ON_CONCEPT_FIX}
        >
          {screenButton}
        </Tooltip>
      )
    } else if (
      !prjScreening &&
      conceptsList?.length < MIN_GOOD_TO_HAVE_CNCPTS
    ) {
      return (
        <Tooltip
          ignoreTextWidth
          relChildPos
          offsetLeft={20}
          text={swMsgs.SCREEN_ON_MIN_CONCEPTS}
        >
          {screenButton}
        </Tooltip>
      )
    }
    return screenButton
  }

  return (
    <div className="projects-screen">
      {/* {screenStatusMessage && <SWAlert />} */}
      {conceptCrudWarning && (
        <SWAlert
          type={notifications.ERROR}
          message={swMsgs.CONCEPT_CRUD_WARNING}
          onCancel={() => setConceptCrudWarning(false)}
          onConfirm={() => setacknowledgedWarning(true)}
        />
      )}
      {Boolean(groupedDuplicateConcepts.length) && (
        <SWAlert
          title="Duplicate Concepts Found!"
          message={[
            <div className="text-align mb-10 ">Concepts</div>,
            ...conceptDuplicatetMsg.map((msg) => (
              <div className="projects-screen__duplicate_concept_msg">
                {msg}
              </div>
            )),
            <div className="projects-screen__duplicate_concept_msg">
              Please change concept descriptions of copies to proceed.
            </div>,
          ]}
          showCancel={false}
          onConfirmText="OK"
          onConfirm={() => setGroupedDuplicateConcepts([])}
        />
      )}
      <div className="projects-screen__screen-btns__block">
        <div className="projects-screen__screen-btns">
          {projectScreeningStatus === swStatus.SCREENED &&
          hasConceptsScreened ? (
            <Button
              className="projects-screen__screen-btns__view"
              name="View Screened Results"
              onClick={handleViewScreenedResults}
            />
          ) : (
            getScreenProjectBtn()
          )}
          {/* <div className="projects-screen__screen-btns__link">
            <LinkButton link={window.location.href} />
          </div> */}
        </div>
      </div>
      <div className="projects-screen__meta-info">
        {editProjectTitle ? (
          <input
            className="projects-screen__project-title--edit sw-font-fs--22"
            onBlur={handleSaveProjectTitle}
            value={projectTitle}
            onChange={({ target }) =>
              setProjectTitle(formatString(target.value, true))
            }
            onKeyUp={handleSaveProjectTitleOnEnter}
          />
        ) : (
          <Tooltip relChildPos showOnOverflow text={projectTitle}>
            <h1
              onClick={() => setEditProjectTitle(true)}
              className="projects-screen__project-title sw-font-fs--22"
            >
              {projectTitle}
            </h1>
          </Tooltip>
        )}
        <span className="projects-screen__market sub-title--14">
          {`${country} ${category}`}
        </span>
        {brand && (
          <div className="projects-screen__brand__block">
            {editBrand ? (
              <input
                className="projects-screen__brand-edit sub-title--14"
                onBlur={handleBrandBlur}
                value={projectBrand}
                onChange={({ target }) => setProjectBrand(target.value)}
                onKeyUp={handleSaveProjectBrandOnEnter}
              />
            ) : (
              <Tooltip relChildPos showOnOverflow text={projectBrand}>
                <span
                  className="projects-screen__brand sub-title--14"
                  onClick={() => setEditBrand(true)}
                >
                  {projectBrand}
                </span>
              </Tooltip>
            )}
          </div>
        )}
        {lastScreened && (
          <span className="projects-screen__screen-status">
            <img
              className="projects-screen__screen-status__calendar"
              src={calendarIcon}
              alt="calendar icon"
            />
            {`Last screened ${moment(lastScreened).format('Do MMM, YY')}`}
          </span>
        )}
        <div className="projects-screen__score-legend">
          <span>
            <span className="score-val">0</span> (worst score){' '}
          </span>
          <span className="projects-screen__score-legend__grp">
            <span className="score score--20"></span>
            <span className="score score--40"></span>
            <span className="score score--60"></span>
            <span className="score score--80"></span>
            <span className="score score--100"></span>
          </span>
          <span>
            <span className="score-val"> 100</span> (best score)
          </span>
        </div>
      </div>
      {editDescription ? (
        <OutsideClickHandler onOutsideClick={handleDescriptionBlur}>
          <div className="projects-screen__description__edit">
            <WysiwygEditor
              initialValue={projectDescription}
              handleOnChange={(value) => setProjectDescription(value)}
            />
          </div>
        </OutsideClickHandler>
      ) : (
        <>
          {description ? (
            <p
              onClick={handleDescriptionEdit}
              className="projects-screen__description para--14"
            >
              {projectDescription}
              {description.length > maxVisbleStringLength && (
                <button
                  className="projects-screen__description__view-btn"
                  type="button"
                  onClick={handleViewMore}
                >
                  {showFullDescription ? 'view less' : 'view more'}
                </button>
              )}
            </p>
          ) : null}
        </>
      )}

      <div className="projects-screen__projects-list">
        <div className="projects-screen__projects-list__items projects-screen__add-new">
          <ButtonCard
            img={addConcept}
            leftImage={plusBtnIcon}
            imgAlt="Add New Concept"
            name="Add New Concept"
            imgClassName="projects-screen__add-new-card__btn-img"
            className="projects-screen__add-new-card__btn"
            onClick={handleAddConcept}
          />
        </div>
        {conceptsList.map(({ id, ...cncptProps }) => (
          <div
            key={id}
            className="projects-screen__projects-list__items"
            onClick={() => setSelectedConceptId(id)}
          >
            <HighLighter
              highlighterId={id}
              className="projects-screen__highlight"
            >
              <ConceptCard
                id={id}
                {...cncptProps}
                deleteConcept={handleDeleteConcept}
                cloneConcept={handleCloneConcept}
              />
            </HighLighter>
          </div>
        ))}
      </div>
      {createConcept && (
        <ConceptCreateEdit
          onCloseCallback={() => setCreateConcept(false)}
          projectId={projectId}
          handleSaveCncptDetails={saveConceptDetails}
          handleUpdtCncptDetails={updateConceptDetails}
        />
      )}
      {selectedConcept && `${selectedConceptId}` && (
        <ConceptCreateEdit
          editWarning={prjScreened && !acknowledgedWarning}
          editWarningCallback={() => setConceptCrudWarning(true)}
          disableEdit={prjScreening}
          projectId={projectId}
          conceptDetails={selectedConcept}
          onCloseCallback={removeSelectedConcept}
          handleSaveCncptDetails={saveConceptDetails}
          handleUpdtCncptDetails={updateConceptDetails}
          screened={selectedConcept.screeningStatus === swStatus.SCREENED}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    projectDetails,
    conceptsList,
    screenStatusMessage,
    subscribed,
  } = state.screenWinner
  return {
    screenWinnerSubscription: subscribed,
    projectDetails,
    conceptsList,
    screenStatusMessage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectDetails: (projectId) =>
      dispatch(fetchProjectDetails(projectId)),
    fetchConcepts: (projectId) => dispatch(fetchConcepts(projectId)),
    updateProjectDetails: (projectDetails, projectId) =>
      dispatch(updateProjectDetails(projectDetails, projectId)),
    updateConceptDetails: (conceptDetails, conceptId, projectId) =>
      dispatch(updateConceptDetails(conceptDetails, conceptId, projectId)),
    saveConceptDetails: (conceptDetails, projectId) =>
      dispatch(saveConceptDetails(conceptDetails, projectId)),
    startScreeningProject: (projectId) =>
      dispatch(startScreeningProject(projectId)),
    checkScreeningStatus: (projectId) =>
      dispatch(checkScreeningStatus(projectId)),
    deleteConcept: (conceptId, projectId) =>
      dispatch(deleteConcept(conceptId, projectId)),
    cloneConcept: (conceptId, projectId) =>
      dispatch(cloneConcept(conceptId, projectId)),
    notify: (notification) => dispatch(notify(notification)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectScreen),
)
