import React from 'react'
import styled from '@emotion/styled'

import { Popper, Box } from '@mui/material'

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1352,
  maxWidth: '375px',
  width: '100%',
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    left: 0,
    marginTop: '0em',
    width: '8em',
    height: '4em',
    '&::before': {
      borderWidth: '0 4em 4em 4em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '0em',
    width: '8em',
    height: '4em',
    '&::before': {
      borderWidth: '4em 4em 0 4em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    left: 0,
    marginLeft: '0em',
    height: '8em',
    width: '4em',
    '&::before': {
      borderWidth: '4em 4em 4em 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: 0,
    marginRight: '0em',
    height: '8em',
    width: '4em',
    '&::before': {
      borderWidth: '4em 0 4em 4em',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}))

export const StyledArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
}))
