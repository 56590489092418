import { isEmpty } from 'lodash'
import {
  ProductDetailsViewActions,
  IngredientQuotesActionTypes,
  IngredientTradeMediaQuotesActionTypes,
} from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import * as AC from './actionConstants'
import ProductDetailResponse from '../../services/networking/Models/ProductDetailResponse'
import ProductQuoteResponse from '../../services/networking/Models/ProductQuoteResponse'
import { error } from 'ajv/dist/vocabularies/applicator/dependencies'
import { dispatch } from 'd3'

export const addCafeProducts = (cafeProducts) => ({
  type: AC.ADD_CAFE_PRODUCTS,
  cafeProductsList: cafeProducts,
})

export const addRetailProducts = (retailProducts) => ({
  type: AC.ADD_RETAIL_PRODUCTS,
  retailProductsList: retailProducts,
})

export const addCafeProductFormats = (cafeProductFormats) => ({
  type: AC.ADD_CAFE_PRODUCT_FORMATS,
  cafeProductFormats: cafeProductFormats,
})

export const addRetailProductFormats = (retailProductFormats) => ({
  type: AC.ADD_RETAIL_PRODUCT_FORMATS,
  retailProductFormats: retailProductFormats,
})

export const addPairingDetails = (pairingDetails) => ({
  type: AC.ADD_PAIRING_DETAILS,
  pairingDetails: pairingDetails,
})

export const addRelatedTrendsDetails = (relatedTrendsDetails) => ({
  type: AC.ADD_RELATED_TRENDS_DETAILS,
  relatedTrendsDetails: relatedTrendsDetails,
})

export const addRelatedTrendsV2 = (relatedTrends, requestDetails) => ({
  type: AC.ADD_RELATED_TRENDS_V2,
  relatedTrendsV2: { data: relatedTrends, requestDetails },
})
export const addRelatedTrendsV2Loading = () => ({
  type: AC.ADD_RELATED_TRENDS_V2_LOADING,
})
export const addRelatedTrendsV2Failure = (error) => ({
  type: AC.ADD_RELATED_TRENDS_V2_FAILURE,
  error: error,
})

export const fetchProductDetails = (productid, lensSelected = 'Ingredient') => {
  return (dispatch) => {
    dispatch(fetchProductDetailsStart())

    let request
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.formSearchRequestForProductFilterDetail(productid) // Product
        break
      case 'Theme':
        request = NetworkUtil.formSearchRequestForThemeFilterDetail(productid) // Theme
        break
      case 'Ingredient':
        request = NetworkUtil.formSearchRequestForProduct(productid) // Ingredients
        break
      default:
        dispatch(fetchProductDetailsfailed('Invalid Actions Detected')) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        const responseObject = new ProductDetailResponse(response.data)
        dispatch(fetchProductDetailsSuccess(responseObject))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fetchProductDetailsfailed(error))
      })
  }
}

export const fetchProductDetailsForCommonTrend = (
  productid,
  lensSelected = 'Ingredient',
  fetchCN,
  cancelToken,
) => {
  return (dispatch) => {
    dispatch(fetchProductDetailsStart())
    let request
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.formSearchRequestForProductFilterDetailForCommonTrend(
          productid,
          fetchCN,
        ) // Product
        break
      case 'Theme':
        request = NetworkUtil.formSearchRequestForThemeFilterDetailForCommonTrend(
          productid,
          fetchCN,
        ) // Theme
        break
      case 'Ingredient':
        request = NetworkUtil.formSearchRequestForProductForCommonTrend(
          productid,
          fetchCN,
        ) // Ingredients
        break
      default:
        dispatch(fetchProductDetailsfailed('Invalid Actions Detected')) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request, cancelToken)
      .then((response) => {
        const responseObject = new ProductDetailResponse(response.data)
        dispatch(fetchProductDetailsSuccess(responseObject))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fetchProductDetailsfailed(error))
      })
  }
}

const fetchProductDetailsStart = () => {
  // Ingredients
  return {
    type:
      ProductDetailsViewActions.type[
        ProductDetailsViewActions.FETCH_PRODUCT_START
      ],
  }
}

export const fetchProductDetailsSuccess = (productDetails) => {
  // Ingredients
  return {
    type:
      ProductDetailsViewActions.type[
        ProductDetailsViewActions.FETCH_PRODUCT_SUCCESS
      ],
    productDetails: productDetails,
  }
}

const fetchProductDetailsfailed = (error) => {
  // Ingredients
  return {
    type:
      ProductDetailsViewActions.type[
        ProductDetailsViewActions.FETCH_PRODUCT_FAIL
      ],
    error: error,
  }
}
export const fetchBlogsAndWebsites = (country, category) => {
  return (dispatch) => {
    const request = NetworkUtil.fetchBlogsAndWebsites(country, category)
    axios
      .get(request)
      .then((response) => {
        dispatch(addBlogsAndWebsites(response.data))
      })
      .catch((error) => {
        dispatch(addBlogsAndWebsites([]))
      })
  }
}
export const fetchCafeProducts = (productid, lensSelected) => {
  return (dispatch) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.fetchCafeProductsForProductFilter(productid) // Product
        break
      case 'Theme':
        request = NetworkUtil.fetchCafeProductsForThemeFilter(productid) // Theme (Need to remove)
        break
      case 'Ingredient':
        request = NetworkUtil.fetchCafeProducts(productid) // Ingredients
        break
      default:
        dispatch(addCafeProducts([])) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addCafeProducts(response.data))
      })
      .catch((error) => {
        dispatch(addCafeProducts([]))
      })
  }
}

export const fetchCafeProductFormats = (productid, lensSelected) => {
  return (dispatch) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request
    switch (lensSelected) {
      case 'Ingredient':
        request = NetworkUtil.fetchCafeProductFormatsUrl(productid) // Ingredients
        break
      default:
        dispatch(addCafeProductFormats([])) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addCafeProductFormats(response.data))
      })
      .catch((error) => {
        dispatch(addCafeProductFormats([]))
      })
  }
}

export const fetchRetailProducts = (productid, lensSelected) => {
  return (dispatch) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.fetchRetailProductsForProductFilter(productid) // Product
        break
      case 'Theme':
        request = NetworkUtil.fetchRetailProductsForThemeFilter(productid) // Theme
        break
      case 'Ingredient':
        request = NetworkUtil.fetchRetailProducts(productid) // Ingredients
        break
      default:
        dispatch(addRetailProducts([])) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addRetailProducts(response.data))
      })
      .catch((error) => {
        dispatch(addRetailProducts([]))
      })
  }
}

export const fetchRetailProductFormats = (productid, lensSelected) => {
  return (dispatch) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request
    switch (lensSelected) {
      case 'Ingredient':
        request = NetworkUtil.fetchRetailProductFormatsUrl(productid) // Ingredients
        break
      default:
        dispatch(addRetailProductFormats([])) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addRetailProductFormats(response.data))
      })
      .catch((error) => {
        console.log('Error')
        throw error
        dispatch(addRetailProductFormats([]))
      })
  }
}

export const fetchIngredientQuotes = (productid, lensSelected) => {
  return async (dispatch, getState) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request1, request2, v, w, responseObject
    try {
      switch (lensSelected) {
        case 'Product':
          request1 = NetworkUtil.fetchIngredientQuotesForProductFilter(
            productid,
          ) // Product
          request2 = NetworkUtil.fetchProductTradeMediaQuotes(productid)
          v = await axios.get(request1)
          w = await axios.get(request2)
          responseObject = {}
          if (v) {
            responseObject = { ...v.data }
          }
          if (!isEmpty(w)) {
            w = w.data
            responseObject = {
              ...responseObject,
              food_dive_quotes: w.food_dive_quotes,
              food_navigator_quotes: w.food_navigator_quotes,
              ift_quotes: w.ift_quotes,
              eat_right_pro_quotes: w.eat_right_pro_quotes,
              american_society_quotes: w.american_society_quotes,
              nutraingredients_quotes: w.nutraingredients_quotes,
              uspto_quotes: w.uspto_quotes,
              japan_food_journal_quotes: w.japan_food_journal_quotes,
              food_beverage_asia_quotes: w.food_beverage_asia_quotes,
              japan_food_quotes: w.japan_food_quotes,
              independent_quotes: w.independent_quotes,
              hindawi_quotes: w.hindawi_quotes,
              korea_herald_quotes: w.korea_herald_quotes,
              food_science_and_nutrition_quotes:
                w.food_science_and_nutrition_quotes,
              fmcg_magazine_quotes: w.fmcg_magazine_quotes,
              fdbusiness_quotes: w.fdbusiness_quotes,
              food_in_canada_quotes: w.food_in_canada_quotes,
              food_drink_network_quotes: w.food_drink_network_quotes,
              food_drink_europe_quotes: w.food_drink_europe_quotes,
              food_and_beverage_news_canada_quotes:
                w.food_and_beverage_news_canada_quotes,
              european_journal_of_nutrition_and_food_safety_quotes:
                w.european_journal_of_nutrition_and_food_safety_quotes,
              confectionery_news_quotes: w.confectionery_news_quotes,
              cnn_quotes: w.cnn_quotes,
              cbc_quotes: w.cbc_quotes,
              candy_industry_quotes: w.candy_industry_quotes,
              canadian_food_business_quotes: w.canadian_food_business_quotes,
              british_journal_of_nutrition_quotes:
                w.british_journal_of_nutrition_quotes,
              bbc_good_food_quotes: w.bbc_good_food_quotes,
              bakery_and_snacks_quotes: w.bakery_and_snacks_quotes,
              asian_food_science_journal_quotes:
                w.asian_food_science_journal_quotes,
              academic_journal_quotes: w.academic_journal_quotes,
              abc_news_quotes: w.abc_news_quotes,
              longdom_quotes: w.longdom_quotes,
              mdpi_quotes: w.mdpi_quotes,
              my_food_research_quotes: w.my_food_research_quotes,
              nature_quotes: w.nature_quotes,
              pr_newswire_quotes: w.pr_newswire_quotes,
              science_alert_quotes: w.science_alert_quotes,
              science_direct_quotes: w.science_direct_quotes,
              snack_food_and_wholesale_bakery_quotes:
                w.snack_food_and_wholesale_bakery_quotes,
              taylor_and_francison_line_quotes:
                w.taylor_and_francison_line_quotes,
              unesda_quotes: w.unesda_quotes,
            }
          }
          if (!v && !w) {
            dispatch(
              fetchIngredientQuotesFailed(
                'Invalid Product Quotes Actions Detected',
              ),
            )
          }
          dispatch(
            fetchIngredientQuotesSuccess(
              new ProductQuoteResponse(responseObject),
            ),
          )
          break
        case 'Theme':
          request1 = NetworkUtil.fetchIngredientQuotesForThemeFilter(productid) // Theme
          request2 = NetworkUtil.fetchThemeTradeMediaQuotes(productid)
          v = await axios.get(request1)
          w = await axios.get(request2)
          responseObject = {}
          if (v) {
            responseObject = { ...v.data }
          }
          if (!isEmpty(w)) {
            w = w.data
            responseObject = {
              ...responseObject,
              food_dive_quotes: w.food_dive_quotes,
              food_navigator_quotes: w.food_navigator_quotes,
              ift_quotes: w.ift_quotes,
              eat_right_pro_quotes: w.eat_right_pro_quotes,
              american_society_quotes: w.american_society_quotes,
              nutraingredients_quotes: w.nutraingredients_quotes,
              uspto_quotes: w.uspto_quotes,
              japan_food_journal_quotes: w.japan_food_journal_quotes,
              food_beverage_asia_quotes: w.food_beverage_asia_quotes,
              japan_food_quotes: w.japan_food_quotes,
              independent_quotes: w.independent_quotes,
              hindawi_quotes: w.hindawi_quotes,
              korea_herald_quotes: w.korea_herald_quotes,
              food_science_and_nutrition_quotes:
                w.food_science_and_nutrition_quotes,
              fmcg_magazine_quotes: w.fmcg_magazine_quotes,
              fdbusiness_quotes: w.fdbusiness_quotes,
              food_in_canada_quotes: w.food_in_canada_quotes,
              food_drink_network_quotes: w.food_drink_network_quotes,
              food_drink_europe_quotes: w.food_drink_europe_quotes,
              food_and_beverage_news_canada_quotes:
                w.food_and_beverage_news_canada_quotes,
              european_journal_of_nutrition_and_food_safety_quotes:
                w.european_journal_of_nutrition_and_food_safety_quotes,
              confectionery_news_quotes: w.confectionery_news_quotes,
              cnn_quotes: w.cnn_quotes,
              cbc_quotes: w.cbc_quotes,
              candy_industry_quotes: w.candy_industry_quotes,
              canadian_food_business_quotes: w.canadian_food_business_quotes,
              british_journal_of_nutrition_quotes:
                w.british_journal_of_nutrition_quotes,
              bbc_good_food_quotes: w.bbc_good_food_quotes,
              bakery_and_snacks_quotes: w.bakery_and_snacks_quotes,
              asian_food_science_journal_quotes:
                w.asian_food_science_journal_quotes,
              academic_journal_quotes: w.academic_journal_quotes,
              abc_news_quotes: w.abc_news_quotes,
              longdom_quotes: w.longdom_quotes,
              mdpi_quotes: w.mdpi_quotes,
              my_food_research_quotes: w.my_food_research_quotes,
              nature_quotes: w.nature_quotes,
              pr_newswire_quotes: w.pr_newswire_quotes,
              science_alert_quotes: w.science_alert_quotes,
              science_direct_quotes: w.science_direct_quotes,
              snack_food_and_wholesale_bakery_quotes:
                w.snack_food_and_wholesale_bakery_quotes,
              taylor_and_francison_line_quotes:
                w.taylor_and_francison_line_quotes,
              unesda_quotes: w.unesda_quotes,
            }
          }
          if (!v && !w) {
            dispatch(
              fetchIngredientQuotesFailed(
                'Invalid Theme Quotes Actions Detected',
              ),
            )
          }
          dispatch(
            fetchIngredientQuotesSuccess(
              new ProductQuoteResponse(responseObject),
            ),
          )
          break
        case 'Ingredient':
          request1 = NetworkUtil.fetchIngredientQuotes(productid)
          request2 = NetworkUtil.fetchIngredientTradeMediaQuotes(productid)
          v = await axios.get(request1)
          w = await axios.get(request2)
          responseObject = {}
          if (v) {
            responseObject = { ...v.data }
          }
          if (!isEmpty(w)) {
            w = w.data
            responseObject = {
              ...responseObject,
              food_dive_quotes: w.food_dive_quotes,
              food_navigator_quotes: w.food_navigator_quotes,
              ift_quotes: w.ift_quotes,
              eat_right_pro_quotes: w.eat_right_pro_quotes,
              american_society_quotes: w.american_society_quotes,
              nutraingredients_quotes: w.nutraingredients_quotes,
              uspto_quotes: w.uspto_quotes,
              japan_food_journal_quotes: w.japan_food_journal_quotes,
              food_beverage_asia_quotes: w.food_beverage_asia_quotes,
              japan_food_quotes: w.japan_food_quotes,
              independent_quotes: w.independent_quotes,
              hindawi_quotes: w.hindawi_quotes,
              korea_herald_quotes: w.korea_herald_quotes,
              food_science_and_nutrition_quotes:
                w.food_science_and_nutrition_quotes,
              fmcg_magazine_quotes: w.fmcg_magazine_quotes,
              fdbusiness_quotes: w.fdbusiness_quotes,
              food_in_canada_quotes: w.food_in_canada_quotes,
              food_drink_network_quotes: w.food_drink_network_quotes,
              food_drink_europe_quotes: w.food_drink_europe_quotes,
              food_and_beverage_news_canada_quotes:
                w.food_and_beverage_news_canada_quotes,
              european_journal_of_nutrition_and_food_safety_quotes:
                w.european_journal_of_nutrition_and_food_safety_quotes,
              confectionery_news_quotes: w.confectionery_news_quotes,
              cnn_quotes: w.cnn_quotes,
              cbc_quotes: w.cbc_quotes,
              candy_industry_quotes: w.candy_industry_quotes,
              canadian_food_business_quotes: w.canadian_food_business_quotes,
              british_journal_of_nutrition_quotes:
                w.british_journal_of_nutrition_quotes,
              bbc_good_food_quotes: w.bbc_good_food_quotes,
              bakery_and_snacks_quotes: w.bakery_and_snacks_quotes,
              asian_food_science_journal_quotes:
                w.asian_food_science_journal_quotes,
              academic_journal_quotes: w.academic_journal_quotes,
              abc_news_quotes: w.abc_news_quotes,
              longdom_quotes: w.longdom_quotes,
              mdpi_quotes: w.mdpi_quotes,
              my_food_research_quotes: w.my_food_research_quotes,
              nature_quotes: w.nature_quotes,
              pr_newswire_quotes: w.pr_newswire_quotes,
              science_alert_quotes: w.science_alert_quotes,
              science_direct_quotes: w.science_direct_quotes,
              snack_food_and_wholesale_bakery_quotes:
                w.snack_food_and_wholesale_bakery_quotes,
              taylor_and_francison_line_quotes:
                w.taylor_and_francison_line_quotes,
              unesda_quotes: w.unesda_quotes,
            }
          }
          if (!v && !w) {
            dispatch(
              fetchIngredientQuotesFailed(
                'Invalid Ingredient Quotes Actions Detected',
              ),
            )
          }
          dispatch(
            fetchIngredientQuotesSuccess(
              new ProductQuoteResponse(responseObject),
            ),
          )
          break
        default:
          dispatch(fetchIngredientQuotesFailed('Invalid Actions Detected')) // In case if someone modifies value localStorage in to useless one
          return
      }
    } catch (error) {
      dispatch(fetchIngredientQuotesFailed(error))
    }

    // axios.get(request)
    //   .then(response => {
    //     const responseObject = new ProductQuoteResponse(response.data)
    //     dispatch(fetchIngredientQuotesSuccess(responseObject))
    //   })
    //   .catch(error => {
    //     dispatch(fetchIngredientQuotesFailed(error))
    //   })
  }
}

export const fetchIngredientTradeMediaQuotes = (productid, lensSelected) => {
  return (dispatch, getState) => {
    // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
    let request
    switch (lensSelected) {
      case 'Product':
        request = NetworkUtil.fetchIngredientTradeMediaQuotes(productid) // Product
        break
      case 'Theme':
        request = NetworkUtil.fetchIngredientTradeMediaQuotes(productid) // Theme
        break
      case 'Ingredient':
        request = NetworkUtil.fetchIngredientTradeMediaQuotes(productid) // Ingredients
        break
      default:
        dispatch(
          fetchIngredientTradeMeidaQuotesFailed('Invalid Actions Detected'),
        ) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        const responseObject = new ProductQuoteResponse(response.data)
        dispatch(fetchIngredientTradeMeidaQuotesSuccess(responseObject))
      })
      .catch((error) => {
        dispatch(fetchIngredientTradeMeidaQuotesFailed(error))
      })
  }
}

export const fetchPairingDetails = (productid, lensSelected) => {
  return (dispatch) => {
    let request
    switch (lensSelected) {
      case 'Ingredient':
        request = NetworkUtil.fetchPairingDetails(productid) // Ingredients
        break
      default:
        dispatch(addPairingDetails(null)) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addPairingDetails(response.data))
      })
      .catch((error) => {
        dispatch(addPairingDetails({}))
      })
  }
}

export const fetchRelatedTrends = (productid, lensSelected) => {
  return (dispatch) => {
    let request
    switch (lensSelected) {
      case 'Ingredient':
        request = NetworkUtil.fetchRelatedTrendsForIngredients(productid) // Ingredients
        break
      case 'Product':
        request = NetworkUtil.fetchRelatedTrendsForProducts(productid) // Products
        break
      case 'Theme':
        request = NetworkUtil.fetchRelatedTrendsForThemes(productid) // Ingredients
        break
      default:
        dispatch(addRelatedTrendsDetails(null)) // In case if someone modifies value localStorage in to useless one
        return
    }

    axios
      .get(request)
      .then((response) => {
        dispatch(addRelatedTrendsDetails(response.data.data))
      })
      .catch((error) => {
        dispatch(addRelatedTrendsDetails({}))
      })
  }
}

export const getRelatedTrendsV2 = (
  currentRelatedTrendsState,
  lens,
  trendId,
  subCategories,
  include = ['ingredient', 'product', 'theme'],
) => {
  return (dispatch) => {
    if (
      isEmpty(currentRelatedTrendsState) ||
      currentRelatedTrendsState.trendId !== trendId ||
      currentRelatedTrendsState.lens !== lens
    ) {
      dispatch(
        fetchRelatedTrendsV2(lens, trendId, 'create', subCategories, include),
      )
    } else {
      const updatedInclude = []
      for (const key of include) {
        if (
          isEmpty(currentRelatedTrendsState[key]) ||
          !Object.keys(currentRelatedTrendsState[key]).includes(
            subCategories[key],
          ) ||
          isEmpty(currentRelatedTrendsState[key][subCategories[key]])
        ) {
          updatedInclude.push(key)
        }
      }
      if (updatedInclude.length) {
        dispatch(
          fetchRelatedTrendsV2(
            lens,
            trendId,
            'update',
            subCategories,
            updatedInclude,
          ),
        )
      }
    }
  }
}

// getRelatedTrendsV2 to be used to check for cache and call below API
export const fetchRelatedTrendsV2 = (
  lens,
  trendId,
  cacheType = 'create',
  subCategories,
  include = ['ingredient', 'product', 'theme'],
) => {
  return (dispatch) => {
    dispatch(addRelatedTrendsV2Loading())
    const url = NetworkUtil.fetchRelatedTrends(
      lens,
      trendId,
      subCategories,
      include,
    )
    const requestDetails = {
      lens,
      trendId,
      cacheType,
      subCategories: {
        ingredient: subCategories.ingredient || 'All',
        product: subCategories.product || 'All',
        theme: subCategories.theme || 'All',
      },
      include,
    }
    axios
      .get(url)
      .then((response) => {
        dispatch(addRelatedTrendsV2(response.data?.data, requestDetails))
      })
      .catch((error) => {
        console.log(error)
        dispatch(addRelatedTrendsV2Failure(error))
      })
  }
}

const fetchIngredientQuotesSuccess = (ingredientQuotes) => {
  return {
    type:
      IngredientQuotesActionTypes.type[
        IngredientQuotesActionTypes.FETCH_INGREDIENT_QUOTES_SUCCESS
      ],
    ingredientQuotes: ingredientQuotes,
  }
}

const fetchIngredientQuotesFailed = (error) => {
  return {
    type:
      IngredientQuotesActionTypes.type[
        IngredientQuotesActionTypes.FETCH_INGREDIENT_QUOTES_FAILED
      ],
    error: error,
  }
}

const fetchIngredientTradeMeidaQuotesSuccess = (ingredientTradeMediaQuotes) => {
  return {
    type:
      IngredientTradeMediaQuotesActionTypes.type[
        IngredientTradeMediaQuotesActionTypes
          .FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_SUCCESS
      ],
    ingredientTradeMediaQuotes: ingredientTradeMediaQuotes,
  }
}

const fetchIngredientTradeMeidaQuotesFailed = (error) => {
  return {
    type:
      IngredientTradeMediaQuotesActionTypes.type[
        IngredientTradeMediaQuotesActionTypes
          .FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_FAILED
      ],
    error: error,
  }
}
