import React, { useState } from 'react'
import { formatNumber } from '../../store/utility'
import {
  Box,
  Typography,
  CardMedia,
  CardContent,
  Button,
  Tooltip,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

const AnalysedTrendCard = ({
  styleSx,
  cardData,
  firstLabel,
  secondLabel,
  handleCancel,
  buttonDisabled,
  handleViewDetail,
  setHoveredCard,
  hoveredCard,
  hoverCardRef,
  clickedCard,
}) => {
  const {
    imgSx,
    typographySx,
    cardContentSx,
    nameSx,
    labelSx,
    valSx,
    cardsx,
    firstValSx,
    imgSxParent,
  } = styleSx
  const { name, firstValue, secondValue, imageURL } = cardData
  const [isCardHovered, setIsCardHovered] = useState(false)

  const handleCardHovered = (isHoverActive) => {
    setIsCardHovered(isHoverActive)

    if (isHoverActive) {
      setHoveredCard(cardData)
    } else {
      setHoveredCard(null)
    }
  }

  const showCancelButton =
    !!isCardHovered ||
    clickedCard?.name?.toLowerCase() === cardData.name?.toLowerCase() ||
    hoveredCard?.name?.toLowerCase() === cardData.name?.toLowerCase()

  return (
    <>
      <Box
        sx={{
          ...cardsx,
          ...(clickedCard?.name?.toLowerCase() ===
            cardData.name?.toLowerCase() && {
            border: ({ palette }) => `1px solid ${palette.primary.main}`,
          }),
          ...(hoveredCard?.name?.toLowerCase() ===
            cardData.name?.toLowerCase() && {
            border: ({ palette }) => `1px solid ${palette.primary.main}`,
          }),
        }}
        onMouseEnter={() => handleCardHovered(true)}
        onMouseLeave={() => handleCardHovered(false)}
        ref={
          clickedCard?.name?.toLowerCase() === cardData.name?.toLowerCase()
            ? hoverCardRef
            : null
        }
        id={cardData.name}
      >
        {showCancelButton && (
          <CancelIcon
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              transform: 'scale(0.6)',
              color: ({ palette }) => palette.primary.main,
            }}
            onClick={() => handleCancel(cardData)}
          />
        )}
        <Box sx={imgSxParent}>
          <CardMedia
            component="img"
            height="140"
            width="110"
            sx={imgSx}
            image={imageURL}
            alt="Analysed Trend Image not available"
          />
        </Box>

        <CardContent sx={cardContentSx}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography gutterBottom variant="subtitle3" sx={nameSx}>
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mt: 1,
            }}
          >
            {firstLabel && firstValue !== null && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={labelSx} variant="caption">
                    {firstLabel}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 600,
                      color: firstValue >= 0 ? '#00BA13' : '#FF0000',
                    }}
                    variant="caption"
                  >
                    {firstValue.toFixed(2)}%
                  </Typography>
                </Box>
              </Box>
            )}
            {secondLabel && secondValue !== null && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={labelSx} variant="caption">
                    {secondLabel}
                  </Typography>
                  <Typography sx={valSx} variant="caption">
                    {formatNumber(secondValue)}
                  </Typography>
                </Box>
              </Box>
            )}
            <Tooltip
              title={
                buttonDisabled && 'You are not subscribed to this dashboard.'
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    width: '100%',
                    mt: secondValue !== null ? 1 : 0,
                    mb: -1,
                    borderRadius: '5px',
                    fontSize: '12px',
                    px: 0,
                  }}
                  size="small"
                  onClick={() => handleViewDetail(cardData)}
                  disabled={buttonDisabled}
                >
                  View Details
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </CardContent>
      </Box>
    </>
  )
}

export default AnalysedTrendCard
