import styled from '@emotion/styled'
import { Table, Grid, Link } from '@mui/material'

export const StyledTable = styled(Table)(({ theme }) => ({
  marginLeft:"50px",
  display: 'block !important',
  overflowX: 'auto',
  paddingRight: '20px',
  width: 'fit-content',
  maxWidth: '100%',
  borderLeft: `solid 1px ${theme.palette.divider}`,
  borderRight: `solid 1px ${theme.palette.divider}`,
  '&::-webkit-scrollbar': {
    height: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
  '& > tbody tr td': {
    borderRight: `1px solid ${theme.palette.divider} !important`,
    textAlign: 'center',
    paddingTop: '9px',
    paddingBottom: '9px',
    width: '140px',
    maxWidth: '140px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& > tbody tr td:first-child': {
    width: '200px',
    minWidth: '200px',
    textAlign: 'left',
    position: 'sticky',
    left: 0,
    background: theme.palette.white,
    padding: 0,
    border: 'none !important',
  },
  '& > tbody tr td:nth-child(2)': {
    borderLeft: 'none !important',
  },
  '& > tbody tr:last-child td': {
    borderBottom: 'none !important',
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
  },
  '& > tbody tr:first-child td': {
    fontWeight: 'bold',
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  '& > tbody tr:first-child td:first-child': {
    fontWeight: 'normal',
    "&:hover": {
      color: theme.palette.black,
    }
  },
}))

export const StyledGrid = styled(Grid)(({ theme }) => ({
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  border: '1px solid transparent',
  cursor: 'pointer',
}))

export const StyledLinkCountry = styled(Link)(({ theme }) => ({
  color: theme.palette.black,
  textDecorationLine: 'none',
  fontWeight: "bold",
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.black,
  textDecorationLine: 'none',
  '&:hover': {
    color: `${theme.palette.primary.main} !important`,
  },
}))