export const ResetActions = {
  RESET_APP: 1,
  RESET_PROJECT_DETAILS: 2,
  RESET_PRODUCT_DETAILS: 3,
  RESET_PRODUCT_NEEDS: 4,
  RESET_NEED_DETAILS: 5,
  RESE_PRODUCT_TOPICS: 6,
  RESET_WSO_DATA: 7,
  type: {
    1: 'RESET_APP',
    2: 'RESET_PROJECT_DETAILS',
    3: 'RESET_PRODUCT_DETAILS',
    4: 'RESET_PRODUCT_NEEDS',
    5: 'RESET_NEED_DETAILS',
    6: 'RESE_PRODUCT_TOPICS',
    7: 'RESET_WSO_DATA',
  },
}

export const SavedTrendsActionTypes = {
  FETCH_SAVED_TRENDS_START: 1,
  FETCH_SAVED_TRENDS_SUCCESS: 2,
  FETCH_SAVED_TRENDS_FAIL: 3,
  type: {
    1: 'FETCH_SAVED_TRENDS_START',
    2: 'FETCH_SAVED_TRENDS_SUCCESS',
    3: 'FETCH_SAVED_TRENDS_FAIL',
  },
}

Object.freeze(SavedTrendsActionTypes)

export const NewTrendsRequestActionTypes = {
  FETCH_NEW_TRENDS_OPTIONS_START: 1,
  FETCH_NEW_TRENDS_OPTIONS_SET: 2,
  FETCH_NEW_TRENDS_OPTIONS_FAILED: 3,
  REQUEST_NEW_TRENDS_START: 4,
  REQUEST_NEW_TRENDS_SUCCESS: 5,
  REQUEST_NEW_TRENDS_FAILED: 6,
  INPUT_CHANGED: 7,
  type: {
    1: 'FETCH_NEW_TRENDS_OPTIONS_START',
    2: 'FETCH_NEW_TRENDS_OPTIONS_SET',
    3: 'FETCH_NEW_TRENDS_OPTIONS_FAILED',
    4: 'REQUEST_NEW_TRENDS_START',
    5: 'REQUEST_NEW_TRENDS_SUCCESS',
    6: 'REQUEST_NEW_TRENDS_FAILED',
    7: 'INPUT_CHANGED',
  },
}

Object.freeze(NewTrendsRequestActionTypes)

export const UserSelectionActionTypes = {
  USER_SELECTED: 'USER_SELECTED',
  USER_SELECTED_PRODUCT_NEED_ID: 'USER_SELECTED_PRODUCT_NEED_ID',
  USER_SELECTED_TOPIC_ID: 'USER_SELECTED_TOPIC_ID',
  USER_SELECTED_PROJECT: 1,
  USER_SELECTED_PRODUCT: 2,
  USER_SELECTED_PROJECT_NEEDS: 3,
  USER_SELECTED_BUBBLE_NEEDS: 4,
  USER_WAIT_ANIMATION_DISPLAYED: 5,
  type: {
    1: 'USER_SELECTED_PROJECT',
    2: 'USER_SELECTED_PRODUCT',
    3: 'USER_SELECTED_PROJECT_NEEDS',
    4: 'USER_SELECTED_BUBBLE_NEEDS',
    5: 'USER_WAIT_ANIMATION_DISPLAYED',
  },
}

Object.freeze(UserSelectionActionTypes)

export const SubscriptionDetailsAction = {
  FETCH_SUBSCRIPTION_DETAILS_SUCCESS: 1,
  FETCH_SUBSCRIPTION_DETAILS_FAIL: 2,
  type: {
    1: 'FETCH_SUBSCRIPTION_DETAILS_SUCCESS',
    2: 'FETCH_SUBSCRIPTION_DETAILS_FAIL',
  },
}

Object.freeze(SubscriptionDetailsAction)

export const ProjectListViewActions = {
  FETCH_PROJECT_LIST_START: 1,
  FETCH_PROJECT_LIST_SUCCESS: 2,
  FETCH_PROJECT_LIST_FAIL: 3,
  FETCH_PROJECT_LIST_NEEDS_SUCCESS: 4,
  FETCH_PROJECT_LIST_NEEDS_FAIL: 5,
  RECORD_REQUEST_START_TIME: 6,
  USER_DELAY_COMPLETE: 7,
  FETCH_PRODUCT_LIST_SUCCESS: 8,
  FETCH_PRODUCT_LIST_FAIL: 9,
  FETCH_THEME_LIST_SUCCESS: 10,
  FETCH_THEME_LIST_FAIL: 11,
  FETCH_SUBSCRIPTION_DETAILS_SUCCESS: 12,
  FETCH_SUBSCRIPTION_DETAILS_FAIL: 13,
  FETCH_WHITE_SPACE_OPPORTUNITY_SUCCESS: 14,
  FETCH_WHITE_SPACE_OPPORTUNITY_FAILED: 15,
  FETCH_FEATURES_SUCCESS: 16,
  FETCH_FEATURES_FAILED: 17,
  SELECT_ASSOC_LEVEL: 18,

  FETCH_CTX_SRC_MAPPING_SUCCESS: 19,
  FETCH_CTX_SRC_MAPPING_FAILED: 20,
  RESET_PROJECTLISTBUILDER_EXCEPT_SUBSCRIPTION: 21,
  type: {
    1: 'FETCH_PROJECT_LIST_START',
    2: 'FETCH_PROJECT_LIST_SUCCESS',
    3: 'FETCH_PROJECT_LIST_FAIL',
    4: 'FETCH_PROJECT_LIST_NEEDS_SUCCESS',
    5: 'FETCH_PROJECT_LIST_NEEDS_FAIL',
    6: 'RECORD_REQUEST_START_TIME',
    7: 'USER_DELAY_COMPLETE',

    8: 'FETCH_PRODUCT_LIST_SUCCESS',
    9: 'FETCH_PRODUCT_LIST_FAIL',
    10: 'FETCH_THEME_LIST_SUCCESS',
    11: 'FETCH_THEME_LIST_FAIL',
    12: 'FETCH_SUBSCRIPTION_DETAILS_SUCCESS',
    13: 'FETCH_SUBSCRIPTION_DETAILS_FAIL',
    14: 'FETCH_WHITE_SPACE_OPPORTUNITY_SUCCESS',
    15: 'FETCH_WHITE_SPACE_OPPORTUNITY_FAILED',

    16: 'FETCH_FEATURES_SUCCESS',
    17: 'FETCH_FEATURES_FAILED',
    18: 'SELECT_ASSOC_LEVEL',

    19: 'FETCH_CTX_SRC_MAPPING_SUCCESS',
    20: 'FETCH_CTX_SRC_MAPPING_FAILED',
    21: 'RESET_PROJECTLISTBUILDER_EXCEPT_SUBSCRIPTION',
  },
}

Object.freeze(ProjectListViewActions)

export const ProductDetailsViewActions = {
  FETCH_PRODUCT_START: 1,
  FETCH_PRODUCT_SUCCESS: 2,
  FETCH_PRODUCT_FAIL: 3,
  type: {
    1: 'FETCH_PRODUCT_START',
    2: 'FETCH_PRODUCT_SUCCESS',
    3: 'FETCH_PRODUCT_FAIL',
  },
}

Object.freeze(ProductDetailsViewActions)

export const NeedDetailsViewActions = {
  FETCH_NEEDS_START: 0,
  FETCH_NEEDS_SUCCESS: 1,
  FETCH_NEEDS_FAIL: 2,
  type: {
    0: 'FETCH_NEEDS_START',
    1: 'FETCH_NEEDS_SUCCESS',
    2: 'FETCH_NEEDS_FAIL',
  },
}

Object.freeze(NeedDetailsViewActions)

export const NeedQuotesViewActions = {
  FETCH_NEEDS_QUOTES_SUCCESS: 1,
  FETCH_NEEDS_QUOTES_FAIL: 2,
  type: {
    1: 'FETCH_NEEDS_QUOTES_SUCCESS',
    2: 'FETCH_NEEDS_QUOTES_FAIL',
  },
}

Object.freeze(NeedQuotesViewActions)

export const CountriesActionTypes = {
  FETCH_COUNTRIES_START: 1,
  FETCH_COUNTRIES_SUCCESS: 2,
  FETCH_COUNTRIES_FAIL: 3,
  type: {
    1: 'FETCH_COUNTRIES_START',
    2: 'FETCH_COUNTRIES_SUCCESS',
    3: 'FETCH_COUNTRIES_FAIL',
  },
}

Object.freeze(CountriesActionTypes)

export const SearchCategoriesActionTypes = {
  FETCH_PROJECT_SEARCH_CATEGORY_START: 1,
  FETCH_PROJECT_SEARCH_CATEGORY_SUCCESS: 2,
  FETCH_PROJECT_SEARCH_CATEGORY_FAILED: 3,
  type: {
    1: 'FETCH_PROJECT_SEARCH_CATEGORY_START',
    2: 'FETCH_PROJECT_SEARCH_CATEGORY_SUCCESS',
    3: 'FETCH_PROJECT_SEARCH_CATEGORY_FAILED',
  },
}

Object.freeze(SearchCategoriesActionTypes)

export const LookForActionTypes = {
  FETCH_LOOK_FOR_START: 1,
  FETCH_LOOK_FOR_SUCCESS: 2,
  FETCH_LOOK_FOR_FAILED: 3,
  type: {
    1: 'FETCH_LOOK_FOR_START',
    2: 'FETCH_LOOK_FOR_SUCCESS',
    3: 'FETCH_LOOK_FOR_FAILED',
  },
}

Object.freeze(LookForActionTypes)

export const IngredientQuotesActionTypes = {
  FETCH_INGREDIENT_QUOTES_SUCCESS: 1,
  FETCH_INGREDIENT_QUOTES_FAILED: 2,
  type: {
    1: 'FETCH_INGREDIENT_QUOTES_SUCCESS',
    2: 'FETCH_INGREDIENT_QUOTES_FAILED',
  },
}

export const IngredientTradeMediaQuotesActionTypes = {
  FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_SUCCESS: 1,
  FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_FAILED: 2,
  type: {
    1: 'FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_SUCCESS',
    2: 'FETCH_INGREDIENT_TRADE_MEDIA_QUOTES_FAILED',
  },
}

Object.freeze(IngredientQuotesActionTypes)

export const GetUserProfileActionTypes = {
  FETCH_USER_PROFILE_START: 1,
  FETCH_USER_PROFILE_SUCCESS: 2,
  FETCH_USER_PROFILE_FAILED: 3,
  SET_LOADING_PROFILE: 4,
  SET_REDIRECTION_URL: 5,
  type: {
    1: 'FETCH_USER_PROFILE_START',
    2: 'FETCH_USER_PROFILE_SUCCESS',
    3: 'FETCH_USER_PROFILE_FAILED',
    4: 'SET_LOADING_PROFILE',
    5: 'SET_REDIRECTION_URL',
  },
}

Object.freeze(GetUserProfileActionTypes)

export const SubNeedDetailActionTypes = {
  FETCH_SUB_NEEDS_START: 1,
  FETCH_SUB_NEEDS_SUCCESS: 2,
  FETCH_SUB_NEEDS_FAIL: 3,
  type: {
    1: 'FETCH_SUB_NEEDS_START',
    2: 'FETCH_SUB_NEEDS_SUCCESS',
    3: 'FETCH_SUB_NEEDS_FAIL',
  },
}

Object.freeze(SubNeedDetailActionTypes)

export const SubNeedQuoteActionTypes = {
  FETCH_SUB_NEED_QUOTES_SUCCESS: 1,
  FETCH_SUB_NEED_QUOTES_FAIL: 2,
  type: {
    1: 'FETCH_SUB_NEED_QUOTES_SUCCESS',
    2: 'FETCH_SUB_NEED_QUOTES_FAIL',
  },
}

Object.freeze(SubNeedQuoteActionTypes)

export const ProductNeedDetailActionTypes = {
  FETCH_PRODUCT_NEED_DETAIL_START: 1,
  FETCH_PRODUCT_NEED_DETAIL_SUCCESS: 2,
  FETCH_PRODUCT_NEED_DETAIL_FAILED: 3,
  type: {
    1: 'FETCH_PRODUCT_NEED_DETAIL_START',
    2: 'FETCH_PRODUCT_NEED_DETAIL_SUCCESS',
    3: 'FETCH_PRODUCT_NEED_DETAIL_FAILED',
  },
}

Object.freeze(ProductNeedDetailActionTypes)

export const ProductNeedQuotesActionTypes = {
  FETCH_PRODUCT_NEED_QUOTES_SUCCESS: 1,
  FETCH_PRODUCT_NEED_QUOTES_FAILED: 2,
  type: {
    1: 'FETCH_PRODUCT_NEED_QUOTES_SUCCESS',
    2: 'FETCH_PRODUCT_NEED_QUOTES_FAILED',
  },
}

Object.freeze(ProductNeedQuotesActionTypes)

export const SearchProjectActionTypes = {
  FETCH_PROJECT_SEARCH_START: 1,
  FETCH_PROJECT_SEARCH_SUCCESS: 2,
  FETCH_PROJECT_SEARCH_FAILED: 3,
  RESET_PROJECT_SEARCH_DATA: 4,
  type: {
    1: 'FETCH_PROJECT_SEARCH_START',
    2: 'FETCH_PROJECT_SEARCH_SUCCESS',
    3: 'FETCH_PROJECT_SEARCH_FAILED',
    4: 'RESET_PROJECT_SEARCH_DATA',
  },
}

Object.freeze(SearchProjectActionTypes)

export const SearchQuotesActionTypes = {
  FETCH_SEARCH_QUOTES_START: 1,
  FETCH_SEARCH_QUOTES_SUCCESS: 2,
  FETCH_SEARCH_QUOTES_FAILED: 3,
  type: {
    1: 'FETCH_SEARCH_QUOTES_START',
    2: 'FETCH_SEARCH_QUOTES_SUCCESS',
    3: 'FETCH_SEARCH_QUOTES_FAILED',
  },
}

Object.freeze(SearchQuotesActionTypes)

export const ProductTopicDetailActionTypes = {
  FETCH_PRODUCT_TOPIC_DETAIL_START: 1,
  FETCH_PRODUCT_TOPIC_DETAIL_SUCCESS: 2,
  FETCH_PRODUCT_TOPIC_DETAIL_FAILED: 3,
  type: {
    1: 'FETCH_PRODUCT_TOPIC_DETAIL_START',
    2: 'FETCH_PRODUCT_TOPIC_DETAIL_SUCCESS',
    3: 'FETCH_PRODUCT_TOPIC_DETAIL_FAILED',
  },
}

Object.freeze(ProductTopicDetailActionTypes)

export const ProductTopicQuotesActionTypes = {
  FETCH_PRODUCT_TOPIC_QUOTE_SUCCESS: 1,
  FETCH_PRODUCT_TOPIC_QUOTE_FAILED: 2,
  type: {
    1: 'FETCH_PRODUCT_TOPIC_QUOTE_SUCCESS',
    2: 'FETCH_PRODUCT_TOPIC_QUOTE_FAILED',
  },
}

Object.freeze(ProductTopicQuotesActionTypes)

export const NeedCategoriesViewActions = {
  FETCH_NEED_CATEGORIES_SUCCESS: 1,
  FETCH_NEED_CATEGORIES_FAILED: 2,
  type: {
    1: 'FETCH_NEED_CATEGORIES_SUCCESS',
    2: 'FETCH_NEED_CATEGORIES_FAILED',
  },
}

Object.freeze(NeedCategoriesViewActions)

export const LoadSearchKeywordsActionTypes = {
  FETCH_SEARCH_KEYWORDS_START: 1,
  FETCH_SEARCH_KEYWORDS_SUCCESS: 2,
  FETCH_SEARCH_KEYWORDS_FAILED: 3,
  type: {
    1: 'FETCH_SEARCH_KEYWORDS_START',
    2: 'FETCH_SEARCH_KEYWORDS_SUCCESS',
    3: 'FETCH_SEARCH_KEYWORDS_FAILED',
  },
}

Object.freeze(LoadSearchKeywordsActionTypes)

export const AdvancedSearch = {
  FETCH_ALL_TRENDS_SUCCESS: 1,
  FETCH_ALL_TRENDS_FAILED: 2,
  FETCH_FILTERED_TRENDS_SUCCESS: 3,
  FETCH_FILTERED_TRENDS_FAILED: 4,
  SAVE_ADVANCED_SEARCH_SUCCESS: 5,
  SAVE_ADVANCED_SEARCH_FAILED: 6,
  RESET_FILTERED_TRENDS: 7,
  VIEW_ADVANCED_SEARCH_SUCCESS: 8,
  VIEW_ADVANCED_SEARCH_FAILED: 9,
  type: {
    1: 'FETCH_ALL_TRENDS_SUCCESS',
    2: 'FETCH_ALL_TRENDS_FAILED',
    3: 'FETCH_FILTERED_TRENDS_SUCCESS',
    4: 'FETCH_FILTERED_TRENDS_FAILED',
    5: 'SAVE_ADVANCED_SEARCH_SUCCESS',
    6: 'SAVE_ADVANCED_SEARCH_FAILED',
    7: 'RESET_FILTERED_TRENDS',
    8: 'VIEW_ADVANCED_SEARCH_SUCCESS',
    9: 'VIEW_ADVANCED_SEARCH_FAILED',
  },
}

Object.freeze(AdvancedSearch)

export const WhiteSpaceActionTypes = {
  WHITE_SPACE_SUCCESS: 1,
  WHITE_SPACE_FAILED: 2,
  type: {
    1: 'WHITE_SPACE_SUCCESS',
    2: 'WHITE_SPACE_FAILED',
  },
}
Object.freeze(WhiteSpaceActionTypes)

export const ScreenWinner = {
  FETCH_COUNTRIES_SUCCESS: 1,
  FETCH_COUNTRIES_FAILED: 2,
  FETCH_CATEGORIES_SUCCESS: 3,
  FETCH_CATEGORIES_FAILED: 4,
  FETCH_PROJECTS_SUCCESS: 5,
  FETCH_PROJECTS_FAILED: 6,
  FETCH_PROJECTS_DETAILS_SUCCESS: 7,
  FETCH_PROJECTS_DETAILS_FAILED: 8,
  FETCH_CONCEPTS_SUCCESS: 9,
  FETCH_CONCEPTS_FAILED: 10,
  NOTIFICATION_ADD: 11,
  NOTIFICATION_REMOVE: 12,
  SUBSCRIPTION_EXISTS: 13,
  FETCHING_STATUS: 14,
  FETCH_PROJECTS_SEARCH_QUERY_SUCCESS: 15,
  FETCH_PROJECTS_SEARCH_QUERY_FAILED: 16,
  FETCH_CONCEPTS_SEARCH_QUERY_SUCCESS: 17,
  FETCH_CONCEPTS_SEARCH_QUERY_FAILED: 18,
  CLEAR_SEARCH_RESULTS: 19,
  UPDATING_CONCEPTS_FAILED: 20,
  SCREENING_ERROR: 21,
  type: {
    1: 'SW_FETCH_COUNTRIES_SUCCESS',
    2: 'SW_FETCH_COUNTRIES_FAILED',
    3: 'SW_FETCH_CATEGORIES_SUCCESS',
    4: 'SW_FETCH_CATEGORIES_FAILED',
    5: 'SW_FETCH_PROJECTS_SUCCESS',
    6: 'SW_FETCH_PROJECTS_FAILED',
    7: 'SW_FETCH_PROJECTS_DETAILS_SUCCESS',
    8: 'SW_FETCH_PROJECTS_DETAILS_FAILED',
    9: 'SW_FETCH_CONCEPTS_SUCCESS',
    10: 'SW_FETCH_CONCEPTS_FAILED',
    11: 'NOTIFICATION_ADD',
    12: 'NOTIFICATION_REMOVE',
    13: 'SW_SUBSCRIPTION_EXISTS',
    14: 'FETCHING_STATUS',
    15: 'FETCH_PROJECTS_SEARCH_QUERY_SUCCESS',
    16: 'FETCH_PROJECTS_SEARCH_QUERY_FAILED',
    17: 'FETCH_CONCEPTS_SEARCH_QUERY_SUCCESS',
    18: 'FETCH_CONCEPTS_SEARCH_QUERY_FAILED',
    19: 'CLEAR_SEARCH_RESULTS',
    20: 'UPDATING_CONCEPTS_FAILED',
    21: 'SCREENING_ERROR',
  },
}

Object.freeze(ScreenWinner)

export const BookmarkActionTypes = {
  FETCH_BOOKMARK_SUCCESS: 1,
  FETCH_BOOKMARK_FAILED: 2,
  FETCH_CHECKED_BOOKMARK_SUCCESS: 3,
  FETCH_CHECKED_BOOKMARK_FAILED: 4,
  FETCH_REMOVE_BOOKMARK_SUCCESS: 5,
  FETCH_REMOVE_BOOKMARK_FAILED: 6,
  SET_BOOKMARK_SWING: 7,
  type: {
    1: 'FETCH_BOOKMARK_SUCCESS',
    2: 'FETCH_BOOKMARK_FAILED',
    3: 'FETCH_CHECKED_BOOKMARK_SUCCESS',
    4: 'FETCH_CHECKED_BOOKMARK_FAILED',
    5: 'FETCH_REMOVE_BOOKMARK_SUCCESS',
    6: 'FETCH_REMOVE_BOOKMARK_FAILED',
    7: 'SET_BOOKMARK_SWING',
  },
}

Object.freeze(BookmarkActionTypes)

export const commonTrendAnalysis = {
  CTA_FETCH_LIST_SUCCESS: 1,
  CTA_FETCH_LIST_FAILED: 2,
  CTA_FETCH_LIST_START: 1,
  type: {
    1: 'CTA_FETCH_LIST_SUCCESS',
    2: 'CTA_FETCH_LIST_FAILED',
    3: 'CTA_FETCH_LIST_START',
  },
}

Object.freeze(commonTrendAnalysis)

export const advancedTrendAnalysis = {
  ATA_FETCH_LIST_SUCCESS: 1,
  ATA_FETCH_LIST_FAILED: 2,
  ATA_FETCH_LIST_START: 3,
  ATA_RESET_COMPARISON_LIST: 4,
  ATA_CMP_LIST_SUCCESS: 5,
  ATA_CMP_LIST_FAILED: 6,
  ATA_FETCH_SUB_CATEGORIES_SUCCESS: 7,
  ATA_FETCH_SUB_CATEGORIES_FAILED: 8,
  ATA_SET_CURRENT_TREND: 9,
  type: {
    1: 'ATA_FETCH_LIST_SUCCESS',
    2: 'ATA_FETCH_LIST_FAILED',
    3: 'ATA_FETCH_LIST_START',
    4: 'ATA_RESET_COMPARISON_LIST',
    5: 'ATA_CMP_LIST_SUCCESS',
    6: 'ATA_CMP_LIST_FAILED',
    7: 'ATA_FETCH_SUB_CATEGORIES',
    8: 'ATA_FETCH_SUB_CATEGORIES_FAILED',
    9: 'ATA_SET_CURRENT_TREND',
  },
}

Object.freeze(advancedTrendAnalysis)

export const fetchSharingUsers = {
  SU_FETCH_LIST_SUCCESS: 1,
  SU_FETCH_LIST_FAILED: 2,
  SU_FETCH_LIST_START: 3,
  type: {
    1: 'SU_FETCH_LIST_SUCCESS',
    2: 'SU_FETCH_LIST_FAILED',
    3: 'SU_FETCH_LIST_START',
  },
}

Object.freeze(fetchSharingUsers)

export const sendSharingUsers = {
  SU_SEND_LIST_SUCCESS: 1,
  SU_SEND_LIST_FAILED: 2,
  SU_SEND_LIST_START: 3,
  type: {
    1: 'SU_SEND_LIST_SUCCESS',
    2: 'SU_SEND_LIST_FAILED',
    3: 'SU_SEND_LIST_START',
  },
}

Object.freeze(sendSharingUsers)

export const trendPillarActionTypes = {
  EDIT_TREND_PILLAR_SUCCESS: 1,
  EDIT_TREND_PILLAR_FAILURE: 2,
  CREATE_PILLAR_SUCCESS: 3,
  CREATE_PILLAR_FAILURE: 4,
  GET_TREND_PILLAR_TO_EDIT: 5,
  INFO_ABOUT_TREND_PILLAR: 6,
  IMPORT_TREND_PILLAR_SUCCESS: 7,
  IMPORT_TREND_PILLAR_FAILURE: 8,
  CLEAR_PILLAR_DETAILS_DATA: 9,
  RESET_TREND_PILLAR_DATA: 10,
  IMPORT_TREND_PILLAR_DETAILS: 11,
  GET_SUGGESTED_TRENDS_SUCCESS: 12,
  GET_TREND_PILLAR_TRENDS: 13,
  GET_IMPORT_TREND_PILLAR_TRENDS_SUCCESS: 14,
  REMOVE_SUGGESTED_TRENDS: 15,
  TREND_PILLAR_TREND_DETAILS_SUCCESS: 16,
  TREND_PILLAR_TREND_DETAILS_FAILURE: 17,
  type: {
    1: 'EDIT_TREND_PILLAR_SUCCESS',
    2: 'EDIT_TREND_PILLAR_FAILURE',
    3: 'CREATE_PILLAR_SUCCESS',
    4: 'CREATE_PILLAR_FAILURE',
    5: 'GET_TREND_PILLAR_TO_EDIT',
    6: 'INFO_ABOUT_TREND_PILLAR',
    7: 'IMPORT_TREND_PILLAR_SUCCESS',
    8: 'IMPORT_TREND_PILLAR_FAILURE',
    9: 'CLEAR_PILLAR_DETAILS_DATA',
    10: 'RESET_TREND_PILLAR_DATA',
    11: 'IMPORT_TREND_PILLAR_DETAILS',
    12: 'GET_SUGGESTED_TRENDS_SUCCESS',
    13: 'GET_TREND_PILLAR_TRENDS',
    14: 'GET_IMPORT_TREND_PILLAR_TRENDS_SUCCESS',
    15: 'REMOVE_SUGGESTED_TRENDS',
    16: 'TREND_PILLAR_TREND_DETAILS_SUCCESS',
    17: 'TREND_PILLAR_TREND_DETAILS_FAILURE',
  },
}

Object.freeze(trendPillarActionTypes)

export const FetchTrendsPillar = {
  FETCH_TRENDS_PILLAR_SUCCESS: 1,
  FETCH_TRENDS_PILLAR_FAILED: 2,
  type: {
    1: 'FETCH_TRENDS_PILLAR_SUCCESS',
    2: 'FETCH_TRENDS_PILLAR_FAILED',
  },
}

Object.freeze(FetchTrendsPillar)

export const DeleteTrendsPillar = {
  DELETE_TRENDS_PILLAR_SUCCESS: 1,
  DELETE_TRENDS_PILLAR_FAILED: 2,
  type: {
    1: 'DELETE_TRENDS_PILLAR_SUCCESS',
    2: 'DELETE_TRENDS_PILLAR_FAILED',
  },
}

Object.freeze(DeleteTrendsPillar)

export const DownloadTrendsPillar = {
  DOWNLOAD_TRENDS_PILLAR_SUCCESS: 1,
  DOWNLOAD_TRENDS_PILLAR_FAILED: 2,
  type: {
    1: 'DOWNLOAD_TRENDS_PILLAR_SUCCESS',
    2: 'DOWNLOAD_TRENDS_PILLAR_FAILED',
  },
}
export const fetchHighlightFeatureList = {
  FETCH_HIGHLIGHT_FEATURE_LIST_SUCCESS: 1,
  FETCH_HIGHLIGHT_FEATURE_LIST_FAILED: 2,
  type: {
    1: 'FETCH_HIGHLIGHT_FEATURE_LIST_SUCCESS',
    2: 'FETCH_HIGHLIGHT_FEATURE_LIST_FAILED',
  },
}

Object.freeze(fetchHighlightFeatureList)

export const globalTrendSearch = {
  GLOBAL_TREND_SEARCH_RESULTS_SUCCESS: 1,
  GLOBAL_TREND_SEARCH_RESULTS_FAILURE: 2,
  RESET_GLOBAL_TREND_SEARCH_RESULTS: 3,
  SET_GLOBAL_TREND_SEARCH_RESULTS_LOADING: 4,
  type: {
    1: 'GLOBAL_TREND_SEARCH_RESULTS_SUCCESS',
    2: 'GLOBAL_TREND_SEARCH_RESULTS_FAILURE',
    3: 'RESET_GLOBAL_TREND_SEARCH_RESULTS',
    4: 'SET_GLOBAL_TREND_SEARCH_RESULTS_LOADING',
  },
}

Object.freeze(globalTrendSearch)

export const trendListForGlobalSearch = {
  FETCH_GLOBAL_SEARCH_TREND_LIST_SUCCESS: 1,
  FETCH_GLOBAL_SEARCH_TREND_LIST_FAILURE: 2,
  FETCH_GLOBAL_SEARCH_TREND_LIST_LOADING: 3,
  type: {
    1: 'FETCH_GLOBAL_SEARCH_TREND_LIST_SUCCESS',
    2: 'FETCH_GLOBAL_SEARCH_TREND_LIST_FAILURE',
    3: 'FETCH_GLOBAL_SEARCH_TREND_LIST_LOADING',
  },
}

Object.freeze(trendListForGlobalSearch)

export const cognitiveSearch = {
  FETCH_ADV_FILTERS_LIST: 1,
  FETCH_FORMATS_LIST: 2,
  FETCH_FILTERED_TRENDS: 3,
  FETCH_ADV_SRCH_RESULTS: 4,
  FETCH_CN: 5,
  FETCH_FS_PRODUCTS: 6,
  FETCH_ADV_FILTERS_LIST_FAILED: 7,
  FETCH_FORMATS_LIST_FAILED: 8,
  FETCH_FILTERED_TRENDS_FAILED: 9,
  FETCH_ADV_SRCH_RESULTS_FAILED: 10,
  FETCH_CN_FAILED: 11,
  FETCH_FS_PRODUCTS_FAILED: 12,
  FETCH_ADV_FILTERS_LIST_LOADING: 13,
  FETCH_FORMATS_LIST_LOADING: 14,
  FETCH_FILTERED_TRENDS_LOADING: 15,
  FETCH_ADV_SRCH_RESULTS_LOADING: 16,
  FETCH_CN_LOADING: 17,
  FETCH_FS_PRODUCTS_LOADING: 18,
  FETCH_RETAIL_PRODUCTS: 19,
  FETCH_RETAIL_PRODUCTS_FAILED: 20,
  FETCH_RETAIL_PRODUCTS_LOADING: 21,
  FETCH_CONSUMER_QUOTES: 22,
  FETCH_CONSUMER_QUOTES_FAILED: 23,
  FETCH_CONSUMER_QUOTES_LOADING: 24,
  FETCH_GRAPH_DATA: 25,
  FETCH_GRAPH_DATA_FAILED: 26,
  FETCH_GRAPH_DATA_LOADING: 27,
  GET_DASHBOARDS_LIST: 28,
  GET_DASHBOARDS_LIST_FAILED: 29,
  GET_DASHBOARDS_LIST_LOADING: 30,
  FETCH_ADV_FILTERS_LIST_UPDATE: 31,
  RESET_COGNITIVE_SEARCH_STATE: 32,
  type: {
    1: 'FETCH_ADV_FILTERS_LIST',
    2: 'FETCH_FORMATS_LIST',
    3: 'FETCH_FILTERED_TRENDS',
    4: 'FETCH_ADV_SRCH_RESULTS',
    5: 'FETCH_CN',
    6: 'FETCH_FS_PRODUCTS',
    7: 'FETCH_ADV_FILTERS_LIST_FAILED',
    8: 'FETCH_FORMATS_LIST_FAILED',
    9: 'FETCH_FILTERED_TRENDS_FAILED',
    10: 'FETCH_ADV_SRCH_RESULTS_FAILED',
    11: 'FETCH_CN_FAILED',
    12: 'FETCH_FS_PRODUCTS_FAILED',
    13: 'FETCH_ADV_FILTERS_LIST_LOADING',
    14: 'FETCH_FORMATS_LIST_LOADING',
    15: 'FETCH_FILTERED_TRENDS_LOADING',
    16: 'FETCH_ADV_SRCH_RESULTS_LOADING',
    17: 'FETCH_CN_LOADING',
    18: 'FETCH_FS_PRODUCTS_LOADING',
    19: 'FETCH_RETAIL_PRODUCTS',
    20: 'FETCH_RETAIL_PRODUCTS_FAILED',
    21: 'FETCH_RETAIL_PRODUCTS_LOADING',
    22: 'FETCH_CONSUMER_QUOTES',
    23: 'FETCH_CONSUMER_QUOTES_FAILED',
    24: 'FETCH_CONSUMER_QUOTES_LOADING',
    25: 'FETCH_GRAPH_DATA',
    26: 'FETCH_GRAPH_DATA_LOADING',
    27: 'FETCH_GRAPH_DATA_FAILED',
    28: 'GET_DASHBOARDS_LIST',
    29: 'GET_DASHBOARDS_LIST_FAILED',
    30: 'GET_DASHBOARDS_LIST_LOADING',
    31: 'FETCH_ADV_FILTERS_LIST_UPDATE',
    32: 'RESET_COGNITIVE_SEARCH_STATE',
  },
}

Object.freeze(cognitiveSearch)

export const myDashboard = {
  FETCH_DASHBOARD_FAVORITES_TRENDS_LIST: 1,
  REMOVE_FROM_FAVORITES_TRENDS: 2,
  ADD_TO_FAVORITES_TRENDS: 3,
  FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_FAILED: 4,
  REMOVE_FROM_FAVORITES_TRENDS_FAILED: 5,
  ADD_TO_FAVORITES_TRENDS_FAILED: 6,
  FETCH_TREND_HIGHLIGHT_DATA: 7,
  FETCH_TREND_HIGHLIGHT_DATA_FAILED: 8,
  FETCH_CHECKED_ADDED_FAVORITE_SUCCESS: 9,
  FETCH_CHECKED_ADDED_FAVORITE_FAILURE: 10,
  FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_LOADING: 11,
  FETCH_TREND_HIGHLIGHT_DATA_LOADING: 12,
  FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_SUCCESS: 13,
  FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_FAILED: 14,
  FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_SUCCESS: 15,
  FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_FAILED: 16,
  FETCH_RECENT_ACTIVITY_LOADING: 17,
  FETCH_RECENT_ACTIVITY_SUCCESS: 18,
  FETCH_RECENT_ACTIVITY_FAILED: 19,
  FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_SUCCESS: 20,
  FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_FAILED: 21,
  FETCH_CHECKED_ADDED_OTHER_FAVORITE_SUCCESS: 22,
  FETCH_CHECKED_ADDED_OTHER_FAVORITE_FAILED: 23,
  FETCH_CHECK_ADDED_FAVORITE_LOADING: 24,
  FETCH_CHECK_ADDED_OTHER_FAVORITE_LOADING: 25,
  FETCH_CHECK_ADDED_FAVORITE_TREND_PILLAR_LOADING: 26,

  type: {
    1: 'FETCH_DASHBOARD_FAVORITES_TRENDS_LIST',
    2: 'REMOVE_FROM_FAVORITES_TRENDS',
    3: 'ADD_TO_FAVORITES_TRENDS',
    4: 'FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_FAILED',
    5: 'REMOVE_FROM_FAVORITES_TRENDS_FAILED',
    6: 'ADD_TO_FAVORITES_TRENDS_FAILED',
    7: 'FETCH_TREND_HIGHLIGHT_DATA',
    8: 'FETCH_TREND_HIGHLIGHT_DATA_FAILED',
    9: 'FETCH_CHECKED_ADDED_FAVORITE_SUCCESS',
    10: 'FETCH_CHECKED_ADDED_FAVORITE_FAILURE',
    11: 'FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_LOADING',
    12: 'FETCH_TREND_HIGHLIGHT_DATA_LOADING',
    13: 'FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_SUCCESS',
    14: 'FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_FAILED',
    15: 'FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_SUCCESS',
    16: 'FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_FAILED',
    17: 'FETCH_RECENT_ACTIVITY_LOADING',
    18: 'FETCH_RECENT_ACTIVITY_SUCCESS',
    19: 'FETCH_RECENT_ACTIVITY_FAILED',
    20: 'FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_SUCCESS',
    21: 'FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_FAILED',
    22: 'FETCH_CHECKED_ADDED_OTHER_FAVORITE_SUCCESS',
    23: 'FETCH_CHECKED_ADDED_OTHER_FAVORITE_FAILED',
    24: 'FETCH_CHECK_ADDED_FAVORITE_LOADING',
    25: 'FETCH_CHECK_ADDED_OTHER_FAVORITE_LOADING',
    26: 'FETCH_CHECK_ADDED_FAVORITE_TREND_PILLAR_LOADING',
  },
}

Object.freeze(myDashboard)

export const markIrrelevant = {
  UPDATE_IRRELEVANT_DOCS: 1,
  MAR_IRRELEVANT_START: 2,
  MAR_IRRELEVANT_SUCCESS: 3,
  MAR_IRRELEVANT_FAILED: 4,
  type: {
    1: 'UPDATE_IRRELEVANT_DOCS',
    2: 'START',
    3: 'SUCCESS',
    4: 'FAILED',
  },
}

Object.freeze(markIrrelevant)

export const conceptGenie = {
  CG_SELECTED_CONCEPTS: 1,
  RESET_CG_SELECTED_CONCEPTS: 2,
  CG_MODIFIED_CONCEPTS: 3,

  type: {
    1: 'CG_SELECTED_CONCEPTS',
    2: 'RESET_CG_SELECTED_CONCEPTS',
    3: 'CG_MODIFIED_CONCEPTS',
  },
}

Object.freeze(conceptGenie)
