import styled from '@emotion/styled'
import { Box } from '@mui/system'

export const RelatedIngredientContainerForDemography = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'auto',
    '& img': {
      width: theme.spacing(7),
      height: theme.spacing(7),
      borderRadius: theme.shape.borderRadius,
      ObjectFit: 'cover',
    },
    '& .text': {
      color: theme.palette.secondary.dark,
    },
    '& >.MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
)
export default styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  '& img': {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.shape.borderRadius,
    ObjectFit: 'cover',
  },
  '& .text': {
    color: theme.palette.secondary.dark,
  },
  '& >.MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
    boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
  },
}))
