import React from 'react'
import { Breadcrumbs, Typography, Link, Box } from '@mui/material'
import ScrollToTop from '../../hoc/ScrollToTop'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const breadcrumbs = [
  <Link variant="breadcrumbsLink" key="1" href="/">
    Foresight Engine
  </Link>,
  <Typography variant="subtitle2" key="2">
    Privacy Policy
  </Typography>,
]

const PrivacyPolicy = () => {
  return (
    <ScrollToTop>
      <Box>
        <Breadcrumbs
          m={2}
          aria-label="breadcrumb"
          separator={<NavigateNextIcon />}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Box m={2}>
          <Typography variant="h2" component="h2">
            Privacy Policy
          </Typography>
          <br />
          <Typography variant="h3" component="h3">
            Effective Date: June 14, 2023
          </Typography>
          <br />
          <Typography variant="h3" component="h3">
            Last updated: June 14, 2023
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            1. Introduction
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            1.1. The domain name https://premium.aipalette.com (“Website”) is
            owned by AI Palette PTE Ltd, a company incorporated under the laws
            of Singapore, having its registered office at #03, 32 Carpenter
            Street, Singapore 059911 (hereinafter referred to as "AI Palette”,
            “We” or “Us” which expression shall, unless it be repugnant to the
            context or meaning thereof, be deemed to include its successors,
            affiliates, and permitted assigns).
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            1.2. The Website is an online portal run by AI Palette. We place our
            highest value on your trust and put top priority on protecting your
            Personal Information and this privacy policy explains how we will
            collect, use, share and process Personal Information in relation to
            our services.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            1.3. By accepting this Policy, you understand and agree to the
            collection, use, sharing and processing of Personal Information as
            described herein. If you provide us with Personal Information about
            someone else, you confirm that they are aware that you have provided
            their information and that they consent to our use of their
            information according to our Policy. Our Policy applies to all the
            visitors, users and others who access our Website.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            2. Scope
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            2.1. This Privacy Policy (“Policy”) is an electronic record in the
            form of an electronic contract formed under the applicable law. This
            Policy does not require any physical, electronic or digital
            signature. This Policy is a legally binding document between you and
            AI Palette. We collect and process your personal and third party
            data carefully, only for the purposes described in this Policy and
            only to the extent necessary as defined herein and within the scope
            of the applicable legal regulations. This Policy seeks to ensure
            that any personal information or third party information handled by
            AI Palette is managed in a way that is ethical, compliant and
            adheres to best industry practices.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            2.2. Please read the terms and conditions of this Policy carefully,
            before accessing or using this Website. By accessing or using the
            Website including its services, you agree to the terms of this
            Policy. If you are accepting this Policy on behalf of another person
            or company or other legal entity, you represent and warrant that you
            have full authority to bind such person, company or legal entity to
            these terms.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            2.3. Our Policy describes the types of information we collect, why
            and how we use the information, with whom we share it, and the
            choices you can make about our use of the information. We also
            describe the measures we take to protect the security of the
            information and how you can contact us about our privacy practices.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            2.4. This Policy describes AI Palette’s current data protection
            policies and practices and may be amended/updated from time to time.
            Any changes to this Policy will become effective upon posting of the
            revised Policy on the Website. We suggest that you regularly check
            this Policy to apprise yourself of any updates. Your continued use
            of Website or provision of data or information thereafter will imply
            your unconditional acceptance of such updates to this Policy.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            3. Consent
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            3.1. By mere use of the Website, you expressly consent to AI
            Palette’s use and disclosure of your Personal Information (as
            defined below) and third-party information in accordance with this
            Policy. If you do not agree with the terms of this Policy, please do
            not use this Website. This Policy shall be deemed to be incorporated
            into the terms of use of the Website and shall be read in addition
            to the terms of use.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            3.2. In order to avail any services on the Website, you may be using
            third-party information. You hereby explicitly agree that you have
            obtained explicit written authorization and consent by way of
            affixing the signature, digital or physical, from all such
            subjects/individuals whose information you verify/provide data
            against in the format required by the applicable laws in force, from
            time to time and store the same in its custody basis such laws. You
            hereby represent and warrant that AI Palette shall not be held
            responsible, liable or accountable in any manner or to any extent
            whatsoever, for your failure to perform obligations under this
            Clause.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            4. Types of Information
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            4.1. “Personal Information” is defined as any information that
            relates to a natural person, which, either directly or indirectly,
            in combination with other information available or likely to be
            available with a body corporate, is capable of identifying such
            person.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            4.2. Personal Information: Personal Information means any
            information that may be used to identify an individual, including,
            but not limited to, the first and last names, physical address,
            telephone number, date of birth, age, gender, e-mail address, or any
            other contact information, educational qualifications, professional
            qualifications including past or current employer information, skill
            sets, project details, designations and any financial information
            including bank account details, credit/debit card details or such
            other payment information. Further, AI Palette may also require you
            to provide certain user verification information such as your
            permanent account number, unique identification number, copy of your
            driving license and passport. We limit our collection of Personal
            Information to that which is necessary for its intended purpose.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            4.3. Business Information: Business Information means any
            information that may be used to identify an individual’s business,
            including but not limited to the name and address of the entity,
            date of incorporation, e-mail address of the entity, contact
            information of the registered office, company identification number
            (CIN), financial information including bank account details, PAN
            details, names of the directors and such other information
            (hereinafter referred to as “Business Information”).
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            4.4. Non-Personal Information: Non-personal Information means
            information that does not specifically identify an individual or
            business, but includes information from you, such as your browser
            type, the URL of the previous websites you visited, your Internet
            Service Provider (ISP), operating system and your Internet Protocol
            (IP) address. AI Palette may gather any Non-Personal Information
            regarding how many people visit this website, the pages they visit,
            their IP address, and the type of browser they used while visiting
            the Website (“hereinafter referred to as “Non-Personal
            Information”). AI Palette may also collect Non-Personal Information
            that you voluntarily provide, such as information included in
            response to a questionnaire or a survey conducted by AI Palette.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            4.5. Personal Information, Business Information, Non-Personal
            Information and third-party information hereinafter shall be
            referred to as “Information”.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            5. Collection of Information
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            5.1. AI Palette may collect information from you when you (i)
            register on the Website; (ii) update or change personal details in
            your account; (iii) use the Website for any of the services being
            offered thereon; (iv) participate in campaigns conducted by AI
            Palette on the Website (if any); and (v) download or update
            software.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            5.2. AI Palette and its business partners may use cookies to monitor
            the Website usage including, without limitation, to provide useful
            features to simplify your experience when you return to the Website,
            like remembering your login id, Personal Information and third-party
            information and to deliver relevant content based on your
            preferences, usage patterns and location.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            5.3. AI Palette may also collect Non-Personal Information based on
            your browsing activity and in relation to your use or access to the
            Website like your browser type, your Internet Protocol address, your
            Operating System, your prior search results etc., which may or may
            not be publically accessible.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            5.4. Information collected by AI Palette from a particular browser
            or device may be used with another computer or device that is linked
            to the browser or device on which such information was collected.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            6. Use of Information
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            6.1. AI Palette may use the Information you provide to fulfil your
            requests for our products, programs, and services, to respond to
            your inquiries about our offerings, and to offer you other products,
            programs or services that we believe may be of interest to you.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            6.2. We use the Information about others to enable us to procure
            information about them. From time to time, we also may use this
            information to offer our products, programs, or services to them.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            6.3. We may use the Information to monitor your use of the services
            and may review and analyse the Information provided by you to
            provide you with customized service.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            6.4. We may use your Non-Personal Information for internal business
            purposes, such as data analysis, research, developing new products,
            enhancing and improving existing products and services and in
            identifying usage trends.{' '}
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            7. Information Sharing
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            7.1. AI Palette does not rent, sell, or share Information with other
            people or with other non-affiliated entities, except with your
            consent, to provide services you have requested for or under the
            following circumstances:
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            7.1.1. AI Palette may engage third party vendors and/or contractors
            to perform certain support services, who may have limited access to
            Information.{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            7.1.2. In order to process your transactions, AI Palette may direct
            you to a payment gateway service provider of its own choice, which
            may have access to the Personal Information provided by you while
            making such payment.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            7.1.3. AI Palette may share Information with Government authorities
            in response to subpoenas, court orders, or other legal process; to
            establish or exercise legal rights; to defend against legal claims;
            or as otherwise required by law. This may be done in response to a
            law enforcement agency's request.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            8. Cookies
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            To enhance your experience with our sites, many of our web pages use
            "cookies." Cookies are text files we place in your computer's
            browser to store your preferences. Cookies, by themselves, do not
            tell us your e-mail address or other personally identifiable
            information unless you choose to provide this information to us by,
            for example, registering at one of our sites. However, once you
            choose to furnish the site with personally identifiable information,
            this information may be linked to the data stored in the cookie. We
            use cookies to understand site usage and to improve the content and
            offerings on our sites. For example, we may use cookies to
            personalize your experience on our web pages (e.g., to recognize you
            by name when you return to our site), save your password in
            password-protected areas, and enable you to use shopping carts on
            our Website. We also may use cookies to offer you products,
            programs, or services.{' '}
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            9. Third Party Service Providers and Social Media Websites
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            AI Palette may engage third party vendors and/or contractors to
            perform certain support services for AI Palette, including, without
            limitation, software maintenance services, advertising and marketing
            services, web hosting services and such other related services which
            are required by AI Palette to provide its services efficiently.
            These third parties may have limited access to Information. If they
            do, this limited access is provided so that they may perform these
            tasks for AI Palette and they are not authorized by AI Palette to
            otherwise use or disclose Information, except to the extent required
            by law. AI Palette does not make any representations concerning the
            privacy practices or policies or terms of use of such websites, nor
            does it control or guarantee the accuracy, integrity, or quality of
            the information, data, text, software, music, sound, photographs,
            graphics, videos, messages or other materials available on such
            websites. The inclusion or exclusion does not imply any endorsement
            by AI Palette of the website, the website's provider, or the
            information on such website.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            10. Disclosure to Acquirers
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            AI Palette may disclose and/or transfer Information to an acquirer,
            assignee or other successor entity in connection with a sale,
            merger, or reorganization of all or substantially all of the equity,
            business or assets of AI Palette.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            11. Protection of Information
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            11.1. AI Palette has taken adequate measures to protect the security
            of Information and to ensure that your choices for its intended use
            are honoured. AI Palette takes robust precautions to protect your
            data from loss, misuse, unauthorized access or disclosure,
            alteration, or destruction.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            11.2. AI Palette considers the confidentiality and security of your
            information to be of utmost importance. It therefore uses industry
            standards, and physical, technical and administrative security
            measures to keep Information confidential and secure and AI Palette
            will not share your Information with third parties, except as
            otherwise provided in this Policy. Please be advised, however, that
            while we strive to protect Information and privacy, we cannot
            guarantee or warranty its absolute security when Information is
            transmitted over the internet into the Website. AI Palette will
            annually evaluate this necessity considering your privacy and our
            relation while keeping the applicable legislation in mind.
            Non-Personal Information will be retained indefinitely.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            12. Minor
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            We do not intend to attract anyone under the relevant age of consent
            to enter into binding legal contracts under the laws of their
            respective jurisdictions. AI Palette does not intentionally or
            knowingly collect Personal Information through the Website from
            anyone under that age. We encourage parents and guardians to be
            involved in the online activities of minor to ensure that no
            personal information is collected from a minor without their prior
            consent.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            13. Limitation of Liability
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            13.1. AI Palette shall not be liable to you for any loss of profit,
            production, anticipated savings, goodwill or business opportunities
            or any type of direct or indirect, incidental, economic,
            compensatory, punitive, exemplary or consequential losses arising
            out of performance or non-performance of its obligations under these
            Terms.
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            13.2. At any point of time, AI Palette’s liability for any loss
            arising from a particular transaction carried out by you on the
            Website shall not exceed the fees paid by you for that particular
            transaction.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            14. Opt-Out
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You have the option to 'opt-out' of all AI Palette’s newsletters and
            other general email marketing communications by way of links
            provided at the bottom of each mailer. AI Palette respects your
            privacy and in the event that you choose to not receive such
            mailers, it shall take all adequate steps to remove you from such
            lists. However, you will not be able to opt-out of receiving
            administrative messages, customer service responses or other
            transactional communications.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            15. Changes to this Policy
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            AI Palette reserves the right to update, change or modify this
            Policy at any time. The Policy shall come to effect from the date of
            such update, change or modification.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            16. Grievance Officer
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            In accordance with the applicable law, the name and contact details
            of the Grievance Officer are provided below:{' '}
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Name: Ajeet Singh
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Address: #03-01, 32 Carpenter Street, Singapore 059911
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Cell: +91- 6364461970
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Email ID: grievanceofficer@aipalette.com
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            17. How to Contact Us
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            If you have additional questions or concerns about this Policy or
            want to make any request or suggestions regarding certain Personal
            Information, please contact us at{' '}
            <Link href="/mui/contact-us">[•]</Link>.
          </Typography>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default PrivacyPolicy
