import { isEmpty } from 'lodash'
import {
  NeedDetailsViewActions,
  SubNeedDetailActionTypes,
  NeedQuotesViewActions,
  SubNeedQuoteActionTypes,
} from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

import ProductNeedsDetails from '../../services/networking/Models/ProductNeedsDetails'
import NeedQuotesResponse from '../../services/networking/Models/NeedQuotesResponse'
import SubNeedDetails from '../../services/networking/Models/SubNeedDetailResponse'
import SubNeedQuoteResponse from '../../services/networking/Models/SubNeedQuoteResponse'

export const fetchNeedsDetails = (projectid, needsid) => {
  return (dispatch, getState) => {
    dispatch(fetchNeedDetailsStart())
    const request = NetworkUtil.formRequestForConsumerNeeds(projectid, needsid)
    axios
      .get(request)
      .then((response) => {
        const responseObject = new ProductNeedsDetails(response.data)
        dispatch(fetchNeedtDetailsSuccess(responseObject))
      })
      .catch((error) => {
        dispatch(fetchNeedDetailsfailed(error))
      })
  }
}

export const fetchKeywordNeedsDetails = (projectid, keyword) => {
  return (dispatch, getState) => {
    dispatch(fetchNeedDetailsStart())
    const request = NetworkUtil.formRequestForConsumerNeedsWithKeyword(
      projectid,
      keyword,
    )

    axios
      .get(request)
      .then((response) => {
        const responseObject = new ProductNeedsDetails(response.data)
        dispatch(fetchNeedtDetailsSuccess(responseObject))
      })
      .catch((error) => {
        dispatch(fetchNeedDetailsfailed(error))
      })
  }
}

const fetchNeedDetailsStart = () => {
  return {
    type: NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_START],
  }
}

export const fetchNeedtDetailsSuccess = (needDetails) => {
  return {
    type:
      NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_SUCCESS],
    needDetails: needDetails,
  }
}

const fetchNeedDetailsfailed = (error) => {
  return {
    type: NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_FAIL],
    error: error,
  }
}

export const fetchNeedQuotes = (projectid, needsid) => {
  return async (dispatch, getState) => {
    try {
      let request1 = NetworkUtil.formRequestForConsumerNeedQuotes(
        projectid,
        needsid,
      )
      let request2 = NetworkUtil.formRequestForConsumerNeedTMQuotes(
        projectid,
        needsid,
      )
      let v = await axios.get(request1)
      let w = await axios.get(request2)
      let responseObject = {}
      if (v) {
        responseObject = { ...v.data }
      }
      if (!isEmpty(w) && !isEmpty(w.data)) {
        w = w.data
        responseObject = {
          ...responseObject,
          food_dive_quotes: w.food_dive_quotes,
          food_navigator_quotes: w.food_navigator_quotes,
          ift_quotes: w.ift_quotes,
          eat_right_pro_quotes: w.eat_right_pro_quotes,
          eat_right_pro_quotes: w.american_society_quotes,
          nutraingredients_quotes: w.nutraingredients_quotes,
          uspto_quotes: w.uspto_quotes,
          japan_food_journal_quotes: w.japan_food_journal_quotes,
          food_beverage_asia_quotes: w.food_beverage_asia_quotes,
          japan_food_quotes: w.japan_food_quotes,
          independent_quotes: w.independent_quotes,
          hindawi_quotes: w.hindawi_quotes,
          korea_herald_quotes: w.korea_herald_quotes,
          food_science_and_nutrition_quotes:
            w.food_science_and_nutrition_quotes,
          fmcg_magazine_quotes: w.fmcg_magazine_quotes,
          fdbusiness_quotes: w.fdbusiness_quotes,
          food_in_canada_quotes: w.food_in_canada_quotes,
          food_drink_network_quotes: w.food_drink_network_quotes,
          food_drink_europe_quotes: w.food_drink_europe_quotes,
          food_and_beverage_news_canada_quotes:
            w.food_and_beverage_news_canada_quotes,
          european_journal_of_nutrition_and_food_safety_quotes:
            w.european_journal_of_nutrition_and_food_safety_quotes,
          confectionery_news_quotes: w.confectionery_news_quotes,
          cnn_quotes: w.cnn_quotes,
          cbc_quotes: w.cbc_quotes,
          candy_industry_quotes: w.candy_industry_quotes,
          canadian_food_business_quotes: w.canadian_food_business_quotes,
          british_journal_of_nutrition_quotes:
            w.british_journal_of_nutrition_quotes,
          bbc_good_food_quotes: w.bbc_good_food_quotes,
          bakery_and_snacks_quotes: w.bakery_and_snacks_quotes,
          asian_food_science_journal_quotes:
            w.asian_food_science_journal_quotes,
          academic_journal_quotes: w.academic_journal_quotes,
          abc_news_quotes: w.abc_news_quotes,
          longdom_quotes: w.longdom_quotes,
          mdpi_quotes: w.mdpi_quotes,
          my_food_research_quotes: w.my_food_research_quotes,
          nature_quotes: w.nature_quotes,
          pr_newswire_quotes: w.pr_newswire_quotes,
          science_alert_quotes: w.science_alert_quotes,
          science_direct_quotes: w.science_direct_quotes,
          snack_food_and_wholesale_bakery_quotes:
            w.snack_food_and_wholesale_bakery_quotes,
          taylor_and_francison_line_quotes: w.taylor_and_francison_line_quotes,
          unesda_quotes: w.unesda_quotes,
        }
      }
      if (!v && !w) {
        dispatch(fetchNeedQuotefailed('Invalid Need Quotes Actions Detected'))
      }
      dispatch(fetchNeedQuoteSuccess(new NeedQuotesResponse(responseObject)))
    } catch (error) {
      dispatch(fetchNeedQuotefailed(error))
    }
  }
}

const fetchNeedQuoteSuccess = (needQuotes) => {
  return {
    type:
      NeedQuotesViewActions.type[
        NeedQuotesViewActions.FETCH_NEEDS_QUOTES_SUCCESS
      ],
    needQuotes: needQuotes,
  }
}

const fetchNeedQuotefailed = (error) => {
  return {
    type:
      NeedQuotesViewActions.type[NeedQuotesViewActions.FETCH_NEEDS_QUOTES_FAIL],
    error: error,
  }
}

export const fetchSubNeedsDetails = (projectid, needsid) => {
  return (dispatch, getState) => {
    dispatch(fetchSubNeedsDetailsStart())
    const request = NetworkUtil.formRequestForSubConsumerNeeds(
      projectid,
      needsid,
    )
    axios
      .get(request)
      .then((response) => {
        const responseObject = new SubNeedDetails(response.data)
        dispatch(fetchSubNeedsDetailsSuccess(responseObject))
      })
      .catch((error) => {
        dispatch(fetchSubNeedsDetailsfailed(error))
      })
  }
}

const fetchSubNeedsDetailsStart = () => {
  return {
    type:
      SubNeedDetailActionTypes.type[
        SubNeedDetailActionTypes.FETCH_SUB_NEEDS_START
      ],
  }
}

const fetchSubNeedsDetailsSuccess = (subNeedDetail) => {
  return {
    type:
      SubNeedDetailActionTypes.type[
        SubNeedDetailActionTypes.FETCH_SUB_NEEDS_SUCCESS
      ],
    subNeedDetail: subNeedDetail,
  }
}

const fetchSubNeedsDetailsfailed = (error) => {
  return {
    type:
      SubNeedDetailActionTypes.type[
        SubNeedDetailActionTypes.FETCH_SUB_NEEDS_FAIL
      ],
    error: error,
  }
}

export const fetchSubNeedQuotes = (projectid, needsid) => {
  return async (dispatch, getState) => {
    try {
      let request1 = NetworkUtil.formRequestForSubConsumerNeedQuotes(
        projectid,
        needsid,
      )
      let request2 = NetworkUtil.formRequestForSubConsumerNeedTMQuotes(
        projectid,
        needsid,
      )
      let v = await axios.get(request1)
      let w = await axios.get(request2)
      let responseObject = {}
      if (v) {
        responseObject = { ...v.data }
      }
      if (!isEmpty(w)) {
        w = w.data
        responseObject = {
          ...responseObject,
          food_dive_quotes: w.food_dive_quotes,
          food_navigator_quotes: w.food_navigator_quotes,
          ift_quotes: w.ift_quotes,
          eat_right_pro_quotes: w.eat_right_pro_quotes,
          american_society_quotes: w.american_society_quotes,
          nutraingredients_quotes: w.nutraingredients_quotes,
          uspto_quotes: w.uspto_quotes,
          japan_food_journal_quotes: w.japan_food_journal_quotes,
          food_beverage_asia_quotes: w.food_beverage_asia_quotes,
          japan_food_quotes: w.japan_food_quotes,
          independent_quotes: w.independent_quotes,
          hindawi_quotes: w.hindawi_quotes,
          korea_herald_quotes: w.korea_herald_quotes,
          food_science_and_nutrition_quotes:
            w.food_science_and_nutrition_quotes,
          fmcg_magazine_quotes: w.fmcg_magazine_quotes,
          fdbusiness_quotes: w.fdbusiness_quotes,
          food_in_canada_quotes: w.food_in_canada_quotes,
          food_drink_network_quotes: w.food_drink_network_quotes,
          food_drink_europe_quotes: w.food_drink_europe_quotes,
          food_and_beverage_news_canada_quotes:
            w.food_and_beverage_news_canada_quotes,
          european_journal_of_nutrition_and_food_safety_quotes:
            w.european_journal_of_nutrition_and_food_safety_quotes,
          confectionery_news_quotes: w.confectionery_news_quotes,
          cnn_quotes: w.cnn_quotes,
          cbc_quotes: w.cbc_quotes,
          candy_industry_quotes: w.candy_industry_quotes,
          canadian_food_business_quotes: w.canadian_food_business_quotes,
          british_journal_of_nutrition_quotes:
            w.british_journal_of_nutrition_quotes,
          bbc_good_food_quotes: w.bbc_good_food_quotes,
          bakery_and_snacks_quotes: w.bakery_and_snacks_quotes,
          asian_food_science_journal_quotes:
            w.asian_food_science_journal_quotes,
          academic_journal_quotes: w.academic_journal_quotes,
          abc_news_quotes: w.abc_news_quotes,
          longdom_quotes: w.longdom_quotes,
          mdpi_quotes: w.mdpi_quotes,
          my_food_research_quotes: w.my_food_research_quotes,
          nature_quotes: w.nature_quotes,
          pr_newswire_quotes: w.pr_newswire_quotes,
          science_alert_quotes: w.science_alert_quotes,
          science_direct_quotes: w.science_direct_quotes,
          snack_food_and_wholesale_bakery_quotes:
            w.snack_food_and_wholesale_bakery_quotes,
          taylor_and_francison_line_quotes: w.taylor_and_francison_line_quotes,
          unesda_quotes: w.unesda_quotes,
        }
      }
      if (!v && !w) {
        dispatch(
          fetchSubNeedQuoteFailed('Invalid Sub Need Quotes Actions Detected'),
        )
      }
      dispatch(
        fetchSubNeedQuoteSuccess(new SubNeedQuoteResponse(responseObject)),
      )
    } catch (error) {
      dispatch(fetchSubNeedQuoteFailed(error))
    }
    // const request = NetworkUtil.formRequestForSubConsumerNeedQuotes(projectid, needsid)
    // axios.get(request)
    //   .then(response => {
    //     const responseObject = new SubNeedQuoteResponse(response.data)
    //     dispatch(fetchSubNeedQuoteSuccess(responseObject))
    //   })
    //   .catch(error => {
    //     dispatch(fetchSubNeedQuoteFailed(error))
    //   })
  }
}

const fetchSubNeedQuoteSuccess = (subNeedQuotes) => {
  return {
    type:
      SubNeedQuoteActionTypes.type[
        SubNeedQuoteActionTypes.FETCH_SUB_NEED_QUOTES_SUCCESS
      ],
    subNeedQuotes: subNeedQuotes,
  }
}

const fetchSubNeedQuoteFailed = (error) => {
  return {
    type:
      SubNeedQuoteActionTypes.type[
        SubNeedQuoteActionTypes.FETCH_SUB_NEED_QUOTES_FAIL
      ],
    error: error,
  }
}
