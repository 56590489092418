import React, { useEffect, useState } from 'react'
import { Button, MenuItem, SvgIcon } from '@mui/material'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'

import DownloadMenu from './styles'

import { ReactComponent as XLSIcon } from '../../../assets/icons/xls.svg'
import { ReactComponent as JPEGIcon } from '../../../assets/icons/jpeg.svg'
import { ReactComponent as PPTIcon } from '../../../assets/icons/ppt.svg'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Lens: props.lensSelected,
      Download_Type: ampiUserData.downloadType,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in DownloadButton')
  }
}

const DownloadButton = (props) => {
  const [anchorElDownload, setAnchorElDownload] = useState(null)
  const [showMenu, setShowMenu] = useState(true)

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      setShowMenu(true)
    }
  }, [])

  const downloadAmplitude = (type) => {
    if (props && props.title) {
      const ampiEvent = `Clicked${props.title}_Download`
      const ampiUserData = {
        downloadType: type,
      }
      amp(props, ampiEvent, ampiUserData)
    }
  }

  const handleOpenDownloadMenu = (event) => {
    setAnchorElDownload(event.currentTarget)
  }

  const handleCloseDownloadMenu = () => {
    setAnchorElDownload(null)
  }
  return props.handleDownloadExcel ||
    props.togglePptDownload ||
    props.toggleImageDownload ? (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DownloadRoundedIcon />}
        onClick={handleOpenDownloadMenu}
      >
        Download
      </Button>
      {showMenu && (
        <DownloadMenu
          anchorEl={anchorElDownload}
          open={Boolean(anchorElDownload)}
          onClose={handleCloseDownloadMenu}
        >
          <MenuItem
            onClick={() => {
              setShowMenu(false)
              props.toggleImageDownload(props.dowImageType)
              downloadAmplitude('Jpeg')
              setShowMenu(true)
              handleCloseDownloadMenu()
            }}
          >
            <SvgIcon component={JPEGIcon} />
            Download JPEG
          </MenuItem>
          {props.handleDownloadExcel && (
            <MenuItem
              onClick={() => {
                props.handleDownloadExcel()
                downloadAmplitude('Excel')
              }}
            >
              <SvgIcon component={XLSIcon} />
              Download Excel
            </MenuItem>
          )}
          {/* {props.togglePptDownload &&
            (!props.dowPptType ? (
              <MenuItem
                onClick={() => {
                  props.togglePptDownload()
                  downloadAmplitude('PPT')
                }}
              >
                <SvgIcon component={PPTIcon} />
                Download PPT
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  props.togglePptDownload(props.dowPptType)
                  downloadAmplitude('PPT')
                }}
              >
                <SvgIcon component={PPTIcon} />
                Download PPT
              </MenuItem>
            ))} */}
        </DownloadMenu>
      )}
    </>
  ) : null
}

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(DownloadButton),
)
