import React, { useState } from 'react'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Table,
} from '@mui/material'
import { StyledTable } from './Styled'
import { amplify, getSignedFloat } from './../../store/utility'
import Link from '@mui/material/Link'

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Item',
  },
  {
    id: 'demand_score',
    numeric: true,
    disablePadding: false,
    label: 'Demand Score',
  },
  {
    id: 'supply_score',
    numeric: true,
    disablePadding: false,
    label: 'Supply Score',
  },
  {
    id: 'two_year_cagr',
    numeric: false,
    disablePadding: false,
    label: '2 Year CAGR',
  },
  {
    id: 'four_year_cagr',
    numeric: false,
    disablePadding: false,
    label: '4 Year CAGR',
  },
  {
    id: 'food-service_count',
    numeric: false,
    disablePadding: false,
    label: 'Food Service Products',
  },
  {
    id: 'retail_count',
    numeric: false,
    disablePadding: false,
    label: 'Retail Products',
  },
  {
    id: 'classification',
    numeric: false,
    disablePadding: false,
    label: 'Classification Phase',
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          User: props.email,
          Lens: props.lensSelected,
          Country_Name: props.wsoData.country,
          Category_Name: props.wsoData.category,
          Redirected_From: 'WSO Table',
          Trend_Name: props.wsoData.trends.filter(
            (trend) => trend.trend_id === ampiUserData.trendId,
          )[0].name,
          Maturity_Phase: props.wsoData.trends.filter(
            (trend) => trend.trend_id === ampiUserData.trendId,
          )[0].classification,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
      default:
        amplitudeUserData = {
          User: props.email,
          Lens: props.lensSelected,
          Country_Name: props.wsoData.country ? props.wsoData.country : null,
          Category_Name: props.wsoData.category ? props.wsoData.category : null,
          Sub_Category: props.Sub_Category ? props.Sub_Category : 'NULL',
          Maturity_Phase: props.maturityPhase,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
    }
  } catch (err) {
    console.log(err, 'Amplitude error in WhiteSpaceTable')
  }
}

// TODO use react-window for better performance
const WhiteSpaceTable = (props) => {
  const { email, wsoTableData } = props
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('demand_score')

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    let ampiEvent = `Clicked_WSO_`
    switch (property) {
      case 'year_on_year_growth':
        ampiEvent += 'YoY'
        break
      case 'supply_score':
        ampiEvent += 'SupplyScore'
        break
      case 'demand_score':
        ampiEvent += 'Item_DemandScore'
        break
      case 'name':
        ampiEvent += 'Item_Sort'
        break
      case 'four_year_cagr':
        ampiEvent += 'CAGR'
        break
      case 'food-service_count':
        ampiEvent += 'FSP'
        break
      case 'retail_count':
        ampiEvent += 'Retail'
        break
      case 'classification':
        ampiEvent += 'Classification_Phase'
        break
    }
    amp(props, ampiEvent)
  }
  const viewDetailsClicked = (trendId) => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      trendId,
    }
    amp(props, ampiEvent, ampiUserData)
  }
  let removedColumn = null
  const foodServiceColumnId = 'food-service_count'
  if (props.wsoData.project_variant === 'bpc') {
    const columnIndexToRemove = headCells.findIndex(
      (headCell) => headCell.id === foodServiceColumnId,
    )
    if (columnIndexToRemove !== -1) {
      removedColumn = headCells.splice(columnIndexToRemove, 1)[0]
    }
  }
  if (
    props.wsoData.project_variant === 'food' &&
    headCells.findIndex((headCell) => headCell.id === foodServiceColumnId) ===
      -1
  ) {
    const objToAdd = {
      disablePadding: false,
      id: 'food-service_count',
      label: 'Food Service Products',
      numeric: false,
    }
    headCells.splice(5, 0, objToAdd)
    removedColumn = null
  }
  return (
    <StyledTable>
      <Table stickyHeader aria-label="Trends table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="center"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={handleRequestSort(headCell.id)}
                  sx={{ display: 'flex' }}
                >
                  <Typography
                    variant="subtitle1"
                    color={(theme) => theme.palette.primary.main}
                    textAlign="left"
                    gutterBottom
                  >
                    {headCell.label}
                  </Typography>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody height={30}>
          {wsoTableData &&
            wsoTableData
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <Link
                      target="_blank"
                      underline="none"
                      variant="body2"
                      href={`/mui/details/${props.wsoData.project_id}/${props.lensSelected}/${row.trend_id}`}
                      onClick={() => viewDetailsClicked(row.trend_id)}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        <Box
                          component="img"
                          src={row.image_url}
                          alt={row.name}
                          height={36}
                          width={36}
                          borderRadius={4}
                        />
                        <Typography
                          variant="subtitle2"
                          color={(theam) => theam.palette.primary.main}
                        >
                          {row.name}
                        </Typography>
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>{row.demand_score.toFixed(2)}</TableCell>
                  <TableCell>{row.supply_score.toFixed(2)}</TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      justifyContent="left"
                    >
                      <Typography variant="subtitle2">
                        {getSignedFloat(row.two_year_cagr, 2)}%
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {getSignedFloat(row.four_year_cagr, 2)}%
                  </TableCell>
                  <TableCell align="left">
                    {row['food-service_count']}
                  </TableCell>
                  <TableCell align="left">{row.retail_count}</TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      justifyContent="flex-start"
                      textAlign="left"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 8 8"
                      >
                        <circle
                          id="Ellipse_4611"
                          data-name="Ellipse 4611"
                          cx="4"
                          cy="4"
                          r="4"
                          fill={props.phaseColor[row.classification]}
                        />
                      </svg>
                      <Typography variant="subtitle2">
                        {row.classification}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </StyledTable>
  )
}

WhiteSpaceTable.propTypes = {}
export default WhiteSpaceTable
