import * as React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Button, Box } from '@mui/material'
import { TooltipBox } from './Styled'
import { getSignedFloat } from '../../store/utility'

const TooltipHoverCard = (props) => {
  const {
    left,
    top,
    name,
    demand_score: demandScore,
    supply_score: supplyScore,
    two_year_cagr: twoYearCAGR,
    four_year_cagr: fourYearCagr,
    retail_count: retailCount,
    image_url: imageUrl,
    trend_id: trendId,
  } = props.tooltipShow
  return (
    <Card
      sx={{
        position: 'absolute',
        left: { left },
        top: { top },
        width: 290,
        zIndex: 100,
        '& .MuiPaper-root': { boxShadow: '0px 0px 5px 0px #00000029' },
      }}
    >
      <TooltipBox>
        <Box display="flex">
          <Box>
            <Typography variant="h6">{name}</Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                Demand_score:
              </Box>{' '}
              {demandScore.toFixed(2)}
            </Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                Supply_score:
              </Box>{' '}
              {supplyScore.toFixed(2)}
            </Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                2 year CAGR:&nbsp;
              </Box>
              {getSignedFloat(twoYearCAGR, 2)}%
            </Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                4 year CAGR:&nbsp;
              </Box>
              {getSignedFloat(fourYearCagr, 2)}%
            </Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                Food service products:&nbsp;
              </Box>
              {props.tooltipShow['food-service_count']}
            </Typography>
            <Typography>
              <Box
                component="span"
                color={(theme) => `${theme.palette.text.secondary}`}
              >
                Retail products:&nbsp;
              </Box>
              {retailCount}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{ objectFit: 'cover' }}
              width={65}
              component="img"
              src={imageUrl}
              borderRadius={2}
              height={65}
            ></Box>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={() => props.viewDetailsClicked(trendId)}
        >
          View trend
        </Button>
      </TooltipBox>
    </Card>
  )
}

export default TooltipHoverCard
