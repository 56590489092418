import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModernDatepicker from 'react-modern-datepicker'
import Moment from 'moment'
import { Box } from '@mui/system'
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import { DatePickerTextField } from './styles'

import { dateRange } from '../../const'

const formatDate = 'DD-MMM-YYYY'

class DateRangePickerChart extends Component {
  constructor(props) {
    super(props)
    const graphSelectedEndDate = this.props.graphSelectedEndDate
      ? Moment(this.props.graphSelectedEndDate)
      : dateRange.default.end
    this.state = {
      startDate: dateRange.default4yearsBack.start.isAfter(graphSelectedEndDate)
        ? Moment(graphSelectedEndDate.startOf('month'), 'DD-MMM-YYYY')
        : Moment(this.props.startDate).format('DD-MM-YYYY') ===
          Moment(dateRange.default4yearsBack.start).format('DD-MM-YYYY')
        ? Moment(graphSelectedEndDate).subtract(4, 'years')
        : dateRange.default4yearsBack.start,

      endDate: Moment(
        `${Moment(graphSelectedEndDate).format('YYYY')}${Moment(
          graphSelectedEndDate,
        ).format('MM')}${Moment(graphSelectedEndDate).format('DD')}`,
        'YYYYMMDD',
      ).endOf('month'),
      // endDate: dateRange.default.end,
    }
  }

  /**
   * Change date
   * @param typeDate @{string}
   * @param date @{DD-MMM-YYYY}
   */
  handleChangeDate = (typeDate, date) => {
    // To handle the problem of 'Invalid date labels to appear in the selection drop-down'
    if ('' + date === 'Invalid Date') {
      return
    }

    if (
      typeDate === 'startDate' &&
      Moment(date, formatDate).isAfter(this.state.endDate)
    ) {
      return
    } else if (
      typeDate === 'endDate' &&
      Moment(date, formatDate).isBefore(this.state.startDate)
    ) {
      return
    }
    this.setState({
      [typeDate]: Moment(date, formatDate),
    })
    this.props.onChange(typeDate, Moment(date, formatDate))
  }

  /**
   * Show Calendar
   * @param typeDate @{string}
   */
  handleShowCalendar = (typeDate) => () => {
    document.getElementById(typeDate).focus()
  }

  render() {
    let { startDate, endDate } = this.state

    if (Moment(this.props.graphSelectedEndDate).isBefore(endDate)) {
      endDate = Moment(this.props.graphSelectedEndDate)
    }
    const maxEndDate = Moment(this.props.graphSelectedEndDate).toDate() // this.props.graphEndDate
    const date = Moment(startDate).isAfter(this.props.graphSelectedEndDate)
      ? dateRange.calendarDefault.start
      : startDate

    return (
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <DatePicker
          value={date.format(formatDate)}
          components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
          onChange={(newValue) => this.handleChangeDate('startDate', newValue)}
          renderInput={(params) => (
            <DatePickerTextField {...params} size="large" />
          )}
        />
        <Box
          sx={{
            height: 2,
            width: 8,
            bgcolor: 'icon',
            borderRadius: 4,
          }}
        />
        <DatePicker
          value={endDate.format(formatDate)}
          components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
          onChange={(newValue) => this.handleChangeDate('endDate', newValue)}
          renderInput={(params) => (
            <DatePickerTextField {...params} size="large" />
          )}
        />
      </Box>
    )
  }
}

DateRangePickerChart.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default DateRangePickerChart
