import React from 'react'
import { PopupBox, Boxes } from './styles'
import { Box, Typography, Button, Divider } from '@mui/material'
import throwAwayDocRed from '../../../../assets/images/testImages/throwAwayDocRed.svg'
import crossIcon from '../../../../assets/images/testImages/discardCross.svg'
import { Grid } from 'react-bootstrap'

const DeletePopUp = ({
  textBody = '',
  textHead = '',
  handleCancel,
  handleDelete,
}) => {
  return (
    <PopupBox sx={{ zIndex: 10000 }}>
      <Boxes>
        <Typography
          variant="subtitle1"
          pt={2}
          mb={1}
          color={({ palette }) => palette.error.main}
        >
          {`Delete ${textHead}`}
        </Typography>
        <Box
          component="img"
          top={24}
          right={20}
          position={'absolute'}
          onClick={handleCancel}
          src={crossIcon}
          alt="No img found"
        />
        <Divider orientation="horizontal" sx={{ marginBottom: '25px' }} />
        <Box
          component="img"
          src={throwAwayDocRed}
          alt="Person throwing paper in trash"
        />
        <Typography
          variant="subtitle2"
          pt={1}
          pb={1}
          color={(theme) => theme.palette.text.secondary}
        >
          {textBody}
        </Typography>{' '}
        <Grid container columns={12}>
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ marginLeft: '12px' }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Grid>
      </Boxes>
    </PopupBox>
  )
}

export default DeletePopUp
