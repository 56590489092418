import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ContactUs from './pages/ContactUs'
import * as actions from './store/actions/index'
import { unAuthorizedPages } from './const'
import asyncComponent from './hoc/AsyncComponent/asyncComponent'
import ProjectListsScreen from './components/ProjectsListScreen/ProjectsListScreen'
import ProjectScreen from './components/ProjectScreen/ProjectScreen'
import SWSearchResultsPage from './containers/SWSearchResultsPage/SWSearchResultsPage'
import SWScreenedResultsPage from './containers/SWScreenedResultsPage/SWScreenedResultsPage'
import SWFAQ from './components/SWFAQ/SWFAQ'

import AuthPage from './pages/AuthPage'
import ErrorPage from './pages/ErrorPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import ProfilePage from './pages/ProfilePage'
import TrendSpottingPage from './pages/TrendSpottingPage'
import ProductCategoryPage from './pages/ProductCategoryPage'
import IngredientPage from './pages/IngredientPage'
import ProductItemPage from './pages/ProductItemPage'
import ThemePage from './pages/ThemePage'
import CategoryInsightPage from './pages/CategoryInsightPage'
import CategoryNeedInsightPage from './pages/CategoryNeedInsightPage'
import CategoryTopicInsightPage from './pages/CategoryTopicInsightPage'
import CategoryTopicPage from './pages/CategoryTopicPage'
import ProductFormatPage from './pages/ProductFormatPage'
import ProductSubFormatPage from './pages/ProductSubFormatPage'
import ComparePage from './pages/ComparePage'
import DocsPage from './pages/DocsPage'
import CommonTrendAnalysis from './containers/CommonTrendAnalysis/CommonTrendAnalysis'
import GlobalTrendSearch from './containers/GlobalTrendSearch/GlobalTrendSearch'
import Admin from './containers/Admin/Admin'
import RegionalAnalyticsRouting from './containers/RegionalAnalytics/RegionalAnalyticsRouting'
import { RegistrationFormContainer } from './containers/redux-container'
import LogoutSection from './components/Logout/LogoutSection'
import * as A from './store/actions/UserAction'
import NetworkUtil from './services/networking/NetworkingUtil'
import {
  isProduction,
  isAipaletteUser,
  amplify,
  domUpdateCheck,
} from './store/utility.js'

import PrivateRoute from './privateRoute'
import BannerPrivacyPolicy from './shared/components/BannerPrivacyPolicy'
import BackgroundSpinner from './components/BackgroundSpinner/BackgroundSpinner'
import WhiteSpace from './containers/WhiteSpace/WhiteSpace'
import BookMarkTable from './components/BookMark/BookmarkTable'
import OverlayLoader from './shared/components/OverlayLoader'
import CognitiveSearch from './components/CognitiveSearch'
import BuildWinner from './microfrontend/App'

import DemographicAnalysis from './containers/DemographicAnalysis'
import Dashboard from './components/Dashboard/Dashboard'
import TrendPillar from './components/TrendBucketTable/TrendPillar'
import SearchResultPage from './pages/SearchPage/SearchResultPage'
import SavedWheelPage from './shared/components/WheelView/SavedWheelPage'
import WheelViewPage from './shared/components/WheelView/WheelViewPage'
import EngagementVisualisation from './pages/EngagementVisualisation'
import BackdropLoader from './microfrontend/pages/generic-components/partials/BackdropLoader.js'
const ZEDA_WIDGET_BTN = 'zeda-widget-btn'
const ZEDA_WIDGET_ROOT = 'zeda-widget-root'

const amp = (props, ampiEvent) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: props.loggedIn.user.email,
    }
    amplify(amplitudeEvent, amplitudeUserData, props.loggedIn.user.email)
  } catch (err) {
    console.log(err, 'Amplitude error in App.js')
  }
}

class App extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    loggedInUser: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    loggedIn: PropTypes.shape({
      access_token: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
      refresh_token: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.any,
        PropTypes.oneOf([null]),
      ]),
      role: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
      features: PropTypes.arrayOf(PropTypes.string),
      userDetails: PropTypes.shape({
        id: PropTypes.number,
        userID: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        confirmPassword: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        designation: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        department: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        role: PropTypes.string,
        address: PropTypes.shape({
          addressID: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([null]),
          ]),
          addressType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          addressLine1: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          addressLine2: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          street: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          city: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          state: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          country: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          zipcode: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          email: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          phone1: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          phone2: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          fax: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
          status: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.oneOf([null]),
          ]),
        }),
        organization: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          billing: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          shipping: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          createDate: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          updateDate: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          status: PropTypes.bool,
        }),
        changeCredentials: PropTypes.bool,
        status: PropTypes.bool,
      }),
    }),
    redirect: PropTypes.bool,
  }

  redirectPath = null
  searchParams = null

  clearRedirectionProps() {
    this.redirectPath = null
    this.searchParams = null
  }

  authenticateUser = () => {
    const tokenFromUrl = new URLSearchParams(location.search).get('token') || ''
    if (NetworkUtil.getAuthKey() !== null || tokenFromUrl) {
      this.props.fetchSecretAccessToken().then(() => {
        this.props.getUserProfile().then(() => {
          this.props.setLoadingProfile(false)
        })
      })
    } else {
      this.props.getUserProfile().then(() => {
        this.props.setLoadingProfile(false)
      })
    }
  }

  componentDidMount() {
    this.redirectPath = this.props.location.pathname
    this.searchParams = this.props.location.search
    this.authenticateUser()
    this.props.fetchSubscriptionsDetails()
  }

  /**
   * Redirect user to authorized page when user attempts
   * to access before login
   */
  setRedirectRoute() {
    const redirectPath = this.redirectPath
    const searchParams = this.searchParams
    const enableRedirection = new URLSearchParams(searchParams).get('redirect')
    // Check if trying to access authorized page
    if (
      redirectPath &&
      redirectPath !== '' &&
      redirectPath !== '/' &&
      !Object.keys(unAuthorizedPages).some((route) =>
        redirectPath.includes(unAuthorizedPages[route]),
      )
    ) {
      const redirectionUrl = encodeURIComponent(
        `${redirectPath}${searchParams}`,
      )
      this.props.history.replace(`/?redirect=${redirectionUrl}`)
      this.props.setRedirectionUrl(decodeURIComponent(redirectionUrl))
    }

    // Check if redirection param exists on homepage
    if (enableRedirection && redirectPath === '/') {
      this.props.setRedirectionUrl(decodeURIComponent(enableRedirection))
    }
    this.clearRedirectionProps()
  }

  componentDidUpdate({ location: prevLocation, loggedIn: prevLoggedIn }) {
    const {
      location: { pathname, search },
      loggedInUser,
      user,
    } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search
    const intercomClickHandler = () => {
      const ampiEvent = 'Clicked_Chat_Icon'
      amp(this.props, ampiEvent)
    }
    if (loggedInUser) {
      if (prevLoggedIn && prevLoggedIn.access_token !== loggedInUser) {
        // eslint-disable-next-line
        if (amplitude)
          // eslint-disable-next-line
          amplitude.getInstance().setUserId(this.props.loggedIn.user?.user_id)

        // used for build winner
        const user = this.props.loggedIn.user
        const userId = user?.user_id
        const firstName = user?.firstName
        const lastName = user?.lastName
        const email = user?.email
        const userName = lastName ? `${firstName} ${lastName}` : firstName
        localStorage.setItem('my-secret-key', JSON.stringify(userId))
        const intercomSettings = {
          app_id: process.env.REACT_APP_INTERCOM_KEY,
          name: userName,
          email,
          created_at: Date.now(), // Signup date as a Unix timestamp
        }
        window.Intercom('boot', intercomSettings)
        window.Intercom('onShow', intercomClickHandler)

        // ---Amplitude Start---
        const ampiEvent = 'Clicked_Login'
        const ampiUserData = {
          User: email,
          Login: true,
        }
        amplify(ampiEvent, ampiUserData, email)
        try {
          window.zeda.init({ email: email, name: userName })

          const targetNode = document.querySelector(`div#${ZEDA_WIDGET_ROOT}`)
            .shadowRoot
          domUpdateCheck(targetNode, ZEDA_WIDGET_BTN, 10000, 'attributes')
            .then(() => {
              const zedaBtn = document
                .getElementById(ZEDA_WIDGET_ROOT)
                .shadowRoot.getElementById(ZEDA_WIDGET_BTN)
              zedaBtn.setAttribute('style', `background:#0274CA!important`)
              const zedaWidget = document.getElementById(ZEDA_WIDGET_ROOT)
              zedaWidget.setAttribute(
                'style',
                `z-index:100000;position:absolute`,
              )
            })
            .catch(() => {
              console.log('Zeda button styling change error')
            })

          window.hj('identify', userId, ampiUserData)
        } catch (err) {
          console.log('Analytics Error ', err)
        }

        // ---Amplitude End---
      }

      if (isProduction() && !isAipaletteUser(user.loggedIn.user?.email)) {
        // Google analytics
        // eslint-disable-next-line
        ga('set', 'userId', user.loggedIn.user?.email)
      }
      this.clearRedirectionProps()
    } else {
      window.Intercom('shutdown')
      this.setRedirectRoute()
    }
    if (isDifferentPathname || isDifferentSearch) {
      if (loggedInUser) {
        this.logPageChange(pathname, search, this.props.loggedIn.user?.email)
      } else if (Object.keys(prevLoggedIn).length > 0) {
        // Since user email is cleared on logout we use prevProps
        if (prevLoggedIn && prevLoggedIn?.user) {
          this.logPageChange(pathname, search, prevLoggedIn.user?.email)
        }
      } else {
        // Dummy email to continue logging page change even when user is logged out
        this.logPageChange(pathname, search, 'dummy@aipalette.com')
      }
    }
  }

  logPageChange(pathname, search = '', email) {
    const page = pathname + search
    const { location } = window
    if (isProduction() && !isAipaletteUser(email)) {
      // eslint-disable-next-line
      ga('set', 'page', `${location.origin}${page}`)
      // eslint-disable-next-line
      ga('set', 'location', `${location.origin}${page}`)
      setTimeout(() => {
        // eslint-disable-next-line
        ga('send', 'pageview')
      })
    }
  }

  render() {
    const { loggedInUser } = this.props
    const isAuthUser = loggedInUser
    return this.props.loadingProfile ? (
      <OverlayLoader />
    ) : (
      <>
        <BackdropLoader shouldRenderText={false} />
        <Switch>
          {/* Commented out the below routes as it's not in use */}
          {/* <Route path="/test" component={StylesComponents} /> */}
          {/* <PrivateRoute exact path="/permission" component={PermissionListContainer} /> */}
          {/* <PrivateRoute exact path="/role" component={RoleListContainer} /> */}
          {/* <PrivateRoute exact path="/topic/:id" component={AsyncTopicPage} /> */}
          {/* <PrivateRoute exact path="/user-profile" component={UserProfileContainer} /> */}
          {/* <PrivateRoute exact path="/users" component={UserPageContainer} /> */}
          {/* <PrivateRoute exact path="/organization" component={OrganizationPageContainer} /> */}
          {/* <PrivateRoute exact path="/logout" component={LogoutSection} /> */}
          {/* <PrivateRoute exact path="/build-winner" component={BuildWinnerPageContainer} /> */}
          {/* Commented out the above routes as it's not in use */}

          {/*
          <PrivateRoute exact path="/projectList/:projectId/needs/:needId/" component={AsyncNeedsDetails} />
          <PrivateRoute exact path="/projectList/:projectId/:urlLensSelected(product|ingredient|theme)?/:productId" component={AsyncProductDetails} />
          <PrivateRoute exact path="/projectList/:projectId/:urlLensSelected(ingredient|product|theme)?/:productId/:needType(cafe\-format|retail\-format)?" component={AsyncProductNeedsDetails} />
          <PrivateRoute exact path="/projectList/:projectId/:urlLensSelected(ingredient|product|theme)?/:productId/:needType(need)?/:needId" component={AsyncProductNeedsDetails} />
          <PrivateRoute exact path="/productComparison" component={ProductComparisionDetails} />
          <PrivateRoute exact path="/projectList/:projectId" component={AsyncProjectListBuilder} />
          <PrivateRoute exact path="/requestTrends" component={AsyncTrendRequestBuilder} />
          <PrivateRoute exact path="/search/:query" component={SearchResultsContainer} />
          <PrivateRoute exact path="/search/:projectId/:query" component={SearchProjectContainer} />
          <PrivateRoute exact path="/trend-spotting" component={TrendRequester} />
          */}
          <PrivateRoute
            exact
            path="/mui/terms-of-service"
            component={TermsOfService}
          />
          <PrivateRoute
            exact
            path="/mui/privacy-policy"
            component={PrivacyPolicy}
          />
          {/* <PrivateRoute exact path="/projectList/:projectId/needs/:needId/topic/:topiisAuthUsercId" component={AsyncTopicPage} /> */}
          {/* <PrivateRoute exact path="/projectList/:projectId/:urlLensSelected(product|ingredient|theme)?/:productId/:needType(cafe\-format|retail\-format)?/topic" component={AsyncProductTopicPage} /> */}
          {/* <PrivateRoute exact path="/projectList/:projectId/:urlLensSelected(product|ingredient|theme)?/:productId/:needType(need)?/:needId/topic/:topicId" component={AsyncProductTopicPage} /> */}
          {/* <PrivateRoute exact path="/viewTrendReport/:report" component={AsyncViewTrendReport} /> */}
          <PrivateRoute exact path="/logout" component={LogoutSection} />
          <PrivateRoute exact path="/mui/contact-us" component={ContactUs} />
          <PrivateRoute exact path="/mui/docs" component={DocsPage} />
          {/* <PrivateRoute exact path="/faq" component={FAQ} /> */}
          {/* {!aipaletteUser &&
            <Route
              path="/mui/*"
              render={() => {
                if (isAuthUser) {
                  return <Redirect to="/trend-spotting"/>
                }
                return <AuthPage />
              }}
            />
          }
          {hideSW &&
            <Route
              path="/screen-winner*"
              render={() => {
                if (isAuthUser) {
                  return <Redirect to="/trend-spotting"/>
                }
                return <AuthPage />
              }}
            />
          } */}
          {/* <PrivateRoute exact path="/mui/bookmark" component={BookMarkTable} /> */}
          <PrivateRoute
            exact
            path="/mui/trend-pillar"
            component={TrendPillar}
          />
          <PrivateRoute
            exact
            path="/mui/trend-pillar/saved-wheels"
            component={SavedWheelPage}
          />
          <PrivateRoute
            exact
            path="/mui/trend-pillar/saved-wheels/:wheelId(\d+)"
            component={WheelViewPage}
          />
          <PrivateRoute exact path="/mui/profile" component={ProfilePage} />
          <PrivateRoute
            exact
            path="/mui/main-system"
            component={TrendSpottingPage}
          />
          <PrivateRoute
            exact
            path="/mui/cognitive-search"
            component={CognitiveSearch}
          />
          <PrivateRoute
            exact
            path="/mui/product-category/:projectId(\d+)"
            component={ProductCategoryPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/:urlLensSelected(ingredient)/:productId(\d+)"
            component={IngredientPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/:urlLensSelected(product)/:productId(\d+)"
            component={ProductItemPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/:urlLensSelected(theme)/:productId(\d+)"
            component={ThemePage}
          />
          <PrivateRoute
            exact
            path="/mui/details/productFormat/:projectId(\d+)/:urlLensSelected(ingredient|product|theme)?/:productId(\d+)/:productType/:formatName"
            component={ProductFormatPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/productFormat/:projectId(\d+)/:urlLensSelected(ingredient|product|theme)?/:productId(\d+)/:productType/:formatName/subFormat/:subProductType/"
            component={ProductSubFormatPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/category/:needId(\d+)"
            component={CategoryInsightPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/:urlLensSelected(ingredient|product|theme)?/:productId(\d+)/need/:needId(\d+)"
            component={CategoryNeedInsightPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/:urlLensSelected(ingredient|product|theme)?/:productId(\d+)/need/:needId(\d+)/topic/:topicId(\d+)"
            component={CategoryTopicInsightPage}
          />
          <PrivateRoute
            exact
            path="/mui/details/:projectId(\d+)/category/:needId(\d+)/topic/:topicId(\d+)"
            component={CategoryTopicPage}
          />
          <PrivateRoute
            exact
            path="/mui/search/:projectId(\d+)/:search"
            component={SearchResultPage}
          />
          <PrivateRoute exact path="/mui/compare" component={ComparePage} />
          <PrivateRoute
            screenWinner
            exact
            path="/screen-winner"
            component={ProjectListsScreen}
          />
          <PrivateRoute
            screenWinner
            exact
            path="/screen-winner/projects/:projectId(\d+)/concepts"
            component={ProjectScreen}
          />
          <PrivateRoute
            screenWinner
            exact
            path="/screen-winner/projects/:projectId(\d+)/screened-results"
            component={SWScreenedResultsPage}
          />
          <PrivateRoute exact path="/mui/white-space" component={WhiteSpace} />
          <PrivateRoute exact path="/mui/workspace" component={Dashboard} />
          <PrivateRoute
            exact
            path="/mui/universal-trend-search"
            component={GlobalTrendSearch}
          />
          <PrivateRoute
            exact
            path="/mui/demographic-analytics"
            component={DemographicAnalysis}
          />

          <PrivateRoute exact path="/mui/admin" component={Admin} />
          <PrivateRoute
            exact
            path="/mui/regional-analytics"
            component={RegionalAnalyticsRouting}
          />
          <PrivateRoute
            screenWinner
            exact
            path={['/screen-winner/faq', '/mui/faq']}
            component={SWFAQ}
          />
          <PrivateRoute
            exact
            path="/mui/engagement-visualisation/:projectId(\d+)?"
            component={EngagementVisualisation}
          />
          <PrivateRoute
            screenWinner
            exact
            path={[
              '/screen-winner/search-results/:query',
              '/screen-winner/projects/:projectId/search-results/:query',
            ]}
            component={SWSearchResultsPage}
          />
          <PrivateRoute
            exact
            path="/mui/common-trend-analysis"
            component={CommonTrendAnalysis}
          />
          <PrivateRoute
            exact
            path="/mui/concept-genie"
            component={BuildWinner}
          />
          <Route
            path="/"
            exact
            render={(props) => {
              if (isAuthUser) {
                return <Redirect to="/mui/main-system" />
              }
              return <AuthPage />
            }}
          />
          <Route
            path={unAuthorizedPages.REGISTRATION}
            exact
            render={(props) => {
              if (isAuthUser) {
                return <Redirect to="/mui/main-system" />
              }
              return <RegistrationFormContainer />
            }}
          />
          <Route
            path={unAuthorizedPages.FORGOT_PASSWORD}
            exact
            render={(props) => {
              if (isAuthUser) {
                return <Redirect to="/mui/main-system" />
              }
              return <ForgotPasswordPage />
            }}
          />
          <Route
            path={`${unAuthorizedPages.RESET_PASSWORD}:token`}
            exact
            render={(props) => {
              if (isAuthUser) {
                return <Redirect to="/mui/main-system" />
              }
              return <ResetPasswordPage />
            }}
          />
          <Route path={unAuthorizedPages.FOUR_O_FOUR} component={ErrorPage} />
          <Redirect from="*" to={unAuthorizedPages.FOUR_O_FOUR} />
        </Switch>
        {isAuthUser && <BannerPrivacyPolicy />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.user.loggedIn.access_token,
  loggedIn: state.user.loggedIn,
  redirect: state.user.redirect,
  user: state.user,
  acceptedPolicy:
    Object.keys(state.user.loggedIn).length > 0
      ? state.user.loggedIn.accepted_policy
      : null,
  acceptedUpdatedPolicy:
    Object.keys(state.user.loggedIn).length > 0
      ? state.user.loggedIn.accepted_updated_policy
      : null,
  loadingProfile: state.user.loadingProfile,
})

const mapDispatchToProps = (dispatch) => ({
  fetchSecretAccessToken: () => dispatch(A.fetchSecretAccessToken()),
  getUserProfile: () => dispatch(A.loginUserGet()),
  setLoadingProfile: () => dispatch(A.setLoadingProfile()),
  setRedirectionUrl: (url) => dispatch(A.setRedirectionUrl(url)),
  fetchSubscriptionsDetails: () =>
    dispatch(actions.fetchSubscriptionsDetails()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
