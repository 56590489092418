import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export const getColoredLabel = (label, color) => {
  return (
    <Typography
      variant="caption"
      sx={{
        color: color,
        display: 'inline',
      }}
    >
      {label}
    </Typography>
  )
}

const SaveAsFavoritePopUpUI = ({
  open,
  handleClose,
  favoriteName,
  handleClickFavorites,
  error,
  handleChangeFavoriteName,
}) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            width: '100%',
          }}
        >
          <Box display="flex">
            <ClearIcon
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.primary.dark,
                cursor: 'pointer',
              }}
              fontSize="0.75rem"
            />
          </Box>
          <Typography variant="h3">Save this Favorite as</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            variant="outlined"
            placeholder="Favorite Name"
            sx={{ width: '315px !important' }}
            onChange={handleChangeFavoriteName}
            value={favoriteName}
          />
          {error ? (
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: ({ palette }) => palette.error.main,
                paddingTop: '10px',
              }}
            >
              Special characters are NOT allowed.
            </Typography>
          ) : (
            <Typography
              display="block"
              sx={{ width: '315px', paddingTop: '10px' }}
              variant="caption"
            >
              Note:{' '}
              {getColoredLabel(
                '(!@#$%^*,.?/“:’;;+=-)',
                ({ palette }) => palette.error.main,
              )}{' '}
              special characters are{' '}
              {getColoredLabel('NOT', ({ palette }) => palette.error.main)}{' '}
              allowed.
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            px: 2,
            pr: '24px !important',
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleClickFavorites}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default SaveAsFavoritePopUpUI
