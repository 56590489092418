import React, { useEffect, useState } from 'react'
import {
  TableCell,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip,
} from '@mui/material'
import { Circle } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import formatNumber from '../../shared/utils/formatNumber'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Link from '@mui/material/Link'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { amplify } from '../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { useSingleSnackBar } from '../../hooks'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()

    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      Lens: ampiUserData.lens,
      Maturity_Phase: ampiUserData.maturityPhase,
      Redirected_From: ampiUserData.redirectedFrom,
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Cell Function')
  }
}

export const maturityPhaseCell = (maturityPhase) => {
  return (
    <TableCell sx={{ border: 'none !important' }}>
      <Circle
        sx={{
          width: '13px',
          color: ({ palette: { colors } }) =>
            colors[(maturityPhase || 'DORMANT').toLowerCase()],
          verticalAlign: 'middle',
        }}
      />
      {maturityPhase}
    </TableCell>
  )
}

export const bucketNameCell = (data) => {
  return (
    <TableCell align="right" sx={{ border: 'none !important' }}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="subtitle2">{data}</Typography>
      </Box>
    </TableCell>
  )
}
// export const arrowIcon = (isExpanded) => {
export const arrowIcon = (
  isExpanded,
  row,
  handleCheckBoxClick,
  showCheckBox,
) => {
  return (
    <TableCell>
      <IconButton aria-label="expand row" size="small" data-testId="icon">
        {isExpanded ? (
          <>
            <ExpandMoreIcon fontSize="medium" />
            {showCheckBox && (
              <Checkbox onChange={(e) => handleCheckBoxClick(e, row)} />
            )}
          </>
        ) : (
          <>
            <NavigateNextIcon fontSize="medium" />
            {showCheckBox && (
              <Checkbox onClick={(e) => handleCheckBoxClick(e, row)} />
            )}
          </>
        )}
      </IconButton>
    </TableCell>
  )
}

export const formatDataPoints = (val) => {
  return (
    <TableCell align="left" sx={{ border: 'none !important' }}>
      {formatNumber(val)}
    </TableCell>
  )
}

export const roundOffFloat = (val) => {
  return (
    <TableCell align="left" sx={{ border: 'none !important' }}>
      {val.toFixed(2)} %
    </TableCell>
  )
}

export const returnAsItIs = (data) => {
  return (
    <TableCell
      sx={{ paddingLeft: '0px !important', border: 'none !important' }}
    >
      {data}
    </TableCell>
  )
}

const handleTrendClick = ({
  country,
  category,
  lensSelected,
  maturity_Phase: maturityPhase,
}) => {
  const ampiEvent = 'Clicked_Trend_Lens'
  const ampiUserData = {
    country,
    category,
    lens: lensSelected,
    maturityPhase,
    redirectedFrom: 'Trend Pillar',
  }
  amp(ampiEvent, ampiUserData)
}

export const returnTrends = (data) => {
  return (
    <>
      <TableCell sx={{ border: 'none !important' }}>
        {' '}
        <Link
          target="_blank"
          underline="none"
          onClick={() => handleTrendClick(data)}
          sx={{
            color: ({ palette }) => `${palette.primary.main} !important`,
            textDecorationColor: 'none',
            textDecorationLine: 'none',
            fontWeight: 500,
            fontSize: '0.875rem',
            '&:visited': {
              color: ({ palette }) => `${palette.primary.main} !important`,
            },
            '&:hover': {
              color: ({ palette }) => `${palette.black} !important`,
            },
          }}
          href={`/mui/details/${data.projectId}/${data.lensSelected}/${data.id}`}
        >
          {data.trend_Name}
        </Link>
      </TableCell>
    </>
  )
}

export const RenderActionIcon = ({
  row,
  handleDelete,
  handleEdit,
  handleDownload,
  handleOpen,
  lensSelected,
  addToFavoriteTrendPillar,
  addedFavTrendPillar,
  fetchAddedFavChecktrendPillar,
  removeFromFavoritesTrendPillar,
  setLoading,
  loading,
  isTrendPillarFavoriteAllowed,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    fetchAddedFavChecktrendPillar(row.id, lensSelected, row.projectId)
  }
  const handleClickAction = (event) => {
    event.stopPropagation()
  }

  const handleClickEdit = () => {
    handleEdit(row.pillarName, row.country, row.category, row.trends, row.id)
    handleOpen(false)
  }

  const handleAddToFavoriteClick = (
    id,
    lensSelected,
    setLoading,
    projectId,
  ) => {
    if (!loading) {
      if (addedFavTrendPillar) {
        removeFromFavoritesTrendPillar(id, lensSelected, setLoading, projectId)
      } else {
        setLoading(true)
        addToFavoriteTrendPillar(id, lensSelected, projectId).then((res) => {
          setLoading(false)
          if (res === 403) {
            const action = () => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => removeSnackBar()}
              >
                <CloseIcon fontSize="small" color="action" />
              </IconButton>
            )
            showSnackBar(
              'Only 10 Trend Pillars can be Favorited',
              'info',
              action,
            )
          } else {
            fetchAddedFavChecktrendPillar(id, lensSelected, projectId)
          }
        })
      }
    }
  }

  useEffect(() => {
    return () => {
      removeSnackBar()
    }
  }, [])

  return (
    <TableCell onClick={handleClickAction}>
      <MoreHorizIcon
        sx={{ color: ({ palette }) => palette.icon }}
        onClick={handleClick}
      />
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 170,
          },
        }}
      >
        <MenuItem
          sx={{
            fontSize: '14px !important',
            color: ({ palette }) => palette.primary.main,
          }}
          onClick={handleClickEdit}
        >
          {' '}
          <EditOutlinedIcon
            sx={{
              color: ({ palette }) => `${palette.primary.main} !important`,
            }}
          />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => handleDownload(row.id, row.country, row.category)}
          sx={{
            fontSize: '14px !important',
            color: ({ palette }) => palette.primary.main,
          }}
        >
          {' '}
          <FileDownloadOutlinedIcon
            sx={{
              color: ({ palette }) => `${palette.primary.main} !important`,
            }}
          />
          Download
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleDelete(row.id, row.pillarName, row.country, row.category)
          }
          sx={{
            fontSize: '14px !important',
            marginBottom: '0px !important',
            color: ({ palette }) => `${palette.error.dark} !important`,
          }}
        >
          <DeleteOutlinedIcon
            sx={{
              color: ({ palette }) => `${palette.error.dark} !important`,
            }}
          />
          Delete
        </MenuItem>
        {isTrendPillarFavoriteAllowed && (
          <Tooltip
            title={`Add${
              addedFavTrendPillar ? 'ed' : ''
            } to Trend Pillar Section under Favorites in My Workspace`}
          >
            <MenuItem
              onClick={() =>
                handleAddToFavoriteClick(
                  row.id,
                  lensSelected,
                  setLoading,
                  row.projectId,
                )
              }
            >
              {addedFavTrendPillar ? (
                <StarIcon
                  sx={{
                    color: ({ palette }) =>
                      `${palette.primary.main} !important`,
                    marginRight: '5px',
                  }}
                />
              ) : (
                <StarBorderOutlinedIcon
                  sx={{
                    marginRight: '6px',
                    color: ({ palette }) => palette.text.secondary,
                  }}
                />
              )}

              <Typography
                sx={{
                  color: ({ palette }) => `${palette.primary.main} !important`,
                  fontSize: '14px !important',
                }}
              >
                Add{addedFavTrendPillar && 'ed'} to Favorites
              </Typography>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
    </TableCell>
  )
}
