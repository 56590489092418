import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Stack,
} from '@mui/material'
import { Box } from '@mui/system'
import ConsumerInsightsItem from '../ConsumerInsightsItem'

import ConsumerInsightsCardContainer from './styles'
import InformationIcon from '../InformationIcon'
import { amplify } from './../../../store/utility'

import { ReactComponent as EmptySpaceFoodCupSvg } from '../../../assets/emptySpaceFoodCup.svg'

const sumArr = (arr) => {
  const sum = arr.reduce((acc, curr) => curr.volume + acc, 0)
  return arr.map((item) => ({ ...item, sum }))
}

const ConsumerInsightsCard = (props) => {
  let needCategories = props.needCategories
  if (isEmpty(needCategories)) return null
  const [catNeedIndex, setCatNeedIndex] = React.useState(0)
  const [catNeedSortBy, setCatNeedSortBy] = React.useState('volume')
  const handleSelectChange = (event) => {
    setCatNeedSortBy(event.target.value)
    if (!props.type) {
      const ampiEvent = 'Clicked_Category_Consumer_Insights_Sort'
      const ampiUserData = {
        User: props.email,
        Project_Need_Sorting: event.target.value,
        Country_Name: props.country,
        Category_Name: props.category,
      }
      amplify(ampiEvent, ampiUserData, props.email)
    }
  }

  const buildLink = (projectId, mappingId) => {
    if (props.type === 'regionalAnalytics') {
      return `?projectId=${encodeURIComponent(
        projectId,
      )}&lens=${encodeURIComponent(props.lens)}&needId=${encodeURIComponent(
        mappingId,
      )}&region=${encodeURIComponent(props.region)}`
    }
    return `/mui/details/${projectId}/category/${mappingId}`
  }

  const consumerInsightClicked = (keyword) => {
    if (props.type === 'regionalAnalytics') {
      if (props.logConsumerInsightClick) {
        try {
          props.logConsumerInsightClick(
            needCategories[catNeedIndex].category,
            keyword,
          )
        } catch (err) {
          console.error(err, 'Amp: Error logging consumer insight clicked!')
        }
      }
    } else {
      const ampiEvent = `Clicked_Consumer_${needCategories[catNeedIndex].category}_Insights`
      const ampiUserData = {
        User: props.email,
        [`${needCategories[catNeedIndex].category}_Name`]: keyword,
        Category_Name: props.category,
        Country_Name: props.country,
      }

      amplify(ampiEvent, ampiUserData, props.email)
    }
  }

  // Hide Expressed Persona for Baby Care
  if (props.category === 'Baby Care') {
    needCategories = needCategories.filter(
      (categories) => categories.category !== 'Expressed Persona',
    )
  }

  return (
    <ConsumerInsightsCardContainer>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            flexGrow: 1,
          }}
        >
          <Typography variant="h3">Consumer Insights</Typography>
          <InformationIcon tooltipText="Category level consumer insights based on the organic conversations online; conversations mapped and identified to each topic." />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" textOverflow="unset" noWrap>
            Sort by:
          </Typography>
          <FormControl variant="outlined">
            <Select defaultValue="volume" onChange={handleSelectChange}>
              <MenuItem value="volume">Volume</MenuItem>
              <MenuItem value="twoYearCagr">2 Year CAGR</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ py: 2 }}>
        <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1.5 }}>
          Category level consumer insights.
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {needCategories.map((x, i) =>
            ['Pairing', 'Barrier'].includes(x.category) ? null : (
              <Chip
                key={i}
                variant="outlined"
                label={x.category}
                isSelected={i == catNeedIndex}
                onClick={(e) => {
                  setCatNeedIndex(i)
                  if (props.type === 'cognitiveSearch') {
                    props.logConsumerInsightChipClick(x)
                  } else {
                    const ampiEvent = 'Clicked_Category_Consumer_Insights'
                    const ampiUserData = {
                      User: props.email,
                      ' Project_Need_Category': x.category,
                      Category_Name: props.category,
                      Country_Name: props.country,
                    }
                    amplify(ampiEvent, ampiUserData, props.email)
                  }
                }}
              />
            ),
          )}
        </Box>
      </Box>
      <Grid container spacing={1}>
        {!needCategories[catNeedIndex].categorized_needs.length ? (
          <Stack
            sx={{
              borderRadius: 1,
              border: ({ palette }) => `1px solid ${palette.divider}`,
              height: 280,
              marginLeft: 1.25,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EmptySpaceFoodCupSvg width="100" height="100" />
            <Typography
              align="center"
              pt={1.25}
              px={1}
              variant="subtitle1"
              color="textSecondary"
            >
              Insufficient data to display for{' '}
              {needCategories[catNeedIndex].category}
            </Typography>
          </Stack>
        ) : (
          sumArr(needCategories[catNeedIndex].categorized_needs)
            .sort((a, b) => {
              if (catNeedSortBy === 'twoYearCagr') {
                return b.two_year_cagr - a.two_year_cagr
              }
              return b.volume - a.volume
            })
            .map(
              ({
                mappingID,
                keyword,
                sum,
                volume,
                iconURI,
                two_year_cagr: twoYearCagr,
              }) =>
                volume > 0 ? (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={mappingID}>
                    {props.disableCardsClick ? (
                      <Box>
                        <ConsumerInsightsItem
                          title={keyword}
                          score={(volume / sum) * 100}
                          iconUri={iconURI}
                          twoYearCagr={twoYearCagr}
                          disableCardsClick={props.disableCardsClick}
                          lensSelected={props.lensSelected}
                        />
                      </Box>
                    ) : (
                      <Link
                        target="_blank"
                        to={() => buildLink(props.projectid, mappingID)}
                        onClick={() => consumerInsightClicked(keyword)}
                      >
                        <ConsumerInsightsItem
                          title={keyword}
                          score={(volume / sum) * 100}
                          iconUri={iconURI}
                          twoYearCagr={twoYearCagr}
                          disableCardsClick={props.disableCardsClick}
                          lensSelected={props.lensSelected}
                        />
                      </Link>
                    )}
                  </Grid>
                ) : null,
            )
        )}
      </Grid>
    </ConsumerInsightsCardContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default withRouter(connect(mapStateToProps, null)(ConsumerInsightsCard))
