import { BookmarkActionTypes } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchBookmarks = () => {
  return (dispatch) => {
    const request = NetworkUtil.getBookmarkedData()
    axios
      .get(request)
      .then((response) => {
        const bookmarked = response.data.data

        dispatch(fetchBookmarkSuccess(bookmarked))
      })
      .catch((error) => {
        dispatch(fetchBookmarkFailed(error))
      })
  }
}

const fetchBookmarkSuccess = (bookmarked) => {
  return {
    type: BookmarkActionTypes.type[BookmarkActionTypes.FETCH_BOOKMARK_SUCCESS],
    bookmarked: bookmarked,
  }
}

const fetchBookmarkFailed = (error) => {
  return {
    type: BookmarkActionTypes.type[BookmarkActionTypes.FETCH_BOOKMARK_FAILED],
    error: error,
  }
}

export const isCheckedBookmarked = (lens, trendId) => {
  return (dispatch) => {
    const request = NetworkUtil.getBookmarkedCheckedData(lens, trendId)
    axios
      .get(request)
      .then((response) => {
        if (response && response.data.data) {
          dispatch(
            fetchIsCheckedBookmarkSuccess(
              response.data.data.bookmarkedTrend.id,
            ),
          )
        } else {
          dispatch(fetchIsCheckedBookmarkSuccess(false))
        }
      })
      .catch((error) => {
        dispatch(fetchIsCheckedBookmarkFailed(error))
      })
  }
}

const fetchIsCheckedBookmarkSuccess = (checked) => {
  return {
    type:
      BookmarkActionTypes.type[
        BookmarkActionTypes.FETCH_CHECKED_BOOKMARK_SUCCESS
      ],
    isChecked: checked,
  }
}

const fetchIsCheckedBookmarkFailed = (error) => {
  return {
    type:
      BookmarkActionTypes.type[
        BookmarkActionTypes.FETCH_CHECKED_BOOKMARK_FAILED
      ],
    error: error,
  }
}

export const removeBookmarked = (removeId) => {
  return async (dispatch) => {
    const request = NetworkUtil.getRemoveBookmark(removeId)
    await axios
      .delete(request)
      .then((response) => {
        dispatch(fetchRemoveBookmarkSuccess())
      })
      .catch((error) => {
        dispatch(fetchRemoveBookmarkFailed(error))
      })
  }
}

const fetchRemoveBookmarkSuccess = () => {
  return {
    type:
      BookmarkActionTypes.type[
        BookmarkActionTypes.FETCH_REMOVE_BOOKMARK_SUCCESS
      ],
  }
}

const fetchRemoveBookmarkFailed = (error) => {
  return {
    type:
      BookmarkActionTypes.type[
        BookmarkActionTypes.FETCH_REMOVE_BOOKMARK_FAILED
      ],
    error: error,
  }
}

export const bookmarkedSwingChange = (swing) => {
  return (dispatch) => {
    dispatch(setBookmarkSwing(swing))
  }
}

const setBookmarkSwing = (swing) => {
  return {
    type: BookmarkActionTypes.type[BookmarkActionTypes.SET_BOOKMARK_SWING],
    isBookmarkSwing: swing,
  }
}
