import typography from '../typography';
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      padding: 8,
      ...typography.subtitle2,
      '& >.MuiSvgIcon-root': {
        color: palette.icon,
        marginRight: 8,
        height: 20,
        width: 20,
      },
      '&:hover': {
        backgroundColor: palette.background.default,
        '& >.MuiSvgIcon-root': {
          color: palette.secondary.main,
        },
      },
      '&:active': {
        backgroundColor: palette.grey[200],
      },
      '&.Mui-selected': {
        backgroundColor: palette.grey[200],
      },
      '&.Mui-selected.Mui-focusVisible': {
        backgroundColor: palette.grey[200],
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        fontWeight: 400,
        fontSize: '0.75rem',
        letterSpacing: '0.03333em',
        lineHeight: 1.17,
      },
    },
  ],
};

