import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import ErrorStateConceptGenieImg from '../../../assets/images/concept_genie_error_state.png'

export const ErrorStateConceptGenieText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '24px',
  fontWeight: '400',
  margin: '24px 0px',
}))

const ErrorStateConceptGenie = (props) => {
  return (
    <Grid container justifyContent={'center'} spacing={2} textAlign={'center'}>
      <Grid item xs={12}>
        <img src={ErrorStateConceptGenieImg} height={300} />
      </Grid>

      <Grid item xs={12}>
        <ErrorStateConceptGenieText>
          "Uh-oh, it looks like we hit a snag. We're working on fixing it,
          please come back soon!"
        </ErrorStateConceptGenieText>
      </Grid>
    </Grid>
  )
}

export default ErrorStateConceptGenie
