import React from 'react'
import Multiselect from 'react-widgets/lib/Multiselect'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'
import { Link } from 'react-router-dom'
/*
Form Field components
 */
export const renderTextArea = ({
  input,
  label,
  placeholder,
  disabled = false,
  rows,
  hoverEnabledMsg,
  hoverDisabledMsg,
  meta: { touched, error, warning },
}) =>
  <div className="form-group">
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
    <textarea rows={rows} {...input} placeholder={placeholder} disabled={disabled}
      className={'form-control hoverToolTip ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))}></textarea>
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && <span className="disabledMsg">{hoverDisabledMsg}</span>}
  </div>

export const renderTextAreaIdeaDesc = ({
  input,
  label,
  placeholder,
  disabled = false,
  rows,
  hoverEnabledMsg,
  hoverDisabledMsg,
  meta: { touched, error, warning },
}) =>
  <div className="">
    <textarea rows={rows} {...input} placeholder={placeholder} disabled={disabled}
      className={'form-control hoverToolTip ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))}></textarea>
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && <span className="disabledMsg">{hoverDisabledMsg}</span>}
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
  </div>

export const renderTextAreaForBuildWinner = ({
  input,
  label,
  placeholder,
  disabled = false,
  rows,
  hoverEnabledMsg,
  hoverDisabledMsg,
  meta: { touched, error, warning },
}) =>
  <div className="">
    <textarea rows={rows} {...input} placeholder={placeholder} disabled={disabled}
      className={'form-control hoverToolTip ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))}></textarea>
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && <span className="disabledMsg">{hoverDisabledMsg}</span>}
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
  </div>

export const renderField = (option) => {
  const {
    input,
    label,
    readOnly,
    type,
    placeholder,
    meta: { touched, error, warning },
    autoComplete = 'on',
    hidden,
  } = option
  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
      }</label>
      <input {...input} autoComplete={autoComplete} readOnly={readOnly} placeholder={placeholder} type={type}
        className={'form-control ' + (hidden && 'hidden ') + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))} />
    </div>)
}
export const renderFieldPasswordWithForgot = (option) => {
  const {
    input,
    label,
    readOnly,
    type,
    placeholder,
    meta: { touched, error, warning },
    autoComplete = 'on',
  } = option
  return (
    <div className="form-group">
      <div className='under-password'>
        <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
        }</label>
        <Link to={'/forgot-password'}>Forgot password?</Link>
      </div>
      <input {...input} autoComplete={autoComplete} readOnly={readOnly} placeholder={placeholder} type={type}
        className={'form-control ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))} />
    </div>)
}
export const renderFieldPassword = (option) => {
  const {
    input,
    label,
    readOnly,
    type,
    placeholder,
    meta: { touched, error, warning },
    autoComplete = 'on',
  } = option

  const copyInput = { ...input }

  const oldValue = copyInput.value.replace(/\s+/g, '')
  let newValue = copyInput.value

  if (oldValue.length > 3) { newValue = oldValue.slice(0, 3) + ' ' + oldValue.slice(3) }

  copyInput.value = newValue

  return (
    <div className="form-group">
      <label htmlFor={copyInput.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
      }</label>
      <input {...copyInput} autoComplete={autoComplete} readOnly={readOnly} placeholder={placeholder} type={type}
        className={'form-control ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))} />
    </div>)
}

export const renderSelect = ({
  input,
  onChange,
  label,
  options,
  readOnly = false,
  noneOptionText = 'Select ...',
  showNoneOption = true,
  displayField = 'name',
  disabled = false,
  valueField = 'id',
  hoverEnabledMsg,
  hoverDisabledMsg,
  meta: { touched, error, warning },
}) => {
  return <div className="form-group" style={{ position: 'relative' }}>
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
    <select {...input}
      className={'form-control hoverTooltip ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))}
      disabled={disabled} readOnly={readOnly}>
      {showNoneOption && <option value="">{noneOptionText}</option>}
      {
        options && options.map(option =>
          <option value={option[valueField]} key={option[valueField]}>{option[displayField]}</option>
        )
      }
    </select>
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && disabled && <span className="disabledMsg">{hoverDisabledMsg}</span>}
  </div>
}

export const renderMultiselectForBuildWinner = ({
  input, placeholder, data, valueField, textField, label, className, meta: { touched, error, warning }, hoverEnabledMsg,
  hoverDisabledMsg,
}) =>
  <div className="">
    <Multiselect {...input}
      placeholder={placeholder}
      className={(touched && ((!error && 'valid-field') || (error && 'invalid-field')))}
      onBlur={() => input.onBlur()}
      value={input.value || []} // requires value to be an array
      data={data}
      valueField={valueField}
      textField={textField}
      containerClassName={className}
    />
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && <span className="disabledMsg">{hoverDisabledMsg}</span>}
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
  </div>

export const renderSelectFoodCategory = ({
  input,
  onChange,
  label,
  options,
  readOnly = false,
  noneOptionText = 'Food Category & Geography ',
  showNoneOption = true,
  displayField = 'name',
  disabled = false,
  valueField = 'id',
  hoverEnabledMsg,
  hoverDisabledMsg,
  meta: { touched, error, warning },
}) => {
  return <div className="form-group" style={{ position: 'relative' }}>
    <label htmlFor={input.name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
    <select {...input}
      className={'form-control hoverTooltip ' + (touched && ((!error && 'valid-field') || (error && 'invalid-field')))}
      disabled={disabled} readOnly={readOnly}>
      {showNoneOption && <option value="">{noneOptionText}</option>}
      {
        options && options.map(option =>
          <option value={option[valueField]} key={option[valueField]}>{option[displayField]}</option>
        )
      }
    </select>
    {hoverEnabledMsg && <span className="enabledMsg">{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && disabled && <span className="disabledMsg">{hoverDisabledMsg}</span>}
  </div>
}

const ListItem = ({ item }) => {
  return (
    <>
      <span className='fu-circle' style={{ backgroundColor: item.color }}/>
      <span>{item.display}</span>
    </>
  )
}

export const renderMultiSelect = ({
  input,
  data,
  valueField = '_id',
  disabled = false,
  textField = 'name',
  label,
  placeholder,
  className,
  meta: { touched, error, warning },
}) => {
  return <div className={'form-group ' + className}>
    <label htmlFor={input.name}>{label}{touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))
    }</label>
    <Multiselect {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []} // requires value to be an array
      data={data}
      disabled={disabled}
      valueField={valueField}
      label={label}
      textField={textField}
      placeholder={placeholder}
      tagComponent={ListItem}
    />
  </div>
}

export const renderDateTimePickerString = ({
  input: { onChange, value, name, onBlur },
  label,
  readOnly,
  dropUp = false,
  info,
  showTime = false,
  showCalendar = true,
  min,
  max,
  disabled = false,
  currentDate,
  meta: { touched, error, warning },
  hoverEnabledMsg,
  hoverDisabledMsg,
  displayFormat = 'DD/MM/YYYY',
  valueFormat = 'YYYY-MM-DD',
  containerClassName,
  className,

}) => {
  let val
  if (value) {
    val = moment(value, valueFormat).isValid() ? moment(value, valueFormat).toDate() : undefined
  }

  const parse = event => {
    if (event) {
      if (showCalendar) {
        if (showTime) {
          if (typeof (event) === 'object' && event.target && event.target.value) {
            const v = moment(event.target.value, displayFormat).format(valueFormat)
            return v
          } else {
            const v = moment(event, displayFormat).format(valueFormat)
            return v
          }
        } else {
          if (typeof (event) === 'object' && event.target && event.target.value) {
            const v = moment(event.target.value, displayFormat).format(valueFormat)
            return v
          } else {
            const v = moment(event, displayFormat).format(valueFormat)
            return v
          }
        }
      } else {
        if (showTime) {
          if (typeof (event) === 'object' && event.target && event.target.value) {
            if (event.target.value.length === 7) { event.target.value = '0' + event.target.value }
            return event.target.value
          } else {
            if (event && event.length === 7) { event = '0' + event }
            const v = moment(event).format(valueFormat)
            return v
          }
        }
      }
    }
    return undefined
  }

  return <div className={'form-group ' + className} style={{ position: 'relative' }}>
    <label htmlFor={name}>{label} {touched &&
            ((error &&
            <span className="validation-error">
              {error}
            </span>))

    }{(info &&
    <span className="field-info">
      {info}
    </span>)}</label>
    <DateTimePicker
      containerClassName={containerClassName}
      min={min}
      max={max}
      readOnly={readOnly}
      disabled={disabled}
      onChange={event => onChange(parse(event))}
      onBlur={event => onBlur(parse(event))}
      time={showTime}
      calendar={showCalendar}
      value={val}
      dropUp={dropUp}
      format={displayFormat}
      currentDate={currentDate}
      onKeyPress={event => event.preventDefault()}
      onKeyDown={event => event.preventDefault()}
    />
    {hoverEnabledMsg && <span className="enabledMsg" style={{ width: 250 }}>{hoverEnabledMsg}</span>}
    {hoverDisabledMsg && <span className="disabledMsg">{hoverDisabledMsg}</span>}

  </div>
}
