import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/networking/axios'
import { Box, Grid, Stack } from '@mui/material'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import ProductInsightDetailsTop from '../../shared/components/ProductInsightDetailsTop'
import RelatedIngredients from '../../shared/components/RelatedIngredients'
import DriverCard from '../../shared/components/DriverCard'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { downloadGraph, downloadSection, downloadFullPage } from '../../const'
import '../muiStyle.css'

class CategoryTopicPage extends Component {
  state = {
    loader: false,
  }

  imageDownload = (section) => {
    this.setState({ loader: true }, () => {
      const name = this.props.subNeedDetail.keyword
      const country = this.props.subNeedDetail.countryName
      const category = this.props.subNeedDetail.countryName.projectName
      if (section === 'Chart') {
        downloadGraph(
          document.getElementById('_dowSectionChart'),
          `${name}_Monthly_Trend_Graph.jpg`,
        )
          .then(() => {
            this.setState({ loader: false })
          })
          .catch((er) => {
            console.log(er)
            this.setState({ loader: false })
          })
      } else {
        let dowSection = document.getElementById('_dowSectionDesc')
        let fileName = `${name}_${country}_${category}_Complete_Report.jpg`
        if (!section) {
          downloadFullPage(
            dowSection,
            '#_dowSectionChart',
            { top: 150, left: 0 },
            document.getElementById('downloadCanvas'),
            `height: 200px; background: white;`,
            fileName,
          )
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              this.setState({ loader: false })
              console.log(er)
            })
        }
        if (section === 'RelatedIngredients') {
          dowSection = document.getElementById('_dowSectionIngredientBlock')
          fileName = `${name}_Related_Ingredients.jpg`
          downloadSection(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch(() => {
              this.setState({ loader: false })
            })
        }
      }
    })
  }

  componentDidMount() {
    const topicID = Number(this.props.match.params.topicId)
    this.props.fetchSubNeedsDetails(topicID)
    this.props.fetchSubNeedQuotes(topicID)
  }

  componentDidUpdate(prevProps) {
    const topicID = Number(this.props.match.params.topicId)
    if (prevProps.topicID !== topicID) {
      this.props.resetState()
      this.props.fetchSubNeedsDetails(topicID)
      this.props.fetchSubNeedQuotes(topicID)
    }
  }

  fetchConsumerQuotes() {
    const topicID = Number(this.props.match.params.topicId)
    this.props.fetchSubNeedQuotes(topicID)
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  render() {
    const { loader } = this.state
    const subNeedDetailscheck =
      !this.props.subNeedDetail || !this.props.subNeedDetail.relatedNeeds

    const projectVariant = this.props.dashboardList?.find(
      (dashboard) =>
        dashboard.criteria === this.props.subNeedDetail?.countryName &&
        dashboard.name === this.props.subNeedDetail?.projectName,
    )?._projectVariant

    if (subNeedDetailscheck)
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              mt: 2,
              pt: 32,
              px: 88,
              flexGrow: 2,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <CircularProgressCustom />
          </Box>
        </Grid>
      )
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        <Box id="_dowSectionDesc">
          {loader && <OverlayLoader />}
          <Stack spacing={3}>
            <ProductInsightDetailsTop
              needChart={true}
              expand={loader}
              catInsight={true}
              projectid={this.props.projectid}
              needid={this.props.needID}
              topicid={this.props.match.params.topicId}
              details={this.props.subNeedDetail}
              positiveSentiment={this.props.subNeedDetail.positiveScore}
              neutralSentiment={this.props.subNeedDetail.negativeScore}
              imageDownload={this.imageDownload}
              country={this.props.subNeedDetail.countryName}
              category={this.props.subNeedDetail.projectName}
              negativeSentiment={this.props.subNeedDetail.neutralScore}
            />
            <Box id="_dowSectionIngredientBlock">
              <RelatedIngredients
                expand={loader}
                dowImageType="RelatedIngredients"
                toggleImageDownload={this.imageDownload}
                blockName="Ingredients"
                projectid={this.props.projectid}
                relatedTrends={this.props.subNeedDetail.subNeedProducts}
                country={this.props.subNeedDetail.countryName}
                category={this.props.subNeedDetail.projectName}
                amplitudeRedirect="Related Consumer Insight Topic"
              />
            </Box>
            {this.props.subNeedDetail.relatedNeeds &&
              this.props.subNeedDetail.relatedNeeds.length !== 0 && (
                <DriverCard
                  expand={loader}
                  categoryPreviewNeed={true}
                  projectid={this.props.projectid}
                  needsList={[...this.props.subNeedDetail.relatedNeeds].sort(
                    (needA, needB) => Number(needB.score) - Number(needA.score),
                  )}
                  category={this.props.subNeedDetail.needCategory}
                />
              )}
          </Stack>
        </Box>
        <ConsumerQuotesCardComponent
          quotesOptions={this.props.subNeedDetail.quotesOptions}
          consumerQuotes={this.props.subNeedQuotes}
          onRefresh={() => {
            this.fetchConsumerQuotes()
          }}
          sourceShow
          category={this.props.subNeedDetail.needCategory}
          country={this.props.subNeedDetail.countryName}
          name={this.props.subNeedDetail.keyword}
          markIrrelevantDisabled={false}
          projectVariant={projectVariant}
        />
        <Box id="downloadCanvas"></Box>
      </Stack>
    )
  }
}

const mapStateToProps = (state, props) => {
  const projectID = Number(props.match.params.projectId)
  const needID = Number(props.match.params.needId)
  const topicID = Number(props.match.params.topicId)
  return {
    projectid:
      state.userSelections.projectID === projectID
        ? state.userSelections.projectID
        : projectID,
    needID:
      state.userSelections.projectNeedID === needID
        ? state.userSelections.projectNeedID
        : needID,
    topicID:
      state.userSelections.topicID === topicID
        ? state.userSelections.topicID
        : topicID,
    error: state.needDetails.error,
    subNeedDetail: state.needDetails.subNeedDetail,
    subNeedQuotes: state.needDetails.subNeedQuotes,
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSelectedNeeds: (needid) =>
      dispatch(actions.userSelectedProjectNeeds(needid)),
    userSelectedProject: (projectid) =>
      dispatch(actions.userSelectedProject(projectid)),
    userSelectedTopic: (topicid) =>
      dispatch(actions.userSelectedTopicId(topicid)),
    resetState: () => dispatch(actions.resetProductNeeds()),
    fetchSubNeedsDetails: (subNeedId) =>
      dispatch(actions.fetchSubNeedsDetails(subNeedId)),
    fetchSubNeedQuotes: (subNeedId) =>
      dispatch(actions.fetchSubNeedQuotes(subNeedId)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(CategoryTopicPage, axios)),
)
