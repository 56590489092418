import * as actions from '../actions/index'

export const saveStore = store =>{
  return next => action => {
  const result = next(action)
  const typeArray = action.type.split('_')
  if(typeArray.length >= 2 && typeArray[0] === 'DOWNLOAD' && typeArray[1] === 'SECTION') {
    const dwList = store.getState().downloadSections
    // window.sessionStorage.setItem('downloadSections', JSON.stringify(dwList) )
    localforage.setItem('downloadSections',{list:dwList.list.toJS(),selectAll:dwList.selectAll}).then(() => {
      localStorage.setItem('added_download', new Date())
    }).catch(er => console.log(er))
  }
  return result
}
} 