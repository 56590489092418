import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import LinearProgressWithLabel from '../LinearProgressWithLabel'
import GrowthChip from '../GrowthTag'

import { ConsumerInsightsItemContainer, ConsumerInsightsBox } from './styles'

const ConsumerInsightsItem = ({
  title,
  score,
  iconUri,
  twoYearCagr,
  disableCardsClick,
}) => (
  <ConsumerInsightsItemContainer
    variant="outlined"
    sx={{
      pointerEvents: disableCardsClick && 'none',
    }}
  >
    <ConsumerInsightsBox>
      <Box>
        <img
          alt="ci icon"
          src={iconUri}
          style={{ width: 18, height: 18, objectFit: 'contain' }}
        />
        <Typography variant="subtitle3" color="primary">
          {title}
        </Typography>
      </Box>
      <Box>
        <LinearProgressWithLabel
          value={Math.round((score + Number.EPSILON) * 100) / 100}
        />
      </Box>
    </ConsumerInsightsBox>
    <Box
      sx={{
        bgcolor: 'grey.50',
        borderRadius: '0px 0px 4px 4px',
        p: '4px 8px',
      }}
    >
      <GrowthChip title="2 Year CAGR" growthValue={twoYearCagr} />
    </Box>
  </ConsumerInsightsItemContainer>
)

ConsumerInsightsItem.propTypes = {
  title: PropTypes.string.isRequired,
}

export default ConsumerInsightsItem
