import React, { useEffect } from 'react'
import { Breadcrumbs, Grid, Link, Typography, Box, Stack } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import productFormat from '../../../assets/mock/product-format.json'
import VolumeCard from '../VolumeCard'
import TopicsCard from '../TopicsCard'
import DownloadButton from '../DownloadButton'
import HideOnScrollAppbar from './../HideOnScrollAppbar'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'
import { useLocation } from 'react-router-dom'
import AddFavoriteButtonParing from '../OtherFavorites/AddFavoriteButtonParing'

const ProductFormatDetailsTop = (props) => {
  const {
    projectid,
    productid,
    lensSelected,
    productName,
    keyword,
    subKeyword,
    formats,
    subFormats,
    productType,
    imageDownload,
    expand,
    country,
    category,
  } = props
  const location = useLocation()
  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/product-category/${projectid}`}
    >
      {props.country}&nbsp;{props.category}
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="4"
      href={`/mui/details/${projectid}/${lensSelected}/${productid}`}
    >
      {productName}
    </Link>,
    <Typography key="6" variant="subtitle2">
      {decodeURIComponent(keyword)}
    </Typography>,
  ]
  if (subKeyword)
    breadcrumbs.splice(
      3,
      1,
      <Link
        variant="breadcrumbsLink"
        key="5"
        href={`/mui/details/productFormat/${projectid}/${lensSelected}/${productid}/${productType}/${keyword}`}
      >
        {decodeURIComponent(keyword)}
      </Link>,
      <Typography key="6" variant="subtitle2">
        {decodeURIComponent(subKeyword)}
      </Typography>,
    )

  useEffect(() => {
    let title = ''
    if (props.country && props.category && productName && keyword) {
      title = `Explore Trends>${props.country} ${
        props.category
      }>${productName}>${decodeURIComponent(keyword)}`
    }
    if (subKeyword) {
      if (
        title &&
        projectid &&
        lensSelected &&
        productid &&
        productType &&
        keyword &&
        subKeyword
      ) {
        shouldLogRecentActivity(
          `${title}>${decodeURIComponent(subKeyword)}`,
          'Platform',
          `/mui/details/productFormat/${projectid}/${lensSelected}/${productid}/${productType}/${encodeURIComponent(
            keyword,
          )}/subFormat/${encodeURIComponent(subKeyword)}`,
        )
      }
    } else {
      if (
        title &&
        projectid &&
        lensSelected &&
        productid &&
        productType &&
        keyword
      ) {
        shouldLogRecentActivity(
          title,
          'Platform',
          `/mui/details/productFormat/${projectid}/${lensSelected}/${productid}/${productType}/${encodeURIComponent(
            keyword,
          )}`,
        )
      }
    }
  }, [
    subKeyword,
    props.country,
    props.category,
    projectid,
    lensSelected,
    productid,
    productType,
    keyword,
    productName,
  ])

  return (
    <Stack gap={2}>
      <HideOnScrollAppbar>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </HideOnScrollAppbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '20px',
        }}
      >
        {productType === 'cafe_product' ? (
          <Typography variant="h4" color="textSecondary">
            {' '}
            Food Service Products Format{' '}
          </Typography>
        ) : (
          <Typography variant="h4" color="textSecondary">
            {' '}
            Related Retail Products Format{' '}
          </Typography>
        )}
        <>
          <Box>
            <AddFavoriteButtonParing
              additionalInfo={null}
              favoriteType={
                productType === 'cafe_product'
                  ? 'FS_PRODUCTS'
                  : 'RETAIL_PRODUCTS'
              }
              favoriteSectionType={'Other Favorites'}
              productFormat
              projectId={projectid}
              productId={productid}
              trendName={
                productType === 'cafe_product'
                  ? `Trend: Food Service Products in ${decodeURIComponent(
                      keyword,
                    )} in ${props.country} ${props.category}`
                  : `Trend: Retail Products in ${decodeURIComponent(
                      keyword,
                    )} in ${props.country} ${props.category}`
              }
              trendType={
                lensSelected.charAt(0).toUpperCase() + lensSelected.slice(1)
              }
              reDirectionUrl={location.pathname}
              breadcrumbs={`Foresight Engine, ${props.country} ${
                props.category
              }, ${productName}, ${decodeURIComponent(keyword)}`}
            />

            <DownloadButton
              dowImageType={''}
              toggleImageDownload={imageDownload}
            />
          </Box>
        </>
      </Box>
      <Box>
        <Typography variant="h2">
          {subKeyword
            ? decodeURIComponent(subKeyword)
            : decodeURIComponent(keyword)}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={6} display="flex">
            <VolumeCard
              value={
                subKeyword
                  ? subFormats[decodeURIComponent(keyword)][
                      decodeURIComponent(subKeyword)
                    ]
                  : formats[decodeURIComponent(keyword)]
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TopicsCard
              expand={expand}
              productFormat
              keyword={keyword}
              projectid={projectid}
              productid={productid}
              urllens={lensSelected}
              languageSelection={false}
              maxHeight={164}
              topics={Object.entries(subFormats[decodeURIComponent(keyword)])
                .sort(
                  (a, b) =>
                    a.percentage_score_current - a.percentage_score_current,
                )
                .map(([k, v]) => ({ name: k, percentage_score_current: v }))}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default ProductFormatDetailsTop
