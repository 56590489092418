import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableContainer, TableRow } from '@mui/material'

import { GenericTableCell } from '../../../store/utility'
import GenericTableHead from './GenericTableHead'
import EmptyIngredientsCard from '../EmptyIngredientsCard'

const GenericTableComponent = (props) => {
  const {
    tableContainerProps,
    tableProps,
    genericTableHeaderProps,
    listOfRows,
    tableRowProps,
    genericTableCellProps,
    emptyStateText,
  } = props

  return (
    <TableContainer {...tableContainerProps}>
      <Table {...tableProps}>
        {listOfRows?.length > 0 && (
          <GenericTableHead {...genericTableHeaderProps} />
        )}
        <TableBody>
          {listOfRows?.length > 0 &&
            listOfRows.map((row, idx) => (
              <TableRow
                data-testId="tableRow"
                key={`${row.id}-${idx}`}
                {...tableRowProps}
              >
                <>
                  <GenericTableCell rowData={row} {...genericTableCellProps} />
                </>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {listOfRows?.length < 1 && <EmptyIngredientsCard lens={emptyStateText} />}
    </TableContainer>
  )
}

GenericTableComponent.defaultProps = {
  emptyStateText: 'Trends',
}

GenericTableComponent.propTypes = {
  tableContainerProps: PropTypes.object,
  tableProps: PropTypes.object,
  genericTableHeaderProps: PropTypes.shape({
    headCell: PropTypes.arrayOf(PropTypes.object),
    orderBy: PropTypes.string,
    order: PropTypes.string,
    handleRequestSort: PropTypes.func,
    headHeight: PropTypes.number,
    border: PropTypes.string,
  }),
  listOfRows: PropTypes.array,
  tableRowProps: PropTypes.object,
  genericTableCellProps: PropTypes.shape({
    tableHeadCells: PropTypes.arrayOf(PropTypes.object),
  }),
  emptyStateText: PropTypes.string,
}

export default GenericTableComponent
