import React from 'react';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  autocompleteClasses, InputBase, TextField, InputAdornment, Popper,
} from '@mui/material';

export const WIDTH = 260;

export const PopperComponent = styled(props => {
  const {
    disablePortal, anchorEl, open, ...other
  } = props;
  return <div {...other} />;
})(({ theme }) => ({
  [`&.${autocompleteClasses.popper}`]: {
    width: '100% !important',
  },
  [`& .${autocompleteClasses.paper}`]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    paddingRight: 2,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.option}`]: {

    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

export const StyledPopper = styled(Popper)(() => ({
  width: WIDTH,
}));

export const StyledTextField = styled(InputBase)(({ theme }) => ({
  width: WIDTH,
  backgroundColor: theme.palette.background.paper,
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  borderRadius: 0,
}));

export const StyledMainTextField = styled(props => (
  <TextField
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </InputAdornment>
      ),
    }}
    {...props}
  />
))(({ theme, open }) => ({
  width: WIDTH,
  '& .MuiOutlinedInput-root': {
    padding: theme.spacing(0.5),
    ...(open && { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }),
  },
}));
