import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export default function BackdropLoader({
  loading,
  shouldRenderText = true,
  backdropBackground = 'rgba(250, 250, 250, 1)',
}) {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max)
  }

  // const loadingTextHelpers = [
  //   'Hang on, let me cook up some brain food for ya!',
  //   'Get your forks ready, because I’m about to dish out some fresh concepts hot off the grill!!',
  //   'Get ready to feast on some fresh ideas!',
  // ]

  const LoadingText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: 14,
  }))

  const [open, setOpen] = React.useState(loading)
  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <div>
      <Backdrop
        sx={{
          backgroundColor: backdropBackground,
          color: '#ececed',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <Box
          display={'flex'}
          sx={{ flexDirection: 'column', gap: 1.5 }}
          alignItems={'center'}
        >
          {/* {shouldRenderText && shouldRenderText ? (
            <LoadingText>{loadingTextHelpers[getRandomInt(3)]}</LoadingText>
           ) : null} */}

          <CircularProgress />
        </Box>
      </Backdrop>
    </div>
  )
}
