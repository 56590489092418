import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  LinearProgress,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  Divider,
} from '@mui/material'
import { Box } from '@mui/system'
import CircleIcon from '@mui/icons-material/Circle'

import { isEmpty } from 'lodash'

import formatNumber from '../../../shared/utils/formatNumber'
import InformationIcon from '../InformationIcon'
import {
  ConsumerCardContainer,
  ConsumerItemsContainer,
  ConsumerItem,
} from './styles'
import { amplify } from './../../../store/utility'

import { ReactComponent as EmptySpaceFoodCupSvg } from '../../../assets/emptySpaceFoodCup.svg'
import palette from '../../../theme/palette'

const renderInformationBoxText = (category) => {
  if (category === 'Driver') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Drivers
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Why are consumers consuming a certain product? What Functional and
          Sensory drivers is the product satisfying?
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Positive social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Barrier') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Drivers
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          What is stopping a consumer from purchasing a particular product?
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Negative social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Expressed Persona') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Expressed Persona
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          The consumer persona explicitly mentioned in Consumer conversations
          around a particular trend, e.g. In an e-commerce product review “My
          father likes dark chocolate” - “Father” is the expressed persona. This
          should be an indicator about who is consuming a particular Food and
          Beverage product. We do not capture personal data.
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Product Attribute') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Product Attribute
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          The product attributes and claims popular with consumers. e.g: sensory
          descriptors consumers use to describe the product.
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Format') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Format
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          The formats in which the consumers consume this trend
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Occasion') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Occasion
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Where and When are consumers eating or drinking a particular product?
          For example Breakfast, Lunch, Dinner, Dessert etc
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  } else if (category === 'Pairing') {
    return (
      <Stack p={1}>
        <Typography variant="subtitle3" color="inherit">
          Pairing
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          What are consumers and Key Opinion Leaders Pairing an
          ingredient/product with? We help you understand which kind of
          ingredient it is being paired with. For example Fruit Pairing, Dairy
          Pairing, Vegetable Pairing. You can also dig deeper to understand
          which ingredients in particular are being paired.
        </Typography>
        <Typography variant="subtitle3" mt={1} color="inherit">
          Source
        </Typography>
        <Typography variant="caption" color={palette.grey['200']} mt={0.5}>
          Social consumer conversations around the topic
        </Typography>
      </Stack>
    )
  }
}

const clickedConsumerInsights = (
  trendName,
  country,
  email,
  category,
  name,
  displayCategory,
  lensSelected,
  title,
) => {
  const ampiEvent = `Clicked${title}_${category}_Insights`
  const ampiUserData = {
    User: email,
    Trend_Name: trendName,
    Country_Name: country,
    Category_Name: displayCategory,
    Lens_Name: lensSelected,
    [`${category}_Name`]: name,
  }
  amplify(ampiEvent, ampiUserData, email)
}
const ConsumerCard = ({
  demographyComponent,
  projectid,
  expand,
  lensSelected,
  productid,
  selectedLang,
  category,
  dataPoint,
  needs,
  trendName,
  country,
  email,
  title,
  displayCategory,
  disableLink,
  scn,
  ccContainerSx = {},
  ccItemSx = {},
  emptyCnSx = {},
  type,
  region,
  isBPC=false,
}) => {
  const renderConsumerItem = ({
    name,
    // eslint-disable-next-line
    native_name,
    percentage_value: percentageValue,
  }) => {
    return (
      percentageValue?.toFixed(2) > 0.0 && (
        <ConsumerItem
          sx={
            disableLink || demographyComponent
              ? {
                  pointerEvents: 'none',
                  ...ccItemSx,
                }
              : ccItemSx
          }
          key={name}
        >
          {percentageValue?.toFixed(2) > 0.0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle3" sx={{ color: 'primary.main' }}>
                {selectedLang === 'English' && name} {/* eslint-disable-line */}
                {selectedLang !== 'English' && native_name[0]}
              </Typography>
              <Typography variant="subtitle2">
                {percentageValue?.toFixed(2)}%
              </Typography>
            </Box>
          )}
          {percentageValue?.toFixed(2) > 0.0 && (
            <LinearProgress
              sx={{ zIndex: 1 }}
              variant="determinate"
              value={percentageValue}
            />
          )}
          {!isEmpty(scn) && scn[name] && (
            <Box>
              <List
                sx={{
                  paddingLeft: '10px',
                }}
              >
                <Divider
                  sx={{
                    height: 40 + (scn[name].length - 1) * 37,
                    position: 'absolute',
                    zIndex: 0,
                    top: '-12px',
                    left: '8px',
                  }}
                  orientation="vertical"
                />
                {scn[name].map(
                  ({
                    name,
                    native_name = [], // eslint-disable-line
                    percentage_score_current: percentScore,
                  }) => (
                    <ListItem
                      sx={{ display: 'flex', p: 0, pt: '8px', width: '100%' }}
                    >
                      <ListItemIcon
                        sx={{
                          maxWidth: '10px',
                          minWidth: '10px',
                          alignSelf: 'start',
                          mt: '5px',
                          mr: '3px',
                          position: 'relative',
                        }}
                      >
                        <CircleIcon
                          sx={{ width: 8, height: 8, color: 'primary.main' }}
                        />
                      </ListItemIcon>
                      <Box sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            mb: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            variant="subtitle3"
                            sx={{ color: 'primary.main' }}
                          >
                            {selectedLang === 'English' && name}{' '}
                            {/* eslint-disable-next-line */}
                            {selectedLang !== 'English' && native_name[0]}
                          </Typography>
                          <Typography variant="subtitle2">
                            {Number(percentScore)?.toFixed(2)}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={Number(percentScore)}
                        />
                      </Box>
                    </ListItem>
                  ),
                )}
              </List>
            </Box>
          )}
        </ConsumerItem>
      )
    )
  }

  const isShowBPCNullCard = dataPoint < 20 && isBPC;
  return (
    <ConsumerCardContainer sx={ccContainerSx}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          px: 1.5,
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{category}</Typography>
        {!!category && (
          <InformationIcon tooltipText={renderInformationBoxText(category)} />
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 1, px: 1.5 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Data points
        </Typography>
        <Typography variant="subtitle2">
        {dataPoint && !isShowBPCNullCard ? formatNumber(dataPoint) : '--'}
        </Typography>
      </Box>
      <ConsumerItemsContainer
        sx={
          expand
            ? { height: 'fit-content !important', overflowY: 'auto !important' }
            : {}
        }
      >
        {dataPoint && !isShowBPCNullCard && !!needs ? (
          needs.map(
            // eslint-disable-next-line
            ({ id, name, native_name, percentage_value: percentageValue }) =>
              disableLink || demographyComponent ? (
                renderConsumerItem({
                  name,
                  native_name,
                  percentage_value: percentageValue,
                })
              ) : (
                <Link
                  target="_blank"
                  to={
                    type === 'regionalAnalytics'
                      ? `?projectId=${encodeURIComponent(
                          projectid,
                        )}&lens=${encodeURIComponent(
                          lensSelected,
                        )}&trendId=${encodeURIComponent(
                          productid,
                        )}&needId=${encodeURIComponent(
                          id,
                        )}&region=${encodeURIComponent(region)}`
                      : `/mui/details/${projectid}/${lensSelected}/${productid}/need/${id}`
                  }
                  onClick={() =>
                    clickedConsumerInsights(
                      trendName,
                      country,
                      email,
                      category,
                      name,
                      displayCategory,
                      lensSelected,
                      title,
                    )
                  }
                  key={name}
                >
                  {renderConsumerItem({
                    name,
                    native_name,
                    percentage_value: percentageValue,
                  })}
                </Link>
              ),
          )
        ) : (
          <Stack
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              ...emptyCnSx,
            }}
          >
            <EmptySpaceFoodCupSvg />
            <Typography
              align="center"
              pt={1.25}
              px={1}
              variant="caption"
              color="textSecondary"
            >
              Insufficient data to display for {category}
            </Typography>
          </Stack>
        )}
      </ConsumerItemsContainer>
    </ConsumerCardContainer>
  )
}
ConsumerCard.propTypes = {
  category: PropTypes.string.isRequired,
  dataPoint: PropTypes.number.isRequired,
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      percentage_value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default ConsumerCard
