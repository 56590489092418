import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { Box } from '@mui/material'
import { getTextDimensions, dataOverlaps, amplify } from '../../store/utility'
import InformationIcon from '../../shared/components/InformationIcon'
import TooltipHoverCard from './Tooltip'
import { IconBox } from './Styled'

const amp = (props, ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: props.email,
      Lens: props.lensSelected,
      Country_Name: props.wsoData.country,
      Category_Name: props.wsoData.category,
      Redirected_From: 'WSO Graph',
      Trend_Name: props.wsoData.trends.filter(
        (trend) => trend.trend_id === ampiUserData.trendId,
      )[0].name,
      Maturity_Phase: props.wsoData.trends.filter(
        (trend) => trend.trend_id === ampiUserData.trendId,
      )[0].classification,
    }
    amplify(amplitudeEvent, amplitudeUserData, props.email)
  } catch (err) {
    console.log(err, 'Amplitude error in WhiteSpaceGraph')
  }
}

/* @param {function} xAxisFunc plotting function for x-axis
 * @param {function} yAxisFunc plotting function for y-axis
 * @param {[{x, y, name}]} data
 * @param {{xAxis: [min, max],yAxis: [min, max]}} drawingRange  */

let appliedZoom = []

const wsoDataFormatter = (data, xScale, yScale) => {
  return data
    ? data.map(
        ({ supply_score: supplyScore, demand_score: demandScore, ...rest }) => {
          return {
            ...rest,
            x: xScale(supplyScore),
            y: yScale(demandScore),
          }
        },
      )
    : []
}

const WhiteSpaceGraph = (props) => {
  const [data, setData] = useState([])
  const viewDetailsClicked = (trendId) => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      trendId,
    }
    amp(props, ampiEvent, ampiUserData)
    const link = `/mui/details/${props.wsoData.project_id}/${props.lensSelected}/${trendId}`
    window.open(link)
  }

  const svgRef = useRef()
  const timeOutRef = useRef()

  useEffect(() => {
    if (props.wsoTableData) {
      setData(props.wsoTableData)
    } else {
      setData([])
    }
  }, [props.wsoTableData])

  useEffect(() => {
    appliedZoom = []
  }, [props.wosData])

  useEffect(() => {
    const height = 425
    const paddingH = 50
    const paddingV = 10
    const titleSpace = 40
    const radius = 6
    const fontSize = 14

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', '#ffffff')
      // .style('overflow', 'visible')
      .style('margin-left', '20px')

    const width = svg.node().getBoundingClientRect().width

    const drawingRange = {
      xAxis: [paddingH + 20, width - 20],
      x1Axis: [paddingH + 20, width],
      yAxis: [height - paddingV - titleSpace, paddingV + titleSpace],
      y1Axis: [height - paddingV + 15 - titleSpace, paddingV + titleSpace],
    }

    const labelOffsetFromCirc = {
      offset: {
        x: radius,
        y: 5,
      },
      height: 18, //  Pick height of label from browser and add error margin
    }

    const overlapDrawingRange = {
      xAxis: drawingRange.xAxis,
      yAxis: [...drawingRange.yAxis].reverse(),
    }

    const overlapResolver = (
      data,
      labelProps,
      drawingRange,
      circRadius,
      fontSize,
    ) => {
      const labelArray = data.map(({ x, y, name, trend_id: trendId }) => {
        const textDimensions = getTextDimensions(name, {
          family: 'Roboto',
          size: fontSize,
        })
        const xPos = x + circRadius
        let yPos = labelProps.offset.y + y
        if (
          y < drawingRange.yAxis[0] + circRadius &&
          y > drawingRange.yAxis[0] - circRadius
        ) {
          yPos = drawingRange.yAxis[0] + fontSize - 3
        } else if (
          y > drawingRange.yAxis[1] - circRadius &&
          y < drawingRange.yAxis[1] + circRadius
        ) {
          yPos = drawingRange.yAxis[1] - textDimensions.fontBoundingBoxDescent // need to offset by height of rendered text
        }
        const textWidth = textDimensions.width
        return {
          x: xPos,
          y: yPos,
          name,
          trendId,
          width: textWidth,
          height: labelProps.height,
          dir: {
            left: true,
            right: true,
          },
        }
      })

      for (let i = 0; i < data.length; i++) {
        const { x, y, width, height } = labelArray[i]
        const leftPos = data[i].x - circRadius - width
        for (let j = 0; j < data.length; j++) {
          if (!labelArray[i].dir.left && !labelArray[i].dir.right) {
            break
          }
          if (i !== j) {
            for (let dir = labelArray[i].dir.left ? 0 : 1; dir < 2; dir++) {
              // dir 0 is left and 1 is right
              const { left } = labelArray[i].dir
              const iRectX1 = dir === 0 ? leftPos : x
              if (iRectX1 < drawingRange.xAxis[0]) {
                labelArray[i].dir.left = false
              }

              const iRectX2 = iRectX1 + width
              const iRectY2 = y + 3 //  Add 3 to give extra space below
              const iRectY1 = iRectY2 - height

              // Check overlap of left label with the datapoint and label,
              // if label is already applied to its
              let jRectX1 = data[j].x - circRadius
              let jRectX2 = jRectX1 + 2 * circRadius
              let jRectY2 = labelArray[j].y + circRadius
              let jRectY1 = jRectY2 - 2 * circRadius
              if (
                (labelArray[j].dir.left || labelArray[j].dir.right) &&
                i > j
              ) {
                const correction = 2 * circRadius
                jRectX1 =
                  labelArray[j].x - (labelArray[j].dir.left ? 0 : correction)
                jRectX2 = jRectX1 + correction + labelArray[j].width
                jRectY2 = labelArray[j].y + 3 //  Add 3 to give extra space below
                jRectY1 = jRectY2 - height
              }

              const overlaps = dataOverlaps(
                {
                  x: [iRectX1, iRectX2],
                  y: [iRectY1, iRectY2],
                },
                {
                  x: [jRectX1, jRectX2],
                  y: [jRectY1, jRectY2],
                },
              )

              if (overlaps) {
                if (dir === 0) {
                  labelArray[i].dir.left = false
                } else {
                  labelArray[i].dir.right = false
                }
              }
            }
          }
        }

        //  Check edge cases
        if (
          leftPos < drawingRange.xAxis[0] ||
          data[i].x - circRadius >= drawingRange.xAxis[1]
        ) {
          labelArray[i].dir.left = false
        }
        if (x + width > drawingRange.xAxis[1] || x < drawingRange.xAxis[0]) {
          labelArray[i].dir.right = false
        }
        const { left } = labelArray[i].dir
        if (left) {
          labelArray[i].x = leftPos
        }
      }
      return labelArray
    }

    svg.selectAll('g').remove()

    const brushLayer = svg.append('g')
    const circlesLayer = svg.append('g')
    //  Setup x-scale
    const xScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range(drawingRange.xAxis)

    const x1Scale = d3
      .scaleLinear()
      .domain([0, 100])
      .range(drawingRange.x1Axis)

    /*  const x = svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale)); */

    //  Setup y-scale
    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range(drawingRange.yAxis)

    const y1Scale = d3
      .scaleLinear()
      .domain([0, 100])
      .range(drawingRange.y1Axis)

    //  Tooltip element
    const tooldiv = d3
      .select('#chart')
      .append('div')
      .style('visibility', 'hidden')
      .style('position', 'absolute')
      .style('background-color', '#ffffff')
      .attr('r', 30)

    const xAxis = d3
      .axisBottom(xScale)
      .ticks()
      .tickFormat((x) => x)
    const x1Axis = d3.axisTop(x1Scale).ticks(0)
    const yAxis = d3
      .axisLeft(yScale)
      .ticks()
      .tickFormat((x) => x)
      .tickPadding([11])
    const y1Axis = d3.axisRight(y1Scale).ticks(0)
    const grid = (g) => {
      svg.selectAll('.grid--x').remove()
      svg.selectAll('.grid--y').remove()
      return g
        .attr('stroke', 'currentColor')
        .attr('stroke-opacity', 0.1)
        .call((g) =>
          g
            .append('g')
            .classed('grid--x', true)
            .selectAll('line')
            .data(xScale.ticks())
            .join('line')
            .attr('x1', (d) => xScale(d))
            .attr('x2', (d) => xScale(d))
            .attr('y1', yScale(0))
            .attr('y2', yScale(100)),
        )
        .call((g) =>
          g
            .append('g')
            .classed('grid--y', true)
            .selectAll('line')
            .data(yScale.ticks())
            .join('line')
            .attr('y1', (d) => yScale(d))
            .attr('y2', (d) => yScale(d))
            .attr('x1', xScale(0))
            .attr('x2', xScale(100)),
        )
        .attr('clip-path', 'url(#clip)')
    }
    //  svg.append('g').call(xAxis).attr('transform', `translate(0,${height})`)
    //  svg.append('g').call(yAxis)
    svg.append('g').call(grid)

    //  Clip-path for drawing range
    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', drawingRange.xAxis[1] - drawingRange.xAxis[0])
      .attr('height', drawingRange.yAxis[0] - drawingRange.yAxis[1])
      .attr(
        'transform',
        `translate(${drawingRange.xAxis[0]},${drawingRange.yAxis[1]})`,
      )

    svg.selectAll('circle').remove()
    svg.selectAll('text').remove()
    svg.selectAll('.axis--dashed').remove()

    svg
      .append('text')
      .attr('x', width / 2 - 20)
      .attr('y', height - 10)
      .text('Supply Score')
      .style('fill', '#525252')

    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 20)
      .attr('x', -drawingRange.yAxis[0] + 80)
      .text('Demand Score')
      .style('fill', '#525252')

    //  svg.append('g').classed('pairing-data-points',true).attr("clip-path", "url(#clip)"),

    //  diagonal line
    const diagonalLine = () =>
      svg
        .append('line')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'axis--dashed')
        .style('stroke', '#06B0F1')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '10,3')
        .attr('x1', xScale(0))
        .attr('x2', xScale(100))
        .attr('y1', yScale(0))
        .attr('y2', yScale(100))

    svg.call(diagonalLine)

    circlesLayer
      .attr('clip-path', 'url(#clip)')
      .selectAll()
      .data(data)
      .enter()
      .append('circle')
      .attr('r', radius) // Radius size, could map to another dimension
      .attr('class', 'dot')
      .attr('cx', (d) => xScale(d.supply_score))
      .attr('cy', (d) => yScale(d.demand_score))
      .style('fill', (d) => props.phaseColor[d.classification])
      .style('cursor', 'pointer')
      .on('click', (d) => viewDetailsClicked(d.trend_id))
      .on('mouseover', (e, d) => {
        //  tooldiv.style('visibility', 'visible').text(e[0] && e[1])
      })

      .on('mouseover', (e, index, listCyrcle) => {
        // Calculate the coordinates for the tooltip.
        const cy = listCyrcle[index].getAttribute('cy')
        const cx = listCyrcle[index].getAttribute('cx')
        let top = Math.floor(Number(cy))
        let left = Math.floor(cx) + 30

        const { width: divWidth, height: divHeight } = document
          .getElementById('wso-graph')
          .getBoundingClientRect()
        if (left + 300 > divWidth) {
          left = left - 310
        }
        if (top + 300 > divHeight) {
          top = top - 110
        }

        // Updating the State
        const uptTooltip = e
        uptTooltip.left = left
        uptTooltip.top = top >= 120 ? 120 : top <= 50 ? 50 : top
        uptTooltip.tooltipShow = true
        props.handleTooltipShow(uptTooltip)
        clearInterval(timeOutRef.current)
        timeOutRef.current = setTimeout(() => {
          props.handleTooltipShow(false)
        }, 15000)
      })
    /*  .on('mouseout', (e,d)=>{
        tooldiv.style('visibility','hidden')
            .text(e[0] && e[1])
      })  */

    svg.select('.data-point-names-x').remove()
    svg
      .append('g')
      .classed('data-point-names-x', true)
      .attr('clip-path', 'url(#clip)')
      .selectAll('text')
      .data(
        overlapResolver(
          wsoDataFormatter(data, xScale, yScale),
          labelOffsetFromCirc,
          overlapDrawingRange,
          radius,
          fontSize,
        ),
      )
      .enter()
      .append('text')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
      .attr(
        'style',
        `color: black; fill: black; font-size: ${fontSize}px; letter-spacing: 0px;`,
      )
      .style('display', (d) => (d.dir.left || d.dir.right ? 'block' : 'none'))
      .style('cursor', 'pointer')
      .on('click', (d) => {
        viewDetailsClicked(d.trendId)
      })
      .text(({ name }) => name)

    svg
      .append('g')
      .attr('class', 'xAxis axis--x')
      .attr('transform', `translate(0,${drawingRange.yAxis[0]})`)
      .style('color', '#e0e1e3')
      .call(xAxis)

    svg
      .append('g')
      .attr('class', 'xAxis axis--x')
      .attr('transform', `translate(0, 49)`)
      .style('color', '#e0e1e3')
      .call(x1Axis)

    svg
      .append('g')
      .attr('class', 'yAxis axis--y')
      .attr('transform', `translate(${drawingRange.xAxis[0]},0)`)
      .style('color', '#e0e1e3')
      .call(yAxis)

    svg
      .append('g')
      .attr('class', 'yAxis axis--y')
      .attr('transform', `translate(${drawingRange.xAxis[1]},0)`)
      .style('color', '#e0e1e3')
      .call(y1Axis)

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#f6c3d0')
      .attr('stroke-width', 4)

    svg
      .select('.axis--x')
      .selectAll('.tick')
      .each(function(d) {
        if (d === 0) {
          this.remove()
        }
      })

    const axisY = svg.select('.axis--y')
    const gridsY = svg.select('.axis-grid--y')

    // const xAxisGrid = d3.axisBottom(xAxis).tickSize(-(height - 40) + 100).tickFormat('').ticks(10);
    // const yAxisGrid = d3.axisRight(yAxis).tickSize(-width + 170).tickFormat('').ticks(10);

    let idleTimeout
    const idleDelay = 350

    var idled = () => {
      idleTimeout = null
    }

    const zoomIn = (selectionArea, noAnimate) => {
      if (noAnimate) {
        selectionArea.forEach((area) => {
          xScale.domain(
            [
              Math.max(drawingRange.xAxis[0], area[0][0]),
              Math.min(drawingRange.xAxis[1], area[1][0]),
            ].map(xScale.invert, xScale),
          )
          yScale.domain(
            [
              Math.min(drawingRange.yAxis[0], area[1][1]),
              Math.max(drawingRange.yAxis[1], area[0][1]),
            ].map(yScale.invert, yScale),
          )
        })
      } else {
        xScale.domain(
          [
            Math.max(drawingRange.xAxis[0], selectionArea[0][0]),
            Math.min(drawingRange.xAxis[1], selectionArea[1][0]),
          ].map(xScale.invert, xScale),
        )
        yScale.domain(
          [
            Math.min(drawingRange.yAxis[0], selectionArea[1][1]),
            Math.max(drawingRange.yAxis[1], selectionArea[0][1]),
          ].map(yScale.invert, yScale),
        )
      }
      svg.append('g').call(grid)
      zoom(true, noAnimate)
      svg.selectAll('.axis--dashed').remove()
      diagonalLine()
      if (!noAnimate) {
        appliedZoom.push(selectionArea)
      }
    }
    // Brush Zoom
    var brushended = (reset) => {
      var selectionArea = d3.event.selection
      if (!selectionArea || reset) {
        if (!idleTimeout && !reset)
          return (idleTimeout = setTimeout(idled, idleDelay))
        xScale.domain([0, 100])
        yScale.domain([0, 100])
        svg.select('#pairing-zoom-reset-btn').remove()
        zoom()
        svg.append('g').call(grid)
        appliedZoom = []
      } else {
        //  xScale.domain([selectionArea[0][0], selectionArea[1][0]].map(xScale.invert, xScale));
        //  yScale.domain([selectionArea[1][1], selectionArea[0][1]].map(yScale.invert, yScale));
        zoomIn(selectionArea)
        svg.select('.brush').call(brush.move, null)
      }
      svg.selectAll('.axis--dashed').remove()
      diagonalLine()
      svg
        .select('.axis--x')
        .selectAll('.tick')
        .each(function(d) {
          if (d === 0) {
            this.remove()
          }
        })
    }

    var zoom = (drawBtn, noAnimate) => {
      props.handleTooltipShow(false)
      const bordercolor = '#0274CA'
      if (drawBtn && !document.getElementById('pairing-zoom-reset-btn')) {
        // Draw reset Zoom
        const zoomBtn = svg
          .append('g')
          .attr('id', 'pairing-zoom-reset-btn')
          .attr('cursor', 'pointer')
        const resetZoomBtn = zoomBtn
          .append('rect')
          .attr('width', 110)
          .attr('height', 30)
          .attr('fill', '#FFFFFF')
          .attr('x', drawingRange.xAxis[1] - 110)
          .attr('y', paddingV + 5)
          .attr('rx', 5)
          .style('stroke', bordercolor)
        zoomBtn
          .append('text')
          .attr('x', drawingRange.xAxis[1] - 100)
          .attr('y', paddingV + 25)
          .attr('fill', '#8A97A7')
          .attr('font-size', 16)
          .text('Reset Zoom')

        zoomBtn.on('click', () => brushended(true))
      }
      const transition = svg.transition().duration(noAnimate ? 0 : 150)
      svg
        .select('.axis--x')
        .transition(transition)
        .call(xAxis)
      svg
        .select('.axis--y')
        .transition(transition)
        .call(yAxis)
      // svg.select(".axis-grid--y").transition(transition).call(yAxis);
      // svg.select(".axis-grid--x").transition(transition).call(xAxis);
      // svg.selectAll("line").style("display", "block");

      // // Remove redundant y axis lines after redraw
      // // axisY.select("path").remove()
      // // axisY.selectAll("line").remove()
      // // gridsY.select('path').remove()

      // // svg.selectAll(".drawn-data-points").transition().duration(150)
      // //     .attr("cx", (d)=> { return Math.round(xAxis(d['supply_score'])) })
      // //     .attr("cy", (d) => { return Math.round(yAxis(d['demand_score'])) });

      // // Zoom horizontal names

      const dataPoints = overlapResolver(
        wsoDataFormatter(data, xScale, yScale),
        labelOffsetFromCirc,
        overlapDrawingRange,
        radius,
        fontSize,
      )
      const dataLabels = svg.select('.data-point-names-x').selectAll('text')
      dataLabels
        .transition(transition)
        .attr('x', (d, i) => {
          return dataPoints[i].x
        })
        .attr('y', (d, i) => {
          return dataPoints[i].y
        })
        .style('display', (d, i) => {
          return dataPoints[i].dir.left || dataPoints[i].dir.right
            ? 'block'
            : 'none'
        })

      svg
        .selectAll('circle')
        .transition()
        .duration(0)
        .attr('cx', (d) => xScale(d.supply_score))
        .attr('cy', (d) => yScale(d.demand_score))
    }

    const brush = d3.brush().on('end', brushended)

    if (appliedZoom.length) {
      zoomIn(appliedZoom, true)
    }
    // brushLayer
    brushLayer
      .attr('clip-path', 'url(#clip)')
      .attr('class', 'brush')
      .call(brush)
    svg.select('.brush').select('.overlay')
    d3.select('#pairing-hover-tooltip-x').attr(
      'style',
      `left: ${width / 2 + 100}px; top: ${height - 25}px; position: absolute;`,
    )
    const yTooltip = d3
      .select('#pairing-hover-tooltip-y')
      .attr(
        'style',
        `left: ${28}px; top: ${drawingRange.yAxis[0] -
          215}px; position: absolute; transform: rotate(270deg);`,
      )
    yTooltip.select('.tooltiptext').attr('style', 'transform: rotate(90deg);')
  }, [data])

  return (
    <Box id="chart" sx={{ position: 'relative' }}>
      <IconBox>
        <div id="pairing-hover-tooltip-x">
          <InformationIcon
            tooltipText={`Supply score is based on the products available in the market`}
          />
        </div>
        <div id="pairing-hover-tooltip-y" className="tooltip">
          <InformationIcon
            tooltipText={`Demand score is based on Consumer engagement of a particular trend`}
          />
        </div>
      </IconBox>
      <svg
        id="wso-graph"
        ref={svgRef}
        onClick={() => props.handleTooltipShow(false)}
      ></svg>
      {props.tooltipShow && (
        <TooltipHoverCard
          tooltipShow={props.tooltipShow}
          project_id={props.wsoData.project_id}
          lensSelected={props.lensSelected}
          viewDetailsClicked={viewDetailsClicked}
        />
      )}
    </Box>
  )
}

export default WhiteSpaceGraph
