import typography from '../typography';
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.default,
      '& .MuiTableRow-root': {
        '& .MuiTableCell-head': {
          ...typography.caption,
          color: palette.primary.main,
        },
      },
    },
  },
};

