
import { Link } from "@mui/material";
import { styled } from "@mui/system";

export const StyledLink = styled(Link)(({theme}) => ({
    '&:focus': {
      color: `${theme.palette.colors.hoverLink} !important`,
      textDecorationColor: '#06B0F1 !important',
      textDecorationLine: 'underline !important',
    },
    '&:hover': {
      color: `${theme.palette.black} !important`,
      textDecorationColor: 'none !important',
      textDecorationLine: 'none !important',
    },
  }))