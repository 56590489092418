import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material'

const ReportDialogLink = ({
  thumbnailUrl,
  name,
  report,
  initOpen = false,
  reportType,
  handleClickOpen,
  handleClose,
  globalSearch = false,
}) => {
  return (
    <React.Fragment>
      <Box>
        <Box>
          {globalSearch ? (
            <Typography
              sx={{
                fontSize: '12px',
                paddingTop: '5px',
              }}
            >
              {name}
            </Typography>
          ) : (
            <>
              {' '}
              <Typography variant="caption" color="textSecondary">
                {reportType}
              </Typography>
              <br />
              <Link
                variant="body2"
                underline="none"
                href="/#"
                onClick={handleClickOpen}
              >
                {thumbnailUrl && (
                  <Typography
                    variant="subtitle3"
                    sx={{ color: 'primary.main' }}
                  >
                    {name}
                  </Typography>
                )}
              </Link>
            </>
          )}
          <Dialog
            fullWidth
            maxWidth="lg"
            open={initOpen}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '2px 2px 14px 0px #24242429',
              },
            }}
          >
            <DialogTitle sx={{ mt: 2 }}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 20,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} md={12}>
                  <Paper>
                    <embed
                      id="reportEm"
                      style={{ width: '100%', height: '78vh' }}
                      src={
                        report + '#zoom=104&toolbar=0&navpanes=1&scrollbar=1'
                      }
                      type="application/pdf"
                      title={name}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </React.Fragment>
  )
}

ReportDialogLink.propTypes = {
  numberOfPaires: PropTypes.number.isRequired,
}

export default ReportDialogLink
