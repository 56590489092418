import React from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import AnalysedTrendCardList from './AnalysedTrendCardList'
import noAnalysedTrend from './../../assets/images/EmptyState/noAnalysedTrend.svg'
import NoResultFound from '../../shared/components/NoResultFound/NoResultFound'

const AnalysedTrends = ({
  tabValue,
  handleTabChange,
  analysedTrends,
  setAnalysedTrends,
  addToExcludeList,
  setTags,
  tags,
  setHoveredCard,
  logAnalysedTrends,
  hoveredCard,
  hoverCardRef,
  clickedCard,
}) => {
  const handleCancel = (cardData) => {
    if (
      analysedTrends[cardData.type].find(
        (trend) => trend.name.toLowerCase() === cardData.name.toLowerCase(),
      )
    ) {
      const newAnalysedTrends = analysedTrends[cardData.type].filter((data) => {
        return data.name.toLowerCase() !== cardData.name.toLowerCase()
      })
      console.log('newAnalysedTrends', newAnalysedTrends)
      setAnalysedTrends({
        ...analysedTrends,
        [cardData.type]: newAnalysedTrends,
      })
    }

    if (
      tags.map((tag) => tag.toLowerCase()).includes(cardData.name.toLowerCase())
    ) {
      const updatedTags = tags.filter(
        (tag) => tag.toLowerCase() !== cardData.name.toLowerCase(),
      )

      setTags(updatedTags)
    }
    logAnalysedTrends(cardData?.name, 'Remove')
    addToExcludeList(cardData.name)
  }

  const listData =
    tabValue === 0 ? analysedTrends?.ingredient : analysedTrends?.theme

  return (
    <Box sx={{ height: '100%' }}>
      <Typography sx={{ fontWeight: 400, fontSize: '16px', pt: 2, px: 2 }}>
        Referenced Trends
      </Typography>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Analysed trends tab"
          variant="primary"
          inkBarStyle={{ background: 'blue' }}
          TabIndicatorProps={{
            sx: {
              background: ({ palette }) => `${palette.primary.main} !important`,
            },
          }}
        >
          <Tab variant="withoutBackground" label="Ingredient" />
          <Tab variant="withoutBackground" label="Themes" />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '295px',
          height: 'auto',
          maxHeight: '700px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '4px',
          },

          '&::-webkit-scrollbar-track': {
            backgroundColor: ({ palette }) => palette.grey[100],
            borderRadius: 8,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: ({ palette }) => palette.grey[500],
            borderRadius: 8,
          },
        }}
      >
        {listData?.length > 0 ? (
          <AnalysedTrendCardList
            listData={listData}
            handleCancel={handleCancel}
            tabValue={tabValue}
            setHoveredCard={setHoveredCard}
            logAnalysedTrends={logAnalysedTrends}
            hoveredCard={hoveredCard}
            hoverCardRef={hoverCardRef}
            clickedCard={clickedCard}
          />
        ) : (
          <NoResultFound
            imgSrc={noAnalysedTrend}
            imgSx={{ width: '260px', mt: 10 }}
            messageSx={{ width: '260px' }}
            message="No Trends Analysed, Please regenerate to see analysis!"
          />
        )}
      </Box>
    </Box>
  )
}

export default AnalysedTrends
