import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { WsoDropdown } from '../../../../containers/WhiteSpace/Styled'

const CountrySelect = ({
  countries,
  value,
  onChange,
  noLabel,
  noCountryFlag,
  component,
}) => (
  <Autocomplete
    id="country-select"
    openOnFocus="true"
    value={value}
    onChange={onChange}
    sx={{
      width: 250,
    }}
    options={countries}
    getOptionLabel={(option) => option.label || ''}
    renderOption={(props, option) => (
      <Box
        component="li"
        sx={{
          '& > img': {
            mr: 1,
            flexShrink: 0,
            overflow: 'hidden',
          },
        }}
        {...props}
      >
        {!noCountryFlag && (
          <img
            loading="lazy"
            width="30"
            height="20"
            src={
              option.img
                ? option.img
                : `https://flagcdn.com/${option.code.toLowerCase()}.svg`
            }
            srcSet={
              option.img
                ? option.img
                : `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`
            }
            alt={option.label}
          />
        )}
        {option.label}
      </Box>
    )}
    renderInput={(params) =>
      component ? (
        <WsoDropdown
          {...params}
          label={!noLabel && 'Country :'}
          placeholder="e.g. China"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      ) : (
        <TextField
          {...params}
          label={!noLabel && 'Country'}
          placeholder="Select country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment,
            startAdornment: value ? (
              <img
                width="30"
                height="20"
                src={
                  value.img
                    ? value.img
                    : `https://flagcdn.com/${value.code.toLowerCase()}.svg`
                }
                srcSet={
                  value.img
                    ? value.img
                    : `https://flagcdn.com/w40/${value.code.toLowerCase()}.png 2x`
                }
                alt={value.label}
              />
            ) : null,
          }}
        />
      )
    }
  />
)

export default CountrySelect
