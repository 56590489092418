import { commonTrendAnalysis } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchCommonTrendAnalysisList = (
  lans,
  maturityPhase,
  across,
  lookup,
) => {
  return (dispatch) => {
    dispatch(fetchCTAListStart())
    const request = NetworkUtil.commonTrendAnalysisAcross(
      lans,
      maturityPhase,
      across,
      encodeURIComponent(lookup),
    )
    axios
      .get(request)
      .then((response) => {
        dispatch(fetchCTAListSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCTAListFailed(error))
      })
  }
}

const fetchCTAListSuccess = (commonTrend) => {
  return {
    type: commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_SUCCESS],
    commonTrend: commonTrend,
  }
}

const fetchCTAListFailed = (error) => {
  return {
    type: commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_FAILED],
    error: error,
  }
}

const fetchCTAListStart = () => {
  return {
    type: commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_START],
  }
}
