import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Divider, MenuItem, Typography, Badge } from '@mui/material'
import { Box } from '@mui/system'
import { CheckCircle, NotificationsNoneRounded } from '@mui/icons-material'

import NotificationItem from '../NotificationItem'
import { amplify, getQueryParams } from '../../../store/utility'

import { IconButtonRoot, NotificationMenu } from './styles'

const NotificationsButton = ({
  notifications,
  markAsRead,
  setUserNotified,
  email,
}) => {
  const [anchorElNotifications, setAnchorElNotification] = useState(null)

  const handleOpenNotifications = (event) => {
    setUserNotified()
    setAnchorElNotification(event.currentTarget)
    const ampiEvent = 'Clicked_Notification'
    const ampiUserData = {
      User: email,
      Notification_Name: notifications,
    }
    amplify(ampiEvent, ampiUserData, email)
  }
  const history = useHistory()
  const location = useLocation()
  const handleCloseNotifications = () => {
    setAnchorElNotification(null)
  }

  const openTargetMarkAsRead = (slug, id = null, url = null, title) => {
    if (slug) {
      markAsRead(slug)
    }
    if (id != null) {
      const query = getQueryParams()
      query.set('activeReport', id)
      if (location.pathname === '/mui/main-system') {
        history.push(`?${query.toString()}`)
      } else {
        history.push(`/mui/main-system?${query.toString()}`)
      }
    } else {
      const pathname = new URL(url).pathname
      history.push(pathname)
    }
    const ampiEvent = 'Clicked_Notification_Content'
    const ampiUserData = {
      User: email,
      Notification_Title: title,
    }
    amplify(ampiEvent, ampiUserData, email)
    handleCloseNotifications()
  }

  const unreadCount = notifications
    ? notifications.filter(({ unread }) => unread).length
    : 0

  const notificationList = notifications
    ? notifications.map(({ verb, id, data, unread, timestamp, slug }) => {
        let content = ''
        let title = ''
        switch (verb) {
          case 'Shared Trend':
            title = `${data.shared_by} has shared ${
              data.trend_name
            } - ${data.lens[0].toUpperCase()}${data.lens.slice(1)}`
            content = (
              <Typography variant="caption" color="textSecondary">
                <Typography variant="caption">
                  &quot;{data.shared_by}&quot;&nbsp;
                </Typography>{' '}
                has shared
                <Typography variant="caption">
                  &nbsp;&quot;{data.trend_name} -{' '}
                  {`${data.lens[0].toUpperCase()}${data.lens.slice(1)}`}&quot;
                </Typography>
                &nbsp; with you.
              </Typography>
            )
            break
          default:
            title = data.report_title
            content = (
              <Typography variant="caption" color="textSecondary">
                Latest report for &nbsp;
                <Typography variant="caption">
                  &quot;
                  {data.report_title}
                  &quot;
                </Typography>
                &nbsp; has been uploaded.
              </Typography>
            )
        }
        return (
          <Fragment key={id}>
            <Divider />
            <NotificationItem
              isNew={unread}
              name={data.report_title}
              image={data.image_url}
              timestamp={timestamp}
              slug={slug}
              content={content}
              openNotification={() =>
                openTargetMarkAsRead(
                  unread ? slug : null,
                  data.report_id,
                  data.trend_url,
                  title,
                )
              }
            />
          </Fragment>
        )
      })
    : []

  return (
    <>
      <IconButtonRoot
        aria-label={unreadCount > 9 ? '9+' : unreadCount}
        size="medium"
        fontSize="medium"
        sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
        onClick={handleOpenNotifications}
        active={Boolean(anchorElNotifications)}
        title="Notifications"
      >
        {unreadCount ? (
          <Badge
            badgeContent={unreadCount > 9 ? '9+' : unreadCount}
            color="secondary"
          >
            <NotificationsNoneRounded fontSize="small" />
          </Badge>
        ) : (
          <NotificationsNoneRounded fontSize="small" />
        )}
      </IconButtonRoot>
      {notifications && !!notifications.length && (
        <NotificationMenu
          anchorEl={anchorElNotifications}
          open={Boolean(anchorElNotifications)}
          onClose={handleCloseNotifications}
        >
          <MenuItem>
            <Box>
              <Typography variant="subtitle3">Notifications</Typography>
              {/* {!!unreadCount &&
              <Button
                  size="medium"
                  endIcon={<CheckCircle />}
                >
                Mark all as read
              </Button>
              } */}
            </Box>
          </MenuItem>
          {notificationList}
        </NotificationMenu>
      )}
    </>
  )
}

NotificationsButton.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default NotificationsButton
