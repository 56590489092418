import * as React from 'react'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { Box } from '@mui/system'

import formatNumber from './../../../shared/utils/formatNumber'

const HoverCard = ({ data, viewDetailsHandle, hoverCardSx }) => {
  const { image, name, firstLabel, firstValue, secondLabel, secondValue } = data
  const {
    imgSx,
    typographySx,
    cardContentSx,
    nameSx,
    labelSx,
    valSx,
  } = hoverCardSx

  return (
    <>
      <CardMedia
        component="img"
        height="140"
        sx={imgSx}
        image={image}
        alt="Image not available"
      />

      <CardContent sx={cardContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography gutterBottom variant="subtitle3" sx={nameSx}>
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 1,
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={labelSx} variant="caption">
                {firstLabel}
              </Typography>
              <Typography sx={valSx} variant="caption">
                {firstValue}%
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={labelSx} variant="caption">
                {secondLabel}
              </Typography>
              <Typography sx={valSx} variant="caption">
                {formatNumber(secondValue)}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{ width: '100%', mt: 1, mb: -1, borderRadius: '5px' }}
            size="small"
            onClick={viewDetailsHandle}
          >
            View Details
          </Button>
        </Box>
      </CardContent>
    </>
  )
}

export default HoverCard
