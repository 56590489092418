
import { ResetActions } from './actionTypes'

export const resetApp = () => {
  return {
    type: ResetActions.type[ResetActions.RESET_APP],
  }
}

export const resetProjectDetails = () => {
  return {
    type: ResetActions.type[ResetActions.RESET_PROJECT_DETAILS],
  }
}

export const resetProductDetails = () => {
  return {
    type: ResetActions.type[ResetActions.RESET_PRODUCT_DETAILS],
  }
}

export const resetProductNeeds = () => {
  return {
    type: ResetActions.type[ResetActions.RESET_PRODUCT_NEEDS],
  }
}

export const resetWsoData = () => {
  return {
    type: ResetActions.type[ResetActions.RESET_WSO_DATA],
  }
}