import { connect } from 'react-redux'
import { Sidebar } from '../../components'
const mapDispatchToprops = (dispatch, ownProps) => ({

})

const mapStateToProps = (state) => ({
  loggedInUserRole: state.user.loggedIn && state.user.loggedIn.role ? state.user.loggedIn.role : null,
})

const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToprops
)(Sidebar)
export default SidebarContainer
