import styled from '@emotion/styled';
import { Box } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  display: 'flex',
  gap: theme.spacing(1),
  paddingRight: theme.spacing(0.5),
  flexDirection: 'column',
  '&::-webkit-scrollbar': {
    width: theme.spacing(0.5),
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));
