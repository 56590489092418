import store from './../indexStore'

export class AmplitudeValues {
  constructor(detail = 'productDetails') {
    const details = store.getState().productDetails.productDetails
    if (detail === 'productDetails') {
      this._country =
        details && store.getState().productDetails.productDetails.country

      this._category =
        details && store.getState().productDetails.productDetails.category

      this._name =
        details && store.getState().productDetails.productDetails.name

      this._trendName =
        details && store.getState().productDetails.productDetails.trendName
    }

    this._email = store.getState().user.loggedIn.user.email
  }

  get email() {
    return this._email
  }

  get country() {
    return this._country
  }

  get category() {
    return this._category
  }

  get name() {
    return this._name
  }

  get trendName() {
    return this._trendName
  }
}
