import React, { useState } from 'react'
import Table from '@mui/material/Table'
import {
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Box,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import GenericTableHead from '../../shared/components/GenericTableComponent/GenericTableHead'

const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (id === 'selection' || id === 'arrow' || id === 'remove') {
      return func ? func(rowData) : rowData
    } else if (rowData[id] != null) {
      return func ? func(rowData[id]) : rowData[id]
    }
  })
  return res
}

const SubTableBody = ({ row, subHeadCells }) =>
  row.map((historyRow) => (
    <TableRow>
      <GenericTableCell rowData={historyRow} tableHeadCells={subHeadCells} />
    </TableRow>
  ))

export const arrowIcon = (isExpanded, handleState) => {
  return (
    <TableCell
      onClick={() => {
        isExpanded && handleState([])
      }}
    >
      <IconButton aria-label="expand row" size="small" data-testId="icon">
        {isExpanded ? (
          <>
            <ExpandMoreIcon fontSize="medium" />
          </>
        ) : (
          <>
            <NavigateNextIcon fontSize="medium" />
          </>
        )}
      </IconButton>
    </TableCell>
  )
}

const rowdisplayFunction = (
  row,
  isExpanded,
  handleState,
  subHeadCells,
  heading,
  headingOnExpand,
  subData,
  handleRequestSort,
) => {
  return (
    <>
      <TableRow data-testId="tableRow">
        {arrowIcon(isExpanded, handleState)}

        <TableCell align="left" colSpan={9}>
          <Typography variant="subtitle2">{`${heading} ${row[headingOnExpand]}`}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: '0px' }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <GenericTableHead
                  headCells={subHeadCells}
                  headHeight={50}
                  border={(theme) => `2px solid ${theme.palette.divider}`}
                  handleRequestSort={handleRequestSort}
                />
                <TableBody>
                  {subData.length ? (
                    <SubTableBody row={subData} subHeadCells={subHeadCells} />
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                        left: '45vw',
                        top: '45px',
                        height: '100px',
                      }}
                    >
                      <Typography>No result found</Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const GenericMainBody = ({
  row,
  open,
  headCells,
  subHeadCells,
  heading,
  headingOnExpand,
  subData,
  handleState,
  handleRequestSort,
}) => {
  if (!row?.length)
    return (
      <Box
        sx={{
          position: 'relative',
          left: '45vw',
          top: '150px',
        }}
      >
        <Typography variant="subtitle2">No data found</Typography>
      </Box>
    )
  const renderElement = [...row].map((row) =>
    open === row.id ? (
      subData &&
      rowdisplayFunction(
        row,
        open === row.id,
        handleState,
        subHeadCells,
        heading,
        headingOnExpand,
        subData,
        handleRequestSort,
      )
    ) : (
      <TableRow data-testId="tableRow">
        <>
          <GenericTableCell rowData={row} tableHeadCells={headCells} />
        </>
      </TableRow>
    ),
  )

  return renderElement
}

export default GenericMainBody
