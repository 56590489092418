import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Divider,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  Backdrop,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Alert,
  Collapse,
  IconButton,
} from '@mui/material'
import PropTypes from 'prop-types'
import ConsumerQuotesInstagram from './partials/ConsumerQuotesInstagram'
import ConsumerQuotesFacebook from './partials/ConsumerQuotesFacebook'
import ConsumerQuotesWeibo from './partials/ConsumerQuotesWeibo'
import ConsumerQuotesTwitter from './partials/ConsumerQuotesTwitter'
import ConsumerQuotesMeituan from './partials/ConsumerQuotesMeituan'
import ConsumerQuotesAmazon from './partials/ConsumerQuotesAmazon'
import ConsumerReview from './partials/ConsumerReview'
import ConsumerQuotesBlogsAndWebsites from './partials/ConsumerQuotesBlogsAndWebsites'
import TradeMedia from '../TradeMedia'
import Multiselect from '../Multiselect'
import ConsumerQuotesLittleRedBook from './partials/ConsumerQuotesLittleRedBook'
import { OTHER_REASON_MAX_LENGTH, tradeMedia, ALL} from '../../utils/constants.js'
import QuotesItemsContainer from './styles'
import { amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { ErrorOutlineRounded, ErrorRounded, Close } from '@mui/icons-material'
import axios from '../../../services/networking/axios'
import store from '../../../indexStore'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import OverlayLoader from '../OverlayLoader'

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const tradeMediaLowerCased = tradeMedia.map((media) => media.toLowerCase())
const verbatimsIrrelevantReasons = [
  ['Wrong Location', 'Wrong Language', 'Wrong Category'],
  ['Wrong Trend', 'Non Food and Beverage related', 'Others'],
]

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance._email,
    }
    switch (ampiEvent) {
      case 'Clicked_Consumer_Quotes_Filter':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiInstance._name,
          Country_Name: ampiInstance._country,
          Category_Name: ampiInstance._category,
          Source_Name: ampiUserData.sourceName,
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          ...ampiUserData,
        }
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesCardComponent')
  }
}
const getMarkIrrelevantAmplitudeData = (
  country,
  category,
  trendName,
  details,
  lens,
) => {
  const type = details?.type
  const ampiEvent = `Clicked_${type || 'Trend_Lens'}_Verbatim_Mark_Irrelevant`
  let ampiUserData = {
    Country_Name: country,
    Category_Name: category,
    Trend_Name: trendName,
    Lens: lens,
  }
  switch (type) {
    case 'Trend_Level_CN':
      ampiUserData = {
        ...ampiUserData,
        Trend_Name: details?.trendName,
        Consumer_Need: trendName,
      }
      break
    case 'Trend_Level_SCN':
      ampiUserData = {
        ...ampiUserData,
        Trend_Name: details?.trendName,
        Consumer_Need: details?.consumerNeed,
        Subconsumer_Need: trendName,
      }
      break
    case 'Demography':
      ampiUserData = {
        ...ampiUserData,
        Agegroup_Size: details?.ageGroup,
      }
      break
    case 'Regional':
      ampiUserData = {
        ...ampiUserData,
        Region_Name: details?.region,
      }
      break
    case 'Regional_CN':
      ampiUserData = {
        ...ampiUserData,
        Region_Name: details?.region,
        Trend_Name: details?.trendName,
        Consumer_Need: trendName,
      }
      break
    default:
    // Do nothing
  }
  return [ampiEvent, ampiUserData]
}
const quoteResolver = (quoteKey, operation) => {
  switch (quoteKey) {
    case '_usptoQuotes':
      operation.patent()
      break
    case '_foodnavigatorQuotes':
    case '_eatrightproQuotes':
    case '_nutraIngredientsQuotes':
    case '_americansocietyQuotes':
    case '_fooddiveQuotes':
    case '_IFTQuotes':
    case '_jpFoodQuotes':
    case '_jpFoodJournalQuotes':
    case '_independentQuotes':
    case '_hindawiQuotes':
    case '_koreaHeraldQuotes':
    case '_foodScienceNutritionQuotes':
    case '_fMCGMagazineQuotes':
    case '_fdBusinessQuotes':
    case '_fdCanadaQuotes':
    case '_foodDrinkNetworkQuotes':
    case '_foodDrinkEuropeQuotes':
    case '_fbNewsCanadaQuotes':
    case '_fdEuropeQuotes':
    case '_fdNetworkQuotes':
    case '_euJournalNutritionQuotes':
    case '_cnfcNewsQuotes':
    case '_cnnQuotes':
    case '_cnbcQuotes':
    case '_candyIndustryQuotes':
    case '_cafbQuotes':
    case '_bjnQuotes':
    case '_bbcQuotes':
    case '_bakerySnacksQuotes':
    case '_asiaFdScJnQuotes':
    case '_acadJnQuotes':
    case '_abcQuotes':
    case '_longdomQuotes':
    case '_mdpiQuotes':
    case '_myFoodResearchQuotes':
    case '_natureTMQuotes':
    case '_prNewsWireQuotes':
    case '_scienceAlertQuotes':
    case '_scienceDirectQuotes':
    case '_snackFoodWholesaleBakeryQuotes':
    case '_taylorQuotes':
    case '_unesdaQuotes':
      operation.trade()
      break
    default:
      operation.social()
  }
}

const quoteSort = (arr) => {
  return [...arr].sort(
    (quoteA, quoteB) =>
      new Date(quoteB.timestamp).getTime() -
      new Date(quoteA.timestamp).getTime(),
  )
}

const ConsumerQuotesCardComponent = (props) => {
  const [quoteCategories, setQuoteCategories] = useState([])
  const [selectedPlatform, setSelectedPlatform] = useState(ALL)
  const defaultShow = {
    checked: false,
  }
  const socialQuotes = []
  const patentQutoes = []
  const tradeQuotes = []

  const [isVerbatimsBackdropOpen, setIsVerbatimsBackdropOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [reasons, setReasons] = useState([])
  const [irrelevancies, setIrrelevancies] = useState([])
  const [isOtherOptionSelected, setIsOtherOptionSelected] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [loader, setLoader] = useState(false)
  const dialogElement = useRef(null)
  const [error, setError] = useState(false)
  const [filteredSocialQuotes, setFilteredSocialQuotes] = useState([])
  const [bpcReviewPlatforms,setBpcReviewPlatforms] = useState([])
  let uniquePlatform = []

  const isSidePanelView = props.isSidePanelView

  const toggleVerbatimsBackdrop = () => {
    setIsVerbatimsBackdropOpen(!isVerbatimsBackdropOpen)
    closeDialog()
    if (showAlert === true && irrelevancies.length >= 1) {
      setShowAlert(false)
    } else {
      setIrrelevancies([])
    }
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
    setIsOtherOptionSelected(false)
    setReasons([])
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  const toggleShowAlert = () => {
    setShowAlert(!showAlert)
  }

  const handleVerbatimPlatformChange = (platformName) => {
    if (platformName?.target?.value) {
      setSelectedPlatform(platformName?.target?.value)
    }
  }

  useEffect(() => {
    if (showAlert === false) {
      const timer = setTimeout(() => {
        setIrrelevancies([])
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const updateOtherReason = (value) => {
    if (value.length > OTHER_REASON_MAX_LENGTH) {
      setError(true)
    }
    if (value.length <= OTHER_REASON_MAX_LENGTH) {
      setOtherReason(value)
      setError(false)
    }
  }

  const resetVerbatimsState = () => {
    setIsVerbatimsBackdropOpen(false)
    setReasons([])
    setOtherReason('')
    setLoader(false)
  }

  const updateIrrelevantDocs = () => {
    setIsDialogOpen(false)
    const productDetails = store.getState()?.productDetails?.productDetails
    let name = props.name
    if (productDetails && productDetails.name) {
      name = productDetails.name
    }
    const email = store.getState().user.loggedIn.user.email
    const data = {
      es_ids: irrelevancies,
      country: props.country,
      category: props.category,
      trend: name,
      lens: props.lens,
      marked_by: email,
      reasons: reasons,
      other_reason: otherReason,
    }
    setLoader(true)
    axios
      .post(NetworkUtil.getMarkIrrelevantCQUrl(), data)
      .then((response) => {
        setShowAlert(true)
        resetVerbatimsState()
        props.onRefresh()
      })
      .catch((error) => {
        console.error(error)
        setShowAlert(true)
        resetVerbatimsState()
        props.onRefresh()
      })
  }

  useEffect(() => {
    if (isDialogOpen && dialogElement.current) {
      dialogElement.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      })
    }
  }, [isDialogOpen])

  const onCheckboxValueChange = (e) => {
    if (e.target.value === 'Others' && e.target.checked) {
      setIsOtherOptionSelected(true)
    }
    if (e.target.checked) {
      setReasons([...reasons, e.target.value])
    } else {
      if (e.target.value === 'Others') {
        setIsOtherOptionSelected(false)
      }
      setReasons(reasons.filter((r) => r !== e.target.value))
    }
  }

  const onMarkIrrelevant = (e) => {
    if (e.target.checked) {
      setIrrelevancies([...irrelevancies, e.target.value])
    } else {
      setIrrelevancies(irrelevancies.filter((r) => r !== e.target.value))
    }
  }
  if (props.consumerQuotes) {
    for (const [key, value] of Object.entries(props.consumerQuotes)) {
      if (value) {
        quoteResolver(key, {
          patent: () => {
            patentQutoes.push(value)
          },
          trade: () => {
            tradeQuotes.push(value)
          },
          social: () => {
            socialQuotes.push(value)
          },
        })
      }
    }
  }

  const handleSelect = (arrSelected) => {
    const { label, checked } = arrSelected
    const ampiEvent = `Clicked_Consumer_Quotes_Filter`
    const ampiUserData = {
      sourceName: label,
    }
    amp(ampiEvent, ampiUserData)
    if (label === ALL) {
      setQuoteCategories((qCat) =>
        qCat.map((cat) => ({
          ...cat,
          checked: !checked,
        })),
      )
    } else {
      const selctdCount = quoteCategories.filter(
        ({ checked, label }) => checked && label !== ALL,
      ).length
      if (selctdCount === quoteCategories.length - 2 && !checked) {
        setQuoteCategories((qCat) =>
          qCat.map((cat) => ({
            ...cat,
            checked: true,
          })),
        )
      } else {
        setQuoteCategories((qCat) =>
          qCat.map((cat) =>
            label === cat.label
              ? {
                  ...cat,
                  checked: !checked,
                }
              : cat.label === ALL
              ? {
                  ...cat,
                  checked: false,
                }
              : cat,
          ),
        )
      }
    }
  }

  const onMarkIrrelevantButtonClick = () => {
    toggleVerbatimsBackdrop()
    if (!props.disableAmplitude) {
      const [ampiEvent, ampiUserData] = getMarkIrrelevantAmplitudeData(
        props.country,
        props.category,
        props.name,
        props.details,
        props.lens,
      )
      amp(ampiEvent, ampiUserData)
    }
  }

  const logMarkIrrelevantActionClick = (type) => {
    let [ampiEvent, ampiUserData] = getMarkIrrelevantAmplitudeData(
      props.country,
      props.category,
      props.name,
      props.details,
      props.lens,
    )
    ampiEvent = `${ampiEvent}_Action`
    const options = [...reasons]
    ampiUserData = {
      ...ampiUserData,
      Action: type,
      Options: options,
    }
    amp(ampiEvent, ampiUserData)
  }

  const onMarkIrrelevantActionClick = (type) => {
    if (!props.disableAmplitude) {
      logMarkIrrelevantActionClick(type)
    }
    if (type === 'Submit') {
      updateIrrelevantDocs()
    } else {
      closeDialog()
    }
  }

  const showAll = (
    quoteCategories.find(({ label }) => label === ALL) || defaultShow
  ).checked
  const showSocial = (
    quoteCategories.find(({ label }) => label === 'Social Data') || defaultShow
  ).checked
  const showTrade = (
    quoteCategories.find(({ label }) => label === 'Trade media') || defaultShow
  ).checked
  const showPatent = (
    quoteCategories.find(({ label }) => label === 'Patent Data') || defaultShow
  ).checked

  const fetchBPCReviewPlatforms = async () => {
    try {
      const requestURL = NetworkUtil.fetchBPCReviewPlatforms()
      const response = await axios.get(requestURL)
      const lowerCaseReviewPlatforms = response.data.review_platforms.map(platform => platform.toLowerCase())
      setBpcReviewPlatforms(lowerCaseReviewPlatforms)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    const categories = []
    fetchBPCReviewPlatforms()
    if (props.consumerQuotes) {
      for (const [key, value] of Object.entries(props.consumerQuotes)) {
        quoteResolver(key, {
          patent: () => {
            if (
              !categories.some(({ label }) => label === 'Patent Data') &&
              value &&
              value.length
            ) {
              categories.push({
                label: 'Patent Data',
                key: 'Patent Data',
                checked: true,
              })
            }
          },
          trade: () => {
            if (
              !categories.some(({ label }) => label === 'Trade media') &&
              value &&
              value.length
            ) {
              categories.push({
                label: 'Trade media',
                key: 'Trade media',
                checked: true,
              })
            }
          },
          social: () => {
            if (
              !categories.some(({ label }) => label === 'Social Data') &&
              value &&
              value.length
            ) {
              categories.push({
                label: 'Social Data',
                key: 'Social Data',
                checked: true,
              })
            }
          },
        })
      }
    }
    setQuoteCategories([
      {
        label: ALL,
        key: ALL,
        checked: true,
      },
      ...categories,
    ])
  }, [props.consumerQuotes])

  function filterByPlatform(nestedQuotesArray) {
    if (selectedPlatform !== ALL) {
      return nestedQuotesArray.map(quotes => 
        quotes.filter(quote => quote.platform.toLowerCase() === selectedPlatform.toLowerCase()),
      )
    }
    return nestedQuotesArray
  }
  useEffect(()=>{
    setFilteredSocialQuotes(filterByPlatform(socialQuotes))
  },[selectedPlatform,props.consumerQuotes])

  if (!props.consumerQuotes) return null
  const quotesList = [
    ...quoteSort(
      filteredSocialQuotes?.map((socialQuote) => socialQuote.slice(0, 20)).flat(),
    ),
    ...quoteSort(tradeQuotes.flat().slice(0, 20)),
    ...quoteSort(patentQutoes.flat().slice(0, 20)),
  ].map((quote) => {
    if (!quote) {
      return null
    }
    const duplicatePlatformList = socialQuotes
      ?.flat()
      ?.map((platformDetail) => capitalizeFirstLetter(platformDetail.platform))
    uniquePlatform = [...new Set(duplicatePlatformList)]

    let quoteItem = null
    const renderSocial = (component) => (showSocial ? component : null)
    const platform = quote?.platform.toLowerCase()
    if (
      platform === 'instagram' &&
      (selectedPlatform === 'Instagram' || selectedPlatform === ALL)
    )
      quoteItem = renderSocial(
        <ConsumerQuotesInstagram
          timestamp={quote.timestamp}
          content={quote.content}
          likes={quote.likes}
          imagePath={quote.image_path}
          link={quote.link}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    else if (
      platform === 'twitter' &&
      (selectedPlatform === 'Twitter' || selectedPlatform === ALL)
    )
      quoteItem = renderSocial(
        <ConsumerQuotesTwitter
          timestamp={quote.timestamp}
          text={quote.text}
          likes={quote.likes}
          tweetUrl={quote.tweet_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    else if (platform === 'weibo')
      quoteItem = renderSocial(
        <ConsumerQuotesWeibo
          timestamp={quote.timestamp}
          text={quote.text}
          postImageUrl={quote.post_image_url}
          city={quote.city}
          postUrl={quote.post_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    else if (platform === 'facebook')
      quoteItem = renderSocial(
        <ConsumerQuotesFacebook
          timestamp={quote.timestamp}
          text={quote.text}
          likes={quote.likes}
          postImageUrl={quote.post_image_url}
          url={quote.url}
          lens={props.lens}
        />,
      )
    else if (platform === 'little red book')
      quoteItem = renderSocial(
        <ConsumerQuotesLittleRedBook
          content={quote.content}
          timestamp={quote.timestamp}
          likes={quote.likes}
          postImageUrl={quote.post_image_url}
          comments={quote.comments}
          shares={quote.shares}
          postUrl={quote.post_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    else if (platform === 'meituan') {
      quoteItem = renderSocial(
        <ConsumerQuotesMeituan
          content={quote.text}
          timestamp={quote.timestamp}
          rating={quote.rating}
          link={quote.restaurant_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    } else if (
      platform === 'amazon' &&
      (selectedPlatform === 'Amazon' || selectedPlatform === ALL)
    ) {
      quoteItem = renderSocial(
        <ConsumerQuotesAmazon
          content={quote.text}
          timestamp={quote.timestamp}
          rating={quote.rating}
          link={quote.product_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    } else if (tradeMediaLowerCased.includes(platform) && showTrade)
      quoteItem = (
        <TradeMedia
          title={quote.author}
          content={quote.text}
          description={quote.title}
          date={quote.timestamp}
          url={quote.input_url}
          platform={quote.platform}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />
      )
    else if (platform === 'uspto' && showPatent)
      quoteItem = (
        <TradeMedia
          title={quote.patent_number}
          content={quote.text}
          description={quote.title}
          date={quote.timestamp}
          platform="Uspto"
          url={quote.input_url}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />
      )
    else if((selectedPlatform === ALL || selectedPlatform.toLowerCase() === platform.toLowerCase()) && bpcReviewPlatforms.includes(platform) && !quote?.headline) {
        quoteItem = renderSocial(
          <ConsumerReview
            content={quote?.platform_specific.review_text}
            timestamp={quote?.review_date}
            rating={quote?.platform_specific.rating}
            link={quote.input_url}
            lens={props.lens}
            logConsumerQuotesClicked={props.logConsumerQuotesClicked}
            type={props.type}
            platform={quote?.platform}
          />,
        )
      }
    else if (
      platform.toLowerCase() === selectedPlatform.toLowerCase() ||
      selectedPlatform === ALL
    ) {
      quoteItem = renderSocial(
        <ConsumerQuotesBlogsAndWebsites
          content={quote.article_text}
          image={quote.image}
          timestamp={quote.timestamp}
          headline={quote.headline}
          platform={platform}
          link={quote?.article_url || quote?.input_url}
          lens={props.lens}
          logConsumerQuotesClicked={props.logConsumerQuotesClicked}
          type={props.type}
        />,
      )
    }

    return (
      quoteItem && (
        <Grid
          key={quote.id}
          xs={12}
          md={props.hasFullWidth ? 12 : 3}
          item
          display="flex"
          sx={{
            position: 'relative',
          }}
        >
          {quoteItem}
          <Backdrop
            opacity="0.3"
            sx={{
              backgroundColor: '#000000',
              position: 'absolute',
              opacity: '0.3 !important',
              margin: '16px 0 0 16px',
              borderRadius: '4px',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isVerbatimsBackdropOpen}
          >
            <Checkbox
              sx={{
                justifyContent: 'flex-start',
                position: 'absolute',
                left: '16px',
                right: '16px',
                bottom: '16px',
              }}
              labelStyle={{
                color: 'white',
              }}
              iconStyle={{
                fill: 'white',
              }}
              inputStyle={{
                color: 'white',
              }}
              style={{
                color: 'white',
              }}
              value={quote.id}
              checked={irrelevancies?.indexOf(quote.id) !== -1}
              onChange={onMarkIrrelevant}
            />
          </Backdrop>
        </Grid>
      )
    )
  })

  if (!quotesList || quotesList.length === 0) return null
  else
    return (
      <Box
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          boxShadow:
            !props.isTrendInfoPage && '0px 4px 12px rgba(39, 43, 49, 0.03)',
          border: props.isTrendInfoPage && '1px solid #E5EAF0',
          padding: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {props.projectVariant === 'food' && (
            <Box>
              <Typography variant="h3" pb={props.disableSubtitle && 2}>
                Verbatims
              </Typography>
              {!props.disableSubtitle && (
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  pt={0.5}
                  pb={2}
                >
                  A selection of most relevant verbatims in relation to this
                  topic
                </Typography>
              )}
            </Box>
          )}
          {props.projectVariant === 'bpc' && (
            <Box>
              <Typography variant="h3" pb={props.disableSubtitle && 2}>
                Verbatims and Reviews
              </Typography>
              {!props.disableSubtitle && (
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  pt={0.5}
                  pb={2}
                >
                  A selection of most relevant verbatims and reviews in relation
                  to this topic.
                </Typography>
              )}
            </Box>
          )}
          {props.sourceShow && props.category === 'Sugar Reduction' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography mr={1} variant="subtitle2">
                Source
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Multiselect
                  searchPlaceholder="Search sources"
                  placeholder="Source"
                  options={quoteCategories}
                  value={quoteCategories.filter(({ checked }) => checked)}
                  onChange={handleSelect}
                />
              </FormControl>
            </Box>
          )}
          {props.projectVariant === 'bpc' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'normal',
                marginLeft: '700px',
              }}
            >
              <Typography mr={1} width="100%" variant="subtitle2">
                Platform
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Select
                  defaultValue="All"
                  onChange={handleVerbatimPlatformChange}
                >
                  {' '}
                  <MenuItem value="All">All</MenuItem>
                  {uniquePlatform?.map((name) => (
                    <MenuItem
                      value={name}
                      onClick={() => handleVerbatimPlatformChange(name)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {props.projectVariant === 'food' && (
            <Box>
              {isVerbatimsBackdropOpen && (
                <Box
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    variant="grayishblue"
                    onClick={toggleVerbatimsBackdrop}
                    sx={{ marginRight: '8px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!irrelevancies.length}
                    onClick={openDialog}
                  >
                    Confirm
                  </Button>
                </Box>
              )}
              {props.projectVariant === 'food' &&
                !props.markIrrelevantDisabled &&
                !isVerbatimsBackdropOpen && (
                  <Box
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      marginTop: isSidePanelView && `-10px`,
                    }}
                    onClick={onMarkIrrelevantButtonClick}
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<ErrorOutlineRounded />}
                    >
                      Mark Irrelevant
                    </Button>
                  </Box>
                )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mx: -2,
            pb: 2,
          }}
        >
          <Divider />
        </Box>
        <QuotesItemsContainer
          id="QuotesItemsContainer"
          container
          spacing={2}
          alignItems="stretch"
          sx={{
            height: props.isTrendInfoPage && 'max-content',
            maxHeight: props.customHeight,
            position: 'relative',
          }}
        >
          {loader ? <OverlayLoader /> : null}
          {quotesList}
          <Box
            sx={{
              width: 'auto',
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Collapse in={showAlert}>
              <Alert
                severity="info"
                icon={<ErrorRounded fontSize="inherit" />}
                action={
                  <IconButton
                    aria-label="close"
                    color="info"
                    size="small"
                    onClick={() => {
                      toggleShowAlert()
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {irrelevancies.length}{' '}
                {irrelevancies.length > 1 ? 'Verbatims have ' : 'Verbatim has '}
                been marked as irrelevant.
              </Alert>
            </Collapse>
          </Box>
          <Dialog
            ref={dialogElement}
            scroll="paper"
            fullWidth={true}
            PaperProps={{
              sx: {
                width: '650px',
                maxWidth: '650px',
                minWidth: '650px',
              },
            }}
            container={document.getElementById('QuotesItemsContainer')}
            style={{
              position: 'absolute',
            }}
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
                position: 'absolute',
                height: document.getElementById('QuotesItemsContainer')
                  ?.scrollHeight,
              },
            }}
            sx={{
              paper: {
                position: 'absolute',
                width: '75%',
              },
            }}
            open={isDialogOpen}
            onClose={closeDialog}
            aria-labelledby="mark-irrelevant-title"
            aria-describedby="mark-irrelevant-description"
          >
            <DialogTitle id="mark-irrelevant-title">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                  onClick={closeDialog}
                >
                  <ErrorRounded
                    fontSize="medium"
                    variant="contained"
                    color="primary"
                    sx={{
                      marginRight: '16px',
                    }}
                  />
                  <Box>
                    <Typography variant="h3">Send feedback</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      Tell us what's wrong?
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: 'flex-end',
                    cursor: 'pointer',
                  }}
                >
                  <Close onClick={closeDialog} />
                </Box>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: '100%',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormGroup>
                      {verbatimsIrrelevantReasons[0]?.map((r) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={r}
                                onChange={onCheckboxValueChange}
                              />
                            }
                            label={r}
                          />
                        )
                      })}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup>
                      {verbatimsIrrelevantReasons[1]?.map((r) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={r}
                                onChange={onCheckboxValueChange}
                              />
                            }
                            label={r}
                          />
                        )
                      })}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ paddingLeft: '16px' }}>
                      {irrelevancies.length}{' '}
                      {irrelevancies.length > 1 ? 'Verbatims' : 'Verbatim'} will
                      be reported.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {isOtherOptionSelected && (
                      <Box sx={{ paddingLeft: '8px' }}>
                        <TextField
                          error={error}
                          helperText={
                            error
                              ? `Max ${OTHER_REASON_MAX_LENGTH} characters allowed.`
                              : ''
                          }
                          inputProps={{
                            maxLength: OTHER_REASON_MAX_LENGTH + 1,
                          }}
                          sx={{
                            width: '100%',
                          }}
                          variant="outlined"
                          size="large"
                          placeholder={'Write your reason here *'}
                          onChange={(e) => updateOtherReason(e.target.value)}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="grayishblue"
                onClick={() => onMarkIrrelevantActionClick('Cancel')}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!reasons.length}
                onClick={() => onMarkIrrelevantActionClick('Submit')}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </QuotesItemsContainer>
      </Box>
    )
}

ConsumerQuotesCardComponent.defaultProps = {
  sourceShow: false,
}

ConsumerQuotesCardComponent.propTypes = {
  sourceShow: PropTypes.bool,
}

export default ConsumerQuotesCardComponent
