import * as AC from '../actions/actionConstants'

const applicationReducer = (state = {
  showSpinner: false,
  breadCrumb: [{
    type: 'Foresight Engine',
    projectId: '',
    productId: '',
    name: 'Foresight Engine',
  }],
  breadCrumbData: [
    {
      type: 'Foresight Engine',
      projectId: '',
      productId: '',
      name: 'Foresight Engine',
    },
  ],
  expandedFilterPanel: false,
  highlight:[], //Eg: {route:'',timeout:(Time in ms), highLightId:'DownloadTrendReport-577', callback: null}] 
}, action) => {
  switch (action.type) {
    case AC.SAVE_BREAD_CRUMBS_DATA:
      const bc = action.breadCrumb
      // seach
      const found = state.breadCrumbData.find((b, i) => b.type === bc.type)
      if(found){
        const breadCrumbData =  [...state.breadCrumbData]
      } else {
        const breadCrumbData =  [...state.breadCrumbData]
        breadCrumbData.push(bc)
        return { ...state, breadCrumbData }
      }
    case AC.SHOW_SPINNER:
      return Object.assign({}, state, { showSpinner: true })

    case AC.HIDE_SPINNER:
      return Object.assign({}, state, { showSpinner: false })

    case AC.BREADCRUMBS_ADD:
      const bcl = [...state.breadCrumb]
      let newAdd = false
      const newBC = []
      bcl.forEach( bc => {
        if (bc.type === action.breadCrumb.type) {
          newAdd = true
          return newBC.push(action.breadCrumb)
        }
        newBC.push(bc)
      })
      if(!newAdd) {
        newBC.push(action.breadCrumb)
      }
      return {
        ...state,
        breadCrumb: [...newBC],
      }
    case AC.BREADCRUMBS_REMOVE_AFTER:
      let numberBC = action.index
      if (!action.index) {
        numberBC = state.breadCrumb.length - 1
      }

      const breadCrumb = state.breadCrumb.filter((b, i) => i < numberBC)
      return {
        ...state,
        breadCrumb,
      }

    case AC.BREADCRUMBS_REPLACE:
      // if (!action.index) {
      //   return {
      //     ...state,
      //     breadCrumb: state.breadCrumb.push()
      //   }
      // }
      if (state.breadCrumb.length < action.index + 1) {
        const bc = [...state.breadCrumb]
        bc.push(action.breadCrumb)
        return {
          ...state,
          breadCrumb: bc,
        }
      }
      return Object.assign({}, state, { breadCrumb: state.breadCrumb.map((b, i) => i === action.index ? action.breadCrumb : b) })
    case AC.RESET_PROJECT_DETAILS:
      return Object.assign({}, state, {
        breadCrumb: [{
          type: 'Foresight Engine',
          projectId: '',
          productId: '',
          name: 'Foresight Engine',
        }],
      })
      case AC.SHOW_FILTER_PANEL:
        return Object.assign({}, state, {
          expandedFilterPanel: action.show
        })
      case AC.ADD_HIGHLIGHT:
        return Object.assign({}, state, {
          highlight: [...state.highlight, action.highlight]
        })
      case AC.REMOVE_HIGHLIGHT:
        return Object.assign({}, state, {
          highlight: state.highlight.filter(hl => hl.highlightId !== action.highlightId)
        })
    default:
      return state
  }
}

export default applicationReducer
