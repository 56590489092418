import React from 'react'
import { TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import {
  trendBucketIngProdSubHeadCells,
  trendBucketThemeSubHeadCells,
} from '../tableColumnsConst'

const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (id === 'trend_Name') {
      return func ? func(rowData) : rowData
    } else if (rowData[id] != null) {
      return func ? func(rowData[id]) : rowData[id]
    }
  })
  return res
}

const SubTableBody = ({ row, lensSelected, country, category }) => {
  return row.map((historyRow) => {
    const updatedRowSubData = {
      ...historyRow,
      country: country,
      category: category,
      lensSelected: lensSelected,
    }
    return (
      <TableRow>
        <GenericTableCell
          rowData={updatedRowSubData}
          tableHeadCells={
            lensSelected === 'Theme'
              ? trendBucketThemeSubHeadCells
              : trendBucketIngProdSubHeadCells
          }
        />
      </TableRow>
    )
  })
}

SubTableBody.PropTypes = {
  row: PropTypes.arrayOf([
    PropTypes.shape({
      trend_Name: PropTypes.string,
      data_points: PropTypes.string,
      rank: PropTypes.string,
      maturity_Phase: PropTypes.string,
      four_year_cagr: PropTypes.string,
      year_on_year_growth: PropTypes.string,
    }),
  ]),
}

export default SubTableBody
