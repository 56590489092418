
export default {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        '& .MuiMenu-list': {
          padding: '4px 0',
          '& .MuiDivider-root': {
            margin: '0',
          },
        },
        boxShadow: '0px 0px 7px 0px #95959529',
      },
    },
  },
};

