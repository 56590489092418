import axios from 'axios'
import Util from './NetworkingUtil'
import { setUserLoggedOut } from '../../store/actions'
import store from '../../indexStore'

const url = process.env.REACT_APP_FORE_SIGHT_BACKEND_URL

const instance = axios.create({
  baseURL: url,
})

instance.defaults.headers.common.Authorization = Util.getAuthKey()
instance.defaults.headers.post['Content-Type'] = 'application/json'

// Comment this out during final build
instance.interceptors.request.use(
  (request) => {
    const headers = { ...request.headers }
    delete headers.ScreenwinnerAuthKey
    delete headers.common.ScreenwinnerAuthKey
    request.headers = headers
    return request
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      return store.dispatch(setUserLoggedOut())
    }
    return Promise.reject(error)
  },
)

export default instance
