import React from 'react'

import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { Typography, Button, Dialog, DialogContent, Box } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import DialogActions from '@mui/material/DialogActions'

import GenericMainBody from '../../../../../../components/GenericTable/GenericMainBody'
import GenericTableHead from '../../../../../../shared/components/GenericTableComponent/GenericTableHead'

const DashboardTableUI = (props) => {
  const {
    dashboardData,
    adminPanelHeadCells,
    dashboardSubHeadCells,
    removeSubcategoryOpen,
    dropdownOpen,
    handleRemoveFormat,
    setRemoveFormatOpen,
    selectedFormat,
    handleState,
    subData,
    deleteSubCategoryDialog,
    setDeleteSubCategoryDialog,
    removeDashboard,
  } = props
  return (
    <>
      <Box mt={3}>
        <TableContainer
          component={Paper}
          sx={{ height: '400px', overflow: 'scroll' }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650, overflow: 'scroll' }}
            aria-label="POC Dashboard table"
          >
            <GenericTableHead headCells={adminPanelHeadCells} />
            <GenericMainBody
              row={dashboardData}
              open={dropdownOpen}
              headCells={adminPanelHeadCells}
              subHeadCells={dashboardSubHeadCells}
              heading={`Dashboard Name: `}
              headingOnExpand={'dashboardName'}
              subData={subData}
              handleState={handleState}
            />
          </Table>
        </TableContainer>

        <Dialog
          onClose={() => setRemoveFormatOpen(false)}
          aria-labelledby="add or remove format"
          open={Boolean(removeSubcategoryOpen)}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                {selectedFormat?.selection ? 'Disable' : 'Enable'} format
                {selectedFormat ? ` ${selectedFormat.format}` : ''}.
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setRemoveFormatOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={handleRemoveFormat}
            >
              {selectedFormat?.selection ? 'Remove' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={() => setDeleteSubCategoryDialog(false)}
          aria-labelledby="delete subcategory dialog"
          open={Boolean(deleteSubCategoryDialog)}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                This will delete the dashboard.
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setDeleteSubCategoryDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                removeDashboard(deleteSubCategoryDialog?.id)
                setDeleteSubCategoryDialog(false)
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default DashboardTableUI
