import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import Link from '@mui/material/Link'
import { visuallyHidden } from '@mui/utils'

import formatNumber from '../../../../shared/utils/formatNumber'
import { Box } from '@mui/system'
import {
  amplify,
  getSignedFloat,
  convertTextToTitleCase,
} from './../../../../store/utility'
import InformationIcon from '../../InformationIcon'
import { ReactComponent as TransitionFlagIcon } from '../../../../assets/icons/transitionFlag.svg'
import TextOverflowTooltip from '../../TextOverflowTooltip'
const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.props.email,
      Lens: ampiUserData.props.lensSelected,
      Redirected_From: ampiUserData.props.viewMode,
    }
    switch (ampiEvent) {
      case 'Clicked_Demography_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.props.countrySelected,
          Category_Name: ampiUserData.props.categorySelected,
          SubCategory_Name: ampiUserData.subCategoryName,
          Agegroup: ampiUserData.ageGroup,
          Trend_Name: ampiUserData.trendName,
        }
        break

      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.name,
          'Maturity Phase': ampiUserData.props.usPhase,
          Country_Name: ampiUserData.props.country,
          Category_Name: ampiUserData.props.category,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch {
    console.log('Amplitude error in Trends Table')
  }
}
function descendingComparator(a, b, orderBy, trendType) {
  if (
    (b.four_year_cagr_all &&
      a.four_year_cagr_all &&
      orderBy === 'two_year_cagr_all') ||
    orderBy === 'four_year_cagr_all'
  ) {
    if (b[orderBy][trendType] < a[orderBy][trendType]) {
      return -1
    }

    if (b[orderBy][trendType] > a[orderBy][trendType]) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }

    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}
function getComparator(order, orderBy, trendType) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, trendType)
    : (a, b) => -descendingComparator(a, b, orderBy, trendType)
}
// TODO use react-window for better performance
// Table Component
const TrendTableUI = (props) => {
  const {
    noRankAvailable,
    orderKey,
    tableCmpHeight,
    shouldOpenTrendInfo,
    sortOrder,
    enableProductLensFsAndRetail,
    demographyComponent,
    trendTransitionDetails,
    logTrendClick,
    visualisationMode,
  } = props
  const isNewVis = props.visualisationMode === 'new'
  const isCognitiveSearch = props.type === 'cognitiveSearch'
  const [order, setOrder] = useState(sortOrder || 'asc')
  const [orderBy, setOrderBy] = useState(
    orderKey || (noRankAvailable ? 'name' : 'scoreRank'),
  )
  const tooltipTextForCAGR =
    'Based on social and search data at category level, and social data at sub category level'
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Item',
      minWidth: 200,
    },
    {
      id: 'data_point',
      numeric: true,
      disablePadding: false,
      label: 'Data Points',
      tooltipText: 'Based on consumer engagement from social platforms',
    },
    {
      id: 'two_year_cagr',
      numeric: true,
      disablePadding: false,
      label: '2 Year CAGR',
      tooltipText: tooltipTextForCAGR,
    },
    {
      id: 'four_year_cagr',
      numeric: true,
      disablePadding: false,
      label: '4 Year CAGR',
      tooltipText: tooltipTextForCAGR,
    },
  ]
  let isSubCategoryNotApplied
  if (props.appliedFilters) {
    isSubCategoryNotApplied =
      !props.appliedFilters.ingredientSubCategory &&
      !props.appliedFilters.productSubCategory &&
      !props.appliedFilters.themeSubCategory
  }
  const getCagr = (row, key) => {
    if (isNewVis) {
      return row[key] && row[key][row.sub_category]
    }
    return row[key] && row[key][row.trend_type[0]]
  }

  const displayTooltipText = (trendName) => {
    const {
      transitioning_from: transitioningFrom,
      transitioning_to: transitioningTo,
    } = trendTransitionDetails[trendName]
    if (!transitioningFrom || !transitioningTo) {
      return 'Trend is transitioning'
    }
    return `Trend is transitioning from ${convertTextToTitleCase(
      transitioningFrom,
    )} to ${convertTextToTitleCase(transitioningTo)}`
  }

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const trendItemClicked = (row) => {
    if (visualisationMode !== 'new') {
      const ampiEvent = `Clicked_Trend_Lens`
      const ampiUserData = {
        props,
        name: row?.name,
      }
      // TODO here for CS send required props
      amp(ampiEvent, ampiUserData)
    } else {
      logTrendClick(row)
    }
  }

  const trendItemClickedDemography = (trendName, ageGroup) => {
    const ampiEvent = `Clicked_Demography_Trend`
    const ampiUserData = {
      props,
      subCategoryName: 'null',
      ageGroup,
      trendName,
    }
    amp(ampiEvent, ampiUserData)
  }
  if (!noRankAvailable) {
    headCells.splice(1, 0, {
      id: 'scoreRank',
      numeric: true,
      disablePadding: false,
      label: 'Rank',
      tooltipText: 'Based on the AI model for a particular maturity phase',
    })
  }
  if (props.lensSelected == 'Ingredient' && !demographyComponent) {
    if (!noRankAvailable) {
      headCells.splice(3, 1, {
        id: 'two_year_cagr_all',
        numeric: true,
        disablePadding: false,
        label: '2 Year CAGR',
        tooltipText: tooltipTextForCAGR,
      })
      headCells.splice(4, 1, {
        id: 'four_year_cagr_all',
        numeric: true,
        disablePadding: false,
        label: '4 Year CAGR',
        tooltipText: tooltipTextForCAGR,
      })
    }
    if (props.projectVariant === 'food') {
      headCells.push({
        id: 'fs_products',
        numeric: true,
        disablePadding: false,
        label: '# FS Products',
      })
    }
    headCells.push({
      id: 'retail_products',
      numeric: true,
      disablePadding: false,
      label: '# Retail Products',
    })
  }
  if (enableProductLensFsAndRetail && props.lensSelected === 'Product') {
    if (props.projectVariant === 'food') {
      headCells.push({
        id: 'fs_products',
        numeric: true,
        disablePadding: false,
        label: '# FS Products',
      })
    }
    headCells.push({
      id: 'retail_products',
      numeric: true,
      disablePadding: false,
      label: '# Retail Products',
    })
  }

  if (props.visualisationMode === 'new') {
    headCells.splice(headCells.map((cell) => cell.id).indexOf('scoreRank'), 1)
    headCells.splice(headCells.map((cell) => cell.id).indexOf('data_point'), 1)
    headCells.splice(1, 0, {
      id: 'four_year_data_points',
      numeric: true,
      disablePadding: false,
      label: '4 Year Data Points',
    })
    headCells.splice(2, 0, {
      id: 'two_year_data_points',
      numeric: true,
      disablePadding: false,
      label: '2 Year Data Points',
    })
  }

  const getImageUrl = (row) => {
    return isNewVis ? row.image_url : row.image
  }

  return (
    <TableContainer sx={{ height: tableCmpHeight || 600 }}>
      <Table
        stickyHeader
        sx={{
          minWidth: 650,
          'th:first-of-type': {
            position: 'sticky',
            left: 0,
            zIndex: 5,
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        }}
        aria-label="Trends table"
      >
        <TableHead>
          <TableRow
            sx={{
              'th:first-of-type': {
                zIndex: 6,
                backgroundColor: (theme) => theme.palette.background.default,
              },
            }}
          >
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sx={{ minWidth: headCell.minWidth }}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={handleRequestSort(headCell.id)}
                >
                  <Box sx={headCell.tooltipText && { width: 'max-content' }}>
                    {headCell.label}
                  </Box>
                  {headCell.tooltipText && (
                    <InformationIcon
                      tooltipText={headCell.tooltipText}
                      sx={{
                        marginLeft: '5px !important',
                      }}
                    />
                  )}
                  {orderBy === headCell.id && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.lensSelected == 'Ingredient' && !demographyComponent
            ? props.data
                .slice()
                .sort(
                  getComparator(
                    order,
                    orderBy,
                    isNewVis
                      ? props.data[0]?.sub_category
                      : props.data[0]?.trend_type &&
                          props.data[0]?.trend_type[0],
                  ),
                )
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {shouldOpenTrendInfo ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          gap={1}
                          onClick={() => {
                            shouldOpenTrendInfo.handleTrendClick(row)
                          }}
                        >
                          <img
                            src={getImageUrl(row)}
                            alt={row.name}
                            height={36}
                            width={36}
                            style={{ borderRadius: 4, objectFit: 'cover' }}
                          />
                          <Typography
                            variant="subtitle3"
                            sx={{ color: 'primary.main' }}
                          >
                            {row.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Link
                          target="_blank"
                          underline="none"
                          variant="body2"
                          href={`/mui/details/${props.projectid}/${props.lensSelected}/${row.id}`}
                          onClick={() => {
                            trendItemClicked(row)
                          }}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src={getImageUrl(row)}
                              alt={row.name}
                              height={36}
                              width={36}
                              style={{
                                borderRadius: 4,
                                objectFit: 'cover',
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <TextOverflowTooltip
                                title={row.name}
                                maxLineCount={1}
                                display="inline"
                              >
                                <Typography
                                  variant="subtitle3"
                                  sx={{
                                    flex: 1,
                                    display: 'inline',
                                    color: 'primary.main',
                                    padding: '0px',
                                    lineBreak: 'anywhere',
                                  }}
                                >
                                  {row.name}
                                </Typography>
                              </TextOverflowTooltip>
                              {!isNewVis &&
                                isSubCategoryNotApplied &&
                                props.showTransitionIcon &&
                                trendTransitionDetails[row.name]
                                  ?.transitioning && (
                                  <Tooltip
                                    sx={{
                                      display: 'inline',
                                      marginRight: '3px',
                                    }}
                                    title={displayTooltipText(row.name)}
                                  >
                                    <Box
                                      display={'inline'}
                                      width={24}
                                      sx={{
                                        marginLeft: '3px',
                                        height: '10px',
                                      }}
                                    >
                                      <SvgIcon component={TransitionFlagIcon} />
                                    </Box>
                                  </Tooltip>
                                )}
                            </Box>
                          </Box>
                        </Link>
                      )}
                    </TableCell>
                    {!noRankAvailable && !isNewVis && (
                      <TableCell align="left">{row.scoreRank}</TableCell>
                    )}
                    {isNewVis && (
                      <TableCell align="left">
                        {formatNumber(row.four_year_data_points)}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {formatNumber(
                        isNewVis ? row.two_year_data_points : row.data_point,
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {getSignedFloat(
                        isCognitiveSearch
                          ? row.two_year_cagr
                          : getCagr(row, 'two_year_cagr_all'),
                        2,
                      )}
                      %
                    </TableCell>
                    <TableCell align="left">
                      {getSignedFloat(
                        isCognitiveSearch
                          ? row.four_year_cagr
                          : getCagr(row, 'four_year_cagr_all'),
                        2,
                      )}
                      %
                    </TableCell>
                    {props.projectVariant === 'food' && (
                      <TableCell align="left">
                        {formatNumber(row.fs_products)}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {formatNumber(row.retail_products)}
                    </TableCell>
                  </TableRow>
                ))
            : props.data
                .slice()
                .sort(getComparator(order, orderBy))
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {shouldOpenTrendInfo ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          gap={1}
                          onClick={() => {
                            shouldOpenTrendInfo.handleTrendClick(row)
                          }}
                        >
                          <img
                            src={getImageUrl(row)}
                            alt={row.name}
                            height={36}
                            width={36}
                            style={{ borderRadius: 4, objectFit: 'cover' }}
                          />
                          <Typography
                            variant="subtitle3"
                            sx={{ color: 'primary.main' }}
                          >
                            {row.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Link
                          target="_blank"
                          underline="none"
                          variant="body2"
                          href={
                            props.openDemographyThemePage
                              ? `/mui/demographic-analytics?trend=${row.name}&projectId=${row.project_id}&country=${props.countrySelected}&category=${props.categorySelected}&lens=${props.lensSelected}&themeId=${row.trend_id}&demography=${row.age_group}`
                              : `/mui/details/${props.projectid}/${props.lensSelected}/${row.id}`
                          }
                          onClick={() => {
                            if (props.openDemographyThemePage) {
                              trendItemClickedDemography(
                                row?.name,
                                row?.age_group,
                              )
                            } else {
                              trendItemClicked(row)
                            }
                          }}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src={getImageUrl(row)}
                              alt={row.name}
                              height={36}
                              width={36}
                              style={{
                                borderRadius: 4,
                                objectFit: 'cover',
                              }}
                            />

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <TextOverflowTooltip
                                title={row.name}
                                maxLineCount={1}
                                display="inline"
                              >
                                <Typography
                                  variant="subtitle3"
                                  sx={{
                                    flex: 1,
                                    display: 'inline',
                                    color: 'primary.main',
                                    padding: '0px',
                                    lineBreak: 'anywhere',
                                  }}
                                >
                                  {row.name}
                                </Typography>
                              </TextOverflowTooltip>

                              {!isNewVis &&
                                props.showTransitionIcon &&
                                isSubCategoryNotApplied &&
                                trendTransitionDetails[row.name]
                                  ?.transitioning === true && (
                                  <Tooltip title={displayTooltipText(row.name)}>
                                    <Box
                                      display={'inline'}
                                      width={24}
                                      sx={{
                                        marginLeft: '3px',
                                        height: '10px',
                                      }}
                                    >
                                      <SvgIcon component={TransitionFlagIcon} />
                                    </Box>
                                  </Tooltip>
                                )}
                            </Box>
                          </Box>
                        </Link>
                      )}
                    </TableCell>
                    {!noRankAvailable && !isNewVis && (
                      <TableCell align="left">{row.scoreRank}</TableCell>
                    )}
                    <TableCell align="left">
                      {formatNumber(
                        isNewVis ? row.four_year_data_points : row.data_point,
                      )}
                    </TableCell>
                    {isNewVis && (
                      <TableCell>
                        {formatNumber(row.two_year_data_points)}
                      </TableCell>
                    )}
                    <TableCell align="left">{`${getSignedFloat(
                      row.two_year_cagr,
                      2,
                    )}%`}</TableCell>
                    <TableCell align="left">{`${getSignedFloat(
                      row.four_year_cagr,
                      2,
                    )}%`}</TableCell>
                    {enableProductLensFsAndRetail &&
                      props.lensSelected === 'Product' &&
                      props.projectVariant === 'food' && (
                        <TableCell align="left">
                          {formatNumber(row.fs_products)}
                        </TableCell>
                      )}
                    {enableProductLensFsAndRetail &&
                      props.lensSelected === 'Product' && (
                        <TableCell align="left">
                          {formatNumber(row.retail_products)}
                        </TableCell>
                      )}
                  </TableRow>
                ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TrendTableUI.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      rank: PropTypes.number,
      volume: PropTypes.number,
      yoy_growth: PropTypes.number,
      mom_growth: PropTypes.number,
      image: PropTypes.string,
    }),
  ).isRequired,
}
export default TrendTableUI
