import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { Box } from '@mui/system'
import { Chip, Typography, Tooltip, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BlogsWebsitesCardContainer from './styles'
import axios from './../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import TextOverflowTooltip from '../TextOverflowTooltip'
import { amplify } from './../../../store/utility'

import { LENSES } from '../../utils/constants'
const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      Trend_Name: ampiInstance._name,
      headline: ampiUserData.headline,
    }
    switch (ampiEvent) {
      case 'Clicked_Blogs_and_Websites':
        amplitudeUserData = {
          ...amplitudeUserData,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in MonthlyTrendMui')
  }
}
const BlogsWebsitesCard = (props) => {
  const { country, category } = props
  const [blogs, setBlogs] = useState()
  const [isHovered, setIsHovered] = useState(false)
  const apiFetch = () => {
    const url = NetworkUtil.fetchBlogsAndWebsites(country, category)
    axios
      .get(url)
      .then((res) => {
        setBlogs(res?.data?.data?.blogs_and_websites)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const headlines = blogs && blogs.map((blog) => blog.headline)
  useEffect(() => {
    apiFetch()
  }, [])
  const handleClick = (headline) => {
    const ampiEvent = 'Clicked_Blogs_and_Websites'
    const ampiUserData = {
      country,
      category,
      headline,
    }
    amp(ampiEvent, ampiUserData)
  }
  return (
    <BlogsWebsitesCardContainer>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: '5px' }}>
          Blogs & Websites
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ marginBottom: '15px' }}
        >
          Blogs and websites from the data sources related to the category.{' '}
        </Typography>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          <Grid container spacing={1}>
            {blogs &&
              blogs.map((blog) => (
                <Grid item xs={12} sm={6} md={4} key={blog.id}>
                  <Box
                    sx={{
                      margin: '5px',
                      border: ({ palette }) => `1px solid ${palette.divider}`,
                      // padding: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <a
                      href={blog.input_url}
                      target="_blank"
                      onClick={() => handleClick(blog.headline)}
                    >
                      {blog?.image != '' && (
                        <Box
                          sx={{
                            borderRadius: '5px',
                          }}
                        >
                          <img
                            src={blog.image}
                            width={'363.46px'}
                            height={'200px'}
                            style={{
                              borderRadius: '5px',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      )}
                      <Box width={'320px'} padding={'20px'}>
                        <Box>
                          <TextOverflowTooltip
                            maxLineCount={2}
                            title={blog.headline}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                padding: '5px 0px 5px 0px',
                                marginBottom: '5px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                                maxHeight: '43px',
                                mb: '2px',
                                marginX: '4px',
                              }}
                            >
                              {blog.headline}
                            </Typography>
                          </TextOverflowTooltip>
                          <Typography
                            variant="body3"
                            color={' #78858F'}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '3',
                              WebkitBoxOrient: 'vertical',
                              maxHeight: '56px',
                              marginBottom: '6px',
                              marginTop: '4px',
                              lineHeight: '1.5',
                              marginX: '5px',
                            }}
                          >
                            {blog.article_text}
                          </Typography>
                        </Box>
                      </Box>
                    </a>
                    <Box paddingLeft={'20px'} paddingBottom={'20px'}>
                      {LENSES.map((lens) => {
                        if (
                          [lens.toLowerCase()] in blog &&
                          blog[lens.toLowerCase()].length > 0
                        ) {
                          return (
                            <Tooltip
                              title={
                                <>
                                  <Box
                                    height={250}
                                    width={250}
                                    p={2}
                                    pr={2.5}
                                    sx={{ overflowY: 'auto !important' }}
                                  >
                                    <Typography variant="caption" color="white">
                                      Following {lens.toLowerCase()}s are tagged
                                      with this blog post:
                                      <br />
                                    </Typography>

                                    {blog &&
                                      blog[lens.toLowerCase()].map(
                                        (name, index) => (
                                          <>
                                            <Typography
                                              variant="caption"
                                              color="white"
                                            >
                                              {index + 1}. {name}
                                            </Typography>
                                            <br />
                                          </>
                                        ),
                                      )}
                                  </Box>
                                </>
                              }
                              arrow
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              <Chip
                                label={`${lens}s (${
                                  blog[lens.toLowerCase()].length
                                })`}
                                sx={{
                                  margin: '2px',
                                  border: ({ palette }) =>
                                    `1px solid ${palette.colors.chipActive}`,
                                  background: ({ palette }) =>
                                    palette.background.paper,
                                }}
                              />
                            </Tooltip>
                          )
                        }
                      })}
                    </Box>
                  </Box>
                </Grid>
              ))}{' '}
          </Grid>
        </Box>
      </Box>
    </BlogsWebsitesCardContainer>
  )
}
const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}
export default withRouter(connect(mapStateToProps, null)(BlogsWebsitesCard))
