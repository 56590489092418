import { connect } from 'react-redux'
import { RoleList } from '../../components'
import * as A from '../../store/actions'
import { initialize } from 'redux-form'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showRoleForm: () => {
      dispatch(A.showRoleForm())
      dispatch(A.hideRoleList())
    },
    showRoleList: () => {
      dispatch(A.hideRoleForm())
      dispatch(A.showRoleList())
    },
    getAllRole: () => {
      return dispatch(A.getAllRolesFromServer()).then(() => {

      })
    },
    getAllPermission: () => {
      return dispatch(A.getAllPermissionsFromServer()).then(() => {

      })
    },
    addRolePermission: (values) => {
      if (!values.id) {
        console.log('add role')
        dispatch(A.showSppiner())
        return dispatch(A.addRoleOnServer(values)).then(() => {
          dispatch(A.hideRoleForm())
          dispatch(A.showRoleList())
          dispatch(A.hideSppiner())
        })
      } else {
        console.log('edit role')
        dispatch(A.showSppiner())
        return dispatch(A.updateRoleOnServer(values)).then(() => {
          dispatch(A.hideRoleForm())
          dispatch(A.showRoleList())
          dispatch(A.hideSppiner())
        })
      }
    },
    editRole: (role) => {
      dispatch(A.showRoleForm())
      dispatch(A.hideRoleList())
      dispatch(initialize('role', role))
    },
    deleteRole: (RoleId) => dispatch(A.deleteRoleOnServer(RoleId)),

    hideForm: () => {
      dispatch(A.hideRoleForm())
      dispatch(A.showRoleList())
    },
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permission.all,
    showSpinner: state.application.showSpinner,
    roles: state.role.all,
    roleForm: state.role.roleForm,
    roleList: state.role.roleList,
    loggedInUser: state.user.loggedIn && state.user.loggedIn.access_token ? state.user.loggedIn.access_token : null,
  }
}

const RoleListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleList)

export default RoleListContainer
