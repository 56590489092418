import { NewTrendsRequestActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  requestForm: null,
  trendRequestID: null,
  error: false,
  infoDisplay: false,
  loading: false,
}

const selectObject = (property) => {
  const elementObject = {
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Type',
      },
      label: 'Enter Request Name',
      value: '',
    },
    lookFor: null,
    geo: null,
    topic: null,
  }

  for (let idx = 0; idx < property.length; idx++) {
    const element = property[idx]
    elementObject[element.name] = {
      elementType: 'select',
      elementConfig: {
        options: element.options.map(option => {
          return { id: option.id, value: option.value, displayValue: option.displayValue }
        }),
      },
      label: element.displayName,
      value: element.options[0].id,
    }
  }

  return elementObject
}

const updateRequestFormWithInput = (state, action) => {
  const updatedRequestForm = {
    ...state.requestForm,
  }
  const updatedFormElement = {
    ...updatedRequestForm[action.eleIdentifier],
  }
  updatedFormElement.value = action.value
  updatedRequestForm[action.eleIdentifier] = updatedFormElement

  return updateObject(state, { requestForm: updatedRequestForm })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.FETCH_NEW_TRENDS_OPTIONS_START]: return updateObject(state, {
      loading: true,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.FETCH_NEW_TRENDS_OPTIONS_SET]: return updateObject(state, {
      requestForm: selectObject(action.requestOptions.list),
      loading: false,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.FETCH_NEW_TRENDS_OPTIONS_FAILED]: return updateObject(state, {
      loading: false,
      error: true,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.REQUEST_NEW_TRENDS_START]: return updateObject(state, {
      loading: true,
      infoDisplay: true,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.REQUEST_NEW_TRENDS_SUCCESS]: return updateObject(state, {
      infoDisplay: false,
      loading: false,
      error: false,
      trendRequestID: action.requestId,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.REQUEST_NEW_TRENDS_FAILED]: return updateObject(state, {
      infoDisplay: false,
      loading: false,
      error: true,
      trendRequestID: null,
    })
    case NewTrendsRequestActionTypes.type[NewTrendsRequestActionTypes.INPUT_CHANGED]: return updateRequestFormWithInput(state, action)

    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        requestForm: null,
        trendRequestID: null,
        error: null,
        infoDisplay: false,
        loading: false,
      })

    default:
      return state
  }
}

export default reducer
