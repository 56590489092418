import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Stack,
  Divider,
} from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { StyledBox, InlineBox, StyledCard, StyledCardContent } from './style'
import nounFoodAndBeverage from './../../components/TrendBucketCard/images/nounFoodAndBeverage.jpg'
import { FixedSizeList } from 'react-window'
import { connect } from 'react-redux'
import SingleSelect from '../SingleSelect'
import {
  getTrendPillarforImporting,
  importPillarDetails,
} from '../../../store/actions/createPillarActions'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import { LENSES } from '../../utils/constants'
import axios from '../../../services/networking/axios'

import classes from './style.css'
import theme from '../../../theme'
import { fetchAllTrends } from './../../../store/actions/advancedSearchActions'
import { amplify } from '../../../store/utility'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
      Country_Name: ampiUserData.country?.label,
      Category_Name: ampiUserData.category?.label,
    }
    switch (ampiEvent) {
      case 'Clicked_TP_Import':
        amplitudeUserData = {
          ...amplitudeUserData,
          Pillar_Name: ampiUserData.selectedpillar.name,
        }
        break
      case 'Clicked_TP_Import_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Selected_Pillar: ampiUserData.selectedpillar?.name,
          Searched_Pillar: ampiUserData.searchTerm,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Trend Bucket Card')
  }
}

const ImportTrendPillar = ({
  countries,
  countrySelected,
  handleCountryChange,
  categories,
  categorySelected,
  handleCategoryChange,
  setShowModal,
  importPillarList,
  projectsList,
  importPillarDetails,
  getTrendPillarforImporting,
  projectId,
  allTrendsList,
  fetchAllTrends,
  handleSubmitClick,
  email,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [selectedpillar, setSelectedPillar] = useState([])
  const { control, handleSubmit } = useForm()
  const [lensNotFoundError, setLensNotFoundError] = useState(false)

  let trends = []
  if (selectedpillar && selectedpillar.lens == 'Ingredient') {
    trends = allTrendsList && allTrendsList.ingredient
  }
  if (selectedpillar && selectedpillar.lens == 'Product') {
    trends = allTrendsList && allTrendsList.product
  }
  if (selectedpillar && selectedpillar.lens == 'Theme') {
    trends = allTrendsList && allTrendsList.theme
  }

  const ingredientPillars =
    (importPillarList[0] &&
      importPillarList[0].ingredient_pillars?.map((pillar) => ({
        ...pillar,
        lens: 'Ingredient',
      }))) ||
    []

  const themePillars =
    (importPillarList[0] &&
      importPillarList[0].theme_pillars?.map((pillar) => ({
        ...pillar,
        lens: 'Theme',
      }))) ||
    []

  const productPillars =
    (importPillarList[0] &&
      importPillarList[0].product_pillars?.map((pillar) => ({
        ...pillar,
        lens: 'Product',
      }))) ||
    []

  const allPillars = [...ingredientPillars, ...themePillars, ...productPillars]

  const allImportPillarsList = allPillars.map(
    ({ id, lens, name, country, category, trends, ...data }) => ({
      id,
      name,
      country,
      category,
      firstName: data.first_name,
      lastName: data.last_name,
      pillarId: data.id,
      lens,
      trends,
    }),
  )

  useEffect(() => {
    if (countrySelected && categorySelected) {
      const prjSelectedId = projectsList.find((prj) => {
        return (
          prj.name === categorySelected.label &&
          prj.criteria === countrySelected.label
        )
      })?.id
      const url = NetworkUtil.fecthFeaturesDetailsRequest(prjSelectedId)
      axios
        .get(url)
        .then((response) => {
          const lensesVal = []
          if (response.data.data.ingredient_classification) {
            lensesVal.push(LENSES[0])
          }
          if (response.data.data.product_classification) {
            lensesVal.push(LENSES[1])
          }
          if (response.data.data.theme_classification) {
            lensesVal.push(LENSES[2])
          }
          if (lensesVal.includes(selectedpillar.lens)) {
            setLensNotFoundError(false)
          } else {
            setLensNotFoundError(true)
          }
        })
        .catch((error) => {
          console.log('Features Request Error:')
        })
    }
  }, [categorySelected, selectedpillar.name])

  useEffect(() => {
    if (projectId) {
      fetchAllTrends(projectId)
    }
  }, [projectId])

  useEffect(() => {
    getTrendPillarforImporting()
  }, [])

  const handleClick = (value) => {
    setSelectedPillar(value)
  }

  const handleImport = (e) => {
    if (
      !lensNotFoundError &&
      selectedpillar.name &&
      countrySelected &&
      categorySelected
    ) {
      const ampiEvent = 'Clicked_TP_Import'
      const ampiUserData = {
        email,
        selectedpillar: selectedpillar,
        country: countrySelected,
        category: categorySelected,
      }
      amp(ampiEvent, ampiUserData)
      handleSubmitClick(e)
      importPillarDetails(
        selectedpillar.name,
        countrySelected.label,
        categorySelected.label,
        selectedpillar.id,
        selectedpillar.trends,
        selectedpillar.lens,
      )
    }
  }

  const pillarsRenderList = ({ data, index, style }) => {
    const { lens, country, category, name, firstName, lastName, id } = data[
      index
    ]
    return searchInput.length ? (
      <StyledCard style={style}>
        <StyledCardContent
          onClick={() => {
            handleClick(data[index])
          }}
          sx={{
            border:
              selectedpillar.id + selectedpillar.lens === id + lens
                ? `1px solid ${theme.palette.secondary.main}`
                : `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="subtitle2" paddingBottom="3px">
            <Highlighted
              text={`${country} | ${category} `}
              highlight={searchInput}
            />
            <InlineBox>
              <Highlighted text={name} highlight={searchInput} />
            </InlineBox>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '10px', color: ' #B5B5B5' }}
          >
            <Highlighted
              text={`Created By : ${firstName} ${lastName}`}
              highlight={searchInput}
            />
          </Typography>
        </StyledCardContent>
      </StyledCard>
    ) : (
      <StyledCard style={style}>
        <StyledCardContent
          onClick={() => {
            handleClick(data[index])
          }}
          sx={{
            border:
              selectedpillar.id + selectedpillar.lens === id + lens
                ? `1px solid ${theme.palette.secondary.main}`
                : `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="subtitle2" paddingBottom="3px">
            {country} | {category} <InlineBox>{name}</InlineBox>
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: '10px', color: ' #B5B5B5' }}
          >
            {`Created By : ${firstName} ${lastName}`}
          </Typography>
        </StyledCardContent>
      </StyledCard>
    )
  }

  const enterData = (e) => {
    setSearchInput(e.target.value)
  }

  const filteredData = allImportPillarsList.filter((item) => {
    return Object.values(item).some((val) =>
      `${val}`.toLowerCase().includes(searchInput.toLowerCase()),
    )
  })

  const Highlighted = ({ text = '', highlight = '' }) => {
    const regex = new RegExp(`(${highlight})`, 'gi')
    const parts = text.split(regex)
    return (
      <Box display="inline">
        {parts.map((part, i) => {
          return regex.test(part) ? (
            <mark key={i} data-testid={i + `marktag`}>
              {part}
            </mark>
          ) : (
            <Box display="inline" key={i}>
              {part}
            </Box>
          )
        })}
      </Box>
    )
  }

  const clearsearch = () => {
    setSearchInput('')
  }

  const logSearch = () => {
    if (searchInput) {
      const ampiEvent = 'Clicked_TP_Import_Search'
      const ampiUserData = {
        email,
        selectedpillar: selectedpillar,
        country: countrySelected,
        category: categorySelected,
        searchTerm: searchInput,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  useEffect(() => {
    logSearch()
  }, [searchInput])

  return (
    <>
      <Box
        sx={{
          width: '421px',
          height: '390px',
        }}
      >
        <TextField
          data-testid="searching"
          placeholder="Search Buckets"
          sx={{
            margin: '10px 10px 10px 20px',
            width: '382px',
            borderRadius: '4px',
            color: '#A6B6C8',
            fontSize: '14px',
          }}
          inputProps={{
            sx: {
              '&::placeholder': {
                fontSize: '14px',
                color: '#A6B6C8',
              },
            },
          }}
          onChange={enterData}
          value={searchInput}
          InputProps={{
            sx: { height: '34px' },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchInput && (
              <InputAdornment
                position="end"
                onClick={clearsearch}
                data-testid="clearbutton"
              >
                <Clear />
              </InputAdornment>
            ),
          }}
        />

        <StyledBox>
          {filteredData.length ? (
            <FixedSizeList
              className={classes.StyledScrollbar}
              height={160}
              itemCount={filteredData.length}
              itemSize={53}
              itemData={filteredData}
              width="382px"
            >
              {pillarsRenderList}
            </FixedSizeList>
          ) : (
            <Box
              sx={{
                width: ' 382px',
                border: '1px solid #E5EAF0',
                height: '202px',
              }}
            >
              <img
                width="58px"
                height="53px"
                style={{ marginLeft: '162px', marginTop: '58px' }}
                src={nounFoodAndBeverage}
              />
              <Typography
                data-testid="nodata"
                display="block"
                variant="caption"
                sx={{ color: '#A6B6C8', marginTop: '17px', marginLeft: '88px' }}
              >
                No result found for the searched data
              </Typography>
            </Box>
          )}
        </StyledBox>
        <Divider />
        <Typography
          sx={{
            fontSize: '14px',
            color: '#555555',
            marginLeft: '20px',
            marginTop: '9px',
            marginBottom: '9px',
          }}
        >
          Importing to
        </Typography>
        <form
          onSubmit={handleSubmit()}
          style={{ maxWidth: '100%', width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '19.5px',
              paddingRight: '19.5px',
            }}
          >
            <Controller
              control={control}
              name="Country"
              render={({
                field: { onChange, name, ref },
                fieldState: { error },
              }) => (
                <SingleSelect
                  label="Country"
                  placeholder="e.g. China"
                  sx={{ width: '185px', height: '35px' }}
                  options={countries}
                  inputRef={ref}
                  name={name}
                  value={countrySelected?.label}
                  onChange={(e, newVal) => {
                    onChange(e)
                    handleCountryChange(e, newVal)
                  }}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
              rules={{ required: 'Country is required' }}
            />
            <Controller
              control={control}
              name="Category"
              render={({
                field: { onChange, name, ref },
                fieldState: { error },
              }) => (
                <SingleSelect
                  disabled={!countrySelected}
                  label="Category"
                  placeholder="e.g. Snacks"
                  options={categories}
                  sx={{ width: '185px', height: '35px' }}
                  inputRef={ref}
                  name={name}
                  value={categorySelected?.label || null}
                  onChange={(e, newVal) => {
                    onChange(e)
                    handleCategoryChange(e, newVal)
                  }}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
              rules={{ required: 'Category is required' }}
            />
          </Box>
          {lensNotFoundError && (
            <Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: ({ palette }) => palette.error.main,
                  marginLeft: '20px',
                  marginTop: '25px',
                }}
              >
                The lens corresponding to the pillar doesn't exist in the
                selected dashboard.
              </Typography>
            </Box>
          )}

          <Stack
            justifyContent="flex-end"
            direction="row"
            spacing={1.25}
            sx={{
              padding: '20px 24px',
              marginTop: '21px',
            }}
          >
            <Button
              sx={{
                '&.MuiButtonBase-root': {
                  width: 120,
                },
              }}
              variant="outlined"
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={handleImport}
              sx={{
                '&.MuiButtonBase-root': {
                  width: 120,
                },
              }}
            >
              {' '}
              Import
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    pillarDetails: state.trendPillar.pillarDetails,
    projectsList: state.savedTrendRequester.savedTrendList?._list,
    importPillarList: state.trendPillar.importDetails,
    allTrendsList: state.projectListBuilder.allTrendsList,
    projectId: state.trendPillar.projectId,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllTrends: (projectId) => dispatch(fetchAllTrends(projectId, true)),
  getTrendPillarforImporting: () => dispatch(getTrendPillarforImporting()),
  importPillarDetails: (
    pillarName,
    countrySelected,
    categorySelected,
    id,
    trends,
    lens,
  ) =>
    dispatch(
      importPillarDetails(
        pillarName,
        countrySelected,
        categorySelected,
        id,
        trends,
        lens,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportTrendPillar)
