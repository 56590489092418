import React from 'react'
import { Button, Tooltip } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
const AddFavButtonUI = ({
  handleClickAddToFav,
  addedFav,
  productFormat,
  pairing,
  favoriteSectionType,
  consumerInsightSection,
}) => {
  return (
    <>
      <Tooltip
        title={`Add${
          addedFav ? 'ed' : ''
        } to ${favoriteSectionType} in My Workspace`}
      >
        {
          <Button
            variant="outlined"
            onClick={handleClickAddToFav}
            sx={{
              height: '35px !important',
              marginRight:
                (productFormat || pairing || consumerInsightSection) &&
                '10px !important',
            }}
          >
            {addedFav ? (
              <StarIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  marginRight: '5px',
                }}
              />
            ) : (
              <StarBorderOutlinedIcon
                sx={{
                  marginRight: '6px',
                  color: ({ palette }) => palette.icon,
                }}
              />
            )}
            Add{addedFav && 'ed'} to Favorites
          </Button>
        }
      </Tooltip>
    </>
  )
}
export default AddFavButtonUI
