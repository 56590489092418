import styled from '@emotion/styled'
import { Tabs, Box, Badge } from '@mui/material'

export const StyledTab = styled(Tabs)(({ theme }) => ({
  borderBottom: 'none',
  marginBottom: '10px',
  marginTop: '10px',
  backgroundColor: `${theme.palette.white}`,
  paddingLeft: '5px',
  '& .MuiTabs-indicator': {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'transparent',
  },
  '& .MuiTab-root.Mui-selected': {
    color: `${theme.palette.text.primary}`,
    padding: '0',
  },
  '& .MuiTab-root': {
    color: `${theme.palette.text.secondary}`,
  },
  '.MuiButtonBase-root': {
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(2)} 0`,
    marginRight: '25px',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      padding: `${theme.spacing(2)} 0`,
      backgroundColor: 'transparent',
    },
  },
}))
export const TopBox = styled(Box)(({ theme }) => ({
  padding: '16px 0px 0px',
  maxWidth: '99%',
  flexBasis: '100%',
  marginLeft: 'auto',
}))

export const TabLabel = styled(Badge)(({ theme }) => ({
  fontSize: 14,
  '& .MuiBadge-badge': {
    fontSize: 8,
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
    transform: 'translate(0,-100%)',
    padding: '2px 0',
    height: 'auto',
  },
}))
