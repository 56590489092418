import { Box, Chip, Tooltip, Typography } from '@mui/material'
import React from 'react'
import TextOverflowTooltip from '../../../shared/components/TextOverflowTooltip'

const MAX_CHIP_WIDTH = 130
const KeywordsChipComponent = (props) => {
  const { keywords, maxKeywords = 2 } = props
  const getChipLabel = (keyword) => (
    <TextOverflowTooltip
      title={keyword}
      checkWidth
      maxLineCount={1}
      tooltipProps={{ placement: 'top' }}
    >
      <Typography>{keyword}</Typography>
    </TextOverflowTooltip>
  )
  return (
    <>
      {keywords.length > maxKeywords ? (
        <Box display="flex" sx={{ gap: 1 }}>
          {keywords.slice(0, maxKeywords).map((keyword, idx) => (
            <Chip
              variant="keyword"
              label={getChipLabel(keyword)}
              key={`${idx}-${keyword}`}
              sx={{ maxWidth: MAX_CHIP_WIDTH }}
            />
          ))}
          <Tooltip
            title={keywords.slice(maxKeywords).join(', ')}
            placement="top-start"
          >
            <Chip
              variant="keyword"
              sx={{
                '& .MuiChip-label': {
                  color: ({ palette }) => palette.primary.main,
                },
              }}
              label={`${keywords.slice(maxKeywords).length} More +`}
            />
          </Tooltip>
        </Box>
      ) : (
        <Box display="flex" sx={{ gap: 1 }}>
          {keywords.map((keyword, idx) => (
            <Chip
              variant="keyword"
              label={getChipLabel(keyword)}
              key={`${idx}-${keyword}`}
              sx={{ maxWidth: MAX_CHIP_WIDTH }}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default KeywordsChipComponent
