import React, { useEffect, useState } from 'react'
import { SvgIcon, Typography, Stack, Grid, Box } from '@mui/material'
import { ReactComponent as LineChartIcon } from '../../assets/icons/line-chart.svg'
import SentimentDonutChart from '../../shared/components/SentimentDonutChart'
import { SentimentLabel } from '../../shared/components/SentimentCard/styles'
import formatNumber from '../../shared/utils/formatNumber'
import ExpandableChipsGroup from '../../shared/components/ExpandableChipsGroup'
import GrowthChip from '../../shared/components/GrowthTag'
import countriesList from '../../mocks/country'
import { trendTypes } from '../../shared/utils/constants'
import { CardContainer, StyledGrid } from './Styled'
import { Link } from 'react-router-dom'
import { getSignedFloat } from '../../store/utility'

const CommonTrendAnalysisCard = ({
  productComparisonData,
  productName,
  acrossSelected,
  lens,
}) => {
  const [filterProduct, setFilterProduct] = useState([])

  useEffect(() => {
    const result = productComparisonData.filter((d) => {
      return d.productDetails._name === productName
    })

    setFilterProduct(result)
  }, [productComparisonData, productName])

  const getImageURL = (country) => {
    const countryFinded = countriesList.find((c) => c.label === country)

    return (
      <img
        loading="lazy"
        width="30px"
        height="20px"
        src={
          countryFinded.img
            ? countryFinded.img
            : `https://flagcdn.com/${countryFinded.code.toLowerCase()}.svg`
        }
        srcSet={
          countryFinded.img
            ? countryFinded.img
            : `https://flagcdn.com/w40/${countryFinded.code.toLowerCase()}.png 2x`
        }
        alt={countryFinded.label}
      />
    )
  }

  const getTrendIcon = (key) => {
    const trend = trendTypes.find((d) => d.id === key)
    return <SvgIcon fontSize="small" component={trend.icon} color="secondary" />
  }

  return (
    <>
      <Grid container spacing={2} columns={16} width="100%" flexWrap="nowrap">
        {filterProduct &&
          filterProduct.map((i, index) => (
            <StyledGrid
              item
              xs={3}
              mx={1}
              my={2}
              key={index}
              backgroundColor={(theme) => theme.palette.white}
            >
              <Link
                target="_blank"
                to={`/mui/details/${i.productDetails.trendProjectId}/${lens}/${i.productDetails.id}`}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={3}>
                    {getImageURL(i.productDetails._country)}
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle2">
                      {i.productDetails._country}
                    </Typography>
                    <Typography variant="subtitle1" mt={2}>
                      {i.productDetails._category}
                    </Typography>
                  </Grid>
                </Grid>
                &nbsp;
                <Grid container spacing={0} columns={12}>
                  <Grid item xs={2}>
                    <SvgIcon component={LineChartIcon} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="subtitle2">{`${formatNumber(
                      i.productDetails._data_point,
                    )} Data Points`}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0} columns={16}>
                  <Grid item xs={15}>
                    <Typography variant="caption" color="textSecondary">
                      Trend Ranking and Maturity
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Typography variant="h1" color="secondary.main">
                    {`#${i.productDetails._rank}`}
                  </Typography>
                  <Typography variant="subtitle2" color="secondary.main">
                    {i.productDetails._trendName}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  {i.productDetails.categories}
                </Typography>
                &nbsp;
                <CardContainer
                  container
                  spacing={0}
                  columns={16}
                  height={100}
                  overflow="auto"
                >
                  <Grid item xs={15}>
                    <Typography variant="caption" color="textSecondary">
                      Sub Category Types:
                    </Typography>
                    <Stack spacing={2} mt={2}>
                      {Object.entries(
                        i.productDetails._filterClassification,
                      ).map(([key, value]) => (
                        <Box key={key}>
                          <Box display={'flex'}>
                            {getTrendIcon(key)}
                            <Typography
                              variant="subtitle2"
                              color="secondary.dark"
                              textTransform="capitalize"
                            >
                              {key.toLowerCase()}
                            </Typography>
                          </Box>
                          <ExpandableChipsGroup trends={value.split(';')} />
                        </Box>
                      ))}
                    </Stack>
                  </Grid>
                </CardContainer>
                &nbsp;
                <Box>
                  <Typography variant="h3" color="textPrimary">
                    Sentiment
                  </Typography>
                  &nbsp;
                  <SentimentDonutChart
                    data={[
                      {
                        value: i.productDetails._positiveSentiment,
                        label: 'Positive',
                      },
                      {
                        value: i.productDetails._negativeSentiment,
                        label: 'Negative',
                      },
                      {
                        value: i.productDetails._neutralSentiment,
                        label: 'Neutral',
                      },
                    ]}
                  />
                  &nbsp;
                  <Box>
                    <SentimentLabel
                      sentiment="positive"
                      value={i.productDetails._positiveSentiment}
                    />
                    &nbsp;
                    <SentimentLabel
                      sentiment="neutral"
                      value={i.productDetails._neutralSentiment}
                    />
                    &nbsp;
                    <SentimentLabel
                      sentiment="negative"
                      value={i.productDetails._negativeSentiment}
                    />
                  </Box>
                </Box>
                &nbsp;
                <GrowthChip
                  title="2 Year CAGR"
                  growthValue={getSignedFloat(i.productDetails.twoYearCagr, 2)}
                />
                &nbsp;
                <Grid container spacing={2} columns={12} mb={1}>
                  <Grid item xs={11}>
                    <Typography
                      color={(theme) => theme.palette.text.hint}
                      variant="caption"
                    >
                      4 Year CAGR{' '}
                      {getSignedFloat(i.productDetails.fourYearCagr, 2)}%
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </StyledGrid>
          ))}
      </Grid>
    </>
  )
}

export default CommonTrendAnalysisCard
