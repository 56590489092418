import { SearchProjectActionTypes, SearchQuotesActionTypes, LoadSearchKeywordsActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  projectSearchData : null,
  projectSearchQuotes: null,
  error : false,
  loading : false,
  quotes_error: false,
  quotes_loading: false,
  searchKeywordsList: null,
  keywordsError: false,
  keywordsLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_START]: return updateObject(state, {
      loading: true,
    })
    case SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_SUCCESS]: return updateObject(state, {
      projectSearchData : action.projectSearchData,
      error : false,
      loading : false,
    })
    case SearchProjectActionTypes.type[SearchProjectActionTypes.FETCH_PROJECT_SEARCH_FAILED]: return updateObject(state, {
      projectSearchData : null,
      error : true,
      loading : false,
    })
    case SearchProjectActionTypes.type[SearchProjectActionTypes.RESET_PROJECT_SEARCH_DATA]: return updateObject(state, {
      projectSearchData : null,
      error : false,
      loading : false,
    })

    case SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_START]: return updateObject(state, {
      quotes_loading: true,
    })
    case SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_SUCCESS]: return updateObject(state, {
      projectSearchQuotes : action.projectSearchQuotes,
      quotes_error : false,
      quotes_loading : false,
    })
    case SearchQuotesActionTypes.type[SearchQuotesActionTypes.FETCH_SEARCH_QUOTES_FAILED]: return updateObject(state, {
      projectSearchQuotes : null,
      quotes_error : true,
      quotes_loading : false,
    })
    case LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_START]: return updateObject(state,{
      keywordsLoading: true,
    })
    case LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_SUCCESS]: return updateObject(state,{
      searchKeywordsList: action.searchKeywordsList,
      keywordsLoading: false,
      keywordsError: false,
    })
    case LoadSearchKeywordsActionTypes.type[LoadSearchKeywordsActionTypes.FETCH_SEARCH_KEYWORDS_FAILED]: return updateObject(state,{
      searchKeywordsList: null,
      keywordsLoading: false,
      keywordsError: true,
    })

    default:
      return state
  }
} 
  
export default reducer