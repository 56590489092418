import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'

import { Grid } from '@mui/material'

import { formatNumber } from '../../store/utility'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import DashboardTrendHighlight from './DashboardTrendHighlight'
import DashboardFavorites from './DashboardFavorites'
import DashboardPersonalisedReportsAndActivity from './DashboardPersonalisedReportsAndActivity'
import CustomSiblingHeightGridItem from '../../shared/components/CustomSiblingHeightGridItem'

const DashboardUI = (props) => {
  const {
    subscriptionDetails,
    fetchFavoritesTrendsList,
    favoritesTrendsData,
    removeFromFavoritesTrends,
    requestProjectsList,
    projectsList,
    fetchAllTrends,
    allTrendsList,
    fetchTrendHighlightData,
    trendHighlightConfig,
    trendHighlightTrendsList,
    loading,
    email,
    otherFavoritesData,
  } = props

  const [lensSelected, setLensSelected] = useState('')
  const [countrySelected, setCountrySelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [subCategorySelected, setSubCategorySelected] = useState(null)
  const [trendHighlightData, setTrendHighlightData] = useState([])
  const projectIdSelected = trendHighlightConfig?.project_id

  const chipsToShow = {
    ...(lensSelected && { lensSelected: lensSelected }),
    ...(countrySelected &&
      categorySelected && {
        dashboardSelected: `${countrySelected} ${categorySelected}`,
      }),
    ...(lensSelected === 'Ingredient' && {
      subCategorySelected: `Subcategory: ${subCategorySelected || 'NA'}`,
    }),
  }

  const handleTrendHighlightFiltersSave = (allDropdownsList) => {
    allDropdownsList.map((obj) => {
      switch (obj.label) {
        case 'Lens':
          setLensSelected(obj.value)
          break
        case 'Country':
          setCountrySelected(obj.value)
          break
        case 'Category':
          setCategorySelected(obj.value)
          break
        case 'Sub-Category':
          setSubCategorySelected(obj.value)
          break
      }
    })
  }

  // useEffects
  useEffect(() => {
    if (!_.isNil(trendHighlightTrendsList)) {
      setTrendHighlightData(
        trendHighlightTrendsList.map((obj) => {
          return {
            ...obj,
            total_data_points: formatNumber(obj.total_data_points) || 0,
            previous_month_data_points:
              formatNumber(obj.previous_month_data_points) || 0,
          }
        }),
      )
    }
  }, [trendHighlightTrendsList])

  useEffect(() => {
    if (trendHighlightConfig && Object.keys(trendHighlightConfig).length) {
      setCountrySelected(trendHighlightConfig.country)
      setCategorySelected(trendHighlightConfig.category)
      setLensSelected(trendHighlightConfig.lens)
      setSubCategorySelected(trendHighlightConfig.sub_category)
    }
  }, [trendHighlightConfig])

  useEffect(() => {
    requestProjectsList()
    fetchFavoritesTrendsList()
    fetchTrendHighlightData()
  }, [])

  return (
    <>
      <MainCommonTabs subscriptionDetails={subscriptionDetails} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8}>
          <DashboardTrendHighlight
            chipsToShow={chipsToShow}
            trendHighlightData={trendHighlightData}
            lensSelected={lensSelected}
            countrySelected={countrySelected}
            categorySelected={categorySelected}
            subCategorySelected={subCategorySelected}
            handleTrendHighlightFiltersSave={handleTrendHighlightFiltersSave}
            projectsList={projectsList}
            fetchAllTrends={fetchAllTrends}
            allTrendsList={allTrendsList}
            trendHighlightConfig={trendHighlightConfig}
            trendHighlightTrendsList={trendHighlightTrendsList}
            fetchTrendHighlightData={fetchTrendHighlightData}
            trendHighlightDataLoading={loading.trendHighlightDataLoading}
            projectIdSelected={projectIdSelected}
            email={email}
          />
        </Grid>
        <CustomSiblingHeightGridItem parentContainerSpacing={2} xs={4}>
          <DashboardPersonalisedReportsAndActivity
            loading={loading.recentActivityLoading}
            email={email}
          />
        </CustomSiblingHeightGridItem>
        <Grid item xs={12}>
          <DashboardFavorites
            subscriptionDetails={subscriptionDetails}
            favoritesTrendsData={favoritesTrendsData}
            removeFromFavoritesTrends={removeFromFavoritesTrends}
            loading={loading.favoritesTrendsLoading}
            otherFavoritesData={otherFavoritesData}
            email={email}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardUI
