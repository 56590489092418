import React from 'react'
import PropTypes from 'prop-types'
import ToggleBtnGrp from './styles'

const ToggleButtonGroup = ({
  value,
  exclusive,
  onChange,
  ariaLabel,
  disabled,
  sx,
  children,
}) => {
  return (
    <ToggleBtnGrp
      value={value}
      disabled={disabled}
      exclusive={exclusive}
      onChange={onChange}
      arai-label={ariaLabel}
      sx={sx}
    >
      {children}
    </ToggleBtnGrp>
  )
}

ToggleButtonGroup.propTypes = {
  value: PropTypes.string.isRequired,
  exclusive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
}

export default ToggleButtonGroup
