import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'

import SWHeader from '../../components/SWHeader/SWHeader'
import SWBreadCrumbs from '../../components/SWBreadCrumbs/SWBreadCrumbsContainer'
// import Footer from "../../components/Navigation/Footer/Footer";

import { PublicFooter } from '../../shared/components'

import './SWLayout.scss'
import Notification from '../../components/SWNotifications/SWNotificationsContainer'

const calculateHeight = () => {
  return window.innerHeight - 84
}

const SWLayout = ({ children }) => {
  const [height, setHeight] = useState(calculateHeight())

  const setContentHeight = () => {
    setHeight(calculateHeight())
  }

  useEffect(() => {
    window.addEventListener('resize', setContentHeight)
    return () => {
      window.removeEventListener('resize', setContentHeight)
    }
  }, [])

  return (
    <>
      <SWHeader />
      <div className="sw-layout__sw-content__block">
        <main className="sw-content" style={{ minHeight: height }}>
          <SWBreadCrumbs />
          <div className="sw-layout__sw-notification__block">
            <Notification />
          </div>
          {children}
        </main>
      </div>
      <Container maxWidth="xl" sx={{ p: 2, background: '#fafbfd' }}>
        <PublicFooter screenwinner />
      </Container>
    </>
  )
}

export default SWLayout
