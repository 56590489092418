import styled from '@emotion/styled'
import { Dialog, DialogTitle, Tabs, Tab, Button, Stack } from '@mui/material'
import theme from '../../../../../theme'
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper': {
    width: 'auto',
    minHeight: '140px',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
  '& .MuiPaper-root': {
    width: '422px',
  },
  '& .MuiAutocomplete-listbox': {
    overflow: 'auto',
    maxHeight: '150px',
    background: 'white',
  },
}))

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  paddingLeft: '20.5px',
  paddingTop: '12.5px',
  paddingBottom: '12.5px',
  fontSize: '16px',
  opacity: 0.8,
}))

export const StyledTabs = styled(Tabs)(() => ({
  paddingTop: '12.5px',
  paddingLeft: '20.5px',
  paddingBottom: '6.5px',
}))
export const StyledTab = styled(Tab)(() => ({
  width: '43px',
  height: '19px',
  paddingTop: '12.5px',
  paddingBottom: '6.5px',
  boxShadow: 'none !important',
}))

export const StyledButton = styled(Button)(() => ({
  '&.MuiButtonBase-root.MuiButton-root': {
    marginTop: '26px !important',
  },
}))
export const StyledStack = styled(Stack)(() => ({
  '& .MuiFormLabel-root': { color: 'black', fontSize: '14px' },
  '& .MuiFormControlLabel-root': { alignItems: 'flex-start' },
}))

export const ImportTabStyling = () => ({
  padding: '0',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '2px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: '2',
  },
  '& .MuiAutocomplete-listbox': {
    width: 'auto !important',

    background: 'white',
  },
  '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper': {
    // maxHeight: '150px !important',
    minHeight: '150px !important',
    width: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
})

export const CreateTabStyling = () => ({
  padding: '18px 24px',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
  '& form': {
    maxWidth: '100%',
  },
})
