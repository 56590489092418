import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory, withRouter } from 'react-router-dom'
import { Box } from '@mui/system'
import {
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { Search } from '@mui/icons-material'

import TrendReportItem from '../TrendReportItem'

import { connect } from 'react-redux'
import Moment from 'moment'

import axios from '../../../services/networking/axios'

import NetworkUtil from '../../../services/networking/NetworkingUtil'
import { getQueryParams, amplify } from '../../../store/utility'
import SearchNotSelected from '../SearchNotSelected'
import CustomReportsEmptyState from '../../../assets/images/testImages/CustomReportsEmptyState.svg'
import { REPORT_COUNT } from '../../utils/constants'

const filters = {
  ALL: 'All',
  CUSTOM: 'Custom',
  GLOBAL: 'Global',
}

const TrendReportCard = (props) => {
  const { email, firstName, lastName, singleType, loading } = props
  const [indexFilter, setIndexFilter] = useState(0)
  const [reportInput, setReportInput] = useState('')
  const location = useLocation()
  const [activeId, setActiveId] = useState(null)
  const history = useHistory()

  const handleInput = (ev) => {
    setReportInput(ev.target.value)
  }

  const openReport = (id) => {
    const query = getQueryParams()
    query.set('activeReport', id)
    history.replace(`?${query.toString()}`)
  }

  const closeReport = () => {
    const query = getQueryParams()
    query.delete('activeReport')
    history.replace(`?${query.toString()}`)
  }

  const searchAmplitude = () => {
    const ampiEvent = `Clicked_Trend_Report_SearchBar`
    const ampiUserData = {
      User: email,
    }
    amplify(ampiEvent, ampiUserData, email)
  }

  const clickMe = (i, filter) => {
    setIndexFilter(i)
    const ampiEvent = `Clicked_${filter}_Trend`
    const ampiUserData = {
      User: email,
    }
    amplify(ampiEvent, ampiUserData, email)
  }

  const filteredReports = useMemo(() => {
    if (singleType) {
      const customReports = props.ar.filter(
        (report) => report.tag === filters.CUSTOM,
      )
      const globalReports = props.ar.filter(
        (report) => report.tag === filters.GLOBAL,
      )

      if (customReports.length) {
        return customReports
      }
      return globalReports
    } else if (indexFilter == 0) {
      return props.ar
    }
    return props.ar.filter(
      (report) => report.tag === Object.values(filters)[indexFilter],
    )
  }, [props.ar, singleType, indexFilter]).filter(({ name, date }) =>
    `${date} ${name}`.toLowerCase().includes(reportInput.toLowerCase()),
  )

  useEffect(() => {
    setActiveId(getQueryParams({ location }).get('activeReport'))
  }, [location.search])

  return (
    <Paper
      sx={{
        position: 'sticky',
        top: '10px',
        boxShadow: '0px 4px 12px 0px #272B3108',
      }}
    >
      {!singleType && (
        <Box p={2}>
          <Grid container alignContent="center" mb={3}>
            <Grid item flexGrow={1} display="flex" alignItems="center">
              <Typography variant="h3">Trend Report</Typography>
            </Grid>
            <Grid item>
              <TextField
                sx={{ display: { xs: 'none', md: 'flex', width: '199px' } }}
                variant="outlined"
                value={reportInput}
                onChange={handleInput}
                onClick={searchAmplitude}
                placeholder="Search"
                size="large"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {Object.values(filters).map((filter, index) => (
              <Grid item key={index}>
                <Chip
                  variant="outlined"
                  label={filter}
                  isSelected={index === indexFilter}
                  onClick={() => clickMe(index, filter)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          maxHeight: !singleType && 'calc(100vh - 210px)',
          overflow: 'auto',
        }}
      >
        {singleType && !loading && !filteredReports?.length && (
          <SearchNotSelected
            text="No Reports Available"
            icon={CustomReportsEmptyState}
          />
        )}
        {filteredReports.map(
          ({
            id,
            name,
            image: thumbnailUrl,
            report,
            report_ppt_url: reportPPTUrl,
            date,
            tag,
          }) => (
            <Fragment key={id}>
              {(!singleType || filteredReports[0].name !== name) && <Divider />}
              <TrendReportItem
                name={name}
                thumbnailUrl={thumbnailUrl}
                report={report}
                openReport={openReport}
                closeReport={closeReport}
                reportPPTUrl={reportPPTUrl}
                date={date}
                email={email}
                firstName={firstName}
                lastName={lastName}
                open={activeId === `${id}`}
                id={id}
                filter={indexFilter}
                filters={filters}
                reportType={tag}
              />
              {singleType &&
                filteredReports[filteredReports.length - 1].name === name && (
                  <Divider />
                )}
            </Fragment>
          ),
        )}
      </Box>
    </Paper>
  )
}

const HPTrendReportList = (props) => {
  const [allReports, setAllReports] = useState([])
  useEffect(() => {
    if (!props.singleType) {
      const request = NetworkUtil.getReportsList()
      axios
        .get(request)
        .then((response) => {
          const data = []
          const reqReports = response.data.data
          for (let i = 0; i < reqReports.length; i++) {
            const reportDetail = reqReports[i]
            let gTag = 'Custom'
            if (response.data.gtag.some((e) => e === reportDetail.name))
              gTag = 'Global'
            data.push({
              id: reportDetail.id,
              name: reportDetail.name,
              report: reportDetail.report,
              report_ppt_url: reportDetail.report_ppt_url,
              image: reportDetail.image,
              date: reportDetail.date,
              tag: gTag,
            })
          }
          data.sort(
            (a, b) =>
              Moment(b.date).format('YYYYMMDD') -
              Moment(a.date).format('YYYYMMDD'),
          )
          setAllReports([...data])
        })
        .catch((err) => console.log('Error fetching reports'))
    }
  }, [])
  return (
    <TrendReportCard
      ar={props.reports || allReports}
      email={props.email}
      singleType={props.singleType}
      loading={props.loading}
    />
  )
}

const mapStateToProps = (state) => {
  if (state.user.loggedIn.user) {
    return {
      firstName: state.user.loggedIn.user.firstName,
      lname: state.user.loggedIn.user.lastName,
      lastName: state.user.loggedIn.user.username,
    }
  }
}
export default withRouter(connect(mapStateToProps)(HPTrendReportList))
