import { Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const TrendGrowthCardContainer = styled(Paper)(() => ({
  flexGrow: 1,
  boxShadow: '0px 4px 12px 0px #272B3108',
}));

export const DatePickerTextField = styled(TextField)(() => ({
  width: 140,
  '& .MuiSvgIcon-root': { fontSize: '18px' },
}));
