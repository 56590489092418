import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import { getValidDate } from '../../helpers/DateHelper'
import ClearIcon from '@mui/icons-material/Clear'
import { getHeaders, getBuildWinnerURL } from '../../network/utility'
import { CG_MAX_TITLE_LENGTH } from '../../../shared/utils/constants'
import { ConceptTextField } from './styles'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  maxHeight: 'calc(100% - 50px)',
  overflow: 'hidden',
  display: 'flex',
}

export const TitleComponent = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  display: 'inline-block',
  marginTop: '12px',
  // borderLeft: "4px solid #0274CA"
  color: theme.palette.primary.main,
}))

const ErrorComponent = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: '4px',
}))

export default function RenameConceptModal({
  conceptDetails,
  active,
  onClose,
  onUpdate,
}) {
  const {
    id,
    title,
    generated_concept: generatedConcept,
    created_at: createdAt,
  } = conceptDetails
  const [open, setOpen] = React.useState(active)
  const [input, setInput] = React.useState(title)
  const [description, setDescription] = React.useState(generatedConcept)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState()

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const handleInputChange = (text) => {
    clearErrorState()
    setInput(text)
  }

  const handleDescriptionChange = (text) => {
    setDescription(text)
  }
  const clearErrorState = () => {
    setErrors(null)
  }

  const updateConceptName = (newName, id, description) => {
    if (newName.length == 0) {
      setErrors('Please input a valid name')
      return
    }
    const userId = localStorage.getItem('my-secret-key')
    const putUrl = getBuildWinnerURL(`concepts/${id}/?user_id=${userId}`)
    const body = {
      title: newName,
      generated_concept: description,
    }
    setLoading(true)

    fetch(
      putUrl,
      getHeaders(
        {
          body: JSON.stringify(body),
        },
        'PUT',
      ),
    )
      .then((res) => {
        if (res.status == 404) {
          setErrors('Name already exits!')
          return
        }
        onClose()
        onUpdate()
        handleClose()
        setLoading(false)
      })
      .catch((error) => {
        console.log('error_occured', error)
        setLoading(false)
        setErrors('Something went wrong, please try again later')
      })
  }

  useEffect(() => {
    if (input.length > CG_MAX_TITLE_LENGTH) {
      setErrors(`Title can only contain ${CG_MAX_TITLE_LENGTH} characters`)
    } else {
      setErrors('')
    }
  }, [input])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ overflow: 'auto', p: 4, flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignContent="flex-end"
              alignItems="baseline"
            >
              <Grid item xs={8}>
                <ConceptTextField
                  fullWidth
                  value={input}
                  label={'Concept Name'}
                  variant="outlined"
                  onChange={(e) => handleInputChange(e.target.value)}
                  id="fullWidth"
                />
              </Grid>
              <Box display="flex">
                <ClearIcon
                  onClick={handleClose}
                  sx={{
                    color: ({ palette }) => palette.black,
                    cursor: 'pointer',
                  }}
                  fontSize="medium"
                />
              </Box>
            </Grid>
            {errors && errors ? (
              <>
                <ErrorComponent>{errors}</ErrorComponent>
              </>
            ) : null}
            <Box
              sx={{
                height: '5vh',
              }}
            ></Box>
            <Grid>
              <Grid item xs={12}>
                <ConceptTextField
                  fullWidth
                  value={description}
                  label={'Concept Description'}
                  variant="outlined"
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  id="fullWidth"
                  multiline
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TitleComponent>Created on - &nbsp; </TitleComponent>
              <TitleComponent sx={{ color: ({ palette }) => palette.black }}>
                {getValidDate(createdAt)}
              </TitleComponent>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Button
                disabled={!input || input.length > CG_MAX_TITLE_LENGTH}
                variant="contained"
                onClick={() => updateConceptName(input.trim(), id, description)}
              >
                Update concept
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
