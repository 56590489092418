import es6Promise from 'es6-promise'
import * as AC from './actionConstants'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import { NotificationManager } from 'react-notifications'
import * as A from '../../store/actions'

es6Promise.polyfill()

export const addOrganization = (organization) => ({
  type: AC.ADD_ORGANIZATION,
  organization: organization,
})
export const editOrganization = (organization) => ({
  type: AC.EDIT_ORGANIZATION,
  organization: organization,
})

export const deleteOrganization = (orgID) => ({
  type: AC.DELETE_ORGANIZATION,
  orgID,
})

export const addAllProjects = (projects) => ({
  type: AC.ADD_ALL_PROJECTS,
  projects,
})

export const addAllOrganizations = (allOrganizations) => ({
  type: AC.ADD_ALL_ORGANIZATIONS,
  allOrganizations,
})
export const getAllUsersOfOrganization = (allUsers) => ({
  type: AC.GET_ALL_USERS_OF_ORGANIZATION,
  allUsers,
})

export const showOrganizationForm = () => ({
  type: AC.SHOW_ORGANIZATION_FORM,
})

export const addViewOrganizationDetails = (organization) => ({
  type: AC.ADD_ORGANIZATION_VIEW_DETAILS,
  organization,
})

export const hideOrganizationForm = () => ({
  type: AC.HIDE_ORGANIZATION_FORM,
})

export const showOrganizationList = () => ({
  type: AC.SHOW_ORGANIZATION_LIST,
})

export const hideOrganizationList = () => ({
  type: AC.HIDE_ORGANIZATION_LIST,
})

export const showOrganizationView = () => ({
  type: AC.SHOW_ORGANIZATION_VIEW,
})

export const hideOrganizationView = () => ({
  type: AC.HIDE_ORGANIZATION_VIEW,
})
export const showRegistrationForm = () => ({
  type: AC.SHOW_REGISTRATION_FORM,
})
export const showUserRegistrationForm = () => ({
  type: AC.SHOW_USER_REGISTRATION_FORM,
})
export const showOrganizationAdminList = () => ({
  type: AC.SHOW_ORGANIZATION_ADMIN_LIST,
})
export const showOrganizationUserList = () => ({
  type: AC.SHOW_ORGANIZATION_USER_LIST,
})
export const hideOrganizationAdminList = () => ({
  type: AC.HIDE_ORGANIZATION_ADMIN_LIST,
})
export const hideOrganizationUserList = () => ({
  type: AC.HIDE_ORGANIZATION_USER_LIST,
})
export const hideRegistrationForm = () => ({
  type: AC.HIDE_REGISTRATION_FORM,
})
export const hideUserRegistrationForm = () => ({
  type: AC.HIDE_USER_REGISTRATION_FORM,
})

export const addOrgAdmin = (orgAdmin) => ({
  type: AC.ADD_ORG_ADMIN,
  orgAdmin,
})

export const editOrgAdmin = (orgAdmin) => ({
  type: AC.EDIT_ORGANIZATION_ADMIN,
  orgAdmin,
})

export const deleteOrgAdmin = (orgAdminID) => ({
  type: AC.DELETE_ORGANIZATION_ADMIN,
  orgAdminID,
})

export const addOrgUser = (orgUser) => ({
  type: AC.ADD_ORG_USER,
  orgUser,
})

export const editOrgUser = (orgUser) => ({
  type: AC.EDIT_ORGANIZATION_USER,
  orgUser,
})

export const deleteOrgUser = (orgUserID) => ({
  type: AC.DELETE_ORGANIZATION_USER,
  orgUserID,
})
export const getAllSubUserOfOrganization = (allSubUsers) => ({
  type: AC.GET_ALL_SUB_USERS,
  allSubUsers,
})
export const addOrgSubUser = (orgSubUser) => ({
  type: AC.ADD_ORG_SUB_USER,
  orgSubUser,
})
export const editOrgSubUser = (orgSubUser) => ({
  type: AC.EDIT_ORGANIZATION_SUB_USER,
  orgSubUser,
})
export const deleteOrgSubUser = (orgUserID) => ({
  type: AC.DELETE_ORGANIZATION_SUB_USER,
  orgUserID,
})
export const showOrganizationProjectList = () => ({
  type: AC.SHOW_ORGANIZATION_PROJECT_LIST,
})
export const hideOrganizationProjectList = () => ({
  type: AC.HIDE_ORGANIZATION_PROJECT_LIST,
})
export const showOrganizationProjectForm = () => ({
  type: AC.SHOW_ORGANIZATION_PROJECT_FORM,
})
export const hideOrganizationProjectForm = () => ({
  type: AC.HIDE_ORGANIZATION_PROJECT_FORM,
})
export const getAllProjectsOfOrganization = (allProjects) => ({
  type: AC.GET_ALL_PROJECTS_OF_ORGANIZATION,
  allProjects,
})
export const deleteOrgProject = (orgProjectID) => ({
  type: AC.DELETE_ORGANIZATION_PROJECT,
  orgProjectID,
})
export const addOrgProject = (orgProject) => ({
  type: AC.ADD_ORG_PROJECT,
  orgProject,
})
export const showOrganizationFeatureList = () => ({
  type: AC.SHOW_ORGANIZATION_FEATURE_LIST,
})
export const hideOrganizationFeatureList = () => ({
  type: AC.HIDE_ORGANIZATION_FEATURE_LIST,
})
export const showOrganizationFeatureForm = () => ({
  type: AC.SHOW_ORGANIZATION_FEATURE_FORM,
})
export const hideOrganizationFeatureForm = () => ({
  type: AC.HIDE_ORGANIZATION_FEATURE_FORM,
})
export const getAllFeaturesOfOrganization = (allFeatures) => ({
  type: AC.GET_ALL_FEATURES_OF_ORGANIZATION,
  allFeatures,
})
export const getAllFeatures = (allFeatures) => ({
  type: AC.GET_ALL_FEATURES,
  allFeatures,
})
export const deleteOrgFeature = (orgFeatureID) => ({
  type: AC.DELETE_ORGANIZATION_FEATURE,
  orgFeatureID,
})
export const addOrgFeature = (orgFeature) => ({
  type: AC.ADD_ORG_FEATURE,
  orgFeature,
})

export const addOrganizationOnServer = (formInput) => {
  return function (dispatch, getState) {
    dispatch(A.showSppiner())
    const request = NetworkUtil.formAddOrganization()
    return axios.post(request, formInput).then(function (response) {
      if (response.data.responseStatus === 'success') {
        dispatch(addOrganization(response.data.resData))
        dispatch(A.hideSppiner())

        NotificationManager.success('Organization Added Successfully', 'Organization')
      }
    }).catch(function (error) {
      console.log(error)
      NotificationManager.error('Organization  Addition Failed')
    })
  }
}

export const updateOrganizationOnServer = (formInput) => {
  return function (dispatch, getState) {
    dispatch(A.showSppiner())
    const request = NetworkUtil.formAddOrganization()
    return axios.put(request, formInput).then(function (response) {
      if (response.data.responseStatus === 'success') {
        dispatch(editOrganization(response.data.resData))
        dispatch(A.hideSppiner())
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
}
export const deleteOrganizationOnServer = (orgID) => {
  return function (dispatch, getState) {
    dispatch(A.showSppiner())
    const request = NetworkUtil.formAddOrganization()
    return axios.delete(request + '/' + orgID).then(function (response) {
      dispatch(deleteOrganization(orgID))
      dispatch(A.hideSppiner())
      // dispatch(getOrganizationsFromServer(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const getOrganizationsFromServer = (formInput) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrganization()
    return axios.get(request).then(function (response) {
      dispatch(addAllOrganizations(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const getAllProjectsFromServer = () => {
  return function (dispatch, getState) {
    const request = NetworkUtil.getAllProjects()
    return axios.get(request).then(function (response) {
      dispatch(addAllProjects(response.data))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const getUserOfOrganizationsFromServer = (orgId) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formGetOrganizationUser()
    return axios.get(request + '/' + orgId).then(function (response) {
      dispatch(getAllUsersOfOrganization(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addOrgAdminOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgAdmin()
    return axios.post(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
      })
  }
}

export const updateOrgAdminOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgAdmin()
    return axios.put(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteOrganizationAdminOnServer = (orgAdminID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgAdmin()
    return axios.delete(request + '/' + orgAdminID).then(function (response) {
      dispatch(deleteOrgAdmin(orgAdminID))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const addOrgUserOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.post(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const updateOrgUserOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.put(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteOrganizationUserOnServer = (orgUserID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.delete(request + '/' + orgUserID).then(function (response) {
      dispatch(deleteOrgUser(orgUserID))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const getAllSubUserOfOrganizationFromServer = (formInput) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formGetOrganizationUser()
    return axios.get(request).then(function (response) {
      dispatch(getAllSubUserOfOrganization(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addOrgSubUserOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.post(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const updateOrgSubUserOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.put(request, data).then(function (response) {
      return response.data
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteOrgSubUserOnServer = (orgUserID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddOrgUser()
    return axios.delete(request + '/' + orgUserID).then(function (response) {
      dispatch(deleteOrgSubUser(orgUserID))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const getProjectsOfOrganizationsFromServer = (orgId) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formGetOrganizationProject()
    return axios.get(request + '/' + orgId).then(function (response) {
      dispatch(getAllProjectsOfOrganization(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addOrgProjectOnServer = (orgProject, orgID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formOrganizationProject(orgProject, orgID)
    return axios.post(request, '').then(function (response) {
      dispatch(addOrgProject(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteOrganizationProjectOnServer = (project, orgID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formOrganizationProject(project, orgID)
    return axios.delete(request).then(function (response) {
      dispatch(deleteOrgProject(project.id))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const getFeaturesOfOrganizationsFromServer = (orgId) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formGetOrganizationFeature()
    return axios.get(request + '/' + orgId).then(function (response) {
      dispatch(getAllFeaturesOfOrganization(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const getAllFeaturesFromServer = () => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formGetAllFeature()
    return axios.get(request).then(function (response) {
      dispatch(getAllFeatures(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addOrgFeatureOnServer = (orgFeature, orgID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formOrganizationFeature(orgFeature, orgID)
    return axios.post(request, '').then(function (response) {
      dispatch(addOrgFeature(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deleteOrganizationFeatureOnServer = (orgFeature, orgID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formOrganizationFeature(orgFeature, orgID)
    return axios.delete(request).then(function (response) {
      dispatch(deleteOrgFeature(orgFeature.id))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}
