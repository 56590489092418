import React, { useState, useMemo, useEffect } from 'react'

import { Grid } from '@mui/material'

import { ProductCardsContainer } from '../FoodServiceProducts/styles'
import RelatedProductCard from '../RelatedRetailProducts/partials/RelatedProductCard'

const MAX_RELATED_PRODUCTS = 20

const SWRelatedProducts = (props) => {
  const { retailProducts } = props

  const [imgErrorIds, setImgErrorIds] = useState([])

  const validRetailProducts = useMemo(
    () =>
      retailProducts.filter(
        // eslint-disable-next-line
        ({ name, image_url, image_uri }) =>
          !imgErrorIds.find(
            // eslint-disable-next-line
            (id) => `${name}##${image_url || image_uri}` === id,
          ),
      ),
    [imgErrorIds, retailProducts],
  )

  useEffect(() => {
    // eslint-disable-next-line
    retailProducts.forEach(({ image_url, image_uri, name }) => {
      const img = new Image()
      // eslint-disable-next-line
      const validImgUrl = image_url || image_uri
      img.onerror = () => {
        img.onerror = null
        setImgErrorIds((imgErrorIds) => [
          ...imgErrorIds,
          `${name}##${validImgUrl}`,
        ])
      }
      img.src = validImgUrl
    })
  }, [retailProducts])

  if (!validRetailProducts.length) {
    return null
  }

  const finalValidRetailProducts = validRetailProducts.slice(
    0,
    MAX_RELATED_PRODUCTS,
  )

  return (
    <ProductCardsContainer
      sx={{ maxHeight: 320, width: '100%' }}
      container
      spacing={1}
      my={1}
    >
      {finalValidRetailProducts.map((item) => (
        <Grid
          item
          xs={finalValidRetailProducts.length === 1 ? 12 : 6}
          key={item.es_id}
        >
          <RelatedProductCard
            // eslint-disable-next-line
            image={item.image_url}
            name={item.name}
            brand={item.brand_name}
            price={item.price}
            currency={item.currency}
            url={item.product_url}
            isBackdropOpen={false}
            esId={item?._id || item?.es_id}
            disableLog
          />
        </Grid>
      ))}
    </ProductCardsContainer>
  )
}

export default SWRelatedProducts
