import * as AC from '../actions/actionConstants'

const organizationReducer = (state = {
  list: [],
  all: [],
  selected: {

  },
  organizationList: true,
  organizationForm: false,
  organizationView: false,
  organizationDetails: {},
  registrationForm: false,
  registrationUserForm: false,
  orgAdminList: true,
  orgUserList: true,
  orgUsers: [],
  orgProjectList: true,
  projectForm: false,
  orgProject: [],
  orgFeatureList: true,
  featureForm: false,
  orgFeature: [],
  allFeature: [],
  allProjects: [],
}, action) => {
  switch (action.type) {
    case AC.ADD_ORGANIZATION:
      return Object.assign({}, state, {
        list:
                    [...state.list,
                      action.organization,
                    ],
        all:
                    [...state.all,
                      action.organization,
                    ],
      })

    case AC.EDIT_ORGANIZATION:
      return Object.assign({}, state, {
        list: state.list.map(org => org.id === action.organization.id ? action.organization : org),
        all: state.all.map(org => org.id === action.organization.id ? action.organization : org),
      })

    case AC.DELETE_ORGANIZATION:
      return Object.assign({}, state, {
        list: state.list.filter(org => org.id !== action.orgID),
        all: state.all.filter(org => org.id !== action.orgID),
      })
    case AC.ADD_ALL_ORGANIZATIONS:
      return Object.assign({}, state, {
        list: action.allOrganizations,
        all: action.allOrganizations,
      })
    case AC.SHOW_ORGANIZATION_FORM:
      return Object.assign({}, state, { organizationForm: true })

    case AC.HIDE_ORGANIZATION_FORM:
      return Object.assign({}, state, { organizationForm: false })

    case AC.HIDE_ORGANIZATION_LIST:
      return Object.assign({}, state, { organizationList: false })

    case AC.SHOW_ORGANIZATION_LIST:
      return Object.assign({}, state, { organizationList: true })

    case AC.HIDE_ORGANIZATION_VIEW:
      return Object.assign({}, state, { organizationView: false })

    case AC.SHOW_ORGANIZATION_VIEW:
      return Object.assign({}, state, { organizationView: true })

    case AC.ADD_ORGANIZATION_VIEW_DETAILS:
      return Object.assign({}, state, { organizationDetails: action.organization })

    case AC.SHOW_REGISTRATION_FORM:
      return Object.assign({}, state, { registrationForm: true })
    case AC.SHOW_USER_REGISTRATION_FORM:
      return Object.assign({}, state, { registrationUserForm: true })
    case AC.SHOW_ORGANIZATION_ADMIN_LIST:
      return Object.assign({}, state, { orgAdminList: true })
    case AC.SHOW_ORGANIZATION_USER_LIST:
      return Object.assign({}, state, { orgUserList: true })
    case AC.HIDE_ORGANIZATION_ADMIN_LIST:
      return Object.assign({}, state, { orgAdminList: false })
    case AC.HIDE_ORGANIZATION_USER_LIST:
      return Object.assign({}, state, { orgUserList: false })

    case AC.HIDE_REGISTRATION_FORM:
      return Object.assign({}, state, { registrationForm: false })
    case AC.HIDE_USER_REGISTRATION_FORM:
      return Object.assign({}, state, { registrationUserForm: false })
    case AC.GET_ALL_USERS_OF_ORGANIZATION:
      return Object.assign({}, state, {
        orgAdmin: action.allUsers.admins,
        orgUser: action.allUsers.users,
      })
    case AC.ADD_ORG_ADMIN:
      return Object.assign({}, state, { orgAdmin: [...state.orgAdmin, action.orgAdmin] })
    case AC.ADD_ORG_USER:
      return Object.assign({}, state, { orgUser: [...state.orgUser, action.orgUser] })

    case AC.EDIT_ORGANIZATION_ADMIN:
      return Object.assign({}, state, {
        orgAdmin: state.orgAdmin.map(admin => admin.id === action.orgAdmin.id ? action.orgAdmin : admin),

      })
    case AC.DELETE_ORGANIZATION_ADMIN:
      return Object.assign({}, state, {
        orgAdmin: state.orgAdmin.filter(admin => admin.id !== action.orgAdminID),

      })

    case AC.EDIT_ORGANIZATION_USER:
      return Object.assign({}, state, {
        orgUser: state.orgUser.map(user => user.id === action.orgUser.id ? action.orgUser : user),

      })
    case AC.DELETE_ORGANIZATION_USER:
      return Object.assign({}, state, {
        orgUser: state.orgUser.filter(admin => admin.id !== action.orgUserID),

      })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        list: [],
        all: [],
        organizationList: true,
        organizationForm: false,
        organizationView: false,
        organizationDetails: {},
        registrationForm: false,
        registrationUserForm: false,
        orgAdminList: true,
        orgUserList: true,
        orgUsers: [],
      })
    case AC.GET_ALL_SUB_USERS:
      console.log('action.allSubUsers', action.allSubUsers)
      return Object.assign({}, state, {
        orgUser: action.allSubUsers,
      })
    case AC.ADD_ORG_SUB_USER:
      console.log(' action.orgSubUser', action.orgSubUser)
      return Object.assign({}, state, { orgUser: [...state.orgUser, action.orgSubUser] })

    case AC.EDIT_ORGANIZATION_SUB_USER:
      console.log(' action.orgSubUser', action.orgSubUser)
      return Object.assign({}, state, {
        orgUser: state.orgUser.map(user => user.id === action.orgSubUser.id ? action.orgSubUser : user),

      })
    case AC.DELETE_ORGANIZATION_SUB_USER:
      console.log(' action.orgUserID', action.orgUserID)
      return Object.assign({}, state, {
        orgUser: state.orgUser.filter(admin => admin.id !== action.orgUserID),

      })
    case AC.SHOW_ORGANIZATION_PROJECT_LIST:
      return Object.assign({}, state, { orgProjectList: true })
    case AC.HIDE_ORGANIZATION_PROJECT_LIST:
      return Object.assign({}, state, { orgProjectList: false })
    case AC.SHOW_ORGANIZATION_PROJECT_FORM:
      return Object.assign({}, state, { projectForm: true })
    case AC.HIDE_ORGANIZATION_PROJECT_FORM:
      return Object.assign({}, state, { projectForm: false })
    case AC.GET_ALL_PROJECTS_OF_ORGANIZATION:
      console.log('action.allProjects', action.allProjects)
      return Object.assign({}, state, {
        orgProject: action.allProjects,

      })
    case AC.DELETE_ORGANIZATION_PROJECT:
      console.log(' action.orgProjectID', action.orgProjectID)
      return Object.assign({}, state, {
        orgProject: state.orgProject.filter(project => project.id !== action.orgProjectID),

      })
    case AC.ADD_ORG_PROJECT:
      console.log(' action.orgProject', action.orgProject)
      return Object.assign({}, state, { orgProject: [...state.orgProject, action.orgProject] })
    case AC.SHOW_ORGANIZATION_FEATURE_LIST:
      return Object.assign({}, state, { orgFeatureList: true })
    case AC.HIDE_ORGANIZATION_FEATURE_LIST:
      return Object.assign({}, state, { orgFeatureList: false })
    case AC.SHOW_ORGANIZATION_FEATURE_FORM:
      return Object.assign({}, state, { featureForm: true })
    case AC.HIDE_ORGANIZATION_FEATURE_FORM:
      return Object.assign({}, state, { featureForm: false })
    case AC.GET_ALL_FEATURES_OF_ORGANIZATION:
      console.log('action.allProjects', action.allFeatures)
      return Object.assign({}, state, {
        orgFeature: action.allFeatures,

      })
    case AC.GET_ALL_FEATURES:
      console.log('action.allProjects', action.allFeatures)
      return Object.assign({}, state, {
        allFeature: action.allFeatures,

      })
    case AC.DELETE_ORGANIZATION_FEATURE:
      console.log(' action.orgFeatureID', action.orgFeatureID)
      return Object.assign({}, state, {
        orgFeature: state.orgFeature.filter(project => project.id !== action.orgFeatureID),

      })
    case AC.ADD_ORG_FEATURE:
      console.log(' action.orgFeature', action.orgFeature)
      return Object.assign({}, state, { orgFeature: [...state.orgFeature, action.orgFeature] })
    case AC.ADD_ALL_PROJECTS:
      return Object.assign({}, state, {
        allProjects: action.projects,
      })

    default:
      return state
  }
}

export default organizationReducer
