import React from 'react'
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  SvgIcon,
  Box,
} from '@mui/material'
import { ReactComponent as PlusIcon } from '../../assets/icons/feather-plus-circle.svg'
import CommonTrendAnalysisExpanded from './CommonTrendAnalysisExpanded'
import {
  StyledTable,
  StyledGrid,
  StyledLink,
  PositiveRate,
  NegativeRate,
} from './Styled'
import { Link } from 'react-router-dom'

const CommonTrendAnalysisTable = (props) => {
  const {
    commonTrendAnalysisList,
    showDetilsFor,
    handleExpandClick,
    handleShrinkClick,
    productComparisonData,
    acrossSelected,
    trendType,
  } = props

  const twoYearCAGRCalculation = (twoYearCagr) => {
    const twoYearCAGR = twoYearCagr.toFixed(2)
    return twoYearCAGR >= 0 ? (
      <PositiveRate component="span">{`+${twoYearCAGR}%`}</PositiveRate>
    ) : (
      <NegativeRate component="span">{`${twoYearCAGR}%`}</NegativeRate>
    )
  }
  const preventPropagation = (event) => {
    event.stopPropagation()
  }

  return (
    <>
      {commonTrendAnalysisList &&
        commonTrendAnalysisList.data.order &&
        commonTrendAnalysisList.data.order.map((d) =>
          showDetilsFor === d ? (
            <CommonTrendAnalysisExpanded
              lens={commonTrendAnalysisList.data.lens}
              handleShrinkClick={handleShrinkClick}
              productComparisonData={productComparisonData}
              imageUrl={
                // eslint-disable-next-line
                commonTrendAnalysisList.data.commonTrends[d][0]['image_url']
              }
              productName={d}
              acrossSelected={acrossSelected}
              emergingInCategories={
                commonTrendAnalysisList.data.commonTrends[d].length
              }
              trendType={trendType}
            />
          ) : (
            <StyledGrid
              container
              spacing={2}
              columns={24}
              backgroundColor={(theme) => theme.palette.white}
              alignItems="center"
              mt={3}
              onClick={() => handleExpandClick(d)}
            >
              <Grid item xs={1}>
                <SvgIcon
                  component={PlusIcon}
                  width="16"
                  height="16"
                  onClick={() => handleExpandClick(d)}
                  cursor="pointer"
                />
              </Grid>
              <Grid item xs={5} ml={1}>
                <Grid container spacing={0} columns={16}>
                  <Grid item xs={4}>
                    <Box
                      component="img"
                      width="50px"
                      height="50px"
                      src={
                        commonTrendAnalysisList.data.commonTrends[d][0][
                          // eslint-disable-next-line
                          'image_url'
                        ]
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {d}
                      <br />
                      <Typography
                        variant="caption"
                        color={(theme) => theme.palette.text.secondary}
                      >
                        ({trendType} in{' '}
                        {commonTrendAnalysisList.data.commonTrends[d].length}{' '}
                        {acrossSelected === 'country'
                          ? 'countries'
                          : 'categories'}
                        )
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={16} pt={-1}>
                <Grid container spacing={0} columns={16} alignItems="center">
                  <Grid item xs={15} mb={1}>
                    <StyledTable>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {acrossSelected === 'country'
                              ? 'Country'
                              : 'Category'}
                          </TableCell>
                          {commonTrendAnalysisList.data.commonTrends[d].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <StyledLink
                                  onClick={preventPropagation}
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${commonTrendAnalysisList.data.lens}/${ds.id}`}
                                >
                                  {acrossSelected === 'country'
                                    ? ds.country
                                    : ds.category}
                                </StyledLink>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Rank</TableCell>
                          {commonTrendAnalysisList.data.commonTrends[d].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <Link
                                  onClick={preventPropagation}
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${commonTrendAnalysisList.data.lens}/${ds.id}`}
                                >
                                  {`#${ds.rank}`}
                                </Link>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>2 Year CAGR</TableCell>
                          {commonTrendAnalysisList.data.commonTrends[d].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <Link
                                  onClick={preventPropagation}
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${commonTrendAnalysisList.data.lens}/${ds.id}`}
                                >
                                  {twoYearCAGRCalculation(ds.two_year_cagr)}
                                </Link>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableBody>
                    </StyledTable>
                  </Grid>
                </Grid>
              </Grid>
            </StyledGrid>
          ),
        )}
    </>
  )
}

export default CommonTrendAnalysisTable
