import React from 'react'
import NoBookmarkImage from '../../assets/images/testImages/nodata.svg'
import { Typography, Box } from '@mui/material'
import { Main } from './Styles'

const NoBookmarksAvailable = () => {
  return (
    <Main>
      <Box>
        <Box component="img" src={NoBookmarkImage} alt="No bookmarks found" />
        <Typography variant="subtitle1">You have not bookmarked any trends yet.</Typography>
      </Box>
    </Main>
  )
}

export default NoBookmarksAvailable