import React from 'react'
import { connect } from 'react-redux'

import { SvgIcon, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'

import TabPanel from '../TabPanel'
import TrendTable from '../TrendsTable'
import EmptyIngredientsCard from '../EmptyIngredientsCard'
import SelectedFiltersTags from '../SelectedFiltersTags'

import { ReactComponent as IngredientsIcon } from '../../../assets/icons/ingredients.svg'
import { ReactComponent as BPCIngredientsIcon } from '../../../assets/icons/bpc-ingredient.svg'
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../../assets/icons/themes.svg'
import SearchNotSelected from '../SearchNotSelected'

const CognitiveSearchTable = (props) => {
  const {
    trends,
    projectVariant,
    lensSelected,
    lensToShow,
    shouldOpenTrendInfo,
    selectedFormatOpts,
    selectedAdvFilterOpts,
    anyFormatsSelected,
  } = props

  const lensIconMap = {
    ingredient: projectVariant === 'bpc' ? BPCIngredientsIcon : IngredientsIcon,
    product: ProductsIcon,
    theme: ThemesIcon,
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    props.handleChangeLens({
      list: [
        lensToShow.find(
          (lens) => lens.displayValue === lensToShow[newValue].name,
        ),
      ],
    })
  }

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Tabs
          value={lensToShow.findIndex(({ name }) => lensSelected === name)}
          onChange={handleChange}
          aria-label="Lens Tabs"
        >
          {lensToShow.map(({ name }, index) => (
            <Tab
              key={name}
              icon={<SvgIcon component={lensIconMap[name.toLowerCase()]} />}
              label={`${name}s`}
              {...tabProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        <SelectedFiltersTags
          lens={lensSelected}
          handleRemoveSelectedFormat={props.handleRemoveSelectedFormat}
          selectedFormats={selectedFormatOpts.filter(
            (obj) => obj.checked === true,
          )}
          handleRemoveSelectedTrend={props.handleRemoveSelectedAdvFilter}
          selectedFilters={selectedAdvFilterOpts.filter(
            (obj) => obj.checked === true,
          )}
          selectedFiltersLabel={'Formats'}
          trends={trends}
          isCS
          showMoreButton={true}
        />
      </Box>
      {lensToShow.map(({ name }, id) => (
        <TabPanel
          key={name}
          value={lensToShow.findIndex(({ name }) => lensSelected === name)}
          index={id}
        >
          {anyFormatsSelected ? (
            trends?.length ? (
              <TrendTable
                // TODO Should add props required for amplitude
                data={trends}
                sortOrder="desc"
                orderKey="data_point"
                lensSelected={props.lensSelected}
                projectVariant={projectVariant}
                noRankAvailable
                enableProductLensFsAndRetail
                shouldOpenTrendInfo={shouldOpenTrendInfo}
                tableCmpHeight={400}
                type="cognitiveSearch"
              />
            ) : (
              <EmptyIngredientsCard lens={props.lensSelected} />
            )
          ) : (
            <SearchNotSelected
              boxStyles={{
                border: ({ palette }) => `1px solid ${palette.divider}`,
                background: ({ palette }) => palette.white,
                borderRadius: ({ shape }) => shape.borderRadius * 0.25,
              }}
              text="Please select Format to explore the Trends"
            />
          )}
        </TabPanel>
      ))}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CognitiveSearchTable)
