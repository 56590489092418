import React from "react";
import Notfound from '../../assets/images/testImages/Notfound.svg';
import { Box, Grid, Typography } from '@mui/material'

const NoResult = () => {
	return (
		<>
			<Grid container spacing={2} columns={12}>
				<Grid xs={5} />
				<Grid xs={4} pt={20}>
					<Box component="img" src={Notfound} alt="data is not available" />
				</Grid>
			</Grid>
			<Grid container spacing={2} columns={16}>
				<Grid xs={7} />
				<Grid xs={3} pt={3}>
					<Typography variant="subtitle1" textAlign='center'>
						No results found for searched data
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default NoResult;


