import palette from '../palette';

export default {
  defaultProps: {
    indicatorColor: 'secondary',
  },
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
    },
    indicator: {
      height: 2,
    },
  },
};

