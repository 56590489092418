import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  fetchProjectDetails,
  searchConcepts,
  searchProjects,
  clearSearchResults,
} from "../../store/actions/screenWinnerActions";
import { addHighlight } from "../../store/actions/index";

import SWSearchResults from "../../components/SWSearchResults/SWSearchResults";

const SWSearchResultsPage = ({
  match,
  conceptsList,
  projectsList,
  searchConcepts,
  searchProjects,
  addHighlight,
  clearSearchResults,
  screenWinnerSubscription,
}) => {
  const { projectId } = match.params;
  const url = match.url;
  const query =
    match.params.query || url.slice(url.search("/search-results/") + 16);

  const handleResultsClick = (route, id) => {
    addHighlight({ route: route, highlightId: id })
  }
  
  useEffect(() => {
    if(screenWinnerSubscription){
      if (projectId) {
        fetchProjectDetails(projectId);
        searchConcepts(projectId, query);
      } else {
        searchProjects(query);
      }
      return () => clearSearchResults();
    }
  }, [query, projectId, screenWinnerSubscription]);

  return (
    <SWSearchResults
      query={query}
      projectsList={projectsList}
      conceptsList={conceptsList}
      handleResultsClick={handleResultsClick}
    />
  );
};

const mapStateToProps = (state) => {
  const { searchResults, subscribed } = state.screenWinner;
  return {
    screenWinnerSubscription: subscribed,
    conceptsList: searchResults.conceptsList,
    projectsList: searchResults.projectsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchConcepts: (projectId, query) => dispatch(searchConcepts(projectId, query)),
    searchProjects: (query) => dispatch(searchProjects(query)),
    clearSearchResults: () => dispatch(clearSearchResults()),
    addHighlight: (hl) => dispatch(addHighlight(hl)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SWSearchResultsPage)
);
