import React from 'react'
import Searchdata from '../../../../../assets/images/testImages/Searchdata.svg'
import { Box, Grid, Typography, Stack } from '@mui/material'

const SearchNotSelected = ({ text = '' }) => {
  return (
    <Grid
      container
      mt={1}
      justifyContent="center"
      sx={{ height: 456, bgcolor: ({ palette }) => palette.background.paper }}
    >
      <Stack spacing={3.75} justifyContent="center" alignItems="center">
        <Box component="img" src={Searchdata} alt="select opiton to search" />
        <Typography variant="subtitle1">
          {text || 'Select above data to view comparison of trends'}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default SearchNotSelected
