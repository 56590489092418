import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import axios from '../../services/networking/axios'
import * as actions from '../../store/actions/index'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import ProductDetailResponse from '../../services/networking/Models/ProductDetailResponse'
import { Breadcrumbs, Grid, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { trendTypes,BPC } from '../../shared/utils/constants'
import DetailedCompareCard from '../../shared/components/DetailedCompareCard'
import AddToCompareCard from '../../shared/components/AddToCompareCard'
import TrendOverCard from '../../shared/components/TrendOverCard'
import OverlayLoader from '../../shared/components/OverlayLoader'
import {
  useCompare,
  useCompareDispatch,
} from '../../shared/contexts/CompareContext'
import '../muiStyle.css'
import { amplify } from './../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

const breadcrumbs = [
  <Link variant="breadcrumbsLink" key="1" href="/">
    Foresight Engine
  </Link>,
  <Typography key="2" variant="subtitle2">
    Compare Trends
  </Typography>,
]

const amp = (props, ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Compare_Country_Category':
        amplitudeUserData = {
          User: ampiInstance._email,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.v.country,
          Category_Name: ampiUserData.v.category,
          Compared_Country: ampiUserData.trends.map((trend) => trend._country),
          Compared_Category: ampiUserData.trends.map(
            (trend) => trend._category,
          ),
          Compared_Trend_Name: ampiUserData.trends.map((trend) => trend._name),
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
      case 'Clicked_Compare_Phase':
        amplitudeUserData = {
          User: ampiInstance._email,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.comProject.country,
          Category_Name: ampiUserData.comProject.category,
          Compared_Country: ampiUserData.trends.map((trend) => trend._country),
          Compared_Category: ampiUserData.trends.map(
            (trend) => trend._category,
          ),
          Compared_Trend_Name: ampiUserData.trends.map((trend) => trend._name),
          Compared_Maturity_Phase: ampiUserData.trends.map(
            (trend) => trend._trendName,
          ),
          Maturity_Phase: ampiUserData.v,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
      case 'Compare_Feature':
        amplitudeUserData = {
          User: ampiInstance._email,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.comProject.country,
          Category_Name: ampiUserData.comProject.category,
          Maturity_Phase: ampiUserData.compTT,
          Trend_Name: ampiUserData.v.name,
          Compared_Country: ampiUserData.trends.map((trend) => trend._country),
          Compared_Category: ampiUserData.trends.map(
            (trend) => trend._category,
          ),
          Compared_Trend_Name: ampiUserData.trends.map((trend) => trend._name),
          Compared_Maturity_Phase: ampiUserData.trends.map(
            (trend) => trend._trendName,
          ),
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in ComparePage')
  }
}

const ComparePage = (props) => {
  const [loading, setLoading] = useState(false)
  const trends = useCompare()
  const dispatch = useCompareDispatch()
  const dogImage = false

  useEffect(() => {
    const defaultSelectedLens =
      (localStorage.getItem('febetaProductLens') &&
        localStorage.getItem('febetaProductLens')[0].toUpperCase() +
          localStorage.getItem('febetaProductLens').slice(1)) ||
      'Ingredient'
    sessionStorage.setItem('defaultSelectedLens', defaultSelectedLens)
    props.requestProjectsList()
    shouldLogRecentActivity(
      "Explore Trends>Compare Trends",
      "Platform",
      `/mui/compare`,
    )
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [props.projectTrendList])

  const [comProject, setCompProject] = useState(null)
  const [compTT, setCompTT] = useState(null)
  const [compTrend, setCompTrend] = useState(null)
  const onProjectChange = (e, v) => {
    setCompProject(v)
    setCompTT(null)
    setCompTrend(null)
    const ampiEvent = 'Clicked_Compare_Country_Category'
    const ampiUserData = {
      trends: trends,
      v: v,
      lens: localStorage.getItem('febetaProductLens'),
    }
    amp(props, ampiEvent, ampiUserData)
  }
  const onTypeChange = (e, v) => {
    setCompTT(v)
    const ampiEvent = 'Clicked_Compare_Phase'
    const ampiUserData = {
      trends: trends,
      comProject: comProject,
      v: v,
      lens: localStorage.getItem('febetaProductLens'),
    }
    amp(props, ampiEvent, ampiUserData)
  }
  const onTrendChange = (e, v) => {
    setCompTrend(v)
    const ampiEvent = 'Compare_Feature'
    const ampiUserData = {
      trends: trends,
      comProject: comProject,
      compTT: compTT,
      v: v,
      lens: localStorage.getItem('febetaProductLens'),
    }
    amp(props, ampiEvent, ampiUserData)
  }

  useEffect(() => {
    if (compTrend) {
      setLoading(true)
      let request = ''
      let cafeProductRequest = ''
      let retailProductRequest = ''
      const productid = compTrend.id
      const lensSelected =
        sessionStorage.getItem('defaultSelectedLens') || 'Ingredient'
      switch (lensSelected) {
        case 'Product':
          request = NetworkUtil.formSearchRequestForProductFilterDetail(
            productid,
          )
          cafeProductRequest = NetworkUtil.fetchCafeProductsForProductFilter(
            productid,
          ) // Product
          retailProductRequest = NetworkUtil.fetchRetailProductsForProductFilter(
            productid,
          ) // Product
          break
        case 'Theme':
          request = NetworkUtil.formSearchRequestForThemeFilterDetail(productid) // Theme
          break
        default:
          // Ingredients
          request = NetworkUtil.formSearchRequestForProduct(productid)
          cafeProductRequest = NetworkUtil.fetchCafeProducts(productid) // Ingredients
          retailProductRequest = NetworkUtil.fetchRetailProducts(productid) // Ingredients
      }
      axios
        .get(request)
        .then((response) => {
          const payload = new ProductDetailResponse(response.data)
          dispatch({ type: 'added', payload: payload })
          setLoading(false)
          setCompTrend(null)
          setCompTT(null)
          setCompProject(null)
        })
        .catch((err) => console.log(err))
    }
  }, [compTrend])

  useEffect(() => {
    if (compTT && comProject) {
      setLoading(true)
      const defaultSelectCategory =
        sessionStorage.getItem('defaultSelectCategory') || 'All'
      const defaultSelectedLens =
        sessionStorage.getItem('defaultSelectedLens') || 'Ingredient'
      props.fetchProjectTrendList(
        comProject.id,
        defaultSelectCategory,
        defaultSelectedLens,
      )
    }
  }, [compTT])

  useEffect(() => {
    if (comProject) {
      localStorage.setItem('febetaProject', comProject.id)
    }
  }, [comProject])

  let myArrayFiltered = []
  if (trends && props.projectTrendList) {
    myArrayFiltered = []
    const masterData = compTT
      ? compTT === 'All Trends'
        ? _.flatten(_.map(props.projectTrendList, 'allTrendResults'))
        : props.projectTrendList[
            parseInt(
              trendTypes.find(({ title }) => title === compTT.split(' ')[0])
                .typer,
            )
          ].allTrendResults
      : []
    myArrayFiltered = masterData
      .filter((t) => !trends.find((v) => v._id === t.id))
      .sort(({ name: a }, { name: b }) => a.localeCompare(b))
  }

  if (!props.productCountry) return null
  return (
    <Stack spacing={3} my={3}>
      <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h2">Compare Trends</Typography>
      {loading && <OverlayLoader />}
      <Box>
        <Grid container spacing={1.25} alignItems="stretch">
          {trends &&
            trends.map((trend) => (
              <Grid item xs={12} md={3} display="flex">
                <DetailedCompareCard
                  id={trend._id}
                  projectid={trend._trendProjectId}
                  country={trend._searchCriteria[0].split(';')[1]}
                  lens={localStorage.getItem('febetaProductLens')}
                  name={trend._name}
                  type={trend._type}
                  rank={trend._rank}
                  trendName={trend._trendName}
                  dataPoint={trend._data_point}
                  category={trend._category}
                  productImage={trend._productImage}
                  categoryData={trend._category_data}
                  filterClassification={trend._filterClassification}
                  trends={trends}
                  isBPC={trend?._projectVariant === BPC
                  }
                />
              </Grid>
            ))}
          {trends && trends.length < 4 && (
            <Grid item xs={12} md={3}>
              <AddToCompareCard
                dogImage={dogImage}
                trends={trends}
                productCountry={props.productCountry}
                comProject={comProject}
                compTrend={compTrend}
                compTT={compTT}
                projectTrendList={
                  compTT && myArrayFiltered ? myArrayFiltered : []
                }
                onProjectChange={onProjectChange}
                onTypeChange={onTypeChange}
                onTrendChange={onTrendChange}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <TrendOverCard trends={trends} />
    </Stack>
  )
}

const mapStateToProps = (state) => {
  var projectTrendList = []
  switch (
    (localStorage.getItem('febetaProductLens') &&
      localStorage.getItem('febetaProductLens')[0].toUpperCase() +
        localStorage.getItem('febetaProductLens').slice(1)) ||
    'Ingredient'
  ) {
    case 'Product':
      projectTrendList =
        state.projectListBuilder.productList &&
        state.projectListBuilder.productList.trends
      break
    case 'Theme':
      projectTrendList =
        state.projectListBuilder.themeList &&
        state.projectListBuilder.themeList.trends
      break
    default:
      projectTrendList =
        state.projectListBuilder.projectList &&
        state.projectListBuilder.projectList.trends
  }
  return {
    selectedProductForComparision:
      state.productComparision.selectedComparisionProduct,
    projectid: state.productComparision.projectid,
    projectTrendList: projectTrendList,
    selectedTrend: state.productComparision.selectedTrend,
    selectedLens: state.productComparision.lensSelected,
    productCountry: state.savedTrendRequester.savedTrendList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
    fetchProjectTrendList: (projectid, defaultSelectCategory, lensSelected) =>
      dispatch(
        actions.fetchProjectList(
          projectid,
          defaultSelectCategory,
          lensSelected,
        ),
      ),
    getProductListOfSelectedTrend: (trend) =>
      dispatch(actions.getProductListForCompareDropDown(trend)),
    userSelectedProject: (projectid) =>
      dispatch(actions.userSelectedProject(projectid)),
    setSelectedProject: (id) => dispatch(actions.setSelectedProject(id)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ComparePage),
)
