import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Link,
  Grid,
  IconButton,
  Typography,
  Breadcrumbs,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SingleSelect from '../SingleSelect'
import DeletePopUp from '../PopUp/DeletePopUp'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import WheelCardList from './WheelCardList'
import NoResultFound from '../NoResultFound/NoResultFound'
import EmptyWheel from './../../../assets/images/EmptyState/EmptyWheel.svg'
import { ReactComponent as ReturnIcon } from './../../../assets/icons/return-icon.svg'
import wheel from './../../../assets/wheel.png'
import { useHistory } from 'react-router-dom'
import { useLoader, useSingleSnackBar } from '../../../hooks'
import OverlayLoader from '../OverlayLoader'
import CloseIcon from '@mui/icons-material/Close'
import { THEME } from '../../utils/constants'

const defaultDropdownSelection = {
  index: -1,
  label: 'All',
}

export default function SavedWheelPage() {
  const [wheelList, setWheelList] = useState([])
  const [countrySelected, setCountrySelected] = useState(
    defaultDropdownSelection,
  )
  const [categorySelected, setCategorySelected] = useState(
    defaultDropdownSelection,
  )
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const [showLoader, setShowLoader] = useLoader()
  const history = useHistory()

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" color="action" />
    </IconButton>
  )

  const handleSnackBar = (message, status) => {
    showSnackBar(message, status, action)
  }

  const breadcrumbs = [
    <Link
      variant="inherit"
      key="1"
      to="/mui/main-system"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
      underline="none"
    >
      Explore Trends
    </Link>,
    <Link
      key="2"
      variant="subtitle2"
      to={`/mui/trend-pillar/?lensSelected=${THEME}`}
      underline="none"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
    >
      Trend Pillar
    </Link>,
    <Typography key="3" variant="subtitle2">
      Saved Wheels
    </Typography>,
  ]

  // Dropdown List for country and category

  let allProjects = []
  wheelList.forEach(({ country, category }) => {
    const project = {
      country,
      category,
    }
    allProjects = [...allProjects, project]
  })

  const uniqueCountries = [
    ...new Set(allProjects.map((project) => project.country)),
  ]

  const countries = uniqueCountries.map((country, index) => {
    return { id: index, label: country }
  })
  if (countries.length > 0 && countries[0]?.id !== -1) {
    countries.unshift(defaultDropdownSelection)
  }

  const uniqueCategories = [
    ...new Set(allProjects.map((project) => project.category)),
  ]
  const categories = uniqueCategories.map((category, index) => {
    return { id: index, label: category }
  })

  // Filtering the wheels based on country and category selection

  const filteringWheelLogic = (wheel) => {
    if (countrySelected.index === -1 && categorySelected.index === -1) {
      return true
    } else if (countrySelected.index === -1) {
      return wheel.category === categorySelected.label
    } else if (categorySelected.index === -1) {
      return wheel.country === countrySelected.label
    } else {
      return (
        wheel.country === countrySelected.label &&
        wheel.category === categorySelected.label
      )
    }
  }

  const filteredWheels = wheelList.filter((wheel) => filteringWheelLogic(wheel))

  // handling functions

  const handleCountryChange = (val) => {
    val ? setCountrySelected(val) : setCountrySelected(defaultDropdownSelection)
  }

  const handleCategoryChange = (val) => {
    val
      ? setCategorySelected(val)
      : setCategorySelected(defaultDropdownSelection)
  }

  const closeDeleteWarning = () => {
    setDeletePopupOpen(false)
  }

  const getWheelList = () => {
    setShowLoader(true)

    const req = NetworkUtil.getAllWheel()

    axios
      .get(req)
      .then((res) => {
        const resBody = res.data.data.wheels

        // Filtering
        const allWheelList = resBody.map((wheel) => {
          const { id, country, category, name } = wheel
          const data = {
            id,
            country,
            category,
            name,
          }
          return data
        })
        setWheelList(allWheelList)

        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        console.log('Error while requesting wheel list', err)
      })
  }

  const handleDelete = () => {
    // Handle Deleting
    const id = deletePopupOpen
    const req = NetworkUtil.getWheel(id)
    axios
      .delete(req)
      .then(() => {
        getWheelList()
        handleSnackBar('Delete Successful', 'success')
      })
      .catch((error) => {
        handleSnackBar('Delete Failed. Please Try Again.', 'error')
        console.log(error)
      })
    setDeletePopupOpen(false)
  }

  const onDeleteOptionClick = (e, id) => {
    e.stopPropagation()
    setDeletePopupOpen(id)
  }

  const handleRedirection = () => {
    history.push(`/mui/trend-pillar/?lensSelected=${THEME}`)
  }

  useEffect(() => {
    getWheelList()
  }, [])

  if (showLoader) return <OverlayLoader />

  return (
    <Box>
      <Grid item xs={4} display={'flex'} alignItems={'center'} gap={3} mt={3}>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          pl={1}
          variant="contained"
          onClick={handleRedirection}
          sx={{
            height: 'auto',
            padding: '10px',
            minWidth: '104px',
          }}
        >
          <ReturnIcon />
          <Typography sx={{ color: ({ palette }) => palette.white }} pl={1}>
            Go back to trend pillars
          </Typography>
        </Button>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <SingleSelect
          text={'Country:'}
          options={countries}
          onChange={(e, newVal) => handleCountryChange(newVal)}
          value={countrySelected}
          sx={{ width: '200px !important', paddingRight: '20px' }}
        />
        <SingleSelect
          text={'Category:'}
          onChange={(e, newVal) => {
            handleCategoryChange(newVal)
          }}
          sx={{ width: '200px !important' }}
          options={categories}
          value={categorySelected}
        />
      </Box>
      <Box pt={2}>
        {filteredWheels.length ? (
          <WheelCardList
            wheelList={filteredWheels}
            onDeleteOptionClick={onDeleteOptionClick}
            wheel={wheel}
          />
        ) : (
          <Box
            sx={{
              height: '500px',
              width: '100%',
              background: 'white',
            }}
            mt={2}
          >
            <NoResultFound
              imgAlt="No Result Found"
              message={`Wheels that are generated and saved will appear here.`}
              imgSrc={EmptyWheel}
              messageSx={{ paddingTop: '10px', maxWidth: '250px' }}
            />
          </Box>
        )}
      </Box>
      {deletePopupOpen && (
        <DeletePopUp
          textBody="Are you sure you want to delete it?"
          textHead="Wheel?"
          handleCancel={closeDeleteWarning}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  )
}
