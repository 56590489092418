import { Box, Button } from '@mui/material';
import styled from '@emotion/styled'

export const Tool = styled(Box) (({theme}) => ({
  fontSize: '14px',
  color: `${theme.palette.text.secondary}`
}))

export const Count = styled('div') (({theme}) => ({
  width: '30px',
  height: '20px',
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-5px',
  right: '-9px',
  lineHeight: '20px',
  fontSize: '12px',
  animation: 'xAxis 1s forwards linear',

  '@keyframes xAxis': {
    '100%': {
      transform: 'translateX(calc(400px - 50px))',
      right: '-19px',
      top: '-65px',
      'z-index': 0,
    },
    '98%': {
      transform: 'translate(calc(400px - 50px))',
      right: '-19px',
      top: '-65px',
      'z-index': 9999,
    },
    '0%': {
      right: '-9px',
      'z-index': 9999,
      transform: 'translateX(calc(0vw))',
    }
  }
}));

export const StyledButton = styled(Button)(({isBookmarkSwing}) => ({
  '-webkit-animation': isBookmarkSwing && 'swing 1s ease',
  animation: isBookmarkSwing && 'swing 1s ease',
  '-webkit-animation-iteration-count': 2,
  'animation-iteration-count': 2,
  position: 'relative !important',
  transition: 'all 0.3s ease',

  '@-webkit-keyframes swing': {
    '15%': {
      '-webkit-transform': 'translateX(5px)',
      transform: 'translateX(5px)'
    },
  
    '30%': {
      '-webkit-transform': 'translateX(-5px)',
      transform: 'translateX(-5px)'
    },
  
    '50%': {
      '-webkit-transform': 'translateX(3px)',
      transform: 'translateX(3px)'
    },
  
    '65%': {
      '-webkit-transform': 'translateX(-3px)',
      transform: 'translateX(-3px)'
    },
  
    '80%': {
      '-webkit-transform': 'translateX(2px)',
      transform: 'translateX(2px)'
    },
  
    '100%': {
      '-webkit-transform': 'translateX(0)',
      transform: 'translateX(0)'
    }
  },
  
  '@keyframes swing': {
    '15%': {
      '-webkit-transform': 'translateX(5px)',
      transform: 'translateX(5px)'
    },
  
    '30%': {
      '-webkit-transform': 'translateX(-5px)',
      transform: 'translateX(-5px)'
    },
  
    '50%': {
      '-webkit-transform': 'translateX(3px)',
      transform: 'translateX(3px)'
    },
  
    '65%': {
      '-webkit-transform': 'translateX(-3px)',
      transform: 'translateX(-3px)'
    },
  
    '80%': {
      '-webkit-transform': 'translateX(2px)',
      transform: 'translateX(2px)'
    },
  
    '100%': {
      '-webkit-transform': 'translateX(0)',
      transform: 'translateX(0)'
    }
  }
}))

