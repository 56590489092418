import React from 'react'
import { PopupBox, Boxes } from './Styled'
import { Box, Typography, Button, Divider } from '@mui/material'
import DiscardBucket from '../../../assets/images/testImages/discardBucket.svg'
import DiscardCross from '../../../assets/images/testImages/discardCross.svg'
import { Grid } from 'react-bootstrap'

const DiscardBucketPopUp = ({
  text = '',
  handleDiscardBucketCancel,
  handleDiscard,
  popUpTab,
  popUpTabDisable = false,
  heading,
  title,
  additionalMessage,
  noImage,
  cancelText,
  discardText,
}) => {
  return (
    <PopupBox sx={{ zIndex: 10000 }}>
      <Boxes>
        <Typography
          variant="subtitle1"
          pt={2}
          mb={1}
          color={(theme) => theme.palette.primary.main}
        >
          {popUpTabDisable
            ? heading
            : popUpTab === 'merge'
            ? 'Discard  Selected Pillars'
            : 'Discard Pillar Changes'}
        </Typography>
        <Box
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          component="img"
          top={24}
          right={20}
          position={'absolute'}
          onClick={() => {
            handleDiscardBucketCancel()
          }}
          src={DiscardCross}
          alt="No img found"
        />
        <Divider orientation="horizontal" sx={{ marginBottom: '25px' }} />
        {!noImage && (
          <Box component="img" src={DiscardBucket} alt="Image Unfound" />
        )}
        <Typography
          variant="subtitle2"
          pt={1}
          pb={1}
          color={(theme) => theme.palette.text.secondary}
        >
          {text != '' ? text : 'Are you sure you want to discard the changes?'}

          <Box sx={{ fontStyle: 'italic', marginTop: '20px' }}>
            {additionalMessage}
          </Box>
        </Typography>{' '}
        <Grid container columns={12}>
          <Button
            variant="outlined"
            onClick={() => {
              handleDiscardBucketCancel()
            }}
          >
            {cancelText || ` Cancel`}
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: '12px' }}
            onClick={() => {
              handleDiscard()
            }}
          >
            {discardText || `Discard`}
          </Button>
        </Grid>
      </Boxes>
    </PopupBox>
  )
}

export default DiscardBucketPopUp
