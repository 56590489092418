import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, MenuItem, SvgIcon, Typography, Link } from '@mui/material'
import { Box } from '@mui/system'

import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'

import { ReactComponent as PDFIcon } from '../../../assets/icons/pdf.svg'
import { ReactComponent as PTTIcon } from '../../../assets/icons/ppt.svg'

import { TrendCardContainer, DownloadMenu, DownloadLoader } from './styles'

import ReportDialogLink from '../ReportDialogLink'
import axios from '../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'

import { getQueryParams, amplify } from '../../../store/utility'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'
import { useHistory } from 'react-router-dom'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Trend_Report':
        amplitudeUserData = {
          User: ampiUserData.email,
          Title: ampiUserData.name,
          Type: ampiUserData.reportType,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiUserData.email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in ReportDialogLink')
  }
}

const TrendReportItem = ({
  id,
  name,
  date,
  thumbnailUrl,
  report,
  reportPPTUrl,
  email,
  firstName,
  lastName,
  open = false,
  filter,
  filters,
  reportType,
  openReport,
  closeReport,
  type,
  logReportClicked,
}) => {
  const history = useHistory()
  const [anchorElDownload, setAnchorElDownload] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)

  const logEvent = () => {
    const ampiEvent = 'Clicked_Trend_Report'
    const ampiUserData = {
      name: name,
      email: email,
      reportType: reportType,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleClickOpenReport = (e) => {
    e.preventDefault()
    if (type === 'searchResult') {
      logReportClicked(name)
    } else {
      logEvent()
    }
    openReport(id)
  }

  const handleCloseReport = () => {
    closeReport()
  }

  const handleOpenDownloadMenu = (event) => {
    setAnchorElDownload(event.currentTarget)
  }

  const handleCloseDownloadMenu = () => {
    setAnchorElDownload(null)
  }

  const handleDownloadComplete = () => {
    setIsDownloading(false)
    setDownloadProgress(0)
  }

  const downloadReport = (isPdf, reportUrl) => {
    const saveDownloadLog = NetworkUtil.saveReportMeta()
    axios
      .post(saveDownloadLog, {
        email,
        username: `${firstName}${lastName ? ` ${lastName}` : ''}`,
        report_name: `${name}.${isPdf ? 'pdf' : 'ppt'}`,
      })
      .catch((er) => console.log('Error in Logging!'))

    delete axios.defaults.headers.common.Authorization
    const ampiEvent = ` Clicked_Trend_Report_Download`
    const ampiUserData = {
      User: email,
      Report_Name: name,
      'Download_Type(PPT, PDF)': isPdf ? 'pdf' : 'ppt',
      filter: Object.values(filters)[filter],
    }
    amplify(ampiEvent, ampiUserData, email)
    setIsDownloading(true)
    axios
      .get(reportUrl, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percentCompleted = Math.floor(
            (loaded / parseFloat(total)) * 100,
          )
          setDownloadProgress(percentCompleted)
        },
      })
      .then((response) => {
        window.saveAs(response.data, name)
        // eslint-disable-next-line
        if (isProduction() && !isAipaletteUser(email)) {
          // eslint-disable-next-line
          amplitude.getInstance().setUserId(email)
          // eslint-disable-next-line
          amplitude.getInstance().setUserProperties(`Name : ${firstName}`)
          // eslint-disable-next-line
          amplitude.getInstance().logEvent(`User Downloaded Trend Report`)
        }
        handleDownloadComplete()
      })
      .catch((err) => {
        console.log('Error in saving reports!', err)
        handleDownloadComplete()
      })
    axios.defaults.headers.common.Authorization = NetworkUtil.getAuthKey()
    handleCloseDownloadMenu()
  }

  useEffect(() => {
    if (open) {
      shouldLogRecentActivity(
        name,
        'Trend Report',
        `${history.location.pathname}${history.location.search}`,
      )
    }
  }, [open])

  return (
    <TrendCardContainer>
      <Link
        variant="body2"
        underline="none"
        href="/#"
        onClick={handleClickOpenReport}
      >
        <img src={thumbnailUrl} alt={name} />
      </Link>
      <Box>
        <ReportDialogLink
          initOpen={open}
          id={id}
          thumbnailUrl={thumbnailUrl}
          name={name}
          report={report}
          reportType={reportType}
          handleClickOpen={handleClickOpenReport}
          handleClose={handleCloseReport}
        />
        <Box>
          {!isDownloading ? (
            <Button
              size="small"
              onClick={handleOpenDownloadMenu}
              startIcon={<FileDownloadRoundedIcon />}
            >
              Download
            </Button>
          ) : (
            <DownloadLoader variant="determinate" value={downloadProgress} />
          )}
          <DownloadMenu
            anchorEl={anchorElDownload}
            open={Boolean(anchorElDownload)}
            onClose={handleCloseDownloadMenu}
          >
            <MenuItem onClick={() => downloadReport(true, report)}>
              <SvgIcon component={PDFIcon} />
              Download PDF
            </MenuItem>
            <MenuItem onClick={() => downloadReport(false, reportPPTUrl)}>
              <SvgIcon component={PTTIcon} />
              Download PPT
            </MenuItem>
          </DownloadMenu>
          <Typography variant="caption" color="textSecondary">
            {date}
          </Typography>
        </Box>
      </Box>
    </TrendCardContainer>
  )
}

TrendReportItem.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default TrendReportItem
