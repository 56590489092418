export default {
  styleOverrides: {
    root: {
      '& .MuiAppBar-root': {
        filter: 'drop-shadow(0px 4px 12px rgba(39, 43, 49, 0.03))',
      },
    },
    elevation4: {
      boxShadow: 'none',
    },
  },
};
