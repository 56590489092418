import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import conceptBulb from '../../assets/images/screenWinner/sw-concept-bulb.svg'
import calendarIcon from '../../assets/images/screenWinner/sw-calendar-icon.svg'
import deleteIcon from '../../assets/images/screenWinner/sw-red-delete.svg'
import ImgSafe from '../Image/ImageSafe'
import {
  screeningStatus as swStatus,
  subCategory as swSubCat,
} from '../../const'
import FlyOutOptions from '../FlyOutOptions/FlyOutOptions.js'
import Tooltip from '../SWTooltip/SWTooltip_v2'

import './ProjectCard.scss'

const ProjectCard = ({
  hideOpts = false,
  id = '1',
  title = '',
  description = '',
  conceptsCount = 0,
  country = '',
  category = '',
  brand = '',
  screeningStatus = '',
  deleteProject,
  subcategory,
}) => {
  const [showOpts, setShowOpts] = useState(false)
  const handleDeleteProject = () => {
    deleteProject(id, screeningStatus === swStatus.IN_PROGRESS)
  }
  const marketText = `${country}, ${category}${brand ? `, ${brand}` : ''}${
    subcategory && subcategory !== swSubCat.ALL ? `, ${subcategory}` : ''
  }`
  return (
    <div className="project-card">
      <div
        className="project-card__opts__block"
        onClick={(ev) => ev.stopPropagation()}
      >
        {!hideOpts && (
          <FlyOutOptions
            show={showOpts}
            options={[
              {
                element: (
                  <span className="project-card__opts__del">
                    <ImgSafe src={deleteIcon} alt="Delete" />
                    Delete
                  </span>
                ),
                onClick: handleDeleteProject,
              },
            ]}
            className="project-card__fl-opts"
            optsClassName="project-card__fl-opts__list"
          >
            <span className="project-card__opts">...</span>
          </FlyOutOptions>
        )}
      </div>
      <Link to={`/screen-winner/projects/${id}/concepts`}>
        <h2 className="project-card__meta-data">Project ID: {id}</h2>
        <Tooltip title={marketText}>
          <h2 className="project-card__market">{marketText}</h2>
        </Tooltip>
        <div className="project-card__info-card">
          <h3 className="info-title title--14">{title}</h3>
          <p className="info-description para--12">{description}</p>
        </div>
        <div className="project-card__cncpts-info__block">
          <div className="project-card__cncpts-info">
            <img
              className="project-card__cncpts-info__img"
              src={calendarIcon}
              alt="calendar"
            />
            {`${
              screeningStatus === swStatus.IN_PROGRESS
                ? 'Screening is in progress'
                : screeningStatus.slice(0, 1) +
                  screeningStatus.slice(1).toLowerCase()
            }`}
          </div>
          <div className="project-card__cncpts-info">
            <img
              className="project-card__cncpts-info__img"
              src={conceptBulb}
              alt="Concept bulb"
            />
            {`${conceptsCount} Concepts`}
          </div>
        </div>
      </Link>
    </div>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ProjectCard
