import { Box, Grid } from '@mui/material'

import React from 'react'

const CustomSiblingHeightGridItem = (props) => {
  const { parentContainerSpacing, xs } = props
  return (
    <Grid item sx={{ position: 'relative' }} xs={xs}>
      <Box
        sx={{
          position: 'absolute',
          top: `${parentContainerSpacing * 8}px`,
          left: `${parentContainerSpacing * 8}px`,
          bottom: 0,
          right: 0,
        }}
      >
        {props.children}
      </Box>
    </Grid>
  )
}

export default CustomSiblingHeightGridItem
