import React,{ useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Stack,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import palette from '../../../theme/palette';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import {amplify} from './../../../store/utility'
import {calcDisplayedWidth} from "./../../../store/utility"
import { useSnackbar } from 'notistack';

import * as actions from '../../../store/actions';
const schema = yup.object({
  firstName : yup
  .string()
  .required('Required')
  .matches(/^[a-zA-Z\s]*$/, 'First Name should contain only alphabets'),
  lastName : yup
  .string()
  .matches(/^(^$)|^[a-zA-Z\s]*$/, 'Last Name should contain only alphabets'),
  email : yup.string().email()
}).required();

const PersonalInformationCard = ({user, updateUserProfile, getUserProfile, firstName, lastName}) => {
  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      lastName : user.lastName,
      email : user.email,
      firstName : user.firstName,
      userName : user.username  
    }
  });
  const { enqueueSnackbar } = useSnackbar()

  const snackbarShow = (content,muiVariant) => {
    enqueueSnackbar(content, { variant: muiVariant});
  };
  const onSubmit = async data => {
    const res = updateUserProfile(data); 
    res ? snackbarShow(`Your profile has been updated successfully`,'success') : snackbarShow('Unable to update profile','error')
    const ampiEvent = `Clicked_Profile_Save_Changes`
    const ampiUserData = {
      'User': user.email,
    }
    amplify(ampiEvent, ampiUserData,user.email)  
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

const valueWidth = (val) => val && calcDisplayedWidth(val, 16 ,'Roboto',400,'normal') < 347 ? '' : val

  const [showPassword, setShowPassword] = useState({
    password: false,
    currentPassword: false,
    confirmPassword: false,
  });
  const handleClickShowPassword = key => () => setShowPassword({
    ...showPassword,
    [key]: !showPassword[key],
  });
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Typography
        variant="h3"
        display="flex"
        justifyContent="center">
        Personal Information
      </Typography> */}
      {/*
      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        width={{
          xs: '100%',
          sm: '75%',
          md: '75%',
          lg: '50%',
          xl: '50%',}}
        sx={{ my: 3 }}
      >
        <Box
          sx={{
            height: 86,
            width: 86,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            border: `1px solid ${palette.divider}`,
            bgcolor: palette.white,
          }}
        >
          <UserIcon />
        </Box>
        <Button
          variant="outlined"
          size="large"
          startIcon={<UploadIcon />}
        >
          Upload Photo
        </Button>
      </Stack> */}
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          sx={{width:'-webkit-fill-available'}}
          spacing={3}
        >
        <form style={{maxWidth: 500}} onSubmit={handleSubmit(onSubmit)}>
          <Stack
            mt={2}
            direction="row"
            spacing={3}>
            {/* <Controller
              control={control}
              name="company"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="Company"
                  placeholder="Company"
                  fullWidth
                />
               </Tooltip>
              )}
            /> */}
            <Controller
              control={control}
              name= "firstName"
              render={({
                field: {
                  onChange, value, firstName, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={firstName}
                  value={value}
                  defaultValue={user.firstName }
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="First Name"
                  placeholder="First Name"
                  fullWidth
                />
                </Tooltip>
              )}
            />
          </Stack>
          <Stack
            mt={2}
            direction="row"
            spacing={3}>
            {/* <Controller
              control={control}
              name="country"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="Country"
                  placeholder="Country"
                  fullWidth
                />
                </Tooltip>
              )}
            /> */}
            <Controller
              control={control}
              name="lastName"
              render={({
                field: {
                  onChange, value, lastName, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={lastName}
                  value={value}
                  defaultValue={user.lastName}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                />
              </Tooltip>
              )}
            />
          </Stack>
          <Stack
            mt={2}
            direction="row"
            spacing={3}>
            {/* <Controller
              control={control}
              name="department"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="Department"
                  placeholder="Department"
                  fullWidth
                />
                </Tooltip>
              )}
            /> */}
            <Controller
              control={control}
              name="email"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  inputRef={ref}
                  name={name}
                  value={value}
                  defaultValue={user.email}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  variant="outlined"
                  size="large"
                  label="Email"
                  placeholder="Email"
                  fullWidth
                  disabled
                />
                </Tooltip>
              )}
            />
            </Stack>
            <Stack
              mt={2}
              mb={4}
              direction="row"
              spacing={3}>
            {/* <Controller
              control={control}
              name="password"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => (
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  fullWidth
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  type={showPassword.password ? 'text' : 'password'}
                  variant="outlined"
                  size="large"
                  label="New Password*"
                  placeholder="New Password"
                  sx={{ width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.password ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                </Tooltip>
              )}
            /> */}
            {/* <Controller
              control={control}
              name="confirmPassword"
              render={({
                field: {
                  onChange, value, name, ref,
                },
                fieldState: { error },
              }) => ( 
                <Tooltip title={ valueWidth(value) }>
                <TextField
                  fullWidth
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error? error.message : ''}
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  size="large"
                  label="Repeat Password*"
                  placeholder="Repeat Password"
                  sx={{ width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('confirmPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                </Tooltip>
              )}
            /> */}
          </Stack>
          <Box sx={{ textAlign: 'left' }}>
            <Button
              justifyContent="center"
              alignItems="center"
              variant="contained"
              size="large"
              type="submit"
              disabled={isSubmitting}
              sx={{
                backgroundColor: (theme) => 
                  isSubmitting ? theme.palette.grey[500] : theme.palette.primary.main
              }}
            >
              {!isSubmitting ? 'Save Changes' : <CircularProgress size={16} />}
            </Button>
          </Box>
        </form>
      </Stack>
    </>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    updated:() => dispatch(loginUserPost()),
    getUserProfile: () => {
      dispatch(actions.getUserProfileOnServer())
    },
  }
}

export default withRouter(connect(
 null,
  mapDispatchToProps
)(PersonalInformationCard));
