import React, { useState, useEffect } from 'react'
import RegionalAnalyticsTableUI from './RegionalAnalyticsTableUI'
import TextOverflowTooltip from '../../shared/components/TextOverflowTooltip'
import { getSignedFloat } from './../../store/utility'
import formatNumber from '../../shared/utils/formatNumber'
import { TableCell, Link, Box, Typography } from '@mui/material'

const RegionalAnalyticsTable = ({
  tableData,
  viewTableNameClicked,
  searchValue,
  logAppliedFilters,
  logRegionalTrendClick,
}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('data_point')
  const [selectedDataPoints, setSelectedDataPoints] = useState(null)
  const [selected4YearCAGR, setSelected4YearCAGR] = useState(null)
  const [selected2YearCAGR, setSelected2YearCAGR] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filtertableData, setFiltertableData] = useState(null)
  const open = Boolean(anchorEl)

  const yoyCagrOpts = [
    {
      label: 'All',
      key: 0,
      labelTooltip: 'All',
    },
    {
      label: '>=5%',
      key: 1,
      labelTooltip: 'Greater than or equal to 5%',
    },
    {
      label: '>=10%',
      key: 2,
      labelTooltip: 'Greater than or equal to 10%',
    },
    {
      label: '>=20%',
      key: 3,
      labelTooltip: 'Greater than or equal to 20%',
    },
  ]

  const dataPointOpts = [
    {
      label: 'All',
      key: 0,
      labelTooltip: 'All',
    },
    {
      label: '>=10K',
      key: 0,
      labelTooltip: 'Greater than or equal to 10K',
    },
    {
      label: '>=100K',
      key: 0,
      labelTooltip: 'Greater than or equal to 100K',
    },
    {
      label: '>=1M',
      key: 0,
      labelTooltip: 'Greater than or equal to 1M',
    },
  ]

  const [allDropdownsList, setAllDropdownsList] = useState([
    {
      index: 0,
      label: 'Data Points',
      value: null,
      options: dataPointOpts,
      placeholder: 'Select Data Points',
      marginTop: '0px',
    },
    {
      index: 1,
      label: '4 Year CAGR',
      value: null,
      options: yoyCagrOpts,
      placeholder: 'Select 4 Year CAGR',
      marginTop: '30px',
    },
    {
      index: 2,
      label: '2 Year CAGR',
      value: null,
      options: yoyCagrOpts,
      placeholder: 'Select 2 Year CAGR',
      marginTop: '30px',
    },
  ])

  const handleFilterClickOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseFilter = () => {
    setAnchorEl(null)
  }

  const handleSelectChange = (index, value) => {
    const updatedList = [...allDropdownsList]
    updatedList[index].value = value
    setAllDropdownsList(updatedList)
    allDropdownsList &&
      allDropdownsList.map((obj) => {
        switch (obj.label) {
          case 'Data Points':
            setSelectedDataPoints(obj.value)
            break
          case '4 Year CAGR':
            setSelected4YearCAGR(obj.value)
            break
          case '2 Year CAGR':
            setSelected2YearCAGR(obj.value)
            break
          default:
            return null
        }
      })
  }

  const filterAllData = (
    tableData,
    selectedDataPoints,
    selected2YearCAGR,
    selected4YearCAGR,
  ) => {
    const newData = tableData.filter((item) => {
      if (
        typeof selectedDataPoints === 'string' &&
        (selectedDataPoints.includes('K') || selectedDataPoints.includes('M'))
      ) {
        selectedDataPoints =
          Number(selectedDataPoints.replace(/[^0-9]/g, '')) *
          (selectedDataPoints.includes('K') ? 1000 : 1000000)
      }
      if (
        typeof selected2YearCAGR === 'string' &&
        selected2YearCAGR.includes('%')
      ) {
        selected2YearCAGR = Number(selected2YearCAGR.replace(/[^0-9]/g, ''))
      }
      if (
        typeof selected4YearCAGR === 'string' &&
        selected4YearCAGR.includes('%')
      ) {
        selected4YearCAGR = Number(selected4YearCAGR.replace(/[^0-9]/g, ''))
      }
      if (
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR &&
        selected4YearCAGR != 'All' &&
        selected4YearCAGR
      ) {
        return (
          item.two_year_cagr >= selected2YearCAGR &&
          item.four_year_cagr >= selected4YearCAGR
        )
      }
      if (
        (selected2YearCAGR === 'All' || selected2YearCAGR === null) &&
        selected4YearCAGR != 'All' &&
        selected4YearCAGR &&
        selectedDataPoints != 'All' &&
        selectedDataPoints
      )
        return (
          item.data_point >= selectedDataPoints &&
          item.four_year_cagr >= selected4YearCAGR
        )
      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        selectedDataPoints != 'All' &&
        selectedDataPoints &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR
      ) {
        return (
          item.data_point >= selectedDataPoints &&
          item.two_year_cagr >= selected2YearCAGR
        )
      }
      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR
      ) {
        return item.two_year_cagr >= selected2YearCAGR
      }
      if (
        selected4YearCAGR != 'All' &&
        selected4YearCAGR &&
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null)
      ) {
        return item.four_year_cagr >= selected4YearCAGR
      }
      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        selectedDataPoints != 'All' &&
        selectedDataPoints &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null)
      ) {
        return item.data_point >= selectedDataPoints
      }
      if (
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null) &&
        (selected4YearCAGR === 'All' || selected4YearCAGR === null)
      ) {
        return tableData
      }
      return (
        item.data_point >= selectedDataPoints &&
        item.two_year_cagr >= selected2YearCAGR &&
        item.four_year_cagr >= selected4YearCAGR
      )
    })
    return newData
  }

  const applySelectedFilters = (
    allDropdownsList,
    selectedDataPoints,
    selected2YearCAGR,
    selected4YearCAGR,
  ) => {
    logAppliedFilters(selectedDataPoints, selected2YearCAGR, selected4YearCAGR)
    handleCloseFilter()
    setFiltertableData(
      filterAllData(
        tableData,
        selectedDataPoints,
        selected2YearCAGR,
        selected4YearCAGR,
      ),
    )
  }

  const resetSelectedFilterValues = () => {
    handleCloseFilter()
    setSelectedDataPoints(null)
    setSelected4YearCAGR(null)
    setSelected2YearCAGR(null)
    setFiltertableData(null)
    const updatedDropdownsList = allDropdownsList.map((dropdown) => {
      return {
        ...dropdown,
        value: null,
      }
    })
    setAllDropdownsList(updatedDropdownsList)
  }

  useEffect(() => {
    if (
      searchValue &&
      !anchorEl &&
      (selected2YearCAGR || selected4YearCAGR || selectedDataPoints)
    ) {
      setFiltertableData(
        filterAllData(
          tableData,
          selectedDataPoints,
          selected2YearCAGR,
          selected4YearCAGR,
        ),
      )
    }
  }, [
    searchValue,
    selected2YearCAGR,
    selected4YearCAGR,
    selectedDataPoints,
    anchorEl,
  ])

  const filteredTableData =
    filtertableData != null ? filtertableData : tableData

  const regionalAnalyticsHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Item',
      width: '100px',
      sorting: true,
      func: (data) => (
        <TableCell>
          <Link
            onClick={() => logRegionalTrendClick('List View', data.name)}
            target="_blank"
            underline="none"
            variant="body2"
            href={viewTableNameClicked(data.name, data.trend_id)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '200px' }}>
              <img
                src={data.image}
                height={36}
                width={36}
                style={{ borderRadius: 4, objectFit: 'cover' }}
              />
              <TextOverflowTooltip title={data.name} maxLineCount={1}>
                <Typography
                  variant="subtitle3"
                  sx={{
                    display: 'inline',
                    alignItems: 'center',
                    marginLeft: '10px',
                    color: 'primary.main',
                  }}
                >
                  {data.name}
                </Typography>
              </TextOverflowTooltip>
            </Box>
          </Link>
        </TableCell>
      ),
    },
    {
      id: 'data_point',
      numeric: true,
      disablePadding: true,
      label: 'Data Points',
      width: '100px',
      sorting: true,
      tooltipText:
        'Based on consumer engagement from social platforms for the selected region.',
      func: (data) => <TableCell> {formatNumber(data.data_point)}</TableCell>,
    },
    {
      id: 'two_year_cagr',
      numeric: true,
      disablePadding: true,
      label: '2 Year CAGR',
      width: '100px',
      sorting: true,
      tooltipText:
        'Based on social and search data at category level for the selected region.',
      func: (data) => (
        <TableCell> {`${getSignedFloat(data.two_year_cagr, 2)}%`}</TableCell>
      ),
    },
    {
      id: 'four_year_cagr',
      numeric: true,
      disablePadding: true,
      label: '4 Year CAGR ',
      width: '100px',
      sorting: true,
      tooltipText:
        'Based on social and search data at category level for the selected region.',
      func: (data) => (
        <TableCell>{`${getSignedFloat(data.four_year_cagr, 2)}%`}</TableCell>
      ),
    },
  ]

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Box sx={{ px: 2 }}>
      <RegionalAnalyticsTableUI
        tableData={filteredTableData}
        tableHeadCells={regionalAnalyticsHeadCells}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        handleSelectChange={handleSelectChange}
        allDropdownsList={allDropdownsList}
        applySelectedFilters={applySelectedFilters}
        resetSelectedFilterValues={resetSelectedFilterValues}
        handleFilterClickOpen={handleFilterClickOpen}
        handleCloseFilter={handleCloseFilter}
        open={open}
        anchorEl={anchorEl}
        selectedDataPoints={selectedDataPoints}
        selected2YearCAGR={selected2YearCAGR}
        selected4YearCAGR={selected4YearCAGR}
      />
    </Box>
  )
}

export default RegionalAnalyticsTable
