import React from "react";

import "./SWScreenedResultsChips.scss";

const resolveImg = (score) => {
  let cls = "underline--";
  if (score >= 0 && score < 20) {
    cls += "red";
  } else if (score >= 20 && score < 40) {
    cls += "orange";
  } else if (score >= 40 && score < 60) {
    cls += "yellow";
  } else if (score >= 60 && score < 80) {
    cls += "lime";
  } else {
    cls += "green";
  }
  return (
    <div className={`sw-screened-results-chips__score-color ${cls}`}></div>
  );
};

const SWScreenedResultsChips = ({
  cardDisplay = false,
  relevanceScore,
  originalityScore,
  finalRank,
}) => {
  const chipClass = `sw-screened-results-chips__chip${
    cardDisplay ? " sw-screened-results-chips__chip--card" : ""
  }`;
  return (
    <div className="sw-screened-results-chips">
      {[
        { name: "Relevance", score: relevanceScore },
        { name: "Originality", score: originalityScore },
      ].map(({ name, score }) => {
        return (
          <div key={name} className={chipClass}>
            {name}
            <span className="sw-screened-results-chips__chip-value">
              {score}
              {resolveImg(score)}
            </span>
          </div>
        );
      })}
      {/* <div className={chipClass}>
        Rank
        <span className="sw-screened-results-chips__chip-value">{finalRank}</span>
      </div> */}
    </div>
  );
};

export default SWScreenedResultsChips;
