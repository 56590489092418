import React from 'react'
import { Breadcrumbs, Typography, Link, Box } from '@mui/material'
import ScrollToTop from '../../hoc/ScrollToTop'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const breadcrumbs = [
  <Link variant="breadcrumbsLink" key="1" href="/">
    Foresight Engine
  </Link>,
  <Typography variant="subtitle2" key="2">
    Terms of Service
  </Typography>,
]
// route terms-of-service
const TermsOfService = () => {
  return (
    <ScrollToTop>
      <Box>
        <Breadcrumbs
          m={2}
          aria-label="breadcrumb"
          separator={<NavigateNextIcon />}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Box m={2}>
          <Typography variant="h2" component="h2">
            Terms of Use
          </Typography>
          <br />
          <Typography variant="h3" component="h3">
            Last updated June 14, 2023
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            These Terms of Use <b>(“Terms of Use”)</b> constitute a legally
            binding agreement made between you, whether personally or on behalf
            of an entity (“you” and derivatives) and Ai Palette Pte Ltd
            ("Company", “we”, “us”, or “our”), concerning your access to and use
            of the premium.aipalette.com website as well as any other media
            form, media channel, mobile website or mobile application related,
            linked, or otherwise connected thereto (collectively, the “Site”).
            You agree that by accessing the Site, you have read, understood, and
            agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
            WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Supplemental terms and conditions or documents that may be posted on
            the Site and/or any other documents that are entered into by you
            with the Company with respect to your use or access of the Site from
            time to time are hereby expressly incorporated herein by reference.
            If these Terms of Use are inconsistent with any provision contained
            in any supplemental terms and conditions or documents that may be
            posted on the Site and/or any other documents that are entered into
            by you with the Company with respect to your use or access of the
            Site, the Company shall determine, in its sole and absolute
            discretion, which of the provisions shall control any such
            inconsistency or conflict. We reserve the right, in our sole
            discretion, to make changes or modifications, add or remove portions
            of these Terms of Use at any time and for any reason. We will alert
            you about any changes by updating the “Last updated” date of these
            Terms of Use, and you waive any right to receive specific notice of
            each such change. It is your responsibility to periodically review
            these Terms of Use to stay informed of updates. You will be subject
            to, and will be deemed to have been made aware of and to have
            accepted, the changes in any revised Terms of Use by your continued
            use of the Site after the date such revised Terms of Use are posted.
            These Terms of Use, the privacy policy (available at
            https://premium.aipalette.com/mui/privacy-policy), and any other
            agreements that may be entered into by you with the Company shall
            constitute the entire agreement between you and the Company and
            shall supersede any and all previous representations,
            understandings, or agreements as to the subject matter hereof.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The Site is intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register on
            the Site. Company reserves the right to terminate any person's
            membership in accordance with the terms of these Terms of Use and/or
            refuse to provide such person with access to the Site if it is
            brought to the notice of Company or if Company discovers for itself
            that such person is not eligible to use the Site.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            INTELLECTUAL PROPERTY RIGHTS
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, other content and graphics
            on the Site (collectively, the “Content”) and the trademarks,
            service marks, trade names and logos contained therein (the “Marks”)
            are owned or controlled by us or licensed to us, and are protected
            by copyright and trademark laws and various other intellectual
            property rights and unfair competition laws of Singapore, foreign
            jurisdictions, and international conventions. The Content and the
            Marks are provided on the Site “AS IS” for your information and
            personal use only. Except as expressly provided in these Terms of
            Use or agreed with the Company in writing, no part of the Site and
            no Content or Marks may be copied, reproduced, aggregated,
            republished, uploaded, posted, publicly displayed, encoded,
            translated, transmitted, distributed, sold, licensed, or otherwise
            exploited for any commercial purpose whatsoever, without express
            prior written permission from the Company.
          </Typography>
          <br />

          <Typography variant="body1" component="p" color="textSecondary">
            Provided that you are eligible to use the Site, you are granted a
            personal, revocable, non-exclusive, non-transferable, limited
            license to access and use the Site and to download or print a copy
            of any portion of the Content to which you have properly gained
            access, solely for your personal, non-commercial use. We reserve all
            rights not expressly granted to you in and to the Site, the Content
            and the Marks.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            CONFIDENTIALITY
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You acknowledge that you may be exposed to or acquire certain
            communication or data that is confidential, privileged communication
            not intended to be disclosed to third parties. All and any
            information disclosed by the Company to you, either directly or
            indirectly, in writing, orally, electronic or other tangible form
            shall be considered “Confidential Information”.
          </Typography>

          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You hereby represent and warrant that:
          </Typography>

          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            (a) You shall not use such Confidential Information for your own or
            any other person's benefit (including without limitation for any
            commercial and/or non-commercial use, whether to develop a competing
            product, or otherwise).
            <br /> (b) You shall use the same or greater degree of care in
            protecting the Confidential Information, as you use for your own
            confidential information, but no less than reasonable care.
            <br />
            (c) You shall not use, sell, tamper, reproduce, duplicate, copy,
            reverse-engineer, disassemble, decompile or decrypt Confidential
            Information or store any of the Confidential Information in an
            externally accessible computer or electronic information retrieval
            system or transmit it in any form or by any means whatsoever outside
            its usual place of storage.
            <br /> (d) You shall promptly notify the Company upon discovery of
            any disclosure or misuse of the Confidential Information. <br />
            (e) Upon request of the Company or upon termination, you shall
            promptly return or destroy the Confidential Information.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You shall use your best efforts to assist the Company in identifying
            and preventing any unauthorized use or disclosure of any
            Confidential Information. Without limiting the foregoing, you shall
            advise the Company immediately upon learning that any person who has
            had access to Confidential Information has violated or intends to
            violate the terms of these Terms of Use and will cooperate with the
            Company in seeking injunctive or other equitable relief against any
            such person.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You acknowledge that breach of your obligation of confidentiality
            may give rise to irreparable injury to the Company, which damage may
            be inadequately compensable in the form of monetary damages.
            Accordingly, the Company may seek and obtain injunctive relief
            against the breach or threatened breach of the foregoing
            undertakings.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            USER REPRESENTATIONS
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            By using the Site, you represent and warrant that: (1) you have the
            legal capacity and you agree to comply with these Terms of Use; (2)
            you are not a minor in the jurisdiction in which you reside; (3) you
            will not access the Site through automated or non-human means,
            whether through a bot, script or otherwise; (4) you will not use the
            Site for any illegal or unauthorized purpose; and (5) your use of
            the Site will not violate any applicable law or regulation.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            FEES AND PAYMENT
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            We accept bank transfer as a form of payment of any dues payable to
            us by you for accessing/using the Site.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Not all access to the Site or services is free, and you may be
            required to purchase or pay a fee to access some of our services.
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Site. You further
            agree to promptly update account and payment information, including
            email address, payment method, and payment card expiration date, so
            that we can complete your transactions and contact you as needed. We
            bill you through an online billing account for purchases made via
            the Site. Sales tax will be added to the price of purchases as
            deemed required by us. We may change prices at any time by notifying
            you of the same in advance. All payments shall be in U.S. dollars,
            provided however, the Company may accept payment in your local
            currency upon mutual agreement between you and the Company. In the
            event you fail to pay the fee payable by you for the services, the
            Company reserves the right to terminate your access to the Site with
            no notice. Further, in the event of delay in the payment of fee by
            you, the Company reserves the right to charge interest on any such
            delayed payment at such rate as may be indicated by the Company in
            writing.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You agree to pay all charges or fees at the prices then in effect
            for your purchases, and you authorize us to charge your chosen
            payment provider for any such amounts upon making your purchase. If
            your purchase is subject to recurring charges, then you consent to
            our charging your payment method on a recurring basis without
            requiring your prior approval for each recurring charge, until you
            notify us of your cancellation in the form and manner indicated by
            us.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Notwithstanding anything contrary contained in these Terms of Use,
            the Company shall not refund any fees upon termination of these
            Terms of Use for any reason whatsoever. We reserve the right to
            correct any errors or mistakes in pricing, even if we have already
            requested or received payment. We also reserve the right to refuse
            any order placed through the Site.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            While availing the payment method available on the Site, the Company
            will not be responsible for or assume any liability, whatsoever in
            respect of any loss or damage arising directly or indirectly to the
            you due to:
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            1. Lack of authorization for any transaction(s); <br />
            2. Exceeding the pre-set limit mutually agreed by you and between
            bank/s;
            <br />
            3. Any payment issues arising out of the transaction;
            <br />
            4. Rejection of transaction for any other reason(s) whatsoever.
            <br />
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You acknowledge that the Company will not be liable for any damages,
            interests, claims, or losses resulting from or suffered on account
            of not processing a transaction/ transaction amount or any delay in
            processing a transaction/transaction amount which is beyond the
            control of the Company.
          </Typography>

          <br></br>
          <Typography variant="h4" component="h4">
            USE OF THE SITE
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Subject to your acceptance of the Terms of Use and on payment of the
            required fees, you are granted non-exclusive, non-assignable,
            royalty free right to view, access and use the Site solely for your
            internal use. You may allow your employees, vendors and other
            identified third parties (“Authorized Users”) to use the Site and
            you shall be responsible for your Authorized Users' compliance.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            On receipt of the fees, Company shall grant you, administrative user
            name and password (“Login”) for your account to enable you to use
            the Site and access its customized dashboard. The Company reserves
            the right to refuse registration of, or cancel passwords it deems
            inappropriate.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Access and use of the services made available on the Site is
            restricted to the specified number of individual Authorized Users
            permitted under your subscription to the Site. You shall be
            responsible for the confidentiality of the Login allotted to you.
            You shall ensure that the Login details are not shared with any
            third party. In any event, unless you notify the Company in writing
            of any unauthorized use or suspicious activity in your account, you
            will be fully responsible for all activities that occur under your
            account and the Company will not be responsible for any loss or
            damage caused to you due to such unauthorized use of the Site.
          </Typography>
          <br></br>
          <Typography variant="h4" component="h4">
            CANCELLATION AND TERMINATION OF ACCESS
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            All purchases are non-refundable unless otherwise agreed with us in
            writing. Subject to any other terms that may be agreed between you
            and the Company, you can cancel your subscription at any time by
            contacting us using the contact information provided below. Your
            cancellation will take effect at the end of the current paid term.
            If you are unsatisfied with our services, please email us at
            billing@aipalette.com or call us at +65 90575170.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Unless otherwise agreed with us in writing, the Company, in its sole
            discretion, may terminate your access to the Site for any reason or
            no reason, including your violation of these Terms of Use or lack of
            use of Site or failure by you to pay fees in a prescribed manner by
            the Company. You acknowledge that, unless otherwise agreed with us
            in writing, the termination of access to the Site may be affected
            without any prior notice, and the Company may immediately deactivate
            or delete the Login and all related information and/or bar any
            further access to the Login or the Site, subject to applicable law.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The disclaimer of warranties, the limitation of liability, the
            governing law provision, and any other provisions which by their
            nature should survive termination, shall survive any termination of
            these Terms of Use.
          </Typography>

          <br></br>
          <Typography variant="h4" component="h4">
            PROHIBITED ACTIVITIES{' '}
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You or your Authorised Users may not access or use the Site for any
            purpose other than that for which we make the Site available. The
            Site may not be used in connection with any commercial endeavors
            except those that are specifically endorsed or approved by us in
            writing.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            As a user of the Site, you agree not to:
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            1. Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us. <br></br>
            2. Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein. <br></br>
            3. Make improper use of our support services or submit false reports
            of abuse or misconduct. <br></br>
            4. Engage in any automated use of the system, such as using scripts
            to send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools. <br></br>
            5. Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.<br></br>
            6. Use any information obtained from the Site in order to harass,
            abuse, or harm another person. <br></br>
            7. Use the Site as part of any effort to compete with us or
            otherwise use the Site and/or the Content for any revenue-generating
            endeavor or commercial enterprise or for the benefit of a third
            party. <br></br>
            8. Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.{' '}
            <br></br>
            9. Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site. <br></br>
            10. Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.{' '}
            <br></br>
            11. Delete the copyright or other proprietary rights notice from any
            Content. <br></br>
            12. Copy or adapt the Site's software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.<br></br>
            13. Upload or transmit (or attempt to upload or to transmit)
            viruses, tTrojan horses, malware, time bombs, or other material,
            including excessive use of capital letters and spamming (continuous
            posting of repetitive text), that interferes with any party's
            uninterrupted use and enjoyment of the Site or modifies, impairs,
            disrupts, alters, or interferes with the use, features, functions,
            operation, or maintenance of the Site. <br></br>
            14. Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
            <br></br>
            15. Except as may be the result of standard search engine or
            internet browser usage, use, launch, develop, or distribute any
            automated system, including without limitation, any spider, robot,
            cheat utility, scraper, cookie tracking, ad exchanges, ad networks,
            data brokerages, or sending electronic communications (including
            e-mail) in violation of applicable law or offline reader that
            accesses the Site, or using or launching any unauthorized script or
            other software.
            <br></br>
            16. Disparage, tarnish, or otherwise harm, in our opinion, us and/or
            the Site. <br></br>
            17. Use the Site in a manner inconsistent with any applicable laws
            or regulations, these Terms of Use, documents that may be posted on
            the Site and/or are entered into with the Company with respect to
            your use or access of the Site or in any unlawful manner, including
            but not limited to violation of any person's privacy rights.{' '}
            <br></br>
            18. Make the services (including the Site and reports or data
            extracted therefrom) available to any third party, other than the
            Authorized Users in furtherance of the purpose that you have
            subscribed to the services under these Terms of Use.
            <br></br>
            19. Use the Site to process data on behalf of any third party other
            than the Authorized Users.
            <br></br>
            20. Falsely imply any sponsorship or association with the Company.
            <br></br>
            21. Use the Site to send unsolicited communications, junk mail,
            spam, pyramid schemes or other forms of duplicate or unsolicited
            messages.
            <br></br>
            22. Use the Site to store or transmit any content that infringes
            upon any person's intellectual property rights.
            <br></br>
            23. Use the Site to knowingly post, transmit, upload, link to, send
            or store any content that is unlawful, racist, hateful, abusive,
            libelous, obscene, or discriminatory.
            <br></br>
            24. Establish a link to the Site or otherwise in such a way as to
            suggest any form of association, approval or endorsement on the
            Company's part, where none exists.
            <br></br>
            25. Commercially exploit the data received through use of the Site
            itself, without prior written consent of the Company or try to use,
            or use the Site in violation of these Terms of Use, documents that
            may be posted on the Site and/or are entered into with the Company
            with respect to your use or access of the Site.
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You agree not to, and cause the Authorized Users to not, host,
            display, upload, modify, publish, transmit, store, update or share
            any information that:
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            1. belongs to another person and to which you do not have any right,
            except as expressly provided in these Terms of Use. <br></br>
            2. is obscene, pornographic, pedophilic, invasive of another
            person's privacy, including bodily privacy, insulting, or harassing
            on the basis of religion or gender, hateful or racially or
            ethnically objectionable, relating or encouraging money laundering
            or gambling or an online game that causes harm, or promoting enmity
            between different groups on the grounds of religion or caste with
            the intent to incite violence. <br></br>
            3. is harmful to minors. <br></br>
            4. infringes upon or violates any third party's rights including,
            but not limited to, any patent, trademark, copyright or other
            proprietary rights or intellectual property rights, rights of
            privacy. <br></br>
            5. impersonates another person.<br></br>
            6. deceives or misleads the addressee about the origin of the
            message or knowingly and intentionally communicates any
            misinformation or information which is patently false and untrue,
            and misleading in nature. <br></br>
            7. contains software viruses or any other computer codes, files or
            programs designed to interrupt, destroy, or limit the functionality
            of any computer resource.<br></br>
            8. is in the nature of an online game that is not verified as a
            permissible online game.
            <br></br>
            9. is in the nature of advertisement or surrogate advertisement or
            promotion of an online game that is not a permissible online game,
            or of any online gaming intermediary offering such an online game.{' '}
            <br></br>
            10. threatens the unity, integrity, defence, security or sovereignty
            of India (if applicable to you), friendly relations with foreign
            States, or public order, or causes incitement to the commission of
            any cognizable offence or prevents investigation of any offence or
            is insulting any foreign States.
            <br></br>
            11. violates any law for the time being in force.
            <br></br>
          </Typography>

          <br></br>
          <h3 className="caption_h3">MOBILE APPLICATION LICENSE</h3>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            Use License
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            If you access the Site via a mobile application, then we grant you a
            revocable, non-exclusive, non-transferable, limited right to install
            and use the mobile application on wireless electronic devices owned
            or controlled by you, and to view,access and use the mobile
            application on such devices strictly in accordance with the terms
            and conditions of this mobile application license contained in these
            Terms of Use. You shall not:<br></br>
            (1) decompile, reverse engineer, disassemble, attempt to derive the
            source code of, or decrypt the application;<br></br>
            (2) make any modification, adaptation, improvement, enhancement,
            translation, or derivative work from the application;<br></br>
            (3) violate any applicable laws, rules, or regulations in connection
            with your access or use of the application;<br></br>
            (4) remove, alter, or obscure any proprietary notice (including any
            notice of copyright or trademark) posted by us or the licensors of
            the application;<br></br>
            (5) use the application for any revenue generating endeavor,
            commercial enterprise, or other purpose for which it is not designed
            or intended;<br></br>
            (6) make the application available over a network or other
            environment permitting access or use by multiple devices or users at
            the same time;<br></br>
            (7) use the application for creating a product, service, or software
            that is, directly or indirectly, competitive with or in any way a
            substitute for the application;<br></br>
            (8) use the application to send automated queries to any website or
            to send any unsolicited commercial e-mail; or <br></br>
            (9) use any proprietary information or any of our interfaces or our
            other intellectual property in the design, development, manufacture,
            licensing, or distribution of any applications, accessories, or
            devices for use with the application.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            Apple and Android Devices{' '}
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The following terms apply when you use a mobile application obtained
            from either the Apple Store or Google Play (each an “App
            Distributor”) to access the Site: (1) the license granted to you for
            our mobile application is limited to a non-transferable license to
            use the application on a device that utilizes the Apple iOS or
            Android operating systems, as applicable, and in accordance with the
            usage rules set forth in the applicable App Distributor's terms of
            service; (2) we are responsible for providing any maintenance and
            support services with respect to the mobile application as specified
            in the terms and conditions of this mobile application license
            contained in these Terms of Use or as otherwise required under
            applicable law, and you acknowledge that each App Distributor has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the mobile application; (3) in the event of
            any failure of the mobile application to conform to any applicable
            warranty, you may notify the applicable App Distributor, and the App
            Distributor, in accordance with its terms and policies, may refund
            the purchase price, if any, paid for the mobile application, and to
            the maximum extent permitted by applicable law, the App Distributor
            will have no other warranty obligation whatsoever with respect to
            the mobile application; (4) you represent and warrant that (i) you
            are not located in a country that is subject to a U.S. government
            embargo, or that has been designated by the U.S. government as a
            “terrorist supporting” country and (ii) you are not listed on any
            U.S. government list of prohibited or restricted parties; (5) you
            must comply with applicable third-party terms of agreement when
            using the mobile application, e.g., if you have a VoIP application,
            then you must not be in violation of their wireless data service
            agreement when using the mobile application; and (6) you acknowledge
            and agree that the App Distributors are third-party beneficiaries of
            the terms and conditions in this mobile application license
            contained in these Terms of Use, and that each App Distributor will
            have the right (and will be deemed to have accepted the right) to
            enforce the terms and conditions in this mobile application license
            contained in these Terms of Use against you as a third-party
            beneficiary thereof.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" component="h4">
            SUBMISSIONS
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You grant to the Company a perpetual, non-revocable, worldwide,
            royalty-free and sub-licensable right and license to incorporate
            into the services, Site or otherwise use any suggestions,
            enhancement requests, recommendations or other feedback you share
            with the Company.
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The Company shall have the right to use your name and/or
            trademark/logo in any sales or marketing materials, communication or
            advertisement in pursuance of these Terms of Use, and you grant the
            Company a royalty-free, fully paid-up, worldwide, non-exclusive
            license to use the same for the purposes identified hereinabove.
          </Typography>
          <br></br>
          <Typography variant="h4" component="h4">
            THIRD-PARTY WEBSITES AND CONTENT
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The Site may contain (or you may be sent via the Site) links to
            other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern such action. You should review the
            applicable terms and policies, including privacy and data gathering
            practices, of any Third-Party Website to which you navigate from the
            Site or relating to any applications you use or install from the
            Site. Any purchases you make through Third-Party Websites will be
            through other websites and from other companies, and we take no
            responsibility whatsoever in relation to such purchases which are
            exclusively between you and the applicable third party. You agree
            and acknowledge that we do not endorse the products or services
            offered on Third-Party Websites and you shall hold us harmless from
            any harm caused by your purchase of such products or services.
          </Typography>
          <br></br>
          <Typography variant="h4" component="h4">
            INDEMNIFICATION AND LIMITATION OF LIABILITY
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            (1) Indemnification
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You agrees to indemnify, defend, and hold harmless the Company, its
            affiliates and their respective officers, directors, agents, and
            employees (each, an “Indemnitee“) from and against any and all
            liabilities, damages, losses, expenses, claims, demands, suits,
            fines, or judgments (each, a “Claim”, and collectively, the
            “Claims“), which may be suffered by, incurred by, accrued against,
            charged to, or recoverable from any Indemnitee, by reason of any
            Claim arising out of or relating to any act, error or omission,
            negligence, or misconduct by you, your officers, directors, agents,
            employees, and subcontractors, during the performance of the
            services, including, without limitation, Claims arising out of or
            relating to any material misrepresentation or breach of warranty of
            any representation or warranty; or any material breach of any
            covenant set forth in these Terms of Use, documents that may be
            posted on the Site and/or are entered into with the Company with
            respect to your use or access of the Site; provided, however, that
            the foregoing indemnity shall not apply to the extent that the
            applicable Claim resulted from the acts or omissions of an
            Indemnitee.
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            (2) Limitation of Liability
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You hereby acknowledge that the Company shall not be held liable to
            you for any special, consequential, incidental, and exemplary or
            punitive damages, or loss of profit or revenues. The Company shall
            also not be liable under any circumstances for damages arising out
            of or related in any way by your inability to access, or your
            difficulty in accessing the Site, any bugs, viruses, trojan horses,
            or the like, which may be transmitted to or through the Site or by
            any third party, any loss of your data, any claim relating to your
            data or content from the Site or your failure to keep the Login
            details secure and confidential. In no event shall the Company's
            liability exceed the fees paid by you to the Company, for the last 3
            consecutive months, prior to the date of any such Claim.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            DISCLAIMER OF WARRANTIES
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            THE SITE AND ALL INFORMATION, CONTENT, MATERIALS AND SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE ARE
            PROVIDED BY COMPANY ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT
            REPRESENTATIONS OR WARRANTIES OF ANY KIND. COMPANY MAKES NO
            REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
            THE OPERATION OF THE SITE, OR THE ACCURACY OR COMPLETENESS OF THE
            CONTENT. WITHOUT LIMITING THE FOREGOING, COMPANY MAKES NO WARRANTY
            THAT THE SITE WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE SITE
            WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE OR THE RESULTS
            THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE EFFECTIVE,
            ACCURATE OR RELIABLE. THE COMPANY SHALL HAVE NO RESPONSIBILITY FOR
            ANY DAMAGE TO YOUR DEVICE OR LOSS OF DATA THAT RESULTS FROM THE
            DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENTS,OR INFORMATION. YOU
            EXPRESSLY AGREE THAT THE USE OF THE SITE IS AT YOUR OWN DISCRETION
            AND SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
            RESULTING FROM ANY TRANSACTION. NO ADVICE OR INFORMATION, WHETHER
            ORAL OR WRITTEN, OBTAINED BY YOU FROM THE COMPANY OR THROUGH OR FROM
            THE SITE OR CONTENT SHALL CREATE ANY WARRANTY.
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            TO THE FULL EXTENT PERMITTED BY LAW, COMPANY DISCLAIMS ANY AND ALL
            REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SITE AND ITS
            CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
            WARRANTIES OF TITLE AND NON-INFRINGEMENT, MERCHANTABILITY,
            SATISFACTORY QUALITY, ACCURACY, COMPATIBILITY, APPLICABILITY,
            USABILITY, APPROPRIATENESS, FITNESS FOR A PARTICULAR PURPOSE OR USE
            AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF PERFORMANCE, COURSE
            OF DEALING, OR USAGE OF TRADE. YOU HEREBY AGREE TO WAIVE, RELEASE,
            DISCHARGE, AND HOLD HARMLESS THE COMPANY, ITS AFFILIATES AND EACH OF
            THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (AS
            APPLICABLE), FROM ANY AND ALL CLAIMS, LOSSES, DAMAGES, LIABILITIES,
            EXPENSES AND CAUSES OF ACTION ARISING OUT OF USE OF THE SITE.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            SITE MANAGEMENT
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use, documents that may be
            posted on the Site and/or are entered into with the Company with
            respect to your use or access of the Site; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, documents that may be posted on the
            Site and/or are entered into with the Company with respect to your
            use or access of the Site, including without limitation, reporting
            such user to law enforcement authorities; (3) in our sole discretion
            and without limitation, refuse, restrict access to, limit the
            availability of, or disable (to the extent technologically feasible)
            any of your Submissions or any portion thereof; (4) in our sole
            discretion and without limitation, notice, or liability, to remove
            from the Site or otherwise disable all files and content that are
            excessive in size or are in any way burdensome to our systems; (5)
            otherwise manage the Site in a manner designed to protect our rights
            and property and to facilitate the proper functioning of the Site;
            (6) in our sole discretion provide updates, bug fixes, corrections,
            modifications, enhancements, upgrades, and new releases to the Site
            and any such modified features or enhancements added to or
            augmenting or otherwise modifying the services will also be subject
            to the terms of these Terms of Use; and (7) the Company shall use
            commercially reasonable efforts to notify you in advance of all
            non-emergency maintenance, for instance, planned downtime for
            upgrades and maintenance (“Planned Downtime”), to be performed on
            the Site. The Company shall endeavor to schedule such Planned
            Downtime during weekends (Singapore time zone) and other off-peak
            hours. You agree that the Company shall not be held responsible for
            your inability to use the Site, if such lack of access results from
            Planned Downtime or a Force Majeure event or conditions beyond
            reasonable control. The term “Force Majeure” shall mean any acts of
            God, civil or military authority, war, riots, civil disturbances,
            accidents, fire, earthquake, floods, strikes, lock-outs, labor
            disturbances, foreign or governmental order that may cause a breach,
            delay or failure in performance of our services or interruption of
            services. It is expressly understood and agreed that for the purpose
            of provision of the services, “conditions beyond reasonable control”
            shall include any break-down, non -availability/partial availability
            of data or any technical errors in the Site or any software in
            connection therewith or other dysfunctionality of or where the
            underlying data has not been updated or if there is a slow-down in
            real time updates or updates are unavailable, erroneously updated or
            partially available, in any third party's records, where such data
            is sourced from any websites hosted by such third party,
            non-retrievability of such data in the stipulated time frame due to
            the servers of such website(s) being down and/or slow (due to
            increase in traffic or for any other reason); or in the event the
            website is shut down / dysfunctional or discontinues services or for
            any other reason whatsoever, which may in any way prevent, restrict
            or interfere with or otherwise adversely affect, the provision of
            the services by the Company. It is further agreed and understood
            that in the event of disagreement as to what would constitute
            “conditions beyond reasonable control”, the decision of the Company
            shall be final and conclusive in this regard.
          </Typography>

          <br />
          <br />
          <Typography variant="h4" component="h4">
            GOVERNING LAW AND DISPUTE RESOLUTION
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            These Terms of Use shall be governed by and interpreted and
            construed in accordance with the laws of Singapore. The place of
            jurisdiction shall exclusively be in Singapore. In the event of any
            dispute arising out of these Terms of Use the same, failing amicable
            resolution through mutual negotiations, shall be referred to and
            settled by arbitration administered by the Singapore International
            Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules
            of the Singapore International Arbitration Centre ("SIAC Rules") for
            the time being in force, which rules are deemed to be incorporated
            by reference in this paragraph conducted by sole arbitrator,
            mutually decided by the disputing parties. If the disputing parties
            cannot agree on an arbitrator, each disputing party shall appoint an
            arbitrator and the arbitral panel shall consist of 3 arbitrators,
            one to be appointed by each disputing party and the third to be
            nominated by the 2 appointed arbitrators. The third arbitrator shall
            serve as chairman of the panel. The decision of the arbitrator(s)
            shall be final and binding upon the parties present in these Terms
            of Use. The seat of arbitration proceedings shall be Singapore. All
            arbitration proceedings shall be conducted in English.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            AGREEMENT TO RECEIVE COMMUNICATION
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You hereby by way of accepting these Terms of Use consent to the
            receipt of communication from the Company by way of Short Message
            Service (SMS) messages, e-mails, promotional and marketing calls,
            and newsletters. These communications could relate to promotions
            that are undertaken by the Company and its third-party partners.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            WAIVER
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            The failure of the Company at any time to require performance any
            provision of these Terms of Use shall in no way affect the Company's
            right to enforce such provisions, nor shall the waiver by the
            Company of any breach of any provision of these Terms of Use be
            taken or held to be a waiver of any further breach of the same
            provision.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            ASSIGNMENT
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            You may not, directly or indirectly, by operation of law or
            otherwise, assign all or any part of these Terms of Use or your
            rights under these Terms of Use, or delegate performance of our
            duties under these Terms of Use, without our written prior consent.
            Notwithstanding the foregoing, subject to this provision herein, you
            may, with prior notice to us, assign these Terms of Use in
            connection with any merger or change of control of you or the sale
            of all or substantially all of your assets, provided that any such
            successor agrees to fulfill its obligations pursuant to these Terms
            of Use. We may assign these Terms of Use to any of our affiliates or
            in connection with any merger or change of control or the sale of
            all or substantially all of our assets as we may deem fit.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            CUMULATIVE REMEDIES
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            All rights and remedies of the Company shall be in addition to all
            other rights and remedies available at law or in equity and
            temporary and permanent injunctive relief.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            GRIEVANCE REDRESSAL MECHANISM
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            In accordance with the Information Technology Act, 2000 and Rules
            made thereunder, the name and contact details of the Grievance
            Officer are provided below:
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            Name: Ajeet Singh <br />
            Email: grievanceofficer@aipalette.com <br />
            Phone No: +91- 6364461970 <br />
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            For registering your complaint, please contact the Grievance Officer
            at the above-mentioned details in relation to any violation of these
            Terms of Use or the Information Technology (Intermediary Guidelines
            and Digital Media Ethics Code) Rules, 2021. The Grievance Officer
            shall redress the complaint in accordance with the provisions of the
            Information Technology Act, 2000 and Rules made thereunder.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h4">
            HOW TO CONTACT US
          </Typography>
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            If you have questions or concerns about these Terms of Use, please
            contact Company at the following email address: info@aipalette.com
          </Typography>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default TermsOfService
