import React from 'react';
import Searchdata from '../../assets/images/testImages/Searchdata.svg';
import { Box, Grid, Typography } from '@mui/material'

const SearchNotSelected = ({msg}) => {
	return (
		<>
			<Grid container spacing={2} columns={12}>
				<Grid xs={5} />
				<Grid xs={4} pt={10}>
					<Box component='img' src={Searchdata} alt='select opiton to search'/>
				</Grid>
			</Grid>
			<Grid container spacing={2} columns={16}>
				<Grid xs={5} />
				<Grid xs={5} pt={3} textAlign='right'>
					<Typography variant='subtitle1' textAlign='right'>
            {msg ? msg : `Select above data to view White Space opportunities`}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default SearchNotSelected;
