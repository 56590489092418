import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'

import BackdropLoader from '../../generic-components/partials/BackdropLoader'
import { ReactComponent as LikeIcon } from '../../../assets/icons/carbon_thumbs-up.svg'
import { ReactComponent as FilledLikeIcon } from '../../../assets/icons/thumbs-up-filled.svg'
import { ReactComponent as DisLikeIcon } from '../../../assets/icons/carbon_thumbs-down.svg'
import { ReactComponent as FilledDisLikeIcon } from '../../../assets/icons/thumbs-down-filled.svg'

import { getHeaders, getBuildWinnerURL } from '../../../network/utility'
import { useSingleSnackBar } from '../../../../hooks'
import OverlayLoader from '../../../../shared/components/OverlayLoader'

const getImage = (type, isFilled, onClick) => {
  if (type == 'positive' && isFilled == false) {
    return <LikeIcon onClick={onClick} />
  } else if (type == 'positive' && isFilled == true) {
    return <FilledLikeIcon onClick={onClick} />
  } else if (type == 'negative' && isFilled == false) {
    return <DisLikeIcon onClick={onClick} />
  } else if (type == 'negative' && isFilled == true) {
    return <FilledDisLikeIcon onClick={onClick} />
  }
}

const ImageIconComponent = ({ onClick, type, isFilled }) => {
  return getImage(type, isFilled, onClick)
}
const FeedbackButtons = (props) => {
  const { id, logConceptFeedback, concept } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [finalFeedback, setFinalFeedback] = useState('NEUTRAL')
  const [likeIsFilled, setLikeIsFilled] = useState(false)
  const [dislikeIsFilled, setDisLikeIsFilled] = useState(false)
  const { showSnackBar } = useSingleSnackBar()

  const updateLikeFilled = () => {
    logConceptFeedback(concept, 'Like')
    setLikeIsFilled((current) => !current)
    setDisLikeIsFilled(false)
  }

  const updateDisLikeFilled = () => {
    logConceptFeedback(concept, 'Dislike')
    setDisLikeIsFilled((current) => !current)
    setLikeIsFilled(false)
  }

  const getFinalFeedback = () => {
    if (likeIsFilled) {
      return 'POSITIVE'
    } else if (dislikeIsFilled) {
      return 'NEGATIVE'
    }
    return 'NEUTRAL'
  }

  const updateConceptFeedback = (feedback, id) => {
    const userId = localStorage.getItem('my-secret-key')
    const feedbackPutUrl = getBuildWinnerURL(
      `concepts/${id}/feedback/?user_id=${userId}`,
    )
    const body = {
      user_feedback: feedback,
    }
    setLoading(true)
    fetch(
      feedbackPutUrl,
      getHeaders(
        {
          body: JSON.stringify(body),
        },
        'PUT',
      ),
    )
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error Recording Feedback! ${res?.statusText}`, 'error')
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setLoading(false)
      })
      .catch((error) => {
        console.log('error_occured', error)
        setLoading(false)
        setError(error)
      })
  }

  useEffect(() => {
    updateConceptFeedback(getFinalFeedback(), id)
  }, [likeIsFilled, dislikeIsFilled])

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sx={{ cursor: 'pointer' }}>
          <ImageIconComponent
            type={'positive'}
            isFilled={likeIsFilled}
            onClick={() => {
              updateLikeFilled()
            }}
          />
        </Grid>
        <Grid item sx={{ cursor: 'pointer' }}>
          <ImageIconComponent
            type={'negative'}
            isFilled={dislikeIsFilled}
            onClick={() => {
              updateDisLikeFilled()
            }}
          />
        </Grid>
      </Grid>
      {loading ? <OverlayLoader /> : null}
    </React.Fragment>
  )
}

export default FeedbackButtons
