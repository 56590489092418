import React from 'react'
import styled from '@emotion/styled'
import FormControlLabel from '@mui/material/FormControlLabel'

export const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme: { palette } }) => ({
  maxWidth: '150px',
  width: '165px',
  fontSize: '14px',
  backgroundColor: palette.background.paper,
  display: 'flex',
  whiteSpace: 'nowrap',
  height: 35,
  borderRadius: 4,
  '&:hover': {
    border: `1px solid ${palette.secondary.main}`,
  },
  '& .MuiFormControlLabel-label': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    WebkitBoxOrient: 'vertical',
    fontSize: '14px',
  },
}))
