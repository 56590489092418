import React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ScreenWinnerImage from '../../../assets/images/screen_winner_marketing_final.svg'

export const MarketingTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '18px',
  fontWeight: '500',
}))

export const MarketingDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.black,
  fontSize: '16px',
  fontWeight: '400',
  margin: '24px 0px',
}))

export const MarketingCTAButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.white,
  fontSize: '14px',
  '&:hover': {
    background: theme.palette.primary.dark,
    color: `${theme.palette.white} !important`,
  },
  '&:active': {
    background: theme.palette.primary.light,
    color: `${theme.palette.white} !important`,
  },
  padding: '12px 12px',
}))

const MarketingComponent = (props) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        mt: 4,
      }}
    >
      <Grid item xs={6}>
        <img src={ScreenWinnerImage} height={375} />
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box>
            <MarketingTitle>
              Want to see if the market likes your concept?
            </MarketingTitle>
            <MarketingDescription>
              Upgrade to Screen Winner and easily test all of your new product
              concepts virtually. Based on our consumer sentiment and product
              availability data, Screen Winner will score your concepts on
              originality and relevancy to the current market. Schedule a call
              with our team to see Screen Winner in action.
            </MarketingDescription>
          </Box>

          <Box textAlign="center">
            <MarketingCTAButton
              target="_top"
              rel="noopener noreferrer"
              href={`mailto:success@aipalette.com`}
            >
              Schedule a demo
            </MarketingCTAButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MarketingComponent
