import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import { format } from 'date-fns'

import { ReactComponent as CommentImg } from '../../../../../assets/social-icons/Comment.svg'
import { ReactComponent as StarImg } from '../../../../../assets/social-icons/Icon-feather-star.svg'
import { ReactComponent as ColorImg } from '../../../../../assets/social-icons/noun.svg'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ConsumerQuotesContainer from './styles'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

import redBook from '../../../../../assets/social-icons/redBook.png'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Likes: ampiUserData.likes,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesLittleRedBook')
  }
}

const ConsumerQuotesLittleRedBook = ({
  content,
  likes,
  postImageUrl,
  postUrl,
  comments,
  shares,
  timestamp,
  lens,
  logConsumerQuotesClicked,
  type,
}) => {
  const [showImg, setShowImg] = useState(true)
  const imgErrorHandler = (e) => {
    e.currentTarget.onerror = null
    setShowImg(false)
  }

  const linkHandler = () => {
    window.open(postUrl, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = 'Clicked_Consumer_Quotes'
      const ampiUserData = {
        platform: 'LittleRedBook',
        likes: likes,
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <ConsumerQuotesContainer
      onClick={postUrl && linkHandler}
      sx={{ cursor: postUrl !== null ? 'pointer' : 'default !important' }}
    >
      {/* You need to use the classname to adjust the size of the picture. */}
      {postImageUrl && (
        <Box
          sx={{
            height: (theme) => theme.spacing(21.25),
            overflow: 'hidden',
          }}
        >
          {postImageUrl && showImg && (
            <img
              src={postImageUrl}
              alt="product"
              className="image"
              onError={imgErrorHandler}
            />
          )}
        </Box>
      )}
      <Typography
        px={2}
        my={1}
        variant="subtitle2"
        sx={{
          height: (theme) => theme.spacing(8),
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        {content}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 'auto',
          }}
        >
          <FavoriteIcon
            sx={{
              color: (theme) => theme.palette.error.main,
              height: 17,
              width: 17,
              marginRight: '3px',
            }}
          />
          {likes || 0}
          <CommentImg
            style={{
              height: 16,
              width: 16,
              marginLeft: '9px',
              marginRight: '3px',
            }}
          />
          {comments || 0}
          <StarImg
            style={{
              height: 16,
              width: 16,
              marginLeft: '9px',
              marginRight: '3px',
            }}
          />
          {shares || 0}
          <ColorImg
            style={{
              height: 16,
              width: 16,
              marginLeft: '9px',
              marginRight: '3px',
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 36,
          overflow: 'hidden',
          marginTop: 'auto',
          /* display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2, */
        }}
      >
        <img src={redBook} />
        <Typography
          width="100%"
          textAlign="center"
          variant="subtitle2"
          color="textSecondary"
          px={2}
        >
          {format(new Date(timestamp), 'MMM dd, yyyy')}
        </Typography>
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesLittleRedBook.propTypes = {
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  postImageUrl: PropTypes.string.isRequired,
  comments: PropTypes.number.isRequired,
  shares: PropTypes.number.isRequired,
  postUrl: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesLittleRedBook
