import React from 'react'

import RegionalAnalyticsTrendPage from './RegionalAnalyticsTrendPage'
import RegionalAnalyticsCI from './RegionalAnalyticsCI'
import RegionalAnalytics from './RegionalAnalytics'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'

const RegionalAnalyticsRouting = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const projectId = queryParams.get('projectId')
  const lens = queryParams.get('lens')
  const trendId = queryParams.get('trendId')
  const trendName = queryParams.get('trend')
  const region = queryParams.get('region')
  const needId = queryParams.get('needId')

  let displayComponent
  if (needId) {
    displayComponent = <RegionalAnalyticsCI />
  } else if (projectId && lens && trendId && trendName && region) {
    displayComponent = <RegionalAnalyticsTrendPage />
  } else {
    displayComponent = <RegionalAnalytics />
  }

  return (
    <FeatureAuthorizer
      authAttr="regional_analytics"
      disableForFeat="cognitive_search"
    >
      {displayComponent}
    </FeatureAuthorizer>
  )
}

export default RegionalAnalyticsRouting
