import React from 'react'
import USAGraphUI from './USAGraphUI'
import { useHistory } from 'react-router-dom'
import { REGIONAL_ANALYTICS_COLORS } from '../../../utils/constants'

const USAGraph = ({
  regionalDistributionData,
  country,
  category,
  region,
  handleRegionClick,
  scale,
  hideTooltip,
  disableClick,
}) => {
  const sortedRegionalDistributionData = regionalDistributionData?.sort(
    (a, b) => {
      return b.distribution - a.distribution
    },
  )

  const colorScheme = {}
  const updatedRegionalDistributionData = sortedRegionalDistributionData?.forEach(
    (region, i) => {
      colorScheme[region.name] = REGIONAL_ANALYTICS_COLORS[i]
    },
  )

  const history = useHistory()
  const handleLinkClick = (link) => {
    history.push(link)
  }

  return (
    <USAGraphUI
      colorScheme={colorScheme}
      country={country}
      category={category}
      region={region}
      handleRegionClick={handleRegionClick}
      handleLinkClick={handleLinkClick}
      regionalDistributionData={sortedRegionalDistributionData}
      scale={scale}
      hideTooltip={hideTooltip}
      disableClick={disableClick}
    />
  )
}

export default USAGraph
