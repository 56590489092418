import React, { useState, useEffect } from 'react'
import {
  Chip,
  Grid,
  SvgIcon,
  Typography,
  Tab,
  CircularProgress,
  ToggleButton,
} from '@mui/material'
import { Box } from '@mui/system'
import { Circle } from '@mui/icons-material'
import { useHistory, withRouter } from 'react-router-dom'
import TabPanel from '../../shared/components/TabPanel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CommonTrendAnalysisTable from '../../components/CommonTrendAnalysisComponents/CommonTrendAnalysisTable'
import { ReactComponent as IngredientsIcon } from '../../assets/icons/ingredients.svg'
import { ReactComponent as ProductsIcon } from '../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../assets/icons/themes.svg'
import NoResult from '../../components/CommonTrendAnalysisComponents/NoResult'
import SearchNotSelected from '../../components/CommonTrendAnalysisComponents/SearchNotSelected'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/networking/axios'
import ToggleButtonGroup from '../../shared/components/ToggleButtonGroup'
import CountrySelect from '../../componentsmui/HomePagePartials/partials/CountrySelect'
import ProductSelect from '../../componentsmui/HomePagePartials/partials/ProductSelect'
import AdvancedTrendAnalysis from './AdvancedTrendAnalysis'
import countriesList from '../../mocks/country'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import { amplify, getQueryParams } from './../../store/utility'
import { StyledTab, TabGrid } from './styled'
import ErrorPage from '../../pages/ErrorPage'

import FeatureAuthorizer from '../../components/FeatureAuthorizer'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

const formatOpts = (list, setGenerator) =>
  Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
    label: item,
    id,
  }))

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_CTA_Analysis_Type':
        amplitudeUserData = {
          User: props.email,
          Analysis_Type: ampiUserData.acrossSelected,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
      case 'Common_Trend_Analysis':
        amplitudeUserData = {
          User: props.email,
          Lens: ampiUserData.lens,
          Analysis_Type: ampiUserData.acrossSelected,
          'Product_Category/Country': ampiUserData.categoryOrCountry.label,
          Maturity_Phase: ampiUserData.maturityPhase,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
      case 'Clicked_Advanced':
        amplitudeUserData = {
          User: props.email,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in CommonTrendAnalysis')
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ATA_MODES = ['basic', 'advanced']

const CommonTrendAnalysis = (props) => {
  const [showAdvancedMode, setShowAdvancedMode] = useState(false)
  const [lensSelected, setLans] = useState('Ingredient')
  const [trendType, setTrendType] = useState('Emerging')
  const [acrossSelected, setAcrossSelected] = useState(null)
  const [countryListFinal, setCountryList] = useState([])
  const [categoriesListFinal, setCategoriesList] = useState([])
  const [showDetilsFor, setShowDetilsFor] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState()
  const [productComparisonData, setProductComparisonData] = useState([])
  const [trendAnalysisMode, setTrendAnalysisMode] = useState(ATA_MODES[0])
  const history = useHistory()

  const handleChange = (event, newValue) => {
    setLans(newValue)
    const ampiEvent = `Clicked_${newValue}s`
    const ampiUserData = {
      User: props.email,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const handleToggleSelection = () => (event, toggle) => {
    if (toggle !== null) {
      setTrendAnalysisMode(toggle)
      if (toggle === ATA_MODES[0]) {
        history.replace(`?mode=${ATA_MODES[0]}`)
      } else {
        const ampiEvent = 'Clicked_Advanced'
        amp(props, ampiEvent)
      }
    }
  }

  useEffect(() => {
    const { projectsList } = props
    let formattedCountries = []
    // eslint-disable-next-line
    ;(formattedCountries =
      projectsList &&
      formatOpts(
        projectsList ? projectsList.list : [],
        (prj) => prj.criteria,
      ).map(({ label, id }) => {
        return {
          label,
          id,
          code: (
            countriesList.find((country) => country.label === label) || {
              code: '',
            }
          ).code,
          img:
            (
              countriesList.find((country) => country.label === label) || {
                img: '',
              }
            ).img || '',
        }
        // eslint-disable-next-line
      })),
      formattedCountries &&
        formattedCountries.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
    setCountryList(formattedCountries)

    let formatedCategories = []
    formatedCategories =
      projectsList &&
      projectsList.list.map((project, index) => {
        return {
          id: index,
          label: project.name,
          projectVariant: project.projectVariant,
        }
      })

    formatedCategories &&
      formatedCategories.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })

    const filteredCategoryUniqueByLabel = [
      ...new Map(
        formatedCategories &&
          formatedCategories.map((item) => [item.label, item]),
      ).values(),
    ]

    setCategoriesList(filteredCategoryUniqueByLabel)
  }, [props.projectsList])

  useEffect(() => {
    props.requestProjectsList()
    shouldLogRecentActivity(
      "Common Trend Analysis",
      "Platform",
      `/mui/common-trend-analysis`,
    )
  }, [])

  useEffect(() => {
    if (props.commonTrendAnalysisList) {
      const ampiEvent = 'Common_Trend_Analysis'
      const ampiUserData = {
        lens: lensSelected,
        acrossSelected: acrossSelected,
        maturityPhase: trendType,
        categoryOrCountry: selectedSearch,
      }
      amp(props, ampiEvent, ampiUserData)
    }
  }, [props.commonTrendAnalysisList])

  useEffect(() => {
    setShowAdvancedMode(Boolean(props.ataEnabled))
  }, [props.ataEnabled])

  useEffect(() => {
    if (showAdvancedMode) {
      const query = getQueryParams()
      const ataMode = query.get('mode')
      if (ATA_MODES.includes(ataMode)) {
        setTrendAnalysisMode(ataMode)
      }
    }
  }, [showAdvancedMode])

  useEffect(() => {
    if (trendAnalysisMode !== ATA_MODES[0]) {
      const query = getQueryParams()
      query.set('mode', trendAnalysisMode)
      history.replace(`?${query.toString()}`)
    }
  }, [trendAnalysisMode])

  const fetchCommonTredDetailsData = (order) => {
    const commonTrend =
      props.commonTrendAnalysisList &&
      props.commonTrendAnalysisList.data.commonTrends[order]
    commonTrend &&
      commonTrend.map((d) => {
        props.getProductDetails(d.id, lensSelected)
      })
  }

  useEffect(() => {
    if (props.productComparison.productDetails)
      setProductComparisonData([
        ...productComparisonData,
        props.productComparison,
      ])
  }, [props.productComparison])

  useEffect(() => {
    if (lensSelected && trendType && acrossSelected && selectedSearch) {
      const trendTyp = trendType
      props.fetchCTAList(
        lensSelected,
        trendTyp,
        acrossSelected,
        selectedSearch.label,
      )
    }
  }, [lensSelected, trendType, acrossSelected, selectedSearch])

  const tabs = ['Ingredient', 'Product', 'Theme'].map((lens, i) => {
    let icon = IngredientsIcon
    if (lens === 'Product') {
      icon = ProductsIcon
    }
    if (lens === 'Theme') {
      icon = ThemesIcon
    }
    return (
      <Tab
        key={lens}
        value={lens}
        icon={<SvgIcon component={icon} />}
        label={`${lens}s`}
        {...a11yProps(i)}
      />
    )
  })

  const tabsPanel = ['Ingredient', 'Product', 'Theme'].map(
    (lensSelected, i) => {
      return (
        <TabPanel
          key={lensSelected}
          value={lensSelected}
          index={lensSelected}
        />
      )
    },
  )

  const MultiColoredChip = ({ phase, cr }) => (
    <Chip
      size="small"
      variant="tag"
      icon={<Circle />}
      phase={phase.toLowerCase()}
      label={phase}
      onClick={() => {
        setTrendType(phase)
        setShowDetilsFor(false)
        const ampiEvent = `Clicked_${phase}`
        const ampiUserData = {
          User: props.email,
          Lens: lensSelected,
          Location: 'Common Trend Analysis',
        }
        amplify(ampiEvent, ampiUserData, props.email)
      }}
      sx={{
        border: trendType === phase ? '2px solid #0274CA' : '',
        width: '102px',
      }}
    />
  )

  const handleExpandClick = (forName) => {
    setProductComparisonData([])
    setShowDetilsFor(forName)
    fetchCommonTredDetailsData(forName)
    const ampiEvent = `Clicked_Add_Icon`
    const ampiUserData = {
      User: props.email,
      Lens: lensSelected,
      'Analysis Type': acrossSelected,
      'Trend Type': trendType,
      [acrossSelected == 'country'
        ? 'category'
        : 'country']: selectedSearch.label,
      [` No. of categories ${trendType} in`]: `${props.commonTrendAnalysisList.data.commonTrends[forName].length}`,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const handleShrinkClick = () => {
    setShowDetilsFor(false)
  }

  const acrossSelectedHandler = (acrossSelected) => {
    setAcrossSelected(acrossSelected)
    setSelectedSearch('')
    const ampiEvent = 'Clicked_CTA_Analysis_Type'
    const ampiUserData = {
      acrossSelected: acrossSelected,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  return (
    <FeatureAuthorizer
      authAttr="common_trends_analysis"
      disableForFeat="cognitive_search"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <MainCommonTabs subscriptionDetails={props.subscriptionDetails} />
        {showAdvancedMode && (
          <Box
            display="flex"
            alignItems="center"
            sx={{ bgcolor: ({ palette }) => palette.background.paper }}
            height={50}
            pr={1.25}
          >
            <ToggleButtonGroup
              value={trendAnalysisMode}
              exclusive
              onChange={handleToggleSelection()}
              aria-label="trend ananlysis mode"
              sx={{
                background: ({ palette }) => palette.background.paper,
              }}
            >
              {ATA_MODES.map((mode) => (
                <ToggleButton value={mode} aria-label={`${mode} analysis`}>
                  {`${mode[0].toUpperCase()}${mode.slice(1)}`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
      </Box>
      {trendAnalysisMode === ATA_MODES[1] ? (
        <AdvancedTrendAnalysis email={props.email} />
      ) : (
        <>
          <Grid container spacing={2} columns={12} ml={0}>
            <TabGrid item xs={4}>
              <StyledTab
                value={lensSelected}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs}
              </StyledTab>
              {tabsPanel}
            </TabGrid>
            <Grid
              item
              xs={7}
              borderBottom={1}
              borderColor={(theme) => theme.palette.divider}
            >
              <Grid
                container
                spacing={1}
                justifyContent="end"
                alignItems="center"
                mt={1}
                sx={{ gap: '5px' }}
              >
                <Box>
                  <MultiColoredChip phase="Dormant" />
                </Box>
                <Box>
                  <MultiColoredChip phase="Emerging" cr="1" />
                </Box>
                <Box>
                  <MultiColoredChip phase="Growing" cr="1" />
                </Box>
                <Box>
                  <MultiColoredChip phase="Matured" cr="4" />
                </Box>
                <Box>
                  <MultiColoredChip phase="Declining" cr="5" />
                </Box>
                <Box>
                  <MultiColoredChip phase="Fading" cr="6" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={12} mt={0.3}>
            <Grid item xs={1} display="flex" alignItems="center">
              <Typography variant="subtitle2">Analysis Type :</Typography>
            </Grid>
            <Grid item xs={4}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Across Categories"
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="category"
                  label={
                    <Typography variant="subtitle2">
                      Across Categories
                    </Typography>
                  }
                  control={
                    <Radio
                      checked={acrossSelected === 'category'}
                      onClick={() => acrossSelectedHandler('category')}
                    />
                  }
                />
                <FormControlLabel
                  value="country"
                  label={
                    <Typography variant="subtitle2">
                      Across Countries
                    </Typography>
                  }
                  control={
                    <Radio
                      checked={acrossSelected === 'country'}
                      onClick={() => acrossSelectedHandler('country')}
                    />
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              {acrossSelected === 'country' ? (
                <ProductSelect
                  categories={categoriesListFinal || []}
                  value={selectedSearch}
                  onChange={(ev, val) => {
                    setSelectedSearch(val)
                    setShowDetilsFor(false)
                  }}
                  noLabel
                />
              ) : (
                <CountrySelect
                  countries={(acrossSelected && countryListFinal) || []}
                  value={selectedSearch}
                  onChange={(ev, val) => {
                    setSelectedSearch(val)
                    setShowDetilsFor(false)
                  }}
                  noLabel
                />
              )}
            </Grid>
          </Grid>
          {acrossSelected &&
            selectedSearch &&
            props.commonTrendAnalysisList === undefined && (
              <Grid
                container
                spacing={2}
                justifyContent={'center'}
                mt={30}
                columns={12}
              >
                <CircularProgress />
              </Grid>
            )}
          {acrossSelected && selectedSearch ? (
            <>
              <CommonTrendAnalysisTable
                commonTrendAnalysisList={props.commonTrendAnalysisList}
                showDetilsFor={showDetilsFor}
                handleExpandClick={(val) => handleExpandClick(val)}
                handleShrinkClick={handleShrinkClick}
                productComparisonData={productComparisonData}
                acrossSelected={acrossSelected}
                trendType={trendType}
              />
            </>
          ) : (
            <SearchNotSelected />
          )}
          {acrossSelected &&
            selectedSearch &&
            (props.commonTrendAnalysisList === null ||
              (props.commonTrendAnalysisList &&
                props.commonTrendAnalysisList.data.order.length === 0)) && (
              <NoResult />
            )}
        </>
      )}
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  return {
    commonTrendAnalysisList: state.commonTrendAnalysis.commonTrendAnalysisList,
    productComparison: state.productDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    projectsList: state.savedTrendRequester.savedTrendList,
    ataEnabled: state.projectListBuilder.subscriptionDetails
      ? state.projectListBuilder.subscriptionDetails
          .common_trends_analysis_advanced
      : false,
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCTAList: (lans, maturityPhase, across, lookup) =>
      dispatch(
        actions.fetchCommonTrendAnalysisList(
          lans,
          maturityPhase,
          across,
          lookup,
        ),
      ),
    getProductDetails: (id, lens) =>
      dispatch(actions.fetchProductDetailsForCommonTrend(id, lens)),
    requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
  }
}

CommonTrendAnalysis.defaultProps = {
  isProductCategory: false,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(CommonTrendAnalysis, axios)),
)
