import { styled } from '@mui/system'

import { Box, Card, CardContent } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '202px',
  marginLeft: '20px',
  marginBottom: '10px',
}))

export const InlineBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '2px 7px 2px 8px',
  borderRadius: '4px',
  display: 'inline',
  fontSize: '12px',
  marginTop: '6px',
  marginBottom: '20px',
}))

export const StyledCard = styled(Card)(() => ({
  boxShadow: 'none',
  width: '382px',
  marginBottom: '10px !important',
}))

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: '0px',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '10.5px',
  paddingTop: '5px !important',
  paddingBottom: '5px  !important',
  paddingLeft: '10.5px  !important',

  '&:hover': {
    border: `1px solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',
  },
  '&:active': {
    border: `1px solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',
  },
}))
