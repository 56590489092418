import React from 'react'
import { Field, Form, reduxForm } from 'redux-form'
import { required } from '../../validation'
import {
  renderField, renderMultiSelect,
} from '../UI/form/index'
import classes from './role.css'
import { reduxFormConst } from '../../const'

let RoleForm = (props) => {
  console.log(' role form props.permissions', props.permissions)
  // const allPermission= props.permissions?props.permissions.map(p=>p.permission):null
  return [
    <Form key="RoleForm" onSubmit={props.handleSubmit}>
      <div className="clearfix">

        <Field name="displayName" placeholder={'Name'} component={renderField}
          label={'Name:'} validate={[required]}/>

        <Field name="role" placeholder={'Role name'} component={renderField}
          label={'Role:'} validate={[required]}/>

        <Field name="permissions" component={renderMultiSelect} data={props.permissions}
          label="Permissions:" validate={[]} textField="permission" valueField="id"
        />
      </div>
      <div className="clearfix" key="RoleFormSubmission">
        <div className="col-md-12">
          <button type="submit" className={'btn ' + classes.btnCustom}>
                        Save
          </button>
        </div>
      </div>
    </Form>]
}

RoleForm = reduxForm({
  form: reduxFormConst.role,
})(RoleForm)

export default RoleForm
