import { styled } from '@mui/system';
import { ReactComponent as FacebookLogo } from '../../../assets/social-icons/facebook.svg';
import { ReactComponent as LinkedinLogo } from '../../../assets/social-icons/linkedin.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/social-icons/instagram.svg';
import { ReactComponent as MediumLogo } from '../../../assets/social-icons/medium.svg';

export const FBIcon = styled(FacebookLogo)(({ theme }) => ({
  '&:hover path': {
    fill: theme.palette.colors.facebookHover,
  },
}));

export const LinkedInIcon = styled(LinkedinLogo)(({ theme }) => ({
  '&:hover path': {
    fill: theme.palette.colors.linkedinHover,
  },
}));

export const InstagramIcon = styled(InstagramLogo)(({ theme }) => ({
  '&:hover path': {
    fill: theme.palette.colors.instagramHover,
  },
}));

export const MediumIcon = styled(MediumLogo)(({ theme }) => ({
  '&:hover path': {
    fill: theme.palette.black,
  },
}));
