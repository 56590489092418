import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import ConceptForm from './ConceptForm'
import Button from '../Buttons/ButtonSW'
import SWScreenedResultsChips from '../SWScreenedResultsChips/SWScreenedResultsChips'
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'

import { notify } from '../../store/actions/screenWinnerActions'
import { notifications, screenWinnerMessages as swMsgs } from '../../const'

import closeIcon from '../../assets/images/screenWinner/swClose.svg'

import './ConceptCreateEdit.scss'
import { formatString } from '../../store/utility'

const ConceptCreateEdit = ({
  screened = false,
  editWarning,
  editWarningCallback,
  disableEdit,
  projectId,
  conceptDetails,
  onCloseCallback,
  handleSaveCncptDetails,
  handleUpdtCncptDetails,
  notify,
}) => {
  const {
    id = '',
    title = '',
    description = '',
    descriptionHTML = '',
    keywordsAnalysed = '',
    attachments = '',
    finalRank = '',
    relevanceScore = '',
    originalityScore = '',
  } = conceptDetails || {}
  const [conceptTitle, setConceptTitle] = useState('')
  const [revalidate, setRevalidate] = useState(false)
  const [conceptDescription, setConceptDescription] = useState('')
  const [conceptRawDescription, setConceptRawDescription] = useState('')
  const [conceptDialog, setConceptDialog] = useState(true)
  const [conceptEdit, setConceptEdit] = useState(false)

  const handleDescriptionChange = (val) => {
    setConceptDescription(val || '')
  }

  const handleRawDescChange = (val) => {
    setConceptRawDescription(val)
  }

  const keywords = []
  for (const [key, value] of Object.entries(keywordsAnalysed)) {
    if (key !== 'error' && Array.isArray(value)) {
      keywords.push({
        name: `${formatString(key, true)}s`,
        value: value.join('; '),
      })
    }
  }

  const conceptDetailsView = (
    <div className="concept-create-edit__view__content concept-details">
      <h3 className="sub-title--14 concept-details__sub-title mb-10">
        Concept name
      </h3>
      <p className="concept-details__content">{title}</p>
      {screened && (
        <>
          <h3 className="sub-title--14 concept-details__sub-title mb-10">
            Screened results
          </h3>
          <div className="concept-details__scores">
            <SWScreenedResultsChips
              relevanceScore={relevanceScore}
              originalityScore={originalityScore}
              finalRank={finalRank}
            />
          </div>
        </>
      )}
      {description && (
        <>
          <h3 className="sub-title--14 concept-details__sub-title mb-10">
            Description
          </h3>
          <div className="concept-details__content concept-details__desc--readonly">
            <WysiwygEditor
              readOnly
              initialRawValue={descriptionHTML}
              initialValue={description}
            />
          </div>
        </>
      )}
      {!!keywords.length && (
        <>
          <h3 className="sub-title--14 concept-details__sub-title mb-10">
            Keywords analysed
          </h3>
          <p className="concept-details__content">
            {keywords.map(({ name, value }) => (
              <span className="concept-details__content__key-words" key={name}>
                <em className="concept-details__content__key-name">{name}</em>:{' '}
                {value}
              </span>
            ))}
          </p>
        </>
      )}
      {attachments && (
        <>
          <h3 className="sub-title--14 concept-details__sub-title mb-10">
            Attachments
          </h3>
          <div className="concept-details__attachments">
            {attachments.map(({ type, name }) => (
              <div className="concept-details__attachment-item">
                <div className="concept-details__attachment-image">
                  <img />
                </div>
                <div className="concept-details__attachment-info">
                  <span className="para--10 concept-details__attachment-name">
                    {name}
                  </span>
                  <div className="concept-details__attachment-meta">
                    <span className="concept-details__attachment-meta__type">
                      {type.toUpperCase()} .
                    </span>
                    <Button textOnly name="Download" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )

  const handleConceptClick = (ev) => {
    ev.stopPropagation()
    if (disableEdit) {
      notify({
        type: notifications.ERROR,
        message: swMsgs.CONCEPT_EDIT_DISABLED,
      })
    } else if (editWarning) {
      editWarningCallback && editWarningCallback()
    } else if (id) {
      setConceptEdit(true)
    }
  }

  const onSubmit = (ev) => {
    ev.preventDefault()
    if (ev.target.name === 'Save Concept') {
      if (conceptTitle && conceptDescription) {
        if (id) {
          handleUpdtCncptDetails(
            {
              concept: {
                title: conceptTitle,
                description: conceptDescription,
                description_html: conceptRawDescription,
              },
            },
            id,
            projectId,
          )
        } else {
          handleSaveCncptDetails(
            {
              concepts: [
                {
                  title: conceptTitle,
                  description: conceptDescription,
                  description_html: conceptRawDescription,
                },
              ],
            },
            projectId,
          )
        }
        setConceptDialog(false)
      } else {
        setRevalidate(true)
      }
    }
  }

  useEffect(() => {
    if (!conceptDialog) {
      onCloseCallback && onCloseCallback()
    }
  }, [conceptDialog])

  useEffect(() => {
    if (conceptEdit && id) {
      setConceptTitle(title)
      setConceptDescription(description)
      setConceptRawDescription(descriptionHTML)
    }
  }, [conceptEdit])

  const formView = (
    <form className="concept-create-edit__view__content" onSubmit={onSubmit}>
      <ConceptForm
        conceptTitle={title}
        conceptDescription={description}
        conceptRawDescription={descriptionHTML}
        revalidate={revalidate}
        revalidateCallback={() => setRevalidate(false)}
        handleTitleChange={setConceptTitle}
        handleDescriptionChange={handleDescriptionChange}
        handleRawDescChange={handleRawDescChange}
      />
      {!!keywords.length && (
        <div className="mt-20">
          <h3 className="sub-title--14 concept-details__sub-title mb-10">
            Keywords analysed
          </h3>
          <p className="concept-details__content">
            {keywords.map(({ name, value }) => (
              <span className="concept-details__content__key-words" key={name}>
                <em className="concept-details__content__key-name">{name}</em>:{' '}
                {value}
              </span>
            ))}
          </p>
        </div>
      )}
      <div className="concept-create-edit__btns">
        <div className="concept-create-edit__btns__cancel">
          <Button
            name="Cancel"
            type="button"
            onClick={() => setConceptDialog(false)}
          />
        </div>
        <div className="concept-create-edit__btns__save-concept">
          <Button name="Save Concept" type="submit" solid onClick={onSubmit} />
        </div>
      </div>
    </form>
  )

  return (
    <div>
      {conceptDialog && (
        <div
          className="concept-create-edit"
          onClick={() => setConceptDialog(false)}
        >
          <div
            className="concept-create-edit__view"
            onClick={handleConceptClick}
            // style={{maxHeight: Math.min(680, window.innerHeight - 200)}}
          >
            <div className="concept-create-edit__view-title-bar">
              <h2 className="concept-create-edit__view-title title--14">
                {screened || conceptEdit
                  ? 'Concept Details'
                  : id
                  ? 'Unscreened concept'
                  : 'Add new concept'}
              </h2>
              <span
                role="button"
                onClick={(ev) => {
                  ev.stopPropagation()
                  setConceptDialog(false)
                }}
              >
                <img src={closeIcon} title="Close" alt="Close icon" />
              </span>
            </div>
            {conceptEdit || !id ? formView : conceptDetailsView}
          </div>
        </div>
      )}
    </div>
  )
}

// const mapStateToProps = (state) => {
//   return {
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (notification) => dispatch(notify(notification)),
  }
}

export default connect(null, mapDispatchToProps)(ConceptCreateEdit)
