import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'

const SearchBox = ({
  searchValue,
  handleSearch,
  placeholder = 'Search',
  sx,
  disabled = false,
}) => {
  return (
    <TextField
      variant="outlined"
      onChange={(e) => handleSearch(e.target.value)}
      placeholder={placeholder}
      value={searchValue}
      sx={sx}
      disabled={disabled}
      size="large"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {searchValue && (
              <ClearIcon
                cursor="pointer"
                onClick={() => {
                  handleSearch('')
                }}
                color="secondary"
                fontSize="small"
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchBox
