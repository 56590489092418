import React, { useState, useEffect } from 'react'
import { Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SingleSelectSearchComponent from '../SingleSelectSearchComponent'
import { connect } from 'react-redux'
import { fetchSavedTrends } from '././../../../store/actions/index'
import { useHistory } from 'react-router-dom'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'

const CustomSeparator = ({
  requestProjectsList,
  projectsList,
  country,
  category,
  logBreadcrumbSelection,
}) => {
  const [dashboardSelected, setDashboardSelected] = useState()
  const history = useHistory()

  const options = projectsList
    ? projectsList.list
        .map((prj) => ({
          key: prj.id,
          category: prj.name,
          country: prj.criteria,
          label: `${prj.criteria} ${prj.name}`,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .filter(
          (option) =>
            option.label !==
            (dashboardSelected?.label || `${country} ${category}`),
        )
    : []

  const handleDashboardSelection = (dashboard) => {
    logBreadcrumbSelection(dashboard)
    setDashboardSelected(dashboard)
  }

  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <SingleSelectSearchComponent
      options={options}
      handleOptionClick={handleDashboardSelection}
      defaultOption={`${country} ${category}`}
      optionSelected={dashboardSelected}
    />,
  ]

  useEffect(() => {
    if (dashboardSelected)
      history.push(`/mui/product-category/${dashboardSelected.key}`)
  }, [dashboardSelected])

  useEffect(() => {
    if (country && category) {
      shouldLogRecentActivity(
        `Explore Trends>${country} ${category}`,
        'Platform',
        history.location.pathname,
      )
    }
  }, [country, category])

  useEffect(() => {
    requestProjectsList()
  }, [])

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
      {breadcrumbs}
    </Breadcrumbs>
  )
}

const mapStateToProps = (state) => {
  return {
    projectsList: state.savedTrendRequester.savedTrendList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestProjectsList: () => dispatch(fetchSavedTrends()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomSeparator)
