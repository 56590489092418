import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { Box, Grid, Stack } from '@mui/material'

import * as actions from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import ProductInsightDetailsTop from '../../shared/components/ProductInsightDetailsTop'
import DriverCard from '../../shared/components/DriverCard'
import OverlayLoader from '../../shared/components/OverlayLoader'

import axios from '../../services/networking/axios'

import { downloadGraph, downloadSection, downloadFullPage } from '../../const'

import '../muiStyle.css'

class CategoryTopicInsightPage extends Component {
  state = {
    loader: false,
  }

  imageDownload = (section) => {
    this.setState({ loader: true }, () => {
      const name = this.props.productTopicDetails.keyword
      const country = this.props.productTopicDetails.countryName
      const category = this.props.productTopicDetails.projectName
      if (section === 'Chart') {
        downloadGraph(
          document.getElementById('_dowSectionChart'),
          `${name}_Monthly_Trend_Graph.jpg`,
        )
          .then(() => {
            this.setState({ loader: false })
          })
          .catch((er) => {
            console.log(er)
            this.setState({ loader: false })
          })
      } else {
        let dowSection = document.getElementById('_dowSectionDesc')
        let fileName = `${name}_${country}_${category}_Complete_Report.jpg`
        if (!section) {
          downloadFullPage(
            dowSection,
            '#_dowSectionChart',
            { top: 150, left: 0 },
            document.getElementById('downloadCanvas'),
            `height: ${
              document
                .querySelector('#_dowSectionChart')
                .getBoundingClientRect().height
            }px; background: white;`,
            fileName,
          )
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              this.setState({ loader: false })
              console.log(er)
            })
        }
        if (section === 'RelatedIngredients') {
          dowSection = document.getElementById('_dowSectionIngredientBlock')
          fileName = `${name}_Related_Ingredients.jpg`
          downloadSection(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch(() => {
              this.setState({ loader: false })
            })
        }
      }
    })
  }

  componentDidMount() {
    const projectid = Number(this.props.match.params.projectId)
    const productid = Number(this.props.match.params.productId)
    const topicid = Number(this.props.match.params.topicId)
    let lensSelected = this.props.match.params.urlLensSelected
    lensSelected = lensSelected.charAt(0).toUpperCase() + lensSelected.slice(1)
    this.props.fetchProductTopicDetails(productid, topicid, lensSelected)
    this.props.fetchProductTopicQuotes(productid, topicid, lensSelected)
  }

  componentDidUpdate(prevProps) {
    const projectid = Number(this.props.match.params.projectId)
    const productid = Number(this.props.match.params.productId)
    const topicid = Number(this.props.match.params.topicId)
    let lensSelected = this.props.match.params.urlLensSelected
    lensSelected = lensSelected.charAt(0).toUpperCase() + lensSelected.slice(1)
    if (topicid !== Number(prevProps.topicid)) {
      this.props.resetProductTopicDetails()
      this.props.fetchProductTopicDetails(productid, topicid, lensSelected)
      this.props.fetchProductTopicQuotes(productid, topicid, lensSelected)
    }
  }

  fetchCQForProductTopic() {
    const productid = Number(this.props.match.params.productId)
    const topicid = Number(this.props.match.params.topicId)
    let lensSelected = this.props.match.params.urlLensSelected
    lensSelected = lensSelected.charAt(0).toUpperCase() + lensSelected.slice(1)
    this.props.fetchProductTopicQuotes(productid, topicid, lensSelected)
  }

  render() {
    const { loader } = this.state
    const projectVariant = this.props.dashboardList?.find(
      (dashboard) =>
        dashboard.criteria === this.props.productTopicDetails?.countryName &&
        dashboard.name === this.props.productTopicDetails?.projectName,
    )?._projectVariant

    if (!this.props.productTopicDetails)
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              mt: 2,
              pt: 32,
              px: 88,
              flexGrow: 2,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <CircularProgressCustom />
          </Box>
        </Grid>
      )
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        {loader && <OverlayLoader />}
        <Box id="_dowSectionDesc">
          <ProductInsightDetailsTop
            topicChart={true}
            expand={loader}
            imageDownload={this.imageDownload}
            projectid={this.props.projectid}
            productid={this.props.productid}
            needid={this.props.needid}
            urllens={this.props.match.params.urlLensSelected}
            details={this.props.productTopicDetails}
            needName={this.props.productTopicDetails.needName}
            positiveSentiment={this.props.productTopicDetails.positiveSentiment}
            neutralSentiment={
              100 -
              Number(this.props.productTopicDetails.positiveSentiment) -
              Number(this.props.productTopicDetails.negativeSentiment)
            }
            negativeSentiment={this.props.productTopicDetails.negativeSentiment}
            country={this.props.productTopicDetails.countryName}
            category={this.props.productTopicDetails.projectName}
            email={this.props.email}
          />
        </Box>

        {/* <RelatedIngredients
              blockName="Product"
              projectid={this.props.projectid}
              relatedTrends={this.props.productTopicDetails.productNeeds}/> */}
        {this.props.productTopicDetails.productNeeds &&
          this.props.productTopicDetails.productNeeds.length !== 0 && (
            <DriverCard
              categoryPreviewNeed={false}
              projectid={this.props.projectid}
              productid={this.props.productid}
              urllens={this.props.match.params.urlLensSelected}
              needsList={[...this.props.productTopicDetails.productNeeds].sort(
                (needA, needB) => Number(needB.score) - Number(needA.score),
              )}
              category={this.props.productTopicDetails.needCategory}
            />
          )}

        {this.props.productTopicQuotes && (
          <ConsumerQuotesCardComponent
            quotesOptions={this.props.productTopicDetails.quotesOptions}
            consumerQuotes={this.props.productTopicQuotes}
            onRefresh={() => {
              this.fetchCQForProductTopic()
            }}
            sourceShow
            country={this.props.productTopicDetails.countryName}
            category={this.props.productTopicDetails.projectName}
            name={this.props.productTopicDetails.keyword}
            lens={this.props.match.params.urlLensSelected}
            markIrrelevantDisabled={false}
            details={{
              type: 'Trend_Level_SCN',
              trendName: this.props.productTopicDetails?.productName,
              consumerNeed: this.props.productTopicDetails?.needName,
            }}
            projectVariant={projectVariant}
          />
        )}
        <Box id="downloadCanvas"></Box>
      </Stack>
    )
  }
}

const mapStateToProps = (state, props) => {
  const downloadSections = state.downloadSections.list
  let dw = {}
  if (state.productTopic && state.productTopic.productTopicDetails) {
    const topicDetails = state.productTopic.productTopicDetails
    const keyword = topicDetails.keyword || ''
    const countryName = topicDetails.countryName || ''
    const projectName = topicDetails.projectName || ''
    const productName = topicDetails.productName || ''
    const fileName = `${countryName} ${projectName} ${productName} - Topic ${keyword}`
    const nameSection = fileName + ' details.jpg'
    // product-consumer-needs
    const nameSectionRelated = fileName + ' related ingredients.jpg'
    const nameSectionChart = fileName + ' engagement graph.png'

    let found = 0
    if (downloadSections.has(fileName)) {
      downloadSections.getIn([fileName, 'files']).forEach((section) => {
        const page = section.toJS()
        if (page.name.includes(`${fileName} Complete Report.jpg`)) {
          found++
        }
      })
    }
    dw = {
      productName,
      fileName,
      nameSection,
      nameSectionRelated,
      nameSectionChart,
      isSectionAdded: found > 0,
    }
  }
  const projectid = Number(props.match.params.projectId)
  const productid = Number(props.match.params.productId)
  const needid = Number(props.match.params.needId)
  const topicid = Number(props.match.params.topicId)
  const lensSelected = Number(props.match.params.lensSelected)
  return {
    projectid: projectid,
    productid: productid,
    needid: needid,
    topicid: topicid,
    needsList: state.projectListBuilder.needsList,
    projectList: state.projectListBuilder.projectList,
    productDetails: state.productDetails.productDetails,
    productNeedDetails: state.productDetails.productNeedDetails,
    productTopicDetails: state.productTopic.productTopicDetails,
    productTopicQuotes: state.productTopic.productTopicQuotes,
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    ...dw,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductTopicDetails: (productId, topicId, lensSelected) =>
      dispatch(
        actions.fetchProductTopicDetails(productId, topicId, lensSelected),
      ),
    fetchProductTopicQuotes: (productId, topicId, lensSelected) =>
      dispatch(
        actions.fetchProductTopicQuotes(productId, topicId, lensSelected),
      ),

    fetchProductSubFormatDetails: (
      productId,
      formatType,
      formatName,
      subFormatName,
      lensSelected,
    ) =>
      dispatch(
        actions.fetchProductSubFormatDetails(
          productId,
          formatType,
          formatName,
          subFormatName,
          lensSelected,
        ),
      ),

    sectionAdd: (productName, section) =>
      dispatch(actions.sectionAdd(productName, section)),
    sectionRemove: (nameSection) =>
      dispatch(actions.sectionRemove(nameSection)),
    spinnerDownload: (isShow) => dispatch(actions.spinnerDownload(isShow)),
    resetProductTopicDetails: () =>
      dispatch(actions.fetchProductTopicDetailsSuccess(null)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(CategoryTopicInsightPage, axios)),
)
