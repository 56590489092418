import { connect } from 'react-redux'
import { RegistrationForm } from '../../components'
import * as A from '../../store/actions'
import { confirmPassword } from '../../validation'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values) => {
    if (confirmPassword(values)) {
      dispatch(A.showSppiner())
      dispatch(A.loginUserOnServer(values))
      dispatch(A.addLoginUserDetail(values))
    }
  },
})

const mapStateToProps = (state) => {
  const fieldsError = state.form['registration-form'] && state.form['registration-form'].syncErrors ? state.form['registration-form'].syncErrors : []
  return ({
    showSpinner: state.application.showSpinner,
    loginError: state.user.loginError,
    fieldsError,
  })
}

const RegistrationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm)

export default RegistrationFormContainer
