import React from 'react'

import ConceptForm from '../ConceptCreateEdit/ConceptForm'
import SearchOptsSingleSelect from '../SearchOptionsDropdown/SearchOptsSingleSelect'
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'
import Button from '../Buttons/ButtonSW'
import ButtonCard from '../Buttons/ButtonCard'
import Input from '../Input/Input'
import Tooltip from '../SWTooltip/SWTooltip'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { screenWinnerMessages as swMsgs } from '../../const'

import { ReactComponent as Iicon } from '../../assets/images/screenWinner/sw-i-icon.svg'

import closeIcon from '../../assets/images/screenWinner/swClose.svg'

import './ProjectCreate.scss'

const ProjectCreate = ({
  countries = [],
  categories = [],
  subCategories = [],
  subCategorySelected,
  dashboardList,
  concepts = [],
  submitError,
  handleClose,
  handleBrandInput,
  handleProjectTitleInput,
  handleProjectDescriptionInput,
  handleSubmit,
  handleConceptTitleChange,
  handleConceptDescriptionChange,
  handleCncptRawDescChange,
  handleConceptFileChange,
  handleAddConcept,
  handleSelectCountry,
  handleSelectCategory,
  countrySelected,
  categorySelected,
  handleSelectSubCategory,
  handleCreateCancel,
  revalidateCallback,
  goBackBtn,
  isLoading,
}) => {
  const handleInput = (value, func) => func(value)

  const projectVariant = dashboardList?.find(
    (dashboard) =>
      dashboard.criteria === countrySelected &&
      dashboard.name === categorySelected,
  )?._projectVariant
  return (
    <div
      className={`project-create ${
        isLoading ? '' : 'project-create--layer-top'
      }`}
    >
      <div
        className="project-create__view"
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className="project-create__view__title">
          <h2 className="title--14">Add New Project</h2>
          <span role="button" onClick={handleClose}>
            <img src={closeIcon} title="Close" alt="Close icon" />
          </span>
        </div>
        <form onSubmit={handleSubmit} className="project-create__view__content">
          <div className="project-create__input-section">
            <label
              className="input-label sub-title--14"
              htmlFor="project-title"
            >
              Project title
            </label>
            <Input
              type="text"
              onChange={(value) => handleInput(value, handleProjectTitleInput)}
              className="sw-input input"
              id="project-title"
              errorMessage="Required"
              required
              revalidate={submitError}
              revalidateCallback={revalidateCallback}
              capitalize
            />
          </div>
          <div className="project-create__input-section">
            <label className="input-label sub-title--14">
              Project Description (optional)
            </label>
            <div className="project-create__prj-desc">
              <WysiwygEditor handleOnChange={handleProjectDescriptionInput} />
            </div>
          </div>
          <div>
            <div className="project-create__input-section">
              <label className="sub-title--14 input-label">Market</label>
              <div className="project-create__input-select">
                <div className="input-select">
                  <SearchOptsSingleSelect
                    data={countries}
                    handleSelect={handleSelectCountry}
                    placeholder="Select Country"
                    // setDefaultVal
                    errorMessage="Required"
                    required
                    revalidate={submitError}
                    revalidateCallback={revalidateCallback}
                  />
                </div>
                <div className="input-select input-select--category">
                  <SearchOptsSingleSelect
                    data={categories}
                    handleSelect={handleSelectCategory}
                    placeholder="Select Category"
                    // setDefaultVal
                    errorMessage="Required"
                    required
                    revalidate={submitError}
                    revalidateCallback={revalidateCallback}
                  />
                </div>
                <div className="input-select--category">
                  <div className="sub-category">
                    {projectVariant === 'food' && (
                      <>
                        <div className="input-select">
                          <SearchOptsSingleSelect
                            data={subCategories}
                            value={subCategorySelected}
                            handleSelect={handleSelectSubCategory}
                            placeholder="Select Sub-Category"
                          />
                        </div>
                        <Tooltip
                          relChildPos
                          offsetTop={-15}
                          offsetLeft={10}
                          CustomTooltip={() => (
                            <>
                              Selecting and screening against a sub-category
                              improves accuracy and relevance of success
                              prediction.
                              <br />
                              Concept scoring weightage
                              <br />
                              Sub-category: 60% and
                              <br /> Category: 40%.
                              <br /> If Sub-category is All
                              <br />
                              weightage on Category: 100%
                              <br />
                            </>
                          )}
                          text={swMsgs.SUB_CATEGORY_INFO}
                          ignoreTextWidth
                        >
                          <div className="sub-category__tooltip">
                            <Iicon fill="#999999" />
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="project-create__input-section">
              <label className="input-label sub-title--14" htmlFor="brand">
                Brand (optional)
              </label>
              <Input
                inputClassName="brand-input"
                type="text"
                onChange={(val) => handleInput(val, handleBrandInput)}
                id="brand"
                revalidate={submitError}
                revalidateCallback={revalidateCallback}
                capitalize
              />
            </div>
          </div>
          <div className="project-create__concept-form">
            {concepts.map(({ id, title, description, attachments }, index) => (
              <div key={id} className="project-create__concept-form__form">
                <ConceptForm
                  conceptTitle={title}
                  conceptDescription={description.text}
                  id={id}
                  title={title}
                  attachments={attachments}
                  handleTitleChange={(value) =>
                    handleConceptTitleChange(id, value)
                  }
                  handleDescriptionChange={(value) =>
                    handleConceptDescriptionChange(id, value)
                  }
                  handleRawDescChange={(value) =>
                    handleCncptRawDescChange(id, value)
                  }
                  handleFileChange={(value) =>
                    handleConceptFileChange(id, value)
                  }
                  revalidate={submitError}
                  revalidateCallback={revalidateCallback}
                  conceptCount={index + 1}
                />
              </div>
            ))}
          </div>
          <div className="project-create__add-concept">
            <ButtonCard
              className="project-create__add-concept__btn"
              name="Add more concept"
              onClick={handleAddConcept}
            />
          </div>
          <div className="project-create__view__footer">
            {goBackBtn}
            <div className="project-create__view__footer__btns">
              <div className="project-create__view__footer__btns__cancel">
                <Button
                  type="button"
                  name="Cancel"
                  onClick={handleCreateCancel}
                />
              </div>
              <Button
                type="submit"
                name="Save Project"
                solid
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}
export default withRouter(connect(mapStateToProps, null)(ProjectCreate))
