import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      '&:hover': {
        color: palette.primary.main,
      },
      '&.Mui-disabled': {
        color: palette.grey[300],
      },
    },
    colorSecondary: {
      color: palette.icon,
      '&$checked': {
        color: palette.primary.main,
      },
      '&:hover': {
        color: palette.secondary.main,
      },
    },
  },
};
