import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { Box, Grid } from '@mui/material'

import CognitiveSearchHeader from '../../shared/components/CognitiveSearchHeader'
import CognitiveSearchTable from '../../shared/components/CognitiveSearchTable'
import ConsumerInsightsCard from '../../shared/components/ConsumerInsightesCard'
import MultiSelectMenuList from '../../shared/components/MultiSelectMenuList'
import TrendInfoDrawer from '../../shared/components/TrendInfoDrawer'

import {
  fetchCSCN,
  fetchFormatsList,
  fetchAdvFiltersList,
  fetchAdvFiltersAppliedTrendsList,
  fetchCSFilteredTrends,
  fetchTrendInfoDetails,
  fetchUserDashboards,
  fetchSubscriptionsDetails,
  updateAdvFiltersList,
  fecthFeaturesDetails,
  resetCognitiveSearchState,
} from '../../store/actions'
import OverlayLoader from '../../shared/components/OverlayLoader'
import FeatureAuthorizer from '../FeatureAuthorizer'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'
import { amplify } from '../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'

const filterCheckedEntity = (entityList) => {
  const filteredResult = entityList
    .filter((entity) => entity.checked)
    .map((entity) => entity.label)

  if (!filteredResult.length) {
    return 'NULL'
  }
  return filteredResult
}

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
    }
    switch (amplitudeEvent) {
      case 'Clicked_Formats_Cognitive_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Formats: filterCheckedEntity(ampiUserData.Formats),
        }
        break
      case 'Clicked_Advance_Search_Cognitive_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Filters: filterCheckedEntity(ampiUserData.Filters),
        }
        break
      case 'Clicked_Trend_Cognitive_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.trendDetails.name,
          Lens: ampiUserData.lens,
          Filters: filterCheckedEntity(ampiUserData.Filters),
          Formats: filterCheckedEntity(ampiUserData.Formats),
        }
        break
      case 'Clicked_Consumer_Insights_Cognitive_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Filters: filterCheckedEntity(ampiUserData.Filters),
          Formats: filterCheckedEntity(ampiUserData.Formats),
          Consumer_Insight: ampiUserData.chip.category,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Cognitive Search')
  }
}

const CognitiveSearch = (props) => {
  const {
    formatOptions,
    advanceFilterOptions,
    advFiltersAppliedTrendsList,
    consumerNeeds,
    filteredTrends,
    fsProductsData,
    retailProductsData,
    consumerQuotesData,
    socialGraphData,
    fetchCSCN,
    fetchCSFilteredTrends,
    fetchFormatsList,
    fetchTrendInfoDetails,
    fetchAdvFiltersList,
    fetchAdvFiltersAppliedTrendsList,
    fetchUserDashboards,
    dashboardsList,
    loading,
    trendInfoLoading,
    // fecthFeaturesDetails,
    // featuresDetails,
    resetCognitiveSearchState,
  } = props

  const [selectedFormatOpts, setSelectedFormatOpts] = useState(formatOptions)
  const [selectedAdvFilterOpts, setSelectedAdvFilterOpts] = useState(
    advanceFilterOptions,
  )
  const [advFiltersAppliedTrends, setAdvFiltersAppliedTrends] = useState(false)
  const [lensSelected, setLensSelected] = useState('Ingredient')
  const [trendDetail, setTrendDetail] = useState(null)
  const [country, setCountry] = useState(null)
  const [category, setCategory] = useState(null)
  const [projectVariant, setProjectVariant] = useState('food')
  const [projectId, setProjectId] = useState(null)
  const [lensToShow, setLensToShow] = useState([
    {
      id: 1,
      name: 'Ingredient',
      displayValue: 'Ingredient',
      value: 'Ingredient',
    },
    {
      id: 2,
      name: 'Product',
      displayValue: 'Product',
      value: 'Product',
    },
    {
      id: 3,
      name: 'Theme',
      displayValue: 'Theme',
      value: 'Theme',
    },
  ])
  const [showMenuList, setShowMenuList] = useState(false)
  const [showCN, setShowCN] = useState(false)
  const anyFormatsSelected = useMemo(() => {
    return selectedFormatOpts?.filter((obj) => obj.checked).length
  }, [selectedFormatOpts])

  //  Amplitude logging
  const filterAmplitudeLogging = (Filters) => {
    const ampiEvent = 'Clicked_Advance_Search_Cognitive_Search'
    const ampiUserData = {
      Filters,
      country,
      category,
    }
    amp(ampiEvent, ampiUserData)
  }
  const formatAmplitudeLogging = (Formats) => {
    const ampiEvent = 'Clicked_Formats_Cognitive_Search'
    const ampiUserData = {
      Formats,
      country,
      category,
    }
    amp(ampiEvent, ampiUserData)
  }

  const trendClickAmplitudeLogging = (trendDetails) => {
    const ampiEvent = 'Clicked_Trend_Cognitive_Search'
    const ampiUserData = {
      trendDetails,
      country,
      category,
      Formats: selectedFormatOpts,
      Filters: selectedAdvFilterOpts,
      lens: lensSelected,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logConsumerInsightChipClick = (chipInfo) => {
    const ampiEvent = 'Clicked_Consumer_Insights_Cognitive_Search'
    const ampiUserData = {
      country,
      category,
      Formats: selectedFormatOpts,
      Filters: selectedAdvFilterOpts,
      chip: chipInfo,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleUpdateFormatOpts = (updatedOptions) => {
    formatAmplitudeLogging(updatedOptions)
    setSelectedFormatOpts(updatedOptions)
  }
  const dispatch = useDispatch()
  const handleUpdateAdvFilterOpts = (updatedOptions) => {
    filterAmplitudeLogging(updatedOptions)
    dispatch(updateAdvFiltersList(updatedOptions))
  }

  const handleResetFormatOpts = () => {
    setSelectedFormatOpts(formatOptions)
  }

  const handleResetAdvFilterOpts = () => {
    dispatch(
      updateAdvFiltersList(
        advanceFilterOptions.map((obj) =>
          obj.checked === true ? { ...obj, checked: false } : { ...obj },
        ),
      ),
    )
  }

  const handleTrendInfoClose = () => {
    setTrendDetail(null)
  }

  const handleTrendClick = (trend) => {
    trendClickAmplitudeLogging(trend)
    setTrendDetail(trend)
  }

  const handleChangeLens = (lensSelected, callback) => {
    setLensSelected(lensSelected.list[0].value)
  }

  const handleSetCountryAndCategory = (selectedDashboardData) => {
    if (Object.keys(selectedDashboardData)?.length) {
      setCountry(selectedDashboardData.country)
      setCategory(selectedDashboardData.category)
      setProjectVariant(selectedDashboardData.variant)
      setProjectId(selectedDashboardData.project_id)
    }
  }

  const handleRemoveSelectedFormat = (nameOfFormat) => {
    setSelectedFormatOpts(
      selectedFormatOpts.map((obj) =>
        obj.label === nameOfFormat ? { ...obj, checked: false } : { ...obj },
      ),
    )
  }

  const handleRemoveSelectedAdvFilter = (keyOfFilter) => {
    dispatch(
      updateAdvFiltersList(
        selectedAdvFilterOpts.map((obj) =>
          obj.key === keyOfFilter ? { ...obj, checked: false } : { ...obj },
        ),
      ),
    )
  }

  // useEffects
  useEffect(() => {
    fetchUserDashboards()
    shouldLogRecentActivity(
      'Cognitive Search',
      'Platform',
      '/mui/cognitive-search',
    )
    // Defer rendering to improve performance
    setTimeout(() => {
      setShowMenuList(true)
    }, 250)
  }, [])

  useEffect(() => {
    // Defer rendering to improve performance
    setShowCN(true)
  }, [showMenuList])

  useEffect(() => {
    if (!country && !category) {
      const defaultDashboard =
        dashboardsList?.length &&
        dashboardsList
          .map((prj) => ({
            category: prj.category,
            country: prj.country,
            label: `${prj.country} ${prj.category}`,
            projectId: prj.project_id,
            variant: prj.variant,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1))[0]
      setCountry(defaultDashboard?.country)
      setCategory(defaultDashboard?.category)
      setProjectVariant(defaultDashboard?.variant)
      setProjectId(defaultDashboard?.projectId)
    }
  }, [dashboardsList])

  useEffect(() => {
    if (country && category) {
      fetchFormatsList(country, category)
    }
  }, [country, category])

  // For updating state on fetch
  useEffect(() => {
    if (formatOptions?.length) {
      setSelectedFormatOpts(formatOptions)
    }
  }, [formatOptions])

  useEffect(() => {
    if (advanceFilterOptions?.length) {
      setSelectedAdvFilterOpts(advanceFilterOptions)
    }
  }, [advanceFilterOptions])
  useEffect(() => {
    if (filteredTrends?.length) {
      if (Object.keys(advFiltersAppliedTrendsList).length) {
        setAdvFiltersAppliedTrends(
          filteredTrends.filter((obj) =>
            advFiltersAppliedTrendsList[lensSelected.toLowerCase()].includes(
              obj.name,
            ),
          ),
        )
      }
    } else {
      setAdvFiltersAppliedTrends(false)
    }
  }, [advFiltersAppliedTrendsList, filteredTrends])

  useEffect(() => {
    if (anyFormatsSelected && country && category) {
      fetchCSCN(
        country,
        category,
        selectedFormatOpts
          .filter((format) => format.checked === true)
          .map((format) => {
            return format.label
          }),
      )
      fetchAdvFiltersList(
        country,
        category,
        selectedFormatOpts
          .filter((format) => format.checked === true)
          .map((format) => {
            return format.label
          }),
      )
    }
  }, [selectedFormatOpts])

  useEffect(() => {
    props.fetchSubscriptionsDetails()
  }, [])

  useEffect(() => {
    if (anyFormatsSelected && country && category) {
      fetchCSFilteredTrends(
        country,
        category,
        selectedFormatOpts
          .filter((format) => format.checked === true)
          .map((format) => {
            return format.label
          }),
        lensSelected,
      )
    }
  }, [selectedFormatOpts, lensSelected])

  useEffect(() => {
    if (selectedAdvFilterOpts?.length) {
      const selectedAdvFilters = selectedAdvFilterOpts
        .filter((filter) => filter.checked === true)
        .map((filter) => {
          return {
            label: filter.label,
            category: filter.key.split('-')[0],
          }
        })
      if (selectedAdvFilters?.length) {
        const advIngSelected = selectedAdvFilters
          .filter((filter) => filter.category === 'ingredient')
          .map((obj) => {
            return obj.label
          })
        const advProductsSelected = selectedAdvFilters
          .filter((filter) => filter.category === 'product')
          .map((obj) => {
            return obj.label
          })
        const advThemesSelected = selectedAdvFilters
          .filter((filter) => filter.category === 'theme')
          .map((obj) => {
            return obj.label
          })
        fetchAdvFiltersAppliedTrendsList(
          country,
          category,
          selectedFormatOpts
            .filter((format) => format.checked === true)
            .map((format) => {
              return format.label
            }),
          advIngSelected,
          advProductsSelected,
          advThemesSelected,
        )
      } else {
        setAdvFiltersAppliedTrends(false)
      }
    }
  }, [selectedAdvFilterOpts])

  useEffect(() => {
    dispatch(updateAdvFiltersList([]))
    setSelectedAdvFilterOpts([])
  }, [projectId])

  // useEffect(() => {
  //   if (projectId) {
  //     const projectID = Number(projectId)
  //     fecthFeaturesDetails(projectID)
  //   }
  // }, [projectId])

  // useEffect(() => {
  //   if (featuresDetails) {
  //     const lensFilters = []
  //     let lensSelectedUpdate = 'Ingredient'

  //     // Check for lens classification is available
  //     if (featuresDetails && featuresDetails.theme_classification) {
  //       lensFilters.push({
  //         id: 3,
  //         name: 'Theme',
  //         displayValue: 'Theme',
  //         value: 'Theme',
  //       })
  //       lensSelectedUpdate = 'Theme'
  //     }

  //     if (featuresDetails && featuresDetails.product_classification) {
  //       lensFilters.push({
  //         id: 2,
  //         name: 'Product',
  //         displayValue: 'Product',
  //         value: 'Product',
  //       })
  //       lensSelectedUpdate = 'Product'
  //     }

  //     if (featuresDetails && featuresDetails.ingredient_classification) {
  //       lensFilters.push({
  //         id: 1,
  //         name: 'Ingredient',
  //         displayValue: 'Ingredient',
  //         value: 'Ingredient',
  //       })
  //       lensSelectedUpdate = 'Ingredient'
  //     }

  //     if (
  //       lensFilters &&
  //       !lensFilters.filter((obj) => obj.name === lensSelectedUpdate).length
  //     ) {
  //       lensSelectedUpdate = lensFilters[0].name
  //     }
  //     setLensSelected(lensSelectedUpdate)
  //     setLensToShow(lensFilters.reverse())
  //   }
  // }, [featuresDetails])

  useEffect(() => {
    return () => {
      resetCognitiveSearchState()
    }
  }, [])

  return (
    <FeatureAuthorizer authAttr="cognitive_search">
      <Box>
        <Grid
          container
          columns={16}
          px={0}
          sx={{
            pb: 4,
          }}
        >
          {loading && <OverlayLoader />}
          <Grid container spacing={2} rowSpacing={3}>
            <Grid item sx={{ width: 250 }}>
              <Box
                sx={{
                  mt: 6,
                  border: ({ palette }) => `1px solid ${palette.divider}`,
                  background: ({ palette }) => palette.white,
                  borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                }}
              >
                {showMenuList && (
                  <MultiSelectMenuList
                    header={{
                      content: 'Smart Filters',
                      showNumOfFiltersApplied: false,
                    }}
                    options={selectedAdvFilterOpts}
                    applyButton={{
                      updateFilterOptions: handleUpdateAdvFilterOpts,
                    }}
                    resetButton={{
                      resetFilterOptions: handleResetAdvFilterOpts,
                    }}
                    selectAllOption={{
                      enabled: false,
                    }}
                    shouldSortOpts
                    searchBarEnabled
                    shouldShowTooltip
                    emptyState={!anyFormatsSelected}
                  />
                )}
              </Box>
            </Grid>
            <Grid item sx={{ width: { xs: '100%', sm: 'calc(100% - 250px)' } }}>
              <Box>
                <CognitiveSearchHeader
                  selectedFormatOpts={selectedFormatOpts}
                  handleUpdateFormatOpts={handleUpdateFormatOpts}
                  handleResetFormatOpts={handleResetFormatOpts}
                  country={country}
                  category={category}
                  dashboardsList={dashboardsList}
                  handleSetCountryAndCategory={handleSetCountryAndCategory}
                />
                {showMenuList && (
                  <CognitiveSearchTable
                    trends={
                      advFiltersAppliedTrends !== false
                        ? advFiltersAppliedTrends
                        : filteredTrends
                    }
                    projectVariant={projectVariant}
                    lensSelected={lensSelected}
                    lensToShow={lensToShow}
                    shouldOpenTrendInfo={{
                      handleTrendClick: handleTrendClick,
                    }}
                    handleChangeLens={handleChangeLens}
                    selectedFormatOpts={selectedFormatOpts}
                    selectedAdvFilterOpts={selectedAdvFilterOpts}
                    handleRemoveSelectedFormat={handleRemoveSelectedFormat}
                    handleRemoveSelectedAdvFilter={
                      handleRemoveSelectedAdvFilter
                    }
                    anyFormatsSelected={anyFormatsSelected}
                  />
                )}
              </Box>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 2,
                }}
              >
                {!!anyFormatsSelected && showCN && consumerNeeds && (
                  <Box
                    sx={{
                      border: ({ palette }) => `1px solid ${palette.divider}`,
                      borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                      overflow: 'hidden',
                    }}
                  >
                    <ConsumerInsightsCard
                      disableCardsClick
                      needCategories={consumerNeeds}
                      category={category}
                      country={country}
                      type="cognitiveSearch"
                      logConsumerInsightChipClick={logConsumerInsightChipClick}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TrendInfoDrawer
          trendInfoLoading={trendInfoLoading}
          trendData={socialGraphData}
          openTrendInfo={trendDetail !== null}
          handleTrendInfoClose={handleTrendInfoClose}
          lensSelected={lensSelected}
          category={category}
          country={country}
          trendDetail={trendDetail}
          fsProductsData={fsProductsData}
          retailProductsData={retailProductsData}
          consumerQuotesData={consumerQuotesData}
          fetchTrendInfoDetails={fetchTrendInfoDetails}
          selectedFormatOpts={selectedFormatOpts}
        />
      </Box>
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  const { cognitiveSearch, user } = state
  const {
    formatsList,
    advFiltersAppliedTrendsList,
    consumerNeeds,
    filteredTrends,
    fsProductsData,
    retailProductsData,
    consumerQuotesData,
    socialGraphData,
    dashboardsList,
  } = cognitiveSearch

  const advanceFilterOptions = cognitiveSearch.advFiltersList
  const loading =
    Object.values(cognitiveSearch.loading).filter((value) => value === true)
      .length > 0
  const trendInfoLoading = cognitiveSearch.trendInfoLoading

  return {
    userName:
      (user.loggedIn?.user && user.loggedIn.user.firstName) ||
      user.loggedIn.user.username ||
      'User',
    email: user?.loggedIn?.user?.email || '',
    formatOptions: formatsList,
    consumerNeeds,
    filteredTrends,
    fsProductsData,
    retailProductsData,
    consumerQuotesData,
    advanceFilterOptions,
    advFiltersAppliedTrendsList,
    socialGraphData,
    dashboardsList,
    loading,
    trendInfoLoading,
    // featuresDetails: state.projectListBuilder.featuresDetails,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetCognitiveSearchState: () => dispatch(resetCognitiveSearchState()),
  // fecthFeaturesDetails: (projectId) =>
  //   dispatch(fecthFeaturesDetails(projectId)),
  fetchCSCN: (country, category, formatsSelected) =>
    dispatch(fetchCSCN(country, category, formatsSelected)),
  fetchFormatsList: (country, category) =>
    dispatch(fetchFormatsList(country, category)),
  fetchAdvFiltersList: (country, category, formatsSelected) =>
    dispatch(fetchAdvFiltersList(country, category, formatsSelected)),
  fetchAdvFiltersAppliedTrendsList: (
    country,
    category,
    formatsSelected,
    advIngSelected,
    advProductsSelected,
    advThemesSelected,
  ) =>
    dispatch(
      fetchAdvFiltersAppliedTrendsList(
        country,
        category,
        formatsSelected,
        advIngSelected,
        advProductsSelected,
        advThemesSelected,
      ),
    ),
  fetchCSFilteredTrends: (country, category, formatsSelected, lensSelected) =>
    dispatch(
      fetchCSFilteredTrends(country, category, formatsSelected, lensSelected),
    ),
  fetchSubscriptionsDetails: () => dispatch(fetchSubscriptionsDetails()),
  fetchTrendInfoDetails: (
    country,
    category,
    formatsSelected,
    lensSelected,
    trendID,
    typeOfData,
    productType,
  ) =>
    dispatch(
      fetchTrendInfoDetails(
        country,
        category,
        formatsSelected,
        lensSelected,
        trendID,
        typeOfData,
        productType,
      ),
    ),
  fetchUserDashboards: () => dispatch(fetchUserDashboards()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CognitiveSearch)
