import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

// just added for presentation purposes
import ingredient from '../../assets/mock/ingredient-2.json';

const CompareContext = createContext(null);
const CompareDispatchContext = createContext(null);

function compareReducer(items, { type, payload }) {
  switch (type) {
    case 'retrieve': {
      const items = localStorage.getItem("febetaComparison") ? JSON.parse(localStorage.getItem("febetaComparison")) : []
      return items
    }
    case 'added': {
      items = localStorage.getItem("febetaComparison") ? JSON.parse(localStorage.getItem("febetaComparison")) : []
      if((items.length === 0) || (!items.some((item) => item._id === payload.id)) && items.length < 4)
        items.push(payload)
        localStorage.setItem("febetaComparison",JSON.stringify(items))
      return items
    }
    case 'deleted': {
      items = JSON.parse(localStorage.getItem("febetaComparison"))
      localStorage.setItem("febetaComparison",JSON.stringify(items.filter(item => item._id !== payload.id)))
      if(items.length === 1){
        // localStorage.removeItem('febetaProject')
        // localStorage.removeItem('febetaProductLens')
        localStorage.removeItem("febetaComparison")
      }
      return items.filter(item => item._id !== payload.id);
    }
    case 'clear': {
      // localStorage.removeItem('febetaProject')
      // localStorage.removeItem('febetaProductLens')
      localStorage.removeItem("febetaComparison")
      return [];
    }
    default: {
      throw Error(`Unknown action: ${type}`);
    }
  }
}

export const CompareProvider = ({ children }) => {
  let initialState = localStorage.getItem("febetaComparison") ? JSON.parse(localStorage.getItem("febetaComparison")) : [] ;
  const [items, dispatch] = useReducer(compareReducer,initialState);
  return (
    <CompareContext.Provider value={items}>
      <CompareDispatchContext.Provider value={dispatch}>
        {children}
      </CompareDispatchContext.Provider>
    </CompareContext.Provider>
  );
};

CompareProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useCompare() {
  return useContext(CompareContext);
}

export function useCompareDispatch() {
  return useContext(CompareDispatchContext);
}
