import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import { format } from 'date-fns'
import ConsumerQuotesContainer from './styles'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesMeituan')
  }
}

const ConsumerQuotesMeituan = ({
  timestamp,
  rating,
  content,
  link,
  lens,
  logConsumerQuotesClicked,
  type,
}) => {
  const linkHandler = () => {
    window.open(link, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = 'Clicked_Consumer_Quotes'
      const ampiUserData = {
        platform: 'Meituan',
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      <Typography
        variant="body3"
        sx={{
          flexGrow: 1,
          pt: 2,
          mb: 3,
          px: 2,
          overflow: 'auto',
          height: 260,
        }}
      >
        {content}
      </Typography>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2,
        }}
      >
        {[...new Array(parseInt(rating))].map((item, id) => (
          <i key={id} class="fa fa-star meituan-rating"></i>
        ))}
        <Typography
          width="100%"
          textAlign="right"
          variant="subtitle2"
          color="textSecondary"
          px={1}
        >
          {format(new Date(timestamp), 'MMM dd, yyyy')}
        </Typography>
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesMeituan.propTypes = {
  timestamp: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  imagePath: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesMeituan
