import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Menu, Button } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box } from '@mui/system'

import SearchInput from '../SearchInput'
import MenuListHeader from '../MenuListHeader'
import MultiSelectMenuItems from '../MultiSelectMenuItems'
import MenuListFooterButtons from '../MenuListFooterButtons'

const ButtonMultiSelectDrowpdown = ({
  buttonContent,
  buttonProps = {},
  options,
  shouldSortOpts,
  onOptClick,
  optWidth,
  useParentAnchor,
  searchBarEnabled,
  header,
  applyButton,
  resetButton,
  selectAllButton,
  selectAllOption,
  dynamicApply,
  singleSelect = false,
}) => {
  const inputRef = useRef()
  const parentAnchorEl = useRef(null)
  const numOfFiltersApplied = options.filter(
    (option) => option.checked === true,
  ).length
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [reqOptions, setReqOptions] = useState(
    shouldSortOpts
      ? options.sort((a, b) => (a.label > b.label ? 1 : -1))
      : options,
  )
  const [beforeSearchOptions, setBeforeSearchOptions] = useState(
    shouldSortOpts
      ? options.sort((a, b) => (a.label > b.label ? 1 : -1))
      : options,
  )
  const [allChecked, setAllChecked] = useState(false)
  useEffect(() => {
    if (shouldSortOpts) {
      setReqOptions(options.sort((a, b) => (a.label > b.label ? 1 : -1)))
    } else {
      setReqOptions(options)
    }
  }, [options, anchorEl])

  useEffect(() => {
    const allOptionsChecked =
      options.length === options.filter((option) => option.checked).length
    setAllChecked(allOptionsChecked)
  }, [anchorEl])

  const handleClick = (ev) => {
    setAnchorEl(ev.target)
  }
  const handleClose = () => {
    if (searchBarEnabled) {
      setSearchValue('')
    }
    setAnchorEl(null)
  }

  const getCheckedOptionsLength = (allOptions) => {
    return allOptions.filter((option) => option.checked === true).length
  }

  const handleOptClick = (opt) => (ev) => {
    ev.preventDefault()
    if (applyButton) {
      if (searchValue !== '') {
        const updatedOptions = beforeSearchOptions.map((option) =>
          option.key === opt.key
            ? { ...option, checked: !option.checked }
            : { ...option, checked: singleSelect ? false : option.checked },
        )
        setReqOptions(updatedOptions)

        setAllChecked((checkboxStatus) =>
          checkboxStatus === true
            ? !checkboxStatus
            : getCheckedOptionsLength(updatedOptions) ===
              beforeSearchOptions.length
            ? !checkboxStatus
            : checkboxStatus,
        )
        setSearchValue('')
      } else {
        const updatedOptions = reqOptions.map((option) =>
          option.key === opt.key
            ? { ...option, checked: !option.checked }
            : { ...option, checked: singleSelect ? false : option.checked },
        )
        setReqOptions(updatedOptions)
        setAllChecked((checkboxStatus) =>
          checkboxStatus === true
            ? !checkboxStatus
            : getCheckedOptionsLength(updatedOptions) === reqOptions.length
            ? !checkboxStatus
            : checkboxStatus,
        )
      }
    } else if (onOptClick) {
      onOptClick(opt)
      if (dynamicApply) {
        const newOptions = options.map((option) => {
          if (option.label === opt.label) {
            return {
              ...option,
              checked: true,
            }
          } else {
            return {
              ...option,
              checked: singleSelect ? false : option.checked,
            }
          }
        })
        setAllChecked((checkboxStatus) =>
          checkboxStatus === true
            ? !checkboxStatus
            : getCheckedOptionsLength(newOptions) === options.length
            ? !checkboxStatus
            : checkboxStatus,
        )
      }
      setSearchValue('')
    }
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const strCheck = (str, subStr) =>
    str.toLowerCase().includes(subStr.toLowerCase())

  const handleSearchBarChange = (event) => {
    if (searchValue === '' && event.target.value !== '') {
      setBeforeSearchOptions(reqOptions)
    }
    setSearchValue(event.target.value)
    if (event.target.value !== '') {
      if (beforeSearchOptions?.length) {
        setReqOptions(
          beforeSearchOptions.filter((option) =>
            strCheck(option.label, event.target.value),
          ),
        )
      } else {
        setReqOptions((filterOpts) =>
          filterOpts.filter((option) =>
            strCheck(option.label, event.target.value),
          ),
        )
      }
    } else {
      setReqOptions(beforeSearchOptions)
    }
    if (
      event.target.value === '' &&
      !(inputRef.current === document.activeElement)
    ) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleApplyFiltersClick = () => {
    if (searchValue !== '') {
      setSearchValue('')
      applyButton.updateFilterOptions(beforeSearchOptions)
    } else {
      applyButton.updateFilterOptions(reqOptions)
    }

    handleClose()
  }

  const handleResetFiltersClick = () => {
    resetButton.resetFilterOptions()
    setSearchValue('')
    handleClose()
  }

  const handleSelectAllClick = () => {
    if (applyButton) {
      if (searchValue !== '') {
        setReqOptions(
          beforeSearchOptions.map((option) => ({ ...option, checked: true })),
        )
        setSearchValue('')
      } else {
        setReqOptions((filterOpts) =>
          filterOpts.map((option) => ({ ...option, checked: true })),
        )
      }
    } else {
      if ('updateParentOptions' in selectAllButton) {
        selectAllButton.updateParentOptions()
      }
    }
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleAllCheckboxClick = (ev) => {
    ev.preventDefault()
    if (applyButton) {
      if (!allChecked) {
        if (searchValue !== '') {
          setReqOptions(
            beforeSearchOptions.map((option) => ({ ...option, checked: true })),
          )
          setSearchValue('')
        } else {
          setReqOptions((filterOpts) =>
            filterOpts.map((option) => ({ ...option, checked: true })),
          )
        }
      } else {
        if (searchValue !== '') {
          setReqOptions(
            beforeSearchOptions.map((option) => ({
              ...option,
              checked: false,
            })),
          )
          setSearchValue('')
        } else {
          setReqOptions((filterOpts) =>
            filterOpts.map((option) => ({ ...option, checked: false })),
          )
        }
      }
    } else {
      if ('updateParentOptions' in selectAllOption) {
        selectAllOption.updateParentOptions(!allChecked)
      }
    }
    setAllChecked((checkboxStatus) => !checkboxStatus)
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  return (
    <>
      <Button ref={parentAnchorEl} onClick={handleClick} {...buttonProps}>
        {buttonContent.content}
        {buttonContent.showNumOfFiltersApplied &&
          (numOfFiltersApplied === options?.length ? (
            <> (all)</>
          ) : numOfFiltersApplied >= 0 ? (
            <> ({numOfFiltersApplied})</>
          ) : null)}
        {buttonContent.hasDropDownIcon && (
          <ArrowDropDownIcon
            sx={{
              color: ({ palette }) => palette.icon,
              transform: anchorEl && 'rotate(180deg)',
            }}
          />
        )}
      </Button>
      <Menu
        anchorEl={useParentAnchor ? parentAnchorEl.current : anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width:
              optWidth ||
              (anchorEl ? anchorEl.getBoundingClientRect().width : 0),
          },
        }}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {header && (
          <MenuListHeader
            header={header}
            numOfFiltersApplied={numOfFiltersApplied}
          />
        )}
        {searchBarEnabled && (
          <Box
            onKeyDown={(e) => e.stopPropagation()}
            component="div"
            sx={{ px: 2, py: 1.5 }}
          >
            <SearchInput
              value={searchValue}
              onChange={handleSearchBarChange}
              txtProps={{
                autoFocus: true,
                fullWidth: true,
                inputRef: inputRef,
              }}
            />
          </Box>
        )}
        <MultiSelectMenuItems
          selectAllOption={selectAllOption}
          searchBarEnabled={searchBarEnabled}
          searchValue={searchValue}
          handleAllCheckboxClick={handleAllCheckboxClick}
          allChecked={allChecked}
          menuOptions={reqOptions}
          menuOptionsApplied={options}
          handleOptClick={handleOptClick}
          extraBoxStyles={{
            maxHeight: 300,
          }}
          singleSelect={singleSelect}
        />
        {(applyButton || resetButton || selectAllButton?.enabled) && (
          <MenuListFooterButtons
            enableResetButton={Boolean(resetButton)}
            handleResetFiltersClick={handleResetFiltersClick}
            enableApplyButton={Boolean(applyButton)}
            handleApplyFiltersClick={handleApplyFiltersClick}
            selectAllButton={selectAllButton}
            handleSelectAllClick={handleSelectAllClick}
          />
        )}
      </Menu>
    </>
  )
}

ButtonMultiSelectDrowpdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    }),
  ).isRequired,
  onOptClick: PropTypes.func,
  buttonContent: PropTypes.shape({
    content: PropTypes.node.isRequired,
    hasDropDownIcon: PropTypes.bool,
    showNumOfFiltersApplied: PropTypes.bool,
  }).isRequired,
  header: PropTypes.shape({
    content: PropTypes.string.isRequired,
    showNumOfFiltersApplied: PropTypes.bool,
  }),
  searchBarEnabled: PropTypes.bool,
  applyButton: PropTypes.shape({
    updateFilterOptions: PropTypes.func.isRequired,
  }),
  resetButton: PropTypes.shape({
    resetFilterOptions: PropTypes.func.isRequired,
  }),
  selectAllButton: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
  selectAllOption: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
}

export default ButtonMultiSelectDrowpdown
