import React, { Component } from 'react'
import Moment from 'moment'
import { Box, SvgIcon, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import BreadcrumbsComponent from '../../../../shared/components/BreadcrumbsComponent'
import { ReactComponent as LineChartIcon } from '../../../../assets/icons/line-chart.svg'
import countriesList from '../../../../mocks/country'

class ExploreTrendsTop extends Component {
  render() {
    const rt = Moment(this.props.refreshedTime, 'YYYY/MM/DD')
    const country = countriesList.find(
      (item) => item.label === this.props.country,
    )
    return (
      <Box
        sx={{
          pt: 3,
          pb: 2,
        }}
      >
        <BreadcrumbsComponent
          country={this.props.country}
          category={this.props.category}
          logBreadcrumbSelection={this.props.logBreadcrumbSelection}
        />
        <Box
          sx={{
            pt: 3,
            pb: 1,
          }}
        >
          <Typography variant="h4" color="text.secondary">
            Product Category
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="h2">{this.props.category}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                '& hr': {
                  mx: 2,
                  height: 20,
                },
              }}
            >
              <Divider orientation="vertical" flexItem />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '& > img': {
                    mr: 1,
                    flexShrink: 0,
                    overflow: 'hidden',
                    objectFit: 'unset',
                  },
                }}
              >
                <img
                  loading="lazy"
                  width="30"
                  height="20"
                  src={
                    country?.img
                      ? country?.img
                      : `https://flagcdn.com/${(
                          country || { code: '' }
                        ).code.toLowerCase()}.svg`
                  }
                  srcSet={
                    country?.img
                      ? country?.img
                      : `https://flagcdn.com/w40/${(
                          country || { code: '' }
                        ).code.toLowerCase()}.png 2x`
                  }
                  alt=""
                />
              </Box>
              <Box>
                <Typography variant="h4" color="text.secondary">
                  {this.props.country}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 0.5,
              }}
            >
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <SvgIcon component={LineChartIcon} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: '2px',
                  mr: '12px',
                }}
              >
                <Box>
                  <Typography variant="subtitle2">
                    {Number(this.props.dataPoints).toLocaleString('en-US')} Data
                    Points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Matched topics by algorithm
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  color: 'secondary.main',
                  mr: 1,
                }}
              >
                <CalendarTodayIcon color="secondary.main" fontSize="small" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: '2px',
                  mr: '16px',
                }}
              >
                <Box>
                  <Typography variant="subtitle2">
                    {`${
                      this.props.projectVariant === 'bpc'
                        ? 'Jan 2018'
                        : 'Jan 2014'
                    } - ${rt.format('MMM YYYY')}`}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Analyzed data date range
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default ExploreTrendsTop
