import React from 'react';
import { Link } from 'react-router-dom'
import {
  Button, Drawer, Grid, Typography,
} from '@mui/material';
import { useCompare, useCompareDispatch } from '../../../shared/contexts/CompareContext';
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import EmptyCompareCard from './partials/EmptyCompareCard';
import CompareCard from './partials/CompareCard';
import {amplify} from "./../../../store/utility"

const amp = (props,ampiEvent,ampiUserData={}) => {
  try {
    const amplitudeEvent = ampiEvent;
    let amplitudeUserData = ampiUserData;
    switch(ampiEvent) {
      case 'Clicked_Compare_Trends':
        amplitudeUserData = {
          'User': props.email,
          'Trend_Name': props.name,
          'Country_Name': props.country,
          'Category_Name': props.category,
          'Lens': props.lens,
          'Maturity_Phase':props.maturityPhase
        }
        amplify(amplitudeEvent, amplitudeUserData,props.email)
        break;
        case 'Clicked_Compare_Close':
        amplitudeUserData = {
          'User': props.email,
        }
        amplify(amplitudeEvent, amplitudeUserData,props.email)
        break;
    }
  }
  catch(err) {
    console.log(err,'Amplitude error in ComparePopUp');
  }
}


const ComparePopup = (props) => {
  const location = useLocation()
  const trends = useCompare();
  const dispatch = useCompareDispatch();
  if(!location.pathname.includes('/mui')){
    return null
  }

  const compareHandler = () => {
    const ampiEvent = "Clicked_Compare_Trends"
    amp(props,ampiEvent)
  }

  const handleClose = () => {
    dispatch({ type: 'clear' })
    const ampiEvent = "Clicked_Compare_Close"
    amp(props,ampiEvent)
  }

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={trends.length > 0}
      onClose={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTop: 0,
          boxShadow: '0px 0px 7px 0px #95959529',
        },
      }}
    >
      <Box sx={{ px: 3, py: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
          <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
            Comparing
            &nbsp;
            {trends.length}
            &nbsp;
            Trends
          </Typography>
          <Button
            sx={{ color: 'text.secondary' }}
            endIcon={<Close />}
            onClick={handleClose}
            size="small"
          >
            Clear and Close
          </Button>
        </Box>
        <Grid container alignItems="stretch" spacing={2}>
          {trends.map(trend => (
            <Grid item xs={2}>
              <CompareCard
                id={trend._id}
                name={trend._name}
                rank={trend._rank}
                image={trend._productImage}
                dataPoint={trend._data_point}
              />
            </Grid>
          ))}
          {trends && trends.length < 4 &&
            <Grid item xs={2}>
              <EmptyCompareCard />
            </Grid>
          }
          <Grid item xs />
          <Box sx={{ my: 'auto', py: 2 }}>
            <Link to={`/mui/compare`}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={compareHandler}
              >
                Compare Trends
              </Button>
            </Link>
          </Box>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default ComparePopup;
