import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import {
  CREATE_PILLAR_SUCCESS,
  CREATE_PILLAR_FAILURE,
  EDIT_TREND_PILLAR_SUCCESS,
  EDIT_TREND_PILLAR_FAILURE,
  GET_TREND_PILLAR_TO_EDIT,
  INFO_ABOUT_TREND_PILLAR,
  IMPORT_TREND_PILLAR_SUCCESS,
  IMPORT_TREND_PILLAR_FAILURE,
  IMPORT_TREND_PILLAR_DETAILS,
  TREND_PILLAR_TREND_DETAILS_SUCCESS,
  TREND_PILLAR_TREND_DETAILS_FAILURE,
} from './actionConstants'
import {
  FetchTrendsPillar,
  DeleteTrendsPillar,
  DownloadTrendsPillar,
  trendPillarActionTypes,
} from './actionTypes'

import { snakeToCamelObjKeys } from '../utility'

const relatedTrendDetailsSuccess = (data) => {
  return {
    type: TREND_PILLAR_TREND_DETAILS_SUCCESS,
    payload: data,
  }
}

const relatedTrendDetailsFailure = (error) => {
  return {
    type: TREND_PILLAR_TREND_DETAILS_FAILURE,
    error: error,
  }
}

const importTrendPillarSuccess = (pillarlist) => {
  return {
    type: IMPORT_TREND_PILLAR_SUCCESS,
    payload: pillarlist,
  }
}

const importTrendPillarFailure = (error) => {
  return {
    type: IMPORT_TREND_PILLAR_FAILURE,
    error: error,
  }
}

const trendPillarInfo = (
  pillarName,
  countrySelected,
  categorySelected,
  lensSelected,
  trends,
  id,
) => {
  return {
    type: INFO_ABOUT_TREND_PILLAR,
    payload: {
      trendPillarName: pillarName,
      country: countrySelected,
      category: categorySelected,
      lens: lensSelected,
      trends: trends,
      id: id,
    },
  }
}

const importPillarInfo = (
  pillarName,
  countrySelected,
  categorySelected,
  pillarId,
  trends,
  lens,
) => {
  return {
    type: IMPORT_TREND_PILLAR_DETAILS,
    payload: {
      pillarName: pillarName,
      country: countrySelected,
      category: categorySelected,
      pillarId: pillarId,
      trends: trends,
      lens: lens,
    },
  }
}

const getTrendPillarForEdit = (id) => {
  return {
    type: GET_TREND_PILLAR_TO_EDIT,
    payload: id,
  }
}

const createTrendPillarSuccess = (payload) => {
  return {
    type: CREATE_PILLAR_SUCCESS,
    payload: payload,
  }
}

const createTrendPillarFailure = (id) => {
  return {
    type: CREATE_PILLAR_FAILURE,
    payload: id,
  }
}

const editpillardetailSuccess = (id) => {
  return {
    type: EDIT_TREND_PILLAR_SUCCESS,
    payload: id,
  }
}

const editpillardetailFailure = (payload) => {
  return {
    type: EDIT_TREND_PILLAR_FAILURE,
    payload: payload,
  }
}

export const getTrendDetails = (lens, pillarId) => {
  return (dispatch) => {
    const request = NetworkUtil.trendPillarTrendDetails(lens, pillarId)
    return axios
      .get(request)
      .then((response) => {
        dispatch(relatedTrendDetailsSuccess(response.data.data))
      })
      .catch((error) => {
        dispatch(relatedTrendDetailsFailure(error))
      })
  }
}
export const getPillarsCount = () => {
  return () => {
    const request = NetworkUtil.trendPillarGeneral()
    return axios
      .get(request)
      .then((res) => {
        return snakeToCamelObjKeys(res.data.data)
      })
      .catch((error) => {
        console.error('Error while getting trend pillars count!')
        return false
      })
  }
}

export const mergePillar = (lens, data) => {
  return () => {
    const request = NetworkUtil.mergeTrendPillar(lens)
    return axios
      .post(request, data)
      .then(() => {
        return true
      })
      .catch((error) => {
        console.error('Error while merging pillars!')
        return false
      })
  }
}
export const createPillar = (data, lens) => {
  return () => {
    const request = NetworkUtil.trendPillar(lens)
    return axios
      .post(request, data)
      .then(() => {
        return true
      })
      .catch((error) => {
        console.error('Error while creating trend pillar!')
        return false
      })
  }

  // return (dispatch) => {
  //   const request = ''
  //   axios
  //     .post(request, r)
  //     .then((response) => {
  //       console.log('response', response.data)
  //       return async function(dispatch, getState) {
  //         dispatch(createTrendPillarSuccess())
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(createTrendPillarFailure(error))
  //     })
  // }
}

// export const gettrendPillar = (projectId) => {
//   return (dispatch) => {
//     const url = NetworkUtil.getAllTrends(projectId)
//     axios
//       .get(url)
//       .then((response) => {
//         console.log('response', response)
//         dispatch(getTrendPillarForEdit(response.data))
//       })
//       .catch((error) => {
//         console.log(error)
//       })
//   }
// }

export const updatedTrendbucket = (data, lens) => {
  return () => {
    const request = NetworkUtil.trendPillar(lens)
    return axios
      .put(request, data)
      .then(() => {
        return true
      })
      .catch((error) => {
        console.error('Error while updating trend pillar!')
        return false
      })
  }
}

export const pillarBasicDetails = (
  pillarName,
  countrySelected,
  categorySelected,
  lensSelected,
  trends,
  id,
) => {
  return async function(dispatch) {
    dispatch(
      trendPillarInfo(
        pillarName,
        countrySelected,
        categorySelected,
        lensSelected,
        trends,
        id,
      ),
    )
  }
}
export const importPillarDetails = (
  pillarName,
  countrySelected,
  categorySelected,
  id,
  trends,
  lens,
) => {
  return async function(dispatch) {
    dispatch(
      importPillarInfo(
        pillarName,
        countrySelected,
        categorySelected,
        id,
        trends,
        lens,
      ),
    )
  }
}

export const getTrendPillarforImporting = () => {
  return (dispatch) => {
    const request = NetworkUtil.trendPillarImport()
    axios
      .get(request)
      .then((response) => {
        dispatch(importTrendPillarSuccess(response.data.data))
      })
      .catch((error) => {
        dispatch(importTrendPillarFailure(error))
      })
  }
}

export const fetchTrendPillarList = (lens) => {
  return (dispatch) => {
    const request = NetworkUtil.trendPillar(lens)
    return axios
      .get(request)
      .then((response) => {
        dispatch(fetchTrendsPillarSuccess(response.data.data))
        return true
      })
      .catch((error) => {
        dispatch(fetchTrendsPillarFailed(error))
        return false
      })
  }
}

const fetchTrendsPillarSuccess = (trendList) => {
  return {
    type: FetchTrendsPillar.type[FetchTrendsPillar.FETCH_TRENDS_PILLAR_SUCCESS],
    trendList,
  }
}

const fetchTrendsPillarFailed = (error) => {
  return {
    type: FetchTrendsPillar.type[FetchTrendsPillar.FETCH_TRENDS_PILLAR_FAILED],
    error,
  }
}

export const DownloadTrendPillarList = (trendId) => {
  return async function(dispatch) {
    const request = NetworkUtil.downloadTrendsFromPillar(trendId)
    return axios
      .post(request, trendId)
      .then(function(response) {
        dispatch(downloadTrendsPillarSuccess(response.data))
        return true
      })
      .catch(function(error) {
        dispatch(downloadTrendsPillarFailed(error))
        return false
      })
  }
}

const downloadTrendsPillarSuccess = (trendId) => {
  return {
    type:
      DownloadTrendsPillar.type[
        DownloadTrendsPillar.DOWNLOAD_TRENDS_PILLAR_SUCCESS
      ],
    trendId: trendId,
  }
}

const downloadTrendsPillarFailed = (error) => {
  return {
    type:
      DownloadTrendsPillar.type[
        DownloadTrendsPillar.DOWNLOAD_TRENDS_PILLAR_FAILED
      ],
    error: error,
  }
}

export const deleteTrendPillar = (data, lens) => {
  return async function(dispatch, getState) {
    const request = NetworkUtil.mergeTrendPillar(lens)
    return axios
      .delete(request, { data })
      .then(function() {
        return true
      })
      .catch(function(error) {
        console.error('Error deleting trend pillar!')
        return false
      })
  }
}

const deleteTrendsPillarSuccess = (trendId) => {
  return {
    type:
      DeleteTrendsPillar.type[DeleteTrendsPillar.DELETE_TRENDS_PILLAR_SUCCESS],
    trendId: trendId,
  }
}

const deleteTrendsPillarFailed = (error) => {
  return {
    type:
      DeleteTrendsPillar.type[DeleteTrendsPillar.DELETE_TRENDS_PILLAR_FAILED],
    error: error,
  }
}

export const clearPillarDetailsData = () => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.CLEAR_PILLAR_DETAILS_DATA
      ],
  }
}

export const resetTrendPillarData = () => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.RESET_TREND_PILLAR_DATA
      ],
  }
}

export const fetchSuggestedTrends = (country, category, lens) => {
  return (dispatch) => {
    const requestCol1 = NetworkUtil.getCtxSearchMapping(
      country,
      category,
      lens,
      1,
    )
    const requestCol2 = NetworkUtil.getCtxSearchMapping(
      country,
      category,
      lens,
      2,
    )

    const fetch1 = axios
      .get(requestCol1)
      .then(({ data }) => {
        dispatch(fetchSuggestedTrendsSuccess(data.data.mapping, lens))
      })
      .catch(() => {
        console.error('Error while fetching contexts!')
      })

    const fetch2 = axios
      .get(requestCol2)
      .then(({ data }) => {
        dispatch(fetchSuggestedTrendsSuccess(data.data.mapping, lens))
      })
      .catch(() => {
        console.error('Error while fetching contexts!')
      })

    return Promise.all([fetch1, fetch2])
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }
}

const fetchSuggestedTrendsSuccess = (suggestedTrends, lens) => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.GET_SUGGESTED_TRENDS_SUCCESS
      ],
    suggestedTrends,
    lens,
  }
}

export const removeSuggestedTrends = () => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.REMOVE_SUGGESTED_TRENDS
      ],
  }
}

export const fetchPillarTrends = (lens, pillarId) => {
  return (dispatch) => {
    const request = `${NetworkUtil.trendPillar(
      lens,
    )}?id=${pillarId}&only_trends=YES`
    return axios
      .get(request)
      .then((res) => {
        dispatch(fetchPillarTrendsSuccess(res.data.data.trends))
        return true
      })
      .catch(() => false)
  }
}

const fetchPillarTrendsSuccess = (trends) => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.GET_TREND_PILLAR_TRENDS
      ],
    trends,
  }
}

export const setImportPillarInfo = (payload) => {
  return {
    type:
      trendPillarActionTypes.type[
        trendPillarActionTypes.IMPORT_TREND_PILLAR_DETAILS
      ],
    payload,
  }
}
