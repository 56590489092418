import React from 'react'
import {
  Field, reduxForm,
} from 'redux-form'
import {
  renderSelect,
} from '../UI/form/index'
import classes from './FeatureForm.css'
import { required, email, passwordLength, phoneNumber, number } from '../../validation'
import { connect } from 'react-redux'
import { reduxFormConst } from '../../const'

class FeatureForm extends React.Component {
  componentWillMount () {
    this.props.change(
      'organization.id', this.props.orgID
    )
  }

  render () {
    const { pristine, submitting } = this.props

    return (
      <form key="FeatureForm" onSubmit={this.props.handleSubmit}>
        <div className='col-md-12 pad'>
          <button type="button"
            className={'glyphicon glyphicon-remove  pull-right left5 ' + classes.crossButton}
            onClick={this.props.hide}/>
        </div>
        <div className="col-md-12">
          <h4>ORGANIZATION FEATURE</h4>
          <Field name="id"
            component={renderSelect}
            options={this.props.features} valueField="id"
            validate={[required]}
          />
        </div>

        <div className={'col-md-12 clearfix text-center ' + classes.saveBtn}>
          <button type="submit" className={'btn ' + classes.btnCustom}>
                        Save
          </button>

        </div>

      </form>

    )
  }
}

FeatureForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.projectForm,
})(FeatureForm)

export default FeatureForm
