import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
  Button,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Tabs,
  Tab,
  Autocomplete,
  Typography,
  Select,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import './styles.css'
import { DataContext } from '../../contexts/data-context'
import ConceptGenieHomeImage from '../../assets/images/conecpt_genie_homescreen_image.png'
import { ReactComponent as TextToConcept } from '../../../assets/icons/text-to-concept.svg'
import { ReactComponent as AIPackagingDesign } from '../../../assets/icons/ai-packaging-design.svg'
import { ReactComponent as WhiteAIPackagingDesign } from '../../../assets/icons/ai-packaging-design-white.svg'
import { ReactComponent as WhiteTextToConcept } from '../../../assets/icons/text-to-concept-white.svg'
import { ReactComponent as BlueChat } from '../../../assets/icons/blue-chat.svg'
import { ReactComponent as BlueImage } from '../../../assets/icons/blue-image.svg'
import { ReactComponent as WhiteChat } from '../../../assets/icons/white-chat.svg'
import { ReactComponent as WhiteImage } from '../../../assets/icons/white-image.svg'
import SavedConceptsDisk from '../../../assets/icons/saved-concepts.svg'
import { getHeaders, getBuildWinnerURL } from '../../network/utility'
import { useSingleSnackBar } from '../../../hooks'
import NewConcept from './components/new-concept'
import SavedConcepts from './components/saved-concepts'
import { buttons } from './dashboard-button-config'
import CustomButtonGroup from '../generic-components/partials/ButtonGroup'
import { getValidDate } from '../../helpers/DateHelper'
import { trimString } from '../../helpers/TextHelper'
import {
  ConceptGenieDashboardContainer,
  ConceptGenieElementContainer,
  ConceptGenieHeadline,
  ConceptGenieImageTabContainer,
  ConceptGenieMainContainer,
  CountryCategoryTypography,
  DashboardOptionsContainer,
  TableColName,
} from './components/styles'
import ExportConceptsDialog from '../generic-components/ExportConceptsDialog'

import { setCGSelectedConcepts, fetchSavedTrends } from '../../../store/actions'
import {
  fetchProjectsList,
  saveConceptDetails,
} from '../../../store/actions/screenWinnerActions'
import SWProjectsListDialog from '../generic-components/SWProjectsListDialog'
import KeywordsChipComponent from '../generic-components/KeywordsChipComponent'
import {
  SW_PRJ_TYPES,
  SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB,
} from '../../../shared/utils/constants'
import { logCGAmplitude } from './amplitude-cg'
import AnalysedTrends from '../../../components/AnalysedTrends/AnalysedTrends'
import saveIcon from './../../assets/icons/save-icon.svg'
import goBack from './../../assets/icons/go-back.svg'
import OverlayLoader from '../../../shared/components/OverlayLoader'
import BackdropLoader from '../generic-components/partials/BackdropLoader'
import RecentTrendActivity from './components/RecentTrendActivity'
import axios from './../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import { image } from 'd3'
import { da } from 'date-fns/locale'

import { amplify } from '../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'

const CONCEPT_TAB = 'Text'
const AI_IMAGE_TAB = 'Image'

const foodBpcOption = [
  { index: 0, label: 'Food & Beverage', value: 'food' },
  { index: 1, label: 'Beauty & Personal Care', value: 'bpc' },
]
const generativeAIOption = [
  {
    index: 0,
    label: CONCEPT_TAB,
    value: CONCEPT_TAB,
    icon: <BlueChat />,
    icon1: <WhiteChat />,
  },
  {
    index: 1,
    label: AI_IMAGE_TAB,
    value: AI_IMAGE_TAB,
    icon: <BlueImage />,
    icon1: <WhiteImage />,
  },
]
const SELECTED_CONCEPTS_LIMIT = 20
const SELECT_DISABLED_TOOLTIP = `A maximum of ${SELECTED_CONCEPTS_LIMIT} concepts can be selected at a time`

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
    }

    switch (ampiEvent) {
      case 'Clicked_Concept_Text_Tab':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.concept.country,
          Category_Name: ampiUserData.concept.category,
        }
        break
      case 'Clicked_Image_Packaging_Tab':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.concept.country,
          Category_Name: ampiUserData.concept.category,
        }
        break
      default:
        break
    }

    amplify(ampiEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Dashboard Tab Change')
  }
}

const Dashboard = (props) => {
  const context = useContext(DataContext)
  const history = useHistory()
  const hoverCardRef = useRef(null)
  const dashboardList = props.dashboardList

  const {
    selectedConcepts,
    projectsListSW,
    fetchProjectsList,
    setCGSelectedConcepts,
    isSWProjectsLoading,
    exportSelectedCncpts,
    screenWinnerSubscription,
    requestProjectsList,
    projectsList,
  } = props

  const hasSelectedConcepts = Boolean(selectedConcepts?.length)
  const [pageView, setPageView] = useState(
    hasSelectedConcepts ? 'saved_concepts' : 'new_concept',
  )
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [buttonsConfig, setButtonConfig] = useState(
    hasSelectedConcepts
      ? buttons.map((obj) => ({ ...obj, isActive: !obj.isActive }))
      : buttons,
  )
  const [refreshNewCncptTab, setRefreshNewCncptTab] = useState(false)
  const [isConceptGenerated, setIsConceptGenerated] = useState(false)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [showOverlayLoader, setShowOverlayLoader] = useState(false)
  const [isEditOn, setIsEditOn] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [isViewOn, setViewOn] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentActiveRow, setCurrentActiveRow] = useState(null)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState()
  const [isSWSelect, setIsSWSelect] = useState(hasSelectedConcepts)
  const [selectedConceptsIds, setSelectedConceptsIds] = useState(
    hasSelectedConcepts ? selectedConcepts.map((item) => item.id) : [],
  )
  const [isSWExportDialog, setIsSWExportDialog] = useState(hasSelectedConcepts)
  const [prjTypeSWSelected, setPrjTypeSWSelected] = useState(
    SW_PRJ_TYPES[0].value,
  )
  const [isSWPrjListDialog, setIsSWPrjListDialog] = useState(false)
  const [selectedSWPrj, setSelectedSWPrj] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [analysedTrends, setAnalysedTrends] = useState(null)
  const [excludeTags, setExcludeTags] = useState([])
  const [excludeInput, setExcludeInput] = useState('')
  const [excludeError, setExcludeError] = useState('')
  const [tags, setTags] = useState([])
  const [input, setInput] = useState('')
  const [includeError, setIncludeError] = useState('')
  const [newConceptData, setNewConceptData] = useState()
  const [generatedConceptText, setGeneratedConceptText] = useState('')
  const [concept, setConcept] = useState({})
  const [isImageGenerated, setIsImageGenerated] = useState(false)
  const [aiGenImage, setAiGenImage] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [imgTabImageUrl, setImgTabImageUrl] = useState('')

  const [hoveredCard, setHoveredCard] = useState(null)
  const [projectSelected, setProjectSelected] = useState(null)
  const [recentlyVisitedTrends, setRecentlyVisitedTrends] = useState([])
  const [currentUserRequestCount, setCurrentUserRequestCount] = useState(0)
  const [currentUserRequestLimit, setCurrentUserRequestLimit] = useState(50)
  const [monthlyUserRequestCount, setMonthlyUserRequestCount] = useState(0)
  const [monthlyUserRequestLimit, setMonthlyUserRequestLimit] = useState(50)

  const [clickedCard, setClickedCard] = useState(null)
  const [projectVariantDropdown, setProjectVariantDropdown] = useState(
    foodBpcOption[0]?.value,
  )
  const [generativeAIDropdown, setGenerativeAIDropdown] = useState(
    generativeAIOption[0]?.value,
  )
  const [dropdownIcon, setDropdownIcon] = useState(generativeAIOption[0]?.icon1)
  const [getUserCurrentImageUrl, setGetUserCurrentImageUrl] = useState('')

  const { showSnackBar } = useSingleSnackBar()

  const projectMapping = useMemo(() => {
    if (projectsList) {
      const updatedProjectList = projectsList
        .map((project, index) => {
          return {
            projectId: project._id,
            category: project._name,
            country: project._criteria,
            id: index,
            label: `${project._criteria} ${project._name}`,
          }
        })
        .sort((a, b) => a.label - b.label)
      return updatedProjectList
    }
    return []
  }, [projectsList])
  const handleImageLoad = (url) => {
    setImageUrl(url)
  }
  useEffect(() => {
    if (isSWExportDialog && prjTypeSWSelected === SW_PRJ_TYPES[1].value) {
      fetchProjectsList()
    }
  }, [isSWExportDialog, prjTypeSWSelected])

  const logGenerateConceptClicked = (Keywords) => {
    const ampiEvent = 'Clicked_Generate_Concept'
    const ampiUserData = {
      Keywords,
      country: projectSelected?.country || 'Null',
      category: projectSelected?.category || 'Null',
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logConceptSaved = (concept, conceptName, conceptText) => {
    const ampiEvent = 'Clicked_Save_Concept'
    const ampiUserData = {
      concept,
      conceptName,
      conceptText,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
    setAnalysedTrends(null)
  }

  const logReset = () => {
    const ampiEvent = 'Clicked_Concept_Genie_Reset'
    const ampiUserData = {}
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logRecentlyVisitedTrend = (name, action) => {
    const ampiEvent = 'Clicked_Concept_Genie_Recently_Visited_Trends'
    const ampiUserData = {
      name,
      action,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logAnalysedTrends = (trendName, action) => {
    const ampiEvent = 'Clicked_Concept_Genie_Analysed_Trends'
    const ampiUserData = {
      trendName,
      action,
      projectSelected,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logModifyTextClicked = (tone) => {
    const ampiEvent = 'Clicked_Concept_Genie_Modify'
    const ampiUserData = {
      projectSelected,
      tone,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logRegenerateClicked = () => {
    const ampiEvent = 'Clicked_Concept_Genie_Regenerate'
    const ampiUserData = {}
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logExcludeKeywordAdded = (excludeKeywords) => {
    const ampiEvent = 'Clicked_Concept_Genie_Exclude'
    const ampiUserData = { excludeKeywords }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logConceptFeedback = (concept, action) => {
    const ampiEvent = `Clicked_${action}_Concept`
    const ampiUserData = {
      concept,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logOptionClicked = (concept, action) => {
    const ampiEvent = 'Clicked_Save_Concept_Kebab_Menu'
    const ampiUserData = {
      concept,
      action,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logAddToSWBtnClick = () => {
    const ampiEvent = 'Clicked_Concept_Genie_SW'
    logCGAmplitude(ampiEvent)
  }

  const logSWExportDialog = (
    action,
    data,
    selectedConceptsIds,
    selectedPrjType,
  ) => {
    const concepts = data
      ?.filter((row) => selectedConceptsIds.includes(row.id))
      .map(({ title }) => title)
    const ampiEvent = 'Clicked_Concept_Genie_SW_Project'
    const ampiUserData = {
      action,
      selectedPrjType,
      concepts,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logSWPrjListDialog = (
    action,
    data,
    selectedConceptsIds,
    selectedSWPrj,
  ) => {
    const concepts = data
      ?.filter((row) => selectedConceptsIds.includes(row.id))
      .map(({ title }) => title)
    let ampiUserData = {
      action,
      concepts,
    }
    if (selectedSWPrj) {
      const selectedPrjData = projectsListSW
        ?.filter((obj) => obj.id == selectedSWPrj)
        .map(({ country, category, subcategory, title }) => ({
          country,
          category,
          subcategory,
          title,
        }))
      if (selectedPrjData?.length) {
        ampiUserData = {
          ...ampiUserData,
          ...selectedPrjData[0],
        }
      }
    }
    const ampiEvent = 'Clicked_CG_SW_Existing_Project_Action'
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const logProjectSelection = (project) => {
    const ampiEvent = 'Clicked_Concept_Genie_Dashboard'
    const ampiUserData = {
      project,
    }
    logCGAmplitude(ampiEvent, ampiUserData)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleDeleteDialogOpen = () => {
    logOptionClicked(currentActiveRow, 'Delete')
    setDeleteDialogOpen(true)
  }

  const getProjectId = (country, category) => {
    return projectMapping.find(
      (project) => project.country === country && project.category === category,
    )?.projectId
  }

  const handleTrendRedirection = (trendDetail) => {
    const { country, category, id, type } = trendDetail
    const projectId = getProjectId(country, category)
    const redirectLink = `/mui/details/${encodeURIComponent(
      projectId,
    )}/${encodeURIComponent(type.toLowerCase())}/${encodeURIComponent(id)}`
    window.open(redirectLink, '_blank')
  }

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue)
  }

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event, row) => {
    if (isSWSelect) {
      return
    }
    setAnchorEl(event.currentTarget)
    setCurrentActiveRow(row)
  }
  const handleClickAction = (event) => {
    event.stopPropagation()
  }

  const userId = localStorage.getItem('my-secret-key')
  const allConceptsUrl = getBuildWinnerURL(`concepts/${userId}/all`)

  const fetchApi = (url) => {
    setLoading(true)
    fetch(url, getHeaders())
      .then((res) => {
        if (!res.ok) {
          showSnackBar(
            `Error Fetching Saved Concepts! ${res?.statusText}`,
            'error',
          )
          if (res?.statusText == 'Forbidden') {
            setLoading(false)
            setData([])
          }
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setLoading(false)
        const finalData = data

        finalData.sort(function(a, b) {
          return (
            new Date(b.generated_concept_created_at) -
            new Date(a.generated_concept_created_at)
          )
        })
        setData(finalData)
      })
      .catch((error) => {
        console.log('error_occured', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchApi(allConceptsUrl)
  }, [isDeleted])

  const handleProjectSelection = (e, v) => {
    setProjectSelected(v)
    logProjectSelection(v)
  }

  const handleDelete = () => {
    handleDeleteDialogClose()
    handleClose()

    const row = currentActiveRow
    const deleteConceptUrl = getBuildWinnerURL(
      `concepts/${row.id}/?user_id=${userId}`,
    )
    fetch(deleteConceptUrl, getHeaders({}, 'DELETE'))
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error Deleting Concept! ${res?.statusText}`, 'error')
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setIsDeleted((prevCheck) => !prevCheck)
      })
      .catch((e) => {
        console.log('Error', e)
      })
  }

  const resetIsEditOn = () => {
    setIsEditOn(false)
  }

  const resetViewOn = () => {
    setViewOn(false)
  }

  const handleEdit = () => {
    setIsEditOn(true)
  }

  const handleView = () => {
    setViewOn(true)
  }

  const refreshData = () => {
    setIsDeleted((prevCheck) => !prevCheck)
  }

  const callRequiredFunc = (row, entity, directCall = true) => {
    if (isSWSelect) {
      return
    }
    if (directCall) {
      setSelectedRow(row)
    } else {
      setSelectedRow(currentActiveRow)
    }
    switch (entity) {
      case 'edit':
        logOptionClicked(currentActiveRow, 'Edit')
        handleEdit()
        handleClose()
        break
      case 'view':
        logOptionClicked(currentActiveRow, 'View')
        handleView()
        handleClose()
        break
      default:
        break
    }
  }

  const updateConceptStateParentLocal = (concept) => {
    if (!_.isEmpty(concept)) {
      setIsConceptGenerated(true)
    }
  }

  const updateButtonConfig = (position) => {
    // first set is_active to false for all the config
    const newArrOfObjs = JSON.parse(JSON.stringify(buttonsConfig))
    newArrOfObjs.forEach((obj) => (obj.isActive = false))

    // set isActive to true for the perticular button
    newArrOfObjs[position].isActive = true

    // update setButtonConfig with new obj
    setButtonConfig(newArrOfObjs)
  }

  const updatePageView = (page, index) => {
    const prevPage = pageView
    setAnalysedTrends(null)
    setPageView(page)
    if (page !== 'new_concept') {
      setIsConceptGenerated(false)
    }
    if ([prevPage, page].every((item) => item === 'new_concept')) {
      setRefreshNewCncptTab(true)
    }
    updateButtonConfig(index)
  }

  const onAddToScreenWinnerClick = () => {
    logAddToSWBtnClick()
    if (!screenWinnerSubscription) {
      history.push('/screen-winner')
    } else {
      setIsSWSelect(true)
    }
  }

  const addToExcludeList = (name) => {
    if (!excludeTags.includes(name)) {
      setExcludeTags([...excludeTags, name])
    }
  }

  const onCancelSWSelect = () => {
    setIsSWSelect(false)
    setSelectedConceptsIds([])
  }

  const onSavedCptsCheckboxClick = (e, id) => {
    if (e.target.checked) {
      setSelectedConceptsIds((conceptIds) => [...conceptIds, id])
    } else {
      setSelectedConceptsIds((conceptIds) =>
        conceptIds.filter((item) => item !== id),
      )
    }
  }

  const onSWExportConfirm = (data) => {
    logSWExportDialog('Export', data, selectedConceptsIds, prjTypeSWSelected)
    if (prjTypeSWSelected === SW_PRJ_TYPES[0].value) {
      setCGSelectedConcepts(
        data.filter((row) => selectedConceptsIds.includes(row.id)),
      )
      history.push('/screen-winner')
    } else {
      setIsSWPrjListDialog(true)
      setIsSWExportDialog(false)
      setCGSelectedConcepts([], true)
    }
  }

  const onSWExportClose = () => {
    setIsSWExportDialog(false)
    setPrjTypeSWSelected(SW_PRJ_TYPES[0].value)
    setCGSelectedConcepts([], true)
  }

  const onSWPrjListClose = () => {
    onSWExportClose()
    setIsSWPrjListDialog(false)
    setSelectedSWPrj(null)
  }

  const handleReset = (isLoggingRequired = false) => {
    if (isLoggingRequired) {
      logReset()
    }
    setAnalysedTrends(null)
    setExcludeError('')
    setExcludeInput('')
    setExcludeTags([])
    setTags([])
    setIncludeError('')
    setNewConceptData(null)
    setData(null)
    setGeneratedConceptText('')
    setConcept({})
    setProjectSelected(null)
  }

  const handleHighlightClick = (part) => {
    const isPresentInTheme = analysedTrends?.theme?.find(
      (theme) => theme.name.toLowerCase() === part.toLowerCase(),
    )
    const isPresentInIng = analysedTrends?.ingredient?.find(
      (ing) => ing.name.toLowerCase() === part.toLowerCase(),
    )

    if (isPresentInTheme) {
      setTabValue(1)
    }
    if (isPresentInIng) {
      setTabValue(0)
    }
    setClickedCard({ name: part })
  }

  useEffect(() => {
    if (hoverCardRef && hoverCardRef.current) {
      hoverCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      })
    }
  }, [clickedCard])

  const onSWPrjListGoBackClick = (data) => {
    logSWPrjListDialog('Go Back', data, selectedConceptsIds, selectedSWPrj)
    setIsSWPrjListDialog(false)
    setSelectedSWPrj(null)
    setIsSWExportDialog(true)
  }

  const onSWPrjListSave = (data) => {
    logSWPrjListDialog('Save Export', data, selectedConceptsIds, selectedSWPrj)
    const selectedProject = selectedSWPrj
    if (selectedProject && data?.length) {
      exportSelectedCncpts(
        {
          concepts: data
            .filter((row) => selectedConceptsIds.includes(row.id))
            .map(({ title, generated_concept: generatedConcept }) => ({
              title: title,
              description: generatedConcept,
              description_html: '',
            })),
        },
        selectedProject,
      )
    }
    onSWPrjListClose()
    history.push(`/screen-winner/projects/${selectedProject}/concepts`)
  }

  const addType = (trendList, lens) => {
    return trendList.map((trendDetail) => {
      return {
        ...trendDetail,
        type: lens,
      }
    })
  }

  const fetchRecentTrendActivity = () => {
    let req = `${NetworkUtil.dashboardRecentActivity()}trends`
    if (projectSelected !== null) {
      req += `?country=${projectSelected.country}&category=${projectSelected.category}`
    }
    setShowOverlayLoader(true)
    axios
      .get(req)
      .then((res) => {
        const data = res.data
        const updatedRes = [
          ...addType(data.ingredients, 'ingredient'),
          ...addType(data.products, 'product'),
          ...addType(data.themes, 'theme'),
        ]
        setRecentlyVisitedTrends(updatedRes)
        setShowOverlayLoader(false)
      })
      .catch((err) => {
        setShowOverlayLoader(false)
      })
  }

  const fetchUserConceptlimit = () => {
    const userId = localStorage.getItem('my-secret-key')
    const getUserCurrentLimitUrl = getBuildWinnerURL(
      `concepts/${userId}/concepts_count`,
    )
    fetch(getUserCurrentLimitUrl, getHeaders({}, 'GET'))
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error getting daily limit! ${res?.statusText}`, 'error')
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setCurrentUserRequestCount(data.current_request_count)
        setCurrentUserRequestLimit(data.request_limit_count)
      })
      .catch((error) => {
        console.log('error_occured', error)
      })
  }
  const fetchUserMonthlylimit = () => {
    const userId = localStorage.getItem('my-secret-key')
    const getUserCurrentLimitUrl = getBuildWinnerURL(
      `concepts/${userId}/monthly_count`,
    )
    fetch(getUserCurrentLimitUrl, getHeaders({}, 'POST'))
      .then((res) => {
        if (!res.ok) {
          showSnackBar(
            `Error getting monthly limit! ${res?.statusText}`,
            'error',
          )
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setMonthlyUserRequestCount(data.remaining_requests)
        setMonthlyUserRequestLimit(data.max_limit)
      })
      .catch((error) => {
        console.log('error_occured', error)
      })
  }

  const getImage = (size, description, event, aiPromptFlag) => {
    const userId = localStorage.getItem('my-secret-key')
    setShowOverlayLoader(true)

    const getUserCurrentImageUrl = getBuildWinnerURL(
      `concepts/${userId}/images/?size=${size}&description=${description}&project_variant=${projectVariantDropdown}&ai_prompt_flag=${aiPromptFlag}`,
    )

    // const body = {
    //   title: `DEFAULT_${tags.toString()}_${new Date().getTime()}`,
    //   description: tags.toString(),
    //   user_id: userId,
    //   excluded_keywords: excludeTags,
    //   country: projectSelected?.country || '',
    //   category: projectSelected?.category || '',
    //   project_variant: projectVariant,
    // }
    fetch(
      getUserCurrentImageUrl,
      getHeaders(
        {
          // body: JSON.stringify(body)
        },
        'POST',
      ),
    )
      .then((res) => {
        if (!res.ok) {
          // showSnackBar(`Error getting image! ${res?.statusText}`, 'error')
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        if (event) {
          if (event.target.name === SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB) {
            setImgTabImageUrl(data.image_url)
          } else {
            setImageUrl(data.image_url)
          }
        } else {
          setImageUrl(data.image_url)
        }
        setShowOverlayLoader(false)
      })
      .catch((error) => {
        setShowOverlayLoader(false)
        console.log('error_occured', error)
      })
  }
  const sendModifyData = (modifier) => {
    setShowOverlayLoader(true)
    const postUrl = getBuildWinnerURL(
      `concepts/${concept?.id}/tone/?user_id=${concept?.user_id}`,
    )
    const body = {
      id: concept?.id,
      project_variant: finalProjectVariant,
      modifier,
    }
    fetch(
      postUrl,
      getHeaders(
        {
          body: JSON.stringify(body),
        },
        'PUT',
      ),
    )
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error Generating Concept! ${res?.statusText}`, 'error')
          setAnalysedTrends(null)
        }
        return res.json()
      })
      .then((data) => {
        // It's a failed request
        if (data && data.detail) {
          setShowOverlayLoader(false)
          context.handleErrors(data.detail)
          setAnalysedTrends(null)
          throw new Error(data.detail)
        }
        // It's a failed request
        else if (data && data.message == 'Forbidden') {
          setShowOverlayLoader(false)
          context.handleErrors(data.message)
          setAnalysedTrends(null)
          throw new Error(data.message)
        } else {
          const ing = data.ingredients.map((ing) => {
            return { ...ing, type: 'ingredient' }
          })
          const themes = data.themes.map((theme) => {
            return { ...theme, type: 'theme' }
          })
          setAnalysedTrends({
            ingredient: ing,
            theme: themes,
          })
          setShowOverlayLoader(false)
          logModifyTextClicked(modifier)
          // Error
          context.handleUpdateData(data, 'current_concept')
        }
      })
      .catch((error) => {
        console.log('error_occured', error)
        setAnalysedTrends(null)
        setShowOverlayLoader(false)
      })
      .finally(() => {
        fetchUserConceptlimit()
      })
  }

  useEffect(() => {
    requestProjectsList()
  }, [])

  useEffect(() => {
    fetchRecentTrendActivity()
  }, [projectSelected])

  const getTableCell = (children, props = {}) => (
    <TableCell {...props}>{children}</TableCell>
  )
  const savedConceptsTable = [
    {
      id: 'checkbox',
      header: '',
      cell: (row) => {
        const checkboxDisabled =
          !selectedConceptsIds.includes(row.id) &&
          selectedConceptsIds?.length >= SELECTED_CONCEPTS_LIMIT
        const children = isSWSelect && (
          <Tooltip
            title={checkboxDisabled ? SELECT_DISABLED_TOOLTIP : ''}
            placement="right"
          >
            <Box>
              <Checkbox
                checked={selectedConceptsIds.includes(row.id)}
                onChange={(e) => onSavedCptsCheckboxClick(e, row.id)}
                disabled={checkboxDisabled}
              />
            </Box>
          </Tooltip>
        )
        return getTableCell(children, { key: `${row.title}` })
      },
    },
    {
      id: 'title',
      sorting: true,
      header: 'Concept Name',
      cell: (row) => {
        const children = (
          <TableColName
            onClick={() => callRequiredFunc(row, 'view', true)}
            styleProps={{ disableCursor: isSWSelect }}
          >
            {row.title}
          </TableColName>
        )
        return getTableCell(children, {
          key: `${row.title}-conceptname`,
          component: 'th',
          scope: 'row',
          sx: { maxWidth: 300 },
        })
      },
    },

    {
      id: 'keywords',
      header: 'Keywords',
      cell: (row) => {
        const children = row.description && (
          <KeywordsChipComponent keywords={row.description.split(',')} />
        )
        return getTableCell(children, {
          key: `${row.title}-keywords`,
          align: 'left',
        })
      },
    },
    {
      id: 'description',
      header: 'Description',
      cell: (row) => {
        const children = trimString(row.generated_concept, 35)
        return getTableCell(children, {
          key: `${row.title}-description`,
          align: 'left',
        })
      },
    },
    {
      id: 'created_at',
      sorting: true,
      header: 'Created on',
      cell: (row) => {
        const children = getValidDate(row.created_at)
        return getTableCell(children, {
          key: `${row.title}-createdon`,
          align: 'left',
        })
      },
    },
    {
      id: 'action',
      header: 'Action',
      cell: (row) => {
        const children = (
          <>
            <MoreHorizIcon
              sx={{
                color: ({ palette }) => palette.icon,
                cursor: isSWSelect ? 'not-allowed' : 'pointer',
              }}
              onClick={(e) => handleClick(e, row)}
            />
            <Menu
              id="action-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  minWidth: 170,
                },
              }}
            >
              <MenuItem
                sx={{
                  fontSize: '14px !important',
                  color: ({ palette }) => palette.primary.main,
                }}
                onClick={() => callRequiredFunc(row, 'view', false)}
              >
                {' '}
                <VisibilityOutlinedIcon
                  sx={{
                    color: ({ palette }) =>
                      `${palette.primary.main} !important`,
                  }}
                />
                View
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: '14px !important',
                  color: ({ palette }) => palette.primary.main,
                }}
                onClick={() => callRequiredFunc(row, 'edit', false)}
              >
                {' '}
                <EditOutlinedIcon
                  sx={{
                    color: ({ palette }) =>
                      `${palette.primary.main} !important`,
                  }}
                />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteDialogOpen()}
                sx={{
                  fontSize: '14px !important',
                  marginBottom: '0px !important',
                  color: ({ palette }) => `${palette.error.dark} !important`,
                }}
              >
                <DeleteOutlinedIcon
                  sx={{
                    color: ({ palette }) => `${palette.error.dark} !important`,
                  }}
                />
                Delete
              </MenuItem>
            </Menu>
          </>
        )
        return getTableCell(children, {
          key: `${row.title}-action`,
          align: 'left',
          onClick: (e) => handleClickAction(e),
        })
      },
    },
  ]

  const projectVariant = dashboardList?.find(
    (dashboard) =>
      dashboard.criteria === projectSelected?.country &&
      dashboard.name === projectSelected?.category,
  )?._projectVariant

  const finalProjectVariant = projectVariant || projectVariantDropdown

  const logClickedConceptTextFromDashboard = () => {
    const ampiEvent = 'Clicked_Concept_Text_Tab'
    const ampiUserData = {
      concept,
    }

    amp(ampiEvent, ampiUserData)
  }

  const logClickedImagePackagingFromDashboard = () => {
    const ampiEvent = 'Clicked_Image_Packaging_Tab'
    const ampiUserData = {
      concept,
    }

    amp(ampiEvent, ampiUserData)
  }

  return (
    <React.Fragment>
      {showOverlayLoader && (
        <BackdropLoader
          loading={showOverlayLoader}
          shouldRenderText={false}
          backdropBackground={'transparent'}
        />
      )}
      <Box>
        <Box sx={{ height: '5vh' }} />
        {pageView !== 'new_concept' &&
          (isSWSelect ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                pb: 4,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="outlined"
                sx={{ fontSize: '12px', mr: 2 }}
                onClick={onCancelSWSelect}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ fontSize: '12px' }}
                disabled={!selectedConceptsIds?.length}
                onClick={() => setIsSWExportDialog(true)}
              >
                Export Concepts
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                pb: 4,
                justifyContent: 'space-between',
              }}
            >
              {pageView !== 'new_concept' && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleReset()
                      updatePageView('new_concept', 0)
                    }}
                  >
                    <img
                      src={goBack}
                      style={{ height: '15px', marginRight: '10px' }}
                    />
                    Go Back to New Concepts
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ fontSize: '12px' }}
                    onClick={onAddToScreenWinnerClick}
                    disabled={!data?.length}
                  >
                    Add to Screen Winner
                  </Button>
                </>
              )}
            </Box>
          ))}

        <Grid
          container
          sx={{ paddingBottom: '20px' }}
          spacing={2}
          justifyContent="flex-end"
          className="dashboard-background"
        >
          <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
            {pageView === 'new_concept' ? (
              <Grid container spacing={2} sx={{ height: 'auto' }}>
                <Grid item xs={2.5} sx={{}}>
                  <RecentTrendActivity
                    recentTrends={recentlyVisitedTrends}
                    tags={tags}
                    setTags={setTags}
                    includeError={includeError}
                    setIncludeError={setIncludeError}
                    excludeTags={excludeTags}
                    handleTrendRedirection={handleTrendRedirection}
                    setInput={setInput}
                    logRecentlyVisitedTrend={logRecentlyVisitedTrend}
                  />
                </Grid>
                <Grid
                  item
                  xs={9.5}
                  sx={{ paddingTop: '0px !important', maxHeight: '100%' }}
                >
                  <Box>
                    <>
                      {' '}
                      <ConceptGenieHeadline>
                        <Box>
                          {' '}
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: '18px',
                              pt: 2,
                              color: ({ palette }) => palette.primary.main,
                            }}
                          >
                            Generate product concepts in seconds, not in weeks.
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#919191',
                              pb: 2,
                              pt: 0.5,
                            }}
                          >
                            We help in generating concepts for your products in
                            just one click!
                          </Typography>
                        </Box>
                        <Button
                          variant="outlined"
                          sx={{
                            padding: '8px 16px',
                            ml: 1,
                            color: ({ palette }) => palette.primary.main,
                            border: ({ palette }) =>
                              `1px solid ${palette.primary.main}`,
                          }}
                          onClick={() => updatePageView('saved_concepts', 1)}
                        >
                          <img
                            src={SavedConceptsDisk}
                            height={18}
                            width={18}
                            style={{
                              marginRight: '8px',
                              boxSizing: 'content-box',
                            }}
                          />
                          Go to Saved Concepts
                        </Button>
                      </ConceptGenieHeadline>
                      <DashboardOptionsContainer>
                        <CountryCategoryTypography pb={2}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={projectSelected}
                            options={projectMapping}
                            onChange={handleProjectSelection}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Dashboard (Optional)"
                              />
                            )}
                          />
                          {!projectSelected?.country ? (
                            <Box>
                              <Select
                                // id="project variant dropdown"
                                value={projectVariantDropdown}
                              >
                                {foodBpcOption.map((option) => {
                                  return (
                                    <MenuItem
                                      value={option.value}
                                      onClick={() =>
                                        setProjectVariantDropdown(option.value)
                                      }
                                    >
                                      {option.label}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </Box>
                          ) : null}
                        </CountryCategoryTypography>

                        <Box
                          pb={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ height: '30px', paddingRight: '10px' }}>
                            <Tabs
                              sx={{
                                height: '32px',
                                minHeight: '32px',
                                borderRadius: '5px',
                                borderBottom: 'none',
                                border: '1px solid #E0E0E0',
                                '& .Mui-selected': {
                                  backgroundColor: '#0274CA',
                                  color: '#FFF !important',
                                  '&:hover': {
                                    backgroundColor: '#0274CA',
                                  },
                                },
                                '& .MuiTabs-indicator': {
                                  background: 'none !important',
                                },
                              }}
                              id="Generative AI dropdown"
                              value={generativeAIDropdown}
                              onChange={(e, newValue) => {
                                setGenerativeAIDropdown(newValue)

                                {
                                  e.target.value === CONCEPT_TAB &&
                                    setDropdownIcon(generativeAIOption[0].icon1)
                                  setIsImageGenerated(false)
                                  setImageUrl('')
                                }
                              }}
                            >
                              {generativeAIOption.map((option) => {
                                return (
                                  <Tab
                                    name={option.label}
                                    onClick={(evt) =>
                                      evt.target.name === AI_IMAGE_TAB
                                        ? logClickedImagePackagingFromDashboard()
                                        : logClickedConceptTextFromDashboard()
                                    }
                                    sx={{
                                      height: '20px',
                                    }}
                                    icon={
                                      generativeAIDropdown === option.label
                                        ? option.icon1
                                        : option.icon
                                    }
                                    label={option.label}
                                    value={option.value}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        padding: '10px',
                                      }}
                                    >
                                      <span> {option.icon1} </span>
                                      <span> {option.label}</span>{' '}
                                    </div>
                                  </Tab>
                                )
                              })}
                            </Tabs>
                          </Box>
                        </Box>
                      </DashboardOptionsContainer>
                      <Divider my={1} />
                    </>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <ConceptGenieDashboardContainer>
                          <NewConcept
                            updateConceptStateParentLocal={
                              updateConceptStateParentLocal
                            }
                            generativeAIDropdownOption={generativeAIDropdown}
                            // monthlyCount={monthlyCount}
                            imageUrl={imageUrl}
                            onButtonClick={handleImageLoad}
                            imgTabImageUrl={imgTabImageUrl}
                            logGenerateConceptClicked={
                              logGenerateConceptClicked
                            }
                            logConceptSaved={logConceptSaved}
                            logConceptFeedback={logConceptFeedback}
                            pageView={pageView}
                            data={data}
                            setData={setData}
                            allConceptsUrl={allConceptsUrl}
                            fetchApi={fetchApi}
                            setIsConceptGenerated={setIsConceptGenerated}
                            refreshNewCncptTab={refreshNewCncptTab}
                            setRefreshNewCncptTab={setRefreshNewCncptTab}
                            analysedTrends={analysedTrends}
                            setAnalysedTrends={setAnalysedTrends}
                            excludeTags={excludeTags}
                            setExcludeTags={setExcludeTags}
                            excludeInput={excludeInput}
                            setExcludeInput={setExcludeInput}
                            tags={tags}
                            setTags={setTags}
                            setExcludeError={setExcludeError}
                            excludeError={excludeError}
                            handleReset={handleReset}
                            includeError={includeError}
                            setIncludeError={setIncludeError}
                            newConceptData={newConceptData}
                            setNewConceptData={setNewConceptData}
                            generatedConceptText={generatedConceptText}
                            setGeneratedConceptText={setGeneratedConceptText}
                            concept={concept}
                            setConcept={setConcept}
                            hoveredCard={hoveredCard}
                            getImage={getImage}
                            isImageGenerated={isImageGenerated}
                            sendModifyData={sendModifyData}
                            loading={loading}
                            setLoading={setLoading}
                            projectSelected={projectSelected}
                            setInput={setInput}
                            input={input}
                            logRegenerateClicked={logRegenerateClicked}
                            logExcludeKeywordAdded={logExcludeKeywordAdded}
                            fetchUserConceptlimit={fetchUserConceptlimit}
                            fetchUserMonthlylimit={fetchUserMonthlylimit}
                            aiGenImage={aiGenImage}
                            currentUserRequestCount={currentUserRequestCount}
                            monthlyUserRequestCount={monthlyUserRequestCount}
                            setCurrentUserRequestCount={
                              setCurrentUserRequestCount
                            }
                            setMonthlyUserRequestCount={
                              setMonthlyUserRequestCount
                            }
                            currentUserRequestLimit={currentUserRequestLimit}
                            monthlyUserRequestLimit={monthlyUserRequestLimit}
                            setCurrentUserRequestLimit={
                              setCurrentUserRequestLimit
                            }
                            setMonthlyUserRequestLimit={
                              setMonthlyUserRequestLimit
                            }
                            handleClick={handleHighlightClick}
                            setHoveredCard={setHoveredCard}
                            projectVariant={finalProjectVariant}
                          />
                        </ConceptGenieDashboardContainer>
                        {generativeAIDropdown === CONCEPT_TAB &&
                          analysedTrends !== null && (
                            <Box
                              sx={{
                                minWidth: '300px',
                                marginLeft: '20px',
                                marginTop: '10px',
                                background: ({ palette }) => palette.white,
                                maxHeight: '800px',
                                borderRadius: '10px',
                              }}
                            >
                              <AnalysedTrends
                                tabValue={tabValue}
                                analysedTrends={analysedTrends}
                                setAnalysedTrends={setAnalysedTrends}
                                handleTabChange={handleTabChange}
                                addToExcludeList={addToExcludeList}
                                setTags={setTags}
                                tags={tags}
                                setHoveredCard={setHoveredCard}
                                logAnalysedTrends={logAnalysedTrends}
                                hoveredCard={hoveredCard}
                                hoverCardRef={hoverCardRef}
                                clickedCard={clickedCard}
                              />
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Box>
                  {generativeAIDropdown === CONCEPT_TAB &&
                  currentUserRequestCount < currentUserRequestLimit &&
                  !isConceptGenerated &&
                  pageView === 'new_concept' ? (
                    <>
                      <ConceptGenieMainContainer>
                        <Box
                          className="image_container"
                          style={{
                            width: '400px',
                            height: '400px',
                            display: 'flex',
                          }}
                        >
                          <img
                            src={ConceptGenieHomeImage}
                            width={450}
                            style={{ height: '100%', margin: 'auto' }}
                          />
                        </Box>
                        <p style={{ fontSize: '14px' }}>
                          Every concept is a glimpse, guiding the journey from
                          concept to reality.
                        </p>
                      </ConceptGenieMainContainer>
                    </>
                  ) : null}
                  {generativeAIDropdown === AI_IMAGE_TAB &&
                  monthlyUserRequestLimit > monthlyUserRequestCount &&
                  !imgTabImageUrl &&
                  pageView === 'new_concept' ? (
                    <>
                      <ConceptGenieMainContainer>
                        <Box
                          className="image_container"
                          style={{
                            width: '400px',
                            height: '400px',
                            display: 'flex',
                          }}
                        >
                          <img
                            src={ConceptGenieHomeImage}
                            width={450}
                            style={{ height: '100%', margin: 'auto' }}
                          />
                        </Box>
                        <p style={{ fontSize: '14px' }}>
                          Every concept is a glimpse, guiding the journey from
                          concept to reality.
                        </p>
                      </ConceptGenieMainContainer>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <SavedConcepts
                logOptionClicked={logOptionClicked}
                loading={loading}
                data={data}
                isEditOn={isEditOn}
                selectedRow={selectedRow}
                isViewOn={isViewOn}
                isDeleteDialogOpen={isDeleteDialogOpen}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDelete={handleDelete}
                resetIsEditOn={resetIsEditOn}
                resetViewOn={resetViewOn}
                handleEdit={handleEdit}
                refreshData={refreshData}
                allConceptsUrl={allConceptsUrl}
                fetchApi={fetchApi}
                tableData={savedConceptsTable}
                order={order}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
              />
            )}
          </Grid>

          <ExportConceptsDialog
            headerText="Export to Screen Winner!"
            open={isSWExportDialog}
            handleClose={() => {
              logSWExportDialog(
                'Cancel',
                data,
                selectedConceptsIds,
                prjTypeSWSelected,
              )
              onSWExportClose()
            }}
            handleConfirmClick={() => onSWExportConfirm(data)}
            radioSelected={prjTypeSWSelected}
            handleRadioChange={(value) => setPrjTypeSWSelected(value)}
            showLabel={!projectsListSW?.length}
            loading={isSWProjectsLoading}
          />
          <SWProjectsListDialog
            listItems={projectsListSW}
            headerText="Screen Winner Projects!"
            open={isSWPrjListDialog}
            handleClose={() => {
              logSWPrjListDialog(
                'Cancel',
                data,
                selectedConceptsIds,
                selectedSWPrj,
              )
              onSWPrjListClose()
            }}
            handleConfirmClick={() => onSWPrjListSave(data)}
            onGoBackClick={() => onSWPrjListGoBackClick(data)}
            radioSelected={selectedSWPrj}
            handleRadioChange={(value) => setSelectedSWPrj(value)}
            loading={isSWProjectsLoading}
          />
        </Grid>
      </Box>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const {
    screenWinner,
    conceptGenie,
    downloadSections,
    savedTrendRequester,
  } = state
  const {
    isShowSpinnerDownload,
    loadingRequests,
    showSingleLoader,
  } = downloadSections
  return {
    projectsList: savedTrendRequester.savedTrendList?._list,
    projectsListSW: screenWinner.projectsList,
    screenWinnerSubscription: screenWinner.subscribed,
    selectedConcepts: conceptGenie.selectedCGConcepts,
    isSWProjectsLoading:
      showSingleLoader && (isShowSpinnerDownload || loadingRequests),
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestProjectsList: () => dispatch(fetchSavedTrends()),
    fetchProjectsList: () => dispatch(fetchProjectsList()),
    setCGSelectedConcepts: (data, reset) =>
      dispatch(setCGSelectedConcepts(data, reset)),
    exportSelectedCncpts: (conceptDetails, projectId) =>
      dispatch(saveConceptDetails(conceptDetails, projectId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
