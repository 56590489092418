import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, Grid, Stack } from '@mui/material'
import * as actions from '../../store/actions/index'
import { lensTypes, PROJECT_VARIANT } from '../../const'
import axios from '../../services/networking/axios'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { amplify, getQueryParams } from '../../store/utility.js'
import Filter from '../../shared/components/Filter'
import ProductCategoryTop from '../../componentsmui/HomePagePartials/partials/ProductCategoryTop'
import TrendsCard from '../../shared/components/TrendsCard'
import ConsumerInsightsCard from '../../shared/components/ConsumerInsightesCard'
import BlogsWebsitesCard from '../../shared/components/BlogsWebsitesCard/index.js'
import OverlayLoader from '../../shared/components/OverlayLoader'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import { AmplitudeValues } from '../../store/amplitudeValues'
import _ from 'lodash'
import '../muiStyle.css'
import { PHASES } from '../../shared/utils/constants'
import { act } from '@testing-library/react'

const FOUR_YEAR_CAGR = 4
const TWO_YEAR_CAGR = 2

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.project.country,
      Category_Name: ampiUserData.project.category,
    }
    switch (ampiEvent) {
      case 'Clicked_Explore_Subcategory_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Subcategory_Name: ampiUserData.subCategory.name,
          Action: ampiUserData.action,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log('Error in ProductCategoryPage')
  }
}

const ingredientLens = lensTypes[0]
class ProductCategoryPage extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    projectid: PropTypes.number,
    projectList: PropTypes.any,
    productList: PropTypes.any,
    themeList: PropTypes.any,
    featuresDetails: PropTypes.object,
    projectListBuilder: PropTypes.shape({
      projectList: PropTypes.any,
      flattenedProducts: PropTypes.array,
      needsList: PropTypes.any,
      error: PropTypes.any,
      requestStartTime: PropTypes.any,
      shouldDelayUser: PropTypes.bool,
      userWaitTime: PropTypes.number,
      totalWaitTime: PropTypes.number,
      needGrowthData: PropTypes.array,
      trendsPhasesData: PropTypes.shape({
        graphPoints: PropTypes.array,
        midYPont: PropTypes.number,
        maxYPoint: PropTypes.number,
        sideX: PropTypes.number,
        otherDetails: PropTypes.shape({
          dataPoints: PropTypes.number,
        }),
      }),
      flags: PropTypes.shape({
        EmergingTrends: PropTypes.bool,
        TrendingThemes: PropTypes.bool,
        GrowingTrends: PropTypes.bool,
        MatureTrends: PropTypes.bool,
        ConsumerNeeds: PropTypes.bool,
        DormantTrends: PropTypes.bool,
        FadingTrends: PropTypes.bool,
        DecliningTrends: PropTypes.bool,
      }),
      trendTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
      trendDates: PropTypes.object,
      advSearchFilters: PropTypes.any,
    }),
    needGrowthData: PropTypes.array,
    trendsPhasesData: PropTypes.shape({
      graphPoints: PropTypes.array,
      midYPont: PropTypes.number,
      maxYPoint: PropTypes.number,
      sideX: PropTypes.number,
      otherDetails: PropTypes.shape({
        dataPoints: PropTypes.number,
      }),
    }),
    needsList: PropTypes.any,
    error: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.handleChangeTrendLens = this.handleChangeTrendLens.bind(this)
    this.handleChangeActiveSubCategories = this.handleChangeActiveSubCategories.bind(
      this,
    )
    this.handleChangeActiveBucketCategories = this.handleChangeActiveBucketCategories.bind(
      this,
    )
    this.handleLoaderStateChange = this.handleLoaderStateChange.bind(this)
    this.componentUpdation = this.componentUpdation.bind(this)
    this.getIngFamilyFilter = this.getIngFamilyFilter.bind(this)
    this.fetchTrendsV2 = this.fetchTrendsV2.bind(this)
    this.handleCagrChange = this.handleCagrChange.bind(this)
    this.getFilteredTrendIds = this.getFilteredTrendIds.bind(this)
    this.onIngFamilyStateChange = this.onIngFamilyStateChange.bind(this)
    this.getTrendsByLens = this.getTrendsByLens.bind(this)
    this.setAllFilteredTrendsList = this.setAllFilteredTrendsList.bind(this)
    this.updateIngredientFamilyFilter = this.updateIngredientFamilyFilter.bind(
      this,
    )
    this.handleRemoveAppliedIngFamily = this.handleRemoveAppliedIngFamily.bind(
      this,
    )
    this.componentInitialisation = this.componentInitialisation.bind(this)
    this.projectNeedCategory = 'All'
    this.pageId = `${parseInt(Math.random() * 255)}${new Date().getTime()}`
    this._operationKey = { and: 'all', or: 'any' }
    this._operations = [
      { name: 'all', id: 'all', displayValue: 'all', value: 'all' },
      { name: 'any', id: 'any', displayValue: 'any', value: 'any' },
    ]
    this._defaultCategoryKeys = {
      ingredient: 'defaultSelectCategory',
      theme: 'defaultThemeCategory',
      product: 'defaultProductCategory',
    }
    this._subCategoryTitle = {
      ingredient: 'Sub-category',
      theme: 'Theme type',
      product: 'Product format',
    }
    this._initialState = {
      appliedHistory: false,
      lensSelected: 'Ingredient',
      lensFilters: [],
      showApplyBtn: false,
      showSaveSelsBtn: false,
      savedFiltersExists: false,
      allTrendsList: null,
      maxLimitReached: '',
      activeSubCategories: {
        main: true,
        ingredient: false,
        product: false,
        theme: false,
      },
      activePillarCategories: {
        main: true,
        ingredient: false,
        product: false,
        theme: false,
      },
      lensSubcategoriesList: [],
      allCategoriesList: {},
      allPillarCategoriesList: {
        ingredient: [],
        theme: [],
        product: [],
      },
      selectedOperation: this._operations[1],
      advSearchSaveMsg: '',
      savedFilterId: null,
      highlightFilter: null,
      viewSearchFilter: false,
      selectedWsoSubCategory: '',
      maxTrendsShown: 0,
      maxTrends: 0,
      wsoSubCategories: [{ id: 'All', displayValue: 'All', name: 'All' }],
      appliedFilters: {
        ingredient: [],
        product: [],
        theme: [],
        themeSubCategory: null,
        productSubCategory: null,
        ingredientSubCategory: null,
        operation: null,
      },
      selectedCtx: [],
      urlError: false,
      error: false,
      loader: false,
      ingFamilyFilterAPIData: {},
      ingFamilyFilter: {},
      ingFamilyFilterInitialState: {},
      updatedAppliedIngFamily: {},
      ingFamilyAppliedFilterIds: [],
      allFilteredTrendsList: [],
      newVisualisationTrends: {},
      selectedCagr: FOUR_YEAR_CAGR,
    }
    this.state = this._initialState
  }

  getCountryCategory = (trendsList) => {
    const [, country, category] = (
      trendsList || { searchCriteria: [';'] }
    ).searchCriteria[0].split(';')
    return { country, category }
  }

  logSubCategory = (subCategory, action) => {
    const { projectList, productList, themeList } = this.props
    const ampiEvent = 'Clicked_Explore_Subcategory_Action'
    const { country, category } = this.getCountryCategory(
      projectList || productList || themeList,
    )
    const project = {
      country,
      category,
    }
    const ampiUserData = {
      subCategory,
      project,
      action,
    }
    amp(ampiEvent, ampiUserData)
  }

  toggleViewSearchFilter = () => {
    this.setState((prevState) => ({
      viewSearchFilter: !prevState.viewSearchFilter,
    }))
  }

  setQueryParams(param, value) {
    const queryParams = getQueryParams()
    queryParams.set(param, encodeURIComponent(value))
    this.props.history.replace(`?${queryParams.toString()}`)
  }

  getDecodedQueryParams(param, noRedirect = true) {
    const queryParams = getQueryParams().get(param)
    let decodedString = null
    try {
      decodedString = decodeURIComponent(queryParams)
    } catch (e) {
      console.log(e)
      if (!noRedirect) {
        this.props.history.replace(
          `/mui/product-category/${Number(this.props.match.params.projectId)}`,
        )
        window.location.reload()
        this.setState({ urlError: true })
      }
    }
    return queryParams ? decodedString : null
  }

  /**
   * Change trend lens and open the corresponding subcategory
   * @param {*} lensSelected
   * @param {*} callback
   */
  handleChangeTrendLens = (lensSelected, callback) => {
    if (lensSelected && lensSelected.list && lensSelected.list[0]) {
      const newActiveSubCategories = { ...this.state.activeSubCategories }
      this.props.saveFilter({
        lensFilter: lensSelected.list,
      })
      if (
        lensSelected.list[0] &&
        this.state.lensSelected !== lensSelected.list[0].displayValue
      ) {
        this.props.fecthWhiteSpaceOpportunityGraphDataReset([])
      }
      if (lensSelected.list.length === 1) {
        const lens = lensSelected.list[0].displayValue
        sessionStorage.setItem(
          'defaultSelectedLens',
          lensSelected.list[0].displayValue,
        )
        this.setQueryParams(
          'defaultSelectedLens',
          lensSelected.list[0].displayValue,
        )
        const projectId = Number(this.props.match.params.projectId)
        const { appliedFilters } = this.state
        const selectedCategory =
          appliedFilters[lens.toLowerCase() + 'SubCategory']
        const categorySelected =
          lens === 'Ingredient'
            ? selectedCategory
              ? selectedCategory.name
              : 'All'
            : 'All'
        if (this.projectNeedCategory !== categorySelected) {
          this.props.fetchProjectNeedCategories(projectId, categorySelected)
          this.projectNeedCategory = categorySelected
        }
        for (const lens in newActiveSubCategories) {
          newActiveSubCategories[lens] =
            lens === lensSelected.list[0].value.toLowerCase()
        }
        newActiveSubCategories.main = true
        this.setState(
          {
            lensSelected: lensSelected.list[0].value,
            lensDropdownChanged: true,
            activeSubCategories: newActiveSubCategories,
          },
          () => {
            if (callback) {
              callback()
            }
          },
        )
      }
    }
  }

  handleChangeActiveSubCategories(type, callback) {
    const newActiveSubCategories = { ...this.state.activeSubCategories }
    newActiveSubCategories.main = true
    newActiveSubCategories[type] = !this.state.activeSubCategories[type]
    this.setState(
      {
        activeSubCategories: newActiveSubCategories,
      },
      () => {
        if (callback) {
          callback()
        }
      },
    )
  }

  handleChangeActiveBucketCategories(type, callback) {
    const newActiveBucketCategories = { ...this.state.activePillarCategories }
    newActiveBucketCategories.main = true
    newActiveBucketCategories[type] = !this.state.activePillarCategories[type]
    this.setState(
      {
        activePillarCategories: newActiveBucketCategories,
      },
      () => {
        if (callback) {
          callback()
        }
      },
    )
  }

  setDefaultSelectedCategory() {
    let defaultSelectCategory = sessionStorage.getItem('defaultSelectCategory')
    if (!defaultSelectCategory) {
      defaultSelectCategory = sessionStorage.setItem(
        'defaultSelectCategory',
        'All',
      )
    }
    return defaultSelectCategory
  }

  checkAndSetSessionStorage(key, defaultValue) {
    const storedValue = sessionStorage.getItem(key)
    if (!storedValue) {
      sessionStorage.setItem(key, defaultValue)
      return defaultValue
    }
    return storedValue
  }

  strArrSort(arr, key) {
    return arr.sort((a, b) => a[key].localeCompare(b[key]))
  }

  /**
   * Initialisation of the component
   */
  componentInitialisation() {
    if (sessionStorage.getItem('URL_ERROR')) {
      this.setState({ urlError: true })
      sessionStorage.removeItem('URL_ERROR')
    }
    const projectID = Number(this.props.match.params.projectId)
    this.props.fecthFeaturesDetails(projectID)
    this.props.viewSavedAdvancedSearch(projectID)
    this.props.userSelectedProject(projectID)
    this.props.fetchAllTrends(projectID)
  }

  componentDidMount() {
    this.componentInitialisation()
  }

  getIngFamilyFilter(filterIds, lens, projectId) {
    if (filterIds && lens && projectId) {
      if (_.isEmpty(filterIds)) {
        this.setState({ ingFamilyFilterAPIData: {} })
      } else {
        actions
          .fetchTrendFamilyFilterItems(filterIds, lens, projectId)
          .then((result) => {
            this.setState({ ingFamilyFilterAPIData: result })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  getTrendsByLens(lens, trendListInitialState, subCatInitialState) {
    let trendsList = trendListInitialState
    let subcat = subCatInitialState
    switch (lens) {
      case 'Ingredient':
        trendsList =
          this.props.filteredTrendsList &&
          this.props.newfilteredTrends?.projectList
            ? this.props.newfilteredTrends.projectList
            : this.props.projectList
        if (
          !_.isEmpty(trendsList) &&
          !_.isEmpty(this.state.ingFamilyFilter.applied)
        ) {
          const trendsArray = trendsList.trends.map((obj) => {
            return {
              ...obj,
              allTrendResults: obj.allTrendResults.filter((trendDetailObj) =>
                this.state.ingFamilyAppliedFilterIds.includes(
                  trendDetailObj.id,
                ),
              ),
            }
          })
          trendsList = {
            ...trendsList,
            trends: trendsArray,
          }
        }
        if (subcat) {
          subcat = this.state.allCategoriesList.ingredient.filter(
            (opt) => opt.checked === true,
          )
        }
        break
      case 'Product':
        trendsList =
          this.props.filteredTrendsList &&
          this.props.newfilteredTrends?.productList
            ? this.props.newfilteredTrends.productList
            : this.props.productList
        if (subcat) {
          subcat = this.state.allCategoriesList.product.filter(
            (opt) => opt.checked === true,
          )
        }
        break
      case 'Theme':
        trendsList =
          this.props.filteredTrendsList &&
          this.props.newfilteredTrends?.themeList
            ? this.props.newfilteredTrends.themeList
            : this.props.themeList
        if (subcat) {
          subcat = this.state.allCategoriesList.theme.filter(
            (opt) => opt.checked === true,
          )
        }
        break
    }

    return [trendsList, subcat]
  }

  setAllFilteredTrendsList = (prevProps, prevState) => {
    if (
      this.props.filteredTrendsList !== prevProps.filteredTrendsList ||
      this.props.newfilteredTrends !== prevProps.newfilteredTrends ||
      this.state.ingFamilyFilter.applied !==
        prevState.ingFamilyFilter.applied ||
      this.state.lensSelected !== prevState.lensSelected ||
      this.state.ingFamilyAppliedFilterIds !==
        prevState.ingFamilyAppliedFilterIds
    ) {
      const [trendsList] = this.getTrendsByLens(this.state.lensSelected, null)
      this.setState({ allFilteredTrendsList: trendsList })
    }
  }

  onIngFamilyStateChange = (state) => {
    this.setState({ ingFamilyFilter: state })
  }

  handleRemoveAppliedIngFamily = (idsToRemove) => {
    this.setState({
      updatedAppliedIngFamily: _.omit(
        this.state.ingFamilyFilter.applied,
        idsToRemove,
      ),
    })
  }

  getFilteredTrendIds = (filteredTrendsList, noFilterAppliedTrends) => {
    if (filteredTrendsList?.projectList) {
      return Object.keys(filteredTrendsList.projectList).reduce(
        (parentAcc, assocKeys) => {
          return [
            ...parentAcc,
            ...(filteredTrendsList.projectList[assocKeys]?.trends
              ? filteredTrendsList.projectList[assocKeys].trends.reduce(
                  (acc, obj) => [
                    ...acc,
                    ...obj.allTrendResults.map(({ id }) => id),
                  ],
                  [],
                )
              : []),
          ]
        },
        [],
      )
    }
    return noFilterAppliedTrends?.trends?.reduce(
      (acc, obj) => [...acc, ...obj.allTrendResults.map(({ id }) => id)],
      [],
    )
  }

  updateIngredientFamilyFilter = (prevProps, prevState) => {
    const projectID = Number(this.props.match.params.projectId)
    if (
      this.state.ingFamilyFilter.applied !== prevState.ingFamilyFilter.applied
    ) {
      if (_.isEmpty(this.state.ingFamilyFilter)) {
        this.setState({ ingFamilyAppliedFilterIds: [] })
      } else {
        this.setState({
          ingFamilyAppliedFilterIds: Object.values(
            this.state.ingFamilyFilter.applied,
          ).reduce((acc, obj) => [...acc, ...obj.trends], []),
        })
      }
    }
    this.setAllFilteredTrendsList(prevProps, prevState)
    // Reset Ing Family on SubCategory Apply
    if (
      this.state.appliedFilters[`${ingredientLens.toLowerCase()}SubCategory`] &&
      !prevState.appliedFilters[`${ingredientLens.toLowerCase()}SubCategory`]
    ) {
      this.setState({ ingFamilyFilterAPIData: {} })
    }
    // Retaining state of IFF when changed lens from ing to other
    if (this.state.lensSelected === ingredientLens) {
      if (this.state.ingFamilyFilter !== prevState.ingFamilyFilter) {
        this.setState({
          ingFamilyFilterInitialState: this.state.ingFamilyFilter,
        })
      }
    }
    // Removing retained state of IFF when changed Smart Filter in other lenses
    if (this.state.lensSelected !== ingredientLens) {
      if (
        this.state.appliedFilters.ingredient !==
          prevState.appliedFilters.ingredient ||
        this.state.appliedFilters.product !==
          prevState.appliedFilters.product ||
        this.state.appliedFilters.operation !==
          prevState.appliedFilters.operation ||
        this.state.appliedFilters.theme !== prevState.appliedFilters.theme
      ) {
        this.setState({ ingFamilyFilterInitialState: {} })
      }
    }
    if (this.props.filteredTrendsList !== prevProps.filteredTrendsList) {
      // Used to set Ingredient Filter Dropdown items when applied any filters
      const filteredTrendIds = this.getFilteredTrendIds(
        this.props.filteredTrendsList,
        this.props.projectList,
      )
      // Reset Ing Family on Smart Filter Apply
      if (!prevProps.filteredTrendsList && this.props.filteredTrendsList) {
        this.setState({ ingFamilyFilterAPIData: {} })
      }
      if (this.state.lensSelected === ingredientLens) {
        this.getIngFamilyFilter(
          filteredTrendIds,
          `${this.state.lensSelected}s`,
          projectID,
        )
      }
    }
    if (this.props.projectList !== prevProps.projectList) {
      // Used to set Ingredient Filter Dropdown items when there are no applied filters
      const filteredTrendIds =
        this.props.projectList?.trends &&
        this.props.projectList.trends.reduce(
          (acc, obj) => [...acc, ...obj.allTrendResults.map(({ id }) => id)],
          [],
        )

      if (
        this.state.lensSelected === ingredientLens &&
        !_.isEmpty(this.props.projectList)
      ) {
        this.getIngFamilyFilter(
          filteredTrendIds,
          `${this.state.lensSelected}s`,
          projectID,
        )
      }
    }
  }

  /**
   * Component update function
   * @param {*} prevProps
   * @param {*} prevState
   * @returns
   */
  componentUpdation(prevProps, prevState) {
    // This check if dashboard is changed through breadcrumb and set all things to default.
    if (
      this.props.projectid &&
      prevProps.projectid &&
      this.props.projectid !== prevProps.projectid
    ) {
      this.props.resetProjectListBuilderExceptSubscription()
      this.componentInitialisation()
      sessionStorage.setItem(
        'defaultSelectedProject',
        Number(this.props.projectid),
      )
      sessionStorage.setItem('defaultSelectedLens', 'Ingredient')
      sessionStorage.setItem(
        'defaultTrendMaturity',
        '["Dormant","Emerging","Growing","Mature","Declining","Fading"]',
      )
      sessionStorage.setItem('defaultTabTrends', '0')
      sessionStorage.setItem('defaultSelectCategory', 'All')
      sessionStorage.setItem('defaultThemeCategory', 'All')
      sessionStorage.setItem('defaultProductCategory', 'All')
      sessionStorage.removeItem('filterRequest')
      this.setState(this._initialState)
      return
    }
    const projectID = Number(this.props.match.params.projectId)
    this.updateIngredientFamilyFilter(prevProps, prevState)
    try {
      const {
        lensSelected,
        selectedWsoSubCategory,
        allCategoriesList,
      } = this.state
      const lensKey = lensSelected[0].toLowerCase() + lensSelected.slice(1)
      if (this.props.projectList !== prevProps.projectList) {
        if (this.props.projectList) {
          const namePage = this.props.projectList.searchCriteria[0].split(
            ';',
          )[2]
          this.props.addBreadCrumb({
            type: 'ProjectList',
            productID: Number(this.props.match.params.projectId),
            name: namePage,
          })
          this.props.userSelectedProject(projectID)
        }
      }
      // Initialize the state with the data fetched and from history
      if (
        this.props.allTrendsList &&
        prevProps.allTrendsList !== this.props.allTrendsList
      ) {
        let prevFilterRequest = null
        try {
          prevFilterRequest = JSON.parse(this.getDecodedQueryParams('advSrch'))
        } catch (e) {
          console.log(e)
        }
        const { allTrendsList } = this.props
        const selectedCtx =
          JSON.parse(
            sessionStorage.getItem(
              `prjSrchCtx${this.getDecodedQueryParams('ctxId')}`,
            ),
          ) || []
        const filterOptions = []
        ;['ingredient', 'theme', 'product'].forEach(
          (lens) =>
            allTrendsList[lens] &&
            allTrendsList[lens].forEach((data) =>
              filterOptions.push({
                ...data,
                id: lens + data.id,
                type: lens,
                displayValue: data.name,
                // Consider both previous applied filters and contextual search selections
                checked:
                  (prevFilterRequest
                    ? !!prevFilterRequest[lens].find(
                        (trend) => trend.name === data.name,
                      )
                    : false) ||
                  (lens === 'theme' &&
                    !!selectedCtx.find((ctxName) => ctxName === data.name)),
              }),
            ),
        )
        const selectedOperation =
          (prevFilterRequest &&
            this._operations.find(
              (opt) =>
                opt.name === this._operationKey[prevFilterRequest.operation],
            )) ||
          this._operations[1]
        const subCategories = {
          ingredient: [],
          theme: [],
          product: [],
        }
        // Setting subcategory by lens
        ;[
          'ingredient_sub_category',
          'theme_sub_category',
          'product_sub_category',
        ].forEach(
          (subCat) =>
            allTrendsList[subCat] &&
            allTrendsList[subCat].forEach((data) => {
              if (data.name !== 'All') {
                const lens = subCat.split('_')[0]
                subCategories[lens].push({
                  ...data,
                  id: lens + data.id,
                  displayValue: data.name,
                  checked:
                    this.getDecodedQueryParams(
                      this._defaultCategoryKeys[lens],
                    ) === data.name,
                })
              }
            }),
        )
        const appliedFilters = { ...this.state.appliedFilters }
        // Updating applied filters
        if (prevFilterRequest) {
          Object.keys(appliedFilters).forEach((lens) => {
            // For only lens - ingredient , theme and product
            if (!lens.includes('SubCategory') && lens !== 'operation') {
              const matchedTrends = []
              prevFilterRequest[lens].forEach((trend) => {
                const matchedTrend = filterOptions.find(
                  (allTrend) =>
                    allTrend.id === trend.id && allTrend.name === trend.name,
                )
                if (matchedTrend) matchedTrends.push(matchedTrend)
              })
              appliedFilters[lens] = matchedTrends
            }
          })
          appliedFilters.operation =
            Object.keys(this._operationKey).find(
              (opt) => opt === prevFilterRequest.operation,
            ) || 'or'
        }
        appliedFilters.themeSubCategory =
          subCategories.theme.find((opt) => opt.checked) || null
        appliedFilters.ingredientSubCategory =
          subCategories.ingredient.find((opt) => opt.checked) || null
        appliedFilters.productSubCategory =
          subCategories.product.find((opt) => opt.checked) || null
        Object.keys(subCategories).forEach((lens) => {
          this.strArrSort(subCategories[lens], 'name')
        })
        const wsoSubCats = this.strArrSort(
          [
            ...subCategories[lensKey],
            { id: 'All', displayValue: 'All', name: 'All' },
          ],
          'name',
        ) || [{ id: 'All', displayValue: 'All', name: 'All' }]
        this.setState({
          appliedFilters,
          selectedOperation,
          allTrendsList: this.strArrSort(filterOptions, 'name'),
          allCategoriesList: subCategories,
          wsoSubCategories: wsoSubCats,
          selectedWsoSubCategory: wsoSubCats[0].name,
          appliedHistory: true,
          selectedCtx,
        })
        if (prevFilterRequest) {
          this.props.fetchFilteredTrends({
            project_id: projectID,
            operation: appliedFilters.operation,
            ingredient: appliedFilters.ingredient.map((trend) => trend.name),
            product: appliedFilters.product.map((trend) => trend.name),
            theme: appliedFilters.theme.map((trend) => trend.name),
            subcategory: appliedFilters.ingredientSubCategory
              ? appliedFilters.ingredientSubCategory.name
              : 'All',
          })
        }
      }
      // Change sub-categories on changing lens
      if (
        allCategoriesList[lensKey] &&
        prevState.lensSelected !== lensSelected
      ) {
        const wsoSubCats = this.strArrSort(
          [
            ...allCategoriesList[lensKey],
            { id: 'All', displayValue: 'All', name: 'All' },
          ],
          'name',
        ) || [{ id: 'All', displayValue: 'All', name: 'All' }]
        this.setState({
          wsoSubCategories: wsoSubCats,
          selectedWsoSubCategory: wsoSubCats[0].name,
        })
      }
      if (
        prevState.selectedWsoSubCategory !== selectedWsoSubCategory ||
        prevState.lensSelected !== lensSelected
      ) {
        // Fetch white space data according to lens and sub-category
        this.props.fecthWhiteSpaceOpportunityGraphData(
          projectID,
          lensSelected,
          selectedWsoSubCategory,
        )
      }
      // Saved searches section
      if (this.props.advSearchSaveMsgProp !== prevProps.advSearchSaveMsgProp) {
        this.setState({
          maxLimitReached: '',
          savedFilterId: null,
          advSearchSaveMsg: this.props.advSearchSaveMsgProp.data.savedUserQuery
            .id,
        })
        if (this.advSearchSaveMsgTimeout) {
          clearTimeout(this.advSearchSaveMsgTimeout)
        }
        this.advSearchSaveMsgTimeout = setTimeout(() => {
          this.setState({ advSearchSaveMsg: '' })
        }, 5000)
      }
      if (this.props.advSearchFilters !== prevProps.advSearchFilters) {
        if (
          this.props.advSearchFilters &&
          this.props.advSearchFilters.length > 0
        ) {
          this.setState({ savedFiltersExists: true })
        } else {
          this.setState({ savedFiltersExists: false })
        }
      }
      // Show/ hide the advanced search action buttons under these conditions
      if (
        (this.state.allTrendsList &&
          prevState.allTrendsList !== this.state.allTrendsList) ||
        prevState.allCategoriesList !== allCategoriesList ||
        prevState.appliedFilters !== this.state.appliedFilters
      ) {
        const { allTrendsList } = this.state
        let { showApplyBtn, showSaveSelsBtn } = this.state
        if (allTrendsList && allTrendsList.find((opt) => opt.checked)) {
          showSaveSelsBtn = true
        } else {
          showSaveSelsBtn = false
        }

        if (
          allTrendsList &&
          (Object.keys(allCategoriesList).find((lens) =>
            allCategoriesList[lens].find((opt) => opt.checked),
          ) ||
            allTrendsList.find((opt) => opt.checked) ||
            this.state.appliedFilters.operation)
        ) {
          showApplyBtn = true
        } else {
          showApplyBtn = false
        }
        this.setState({ showApplyBtn, showSaveSelsBtn })
      }

      // Handle state update from history if present.
      if (
        (this.props.featuresDetails &&
          prevProps.featuresDetails !== this.props.featuresDetails) ||
        (prevState.appliedHistory !== this.state.appliedHistory &&
          this.props.featuresDetails)
      ) {
        let prevFilterRequest = null
        let flag = false
        try {
          prevFilterRequest = !!Array.from(getQueryParams().keys()).reduce(
            (itr) => (itr += 1),
            0,
          )
        } catch (e) {
          console.log(e)
        }
        if (
          !prevFilterRequest &&
          prevState.appliedHistory === this.state.appliedHistory
        ) {
          flag = true
        } else if (prevFilterRequest && this.state.appliedHistory) {
          flag = true
        }
        if (this.state.appliedHistory) {
          this.setState(this.getLensFilters())
        }

        // Update session storage from url
        if (flag) {
          const {
            ingredient_feature, // eslint-disable-line
            theme_feature, // eslint-disable-line
            product_feature, // eslint-disable-line
          } = this.props.featuresDetails
          const prevSelectedSelectCategory = (
            (allCategoriesList.ingredient || []).find(
              (cat) =>
                cat.name ===
                this.getDecodedQueryParams('defaultSelectCategory', true),
            ) || { name: 'All' }
          ).name
          const prevSelectedThemeCategory = (
            (allCategoriesList.theme || []).find(
              (cat) =>
                cat.name ===
                this.getDecodedQueryParams('defaultThemeCategory', true),
            ) || { name: 'All' }
          ).name
          const prevSelectedProductCategory = (
            (allCategoriesList.product || []).find(
              (cat) =>
                cat.name ===
                this.getDecodedQueryParams('defaultProductCategory', true),
            ) || { name: 'All' }
          ).name
          sessionStorage.setItem(
            'defaultSelectCategory',
            prevSelectedSelectCategory,
          )
          sessionStorage.setItem(
            'defaultThemeCategory',
            prevSelectedThemeCategory,
          )
          sessionStorage.setItem(
            'defaultProductCategory',
            prevSelectedProductCategory,
          )
          let categorySelected = 'All'
          if (lensSelected === 'Ingredient') {
            // Need categories for Ingredient only can be fetched according
            // to sub category but for other lenses always choose 'All'
            categorySelected = prevSelectedSelectCategory
          }
          this.projectNeedCategory = categorySelected
          // eslint-disable-next-line
          if (ingredient_feature) {
            this.props.fetchIngredientTrendList(
              projectID,
              prevSelectedSelectCategory,
            )
          }
          // eslint-disable-next-line
          if (theme_feature) {
            this.props.fetchThemeTrendList(projectID, prevSelectedThemeCategory)
          }
          // eslint-disable-next-line
          if (product_feature) {
            this.props.fetchProductTrendList(
              projectID,
              prevSelectedProductCategory,
            )
          }
          this.props.fetchProjectNeedCategories(projectID, categorySelected)
        }
      }
    } catch (er) {
      console.log(er)
      this.props.history.replace(`/404`)
      window.location.reload()
    }
    this.fetchLensCtxSrchMapping(prevProps)
    this.setLocalStorageSerchCtx(prevState)
    this.fetchTrendPillars(prevProps)
  }

  componentDidUpdate(prevProps, prevState) {
    this.componentUpdation(prevProps, prevState)
  }

  fetchTrendPillars = (prevProps) => {
    const { subscriptionDetails } = this.props
    if (
      subscriptionDetails &&
      prevProps.subscriptionDetails !== subscriptionDetails &&
      subscriptionDetails.trend_pillar
    ) {
      this.props.getTrendPillarList()
    }
  }

  setLocalStorageSerchCtx(prevState) {
    const { selectedCtx } = prevState
    const { selectedCtx: currSelectedCtx } = this.state
    if (selectedCtx !== currSelectedCtx) {
      sessionStorage.setItem(
        `prjSrchCtx${this.pageId}`,
        JSON.stringify(currSelectedCtx),
      )
      this.setQueryParams('ctxId', this.pageId)
    }
  }

  fetchLensCtxSrchMapping(prevProps) {
    const { themeList, featuresDetails } = prevProps
    const {
      themeList: thLs,
      ctxSrchMapping,
      featuresDetails: featDetails,
    } = this.props
    const trendsList = this.props.themeList
    if (trendsList && featDetails) {
      const [country, category] = trendsList?.searchCriteria[0]
        .split(';')
        .slice(1)
      if (country && category) {
        if (
          (themeList !== thLs || featuresDetails !== featDetails) &&
          !ctxSrchMapping.theme &&
          featDetails.theme_feature
        ) {
          this.props.fetchCtxSrchMapping(country, category, 'theme')
          this.props.fetchBlogsAndWebsites(country, category)
        }
      }
    }
  }

  componentDidCatch() {
    this.setState({ error: true })
  }

  componentWillMount() {
    // Setting sessionStorage to default.
    const defaultProject = Number(this.props.match.params.projectId)
    const newActiveSubCategories = { ...this.state.activeSubCategories }
    const lensFromURL = getQueryParams(this.props).get('defaultSelectedLens')
    const defaultSelectedLens = lensFromURL || 'Ingredient'
    this.setDefaultSelectedCategory()
    let defaultSelectedProject = Number(
      sessionStorage.getItem('defaultSelectedProject'),
    )
    defaultSelectedProject = isNaN(defaultSelectedProject)
      ? 0
      : defaultSelectedProject
    if (
      lensTypes.indexOf(defaultSelectedLens) < 0 ||
      defaultProject !== defaultSelectedProject
    ) {
      sessionStorage.setItem('defaultSelectedLens', defaultSelectedLens)
      sessionStorage.setItem('defaultSelectedProject', Number(defaultProject))
      sessionStorage.setItem(
        'defaultTrendMaturity',
        '["Dormant","Emerging","Growing","Mature","Declining","Fading"]',
      )
      sessionStorage.setItem('defaultTabTrends', '0')
      sessionStorage.setItem('defaultSelectCategory', 'All')
      sessionStorage.setItem('defaultThemeCategory', 'All')
      sessionStorage.setItem('defaultProductCategory', 'All')
      sessionStorage.removeItem('filterRequest')
    }
    newActiveSubCategories[defaultSelectedLens.toLowerCase()] = true
    this.setState({
      lensSelected: defaultSelectedLens,
      maturityDropdownChanged: false,
    })
  }

  componentWillUnmount() {
    this.props.showFilterPanel(false)
    this.props.resetProjectDetails()
  }

  handleOutsideSavedFilterClick = (ev) => {
    if (ev.currentTarget === ev.target) {
      this.setState({ viewSearchFilter: false })
    }
  }

  handleWsoSubCategoryChange = (selectedWsoSubCategory) => {
    this.setState({ selectedWsoSubCategory })
  }

  productSelectedHandler = (product, lensSelected) => {
    const { projectid } = this.props
    const bc = {
      type: 'TrendProduct',
      productID: product.id,
      name: product.name,
    }

    this.props.userSelectedProduct(product.id)
    this.props.saveBreadcrumbData(bc)

    this.props.history.push(
      `/projectList/${projectid}/${lensSelected.toLowerCase()}/${product.id}`,
    )
  }

  handleSubCategorySelect = (id) => {
    const lensKey = this.state.lensSelected.toLowerCase()
    const newCategoriesList = { ...this.state.allCategoriesList }
    newCategoriesList[lensKey] = newCategoriesList[lensKey].map((option) => {
      let checked = option.id === id
      if (checked) {
        if (option.checked) {
          checked = false
        }
      }
      return { ...option, checked }
    })
    this.setState({ allCategoriesList: newCategoriesList })
  }

  handleSubBucketSelect = (id, lensKey) => {
    const newCategoriesList = { ...this.state.allPillarCategoriesList }
    newCategoriesList[lensKey] = newCategoriesList[lensKey].map((option) => {
      let checked = option.id === id
      if (checked) {
        if (option.checked) {
          checked = false
        }
      }
      return { ...option, checked }
    })
    this.setState({ allPillarCategoriesList: newCategoriesList })
  }

  handleOperationSelect = (ev) => {
    this.setState({ selectedOperation: { name: ev.target.value } })
  }

  handleTrendsSelectionChange = (id, reset = false, callback) => {
    let { allTrendsList, selectedCtx } = this.state
    if (reset) {
      this.setState({
        selectedCtx: [],
        allTrendsList: allTrendsList.map((option) => ({
          ...option,
          checked: false,
        })),
      })
      return
    }
    allTrendsList = [...allTrendsList]
    const allSelectionsCount = {
      ingredient: 0,
      product: 0,
      theme: 0,
    }
    let checkOptionIndex = null
    allTrendsList.forEach((option, index) => {
      if (option.id === id) {
        checkOptionIndex = index
      }
      if (option.checked) {
        allSelectionsCount[option.type] += 1
      }
    })
    const checkOption = allTrendsList[checkOptionIndex]
    allTrendsList.splice(checkOptionIndex, 1, {
      ...checkOption,
      checked: !checkOption.checked,
    })
    if (checkOption.checked) {
      selectedCtx = selectedCtx.filter(
        (ctxName) => ctxName !== checkOption.name,
      )
      this.setState({ selectedCtx })
    }
    this.setState({ allTrendsList, maxLimitReached: '' }, () => {
      // this.handleApplyFilters()
      if (callback) {
        callback()
      }
    })
  }

  // Contextual search
  handleChangeCtx = (ctxName, checked) => {
    const { selectedCtx } = this.state
    const { ctxSrchMapping } = this.props
    if (
      ctxSrchMapping.theme &&
      (ctxSrchMapping.theme[ctxName.toUpperCase()] || []).length
    ) {
      if (!checked) {
        this.setState({
          selectedCtx: selectedCtx.filter((name) => name !== ctxName),
        })
      } else {
        this.setState({
          selectedCtx: Array.from(new Set([ctxName, ...selectedCtx])),
        })
      }
    }
  }

  // Contextual search select all
  handleToggleAllCntxtSrchTrends = (checked, selectedCtx) => {
    const { allTrendsList } = this.state
    const { ctxSrchMapping } = this.props
    const matchedSrchList = selectedCtx.reduce(
      (acc, ctxName) => [
        ...acc,
        ...(ctxSrchMapping.theme[ctxName.toUpperCase()] || []),
      ],
      [],
    )
    if (!checked) {
      const deselectCtx = selectedCtx.filter(
        (ctxName) => !matchedSrchList.find(({ name }) => ctxName === name),
      )
      this.setState({ selectedCtx: deselectCtx })
    }
    this.setState({
      allTrendsList: allTrendsList.map((trnd) => {
        if (
          matchedSrchList.length &&
          matchedSrchList.find((ctx) => `theme${ctx.id}` === trnd.id)
        ) {
          return { ...trnd, checked }
        }
        return trnd
      }),
    })
  }

  // Contextual search multi-select
  selectMultiCtx = (ctxNames) => {
    const { selectedCtx } = this.state
    const { ctxSrchMapping } = this.props
    if (ctxSrchMapping.theme) {
      const matchedNames = ctxNames.reduce(
        (existingCtx, name) =>
          (ctxSrchMapping.theme[name.toUpperCase()] || []).length
            ? [name, ...existingCtx]
            : existingCtx,
        [],
      )
      this.setState({
        selectedCtx: Array.from(new Set([...matchedNames, ...selectedCtx])),
      })
    }
  }

  handleRemoveSelectedTrend = (id, lensKey) => {
    let { allTrendsList, appliedFilters } = this.state
    appliedFilters[lensKey] = appliedFilters[lensKey].filter(
      (trend) => trend.id !== id,
    )
    allTrendsList = [...allTrendsList]
    const checkOptionIndex = allTrendsList.findIndex(
      (option) => option.id === id,
    )
    const checkOption = allTrendsList[checkOptionIndex]
    allTrendsList.splice(checkOptionIndex, 1, {
      ...checkOption,
      checked: false,
    })
    this.handleChangeCtx(allTrendsList[checkOptionIndex].name, false)
    this.setState({ allTrendsList, appliedFilters }, () =>
      this.handleApplyFilters(appliedFilters),
    )
  }

  handleRemoveCategory = (lens, id) => {
    const { appliedFilters, allCategoriesList } = this.state
    const lensKey = lens.toLowerCase()
    const selectedCategory = allCategoriesList[lensKey].find(
      ({ checked }) => checked,
    )
    this.logSubCategory(selectedCategory, 'Remove')
    appliedFilters[`${lensKey}SubCategory`] = null
    allCategoriesList[lensKey] = allCategoriesList[lensKey].map((opt) =>
      opt.id === id ? { ...opt, checked: false } : opt,
    )
    this.setState({ appliedFilters, allCategoriesList }, () =>
      this.handleApplyFilters(appliedFilters),
    )
    this.fetchTrendsV2('All')
  }

  handleResetAllFilters = () => {
    const { allTrendsList } = this.state
    this.setState(
      {
        selectedOperation: { name: 'any' },
        allTrendsList: allTrendsList.map((trend) => ({
          ...trend,
          checked: false,
        })),
        selectedCtx: [],
      },
      () => this.handleApplyFilters(),
    )
    const ampiEvent = `Clicked_Reset_Filter`
    const ampiUserData = {
      User: this.props.email,
    }
    amplify(ampiEvent, ampiUserData, this.props.email)
  }

  handleApplySavedFilter = (savedFilter) => {
    const {
      ingredients,
      themes,
      products,
      sub_category, // eslint-disable-line
      boolean_logic, // eslint-disable-line
      product_sub_category, // eslint-disable-line
      theme_sub_category, // eslint-disable-line
    } = savedFilter
    const { allTrendsList, allCategoriesList } = this.state

    // Store current filters
    const updatedFilters = {
      ingredient:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'ingredient')
            .filter((item) =>
              ingredients.find((trend) => trend === item.name),
            )) ||
        [],
      product:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'product')
            .filter((item) => products.find((trend) => trend === item.name))) ||
        [],
      theme:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'theme')
            .filter((item) => themes.find((trend) => trend === item.name))) ||
        [],
      themeSubCategory:
        (allCategoriesList &&
          allCategoriesList.theme &&
          allCategoriesList.theme.find(
            (item) => item.name === theme_sub_category, // eslint-disable-line
          )) ||
        null,
      productSubCategory:
        (allCategoriesList &&
          allCategoriesList.product &&
          allCategoriesList.product.find(
            (item) => item.name === product_sub_category, // eslint-disable-line
          )) ||
        null,
      ingredientSubCategory:
        (allCategoriesList &&
          allCategoriesList.ingredient &&
          allCategoriesList.ingredient.find(
            (item) => item.name === sub_category, // eslint-disable-line
          )) ||
        null,
      operation: boolean_logic, // eslint-disable-line
    }
    const allTrendsSelected = [...ingredients, ...themes, ...products]
    const newAllTrendsList = allTrendsList.map((trend) =>
      allTrendsSelected.find((tr) => tr === trend.name)
        ? { ...trend, checked: true }
        : trend,
    )
    const newAllCategoriesList = { ...allCategoriesList }

    // Handling checking of subcategory for currently selected state
    Object.keys(newAllCategoriesList).forEach((lens) => {
      switch (lens) {
        case 'ingredient':
          newAllCategoriesList[lens] = newAllCategoriesList[lens].map((cat) =>
            cat.name === sub_category // eslint-disable-line
              ? { ...cat, checked: true }
              : { ...cat, checked: false },
          )
          break
        case 'theme':
          newAllCategoriesList[lens] = newAllCategoriesList[lens].map((cat) =>
            cat.name === theme_sub_category // eslint-disable-line
              ? { ...cat, checked: true }
              : { ...cat, checked: false },
          )
          break
        case 'product':
          newAllCategoriesList[lens] = newAllCategoriesList[lens].map((cat) =>
            cat.name === product_sub_category // eslint-disable-line
              ? { ...cat, checked: true }
              : { ...cat, checked: false },
          )
          break
      }
    })
    this.selectMultiCtx(
      newAllTrendsList
        .filter(({ checked, type }) => checked && type === 'theme')
        .map(({ name }) => name),
    )
    this.setState(
      {
        appliedFilters: updatedFilters,
        allTrendsList: newAllTrendsList,
        allCategoriesList: newAllCategoriesList,
        selectedOperation: this._operations.filter(
          (opt) => this._operationKey[boolean_logic] === opt.name,
        )[0],
      },
      () => this.handleApplyFilters(updatedFilters),
    )
    this.toggleViewSearchFilter()
  }

  handleSubCategoryApply = (lens) => {
    const { appliedFilters, allCategoriesList } = this.state
    const selectedCategory = allCategoriesList[lens].find(
      ({ checked }) => checked,
    )
    const updatedFilters = {
      ...appliedFilters,
      [`${lens}SubCategory`]: selectedCategory || null,
    }
    this.setState({ appliedFilters: updatedFilters }, () =>
      this.handleApplyFilters(updatedFilters),
    )
    this.fetchTrendsV2(selectedCategory?.name)
    this.logSubCategory(selectedCategory, 'Apply')
  }

  getOperation = (name) => {
    return name === 'all' ? 'and' : 'or'
  }

  handleTrendFiltersApply = () => {
    const { appliedFilters, allTrendsList, selectedOperation } = this.state
    const ingredient = []
    const theme = []
    const product = []

    allTrendsList.forEach((opt) => {
      if (opt.checked) {
        const trendItem = { name: opt.name, id: opt.id }
        switch (opt.type) {
          case 'ingredient':
            ingredient.push(trendItem)
            break
          case 'theme':
            theme.push(trendItem)
            break
          case 'product':
            product.push(trendItem)
            break
        }
      }
    })
    const updatedFilters = {
      ...appliedFilters,
      ingredient,
      product,
      theme,
      operation: this.getOperation(selectedOperation.name),
    }
    this.setState({ appliedFilters: updatedFilters }, () =>
      this.handleApplyFilters(updatedFilters),
    )
  }

  handleApplyFilters = (updatedAppliedFilter) => {
    const projectId = Number(this.props.match.params.projectId)
    const ingredientsSelected = []
    const productsSelected = []
    const themesSelected = []
    const {
      themeSubCategory,
      productSubCategory,
      ingredientSubCategory,
      operation,
    } = this.state.appliedFilters
    const appliedFilters = {
      ingredient: [],
      theme: [],
      product: [],
      themeSubCategory,
      productSubCategory,
      ingredientSubCategory,
      operation,
    }
    const {
      selectedOperation,
      allCategoriesList,
      allTrendsList,
      lensSelected,
    } = this.state
    const lensKey = lensSelected.toLowerCase()
    // Adding selected trends
    allTrendsList.forEach((opt) => {
      if (opt.checked) {
        switch (opt.type) {
          case 'ingredient':
            ingredientsSelected.push(opt.name)
            appliedFilters.ingredient.push({ name: opt.name, id: opt.id })
            break
          case 'theme':
            themesSelected.push(opt.name)
            appliedFilters.theme.push({ name: opt.name, id: opt.id })
            break
          case 'product':
            productsSelected.push(opt.name)
            appliedFilters.product.push({ name: opt.name, id: opt.id })
            break
        }
      }
    })
    // Subcategory selected
    const subCatSelected = allCategoriesList.ingredient.find(
      (opt) => opt.checked,
    )
    let requestBody = {
      project_id: projectId,
      ingredient: ingredientsSelected,
      product: productsSelected,
      theme: themesSelected,
      operation: this.getOperation(selectedOperation.name),
      subcategory: subCatSelected ? subCatSelected.name : 'All',
    }
    appliedFilters.operation = requestBody.operation
    if (updatedAppliedFilter) {
      requestBody = {
        project_id: projectId,
        ingredient: updatedAppliedFilter.ingredient.map((trend) => trend.name),
        product: updatedAppliedFilter.product.map((trend) => trend.name),
        theme: updatedAppliedFilter.theme.map((trend) => trend.name),
        operation: updatedAppliedFilter.operation,
        subcategory: (
          updatedAppliedFilter.ingredientSubCategory || { name: 'All' }
        ).name,
      }
    }
    if (
      !requestBody.ingredient.length &&
      !requestBody.product.length &&
      !requestBody.theme.length
    ) {
      this.props.resetFilteredTrends()
      this.setQueryParams('advSrch', '')
      sessionStorage.removeItem('filterRequest')
    } else {
      // If requestBody contain atleast one of ingredient , product or theme
      if (updatedAppliedFilter) {
        const filters = {
          ingredient: updatedAppliedFilter.ingredient,
          product: updatedAppliedFilter.product,
          theme: updatedAppliedFilter.theme,
        }
        this.saveFilterParams(
          projectId,
          updatedAppliedFilter.ingredientSubCategory,
          filters,
        )
      } else {
        const filters = {
          ingredient: appliedFilters.ingredient,
          product: appliedFilters.product,
          theme: appliedFilters.theme,
        }
        this.saveFilterParams(projectId, subCatSelected, filters)
      }
      this.props.fetchFilteredTrends(requestBody)
    }

    // Selected subCategory by lens
    const ingSubCat = (updatedAppliedFilter
      ? updatedAppliedFilter.ingredientSubCategory
      : allCategoriesList.ingredient.find((opt) => opt.checked)) || {
      name: 'All',
    }
    const prodSubCat = (updatedAppliedFilter
      ? updatedAppliedFilter.productSubCategory
      : allCategoriesList.product.find((opt) => opt.checked)) || { name: 'All' }
    const themeSubCat = (updatedAppliedFilter
      ? updatedAppliedFilter.themeSubCategory
      : allCategoriesList.theme.find((opt) => opt.checked)) || { name: 'All' }

    // If defaultCategory from sessionstorage does not match update it.
    this.fetchTrendsOnSubCategoryChange(
      projectId,
      ingSubCat.name,
      prodSubCat.name,
      themeSubCat.name,
    )
    if (ingSubCat.name !== sessionStorage.getItem('defaultSelectCategory')) {
      appliedFilters.ingredientSubCategory =
        ingSubCat.name !== 'All' ? ingSubCat : null
    }
    if (prodSubCat.name !== sessionStorage.getItem('defaultProductCategory')) {
      appliedFilters.productSubCategory =
        prodSubCat.name !== 'All' ? prodSubCat : null
    }
    if (themeSubCat.name !== sessionStorage.getItem('defaultThemeCategory')) {
      appliedFilters.themeSubCategory =
        themeSubCat.name !== 'All' ? themeSubCat : null
    }

    if (!updatedAppliedFilter) {
      this.setState({ appliedFilters })
    }
    const selectedCategory = updatedAppliedFilter
      ? (updatedAppliedFilter[`${lensKey}SubCategory`] || { name: 'All' })
          .name || 'All'
      : subCatSelected
      ? subCatSelected.name
      : 'All'
    this.fetchNeedCategoriesOnSubCatChange(projectId, selectedCategory)
  }

  fetchNeedCategoriesOnSubCatChange = (projectId, category) => {
    const categorySelected =
      this.state.lensSelected === 'Ingredient' ? category : 'All'
    if (this.projectNeedCategory !== categorySelected) {
      this.props.fetchProjectNeedCategories(projectId, categorySelected)
      this.projectNeedCategory = categorySelected
    }
  }

  saveFilterParams = (projectId, subCatSelected, filters) => {
    const filterDetails = {
      ...filters,
      project_id: projectId,
      subcategory: subCatSelected?.name || 'All',
    }
    this.setQueryParams('advSrch', JSON.stringify(filterDetails))
    sessionStorage.setItem('filterRequest', JSON.stringify(filterDetails))
  }

  fetchTrendsOnSubCategoryChange = (
    projectId,
    ingSubCat,
    prodSubCat,
    themeSubCat,
  ) => {
    // If defaultCategory from sessionstorage does not match update it.
    if (ingSubCat !== sessionStorage.getItem('defaultSelectCategory')) {
      this.props.fetchIngredientTrendList(projectId, ingSubCat)
      sessionStorage.setItem('defaultSelectCategory', ingSubCat)
      this.setQueryParams('defaultSelectCategory', ingSubCat)
    }
    if (prodSubCat !== sessionStorage.getItem('defaultProductCategory')) {
      this.props.fetchProductTrendList(projectId, prodSubCat)
      sessionStorage.setItem('defaultProductCategory', prodSubCat)
      this.setQueryParams('defaultProductCategory', prodSubCat)
    }
    if (themeSubCat !== sessionStorage.getItem('defaultThemeCategory')) {
      this.props.fetchThemeTrendList(projectId, themeSubCat)
      sessionStorage.setItem('defaultThemeCategory', themeSubCat)
      this.setQueryParams('defaultThemeCategory', themeSubCat)
    }
  }

  viewModSavedFilter = (projectID) => {
    this.props.viewSavedAdvancedSearch(projectID)
  }

  handleGotoFilter = () => {
    this.setState({
      advSearchSaveMsg: '',
      viewSearchFilter: true,
      highlightFilter: 0,
    })
    if (this.gotoFilterTimeout) {
      clearTimeout(this.gotoFilterTimeout)
    }
    this.gotoFilterTimeout = setTimeout(() => {
      this.setState({ highlightFilter: null })
    }, 6000)
  }

  handleShowMatchedFilter = () => {
    this.setState(({ savedFilterId }) => ({
      savedFilterId: null,
      viewSearchFilter: true,
      highlightFilter: parseInt(savedFilterId, 10),
    }))
    if (this.gotoFilterTimeout) {
      clearTimeout(this.gotoFilterTimeout)
    }
    this.gotoFilterTimeout = setTimeout(() => {
      this.setState({ highlightFilter: null })
    }, 6000)
  }

  /**
   *  Handle saving of filters
   * @returns
   */
  handleSaveFilters = () => {
    const projectId = Number(this.props.match.params.projectId)
    const { allTrendsList, allCategoriesList, selectedOperation } = this.state
    const { advSearchFilters } = this.props
    // Currently selected filter details
    const requestBody = {
      name: '',
      ingredients:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'ingredient')
            .filter((opt) => opt.checked)
            .map((opt) => opt.name)) ||
        [],
      themes:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'theme')
            .filter((opt) => opt.checked)
            .map((opt) => opt.name)) ||
        [],
      products:
        (allTrendsList &&
          allTrendsList
            .filter((item) => item.type === 'product')
            .filter((opt) => opt.checked)
            .map((opt) => opt.name)) ||
        [],
      sub_category:
        (allCategoriesList &&
          allCategoriesList.ingredient &&
          (
            allCategoriesList.ingredient.find((opt) => opt.checked) || {
              name: 'All',
            }
          ).name) ||
        'All',
      product_sub_category:
        (allCategoriesList &&
          allCategoriesList.product &&
          (
            allCategoriesList.product.find((opt) => opt.checked) || {
              name: 'All',
            }
          ).name) ||
        'All',
      theme_sub_category:
        (allCategoriesList &&
          allCategoriesList.theme &&
          (
            allCategoriesList.theme.find((opt) => opt.checked) || {
              name: 'All',
            }
          ).name) ||
        'All',
      boolean_logic: selectedOperation.name === 'all' ? 'and' : 'or',
    }
    let savedFiltersExists = false
    // Set all applied filter seperated by ;
    const filterName = []
    const ingredientNames = requestBody.ingredients.slice(0, 3).join('; ')
    const productNames = requestBody.products.slice(0, 3).join('; ')
    const themeNames = requestBody.themes.slice(0, 3).join('; ')
    if (ingredientNames) {
      filterName.push(ingredientNames)
    }
    if (productNames) {
      filterName.push(productNames)
    }
    if (themeNames) {
      filterName.push(themeNames)
    }
    requestBody.name = filterName.join('; ')
    let filterId = null
    // Finding from list of already added filters
    for (let i = 0; i < advSearchFilters.length; i++) {
      const filter = advSearchFilters[i]
      let ingredientMatchCount = 0
      let themeMatchCount = 0
      let productMatchCount = 0
      requestBody.ingredients.forEach((reqFilter) => {
        if (
          filter.ingredients.find((savedFilter) => savedFilter === reqFilter)
        ) {
          ingredientMatchCount++
        }
      })
      requestBody.themes.forEach((reqFilter) => {
        if (filter.themes.find((savedFilter) => savedFilter === reqFilter)) {
          themeMatchCount++
        }
      })
      requestBody.products.forEach((reqFilter) => {
        if (filter.products.find((savedFilter) => savedFilter === reqFilter)) {
          productMatchCount++
        }
      })

      // Checking if applied filter already exist.
      if (
        filter['sub_category'] === requestBody['sub_category'] && // eslint-disable-line
        filter['theme_sub_category'] === requestBody['theme_sub_category'] && // eslint-disable-line
        filter['product_sub_category'] === // eslint-disable-line
          requestBody['product_sub_category'] && // eslint-disable-line
        requestBody.ingredients.length === filter.ingredients.length &&
        requestBody.themes.length === filter.themes.length &&
        requestBody.products.length === filter.products.length &&
        ingredientMatchCount === requestBody.ingredients.length &&
        themeMatchCount === requestBody.themes.length &&
        productMatchCount === requestBody.products.length &&
        filter['boolean_logic'] === requestBody['boolean_logic'] // eslint-disable-line
      ) {
        this.setState({
          savedFilterId: `${i}`,
          advSearchSaveMsg: '',
          maxLimitReached: '',
        })
        filterId = i
        if (this.savedFilterExistsTimeout) {
          clearTimeout(this.savedFilterExistsTimeout)
        }
        this.savedFilterExistsTimeout = setTimeout(
          () =>
            this.setState({
              savedFilterId: null,
              highlightFilter: null,
            }),
          5000,
        )
        savedFiltersExists = true
        break
      }
    }
    // If filter does not already exist save.
    if (!savedFiltersExists)
      this.props.saveAdvancedSearchFilter(projectId, requestBody)
    return { savedFiltersExists, filterId }
  }

  needsSelectedHandler = (need) => {
    const projectid = Number(this.props.match.params.projectId)
    this.props.userSelectedNeeds(need.id)
    const bc = {
      type: 'Need',
      needID: need.id,
      name: need.name,
      projectID: this.props.projectid,
    }
    // this.props.addBreadCrumb(bc)
    this.props.saveBreadcrumbData(bc)
    // window.open(`/projectList/${projectid}/needs/${need.id}`)
    this.props.history.push(`/projectList/${projectid}/needs/${need.id}`)
  }

  lensIsEmpty(trends) {
    let check = true
    for (const trend of trends) {
      for (const trendResultKey in trend) {
        if (
          typeof trend[trendResultKey] === 'object' &&
          trend[trendResultKey].length !== 0
        ) {
          check = false
          return check
        }
      }
    }
    return check
  }

  getLensFilters() {
    const featuresDetails = this.props.featuresDetails
    const lensFilters = []
    let lensSelected = 'Ingredient'

    // Check for lens feature and classification are available
    if (
      featuresDetails &&
      featuresDetails.theme_feature &&
      featuresDetails.theme_classification
    ) {
      lensFilters.push({
        id: 3,
        name: 'Theme',
        displayValue: 'Theme',
        value: 'Theme',
      })
      lensSelected = 'Theme'
    }

    if (
      featuresDetails &&
      featuresDetails.product_feature &&
      featuresDetails.product_classification
    ) {
      lensFilters.push({
        id: 2,
        name: 'Product',
        displayValue: 'Product',
        value: 'Product',
      })
      lensSelected = 'Product'
    }

    if (
      featuresDetails &&
      featuresDetails.ingredient_feature &&
      featuresDetails.ingredient_classification
    ) {
      lensFilters.push({
        id: 1,
        name: 'Ingredient',
        displayValue: 'Ingredient',
        value: 'Ingredient',
      })
      lensSelected = 'Ingredient'
    }
    // Check if selected dropdowns can be retained
    const defaultSelectedLens = getQueryParams().get('defaultSelectedLens')
    // sessionStorage.getItem('defaultSelectedLens');

    if (lensFilters && defaultSelectedLens) {
      if (lensFilters.find((lens) => lens.name === defaultSelectedLens)) {
        lensSelected = defaultSelectedLens
      } else {
        lensSelected = lensFilters[0].name
      }
    }
    return { lensFilters: lensFilters.reverse(), lensSelected } // [...] OR null
  }

  // Check if feature,classification,list and trend exist for atleast one lens
  dataExists(props) {
    const { featuresDetails } = props || this.props
    let exists = false

    if (featuresDetails) {
      if (
        featuresDetails.ingredient_feature &&
        featuresDetails.ingredient_classification
      ) {
        if (this.props.projectList && this.props.projectList.trends) {
          exists = true
        }
      } else {
        if (
          featuresDetails.theme_feature &&
          featuresDetails.theme_classification
        ) {
          if (this.props.themeList && this.props.themeList.trends) {
            exists = true
          }
        } else {
          if (
            featuresDetails.product_feature &&
            featuresDetails.product_classification
          ) {
            if (this.props.productList && this.props.productList.trends) {
              exists = true
            }
          }
        }
      }
    }
    return exists
  }

  handleChangeMaturityFilter = (changed) => {
    this.setState({ maturityDropdownChanged: changed })
  }

  setMaxDataPoints = (val) => {
    this.setState({ maxTrends: val })
  }

  handleSliderChange = (val) => {
    this.setState({ maxTrendsShown: val })
  }

  fetchTrendsV2 = (subCategory = null, cagrValue = null) => {
    const cagr = cagrValue || this.state.selectedCagr
    const subCategoryValue =
      subCategory ||
      this.state.appliedFilters[
        `${this.state.lensSelected.toLowerCase()}SubCategory`
      ]?.name ||
      'All'
    let trendsV2Req
    switch (this.state.lensSelected) {
      case 'Ingredient':
        trendsV2Req =
          NetworkingUtil.formSearchRequestForProject(this.props.projectid) +
          `?need_filter=${encodeURIComponent(
            subCategoryValue,
          )}&cagr_period=${cagr}&version=2.0`
        break
      case 'Product':
        trendsV2Req =
          NetworkingUtil.formSearchRequestForProductFilter(
            this.props.projectid,
          ) +
          `?need_filter=${encodeURIComponent(
            subCategoryValue,
          )}&cagr_period=${cagr}&version=2.0`
        break
      case 'Theme':
        trendsV2Req =
          NetworkingUtil.formSearchRequestForThemeFilter(this.props.projectid) +
          `?need_filter=${encodeURIComponent(
            subCategoryValue,
          )}&cagr_period=${cagr}&version=2.0`
    }
    this.setState({
      loader: true,
    })
    axios
      .get(trendsV2Req)
      .then((res) => {
        this.setState({
          newVisualisationTrends: res.data,
        })
        this.setState({
          loader: false,
        })
      })
      .catch((err) => {
        this.setState({
          loader: false,
        })
        console.log('Error while fetching viusalisation view trends', err)
      })
  }

  handleCagrChange = (e) => {
    const updatedCagr = e.target.checked ? FOUR_YEAR_CAGR : TWO_YEAR_CAGR
    this.setState({ selectedCagr: updatedCagr })
    this.fetchTrendsV2(null, updatedCagr)
  }

  handleLoaderStateChange = (newValue) => {
    this.setState({ loader: newValue })
  }

  /**
   * Handle downloading of excel
   * @param  tab
   */
  handleDownloadExcel = (tab, newVisTrends) => {
    this.setState({
      loader: true,
    })
    const {
      ingFamilyFilter: { applied: appliedIngFamilies },
    } = this.state
    let trends = []
    const level = this.props.assocLvl
    const [trendsList, subcat] = this.getTrendsByLens(
      tab,
      {
        searchCriteria: [';'],
      },
      [],
    )
    const ingFamilyFilter = appliedIngFamilies
      ? Object.values(appliedIngFamilies)
          .filter(({ childIds }) => childIds?.length)
          .map(({ label }) => label)
      : []

    const ingSubFamilyFilter = appliedIngFamilies
      ? Object.values(appliedIngFamilies)
          .filter(({ childIds }) => !childIds?.length)
          .map(({ label }) => label)
      : []

    const { country, category } = this.getCountryCategory(
      newVisTrends || trendsList,
    )
    let request = NetworkingUtil.downloadExcel()
    let excelFileName = `${country}_${category
      .split(' ')
      .join('_')}_${tab}s_data.xlsx`

    trends = trendsList.trends

    const filtersApplied = {
      trend_filter: {
        ingredient: this.state.appliedFilters.ingredient.map(
          (item) => item.name,
        ),
        product: this.state.appliedFilters.product.map((item) => item.name),
        theme: this.state.appliedFilters.theme.map((item) => item.name),
      },
      sub_category: subcat.map((item) => item.name),
      lens: tab,
      country,
      category,
      ingredient_family_filter: ingFamilyFilter,
      ingredient_sub_family_filter: ingSubFamilyFilter,
    }
    filtersApplied.association_level =
      filtersApplied.trend_filter.ingredient.length ||
      filtersApplied.trend_filter.product.length ||
      filtersApplied.trend_filter.theme.length
        ? level
            .filter((item) => item.checked)
            .map((i) => i.name[0].toUpperCase() + i.name.slice(1))
        : []

    let req = {}
    // Data required to send as request through api
    if (newVisTrends?.trends) {
      request = NetworkingUtil.downloadExcel('2.0')
      excelFileName = `${country}_${category} Download List View.xlsx`
      trends = newVisTrends.trends

      // Ingredient lens
      if (tab === lensTypes[0]) {
        // Exclude fs product for bpc
        if (this.props.projectVariant === PROJECT_VARIANT.BPC) {
          trends = trends.map((type, allTrendResults) => {
            return {
              type,
              allTrendResults: allTrendResults.map(
                ({
                  id,
                  name,
                  four_year_data_points: fourYearDataPoints,
                  two_year_data_points: twoYearDataPoints,
                  two_year_cagr_all: twoYearCagrAll,
                  four_year_cagr_all: fourYearCagrAll,
                  retail_products: retailProducts,
                  sub_category: subCategory,
                }) => ({
                  id,
                  name,
                  four_year_data_points: fourYearDataPoints,
                  two_year_data_points: twoYearDataPoints,
                  two_year_cagr: Number(twoYearCagrAll[subCategory]?.toFixed(2)) || 0,
                  four_year_cagr: Number(
                    fourYearCagrAll[subCategory]?.toFixed(2) || 0,
                  ),
                  retail_products: retailProducts,
                }),
              ),
            }
          })
        } else {
          trends = trends.map(({ type, allTrendResults }) => {
            return {
              type,
              allTrendResults: allTrendResults.map(
                ({
                  id,
                  name,
                  four_year_data_points: fourYearDataPoints,
                  two_year_data_points: twoYearDataPoints,
                  two_year_cagr_all: twoYearCagrAll,
                  four_year_cagr_all: fourYearCagrAll,
                  retail_products: retailProducts,
                  fs_products: fsProducts,
                  sub_category: subCategory,
                }) => ({
                  id,
                  name,
                  four_year_data_points: fourYearDataPoints,
                  two_year_data_points: twoYearDataPoints,
                  two_year_cagr: Number(twoYearCagrAll[subCategory]?.toFixed(2)) || 0,
                  four_year_cagr: Number(
                    fourYearCagrAll[subCategory]?.toFixed(2) || 0,
                  ),
                  retail_products: retailProducts,
                  fs_products: fsProducts,
                }),
              ),
            }
          })
        }
      } else {
        // for theme and product lens
        trends = trends.map(({ type, allTrendResults }) => {
          return {
            type,
            allTrendResults: allTrendResults.map(
              ({
                id,
                name,
                two_year_cagr: twoYearCagr,
                four_year_data_points: fourYearDataPoints,
                two_year_data_points: twoYearDataPoints,
                four_year_cagr: fourYearCagr,
              }) => ({
                id,
                name,
                two_year_cagr: Number(twoYearCagr?.toFixed(2)) || 0,
                four_year_data_points: fourYearDataPoints,
                two_year_data_points: twoYearDataPoints,
                four_year_cagr: Number(fourYearCagr?.toFixed(2)) || 0,
              }),
            ),
          }
        })
      }
      req = {
        trends,
        ...filtersApplied,
      }
    } else {
      req = {
        trends: trends.map(({ type, allTrendResults }) => ({
          type: type === 'Mature Trends' ? 'Matured Trends' : type,
          allTrendResults:
            tab === lensTypes[0]
              ? this.props.projectVariant === PROJECT_VARIANT.BPC // bpc won't contain fs product
                ? allTrendResults.map(
                    ({
                      id,
                      name,
                      data_point: dataPoint,
                      scoreRank,
                      two_year_cagr_all: twoYearCagrAll,
                      four_year_cagr_all: fourYearCagrAll,
                      retail_products: retailProducts,
                      trend_type: trendType,
                    }) => ({
                      id,
                      name,
                      rank: scoreRank,
                      data_point: dataPoint,
                      two_year_cagr: Number(
                        twoYearCagrAll[trendType[0]]?.toFixed(2),
                      ) || 0,
                      four_year_cagr: Number(
                        fourYearCagrAll[trendType[0]]?.toFixed(2),
                      ) || 0,
                      retail_products: retailProducts,
                    }),
                  )
                : allTrendResults.map(
                    ({
                      id,
                      name,
                      data_point: dataPoint,
                      scoreRank,
                      two_year_cagr_all: twoYearCagrAll,
                      four_year_cagr_all: fourYearCagrAll,
                      fs_products: fsProducts,
                      retail_products: retailProducts,
                      trend_type: trendType,
                    }) => ({
                          id,
                          name,
                          rank: scoreRank,
                          data_point: dataPoint,
                          two_year_cagr: Number(
                            twoYearCagrAll[trendType[0]]?.toFixed(2),
                          ) || 0,
                          four_year_cagr: Number(
                            fourYearCagrAll[trendType[0]]?.toFixed(2),
                          ) || 0,
                          fs_products: fsProducts,
                          retail_products: retailProducts,
                        })
                      
                  )
              : allTrendResults.map(
                  // theme and product lens won't contain fs product and retail product
                  ({
                    id,
                    name,
                    two_year_cagr: twoYearCagr,
                    data_point: dataPoint,
                    scoreRank,
                    four_year_cagr: fourYearCagr,
                  }) => ({
                    id,
                    name,
                    rank: scoreRank,
                    two_year_cagr: Number(twoYearCagr?.toFixed(2)) || 0,
                    data_point: dataPoint,
                    four_year_cagr: Number(fourYearCagr?.toFixed(2)) || 0,
                  }),
                ),
        })),
        ...filtersApplied,
        maturity_sort_order: [...PHASES],
      }
    }
    // Fetch excel data and download
    axios
      .post(request, req, { responseType: 'blob' })
      .then(({ data }) => {
        saveAs(data, excelFileName) // eslint-disable-line
        this.setState({
          loader: false,
        })
      })
      .catch((err) => {
        this.setState({
          loader: false,
        })
      })
  }

  render() {
    const {
      projectList,
      assocLvl,
      setAssocStrength,
      themeList,
      productList,
      newfilteredTrends,
      filteredTrendsList,
      loading,
      ctxSrchMapping,
    } = this.props
    const {
      allTrendsList,
      selectedOperation,
      appliedFilters,
      allCategoriesList,
      maxTrends,
      maxTrendsShown,
      allPillarCategoriesList,
      lensFilters,
      selectedCtx,
      loader,
    } = this.state
    const { country, category } = this.getCountryCategory(
      projectList || productList || themeList,
    )
    if (
      !this.props.allTrendsList ||
      (!projectList && !productList && !themeList)
    )
      return <OverlayLoader />

    const logBreadcrumbSelection = (project) => {
      const ampiEvent = 'Clicked_Breadcrumb'
      const ampiUserData = {
        project,
      }
      amp(ampiEvent, ampiUserData)
    }

    return (
      <Fragment>
        {(loading || loader) && <OverlayLoader />}

        <Box>
          <Grid
            container
            spacing={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexBasis: 'auto',
              flexWrap: 'nowrap',
              flex: 1,
              flexShrink: 0,
            }}
          >
            <Grid item>
              <Box sx={{ ml: -3, mb: 3 }}>
                {allTrendsList && (
                  <Filter
                    activeSubCategories={this.state.activeSubCategories}
                    handleChangeActiveSubCategories={
                      this.handleChangeActiveSubCategories
                    }
                    activePillarCategories={this.state.activePillarCategories}
                    handleChangeActiveBucketCategories={
                      this.handleChangeActiveBucketCategories
                    }
                    handleChangeTrendLens={this.handleChangeTrendLens}
                    prjId={Number(this.props.match.params.projectId)}
                    maxTrends={maxTrends}
                    uptTrends={allTrendsList}
                    selectedOperation={selectedOperation.name}
                    handleOperationSelect={this.handleOperationSelect}
                    trends={this.props.allTrendsList}
                    filteredTrends={this.props.filteredTrends}
                    handleTrendsSelectionChange={
                      this.handleTrendsSelectionChange
                    }
                    handleSubCategorySelect={this.handleSubCategorySelect}
                    handleSubBucketSelect={this.handleSubBucketSelect}
                    allCategoriesList={allCategoriesList}
                    allPillarCategoriesList={allPillarCategoriesList}
                    handleSliderChange={this.handleSliderChange}
                    category={category}
                    country={country}
                    handleApplyFilters={this.handleTrendFiltersApply}
                    handleApplySavedFilter={this.handleApplySavedFilter}
                    showSaveSelsBtn={this.state.showSaveSelsBtn}
                    handleSaveFilters={this.handleSaveFilters}
                    selectedCtx={selectedCtx}
                    handleResetAllFilters={this.handleResetAllFilters}
                    handleChangeCtx={this.handleChangeCtx}
                    ctxSrchMapping={ctxSrchMapping}
                    lensToShow={lensFilters}
                    handleToggleAllCntxtSrchTrends={
                      this.handleToggleAllCntxtSrchTrends
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs
              sx={{
                overflow: 'auto',
              }}
            >
              <Stack spacing={2} mb={3}>
                <ProductCategoryTop
                  category={category}
                  country={country}
                  logBreadcrumbSelection={logBreadcrumbSelection}
                  dataPoints={this.props.dataPoints}
                  projectVariant={this.props.projectVariant}
                  refreshedTime={
                    (
                      this.props.projectList ||
                      this.props.productList ||
                      this.props.themeList
                    )?.refreshed_time // eslint-disable-line
                  }
                />

                {allTrendsList && this.state.allFilteredTrendsList && (
                  <TrendsCard
                    vizMode={{ default: 'old', isOverride: true }}
                    ingFamilyFilter={this.state.ingFamilyFilter}
                    ingFamilyFilterInitialState={
                      this.state.ingFamilyFilterInitialState
                    }
                    updatedAppliedIngFamily={this.state.updatedAppliedIngFamily}
                    ingFamilyFilterAPIData={this.state.ingFamilyFilterAPIData}
                    onIngFamilyStateChange={this.onIngFamilyStateChange}
                    handleRemoveAppliedIngFamily={
                      this.handleRemoveAppliedIngFamily
                    }
                    handleChangeActiveSubCategories={
                      this.handleChangeActiveSubCategories
                    }
                    activeSubCategories={this.state.activeSubCategories}
                    handleSubCategorySelect={this.handleSubCategorySelect}
                    handleSubCategoryApply={this.handleSubCategoryApply}
                    allCategoriesList={allCategoriesList}
                    showTransitionIcon
                    trends={this.state.allFilteredTrendsList.trends}
                    handleChangeTrendLens={this.handleChangeTrendLens}
                    lensSelected={this.state.lensSelected}
                    handleDownloadExcel={this.handleDownloadExcel}
                    setAssocStrength={setAssocStrength}
                    handleRemoveSelectedTrend={this.handleRemoveSelectedTrend}
                    showAssocLvl={!!filteredTrendsList}
                    appliedFilters={appliedFilters}
                    setMaxDataPoints={this.setMaxDataPoints}
                    assocLvl={assocLvl}
                    projectid={this.props.projectid}
                    excelDownloadCount={
                      this.props.subscriptionDetails
                        ?.classified_trends_download_count || 20 // eslint-disable-line
                    }
                    ingredientList={
                      filteredTrendsList
                        ? newfilteredTrends && newfilteredTrends.projectList
                          ? newfilteredTrends.projectList
                          : projectList
                        : projectList
                    }
                    productList={
                      filteredTrendsList
                        ? newfilteredTrends && newfilteredTrends.productList
                          ? newfilteredTrends.productList
                          : productList
                        : productList
                    }
                    themeList={
                      filteredTrendsList
                        ? newfilteredTrends && newfilteredTrends.themeList
                          ? newfilteredTrends.themeList
                          : themeList
                        : themeList
                    }
                    lensToShow={lensFilters}
                    maxTrendsShown={maxTrendsShown}
                    category={category}
                    country={country}
                    handleRemoveCategory={this.handleRemoveCategory}
                    newVisualisationTrends={this.state.newVisualisationTrends}
                    setNewVisualisationTrends={
                      this.state.setNewVisualisationTrends
                    }
                    fetchTrendsV2={this.fetchTrendsV2}
                    selectedCagr={this.state.selectedCagr}
                    handleCagrChange={this.handleCagrChange}
                    handleLoaderStateChange={this.handleLoaderStateChange}
                  />
                )}
                {this.props.needCategories && (
                  <ConsumerInsightsCard
                    projectid={this.props.projectid}
                    needCategories={this.props.needCategories}
                    category={category}
                    country={country}
                    lensSelected={this.state.lensSelected}
                  />
                )}
                {this.props.projectVariant === PROJECT_VARIANT.BPC && (
                  <BlogsWebsitesCard
                    projectid={this.props.projectid}
                    category={category}
                    country={country}
                    Title={'Hiii'}
                    Content={
                      'In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting... In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting...In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting...In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting...In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting...In recent times, copper peptides have gained immense popularity because of their amazing qualities in maintaining youthful and healthy skin. Moreover, it is interesting...   '
                    }
                    IngredientsNumber={1}
                    ProductsNumber={1}
                    ThemesNumber={1}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const projectID = Number(props.match.params.projectId)
  return {
    filter: state.chart.filter,
    projectid:
      state.userSelections.projectID === projectID
        ? state.userSelections.projectID
        : projectID,
    projectList: state.projectListBuilder.projectList,
    productList: state.projectListBuilder.productList,
    themeList: state.projectListBuilder.themeList,
    filteredTrendsList: state.projectListBuilder.filteredTrendsList,
    projectListBuilder: state.projectListBuilder,
    needGrowthData: state.projectListBuilder.needGrowthData,
    trendsPhasesData: state.projectListBuilder.trendsPhasesData,
    needsList: state.projectListBuilder.needsList,
    error: state.projectListBuilder.error,
    dataPoints: state.projectListBuilder.dataPoints,
    needCategories: state.projectListBuilder.needCategories,
    wsoGraphData: state.projectListBuilder.wsoGraphData,
    featuresDetails: state.projectListBuilder.featuresDetails,
    userLoggedIn: state.user.loggedIn.user,
    allTrendsList: state.projectListBuilder.allTrendsList,
    advSearchSaveMsgProp: state.projectListBuilder.savedAdvancedSearchId,
    advSearchFilters: state.projectListBuilder.advSearchFilters,
    newfilteredTrends: state.projectListBuilder.filterAssocLvlTrends,
    assocLvl: state.projectListBuilder.assocLevl,
    loading: state.downloadSections.loadingRequests,
    ctxSrchMapping: state.projectListBuilder.ctxSrchMapping,
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    projectVariant: state.projectListBuilder.projectList?.projectVariant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBlogsAndWebsites: (country, category) =>
      dispatch(actions.fetchBlogsAndWebsites(country, category)),
    fecthFeaturesDetails: (projectId) =>
      dispatch(actions.fecthFeaturesDetails(projectId)),
    fetchProjectTrendList: (projectid, defaultSelectCategory, lensSelected) =>
      dispatch(
        actions.fetchProjectList(
          projectid,
          defaultSelectCategory,
          lensSelected,
        ),
      ),
    fecthWhiteSpaceOpportunityGraphData: (
      projectid,
      lensSelected,
      subCategory,
    ) =>
      dispatch(
        actions.fecthWhiteSpaceOpportunityGraphData(
          projectid,
          lensSelected,
          subCategory,
        ),
      ),
    fecthWhiteSpaceOpportunityGraphDataReset: (data) =>
      dispatch(actions.fecthWhiteSpaceOpportunityGraphDataSuccess(data)),
    userSelectedProduct: (productid) =>
      dispatch(actions.userSelectedProduct(productid)),
    userSelectedNeeds: (needid) =>
      dispatch(actions.userSelectedProjectNeeds(needid)),
    setProductListHiddenConf: (value) => {
      dispatch(actions.setProductListHiddenConf(value))
    },
    userSelectedProject: (projectid) =>
      dispatch(actions.userSelectedProject(projectid)),
    addBreadCrumb: (breadCrumb) => {
      dispatch(actions.addBreadCrumb(breadCrumb))
    },
    saveFilter: (payload) => dispatch(actions.saveFilter(payload)),
    viewSavedAdvancedSearch: (payload) =>
      dispatch(actions.viewSavedAdvancedSearch(payload)),
    saveBreadcrumbData: (bc) => dispatch(actions.saveBreadCrumbData(bc)),
    fetchProjectNeedCategories: (projectid, defaultSelectCategory) =>
      dispatch(
        actions.fetchProjectNeedCategories(projectid, defaultSelectCategory),
      ),
    fetchAllTrends: (projectId) => dispatch(actions.fetchAllTrends(projectId)),
    fetchFilteredTrends: (request) =>
      dispatch(actions.fetchFilteredTrends(request)),
    fetchIngredientTrendList: (projectid, defaultSelectCategory) =>
      dispatch(
        actions.fetchIngredientTrendList(projectid, defaultSelectCategory),
      ),
    fetchThemeTrendList: (projectid, defaultSelectCategory) =>
      dispatch(actions.fetchThemeTrendList(projectid, defaultSelectCategory)),
    fetchProductTrendList: (projectid, defaultSelectCategory) =>
      dispatch(actions.fetchProductTrendList(projectid, defaultSelectCategory)),
    resetFilteredTrends: () => dispatch(actions.resetFilteredTrends()),
    showFilterPanel: (show) => dispatch(actions.showFilterPanel(show)),
    saveAdvancedSearchFilter: (pi, rb) =>
      dispatch(actions.saveAdvancedSearch(pi, rb)),
    setAssocStrength: (id) => dispatch(actions.setAssocStrength(id)),
    resetProjectDetails: () => dispatch(actions.resetProjectDetails()),
    fetchCtxSrchMapping: (country, category, lens) =>
      dispatch(actions.fetchCtxSrchMapping(country, category, lens)),
    getTrendPillarList: () => dispatch(actions.getTrendPillarforImporting()),
    resetProjectListBuilderExceptSubscription: () =>
      dispatch(actions.resetProjectListBuilderExceptSubscription()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(ProductCategoryPage, axios)),
)
