import styled from '@emotion/styled'
import React from 'react'

import { TextField, InputAdornment, Paper } from '@mui/material'

export const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
}))

export const StyledTextField = styled((props) => (
  <TextField
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {props.text ? props.text : null}
        </InputAdornment>
      ),
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-input': {
      paddingLeft: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    },
  },
}))
