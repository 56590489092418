import React, { useState } from "react";
import PropTypes from "prop-types";
import SWAccordian from "./SWAccordian";

const SWAccordianContainer = ({ heading, content }) => {
  const [show, setShow] = useState(false);
  const handleHeadingClick = () => {
    setShow((show) => !show);
  };
  return (
    <SWAccordian
      heading={show ? heading.expanded : heading.collapsed}
      content={show ? content : null}
      onHeadingClick={handleHeadingClick}
    />
  );
};

SWAccordianContainer.propTypes = {
  heading: PropTypes.shape({
    expanded: PropTypes.string,
    collapsed: PropTypes.string,
  }),
  content: PropTypes.string,
};

export default SWAccordianContainer;
