import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/axios'
import OverlayLoader from '../../shared/components/OverlayLoader'
import axiosClass from 'axios'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

let requestSource = axiosClass.CancelToken.source()

const cancelRequest = () => {
  if (requestSource) {
    requestSource.cancel()
  }
  requestSource = axiosClass.CancelToken.source()
}

let cancelRedirection = false

const DocsPage = () => {
  const location = useLocation()
  const handleDoc360Login = (redirectPath) => {
    const request = NetworkingUtil.documentation()
    axios
      .get(request, { cancelToken: requestSource.token })
      .then((res) => {
        if (!cancelRedirection) {
          window.location.replace(
            `${res.data.data.redirect_url}${
              redirectPath ? `&redirectUrl=${redirectPath}` : ''
            }`,
          )
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const redirectPath = queryParams.get('RedirectPath')
    handleDoc360Login(redirectPath)
  }, [location])

  useEffect(() => {
    cancelRedirection = false
    shouldLogRecentActivity(
      "Help doc",
      "Platform",
      `${location.pathname}${location.search}`,
    )
    return () => {
      cancelRequest()
      cancelRedirection = true
    }
  }, [])

  return <OverlayLoader />
}

export default DocsPage
