import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  StyledBox,
  BoxStyle,
  StyledBoxToDisplayCountry,
  BorderStyle,
  StyledGrid,
  GridStyling,
} from './style'
import formatNumber from '../../shared/utils/formatNumber'
import countriesList from '../../mocks/country'
import { Circle } from '@mui/icons-material'
import { getSignedFloat } from '../../store/utility'

function GlobalTrendSearchTable({
  globalTrendSearchInfo,
  selectedLens,
  handleTrendClicked,
}) {
  return globalTrendSearchInfo.results.map((data) => {
    const country = countriesList.find((item) => item.label === data.country)
    return (
      <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Grid container sx={{ background: ' white' }}>
          <Grid item xs={2}>
            <StyledBoxToDisplayCountry>
              <Grid
                sx={{
                  display: 'inline',
                  marginRight: '10px',
                }}
              >
                {country && (
                  <img
                    style={{
                      width: 60,
                      height: 30,
                      minWidth: 60,
                    }}
                    loading="lazy"
                    src={
                      country.img
                        ? country.img
                        : `https://flagcdn.com/w20/${country.code.toLowerCase()}.png`
                    }
                    srcSet={
                      country.img
                        ? country.img
                        : `https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`
                    }
                    alt={`Flag of ${country.label}`}
                  />
                )}
              </Grid>
              <Grid>
                <Typography variant="h4">{data.country}</Typography>
                <Typography
                  variant="caption"
                  color={(theme) => theme.palette.text.secondary}
                >
                  (Found in {data.categories.length} Categories)
                </Typography>
              </Grid>
            </StyledBoxToDisplayCountry>
          </Grid>
          <GridStyling>
            <Box
              sx={{
                minWidth: '150px',
              }}
            >
              <BoxStyle sx={BorderStyle}>Category</BoxStyle>
              <BoxStyle sx={BorderStyle}>Data Point</BoxStyle>
              <BoxStyle sx={BorderStyle}>Maturity Phase</BoxStyle>
              <BoxStyle
                sx={{
                  borderBottom: 'transparent ! important',
                  borderLeft: 'transparent !important',
                }}
              >
                4 Year CAGR
              </BoxStyle>
            </Box>
          </GridStyling>
          <StyledGrid>
            <StyledBox>
              {data.categories &&
                data.categories.map(
                  ({
                    categoryName,
                    dataPoints,
                    maturityPhase,
                    fourYearCagr,
                    projectId,
                    trendId,
                  }) => {
                    return (
                      <Box
                        sx={{
                          minWidth: '150px',
                          '&:hover': ({ palette }) => ({
                            border: `1px solid ${palette.primary.main}`,
                          }),
                        }}
                      >
                        <Link
                          style={{ color: 'black' }}
                          onClick={() => {
                            handleTrendClicked(
                              maturityPhase,
                              categoryName,
                              country,
                            )
                          }}
                          target="_blank"
                          to={`/mui/details/${projectId}/${selectedLens}/${trendId}`}
                        >
                          <BoxStyle
                            sx={{
                              fontWeight: 'bold',
                              borderRight: 'transparent !important',
                            }}
                          >
                            {' '}
                            {categoryName}
                          </BoxStyle>
                          <BoxStyle
                            sx={{
                              color: ({ palette }) => palette.secondary.main,
                              borderRight: 'transparent !important',
                            }}
                          >
                            {formatNumber(dataPoints)}
                          </BoxStyle>

                          <BoxStyle
                            sx={{
                              borderRight: 'transparent !important',
                            }}
                          >
                            {
                              <Circle
                                sx={{
                                  width: '15px',
                                  height: '15px',
                                  marginRight: '10px',
                                  color: ({ palette: { colors } }) =>
                                    colors[maturityPhase.toLowerCase()],
                                }}
                              />
                            }
                            {maturityPhase}
                          </BoxStyle>
                          <BoxStyle
                            sx={{
                              borderBottom: 'transparent ! important',
                              borderRight: 'transparent !important',
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                color: ({ palette }) =>
                                  fourYearCagr >= 0
                                    ? palette.success.dark
                                    : palette.error.dark,
                              }}
                            >{`${getSignedFloat(fourYearCagr, 2)}%`}</Box>
                          </BoxStyle>
                        </Link>
                      </Box>
                    )
                  },
                )}
            </StyledBox>
          </StyledGrid>
        </Grid>
      </Box>
    )
  })
}

export default GlobalTrendSearchTable
