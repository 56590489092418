import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import logo from '../../assets/images/TrendIcon/ai-palette--logo.svg'

import {
  Field, reduxForm,
} from 'redux-form'
import {
  renderField,
  renderFieldPassword,
} from '../UI/form/index'
import { email, required, passwordLength, contactNumberLength } from '../../validation'
import Spinner from '../../components/UI/Spinner/Spinner'
import { reduxFormConst } from '../../const'

class RegistrationForm extends React.Component {
  state = {
    step: 0,
    isShowPassword: false,
  }

  fields = {
    step0: {
      company: 'company',
      name: 'name',
      country: 'country',
      designation: 'designation',
      contactNumber: 'contactNumber',
    },
    step1: {
      email: 'email',
      password: 'password',
      confirmPassword: 'confirmPassword',
    },
  }

  /**
   * Next registration step
   * @param access {boolean} - If the fields are filled correctly
   */
  handleNextStep = (access) => () => {
    if (access) {
      this.props.clearAsyncError()
      this.props.clearSubmitErrors()
      this.setState({ step: 1 })
    } else {
      this.props.handleSubmit()
    }
  }

  /**
   * Prev registration step
   */
  handlePrevStep = () => {
    this.setState({ step: 0 })
  }

  /**
   * Show password in field
   */
  handleShowPassword = () => {
    this.setState(
      (prevState) => ({
        isShowPassword: !prevState.isShowPassword,
      })
    )
  }

  render () {
    const { step, isShowPassword } = this.state
    const { loginError, handleSubmit, fieldsError } = this.props
    const { step0, step1 } = this.fields

    const accessNextStep = (fieldsError[step0.company] || fieldsError[step0.contactNumber]) === undefined

    // Password field
    const classesEye = classNames([
      'glyphicon',
      'formControlFeedback',
      { 'glyphicon-eye-close': !isShowPassword },
      { 'glyphicon-eye-open': isShowPassword },
    ])
    const typeFieldPassword = isShowPassword ? 'text' : 'password'
    //* *
    const classesActiveStep0 = classNames([
      'registration__step',
      { 'registration__step-active': step === 0 },
    ])
    const classesActiveStep1 = classNames([
      'registration__step',
      { 'registration__step-active': step === 1 },
    ])
    return (
      <div className='login__wrapper'>

        {this.props.showSpinner ? <div className="sppinerSection"><Spinner/></div> : null}
        <div className='logo__wrapper'>
          <div className="col-md-1 logo">
            <img src={logo}/>
          </div>
        </div>
        <div className='container'>
          <div className='container__inner'>
            <div className='img__block'>
            </div>
            <div className='loginbox__block'>
              <div className='loginbox__inner'>
                <div className='login__box'>
                  <div className='box__heading'>
                    <span>SIGN UP TO AI PALETTE</span>
                    <p>Please enter your credentials to proceed.</p>
                  </div>
                  <div className='loginBoxBody'>
                    <div className="login-error">{loginError}</div>
                    <form key="loginForm" onSubmit={handleSubmit}>
                      <div className={classesActiveStep0}>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step0.company}
                            label="Company*"
                            type="text"
                            component={renderField}
                            className="form-control"
                            id="companyName"
                            placeholder="Company Name"
                            maxlength="80" size="30"
                            validate={[required]}
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step0.name}
                            label="Name"
                            type="text"
                            component={renderField}
                            className="form-control"
                            id="ownerName"
                            placeholder="Name"
                            size="50"
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step0.country}
                            label="Country"
                            type="text"
                            component={renderField}
                            className="form-control"
                            id="CountryName"
                            placeholder="Country"
                            size="50"
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step0.designation}
                            label="Designation"
                            type="text"
                            component={renderField}
                            className="form-control"
                            id="ownerName"
                            placeholder="Designation"
                            size="50"
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step0.contactNumber}
                            label="Contact number*"
                            type="text"
                            component={renderFieldPassword}
                            className="form-control"
                            id="ownerName"
                            placeholder="265 894563"
                            size="50"
                            validate={[required, contactNumberLength]}
                          />
                        </div>
                      </div>
                      <div className={classesActiveStep1}>
                        <div className='formGroup hasFeedback'>
                          <Field
                            name={step1.email}
                            label="Email Address"
                            type="text"
                            component={renderField}
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            maxlength="80" size="30"
                            autoComplete={'off'}
                            validate={[required, email]}
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <span className={classesEye} onClick={this.handleShowPassword}/>
                          <Field
                            name={step1.password}
                            label="Password"
                            type={typeFieldPassword}
                            component={renderField}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            size="50"
                            autoComplete={'new-password'}
                            validate={[required, passwordLength]}
                          />
                        </div>
                        <div className='formGroup hasFeedback'>
                          <span className={classesEye} onClick={this.handleShowPassword}/>
                          <Field
                            name={step1.confirmPassword}
                            label="Confirm Password"
                            type={typeFieldPassword}
                            component={renderField}
                            className="form-control"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            size="50"
                            autoComplete='current-password'
                            validate={[required, passwordLength]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        {
                          step === 1 && (
                            <>
                              <div className="col-xs-12">
                                <button type="submit" className="btn btn__blue loginpage">
                                  Sign up
                                </button>
                              </div>
                              <div className="col-xs-12 text-center">
                                <h5 className="registrationPara">Have an account?<Link
                                  to="/">Sign in</Link>
                                </h5>
                              </div>

                              <div className="col-xs-12">
                                <div className="customCheckbox">
                                  <input type="checkbox" id="agreement"/>
                                  <label htmlFor="agreement">I agree all statements
                                    <a href="#">terms of service</a>
                                  </label>
                                </div>
                              </div>
                            </>
                          )
                        }

                        <div className="col-xs-12">
                          {
                            step === 0
                              ? <div className="nextBtn"
                                onClick={this.handleNextStep(accessNextStep)}>
                                <span>Next</span>
                              </div>
                              : <div className="previousBtn"
                                onClick={this.handlePrevStep}>
                                <span>Previous</span>
                              </div>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RegistrationForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.registrationForm,
})(RegistrationForm)

export default RegistrationForm
