export const getBaseURL = () => process.env.REACT_APP_NEW_PLATFORM_BACKEND_URL
    
export const getRequestOptions = (token, isBearer=false) => {
    return isBearer ? {
        headers: {
            Authorization: `Bearer ${token}`
        },
    } : {
        headers: {
            Authorization: `${token}`,
        },
    };
}