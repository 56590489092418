import React from 'react'

import { StyledFab } from './styles'

const FloatingActionButton = (props) => {
  const { fabStyles, variant, size, handleClick, fabContent } = props

  return (
    <StyledFab
      sx={{ m: 'auto', ...fabStyles }}
      variant={variant || 'extended'}
      size={size || 'medium'}
      aria-label="ViewMore"
      onClick={handleClick}
    >
      {fabContent.text}&nbsp;
      {fabContent.icon}
    </StyledFab>
  )
}

export default FloatingActionButton
