import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

import { cognitiveSearch as cognitiveSearchActionTypes } from './actionTypes'

export const fetchUserDashboards = () => {
  return (dispatch) => {
    dispatch(dashboardsCSLoading())
    const url = NetworkUtil.getCSDashboards()
    axios
      .get(url)
      .then((response) => {
        dispatch(dashboardsCSSuccess(response.data?.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(dashboardsCSFailed(error))
      })
  }
}

export const updateAdvFiltersList = (advFiltersList) => {
  return (dispatch) => {
    dispatch(advFiltersListCSUpdate(advFiltersList))
  }
}

export const fetchFormatsList = (country, category) => {
  return (dispatch) => {
    dispatch(formatsListCSLoading())
    const url = NetworkUtil.getCSFormatsList(
      encodeURIComponent(country),
      encodeURIComponent(category),
    )
    axios
      .get(url)
      .then((response) => {
        dispatch(formatsListCSSuccess(response.data?.data.formats))
      })
      .catch((error) => {
        console.log(error)
        dispatch(formatsListCSFailed(error))
      })
  }
}

export const fetchAdvFiltersList = (country, category, formatsSelected) => {
  return (dispatch) => {
    dispatch(advFiltersListCSLoading())
    const url = NetworkUtil.getCSAdvFiltersList()
    const data = JSON.stringify({
      country: country,
      category: category,
      ...(formatsSelected?.length > 0 && { formats: formatsSelected }),
    })
    axios
      .post(url, data)
      .then((response) => {
        dispatch(advFiltersListCSSuccess(response.data?.data.trends))
      })
      .catch((error) => {
        console.log(error)
        dispatch(advFiltersListCSFailed(error))
      })
  }
}

export const fetchAdvFiltersAppliedTrendsList = (
  country,
  category,
  formatsSelected,
  advIngSelected,
  advProductsSelected,
  advThemesSelected,
) => {
  return (dispatch) => {
    dispatch(advFiltersAppliedTrendsListCSLoading())
    const url = NetworkUtil.getCSAdvFiltersAppliedTrendsList()
    const data = JSON.stringify({
      country: country,
      category: category,
      ...(formatsSelected?.length > 0 && { formats: formatsSelected }),
      ingredient: advIngSelected,
      product: advProductsSelected,
      theme: advThemesSelected,
    })
    axios
      .post(url, data)
      .then((response) => {
        dispatch(
          advFiltersAppliedTrendsListCSSuccess(response.data?.data.trends),
        )
      })
      .catch((error) => {
        console.log(error)
        dispatch(advFiltersAppliedTrendsListCSFailed(error))
      })
  }
}

export const fetchCSFilteredTrends = (
  country,
  category,
  formatsSelected,
  lensSelected,
) => {
  return (dispatch) => {
    const url = NetworkUtil.getCSFilteredTrends()
    dispatch(filteredTrendsCSLoading())
    const data = JSON.stringify({
      country: country,
      category: category,
      lens: lensSelected.toLowerCase(),
      ...(formatsSelected?.length > 0 && { formats: formatsSelected }),
    })
    axios
      .post(url, data)
      .then((response) => {
        dispatch(filteredTrendsCSSuccess(response.data?.data.trends))
      })
      .catch((error) => {
        console.log(error)
        dispatch(filteredTrendsCSFailed(error))
      })
  }
}

export const fetchCSCN = (country, category, formatsSelected) => {
  return (dispatch) => {
    dispatch(consumerNeedsCSLoading())
    const url = NetworkUtil.getSCN()
    const data = JSON.stringify({
      country: country,
      category: category,
      ...(formatsSelected?.length && { formats: formatsSelected }),
    })
    axios
      .post(url, data)
      .then((response) => {
        dispatch(consumerNeedsCSSuccess(response.data?.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(consumerNeedsCSFailed(error))
      })
  }
}

export const fetchTrendInfoDetails = (
  country,
  category,
  formatsSelected,
  lensSelected,
  trendID,
  typeOfData = 'formats_social_data',
  productType,
) => {
  return (dispatch) => {
    switch (typeOfData) {
      case 'format_products':
        if (lensSelected !== 'Theme') {
          if (productType === 'retail_product') {
            dispatch(retailProductsCSLoading())
          } else {
            dispatch(fsProductsCSLoading())
          }
        }
        break
      case 'formats_quotes':
        dispatch(consumerQuotesCSLoading())
        break
      default:
        dispatch(socialDataCSLoading())
    }
    let url
    const data = JSON.stringify({
      country: country,
      category: category,
      ...(formatsSelected?.length && { formats: formatsSelected }),
      ...(typeOfData === 'format_products' && { product_type: productType }),
      ...(typeOfData === 'formats_social_data' && { elastic_query: true }),
    })
    switch (typeOfData) {
      case 'format_products':
        switch (lensSelected) {
          case 'Product':
            url = NetworkUtil.getCSTrendInfoDetailsProducts(trendID, typeOfData) // Product
            break
          case 'Ingredient':
            url = NetworkUtil.getCSTrendInfoDetailsIngredients(
              trendID,
              typeOfData,
            ) // Ingredients
            break
          default:
            return
        }
        break
      case 'formats_quotes':
        switch (lensSelected) {
          case 'Product':
            url = NetworkUtil.getCSTrendInfoDetailsProducts(trendID, typeOfData) // Product
            break
          case 'Theme':
            url = NetworkUtil.getCSTrendInfoDetailsThemes(trendID, typeOfData) // Theme
            break
          case 'Ingredient':
            url = NetworkUtil.getCSTrendInfoDetailsIngredients(
              trendID,
              typeOfData,
            ) // Ingredients
            break
          default:
            return
        }
        break
      default:
        switch (lensSelected) {
          case 'Product':
            url = NetworkUtil.getCSTrendInfoDetailsProducts(trendID, typeOfData) // Product
            break
          case 'Theme':
            url = NetworkUtil.getCSTrendInfoDetailsThemes(trendID, typeOfData) // Theme
            break
          case 'Ingredient':
            url = NetworkUtil.getCSTrendInfoDetailsIngredients(
              trendID,
              typeOfData,
            ) // Ingredients
            break
          default:
            return
        }
    }
    axios
      .post(url, data)
      .then((response) => {
        function renameKeys(obj, newKeys) {
          const keyValues = Object.keys(obj).map((key) => {
            const newKey = newKeys[key] || key
            return { [newKey]: obj[key] }
          })
          return Object.assign({}, ...keyValues)
        }
        switch (typeOfData) {
          case 'format_products':
            if (productType === 'retail_product') {
              dispatch(retailProductsCSSuccess(response.data?.data))
            } else {
              dispatch(fsProductsCSSuccess(response.data?.data))
            }
            break
          case 'formats_quotes':
            dispatch(consumerQuotesCSSuccess(response.data))
            break
          default:
            const newKeys = {
              engagement_score: 'engagementScore',
            }
            const reqData = response.data?.data
            const graphData = reqData.social_data.map((obj, index) => {
              const modifiedObj = renameKeys(obj, newKeys)
              return {
                ...modifiedObj,
              }
            })
            reqData.social_data = graphData
            dispatch(socialDataCSSuccess(reqData))
        }
      })
      .catch((error) => {
        console.log(error)
        switch (typeOfData) {
          case 'format_products':
            if (productType === 'retail_product') {
              dispatch(retailProductsCSFailed(error))
            } else {
              dispatch(fsProductsCSFailed(error))
            }
            break
          case 'formats_quotes':
            dispatch(consumerQuotesCSFailed(error))
            break
          default:
            dispatch(socialDataCSFailed(error))
        }
      })
  }
}

const dashboardsCSSuccess = (dashboardsList) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.GET_DASHBOARDS_LIST
      ],
    dashboardsList,
  }
}
const dashboardsCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.GET_DASHBOARDS_LIST_FAILED
      ],
    error: error,
  }
}
const dashboardsCSLoading = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.GET_DASHBOARDS_LIST_LOADING
      ],
  }
}

const formatsListCSSuccess = (formatsListData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FORMATS_LIST
      ],
    formatsListData: formatsListData,
  }
}
const formatsListCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FORMATS_LIST_FAILED
      ],
    error: error,
  }
}
const formatsListCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FORMATS_LIST_LOADING
      ],
  }
}

export const advFiltersListCSSuccess = (advFiltersListData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST
      ],
    advFiltersListData: advFiltersListData,
  }
}
const advFiltersListCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_FAILED
      ],
    error: error,
  }
}
const advFiltersListCSUpdate = (advFiltersListData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_UPDATE
      ],
    advFiltersListDataUpdated: advFiltersListData,
  }
}
const advFiltersListCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_LOADING
      ],
  }
}

const advFiltersAppliedTrendsListCSSuccess = (
  advFiltersAppliedTrendsListData,
) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS
      ],
    advFiltersAppliedTrendsListData: advFiltersAppliedTrendsListData,
  }
}
const advFiltersAppliedTrendsListCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS_FAILED
      ],
    error: error,
  }
}
const advFiltersAppliedTrendsListCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS_LOADING
      ],
  }
}

const filteredTrendsCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS_LOADING
      ],
  }
}
const filteredTrendsCSSuccess = (filteredTrendsData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS
      ],
    filteredTrendsData: filteredTrendsData,
  }
}
const filteredTrendsCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS_FAILED
      ],
    error: error,
  }
}

const consumerNeedsCSSuccess = (consumerNeedsData) => {
  return {
    type: cognitiveSearchActionTypes.type[cognitiveSearchActionTypes.FETCH_CN],
    consumerNeedsData: consumerNeedsData,
  }
}
const consumerNeedsCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_CN_FAILED
      ],
    error: error,
  }
}
const consumerNeedsCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_CN_LOADING
      ],
  }
}

const socialDataCSSuccess = (socialGraphData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_GRAPH_DATA
      ],
    socialGraphData: socialGraphData,
  }
}
const socialDataCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_GRAPH_DATA_FAILED
      ],
    error: error,
  }
}
const socialDataCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_GRAPH_DATA_LOADING
      ],
  }
}

const fsProductsCSSuccess = (fsProductsData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FS_PRODUCTS
      ],
    fsProductsData: fsProductsData,
  }
}
const fsProductsCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FS_PRODUCTS_FAILED
      ],
    error: error,
  }
}
const fsProductsCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_FS_PRODUCTS_LOADING
      ],
  }
}

const retailProductsCSSuccess = (retailProductsData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS
      ],
    retailProductsData: retailProductsData,
  }
}
const retailProductsCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS_FAILED
      ],
    error: error,
  }
}
const retailProductsCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS_LOADING
      ],
  }
}

const consumerQuotesCSSuccess = (consumerQuotesData) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES
      ],
    consumerQuotesData: consumerQuotesData,
  }
}
const consumerQuotesCSFailed = (error) => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES_FAILED
      ],
    error: error,
  }
}
const consumerQuotesCSLoading = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES_LOADING
      ],
  }
}

export const resetCognitiveSearchState = () => {
  return {
    type:
      cognitiveSearchActionTypes.type[
        cognitiveSearchActionTypes.RESET_COGNITIVE_SEARCH_STATE
      ],
  }
}
