import styled from '@emotion/styled'
import Carousel from 'react-elastic-carousel'
import { Grid } from '@mui/material'

export const StyledCarousel = styled(Carousel)(({ theme, filterProductLength }) => ({
  '& .rec.rec-arrow-left': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-right': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-left:hover:enabled .rec.rec-arrow-right:hover:enabled': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 5px 3px ${theme.palette.secondary.dark}`,
  },

  '& .rec-dot_active': {
		visibility: !filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },

  '& .rec-dot_disable': {
		visibility: filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },
}))

export const StyledGrid = styled(Grid)(({ theme }) => ({
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  border: '1px solid transparent',
  cursor: 'pointer',
}))


