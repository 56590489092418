import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

import { myDashboard as myDashboardActionTypes } from './actionTypes'

export const fetchFavoritesTrendsList = () => {
  return (dispatch) => {
    dispatch(dashboardFavoritesTrendsLoading())
    const url = NetworkUtil.dashboardFavoritesTrends()
    axios
      .get(url)
      .then((response) => {
        dispatch(dashboardFavoritesTrendsSuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(dashboardFavoritesTrendsFailure(error))
      })
  }
}
export const fetchOtherFavoritesList = (setShowLoader) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesOtherFavorites()
    setShowLoader(true)
    axios
      .get(url)
      .then((response) => {
        setShowLoader(false)
        dispatch(getOtherFavoritesListSuccess(response.data))
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
        dispatch(getOtherFavoritesListFailure(error))
      })
  }
}

export const removeFromOtherFavorites = (
  name,
  lens,
  favouriteType,
  favouriteId,
  urlLink,
  setShowLoader,
) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesOtherFavorites()
    const data = {
      name: name,
      lens: lens,
      favourite_type: favouriteType,
      favourite_id: favouriteId,
      url: urlLink,
    }
    setShowLoader(true)
    return axios
      .delete(url, { data })
      .then(() => {
        setShowLoader(false)
        dispatch(fetchOtherFavoritesList())
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
      })
  }
}

export const fetchAddedOtherFavCheck = (
  name,
  lens,
  favouriteType,
  favouriteId,
  urlLink,
  setShowLoader,
  projectId,
) => {
  return (dispatch) => {
    const url = NetworkUtil.checkForAddedOtherFavorite(
      name,
      lens,
      favouriteType,
      favouriteId,
      urlLink,
      projectId,
    )
    setShowLoader(true)
    dispatch(checkAddedOtherFavouriteLoading())
    axios
      .get(url)
      .then((response) => {
        setShowLoader(false)
        dispatch(checkAddedOtherFavouriteSuccess(response.data))
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
        dispatch(checkAddedOtherFavouriteFailure(error))
      })
  }
}

export const fetchFavoritesTrendPillarList = (setShowLoader) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesTrendPillars()
    setShowLoader(true)
    return axios
      .get(url)
      .then((response) => {
        setShowLoader(false)
        dispatch(getFavoriteTrendPillarListSuccess(response.data))
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
        dispatch(getFavoriteTrendPillarListFailure(error))
      })
  }
}

export const addToFavoriteTrendPillar = (pillarId, pillarType, projectId) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesTrendPillars()
    const data = {
      trend_piller_id: pillarId,
      trend_piller_type: pillarType,
    }
    return axios
      .post(url, data)
      .then(() => {
        fetchAddedFavChecktrendPillar(pillarId, pillarType, projectId)
      })
      .catch((error) => {
        return error.response.status
      })
  }
}

export const removeFromFavoritesTrendPillar = (
  pillarId,
  pillarType,
  setShowLoader,
  projectId,
) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesTrendPillars()
    const data = {
      trend_piller_id: pillarId,
      trend_piller_type: pillarType,
    }
    setShowLoader(true)
    return axios
      .delete(url, { data })
      .then(() => {
        dispatch(fetchFavoritesTrendPillarList(setShowLoader))
        dispatch(fetchAddedFavChecktrendPillar(pillarId, pillarType, projectId))
        setShowLoader(false)
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
      })
  }
}

export const fetchAddedFavChecktrendPillar = (
  pillarId,
  pillarType,
  projectId,
) => {
  return (dispatch) => {
    const url = NetworkUtil.checkForAddedFavoriteTrendPillar(
      pillarId,
      pillarType,
      projectId,
    )
    dispatch(checkAddedFavouriteTrendPillarLoading())
    return axios
      .get(url)
      .then((response) => {
        dispatch(checkAddedFavouriteTrendPillarSuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(checkAddedFavouriteTrendPillarFailure(error))
      })
  }
}

export const fetchAddedFavCheck = (trendType, trendId, projectId) => {
  return (dispatch) => {
    dispatch(checkAddedFavouriteLoading())
    const url = NetworkUtil.checkForAddedFavorite(trendType, trendId, projectId)
    axios
      .get(url)
      .then((response) => {
        dispatch(checkAddedFavouriteSuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(checkAddedFavouriteFailure(error))
      })
  }
}

export const removeFromFavoritesTrends = (
  trendId,
  trendName,
  trendType,
  projectId,
) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesTrends()
    const data = {
      trend_id: trendId,
      trend_name: trendName,
      trend_type: trendType,
      project_id: projectId,
    }
    return axios
      .delete(url, { data })
      .then(() => {
        dispatch(fetchFavoritesTrendsList())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const addToFavoritesTrends = (
  trendId,
  trendName,
  trendType,
  projectId,
  frontEndPath,
  redirectionUrl,
) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesTrends()
    const data = {
      trend_id: trendId,
      trend_name: trendName,
      trend_type: trendType,
      project_id: projectId,
      frontend_path: frontEndPath,
      redirection_url: redirectionUrl,
    }
    return axios
      .post(url, data)
      .then(() => {
        console.log('Added to favorites')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const fetchTrendHighlightData = (
  projectId,
  lensSelected,
  subCategorySelected,
) => {
  return (dispatch) => {
    dispatch(dashboardTrendHighlightLoading())
    const url = NetworkUtil.dashboardTrendHighlightData(
      projectId,
      lensSelected,
      subCategorySelected,
    )
    axios
      .get(url)
      .then((response) => {
        dispatch(dashboardTrendHighlightSuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(dashboardTrendHighlightFailure(error))
      })
  }
}

export const fetchRecentActivity = () => {
  return (dispatch) => {
    dispatch(dashboardRecentActivityLoading())
    const url = NetworkUtil.dashboardRecentActivity()
    axios
      .get(url)
      .then((response) => {
        dispatch(dashboardRecentActivitySuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(dashboardRecentActivityFailure(error))
      })
  }
}

export const logRecentActivity = (title, activityType, redirectionUrl) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardRecentActivity()
    const data = {
      title: title,
      activity_type: activityType,
      url: redirectionUrl,
    }
    axios.post(url, data).then((response) => {
      console.log(response?.data?.message)
    })
  }
}
export const addToFavoritesOtherFavorites = (
  trendName,
  trendType,
  projectId,
  favoriteType,
  productId,
  breadcrumbs,
  reDirectionUrl,
  additionalInfo,
) => {
  return (dispatch) => {
    const url = NetworkUtil.dashboardFavoritesOtherFavorites()
    const data = {
      name: trendName,
      lens: trendType,
      project_id: projectId,
      favourite_type: favoriteType,
      favourite_id: productId,
      navigation: breadcrumbs,
      url: reDirectionUrl,
      additional_info: additionalInfo,
      analytical_data: null,
    }
    return axios
      .post(url, data)
      .then(() => {
        dispatch(
          fetchAddedOtherFavCheck(
            trendName,
            trendType,
            favoriteType,
            productId,
            reDirectionUrl,
          ),
          dispatch(fetchOtherFavoritesList()),
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const dashboardFavoritesTrendsLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_LOADING
      ],
  }
}
export const dashboardFavoritesTrendsSuccess = (favoritesTrendsData) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST
      ],
    favoritesTrendsData: favoritesTrendsData,
  }
}
export const dashboardFavoritesTrendsFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_FAILED
      ],
    error: error,
  }
}

export const dashboardRecentActivityLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_RECENT_ACTIVITY_LOADING
      ],
  }
}
export const dashboardRecentActivitySuccess = (recentActivity) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_RECENT_ACTIVITY_SUCCESS
      ],
    recentActivity: recentActivity,
  }
}
export const dashboardRecentActivityFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_RECENT_ACTIVITY_FAILED
      ],
    error: error,
  }
}

export const dashboardTrendHighlightLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA_LOADING
      ],
  }
}
export const dashboardTrendHighlightSuccess = (trendHighlightData) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA
      ],
    trendHighlightData: trendHighlightData,
  }
}
export const dashboardTrendHighlightFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA_FAILED
      ],
    error: error,
  }
}

export const checkAddedFavouriteLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECK_ADDED_FAVORITE_LOADING
      ],
  }
}
export const checkAddedFavouriteSuccess = (addedFav) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_SUCCESS
      ],
    addedFav: addedFav,
  }
}
export const checkAddedFavouriteFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_FAILURE
      ],
    error: error,
  }
}

export const getFavoriteTrendPillarListSuccess = (
  favoritesTrendPillarsData,
) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes
          .FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_SUCCESS
      ],
    favoritesTrendPillarsData: favoritesTrendPillarsData,
  }
}
export const getFavoriteTrendPillarListFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes
          .FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_FAILED
      ],
    error: error,
  }
}

export const checkAddedFavouriteTrendPillarLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECK_ADDED_FAVORITE_TREND_PILLAR_LOADING
      ],
  }
}
export const checkAddedFavouriteTrendPillarSuccess = (addedFavTrendPillar) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_SUCCESS
      ],
    addedFavTrendPillar: addedFavTrendPillar,
  }
}
export const checkAddedFavouriteTrendPillarFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_FAILED
      ],
    error: error,
  }
}

export const getOtherFavoritesListSuccess = (otherFavoritesData) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_SUCCESS
      ],
    otherFavoritesData: otherFavoritesData,
  }
}
export const getOtherFavoritesListFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_FAILED
      ],
    error: error,
  }
}

export const checkAddedOtherFavouriteLoading = () => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECK_ADDED_OTHER_FAVORITE_LOADING
      ],
  }
}
export const checkAddedOtherFavouriteSuccess = (addedOtherFav) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_OTHER_FAVORITE_SUCCESS
      ],
    addedOtherFav: addedOtherFav,
  }
}
export const checkAddedOtherFavouriteFailure = (error) => {
  return {
    type:
      myDashboardActionTypes.type[
        myDashboardActionTypes.FETCH_CHECKED_ADDED_OTHER_FAVORITE_FAILED
      ],
    error: error,
  }
}
