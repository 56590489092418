import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as A from '../../store/actions';
import {
  Stack, Typography, Tabs,
  Tab, Box, SvgIcon, CircularProgress
} from '@mui/material';
import ChangePasswordCard from '../../shared/components/ChangePasswordCard';
import TabPanel from '../../shared/components/TabPanel';
import PersonalInformationCard from '../../shared/components/PersonalInformationCard';
import { ReactComponent as PersonalInfoIcon } from '../../assets/icons/personal-information.svg';
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg';
import '../muiStyle.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProfilePage = (props) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    props.getUserProfile();
  }, []);

  if(!props.userProfile)
    return <CircularProgress size={16}/>
  return (
    <Stack
      spacing={3}
      sx={{ my: 3 }}>
      <Typography variant="h3">
        Profile Page
      </Typography>
      <Box>
        <Tabs value={tab} onChange={handleChange} aria-label="profile tabs">
          <Tab
            icon={<SvgIcon component={PersonalInfoIcon} />}
            label="Personal Information"
            {...a11yProps(0)}
          />
          <Tab
            icon={<SvgIcon component={PasswordIcon} />}
            label="Password"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <PersonalInformationCard
          loginUserDetails={props.loginUserDetails}
          user={props.userProfile.user}
          updateUserProfile={props.updateUserProfile}/>
      </TabPanel>
      <Typography>
      </Typography>
      <TabPanel value={tab} index={1}>
        <ChangePasswordCard 
        user = { props.userProfile.user }
        />
      </TabPanel>
    </Stack>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: () => {
    dispatch(A.getUserProfileOnServer())
  },
  updateUserProfile: async values => {
    delete values.name
    return dispatch(A.updateUserProfileOnServer(values))
  },
})

const mapStateToProps = (state) => {
  return {
    loginUserDetails: state.user.loggedIn.userDetails,
    showSpinner: state.application.showSpinner,
    userProfile: state.user.userProfile,
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage));
