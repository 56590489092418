import React from "react";

import Accordian from "../SWAccordian/SWAccordianContainer";

import expandPlus from "../../assets/images/screenWinner/sw-expand-plus.svg";
import collapseMinus from "../../assets/images/screenWinner/sw-collapse-minus.svg";
import foodCategoryTrends from '../../assets/images/faq/foodCategoryTrends.png'
import trendsPositionsInMap from '../../assets/images/faq/trendsPositionsInMap.png'
import subCategoriesOfTrend from '../../assets/images/faq/subCategoriesOfTrend.png'
import specificTrendType from '../../assets/images/faq/specificTrendType.png'
import trendsCategory from '../../assets/images/faq/trendsCategory.png'
import sortFeatures  from '../../assets/images/faq/sortFeatures.png'
import trendGrowthChart from '../../assets/images/faq/trendGrowthChart.png'
import marketTrend from '../../assets/images/faq/marketTrend.png'
import consumerInsights from '../../assets/images/faq/consumerInsights.png'
import consumerInsights2 from '../../assets/images/faq/consumerInsights2.png'
import consumerPersona from '../../assets/images/faq/consumerPersona.png'
import consumerGroup from '../../assets/images/faq/consumerGroup.png'
import consumerQuotes from '../../assets/images/faq/consumerQuotes.png'
import productAttribute from '../../assets/images/faq/productAttribute.png'
import foodProductTexture from '../../assets/images/faq/foodProductTexture.png'
import format from '../../assets/images/faq/format.png'
import taste from '../../assets/images/faq/taste.png'
import foodServiceProducts from '../../assets/images/faq/foodServiceProducts.png'
import foodCategory from '../../assets/images/faq/foodCategory.png'
import topicSection from '../../assets/images/faq/topicSection.png'
import searchSpecificTrend from '../../assets/images/faq/searchSpecificTrend.png'
import topicSearch  from '../../assets/images/faq/topicSearch.png'
import extraInfoRelToSpecificTopic from '../../assets/images/faq/extraInfoRelToSpecificTopic.png'
import relProdAndCat from '../../assets/images/faq/relProdAndCat.png'
import downloadChart from '../../assets/images/faq/downloadChart.png'
import downloadData from '../../assets/images/faq/downloadData.png'
import { useLocation} from 'react-router-dom'
import { useEffect } from "react";

import "./SWFAQ.scss";

const SWFAQ = () => {
  const location = useLocation();
  console.log(location);
  const qAndASections = [
    {
      topic: "Funtionalities related to Project",
      qAndA: [
        {
          question: "How to add a new Project?",
          answer:
            "A new project can be added by clicking on the “Add New Project” tile.",
        },
        {
          question: "How does the search work for Project?",
          answer:
            "Based on the project title and description. It won’t provide results for the concetps within the project.",
        },
        {
          question: "What does “Unscreened” mean on the Project tile?",
          answer:
            "Unscreened on a Project tile means that project is not yet Screened and results are not available. Or have added/edited a concept to the existing project.",
        },
        {
          question:
            "Is it mandatory to add attachments/files while adding a new project?",
          answer:
            "No, its optional. Attachments are not used in the process of screening.",
        },
        {
          question:
            "What are the file formats supported while uploading a file in a New Project?",
          answer:
            "The supported file formats are .txt, .csv, .xlsx, .xls, image files & any kind of text document.",
        },
        {
          question:
            "Can I edit a Project title and Project description after saving it? Will it impact the Screening Results?",
          answer:
            "Yes, both the Project title and description are editable. This will have no impact on the Screening results i.e. Relevance, Originality and Rank scores.",
        },
        {
          question: "Can I share the Project with other colleagues?",
          answer:
            "Yes, can be shared with the help “Share Link” button on the top right corner of the screen.",
        },
        {
          question: "Where can I see the results of Screened Project?",
          answer:
            "Can be seen below the search bar, only after a Project is screened by clicking on the “View Screened Results”.",
        },
      ],
    },
    {
      topic: "Functionalities related to Concept",
      qAndA: [
        {
          question:
            "Is it mandatory to add a Concept Description while adding a new Project?",
          answer: "Yes, it is mandatory to add Concept description.",
        },
        {
          question: "How does the search work for Concept?",
          answer: "This works on the Concept title and description.",
        },
        {
          question: "How to add a new Concept?",
          answer:
            "A new Concept can be added by going inside the project and clicking on the “Add new Concept” tile.",
        },
        {
          question:
            "Do my Relevance, Originality, and Rank change when a new concept is added or an existing concept is edited?",
          answer:
            "Yes, Relevance, Originality, and Rank change when a new concept is added or an existing concept is edited as they are relative to all the concepts within a project.",
        },
        {
          question:
            "Can I see my previous screened results after adding a New Concept or editing an existing one?",
          answer:
            "No, a Version history is not maintained at the Concept level.",
        },
        {
          question:
            "What are the file formats supported while uploading a file in a New Concept?",
          answer:
            "The supported file formats are .txt, .csv, .doc, .docx, .xlsx, .xls, image files & any kind of text document.",
        },
        {
          question: "Can I edit an existing/screened Concept?",
          answer:
            "Yes, the existing Concept can be edited but the Relevance, Originality, and Rank will be nullified and one will have to screen the project again to get the scores.",
        },
        {
          question:
            "Can I create a Clone of an existing Screened/Unscreened Concept?",
          answer:
            "Yes, an existing Screened/Unscreened Concept can be cloned by clicking on the “Clone” Button that comes down as a drop down when clicked on 3-dots at the Top right corner of the each Concept Tile.",
        },
        {
          question: "Can I delete a Screened/Unscreened Concept?",
          answer:
            "Yes, a Screened/Unscreened Concept can be deleted by clicking on the “Delete” Button that comes down as a drop down when clicked on 3-dots at the Top right corner of the each Concept Tile.",
        },
        {
          question: "Can I recover a Deleted Concept?",
          answer: "No, the deleted Concept cannot be recovered.",
        },
      ],
    },
    {
      topic:
        "Methodologies - Rank, Relevance and originality calculations, etc.",
      qAndA: [
        {
          question:
            "Why do Relevance, Originality, and Rank change when a new concept is added or an existing concept is edited?",
          answer:
            "Relevance, Originality, and Rank change when a new concept is added or an existing concept is edited as they all are relative scores of a concept within a project.",
        },
      ],
    },
    {
      topic: "Best Practices ",
      qAndA: [
        {
          question: "Concept Writing Tips",
          answer: (
            <>
              Do's:
              <ol className="sw-faq__cncpt-tips">
                <li>
                  Write for an external audience: A concept statement is an
                  external document meant to be seen/read by customers or
                  consumers. This means you need to have certain executional
                  elements in the concept. Elements like headlines, selling
                  lines, claims or even simple words like “new” will help you
                  achieve stronger scores
                </li>
                <li>
                  Answer a specific consumer problem: Customers identify with
                  the problems the product solves. The tension can be functional
                  or emotional, and the introductory paragraph should clearly
                  set it up.
                </li>
                <li>{`Keep it brief: Include all important information. Retain the customers’ attention with simple, concise & clean language. Avoid unnecessary detail.`}</li>
                <li>
                  Highlight uniqueness: Focus on what makes the product unique
                  in the market or adds value.
                </li>
                <li>
                  Provide context: Provide enough context to customers to show
                  how product would be used or consumed and how it benefits
                  them.
                </li>
              </ol>
              Don't:
              <ol className="sw-faq__cncpt-tips">
                <li>
                  Overpromise: Description should be accurate and should not be
                  loaded with excessive superlatives.
                </li>
                <li>
                  Attempt to write the concept in one step: Instead, use a
                  2-step process: 1 - Write a concept outline. 2 - Write the
                  full concept.
                </li>
                <li>
                  Dismiss ideas too early: Keep every idea in the melting pot
                  for a quick bit of customer screening. Don’t be put off by
                  concepts which present internal challenges, you still need to
                  know if these test well with the customer.
                </li>
              </ol>
            </>
          ),
        },
        {
          question: "Concept Writing Checklist",
          answer: (
            <>
              Ask yourself these questions after writing the concepts:
              <ol className="sw-faq__cncpt-tips">
                <li>
                  Is the concept statement clear and brief? Can someone without
                  any background understand it within 30 seconds?
                </li>
                <li>Is the value proposition easy to understand?</li>
                <li>
                  Is there any differentiation between the concept and what is
                  already available in the market?
                </li>
                <li>
                  Is the concept believable and able to deliver as promised?
                </li>
              </ol>
            </>
          ),
        },
      ],
    },
    {
      topic: "General FAQs",
      qAndA: [
        {
          question: "Can I switch between Foresight Engine and Screen Winner?",
          answer:
            "One can switch between the two but will be redirected to the main Dashboards each time.",
        },
        {
          question:
            "Are my attachments used in the Project and Concept analysis?",
          answer: "No",
        },
        {
          question: "Do I have to be a subscriber to use ScreenWinner?",
          answer: "Yes, you have to be a subscriber to use the Screen Winner.",
        },
        {
          question:
            "How to navigate to the Screen Winner dashboard from the Concept screening page?",
          answer: (
            <>
              <ul className="sw-faq__gen">
                <li>
                  One way to do it is via clicking on the “Screen Winner” next
                  to the search bar.
                </li>
                <li>
                  Second way to do it is via clicking on the “Projects” in the
                  breadcrumb.
                </li>
              </ul>
              {/* <img src={} la/> */}
            </>
          ),
        },
        {
          question: "How to Screen a Project?",
          answer:
            "Can be done by clicking on the Screen Project Button below the search bar at the top right corner.",
        },
        {
          question: "How to Screen a Concept?",
          answer: "Can be done by clicking on the Screen Concept button.",
        },
        {
          question: "Can I download the graph?",
          answer: "Yes, the graph can be downloaded in the PNG format.",
        },
      ],
    },

    {
      topic: "Archive",
      qAndA: [
        {
          question:
            "Is it mandatory to add a Project description while adding a new Project?",
          answer:
            "Project description is optional but it is advised to write the description.",
        },
        {
          question: "Can I filter across the market among the Project?",
          answer:
            "Yes, one can filter across the market among the Project using the Country",
        },
        {
          question: "Can I see the total number of Concepts in a Project?",
          answer:
            "Yes, can be seen on the Left bottom Corner next to “Screening status” of every Project Tile.",
        },
        {
          question: "Can I download the results of a Screened Project?",
          answer:
            "Yes, the results will be downloaded in a Presentation format in a PDF file.",
        },
        {
          question: "How Relevance, Originality, and Rank are calculated?",
          answer: (
            <>
              Originality: It is calculated based on how similar a given product
              concept is to all the other products in the market by analyzing
              the claims of those products and the ingredients mentioned.
              <br />
              Relevance: It is the Consolidated Relevance score of the trends
              associated with a given product concept based on demand for
              Ingredient and Theme (macro trend) in the market based on trend
              maturity."
            </>
          ),
        },
        {
          question: "Can I delete a Screened/Unscreened Project?",
          answer: "Yes, a Screened/Unscreened Project can be deleted.",
        },
        {
          question: "Can I recover a Deleted Project?",
          answer: "No",
        },
      ],
    },
  ];

  const faqQAndASections=[
    {
      topic: "General",
      qAndA: [
        {
          question: "What is Ai Palette’s Foresight Engine?",
          answer:(
            <>
              The Ai Palette Foresight Engine converts millions of data points of unstructured data into clear, structured, and accurate insights to identify future trends in food and beverage.<br/>
              The Foresight Engine enables you to understand current trends in food and the level of maturity of each trend by analyzing market and category relevant public data. It also helps identify the underlying consumer needs that are driving consumption in a certain Food or Beverage category.
            </>
          )
        },
        {
          question: "What does the Foresight Engine do?",
          answer: (
            <>
              Our proprietary algorithm designed for food and beverage helps you understand consumer motivations at both category and trend level.<br/>
              Customised based on your chosen market, the Foresight Engine helps you visualise how trends are evolving in the market.<br/>
              Our Language Agnostic programme understands non-English languages and analyzes human language in organic conversations online.
            </>
          ),
        },
      ]
    },
    {
      topic: "Methodology",
      qAndA: [
        {
          question: "What is our methodology?",
          answer:(
            <>
              We identify and collect local publicly available data sources surrounding food and beverage, and clean and structure the data. We then apply our proprietary Natural Language Processing algorithm to uncover consumer needs and trends and run time series analysis to classify trends. The Foresight Engine presents our output in a manner that is easy to use.<br/>
              Depending on your target market, our algorithm identifies and evaluates millions of data points. Various data sources like social media, search engines, recipes and review sites, news and discussion sites, and e-commerce sites are analyzed by our algorithm.
            </>
          )
        },
        {
          question: "How does the Foresight Engine classify trends?",
          answer: (
            <>
              Based on your target market, an exhaustive trend list is identified based on multiple category and market specific sources. We measure each trend on the volume of engagement online, which includes likes, comments, shares, search queries from consumers and products from retail stores, cafes and restaurants.<br/>
              A trend would be classified on these factors:<br/>
              <ol className="sw-faq__cncpt-tips">
                  <li>
                    Growth Rate: To understand how the trend has been received by consumers over a long period of time.
                  </li>
                  <li>
                    Volume of Engagement: To understand the magnitude of consumer interests
                  </li>
                  <li>
                    Current Product Listings: To understand the level of maturity in each trend
                  </li>
                     (Current Product Listings includes products from restaurants, retail outlets, and ecommerce)
              </ol>  
            </>
          ),
        },
        {
          question: "How is the Foresight Engine different from social listening tools",
          answer: "Social listening involves monitoring what is currently happening in social conversations based on a limited set of previously determined keywords. The Foresight Engine collects and analyzes category and market specific data based on numerous keywords associated with the category and the trend. While social listening is limited to only data from social media, the Foresight Engine takes into account not only social media, but search queries, products from restaurants, cafes and online retailers. This gives you a holistic picture and enables you to identify what is trending in a category in a particular market as it does not limit the data to a few pre-determined keywords.",
        }, 
      ]
    },
    {
      topic: "Getting Started",
      qAndA: [
        {
          question: "How do I get started with the Foresight Engine?",
          answer:
            (
              <>
                You should have received an email from the Ai Palette Team with your log-in details and a link to the platform https://premium.aipalette.com/ <br/>
                On the homepage, select the country and category that you are interested in identifying trends for. For example if you are interested in knowing what is trending in Thailand Beverage either select Product Category as Thailand or Click on the “Beverage in Thailand” tile shown in the Dashboard (highlighted in black below) <br/>
                A map showing the trends trend position for your food category will be displayed where they are classified based on maturity of each trend. Click on the “Explore Category” to know more about the selected category. <br/>
                <br/>
                <img className="img_faq" src={foodCategoryTrends} /><br/>
                <br/>
                You may click or move through the dots to see which trends are positioned in a certain point in the map.<br/>
                <br/>
                <img className="img_faq" src={trendsPositionsInMap} />
              </>
            ),
        },
      ],   
    },
    {
      topic: "Trend Lenses",
      qAndA: [
        {
          question: " What kind of Trends does the Foresight Engine cover?",
          answer:
            (
             <>
               The Foresight Engine covers trends through three lenses.
               <ol className="sw-faq__cncpt-tips">
                <li>
                 Ingredient Lens: The Ingredient lense covers trends which are ingredients and flavours 
                 in various food and beverage categories. The ingredient lense can help in new product 
                 development, positioning and communication for new and existing products etc.
                </li>
                <li>
                 Theme Lens: The Theme lense covers a diverse list of themes and territories
                 which are being mentioned in consumer conversations. This lens can be used when designing
                 the positioning strategy for a new or existing product, building a new product,
                 developing marketing campaigns etc.
                </li>
                <li>
                 Product Lens: The Product Lens covers products present in the Hotels,
                 Restaurants and Cafes (HoReCa) and the Retail segments and products made by home chefs.
                 </li>
                </ol>
             </>
            ),
        },
        {
          question: " What kind of Themes are captured in the Themes Lens?",
          answer:
            (
             <>
               The Themes lense covers the maturity of Themes in a particular category like Beverages, Snacks etc. This is on the basis of consumer conversations from all our data sources.
              <ol className="sw-faq__cncpt-tips">
               <li>
                 Positioning: Benefits and Claims mentioned in consumer conversations
                 e.g. Added Fibre, 30 minute meals etc. as well as Causes e.g. Sustainability
               </li>
               <li>
                 Packaging: Packaging attributes e.g. resealable, different sizes etc.
               </li>
               <li>
                 Method of Preparation: Covers a wide variety of cooking and preparation methods
               </li>
               <li>
                 Health: Covers positioning, claims, concerns expressed by consumers 
                 since Health is a popular and broad topic which can have many different consumer interpretations
               </li>
               <li>
                 Emotions: Covers emotions most commonly connected with consumption of food and beverages
               </li>
               <li>
                 Cuisines: Global, international and local cuisines
               </li>
               <li>
                 Consumption Context: Covers different contexts of consumption from a consumers point of view 
                 e.g. Dining in, Breakfast etc. Also taps into Consumption connected to routines and 
                 special occasions by Lifestage like After School, Valentine's Day etc.
                </li>
               <li>
                 Channel: Retail and other Distribution channels that are expressed in consumer conversations
               </li>
               <li>
                 Attribute: Physical product attributes
               </li>
               <li>
                 Target Group: Covers different consumer personas expressed in conversations. 
                 These can be personas by demography, behaviour etc.
                </li>
             </ol>
             </>  
            ),
        },
        {
          question: "What do each of the Themes mean?",
          answer:
          (
            <>
              <ol className="sw-faq__cncpt-tips sw__li__num">
                The Foresight Engine covers trends through three lenses.
                The Foresight Engine defines each Theme as a keyword and some commonly used synonyms associated with that keyword. For example, theme Aroma not only covers “Aroma”, but also other closely used words such as Aromatic, Fragrant and Fragrance.
                <li>30 Minute Meals: Meal options that will be ready in under 30 minutes</li>
                <li>Active Culture: Live active cultures or the good bacteria, or probiotics</li>
                <li>Active Ingredients: Biologically active ingredient</li>
                <li>Adaptogenic: A natural substance considered to help the body adapt to stress</li>
                <li>Added Fibre: Functional/Synthetic fiber added to foods</li>
                <li>African Cuisine: Food and drinks prepared in African style or considered native to Africa</li>
                <li>After Class: After attending a class online/offline</li>
                <li>After College: After college is over for the day</li>
                <li>After Office: After the office is over for the day</li>
                <li>After School: After school is over for the day</li>
                <li>After Surgery: Suitable for consumption post a surgery</li>
                <li>After Work: After the work is done for the day</li>
                <li>Afternoon: During the noon time</li>
                <li>Aftertaste: A taste remaining in the mouth after eating or drinking something</li>
                <li>Aged: Food or drink having been subjected to ageing</li>
                <li>Aids Digestion: Helping with digestion</li>
                <li>Aliwan Festival: An annual festival in Philippines that promotes Filipino art, culture and heritage</li>
                <li>Alkaline Diet: Diet consisting of alkaline foods</li>
                <li>Alkaline: Foods that are perceived to have a pH of 7 or more, helping to maintain pH balance of the body</li>
                <li>Allergy: Foods helping or causing an allergic response by the body</li>
                <li>Alternative Protein: Plant-based or food-technology alternatives to animal protein</li>
                <li>Ambient: Relating to food that can safely be stored at room temperature in a sealed container</li>
                <li>Ambrosial: Food or drink perceived to have extremely pleasing taste and sweet fragrant properties</li>
                <li>American Cuisine: Food and drinks prepared in American style or considered native to America</li>
                <li>American Football: Consumed on the occasion of the game of American Football</li>
                <li>Amino Acids: Presence or association with amino acids which are the organic compounds that combine to form proteins</li>
                <li>Ancient Grain: Category of grains and pseudocereals that are purported to have been minimally changed by selective breeding, such as millet, barley, oats, quinoa, etc.</li>
                <li>Andhra Cuisine: Food and drinks prepared in Andhra style or considered native to the Indian State Andhra Pradesh</li>
                <li>Animal Cruelty-free: Label for products or activities that do not harm or kill animals anywhere in the world</li>
                <li>Anniversary: Date on which an event took place in a previous year, and consumption on the occasion to celebrate that event</li>
                <li>Anti-Cancer: Used in the treatment of cancer</li>
                <li>Anti-Diabetic: Used in the treatment of diabetes</li>
                <li>Anti-Flatulent: Perceived to be beneficial with prevention or treatment of flatulence</li>
                <li>Anti-Inflammatory: Perceived to be beneficial to reduce inflammation, often helping to relieve pain</li>
                <li>Anti-Oxidant: Associated with being rich in anti-oxidants which are compounds that inhibit oxidation leading to slower ageing</li>
                <li>Antibiotic Free: Claimed to be free of antibiotics</li>
                <li>Anxiety Relief: Claimed to help relieve anxiety</li>
                <li>Appearance: How the appearance of an ingredient impacts consumption</li>
                <li>Appetizer: A small dish of food or a drink taken before a meal or the main course of a meal to stimulate one's appetite</li>
                <li>Appetizing: Associated with or perceived to stimulate one's appetite</li>
                <li>April: Consumed during the month of April</li>
                <li>Armenian Cuisine: Food and drinks prepared in Armenian style or considered native to Armenia</li>
                <li>Aroma: Associated with a distinctive, typically pleasant smell</li>
                <li>Artificial Sweetener: Sweetened or perceived to be sweetened with an artificial sweetener</li>
                <li>Artificial-free: Claimed to be free of artificial ingredients</li>
                <li>Artisanal: Claimed to made by hand using traditional methods by skilled craft workers, known as food artisans</li>
                <li>Asian Cuisine: Food and drinks prepared in Asian style or considered native to Asia</li>
                <li>Assamese Cuisine: Food and drinks prepared in Assamese style or considered native to Indian State Assam</li>
                <li>At Class: Consumption context of being at a class</li>
                <li>At College: Consumption context of being at college</li>
                <li>At Home: Consumption context of being at home</li>
                <li>At Office: Consumption context of being at office</li>
                <li>At School: Consumption context of being at school</li>
                <li>Ati-Atihan Festival: Consumption context of Ati-Atihan Festival, a Philippine festival held annually in January in honor of the Santo Niño in several towns of the province of Aklan, Panay Island</li>
                <li>Atkin: A diet that restricts carbohydrates and encourages eating more protein and fat for weight loss</li>
                <li>August: Consumed during the month of August</li>
                <li>Authentic: Genuineness and Purity of an ingredient</li>
                <li>Autumn: Consumed during Autumn season</li>
                <li>Ayurvedic: Related to Ayurveda, the traditional Hindu system of medicine</li>
                <li>Baby Shower: Occasion of baby shower, the ceremony to celebrate the delivery or expected birth of a child</li>
                <li>Bachelor Party: Occasion og bachelor party, which is a party given for a man who is about to get married, typically one attended by men only</li>
                <li>Back to School: Relating to the occasion of the start of a new school year</li>
                <li>Badminton: Consumed in context of a game of Badminton</li>
                <li>Bailes de Luces: Consumed in the context of Bailes de Lucas, or "Dances of Light" festival of the Philippines</li>
                <li>Baked Not Fried: Prepared by baking and not frying</li>
                <li>Baked: Prepared by baking</li>
                <li>Bakery: Relating to baked goods such as breads and cakes, or could refer to a place where baked goods are sold</li>
                <li>Balanced Diet: Perceived to having different elements in the correct proportions in the diet</li>
                <li>Balanced: Perceived to have different elements in the correct proportion</li>
                <li>Balsamic: Associated with the nature of or yielding balsam</li>
                <li>Bar Hopping: Consumption in context of visiting several bars in succession and having a drink in each</li>
                <li>Basketball: Consumed in context of a game of Basketball</li>
                <li>Bathtime: Consumption context of bath time</li>
                <li>Bazaar: Channel of a large market or shop selling miscellaneous goods</li>
                <li>BBQ Party: Consumed during a BBQ party</li>
                <li>Bean-to-Bar: Refers to a trade model. It generally indicates that one brand controls every stage, from the purchase of the beans to the creation of the chocolate bar</li>
                <li>Beauty and Youth: Ingredients that are known to have a positive impact on beauty, skin and outwardly appearance</li>
                <li>Bedtime: Consumption context of bed time</li>
                <li>Beer Festival: Consumed during a beer festival</li>
                <li>Before Class: Consumed before class</li>
                <li>Benefit: Associated advantage with a food or beverage</li>
                <li>Bengali Cuisine: Food and drinks prepared in Bengali style or considered native to Indian State Bengal</li>
                <li>Beta Glucans: One form of soluble dietary fiber that's strongly linked to improving cholesterol levels and boosting heart health</li>
                <li>Better for Me: Perceived to have properties that could prove to be better, typically healthier</li>
                <li>Between Meals: Consumption in between meals</li>
                <li>Bhojpuri Cuisine: Food and drinks prepared in Bhojpuri style or considered native to Indian States of Bihar, Uttar Pradesh, Jharkhand</li>
                <li>Biking: Consumed in context of biking</li>
                <li>Biodegradable: Packaged in material that is biodegradable</li>
                <li>Bioplastic: A type of biodegradable plastic derived from biological substances rather than petroleum</li>
                <li>Birthday Party: Consumption in context of a birthday party</li>
                <li>Birthday: Consumption in context of a birthday</li>
                <li>Bitter: Associated with bitter taste</li>
                <li>Bittersweet: Associated with bittersweet taste</li>
                <li>Blacken: Make or become dark or black, by roasting or burning</li>
                <li>Blanch: Briefly immerse an item of food in boiling water, especially as a technique for removing the skin from nuts or fruit or for preparing vegetables for further cooking</li>
                <li>Blended: Made by blending or combining several things together</li>
                <li>Blood Pressure: Associated with impact on blood pressure</li>
                <li>Blood: Associated with impact on blood health</li>
                <li>Board Game Night: Associated with consumption on a board game night</li>
                <li>Body: Associated with impact on health and state of one's body</li>
                <li>Boiled: A product that is made by the process of boiling</li>
                <li> Bold: Associated with having a bold or somewhat strong taste or flavour</li>
                <li>Bollywood Night: Associated with consumption on a night watching Bollywood movies or listening/dancing to Bollywood songs</li>
                <li>Bollywood Party: Consumption in context of a Bollywood theme party</li>
                <li>Bone Health: Associated with impact on bone health</li>
                <li>Book Club: Consumption in context of an event by a book club</li>
                <li>Bowling: Consumption in context of a game of bowling</li>
                <li>BPA Free: Packaging in plastic material which is claimed to be free from BPA (Bisphenol A)</li>
                <li>Brackish: Of slightly salty taste</li>
                <li>Brain Health: Associated with brain health</li>
                <li>Breaded: Food that is typically coated with breadcrumbs and then fried</li>
                <li>Breakfast: Associated with being suitable for consumption for breakfast</li>
                <li>Breastfeed: Perceived to be suitable for breastfeeding mothers</li>
                <li>Breath Freshening: Perceived to freshen breath or associated with having breath freshening properties</li>
                <li>Brewed: Prepared by brewing</li>
                <li>Bridal Shower: Consumption on the occasion of a bridal shower</li>
                <li>Brine: Prepared by soaking or preserving in salty water</li>
                <li>Briny: Of strong salty taste</li>
                <li>British Cuisine: Food and drinks prepared in British style or considered native to Britain</li>
                <li>Broil: Cooked by exposure to direct heat, especially meat or fish</li>
                <li>Bruised: Prepared food or food item that has turned to having black/blue spots</li>
                <li>Brunch: Consumption occasion of brunch time</li>
                <li>Burmese Cuisine: Food and drinks prepared in Burmese style or considered native to Burma</li>
                <li>Burnt: Prepared in a way to have a burnt taste or flavour</li>
                <li>Business Lunch: Consumption occasion of a business lunch</li>
                <li>Buttock: Associated with impacting health or shape of one's buttocks</li>
                <li>Café: Consumption at a café or food ordered from a café</li>
                <li>Caffeine Levels: Associated with or talking about caffeine content</li>
                <li>Calcium: Associated with being rich in calcium</li>
                <li>Calorie Burning: Perceived to be good for burning calories</li>
                <li>Calories per Cup: Mentioning or discussing the calories per cup in a food or beverage item</li>
                <li>Calories: Discussing about calorific content of a food or beverage item</li>
                <li>Campus: Consumption at campus</li>
                <li>Canadian Cuisine: Food and drinks prepared in Canadian style or considered native to Canada</li>
                <li>Cancer: Associated with causing causing/preventing/treating cancer</li>
                <li>Canteen: Consumption at a canteen</li>
                <li>Cantonese Cuisine: The cuisine of the Guangdong province of China, particularly the provincial capital, Guangzhou and the surrounding regions in the Pearl River Delta, including Hong Kong and Macau</li>
                <li>Caramelize: Prepared by caramelizing</li>
                <li>Carbon Neutral: Claim that the brand/product/company has achieved net zero carbon dioxide emissions</li>
                <li>Carbonated: Of a soft drink, effervescent on account of containing dissolved carbon dioxide</li>
                <li>Carbonic Acid: Having or perceived to have carbonic acid</li>
                <li>Cardiovascular: Associated with having impact on cardiovascular health</li>
                <li>Cards Club: Consumption context of an event of a cards club</li>
                <li>Cards Party: Consumption context of a cards party</li>
                <li>Caribbean Cuisine: Food and drinks prepared in Caribbean style or considered native to Caribbean region</li>
                <li>Cartilage: Associated with health of cartilage</li>
                <li>Casein: Associated with being rich in casein protein</li>
                <li>Catering: Consumption context of catering</li>
                <li>Caustic: Associated with being caustic, i.e. capable of burning living tissue</li>
                <li>CBD Infused: Infused with Cannabidiol (CBD)</li>
                <li>Celebration: Consumption in relation to celebrating an event or achievement</li>
                <li>Cellular Health: Associated with being good for cellular health</li>
                <li>Char: Prepared by charring</li>
                <li>Charity: Consumption in context of an event by a charity</li>
                <li>Cheat Day: A break day from a diet regime</li>
                <li>Cheesy: Associated with being full of cheese or having a cheesy flavour or taste</li>
                <li>Chemical free: Claim that the product is free from chemicals</li>
                <li>Cherry Blossom Festival: Consumption context of Cherry Blossom Festival</li>
                <li>Chest: Associated with having impact on chest health</li>
                <li>Childbirth: Consumption occasion of celebrating a childbirth</li>
                <li>Children's Day: Consumption occasion of celebrating Children's day</li>
                <li>Children's Health: Associated with being good for children's health</li>
                <li>Chinese Cuisine: Food and drinks prepared in Chinese style or considered native to China</li>
                <li>Chinese New Year: Consumption in context of celebrating Chinese New Year</li>
                <li>Chlorine-free: Claim that the product is free from chlorine</li>
                <li>Chocolatey: Having a chocolatey taste or flavour</li>
                <li>Christmas: Consumption in context of celebrating Christmas</li>
                <li>Chromium-free: Claim that the product is free from chromium</li>
                <li>Cinco de Mayo: Consumption in context of Cinco de Mayo</li>
                <li>Circular Economy: Claim that the brand/product/company believes in and follows the approach of circular economy, a systemic approach to economic development designed to benefit businesses, society, and the environment.</li>
                <li>Circular Shopping: Claim that the brand/company believes in circular shopping, a concept that looks to disrupt that linear trajectory, keeping products and materials in use through recycling</li>
                <li>Clarity: Clear or transparent nature of a product, sometimes seen as a sign of purity</li>
                <li>Clear Label: A product label that gives more clarity on ingredients and specific details</li>
                <li>Cloudy: Having a cloudy appearance</li>
                <li>Club Hopping: Consumption in context of visiting several clubs in succession and having drink/food in each</li>
                <li>Clubbing: Consumption in context of clubbing</li>
                <li>Cognitive Function: Associated with cognitive function of health</li>
                <li>Cognitive: Associated with cognitive health</li>
                <li>Cold Brew: Cold brewed F&B item</li>
                <li>Cold Weather: Associated with food preferred in cold weather</li>
                <li>Cold-Pressed: That uses a hydraulic press to extract juice from fruit and vegetables, as opposed to other methods such as centrifugal or single auger</li>
                <li>Cold: Associated with being consumed cold or in cold weather</li>
                <li>Color: Color of a product which makes it visually striking and appealing</li>
                <li>Colorful Pack: Products associated with colorful packaging</li>
                <li>Comfort: Refers to a product which provides comfort</li>
                <li>Commuting: Associated with the food which is travel friendly or easy to consume while commuting</li>
                <li>Complex: Perceived to be complex in preparation or ingredient content</li>
                <li>Compostable: Perceived to be suitable for composting, typically comprising of organic waste</li>
                <li>Concentrated Extract: Concentrated extract used to prepare a food or beverage product</li>
                <li>Conching: The process of using a surface scraping mixer and agitator that evenly distributes cocoa butter with chocolate</li>
                <li>Conference: Consumption in context of attending a conference</li>
                <li>Contactless Delivery: Associated with the safety precautions of food delivery</li>
                <li>Contactless: Associated with the safety precautions, such as contactless food delivery</li>
                <li>Continental: European or western cuisine</li>
                <li>Convenience Store: A corner store is a small retail business that stocks a range of everyday items</li>
                <li>Convenience: Food that is commercially prepared to optimise ease of consumption</li>
                <li>Convenient Packaging: Features are the focus for packages that enhance ease of use, both at home and on the go</li>
                <li>Cool: Foods that are consumed in the cool form or in cool weather</li>
                <li>Covid-19: Foods which builds immunity against covid-19</li>
                <li>Craft: Prepared using traditional or artisanal method</li>
                <li>Cravings: A strong desire for a specific food</li>
                <li>Creche: Consumption in relation with small kids at a creche</li>
                <li>Cricket: Consumed on the occasion of the game of cricket</li>
                <li>Crisp: Associated with pleasantly hard and dry texture</li>
                <li>Cultured: Food related to cultural inclination</li>
                <li>Cure: Associated with curing a certain condition</li>
                <li>Dairy-free: Claim that the food is made from plants or nuts instead or is dairy free</li>
                <li>Date Night: Consumption in context of date </li>
                <li>Day Care: Plenty of vegetables and fruit. Whole grain breads, cereals, pitas, tortillas, roti, crackers and pasta. Fish, beans, chickpeas, soybeans, lentils, eggs </li>
                <li>Decadent: Rich, luxurious, fat-filled, highly caloric and possibly downright dangerous dishes</li>
                <li>December: Associated with the seasonal foods especially during december</li>
                <li>Destress: Foods that help you de-stress</li>
                <li>Detox: Associated with the food which detoxify the body by removal of toxins or other contaminants</li>
                <li>Diabetes: Associated with the diet to follow which keeps diabetic patient healthy</li>
                <li>Diabetic: Associated with the diet to follow which involves in eating the healthiest foods in moderate amounts and sticking to regular mealtimes in order to escape diabetes</li>
                <li>Diet: Refers to a particular diet, such as keto, vegan, vegetarian etc. followed by an individual, and foods that are associated to be suitable for that diet</li>
                <li>Difficult to Digest: Associated with the food which slows down the digestion process</li>
                <li>Dinagyang Festival: Food associated with this religious and cultural festival in Iloilo City, Philippines </li>
                <li>Dine In: Relating to or offering food that is eaten in the restaurant where it is ordered rather than being taken away</li>
                <li>Diner: A restaurant that serves simple, cheap food</li>
                <li>Dinner: Food consumption in context of dinner</li>
                <li>Directly from Farmers: Food on the table came directly from a specific farm, without going through a store, market, or distributor along the way</li>
                <li>Disease: Associated with food which helps fighting diseases</li>
                <li>Disposable: Single-use disposable products used by a food provider for serving or transporting prepared, ready-to-consume food or beverages including, but not limited to, plates, cups, bowls, trays, utensils, plastic straws, cup lids, and hinged or lidded containers.</li>
                <li>Distribution: Channels through which a product is made available to the consumer</li>
                <li>Diwali: Associated with consumption related to diwali </li>
                <li>Donation: Non-perishable and unspoiled perishable food mentioned in context of donation</li>
                <li>Double Ninth Festival: Food consumption associated with double ninth festival</li>
                <li>Double Seventh Festival: Food consumption associated with double seventh festival</li>
                <li>Dragon Boat Festival: Associated with consumption related to dragon boat festival</li>
                <li>Drive-through: Associated with the restaurant which serves you through a window so that you do not need to leave your car</li>
                <li>Dulcet: Associated to the foods which is sweet to the taste</li>
                <li>Durable Pack: Food that comes in packaging that is perceived to be durable</li>
                <li>Durga Pooja: Associated with the consumption related to durga pooja</li>
                <li>Dussehra: Associated with the consumption related to Dussehra</li>
                <li>E-commerce: Associated with the sale or availability on online channels</li>
                <li>Earth Day: Consumption of food in context of Earth Day</li>
                <li>Earthy: Associated with flavor in a food that reminds you of smelling the earth after a spring rain</li>
                <li>Ease of Preparation: Food which is ease to prepare</li>
                <li>Easter: Associated with the consumption on the occasion of Easter</li>
                <li>Eastern-Indian Cuisine: Food and drinks prepared in East-Indian style or considered native to Eastern region of India</li>
                <li>Easy to Carry: Food which is convenient to carry/ travel friendly</li>
                <li>Easy to Store: Consumption of food which is easy to store</li>
                <li>Easy: Perceived as easy to make</li>
                <li>Eat Clean: Eating clean encourages a lifestyle approach of a diet free from sugar, saturated fat, trans fat, preservatives, artificial ingredients, with more unprocessed foods and regular exercise.</li>
                <li>Eco-Friendly: Associated with the food products which comes with eco-friendly packaging</li>
                <li>Edible Packaging: Associated with the food products which comes with edible packaging</li>
                <li>Effective: Associated with good healthy nutritious food</li>
                <li>Eid: Food consumption during Eid</li>
                <li>Elderly Clubs: Consumption in context with the elderly clubs</li>
                <li>Elements: Food and beverages associated with having the essential elements</li>
                <li>Eliminate Sugar: Associated with the consumption of food that can help eliminate sugar</li>
                <li>Energy: Associated with the energy giving foods</li>
                <li>Entertain Guests at Home: Associated with the consumption context of such occasions</li>
                <li>Entreé: Associated with the dish served before the main course of a meal</li>
                <li>Environment Friendly Packaging: Associated with the food which comes with environment friendly packaging</li>
                <li>Enzyme: Associated with being rich in enzymes, that are protein molecules</li>
                <li>Ethical: Claim that the product/brand/company follows ethical sourcing or processes</li>
                <li>European Cuisine: Food and drinks prepared in European style or considered native to Europe</li>
                <li>Evening: Food consumption during evening time</li>
                <li>Excess: Lack of moderation, in eating or drinking. When an amount of an item consumed is more than necessary, permitted, or desirable</li>
                <li>Exercise Recovery: Associated with the intake of food and fluids to recover after exercise</li>
                <li>Extended Shelf Life: Associated with the foods which have longer shelf life</li>
                <li>Extraction: The process of removing or distilling
                <li>Eyesight: Consumption of food which is good for eyesight</li>
                <li>Faeces Consistency: Perceived to help with faeces consistency</li>
                <li>Family Pack: Associated with the foods with come with family pack</li>
                <li>Family-Size: Associated with the food in context to the family size</li>
                <li>Farmer's Market: Foods which are sold by farmers and growers directly to their public</li>
                <li>Fast Delivery: Timeliness in delivery of food</li>
                <li>Fasting: Foods associated to the context of fasting</li>
                <li>Fat Reduction: Associated with the food which helps in fat reduction</li>
                <li>Father's Day: Food consumption on the occasion of Father's day</li>
                <li>February: Associated with the seasonal foods especially in february</li>
                <li>Feeling: When consuming a product is strongly associated with a feeling or sensation</li>
                <li>Ferment: Process of converting carbohydrates to alcohol or organic acids using microorganisms—yeasts or bacteria—under anaerobic conditions</li>
                <li>Fever Relief: Associated with the food which helps fighting fever</li>
                <li>Fever: Associated with the food which helps fighting fever</li>
                <li>Fibre: Food which is rich in fibre</li>
                <li>Fiery: Food which has a very strong hot or spicy taste</li>
                <li>Filipino Cuisine: Food and drinks prepared in Filipino style or considered native to the Philippines</li>
                <li>Film Festivals and Awards: Food consumption in context of attending film festivals or award ceremonies</li>
                <li>Fine Dining : Consumed at a fine dining restaurant or cafe</li>
                <li>Fine Dining at Home: Consumption of fine dining F&B at home</li>
                <li>First Date: Food consumption in the context of first date</li>
                <li>Fitness: Associated with the food consumption which boosts one's fitness</li>
                <li>Flavonoids: A diverse group of phytonutrients (plant chemicals) found in almost all fruits and vegetables</li>
                <li>Flavor: Associated with the flavor rich food</li>
                <li>Flavored: Associated with the food rich in different flavors</li>
                <li>Flavorful: Associated with the foods with strong, pleasant taste and is good to eat</li>
                <li>Flexitarian: A a primarily vegetarian diet which can occasionally include meat or fish</li>
                <li>Fluorine-free: claims the road to eliminating fluorinated chemicals in food packaging</li>
                <li>Foil: Associated with the food which comes with foil packaging</li>
                <li>Food Court: An indoor plaza or common area within a facility that is contiguous with the counters of multiple food vendors and provides a common area for self-serve dinner</li>
                <li>Food Delivery: Associated to the timeliness in the delivery of food to customers</li>
                <li>Food Festival: Food consumption in context of food festival</li>
                <li>Food Mart: Availability or purchase from a food mart, a market or place where goods are sold</li>
                <li>Food Store: Availability or purchase from a food store, a store that sells food and household supplies </li>
                <li>Food Truck Festival: Consumption of food associated with the occasion of food truck festival</li>
                <li>For a Break: Considered suitable for consumption during a quick break</li>
                <li>For Adults: Food perceived to be suitable or preferred by adults</li>
                <li>For Children: Food perceived to be suitable or preferred by children</li>
                <li>For Elders: Food perceived to be suitable or preferred by elders</li>
                <li>For Fathers: Food perceived to be suitable or preferred by fathers</li>
                <li>For Millennials: Food perceived to be suitable or preferred by millennials</li>
                <li>For Mothers: Food perceived to be suitable or preferred by mothers</li>
                <li>For Teenagers: Food perceived to be suitable or preferred by teenagers</li>
                <li>Form: A particular visible shape, type or variety in which an ingredient exists or is used</li>
                <li>Formula: A list of ingredients with which a food or beverage item is made</li>
                <li>Fragile: Easily broken or damaged</li>
                <li>Freeze Dried: Food or beverage which is freeze dried, a low temperature dehydration process that involves freezing the product, lowering pressure, then removing the ice by sublimation</li>
                <li>French Cuisine: Food and drinks prepared in French style or considered native to France</li>
                <li>Frequency: Helping with frequency of bowel movements</li>
                <li>Fresh: Recently made or obtained i.e. not preserved. Can also refer to a trend being new or different</li>
                <li>Fried: The cooking of food in hot fats or oils, usually done with a shallow oil bath in a pan over a fire or as so-called deep fat frying</li>
                <li>Friendship Day: Consumption in context of friendship day</li>
                <li>Frozen: Food that has been subjected to rapid freezing and is kept frozen until used</li>
                <li>Fruity: Associated to the food (especially of food or drink) resembling or containing fruit</li>
                <li>Full-Bodied: Having a substantial weight and rich texture</li>
                <li>Fully-Cooked: Food that having been fully-cooked with skill so as to be pleasant to eat</li>
                <li>Function: Having a specific function, often health related</li>
                <li>Functional: Having potentially positive effect on health beyond basic nutrition</li>
                <li>Game Night: Food consumption during game night</li>
                <li>Gaming: Consumption in context of gaming</li>
                <li>Ganesh Chaturthi: Associated with the foods in the occasion of ganesh chaturthi</li>
                <li>Gas: Helping with treating or causing stomach gas</li>
                <li>Get-Together: Associated with the foods in the context of get-together</li>
                <li>Getaway: Suitable for consumption on a travel getaway</li>
                <li>Gift: A present given willingly to someone</li>
                <li>Glaze: A coating of a glossy, often sweet, sometimes savoury, substance applied to food typically by dipping, dripping, or with a brush</li>
                <li>Gloomy: Associated with food that could lead to a gloomy mood or could be suitable for consumption in a gloomy weather/mood</li>
                <li>Gluten-Free: Food or a diet which claims not containing gluten</li>
                <li>GMO-free: Claims that genetic makeup of the plants and animals used in the product has not been altered</li>
                <li>Goan Cuisine: Food and drinks prepared in Goan style or considered native to the Indian State Goa</li>
                <li>Good to Eat: Good tasting or filling food</li>
                <li>Good to Share: Item of pack that is considered to be good to share</li>
                <li>Grab-n-go: It refers to pre-packaged, ready-to-eat food items often sold at a self-service refrigerator that are suitable for grabbing on the go</li>
                <li>Graduation: Food consumption in the context of graduation ceremony</li>
                <li>Grain-Free: Claim that the product is grain-free</li>
                <li>Greek Cuisine: Food and drinks prepared in Greek style or considered native to Greece</li>
                <li>Green Packaging: Associated with foods that claim to have recyclable, sustainable or green packaging</li>
                <li>Grill: Made by grilling, i.e. cooking that involves dry heat applied to the surface of food, commonly from above, below or from the side</li>
                <li>Grocery Delivery: The online channel of grocery delivery at-home</li>
                <li>Grocery Store: A store primarily engaged in retailing a general range of food products</li>
                <li>Growth: Associated with the foods which are good for growth and development</li>
                <li>Gujarati Cuisine: Food and drinks prepared in Gujarati style or considered native to the Indian State Gujarat</li>
                <li>Gums; Its a type of sweet that you chew for a long time but do not swallow</li>
                <li>Gustatory: Foods that are relating to or associated with eating or the sense of taste</li>
                <li>Gut Health: The function and balance of bacteria of the many parts of the gastrointestinal tract</li>
                <li>Halal: Associated with the food which adheres to Islamic law, as defined in the Koran</li>
                <li>Halloween: Associated with the food in the context of halloween</li>
                <li>Hand-Crafted: Associated with the products made by hand or using the hands, as opposed to by mass production or using machinery</li>
                <li>Hand-Made: Associated with the products made by hand or using the hands, as opposed to by mass production or using machinery</li>
                <li>Hangout: Food associated in the context of hangout</li>
                <li>Happy Hour: Food associated inn the context of happy hour</li>
                <li>Happy: Food associated inn the context of happy</li>
                <li>Hawaiian Cuisine: Food and drinks prepared in Hawaiian style or considered native to Hawaii</li>
                <li>Healthy: Associated with the healthy food items</li>
                <li>Heart Health: Food associated for good heart health</li>
                <li>Hemp Infused: Claim or declaration that Hemp/CBD is present in the product</li>
                <li>Herbal: An ingredient that relates to or is made from herbs and their culinary and medicinal properties</li>
                <li>High Fat: Associated with the food which is high in fat content</li>
                <li>High Fibre: Associated with the food which is high in fibre content</li>
                <li>High Performance: Associated with food which helps maximise your energy</li>
                <li>High Protein: Associated with the food which has high protein content</li>
                <li>High Saturated Fat: Associated with the food which has high saturated fat</li>
                <li>High Transfat: Associated with the food which has high transfat</li>
                <li>Hobby Classes: Associated with consumption in context of hobby classes</li>
                <li>Holi: Associated with consumption in context of the Indian Festival of Holi</li>
                <li>Holiday: Associated with consumption in context of holiday</li>
                <li>Home Delivery: Associated with bringing of items to the customer's home rather than the customer taking or collecting them from the store</li>
                <li>Home Party: Associated with consumption in context of home party</li>
                <li>Homemade: Food items which are home cooked or homemade</li>
                <li>Honeyed: Food containing or coated with honey</li>
                <li>Honeymoon: Consumption of food in the context of honeymoon</li>
                <li>Hormone-free: Claim that beef or milk, or any other animal food product has been produced without the use of additional natural or synthetic sex steroids or protein growth hormones</li>
                <li>Hot Weather: Associated with foods that are consumed during hot weather</li>
                <li>Hot: Associated with the food when it has a strong, burning taste caused by chillies, pepper, or ginger, e.g. a hot curry</li>
                <li>Hotel: Food consumption at the hotel</li>
                <li>House Party: Food consumption in the house party</li>
                <li>Hyderabadi Cuisine: Food and drinks prepared in Hyderabadi style or considered native to Indian State Hyderabad</li>
                <li>Hydrating: Food can be preserved for indefinite periods by extracting the moisture, thereby inhibiting the growth of microorganisms</li>
                <li>Immunity: Associated with the foods that can boost immunity</li>
                <li>Imported: A mass produced food that can be sold at a cheaper price</li>
                <li>Improves Mood: Food which is associated with improving the person's mood on consumption</li>
                <li>In-store: Availability of an item within a store or shop</li>
                <li>Independence Day: Food consumption associated to the context of Independence day</li>
                <li>Indian Cuisine: Food and drinks prepared in Indian style or considered native to India</li>
                <li>Individually Wrapped: Supports the integration of components into a system by providing the ability to instantiate components, query their interfaces and bind them to the roles</li>
                <li>Indonesian Cuisine: Food and drinks prepared in Indonesian style or considered native to Indonesia</li>
                <li>Indori Cuisine: Food and drinks prepared in Indori style or considered native to Indian State Indore</li>
                <li>Indulgence: Foods those eaten to satisfy cravings or hunger and could be healthy or unhealthy</li>
                <li>Infused: The process of extracting flavor from an ingredient, such as tea leaves, herbs or fruit by steeping in liquid (usually hot), such as water, for tea</li>
                <li>Insomnia: Food that is associated with causing or treating insomnia</li>
                <li>Integrated: Food which to address the energy component of sustainable crop intensification through an ecosystem approach</li>
                <li>Intense: A desire to consume a specific food, and is different from normal hunger</li>
                <li>Intensity: Of an intense taste or flavour or effect</li>
                <li>Intermittent Fasting: Foods perceived to be suitable or consumed during intermittent fasting, which is an eating pattern where you cycle between periods of eating and fasting</li>
                <li>International: A global cuisine is a cuisine that is practiced around the world</li>
                <li>Intestine Health: Food items that are associated for good intestine</li>
                <li>Intestine: Food items that are associated for good intestine</li>
                <li>Isotonic: F&B containing added salts, minerals etc., intended to replace those which the body loses during physical exercise</li> 
                <li>Italian Cuisine: Food and drinks prepared in Italian style or considered native to Italy</li>
                <li>Jain: Completely vegetarian food and also excludes underground vegetables such as potato, garlic, onion etc, to prevent injuring small insects and microorganisms; and also to prevent the entire plant getting uprooted and killed</li>
                <li>Jamaican Cuisine: Food and drinks prepared in Jamaican style or considered native to Jamaica</li>
                <li>Janmashtami: consumption of food in the context of janmashtami</li>
                <li>January: Associated with the seasonal food especially for january</li>
                <li>Japanese Cuisine: Food and drinks prepared in Japanese style or considered native to Japan</li>
                <li>Juicy: Food items containing a lot of juice or having a juicy texture</li>
                <li>July: Associated with the seasonal foods especially July</li>
                <li>June: Associated with the seasonal foods especially June</li>
                <li>Kadayawan Festival: Food associated in the context of kadayawan festival</li>
                <li>Karaoke: Associated with the food consumption on the occasion of karaoke</li>
                <li>Kashmiri Cuisine: Food and drinks prepared in Kashmiri style or considered native to Indian State Kashmir</li>
                <li>Kathiyawadi Cuisine: Food and drinks prepared in Kathiyawadi style or considered native to Indian region of Kathiyawad</li>
                <li>Keto: A high-fat, adequate-protein, low-carbohydrate diet that in medicine is used mainly to treat hard-to-control epilepsy in children</li>
                <li>Kidney Health: Associated with the food that is good for kidney health</li>
                <li>Kids Playdate: Associated with the consumption of food during kids playdate</li>
                <li>Kindergarten: Associated with the food consumption for the kindergarten kids</li>
                <li>Kitty Party: Associated with the food consumption during the kitty party</li>
                <li>Konkani Cuisine: Food and drinks prepared in Konkani style or considered native to Indian State Goa and adjacent parts of south-western India</li>
                <li>Korean Cuisine: Food and drinks prepared in Korean style or considered native to Korea</li>
                <li>Kosher: Food that conform to the Jewish dietary regulations of kashrut, primarily derived from Leviticus and Deuteronomy</li>
                <li>Laba Festival: Food consumption associated in the context to laba festival </li>
                <li>Labelling: Food labelling includes any written, printed or graphic matter that is present on the label, accompanies the food, or is displayed near the food, including that for the purpose of promoting its sale or disposal</li>
                <li>Lacking in Sugar: Associated with the food which lacks sugar</li>
                <li>Lactic Acid: Associated to food as a food preservative, curing agent, and flavoring agent</li>
                <li>Lantern Festival: Associated with food in the context of Lantern festival</li>
                <li>Laughter Club: Associated with food in the context of laughter club</li>
                <li>Laxative: Purgatives, or aperients are substances that loosen stools and increase bowel movements</li>
                <li>LCHF: Associated with the diet method of eating that focuses on reducing carbs and replacing them with healthy fats</li>
                <li>Lebanese Cuisine: Food and drinks prepared in Lebanese style or considered native to Lebanon</li>
                <li>Less Reflux: Foods that are associated with less reflux</li>
                <li>Less Sugar: Foods that are associated with less sugar</li>
                <li>Less Sweet: Foods that are associated with less sweet</li>
                <li>Light: Visually an item that is brighter and paler than its surroundings. Can also refer to a taste that is gentle, not strong and severe</li>
                <li>Limited Edition: Food that is limited to a specified small number</li>
                <li>Linger: Having a taste or flavour that lingers or stay after consumption</li>
                <li>Lingering: Having a taste or flavour that is lingering for some time after consumption</li>
                <li>Literary Festival: Association of food in the context of literary festival</li>
                <li>Liver Health: Food which is associated to good liver health</li>
                <li>Local: Food that is produced within a short distance of where it is consumed, often accompanied by a social structure and supply chain different from the large-scale supermarket system</li>
                <li>Locavore Diet: Associated with the eating food that is grown or produced within their local community or region</li>
                <li>Long Shelf Life: Food which is associated with extended shelf life</li>
                <li>Longlasting Flavour: Foods that are associated with long term flavors</li>
                <li>Longlasting: Food which has a longer and extended shelf life</li>
                <li>Loop Alliance: Food items whose packaging adheres to no plastic waste</li>
                <li>Looped Recycling: Process by which a product is used, recycled, and then made into a new product therefore not ever entering landfill</li>
                <li>Low Allergen: A diet that exclude all foods containing dairy products, soy, wheat, eggs, peanuts, tree nuts, and fish</li>
                <li>Low Calorie: Associated with the food which is low in calories</li>
                <li>Low Carbohydrate: Associated with the food which is low in carbohydrate</li>
                <li>Low Fat: Associated with the food which is low fat</li>
                <li>Low Glycemic Index: Claim that the food is of Low GI means it is slowly digested and absorbed, causing a slower and smaller rise in blood sugar levels</li>
                <li>Low Lactose: Food that is associated with low lactose</li>
                <li>Low Salt: Food which is associated with low salt</li>
                <li>Low Saturated Fat: Food which is associated with low saturated fat</li>
                <li>Low Sodium: Food which is associated with low sodium</li>
                <li>Low Sugar: Food which is associated with low sugar content</li>
                <li>Low Transfat: Food which is associated with low transfat</li>
                <li>Lunch: Food consumption in the context of lunch</li>
                <li>Lung Health: Food consumption for healthy lungs</li>
                <li>Macronutrient: A type of food (e.g. fat, protein, carbohydrate) required in large amounts in the diet</li>
                <li>Magnesium: Associated with the food with magnesium content</li>
                <li>Maharashtrian Cuisine: Food and drinks prepared in Maharashtrian/Marathi style or considered native to Indian State Maharashtra</li>
                <li>Mahashivratri: Association of food in the context of mahashivratri</li>
                <li>Main Course: The featured or primary dish in a meal consisting of several courses</li>
                <li>Maintain: F&B that helps you maintain a certain health aspect, e.g. blood sugar, weight, etc.</li>
                <li>Makar Sankranti: Food associated in the context of makar sankranti</li>
                <li>Malaysian Cuisine: Food and drinks prepared in Malaysian style or considered native to Malaysia</li>
                <li>Malvani Cuisine: Food and drinks prepared in Malvani style or considered native to the South Konkan region of Indian States Maharashtra and Goa</li>
                <li>March: Associated with the seasonal food especially during march</li>
                <li>Marinate: Marination is the process of soaking foods in a seasoned, often acidic, liquid before cooking</li>
                <li>Market: It is one of a composition of systems, institutions, procedures, social relations or infrastructures whereby parties engage in exchange</li>
                <li>Mart: Exposition for the sale of goods by manufacturers and wholesalers to retail merchants</li>
                <li>Marwari Cuisine: Food and drinks prepared in Marwari style or considered native to Indian region of Marwar</li>
                <li>MassKara Festival: Food associated in the context of masskara festival</li>
                <li>Maternity Leave: It is a paid leave of absence from work that allows women employees the benefit of taking care of their newly born, and at the same time retain their jobs</li>
                <li>May: Associated with the seasonal foods especially for may</li>
                <li>Meal Time: The time at which a meal is usually eaten</li>
                <li>Medicinal: Associated with food products which has medicinal properties</li>
                <li>Medicine: Foods with powerful medicinal properties</li>
                <li>Meditation: Foods perceived to be suitable for consumption before or after meditation</li>
                <li>Mediterranean Cuisine: Food and drinks prepared in Mediterranean style or considered native to Mediterranean region</li>
                <li>Meghalayan Cuisine: Food and drinks prepared in Meghalayan style or considered native to Indian style of Meghalaya</li>
                <li>Men's Health: Foods that are associated for good men's health</li>
                <li>Mental Health: Foods associated to maintain good mental health</li>
                <li>Method: The method chosen to prepare, serve or consume an F&B item</li>
                <li>Mexican Cuisine: Food and drinks prepared in Mexican style or considered native to Mexico</li>
                <li>Michelin Delivery: Home delivery of F&B from a Michelin star restaurant/café</li>
                <li>Microwaveable: Associated with food or food packaging that is microwaveable </li>
                <li>Mid-autumn Festival: Food consumption in context of mid-autumn festival</li>
                <li>Mild: Foods that does not taste or smell strong, sharp, or bitter, especially when you like it because of this</li>
                <li>Mildly Sweetened; Foods which are mildly sweet</li>
                <li>Milky: Food items which is like milk, or made with milk</li>
                <li>Mineral Fortified: Foods that have nutrients added to them that don't naturally occur in the food</li>
                <li>Minty: Food that tasting or smelling of mint</li>
                <li>Moisture Content: The quantity of water contained in a material</li></li>
                <li>Mom and Pop Store: A small reta    il store owned and operated by members of the same family</li>
                <li>Mom Made: Food that made at home or on the premises</li>
                <li>Mongolian Cuisine: Food and drinks prepared in Mongolian style or considered native to Mongolia</li>
                <li>Monsoon: Seasonal foods especially during monsoon</li>
                <li>Moriones Festival: Food consumption that is associated with moriones festival</li>
                <li>Morning: Consumption of food during morning time</li>
                <li>Moroccan Cuisine: Food and drinks prepared in Moroccan style or considered native to Morocco</li>
                <li>Mother's Day: Food consumption that is associated in the context of mother's day</li>
                <li>Mouthwatering: Food that looks or smells very good</li>
                <li>Movie Multiplex: A movie theater complex with multiple screens within a single complex</li>
                <li>Movie Night: Foods that are consumed during movie night</li>
                <li>Mughlai Cuisine: Food and drinks prepared in Indo-Persian style or considered to be of Mughal origin</li>
                <li>Multigrain: Foods that are enrich in multi-grain</li>
                <li>Muscle Gain: Foods that are associated in boosting muscles</li>
                <li>Muscle: Foods that are associated in boosting muscles</li>
                <li>Music Festivals and Concerts: Foods that are consumed or are in context with music festivals and concerts</li>
                <li>Natural Sugar: Foods items that contain natural sugar</li>
                <li>Natural: Existing in and derived from nature, not made by artificial means</li>
                <li>Navratri: Food consumption during navratri time</li>
                <li>Neighbourhood Shop: A store specifically meant to fit in with its neighborhood surroundings and especially catering to the needs and desires of the local microcosm</li>
                <li>Nervous System Health: Food that focuses on good nervous system health</li>
                <li>New Age Diet: It comprises recommendations of minimum amounts to consume for fruits, vegetables, legumes, low-fat dairy, low-fat cheese, fish, low-fat meat and poultry, nuts, olive oil, fluids, and vitamin D</li>
                <li>New and Innovative: Associated with the foods with new things and innovations</li>
                <li>Next-day Delivery: Means that senders can have items delivered to their customers more quickly than with a standard 2 or 3-day service.</li>
                <li>Night: Food associated with the context during night</li>
                <li>Nitrate-free: Foods only that there are no nitrates or nitrites added, except for the naturally occurring kind.</li>
                <li>Nitro: Nitro cold brew coffee charged with nitrogen to give it a rich, creamy head of foam, similar to draft beer</li>
                <li>No Added Sugar: Product that are specifically marketed as no added sugar by virtue of its manufacturing process where no sugar is added as an ingredient. However this does not mean that the products is sugar free as natural sugars may still be present. For example, no-added-sugar juices may contain natural sugar from the fruit of extraction</li>
                <li>No Added Sweetener: Foods that contains no sugars added during processing or packing, including ingredients that contain sugar such as juice or dry fruit</li>
                <li>No additive: Food associated with not containing any chemical additives</li>
                <li>No Aftertaste: Associated with the food that not tastes (usually an unpleasant one) that stays in your mouth after you have eaten or drunk something</li>
                <li>No Allergen: This refers to products that are free of the current top 8 allergens as recognized by the FDA which are dairy, wheat, eggs, soy, shellfish, fish, tree nuts (including coconut) and peanuts</li>
                <li>No Animal Cruelty: It is a label for products or activities that do not harm or kill animals anywhere in the world</li>
                <li>No Animal Ingredient: Associated with the food products which doesný contain animal-derived ingredient resource</li>
                <li>No Artificial Color: Food products where coloring agent is either absent or made from ingredients considered as 'natural.'</li>
                <li>No Artificial Flavours: Food products that doesn't contain any artificial flavoring substances</li>
                <li>No Artificial Ingredient: Associated with food which does not constitute any artificial ingredient</li>
                <li>No Artificial Preservative: Associated with the food which no added artificial preservative</li>
                <li>No Bleaching: Claim that the food item, often flour is unbleached</li>
                <li>No Calorie: Food associated with the no calorie consumption</li>
                <li>No Colic: Claim that the food item won't cause colic, severe pain in the abdomen caused by wind or obstruction in the intestines and suffered especially by babies</li>
                <li>No Fat: Food items which no fat constituents</li>
                <li>No Glycemic Index: F&B associated with low or no glycemic index, a figure representing the relative ability of a carbohydrate food to increase the level of glucose in the blood</li>
                <li>No Grain: Food that includes the elimination all grains, as well as foods derived from them</li>
                <li>No Lactose: Associated with the food with no lactose</li>
                <li>No Nuts: Associated with the food which doesn't constitute nuts</li>
                <li>No Packaging Waste: This refers to a system of packaging where all materials are utilized, reused, or recycled, so there's no waste</li>
                <li>No Preservative: Associated with the food that claims no added preservatives</li>
                <li>No Reflux: Claim that the product does not lead to Gastroesophageal reflux</li>
                <li>No Saturated Fat: Food that claims to have no saturated fat</li>
                <li>No Sodium: Associated with the food with no sodium</li>
                <li>No Soy: Associated with the food with no soy</li>
                <li>No Sugar: Associated with the food with no sugar</li>
                <li>No Sugariness: Associated with the food which doesn't have exaggeratedly sweet</li>
                <li>No Transfat: Claim that the F&B product is free from trans fat</li>
                <li>No Waste: Claim that the product was made/created without creating any waste, or that it is created in such a way that all of it can be used without leaving behind an waste</li>
                <li>Non-GMO: Claim that the product is free from GMO, i.e. non genetically modified</li>
                <li>Non-irradiated: Claim that the product and its ingredients have not been exposed to radiation</li>
                <li>Nonalcoholic: F&B which are non-alcoholic</li>
                <li>North-Indian Cuisine: Food and drinks prepared in North Indian style or considered native to North Indian region</li>
                <li>Not Sugary: Foods that are not being, containing, or made from sugar</li>
                <li>Nourish: Food items containing substances necessary for growth, health, and good condition</li>
                <li>Nourishing: Food items containing substances necessary for growth, health, and good condition</li>
                <li>Nourishment: Food associated as being necessary for growth, health, and good condition</li>
                <li>November: Food consumption which is seasonal in the context of november</li>
                <li>Nutrition: Food that is nutritious</li>
                <li>Nutty: Food containing or tasting of nuts</li>
                <li>October: Food consumption which is seasonal in the context of october</li>
                <li>Office Party: Food consumption in the context of office party</li>
                <li>On-the-Go: Grab a greasy quickie food bite and eat it while you drive from point A to point B</li>
                <li>Onam: Food consumption in the context of onam</li>
                <li>One Pan: A simple cooking method that easily combines all a dish's essential ingredients in one pot while cooking</li>
                <li>Online Shopping Festivals: Food consumption in the context of online shopping festivals</li>
                <li>Online: Related to the food which caters online service</li>
                <li>Order Pick-up: Orders are placed by phone or online, packaged up, and either put into customers' car trunks for them or, in more pedestrian cities like New York, set outside for pickup</li>
                <li>Oriya Cuisine: Food and drinks prepared in Oriya style or considered native to Indian State Odisha</li>
                <li>Outing: Food consumption in the context of outing</li>
                <li>Packaged: This means any food offered for retail sale in Oregon, other than raw food and food served, sold, or provided ready to eat in any bake sale</li>
                <li>Packaging Material: Talking about the type of packaging material used for packing the product</li>
                <li>Pahiyas Festival: Consumption of food with the context of pahiyas festival</li>
                <li>Pain Relief: Associated with the food which has pain relief properties</li>
                <li>Pain: Perceived to help with pain relief</li>
                <li>Palatable: Used about food or drink with an agreeable or pleasant taste</li>
                <li>Paleo: A paleo diet typically includes lean meats, fish, fruits, vegetables, nuts and seeds </li>
                <li>Panagbenga Festival: Food consumption in the context of panagbenga festival</li>
                <li>Paper Pack: Associated with the foods that come with paper pack packaging</li>
                <li>Party: Food consumption in the context of party</li>
                <li>Paternity Leave: A period of time when the father of a new baby is allowed to be away from work</li>
                <li>Pathogen: Food associated with building immune against pathogen</li>
                <li>Patient: Food that is recommended to the patients for the speedy recovery</li>
                <li>Paunch: Foods that are associated with the removal of a large or protruding belly</li>
                <li>Peppery: Foods that are strongly flavoured with pepper or other hot spices</li>
                <li>Performance: Perceived to impact one's performance; could also point to a product's performance in terms of how it acts on its mentioned claims</li>
                <li>Perishable: Associated with the food that will go bad quickly</li>
                <li>Peruvian Cuisine: Food and drinks prepared in Peruvian style or considered native to Peru</li>
                <li>Pesticide-free: Food that claim to be pesticide-free</li>
                <li>pH Level: Associated with the food with has a healthy balance in PH scale</li>
                <li>Physiology: Associated with the food which helps building good physiology</li>
                <li>Physique: Consumption of food which builds good physique</li>
                <li>Pickle: A piece of food and especially a cucumber that has been preserved in a solution of salt water or vinegar</li>
                <li>Picnic: Food consumption in the context of picnic</li>
                <li>Pintados Festival: Food consumption in the context of pintados festival</li>
                <li>Plant Protein: Associated with the food which is rich in plant protein</li>
                <li>Plant-based packaging: Food that comes with bioplastic packaging</li>
                <li>Plant-Based: Focus on foods primarily from plants</li>
                <li>Plantable Packaging: Food that comes with bioplastic packaging</li>
                <li>Plastic Pack: Food that comes with plastic packaging</li>
                <li>Playdates: Consumption of foods that are in context with playdates</li>
                <li>Playschool: Consumption of foods that are in context with playschool</li>
                <li>Pleasing Pack: Food that comes with good pleasing packaging</li>
                <li>Poach: To cook food (especially fish or eggs) gently in a small amount of liquid</li>
                <li>Pongal: Food consumption with the context of pongal</li>
                <li>Portable: Of or involving food that is easily portable, i.e. suitable to be taken and eaten off the premises</li>
                <li>Post Surgery: Food that is recommended to consume during post surgery</li>
                <li>Potassium: Food that is rich in potassium</li>
                <li>Pranik: Claim that the foods are pranik essentially means that they are non-processed or not in heated too much</li>
                <li>Prebiotic: Compounds in food that induce the growth or activity of beneficial microorganisms such as bacteria and fungi</li>
                <li>Pregnancy: Food that is recommended to consume during pregnancy</li>
                <li>Pregnancy: Food that is recommended to consume during pregnancy</li>
                <li>Premium Delivery: Premium Shipping allows you to provide fast shipping options to customers</li>
                <li>Premium Takeaway: Premium or fine dining foods available for home delivery</li>
                <li> Premium: Definition of a premium food lies in the quality of its ingredients</li>
                <li>Preservative-free: Claims that the food has no preservatives</li>
                <li>Prevention: Associated with prevention of a disease</li>
                <li>Probiotic: Probiotics are live bacteria and yeasts that are good for health, especially for digestive system</li>
                <li>Protection: Associated with the diet that act as a protective agent to the body system</li>
                <li>Protective: Associated with the diet that act as a protective agent to the body system</li>
                <li>Protein: Food associated with high rich protein</li>
                <li>Punjabi Cuisine: Food and drinks prepared in Punjabi style or considered native to Indian State Punjab</li>
                <li>Qingming Festival: Consumption of food with the context of qingming festival</li>
                <li>Quality Criteria: The degree of excellence of a Food or Beverage product or brand</li>
                <li>Quantity: The amount or number of a material or ingredient specified</li>
                <li>Quick Meal: Refers to the food with speed of service</li>
                <li>Radical: These substances include fried foods</li>
                <li>Rainy: Associated to the seasonal foods especially during rainy season</li>
                <li>Rajasthani Cuisine: Food and drinks prepared in Rajasthani style or considered native to Indian State Rajasthan</li>
                <li>Raksha Bandhan: Consumed in context of the Indian festival of Raksha Bandhan</li>
                <li>Ready to Drink: A beverage in ready-to-drink format</li>
                <li>Ready to Eat: A food or snack item that is ready-to-eat</li>
                <li>Real Butter: Associated with the food made of real butter</li>
                <li>Real Cheese: Associated with the food made of real cheese</li>
                <li>Real Yogurt: Associated with the food made of real yogurt</li>
                <li>Recycling: Associated with the food that comes with the recycling packaging</li>
                <li>Reduced Fat: Food that helps in fat reduction</li>
                <li>Reduced Saturated Fat: Food that helps in reduction of saturated fat</li>
                <li>Reduced Transfat: Food that helps in reduction of transfat</li>
                <li>Refill: A refill of a particular product, such as soap powder, is a quantity of that product sold in a cheaper container than the one it is usually sold in. You use a refill to fill the more permanent container when it is empty.</li>
                <li>Reflux: Reflux means to flow back or return. Gastroesophageal reflux is when what's in your stomach backs up into your esophagus. Associated with foods causing reflux.</li>
                <li>Refreshing: A food or beverage serving to refresh or reinvigorate someone. Also used to describe welcome or stimulating because new or different</li>
                <li>Refrigerate: To make food, etc. cold in order to keep it fresh</li>
                <li>Reheat: Food that is consumed after reheating</li>
                <li>Relax: Food that is perceived to help the consumer relax</li>
                <li>Relaxing: Food often consumed when one is relaxi</li>
                <li>Renewable Packaging: Renewable content refers to the use, in the manufacturing of a package, of bio-based and natural resources that can be replenished through a natural process</li>
                <li>Resealable: Resealable packaging is any type of packaging that allows the consumer or user to reseal or replace the packaging</li>
                <li>Responsible Sourcing: Claim that the product is responsibly sourced. Responsible sourcing implies a voluntary commitment by companies to take into account social and environmental considerations when managing their relationships with suppliers</li>
                <li>Restaurant: Consumption at restaurant, which is a place where you can buy and eat a meal</li>
                <li>Restless Legs Syndrome: Perceived to help with curing or reducing restless legs syndrome</li>
                <li>Result: a consequence or an outcome that is caused or produced</li>
                <li>Retail Store: a place of business usually owned and operated by a retailer but sometimes owned and operated by a manufacturer or by someone other than a retailer in which merchandise is sold primarily to ultimate consumers</li>
                <li>Return-ready Pack: A pack that is suitably packaged to be returned to the seller/producer</li>
                <li>Returnable Packaging: This is manufactured of durable materials and is specifically designed for multiple trips and extended life</li>
                <li>Reusable: Associated with the food packaging which is manufactured of durable materials</li>
                <li>Rich: Associated with the food that is oily or sweet, or being high in plant nutrients, or highly seasoned</li>
                <li>Ripe: Something fully grown or ready to be eaten, used or implemented</li>
                <li>Roast: Food that is prepared by a cooking method that uses dry heat where hot air covers the food, cooking it evenly on all sides with temperatures of at least 150 °C (300 °F) from an open flame, oven, or other heat source</li>
                <li>Room Temperature: Food that is consumed in cooked and consumed in room temperature</li>
                <li>Safe Pack: Safepack can be adjusted to different bottle shapes so that every product reaches its destination safely</li>
                <li>Safe Packaging: Safe packaging can be adjusted to different bottle shapes so that every product reaches its destination safely</li>
                <li>Safety: The condition of being protected from or unlikely to cause danger, risk, or injury</li>
                <li>Sales Meet: Associated with the food consumption during the sales meet</li>
                <li>Salty: having the taste of or containing salt</li>
                <li>Sattvik: A diet that is meant to include food and eating habit that is "pure, essential, natural, vital, energy-giving, clean, conscious, true, honest, wise</li>
                <li>Saute: To fry food quickly in a little fat or oil until it turns brown</li>
                <li>Save the Planet: Claim that the product/brand/company follows practices that are sustainable and eco-friendly</li>
                <li>Savory: Consumption of food having a taste that is not sweet but salty</li>
                <li>Sear: It is a technique used in grilling, baking, braising, roasting, sautéing, etc, in which the surface of the food is cooked at high temperature until a browned crust forms</li>
                <li>Seasonal: Associated with the foods that are seasonal</li>
                <li>Selenium: Food sources of selenium include seafoods; some meats, such as kidney and liver; and some grains and seeds</li>
                <li>Self-Heating: Self-heating food packaging is active packaging with the ability to heat food contents without external heat sources or power</li>
                <li>Semi-Processed: Some food products have undergone some cooking procedures so that the finishing touches have to be given by the consumer</li>
                <li>Seminar: Food consumption with the context of seminar</li>
                <li>September: Associated with the foods that are seasonal especially during september</li>
                <li>Shelf stable: Shelf-stable food is food of a type that can be safely stored at room temperature in a sealed container</li>
                <li>Shelf-ready: This packaging refers to the packaging of a product so that it is delivered to a retailer in packaging which is optimized for efficient stocking and sale</li>
                <li>Shop: a building or part of a building where things are bought and sold</li>
                <li>Simmering: Simmering is a food preparation technique by which foods are cooked in hot liquids kept just below the boiling point of water[1] (lower than 100 °C or 212 °F) and above poaching temperature</li>
                <li>Simple: Food which is associated with the home-made rather than using bizarre ingredients</li>
                <li>Single Serve: A serving is an amount of food that is given to one person at a meal</li>
                <li>Single Use: Food with packaging that is designed to be used once and then disposed of or destroyed</li>
                <li>Sinulog Festival: Food consumption with the context of sinulog festival</li>
                <li>Size: Relative extent or overall dimensions of an item</li>
                <li>Sleep Apnea: Perceived to help cure/reduce sleep apnea</li>
                <li>Sleep Disorder: Perceived to help cure/reduce sleep disorder</li>
                <li>Sleep Quality: Perceived to improve quality of sleep</li>
                <li>Sleepover: Consumption in context of a sleepover, a social occasion whereby a friend stays overnight at another friend's house</li>
                <li>Slim: Food that contributes to slim down</li>
                <li>Slimmer: Food that contributes to slim down</li>
                <li>Slimming; Food that contributes to slim down</li>
                <li>Small One-Person Box: The amount of food that is given to one person at a meal</li>
                <li>Small pack: Food that is associated with the small packaging</li>
                <li>Smoked: Food that is cooked directly over the fire</li>
                <li>Smoky: Food that is cooked directly over the fire</li>
                <li>Smooth: Having an even and regular surface; free from perceptible projections, lumps, or indentations. For a liquid, it means with an even consistency or without lumps. Smooth is also used to refer to aftertaste, like a liquid that does not leave any lingering taste in your throat or mouth,</li>
                <li>Snack Time: Food consumption during the snack time</li>
                <li>Snoring: Perceived to help with curing or reducing snoring</li>
                <li>Snow: Consumption in context of the snowing weather</li>
                <li>Soccer: Associate to the kind of foods soccer players eat</li>
                <li>Soothing: Associated with the foods that are tending to soothe also or having a sedative effect</li>
                <li>Sour: Food that have unpleasant or unfriendly taste</li>
                <li>Sourcing: Purchasing or procurement of food, ingredients and other consumable products from within a specific radius (distance) from where they will be used or sourced, or from a given geographical area</li>
                <li>South-Indian Cuisine: Food and drinks prepared in South-Indian style or considered native to South Indian region</li>
                <li>Soy-free: Food that claims to be no soya</li>
                <li>Spanish Cuisine: Food and drinks prepared in Spanish style or considered native to Spain</li>
                <li>Specialty: A product, especially a type of food, which a person or region is famous for making well</li>
                <li>Speed: The rate at which a food or beverage item acts or provides a desired impact</li>
                <li>Spicy: Food having sharp, strong, pungent flavor</li>
                <li>Sports: The kind of food recommended to the sports person to consume</li>
                <li>Spring: Consumption of food which is seasonal especially during spring</li>
                <li>Sri Lankan Cuisine: Food and drinks prepared in Sri Lankan style or considered native to Sri Lanka</li>
                <li>Stockpile: Suitable for stockpiling</li>
                <li>Stomach: Perceived to be good for stomach health</li>
                <li>Stores: Available, bought sold at stores</li>
                <li>Street and Stall Food: Food obtainable from a street side vendor, often from a makeshift or portable stall.</li>
                <li>Street Food: Food obtainable from a street side vendor, often from a makeshift or portable stall.</li>
                <li>Street Vendors: Food obtainable from a street side vendor, often from a makeshift or portable stall.</li>
                <li>Strength: Food that has the quality or state of being strong and pronounced</li>
                <li>Strengthen: Foods that are consumed to strengthening of body</li>
                <li>Strengthening: Foods that are consumed to strengthening of body</li>
                <li>Stress Free: Consumption of food which caters to stress relief</li>
                <li>Stress Relief: Consumption of food which caters to stress relief</li>
                <li>Substitute: A product or ingredient acting or serving in place of another</li>
                <li>Substitutes: A drink, bar, soup, etc which is intended as a substitute for a solid food meal, usually with controlled quantities of calories and nutrients</li>
                <li>Sugar Substitute: A sugar substitute is a food additive that provides a sweet taste like that of sugar while containing significantly less food energy than sugar-based sweeteners, making it a zero-calorie or low-calorie sweetener</li>
                <li>Sugar Tax: Tax or surcharge designed to reduce consumption of drinks with added sugar</li>
                <li>Sugarfree: Associated with the food containing an artificial sweetening substance instead of sugar</li>
                <li>Summer: Consumption of food which is seasonal especially during summer</li>
                <li>Sun-dried: Food that is dried naturally by the heat of the sun</li>
                <li>Sunday Binge: Associated with consumption for Sunday Binge i.e. consuming more indulgent foods only on Sunday</li>
                <li>Sunny Weather: Preferred for consumption when the weather is sunny</li>
                <li>Superfood: Perceived as a superfood, i.e. food that have high nutrient density</li>
                <li>Supergrain: Blend of seven ancient grains including Flax, Chia, Quinoa, Amaranth, Buckwheat, Millet and Teff</li>
                <li>Supermarket: Bought, sold or available at a supermarket</li>
                <li>Supper: Consumption occasion of supper</li>
                <li>Sustainability: Focused on healthy food to meet current food needs while maintaining healthy ecosystems that can also provide food for generations to come with minimal negative impact to the environment</li>
                <li>Sustainable Packaging: Food and beverage items with packaging material that leads to improved sustainability</li>
                <li>Sweet: Having a sweet taste or flavour</li>
                <li>Swelling: Perceived to help with swelling</li>
                <li>Swimming: Suitable or preferred for consumption in context of swimming</li>
                <li>Swiss Cuisine: Food and drinks prepared in Swiss style or considered native to Switzerland</li>
                <li>Symptoms: Helping with certain symptoms</li>
                <li>Syrupy: Having a syrupy texture or taste</li>
                <li>Take-out: Available or suitable for take-out</li>
                <li>Takeaway Order: Available or suitable for takeaway order</li>
                <li>Takeaway: Available or suitable for takeaway</li>
                <li>Tamil Cuisine: Food and drinks prepared in Tamil style or considered native to Indian State Tamil Nadu</li>
                <li>Tangy: Having a tangy flavour or taste</li>
                <li>Taste: Taste refers to the sensation of flavour perceived in the mouth on contact with a substance. Taste covers a broad variety of Sensory Descriptors used most commonly to describe the taste of Food and Beverages</li>
                <li>Tasty: Perceived as having a good taste</li>
                <li>Tea Party: Consumption at a tea party</li>
                <li>Tea Time: Consumption at tea time</li>
                <li>Team Meet: Consumption in context of a team meet</li>
                <li>Team Outing: Consumption in context of a team outing</li>
                <li>Team Party: Consumption in context of a team party</li>
                <li>Teething Pain: Perceived to help with teething pain</li>
                <li>Temperature: Mentioning about preferred temperature of food and beverages for cooking or consumption</li>
                <li>Tennis: Consumption in context of a game of tennis</li>
                <li>Tetra Pack: Packaging of food or beverage in a tetra pack</li>
                <li>Texture: The feel, appearance, or consistency of a substance or ingredient</li>
                <li>Thai Cuisine: Food and drinks prepared in Thai style or considered native to Thailand</li>
                <li>Thanksgiving Day: Consumption on the occasion of Thanksgiving Day</li>
                <li>Three-Ingredient: Recipes that need three-ingredients only</li>
                <li>Tibetan Cuisine: Food and drinks prepared in Tibetan style or considered native to Tibet</li>
                <li>Tissues: Helping with health of tissues</li>
                <li>Togetherness: Consumed or cooked together, e.g. potluck, bake together, etc.</li>
                <li>Traceability: The quality of having an origin or course of development that may be found or followed</li>
                <li>Travel Pack: Available in a travel-friendly pack</li>
                <li>Treatment: Associated with treatment of certain condition</li>
                <li>Trekking: Consumption in context of trekking</li>
                <li>Tube: Available in a tube pack</li>
                <li>Under Quarantine: Suitable or preferred for consumption under quarantine</li>
                <li>Unique Flavour: Having a unique distinctive flavour</li>
                <li>Unique Pack: Having a unique distinctive packaging</li>
                <li>University: Consumption while at university</li>
                <li>Unsalted: Food or beverages without salt</li>
                <li>Unsweetened: Food or beverages without any sweetener or sugar</li>
                <li>Unwind: Suitable for consumption when unwinding alone or with friends and family</li>
                <li>Upcycled: Product that is claimed to be made by upcycling or suitable for upcycling, which is essentially the reuse (of discarded objects or material) in such a way as to create a product of higher quality or value than the original</li>
                <li>Upcycling: Reusing discarded objects or material to create a product of higher quality or value than original</li>
                <li>USDA Organic: Organic certification is a certification process for producers of organic food and other organic agricultural products in the US</li>
                <li>Vacations: Consumption during a vacation</li>
                <li>Valentine's Day: Consumption in context of Valentine's Day</li>
                <li>Value Pack: A big pack or pack with multiple sets grouped together for a significantly lower price</li>
                <li>Value: The importance, worth, or usefulness of a food or beverage item</li>
                <li>Variety Pack: A pack with an assortment of various types of food items</li>
                <li>Vegan: Diet or food items without any animal product like meat, seafood or dairy</li>
                <li>Vegetarian: Diet or food items without meat, seafood or eggs</li>
                <li>Video Streaming: Consumption when video streaming</li>
                <li>Vietnamese Cuisine: Food and drinks prepared in Vietnamese style or considered native to Vietnam</li>
                <li>Virus: Considered as suitable for consumption to prevent or treat the virus</li>
                <li>Vitality: Perceived to be rich in a combination of herbs and nutrients in the ideal dosages to optimize hormonal health in men</li>
                <li>Vitamin A: Perceived to be rich in vitamin A, an organic compound essential for nutrition</li>
                <li>Vitamin B: Perceived to be rich in vitamin B, an organic compound essential for nutrition</li>
                <li>Vitamin B12: Perceived to be rich in vitamin B12, an organic compound essential for nutrition</li>
                <li>Vitamin B6: Perceived to be rich in vitamin B6, an organic compound essential for nutrition</li>
                <li>Vitamin C: Perceived to be rich in vitamin C, an organic compound essential for nutrition</li>
                <li>Vitamin D: Perceived to be rich in vitamin D, an organic compound essential for nutrition</li>
                <li>Vitamin E: Perceived to be rich in vitamin E, an organic compound essential for nutrition</li>
                <li>Vitamin Fortified: Food or drink that has nutrients added to it that do not naturally occur in it</li>
                <li>Vitamin K: Perceived to be rich in vitamin K, an organic compound essential for nutrition</li>
                <li>Volatile: An ingredient that easily evaporates at normal temperatures. Also used to describe food that is liable to change rapidly and unpredictably, especially for the worse</li>
                <li>Volleyball: Consumption in context of a game of volleyball</li>
                <li>Warm: Suitable for consumption in warm weather. Could also imply that the item is preferred for consumption when warm</li>
                <li>Wastage: The action or process of losing or destroying something by using it carelessly or extravagantly</li>
                <li>Waste free: Claim that the product or practice is waste free, i.e. creating little or no waste</li>
                <li>Weaning: The process of gradually introducing an infant to what will be its adult diet while withdrawing the supply of its mother's milk</li>
                <li>Webinar: A seminar conducted over the internet</li>
                <li>Wedding: Consumption in context of a wedding</li>
                <li>Weight Loss: Suitable for weight loss</li>
                <li>Weight: Suitable for consumption when weight watching</li>
                <li>Western-Indian Cuisine: Food and drinks prepared in West-Indian style or considered native to Western Indian region</li>
                <li>Wet Market: Available,bought or sold at a wet market</li>
                <li>Whipped: Made by whisking or beating to turn into a froth</li>
                <li>Winter Solstice: Consumption in context of winter solstice, that marks the onset of winter</li>
                <li>Winter: Consumed during the winter season</li>
                <li>Women's Health: Suitable for improving women's health</li>
                <li>Woody: Having a woody taste, fragrance, flavour or texture</li>
                <li>Working Lunch: Consumption at a working lunch</li>
                <li>Workout and Cardio: Suitable for consumption when working out or doing cardio</li>
                <li>Yeast-Free: Claim that the product is free from yeast</li>
                <li>Yoga: Consumed in context of the practice of Yoga</li>
                <li>Zero Waste: Claim that the product is focused on waste prevention and could be reused</li>
                <li>Zesty: Having a strong, pleasant, and somewhat spicy flavor</li>
                <li>Zinc: Rich in Zinc, a mineral that is essential to the body and is a constituent of many enzymes that permit chemical reactions to proceed at normal rates</li>
                <li>Zip Lock: Packed in a sealable plastic bag with a two-part strip along the opening that can be pressed together and readily reopened</li>
              </ol> 
            </>
            ), 
        },
      ],    
    },
    {
      topic: "Understanding our Data",
      qAndA: [
        {
          question: "How do I read the Trends Classification Map?",
          answer:(
            <>
             Each trend in your chosen category has been mapped based on the volume of engagement and its growth. They are categorised into 6 trends:<br/>
            <ol className="sw-faq__cncpt-tips">
              <li>
                Dormant: Low volume of engagement, Low growth
              </li>
              <li>
                Emerging: Low volume of engagement, High growth
              </li>
              <li>
                Growing: High volume of engagement, High growth
              </li>
              <li>
                Mature: High volume of engagement, Low growth
              </li>
              <li>
                Declining: High volume of engagement, Declining growth
              </li>
              <li>
                Fading: Low volume of engagement, Declining growth
              </li>
              <li>
                Trends are classified not just based on historical data but also on anticipating
                what is going to come next, based on Consumer Behaviour online, Menu items & Retail Products. 
                If a trend is classified as growing, it implies that there is still room for it to grow as there
                is still consumer interest and engagement with that trend.
              </li >
            </ol>
            </>
          )
        },
        {
          question: "Are individual trends being classified into subcategories? Can I filter the map to see subcategories of the trends?",
          answer:(
            <>
              Yes, on the top-left corner of the page, you may click on the drop down menu to see the subcategories of each trend.<br/>
              <br/>
              <img className="img_faq" src={subCategoriesOfTrend} />
            </>
            ),   
        },
        {
          question: "Can I filter the map to see a specific trend type?",
          answer: (
            <>
              Yes. To see only a specific trend, you may unselect other undesired trends on the top-right “Select Trends” bar. For example, to see only Emerging trends, unselect Dormant, Growing, Mature, Declining, and Fading.<br/>
              <br/>
              <img className="img_faq" src={specificTrendType} />
            </> 
            ),
        },
        {
          question: "How do I learn more about the trends in each trend category?",
          answer: (
            <>
              Below the Trends Classification Map, you may find the top trends in each category.<br/>
              <br/>
              <img className="img_faq" src={trendsCategory} /> 
            </>
          ),
        },
        {
          question: "How do I sort trends?",
          answer:
            (
              <>
                The sorting feature allows you to sort trends based on Overall Rank, Volume of Engagement, and Growth Rate; either by year-on-year or month-on-month.<br/>
                For example, If you want to discover the Growing trends with the Highest Consumer Interest for Fruits and Vegetables Snacks, filter for “Fruits and Vegetables”, scroll down to the Trend Classification table, and select Volume. <br/>
                This sorting function is found in the Trend Classification and the Consumer Needs section, to provide ease of access to information that is tailored to your needs.<br/>
                <br/>
                <img className="img_faq" src={sortFeatures} />   
              </>
            ),
        },
      ],  
    },
    {
      topic: "Predictive Analytics",
      qAndA: [
        {
          question: "How does Predictive Analytics work?",
          answer:(
            <>
              The Foresight Engine can predict the future trajectory of a trend. Ai Palette's algorithm uses historical data to identify what trends will scale and gain popularity with consumers in the next six months.<br/>
              Our algorithm is trained on six years of historical data from social media and search that gives a more accurate prediction. We constantly test the algorithm's accuracy by seeing which predicted trends actually happen.
            </>
          ),
        },
        {
          question: "How can I see the predicted trend on the Foresight Engine?",
          answer:
          (
            <>
               The predictive analytics model predicts the future trajectory for trends. On every trend page, the Blue line below shows the trend predicted for the next 6 months for Cranberry in the Beverages category.<br/>
               <br/>
               <img className="img_faq" src={trendGrowthChart} />
            </>
          ), 
        },
        {
          question: `Why do some trends say that there is "No sufficient data to predict"?`,
          answer:
            (
              <>
                For trends typically in the Dormant or Fading category, the volume of data is very small as these trends are still niche. Every predictive analytics model requires a certain minimum volume of data to be able to make predictions. This message will appear when the data is insufficient for the model to make a prediction to an acceptable degree of confidence.<br/>
                <img className="img_faq" src={marketTrend} />
              </>
            ),
        },
      ],   
    },
    {
      topic: "Consumer Insights",
      qAndA: [
        {
          question: "How do I learn more about the consumer needs of my food category?",
          answer:(
           <>
            In the Consumer Insights section, you will find consumer needs of your food category separated into Driver, Barrier, Expressed Persona, Product Attribute, Format, Occasion, and Pairing.<br/>
            This section enables you to understand consumers based on their organic conversations online. In the example below, the top drivers of this food category are Health, Taste, and Beauty and Youth. From this information, you may infer that consumers prioritise the health benefits of a product. This is derived from topics discussed by people stating that a product is “beneficial for their wellbeing” or “helps reduce blood glucose levels.”<br/><br/>
            <img className="img_faq" src={consumerInsights} /><br/>
            <br/>
           </>
          ),
        },  
        {
          question: "How do I learn more about which consumer persona a trend is most popular with?",
          answer:
            (
              <>
                In the Consumer Insights section, the Foresight Engine identifies the Expressed Persona of your food category based on various data sources like consumer quotes in social media. This is the target consumer profile that is expressed in the consumer conversations.<br/>
                <br/>
                In the example below, the majority of our data found that this particular food is mainly bought for mothers.<br/>
                <br/>
                <img className="img_faq" src={consumerPersona} /> <br/>
                Clicking on the “For Mothers” button will direct you to more information on this consumer group. The sentiments for this consumer is largely positive due to consumer quotes on the subject.<br/>
                <br/>
                <img className="img_faq" src={consumerGroup} /> <br/>
                The consumer quotes come from several data sources like social media, and news and discussion forums. <br/>
                <br/>
                <img className="img_faq" src={consumerQuotes} /> <br/>
              </>
            ),
        },
        {
          question: "What is a Product Attribute and why is it important?",
          answer:
            (
              <>
                Product attributes are essential in knowing what consumers are looking out for in their decision making process. Product attributes can range from taste, temperature of the food, or texture. To find out more, click on a specific product attribute.<br/>
                <br/><br/>
                <img className="img_faq" src={productAttribute} /> <br/>
                In the example below, texture of a food product can be separated into topics like oily, gooey, cream, and velvety. These topics are derived from consumer quotes to help you understand what people think of a certain food, and if there are any positive sentiments tied to it.<br/><br/>
                <img className="img_faq" src={foodProductTexture} /> <br/>
              </>
            ),
        },
        {
          question: "What is “Format” in Consumer Insights?",
          answer:
            (
              <>
                Format in Consumer Insights refers to a specific classification or a type of food that would fall under a food product.<br/>
                This specificity helps you understand what type of format people are regularly consuming relating to your food category. To find out more, click on a specific format.<br/>
                <br/>  
                <img className="img_faq" src={format} />
              </>
            ),
        },
        {
          question: "Can I identify the top trends under the “Taste” consumer need?",
          answer:
            (
              <>
               Yes. Clicking on any consumer need icon will direct you to more information on the topic. Below the graph, you will find top trends related to taste based on organic conversations online. In this instance, Chinese Bayberry, Ume, Cashew, Grape, and Plum are the top five trends under the “Taste” consumer need.<br/>
               <br />
               <img className="img_faq" src={taste} />
               <br/>
               <br/>
               In this subsection, you may find more information on the topics relating to “taste”; like flavour, sweetness and even richness of the food category.<br/>
              </>
            ),
        },
        {
          question: "What are sentiments and why are they important?",
          answer:
            (
              <>
              <ol className="sw-faq__cncpt-tips">
               <li>
                 Sentiments in the Foresight Engine are consumer sentiments and feelings
                 towards a particular trend or need, gathered from a volume of engagement online.
                </li>
               <li>
                 Sentiments help you understand the positive reasons driving the consumption
                 of the product and if there are any negative associations with that product.
                </li>
               </ol>
              </>
            ),
        },
        {
          question: "How do I understand the maturity of a trend in different subcategories?",
          answer:
            (
              <>
                The Foresight Engine can help you analyze what is the maturity of a trend in each subcategory. For example, if you want to understand the maturity of Black Sesame in different beverage sub-categories, you can go to the trend page of Black Sesame. At a Beverage Category level the Black Sesame trend is classified as a Growing Trend based on engagement online, number of search queries, growth rate, number of retail and cafe products.<br/>
                It is an Matured Trend in Energy Drink and Dairy subcategories based on engagement online, number of search queries, growth rate, number of retail and cafe products.
              </>
            ),
        },
        {
          question: "How do I explore the different products for a trend based on the different formats?",
          answer:
          (
            <>
              For every trend, the Foresight Engine captures analytics on what are the different types of products in the Cafe and Retail space. In the example below, for the trend of Almond Milk you can see that in Food service; Dairy and Coffee are  popular formats. In Retail products, dairy is a popular format.<br/>
              <br/>
              <img className="img_faq" src={foodServiceProducts} />
            </>
          ),
        },
        {
          question: "Can I find out what people are talking about in a specific driver/motivation?",
          answer:
            (
              <>
                The Foresight Engine identifies drivers and motivators in your food category. Each topic can be clicked to see the individual volume performance, sentiment, and engagement score.<br/>
                <br/>
                <img className="img_faq" src={foodCategory} /><br/>
                The Foresight Engine pulls data from organic conversations online and presents them in the “Topics” section.<br/>
                <br/>
                <img className="img_faq" src={topicSection} /><br/> 
              </>
            ),
        },  
      ],  
    },
    {
      topic: "Semantics Dictionary",
      qAndA: [
        {
          question: "How does the Foresight Engine define a sub-category? ",
          answer:(
            <>
              The Foresight Engine defines a sub-category from the Consumer’s point of view. This is sometimes slightly different from a retailer’s category definition, as it takes into account the driver and consumption occasion according to the consumer to define the boundaries of a category and sub-category.<br/>
              For example, from a retailers point of view Snacks are limited to products like Chips, Crisps and Wafers. Looking at the Snacks category from the consumer’s point of view, we also include other snacks like Vegetable sticks and Energy Bars.
            </>
          )
        },
        {
          question: "Which Sub-categories are covered under Beverages?",
          answer:
            (
              <>
                The Foresight Engine covers the following sub-categories
                <ol className="sw-faq__cncpt-tips">
                <li>
                  Tea: Tea covers hot or cold drinks typically made by infusing the dried crushed leaves
                  of different varieties of the tea plant in boiling water
                </li>
                <li>
                  Coffee: Coffee covers coffee made from roasted coffee beans and decaffeinated 
                  coffee alternatives like flavoured Lattes such as Purple Potato Latte and Charcoal Latte
                </li>
                <li>
                  Juice: Juice is a drink made from the extraction or pressing
                   of the natural liquid contained in fruit and vegetables.
                </li>
                <li>
                  Carbonates: Carbonates or Sodas are beverages that contain dissolved carbon dioxide. 
                  The dissolution of CO2 in a liquid, gives rise to fizz or effervescence.
                </li>
                <li>
                  Energy Drink: Energy Drink covers drinks which are consumed for mental and physical stimulation
                  and satiation. This sub-category covers Energy Drinks which :containing stimulant compounds like 
                  caffeine as well as Meal Replacement drinks and other drinks that consumers derive energy from. 
                  They may or may not be carbonated and may also contain sugar, other sweeteners, herbal extracts, 
                  taurine, and amino acids.
                </li>
                <li>
                  Hydration: Hydration sub-category covers drinks where thirst-quenching is the key driver for consumption
                </li>
                <li>
                  Dairy: Dairy covers Milk and as well as alternative Non-Dairy Milk from other vegetable sources like Walnut Milk
                </li>
                <li>
                  Smoothie: A Smoothie is a thick and creamy beverage made from pureed raw fruit, vegetables, 
                  and sometimes dairy products e.g. milk, yogurt, typically using a blender
                </li>
              </ol>
              </>
            ),
        },
        {
          question: "Which Sub-categories are covered under Snacks? ",
          answer:(
          <>
            The Foresight Engine covers the following sub-categories under Snacks. The Foresight Engine covers Snacks from a Consumer's perspective
            <ol className="sw-faq__cncpt-tips">
             <li> 
               Fruits and Vegetables: Fruits and Vegetables covers Snacks
               made from Fruits and Vegetables in different formats such as Dried forms, Crisps, 
               Chips and Crackers or Fruit and Vegetable flavoured Snacks
             </li>
             <li>
               Nuts and Seeds: Nuts and Seeds covers Snacks which contain Nuts and Seeds in different forms
             </li>
             <li>
               Snack Mixes: Snack Mixes are a subset of Snacks consisting of multiple snack items such as
               Trail Mixes, Fruit Snack mixes etc.
              </li>
             <li>
               Grain Snacks: Grain Snacks consist of Snacks containing grains such as Wheat and different
               forms of Wheat, Granola, Oat, Rice etc.
             </li>
             <li>
               Fruit, Nut and Grain Bars: Fruit, Nut and Grain Bars sub-category covers products in a Bar format.
              </li>
             <li>
               Meat Snacks: Meat Snacks can be made from a variety of Meats such as Beef, Chicken, Pork and Sea 
               food or flavoured with Meat and covers popular formats such as Jerky.
              </li>
             <li>
               Popcorn: Popcorn covers a variety of corn kernel which expands and puffs up when heated; the same
               names are also used to refer to the foodstuff produced by the expansion
             </li>
            </ol>
          </>
          )
        },
        {
          question: "What Consumer Insights does the Foresight Engine capture?",
          answer:
            (
              <>
               The Foresight Engine is designed to help you answer all the questions which go into the Innovation process. We want to give you a nuanced understanding of what is driving a particular trend. Also, we understand that the briefs you receive might approach innovation from different viewpoints and we have built the Foresight Engine platform to give you that flexibility<br/>
               <br/>
               The Foresight Engine captures Consumer Insights from many different angles such as:<br/>
               <ol className="sw-faq__cncpt-tips">
                 <li>
                   Drivers: Why are consumers consuming a certain product? What Functional
                   and Sensory drivers is the product satisfying? For example, Health, Taste,
                   Beauty and Youth, Freshness etc.
                 </li>
                 <li>
                   Barriers: What is stopping a consumer from purchasing a particular product? 
                   For example Price, Health, Taste, Not for Children, Distribution etc.
                 </li>
                 <li>
                   Expressed Persona: Which consumer persona is expressed in Consumer conversations
                   around a particular trend. This should help you understand who is consuming a 
                   particular Food and Beverage product. For example Children, Elders. Since this
                   analyzes consumer conversations, some personas that are not as frequently mentioned 
                   are not captured
                  </li>
                 <li>
                   Product Attribute: What product attributes and claims are popular with consumers? 
                   What sensory descriptors do consumers use to describe the product? For example 
                   Beverage Format, Temperature, Texture, Method of Preparation etc.
                  </li>
                 <li>
                   Occasion: Where and When are consumers eating or drinking a particular product? 
                   For example Breakfast, Lunch, Dinner, Dessert etc.
                  </li>
                 <li>
                   Pairing: What are consumers and Key Opinion Leaders Pairing an ingredient/product
                   with? We help you understand which kind of ingredient it is being paired with. For
                   example Fruit Pairing, Dairy Pairing, Vegetable Pairing. You can also dig deeper to 
                   understand which ingredients in particular are being paired.
                  </li>
               </ol>
              </>
            ),
        },
        {
          question: "What do each of the Drivers and Barriers mean?",
          answer:
            (
              <>
              The Foresight Engine defines each Driver as a keyword and all the root words associated with that keyword. For example, Health as a Driver will not only capture Health in different forms like Healthy but will also capture all the topics associated with Health such as Heart health, weight loss, fitness, protein etc.<br/>
              <ol className="sw-faq__cncpt-tips">
                <li>
                  Action: Function or role of a particular ingredient i.e. how it works
                </li>
                <li>
                  Appearance: How the appearance of an ingredient impacts consumption
                </li>
                <li>
                  Authentic: Genuineness and Purity of an ingredient
                </li>
                <li>
                  Balance: Even distribution of sometimes opposing taste and flavours
                </li>
                <li>
                  Beauty and Youth: Ingredients that are known to have a positive impact 
                  on beauty, skin and outwardly appearance
                </li>
                <li>
                  Boiled: A product that is made by the process of boiling
                </li>
                <li>
                  Brand: A brand is a particular identity or image regarded as an asset
                </li>
                <li>
                  Brew: A product that is made by brewing
                </li>
                <li>
                  Brightness: Brightness as a visually appealing attribute of a food or beverage product
                </li>
                <li>
                  Caffeinated: Beverage or food containing Caffeine
                </li>
                <li>
                  Clarity: Clear or transparent nature of a product, sometimes seen as a sign of purity
                  </li>
                <li>
                  Color: Color of a product which makes it visually striking and appealing
                </li>
                <li>
                  Comfort: Refers to a product which provides comfort
                </li>
                <li>
                  Concentrated Extract: Concentrated extract used to prepare a food or beverage product
                </li>
                <li>
                  Distribution: Channels through which a product is made available to the consumer
                </li>
                <li>
                  Eat Clean: Eating clean encourages a lifestyle approach of a diet free from sugar, 
                  saturated fat, trans fat, preservatives, artificial ingredients, with more unprocessed
                  foods and regular exercise.
                </li>
                <li>
                  Excess: Lack of moderation, in eating or drinking. When an amount of an item consumed 
                  is more than necessary, permitted, or desirable
                </li>
                <li>
                  Extraction: The process of removing or distilling
                </li>
                <li>
                  Feeling: When consuming a product is strongly associated with a feeling or sensation
                </li>
                <li>
                  Form: A particular visible shape, type or variety in which an ingredient exists or is used
                </li>
                <li>
                  Format: Medium in which a food or beverage item is consumed in. For example
                  a beverage format like Tea, Juice etc.
                </li>
                <li>
                  Formula: A list of ingredients with which a food or beverage item is made
                </li>
                <li>
                  Fragile: Easily broken or damaged
                </li>
                <li>
                  Fragrance: The quality or state of having a sweet, pleasant or distinct odour
                </li>
                <li>
                  Fresh: Recently made or obtained i.e. not preserved. Can also refer to
                  a trend being new or different
                </li>
                <li>
                  Gift: A present given willingly to someone
                </li>
                <li>
                  Health: The state of being free from illness or injury. Covers a variety 
                  of topics associated with a person’s physical or mental condition
                </li>
                <li>
                  Herbal: An ingredient that relates to or is made from herbs and their culinary
                  and medicinal properties
                </li>
                <li>
                  Light: Visually an item that is brighter and paler than its surroundings. 
                  Can also refer to a taste that is gentle, not strong and severe
                </li>
                <li>
                  Method of Preparation: Process by which a certain product is prepared. 
                  Covers a wide variety of cooking and preparation methods
                </li>
                <li>
                  Moisture Content: The quantity of water contained in a material
                </li>
                <li>
                  Natural: Existing in and derived from nature, not made by artificial means    
                </li>
                <li>
                  Nourishment: Food associated as being necessary for growth, health, and good condition
                </li>
                <li>
                  Price: Money paid to purchase a certain item
                </li>
                <li>
                  Quality Criteria: The degree of excellence of a Food or Beverage product or brand
                </li>
                <li>
                  Quantity: The amount or number of a material or ingredient specified
                </li>
                <li>
                  Refreshing: A food or beverage serving to refresh or reinvigorate someone. 
                  Also used to describe welcome or stimulating because new or different
                </li>
                <li>
                  Result: a consequence or an outcome that is caused or produced
                </li>
                <li>
                  Safety: The condition of being protected from or unlikely to cause danger, risk, or injury
                </li>
                <li>
                  Size: Rel
                  ative extent or overall dimensions of an item
                </li>
                <li>
                  Smooth: Having an even and regular surface; free from perceptible projections, lumps, or 
                  indentations. For a liquid, it means with an even consistency or without lumps. Smooth is 
                  also used to refer to aftertaste, like a liquid that does not leave any lingering taste in your throat or mouth
                </li>
                <li>
                  Speed: The rate at which a food or beverage item acts or provides a desired impact
                </li>
                <li>
                  Strength: Food that has the quality or state of being strong and pronounced
                </li>
                <li>
                  Substitute: A product or ingredient acting or serving in place of another
                </li>
                <li>
                  Taste: Taste refers to the sensation of flavour perceived in the mouth on contact
                  with a substance. Taste covers a broad variety of Sensory Descriptors used most commonly
                  to describe the taste of Food and Beverages
                </li>
                <li>
                  Temperature Preference: The temperature at which a Beverage or Food item is most commonly consumed
                </li>
                <li>
                  Texture: The feel, appearance, or consistency of a substance or ingredient
                </li>
                <li>
                  Transparent: A food item allowing light to pass through so that objects behind can be distinctly seen
                </li>
                <li>
                  Value: The importance, worth, or usefulness of a food or beverage item
                </li>
                <li>
                  Volatile: An ingredient that easily evaporates at normal temperatures. Also used to describe food that
                  is liable to change rapidly and unpredictably, especially for the worse
                </li>
              </ol>
              </>
            ),
        },     
      ],   
    },
    {
      topic: "Search",
      qAndA: [
        {
          question: "How do I search for a specific trend? ",
          answer:
          (
            <>
              To find a specific trend, type your desired trend name into the search box. The search results will show you exact trends as well as related entries. Related trends are usually trends that are paired with what you searched.<br/>
              <br/>
              <img className="img_faq" src={searchSpecificTrend} /><br/>
            </>
          ),   
        },
        {
          question: "Can I search for a specific topic or consumer need?",
          answer:
            (
              <>
                To find a specific topic, type the topic you want to learn more about into the search box. The search results will provide you the exact topic you desire, and any related trends based on your search entry.<br/>
                <br/>
                <img className="img_faq" src={topicSearch} /><br/>
                On the same results page, you will also find additional information on where these related trends are consumed (and in what format).<br/>
                <br/>
                <img className="img_faq" src={extraInfoRelToSpecificTopic} /><br/>
                More information on retail products related to your search entry is also displayed below the page.<br/>
                <br/>
                <img className="img_faq" src={relProdAndCat} /><br/>
              </>
            ),
        },
        {
          question: "Can I download the data on the Foresight Engine or any information I want? ",
          answer:
          (
            <>
              Yes. Simply click on the download icon on the top-right corner of each section. Note that this download feature is applicable on graphs, charts, sentiments, and consumer needs sections of the Foresight Engine (as shown below).<br/>
              <br/>
              <img className="img_faq" src={downloadChart} /><br/>
              <br/>
              <img className="img_faq" src={downloadData} /><br/>
            </>
          ),     
        },      
      ],  
    },
  ]
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
   <div>
    <div className="sw-faq">
      <h1 className="sw-faq__heading">FAQs</h1>
      <p className="sw-faq__sub-title">Need answers? Find them here..</p> 
      {(location.pathname.includes("/screen-winner")?qAndASections:faqQAndASections).map(({ topic, qAndA }) => (
        <div className="sw-faq__topic__block">
          <h2 className="sw-faq__topic">{topic}</h2>
          <div className="sw-faq__topic__content__block">
            {qAndA.map(({ question, answer }) => (
              <div className="sw-faq__topic__content">
                <Accordian
                  heading={{
                    expanded: (
                      <h3 className="sw-faq__topic__question">
                        <img
                          className="sw-faq__topic__question__img"
                          src={collapseMinus}
                          alt="Collapse icon"
                        />
                        {question}
                      </h3>
                    ),
                    collapsed: (
                      <h3 className="sw-faq__topic__question">
                        <img
                          className="sw-faq__topic__question__img"
                          src={expandPlus}
                          alt="Expand icon"
                        />
                        {question}
                      </h3>
                    ),
                  }}
                  content={<p className="sw-faq__topic__answer">{answer}</p>}
                />
              </div>
            ))}
          </div>
        </div>
      ))}     
    </div>
   </div>
  );
};

export default SWFAQ;
