import { ReactComponent as DormantIcon } from '../../assets/icons/trend-dormant.svg'
import { ReactComponent as EmergingIcon } from '../../assets/icons/trend-emerging.svg'
import { ReactComponent as GrowingIcon } from '../../assets/icons/trend-growing.svg'
import { ReactComponent as MatureIcon } from '../../assets/icons/trend-mature.svg'
import { ReactComponent as DecliningIcon } from '../../assets/icons/trend-declining.svg'
// eslint-disable-next-line import/prefer-default-export

export const trendTypes = [
  {
    id: 'DORMANT',
    title: 'Dormant',
    description: 'Low volume with negligible rate of growth',
    typer: 0,
    icon: DormantIcon,
  },
  {
    id: 'EMERGING',
    description: 'Low volume with positive rate of growth',
    title: 'Emerging',
    typer: 1,
    icon: EmergingIcon,
  },
  {
    id: 'GROWING',
    title: 'Growing',
    description: 'High volume with positive rate of growth',
    typer: 2,
    icon: GrowingIcon,
  },
  {
    id: 'MATURED',
    title: 'Matured',
    description: 'High volume with negligible rate of growth',
    typer: 3,
    icon: MatureIcon,
  },
  {
    id: 'DECLINING',
    title: 'Declining',
    description: 'High volume with negative rate of growth',
    typer: 4,
    icon: DecliningIcon,
  },
  {
    id: 'FADING',
    title: 'Fading',
    description: 'Low volume with negative rate of growth',
    typer: 5,
    icon: DecliningIcon,
  },
]

export const currencies = {
  USD: '$',
  EURO: '€',
  GBP: '£',
  RUB: '₽',
  INR: '₹',
  CNY: '¥',
  AUD: '$',
  BRL: 'R$',
  THB: '฿',
  PHP: '₱',
  IDR: 'Rp',
  CAD: '$',
}

export const tradeMedia = [
  'IFT',
  'Nutraingredients',
  'EatRightPro',
  'American Society for Nutrition',
  'FoodDive',
  'FoodNavigator',
  'FoodScienceandNutrition',
  'Hindawi',
  'TaylorandFrancisOnline',
  'BakeryaAndSnacks',
  'ConfectioneryNews',
  'BritishJournalofNutrition',
  'Snack Food and Wholesale Bakery',
  'CandyIndustry',
  'PR Newswire',
  'ABCNews',
  'CBC',
  'CNN',
  'ScienceDirect',
  'MDPI',
  'FoodInCanada',
  'AcademicJournal',
  'Science Alert',
  'CanadianFoodBusiness',
  'Nature',
  'EuropeanJournalofNutritionandFoodSafety',
  'AsianFoodScienceJournal',
  'Longdom',
  'FoodAndBeverageNewsCanada',
  'myFoodResearch',
  'FMCGMagazine',
  'FoodDrinkNetwork',
  'Unesda',
  'FoodDrinkEurope',
  'Independent',
  'BbcGoodFood',
  'JapanFoodJournal',
  'JapanFood',
  'KoreaHerald',
  'FoodBeverageAsia',
  'BbcGoodFood',
  'FdBusiness',
]

export const ENG_LANG = 'English'

export const projectCCL = {
  1: { Country: 'Thailand', Category: 'Beverage', Language: 'Thai' },
  11: { Country: 'Thailand', Category: 'Alcoholic Beverage', Language: 'Thai' },
  243: { Country: 'Thailand', Category: 'Dairy', Language: 'Thai' },
  676: { Country: 'Thailand', Category: 'Food', Language: 'Thai' },
  665: { Country: 'Thailand', Category: 'Plant-based', Language: 'Thai' },
  413: {
    Country: 'Thailand',
    Category: 'Food Service Beverage',
    Language: 'Thai',
  },
  152: {
    Country: 'Thailand',
    Category: 'Food Service Savoury',
    Language: 'Thai',
  },
  451: { Country: 'Thailand', Category: 'Ready-to-Eat', Language: 'Thai' },
  417: { Country: 'Thailand', Category: 'Snacks', Language: 'Thai' },
  878: { Country: 'Thailand', Category: 'Confectionery', Language: 'Thai' },
  881: { Country: 'Thailand', Category: 'Bakery', Language: 'Thai' },

  662: { Country: 'Asia', Category: 'Sugar Reduction', Language: ENG_LANG },

  383: {
    Country: 'Australia',
    Category: 'Alcoholic Beverage',
    Language: ENG_LANG,
  },
  482: { Country: 'Australia', Category: 'Beverage', Language: ENG_LANG },
  516: { Country: 'Australia', Category: 'Confectionery', Language: ENG_LANG },
  668: { Country: 'Australia', Category: 'Ready-to-Eat', Language: ENG_LANG },
  659: { Country: 'Australia', Category: 'Snacks', Language: ENG_LANG },
  685: { Country: 'Australia', Category: 'Dairy', Language: ENG_LANG },
  683: { Country: 'Australia', Category: 'Bakery', Language: ENG_LANG },
  781: { Country: 'Australia', Category: 'All Snacking', Language: ENG_LANG },
  883: { Country: 'Australia', Category: 'Plant-based', Language: ENG_LANG },

  653: {
    Country: 'Brazil',
    Category: 'Alcoholic Beverage',
    Language: 'Portugese',
  },
  654: { Country: 'Brazil', Category: 'Beverage', Language: 'Portugese' },
  978: { Country: 'Brazil', Category: 'Bakery', Language: 'Portugese' },
  979: { Country: 'Brazil', Category: 'Dairy', Language: 'Portugese' },
  1043: { Country: 'Brazil', Category: 'Ready-to-Eat', Language: 'Portugese' },
  980: { Country: 'Brazil', Category: 'Snacks', Language: 'Portugese' },
  1341: { Country: 'Brazil', Category: 'Confectionery', Language: 'Portugese' },

  20: { Country: 'Canada', Category: 'Confectionery', Language: ENG_LANG },
  380: { Country: 'Canada', Category: 'Beverage', Language: ENG_LANG },
  381: { Country: 'Canada', Category: 'Snacks', Language: ENG_LANG },
  382: {
    Country: 'Canada',
    Category: 'Alcoholic Beverage',
    Language: ENG_LANG,
  },
  684: { Country: 'Canada', Category: 'Plant-based', Language: ENG_LANG },
  779: { Country: 'Canada', Category: 'Bakery', Language: ENG_LANG },
  944: { Country: 'Canada', Category: 'Ready-to-Eat', Language: ENG_LANG },

  4: { Country: 'China', Category: 'Beverage', Language: 'Mandarin' },
  8: { Country: 'China', Category: 'Snacks', Language: 'Mandarin' },
  185: {
    Country: 'China',
    Category: 'Food Service Savoury',
    Language: 'Mandarin',
  },
  418: { Country: 'China', Category: 'Dairy', Language: 'Mandarin' },
  241: { Country: 'China', Category: 'Food', Language: 'Mandarin' },
  446: {
    Country: 'China',
    Category: 'Food Service Beverage',
    Language: 'Mandarin',
  },
  581: { Country: 'China', Category: 'Ready-to-Eat', Language: 'Mandarin' },
  670: {
    Country: 'China',
    Category: 'Alcoholic Beverage',
    Language: 'Mandarin',
  },
  672: { Country: 'China', Category: 'Confectionery', Language: 'Mandarin' },
  661: { Country: 'China', Category: 'Bakery', Language: 'Mandarin' },
  1176: { Country: 'China', Category: 'Plant-based', Language: 'Mandarin' },

  651: {
    Country: 'Colombia',
    Category: 'Alcoholic Beverage',
    Language: 'Spanish',
  },
  652: { Country: 'Colombia', Category: 'Beverage', Language: 'Spanish' },
  1439: { Country: 'Colombia', Category: 'Snacks', Language: 'Spanish' },
  1472: { Country: 'Colombia', Category: 'Confectionery', Language: 'Spanish' },

  663: { Country: 'Europe', Category: 'Sugar Reduction', Language: ENG_LANG },

  657: { Country: 'France', Category: 'Bakery', Language: 'French' },
  713: { Country: 'France', Category: 'Beverage', Language: 'French' },
  720: { Country: 'France', Category: 'Confectionery', Language: 'French' },
  722: { Country: 'France', Category: 'Dairy', Language: 'French' },
  714: { Country: 'France', Category: 'Snacks', Language: 'French' },
  1208: { Country: 'France', Category: 'Plant-based', Language: 'French' },
  1209: {
    Country: 'France',
    Category: 'Alcoholic Beverage',
    Language: 'French',
  },
  1340: { Country: 'France', Category: 'Ready-to-Eat', Language: 'French' },
  1343: { Country: 'Spain', Category: 'Snacks', Language: 'Spanish' },
  1373: { Country: 'Italy', Category: 'Snacks', Language: 'Italian' },

  244: { Country: 'Germany', Category: 'Bakery', Language: 'German' },
  715: { Country: 'Germany', Category: 'Beverage', Language: 'German' },
  716: { Country: 'Germany', Category: 'Snacks', Language: 'German' },
  717: { Country: 'Germany', Category: 'Confectionery', Language: 'German' },
  719: { Country: 'Germany', Category: 'Dairy', Language: 'German' },
  1109: { Country: 'Germany', Category: 'Ready-to-Eat', Language: 'German' },
  885: {
    Country: 'Germany',
    Category: 'Alcoholic Beverage',
    Language: 'German',
  },
  1241: { Country: 'Germany', Category: 'Plant-based', Language: 'German' },

  245: { Country: 'India', Category: 'Bakery', Language: 'Hindi' },
  7: { Country: 'India', Category: 'Beverage', Language: 'Hindi' },
  582: { Country: 'India', Category: 'Confectionery', Language: 'Hindi' },
  350: { Country: 'India', Category: 'Dairy', Language: 'Hindi' },
  675: { Country: 'India', Category: 'Food', Language: 'Hindi' },
  2: { Country: 'India', Category: 'Health and Wellness', Language: 'Hindi' },
  548: { Country: 'India', Category: 'Ready-to-Eat', Language: 'Hindi' },
  9: { Country: 'India', Category: 'Snacks', Language: 'Hindi' },

  12: {
    Country: 'Indonesia',
    Category: 'Alcoholic Beverage',
    Language: 'Bahasa',
  },
  251: { Country: 'Indonesia', Category: 'Bakery', Language: 'Bahasa' },
  13: { Country: 'Indonesia', Category: 'Beverage', Language: 'Bahasa' },
  188: { Country: 'Indonesia', Category: 'Confectionery', Language: 'Bahasa' },
  242: { Country: 'Indonesia', Category: 'Dairy', Language: 'Bahasa' },
  677: { Country: 'Indonesia', Category: 'Food', Language: 'Bahasa' },
  187: { Country: 'Indonesia', Category: 'Plant-based', Language: 'Bahasa' },
  656: {
    Country: 'Indonesia',
    Category: 'Food Service Beverage',
    Language: 'Bahasa',
  },
  450: { Country: 'Indonesia', Category: 'Ready-to-Eat', Language: 'Bahasa' },
  281: { Country: 'Indonesia', Category: 'Snacks', Language: 'Bahasa' },

  673: { Country: 'Japan', Category: 'Beverage', Language: 'Japanese' },
  674: { Country: 'Japan', Category: 'Ready-to-Eat', Language: 'Japanese' },
  53: { Country: 'Japan', Category: 'Snacks', Language: 'Japanese' },
  746: {
    Country: 'Japan',
    Category: 'Alcoholic Beverage',
    Language: 'Japanese',
  },
  884: { Country: 'Japan', Category: 'Confectionery', Language: 'Japanese' },
  1142: { Country: 'Japan', Category: 'Plant-based', Language: 'Japanese' },
  1010: {
    Country: 'Japan',
    Category: 'Dairy',
    Language: 'Japanese',
  },
  945: {
    Country: 'Japan',
    Category: 'Bakery',
    Language: 'Japanese',
  },

  679: { Country: 'Malaysia', Category: 'Food', Language: 'Malay' },
  416: { Country: 'Malaysia', Category: 'Ready-to-Eat', Language: 'Malay' },
  614: { Country: 'Philippines', Category: 'Bakery', Language: 'Tagalog' },
  6: { Country: 'Philippines', Category: 'Beverage', Language: 'Tagalog' },
  615: {
    Country: 'Philippines',
    Category: 'Confectionery',
    Language: 'Tagalog',
  },
  449: { Country: 'Philippines', Category: 'Dairy', Language: 'Tagalog' },
  678: { Country: 'Philippines', Category: 'Food', Language: 'Tagalog' },
  515: {
    Country: 'Philippines',
    Category: 'Ready-to-Eat',
    Language: 'Tagalog',
  },
  10: { Country: 'Philippines', Category: 'Snacks', Language: 'Tagalog' },
  812: {
    Country: 'Philippines',
    Category: 'Alcoholic Beverage',
    Language: 'Tagalog',
  },
  882: { Country: 'Philippines', Category: 'Plant-based', Language: 'Tagalog' },

  5: { Country: 'Singapore', Category: 'Coffee', Language: ENG_LANG },
  680: { Country: 'Singapore', Category: 'Food', Language: ENG_LANG },

  658: { Country: 'South Korea', Category: 'Bakery', Language: 'Korean' },
  669: { Country: 'South Korea', Category: 'Beverage', Language: 'Korean' },
  671: { Country: 'South Korea', Category: 'Ready-to-Eat', Language: 'Korean' },
  660: { Country: 'South Korea', Category: 'Snacks', Language: 'Korean' },
  887: {
    Country: 'South Korea',
    Category: 'Alcoholic Beverage',
    Language: 'Korean',
  },

  249: {
    Country: 'Switzerland',
    Category: 'Convenience Food',
    Language: 'Swiss',
  },

  648: { Country: 'UK', Category: 'Beverage', Language: ENG_LANG },
  248: { Country: 'UK', Category: 'Convenience Food', Language: ENG_LANG },
  686: { Country: 'UK', Category: 'Alcoholic Beverage', Language: ENG_LANG },
  723: { Country: 'UK', Category: 'Bakery', Language: ENG_LANG },
  724: { Country: 'UK', Category: 'Confectionery', Language: ENG_LANG },
  726: { Country: 'UK', Category: 'Dairy', Language: ENG_LANG },
  725: { Country: 'UK', Category: 'Snacks', Language: ENG_LANG },
  879: { Country: 'UK', Category: 'Ready-to-Eat', Language: ENG_LANG },
  880: { Country: 'UK', Category: 'Plant-based', Language: ENG_LANG },

  664: {
    Country: 'North America',
    Category: 'Sugar Reduction',
    Language: ENG_LANG,
  },

  649: {
    Country: 'Mexico',
    Category: 'Alcoholic Beverage',
    Language: 'Spanish',
  },
  650: { Country: 'Mexico', Category: 'Beverage', Language: 'Spanish' },
  1079: { Country: 'Mexico', Category: 'Snacks', Language: 'Spanish' },
  1342: { Country: 'Mexico', Category: 'Confectionery', Language: 'Spanish' },

  314: { Country: 'USA', Category: 'Alcoholic Beverage', Language: ENG_LANG },
  87: { Country: 'USA', Category: 'Bakery', Language: ENG_LANG },
  347: { Country: 'USA', Category: 'Beverage', Language: ENG_LANG },
  247: { Country: 'USA', Category: 'Confectionery', Language: ENG_LANG },
  647: {
    Country: 'USA',
    Category: 'Confectionery IceCream',
    Language: ENG_LANG,
  },
  655: {
    Country: 'USA',
    Category: 'Confectionery IceCream Bakery',
    Language: ENG_LANG,
  },
  682: { Country: 'USA', Category: 'Dairy', Language: ENG_LANG },
  86: { Country: 'USA', Category: 'Plant-based', Language: ENG_LANG },
  218: { Country: 'USA', Category: 'Ready-to-Eat', Language: ENG_LANG },
  119: { Country: 'USA', Category: 'Skincare', Language: ENG_LANG },
  246: { Country: 'USA', Category: 'Snacks', Language: ENG_LANG },
  317: { Country: 'USA', Category: 'Sugar Reduction', Language: ENG_LANG },

  284: { Country: 'Vietnam', Category: 'Bakery', Language: 'Vietnamese' },
  250: { Country: 'Vietnam', Category: 'Beverage', Language: 'Vietnamese' },
  186: {
    Country: 'Vietnam',
    Category: 'Confectionery',
    Language: 'Vietnamese',
  },
  54: { Country: 'Vietnam', Category: 'Dairy', Language: 'Vietnamese' },
  666: { Country: 'Vietnam', Category: 'Plant-based', Language: 'Vietnamese' },
  667: { Country: 'Vietnam', Category: 'Ready-to-Eat', Language: 'Vietnamese' },
  282: { Country: 'Vietnam', Category: 'Snacks', Language: 'Vietnamese' },
  813: {
    Country: 'Vietnam',
    Category: 'Alcoholic Beverage',
    Language: 'Vietnamese',
  },

  1274: { Country: 'Sweden', Category: 'Ready-to-Eat', Language: 'Swedish' },

  886: {
    Country: 'Nigeria',
    Category: 'Alcoholic Beverage',
    Language: ENG_LANG,
  },
  889: { Country: 'Nigeria', Category: 'Beverage', Language: ENG_LANG },

  888: {
    Country: 'Spain',
    Category: 'Alcoholic Beverage',
    Language: 'Spanish',
  },

  911: {
    Country: 'South Africa',
    Category: 'Alcoholic Beverage',
    Language: ENG_LANG,
  },
  1110: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Beverage',
    Language: 'Arabic',
  },
  1111: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Confectionery',
    Language: 'Arabic',
  },
  1112: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Dairy',
    Language: 'Arabic',
  },
  1113: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Plant-based',
    Language: 'Arabic',
  },
  1114: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Ready-to-Eat',
    Language: 'Arabic',
  },
  1115: {
    Country: 'Kingdom of Saudi Arabia',
    Category: 'Snacks',
    Language: 'Arabic',
  },
}

export const countryLangs = {
  Afghanistan: 'Dari',
  Albania: 'Albanian',
  Algeria: 'Arabic',
  Andorra: 'Catalan',
  Angola: 'Portuguese',
  'Antigua and Barbuda': ENG_LANG,
  Argentina: 'Spanish',
  Armenia: 'Armenian',
  Australia: ENG_LANG,
  Austria: 'German',
  Azerbaijan: 'Azerbaijani',
  Bahamas: ENG_LANG,
  Bahrain: 'Arabic',
  Bangladesh: 'Bengali',
  Barbados: ENG_LANG,
  Belarus: 'Belarusian',
  Belgium: 'Dutch',
  Belize: ENG_LANG,
  Benin: 'French',
  Bhutan: 'Dzongkha',
  Bolivia: 'Spanish',
  'Bosnia and Herzegovina': 'Bosnian',
  Botswana: ENG_LANG,
  Brazil: 'Portuguese',
  Brunei: 'Malay',
  Bulgaria: 'Bulgarian',
  'Burkina Faso': 'French',
  Burundi: 'Kirundi',
  'Cabo Verde': 'Portuguese',
  Cambodia: 'Khmer',
  Cameroon: ENG_LANG,
  Canada: ENG_LANG,
  'Central African Republic': 'French',
  Chad: 'French',
  Chile: 'Spanish',
  China: 'Mandarin',
  Colombia: 'Spanish',
  Comoros: 'Comorian',
  'Congo Brazzaville': 'French',
  'Costa Rica': 'Spanish',
  "Côte d'Ivoire": 'French',
  Croatia: 'Croatian',
  Cuba: 'Spanish',
  Cyprus: 'Greek',
  'Czech Republic': 'Czech',
  'Democratic Republic of the Congo': 'French',
  Denmark: 'Danish',
  Djibouti: 'French',
  Dominica: ENG_LANG,
  'Dominican Republic': 'Spanish',
  'East Timor': 'Tetum',
  Ecuador: 'Spanish',
  Egypt: 'Arabic',
  'El Salvador': 'Spanish',
  'Equatorial Guinea': 'Spanish',
  Eritrea: 'Tigrinya',
  Estonia: 'Estonian',
  Eswatini: ENG_LANG,
  Ethiopia: 'Amharic',
  Fiji: ENG_LANG,
  Finland: 'Finnish',
  France: 'French',
  Gabon: 'French',
  Gambia: ENG_LANG,
  Georgia: 'Georgian',
  Germany: 'German',
  Ghana: ENG_LANG,
  Greece: 'Greek',
  Grenada: ENG_LANG,
  Guatemala: 'Spanish',
  Guinea: 'French',
  'Guinea Bissau': 'Portuguese',
  Guyana: ENG_LANG,
  Haiti: 'French',
  Honduras: 'Spanish',
  Hungary: 'Hungarian',
  Iceland: 'Icelandic',
  India: ENG_LANG,
  Indonesia: 'Indonesian',
  Iran: 'Persian',
  Iraq: 'Arabic',
  Ireland: ENG_LANG,
  Israel: 'Hebrew',
  Italy: 'Italian',
  Jamaica: ENG_LANG,
  Japan: 'Japanese',
  Jordan: 'Arabic',
  Kazakhstan: 'Kazakh',
  Kenya: ENG_LANG,
  'Kingdom of Saudi Arabia': 'Arabic',
  Kiribati: ENG_LANG,
  Kosovo: 'Albanian',
  Kuwait: 'Arabic',
  Kyrgyzstan: 'Kyrgyz',
  Laos: 'Lao',
  Latvia: 'Latvian',
  Lebanon: 'Arabic',
  Lesotho: ENG_LANG,
  Liberia: ENG_LANG,
  Libya: 'Arabic',
  Liechtenstein: 'German',
  Lithuania: 'Lithuanian',
  Luxembourg: 'Luxembourgish',
  Madagascar: 'Malagasy',
  Malawi: ENG_LANG,
  Malaysia: 'Malay',
  Maldives: 'Dhivehi',
  Mali: 'French',
  Malta: ENG_LANG,
  'Marshall Islands': ENG_LANG,
  Mauritania: 'Arabic',
  Mauritius: 'French',
  Mexico: 'Spanish',
  Micronesia: ENG_LANG,
  Moldova: 'Romanian',
  Monaco: 'French',
  Mongolia: 'Mongolian',
  Montenegro: 'Montenegrin',
  Morocco: 'Arabic',
  Mozambique: 'Portuguese',
  Myanmar: 'Burmese',
  Namibia: ENG_LANG,
  Nauru: ENG_LANG,
  Nepal: 'Nepali',
  Netherlands: 'Dutch',
  'New Zealand': ENG_LANG,
  Nicaragua: 'Spanish',
  Niger: 'French',
  Nigeria: ENG_LANG,
  'North Korea': 'Korean',
  'North Macedonia': 'Macedonian',
  Norway: 'Norwegian',
  Oman: 'Arabic',
  Pakistan: ENG_LANG,
  Palau: ENG_LANG,
  'Palestine State': 'Arabic',
  Panama: 'Spanish',
  'Papua New Guinea': ENG_LANG,
  Paraguay: 'Spanish',
  Peru: 'Spanish',
  // Philippines: 'Filipino', Removed and kept here to keep a track that it should be removed
  Poland: 'Polish',
  Portugal: 'Portuguese',
  Qatar: 'Arabic',
  Romania: 'Romanian',
  Russia: 'Russian',
  Rwanda: ENG_LANG,
  'Saint Kitts and Nevis': ENG_LANG,
  'Saint Lucia': ENG_LANG,
  'Saint Vincent and the Grenadines': ENG_LANG,
  Samoa: ENG_LANG,
  'San Marino': 'Italian',
  'Sao Tome and Principe': 'Portuguese',
  'Saudi Arabia': 'Arabic',
  Senegal: 'French',
  Serbia: 'Serbian',
  Seychelles: ENG_LANG,
  'Sierra Leone': ENG_LANG,
  Singapore: ENG_LANG,
  Slovakia: 'Slovak',
  Slovenia: 'Slovene',
  'Solomon Islands': ENG_LANG,
  Somalia: 'Somali',
  'South Africa': ENG_LANG,
  'South Korea': 'Korean',
  'South Sudan': ENG_LANG,
  Spain: 'Spanish',
  'Sri Lanka': 'Sinhala',
  Sudan: ENG_LANG,
  Suriname: 'Dutch',
  Sweden: 'Swedish',
  Switzerland: 'German',
  Syria: 'Arabic',
  Taiwan: 'Mandarin',
  Tajikistan: 'Tajik',
  Tanzania: ENG_LANG,
  Thailand: 'Thai',
  Togo: 'French',
  Tonga: ENG_LANG,
  'Trinidad and Tobago': ENG_LANG,
  Tunisia: 'Arabic',
  Turkey: 'Turkish',
  Turkmenistan: 'Turkmen',
  Tuvalu: ENG_LANG,
  Uganda: ENG_LANG,
  Ukraine: 'Ukrainian',
  UAE: 'Arabic',
  UK: ENG_LANG,
  USA: ENG_LANG,
  Uruguay: 'Spanish',
  Uzbekistan: 'Uzbek',
  Vanuatu: ENG_LANG,
  'Vatican City': 'Italian',
  Venezuela: 'Spanish',
  Vietnam: 'Vietnamese',
  Yemen: 'Arabic',
  Zambia: ENG_LANG,
  Zimbabwe: ENG_LANG,
}

export const emptyNativeLangsProjects = [911, 675, 2, 9, 548, 350, 245, 7, 582]

export const chipKeys = [1, 2, 3, 4, 5, 6]

export const LENSES = ['Ingredient', 'Product', 'Theme']

export const INGREDIENT = 'Ingredient'
export const PRODUCT = 'Product'
export const THEME = 'Theme'

export const REGIONAL_ANALYTICS_COLORS = [
  '#0060A9',
  '#0274CA',
  '#079CD6',
  '#29C0FA',
]

export const URLS = {
  MY_WORKSPACE: '/mui/workspace',
  MAIN_SYSTEM: '/mui/main-system',
  COGNITIVE_SEARCH: '/mui/cognitive-search',
  DEMOGRAPHIC_ANALYTICS: '/mui/demographic-analytics',
  PROFILE: '/mui/profile',
  COMMON_TREND_ANALYSIS: '/mui/common-trend-analysis',
  PRIVACY_POLICY: '/mui/privacy-policy',
  WSO: '/mui/white-space',
  TERMS_OF_SERVICE: '/mui/terms-of-service',
  CONTACT_US: '/mui/contact-us',
  UNIVERSAL_TREND_SEARCH: '/mui/universal-trend-search',
  REGIONAL_ANALYTICS: '/mui/regional-analytics',
  TREND_PILLAR: '/mui/trend-pillar',
  BOOKMARK: '/mui/bookmark',
  ADMIN: '/mui/admin',
  CONCEPT_GENIE: '/mui/concept-genie',
  SCREEN_WINNER: '/screen-winner',
  DOCS: '/mui/docs',
  LOGOUT: '/logout',
  COMPARE: '/mui/compare',
  ENGAGEMENT_VISUALIZATION: '/mui/engagement-visualisation/',
}

export const NAV_TABS = [
  {
    p: 0,
    name: 'My Workspace',
    url: URLS.MY_WORKSPACE,
    key: 'personalised_dashboard_access',
    isInBeta: false,
  },
  {
    p: 1,
    name: 'Explore Trends',
    url: URLS.MAIN_SYSTEM,
    key: '',
    isInBeta: false,
  },
  {
    p: 2,
    name: '2X2 Engagement Visualization',
    url: URLS.ENGAGEMENT_VISUALIZATION,
    key: 'engagement_visualization',
    isInBeta: true,
  },
  {
    p: 3,
    name: 'White Space Opportunity',
    url: URLS.WSO,
    key: 'whitespace',
    isInBeta: false,
  },
  {
    p: 4,
    name: 'Common Trends Analysis',
    url: URLS.COMMON_TREND_ANALYSIS,
    key: 'common_trends_analysis',
    isInBeta: false,
  },
  {
    p: 5,
    name: 'Universal Trend Search',
    url: URLS.UNIVERSAL_TREND_SEARCH,
    key: 'universal_search',
    isInBeta: false,
  },
  {
    p: 6,
    name: 'Demographic Analytics',
    url: URLS.DEMOGRAPHIC_ANALYTICS,
    key: 'demographic_analytics',
    isInBeta: true,
  },
  {
    p: 7,
    name: 'Regional Analytics',
    url: URLS.REGIONAL_ANALYTICS,
    key: 'regional_analytics',
    isInBeta: true,
  },
]

export const AGE_GROUP_GEN_X = 'Gen X'
export const AGE_GROUP_GEN_Z = 'Gen Z'
export const AGE_GROUP_MILLENNIALS = 'Millennials'

export const DEMOGRAPHIC_AGE_RANGES = {
  ageGroupGenX: '43-75',
  ageGroupMillennials: '27-42',
  ageGroupGenZ: '11-26',
}
export const pillarNameLimitError = 'Pillar Name can only contain 64 characters'
export const pillarNameExistsError = 'Pillar Name already exists'
export const pillarNameRequiredError = 'Pillar Name is required'

export const INSIGNIFICANT_DATA_MESSAGE = 'Data is statistically insignificant!'
export const INSIGNIFICANT_SOCIAL_MENTION_COUNT = 50
export const INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT = 30

// Concept Genie
export const CG_MAX_TITLE_LENGTH = 140

export const SW_PRJ_TYPES = [
  {
    value: 'new-project',
    label: 'New Project',
  },
  {
    value: 'existing-project',
    label: 'Existing Project',
  },
]

export const OTHER_REASON_MAX_LENGTH = 140

// Wheel

export const WHEEL_GENERATE_PROGRESS_MESSAGE =
  'Hold on, while we generate your wheel..'

export const PHASES = [
  'Dormant',
  'Emerging',
  'Growing',
  'Matured',
  'Declining',
  'Fading',
]

export const phaseColor = {
  // DORMANT: '#ECE55F',
  // EMERGING: '#A7DE4C',
  // GROWING: '#6EB647',
  // MATURED: '#E2873D',
  // DECLINING: '#DD7066',
  // FADING: '#EABAB6',
  'Safe bet': '#F5BA41',
  Opportunity: '#16C7A2',
  Niche: '#78C4FE',
  Upcoming: '#816AD0',
}

export const SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB =
  'searchBarGenerateImgInImgTab'

export const BPC = 'bpc'

export const ALL = 'All'