import React from 'react'
import FilterComponentUI from './FilterComponentUI'
import { Button, Popover, Typography } from '@mui/material'
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone'
const FilterComponent = ({
  handleSelectChange,
  allDropdownsList,
  resetSelectedFilterValues,
  applySelectedFilters,
  handleFilterClickOpen,
  handleCloseFilter,
  anchorEl,
  open,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
}) => {
  return (
    <>
      <Button
        onClick={handleFilterClickOpen}
        variant="outlined"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          height: '35px ',
          width: '152px',
          padding: '0px',
          border: (theme) =>
            `1px solid ${theme.palette.secondary.light} !important`,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginLeft: '10px',
          }}
        >
          <FilterAltTwoToneIcon
            sx={{ color: (theme) => theme.palette.secondary.light }}
          />
          Filters
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FilterComponentUI
          allDropdownsList={allDropdownsList}
          handleSelectChange={handleSelectChange}
          applySelectedFilters={applySelectedFilters}
          resetSelectedFilterValues={resetSelectedFilterValues}
          selectedDataPoints={selectedDataPoints}
          selected2YearCAGR={selected2YearCAGR}
          selected4YearCAGR={selected4YearCAGR}
        />
      </Popover>
    </>
  )
}

export default FilterComponent
