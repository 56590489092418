import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import RegionalAnalyticsTrendPageUI from './RegionalAnalyticsTrendPageUI'
import { NeedChart } from './../../services/networking/Models/SubNeedDetailResponse'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import { useHistory } from 'react-router-dom'
import axios from './../../services/networking/axios'
import ProductQuoteResponse from '../../services/networking/Models/ProductQuoteResponse'
import { downloadSection, downloadFullPage } from '../../const'
import { useLoader } from '../../hooks'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'
import _ from 'lodash'
import { amplify } from '../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'
import {
  INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
  INSIGNIFICANT_SOCIAL_MENTION_COUNT,
} from './../../shared/utils/constants'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      Lens: ampiUserData.lens,
      Trend_Name: ampiUserData.trendName,
    }
    switch (amplitudeEvent) {
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          ...amplitudeUserData,
          Redirected_From: 'Regional Trend Page',
        }
        break
      case 'Clicked_Regional_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Region_Name: ampiUserData.region,
          Redirected_From: ampiUserData.redirectedFrom,
        }
        break
      case 'Clicked_Regional_Quotes':
        amplitudeUserData = {
          ...amplitudeUserData,
          Region_Name: ampiUserData.region,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in RegionalAnalyticsTrendPageUI')
  }
}

const RegionalAnalyticsTrendPage = ({ subscriptionDetails, email }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const projectId = queryParams.get('projectId')
  const country = queryParams.get('country')
  const category = queryParams.get('category')
  const lens = queryParams.get('lens').toLowerCase()
  const trendId = queryParams.get('trendId')
  const trendName = queryParams.get('trend')
  const [loader, setLoader] = useLoader()
  const [graphData, setGraphData] = useState()
  const [cagrData, setCagrData] = useState()
  const [sentimentData, setSentimentData] = useState()
  const [consumerInsightData, setConsumerInsightData] = useState()
  const [fsData, setFsData] = useState()
  const [fsFormatData, setFsFormatData] = useState()
  const [retailProductData, setRetailProductData] = useState()
  const [retailProductFormatData, setRetailProductFormatData] = useState()
  const [relatedTrendData, setRelatedTrendData] = useState()
  const [consumerQuote, setConsumerQuote] = useState()
  const [filteredTrendData, setFilteredTrendData] = useState()
  const [chartCAGR, setChartCAGR] = useState()
  const [regionalDistributionData, setRegionalDistributionData] = useState()
  const [trendGrowthExtraInfo, setTrendGrowthExtraInfo] = useState()
  const [trendMetaData, setTrendMetaData] = useState(null)
  const [region, setregion] = useState(queryParams.get('region'))
  const [regionSelectionOptions, setRegionSelectionOptions] = useState([])
  const [isDataInsignificant, setIsDataInsignificant] = useState(false)
  const history = useHistory()
  const dataPoint = trendMetaData?.dataPoint || 0

  const logTrendClick = () => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      trendName,
      lens,
      country,
      category,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logRegionalTrendClick = (
    trendDetail,
    redirectedFrom,
    selectedRegion,
  ) => {
    const ampiEvent = 'Clicked_Regional_Trend'
    const ampiUserData = {
      trendName: trendDetail?.name,
      lens,
      country,
      category,
      region: selectedRegion || region,
      redirectedFrom,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logConsumerQuotesClicked = () => {
    const ampiEvent = 'Clicked_Regional_Quotes'
    const ampiUserData = {
      trendName,
      lens,
      country,
      category,
      region,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleSnackBarRemove = () => {
    setIsDataInsignificant(false)
  }

  const handleRegionClick = (selectedRegion) => {
    setregion(selectedRegion.label)
    history.push(
      `?projectId=${encodeURIComponent(projectId)}&lens=${encodeURIComponent(
        _.startCase(lens),
      )}&country=${encodeURIComponent(country)}&category=${encodeURIComponent(
        category,
      )}&region=${encodeURIComponent(
        selectedRegion.label,
      )}&trend=${encodeURIComponent(trendName)}&trendId=${encodeURIComponent(
        trendId,
      )}`,
    )
    logRegionalTrendClick(
      trendName,
      'Breadcrumb Dropdown',
      selectedRegion.label,
    )
  }

  const fetchRegionalDashboardList = () => {
    setLoader(true)
    const request = NetworkingUtil.regionalDashboardList()
    axios
      .get(request)
      .then((res) => {
        setLoader(false)
        const dashboardListResponse = res.data.data
        const formattedOptions = dashboardListResponse.map((data) => {
          return {
            country: data.Country,
            category: data.Category,
            region: data.Region,
          }
        })
        setRegionSelectionOptions(formattedOptions)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional dashboard list')
      })
  }

  const setStateFromRoute = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const region = queryParams.get('region')
    if (region) {
      const matchedRegion = regionSelectionOptions.find(
        ({ region: r }) => r === region,
      )
      if (matchedRegion) {
        setregion(region)
      } else {
        // TODO: Should add 404
      }
    }
  }

  const regionSelectionOpt =
    regionSelectionOptions &&
    [
      ...new Set(
        regionSelectionOptions
          .filter((opt) => {
            return opt.country === country
          })
          .map((opt) => opt.region),
      ),
    ].map((region, i) => {
      return { key: i, label: region }
    })

  const breadCrumbLink = () => {
    const link = `?lens=${encodeURIComponent(
      lens,
    )}&category=${encodeURIComponent(category)}&region=${encodeURIComponent(
      region,
    )}`
    return link
  }

  const filterDuplicates = (arr) => {
    if (typeof arr !== 'undefined' && arr) {
      var resultArr = []
      arr.filter(function(item) {
        var i = resultArr.findIndex((x) => x.name == item.name)
        if (i <= -1) {
          resultArr.push(item)
        }
        return null
      })
      return resultArr
    } else {
      return null
    }
  }

  const toggleImageDownload = (action) => {
    setLoader(true)
    const name = trendName

    let dowSection = null
    let fileName = `${name} in ${country} ${category} JPG`
    switch (action) {
      case 'Chart': {
        // added brackets
        dowSection = document.getElementById('_dowSectionChart')
        fileName = `${name}_Trend_Chart.jpg`
        break
      } // added brackets
      case 'Cati': {
        // added brackets
        dowSection = document.getElementById('_dowSectionCati')
        fileName = `${name}_Consumer_Needs.jpg`
        break
      } // added brackets
      case 'Retail': {
        // added brackets
        dowSection = document.getElementById('_dowSectionRetailPdct')
        fileName = `${name}_Retail_Products.jpg`
        break
      }
      case 'Cafe': {
        // added brackets
        dowSection = document.getElementById('_dowSectionCafePdct')
        fileName = `${name}_Food_Service_Products.jpg`
        break
      }
      case 'RelatedThemes': {
        // added brackets
        dowSection = document.getElementById('_dowSectionThemeBlock')
        fileName = `${name}_Related_Themes.jpg`
        break
      }
      default: {
        // added brackets
        dowSection = document.getElementById('_dowSectionDesc')
        fileName = `${name}_${country}_${category}_Complete_Report.jpg`
        break
      } // added brackets
    }
    if (!action) {
      downloadFullPage(
        dowSection,
        '#_dowSectionChart',
        { top: 300, left: 0 },
        document.getElementById('downloadCanvas'),
        `height: 500px; background: white;`,
        fileName,
      )
        .then(() => {
          setLoader(false)
        })
        .catch((er) => {
          setLoader(false)
          console.log(er)
        })
    } else if (action === 'Chart') {
      const { width, height } = dowSection.getBoundingClientRect()
      // eslint-disable-next-line
      html2canvas(dowSection, { width, height })
        .then((canvas) => {
          canvas.toBlob((blob) => {
            // eslint-disable-next-line
            saveAs(blob, fileName)
            setLoader(false)
          })
        })
        .catch((er) => {
          console.log(er)
          setLoader(false)
        })
    } else {
      downloadSection(dowSection, fileName)
        .then(() => {
          setLoader(false)
        })
        .catch((er) => {
          console.log(er)
          setLoader(false)
        })
    }
  }

  /**
   * Crop the compete canvas report to desired size
   */
  const cropImage = (cropCanvas, left, top, width, height) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.height = height
    canvas.width = width
    ctx.drawImage(cropCanvas, left, top, width, height, 0, 0, width, height)
    return canvas
  }

  const saveFilteredTrendData = (data, cagr) => {
    setFilteredTrendData(data)
    setChartCAGR(cagr)
  }

  const getChartDataExcel = () => {
    const selectedData = filteredTrendData?.find(
      (arr) => arr.name === 'Historical Growth',
    )
    let dateRange = []
    let predDateRange = []
    if (selectedData) {
      dateRange = selectedData.data.map(
        (data) => Moment(data[0]).format('YYYY-MM-DD'), // eslint-disable-line
      )
    }
    const selectedPredData = filteredTrendData?.find(
      (arr) => arr.name === 'Predicted Growth',
    )
    if (selectedPredData) {
      predDateRange = selectedPredData.data.map(
        (data) => Moment(data[0]).format('YYYY-MM-DD'), // eslint-disable-line
      )
    }
    const formatChartData = (data) => {
      const chartData = []
      data &&
        data.forEach((value, i) => {
          const splitedPeriod = value.period.split('T')
          if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
            chartData.push({
              period: splitedPeriod[0],
              engagementScore: value.engagementScore,
            })
          } else {
            chartData.push({
              period: value.period,
              engagementScore: value.engagementScore,
            })
          }
        })
      return chartData
    }

    return {
      trend_chart: formatChartData(graphData).filter((data) =>
        dateRange.includes(data.period),
      ),
    }
  }

  const saveGraphExcel = (...args) => {
    setLoader(true)
    var chartData = []
    const predictiveChartData = []
    const searchData = []
    const socialData = []

    graphData.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        chartData[i] = {
          period: splitedPeriod[0],
          engagementScore: value.engagementScore,
        }
      } else {
        chartData[i] = {
          period: value.period,
          engagementScore: value.engagementScore,
        }
      }
    })

    const dataToSave = {
      name: trendName,
      lens: 'Ingredient',
      trend_name: graphData.trendName,
      ...getChartDataExcel(),
      category_data: consumerInsightData,
      retail_format: retailProductFormatData,
      retail_data_point: trendGrowthExtraInfo.retailDataPoint,
      food_service_format: fsFormatData,
      food_service_data_point: trendGrowthExtraInfo.cafeDataPoint,
    }

    let request = ''
    let excelFileName = ''
    if (args[1]) {
      request = NetworkingUtil.getCIExcel()
      excelFileName = `${trendName}_${country}_${category}_Consumer_Insights_Report.xlsx`
    } else if (args[2]) {
      request = NetworkingUtil.getRFExcel()
      excelFileName = `${trendName}_${country}_${category}_Retail__ProductFormat_Report.xlsx`
    } else if (args[3]) {
      request = NetworkingUtil.getFSFExcel()
      excelFileName = `${trendName}_${country}_${category}_FoodService_ProductFormat_Report.xlsx`
    } else if (args[4]) {
      request = NetworkingUtil.getAllExcel()
      excelFileName = `${trendName}_${country}_${category}_Complete_Report.xlsx`
    } else {
      request = NetworkingUtil.getTrendExcel()
      excelFileName = trendName + '_Monthly_Trend_Graph' + '.xlsx'
    }

    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, excelFileName)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  const cIExcelDownload = () => {
    saveGraphExcel(false, true, false, false, false)
  }

  const rFExcelDownload = () => {
    saveGraphExcel(false, false, true, false, false)
  }

  const fSExcelDownload = () => {
    saveGraphExcel(false, false, false, true, false)
  }

  const consolidatedExcelDownlaod = () => {
    const productName = trendName

    const inExcelFileName = `${productName} in ${country} ${category} EXCEL`
    saveGraphExcel(false, false, false, false, true, inExcelFileName)
  }

  const getChartDataPpt = () => {
    return { chartData: graphData }
    const parseChartData = (data) => ({
      _period: Moment(data[0]).format('YYYY-MM-DD'), // eslint-disable-line
      _engagementScore: data[1],
    })
    let chartData = []
    let predictiveChartData = []
    let cagrSeries = []
    let cagrVal = ''
    const chartDataTmp = filteredTrendData?.find(
      (arr) => arr.name === 'Historical Growth',
    )
    if (chartDataTmp) {
      chartData = chartDataTmp.data.map(parseChartData)
    }
    const predictiveChartDataTmp = filteredTrendData?.find(
      (arr) => arr.name === 'Predicted Growth',
    )
    if (predictiveChartDataTmp) {
      predictiveChartData = predictiveChartDataTmp.data.map(parseChartData)
    }
    const cagrDataTmp = filteredTrendData?.find(
      (arr) => arr.name === '4 year CAGR',
    )
    if (cagrDataTmp) {
      cagrSeries = cagrDataTmp.data.map(parseChartData)
      cagrVal = `${chartCAGR}`
    }

    return {
      chartData,
      predictiveChartData,
      cagr_series: cagrSeries,
      cagr_val: cagrVal,
    }
  }

  const updateProductFormats = (productFormats) => {
    const formats = []
    for (const name in productFormats) {
      formats.push({
        name: name,
        percentage_value: productFormats[name],
      })
    }
    return formats
  }

  const savePptSection = () => {
    const productName = trendName

    const dataToSave = {
      project_id: parseInt(projectId),
      lens: lens,
      cCategory: `${country} - ${category}`,
      productName: trendName,
      imgurl: graphData.productImage,
      mainDataPoint: graphData._data_point,
      rank: graphData.rank,
      trend: graphData.trendName,
      filterClassification: graphData.filterClassification,
      positiveSenti: graphData.positiveSentiment,
      neutralSenti: graphData.neutralSentiment,
      negativeSenti: graphData.negativeSentiment,
    }
    const request = NetworkingUtil.getgraphDataPpt()
    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, `${dataToSave.productName}_description.pptx`)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const saveGraphPpt = () => {
    const predChart = graphData
    const productName = trendName

    const dataToSave = {
      name: productName,
      country,
      cagr_series: [],
      predictiveChartData: [],
      cagr_val: cagrData.two_year_cagr,
      ...getChartDataPpt(),
      confidenceLevel: '',
      regional_analytics: true,
    }
    const request = NetworkingUtil.getMonthlyTrendsGraphPpt()
    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, `${dataToSave.name}_Monthly_Trend_Graph.pptx`)
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  const saveSectionToPpt = (section, sortBy) => {
    setLoader(true)
    const productName = trendName

    switch (section) {
      case 'consumerNeeds':
        {
          const consumerInsightsRequest = NetworkingUtil.getConsumerInsightsPpt()
          const consumerInsightsData = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            category_data: consumerInsightData,
            project_id: projectId,
          }
          axios
            .post(consumerInsightsRequest, consumerInsightsData, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(
                data,
                `${consumerInsightsData.productName}_ConsumerNeeds.pptx`,
              )
              setLoader(false)
            })
            .catch((err) => {
              // console.log(err)
              setLoader(false)
            })
        }
        break
      case 'cafe_product':
        {
          const cafeProducts = fsData
          const cafeProductsRequest = NetworkingUtil.getCafeProductsPpt()
          let filteredCafeProducts = []
          if (cafeProducts) {
            filteredCafeProducts = cafeProducts.slice(0, 4)
          }
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            cafeProducts: cafeProducts,
            cafeDataPoint: trendGrowthExtraInfo.cafeDataPoint,
            cafeCategoryData: updateProductFormats(fsFormatData) || [],
          }
          axios
            .post(cafeProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_Cafe_Products.pptx`)
              setLoader(false)
            })
            .catch((err) => {
              // console.log(err)
              setLoader(false)
            })
        }
        break
      case 'retail_product':
        {
          let filteredRetailProducts = []
          const startRange = 0
          const endRange = 4
          if (retailProductData) {
            filteredRetailProducts = retailProductData.slice(
              startRange,
              endRange,
            )
            filteredRetailProducts = filteredRetailProducts.map((s) => {
              if (isNaN(s.price)) {
                s.price = 0
              } else {
                if (typeof s.price === 'string') {
                  s.price = s.price.trim()
                }
              }
              return s
            })
          }

          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            retailProducts: filteredRetailProducts,
            retailDataPoint: trendGrowthExtraInfo.retailDataPoint,
            retailCategoryData:
              updateProductFormats(retailProductFormatData) || [],
          }
          const retailProductsRequest = NetworkingUtil.getRetailProductsPpt()
          axios
            .post(retailProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(
                data,
                `${dataToSave.productName}_RelatedRetailProducts.pptx`,
              )
              setLoader(false)
            })
            .catch((err) => {
              // console.log(err)
              setLoader(false)
            })
        }
        break
      case 'relatedProducts':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedProducts: filterDuplicates(
              relatedTrendData.related_products,
            ),
            sortBy: sortBy || 'Rank',
          }
          const relatedProductsRequest = NetworkingUtil.getRelatedProductsPpt()
          axios
            .post(relatedProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedProducts.pptx`)
              setLoader(false)
            })
            .catch((err) => {
              setLoader(false)
            })
        }
        break
      case 'relatedThemes':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedThemes: filterDuplicates(relatedTrendData.related_themes),
            sortBy: sortBy || 'Rank',
          }
          const relatedThemesRequest = NetworkingUtil.getRelatedThemesPpt()
          axios
            .post(relatedThemesRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedThemes.pptx`)
              setLoader(false)
            })
            .catch((err) => {
              setLoader(false)
            })
        }
        break
      case 'relatedIngredients':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedIngredients: filterDuplicates(
              relatedTrendData.related_ingredients,
            ),
            sortBy: sortBy || 'Rank',
          }
          const relatedIngredientsRequest = NetworkingUtil.getRelatedIngredientsPpt()
          axios
            .post(relatedIngredientsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedIngredients.pptx`)
              setLoader(false)
            })
            .catch((err) => {
              setLoader(false)
            })
        }
        break
    }
  }

  const togglePptDownload = () => {
    setLoader(true)
    const productName = trendName
    //
    //
    const trendChart = graphData
    const ingredientQuotes = graphData.ingredientQuotes
    const fileName = `${productName} in ${country} ${category} PPT`
    var chartData = []
    const predictiveChartData = []
    trendChart.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        chartData[i] = {
          _period: splitedPeriod[0],
          _engagementScore: value.engagementScore,
        }
      } else {
        chartData[i] = {
          _period: value.period,
          _engagementScore: value.engagementScore,
        }
      }
    })

    // Related
    let relatedThemesSafe = null
    let relatedProductsSafe = null
    let relatedIngredientsSafe = null
    if (relatedTrendData) {
      relatedThemesSafe = []

      relatedProductsSafe = filterDuplicates(relatedTrendData.related_products)
      relatedIngredientsSafe = filterDuplicates(
        relatedTrendData.related_ingredients,
      )
    }

    // Retail products
    let filteredRetailProducts = []
    const startRange = 0
    const endRange = 4
    if (retailProductData) {
      filteredRetailProducts = retailProductData.slice(startRange, endRange)
      filteredRetailProducts = filteredRetailProducts.map((s) => {
        if (isNaN(s.price)) {
          s.price = 0
        } else {
          if (typeof s.price === 'string') {
            s.price = s.price.trim()
          }
        }
        return s
      })
    }

    // On Premise and Home Products
    const cafeProducts = fsData
    let filteredCafeProducts = []
    if (cafeProducts) {
      filteredCafeProducts = cafeProducts.slice(0, 4)
    }
    // const predChart = graphData.predictiveTrendChart

    const dataToSave = {
      project_id: parseInt(projectId),
      country: country,
      lens: lens,
      cCategory: `${country} - ${category}`,
      productName: productName,
      imgurl: trendGrowthExtraInfo.img,
      mainDataPoint: graphData._data_point,
      // rank: graphData.rank,
      trend: trendName,
      // filterClassification: graphData.filterClassification,
      positiveSenti: sentimentData.positive_sentiment,
      neutralSenti: sentimentData.neutral_sentiment,
      negativeSenti: sentimentData.negative_sentiment,
      confidenceLevel: '',
      // (predChart &&
      //   predChart[0] &&
      //   predChart[0].confidence &&
      //   predChart[0].confidence[0] +
      //     predChart[0].confidence.slice(1).toLowerCase()) ||
      // '',
      name: productName,
      // yoy: graphData.yoyGrowthRate,
      ...getChartDataPpt(),

      category_data: consumerInsightData,

      relatedThemes: relatedThemesSafe,
      relatedProducts: relatedProductsSafe,
      relatedIngredients: relatedIngredientsSafe,
      sortBy: 'Rank',
      cafeProducts: filteredCafeProducts,
      cafeDataPoint: trendGrowthExtraInfo.cafeDataPoint,
      cafeCategoryData: updateProductFormats(fsFormatData) || [],
      retailProducts: filteredRetailProducts,
      retailDataPoint: trendGrowthExtraInfo.retailDataPoint,
      retailCategoryData: updateProductFormats(retailProductFormatData) || [],
    }

    const request = NetworkingUtil.getWholePagePpt()
    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(
          data,
          `${productName}_${country}_${category}_Complete_Report.pptx`,
        )
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  const formatChange = (engagementScoreData) => {
    const modifiedGraphData = Object.entries(
      engagementScoreData[0].engagement_score,
    ).map(([date, score]) => {
      return new NeedChart({ period: date, engagementScore: score })
    })
    return modifiedGraphData
  }

  const fetchRegionalConsumerQuotes = () => {
    setLoader(true)
    let quoteRequest
    switch (lens) {
      case 'ingredient':
        quoteRequest = NetworkingUtil.fetchRegionalIngredientQuotes(
          trendId,
          region,
        )

        break
      case 'product':
        quoteRequest = NetworkingUtil.fetchRegionalIngredientQuotesForProduct(
          trendId,
          region,
        )

        break
      case 'theme':
        quoteRequest = NetworkingUtil.fetchRegionalIngredientQuotesForTheme(
          trendId,
          region,
        )

        break
    }

    axios
      .get(quoteRequest)
      .then((res) => {
        const quoteResponse = res.data
        setConsumerQuote(new ProductQuoteResponse(quoteResponse))
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional quote data')
      })
  }

  const fetchRegionalRetailProducts = () => {
    if (lens !== 'theme') {
      setLoader(true)
      let rpRequest
      switch (lens) {
        case 'ingredient':
          rpRequest = NetworkingUtil.fetchRegionalRetailProductsIngredient(
            trendId,
            region,
          )
          break
        case 'product':
          rpRequest = NetworkingUtil.fetchRegionalRetailProductsForProduct(
            trendId,
            region,
          )
          break
      }
      axios
        .get(rpRequest)
        .then((res) => {
          const retailProductsResponse = res.data
          setRetailProductData(retailProductsResponse)
          setLoader(false)
        })
        .catch(() => {
          setLoader(false)
          console.log('error in getting regional retail product data')
        })
    }

    const rpFormatRequest = NetworkingUtil.fetchRegionalRetailProductFormats(
      trendId,
      region,
    )
    setLoader(true)
    axios
      .get(rpFormatRequest)
      .then((res) => {
        const rpFormatResponse = res.data.data
        setRetailProductFormatData(rpFormatResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional food service data')
      })
  }

  const fetchFoodServiceProducts = () => {
    if (lens !== 'theme') {
      setLoader(true)
      let fsRequest
      switch (lens) {
        case 'ingredient':
          fsRequest = NetworkingUtil.fetchRegionalFoodServiceIngredient(
            trendId,
            region,
          )
          break
        case 'product':
          fsRequest = NetworkingUtil.fetchRegionalFoodServiceProduct(
            trendId,
            region,
          )
          break
      }
      axios
        .get(fsRequest)
        .then((res) => {
          const fsResponse = res.data
          setFsData(fsResponse)
          setLoader(false)
        })
        .catch(() => {
          setLoader(false)
          console.log('error in getting regional food service data')
        })
    }

    const fsFormatRequest = NetworkingUtil.fetchRegionalFoodServiceFormat(
      trendId,
      region,
    )
    setLoader(true)
    axios
      .get(fsFormatRequest)
      .then((res) => {
        const fsFormatResponse = res.data.data
        setFsFormatData(fsFormatResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional food service data')
      })
  }

  const fetchConsumerInsight = () => {
    setLoader(true)
    const consumerInsightRequest = NetworkingUtil.regionalConsumerInsight(
      trendId,
      lens,
      region,
    )
    axios
      .get(consumerInsightRequest)
      .then((res) => {
        const consumerInsightResponse = res.data.data
        setConsumerInsightData(consumerInsightResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional trend consumer insight data')
      })
  }

  const fetchRegionalDistribution = () => {
    setLoader(true)
    const regionalDistributionRequest = NetworkingUtil.regionalDistributionForCountryGraph(
      country,
      category,
      trendName,
      lens,
    )
    axios
      .get(regionalDistributionRequest)
      .then((res) => {
        const regionalDistributionResponse = res.data.data.distribution
        setRegionalDistributionData(regionalDistributionResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log(
          'error in getting regional distribution for country graph data',
        )
      })
  }

  const fetchTrendGrowthData = () => {
    setLoader(true)
    const trendRequest = NetworkingUtil.regionalTrendGraph(
      country,
      category,
      trendName,
      lens,
      region,
    )
    axios
      .get(trendRequest)
      .then((res) => {
        const trendGrowthResponse = res.data.data
        const extraInfo = {
          cafeDataPoint: trendGrowthResponse[0].cafe_data_point,
          retailDataPoint: trendGrowthResponse[0].retail_data_point,
          img: trendGrowthResponse[0].image_url,
        }
        setTrendGrowthExtraInfo(extraInfo)
        setCagrData(trendGrowthResponse[0].cagr)
        setTrendMetaData({ dataPoint: trendGrowthResponse[0].data_point })
        setSentimentData(trendGrowthResponse[0].sentiment_score)
        const formattedGraphData = formatChange(trendGrowthResponse)
        setGraphData(formattedGraphData)
        const nonZeroEngagementScoreDataPercent =
          (formattedGraphData.filter(
            ({ engagementScore }) => engagementScore !== 0,
          ).length /
            formattedGraphData.length) *
          100
        setIsDataInsignificant(
          trendGrowthResponse[0].unique_mention_count <=
            INSIGNIFICANT_SOCIAL_MENTION_COUNT ||
            nonZeroEngagementScoreDataPercent <=
              INSIGNIFICANT_ENGAGEMENT_SCORE_DATA_PERCENT,
        )
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional trend growth graph data')
      })
  }
  const fetchRegionalRelatedTrends = () => {
    setLoader(true)
    let relatedTrendRequest
    switch (lens) {
      case 'ingredient':
        relatedTrendRequest = NetworkingUtil.fetchRegionalRelatedTrendsForIngredients(
          trendId,
          region,
        )
        break
      case 'product':
        relatedTrendRequest = NetworkingUtil.fetchRegionalRelatedTrendsForProducts(
          trendId,
          region,
        )
        break
      case 'theme':
        relatedTrendRequest = NetworkingUtil.fetchRegionalRelatedTrendsForThemes(
          trendId,
          region,
        )
        break
    }
    axios
      .get(relatedTrendRequest)
      .then((res) => {
        const relatedTrendResponse = res.data.data
        setRelatedTrendData(relatedTrendResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional related Trends data')
      })
  }

  const getRelatedTrendsUrlOnClick = (blockName, data) => {
    return (
      '/mui/regional-analytics/?projectId=' +
      encodeURIComponent(projectId) +
      '&lens=' +
      encodeURIComponent(blockName) +
      '&country=' +
      encodeURIComponent(country) +
      '&category=' +
      encodeURIComponent(category) +
      '&region=' +
      encodeURIComponent(region) +
      '&trend=' +
      encodeURIComponent(data.name) +
      '&trendId=' +
      encodeURIComponent(data.id)
    )
  }

  useEffect(() => {
    fetchTrendGrowthData()
    fetchConsumerInsight()
    // TODO Should Uncomment After
    // fetchFoodServiceProducts()
    fetchRegionalRetailProducts()
    fetchRegionalRelatedTrends()
    fetchRegionalConsumerQuotes()
    fetchRegionalDistribution()
  }, [region])

  useEffect(() => {
    fetchRegionalDashboardList()
  }, [])

  useEffect(() => {
    if (regionSelectionOptions?.length) {
      setStateFromRoute()
    }
  }, [regionSelectionOptions])

  return (
    <FeatureAuthorizer
      authAttr="regional_analytics"
      disableForFeat="cognitive_search"
    >
      <RegionalAnalyticsTrendPageUI
        subscriptionDetails={subscriptionDetails}
        projectid={projectId}
        productid={trendId}
        projectVariant={'food'}
        loader={loader}
        dataPoint={dataPoint}
        graphData={graphData}
        cagrData={cagrData}
        sentimentData={sentimentData}
        consumerInsightData={consumerInsightData}
        fsData={fsData}
        fsFormatData={fsFormatData}
        retailProductData={retailProductData}
        retailProductFormatData={retailProductFormatData}
        relatedTrendData={relatedTrendData}
        lens={lens}
        trendMetaData={trendMetaData}
        filterDuplicates={filterDuplicates}
        consumerQuote={consumerQuote}
        getChartDataPpt={getChartDataPpt}
        saveGraphPpt={saveGraphPpt}
        toggleImageDownload={toggleImageDownload}
        togglePptDownload={togglePptDownload}
        cIExcelDownload={cIExcelDownload}
        saveSectionToPpt={saveSectionToPpt}
        region={region}
        trendGrowthExtraInfo={trendGrowthExtraInfo}
        trendName={trendName}
        handleRegionClick={handleRegionClick}
        country={country}
        category={category}
        regionalDistributionData={regionalDistributionData}
        breadCrumbLink={breadCrumbLink}
        regionSelectionOpt={regionSelectionOpt}
        getRelatedTrendsUrlOnClick={getRelatedTrendsUrlOnClick}
        logTrendClick={logTrendClick}
        logConsumerQuotesClicked={logConsumerQuotesClicked}
        logRegionalTrendClick={logRegionalTrendClick}
        fetchRegionalConsumerQuotes={fetchRegionalConsumerQuotes}
        isDataInsignificant={isDataInsignificant}
        handleSnackBarRemove={handleSnackBarRemove}
        onRefresh={() => {
          fetchRegionalRetailProducts()
        }}
      />
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

export default connect(mapStateToProps, null)(RegionalAnalyticsTrendPage)
