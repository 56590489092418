import {
  ProjectListViewActions,
  NeedCategoriesViewActions,
} from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import * as AC from './actionConstants'
import { saveFilter } from './chartActions'
import ProductConsumerNeeds from '../../services/networking/Models/ProductConsumerNeeds'
import { addRequest, doneRequest } from './downloadSections'

export const fetchSubscriptionsDetails = () => {
  return (dispatch, getState) => {
    const requestFrom = {
      name: 'fetchSubscriptionsDetails',
      requestTime: new Date(),
    }
    // Get subscriptions details
    dispatch(addRequest(requestFrom))
    const url = NetworkUtil.fetchSubscriptionsDetailsRequest()
    axios
      .get(url)
      .then((response) => {
        const subscription = response.data
        dispatch(fetchSubscriptionsDetailsSuccess(subscription.data))
        dispatch(doneRequest(requestFrom))
      })
      .catch((error) => {
        dispatch(fetchSubscriptionsDetailsFailed(error))
        dispatch(doneRequest(requestFrom))
      })
  }
}

export const fecthFeaturesDetails = (projectId) => {
  return (dispatch, getState) => {
    const url = NetworkUtil.fecthFeaturesDetailsRequest(projectId)
    const requestFrom = {
      name: 'fecthFeaturesDetails',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then((response) => {
        dispatch(fecthFeaturesDetailsSuccess(response.data.data))
        dispatch(doneRequest(requestFrom))
      })
      .catch((error) => {
        console.log('Features Request Error:', error)
        dispatch(fecthFeaturesDetailsFailed(error))
        dispatch(doneRequest(requestFrom))
      })
  }
}

export const fecthWhiteSpaceOpportunityGraphData = (
  projectId,
  lensSelected,
  subCategory,
) => {
  lensSelected = lensSelected || 'Ingredient'
  subCategory = subCategory || 'All'
  return (dispatch) => {
    // Get subscriptions details
    const url =
      NetworkUtil.fecthWhiteSpaceOpportunityGraphDataRequest(projectId) +
      '?lens=' +
      lensSelected.toLowerCase() +
      '&subcategory=' +
      encodeURIComponent(subCategory)
    const requestFrom = {
      name: 'fecthWhiteSpaceOpportunityGraphData',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    axios
      .get(url)
      .then((response) => {
        dispatch(doneRequest(requestFrom))
        dispatch(fecthWhiteSpaceOpportunityGraphDataSuccess(response.data))
      })
      .catch((error) => {
        dispatch(doneRequest(requestFrom))
        console.log('White Space Opportunity Data Error:', error)
        dispatch(fecthWhiteSpaceOpportunityGraphDataFailed(error))
      })
  }
}

export const fetchChart1 = (projectID, date) => {
  return (dispatch) => {
    fetchList(dispatch, projectID, date)
  }
}

export const fetchProjectList = (
  projectID,
  defaultSelectCategory,
  lensSelected,
) => {
  return (dispatch, getState) => {
    dispatch(updateRequestStartTime())
    fetchList(
      dispatch,
      projectID,
      new Date(),
      defaultSelectCategory,
      lensSelected,
    )
    fetchNeedsList(dispatch, projectID)
  }
}

export const fetchIngredientTrendList = (projectid, defaultSelectCategory) => {
  return (dispatch) => {
    fetchIngredientList(
      dispatch,
      '',
      new Date(),
      projectid,
      defaultSelectCategory,
    )
  }
}

export const fetchThemeTrendList = (projectid, defaultSelectCategory) => {
  return (dispatch) => {
    fetchThemeList(dispatch, '', new Date(), projectid, defaultSelectCategory)
  }
}

export const fetchProductTrendList = (projectid, defaultSelectCategory) => {
  return (dispatch) => {
    fetchProductList(dispatch, '', new Date(), projectid, defaultSelectCategory)
  }
}

const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    const A = a[key].toLowerCase().replace(' trends', '')
    const B = b[key].toLowerCase().replace(' trends', '')

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })

  return array
}

const fetchIngredientList = (
  dispatch,
  request,
  date,
  projectid,
  defaultSelectCategory = 'All',
) => {
  // dispatch(cancelfetchProjectList())
  const requestFrom = {
    name: 'fetchIngredientList',
    requestTime: date,
  }
  dispatch(addRequest(requestFrom))
  defaultSelectCategory = encodeURIComponent(defaultSelectCategory)
  const url =
    request ||
    NetworkUtil.formSearchRequestForProject(projectid) +
      `?need_filter=${defaultSelectCategory}`
  axios
    .get(url)
    .then((response) => {
      const isBeverage = response.data.searchCriteria.includes(
        'Food Category;Thailand;Beverages',
      )
      const order = [
        'dormant',
        'emerging',
        'growing',
        'mature',
        'declining',
        'fading',
      ]
      let result = response.data
      if (isBeverage) {
        result = {
          ...response.data,
          trends: mapOrder(response.data.trends, order, 'type'),
        }
      }
      dispatch(fetchProjectListSuccess(result, date))
      dispatch(doneRequest(requestFrom))
    })
    .catch((error) => {
      // dispatch(fetchProjectListcancelled(err))
      dispatch(fetchProjectListfailed(error))
      dispatch(doneRequest(requestFrom))
    })
}

const fetchThemeList = (
  dispatch,
  request,
  date,
  projectid,
  defaultSelectCategory = 'All',
) => {
  const requestFrom = {
    name: 'fetchThemeList',
    requestTime: date,
  }
  dispatch(addRequest(requestFrom))
  defaultSelectCategory = encodeURIComponent(defaultSelectCategory)

  const url =
    request ||
    NetworkUtil.formSearchRequestForThemeFilter(projectid) +
      `?need_filter=${defaultSelectCategory}` // Product Filter

  axios
    .get(url)
    .then((response) => {
      const result = response.data
      dispatch(fetchThemeListSuccess(result, date))
      dispatch(doneRequest(requestFrom))
    })
    .catch((error) => {
      console.log('Theme Error ====> ', error)
      dispatch(fetchProjectListfailed(error))
      dispatch(doneRequest(requestFrom))
    })
}

const fetchProductList = (
  dispatch,
  request,
  date,
  projectid,
  defaultSelectCategory = 'All',
) => {
  const requestFrom = {
    name: 'fetchProductList',
    requestTime: date,
  }
  dispatch(addRequest(requestFrom))
  defaultSelectCategory = encodeURIComponent(defaultSelectCategory)
  const url =
    request ||
    NetworkUtil.formSearchRequestForProductFilter(projectid) +
      `?need_filter=${defaultSelectCategory}` // Product Filter
  axios
    .get(url)
    .then((response) => {
      const result = response.data
      dispatch(fetchProductListSuccess(result, date))
      dispatch(doneRequest(requestFrom))
    })
    .catch((error) => {
      console.log('Product Error ====> ', error)
      dispatch(fetchProjectListfailed(error))
      dispatch(doneRequest(requestFrom))
    })
}

const fetchList = (
  dispatch,
  projectid,
  date,
  defaultSelectCategory = 'All',
  lensSelected,
) => {
  defaultSelectCategory = encodeURIComponent(defaultSelectCategory)
  const request =
    NetworkUtil.formSearchRequestForProject(projectid) +
    `?need_filter=${
      lensSelected === 'Ingredient' ? defaultSelectCategory : 'All'
    }`
  const productFetchUrl =
    NetworkUtil.formSearchRequestForProductFilter(projectid) +
    `?need_filter=${lensSelected === 'Product' ? defaultSelectCategory : 'All'}` // Product Filter
  const themeFetchUrl =
    NetworkUtil.formSearchRequestForThemeFilter(projectid) +
    `?need_filter=${lensSelected === 'Theme' ? defaultSelectCategory : 'All'}` // Product Filter

  const url = NetworkUtil.fetchProductTypesFillterRequest(projectid)
  axios
    .get(url)
    .then((res) => {
      const ingredientFilters = (res.data.ingredient_filters || []).map((s) => {
        s.displayValue = s.name
        s.value = s.name
        return s
      })

      const themeFilters = (res.data.theme_filters || []).map((s) => {
        s.displayValue = s.name
        s.value = s.name
        return s
      })

      const productFilters = (res.data.product_filters || []).map((s) => {
        s.displayValue = s.name
        s.value = s.name
        return s
      })

      dispatch(
        saveFilter({
          allFilter: ingredientFilters,
          allThemeFilter: themeFilters,
          allProductFilter: productFilters,

          filter: ingredientFilters.filter((x) => x.name === 'All'),
          theme_filter: themeFilters.filter((x) => x.name === 'All'),
          product_filter: productFilters.filter((x) => x.name === 'All'),
        }),
      )
    })
    .catch((error) => {
      console.log(error)
    })

  // Ingredient filter
  fetchIngredientList(dispatch, request, date)

  // Product filter
  fetchProductList(dispatch, productFetchUrl, date)

  // Theme filter
  fetchThemeList(dispatch, themeFetchUrl, date)
}

export const setProductListHiddenConf = (value) => ({
  type: AC.SET_PROJECT_LIST_HIDDEN_CONF,
  value,
})

export const fetchNeedsList = (dispatch, projectid) => {
  const request = NetworkUtil.formSearchRequestForNeedsList(projectid)
  axios
    .get(request)
    .then((response) => {
      const responseObject = new ProductConsumerNeeds(response.data)
      dispatch(fetchProjectsNeedsListSuccess(responseObject))
    })
    .catch((error) => {
      dispatch(fetchProjectsNeedsListfailed(error))
    })
}

const fetchProjectListSuccess = (projectList, date) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_PROJECT_LIST_SUCCESS
      ],
    projectList: projectList,
    date,
  }
}

const fetchProductListSuccess = (productList, date) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_PRODUCT_LIST_SUCCESS
      ],
    productList: productList,
    date,
  }
}

const fetchSubscriptionsDetailsSuccess = (subscriptionDetails) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_SUBSCRIPTION_DETAILS_SUCCESS
      ],
    subscriptionDetails: subscriptionDetails,
  }
}

const fetchThemeListSuccess = (themeList, date) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_THEME_LIST_SUCCESS
      ],
    themeList: themeList,
    date,
  }
}

export const fecthWhiteSpaceOpportunityGraphDataSuccess = (wsoGraphData) => {
  // White Space Opportunity Data
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_WHITE_SPACE_OPPORTUNITY_SUCCESS
      ],
    wsoGraphData: wsoGraphData,
  }
}

const fecthFeaturesDetailsSuccess = (featuresDetails) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_FEATURES_SUCCESS
      ],
    featuresDetails: featuresDetails,
  }
}

const fetchProjectListfailed = (error) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_PROJECT_LIST_FAIL
      ],
    error: error,
  }
}

const fetchSubscriptionsDetailsFailed = (error) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_SUBSCRIPTION_DETAILS_FAIL
      ],
    error: error,
  }
}

const fetchProjectsNeedsListSuccess = (needsList) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_PROJECT_LIST_NEEDS_SUCCESS
      ],
    needsList: needsList,
  }
}

const fetchProjectsNeedsListfailed = (error) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_PROJECT_LIST_NEEDS_FAIL
      ],
    error: error,
  }
}

const fecthWhiteSpaceOpportunityGraphDataFailed = (error) => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.FETCH_WHITE_SPACE_OPPORTUNITY_FAILED
      ],
    error: error,
  }
}

const fecthFeaturesDetailsFailed = (error) => {
  return {
    type:
      ProjectListViewActions.type[ProjectListViewActions.FETCH_FEATURES_FAILED],
    error: error,
  }
}

const fetchNeedChartDataSuccess = (scoreList, trendDates) => {
  return {
    type: AC.CONSUMERNEED_SCORES_FETCH_SUCCESS,
    scoreList,
    trendDates,
  }
}

export const fetchTrendPhasesDataSuccess = (
  filteredData,
  emergingData,
  allData,
  trendTypes,
  trendDates,
) => {
  return {
    type: AC.TRENDS_PHASES_DATA_FETCH_SUCCESS,
    filteredData,
    emergingData,
    allData,
    trendTypes,
    trendDates,
  }
}

const updateRequestStartTime = () => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.RECORD_REQUEST_START_TIME
      ],
    time: new Date().getTime(),
  }
}

export const delayUser = (timeInterval) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(userDelayComplete())
    }, timeInterval)
  }
}

const userDelayComplete = () => {
  return {
    type:
      ProjectListViewActions.type[ProjectListViewActions.USER_DELAY_COMPLETE],
  }
}

export const setAssocStrength = (id) => {
  return {
    type:
      ProjectListViewActions.type[ProjectListViewActions.SELECT_ASSOC_LEVEL],
    id,
  }
}

export const fetchProjectNeedCategories = (
  projectid,
  defaultSelectCategory = 'All',
) => {
  defaultSelectCategory = encodeURIComponent(defaultSelectCategory)
  return (dispatch, getState) => {
    const requestFrom = {
      name: 'fetchProjectNeedCategories',
      requestTime: new Date(),
    }
    dispatch(addRequest(requestFrom))
    const request =
      NetworkUtil.formRequestForProjectNeedCategories(projectid) +
      `?need_filter=${defaultSelectCategory}`
    axios
      .get(request)
      .then((response) => {
        // const responseObject = new ProductConsumerNeeds(response.data)
        dispatch(doneRequest(requestFrom))
        dispatch(fetchProjectNeedCategoriesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(doneRequest(requestFrom))
        dispatch(fetchProjectNeedCategoriesfailed(error))
      })
  }
}

const fetchProjectNeedCategoriesSuccess = (data) => {
  return {
    type:
      NeedCategoriesViewActions.type[
        NeedCategoriesViewActions.FETCH_NEED_CATEGORIES_SUCCESS
      ],
    needCategories: data.need_categories,
  }
}

const fetchProjectNeedCategoriesfailed = (error) => {
  return {
    type:
      NeedCategoriesViewActions.type[
        NeedCategoriesViewActions.FETCH_NEED_CATEGORIES_FAILED
      ],
    error: error,
  }
}

export const fetchCtxSrchMapping = (country, category, lens) => {
  return (dispatch) => {
    const requestFrom1 = {
      name: 'fetchCtxSrchMapping1',
      requestTime: new Date(),
    }
    const requestFrom2 = {
      name: 'fetchCtxSrchMapping2',
      requestTime: new Date(),
    }
    const requestCol1 = NetworkUtil.getCtxSearchMapping(
      country,
      category,
      lens,
      1,
    )
    const requestCol2 = NetworkUtil.getCtxSearchMapping(
      country,
      category,
      lens,
      2,
    )
    dispatch(addRequest(requestFrom1))
    axios
      .get(requestCol1)
      .then((response) => {
        dispatch(doneRequest(requestFrom1))
        dispatch(fetchCtxSrchMappingSuccess(response.data.data.mapping, lens))
      })
      .catch((error) => {
        dispatch(doneRequest(requestFrom1))
        dispatch(fetchCtxSrchMappingFailed(error))
      })
    dispatch(addRequest(requestFrom2))
    axios
      .get(requestCol2)
      .then((response) => {
        dispatch(doneRequest(requestFrom2))
        dispatch(fetchCtxSrchMappingSuccess(response.data.data.mapping, lens))
      })
      .catch((error) => {
        dispatch(doneRequest(requestFrom2))
        dispatch(fetchCtxSrchMappingFailed(error))
      })
  }
}

const fetchCtxSrchMappingSuccess = (ctxSrchMapping, lens, sessionId) => ({
  type:
    ProjectListViewActions.type[
      ProjectListViewActions.FETCH_CTX_SRC_MAPPING_SUCCESS
    ],
  ctxSrchMapping,
  lens,
  // sessionId
})

const fetchCtxSrchMappingFailed = (error) => ({
  type:
    ProjectListViewActions.type[
      ProjectListViewActions.FETCH_CTX_SRC_MAPPING_FAILED
    ],
  error,
})

export const resetProjectListBuilderExceptSubscription = () => {
  return (dispatch) => {
    dispatch(resetProjectListBuilderExceptSubscriptionSuccess())
  }
}

const resetProjectListBuilderExceptSubscriptionSuccess = () => {
  return {
    type:
      ProjectListViewActions.type[
        ProjectListViewActions.RESET_PROJECTLISTBUILDER_EXCEPT_SUBSCRIPTION
      ],
  }
}
