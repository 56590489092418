import styled from '@emotion/styled'
import { Box } from '@mui/system'

export default styled(Box)(({ theme, isDisableClick }) => ({
  display: 'flex',
  // height: theme.spacing(9.25),
  height: '100%',
  maxHeight: '108px',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  padding: theme.spacing(2),
  cursor: isDisableClick ? 'auto' : 'pointer',
  ...(isDisableClick
    ? {}
    : {
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        },
        '&:active': {
          backgroundColor: theme.palette.grey[100],
          boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        },
      }),
}))
