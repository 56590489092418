import styled from '@emotion/styled'
import { Box, Grid, Tabs, TextField } from '@mui/material'

export const MainStyledBox = styled(Box)(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	marginTop: '40px'
}))

export const StyledBox = styled(Box)(({ isSelected, theme }) => ({
	top: '208px',
	left: '24px',
	minWidth: '88px',
	height: '36px',
	border: isSelected ? `1px solid ${theme.palette.secondary.main}` : `1px solid ${theme.palette.icon}`,
	borderRadius: '5px',
	opacity: 1,
	marginRight: '20px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '0 15px 0 15px',
	color: isSelected ?  `${theme.palette.secondary.main}` : `${theme.palette.text.secondary}`,
	marginBottom: '15px',

	'&:hover': {
		cursor: 'pointer',
	},
}))

export const Item = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.white,
	paddingTop: '10px',
	paddingBottom: '10px',
	marginLeft: '-5px',
	'.MuiGrid-root': {
		padding: '3px',
	},
	'.MuiAutocomplete-root': {
		width: '100%',
	}
}));

export const StyledTab = styled(Tabs)(({ theme }) => ({
	borderBottom: 'none',
	marginBottom: '10px',
	marginTop: '10px',
	'& .MuiTabs-indicator': {
		borderBottom: `2px solid ${theme.palette.secondary.main}`,
		backgroundColor: 'transparent',
	},
	'& .MuiTab-root.Mui-selected': {
		color: `${theme.palette.text.primary}`,
		padding: '0',
	},
	'& .MuiTab-root': {
		color: `${theme.palette.text.secondary}`,
	},
	'.MuiButtonBase-root': {
		fontSize: theme.spacing(2),
		padding: `${theme.spacing(2)} 0`,
		marginRight: '25px',
		'&:hover': {
			border: 'none',
			boxShadow: 'none',
			padding: `${theme.spacing(2)} 0`,
		}
	}

}))

export const SearchBox = styled(TextField)(() => ({
	width: '280px',
	'.MuiOutlinedInput-input': {
		padding: '10px !important',
		textTransform: 'capitalize'
	}
}))

export const WsoDropdown = styled(TextField)(() => ({
	'.MuiAutocomplete-root': {
		marginLeft: '2px'
	},
	'.MuiInputLabel-root': {
		color: 'black',
		fontSize: '14px'
	},
	'.MuiInputLabel-root: hover': {
		color: 'black'
	},
	'.MuiOutlinedInput-input': {
		padding: '10px 3px !important'
	},
}))
