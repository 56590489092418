import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'moment'

import * as actions from '../../store/actions/index'
import axios from '../../services/networking/axios'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { withRouter } from 'react-router-dom'
import { Box, Grid, Stack } from '@mui/material'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import RelatedRetailProducts from '../../shared/components/RelatedRetailProducts'
import ProductItemDetailsTop from '../../shared/components/ProductItemDetailsTop'
import TrendGrowthCard from '../../shared/components/TrendGrowthCard'
import DetailedConsumerInsights from '../../shared/components/DetailedConsumerInsights'
import RelatedTrends from '../../shared/components/RelatedTrends'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import FoodServiceProducts from '../../shared/components/FoodServiceProducts'
import ComparePopup from '../../shared/components/ComparePopUp'
import NetworkingUtil from '../../services/networking/NetworkingUtil'

import '../muiStyle.css'

import { getUrlSelectedLens, amplify } from '../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'
import { downloadSection, downloadFullPage, downloadGraph } from '../../const'
import OverlayLoader from '../../shared/components/OverlayLoader'
import ErrorHandler from '../../shared/components/ErrorHandler'
import RegionalAndAgeGroupDistributionGraph from '../../containers/DemographicAnalysis/RegionalAndAgeGroupDistributionGraph'
import { fetchAllTrends } from '../../store/actions/index'
import { PRODUCT, PHASES,BPC } from '../../shared/utils/constants'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const email = ampiInstance.email
    const amplitudeEvent = ampiEvent
    const {
      props,
      subCategoryFilter,
      trendDetail,
      redirectedFrom,
    } = ampiUserData
    const { productDetails, lensSelected } = props
    const { country, category, name } = productDetails
    let amplitudeUserData = {
      User: email,
      Country_Name: country,
      Category_Name: category,
      Lens: lensSelected,
    }
    switch (ampiEvent) {
      case 'Clicked_Related_Themes_Dropdown':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: name,
          Sub_Category_Filter: subCategoryFilter,
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: trendDetail.trendType,
          Redirected_From: redirectedFrom,
          Trend_Name: trendDetail.name,
        }
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log('Error in Product Page amplitude event')
  }
}

class ProductItemPage extends Component {
  constructor(props) {
    super(props)
    this.state = { loader: false, selectedThemeSubCategory: 'All' }
  }

  static propTypes = {
    productDetails: PropTypes.any,
  }

  logTrendClick = (trendDetail, redirectedFrom) => {
    const ampiEvent = 'Clicked Trend Lens'
    const ampiUserData = {
      trendDetail,
      props: this.props,
      redirectedFrom,
    }
    amp(ampiEvent, ampiUserData)
  }

  logSubCategorySelection = (subCategoryFilter) => {
    const ampiEvent = 'Clicked_Related_Themes_Dropdown'
    const ampiUserData = {
      props: this.props,
      subCategoryFilter,
    }
    amp(ampiEvent, ampiUserData)
  }

  filterDuplicates = (arr) => {
    if (typeof arr !== 'undefined' && arr) {
      var resultArr = []
      arr.filter(function(item) {
        var i = resultArr.findIndex((x) => x.name == item.name)
        if (i <= -1) {
          resultArr.push(item)
        }
        return null
      })
      return resultArr
    } else {
      return null
    }
  }

  saveFilteredTrendData = (data, cagr) => {
    this.filteredTrendData = data
    this.chartCagr = cagr
  }

  toggleImageDownload = (action) => {
    this.setState(
      {
        loader: true,
      },
      () => {
        const name = this.props.productDetails.name.replace(' ', '_')
        const { country, category } = this.props.productDetails
        let dowSection = null
        let fileName = `${name} in ${country} ${category} JPG`
        switch (action) {
          case 'Chart': {
            // added brackets
            dowSection = document.getElementById('_dowSectionChart') // this._dowSectionChart.current
            fileName = `${name}_Trend_Chart.png`
            break
          } // added brackets
          case 'Cati': {
            // added brackets
            dowSection = document.getElementById('_dowSectionCati') // this._dowSectionCati.current
            fileName = `${name}_Consumer_Needs.jpg`
            break
          } // added brackets
          case 'Retail': {
            // added brackets
            dowSection = document.getElementById('_dowSectionRetailPdct') // this._dowSectionRetailPdct.current
            fileName = `${name}_Retail_Products.jpg`
            break
          }
          case 'Cafe': {
            // added brackets
            dowSection = document.getElementById('_dowSectionCafePdct') // this._dowSectionCafePdct.current
            fileName = `${name}_Food_Service_Products.jpg`
            break
          }
          case 'RelatedThemes': {
            // added brackets
            dowSection = document.getElementById('_dowSectionThemeBlock') // this._dowSectionThemeBlock.current
            fileName = `${name}_Related_Themes.jpg`
            break
          }
          default: {
            // added brackets
            dowSection = document.getElementById('_dowSectionDesc') // this._dowSectionDesc.current
            fileName = `${name}_${country}_${category}_Complete_Report.png`
            break
          } // added brackets
        }
        if (!action) {
          downloadFullPage(
            dowSection,
            '#_dowSectionChart',
            { top: 300, left: 0 },
            document.getElementById('downloadCanvas'),
            `height: 500px; background: white;`,
            fileName,
          )
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              this.setState({ loader: false })
              console.log(er)
            })
        } else if (action === 'Chart') {
          downloadGraph(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              console.log(er)
              this.setState({ loader: false })
            })
        } else {
          downloadSection(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              console.log(er)
              this.setState({ loader: false })
            })
        }
      },
    )
  }

  getChartDataExcel = () => {
    const {
      trendChart,
      predictiveTrendChart,
      _socialData,
      _webSearchData,
    } = this.props.productDetails
    const selectedData = this.filteredTrendData.find(
      (arr) => arr.name === 'Historical Growth',
    )
    let dateRange = []
    let predDateRange = []
    if (selectedData) {
      dateRange = selectedData.data.map((data) =>
        Moment(data[0]).format('YYYY-MM-DD'),
      )
    }
    const selectedPredData = this.filteredTrendData.find(
      (arr) => arr.name === 'Predicted Growth',
    )
    if (selectedPredData) {
      predDateRange = selectedPredData.data.map((data) =>
        Moment(data[0]).format('YYYY-MM-DD'),
      )
    }
    const formatChartData = (data) => {
      const chartData = []
      data.forEach((value, i) => {
        const splitedPeriod = value.period.split('T')
        if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
          chartData.push({
            period: splitedPeriod[0],
            engagementScore: value.engagementScore,
          })
        } else {
          chartData.push({
            period: value.period,
            engagementScore: value.engagementScore,
          })
        }
      })
      return chartData
    }

    return {
      trend_chart: formatChartData(trendChart).filter((data) =>
        dateRange.includes(data.period),
      ),
      predictive_chart: formatChartData(predictiveTrendChart).filter((data) =>
        predDateRange.includes(data.period),
      ),
      social_chart: formatChartData(_socialData).filter((data) =>
        dateRange.includes(data.period),
      ),
      search_data: formatChartData(_webSearchData).filter((data) =>
        dateRange.includes(data.period),
      ),
    }
  }

  saveGraphExcel = (...args) => {
    this.setState({
      loader: true,
    })
    const { productDetails } = this.props
    var chartData = []
    const predictiveChartData = []
    const searchData = []
    const socialData = []

    productDetails.trendChart.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        chartData[i] = {
          period: splitedPeriod[0],
          engagementScore: value.engagementScore,
        }
      } else {
        chartData[i] = {
          period: value.period,
          engagementScore: value.engagementScore,
        }
      }
    })

    productDetails.predictiveTrendChart.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        predictiveChartData[i] = {
          period: splitedPeriod[0],
          engagementScore: value.engagementScore,
          // _confidence: value.confidence
        }
      } else {
        predictiveChartData[i] = {
          period: value.period,
          engagementScore: value.engagementScore,
          // _confidence: value.confidence
        }
      }
    })

    productDetails.socialData.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        socialData[i] = {
          period: splitedPeriod[0],
          engagementScore: value.engagementScore,
        }
      } else {
        socialData[i] = {
          period: value.period,
          engagementScore: value.engagementScore,
        }
      }
    })

    productDetails.webSearchData.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        searchData[i] = {
          period: splitedPeriod[0],
          engagementScore: value.engagementScore,
        }
      } else {
        searchData[i] = {
          period: value.period,
          engagementScore: value.engagementScore,
        }
      }
    })

    const dataToSave = {
      name: productDetails.name,
      lens: 'Product',
      trend_name: productDetails.trendName,
      filter_classification: productDetails.filterClassification,
      ...this.getChartDataExcel(),
      category_data: productDetails.category_data,
      retail_format: productDetails._productFormat.retail,
      retail_data_point: productDetails._retail_data_point,
      food_service_format: productDetails._productFormat.cafe,
      food_service_data_point: productDetails._cafe_data_point,
    }

    let request = ''
    let excelFileName = ''
    if (args[1]) {
      request = NetworkingUtil.getCIExcel()
      excelFileName = `${productDetails.name}_${productDetails.country}_${productDetails.category}_Consumer_Insights_Report.xlsx`
    } else if (args[2]) {
      request = NetworkingUtil.getRFExcel()
      excelFileName = `${productDetails.name}_${productDetails.country}_${productDetails.category}_Retail__ProductFormat_Report.xlsx`
    } else if (args[3]) {
      request = NetworkingUtil.getFSFExcel()
      excelFileName = `${productDetails.name}_${productDetails.country}_${productDetails.category}_FoodService_ProductFormat_Report.xlsx`
    } else if (args[4]) {
      request = NetworkingUtil.getAllExcel()
      excelFileName = `${productDetails.name}_${productDetails.country}_${productDetails.category}_Complete_Report.xlsx`
    } else {
      request = NetworkingUtil.getTrendExcel()
      excelFileName = productDetails.name + '_Monthly_Trend_Graph' + '.xlsx'
    }

    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, excelFileName)
        this.setState({
          loader: false,
        })
      })
      .catch((err) => {
        this.setState({
          loader: false,
        })
      })
  }

  cIExcelDownload = () => {
    this.saveGraphExcel(false, true, false, false, false)
  }

  rFExcelDownload = () => {
    this.saveGraphExcel(false, false, true, false, false)
  }

  fSExcelDownload = () => {
    this.saveGraphExcel(false, false, false, true, false)
  }

  consolidatedExcelDownlaod = () => {
    const { productDetails } = this.props
    const productName = productDetails.name
    const category = productDetails.category
    const country = productDetails.country
    const inExcelFileName = `${productName} in ${country} ${category} EXCEL`
    this.saveGraphExcel(false, false, false, false, true, inExcelFileName)
  }

  getChartDataPpt = () => {
    const parseChartData = (data) => ({
      _period: Moment(data[0]).format('YYYY-MM-DD'),
      _engagementScore: data[1],
    })
    let chartData = []
    let predictiveChartData = []
    // eslint-disable-next-line
    let cagr_series = []
    // eslint-disable-next-line
    let cagr_val = ''
    const chartDataTmp = this.filteredTrendData.find(
      (arr) => arr.name === 'Historical Growth',
    )
    if (chartDataTmp) {
      chartData = chartDataTmp.data.map(parseChartData)
    }
    const predictiveChartDataTmp = this.filteredTrendData.find(
      (arr) => arr.name === 'Predicted Growth',
    )
    if (predictiveChartDataTmp) {
      predictiveChartData = predictiveChartDataTmp.data.map(parseChartData)
    }
    const cagrDataTmp = this.filteredTrendData.find(
      (arr) => arr.name === '4 year CAGR',
    )
    if (cagrDataTmp) {
      // eslint-disable-next-line
      cagr_series = cagrDataTmp.data.map(parseChartData)
      // eslint-disable-next-line
      cagr_val = `${this.chartCagr}`
    }

    return {
      chartData,
      predictiveChartData,
      cagr_series,
      cagr_val,
    }
  }

  updateProductFormats(productFormats) {
    const formats = []
    for (const name in productFormats) {
      formats.push({
        name: name,
        percentage_value: productFormats[name],
      })
    }
    return formats
  }

  saveGraphPpt = () => {
    const predChart = this.props.productDetails.predictiveTrendChart
    const { productDetails } = this.props
    const productName = productDetails.name
    const country = productDetails.country
    const dataToSave = {
      name: productName,
      country,
      yoy: this.props.productDetails.yoyGrowthRate,
      ...this.getChartDataPpt(),
      confidenceLevel:
        (predChart &&
          predChart[0] &&
          predChart[0].confidence &&
          predChart[0].confidence[0] +
            predChart[0].confidence.slice(1).toLowerCase()) ||
        '',
    }
    const request = NetworkingUtil.getMonthlyTrendsGraphPpt()
    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, `${dataToSave.name}_Monthly_Trend_Graph.pptx`)
        // this.props.spinnerDownload(false)
      })
      .catch((err) => {
        // console.log(err)
        // this.props.spinnerDownload(false)
      })
  }

  saveSectionToPpt = (section, sortBy) => {
    this.setState({
      loader: true,
    })
    const { productDetails } = this.props
    const productName = productDetails.name
    const category = productDetails.category
    const country = productDetails.country
    switch (section) {
      case 'consumerNeeds':
        {
          const consumerInsightsRequest = NetworkingUtil.getConsumerInsightsPpt()
          const consumerInsightsData = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            category_data: productDetails.category_data,
            project_id: this.props.match.params.projectId,
          }
          axios
            .post(consumerInsightsRequest, consumerInsightsData, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(
                data,
                `${consumerInsightsData.productName}_ConsumerNeeds.pptx`,
              )
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              // console.log(err)
              this.setState({
                loader: false,
              })
            })
        }
        break
      case 'cafe_product':
        {
          const cafeProducts = this.props.cafeProducts
          const cafeProductsRequest = NetworkingUtil.getCafeProductsPpt()
          let filteredCafeProducts = []
          if (cafeProducts) {
            filteredCafeProducts = cafeProducts.slice(0, 4)
          }
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            cafeProducts: cafeProducts,
            cafeDataPoint: this.props.productDetails._cafe_data_point,
            cafeCategoryData:
              this.updateProductFormats(this.props.cafeProductFormats) || [],
          }
          axios
            .post(cafeProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_Cafe_Products.pptx`)
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              // console.log(err)
              this.setState({
                loader: false,
              })
            })
        }
        break
      case 'retail_product':
        {
          let filteredRetailProducts = []
          const startRange = 0
          const endRange = 4
          if (this.props.retailProducts) {
            filteredRetailProducts = this.props.retailProducts.slice(
              startRange,
              endRange,
            )
            filteredRetailProducts = filteredRetailProducts.map((s) => {
              if (isNaN(s.price)) {
                s.price = 0
              } else {
                if (typeof s.price === 'string') s.price = s.price.trim()
                // eslint-disable-next-line
                else s.price = s.price
              }
              return s
            })
          }

          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            retailProducts: filteredRetailProducts,
            retailDataPoint: productDetails._retail_data_point,
            retailCategoryData:
              this.updateProductFormats(this.props.retailProductFormats) || [],
          }
          const retailProductsRequest = NetworkingUtil.getRetailProductsPpt()
          axios
            .post(retailProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(
                data,
                `${dataToSave.productName}_RelatedRetailProducts.pptx`,
              )
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              // console.log(err)
              this.setState({
                loader: false,
              })
            })
        }
        break
      case 'relatedProducts':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedProducts: this.filterDuplicates(
              this.props.relatedTrendsDetails.related_products,
            ),
            sortBy: sortBy || 'Rank',
          }
          const relatedProductsRequest = NetworkingUtil.getRelatedProductsPpt()
          axios
            .post(relatedProductsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedProducts.pptx`)
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              this.setState({
                loader: false,
              })
            })
        }
        break
      case 'relatedThemes':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedThemes: this.filterDuplicates(
              this.props.relatedTrendsDetails.related_themes,
            ),
            sortBy: sortBy || 'Rank',
          }
          const relatedThemesRequest = NetworkingUtil.getRelatedThemesPpt()
          axios
            .post(relatedThemesRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedThemes.pptx`)
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              this.setState({
                loader: false,
              })
            })
        }
        break
      case 'relatedIngredients':
        {
          const dataToSave = {
            productName: productName,
            cCategory: `${country} - ${category}`,
            relatedIngredients: this.filterDuplicates(
              this.props.relatedTrendsDetails.related_ingredients,
            ),
            sortBy: sortBy || 'Rank',
          }
          const relatedIngredientsRequest = NetworkingUtil.getRelatedIngredientsPpt()
          axios
            .post(relatedIngredientsRequest, dataToSave, {
              responseType: 'blob',
            })
            .then(({ data }) => {
              // eslint-disable-next-line
              saveAs(data, `${dataToSave.productName}_RelatedIngredients.pptx`)
              this.setState({
                loader: false,
              })
            })
            .catch((err) => {
              this.setState({
                loader: false,
              })
            })
        }
        break
    }
  }

  togglePptDownload = () => {
    this.setState({
      loader: true,
    })
    const { productDetails } = this.props
    const productName = productDetails.name
    const category = productDetails.category
    const country = productDetails.country
    const trendChart = productDetails.trendChart
    const ingredientQuotes = this.props.productDetails.ingredientQuotes
    const fileName = `${productName} in ${country} ${category} PPT`
    var chartData = []
    const predictiveChartData = []
    trendChart.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        chartData[i] = {
          _period: splitedPeriod[0],
          _engagementScore: value.engagementScore,
        }
      } else {
        chartData[i] = {
          _period: value.period,
          _engagementScore: value.engagementScore,
        }
      }
    })

    productDetails.predictiveTrendChart.forEach(function(value, i) {
      var splitedPeriod = value.period.split('T')
      if (splitedPeriod.length != 0 && splitedPeriod.length > 1) {
        predictiveChartData[i] = {
          _period: splitedPeriod[0],
          _engagementScore: value.engagementScore,
          // _confidence: value.confidence
        }
      } else {
        predictiveChartData[i] = {
          _period: value.period,
          _engagementScore: value.engagementScore,
          _confidence: value.confidence,
        }
      }
    })

    // Related
    let relatedThemesSafe = null
    let relatedProductsSafe = null
    let relatedIngredientsSafe = null
    if (
      // eslint-disable-next-line
      typeof this.props.relatedTrendsDetails !== undefined &&
      this.props.relatedTrendsDetails
    ) {
      relatedThemesSafe = []
      relatedProductsSafe = this.filterDuplicates(
        this.props.relatedTrendsDetails.related_products,
      )
      relatedIngredientsSafe = this.filterDuplicates(
        this.props.relatedTrendsDetails.related_ingredients,
      )
    }

    // Retail products
    let filteredRetailProducts = []
    const startRange = 0
    const endRange = 4
    if (this.props.retailProducts) {
      filteredRetailProducts = this.props.retailProducts.slice(
        startRange,
        endRange,
      )
      filteredRetailProducts = filteredRetailProducts.map((s) => {
        if (isNaN(s.price)) {
          s.price = 0
        } else {
          if (typeof s.price === 'string') s.price = s.price.trim()
          // eslint-disable-next-line
          else s.price = s.price
        }
        return s
      })
    }

    // On Premise and Home Products
    const cafeProducts = this.props.cafeProducts
    let filteredCafeProducts = []
    if (cafeProducts) {
      filteredCafeProducts = cafeProducts.slice(0, 4)
    }
    const predChart = productDetails.predictiveTrendChart

    const dataToSave = {
      project_id: parseInt(this.props.match.params.projectId),
      country: country,
      lens: getUrlSelectedLens(this.props),
      cCategory: `${country} - ${category}`,
      productName: productName,
      imgurl: productDetails.productImage,
      mainDataPoint: productDetails._data_point,
      rank: productDetails.rank,
      trend: productDetails.trendName,
      filterClassification: productDetails.filterClassification,
      positiveSenti: productDetails.positiveSentiment,
      neutralSenti: productDetails.neutralSentiment,
      negativeSenti: productDetails.negativeSentiment,
      confidenceLevel:
        (predChart &&
          predChart[0] &&
          predChart[0].confidence &&
          predChart[0].confidence[0] +
            predChart[0].confidence.slice(1).toLowerCase()) ||
        '',
      name: productName,
      yoy: productDetails.yoyGrowthRate,
      ...this.getChartDataPpt(),

      category_data: productDetails.category_data,

      relatedThemes: relatedThemesSafe,
      relatedProducts: relatedProductsSafe,
      relatedIngredients: relatedIngredientsSafe,
      sortBy: 'Rank',
      cafeProducts: filteredCafeProducts,
      cafeDataPoint: productDetails._cafe_data_point,
      cafeCategoryData:
        this.updateProductFormats(this.props.cafeProductFormats) || [],
      retailProducts: filteredRetailProducts,
      retailDataPoint: productDetails._retail_data_point,
      retailCategoryData:
        this.updateProductFormats(this.props.retailProductFormats) || [],
    }

    const request = NetworkingUtil.getWholePagePpt()
    axios
      .post(request, dataToSave, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(
          data,
          `${productName}_${country}_${category}_Complete_Report.pptx`,
        )
        this.setState({
          loader: false,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          loader: false,
        })
      })
  }

  fetchData(productid, lensSelected) {
    this.props.fetchProductDetails(productid, lensSelected)
    this.props.fetchCafeProducts(productid, lensSelected)
    this.props.fetchRetailProducts(productid, lensSelected)
    this.props.fetchRelatedTrends(productid, lensSelected)
    this.props.fetchConsumerQuotes(productid, lensSelected)
    this.props.getRelatedTrendsV2(
      this.props.relatedTrendsV2,
      lensSelected.toLowerCase(),
      productid,
      { theme: this.state.selectedThemeSubCategory },
      ['theme'],
    )
  }

  getRelatedTrendsUrlOnClick = (blockName, data) => {
    return (
      '/mui/details/' +
      this.props.projectid +
      '/' +
      blockName.toLowerCase() +
      '/' +
      data.id
    )
  }

  onThemeSubCategoryChange = (selectedValue) => {
    this.logSubCategorySelection(selectedValue)
    this.setState({
      selectedThemeSubCategory: selectedValue,
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const lensSelected = 'Product'
    const productid = Number(this.props.match.params.productId)
    this.fetchData(productid, lensSelected)
    if (this.props.projectid) {
      this.props.fetchAllTrends(this.props.projectid)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const lensSelected = 'Product'
    const productid = Number(this.props.match.params.productId)
    if (productid !== Number(prevProps.match.params.productId)) {
      window.scrollTo(0, 0)
      this.props.resetProductDetails()
      this.fetchData(productid, lensSelected)
    }
    if (
      this.state.selectedThemeSubCategory !== prevState.selectedThemeSubCategory
    ) {
      this.props.getRelatedTrendsV2(
        this.props.relatedTrendsV2,
        lensSelected.toLowerCase(),
        productid,
        { theme: this.state.selectedThemeSubCategory },
        ['theme'],
      )
    }
    if (this.props.projectid && this.props.projectid !== prevProps.projectid) {
      this.props.fetchAllTrends(this.props.projectid)
    }
  }

  componentWillUnmount() {
    this.props.resetProductDetails()
  }

  fetchCQ() {
    const lensSelected = PRODUCT
    const productid = Number(this.props.match.params.productId)
    this.props.fetchConsumerQuotes(productid, lensSelected)
  }

  refreshFsrData() {
    const lensSelected = PRODUCT
    const productid = Number(this.props.match.params.productId)
    this.props.fetchCafeProducts(productid, lensSelected)
    this.props.fetchRetailProducts(productid, lensSelected)
  }

  render() {
    const noSideCPFormastsProps = this.props.cafeProductFormats
      ? {}
      : { noSide: true }
    const noSideRPFormatsProps = this.props.retailProductFormats
      ? {}
      : { noSide: true }
    const { loader } = this.state
    if (
      !this.props.productDetails ||
      this.props.productDetails._id !== this.props.productid
    )
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              mt: 2,
              pt: 32,
              px: 88,
              flexGrow: 2,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <CircularProgressCustom />
          </Box>
        </Grid>
      )
    return (
      <React.Fragment>
        <Stack spacing={3} sx={{ my: 3 }} id="_dowSectionDesc">
          {(loader || this.props.relatedTrendsLoader) && <OverlayLoader />}
          <Box>
            <Stack spacing={3}>
              <ProductItemDetailsTop
                projectid={this.props.projectid}
                productid={this.props.productid}
                name={this.props.productDetails.name}
                country={this.props.productDetails.country}
                category={this.props.productDetails.category}
                trendName={this.props.productDetails.trendName}
                type={this.props.productDetails.trendName}
                rank={this.props.productDetails.rank}
                dataPoint={this.props.productDetails.dataPoint}
                productImage={this.props.productDetails.productImage}
                filterClassfn={this.props.productDetails.filterClassification}
                positiveSentiment={this.props.productDetails.positiveSentiment}
                neutralSentiment={this.props.productDetails.negativeSentiment}
                negativeSentiment={this.props.productDetails.neutralSentiment}
                details={this.props.productDetails}
                toggleImageDownload={this.toggleImageDownload}
                consolidatedExcelDownlaod={this.consolidatedExcelDownlaod}
                togglePptDownload={this.togglePptDownload}
                getIsBookmarkChecked={this.props.getIsBookmarkChecked}
                isBookmarkChecked={this.props.isBookmarkChecked}
                removeBookmarked={this.props.removeBookmarked}
                bookmarkedSwingChange={this.props.bookmarkedSwingChange}
                getBookmarkData={this.props.getBookmarkData}
                isBookmarkSwing={this.props.isBookmarkSwing}
              />
              <Box id="_dowSectionChart">
                <TrendGrowthCard
                  details={this.props.productDetails}
                  getChartDataExcel={this.getChartDataExcel}
                  getChartDataPpt={this.getChartDataPpt}
                  saveFilteredTrendData={this.saveFilteredTrendData}
                  toggleImageDownload={this.toggleImageDownload}
                  togglePptDownload={this.saveGraphPpt}
                  handleDownloadExcel={this.saveGraphExcel}
                  lens={this.props.lensSelected}
                  largeView
                />
              </Box>
              <Box id="_dowSectionCati">
                {this.props.productDetails.category_data && (
                  <DetailedConsumerInsights
                    expand={loader}
                    projectid={this.props.projectid}
                    productid={this.props.productid}
                    lensSelected="product"
                    productType="consumerNeeds"
                    categoryData={this.props.productDetails.category_data}
                    country={this.props.productDetails._country}
                    category={this.props.productDetails._category}
                    name={this.props.productDetails._name}
                    email={this.props.email}
                    toggleImageDownload={this.toggleImageDownload}
                    cIExcelDownload={this.cIExcelDownload}
                    togglePptDownload={this.saveSectionToPpt}
                    isBPC={this.props.projectVariant === BPC}
                  />
                )}
              </Box>

              <RegionalAndAgeGroupDistributionGraph
                country={this.props.productDetails._country}
                trendName={this.props.productDetails._name}
                category={this.props.productDetails._category}
                projectId={this.props.match.params.projectId}
                lens={PRODUCT}
                themeId={this.props.match.params.productId}
              />

              <Box id="_dowSectionCafePdct">
                {this.props.productDetails.cafeDataPoint !== 0 &&
                  this.props.cafeProducts.length !== 0 &&
                  this.props.projectVariant === 'food' && (
                    <FoodServiceProducts
                      isProductPage
                      expand={loader}
                      {...noSideCPFormastsProps}
                      projectid={this.props.projectid}
                      productid={this.props.productid}
                      productType="cafe_product"
                      cafeDataPoint={this.props.productDetails.cafeDataPoint}
                      cafeProductFormats={this.props.cafeProductFormats}
                      cafeProducts={this.props.cafeProducts}
                      toggleImageDownload={this.toggleImageDownload}
                      togglePptDownload={this.saveSectionToPpt}
                      fSExcelDownload={this.fSExcelDownload}
                      country={this.props.productDetails._country}
                      trendName={this.props.productDetails._name}
                      category={this.props.productDetails._category}
                      lens={PRODUCT}
                      onRefresh={() => {
                        this.refreshFsrData()
                      }}
                    />
                  )}
              </Box>
              <Box id="_dowSectionRetailPdct">
                {this.props.productDetails.retailDataPoint !== 0 &&
                  this.props.retailProducts.length !== 0 && (
                    <RelatedRetailProducts
                      isProductPage
                      expand={loader}
                      {...noSideRPFormatsProps}
                      productType="retail_product"
                      projectid={this.props.projectid}
                      retailDataPoint={
                        this.props.productDetails.retailDataPoint
                      }
                      defaultSortOrder="desc"
                      defaultOrderKey="data_point"
                      retailProductFormats={this.props.retailProductFormats}
                      projectVariant={this.props.projectVariant}
                      retailProducts={this.props.retailProducts}
                      toggleImageDownload={this.toggleImageDownload}
                      togglePptDownload={this.saveSectionToPpt}
                      rFExcelDownload={this.rFExcelDownload}
                      country={this.props.productDetails._country}
                      trendName={this.props.productDetails._name}
                      name={this.props.productDetails._name}
                      category={this.props.productDetails._category}
                      lens={'Product'}
                      onRefresh={() => {
                        this.refreshFsrData()
                      }}
                    />
                  )}
              </Box>
              <Box id="_dowSectionThemeBlock">
                <ErrorHandler>
                  <RelatedTrends
                    relatedTrends={
                      this.props.relatedTrendsV2.theme?.[
                        this.state.selectedThemeSubCategory
                      ] || {}
                    }
                    defaults={{
                      defaultSortOrder: 'desc',
                      defaultOrderKey: 'data_point',
                      defaultMaturityPhase: PHASES[3],
                    }}
                    parentTrendName={this.props.productDetails.name}
                    lens="Product"
                    blockName="Theme"
                    showAssocLvl
                    getRedirectionUrl={this.getRelatedTrendsUrlOnClick}
                    selectedSubCategory={this.state.selectedThemeSubCategory}
                    onSubCategoryChange={this.onThemeSubCategoryChange}
                    subCategoriesList={this.props.themeSubCatList}
                    type="productPage"
                    logTrendClick={this.logTrendClick}
                  />
                </ErrorHandler>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <ConsumerQuotesCardComponent
            consumerQuotes={this.props.consumerQuotes}
            name={this.props.productDetails.name}
            country={this.props.productDetails.country}
            email={this.props.email}
            category={this.props.productDetails.category}
            lens="Product"
            maturityPhase={this.props.productDetails.trendName}
            onRefresh={() => {
              this.fetchCQ()
            }}
            sourceShow
            markIrrelevantDisabled={false}
            projectVariant={this.props.projectVariant}
          />
        </Stack>
        <ComparePopup
          name={this.props.productDetails.name}
          country={this.props.productDetails.country}
          email={this.props.email}
          maturityPhase={this.props.productDetails.trendName}
          category={this.props.productDetails.category}
          lens="Product"
        />
        <Box id="downloadCanvas"></Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const projectid = Number(props.match.params.projectId)
  const productid = Number(props.match.params.productId)
  const lensSelected = 'Product'
  const themeSubCatList =
    state.projectListBuilder?.allTrendsList?.theme_sub_category || []
  return {
    projectid: projectid,
    productid: productid,
    lensSelected: lensSelected,
    productDetails: state.productDetails.productDetails,
    pairingDetails: state.productDetails.pairingDetails,
    cafeProducts: state.productDetails.cafeProducts,
    retailProducts: state.productDetails.retailProducts,
    cafeProductFormats: state.productDetails.cafeProductFormats,
    retailProductFormats: state.productDetails.retailProductFormats,
    relatedTrendsDetails: state.productDetails.relatedTrendsDetails,
    relatedTrendsV2: state.productDetails.relatedTrendsV2,
    relatedTrendsLoader: state.productDetails.loader.relatedTrendsV2,
    consumerQuotes: state.productDetails.ingredientQuotes,
    isBookmarkChecked: state.bookmark,
    isBookmarkSwing: state.bookmark.isBookmarkSwing,
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    projectVariant: state.productDetails.productDetails?.projectVariant,
    themeSubCatList: [...themeSubCatList].sort((a, b) =>
      a.name > b.name ? 1 : -1,
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductDetails: (productid, lensSelected) =>
      dispatch(actions.fetchProductDetails(productid, lensSelected)),
    fetchPairingDetails: (productid, lensSelected) =>
      dispatch(actions.fetchPairingDetails(productid, lensSelected)),
    fetchProjectTrendList: (projectid) =>
      dispatch(actions.fetchProjectList(projectid)),
    fetchRetailProductFormats: (productid, lensSelected) =>
      dispatch(actions.fetchRetailProductFormats(productid, lensSelected)),
    fetchCafeProductFormats: (productid, lensSelected) =>
      dispatch(actions.fetchCafeProductFormats(productid, lensSelected)),
    fetchCafeProducts: (productid, lensSelected) =>
      dispatch(actions.fetchCafeProducts(productid, lensSelected)),
    fetchRetailProducts: (productid, lensSelected) =>
      dispatch(actions.fetchRetailProducts(productid, lensSelected)),
    fetchRelatedTrends: (productid, lensSelected) =>
      dispatch(actions.fetchRelatedTrends(productid, lensSelected)),
    getRelatedTrendsV2: (
      currentRelatedTrendsState,
      lens,
      trendId,
      subCategories,
      include,
    ) =>
      dispatch(
        actions.getRelatedTrendsV2(
          currentRelatedTrendsState,
          lens,
          trendId,
          subCategories,
          include,
        ),
      ),
    fetchAllTrends: (projectId) => dispatch(fetchAllTrends(projectId)),
    fetchConsumerQuotes: (productid, lensSelected) =>
      dispatch(actions.fetchIngredientQuotes(productid, lensSelected)),
    resetProductDetails: () => dispatch(actions.resetProductDetails()),
    spinnerDownload: (isShow) => dispatch(actions.spinnerDownload(isShow)),
    getIsBookmarkChecked: (lens, trendId) =>
      dispatch(actions.isCheckedBookmarked(lens, trendId)),
    removeBookmarked: (removeId) =>
      dispatch(actions.removeBookmarked(removeId)),
    bookmarkedSwingChange: (swing) =>
      dispatch(actions.bookmarkedSwingChange(swing)),
    getBookmarkData: () => dispatch(actions.fetchBookmarks()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(ProductItemPage, axios)),
)
