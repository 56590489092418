import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import { forgetPasswordOnServer } from '../../store/actions'
import {
  Box,
  Button,
  CssBaseline,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LayoutLoginV3 from '../../layouts/LayoutLoginV3'

import { formatString } from '../../store/utility'

import '../muiStyle.css'

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .required(),
  })
  .required()

const ForgotPasswordPage = (props) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data) => {
    await Object.assign(data, { origin: window.location.origin.toString() })
    setError('')
    const response = await props.forgetPassword({
      ...data,
      origin: window.location.origin,
    })
    if (response.data && response.data.result) {
      localStorage.setItem(
        '__ai_pass_sent_notif',
        'Reset password instructions have been sent to mail.',
      )
      history.push('/')
    } else {
      setError(
        response.data
          ? response.data.message
          : 'No Account associated with given email.',
      )
    }
  }

  return (
    <LayoutLoginV3>
      <CssBaseline />
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ width: 400 }}
      >
        <Stack spacing={1} textAlign="center">
          <Typography variant="h2">Password assistance</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Enter your email to recover your password. You will receive an email
            with instructions.
          </Typography>
        </Stack>
        {error && (
          <Typography color="error.main" variant="subtitle2">
            {error}
          </Typography>
        )}
        <form style={{ maxWidth: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, value, name, ref },
              fieldState: { error },
            }) => (
              <TextField
                inputRef={ref}
                name={name}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? formatString(error.message, true) : ''}
                variant="outlined"
                size="large"
                label="Email Address"
                placeholder="Email"
                sx={{ width: 400 }}
              />
            )}
          />
          <Box sx={{ mt: 4, mb: 1 }}>
            <Button
              variant="contained"
              size="large"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              {!isSubmitting ? 'Continue' : <CircularProgress size={16} />}
            </Button>
          </Box>
          <Stack alignItems="flex-end">
            <Button variant="outlined" href="/" size="large" fullWidth>
              Back to Login
            </Button>
          </Stack>
        </form>
      </Stack>
    </LayoutLoginV3>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgetPassword: (values) => dispatch(forgetPasswordOnServer(values)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ForgotPasswordPage))
