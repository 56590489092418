import { Box, Typography } from '@mui/material'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import React from 'react'
import DashboardTooltip from '../DashboardTooltip'

const DashboardCAGRLabel = (props) => {
  const {
    boxProps,
    title,
    twoYearCAGR,
    twoYearCAGRChange,
    variant,
    hideTooltip,
    hasAmplitudeEvent,
  } = props
  const handleHoverOpen = () => {
    if (hasAmplitudeEvent) {
      hasAmplitudeEvent.handleHoverOpen('2 Yr CAGR')
    }
  }
  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      {title && (
        <Typography
          variant={variant || 'caption2'}
          sx={{
            color: ({ palette }) => palette.text.secondary,
          }}
        >
          {title}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Typography
          variant={variant || 'caption2'}
          sx={{
            color: ({ palette }) =>
              twoYearCAGR >= 0 ? palette.success.dark : palette.error.dark,
          }}
        >
          &nbsp;{twoYearCAGR >= 0 && '+'}
          {twoYearCAGR}%
        </Typography>
        {(twoYearCAGRChange || twoYearCAGRChange === 0) &&
          (hideTooltip ? (
            <Typography
              variant={variant || 'caption2'}
              sx={{
                color: ({ palette }) =>
                  twoYearCAGRChange >= 0
                    ? palette.success.dark
                    : palette.error.dark,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              &nbsp;(
              {twoYearCAGRChange >= 0 ? (
                <ArrowDropUpRoundedIcon fontSize="small" sx={{ mx: '-4px' }} />
              ) : (
                <ArrowDropDownRoundedIcon
                  fontSize="small"
                  sx={{ mx: '-4px' }}
                />
              )}
              {Math.abs(twoYearCAGRChange)}
              %)
            </Typography>
          ) : (
            <DashboardTooltip
              title={`Compare to last month 2 Yr CAGR the Trend has ${
                twoYearCAGRChange >= 0 ? 'increased' : 'decreased'
              } by ${Math.abs(twoYearCAGRChange)}`}
              handleOpen={handleHoverOpen}
            >
              <Typography
                variant={variant || 'caption2'}
                sx={{
                  color: ({ palette }) =>
                    twoYearCAGRChange >= 0
                      ? palette.success.dark
                      : palette.error.dark,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                &nbsp;(
                {twoYearCAGRChange >= 0 ? (
                  <ArrowDropUpRoundedIcon
                    fontSize="small"
                    sx={{ mx: '-4px' }}
                  />
                ) : (
                  <ArrowDropDownRoundedIcon
                    fontSize="small"
                    sx={{ mx: '-4px' }}
                  />
                )}
                {Math.abs(twoYearCAGRChange)}
                %)
              </Typography>
            </DashboardTooltip>
          ))}
      </Box>
    </Box>
  )
}

export default DashboardCAGRLabel
