import React from 'react'

import {
  Radio,
  Grid,
  TextField,
  Box,
  Button,
  List,
  Checkbox,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

function FeaturesTabUI({
  handleNumberOfConsumerQuotes,
  numberOfConsumerQuotes,
  handleSelectMainFeatures,
  handleSelectsubFeatures,
  handleEnableOnly,
  handleFeatureTab,
  subfeaturesList,
  featureList,
  handleExit,
  handleFeatures,
}) {
  return (
    <>
      <Box sx={{ padding: '20px' }}>
        {featureList?.features?.map((feat, index) => (
          <List>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '700px',
                alignItems: 'center',
              }}
            >
              {feat.name}
              <Grid>
                <FormControlLabel
                  disabled={feat.disabled}
                  control={
                    <Radio
                      checked={feat.value === true}
                      onClick={() => {
                        !feat.value === true &&
                          handleSelectMainFeatures(feat.column_name)
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="disabled"
                  disabled={feat.disabled}
                  control={
                    <Radio
                      checked={feat.value === false}
                      onClick={() => {
                        !feat.value === false &&
                          handleSelectMainFeatures(feat.column_name)
                      }}
                    />
                  }
                  label="No"
                />
              </Grid>
            </Grid>

            {subfeaturesList?.map((p, index2) => {
              if (index === index2)
                return p.map((subFeat) => {
                  return (
                    <List
                      sx={{
                        padding: '0px',
                        marginLeft: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        color:
                          feat.value === false &&
                          ((theme) => theme.palette.text.disabled),
                      }}
                    >
                      <Box width={740}>{subFeat.name}</Box>
                      {subFeat.type === 'radio' ? (
                        <Grid display="flex">
                          <FormControlLabel
                            disabled={feat.value === false || subFeat.disabled}
                            control={
                              <Radio
                                checked={
                                  !(feat.value === false) &&
                                  subFeat.value === true
                                }
                                onClick={() => {
                                  !subFeat.value === true &&
                                    handleSelectsubFeatures(subFeat.column_name)
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={feat.value === false || subFeat.disabled}
                            control={
                              <Radio
                                checked={
                                  !feat.value === false &&
                                  subFeat.value === false
                                }
                                onClick={() => {
                                  !subFeat.value === false &&
                                    handleSelectsubFeatures(subFeat.column_name)
                                }}
                              />
                            }
                            label="No"
                          />
                          {subFeat.only_enabled !== undefined && (
                            <FormControlLabel
                              disabled={feat.value === false}
                              label="Only enable this feature"
                              control={
                                <Checkbox
                                  onChange={() =>
                                    handleEnableOnly(
                                      subFeat.column_name,
                                      subFeat.only_enabled,
                                    )
                                  }
                                  checked={subFeat.only_enabled}
                                />
                              }
                            />
                          )}
                        </Grid>
                      ) : (
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '150px',
                            alignItems: 'center',
                            ml: '10px',
                          }}
                        >
                          <FormControlLabel
                            disabled={feat.value === false || subFeat.disabled}
                            control={
                              <TextField
                                value={numberOfConsumerQuotes}
                                defaultValue={subFeat.value}
                                sx={{
                                  width: '50px',
                                }}
                                onChange={handleNumberOfConsumerQuotes}
                              />
                            }
                          />
                          {subFeat.only_enabled !== undefined && (
                            <FormControlLabel
                              disabled={feat.value === false}
                              label="Only enable this feature"
                              control={
                                <Checkbox
                                  onChange={() =>
                                    handleEnableOnly(
                                      subFeat.column_name,
                                      subFeat.only_enabled,
                                    )
                                  }
                                  checked={subFeat.only_enabled}
                                />
                              }
                            />
                          )}
                        </Grid>
                      )}
                    </List>
                  )
                })
            })}
          </List>
        ))}

        <Box
          sx={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          mt={4}
        >
          <Grid>
            <Button
              alignItems="center"
              variant="contained"
              size="large"
              type="submit"
              onClick={handleExit}
            >
              Exit Without Saving
            </Button>
          </Grid>
          <Grid>
            <Button
              alignItems="center"
              variant="contained"
              size="large"
              type="submit"
              onClick={handleFeatures}
              sx={{
                marginRight: '100px',
              }}
            >
              Save and Exit
            </Button>
            <Button
              alignItems="center"
              variant="contained"
              size="large"
              type="submit"
              onClick={handleFeatureTab}
            >
              Next
            </Button>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
export default FeaturesTabUI
