import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import requestSavedTrendsReducer from './requestSavedTrendsReducer'
import requestNewTrendsReducer from './requestNewTrendReducer'
import userSelectionsReducer from './userSelectionReducer'
import projectListReducer from './projectListReducers'
import productDetailsReducer from './productDetailsReducer'
import needDetailsReducer from './needDetailsReducer'
import organizationReducer from './organizationReducer'
import buildWinnerReducer from './buildWinnerReducer'
import userReducer from './userReducer'
import applicationReducer from './applicationReducer'
import permissionReducer from './permissionReducer'
import roleReducer from './roleReducer'
import productComparisionReducer from './productComparisionReducer'
import chartReducer from './chartReducer'
import CountryReducer from './countryReducer'
import searchCategoriesReducer from './searchCategoriesReducer'
import searchLookForReducer from './LookForReducer'
import searchReducer from './searchReducer'
import downloadSections from './downloadSections'
import productNeed from './productNeedReducer'
import productTopic from './productTopicReducer'
import screenWinner from './ScreenWinner/screenWinner'
import whiteSpaceReducers from './whiteSpaceReducer'
import bookmarkReducer from './bookmarkReducer'
import commonTrendAnalysis from './commonTrendAnalysis'
import advancedTrendAnalysis from './advancedTrendAnalysis'
import fetchSharingUsers from './sharingTrendsReducer'
import createpillarReducer from './createPillarReducer'
import highlightFeature from './highLightFeatureReducer'
import globalTrendSearchReducer from './globalSearchTrend'
import cognitiveSearch from './cognitiveSearch'
import dashboardReducer from './dashboardReducer'
import subscriptionReducer from './subscriptionReducer'
import conceptGenieReducer from './conceptGenieReducer'

const appReducer = combineReducers({
  subscriptions: subscriptionReducer,
  application: applicationReducer,
  savedTrendRequester: requestSavedTrendsReducer,
  newTrendsRequester: requestNewTrendsReducer,
  userSelections: userSelectionsReducer,
  projectListBuilder: projectListReducer,
  productDetails: productDetailsReducer,
  needDetails: needDetailsReducer,
  buildWinner: buildWinnerReducer,
  user: userReducer,
  form: formReducer,
  organization: organizationReducer,
  bookmark: bookmarkReducer,
  permission: permissionReducer,
  role: roleReducer,
  productComparision: productComparisionReducer,
  chart: chartReducer,
  country: CountryReducer,
  searchCategory: searchCategoriesReducer,
  searchLookFor: searchLookForReducer,
  search: searchReducer,
  downloadSections: downloadSections,
  productNeed: productNeed,
  productTopic: productTopic,
  screenWinner,
  whiteSpace: whiteSpaceReducers,
  commonTrendAnalysis,
  advancedTrendAnalysis,
  fetchSharingUsers,
  trendPillar: createpillarReducer,
  highlightFeature,
  globalSearchTrends: globalTrendSearchReducer,
  cognitiveSearch,
  myDashboard: dashboardReducer,
  conceptGenie: conceptGenieReducer,
})

export default appReducer
