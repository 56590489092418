import typography from '../typography'
import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      minHeight: 'unset',
      ...typography.subtitle2,
      '&.MuiTab-labelIcon': {
        padding: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        '& .MuiSvgIcon-root': {
          height: 16,
          width: 16,
          color: palette.icon,
        },
      },
      '&.Mui-selected': {
        color: palette.secondary.main,
        '& .MuiSvgIcon-root': {
          color: palette.secondary.main,
        },
      },
      '&:hover': {
        backgroundColor: palette.background.default,
        boxShadow: `inset 0px -2px 0px 0px ${palette.grey[300]}`,
      },
      '&>.MuiTab-iconWrapper': {
        margin: '0px',
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
  },
  variants: [
    {
      props: { variant: 'withoutBackground' },
      style: {
        textDecoration: 'none',
        color: palette.text.secondary,
        fontWeight: 400,
        paddingBottom: 0,
        '&.Mui-selected': {
          color: palette.primary.main,
          fontWeight: 500,
          '& .MuiSvgIcon-root': {
            color: palette.secondary.main,
          },
        },
        '&:hover': {
          color: palette.primary.main,
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: palette.primary.main,
        },
      },
    },
  ],
}
