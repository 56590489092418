import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import palette from '../palette'
import typography from '../typography'

const tagColors = [
  'dormant',
  'emerging',
  'growing',
  'matured',
  'declining',
  'fading',
]

export default {
  defaultProps: {
    deleteIcon: <CloseRoundedIcon />,
  },
  styleOverrides: {
    root: {
      height: 24,
    },
    filled: {
      background: palette.colors.chip,
      borderRadius: 14,
      '& .MuiChip-label': {
        ...typography.subtitle3,
        color: palette.secondary.dark,
        padding: 0,
        '& span': {
          ...typography.subtitle2,
        },
      },
      '&:hover': {
        background: palette.colors.chipHover,
      },
      '&:active': {
        background: palette.colors.chipActive,
      },
      '&:focus': {
        background: palette.colors.chipActive,
      },
    },
    sizeMedium: {
      padding: '4px 8px',
    },
    outlined: {
      border: `1px solid ${palette.divider}`,
      borderRadius: 14,
      background: palette.background.paper,
      '& .MuiChip-label': {
        ...typography.subtitle2,
        padding: 0,
        '&:active': {
          color: palette.secondary.dark,
        },
      },
      '&:hover': {
        background: palette.background.default,
        border: `1px solid ${palette.grey[500]}`,
      },
      '&:active': {
        background: palette.background.paper,
        border: `1px solid ${palette.secondary.dark}`,
      },
      '& .MuiChip-deleteIcon': {
        fontSize: 'medium',
        margin: '0 -4px 0 0',
      },
    },
  },
  variants: [
    {
      props: { variant: 'keyword' },
      style: {
        background: palette.colors.chipKeyword,
        borderRadius: 4,
        '& .MuiChip-label': {
          ...typography.subtitle2,
          padding: 0,
        },
        '&:hover': {
          background: palette.colors.chipKeyword,
        },
        '&:active': {
          background: palette.colors.chipKeyword,
        },
        '&:focus': {
          background: palette.colors.chipKeyword,
        },
      },
    },
    {
      props: { variant: 'outlined', isSelected: true },
      style: {
        background: palette.background.paper,
        border: `1px solid ${palette.secondary.dark}`,
        '&:hover': {
          border: `1px solid ${palette.secondary.dark}`,
        },
        '& .MuiChip-label': {
          color: palette.secondary.dark,
        },
      },
    },
    ...tagColors.map((color) => ({
      props: { variant: 'tag', phase: color },
      style: {
        background: palette.background.paper,
        border: `1px solid ${palette.divider}`,
        '&:hover': {
          border: `1px solid ${palette.colors[color]}`,
          background: palette.background.paper,
        },
        '& .MuiChip-label': {
          ...typography.subtitle2,
          padding: '0 10px',
        },
        '& .MuiChip-icon': {
          marginLeft: 2,
          color: palette.colors[color],
        },
      },
    })),
    {
      props: { variant: 'outlinedTag' },
      style: {
        background: palette.background.paper,
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: 4,
        '&:hover': {
          border: `1px solid ${palette.secondary.dark}`,
          background: palette.colors.chip,
        },
        '&:active': {
          border: `1px solid ${palette.secondary.dark}`,
          background: palette.colors.tagActive,
          boxShadow: 'none',
        },
        '& .MuiChip-label': {
          ...typography.subtitle2,
          color: palette.secondary.dark,
          padding: '0 2px 0 0',
        },
        '& .MuiChip-deleteIcon': {
          color: palette.secondary.main,
          fontSize: 'medium',
          margin: '0 -2px 0 0',
          '&:hover': {
            color: palette.secondary.main,
          },
          '&:active': {
            color: palette.secondary.main,
          },
        },
      },
    },
    {
      props: { variant: 'contained' },
      style: {
        background: palette.primary.main,
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: 4,
        '&:hover': {
          border: `1px solid ${palette.primary.dark}`,
          background: palette.primary.dark,
        },
        '&:active': {
          border: `1px solid ${palette.secondary.dark}`,
          background: palette.colors.tagActive,
          boxShadow: 'none',
        },
        '& .MuiChip-label': {
          ...typography.subtitle2,
          color: palette.white,
          padding: '0 2px 0 0',
        },
        '& .MuiChip-deleteIcon': {
          color: palette.white,
          fontSize: 'medium',
          margin: '0 -2px 0 0',
        },
      },
    },
  ],
}
