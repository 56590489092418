import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { removeHighlight } from '../../store/actions/index'

import './HighLighter.scss'

const HighLighter = ({
  children,
  highlighterId,
  highlightInfo,
  removeHighlight,
  className = '',
}) => {
  if (!highlightInfo) return children
  const highlightRef = useRef(null)
  const highlightTimeout = useRef(null)
  const [showHighlight, setShowHighlight] = useState(true)
  const [highlighterPos, setHighligterPos] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  })

  const setPosition = () => {
    if (highlightRef.current) {
      const {
        left,
        top,
        width,
        height,
      } = highlightRef.current.nextSibling.getBoundingClientRect()
      if (showHighlight) {
        setHighligterPos({ left, top, width, height })
      }
    }
  }

  const hideHighlight = () => {
    setShowHighlight(false)
    window.removeEventListener('scroll', setPosition)
  }

  useEffect(() => {
    window.addEventListener('scroll', setPosition)
    highlightTimeout.current = setTimeout(
      () => hideHighlight(),
      highlightInfo.timeout || 5000,
    )
    setPosition()
    return () => {
      window.removeEventListener('scroll', setPosition)
      clearTimeout(highlightTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (highlightRef.current) {
      setPosition()
      highlightRef.current.nextSibling.scrollIntoView()
    }
  }, [highlighterPos.left, highlighterPos.right])

  useEffect(() => {
    if (!showHighlight) {
      removeHighlight(highlighterId)
      const { callback } = highlightInfo
      if (callback) {
        callback()
      }
    }
  }, [showHighlight])
  return (
    <>
      {showHighlight && (
        <div
          className={`highlighter${` ${className}` || ''}`}
          ref={highlightRef}
          style={highlighterPos}
        ></div>
      )}
      {children}
    </>
  )
}

const mapStateToProps = ({ application }, props) => ({
  highlightInfo: application.highlight.find(
    (hl) => hl.highlightId === props.highlighterId,
  ),
})
const mapDispatchToProps = (dispatch) => ({
  removeHighlight: (id) => dispatch(removeHighlight(id)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HighLighter)
