import { Box, styled } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  // border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  width: 433,
  '& >.MuiTypography-root': {
    marginBottom: theme.spacing(2),
  },
  '& >.MuiBox-root': {
    height: theme.spacing(65.75),
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
    '& >.MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.7),
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
}));
