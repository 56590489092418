import React, { useState } from 'react';
import {
  Stack, Typography,
  Button, TextField, CircularProgress, InputAdornment, IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import { updatePasswordOnServer } from "./../../../store/actions/UserAction"
import { useSnackbar } from 'notistack';

const ChangePasswordCard = ({user,updatePassword}) => {
  const schema = yup.object({
    currentPassword: yup.string()
      .min(6, 'must be at least 6 characters')
      .required('Required'),
    newPassword: yup.string()
    .required('Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .test(
        'test-name',
        'Password should not contain first name, user name and email values',
        function (newPassword) {
          const regexfirstname = new RegExp(`${user.firstName}`, 'gi')
          const regexmail = new RegExp(`${user.email}`, 'gi')
          const regexusername = new RegExp(`${user.username}`, 'gi')
          if (
            regexfirstname.test(newPassword) ||
            regexmail.test(newPassword) ||
            regexusername.test(newPassword)
          ) {
            return false
          } else {
            return true
          }
        }
      )
      ,
    confirmPassword: yup.string()
      .required('Required')
      .min(6, 'must be at least 6 characters')
      .oneOf([yup.ref('newPassword'), null], "Repeat password and New Password do not match."),
  }).required();
  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar()
  const snackbarShow = (content, muiVariant) => {
    enqueueSnackbar(content, { variant: muiVariant })
  }
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    currentPassword: false,
    confirmPassword: false,
  });

  const onSubmit = async data => {
      data = {
        old_password: `${data.currentPassword}`,
        new_password: `${data.newPassword}`,
        confirm_password: `${data.confirmPassword}`,
      }
   const result = await updatePassword(data);
   result.data.error_message
   ? snackbarShow(result.data.error_message, 'error')
   : snackbarShow(result.data.message, 'success')
  };

  const handleClickShowPassword = key => () => setShowPassword({
    ...showPassword,
    [key]: !showPassword[key],
  });

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography variant="h3">
          Password
        </Typography>
        <Controller
          control={control}
          name="currentPassword"
          render={({
            field: {
              onChange, value, name, ref,
            },
            fieldState: { error },
          }) => (
            <TextField
              inputRef={ref}
              name={name}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ''}
              type={showPassword.currentPassword ? 'text' : 'password'}
              variant="outlined"
              size="large"
              label="Current Password"
              placeholder="Current Password"
              sx={{ width: 400 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('currentPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.currentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({
            field: {
              onChange, value, name, ref,
            },
            fieldState: { error },
          }) => (
            <TextField
              inputRef={ref}
              name={name}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ''}
              type={showPassword.newPassword ? 'text' : 'password'}
              variant="outlined"
              size="large"
              label="New Password"
              placeholder="New Password"
              sx={{ width: 400 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('newPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {' '}
        <Controller
          control={control}
          name="confirmPassword"
          render={({
            field: {
              onChange, value, name, ref,
            },
            fieldState: { error },
          }) => (
            <TextField
              inputRef={ref}
              name={name}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error? error.message : ''}
              type={showPassword.confirmPassword ? 'text' : 'password'}
              variant="outlined"
              size="large"
              label="Repeat Password"
              placeholder="Repeat Password"
              sx={{ width: 400 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('confirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box sx={{ width: 'fit-content' }}>
          <Button
            variant="contained"
            size="large"
            disabled={isSubmitting}
            type="submit"
            fullWidth
          >
            {!isSubmitting ? 'Save Password' : <CircularProgress size={16} />}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (values) => dispatch(updatePasswordOnServer(values)),
  }
}

export default (connect(null, mapDispatchToProps)(ChangePasswordCard))

