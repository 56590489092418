import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

const CircularProgressCustom = ({ size }) => (
  <Box sx={{ position: 'relative' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: theme => theme.palette.colors.chipHover,
      }}
      size={size}
      thickness={4}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: theme => theme.palette.secondary.main,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={size}
      thickness={4}
    />
  </Box>
);

CircularProgressCustom.defaultProps = {
  size: 58,
};

CircularProgressCustom.propTypes = {
  size: PropTypes.number,
};

export default CircularProgressCustom;
