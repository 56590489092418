import React from 'react'
import PropTypes from 'prop-types'
import ChartTrendOverMui from '../../../../../ChartTrendOverMui/ChartTrendOverMui'
import Moment from 'moment'
import saveSvgAsPng from 'save-svg-as-png'
import DateRangePickerChartMui from '../../../../../DateRangePickerChart/DateRangePickerChartMui'
import { dateRange } from '../../../../../../const'
import * as actions from '../../../../../../store/actions'
import {
  amplify,
  formatNumber,
  cagrcalculation,
} from '../../../../../../store/utility'
import { connect } from 'react-redux'
import './style.scss'
import { Box } from '@mui/system'
import {
  FormControl,
  Divider,
  MenuItem,
  Select,
  Typography,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import GrowthChip from '../../../../../../shared/components/GrowthTag'
import InformationIcon from '../../../../../../shared/components/InformationIcon'
import DownloadButton from '../../../../../../shared/components/DownloadButton'
import { AmplitudeValues } from '../../../../../../store/amplitudeValues'
import OverlayLoader from '../../../../../../shared/components/OverlayLoader'

import { ReactComponent as LineChartIcon } from '../../../../../../assets/icons/line-chart.svg'

import { TrendChart } from '../../../../../../services/networking/Models/ProductDetailResponse'
import NetworkUtil from '../../../../../../services/networking/NetworkingUtil'
import axios from '../../../../../../services/networking/axios'
import TextOverflowTooltip from '../../../../../../shared/components/TextOverflowTooltip'

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
    }
    switch (ampiEvent) {
      case 'Select_Start_Date_Trend_Graph':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiInstance._trendName,
          Lens: props.lens,
          Select_Date_Start_Enagagement_Date: Moment(
            ampiUserData.state.startDate,
          ).format('D/M/Y'),
        }
        break
      case 'Select_End_Date_Trend_Graph':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiInstance._trendName,
          Lens: props.lens,
          Select_Date_End_Enagagement_Date: Moment(
            ampiUserData.state.endDate,
          ).format('D/M/Y'),
        }
        break
      case 'Clicked_Data_Source_Trend_Graph':
        amplitudeUserData = {
          ...amplitudeUserData,
          Graph_Data_Source: ampiUserData.graphDataSource,
        }
        break
      case 'Clicked_Sub_Category_Dropdown':
        amplitudeUserData = {
          ...amplitudeUserData,
          Sub_Category: ampiUserData.subCategory,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in MonthlyTrendMui')
  }
}

class MonthlyTrendsMui extends React.Component {
  constructor(props) {
    super(props)
    const { showSubCategoryData, subCatClassification } = props
    const subCategoriesList = []
    // Initialize sub-category for sub-category dropdown
    if (showSubCategoryData) {
      for (const [, value] of Object.entries(subCatClassification)) {
        value.split(';').forEach((subCat) => {
          subCategoriesList.push({
            id: subCat.trim(),
            name: subCat.trim(),
            socialData: null,
            fourYearCagr: null,
            twoYearCagr: null,
            fourYearCoeff: null,
            fourYearIntercept: null,
            dataPoints: null,
          })
        })
      }
      subCategoriesList.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      )
      subCategoriesList.splice(0, 0, { id: 'All', name: 'All' })
    }
    this.state = {
      intervalMonths: true,
      focusedInput: null,
      datePicker: [],
      startDate: Moment(),
      endDate: Moment(),
      isDownloading: false,
      dateIsSelected: false,
      endDateSelected: false,
      salesSelected: false,
      selectedChart: 'Market Trend',
      showTooltip: false,
      manualClick: false,
      selectedSubCategory: 'All',
      subCategoryData: subCategoriesList,
      loading: false,
      loadedSubCategory: 'All',
    }
  }

  componentDidMount() {
    this.setState({
      datePicker: [
        dateRange.default4yearsBack.start.toDate(),
        dateRange.default.end.toDate(),
      ],
      startDate: dateRange.default4yearsBack.start,
      endDate: dateRange.default.end,
    })

    if (this.props.addDownloadIconForChart) this.props.addDownloadIconForChart()
  }

  /**
   * Choose between year and month. Displays on charts throughout the year or month
   * @param intervalMonths {boolean}
   * @returns {Function}
   */
  setIntervalMonths = (intervalMonths) => () => {
    this.setState({ intervalMonths })
  }

  /**
   * Handling Form Field Changes
   * @param ev {string} - Name field
   * @param date {Moment}
   */
  handleChangeDate = (ev, date) => {
    if (date.isValid()) {
      const endDateSelected = ev === 'endDate'
      this.setState({
        [ev]: date,
        dateIsSelected: true,
        endDateSelected: endDateSelected,
        manualClick: true,
      })
    }
  }

  /**
   * Open the menu for choosing a graphic format for downloading
   */
  handleShowPopupDownloadChart = () => {
    const menu = document.querySelector('.apexcharts-menu-icon[title="Menu"]')
    if (menu) {
      menu.click()
    }
  }

  handleShowTooltip = (show) => {
    this.setState({ showTooltip: show })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSubCategory } = this.state
    if (this.props.addDownloadIconForChart) this.props.addDownloadIconForChart()
    if (this.state.manualClick) {
      if (prevState.startDate !== this.state.startDate) {
        const ampiEvent = 'Select_Start_Date_Trend_Graph'
        const ampiUserData = {
          state: this.state,
        }
        amp(this.props, ampiEvent, ampiUserData)
      }
      if (prevState.endDate !== this.state.endDate) {
        const ampiEvent = 'Select_End_Date_Trend_Graph'
        const ampiUserData = {
          state: this.state,
        }
        amp(this.props, ampiEvent, ampiUserData)
      }
    }
    if (prevState.selectedSubCategory !== selectedSubCategory) {
      if (selectedSubCategory !== 'All') {
        this.fetchSocialData()
      } else {
        this.setState({
          selectedChart: 'Market Trend',
          loadedSubCategory: 'All',
        })
      }
    }
  }

  fetchSocialData = () => {
    const { subCategoryData, selectedSubCategory } = this.state
    const socialDataMissing = subCategoryData.some(
      ({ name, socialData }) => name === selectedSubCategory && !socialData,
    )
    if (socialDataMissing) {
      this.setState({ loading: true })
      const request = NetworkUtil.formSearchRequestForProductForCommonTrend(
        this.props.trendId,
        false,
        true,
        selectedSubCategory,
        true,
        true,
        true,
        true,
      )
      axios
        .get(request)
        .then(({ data }) => {
          const {
            social_chart: socialData,
            four_year_cagr: fourYearCagr,
            trend_four_year_coefficient: fourYearCoeff,
            trend_four_year_intercept: fourYearIntercept,
            two_year_cagr: twoYearCagr,
            data_point: dataPoints,
          } = data
          const updateSubCategoriesList = subCategoryData.map(
            ({ name, ...rest }) => {
              if (name === selectedSubCategory) {
                return {
                  name,
                  ...rest,
                  socialData: socialData.map(
                    (socialDatum) => new TrendChart(socialDatum),
                  ),
                  fourYearCagr,
                  fourYearCoeff,
                  fourYearIntercept,
                  twoYearCagr,
                  dataPoints,
                }
              }
              return { name, ...rest }
            },
          )
          this.setState({
            subCategoryData: updateSubCategoriesList,
            loading: false,
            selectedChart: 'Sub-category Social Data',
            loadedSubCategory: selectedSubCategory,
          })
        })
        .catch(() => {
          this.setState({
            loading: false,
            loadedSubCategory: selectedSubCategory,
          })
          console.error('Error while fetching social data for sub-categories!')
        })
    } else {
      this.setState({
        selectedChart: 'Sub-category Social Data',
        loadedSubCategory: selectedSubCategory,
      })
    }
  }

  handleTrendSourceChangeSelect = (e) => {
    if (e) {
      const v = e.target.value
      const newStateOptions = { selectedChart: v }
      if (v === 'My Sales Data') {
        newStateOptions.salesSelected = true
      } else {
        newStateOptions.salesSelected = false
      }
      this.setState(newStateOptions)
    }
  }

  handleSubCategorySelect = (e) => {
    if (e) {
      this.setState({ selectedSubCategory: e.target.value })
    }
  }

  dataSourceClickHandler = (name) => {
    const ampiEvent = 'Clicked_Data_Source_Trend_Graph'
    const ampiUserData = {
      graphDataSource: name,
    }
    amp(this.props, ampiEvent, ampiUserData)
  }

  logSubCategorySelect = (subCategory) => {
    const ampiEvent = 'Clicked_Sub_Category_Dropdown'
    const ampiUserData = {
      subCategory,
    }
    amp(this.props, ampiEvent, ampiUserData)
  }

  toggleDownload = (addList = false) => () => {
    const chartEl = document.querySelector('#download-chart svg')

    const { addedSectionChart, nameSectionChart } = this.props

    if (addedSectionChart) {
      this.props.sectionRemove(nameSectionChart)
    } else {
      this.setState({ isDownloading: true }, () => {
        saveSvgAsPng
          .svgAsPngUri(chartEl, { backgroundColor: '#fff' })
          .then((blob) => {
            this.props.sectionAdd({
              name: nameSectionChart,
              data: blob,
            })
            this.setState({ isDownloading: false })
          })
          .catch((er) => {
            console.log(er)
            this.setState({ isDownloading: false })
          })
      })
    }
  }

  saveSection = () => {
    const chartEl = document.querySelector('#download-chart svg')
    this.setState({ isDownloading: false }, () => {
      saveSvgAsPng
        .svgAsPngUri(chartEl, { backgroundColor: 'white' })
        .then((url) => {
          window.saveAs(url, this.props.nameSectionChart)
          this.setState({ isDownloading: false })
        })
        .catch(() => {
          this.setState({ isDownloading: false })
        })
    })
  }

  arrIsNonZero(chartArr) {
    let noAllZero = false
    for (const entry of chartArr) {
      if (entry.engagementScore > 0.0) {
        noAllZero = true
        break
      }
    }
    return noAllZero
  }

  removeSucceedingZeroeEngagementEntries(trendsArr) {
    for (let i = trendsArr.length - 1; i >= 0; i--) {
      if (trendsArr[i].engagementScore > 0) {
        break
      } else {
        trendsArr.splice(-1, 1)
      }
    }
    return trendsArr
  }

  getNormalizedData = (value, maxScore) => {
    let newValue
    if (maxScore) {
      newValue = this.props.shouldModifyData
        ? parseInt((value / maxScore) * 100) ||
          ((value / maxScore) * 100).toFixed(2)
        : ((value / maxScore) * 100).toFixed(2)
    } else {
      newValue = value
    }
    return newValue
  }

  predictionDataExistsCheck = (trendData, maxScore) => {
    let lowTrendEngmnt = false
    let inadequateTrendEngmnt = false

    // Check against entire trend_chart data without removing the end zero scores
    const trendArr = trendData.map(({ engagementScore, period }) => ({
      timestamp: new Date(period),
      score: this.getNormalizedData(engagementScore, maxScore),
    }))
    const trendArrLen = trendArr.length

    // >=75% of values <=2.0 check
    const lowTrendEngmntCheckArr = trendArr.filter(
      ({ score }) => Number(score) <= 2,
    )
    if (lowTrendEngmntCheckArr.length / trendArrLen >= 0.75) {
      lowTrendEngmnt = true
    }

    // >=80% of values >=100.0 check
    const inadequateTrendEngmntArr = trendArr.filter(
      ({ score }) => Number(score) >= 100,
    )
    if (inadequateTrendEngmntArr.length / trendArrLen >= 0.8) {
      inadequateTrendEngmnt = true
    }

    return {
      lowTrendEngmnt,
      inadequateTrendEngmnt,
    }
  }

  render() {
    const {
      largeView,
      showResponseCagr,
      trendCoefficient,
      predictedCoefficient,
      predictedIntercept,
      trendIntercept,
      predictiveData = [],
      salesData = [],
      salesFeature = false,
      splitFeature = false,
      fourYearCagr,
      handleDownloadExcel,
      toggleImageDownload,
      togglePptDownload,
      trendCoefficientIntercept,
      small,
      showGrowthChip,
      showTwoYearCagr,
      nameOfTrend,
      showSubCategoryData,
      twoYearCagr,
      dataPoint,
      isTrendInfoPage,
      showDataPoints,
      graphSubText,
    } = this.props
    // eslint-disable-next-line
    let { predictive_feature = false } = this.props
    let yLabel = 'Engagement score'
    const props = this.props
    const {
      intervalMonths,
      startDate,
      endDate,
      salesSelected,
      selectedChart = 'Market Trend',
      loadedSubCategory,
      subCategoryData,
      loading,
    } = this.state
    let confidence = null
    const dataObj = [
      {
        name: 'Historical Growth',
        data: [],
        type: 'line',
      },
    ]
    // Initializing co-efficient and  intercept for 4 year and 2 year cagr
    let fourYearCoeff = trendCoefficient
    let fourYearIntercept = trendIntercept
    let trendFourYearCagr = fourYearCagr
    let trendTwoYearCagr = twoYearCagr
    let trendDataPoints = dataPoint
    let twoYearCoeff = 0
    let twoYearIntercept = 0
    var selectedChartValue

    // If sub-category data is selected, use CAGR values from sub-category API . This is only for (ingredient page)
    if (loadedSubCategory !== 'All') {
      const {
        twoYearCagr,
        fourYearCagr,
        fourYearCoeff: coeff,
        fourYearIntercept: intercept,
        dataPoints,
      } = subCategoryData.find(({ name }) => name === loadedSubCategory)
      fourYearCoeff = coeff || 0
      fourYearIntercept = intercept || 0
      trendFourYearCagr = fourYearCagr || 0
      trendTwoYearCagr = twoYearCagr || 0
      trendDataPoints = formatNumber(dataPoints) || 0
      selectedChartValue = 'social_data'
    }

    if (isTrendInfoPage || showDataPoints) {
      trendDataPoints = formatNumber(trendDataPoints) || 0
    }

    let trendsArr = []
    let predictiveArr = []
    let errorMargin = false

    let lowTrendEngmnt = false
    let inadequateTrendEngmnt = false

    if (salesSelected) {
      trendsArr = salesData
      predictiveArr = []
    } else {
      switch (selectedChart) {
        case 'Web Search Data':
          trendsArr = props.webSearchData.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        case 'Social Data':
          trendsArr = props.socialData.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        case 'Trade Media Data':
          trendsArr = props.trademediaData.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        case 'Patent Data':
          trendsArr = props.patentData.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        case 'Retail Data':
          trendsArr = props.retailCountsData.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          yLabel = 'Retail count'
          break
        case 'YouTube Search Data':
          trendsArr = props.youtubeDataChart.slice()
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        case 'Sub-category Social Data':
          trendsArr =
            subCategoryData.find(({ name }) => name === loadedSubCategory)
              .socialData || []
          predictiveArr = []
          // eslint-disable-next-line
          predictive_feature = false
          break
        default:
          trendsArr = props.trendData.slice()
          predictiveArr = (predictiveData || []).slice()
      }
    }

    trendsArr = this.removeSucceedingZeroeEngagementEntries(trendsArr)

    if (predictiveArr.length > 0) {
      // If we have predictive data. Let's pass to chart instance for rendering
      dataObj.push({
        name: 'Predicted Growth',
        data: [],
        type: 'line',
      })
    }

    if (!intervalMonths) {
      let dataByYear = {}
      trendsArr.forEach((item) => {
        const year = Moment(item.period).year()
        if (!dataByYear[year]) {
          dataByYear[year] = []
        }
        dataByYear[year].push(item.engagementScore)
      })
      Object.keys(dataByYear).forEach((year) => {
        const sum = dataByYear[year].reduce((a, b) => a + b, 0)
        dataByYear[year] = sum / dataByYear[year].length
      })
      trendsArr = Object.keys(dataByYear).map((year) => ({
        period: Moment(new Date())
          .month(0)
          .date(1)
          .year(year)
          .format('YYYY-MM-DD'),
        engagementScore: parseFloat(dataByYear[year]).toFixed(2),
      }))

      dataByYear = {}
      predictiveArr.forEach((item) => {
        const year = Moment(item.period).year()
        if (!dataByYear[year]) {
          dataByYear[year] = []
        }
        dataByYear[year].push(item.engagementScore)
      })
      Object.keys(dataByYear).forEach((year) => {
        const sum = dataByYear[year].reduce((a, b) => a + b, 0)
        dataByYear[year] = sum / dataByYear[year].length
      })
      predictiveArr = Object.keys(dataByYear).map((year) => ({
        period: Moment(new Date())
          .month(0)
          .date(1)
          .year(year)
          .format('YYYY-MM-DD'),
        engagementScore: parseFloat(dataByYear[year]).toFixed(2),
      }))
    }

    const trendMaxScore = trendsArr.reduce(
      (max, arr) => (max < arr.engagementScore ? arr.engagementScore : max),
      -1,
    )
    const predictiveMaxScore = predictiveArr.reduce((max, arr) => {
      if (arr.engagementErrorMargin) {
        const { top } = arr.engagementErrorMargin
        return max < top ? top : max
      }
      return max < arr.engagementScore ? arr.engagementScore : max
    }, -1)
    const maxScore = Math.max(trendMaxScore, predictiveMaxScore)
    let graphSelectedEndDate = new Date('2014/01/01')
    let key

    for (const element in trendsArr) {
      const value = trendsArr[element].engagementScore
      // If engagement score is non-0, then only pick the timestamp
      key = trendsArr[element].period
      const newValue = this.getNormalizedData(value, maxScore)
      const row = [new Date(key).getTime(), newValue]
      dataObj[0].data.push(row)
    }

    // Prediction condition check
    if (selectedChart === 'Market Trend') {
      const predictionCheckResult = this.predictionDataExistsCheck(
        this.props.trendData,
        maxScore,
      )
      lowTrendEngmnt = predictionCheckResult.lowTrendEngmnt
      inadequateTrendEngmnt = predictionCheckResult.inadequateTrendEngmnt
    }

    if (key) {
      graphSelectedEndDate = new Date(key)
      key = null
    }
    /* lastDay */
    var y = graphSelectedEndDate.getFullYear()
    var m = graphSelectedEndDate.getMonth()
    var lastDay = new Date(y, m + 1, 0)
    graphSelectedEndDate = lastDay
    var d = endDate.format('YYYY-MM-DD')

    if (
      Moment(startDate).format('DD-MM-YYYY') ===
      Moment(dateRange.default4yearsBack.start).format('DD-MM-YYYY')
    ) {
      this.setState({
        startDate: Moment(graphSelectedEndDate).subtract(4, 'years'),
      })
    }

    var monthlyEngagementSum = 0
    var result = false // In selected duration
    let nonZerocount = 0
    const dataFiller = []
    let cagr = cagrcalculation(fourYearCoeff, fourYearIntercept, 48)
    const yMin = -1
    const yMax = 0
    let xMax = 0
    let xMin = 0

    const dataSources = [
      'Social Data',
      'Web Search Data',
      'YouTube Search Data',
    ]
    // Calculate 4 year CAGR
    if (loadedSubCategory === 'All' && selectedChart !== 'Market Trend') {
      // If sub-category matches data sources then show CAGR else hide it
      if (dataSources.includes(selectedChart)) {
        if (selectedChart === dataSources[0]) {
          selectedChartValue = 'social_data'
        }
        if (selectedChart === dataSources[1]) {
          selectedChartValue = 'web_search_data'
        }
        if (selectedChart === dataSources[2]) {
          selectedChartValue = 'youtube_search_data'
        }
        if (dataObj && dataObj[0] && dataObj[0].data.length) {
          const latestHistoricalPoint =
            dataObj[0].data[dataObj[0].data.length - 1]
          const prevFourYearHistoricalPoint = dataObj[0].data.slice(-49)
          xMax = latestHistoricalPoint[0]
          xMin = prevFourYearHistoricalPoint[0][0]
          if (trendCoefficientIntercept !== undefined) {
            fourYearCoeff =
              trendCoefficientIntercept[selectedChartValue]
                ?.four_year_coefficient
            fourYearIntercept =
              trendCoefficientIntercept[selectedChartValue]?.four_year_intercept
            twoYearCoeff =
              trendCoefficientIntercept[selectedChartValue]
                ?.two_year_coefficient
            twoYearIntercept =
              trendCoefficientIntercept[selectedChartValue]?.two_year_intercept
            trendTwoYearCagr = cagrcalculation(
              twoYearCoeff,
              twoYearIntercept,
              24,
            )
            cagr = cagrcalculation(fourYearCoeff, fourYearIntercept, 48)
            trendFourYearCagr = cagr
          }
        }
      } else {
        trendFourYearCagr = 0
        fourYearCoeff = 0
        fourYearIntercept = 0
        trendTwoYearCagr = 0
        twoYearCoeff = 0
        twoYearIntercept = 0
      }
    }
    dataObj[0].data = dataObj[0].data.filter((point) => {
      const date = new Date(point[0])
      // An extra checks for end date selection based data filtering
      if (this.state.dateIsSelected) {
        if (this.state.endDateSelected) {
          if (!(endDate.endOf('month').format('YYYY-MM-DD') === d)) {
            predictiveArr = []
            result =
              Moment(date).isBefore(endDate) &&
              Moment(date).isAfter(startDate) &&
              Moment(date).isBefore(endDate.startOf('month'))
            if (result) {
              monthlyEngagementSum += Number(point[1])
              nonZerocount += 1
            }
            return result
          }
        }
        result =
          Moment(date).isBefore(endDate) && Moment(date).isAfter(startDate)
        if (result) {
          monthlyEngagementSum += Number(point[1])
          nonZerocount += 1
        }
        return result
      }
      result =
        Moment(date).isBefore(Moment(endDate)) &&
        Moment(date).isAfter(startDate)
      let result2
      if (!result && Moment(startDate).isBefore(dateRange.default4yearsBack)) {
        result2 =
          Moment(date).isBefore(Moment(endDate)) &&
          Moment(date).isAfter(dateRange.calendarDefault.start)
        if (!isNaN(point[0])) {
          dataFiller.push(point)
        }
      }
      if (result || (Number(point[1]) && result2)) {
        // Added result
        monthlyEngagementSum += Number(point[1])
        nonZerocount += 1
      }
      return result
    })

    if (nonZerocount === 1) {
      dataObj[0].data = dataFiller
    }

    if (predictiveArr.length > 0 && dataObj[0].data.length > 0) {
      dataObj[1].data.push(dataObj[0].data.slice(-1).pop())
      confidence = predictiveArr[0].confidence || 'LOW'
    }

    let allZeroPredictorVal = 0.0
    const topErrorMarginArr = []
    const bottomErrorMarginArr = []
    for (const element in predictiveArr) {
      const key = predictiveArr[element].period
      const value = predictiveArr[element].engagementScore

      // If prediction error margin exists
      if (predictiveArr[element].engagementErrorMargin) {
        errorMargin = true
        let { top, bottom } = predictiveArr[element].engagementErrorMargin
        if (maxScore) {
          top = props.shouldModifyData ? parseInt((top / maxScore) * 100) : top
          bottom = props.shouldModifyData
            ? parseInt((bottom / maxScore) * 100)
            : bottom
        }
        topErrorMarginArr.push([new Date(key).getTime(), top])
        bottomErrorMarginArr.push([new Date(key).getTime(), bottom])
      }

      let newValue
      if (maxScore) {
        newValue = props.shouldModifyData
          ? parseInt((value / maxScore) * 100)
          : value
      } else {
        newValue = value
      }

      allZeroPredictorVal = newValue || allZeroPredictorVal
      const row = [new Date(key).getTime(), newValue]
      dataObj[1].data.push(row)
    }
    if (topErrorMarginArr.length) {
      const firstPredElem = dataObj[1].data[0]
      dataObj.push({
        name: 'Top error margin',
        type: 'line',
        data: [firstPredElem, ...topErrorMarginArr],
      })
      dataObj.push({
        name: 'Bottom error margin',
        type: 'line',
        data: [firstPredElem, ...bottomErrorMarginArr],
      })
    }

    if (!allZeroPredictorVal && predictiveArr.length > 0) {
      // !0.0 -> true
      dataObj[1].data = [] // "Not enough data to predict" (explicitly setting predictive Data [], not conditional)
    }

    let hideDatepicker = false
    if (trendsArr.length === 0) {
      if (selectedChart === 'Market Trend') {
        return null
      } else {
        // If sales data is sales selected then hide date picker
        hideDatepicker = true
      }
    }

    confidence = confidence
      ? confidence[0].toUpperCase() + confidence.slice(1).toLowerCase()
      : ''
    let dropdownOptions = []
    let newDropdownsEntryAdded = false
    if (this.arrIsNonZero(props.trendData.slice())) {
      dropdownOptions.push({
        id: 1,
        name: 'Market Trend',
        displayValue: 'Market Trend',
        value: 'Market Trend',
      })
    } else {
      console.log('[Market Trend] All trend data entries are 0 or arr is empty')
      return null
    }

    if (splitFeature) {
      let splitDropdownOptions = [
        {
          id: 2,
          name: 'Social Data',
          displayValue: 'Social Data',
          value: 'Social Data',
        },
        {
          id: 3,
          name: 'Web Search Data',
          displayValue: 'Web Search Data',
          value: 'Web Search Data',
        },
        {
          id: 4,
          name: 'Trade Media Data',
          displayValue: 'Trade Media Data',
          value: 'Trade Media Data',
        },
        {
          id: 5,
          name: 'Patent Data',
          displayValue: 'Patent Data',
          value: 'Patent Data',
        },
      ]

      const dataArr = [
        this.props.socialData.slice(),
        this.props.webSearchData.slice(),
        this.props.trademediaData.slice(),
        this.props.patentData.slice(),
      ]

      // As MicroService
      if (
        this.props.retailCountsData &&
        this.props.retailCountsData.slice().length !== 0
      ) {
        splitDropdownOptions.push({
          id: 7,
          name: 'Retail Data',
          displayValue: 'Retail Data',
          value: 'Retail Data',
        })
        dataArr.push(this.props.retailCountsData.slice())
      }

      if (props.youtubeDataChart && props.youtubeDataChart.length) {
        splitDropdownOptions.push({
          id: 9,
          name: 'YouTube Search Data',
          displayValue: 'YouTube Search Data',
          value: 'YouTube Search Data',
        })
        dataArr.push(props.youtubeDataChart.slice())
      }

      splitDropdownOptions = splitDropdownOptions.filter((data, i) => {
        const allNonZero = this.arrIsNonZero(dataArr[i])
        if (allNonZero) {
          newDropdownsEntryAdded = true
        } else {
          console.log(
            '[Split feature]',
            data.name,
            ' All trend data entries are 0 or arr is empty',
          )
        }
        return allNonZero
      })
      dropdownOptions = dropdownOptions.concat(splitDropdownOptions)
    }

    if (salesFeature) {
      if (this.arrIsNonZero(salesData.slice())) {
        newDropdownsEntryAdded = true
        dropdownOptions.push({
          id: 7,
          name: 'My Sales Data',
          displayValue: 'My Sales Data',
          value: 'My Sales Data',
        })
      } else {
        console.log(
          '[Split feature] Sales, All trend data entries are 0 or arr is empty',
        )
      }
    }

    const finalPredictionDataIndex = dataObj.findIndex(
      (d) => d.name === 'Predicted Growth',
    )
    if (
      finalPredictionDataIndex !== -1 &&
      dataObj[finalPredictionDataIndex].data.length === 0
    ) {
      dataObj.splice(finalPredictionDataIndex, 1)
    }

    return (
      <div
        className={`monthly_trends${largeView ? '' : ' monthly_trends--sm'}`}
      >
        {loading && <OverlayLoader />}
        <div className="monthly_trends__body">
          {
            <div className="monthly_trends__header">
              <div
                className="monthly_trends__header_left"
                style={{ flex: isTrendInfoPage && 1 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    p: 2,
                    pr: !isTrendInfoPage ? 0 : 2,
                    width: isTrendInfoPage && '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      width: isTrendInfoPage && '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {!isTrendInfoPage ? (
                        <Typography variant={small ? 'h4' : 'h3'}>
                          Trend Growth
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            flex: 1,
                          }}
                        >
                          <TextOverflowTooltip
                            title={nameOfTrend}
                            maxLineCount={1}
                          >
                            <Typography variant={small ? 'h4' : 'h3'}>
                              {nameOfTrend}
                            </Typography>
                          </TextOverflowTooltip>
                        </Box>
                      )}
                      {((!this.props.needChart && !this.props.topicChart) ||
                        showGrowthChip) &&
                        (showTwoYearCagr ? (
                          <Box
                            sx={{
                              bgcolor: 'grey.50',
                              borderRadius: '4px',
                              display: 'flex',
                              pr: 1,
                            }}
                          >
                            <GrowthChip
                              title="2 Year CAGR"
                              growthValue={trendTwoYearCagr}
                            />
                            {((showSubCategoryData &&
                              subCategoryData.length > 0) ||
                              isTrendInfoPage ||
                              showDataPoints) && (
                              <Typography
                                component="span"
                                variant="subtitle2"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <SvgIcon component={LineChartIcon} />
                                {trendDataPoints} Data Points
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <GrowthChip
                            title="2 Year CAGR"
                            growthValue={this.props.twoYearCagr}
                          />
                        ))}
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Growth of the trend over a period of time
                    </Typography>
                  </Box>
                </Box>
              </div>
              <div
                className="monthly_trends__header_right"
                style={{ alignSelf: 'flex-start', paddingTop: 16 }}
              >
                <div
                  className="monthly_trends__tools"
                  style={{ display: 'flex' }}
                >
                  {showSubCategoryData && subCategoryData.length > 0 && (
                    <>
                      <Box
                        display="flex"
                        alignSelf="flex-start"
                        alignItems="center"
                        mr={1}
                      >
                        <Typography component="span" variant="subtitle2">
                          Sub-Category:
                        </Typography>
                        <FormControl
                          sx={{ width: 80, marginLeft: 1 }}
                          variant="outlined"
                          size="large"
                        >
                          <Select
                            defaultValue="All"
                            onChange={this.handleSubCategorySelect}
                          >
                            {subCategoryData.map(({ id, name }) => (
                              <MenuItem
                                key={id}
                                value={name}
                                onClick={() => this.logSubCategorySelect(name)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  )}
                  {hideDatepicker ? (
                    <></>
                  ) : (
                    <DateRangePickerChartMui
                      className="product_rus"
                      onChange={this.handleChangeDate}
                      graphSelectedEndDate={graphSelectedEndDate}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  )}
                  {loadedSubCategory !== 'All' ? (
                    <Tooltip
                      placement="top"
                      title="Sub-category level data is only available for social data"
                    >
                      <Box display="flex" alignItems="center" ml={1}>
                        <Typography component="span" variant="subtitle2">
                          Data Source:
                        </Typography>
                        <FormControl
                          variant="outlined"
                          size="large"
                          sx={{ width: 80, marginLeft: 1 }}
                        >
                          <Select
                            value="Social Data"
                            defaultValue={'Social Data'}
                          >
                            <MenuItem key={'Social Data'} value={'Social Data'}>
                              Social Data
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Tooltip>
                  ) : (
                    (((salesFeature || splitFeature) &&
                      newDropdownsEntryAdded) ||
                      isTrendInfoPage) && (
                      <Box display="flex" alignItems="center" ml={1}>
                        <Typography variant="subtitle2">
                          Data Source:
                        </Typography>
                        {isTrendInfoPage ? (
                          <Typography
                            component="span"
                            variant="subtitle2"
                            mr={1.5}
                          >
                            &nbsp;Social Data
                          </Typography>
                        ) : (
                          <FormControl
                            variant="outlined"
                            size="large"
                            sx={{ width: 80, marginLeft: 1 }}
                          >
                            <Select
                              defaultValue={'Market Trend'}
                              onChange={this.handleTrendSourceChangeSelect}
                            >
                              {dropdownOptions.map(({ id, name }) => (
                                <MenuItem
                                  key={id}
                                  value={name}
                                  onClick={() =>
                                    this.dataSourceClickHandler(name)
                                  }
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    )
                  )}

                  {(handleDownloadExcel ||
                    toggleImageDownload ||
                    togglePptDownload) && (
                    <Box ml={1}>
                      <DownloadButton
                        title="_Trend_Level"
                        lensSelected={this.props.lens}
                        handleDownloadExcel={handleDownloadExcel}
                        toggleImageDownload={toggleImageDownload}
                        togglePptDownload={togglePptDownload}
                        dowImageType="Chart"
                      />
                    </Box>
                  )}
                </div>
                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Typography component="span" variant="subtitle2">
                    {graphSubText}
                  </Typography>
                </Box>
              </div>
            </div>
          }
          <Divider />
          <ChartTrendOverMui
            data={dataObj}
            saveFilteredTrendData={this.props.saveFilteredTrendData}
            intervalMonths={intervalMonths}
            yLabel={yLabel}
            trendCoefficient={fourYearCoeff}
            trendIntercept={fourYearIntercept}
            xMax={xMax}
            xMin={xMin}
            selectedChart={selectedChart}
            selectedChartValue={selectedChartValue}
            cagr={showResponseCagr ? trendFourYearCagr : cagr}
            yMin={yMin}
            yMax={yMax}
            predictedCoefficient={predictedCoefficient}
            predictedIntercept={predictedIntercept}
            largeView={largeView}
            hasPadding={isTrendInfoPage}
            errorMargin={errorMargin}
            lens={props.lens}
          />
        </div>
        {!this.props.noConf &&
        confidence &&
        dataObj[0] &&
        dataObj[0].data.length &&
        nonZerocount > 0 &&
        nonZerocount != 1 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.7,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              display="inline"
            >
              Confidence Level of Prediction:
            </Typography>
            <Typography variant="subtitle2" display="inline">
              {' '}
              {confidence}{' '}
            </Typography>
            <InformationIcon tooltipText="Likely hood of this trend to rise or decline in the coming months." />
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end">
            {!this.props.noConf && confidence ? (
              <span
                style={{
                  marginRight: '0px',
                  position: 'relative',
                  bottom: '10px',
                }}
              >
                <Typography variant="subtitle2" display="inline">
                  {!monthlyEngagementSum
                    ? 'No sufficient data to plot in the selected duration'
                    : 'No sufficient data to plot'}
                </Typography>
              </span>
            ) : (
              <Box display="flex" alignItems="center">
                {!this.props.noConf && selectedChart === 'Market Trend' && (
                  <>
                    <Typography
                      sx={{ display: 'flex' }}
                      variant="subtitle2"
                      display="inline"
                      mr={1}
                    >
                      Prediction unavailable
                    </Typography>
                    {(inadequateTrendEngmnt || lowTrendEngmnt) && (
                      <InformationIcon
                        tooltipText={
                          lowTrendEngmnt
                            ? `The future one year growth of ${nameOfTrend} cannot be predicted due to low engagement seen from consumer conversations over a period.`
                            : `The future growth of ${nameOfTrend} cannot be predicted due to inadequate engagement seen from consumer conversations over a period.`
                        }
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </div>
    )
  }
}

MonthlyTrendsMui.propTypes = {
  canDownload: PropTypes.string,
  predictiveData: PropTypes.array,
}

const mapStateToProps = (state, props) => {
  return {
    nameSectionChart: props.canDownload,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sectionAdd: (section) => dispatch(actions.sectionAdd(section)),
    sectionRemove: (nameSection) =>
      dispatch(actions.sectionRemove(nameSection)),
    spinnerDownload: (isShow) => dispatch(actions.spinnerDownload(isShow)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyTrendsMui)
