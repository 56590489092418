import React from 'react';
import Link from "react-router-dom/Link";
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import formatNumber from '../../utils/formatNumber';
import ProductCardContainer from './styles';

const ProductCard = ({
  left, top, isPC, projectid, lensSelected='Ingredient', productid, name, rank, image, dataPoint, closeTooltip
  }) => {
    let link = null;
    const projectID = Number(projectid);
    if (projectID) {
      link = `/mui/details/${projectID}`;
    }
    link += `/${lensSelected.toLowerCase()}/${productid}`;

  return (
  <ProductCardContainer
    sx={{
      position: "absolute",
      left: `${left}px`,
      top: `${top + 25}px`,
      display: isPC ? "flex" : "none !important"
    }}
    onMouseLeave={closeTooltip}>
    <Box>
      <img src={image} alt={name} />
      <Box>
        <Typography variant="subtitle3">{name}</Typography>
        <Box>
          <Box>
            <Typography variant="caption" color="textSecondary">Rank - </Typography>
            <Typography variant="caption">
              #
              {rank}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="textSecondary">Data points - </Typography>
            <Typography variant="caption">{dataPoint}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    <Link target="_blank" to={link}>
      <Button
        variant="outlined"
        size="small"
        sx={{ width: '100%', mt: 1, mb: -1 }}>
        View Details
      </Button>
    </Link>
  </ProductCardContainer>
  )}

ProductCard.defaultProps = {
  lensSelected: "Ingredient"
}

ProductCard.propTypes = {
  isShow: PropTypes.bool.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  dataPoint: PropTypes.number.isRequired,
}

export default ProductCard
