import { Chip } from '@mui/material'
import styled from '@emotion/styled'

export const StyledChip = styled(Chip)(({ theme, addTooltip, noPointerEvent, }) => ({
  cursor: 'default',
  textDecoration: 'none',
  pointerEvents: noPointerEvent && 'none',
  backgroundColor: addTooltip &&'transparent',
  textOverflow: addTooltip && 'ellipsis',
  overflow: addTooltip && 'hidden',
  '&: hover': {
    border: `1px solid ${theme.palette.colors.chipBorder}`,
  },
  '.MuiChip-label': {
    color: `${theme.palette.text.primary} !important`,
    maxWidth: addTooltip && '150px',
  },
}));
