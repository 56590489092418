import { Box, CircularProgress, Popper, Typography } from '@mui/material'
import React from 'react'
import GenericTab from './GenericTab'
import noResultGlobalSearch from './../../../assets/images/EmptyState/noResultGlobalSearch.svg'
import NoResultFound from '../NoResultFound/NoResultFound'

const GlobalSearchPaper = (props) => {
  const {
    disablePortal,
    anchorEl,
    open,
    children,
    isFocused,
    searchedText,
    selectedTab,
    handleTabSelection,
    listOfTabs,
    entityLoader,
    options,
    autocompleteOpen,
    ...other
  } = props

  return (
    <Popper
      sx={{ zIndex: '1201' }}
      anchorEl={anchorEl}
      open={isFocused && autocompleteOpen}
    >
      <Box
        sx={{
          height: '480px',
          width: '450px',
          background: ({ palette }) => palette.white,
          boxShadow: ({ palette }) => `0px 2px 4px ${palette.shadow.primary}`,
          borderRadius: '4px',
        }}
        onMouseDown={(event) => event.preventDefault()}
      >
        {entityLoader ? (
          <>
            <Box
              sx={{
                height: '480px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ overflow: 'auto' }} id="scrollBox">
              <GenericTab
                tabList={listOfTabs}
                selectedTab={selectedTab}
                handleTabSelection={handleTabSelection}
                component={
                  options.length > 0 ? (
                    children
                  ) : (
                    <Box sx={{ height: '400px', width: '100%' }}>
                      <NoResultFound
                        imgAlt="No Result Found"
                        message={`No result found for ${searchedText}`}
                        imgSrc={noResultGlobalSearch}
                        messageSx={{ paddingTop: '10px', maxWidth: '250px' }}
                      />
                    </Box>
                  )
                }
              />
            </Box>
            <Box
              sx={{
                height: '30px',
                display: 'flex',
                padding: '5px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: ({ palette }) => palette.text.secondary,
                }}
              >
                Press Enter (⏎) to see search results for {searchedText}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Popper>
  )
}

export default GlobalSearchPaper
