import React from 'react'
import PropTypes from 'prop-types'

import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'

const HEIGHT_OF_ITEM = 32
const ITEM_PADDING_TOP = 8

const SingleSelectFilter = (props) => {
  const {
    label,
    selectedItem,
    onChange,
    type,
    items,
    showAllOption,
    maxItemsToDisplay,
  } = props
  return (
    <Box display="flex" alignItems="center" sx={{ mr: 2, gap: 0.5 }}>
      {label && (
        <Typography variant="subtitle2" color="textSecondary">
          {label}:{' '}
        </Typography>
      )}
      <FormControl
        sx={{
          minWidth: 120,
        }}
        size="large"
      >
        <Select
          defaultValue={selectedItem}
          value={selectedItem}
          onChange={(e) => onChange(e.target.value)}
          inputProps={{ 'aria-label': type }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight:
                  HEIGHT_OF_ITEM * maxItemsToDisplay + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {showAllOption && (
            <MenuItem value="All" key={`${type}-All`}>
              All
            </MenuItem>
          )}
          {items.map((value) => (
            <MenuItem value={value.name} key={`${type}-${value.name}`}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

SingleSelectFilter.defaultProps = {
  maxItemsToDisplay: 6,
}

SingleSelectFilter.propTypes = {
  label: PropTypes.string,
  selectedItem: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  items: PropTypes.array,
  showAllOption: PropTypes.bool,
  maxItemsToDisplay: PropTypes.number,
}

export default SingleSelectFilter
