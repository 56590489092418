import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Spinner from '../../components/UI/Spinner/Spinner'
import { PermissionForm } from '../index'
import classes from './permission.css'
import Dialog from 'react-bootstrap-dialog'

class PermissionList extends Component {
  componentWillMount () {
    this.props.getAllPermission()
  }

  componentWillUnmount () {
    this.props.hideForm()
  }

  editCellButton (cell, row, enumObject, rowIndex) {
    return (<button className="glyphicon glyphicon-pencil pull-left btn customBtn" type="button"
      onClick={() => {
        this.props.editPermission(row)
      }}>
    </button>
    )
  }

  deleteCellButton (cell, row, enumObject, rowIndex) {
    return (<button className="glyphicon glyphicon-trash pull-left btn customBtn" type="button"
      onClick={() => {
        this.dialog.show({
          title: 'Manage Detail',
          body: 'Are you sure to remove this detail ?',
          actions: [
            Dialog.DefaultAction('Remove', () => {
              this.props.deletePermission(row.id)
            }, 'btn-custom'),
            Dialog.DefaultAction('Close', () => {
              this.dialog.hide()
            }, 'btn-custom'),
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            this.dialog.hide()
          },
        })
      }}>
    </button>
    )
  }

  render () {
    return (
      <div className="clearfix">
        <Dialog ref={(el) => {
          this.dialog = el
        }}/>

        {this.props.showSpinner ? <div className="sppinerSection"><Spinner/></div> : null}

        {this.props.permissionList
          ? <div className="col-md-12">
            <button className={'btn ' + classes.btnCustom} onClick={() => {
              this.props.showPermissionForm()
            }}>Add Permission
            </button>
            <BootstrapTable options={this.options} data={this.props.permissions}
              striped={true}
              hoveNr={true}>
              <TableHeaderColumn isKey dataField='_id' hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='displayName'>Name</TableHeaderColumn>
              <TableHeaderColumn dataField='permission'>Permission Name</TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField='button'
                dataFormat={this.editCellButton.bind(this)}><i
                  className="fa fa-pencil"></i></TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField='button'
                dataFormat={this.deleteCellButton.bind(this)}><i
                  className="fa fa-trash"></i>
              </TableHeaderColumn>

            </BootstrapTable>
          </div> : null
        }

        {this.props.permissionForm
          ? <div className='col-md-12'>
            <button className={'btn ' + classes.btnCustom} onClick={() => {
              this.props.showPermissionList()
            }}><i className="fa fa-arrow-left"></i>
            </button>
            <PermissionForm
              onSubmit={this.props.addPermission}
            />
          </div> : null
        }

      </div>
    )
  }
}

export default PermissionList
