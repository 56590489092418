import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../UI/Spinner/Spinner'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"

const BackgroundSpinner = props => {
  const trendDetails = document.getElementsByClassName("trend__phases__details")[1]
  let style = {}
  const regex = /^\/projectList\/[0-9]+$/
  if(trendDetails){
    const {width , x} = trendDetails.getBoundingClientRect()
    style.left = parseInt(width/2 + x, 10)
    style.top = 450
  }
  if (props.isShow && !props.location.pathname.includes('/mui/') && props.location.pathname !== '/') {
    return (
      <div className='background-spinner'>
        <Spinner style={regex.test(props.location.pathname) && style}/>
      </div>
    )
  } else {
    return null
  }
}

BackgroundSpinner.propTypes = {

}

const mapStateToProps = state => {
  const {isShowSpinnerDownload, loadingRequests, showSingleLoader} = state.downloadSections
  return {
    isShow: showSingleLoader && (isShowSpinnerDownload || loadingRequests),
  }
}

export default withRouter(connect(mapStateToProps)(BackgroundSpinner))
