export const buttons = [
  {
    name: 'New Concept',
    id: 'new_concept',
    isActive: true,
  },
  {
    name: 'Saved Concepts',
    id: 'saved_concepts',
    isActive: false,
  },
]
