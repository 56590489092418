import React from 'react'
import styled from '@emotion/styled'

import { TextField, InputAdornment, Typography } from '@mui/material'

export const WIDTH = '200px'

export const StTextField = styled((props) => (
  <TextField
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: '14px !important',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {props.text ? props.text : null}
          </Typography>
        </InputAdornment>
      ),
    }}
    {...props}
  />
))(({ theme, open }) => ({
  width: WIDTH,
  '& .MuiOutlinedInput-root': {
    // padding: theme.spacing(0.5),
    ...(open && { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }),
    '& .MuiOutlinedInput-input': {
      paddingLeft: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    },
  },
}))
