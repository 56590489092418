import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import countriesList from '../../../mocks/country'

import { Box, Chip, Divider, Typography } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'

import CommonCardComponent from '../../../shared/components/CommonCardComponent'
import DashboardTrendHighlightCardsList from '../../../shared/components/DashboardTrendHighlightCardsList'
import PopoverDropdownsList from '../../../shared/components/PopoverDropdownsList'
import DashboardTooltip from '../../../shared/components/DashboardTooltip'
import { amplify } from '../../../store/utility'

const formatOpts = (list, setGenerator) =>
  Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
    label: item,
    id: item.split(' ').join('-'),
  }))

const DashboardTrendHighlight = (props) => {
  const {
    chipsToShow,
    trendHighlightData,
    lensSelected,
    countrySelected,
    categorySelected,
    subCategorySelected,
    handleTrendHighlightFiltersSave,
    projectsList,
    fetchAllTrends,
    allTrendsList,
    trendHighlightTrendsList,
    fetchTrendHighlightData,
    trendHighlightDataLoading,
    projectIdSelected,
    email,
    trendHighlightConfig,
  } = props

  const amp = (ampiEvent, ampiUserData) => {
    try {
      const amplitudeEvent = ampiEvent
      amplify(amplitudeEvent, ampiUserData, ampiUserData.User)
    } catch (err) {
      console.log(err, 'Amplitude error in My Workspace')
    }
  }

  const [lastUpdated, setLastUpdated] = useState('')
  const [allDropdownsList, setAllDropdownsList] = useState([
    {
      label: 'Country',
      value: null,
      options: [],
      loading: true,
    },
    {
      label: 'Category',
      value: null,
      options: [],
      loading: true,
    },
    {
      label: 'Lens',
      value: null,
      options: [],
      loading: true,
    },
    {
      label: 'Sub-Category',
      value: null,
      options: [],
      shouldHideCondition: {
        condition: false,
        dependency: 'Lens',
        showWhenOptions: ['Ingredient'],
      },
      disableEmptyCheck: true,
      loading: true,
    },
  ])
  const [projectId, setProjectId] = useState(null)

  const handleFiltersSave = (dropdownsList) => {
    handleTrendHighlightFiltersSave(dropdownsList)
    if (projectId) {
      fetchTrendHighlightData(
        projectId,
        dropdownsList[2].value,
        dropdownsList[3].value,
      )
    }
  }

  const handleInfoHover = () => {
    const ampiEvent = 'Clicked_MW_TrendHighlight_info'
    const ampiUserData = {
      User: email,
    }
    amp(ampiEvent, ampiUserData)
  }

  useEffect(() => {
    if (trendHighlightConfig && Object.keys(trendHighlightConfig).length) {
      const ampiEvent = 'Clicked_MW_TrendHighlight'
      const ampiUserData = {
        User: email,
        Country_Name: trendHighlightConfig?.country || '',
        Category_Name: trendHighlightConfig?.category || '',
        Lens: trendHighlightConfig?.lens || '',
        SubCategory_Name: trendHighlightConfig?.sub_category || '',
      }
      amp(ampiEvent, ampiUserData)
    }
  }, [trendHighlightConfig])

  useEffect(() => {
    if (trendHighlightTrendsList.length) {
      setLastUpdated(
        trendHighlightTrendsList[0]?.last_updated_timestamp
          ? new Date(
              trendHighlightTrendsList[0].last_updated_timestamp,
            ).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
            })
          : '',
      )
    } else {
      setLastUpdated('')
    }
  }, [trendHighlightTrendsList])

  // To remove subcategory dropdown when no options available
  // TODO Should uncomment this after
  // useEffect(() => {
  //   setAllDropdownsList((dropdownsList) =>
  //     dropdownsList.map((obj) =>
  //       obj.label === 'Sub-Category'
  //         ? {
  //             ...obj,
  //             shouldHideCondition: {
  //               ...obj.shouldHideCondition,
  //               condition:
  //                 !allDropdownsList[3].loading &&
  //                 !allDropdownsList[3].options?.length,
  //             },
  //           }
  //         : { ...obj },
  //     ),
  //   )
  // }, [allDropdownsList[3].loading, allDropdownsList[3].options])

  // To populate countries dropdown
  useEffect(() => {
    const countries = formatOpts(
      projectsList
        ? projectsList.list.filter((obj) => obj.isHistoricalDataAvailable)
        : [],
      (prj) => prj.criteria,
    ).map(({ label, id }) => {
      return {
        label,
        id,
        code: (
          countriesList.find((country) => country.label === label) || {
            code: '',
          }
        ).code,
      }
    })
    setAllDropdownsList((dropdownsList) =>
      dropdownsList.map((obj) =>
        obj.label === 'Country'
          ? {
              ...obj,
              options: countries,
              ...(projectsList?.list?.length && { loading: false }),
            }
          : {
              ...obj,
              ...(projectsList?.list?.length && { loading: false }),
            },
      ),
    )
  }, [projectsList])

  // To populate categories dropdown
  useEffect(() => {
    let prjList = projectsList
      ? projectsList.list.filter((obj) => obj.isHistoricalDataAvailable)
      : []
    const countrySelectedNew = allDropdownsList[0].value
    if (countrySelectedNew && prjList.length) {
      prjList = prjList.filter((prj) => prj.criteria === countrySelectedNew)
      const categories = formatOpts(prjList, (prj) => prj.name).sort((a, b) =>
        a.label > b.label ? 1 : -1,
      )
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Category'
            ? {
                ...obj,
                options: categories,
                value: !categories.some(
                  (category) => category.label === obj.value,
                )
                  ? null
                  : obj.value,
              }
            : { ...obj },
        ),
      )
    }
  }, [projectsList, allDropdownsList[0].value])

  // To set lens and sub category loading when API is called
  useEffect(() => {
    if (projectsList?.list?.length && projectId) {
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Lens' || obj.label === 'Sub-Category'
            ? { ...obj, loading: true }
            : { ...obj },
        ),
      )
      fetchAllTrends(projectId).then(() => {
        setAllDropdownsList((dropdownsList) =>
          dropdownsList.map((obj) =>
            obj.label === 'Lens' || obj.label === 'Sub-Category'
              ? { ...obj, loading: false }
              : { ...obj },
          ),
        )
      })
    }
  }, [projectsList, projectId])

  // To set lens dropdown list
  useEffect(() => {
    if (projectId && allTrendsList && Object.keys(allTrendsList).length) {
      const itemsToCheck = ['ingredient', 'product', 'theme']
      const lensOptions = []
      for (let i = 0; i < itemsToCheck.length; i++) {
        if (allTrendsList[itemsToCheck[i]]?.length) {
          lensOptions.push({
            label: _.startCase(itemsToCheck[i]),
            id: _.startCase(itemsToCheck[i]),
          })
        }
      }
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Lens'
            ? {
                ...obj,
                options: lensOptions,
                value: !lensOptions.some((lens) => lens.label === obj.value)
                  ? null
                  : obj.value,
              }
            : { ...obj },
        ),
      )
    }
  }, [projectId, allTrendsList])

  // To set sub category dropdown list
  useEffect(() => {
    if (
      projectId &&
      allTrendsList &&
      Object.keys(allTrendsList).length &&
      allDropdownsList[2].value === 'Ingredient'
    ) {
      const subCategoryData = allTrendsList.ingredient_sub_category.filter(
        (subCategoryObj) => subCategoryObj.value !== 'All',
      )
      const updatedData = subCategoryData
        .sort((a, b) => (a.value > b.value ? 1 : -1))
        .map((obj) => ({
          label: obj.value,
          id: obj.value.split(' ').join('-'),
        }))

      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Sub-Category'
            ? {
                ...obj,
                options: updatedData,
                value: !updatedData.some(
                  (subCategory) => subCategory.label === obj.value,
                )
                  ? null
                  : obj.value,
              }
            : { ...obj },
        ),
      )
    }
  }, [projectId, allTrendsList, allDropdownsList[2].value])

  // To set countrySelected value
  useEffect(() => {
    if (countrySelected) {
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Country'
            ? { ...obj, value: countrySelected }
            : { ...obj },
        ),
      )
    }
  }, [countrySelected])
  // To set categorySelected value
  useEffect(() => {
    if (categorySelected) {
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Category'
            ? { ...obj, value: categorySelected }
            : { ...obj },
        ),
      )
    }
  }, [categorySelected])
  // To set lensSelected value
  useEffect(() => {
    if (lensSelected) {
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Lens' ? { ...obj, value: lensSelected } : { ...obj },
        ),
      )
    }
  }, [lensSelected])
  // To set subCategorySelected value
  useEffect(() => {
    if (subCategorySelected) {
      setAllDropdownsList((dropdownsList) =>
        dropdownsList.map((obj) =>
          obj.label === 'Sub-Category'
            ? { ...obj, value: subCategorySelected }
            : { ...obj },
        ),
      )
    }
  }, [subCategorySelected])

  useEffect(() => {
    if (allDropdownsList[0].value && allDropdownsList[1].value) {
      const projectID = projectsList?.list.filter(
        (prjObj) =>
          prjObj.criteria === allDropdownsList[0].value &&
          prjObj.name === allDropdownsList[1].value,
      )[0]?.id
      if (projectID) {
        setProjectId(projectID)
      } else {
        setProjectId(null)
      }
    } else {
      setProjectId(null)
    }
  }, [allDropdownsList[0].value, allDropdownsList[1].value, projectsList])

  return (
    <CommonCardComponent
      loaderComponent={{
        loading: trendHighlightDataLoading,
        boxStyles: {
          height: '500px',
        },
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Trend Highlight&nbsp;</Typography>
          <DashboardTooltip
            handleOpen={handleInfoHover}
            title="These are the top trends in the selected dashboard that are picked-up by our state-of-the-art AI, that have transitioned to a higher classification from last month"
          >
            <InfoOutlinedIcon
              fontSize="small"
              sx={{ color: ({ palette }) => palette.text.secondary }}
            />
          </DashboardTooltip>
        </Box>
        <Box display="flex" alignItems="center">
          {lastUpdated && (
            <React.Fragment>
              <SyncIcon color="primary" fontSize="1rem" />
              <Typography>&nbsp;Last Updated:</Typography>
              <Typography color="primary">&nbsp;{lastUpdated}</Typography>
            </React.Fragment>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              '& hr': {
                mx: 2,
                height: 20,
              },
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Box>
          <PopoverDropdownsList
            buttonContent={<TuneRoundedIcon />}
            headingText="Configure Trend Highlight"
            handleSaveButtonClick={handleFiltersSave}
            allDropdownsList={allDropdownsList}
            setAllDropdownsList={setAllDropdownsList}
            email={email}
            amplitudeFn={amp}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mb: 1.5,
          }}
        >
          {Object.keys(chipsToShow).map((key) => (
            <DashboardTooltip
              title={
                <>
                  You can change the trend highlights from Configure{' '}
                  <TuneRoundedIcon
                    sx={{
                      verticalAlign: 'text-top',
                      color: ({ palette }) => palette.primary.main,
                      background: ({ palette }) => palette.white,
                      fontSize: '0.75rem',
                    }}
                  />
                </>
              }
            >
              <Box>
                <Chip
                  variant="outlined"
                  label={chipsToShow[key]}
                  disabled
                  sx={{
                    opacity: '1 !important',
                    mr: 1,
                    '& .MuiChip-label': {
                      color: ({ palette }) => palette.text.secondary,
                    },
                  }}
                />
              </Box>
            </DashboardTooltip>
          ))}
        </Box>
        <DashboardTrendHighlightCardsList
          trendHighlightData={trendHighlightData}
          lensSelected={lensSelected}
          projectId={projectIdSelected}
          country={countrySelected}
          category={categorySelected}
          subCategory={subCategorySelected}
          email={email}
          amplitudeFn={amp}
          countrySelected={countrySelected}
        />
      </Box>
    </CommonCardComponent>
  )
}

export default DashboardTrendHighlight
