import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { setCGSelectedConcepts, setUserLoggedOut } from '../../store/actions'

class LogoutSection extends React.Component {
  componentWillMount() {
    this.props.onLogout()
    // Clear localStorage
    localStorage.clear()
    sessionStorage.clear()
    // Reset State
    this.props.resetCGSelectedConcepts()
  }

  render() {
    return <div>{this.props.isUserLoggedOut ? <Redirect to="/" /> : null}</div>
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedOut: state.user.isUserLoggedOut,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(setUserLoggedOut()),
    resetCGSelectedConcepts: () => dispatch(setCGSelectedConcepts([], true)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutSection),
)
