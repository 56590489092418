import React from 'react';
import typography from '../typography';
import palette from '../palette';
import { createSvgIcon } from '@mui/material';
import { ReactComponent as TableSortIcon } from '../../assets/icons/table-sort.svg';

const TableSortIcons = createSvgIcon(<TableSortIcon />, 'TableSortIcon');

export default {
  defaultProps: {
    IconComponent: TableSortIcons,
  },
  styleOverrides: {
    root: {
      ...typography.caption,
      color: palette.primary.main,
      '& .MuiSvgIcon-root': {
        marginLeft: 16,
        fontSize: 12,
        opacity: 1,
        transform: 'rotate(0)',
      },
      '& path': {
        fill: palette.icon,
      },
      '&:not(.Mui-active)': {
        '& .MuiSvgIcon-root path': {
          fill: palette.icon,
        },
      },
      '&.Mui-active': {
        color: palette.primary.main,
      },
    },
    iconDirectionDesc: {
      '& path:first-of-type': {
        fill: palette.primary.main,
      },
    },
    iconDirectionAsc: {
      '& path:not(:first-of-type)': {
        fill: palette.primary.main,
      },
    },
  },
};
