import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../services/networking/axios'
import * as actions from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import {
  getUrlSelectedLens,
  isProduction,
  isAipaletteUser,
  getQueryParams,
} from '../../store/utility'
import { Box, Grid, Stack } from '@mui/material'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import CategoryInsightDetailsTop from '../../shared/components/CategoryInsightDetailsTop'
import DriverCard from '../../shared/components/DriverCard'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { downloadGraph, downloadSection, downloadFullPage } from '../../const'
import '../muiStyle.css'

class CategoryNeedInsightPage extends Component {
  state = {
    laoder: false,
    isDownloading: false,
  }

  needsSelectedHandler = (id) => {
    this.props.userSelectedNeeds(id)
    this.props.resetState()
  }

  productSelectedHandler = (product, lensSelected) => {
    const { projectid } = this.props
    this.props.userSelectedProduct(product.id)
    this.props.addBreadCrumb({
      id: product.id,
      name: product.name,
      type: 'TrendProduct',
    })
    if (isProduction() && !isAipaletteUser(this.props.userLoggedIn.email)) {
      // eslint-disable-next-line
      amplitude.getInstance().setUserId(this.props.userLoggedIn.email)
      // eslint-disable-next-line
      amplitude
        .getInstance()
        .setUserProperties(`Name : ${this.props.userLoggedIn.firstName}`)
      // eslint-disable-next-line
      amplitude
        .getInstance()
        .logEvent(
          `Clicked ` +
            `/projectList/${projectid}/${lensSelected.toLowerCase()}/${
              product.id
            }`,
        )
    }
    this.props.history.push(
      `/projectList/${projectid}/${lensSelected.toLowerCase()}/${product.id}`,
    )
  }

  imageDownload = (section) => {
    this.setState({ loader: true }, () => {
      const name = this.props.needDetails.keyword
      const country = this.props.needDetails.countryName
      const category = this.props.needDetails.projectName
      if (section === 'Chart') {
        downloadGraph(
          document.getElementById('_dowSectionChart'),
          `${name}_Monthly_Trend_Graph.jpg`,
        )
          .then(() => {
            this.setState({ loader: false })
          })
          .catch((er) => {
            console.log(er)
            this.setState({ loader: false })
          })
      } else {
        let dowSection = document.getElementById('_dowSectionDesc')
        let fileName = `${name}_${country}_${category}_Complete_Report.jpg`
        if (!section) {
          downloadFullPage(
            dowSection,
            '#_dowSectionChart',
            { top: 150, left: 0 },
            document.getElementById('downloadCanvas'),
            `height: ${
              document
                .querySelector('#_dowSectionChart')
                .getBoundingClientRect().height
            }px; background: white;`,
            fileName,
          )
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              this.setState({ loader: false })
              console.log(er)
            })
        }
        if (section === 'RelatedIngredients') {
          dowSection = document.getElementById('_dowSectionIngredientBlock')
          fileName = `${name}_Related_Ingredients.jpg`
          downloadSection(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch(() => {
              this.setState({ loader: false })
            })
        }
      }
    })
  }

  componentDidMount() {
    const needid = this.props.needid
    const projectid = this.props.projectid
    const productid = this.props.productid

    this.props.fetchProductNeedsDetails(projectid, needid)
    this.props.fetchProductNeedQuotes(projectid, needid)
    const { needDetails, loading, productFormatName } = this.props

    const lensSelected = getUrlSelectedLens(this.props)
    const needType = this.props.match.params.needType

    this.props.userSelectedProductNeed(needid)
    this.props.userSelectedProduct(productid)
    this.props.userSelectedProject(projectid)
    window.scrollTo(0, 0)

    if (productid) {
      if (needid || productFormatName) {
        if (!needDetails && !loading) {
          switch (needType) {
            case 'retail-format':
              // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
              this.props.fetchProductFormatDetails(
                this.props.productid,
                'retail_product',
                productFormatName,
                lensSelected,
              )
              break
            case 'cafe-format':
              // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
              this.props.fetchProductFormatDetails(
                this.props.productid,
                'cafe_product',
                productFormatName,
                lensSelected,
              )
              break
            default:
              this.props.fetchProductNeedsDetails(
                productid,
                needid,
                lensSelected,
              )
              this.props.fetchProductNeedQuotes(productid, needid, lensSelected)
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  fetchCQForProduct() {
    const needid = this.props.needid
    const projectid = this.props.projectid
    this.props.fetchProductNeedQuotes(projectid, needid)
  }

  componentDidUpdate(prevProps, prevState) {
    const lensSelected = getUrlSelectedLens(this.props)
    const needType = this.props.match.params.needType
    const productFormatName = this.props.productFormatName
    if (!this.props.loading && prevProps.loading) {
      window.scrollTo(0, 0)
    }
    if (
      this.props.match.params.needId !== prevProps.match.params.needId ||
      this.props.productFormatName !== prevProps.productFormatName
    ) {
      if (this.props.productid) {
        switch (needType) {
          case 'retail-format':
            // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
            this.props.fetchProductFormatDetails(
              this.props.productid,
              'retail_product',
              productFormatName,
              lensSelected,
            )
            break
          case 'cafe-format':
            // Redundant but it's safe for future to call the same
            // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
            this.props.fetchProductFormatDetails(
              this.props.productid,
              'cafe_product',
              productFormatName,
              lensSelected,
            )
            break
          default:
            // need
            this.props.fetchProductNeedsDetails(
              this.props.productid,
              this.props.match.params.needId,
              lensSelected,
            )
            this.props.fetchProductNeedQuotes(
              this.props.productid,
              this.props.match.params.needId,
              lensSelected,
            )
        }
      }
    }

    if (
      !this.props.needDetails &&
      this.props.needDetails !== prevProps.needDetails &&
      !this.props.loading
    ) {
      if (
        (this.props.needid || this.props.productFormatName) &&
        this.props.productid
      ) {
        switch (needType) {
          case 'cafe-format':
            // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
            this.props.fetchProductFormatDetails(
              this.props.productid,
              'cafe_product',
              productFormatName,
              lensSelected,
            )
            break
          case 'retail-format':
            // Redundant but it's safe for future to call the same
            // this.props.fetchProductFormatDetails(this.props.productid, this.props.match.params.needId, lensSelected);
            this.props.fetchProductFormatDetails(
              this.props.productid,
              'retail_product',
              productFormatName,
              lensSelected,
            )
            break
          default:
            // need
            this.props.fetchProductNeedsDetails(
              this.props.productid,
              this.props.needid,
              lensSelected,
            )
            this.props.fetchProductNeedQuotes(
              this.props.productid,
              this.props.needid,
              lensSelected,
            )
        }
      }
    }
  }

  getSortedByPercentageValues(productFormatArr) {
    productFormatArr = productFormatArr || []
    return productFormatArr.sort((format1, format2) => {
      const format1PercentageScoreCurrent = format1.percentage_score_current
        ? format1.percentage_score_current
        : 0
      const format2PercentageScoreCurrent = format2.percentage_score_current
        ? format2.percentage_score_current
        : 0
      return format2PercentageScoreCurrent - format1PercentageScoreCurrent
    })
  }

  render() {
    const { loader } = this.state
    const projectVariant = this.props.dashboardList?.find(
      (dashboard) =>
        dashboard.criteria === this.props.needDetails?.countryName &&
        dashboard.name === this.props.needDetails?.projectName,
    )?._projectVariant

    if (!this.props.needDetails)
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              mt: 2,
              pt: 32,
              px: 88,
              flexGrow: 2,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <CircularProgressCustom />
          </Box>
        </Grid>
      )
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        {(loader || this.props.loading) && <OverlayLoader />}
        <Box id="_dowSectionDesc">
          <Stack spacing={3}>
            <CategoryInsightDetailsTop
              expand={loader}
              needChart={true}
              projectid={this.props.projectid}
              productid={this.props.productid}
              needid={this.props.needid}
              urllens={this.props.match.params.urlLensSelected}
              details={this.props.needDetails}
              country={this.props.needDetails.countryName}
              category={this.props.needDetails.projectName}
              email={this.props.email}
              imageDownload={this.imageDownload}
              positiveSentiment={-99999}
              neutralSentiment={-99999}
              negativeSentiment={-99999}
            />
            {this.props.needDetails.productNeeds &&
              this.props.needDetails.productNeeds.length !== 0 && (
                <DriverCard
                  expand={loader}
                  categoryPreviewNeed={false}
                  projectid={this.props.projectid}
                  productid={this.props.productid}
                  urllens={this.props.match.params.urlLensSelected}
                  needsList={[...this.props.needDetails.productNeeds].sort(
                    (needA, needB) => Number(needB.score) - Number(needA.score),
                  )}
                  category={this.props.needDetails.needCategory}
                />
              )}
          </Stack>
        </Box>
        <ConsumerQuotesCardComponent
          quotesOptions={this.props.needDetails.quotesOptions}
          consumerQuotes={this.props.productNeedQuotes}
          onRefresh={() => {
            this.fetchCQForProduct()
          }}
          sourceShow
          country={this.props.needDetails.countryName}
          category={this.props.needDetails.projectName}
          name={this.props.needDetails.keyword}
          lens={this.props.match.params.urlLensSelected}
          markIrrelevantDisabled={false}
          details={{
            type: 'Trend_Level_CN',
            trendName: this.props.needDetails?.productName,
          }}
          projectVariant={projectVariant}
        />
        <Box id="downloadCanvas"></Box>
      </Stack>
    )
  }
}

const mapStateToProps = (state, props) => {
  const downloadSections = state.downloadSections.list
  const productFormatName = decodeURIComponent(
    getQueryParams(props).get('productFormat'),
  )
  let dw = {}
  if (state.productNeed && state.productNeed.productNeedDetails) {
    const needDetails = state.productNeed.productNeedDetails
    const productName = `${needDetails.countryName} ${needDetails.category ||
      needDetails.projectName ||
      ''} ${needDetails.productName} ${needDetails.keyword} ${
      props.match.params.needType === 'need'
        ? 'Consumer Need'
        : 'Product Format'
    }`
    const nameSection = productName + ' need details.jpg'
    // product-consumer-needs
    const nameSectionRelated = productName + ' related ingredients.jpg'
    const nameSectionChart = productName + ' engagement chart.jpg'
    let found = 0
    const fileName = `${productName} Complete Report.jpg`
    if (downloadSections.has(productName)) {
      downloadSections.getIn([productName, 'files']).forEach((section) => {
        const page = section.toJS()
        if (page.name.includes(fileName)) {
          found++
        }
      })
    }
    dw = {
      productName,
      nameSection,
      nameSectionRelated,
      nameSectionChart,
      isSectionAdded: found > 0,
    }
  }
  const projectid = Number(props.match.params.projectId)
  const productid = Number(props.match.params.productId)
  const needid = Number(props.match.params.needId)
  return {
    // projectid: state.userSelections.projectID === projectID ? state.userSelections.projectID : projectID,
    // productid: state.userSelections.productID === productID ? state.userSelections.productID : productID,
    // needid: state.userSelections.productNeedID === needid ? state.userSelections.productNeedID : needid,
    projectid: projectid,
    productid: productid,
    needid: needid,
    needDetails: state.productNeed.productNeedDetails,
    productNeedQuotes: state.productNeed.productNeedQuotes,
    needsList: state.projectListBuilder.needsList,
    error: state.productNeed.error,
    loading: state.productNeed.loading,
    productDetails: state.productDetails.productDetails,
    projectList: state.projectListBuilder.projectList,
    userLoggedIn: state.user.loggedIn.user,
    productFormatName: productFormatName,
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    ...dw,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductNeedsDetails: (productId, needid, lensSelected) =>
      dispatch(
        actions.fetchProductNeedDetails(productId, needid, lensSelected),
      ),
    fetchProductNeedQuotes: (productId, needid, lensSelected) =>
      dispatch(actions.fetchProductNeedQuotes(productId, needid, lensSelected)),
    fetchKeywordNeedsDetails: (projectid, keyword) =>
      dispatch(actions.fetchKeywordNeedsDetails(projectid, keyword)),
    userSelectedProduct: (productid) =>
      dispatch(actions.userSelectedProduct(productid)),
    userSelectProject: (productid) =>
      dispatch(actions.userSelectedProject(productid)),
    userSelectedProductNeed: (needid) =>
      dispatch(actions.userSelectedProductNeed(needid)),
    resetState: () => {
      dispatch(actions.resetProductNeeds())
      dispatch(actions.resetProductDetails())
    },

    // Product format
    fetchProductFormatDetails: (
      productId,
      formatType,
      formatName,
      lensSelected,
    ) =>
      dispatch(
        actions.fetchProductFormatDetails(
          productId,
          formatType,
          formatName,
          lensSelected,
        ),
      ),
    userSelectedProject: (productid) =>
      dispatch(actions.userSelectedProject(productid)),

    // fetchProductDetails: (productid) => dispatch(actions.fetchProductDetails(productid)),
    sectionAdd: (productName, section) =>
      dispatch(actions.sectionAdd(productName, section)),
    sectionRemove: (nameSection) =>
      dispatch(actions.sectionRemove(nameSection)),
    spinnerDownload: (isShow) => dispatch(actions.spinnerDownload(isShow)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(CategoryNeedInsightPage, axios)),
)
