import React from 'react'
import {
  Field, reduxForm, formValueSelector,
} from 'redux-form'
import {
  renderField,
} from '../UI/form/index'
import { required, passwordLength} from '../../validation'
import { connect } from 'react-redux'
import { reduxFormConst } from '../../const'
import logo from '../../assets/images/TrendIcon/ai-palette--logo.svg'
import NetworkingUtil from '../../services/networking/NetworkingUtil'


const passwordMatch = (value, values) => {
  return (value !== values.password) ? 'Should match password value' : undefined
}

class ResetPasswordForm extends React.Component {

  componentDidMount (){
    NetworkingUtil.clearAuthKey() // Otherwise user will be automatically be logged while resetting password (so terminate the earlier active session if any)

    this.props.initialize({
      token: this.props.token,
    })
  }

  render () {
    return (
      <div className='login__wrapper'>
        <div className='logo__wrapper'>
          <div className="col-md-1 logo">
            <img src={logo} />
          </div>
        </div>
        <div className='container'>
          <div className='container__inner'>
            <div className='img__block'>
            </div>
            <div className='loginbox__block forget__password'>
              <div className='loginbox__inner'>
                <div className='login__box'>
                  <div className='box__heading'>
                    <span className="caption">Reset Password</span>
                    <p className="sub__caption">Enter new password and confirm password.</p>
                  </div>
                  <div className='loginBoxBody'>
                    <form key="resetPasswordForm" onSubmit={this.props.handleSubmit}>
                      <div>
                        <Field 
                          name="password" 
                          label="New Password:" 
                          component={renderField} 
                          type="password"
                          validate={[required, passwordLength]}
                        />
                        <Field 
                          name="confirmPassword" 
                          label="Confirm Password:" 
                          component={renderField}
                          type="password"
                          validate={[required, passwordLength, passwordMatch]}
                        />
                        <Field
                          name="token"
                          type="text"
                          component={renderField}
                          className="form-control"
                          placeholder="Token"
                          hidden
                        />
                      </div>

                      <div className={'col-xs-12  text-center '}>
                        <button type="submit" className="btn btn__blue">
                                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

ResetPasswordForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.forgetPassword,
})(ResetPasswordForm)

const selector = formValueSelector('registration-form')

ResetPasswordForm = connect()(ResetPasswordForm)
export default ResetPasswordForm
