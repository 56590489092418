import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import GlobalSearchUI from './GlobalSearchUI'
import { withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from './../../../services/networking/axios'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'
import useDebounce from '../../hooks/useDebounce'
import {
  makeUniqueByKey,
  getQueryParams,
  amplify,
} from '../../../store/utility'
import axiosClass from 'axios'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { fetchSavedTrends } from '../../../store/actions'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const getTabLabel = (tab, tabList) => {
      return tabList.find((tabDetail) => tabDetail.id === tab).label
    }
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.project.country,
      Category_Name: ampiUserData.project.category,
    }
    switch (amplitudeEvent) {
      case 'Clicked_Trend_Report_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Report: ampiUserData.entity.entityName,
        }
        break
      case 'Clicked_Tab_Search_Bar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Tab: getTabLabel(ampiUserData.tab, ampiUserData.tabList),
        }
        break
      case 'Clicked_Trend_From_Suggestions':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.entity.entityName,
          Lens: ampiUserData.entity.type,
          Redirected_From: 'Search Suggestion',
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Global Search')
  }
}

let highlightReason = null
let highlightedOption = null

const CancelToken = axiosClass.CancelToken
let ingredientSource = CancelToken.source()
let productSource = CancelToken.source()
let themeSource = CancelToken.source()
let reportSource = CancelToken.source()

const defaultTab = [{ id: 0, label: 'Recent' }]
const PAGE_SIZE = 10

const GlobalSearch = ({
  projectId,
  requestProjectsList,
  projectsList,
  searchedKeyword,
  smallSize,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [searchedText, setSearchedText] = useState(searchedKeyword || '')
  const debouncedSearchValue = useDebounce(searchedText, 300)
  const [selectedTab, setSelectedTab] = useState(0)
  const [entityData, setEntityData] = useState([])
  const [entityLoader, setEntityLoader] = useState(false)
  const [ingredientSuggestions, setIngredientSuggestions] = useState([])
  const [productSuggestions, setProductSuggestions] = useState([])
  const [themeSuggestions, setThemeSuggestions] = useState([])
  const [reportSuggestions, setReportSuggestions] = useState([])
  const [allSuggestions, setAllSuggestions] = useState([])
  const [ingredientSuggestionCount, setIngredientSuggestionCount] = useState(0)
  const [productSuggestionCount, setProductSuggestionCount] = useState(0)
  const [themeSuggestionCount, setThemeSuggestionCount] = useState(0)
  const [reportSuggestionCount, setReportSuggestionCount] = useState(0)
  const [ingredientNextURL, setIngredientNextURL] = useState('')
  const [productNextURL, setProductNextURL] = useState('')
  const [themeNextURL, setThemeNextURL] = useState('')
  const [reportNextURL, setReportNextURL] = useState('')
  const [ingredientFetching, setIngredientFetching] = useState(false)
  const [productFetching, setProductFetching] = useState(false)
  const [themeFetching, setThemeFetching] = useState(false)
  const [reportFetching, setReportFetching] = useState(false)
  const [recentHistory, setRecentHistory] = useState([])
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [isOptionFocused, setIsOptionFocused] = useState(false)
  const [activeReport, setActiveReport] = useState(null)
  const [project, setProject] = useState({})

  const location = useLocation()

  const cancelRequest = (type) => {
    switch (type) {
      case 'ingredient':
        ingredientSource.cancel()
        ingredientSource = axiosClass.CancelToken.source()
        break
      case 'product':
        productSource.cancel()
        productSource = axiosClass.CancelToken.source()
        break
      case 'theme':
        themeSource.cancel()
        themeSource = axiosClass.CancelToken.source()
        break
      case 'report':
        reportSource.cancel()
        reportSource = axiosClass.CancelToken.source()
        break
    }
  }

  const history = useHistory()
  const query = getQueryParams()

  const tabList = debouncedSearchValue
    ? [
        { id: 0, label: 'All' },
        {
          id: 1,
          label: 'Ingredient',
          labelQuantity: ingredientSuggestionCount,
        },
        { id: 2, label: 'Product', labelQuantity: productSuggestionCount },
        { id: 3, label: 'Theme', labelQuantity: themeSuggestionCount },
        { id: 4, label: 'Report', labelQuantity: reportSuggestionCount },
      ]
    : defaultTab

  const searchBoxRef = useRef(null)

  const logEntityClicked = (entity) => {
    let ampiEvent = 'Clicked_Trend_From_Suggestions'
    const ampiUserData = {
      entity,
      project,
    }
    if (entity.type !== 'report') {
      amp(ampiEvent, ampiUserData)
    } else if (!activeReport) {
      ampiEvent = 'Clicked_Trend_Report_Search'
      amp(ampiEvent, ampiUserData)
    }
  }

  const logTabChange = (tab) => {
    const ampiEvent = 'Clicked_Tab_Search_Bar'
    const ampiUserData = {
      tab,
      tabList,
      project,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleTabSelection = (tab) => {
    setSelectedTab(tab)
    logTabChange(tab)
  }

  const getCountryCategory = (projectId) => {
    if (projectsList.length) {
      return projectsList.find((project) => `${project.id}` === projectId)
    }
  }

  const sendRecentHistory = (entityDetails) => {
    if (entityDetails.type !== 'report') {
      setAutocompleteOpen(false)
      const search = document.querySelector('#global-search')
      search.blur()
      setIsFocused(false)
      setAutocompleteOpen(false)
      setSearchedText('')
    }

    const formattedEntityDetails = {
      entity_id: entityDetails.id,
      entity_name: entityDetails.entityName,
      entity_category: entityDetails.type,
      image_url: entityDetails.img,
      project_id: projectId,
      ...(entityDetails.type === 'report' && {
        resource_url: entityDetails.reportURL,
      }),
    }
    const recentHistoryReq = NetworkingUtil.postRecentHistory()
    axios
      .post(recentHistoryReq, formattedEntityDetails)
      .then((res) => {})
      .catch((error) => {
        console.log('post history api call failed')
      })
  }

  const buildLink = (p, l, i) => {
    return `/mui/details/${p}/${l.toLowerCase()}/${i}`
  }

  const openReport = (id) => {
    setActiveReport(id)
    query.set('activeReport', id)
    history.replace(`?${query.toString()}`)
  }

  const trendRedirection = (entity) => {
    if (entity?.id) {
      logEntityClicked(entity)
      const link = buildLink(projectId, entity.type, entity.id)
      history.push(link)
      setSearchedText(entity.entityName)
    }
  }

  const searchResultRedirection = (text) => {
    setIsFocused(false)
    // Redirection to search page
    if (text && typeof text === 'string') {
      history.push(`/mui/search/${projectId}/${text}`)
    }
  }

  const handleEntityClick = (e, v) => {
    // Required for preventing report from opening on clsoing it
    if (v && e.target.offsetParent) {
      sendRecentHistory(v)
      if (v.type === 'report') {
        if (activeReport) {
          setActiveReport(null)
        } else {
          logEntityClicked(v)
          openReport(v.id)
        }
      } else {
        if (highlightReason === 'keydown') {
          trendRedirection(v)
        } else if (highlightReason === 'mousemove') {
          if (e.code === 'Enter') {
            if (searchedText) {
              searchResultRedirection(searchedText)
            } else {
              e.preventDefault()
            }
          } else {
            trendRedirection(v)
          }
        } else if (e.code === 'Enter') {
          if (searchedText) {
            searchResultRedirection(searchedText)
          }
        } else {
          trendRedirection(v)
        }
      }
    } else {
      if (searchedText && !activeReport) {
        searchResultRedirection(searchedText)
      }
    }
  }

  const handleOnChange = (e, v) => {
    if (typeof v === 'string') {
      //  Redirect to Search Result Page
      searchResultRedirection(v)
    } else {
      // Either open report or redirect to trend page
      handleEntityClick(e, v)
    }
  }

  const handleKeyUp = (e, v) => {
    if (e.code === 'ArrowDown') {
      setIsOptionFocused(false)
    }
    if (isOptionFocused && e.code === 'Enter') {
      searchResultRedirection(e.target.value)
    }
    if (highlightReason !== 'mousemove' && e.code === 'Enter') {
      handleEntityClick(e, highlightedOption)
    } else if (e.code === 'Enter' && e.target.value !== '') {
      searchResultRedirection(e.target.value)
    }

    setSearchedText(e.target.value)
  }

  const clearSearchedText = () => {
    setSearchedText('')
  }

  const formatSuggestions = (
    suggestions,
    isRecentHistoryFormatting = false,
  ) => {
    return suggestions.map((entity) => {
      return {
        id: entity.entity_id,
        type: entity.entity_category,
        entityName: entity.entity_name,
        img: entity.image_url,
        ...(entity.entity_category === 'report' && {
          reportURL: isRecentHistoryFormatting
            ? entity.resource_url
            : entity.report_url,
        }),
      }
    })
  }

  const fetchRecentHistoryAPICall = () => {
    if (projectId) {
      const recentHistory = NetworkingUtil.fetchRecentHistory(projectId)
      setEntityLoader(true)
      axios
        .get(recentHistory)
        .then((res) => {
          const fetchedEntityData = formatSuggestions(res.data, true)
          const uniqueRecentHistory = makeUniqueByKey(fetchedEntityData, 'id')
          setRecentHistory(uniqueRecentHistory)
          setEntityData(uniqueRecentHistory)
          setEntityLoader(false)
        })
        .catch(() => {
          setEntityLoader(false)
          console.log('error in getting recent history')
        })
    }
  }

  const sourceIdentification = (type) => {
    switch (type) {
      case 'ingredient':
        return ingredientSource
      case 'product':
        return productSource
      case 'theme':
        return themeSource
      case 'report':
        return reportSource
    }
  }

  const fetchTrendSuggestions = (
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    type,
    setSuggestionCount,
    setTrendSuggestion,
    trendSuggestions,
    nextReq,
    setNextReq,
    trendFetching,
    setTrendFetching,
    pagination = false,
  ) => {
    cancelRequest(type)
    const trendNextReq = isSearchedTextChanged ? '' : nextReq
    if (trendNextReq === '' && !pagination) {
      setEntityLoader(true)
    }
    const trendSuggestionReq =
      trendNextReq ||
      NetworkingUtil.fetchSearchSuggestions(
        projectId,
        debouncedSearchValue,
        type,
        PAGE_SIZE,
      )
    if (
      (!trendFetching && !trendSuggestions.length) ||
      nextReq !== null ||
      isSearchedTextChanged
    ) {
      setTrendFetching(true)
      axios
        .get(trendSuggestionReq, {
          cancelToken: sourceIdentification(type).token,
        })
        .then((res) => {
          setSuggestionCount(res.data.count)

          const formattedSuggestions = formatSuggestions(res.data.results)
          if (isSearchedTextChanged) {
            setTrendSuggestion([...formattedSuggestions])
            if (isEntitySetNeeded) setEntityData([...formattedSuggestions])
          } else {
            const updatedSuggestions = makeUniqueByKey(
              trendNextReq
                ? [...trendSuggestions, ...formattedSuggestions]
                : [...formattedSuggestions],
              'id',
            )
            setTrendSuggestion([...updatedSuggestions])

            if (isEntitySetNeeded) {
              setEntityData([...updatedSuggestions])
            }
          }

          setNextReq(res.data.next)
          setTrendFetching(false)
          if (trendNextReq === '' && !pagination) setEntityLoader(false)
        })
        .catch(() => {
          console.log('error in getting ingredient search suggestion')
          setTrendFetching(false)
          if (trendNextReq === '' && !pagination) setEntityLoader(false)
        })
    }
  }

  const fetchIngredientSuggestions = (
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    isPaginated = false,
  ) => {
    fetchTrendSuggestions(
      isEntitySetNeeded,
      isSearchedTextChanged,
      'ingredient',
      setIngredientSuggestionCount,
      setIngredientSuggestions,
      ingredientSuggestions,
      ingredientNextURL,
      setIngredientNextURL,
      ingredientFetching,
      setIngredientFetching,
      isPaginated,
    )
  }

  const fetchProductSuggestions = (
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    isPaginated = false,
  ) => {
    fetchTrendSuggestions(
      isEntitySetNeeded,
      isSearchedTextChanged,
      'product',
      setProductSuggestionCount,
      setProductSuggestions,
      productSuggestions,
      productNextURL,
      setProductNextURL,
      productFetching,
      setProductFetching,
      isPaginated,
    )
  }

  const fetchThemeSuggestions = (
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    isPaginated = false,
  ) => {
    fetchTrendSuggestions(
      isEntitySetNeeded,
      isSearchedTextChanged,
      'theme',
      setThemeSuggestionCount,
      setThemeSuggestions,
      themeSuggestions,
      themeNextURL,
      setThemeNextURL,
      themeFetching,
      setThemeFetching,
      isPaginated,
    )
  }

  const fetchreportSuggestions = (
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    isPaginated = false,
  ) => {
    fetchTrendSuggestions(
      isEntitySetNeeded,
      isSearchedTextChanged,
      'report',
      setReportSuggestionCount,
      setReportSuggestions,
      reportSuggestions,
      reportNextURL,
      setReportNextURL,
      reportFetching,
      setReportFetching,
      isPaginated,
    )
  }

  const fetchOtherApis = (type, isSearchedTextChanged, isPaginated) => {
    if (type !== 'ingredient')
      fetchIngredientSuggestions(false, isSearchedTextChanged, isPaginated)
    if (type !== 'product')
      fetchProductSuggestions(false, isSearchedTextChanged, isPaginated)
    if (type !== 'theme')
      fetchThemeSuggestions(false, isSearchedTextChanged, isPaginated)
    if (type !== 'report')
      fetchreportSuggestions(false, isSearchedTextChanged, isPaginated)
  }

  const fetchAllApis = (
    type,
    isEntitySetNeeded = false,
    isSearchedTextChanged = false,
    isPaginated = false,
  ) => {
    switch (type) {
      case 'ingredient':
        fetchIngredientSuggestions(
          isEntitySetNeeded,
          isSearchedTextChanged,
          isPaginated,
        )
        break
      case 'product':
        fetchProductSuggestions(
          isEntitySetNeeded,
          isSearchedTextChanged,
          isPaginated,
        )
        break
      case 'theme':
        fetchThemeSuggestions(
          isEntitySetNeeded,
          isSearchedTextChanged,
          isPaginated,
        )
        break
      case 'report':
        fetchreportSuggestions(
          isEntitySetNeeded,
          isSearchedTextChanged,
          isPaginated,
        )
        break
    }
  }

  //  This function merge arrays in alternate group of two items.
  const mergeForAllTab = (ingArr, proArr, themeArr, reportArr) => {
    const mergedArr = []

    const arr1 = [...ingArr]
    const arr2 = [...proArr]
    const arr3 = [...themeArr]
    const arr4 = [...reportArr]

    let arr1Index = 0
    let arr2Index = 0
    let arr3Index = 0
    let arr4Index = 0

    while (
      mergedArr.length !==
      arr1.length + arr2.length + arr3.length + arr4.length
    ) {
      if (arr1[arr1Index] !== undefined) {
        mergedArr.push(arr1[arr1Index])
        arr1Index++
      }
      if (arr1[arr1Index] !== undefined) {
        mergedArr.push(arr1[arr1Index])
        arr1Index++
      }
      if (arr2[arr2Index] !== undefined) {
        mergedArr.push(arr2[arr2Index])
        arr2Index++
      }
      if (arr2[arr2Index] !== undefined) {
        mergedArr.push(arr2[arr2Index])
        arr2Index++
      }

      if (arr3[arr3Index] !== undefined) {
        mergedArr.push(arr3[arr3Index])
        arr3Index++
      }
      if (arr3[arr3Index] !== undefined) {
        mergedArr.push(arr3[arr3Index])
        arr3Index++
      }
      if (arr4[arr4Index] !== undefined) {
        mergedArr.push(arr4[arr4Index])
        arr4Index++
      }
      if (arr4[arr4Index] !== undefined) {
        mergedArr.push(arr4[arr4Index])
        arr4Index++
      }
    }

    return mergedArr
  }

  const handleAllSuggestions = () => {
    const mergedArr = mergeForAllTab(
      ingredientSuggestions,
      productSuggestions,
      themeSuggestions,
      reportSuggestions,
    )

    const newSuggestion = makeUniqueByKey(mergedArr, 'id')

    setAllSuggestions([...newSuggestion])
    if (selectedTab === 0 && searchedText !== '') {
      setEntityData([...newSuggestion])
    }
  }

  useEffect(() => {
    handleAllSuggestions()
  }, [
    ingredientSuggestions.length,
    productSuggestions.length,
    themeSuggestions.length,
    reportSuggestions.length,
  ])

  const loadMoreResults = () => {
    switch (selectedTab) {
      case 0:
        fetchAllApis('ingredient', false, false, true)
        fetchAllApis('product', false, false, true)
        fetchAllApis('theme', false, false, true)
        fetchAllApis('report', false, false, true)
        handleAllSuggestions()
        break
      case 1:
        fetchAllApis('ingredient', true, false, true)
        break
      case 2:
        fetchAllApis('product', true, false, true)
        break
      case 3:
        fetchAllApis('theme', true, false, true)
        break
      case 4:
        fetchAllApis('report', true, false, true)
    }
  }

  const clearState = () => {
    setEntityData([])
    setIngredientSuggestions([])
    setThemeSuggestions([])
    setProductSuggestions([])
    setReportSuggestions([])
    setIngredientSuggestionCount(0)
    setProductSuggestionCount(0)
    setThemeSuggestionCount(0)
    setReportSuggestionCount(0)
  }

  const searchTextModified = () => {
    clearState()
    if (debouncedSearchValue === '') {
      if (!recentHistory.length && searchedText === '' && !activeReport) {
        setAutocompleteOpen(false)
      }
      setSelectedTab(0)
      fetchRecentHistoryAPICall()
    } else {
      if (searchedText !== '' && !autocompleteOpen) {
        setAutocompleteOpen(true)
      }
      switch (selectedTab) {
        case 0:
          fetchAllApis('ingredient', false, true, false)
          fetchAllApis('product', false, true, false)
          fetchAllApis('theme', false, true, false)
          fetchAllApis('report', false, true, false)
          break
        case 1:
          fetchAllApis('ingredient', true, true, false)
          break
        case 2:
          fetchAllApis('product', true, true, false)

          break
        case 3:
          fetchAllApis('theme', true, true, false)
          break
        case 4:
          fetchAllApis('report', true, true, false)
          break
      }
    }
  }

  const tabChange = () => {
    if (debouncedSearchValue) {
      switch (selectedTab) {
        case 0:
          if (!allSuggestions.length) {
            fetchAllApis('ingredient', false, false, false)
            fetchAllApis('product', false, false, false)
            fetchAllApis('theme', false, false, false)
            fetchAllApis('report', false, false, false)
            handleAllSuggestions()
          } else {
            setEntityData([...allSuggestions])
          }
          break
        case 1:
          if (!ingredientSuggestions.length) {
            fetchAllApis('ingredient', true, false, false)
          } else {
            setEntityData([...ingredientSuggestions])
          }
          break
        case 2:
          if (!productSuggestions.length) {
            fetchAllApis('product', true, false, false)
          } else {
            setEntityData([...productSuggestions])
          }
          break
        case 3:
          if (!themeSuggestions.length) {
            fetchAllApis('theme', true, false, false)
          } else {
            setEntityData([...themeSuggestions])
          }
          break
        case 4:
          if (!reportSuggestions.length) {
            fetchAllApis('report', true, false, false)
          } else {
            setEntityData([...reportSuggestions])
          }
          break
      }
    }
  }

  const togglePopper = (isPopperOpen) => {
    if (isPopperOpen) {
      setIsFocused(isPopperOpen)
      if (!recentHistory.length && searchedText === '') {
        setAutocompleteOpen(false)
      } else {
        setAutocompleteOpen(true)
      }
      tabChange()
    } else {
      if (activeReport === null && query.get('activeReport') === null) {
        setIsFocused(isPopperOpen)
        setAutocompleteOpen(false)
      }
    }
  }

  const handleSearchFocus = (e) => {
    if (e.code === 'F3' || ((e.ctrlKey || e.metaKey) && e.code === 'KeyF')) {
      e.preventDefault()
      setIsFocused(true)
      const search = document.querySelector('#global-search')
      search.focus()
    }
  }

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget
    const percentScrolled =
      ((listboxNode.scrollTop + listboxNode.clientHeight) /
        listboxNode.scrollHeight) *
      100
    if (percentScrolled > 70) {
      loadMoreResults()
    }
  }

  useEffect(() => {
    fetchRecentHistoryAPICall()
    window.addEventListener('keydown', handleSearchFocus)
    return () => window.removeEventListener('keydown', handleSearchFocus)
  }, [])

  useEffect(() => {
    searchTextModified()
  }, [debouncedSearchValue])

  useEffect(() => {
    tabChange()
  }, [selectedTab])

  // When navigated to trend page the text cursor was present in search bar
  useEffect(() => {
    if (!isFocused) {
      const search = document.querySelector('#global-search')
      search.blur()
    }
  }, [isFocused])

  // If URL changes then update
  useEffect(() => {
    if (activeReport === null || searchedKeyword) {
      fetchRecentHistoryAPICall()
      setSearchedText('')
    }
  }, [location])

  useEffect(() => {
    if (searchedKeyword) {
      setSearchedText(searchedKeyword)
      setAutocompleteOpen(false)
    }
  }, [searchedKeyword])

  useEffect(() => {
    setEntityLoader(true)
    requestProjectsList().then(() => {
      setEntityLoader(false)
    })
    return () => {
      setProject({})
      highlightReason = null
      highlightedOption = null
    }
  }, [])

  useEffect(() => {
    if (projectsList && projectId) {
      const mappedProject = getCountryCategory(projectId)
      const newProject = {
        id: mappedProject?.id,
        country: mappedProject?.criteria,
        category: mappedProject?.name,
      }
      setProject(newProject)
    }
  }, [projectId, projectsList?.length])

  const autoCompleteOptions = [...entityData]

  const handleHighlightOption = (e, v) => {
    if (e) {
      highlightReason = e.type
      highlightedOption = v
    }
  }

  return (
    <GlobalSearchUI
      searchBoxRef={searchBoxRef}
      isFocused={isFocused}
      searchedText={searchedText}
      clearSearchedText={clearSearchedText}
      selectedTab={selectedTab}
      handleTabSelection={handleTabSelection}
      listOfTabs={tabList}
      entityLoader={entityLoader}
      options={autoCompleteOptions}
      projectId={projectId}
      sendRecentHistory={sendRecentHistory}
      togglePopper={togglePopper}
      handleScroll={handleScroll}
      handleKeyUp={handleKeyUp}
      handleOnChange={handleOnChange}
      autocompleteOpen={autocompleteOpen}
      setSearchedText={setSearchedText}
      setIsOptionFocused={setIsOptionFocused}
      smallSize={smallSize}
      activeReport={activeReport}
      setActiveReport={setActiveReport}
      logEntityClicked={logEntityClicked}
      handleHighlightOption={handleHighlightOption}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    projectId: props.match.params.projectId,
    searchedKeyword: props.match.params.search,
    projectsList: state.savedTrendRequester.savedTrendList?._list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestProjectsList: () => dispatch(fetchSavedTrends()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalSearch, axios),
)
