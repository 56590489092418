import { fetchSharingUsers } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  countryList : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_SUCCESS]: return updateObject(state, {
      fetchSharingUsersList : action.sharingUserProfiles,
      error : false,
      loading : false,
    })
    case fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_FAILED]: return updateObject(state, {
      fetchSharingUsersList : null,
      error : true,
      loading : false,
    })
    case fetchSharingUsers.type[fetchSharingUsers.SU_FETCH_LIST_START]: return updateObject(state, {
      fetchSharingUsersList : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer