import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  Tooltip,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { FixedSizeList } from 'react-window'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const MultiSelectMenuItems = ({
  selectAllOption,
  searchBarEnabled,
  searchValue,
  handleAllCheckboxClick,
  allChecked,
  menuOptions,
  handleOptClick,
  extraBoxStyles,
  shouldShowTooltip,
  singleSelect = false,
}) => {
  const [heightOfList, setHeightOfList] = useState(menuOptions.length * 32)
  const itemsRenderList = ({ data, index, style }) => {
    if (shouldShowTooltip || data[index].label.length > 24) {
      return (
        <Tooltip
          title={
            <Typography
              variant="caption"
              sx={{
                textTransform: 'capitalize',
                color: ({ palette }) => palette.primary.contrastText,
              }}
            >
              {data[index].label}
              {data[index].key.indexOf('-') === -1 ? null : (
                <>
                  : &nbsp;
                  <b>
                    {data[index].key.split('-')[0][0].toUpperCase() +
                      data[index].key.split('-')[0].slice(1)}
                  </b>
                </>
              )}
            </Typography>
          }
          placement="right"
        >
          <MenuItem
            style={style}
            onClick={handleOptClick(data[index])}
            sx={{
              width: '100%',
              px: searchBarEnabled && 2,
              ...(data[index]?.checked
                ? {
                    background: ({ palette }) => palette.option.selected,
                  }
                : {}),
            }}
          >
            <FormControlLabel
              value={data[index]?.key}
              sx={{
                mx: 0,
                '& .MuiTypography-root': {
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              label={data[index]?.label}
              checked={data[index]?.checked}
              control={
                singleSelect ? (
                  <Radio
                    checked={data[index]?.checked}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                      ...(data[index]?.checked
                        ? {
                            color: ({ palette }) =>
                              `${palette.secondary.main} !important`,
                          }
                        : {}),
                    }}
                    style={{ marginRight: 8 }}
                  />
                ) : (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={data[index]?.checked}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                      ...(data[index]?.checked
                        ? {
                            color: ({ palette }) =>
                              `${palette.secondary.main} !important`,
                          }
                        : {}),
                    }}
                    style={{ marginRight: 8 }}
                  />
                )
              }
            />
          </MenuItem>
        </Tooltip>
      )
    } else {
      return (
        <MenuItem
          style={style}
          onClick={handleOptClick(data[index])}
          sx={{
            width: '100%',
            px: searchBarEnabled && 2,
            ...(data[index]?.checked
              ? {
                  background: ({ palette }) => palette.option.selected,
                }
              : {}),
          }}
        >
          <FormControlLabel
            value={data[index]?.key}
            sx={{
              mx: 0,
              '& .MuiTypography-root': {
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            label={data[index]?.label}
            checked={data[index]?.checked}
            control={
              singleSelect ? (
                <Radio
                  checked={data[index]?.checked}
                  sx={{
                    padding: 0,
                    marginRight: 1,
                    ...(data[index]?.checked
                      ? {
                          color: ({ palette }) =>
                            `${palette.secondary.main} !important`,
                        }
                      : {}),
                  }}
                  style={{ marginRight: 8 }}
                />
              ) : (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={data[index]?.checked}
                  sx={{
                    padding: 0,
                    marginRight: 1,
                    ...(data[index]?.checked
                      ? {
                          color: ({ palette }) =>
                            `${palette.secondary.main} !important`,
                        }
                      : {}),
                  }}
                  style={{ marginRight: 8 }}
                />
              )
            }
          />
        </MenuItem>
      )
    }
  }

  useEffect(() => {
    if (menuOptions.length > 8 && extraBoxStyles.maxHeight) {
      if (selectAllOption?.enabled) {
        setHeightOfList(extraBoxStyles.maxHeight - 36)
      } else {
        setHeightOfList(extraBoxStyles.maxHeight)
      }
    } else if (extraBoxStyles.height) {
      if (selectAllOption?.enabled) {
        setHeightOfList(extraBoxStyles.height - 36)
      } else {
        setHeightOfList(extraBoxStyles.height)
      }
    } else {
      setHeightOfList(menuOptions.length * 36)
    }
  }, [menuOptions, selectAllOption, extraBoxStyles])
  return (
    <Box component="div" sx={{ ...extraBoxStyles }}>
      {menuOptions.length ? (
        <Box>
          {selectAllOption?.enabled &&
            (!searchBarEnabled || searchValue === '') && (
              <MenuItem
                onClick={handleAllCheckboxClick}
                sx={{
                  width: '100%',
                  px: searchBarEnabled && 2,
                  ...(allChecked
                    ? {
                        background: ({ palette }) => palette.option.selected,
                      }
                    : {}),
                }}
              >
                <FormControlLabel
                  value="Select All"
                  sx={{ mx: 0 }}
                  label="Select All"
                  checked={allChecked}
                  control={
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={allChecked}
                      sx={{
                        padding: 0,
                        marginRight: 1,
                        ...(allChecked
                          ? {
                              color: ({ palette }) =>
                                `${palette.secondary.main} !important`,
                            }
                          : {}),
                      }}
                      style={{ marginRight: 8 }}
                    />
                  }
                />
              </MenuItem>
            )}
          <FixedSizeList
            height={heightOfList}
            itemCount={menuOptions.length}
            itemSize={36}
            itemData={menuOptions}
            width="100%"
          >
            {itemsRenderList}
          </FixedSizeList>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            p: 1,
          }}
        >
          No Filters
        </Box>
      )}
    </Box>
  )
}

MultiSelectMenuItems.propTypes = {
  searchValue: PropTypes.string,
  handleAllCheckboxClick: PropTypes.func,
  allChecked: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    }),
  ).isRequired,
  handleOptClick: PropTypes.func,
  searchBarEnabled: PropTypes.bool,
  selectAllOption: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
}

export default MultiSelectMenuItems
