import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { ListItem, ListItemText } from '@mui/material'
import TourProviderUI from './TourProviderUI'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from './../../../store/actions/index'
import { amplify } from './../../../store/utility'
import { AmplitudeValues } from './../../../store/amplitudeValues'

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
      Feature_Name: props.highlightFeature.name,
    }
    switch (ampiEvent) {
      case 'Clicked_Highlight_Pop_Up':
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        break
      case 'Clicked_Highlight_Pop_Up_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in Tour Provider')
  }
}

const TourProvider = (props) => {
  const { modalRef, feat } = props
  if (feat !== props.highlightFeature?.code) {
    return null
  }
  const [arrowRef, setArrowRef] = useState(null)
  const [isShown, setIsShown] = useState(false)

  const popperRef = useRef(null)

  const { top, left, right } = useMemo(
    () =>
      modalRef.current
        ? modalRef.current.getBoundingClientRect()
        : { top: 0, left: 0, right: 0, bottom: 0 },
    [modalRef.current],
  )

  const { offsetWidth, offsetHeight } = useMemo(
    () =>
      popperRef.current
        ? popperRef.current
        : { offsetWidth: 0, offsetHeight: 0 },
    [popperRef.current],
  )

  const content = {
    name: props.userName,
    featureName: props.highlightFeature.name,
    useCase: props.highlightFeature.featureDetails,
    link: props.highlightFeature.docUrl,
  }
  const pathname = content.link && new URL(content.link).pathname

  const useCaseList =
    content.useCase &&
    content.useCase.map((eachUseCase, i) => (
      <ListItem
        sx={{ display: 'list-item', listStyleType: 'decimal', padding: 0 }}
      >
        <ListItemText primary={` ${eachUseCase.description}`} />
      </ListItem>
    ))

  const tourList = localStorage.getItem('tourList')

  const popperPosition = () => {
    if (left - offsetWidth > 5) {
      return 'left'
    }
    if (right + offsetWidth < document.body.offsetWidth) {
      return 'right'
    }
    if (top - offsetHeight > 5) {
      return 'top'
    }
    return 'bottom'
  }

  const highlightAction = (action) => {
    const ampiEvent = 'Clicked_Highlight_Pop_Up_Action'
    const ampiUserData = {
      action,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  const crossClicked = () => {
    setIsShown(false)
    localStorage.setItem('tourList', 'none')
    highlightAction('Closed')
  }

  const clickedOK = (action) => {
    setIsShown(false)
    localStorage.setItem('tourList', 'none')
    const data = {
      code: props.highlightFeature.code,
      accepted_by_user: true,
      seen_by_user: true,
    }
    props.highlightFeatureSeen(data)
    highlightAction(action)
  }

  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'auto'
  }, [isShown])

  useEffect(() => {
    if (props.highlightFeature) {
      const data = {
        code: props.highlightFeature.code,
        accepted_by_user: false,
        seen_by_user: true,
      }
      props.highlightFeatureSeen(data).finally(() => {
        setIsShown(true)
      })
      const ampiEvent = 'Clicked_Highlight_Pop_Up'
      amp(props, ampiEvent)
    }
  }, [props.highlightFeature])

  return (
    isShown &&
    tourList !== 'none' && (
      <TourProviderUI
        modalRef={modalRef.current}
        open={isShown}
        setOpen={setIsShown}
        content={content}
        pathname={pathname}
        useCaseList={useCaseList}
        crossClicked={crossClicked}
        popperRef={popperRef}
        popperPosition={popperPosition}
        arrowRef={arrowRef}
        setArrowRef={setArrowRef}
        clickedOK={clickedOK}
      />
    )
  )
}

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    highlightFeature: state.highlightFeature.highlightList,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    highlightFeatureSeen: (data) => dispatch(actions.putHighlightFeature(data)),
    highlightFeatureFetch: () =>
      dispatch(actions.fetchListOfHighlightFeatures()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TourProvider),
)
