import { Button, Tooltip, Box } from '@mui/material'

const ImageGenerateButton = ({
  buttonName,
  monthlyLimitExceeded,
  generateImage,
  WhiteAIPackagingDesign,
  tags,
}) => (
  <Box sx={{ display: 'flex' }}>
    <Tooltip title={monthlyLimitExceeded && 'Monthly Limit Exceeded'}>
      <span>
        <Button
          variant="contained"
          onClick={generateImage}
          disabled={tags.length < 3 || monthlyLimitExceeded}
          sx={{ height: '35px' }}
        >
          <img
            src={WhiteAIPackagingDesign}
            height={18}
            width={18}
            style={{
              marginRight: '8px',
              boxSizing: 'content-box',
            }}
          />
          {buttonName}
        </Button>
      </span>
    </Tooltip>
  </Box>
)

export default ImageGenerateButton
