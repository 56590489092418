import React, { useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../services/networking/swAxios'
import { screeningStatus as swStatus } from '../../const'

import { fetchConcepts } from '../../store/actions/screenWinnerActions'

import NetworkUtil from '../../services/networking/SWNetworkingUtil'

import SWScreenedResults from '../../components/SWScreenedResults/SWScreenedResults'

const SWScreenedResultsPage = ({
  match,
  conceptsList,
  fetchConcepts,
  screenWinnerSubscription,
  projectDetails,
}) => {
  const history = useHistory()

  useEffect(() => {
    if (conceptsList.length) {
      const screenedConcepts = conceptsList.filter(
        (cncpt) => cncpt.screeningStatus === swStatus.SCREENED,
      )
      if (!screenedConcepts.length) {
        history.push('/screen-winner')
      }
    }
  }, [conceptsList])

  const { projectId } = match.params
  const handleDownloadResults = () => {
    axios
      .get(NetworkUtil.getResultsInExcel(projectId), {
        responseType: 'blob',
      })
      .then(({ data }) => {
        window.saveAs(data, 'Screened results')
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (projectId && screenWinnerSubscription) {
      fetchConcepts(projectId)
    }
  }, [screenWinnerSubscription])

  return (
    <SWScreenedResults
      conceptsList={conceptsList}
      downloadResults={handleDownloadResults}
      projectDetails={projectDetails}
    />
  )
}

const mapStateToProps = (state) => {
  const { projectDetails, conceptsList, subscribed } = state.screenWinner
  return {
    screenWinnerSubscription: subscribed,
    projectDetails,
    conceptsList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConcepts: (projectId) => dispatch(fetchConcepts(projectId)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SWScreenedResultsPage),
)
