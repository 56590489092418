import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter,useHistory } from "react-router-dom";
import { Box } from '@mui/system';
import { Typography, Link } from '@mui/material';
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import DriverItemContainer from './styles';

const DriverItem = ({
  categoryPreviewNeed, title, icon, percent,urllens, projectid, needid, productid, handleClick
}) => {
  const history = useHistory();
  const buildLink = (categoryPreviewNeed,projectid,urllens,productid,needid) => {
    if (categoryPreviewNeed)
      history.push(`/mui/details/${projectid}/category/${needid}`)
    else
      history.push(`/mui/details/${projectid}/${urllens}/${productid}/need/${needid}`)
    return false;
  }
  return (
    <Link
      sx={{textDecoration: 'none'}}
      href='#'
      onClick={() => buildLink(categoryPreviewNeed,projectid,urllens,productid,needid)}>
      <DriverItemContainer variant="outlined">
        <Box>
          <img src={icon} alt={title} style={{ maxWidth: '7.5%'}}/>
          <Typography
            variant="subtitle1"
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <LinearProgressWithLabel value={percent} />
        </Box>
      </DriverItemContainer>
    </Link>
  )};

DriverItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  percent: PropTypes.number.isRequired,
};

export default withRouter(
  connect(
    null,
    null
  )(DriverItem));
