import React, { useState, useEffect } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js'

import 'draft-js/dist/Draft.css'
import './WysiwygEditor.scss'

const getInitialEditorState = (value, raw) => {
  if (raw) {
    return EditorState.createWithContent(convertFromRaw(value))
  }
  if (value) {
    return EditorState.createWithContent(ContentState.createFromText(value))
  }
  return EditorState.createEmpty()
}

const UL_STYLE = 'unordered-list-item'
const OL_STYLE = 'ordered-list-item'

const WysiwygEditor = ({
  rteOn = false,
  initialValue = '',
  initialRawValue,
  placeholder = '',
  handleOnChange,
  handlePlainTextChange,
  handleRawTextChange,
  errorMessage = '',
  revalidate,
  required,
  revalidateCallback,
  readOnly = false,
}) => {
  const [editorState, setEditorState] = useState(() => {
    if (initialRawValue)
      return getInitialEditorState(JSON.parse(initialRawValue), true)
    return getInitialEditorState(initialValue)
  })
  const [listStyle, setListStyle] = useState('')
  const [error, setError] = useState(false)
  const [active, setActive] = useState(false)

  const handleBlur = () => {
    setActive(false)
    errorCheck()
  }

  const handleFocus = () => {
    setActive(true)
  }

  const errorCheck = () => {
    if (required) {
      if (!getValue(editorState)) {
        setError(true)
      } else {
        setError(false)
      }
    }
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  function blockStyleFn(contentBlock) {
    switch (contentBlock.getType()) {
      case 'blockquote':
        return ''
      case UL_STYLE:
        return 'sw-wysiwyg-editor__ul'
      case OL_STYLE:
        return 'sw-wysiwyg-editor__ol'
      default:
        return ''
    }
  }

  const getValue = (editorState) => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks
    const value = blocks
      .map((block) => (!block.text.trim() && '\n') || block.text)
      .join('\n')
    return value === '\n' ? '' : value
  }

  const toggleUList = () => {
    setEditorState((editorState) =>
      RichUtils.toggleBlockType(editorState, UL_STYLE),
    )
  }

  const toggleOList = () => {
    setEditorState((editorState) =>
      RichUtils.toggleBlockType(editorState, OL_STYLE),
    )
  }

  useEffect(() => {
    if (handleOnChange) {
      handleOnChange(getValue(editorState))
    }
    handlePlainTextChange &&
      handlePlainTextChange(
        editorState.getCurrentContent().getPlainText('\u0001'),
      )
    handleRawTextChange &&
      handleRawTextChange(
        JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      )
    if (RichUtils.getCurrentBlockType(editorState) === UL_STYLE) {
      setListStyle(UL_STYLE)
    } else if (RichUtils.getCurrentBlockType(editorState) === OL_STYLE) {
      setListStyle(OL_STYLE)
    } else {
      setListStyle('')
    }
    if (error) {
      errorCheck()
    }
  }, [editorState])

  useEffect(() => {
    if (revalidate) {
      errorCheck()
    }
  }, [revalidate])

  useEffect(() => {
    if (error && revalidate) {
      revalidateCallback && revalidateCallback()
    }
  }, [error])

  const cls = `${error ? ' sw-input--error' : ''}${
    active ? ' sw-wysiwyg-editor--active' : ''
  }`

  return (
    <>
      <div
        className={`sw-wysiwyg-editor__block${
          readOnly ? ' sw-wysiwyg-editor--read-only' : cls
        }`}
      >
        {rteOn && !readOnly && (
          <div className="sw-wysiwyg-editor__btns">
            <button
              className={`sw-wysiwyg-editor__num-list${
                listStyle === OL_STYLE
                  ? ' sw-wysiwyg-editor__num-list--active'
                  : ''
              }`}
              type="button"
              onClick={toggleOList}
            ></button>
            <button
              className={`sw-wysiwyg-editor__bul-list${
                listStyle === UL_STYLE
                  ? ' sw-wysiwyg-editor__bul-list--active'
                  : ''
              }`}
              type="button"
              onClick={toggleUList}
            ></button>
          </div>
        )}
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          blockStyleFn={blockStyleFn}
          onFocus={handleFocus}
          // handleKeyCommand={handleKeyCommand}
          placeholder={placeholder}
          tabIndex="0"
          onBlur={handleBlur}
          readOnly={readOnly}
        />
      </div>
      {error && errorMessage && (
        <span className="sw-error para--12">{errorMessage}</span>
      )}
    </>
  )
}

export default WysiwygEditor
