import * as React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { currencies } from '../../../shared/utils/constants'
import imagePlaceholder from '../../../assets/product-placeholder.png'
import { amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import BackdropWithCheckbox from '../MarkIrrelevant/BackdropWithCheckbox'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {}
    switch (ampiEvent) {
      case 'Clicked_Pairing_FSP':
        const ampiInstance = new AmplitudeValues()
        amplitudeUserData = {
          ...amplitudeUserData,
          User: ampiInstance.email,
          Lens: ampiUserData.lens,
          Trend_Name: ampiUserData.trendName,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Maturity_Phase: ampiUserData.maturityPhase,
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          User: ampiUserData.email,
          Product_Title: ampiUserData.title,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Food Service Card')
  }
}

const FoodServiceCard = ({
  title,
  description,
  price,
  currency,
  image,
  restaurantName,
  url,
  email,
  lens,
  isTrendChecked,
  country,
  category,
  type,
  logFoodServiceClicked,
  isBackdropOpen,
  esId,
  handleEsDocSelection,
  selectedIrrelevantDocs,
}) => {
  const foodServiceCardClickHandler = (url) => {
    if (isBackdropOpen === true) {
      return
    }
    window.open(url, '_blank')
    if (type === 'searchResult') {
      logFoodServiceClicked(title)
    } else {
      if (lens) {
        const ampiEvent = 'Clicked_Pairing_FSP'
        const ampiUserData = {
          lens: lens,
          trendName: title,
          country,
          category,
          maturityPhase: isTrendChecked,
        }
        amp(ampiEvent, ampiUserData)
      } else {
        const ampiEvent = 'Clicked_FSP'
        const ampiUserData = {
          email,
          title,
        }
        amp(ampiEvent, ampiUserData)
      }
    }
  }

  const currencySymbol = currencies[currency ? currency.toUpperCase() : '']
  const fsCardContent = (
    <>
      {/*
    <CardMedia
        component="img"
        height="140"
        image={image || imagePlaceholder}
        alt={title}
      /> */}
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {/* {!!Number(price) && (
          <Typography variant="caption">
            {currencySymbol || (currency ? currency.toUpperCase() : '')}
            &nbsp;
            {Number(price).toFixed(2)}
          </Typography>
        )} */}
        <Typography variant="subtitle3" sx={{ color: 'primary.main' }}>
          {title}
        </Typography>
        <Typography variant="caption">{restaurantName}</Typography>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </CardContent>
    </>
  )
  return url ? (
    <Card
      variant="outlined"
      onClick={() => foodServiceCardClickHandler(url)}
      sx={{
        height: '100%',
        boxShadow: 'none !important',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          border: 1,
          borderColor: 'primary.main',
        },
        '&:active': {
          bgcolor: 'grey.100',
        },
      }}
    >
      {fsCardContent}
      <BackdropWithCheckbox
        isOpen={isBackdropOpen}
        selectedIrrelevantDocs={selectedIrrelevantDocs}
        esId={esId}
        handleEsDocSelection={handleEsDocSelection}
      />
    </Card>
  ) : (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        position: 'relative',
        boxShadow: 'none !important',
      }}
    >
      {fsCardContent}
      <BackdropWithCheckbox
        isOpen={isBackdropOpen}
        esId={esId}
        selectedIrrelevantDocs={selectedIrrelevantDocs}
        handleEsDocSelection={handleEsDocSelection}
      />
    </Card>
  )
}

FoodServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  restaurantName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default FoodServiceCard
