import React, { useEffect, useState, useContext, useRef } from 'react'
import Grid from '@mui/material/Grid'
import SearchBarWithButton from './search-bar'
import { DataContext } from '../../../contexts/data-context'
import Box from '@mui/material/Box'
import {
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Popover,
  Tab,
} from '@mui/material'
import BackdropLoader from '../../generic-components/partials/BackdropLoader'
import SaveConceptModal from '../../generic-components/SaveConceptModal'
import ImageGenerateButton from '../../generic-components/ImageGenerateButton'
import TextField from '@mui/material/TextField'
import RightArrowCustom from '../../../assets/icons/arrow-thin-right-icon.svg'
import SaveIconCustom from './../../../assets/icons/save-concept.svg'
import { ReactComponent as CopyIcon } from './../../../assets/icons/copy.svg'
import WhiteAIPackagingDesign from '../../../../assets/icons/ai-packaging-design-white.svg'
import MonthlyCountIcon from '../../../../assets/icons/monthly-count-icon.svg'
import ImageDownload from '../../../../assets/icons/concept-genie-download-icon.svg'
import SingleSelect from '../../../../shared/components/SingleSelect'
import refreshIcon from './../../../assets/icons/refresh-cg.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import FeedbackButtons from './feedback_buttons'
import MarketingComponent from '../components/marketingComponent'
import RequestLimitExceeded from '../components/requestLimitExceeded'
import ErrorStateConceptGenie from '../components/errorStateComponent'
import { useSingleSnackBar } from '../../../../hooks'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExcludeKeyword from './ExcludeKeyword'
import ModifyResult from './ModifyResult'
import {
  BlueCaptionHiglight,
  MonthlyLimitTypography,
  NoteCaption,
  ImageTypeTabsContainer,
  SingleSelectInput,
} from './styles'
import { getAdvancedHighlightingText } from './../../../../shared/utils/highlightText'
import { getBuildWinnerURL, getHeaders } from '../../../network/utility'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { saveAs } from 'file-saver'

import { amplify } from '../../../../store/utility'
import { AmplitudeValues } from '../../../../store/amplitudeValues'
import copyIcon from '../../../../assets/icons/copyIcon.svg'
import { SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB } from '../../../../../src/shared/utils/constants'

const defaultConceptText =
  'Product concept: Fruit Fusion Smoothie Mix\n\nDescription: Our Fruit Fusion Smoothie Mix is a delightful blend of apple, mango, and banana, offering a perfect combination of sweet and tangy flavors. This convenient and versatile mix allows you to create delicious and healthy smoothies with a tropical twist.\n\nClaims:\n- Made with real fruit: Our Fruit Fusion Smoothie Mix is made using real, high-quality apple, mango, and banana, ensuring you get the authentic taste of these fruits in every sip.\n- Rich in vitamins and minerals: Packed with essential vitamins and minerals, this smoothie mix provides a nutritious boost to your daily diet.\n- Easy and convenient: Simply add the smoothie mix to your favorite liquid base and blend for a refreshing drink in minutes.\n- Versatile usage: Apart from smoothies, our Fruit Fusion Smoothie Mix can be used to make sorbets, popsicles, or as a flavor enhancer for yogurt bowls and granola.\n- No added sugars or artificial flavors: We believe in keeping things natural, so our smoothie mix is free from added sugars and artificial flavors, making it a guilt-free choice for a wholesome snack or meal.\n- Suitable for all dietary preferences: Whether you follow a vegan, vegetarian, or gluten-free diet, our Fruit Fusion Smoothie Mix is suitable for everyone to enjoy.\n\nNote: This is a fictional product concept created for illustration purposes only.'

const CONCEPT_TAB = 'Text'
const AI_IMAGE_TAB = 'Image'

const PACKAGE = 'Package'
const FOOD_SERVICE = 'Food Service'

const imageGenerateOptions = [
  {
    value: PACKAGE,
    label: PACKAGE,
  },
  {
    value: FOOD_SERVICE,
    label: FOOD_SERVICE,
  },
]

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
    }

    switch (ampiEvent) {
      case 'Clicked_Generate_Image_From_Concept_Genie':
        amplitudeUserData = {
          ...amplitudeUserData,

          Country_Name: ampiUserData.concept.country,
          Category_Name: ampiUserData.concept.category,
          Keywords: ampiUserData.Keywords,
        }
        break
      case 'Clicked_Regenerate_Image_From_Concept_Genie':
        amplitudeUserData = {
          ...amplitudeUserData,

          Country_Name: ampiUserData.concept.country,
          Category_Name: ampiUserData.concept.category,
          Keywords: ampiUserData.Keywords,
        }
        break
      case 'Clicked_Generate_Image_From_Image_Packaging':
        amplitudeUserData = {
          ...amplitudeUserData,

          Country_Name: ampiUserData.concept.country,
          Category_Name: ampiUserData.concept.category,
          Image_Prompt: ampiUserData.Image_Prompt,
        }
        break
      default:
        break
    }

    amplify(ampiEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Image Generation')
  }
}

const NewConcept = (props) => {
  const {
    tags,
    setTags,
    newConceptData,
    setNewConceptData,
    generatedConceptText,
    setGeneratedConceptText,
    concept,
    setConcept,
    hoveredCard,
    sendModifyData,
    excludeTags,
    projectSelected,
    handleReset,
    input,
    setInput,
    logRegenerateClicked,
    logExcludeKeywordAdded,
    fetchUserConceptlimit,
    fetchUserMonthlylimit,
    monthlyUserRequestCount,
    monthlyUserRequestLimit,
    currentUserRequestCount,
    setCurrentUserRequestCount,
    setMonthlyUserRequestCount,
    setMonthlyUserRequestLimit,
    currentUserRequestLimit,
    setCurrentUserRequestLimit,
    handleClick,
    imageUrl,
    imgTabImageUrl,
    onButtonClick,
    setHoveredCard,
    setExcludeTags,
    projectVariant,
    generativeAIDropdownOption,
    getImage,
  } = props

  const [conceptTextImgUrl, setconceptTextImgUrl] = useState(imageUrl ?? '')
  const [imagePkgImgUrl, setImgPkgImgUrl] = useState(imgTabImageUrl ?? '')

  useEffect(() => {
    if (imageUrl) setconceptTextImgUrl(imageUrl)
  }, [imageUrl])

  useEffect(() => {
    if (imgTabImageUrl) setImgPkgImgUrl(imgTabImageUrl)
  }, [imgTabImageUrl])

  const Resolutions = ['1024x1024', '1024x1792', '1792x1024']
  const TitleComponent = styled(Typography)(({ theme }) => ({
    fontSize: 44,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 600,
  }))
  const LimitNumberContainer = styled(Typography)(({ theme }) => ({
    background: theme.palette.colors.greyBackground,
    padding: '4px 8px',
    borderRadius: '4px',
    color: theme.palette.black,
    fontSize: 12,
    display: 'inline-block',
    marginLeft: '4px',
  }))
  const SubTitleComponent = styled(Typography)(({ theme }) => ({
    fontSize: '26px',
    textAlign: 'center',
    color: theme.palette.black,
    margin: '20px 0px',
    fontWeight: 400,
  }))

  const ErrorComponent = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
  }))
  const context = useContext(DataContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = useState(context.data.isLoading)
  const [showConceptModal, setShowConceptModal] = useState(false)
  const [errors, setErrors] = useState(null)
  const [conceptLimitExceeded, setConceptLimitExceeded] = useState(false)
  const [monthlyLimitExceeded, setMonthlyLimitExceeded] = useState(false)
  const [isAccordianExpanded, setIsAccordianExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentEditingText, setCurrentEditingText] = useState(
    generatedConceptText || '',
  )
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const [regenerateConcept, setRegenerateConcept] = useState(false)
  const [toneSelected, setToneSelected] = useState('DEFAULT')
  const [imageGenerated, setImageGenerated] = useState(false)
  const [prompt, setPrompt] = useState({})
  const [size, setSize] = useState('Select Resolution')
  const [conceptPrompt, setConceptPrompt] = useState('')
  const [imgPrompt, setImgPrompt] = useState('')
  const [regenerateImage, setRegenerateImage] = useState(false)
  const { heading, subHeading } = props
  const [selectedSize, setSelectedSize] = useState('Select Resolution')
  const [showOverlayLoader, setShowOverlayLoader] = useState(false)
  const [imageData, setImageData] = useState(null)
  const imageRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [generateImageDropdown, setGenerateImageDropdown] = useState(PACKAGE)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleRegenerate = () => {
    logRegenerateClicked()
    if (!regenerateConcept) {
      setRegenerateConcept(true)
    }
  }
  const handleRegenerateImage = () => {
    if (!regenerateImage) {
      setRegenerateImage(true)
    }

    getImage(
      selectedSize,
      generativeAIDropdownOption === CONCEPT_TAB ? conceptPrompt : imgPrompt,
    )
  }

  const resetImageUrls = () => {
    setconceptTextImgUrl('')
    setImgPkgImgUrl('')
  }

  const refreshState = () => {
    setGeneratedConceptText('')
    setConcept({})
    setTags([])
    props.setIsConceptGenerated(false)
    handleReset()
  }

  const handleHover = (name) => {
    const isPresentInTheme = props.analysedTrends?.theme?.find(
      (theme) => theme?.name?.toLowerCase() === name?.toLowerCase(),
    )
    const isPresentInIng = props.analysedTrends?.ingredient?.find(
      (ing) => ing?.name?.toLowerCase() === name?.toLowerCase(),
    )
    if (isPresentInTheme || isPresentInIng) {
      setHoveredCard({ name })
    }
  }

  const handleAccordianExpansion = () => {
    setIsAccordianExpanded(!isAccordianExpanded)
  }

  const ProductDescription = (data) => {
    const startIndex = data.indexOf('Product concept')
    const endIndex = data.indexOf('Description') - 4
    const desiredPortion = data.substring(startIndex, endIndex)
    setConceptPrompt(desiredPortion)
  }
  const ImagePrompt = (data) => {
    setImgPrompt(data)
  }
  const SelectedSize = (data) => {
    setSize(data)
  }
  const linkRef = useRef()

  const scrollToImage = () => {
    if (imageRef.current)
      imageRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const logGenerateImageFromConceptGenie = () => {
    const ampiEvent = 'Clicked_Generate_Image_From_Concept_Genie'
    const ampiUserData = {
      concept,
      Keywords: tags,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logRegenerateImageFromConceptGenie = () => {
    const ampiEvent = 'Clicked_Regenerate_Image_From_Concept_Genie'
    const ampiUserData = {
      concept,
      Keywords: tags,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logGenerateImageFromImagePackaging = () => {
    const ampiEvent = 'Clicked_Generate_Image_From_Image_Packaging'
    const ampiUserData = {
      concept,
      Image_Prompt: input,
    }
    amp(ampiEvent, ampiUserData)
  }

  const generateImage = (imageState, event) => {
    if (event?.target?.name === SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB) {
      logGenerateImageFromImagePackaging()
      getImage(size, imgPrompt, event)
    } else {
      logGenerateImageFromConceptGenie()
      getImage(size, conceptPrompt, event, generateImageDropdown)
    }
    setImageGenerated(imageState)
    scrollToImage()
  }

  const regenerateConceptImage = (imageState) => {
    logRegenerateImageFromConceptGenie()
    setImageGenerated(imageState)
    getImage(
      size,
      generativeAIDropdownOption === CONCEPT_TAB ? conceptPrompt : imgPrompt,
      null,
      generateImageDropdown,
    )
    scrollToImage()
  }

  useEffect(() => {
    if (props.refreshNewCncptTab) {
      refreshState()
      props.setRefreshNewCncptTab(false)
    }
  }, [props.refreshNewCncptTab])

  useEffect(() => {
    if (tags.length) {
      setConcept(context.data.current_concept)
      setGeneratedConceptText(
        context.data.current_concept &&
          context.data.current_concept.generated_concept,
      )
    }
  }, [context.data.current_concept])

  useEffect(() => {
    props.fetchApi(props.allConceptsUrl)
  }, [])

  useEffect(() => {
    setErrors(context.data.errors)
  }, [context.data.errors && context.data.errors])

  useEffect(() => {
    props.updateConceptStateParentLocal(concept)
  }, [concept])

  const resetShowConceptModal = () => {
    setShowConceptModal(false)
  }

  const handleGeneratedConceptChange = (value) => {
    setCurrentEditingText(value)
  }

  const handleEditingSave = () => {
    setGeneratedConceptText(currentEditingText)
    setIsEditing(false)
  }

  const handleDownload = async (downloadUrl) => {
    try {
      setShowOverlayLoader(true)
      const endPointUrl = `${getBuildWinnerURL(
        'concepts/download_image?imageUrl=',
      )}${encodeURIComponent(downloadUrl)}`
      const response = await fetch(
        endPointUrl,
        getHeaders({}, 'GET', 'image/png'),
      )
      if (!response.ok) {
        const jsonResponse = await response.json()
        throw new Error(jsonResponse.detail)
      }
      const blob = await response.blob()
      saveAs(blob, 'ConceptGenie_Image.png')
      setShowOverlayLoader(false)
    } catch (error) {
      setShowOverlayLoader(false)
      showSnackBar(error.message, 'error')
    }
  }

  const handleEditingCancel = () => {
    setIsEditing(false)
    setCurrentEditingText(generatedConceptText || '')
  }

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  const handleSizeChange = (val) => {
    if (!val) {
      return
    }
    setSelectedSize(val)
  }
  const handleHighlighting = (generatedConceptText = defaultConceptText) => {
    const textToBold = ['Product concept:', 'Description:', 'Claims:']
    const analysedTrendsIng =
      props.analysedTrends?.ingredient?.map((data) =>
        data.name.toLowerCase(),
      ) || []
    const analysedTrendsTheme =
      props.analysedTrends?.theme?.map((data) => data.name.toLowerCase()) || []
    let analysedTrends = [...analysedTrendsIng, ...analysedTrendsTheme]

    if (analysedTrends.includes(hoveredCard?.name?.toLowerCase())) {
      analysedTrends = analysedTrends.filter(
        (trend) => trend.toLowerCase() !== hoveredCard?.name.toLowerCase(),
      )
    }
    analysedTrends = analysedTrends.filter(
      (el) =>
        !excludeTags.map((tag) => tag.toLowerCase()).includes(el.toLowerCase()),
    )

    const highlightedText = getAdvancedHighlightingText(
      generatedConceptText,
      [
        { arr: textToBold, type: 'bold' },
        { arr: analysedTrends, type: 'blue' },
        { arr: excludeTags, type: 'strikeThrough' },
        {
          arr: hoveredCard?.name ? [hoveredCard.name.toLowerCase()] : [],
          type: 'border',
        },
      ],
      handleClick,
      handleHover,
    )
    return highlightedText
  }

  useEffect(() => {
    setCurrentEditingText(generatedConceptText)
  }, [generatedConceptText])

  const copyToClipboard = () => {
    const content = newConceptData.generated_concept
    navigator.clipboard
      .writeText(content)
      .then(() => setOpen(true))
      .catch((err) => console.error('Failed to copy:', err))
  }

  const handleCloseCopy = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      {showOverlayLoader && (
        <BackdropLoader
          loading={showOverlayLoader}
          shouldRenderText={false}
          backdropBackground={'transparent'}
        />
      )}
      {(monthlyLimitExceeded && generativeAIDropdownOption === AI_IMAGE_TAB) ||
      (conceptLimitExceeded && generativeAIDropdownOption === CONCEPT_TAB) ? (
        <Grid item xs={12}>
          <RequestLimitExceeded />
        </Grid>
      ) : errors && errors.length ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <>
              <ErrorStateConceptGenie />
              {/* <Button onClick={() => refreshState()}>Reload</Button> */}
            </>
          </Box>
        </>
      ) : (
        <>
          {/* search bar  and generate button */}
          <Box
            sx={{
              width: '100%',
            }}
          >
            <SearchBarWithButton
              imageUrl={imageUrl}
              imagePkgImgUrl={imagePkgImgUrl}
              setConceptLimitExceeded={setConceptLimitExceeded}
              setMonthlyLimitExceeded={setMonthlyLimitExceeded}
              logGenerateConceptClicked={props.logGenerateConceptClicked}
              tags={tags}
              setInput={setInput}
              setTags={setTags}
              input={input}
              resetImageUrls={resetImageUrls}
              promptImage={ImagePrompt}
              resolutionSelected={SelectedSize}
              promptConcept={ProductDescription}
              onConceptImageGenerated={generateImage}
              isImageGenerated={generateImage}
              generativeAIDropdownOption={generativeAIDropdownOption}
              analysedTrends={props.analysedTrends}
              setAnalysedTrends={props.setAnalysedTrends}
              excludeTags={props.excludeTags}
              data={newConceptData}
              setData={setNewConceptData}
              includeError={props.includeError}
              setIncludeError={props.setIncludeError}
              handleReset={props.handleReset}
              projectSelected={projectSelected}
              regenerateConcept={regenerateConcept}
              setRegenerateConcept={setRegenerateConcept}
              monthlyUserRequestCount={monthlyUserRequestCount}
              currentUserRequestCount={currentUserRequestCount}
              setCurrentUserRequestCount={setCurrentUserRequestCount}
              setMonthlyUserRequestCount={setMonthlyUserRequestCount}
              currentUserRequestLimit={currentUserRequestLimit}
              monthlyUserRequestLimit={monthlyUserRequestLimit}
              setCurrentUserRequestLimit={setCurrentUserRequestLimit}
              setMonthlyUserRequestLimit={setMonthlyUserRequestLimit}
              fetchUserConceptlimit={fetchUserConceptlimit}
              fetchUserMonthlylimit={fetchUserMonthlylimit}
              setIsAccordianExpanded={setIsAccordianExpanded}
              setExcludeTags={setExcludeTags}
              projectVariant={projectVariant}
            />
            {generativeAIDropdownOption === CONCEPT_TAB ? (
              <Accordion
                sx={{
                  margin: '0 !important',
                  '& .Mui-expanded': { margin: '0 !important' },
                }}
                expanded={isAccordianExpanded}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: ({ palette }) => palette.black }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={handleAccordianExpansion}
                  sx={{
                    height: '35px',
                    minHeight: '30px !important',
                    '& .Mui-expanded': { minHeight: '30px' },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Exclude keywords (Optional){' '}
                    {isAccordianExpanded && (
                      <NoteCaption pl={1}>
                        Hit <BlueCaptionHiglight>enter</BlueCaptionHiglight> or{' '}
                        <BlueCaptionHiglight>
                          {' '}
                          click on exclude
                        </BlueCaptionHiglight>{' '}
                        to add a keyword.
                      </NoteCaption>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ExcludeKeyword
                    generativeAIDropdownOption={generativeAIDropdownOption}
                    excludeTags={props.excludeTags}
                    setExcludeInput={props.setExcludeInput}
                    setExcludeTags={setExcludeTags}
                    excludeInput={props.excludeInput}
                    excludeError={props.excludeError}
                    setExcludeError={props.setExcludeError}
                    tags={tags}
                    logExcludeKeywordAdded={logExcludeKeywordAdded}
                    isConceptPresent={!_.isEmpty(concept)}
                    concept={concept}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '3vh',
            }}
          ></Box>
          {generativeAIDropdownOption === CONCEPT_TAB && !_.isEmpty(concept) ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                  <ModifyResult
                    sendModifyData={sendModifyData}
                    setToneSelected={setToneSelected}
                    toneSelected={toneSelected}
                  />
                  <Button
                    variant="contained"
                    onClick={handleRegenerate}
                    disabled={tags.length < 3}
                    sx={{ height: '35px', ml: 1 }}
                  >
                    <img
                      src={refreshIcon}
                      height={18}
                      width={18}
                      style={{
                        marginRight: '8px',
                        boxSizing: 'content-box',
                      }}
                    />
                    Regenerate
                  </Button>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Button style={{ padding: '0px', marginRight: '-10px' }}>
                    <img
                      onClick={copyToClipboard}
                      src={copyIcon}
                      height={36}
                      width={36}
                    />
                  </Button>

                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleCloseCopy}
                    message="Copied to Clipboard!"
                    action={action}
                  />

                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        padding: '8px 16px',
                        ml: 1,
                        color: ({ palette }) => palette.primary.main,
                        border: ({ palette }) =>
                          `1px solid ${palette.primary.main}`,
                      }}
                      onClick={() => setShowConceptModal(true)}
                    >
                      <img
                        src={SaveIconCustom}
                        height={18}
                        width={18}
                        style={{
                          marginRight: '8px',
                          boxSizing: 'content-box',
                        }}
                      />
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                {generativeAIDropdownOption === CONCEPT_TAB &&
                  (isEditing ? (
                    <>
                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1,
                          }}
                        >
                          <IconButton
                            onClick={handleEditingCancel}
                            aria-label="edit-save"
                            sx={{
                              background: '#DD0017',
                              borderRadius: '4px',
                              height: '22px',
                              width: '22px',
                              ':hover': {
                                background: '#DD0017',
                              },
                            }}
                          >
                            <CloseRoundedIcon sx={{ color: 'white' }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleEditingSave()
                            }}
                            aria-label="edit-remove"
                            sx={{
                              background: '#1B9874',
                              borderRadius: '4px',
                              height: '22px',
                              width: '22px',
                              ml: 1,
                              ':hover': {
                                background: '#1B9874',
                              },
                            }}
                          >
                            {' '}
                            <DoneRoundedIcon sx={{ color: 'white' }} />
                          </IconButton>
                        </Box>
                        <TextField
                          fullWidth
                          value={currentEditingText || ''}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={RightArrowCustom}
                                  height={16}
                                  width={16}
                                  style={{ marginTop: 28 }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          multiline
                          onChange={(e) => {
                            handleGeneratedConceptChange(e.target.value)
                          }}
                          id="fullWidth"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              alignItems: 'flex-start',
                              border: ({ palette }) =>
                                `1px solid ${palette.divider}`,
                              '&:hover': {
                                border: ({ palette }) =>
                                  `1px solid ${palette.secondary.main}`,
                              },
                              '&:focus-within': {
                                border: ({ palette }) =>
                                  `1px solid ${palette.primary.main}`,
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none !important',
                              },
                            },
                          }}
                        ></TextField>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #E8E8E8',
                        background: 'white',
                        p: 2,
                        position: 'relative',
                        maxHeight: '500px',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },

                        '&::-webkit-scrollbar-track': {
                          backgroundColor: ({ palette }) => palette.grey[100],
                          borderRadius: 8,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: ({ palette }) => palette.grey[500],
                          borderRadius: 8,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          mr: 1,
                          display: 'flex',
                          alignItems: 'center',
                          color: ({ palette }) => palette.primary.main,
                          gap: 0.5,
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                        }}
                        onClick={() => {
                          setIsEditing(true)
                        }}
                      >
                        <EditOutlinedIcon
                          sx={{
                            color: ({ palette }) =>
                              `${palette.primary.main} !important`,
                          }}
                          fontSize="medium"
                        />{' '}
                        <Typography color={'inherit'}>Edit</Typography>
                      </Box>
                      {handleHighlighting(generatedConceptText) || ''}
                    </Box>
                  ))}
              </Box>
            </>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              flexDirection: 'row',
            }}
          >
            {generativeAIDropdownOption === CONCEPT_TAB && !_.isEmpty(concept) && (
              <Box>
                <Grid sx={{ paddingTop: '6px' }}>
                  <FeedbackButtons
                    id={concept && concept.id}
                    concept={concept}
                    logConceptFeedback={props.logConceptFeedback}
                  />
                </Grid>
              </Box>
            )}

            <Box style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              {generativeAIDropdownOption === CONCEPT_TAB &&
                !monthlyLimitExceeded &&
                projectVariant == 'food' &&
                !_.isEmpty(concept) && (
                  <>
                    <ImageTypeTabsContainer
                      id="Image Select Box"
                      value={generateImageDropdown}
                      onChange={(e, newValue) => {
                        setGenerateImageDropdown(newValue)
                      }}
                    >
                      {imageGenerateOptions.map((option) => {
                        return (
                          <Tab
                            name={option.label}
                            sx={{
                              height: '34px',
                              borderRadius: '5px',
                              width: '120px',
                            }}
                            label={option.label}
                            value={option.value}
                          />
                        )
                      })}
                    </ImageTypeTabsContainer>
                    <SingleSelectInput>
                      <SingleSelect
                        variant="outlined"
                        placeholder="Select Resolution"
                        onChange={(e, newVal) => handleSizeChange(newVal)}
                        value={selectedSize}
                        options={Resolutions}
                      ></SingleSelect>
                    </SingleSelectInput>
                  </>
                )}
              <Box>
                {generativeAIDropdownOption === CONCEPT_TAB &&
                !_.isEmpty(concept) ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {conceptTextImgUrl === '' ? (
                      <ImageGenerateButton
                        buttonName={'Generate Image'}
                        monthlyLimitExceeded={monthlyLimitExceeded}
                        generateImage={generateImage}
                        WhiteAIPackagingDesign={WhiteAIPackagingDesign}
                        tags={tags}
                      />
                    ) : (
                      <ImageGenerateButton
                        buttonName={'Regenerate Image'}
                        monthlyLimitExceeded={monthlyLimitExceeded}
                        generateImage={generateImage}
                        WhiteAIPackagingDesign={WhiteAIPackagingDesign}
                        tags={tags}
                      />
                    )}
                  </Box>
                ) : null}
                {generativeAIDropdownOption === CONCEPT_TAB &&
                  !_.isEmpty(concept) && (
                    <MonthlyLimitTypography>
                      Month's Limit:
                      <LimitNumberContainer>
                        {monthlyUserRequestCount}/{monthlyUserRequestLimit}
                      </LimitNumberContainer>
                    </MonthlyLimitTypography>
                  )}
              </Box>
            </Box>
          </Box>
          {generativeAIDropdownOption === CONCEPT_TAB &&
          !_.isEmpty(concept) &&
          imageGenerated &&
          conceptTextImgUrl ? (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box style={{ position: 'relative', display: 'inline-block' }}>
                <img
                  ref={imageRef}
                  id="download-image"
                  src={conceptTextImgUrl}
                  alt="Generated Image"
                  onLoad={scrollToImage}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '80vh',
                    boxSizing: 'content-box',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 0px 10px 0px',
                  }}
                />
                <Button
                  onClick={() => {
                    handleDownload(conceptTextImgUrl)
                  }}
                  style={{
                    zIndex: 4,
                    position: 'absolute',
                    transform: 'translate(-100%, 20%)',
                    padding: '16px 0px 10px 0px',
                  }}
                >
                  <img src={ImageDownload} alt="Generated Image" />
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              ></Box>
            </Box>
          ) : null}

          {generativeAIDropdownOption === AI_IMAGE_TAB &&
          imageGenerated &&
          imagePkgImgUrl !== '' ? (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box style={{ position: 'relative', display: 'inline-block' }}>
                <img
                  ref={imageRef}
                  onLoad={scrollToImage}
                  id="download-image"
                  src={imagePkgImgUrl}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '80vh',
                    boxSizing: 'content-box',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 0px 10px 0px',
                  }}
                />
                <Button
                  onClick={() => {
                    handleDownload(imagePkgImgUrl)
                  }}
                  style={{
                    zIndex: 4,
                    position: 'absolute',
                    transform: 'translate(-100%, 20%)',
                    padding: '16px 0px 10px 0px',
                  }}
                >
                  <img src={ImageDownload} alt="Generated Image" />
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              ></Box>
            </Box>
          ) : null}
          {showConceptModal ? (
            <>
              <SaveConceptModal
                setGeneratedConceptText={setGeneratedConceptText}
                active={showConceptModal}
                conceptDetails={concept}
                closeConceptModal={resetShowConceptModal}
                updatedConceptText={generatedConceptText}
                logConceptSaved={props.logConceptSaved}
                data={props.data}
                setData={props.setData}
                allConceptsUrl={props.allConceptsUrl}
                fetchApi={props.fetchApi}
                setConcept={setConcept}
                refreshState={refreshState}
                setTags={setTags}
              />
            </>
          ) : null}
        </>
      )}
    </React.Fragment>
  )
}

export default NewConcept
