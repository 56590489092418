import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import palette from '../palette';
import typography from '../typography';

const LinkBehavior = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export default {
  defaultProps: {
    component: LinkBehavior,
  },
  styleOverrides: {
    root: {
      ...typography.subtitle2,
      fontFamily: typography.fontFamily,
      color: palette.secondary.main,
      underline: 'always',
      textDecorationColor: palette.secondary.main,
      '&:hover': {
        color: palette.secondary.light,
      },
      underlineHover: {
        color: palette.secondary.light,
      },
      '&:active': {
        color: palette.secondary.dark,
        underline: palette.secondary.dark,
      },
    },
  },
  variants: [
    {
      props: { variant: 'black' },
      style: {
        ...typography.subtitle2,
        fontFamily: typography.fontFamily,
        color: palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
          color: palette.primary.main,
          textDecoration: 'underline',
        },
        underlineHover: {
          color: palette.primary.main,
        },
        '&:active': {
          color: palette.info.dark,
          textDecoration: 'underline',
        },
      },
    },
    {
      props: { variant: 'breadcrumbsLink' },
      style: {
        ...typography.subtitle2,
        fontFamily: typography.fontFamily,
        color: palette.text.secondary,
        textDecoration: 'none',
        '&:hover': {
          color: palette.text.secondary,
          textDecoration: 'underline',
        },
        underlineHover: {
          color: palette.text.secondary,
        },
        '&:active': {
          color: palette.primary.dark,
          textDecoration: 'none',
        },
      },
    },
  ],
};
