import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useLoader } from '../../hooks'

import DemographicAnalysisUI from './DemographicAnalysisUI'
import AnalyticsHoverCard from '../RegionalAnalytics/AnalyticsHoverCard'
import DemographicThemePage from './DemographicThemePage'
import SearchNotSelected from '../../components/WhiteSpaceComponent/SearchNotSelected'
import SingleSelect from '../../shared/components/SingleSelect'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'

import NetworkUtil from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/axios'
import { amplify } from '../../store/utility'
import {
  AGE_GROUP_GEN_X,
  AGE_GROUP_GEN_Z,
  AGE_GROUP_MILLENNIALS,
} from '../../shared/utils/constants'
import { ReactComponent as ThemesIcon } from '../../../src/assets/icons/themes.svg'

import { ReactComponent as IngredientsIcon } from '../../../src/assets/icons/ingredients.svg'
import { ReactComponent as ProductsIcon } from '../../../src/assets/icons/products.svg'
import { Tabs, Tab, SvgIcon, Box, Typography, Stack } from '@mui/material'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
      Country_Name: ampiUserData.country || 'NULL',
      Category_Name: ampiUserData.category || 'NULL',
    }
    switch (ampiEvent) {
      case 'Clicked_Demography_Agegroup':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          SubCategory_Name: ampiUserData.subCategoryName,
          Agegroup: ampiUserData.ageGroupTab,
        }
        break
      case 'Clicked_Demography_Graph':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          SubCategory_Name: ampiUserData.subCategoryName,
          Agegroup: ampiUserData.ageGroupTab,
          Selected_View: ampiUserData.viewMode,
        }
        break
      case 'Clicked_Demography_List':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          SubCategory_Name: ampiUserData.subCategoryName,
          Agegroup: ampiUserData.ageGroupTab,
          Selected_View: ampiUserData.viewMode,
        }
        break

      case 'Clicked_Demography_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          SubCategory_Name: ampiUserData.subCategoryName,
          Agegroup: ampiUserData.ageGroupTab,
          Redirected_From: ampiUserData.view,
          Trend_Name: ampiUserData.trendName,
        }
        break
      case 'Clicked_Demography_Dashboard':
        amplitudeUserData = {
          ...amplitudeUserData,
        }
        break
      case 'Clicked_Demography_Filter':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Data_Points: ampiUserData.dataPoints || 'NULL',
          '2 Year CAGR': ampiUserData.twoYearCAGR || 'NULL',
          '4 Year CAGR': ampiUserData.fourYearCAGR || 'NULL',
          Agegroup: ampiUserData.ageGroupTab,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch {
    console.log('Amplitude error in Demography Analysis')
  }
}

function a11yProps(index) {
  return {
    id: `lens-tab-${index}`,
    'aria-controls': `lens-tabpanel-${index}`,
  }
}

const DemographicAnalysis = ({ subscriptionDetails, email }) => {
  const resetBtnId = 'zoom-reset-btn'
  const [demographyDashboards, setdemographyDashboards] = useState([])
  const [ageGroupTab, setAgeGroupTab] = useState('Gen X')
  const [countrySelected, setCountrySelected] = useState('USA')
  const [categorySelected, setCategorySelected] = useState(null)
  const [themeId, setThemeId] = useState('')
  const [themeName, setThemeName] = useState('')
  const [lens, setLens] = useState('Ingredient')
  const [searchValue, setSearchValue] = useState('')
  const [trendGraphDetails, setTrendGraphDetails] = useState(null)
  const [demographicData, setdemographicData] = useState(null)
  const [showLoader, setShowLoader] = useLoader()
  const [viewMode, setViewMode] = useState('chart')
  const [tooltipData, setTooltipData] = useState(false)
  const [trendTooltipData, settrendTooltipData] = useState('')
  const [dataPoint, setDataPoint] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [selectedDataPoints, setSelectedDataPoints] = useState(null)
  const [selected4YearCAGR, setSelected4YearCAGR] = useState(null)
  const [selected2YearCAGR, setSelected2YearCAGR] = useState(null)
  const [resetBtn, setResetBtn] = useState(false)
  const [filteredData, setFilteredData] = useState(null)
  // const [fsProducts, setFsProducts] = useState()
  // const [retailProducts, setRetailProducts] = useState()
  // const [fsProductFormat, setFsProductFormat] = useState()
  // const [retailProductsFormat, setRetailProductsFormat] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const ingredientLens = 'Ingredient'
  const productLens = 'Product'
  const themeLens = 'Theme'
  const ageGroupGenX = AGE_GROUP_GEN_X
  const ageGroupGenZ = AGE_GROUP_GEN_Z
  const ageGroupMillanials = AGE_GROUP_MILLENNIALS

  const logAppliedFilters = (dataPoints, twoYearCAGR, fourYearCAGR) => {
    const ampiEvent = 'Clicked_Demography_Filter'
    const ampiUserData = {
      email,
      lens,
      country: countrySelected,
      category: categorySelected,
      dataPoints,
      twoYearCAGR,
      fourYearCAGR,
      ageGroupTab,
    }
    amp(ampiEvent, ampiUserData)
  }

  const filteredTableData = useMemo(
    () =>
      searchValue !== ''
        ? filteredData?.filter((d) =>
            d.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : filteredData,
    [searchValue, filteredData],
  )

  const tableData = useMemo(
    () =>
      searchValue !== ''
        ? trendGraphDetails.filter((d) =>
            d.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : trendGraphDetails,
    [searchValue, trendGraphDetails],
  )

  const handleFilterClickOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseFilter = () => {
    setAnchorEl(null)
  }

  const history = useHistory()

  const [tab, setTab] = useState(ingredientLens)
  const lensToShow = [
    {
      id: 1,
      name: ingredientLens,
      displayValue: ingredientLens,
      value: ingredientLens,
    },
    { id: 2, name: productLens, displayValue: productLens, value: productLens },
    { id: 3, name: themeLens, displayValue: themeLens, value: themeLens },
  ]

  const tabs = lensToShow.map((lens, i) => {
    let icon = IngredientsIcon
    if (lens.value === ingredientLens) {
      icon = IngredientsIcon
    }
    if (lens.value === productLens) {
      icon = ProductsIcon
    }
    if (lens.value === themeLens) {
      icon = ThemesIcon
    }
    return (
      <Tab
        key={lens.value}
        value={lens.value}
        icon={<SvgIcon component={icon} />}
        label={`${lens.value}s`}
        {...a11yProps(i)}
      />
    )
  })

  useEffect(() => {
    setTab(ingredientLens)
  }, [])

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setLens(newValue)
    setAgeGroupTab(ageGroupGenX)
    setSelectedDataPoints(null)
    setSelected2YearCAGR(null)
    setSelected4YearCAGR(null)
    setFilteredData(null)
    setSearchValue('')
    const updatedDropdownsList = allDropdownsList.map((dropdown) => {
      return {
        ...dropdown,
        value: null,
      }
    })
    setAllDropdownsList(updatedDropdownsList)
  }

  const queryParams = new URLSearchParams(history.location.search)
  const country = queryParams.get('country')
  const category = queryParams.get('category')

  const demography = queryParams.get('demography')
  const themename = queryParams.get('trend')
  const themeid = queryParams.get('themeId')
  const lenSelected = queryParams.get('lens')
  const projectid = queryParams.get('projectId')

  const ampEventOnAgeGroupButtons = (ageGroupTab) => {
    const ampiEvent = 'Clicked_Demography_Agegroup'
    const ampiUserData = {
      email,
      lens,
      country: countrySelected,
      category: categorySelected,
      subCategoryName: 'null',
      ageGroupTab,
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }

  const ampEventOnChartView = () => {
    const ampiEvent = 'Clicked_Demography_Graph'
    const ampiUserData = {
      email,
      lens,
      country,
      category,
      subCategoryName: 'null',
      ageGroupTab,
      viewMode: 'Graph',
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }

  const logCategoryChange = (category) => {
    const ampiEvent = 'Clicked_Demography_Dashboard'
    const ampiUserData = {
      email,
      country,
      category,
    }
    amp(ampiEvent, ampiUserData)
  }

  useEffect(() => {
    if (categorySelected && countrySelected && themeid === null) {
      ampEventOnAgeGroupButtons('Gen X')
    }
  }, [categorySelected])

  useEffect(() => {
    if (categorySelected && themeid === null) {
      ampEventOnChartView()
    }
  }, [categorySelected])

  const handleClickMillennials = () => {
    setAgeGroupTab('Millennials')
    setSearchValue('')
    ampEventOnAgeGroupButtons('Millennials')
  }

  const handleClickGenZ = () => {
    setAgeGroupTab('Gen Z')
    setSearchValue('')
    ampEventOnAgeGroupButtons('Gen Z')
  }

  const handleClickGenX = () => {
    setAgeGroupTab('Gen X')
    setSearchValue('')
    ampEventOnAgeGroupButtons('Gen X')
  }

  const setChart = () => {
    setViewMode('chart')
    ampEventOnChartView()
  }

  const setTable = () => {
    setViewMode('table')
    const ampiEvent = 'Clicked_Demography_List'
    const ampiUserData = {
      email,
      lens: 'Theme',
      country,
      category,
      subCategoryName: 'null',
      ageGroupTab,
      viewMode: 'Table',
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }
  const handleCategoryChange = (e, newVal) => {
    logCategoryChange(newVal)
    setCategorySelected(newVal)
    setSearchValue('')
    if (!newVal) {
      history.push('/mui/demographic-analytics')
      setAgeGroupTab(ageGroupGenX)
      setLens(ingredientLens)
      setTab(ingredientLens)
      setViewMode('chart')
    }
  }

  const ampEventClickOnViewDetails = (trend) => {
    const ampiEvent = 'Clicked_Demography_Trend'
    const ampiUserData = {
      email,
      lens,
      country,
      category,
      subCategoryName: 'null',
      ageGroupTab,
      view: viewMode,
      trendName: trend?.name || trendTooltipData.name,
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }

  const handleHover = (graphPointData) => {
    settrendTooltipData(graphPointData)
    const tooltip = {
      image: graphPointData.image,
      name: graphPointData.name,
      twoYearCagr: graphPointData.yScaleData,
      engagementScore: graphPointData.xScaleData,
    }
    setTooltipData(tooltip)
  }
  const trendRedirectLink = `/mui/demographic-analytics?trend=${trendTooltipData.name}&projectId=${trendTooltipData.project_id}&country=${countrySelected}&category=${categorySelected}&lens=${lens}&themeId=${trendTooltipData.trend_id}&demography=${trendTooltipData.age_group}`

  const handleSearchTheme = (searchVal) => {
    setSearchValue(searchVal)
  }

  const modifiedData = useMemo(
    () =>
      trendGraphDetails?.map(
        ({
          two_year_cagr: twoYearCagr,
          final_engagement_score: finalEngagementScore,
          ...trendDetails
        }) => {
          return {
            ...trendDetails,
            xScaleData: finalEngagementScore,
            yScaleData: twoYearCagr,
          }
        },
      ),
    [trendGraphDetails],
  )

  const minXDomain = modifiedData?.reduce(function(prev, curr) {
    return prev.xScaleData < curr.xScaleData ? prev : curr
  }, {})

  const maxXDomain = modifiedData?.reduce(function(prev, curr) {
    return prev.xScaleData > curr.xScaleData ? prev : curr
  }, {})

  const minYDomain = modifiedData?.reduce(function(prev, curr) {
    return prev.yScaleData < curr.yScaleData ? prev : curr
  }, {})

  const maxYDomain = modifiedData?.reduce(function(prev, curr) {
    return prev.yScaleData > curr.yScaleData ? prev : curr
  }, {})

  const graphHelperData = {
    xAxisText: 'Engagement',
    yAxisText: 'Growth Rate ',
    scaleX: 'scaleSymlog',
    scaleY: 'scaleSymlog',
    yAxisTextis: 'The growth rate is measured by 2 Year CAGR',
    xAxisTextis:
      'Data points based on consumer engagement from social platforms.',
    yDomain: [
      Math.floor(minYDomain?.yScaleData / 10) * 10 - 10,
      Math.ceil(maxYDomain?.yScaleData / 10) * 10 + 10,
    ],
    domain: [
      Math.floor(minXDomain?.xScaleData / 10) * 10 - 10,
      Math.ceil(maxXDomain?.xScaleData / 10) * 10 + 10,
    ],
  }
  const calculateMedian = (array) => {
    if (!array?.length) {
      return null
    }
    const sortedArray = [...array].sort((a, b) => b - a)
    const arrLength = sortedArray.length
    if (arrLength % 2 === 0) {
      const medianVal =
        (sortedArray[arrLength / 2] + sortedArray[arrLength / 2 - 1]) / 2
      return medianVal
    }
    return sortedArray[Math.floor(arrLength / 2)]
  }

  const medianXVal = useMemo(
    () => calculateMedian(modifiedData?.map((data) => data.xScaleData)),
    [modifiedData],
  )

  const medianYVal = useMemo(
    () => calculateMedian(modifiedData?.map((data) => data.yScaleData)),
    [modifiedData],
  )

  const searchFiltreData = useMemo(
    () =>
      searchValue !== ''
        ? modifiedData.filter((d) =>
            d.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : modifiedData,
    [searchValue, modifiedData],
  )

  const searchFiltredData = filteredData ? filteredTableData : tableData

  const getDemographyDashboards = () => {
    const request = NetworkUtil.demographicAnalyticsDashboard()
    axios
      .get(request)
      .then((res) => {
        setdemographyDashboards(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getAllTrends = (
    countrySelected,
    categorySelected,
    ageGroupTab,
    lens,
  ) => {
    const request = NetworkUtil.demographicAnalyticsAllTrends(
      countrySelected,
      categorySelected,
      ageGroupTab,
      lens,
    )

    return axios
      .get(request)
      .then((res) => {
        setTrendGraphDetails(res.data.data.trend_data)
      })
      .catch(() => {})
  }

  // const getDemographyFsProductsForProducts = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyFoodServiceProduct(
  //     productID,
  //     ageGroup,
  //   )
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setFsProducts(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  // const getDemographyFsProductsForIngredients = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyCafeProducts(productID, ageGroup)
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setFsProducts(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  // const getDemographyRetailProductsforIngredient = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyRetailProducts(
  //     productID,
  //     ageGroup,
  //   )
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setRetailProducts(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  // const getDemographyRetailProductsforProduct = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyRetailProductsForProduct(
  //     productID,
  //     ageGroup,
  //   )
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setRetailProducts(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  // const getDemographyRetailProductsFormat = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyRetailProductFormats(
  //     productID,
  //     ageGroup,
  //   )
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setRetailProductsFormat(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  // const getDemographyFsProductsFormat = (productID, ageGroup) => {
  //   const request = NetworkUtil.fetchDemographyFoodServiceFormat(
  //     productID,
  //     ageGroup,
  //   )
  //   setShowLoader(true)
  //   axios
  //     .get(request)
  //     .then((res) => {
  //       setFsProductFormat(res.data.data)
  //       setShowLoader(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setShowLoader(false)
  //     })
  // }

  const viewDetailsClicked = () => {
    window.open(trendRedirectLink)
    ampEventClickOnViewDetails()
  }

  const scatteredPlotTooltip = (
    <AnalyticsHoverCard
      demographyComponent
      image={tooltipData.image}
      name={tooltipData.name}
      twoYearCagr={tooltipData.twoYearCagr}
      engagementScore={tooltipData.engagementScore}
      viewDetailsLink={() => viewDetailsClicked()}
    />
  )

  const allCategories = demographyDashboards?.map((i) => i.Category)

  // All useEffects
  useEffect(() => {
    if (lenSelected) {
      setLens(lenSelected)
    }
    setProjectId(projectid)
    setCategorySelected(categorySelected || category)
    setCountrySelected('USA' || country)
    setAgeGroupTab(demography || ageGroupTab)
    setThemeId(themeid)
    setThemeName(themename)
  }, [])

  useEffect(() => {
    if (categorySelected && countrySelected && !themeId) {
      history.push(
        `?country=${encodeURIComponent(
          countrySelected,
        )}&category=${encodeURIComponent(
          categorySelected,
        )}&demography=${encodeURIComponent(ageGroupTab)}`,
      )
    }
  }, [categorySelected, countrySelected, ageGroupTab, category])

  useEffect(() => {
    getDemographyDashboards()
  }, [])

  const yoyCagrOpts = [
    {
      label: 'All',
      key: 0,
      labelTooltip: 'All',
    },
    {
      label: '>=5%',
      key: 1,
      labelTooltip: 'Greater than or equal to 5%',
    },
    {
      label: '>=10%',
      key: 2,
      labelTooltip: 'Greater than or equal to 10%',
    },
    {
      label: '>=20%',
      key: 3,
      labelTooltip: 'Greater than or equal to 20%',
    },
  ]

  const dataPointOpts = [
    {
      label: 'All',
      key: 0,
      labelTooltip: 'All',
    },
    {
      label: '>=10K',
      key: 0,
      labelTooltip: 'Greater than or equal to 10K',
    },
    {
      label: '>=100K',
      key: 0,
      labelTooltip: 'Greater than or equal to 100K',
    },
    {
      label: '>=1M',
      key: 0,
      labelTooltip: 'Greater than or equal to 1M',
    },
  ]

  const [allDropdownsList, setAllDropdownsList] = useState([
    {
      index: 0,
      label: 'Data Points',
      value: null,
      options: dataPointOpts,
      placeholder: 'Select Data Points',
      marginTop: '0px',
    },
    {
      index: 1,
      label: '4 Year CAGR',
      value: null,
      options: yoyCagrOpts,
      placeholder: 'Select 4 Year CAGR',
      marginTop: '30px',
    },
    {
      index: 2,
      label: '2 Year CAGR',
      value: null,
      options: yoyCagrOpts,
      placeholder: 'Select 2 Year CAGR',
      marginTop: '30px',
    },
  ])

  const handleSelectChange = (index, value) => {
    const updatedList = [...allDropdownsList]
    updatedList[index].value = value
    setAllDropdownsList(updatedList)
    allDropdownsList.forEach((obj) => {
      switch (obj.label) {
        case 'Data Points':
          setSelectedDataPoints(obj.value)
          break
        case '4 Year CAGR':
          setSelected4YearCAGR(obj.value)
          break
        case '2 Year CAGR':
          setSelected2YearCAGR(obj.value)
          break
        default:
          return null
      }
    })
  }
  const applySelectedFilters = (
    allDropdownsList,
    selectedDataPoints,
    selected2YearCAGR,
    selected4YearCAGR,
  ) => {
    logAppliedFilters(selectedDataPoints, selected2YearCAGR, selected4YearCAGR)
    handleCloseFilter()

    const newData = trendGraphDetails.filter((item) => {
      if (
        typeof selectedDataPoints === 'string' &&
        (selectedDataPoints.includes('K') || selectedDataPoints.includes('M'))
      ) {
        selectedDataPoints =
          Number(selectedDataPoints.replace(/[^0-9]/g, '')) *
          (selectedDataPoints.includes('K') ? 1000 : 1000000)
      }
      if (
        typeof selected2YearCAGR === 'string' &&
        selected2YearCAGR.includes('%')
      ) {
        selected2YearCAGR = Number(selected2YearCAGR.replace(/[^0-9]/g, ''))
      }
      if (
        typeof selected4YearCAGR === 'string' &&
        selected4YearCAGR.includes('%')
      ) {
        selected4YearCAGR = Number(selected4YearCAGR.replace(/[^0-9]/g, ''))
      }
      if (
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR &&
        selected4YearCAGR != 'All' &&
        selected4YearCAGR
      ) {
        return (
          item.two_year_cagr >= selected2YearCAGR &&
          item.four_year_cagr >= selected4YearCAGR
        )
      }

      if (
        (selected2YearCAGR === 'All' || selected2YearCAGR === null) &&
        selected4YearCAGR != 'All' &&
        selected4YearCAGR &&
        selectedDataPoints != 'All' &&
        selectedDataPoints
      )
        return (
          item.data_point >= selectedDataPoints &&
          item.four_year_cagr >= selected4YearCAGR
        )

      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        selectedDataPoints != 'All' &&
        selectedDataPoints &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR
      ) {
        return (
          item.data_point >= selectedDataPoints &&
          item.two_year_cagr >= selected2YearCAGR
        )
      }
      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        selected2YearCAGR != 'All' &&
        selected2YearCAGR
      ) {
        return item.two_year_cagr >= selected2YearCAGR
      }

      if (
        selected4YearCAGR != 'All' &&
        selected4YearCAGR &&
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null)
      ) {
        return item.four_year_cagr >= selected4YearCAGR
      }

      if (
        (selected4YearCAGR === 'All' || selected4YearCAGR === null) &&
        selectedDataPoints != 'All' &&
        selectedDataPoints &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null)
      ) {
        return item.data_point >= selectedDataPoints
      }

      if (
        (selectedDataPoints === 'All' || selectedDataPoints === null) &&
        (selected2YearCAGR === 'All' || selected2YearCAGR === null) &&
        (selected4YearCAGR === 'All' || selected4YearCAGR === null)
      ) {
        return trendGraphDetails
      }

      return (
        item.data_point >= selectedDataPoints &&
        item.two_year_cagr >= selected2YearCAGR &&
        item.four_year_cagr >= selected4YearCAGR
      )
    })
    setFilteredData(newData)
  }

  const resetSelectedFilterValues = () => {
    handleCloseFilter()
    setSelectedDataPoints(null)
    setSelected4YearCAGR(null)
    setSelected2YearCAGR(null)
    setFilteredData(null)
    const updatedDropdownsList = allDropdownsList.map((dropdown) => {
      return {
        ...dropdown,
        value: null,
      }
    })
    setAllDropdownsList(updatedDropdownsList)
  }

  const handleClickViewDetails = (trend) => {
    const link = `/mui/demographic-analytics?trend=${trend.name}&projectId=${trend.project_id}&country=${countrySelected}&category=${categorySelected}&lens=${lens}&themeId=${trend.trend_id}&demography=${trend.age_group}`
    window.open(link, '_blank')
    ampEventClickOnViewDetails(trend)
  }

  return (
    <FeatureAuthorizer
      authAttr="demographic_analytics"
      disableForFeat="cognitive_search"
    >
      {themeid &&
      themename &&
      country &&
      category &&
      lens &&
      demography &&
      projectid ? (
        <DemographicThemePage
          // getDemographyRetailProductsFormat={getDemographyRetailProductsFormat}
          // getDemographyFsProductsFormat={getDemographyFsProductsFormat}
          // getDemographyRetailProductsforIngredient={
          //   getDemographyRetailProductsforIngredient
          // }
          // getDemographyFsProductsForIngredients={
          //   getDemographyFsProductsForIngredients
          // }
          // getDemographyFsProductsForProducts={
          //   getDemographyFsProductsForProducts
          // }
          // getDemographyRetailProductsforProduct={
          //   getDemographyRetailProductsforProduct
          // }
          // fsProducts={fsProducts}
          // retailProducts={retailProducts}
          // fsProductFormat={fsProductFormat}
          // retailProductsFormat={retailProductsFormat}
          projectId={projectid}
          email={email}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          country={country}
          category={category}
          themeId={themeid}
          trendName={themename}
          ageGroupTab={demography}
          lens={lenSelected || ingredientLens}
          setAgeGroupTab={setAgeGroupTab}
          setThemeId={setThemeId}
          setCountrySelected={setCountrySelected}
          setCategorySelected={setCategorySelected}
          setThemeName={setThemeName}
          setLens={setLens}
          ingredientLens={ingredientLens}
          productLens={productLens}
          themeLens={themeLens}
          ageGroupGenX={ageGroupGenX}
          ageGroupGenZ={ageGroupGenZ}
          ageGroupMillanials={ageGroupMillanials}
        />
      ) : (country || countrySelected) &&
        (category || categorySelected) &&
        themeid === null ? (
        <DemographicAnalysisUI
          viewDetailsClicked={handleClickViewDetails}
          ageGroupGenX={ageGroupGenX}
          ageGroupGenZ={ageGroupGenZ}
          ageGroupMillanials={ageGroupMillanials}
          ingredientLens={ingredientLens}
          productLens={productLens}
          themeLens={themeLens}
          medianXVal={medianXVal}
          medianYVal={medianYVal}
          selectedDataPoints={selectedDataPoints}
          selected2YearCAGR={selected2YearCAGR}
          selected4YearCAGR={selected4YearCAGR}
          handleFilterClickOpen={handleFilterClickOpen}
          handleCloseFilter={handleCloseFilter}
          open={open}
          anchorEl={anchorEl}
          applySelectedFilters={applySelectedFilters}
          resetSelectedFilterValues={resetSelectedFilterValues}
          handleSelectChange={handleSelectChange}
          allDropdownsList={allDropdownsList}
          tabs={tabs}
          tab={tab}
          handleChange={handleChange}
          lensToShow={lensToShow}
          email={email}
          showLoader={showLoader}
          searchFiltreData={trendGraphDetails && searchFiltreData}
          searchFiltredData={searchFiltredData}
          sx={{ marginTop: '0px' }}
          demographyDashboards={demographyDashboards}
          handleClickMillennials={handleClickMillennials}
          handleClickGenZ={handleClickGenZ}
          handleClickGenX={handleClickGenX}
          demography={demography}
          ageGroupTab={ageGroupTab}
          setAgeGroupTab={setAgeGroupTab}
          countrySelected={countrySelected}
          country={country}
          setCountrySelected={setCountrySelected}
          category={category}
          categorySelected={categorySelected}
          setCategorySelected={setCategorySelected}
          lens={lens}
          handleSearchTheme={handleSearchTheme}
          demographicData={demographicData}
          searchValue={searchValue}
          handleCategoryChange={handleCategoryChange}
          setdemographicData={setdemographicData}
          setShowLoader={setShowLoader}
          setTrendGraphDetails={setTrendGraphDetails}
          viewMode={viewMode}
          setChart={setChart}
          setTable={setTable}
          handleHover={handleHover}
          viewDetailsLink={trendRedirectLink}
          scatteredPlotTooltip={scatteredPlotTooltip}
          dataPoint={dataPoint}
          graphHelperData={graphHelperData}
          resetBtn={resetBtn}
          setResetBtn={setResetBtn}
          resetBtnId={resetBtnId}
        />
      ) : (
        <>
          <MainCommonTabs subscriptionDetails={subscriptionDetails} />
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mb: 1.25,
              py: 1.5,
              px: 2,
              backgroundColor: ({ palette }) => palette.background.paper,
            }}
          >
            <SingleSelect
              label=" Country"
              sx={{ width: '180px', mr: 4 }}
              placeholder="USA"
              options={[]}
              value={countrySelected || null}
              disabled
            />

            <SingleSelect
              label="Product Category"
              sx={{ width: '180px' }}
              placeholder="Select Category"
              options={allCategories}
              value={categorySelected || null || category}
              onChange={(e, newVal) => {
                handleCategoryChange(e, newVal)
              }}
            />
          </Stack>

          <SearchNotSelected
            msg={'Select category to view Demographic Analysis'}
          />
        </>
      )}
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default withRouter(connect(mapStateToProps)(DemographicAnalysis))
