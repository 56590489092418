import React from "react";
import PropTypes from "prop-types";

import "./ButtonSW.scss";

/**
 * Screen winner primary button
 */
const Button = ({
  name,
  type = "button",
  solid = false,
  className = "",
  textOnly = false,
  onClick,
  disabled,
  leftImage,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${
        textOnly
          ? "btn-sw btn-sw--tertiary"
          : `btn-sw${!solid ? " btn-sw--outline" : ""}`
      }${disabled ? " btn-sw--disabled" : ""} ${className ? className : ""}`}
      name={name}
      type={type}
    >
      {leftImage && <img className="btn-sw__img" src={leftImage} alt="img"/>}
      {name}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  solid: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Button;
