import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from '../../../store/actions/index'
import {
  Button,
  Grid,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Radio,
  Slider,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material'
import { ExpandLess, ExpandMore, Close as CloseIcon } from '@mui/icons-material'
import { Box } from '@mui/system'
import { FixedSizeList } from 'react-window'
import useDebounce from '../../../shared/hooks/useDebounce'
import SearchInput from '../../../shared/components/SearchInput'
import FilterTrendItem from '../../../shared/components/FilterTrendItem'
import ErrorHandler from '../ErrorHandler'
import { useSnackbar } from 'notistack'
import { FilterContainer, SectionContainer } from './styles'
import { amplify } from './../../../store/utility'
import SavedFiltersDialog from '../SavedFiltersDialog'

const searchByQuery = (query) => (item) => {
  const { name } = item
  return name.toLowerCase().includes(query.toLowerCase())
}

const mapFilter = (ele) => {
  return ele
    .filter((ingredient) => ingredient.checked)
    .map((ingredient) => {
      if (ingredient.name) return ingredient.name
    })
}

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Apply_Button': // do not remove using multiple check for switch statement
      case 'Clicked_Save_Selections':
        amplitudeUserData = {
          User: props.email,
          Number_Of_Trends: ampiUserData.numberOfTrends,
          SC_Ingredient: mapFilter(ampiUserData.ingredientSubCategory)[0],
          SC_Theme: mapFilter(ampiUserData.themeSubCategory)[0],
          SC_Product: mapFilter(ampiUserData.productSubCategory)[0],
          Filter: mapFilter(ampiUserData.allTrends),
          // 'Match': ampiUserData.selectedOperation,
          Match: props.selectedOperation,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
      case 'Clicked_View_Save_Filters':
        amplitudeUserData = {
          User: props.email,
        }
        amplify(amplitudeEvent, amplitudeUserData, props.email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in Filter')
  }
}

const Filter = (props) => {
  const { ingredient, theme, product } = props.trends
  const {
    allCategoriesList,
    maxTrends,
    handleSliderChange,
    ctxSrchMapping,
    handleToggleAllCntxtSrchTrends,
    selectedCtx,
    handleChangeCtx,
    handleResetAllFilters,
    handleChangeTrendLens,
    lensToShow,
    activeSubCategories,
    activePillarCategories,
    handleChangeActiveSubCategories,
    handleChangeActiveBucketCategories,
    allPillarCategoriesList,
    handleSubBucketSelect,
  } = props
  const ingredientSubCategory = allCategoriesList.ingredient
  const themeSubCategory = allCategoriesList.theme
  const bucketIngredientSubCategory = allPillarCategoriesList.ingredient
  const bucketProductSubCategory = allPillarCategoriesList.product
  const bucketThemeSubCategory = allPillarCategoriesList.theme
  const productSubCategory = allCategoriesList.product
  const [allTrendsOpen, setAllTrendsOpen] = useState(true)
  const [contextSrchOpen, setContextSrchOpen] = useState(true)
  const [numberOfTrends, setNumberOfTrends] = useState(1)
  const [allTrends, setAllTrends] = useState(() =>
    [
      ...ingredient.map((v) => ({
        ...v,
        id: `${'ingredient'}${v.id}`,
        type: 'ingredient',
      })),
      ...theme.map((v) => ({ ...v, id: `${'theme'}${v.id}`, type: 'theme' })),
      ...product.map((v) => ({
        ...v,
        id: `${'product'}${v.id}`,
        type: 'theme',
      })),
    ].sort((a, b) => a.name.localeCompare(b.name)),
  )
  const [allTrendsSearchQuery, setAllTrendsSearchQuery] = useState('')
  const [subBucketSearchQuery, setSubBucketSearchQuery] = useState('')
  const allTrendsDebouncedQuery = useDebounce(allTrendsSearchQuery, 400)
  const subBucketDebouncedQuery = useDebounce(subBucketSearchQuery, 400)
  const numberOfTrendsDebounced = useDebounce(numberOfTrends, 250)
  const [searchTriggeredCtx, setSearchTriggeredCtx] = useState(false)
  const [savedSelectionOpen, setSavedSelectionOpen] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({})
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [recentlySavedFilter, setRecentlySavedFilter] = useState(null)
  const recentSavedFilterRef = useRef({ snackbar: null, savedFilter: null })
  const searchInitCtx = () => {
    if (allTrends.filter(({ checked }) => checked).length === 0) {
      setSearchTriggeredCtx(true)
    }
  }
  useEffect(() => {
    async function fetchSavedFilters() {
      await props.viewSavedAdvancedSearch(Number(props.match.params.projectId))
    }
    fetchSavedFilters()
  }, [])

  const handleAllTrendsSearch = useCallback((e) => {
    setAllTrendsSearchQuery(e.target.value)
  }, [])

  useEffect(() => {
    return () => {
      removeSnackbar()
      removeSavedFilterHighlight()
    }
  }, [])

  useLayoutEffect(() => {
    if (recentlySavedFilter !== null) {
      setSavedSelectionOpen(true)
      recentSavedFilterRef.current = {
        ...recentSavedFilterRef.current,
        savedFilter: setTimeout(() => {
          setRecentlySavedFilter(null)
        }, 5000),
      }
    }
  }, [recentlySavedFilter])

  useEffect(() => {
    if (!allTrendsSearchQuery && searchTriggeredCtx) {
      setSearchTriggeredCtx(false)
    }
  }, [allTrendsSearchQuery])

  useLayoutEffect(() => {
    handleSliderChange(numberOfTrendsDebounced)
  }, [numberOfTrendsDebounced])

  useEffect(() => {
    setNumberOfTrends(maxTrends)
  }, [maxTrends])
  useEffect(() => {
    setAllTrends([...props.uptTrends])
  }, [props.uptTrends])

  useEffect(() => {
    async function fetchSavedFilters() {
      await props.viewSavedAdvancedSearch(Number(props.match.params.projectId))
    }
    fetchSavedFilters()
  }, [])

  const handleBucketCategorySearch = useCallback(
    (e) => setSubBucketSearchQuery(e.target.value),
    [],
  )
  const handleSelectTrend = useCallback(
    (e, check, ctx) => {
      const { value, checked } = e.target
      setAllTrends(
        allTrends.map((trend) =>
          trend.id === value ? { ...trend, checked: !!checked } : trend,
        ),
      )
      if (!ctx) {
        const ctxName = allTrends.find(
          ({ id, type }) => id === value && type === 'theme',
        )
        if (ctxName) {
          handleChangeCtx(ctxName.name, checked)
        }
      } else {
        searchInitCtx()
      }
      props.handleTrendsSelectionChange(value)
    },
    [allTrends, allTrendsSearchQuery],
  )

  const filtredTrends = useMemo(
    () => [
      ...allTrends.filter(({ checked }) => checked),
      ...allTrends.filter(({ checked }) => !checked),
    ],
    [allTrends],
  )
  const trendSearchResults = useMemo(() => {
    const results = filtredTrends.filter(searchByQuery(allTrendsDebouncedQuery))
    const noRes = (arr) =>
      arr.length
        ? arr
        : [{ id: '#no-res', name: 'No results found', type: 'empty' }]
    return [
      ...(ingredient && ingredient.length
        ? [
            { id: '#ingredient', name: 'Ingredient', type: 'header' },
            ...noRes(results.filter(({ type }) => type === 'ingredient')),
          ]
        : []),
      ...(theme && theme.length
        ? [
            { id: '#theme', name: 'Themes', type: 'header' },
            ...noRes(results.filter(({ type }) => type === 'theme')),
          ]
        : []),
      ...(product && product.length
        ? [
            { id: '#product', name: 'Product', type: 'header' },
            ...noRes(results.filter(({ type }) => type === 'product')),
          ]
        : []),
    ]
  }, [allTrendsDebouncedQuery, filtredTrends])
  const handlAllTrendsOpen = () => {
    setAllTrendsOpen(!allTrendsOpen)
  }

  const handleContextSrchOpen = () => {
    setContextSrchOpen(!contextSrchOpen)
  }

  const handleBucketCategoryOpen = (type) => () => {
    handleChangeActiveBucketCategories(type)
  }

  const handleClickOpen = () => {
    setSavedSelectionOpen(true)
    const ampiEvent = 'Clicked_View_Save_Filters'
    amp(props, ampiEvent)
  }
  const handleClose = () => {
    setSavedSelectionOpen(false)
  }

  const sliderUnit = maxTrends / 5
  const sliderMarks = useMemo(
    () =>
      sliderUnit
        ? [
            ...Array(
              Math.round(Math.min(maxTrends / sliderUnit, maxTrends)) + 1,
            ).keys(),
          ].map((i) => Math.max(sliderUnit * i, i))
        : [],
    [maxTrends, sliderUnit],
  )
  const ctxSelectAll = (selected, srchCtxKey) => {
    searchInitCtx()
    handleToggleAllCntxtSrchTrends(selected, srchCtxKey)
  }

  // Contextual Search
  const srchCtxKey = searchTriggeredCtx
    ? [allTrendsSearchQuery]
    : allTrends.filter(({ type, checked }) => type === 'theme' && checked)
        .length
    ? selectedCtx
    : [allTrendsSearchQuery]
  const ctxSectionTitle = `Themes related to ${srchCtxKey.join(', ')}`
  const contextMapList =
    ctxSrchMapping.theme &&
    srchCtxKey.reduce(
      (acc, ctxName) => [
        ...acc,
        ...(ctxSrchMapping.theme[ctxName.toUpperCase()] || []),
      ],
      [],
    )
  const contextSrchItem = (props) => {
    const { data, index, style } = props
    const multiVals = data.items.length > 1
    if (index === 0 && multiVals) {
      const allSelected =
        data.items.filter(({ checked }) => checked).length === data.items.length
      return (
        <Box
          key={'Select all'}
          sx={{ display: 'flex', alignItems: 'center' }}
          style={style}
        >
          <FormControlLabel
            label={'Select all'}
            value={'Select all'}
            onChange={() => ctxSelectAll(!allSelected, srchCtxKey)}
            checked={allSelected}
            control={
              <Checkbox
                inputProps={{ 'aria-labelledby': 'Select all' }}
                color="secondary"
                size="small"
                disableRipple
                sx={{ ml: -1 }}
              />
            }
          />
        </Box>
      )
    }
    return (
      <FilterTrendItem
        hideLens
        {...props}
        index={multiVals ? index - 1 : index}
      />
    )
  }

  const ctxTitleButton = () => {
    const title =
      ctxSectionTitle.length > 24
        ? ctxSectionTitle.slice(0, 21).trim() + '...'
        : ctxSectionTitle
    const ctxListItemBtn = (
      <ListItemButton onClick={handleContextSrchOpen}>
        <ListItemText primary={title} />
        {contextSrchOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    )
    return ctxSectionTitle.length > 24 ? (
      <Tooltip title={ctxSectionTitle}>{ctxListItemBtn}</Tooltip>
    ) : (
      ctxListItemBtn
    )
  }

  const matchedCtx =
    contextMapList &&
    !!contextMapList.length &&
    allTrends.filter(
      ({ name, id, type }) =>
        type === 'theme' && contextMapList.find((cntxt) => cntxt.name === name),
    )

  const removeSnackbar = () => {
    const { snackbar } = recentSavedFilterRef.current
    if (snackbar) {
      closeSnackbar(snackbar)
    }
    recentSavedFilterRef.current = {
      ...recentSavedFilterRef.current,
      snackbar: null,
    }
  }

  const removeSavedFilterHighlight = () => {
    const { savedFilter } = recentSavedFilterRef.current
    if (savedFilter) {
      clearTimeout(savedFilter)
    }
    recentSavedFilterRef.current = {
      ...recentSavedFilterRef.current,
      savedFilter: null,
    }
  }

  const action = (id, key) => (
    <>
      <Button
        size="small"
        onClick={() => {
          closeSnackbar(key)
          showRecentlySavedFilterId(id)
        }}
      >
        View
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon fontSize="small" color="action" />
      </IconButton>
    </>
  )

  const showRecentlySavedFilterId = (id) => {
    removeSavedFilterHighlight()
    removeSnackbar()
    setRecentlySavedFilter(id)
  }

  const applyButtonClicked = () => {
    props.handleApplyFilters()
    const ampiEvent = `Clicked_Apply_Button`
    const ampiUserData = {
      numberOfTrends: numberOfTrends,
      ingredientSubCategory: ingredientSubCategory,
      themeSubCategory: themeSubCategory,
      productSubCategory: productSubCategory,
      allTrends: allTrends,
      selectedOperation: props.selectedOperation,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  const handleSaveFilters = () => {
    const ampiEvent = `Clicked_Save_Selections`
    const ampiUserData = {
      numberOfTrends: numberOfTrends,
      ingredientSubCategory: ingredientSubCategory,
      themeSubCategory: themeSubCategory,
      productSubCategory: productSubCategory,
      allTrends: allTrends,
      selectedOperation: props.selectedOperation,
    }
    amp(props, ampiEvent, ampiUserData)
    removeSnackbar()
    const { savedFiltersExists, filterId } = props.handleSaveFilters()
    if (savedFiltersExists) {
      recentSavedFilterRef.current.snackbar = enqueueSnackbar(
        'This filter already exists.',
        { variant: 'info', action: (key) => action(filterId, key) },
      )
    } else {
      recentSavedFilterRef.current.snackbar = enqueueSnackbar(
        'Your selection has been saved!',
        { variant: 'success', action: (key) => action(0, key) },
      )
    }
  }

  const subCategoryBucketSearchResults = useMemo(
    () => ({
      ingredientsBucketSearchResult: bucketIngredientSubCategory.filter(
        searchByQuery(subBucketDebouncedQuery),
      ),
      themesBucketSearchResult: bucketThemeSubCategory.filter(
        searchByQuery(subBucketDebouncedQuery),
      ),
      productsBucketSearchResult: bucketProductSubCategory.filter(
        searchByQuery(subBucketDebouncedQuery),
      ),
    }),
    [
      subBucketDebouncedQuery,
      bucketIngredientSubCategory,
      bucketThemeSubCategory,
      bucketProductSubCategory,
    ],
  )

  const subCategoryBucketItems = useMemo(
    () => (type) => {
      const {
        ingredientsBucketSearchResult,
        themesBucketSearchResult,
        productsBucketSearchResult,
      } = subCategoryBucketSearchResults
      const bucketSubCategory =
        type === 'theme'
          ? themesBucketSearchResult
          : type === 'product'
          ? productsBucketSearchResult
          : ingredientsBucketSearchResult
      return bucketSubCategory.length ? (
        bucketSubCategory.map(({ id, name, checked }) => (
          <Box key={id}>
            <FormControlLabel
              value={id}
              label={name}
              control={
                <Radio
                  checked={checked}
                  onClick={() => {
                    props.handleSubBucketSelect(id, type)
                  }}
                  inputProps={{ 'aria-labelledby': id }}
                  color="secondary"
                  size="small"
                  sx={{ ml: -1, '&:hover': { bgcolor: 'transparent' } }}
                />
              }
            />
          </Box>
        ))
      ) : (
        <Typography color="text.secondary">No results found</Typography>
      )
    },
    [subCategoryBucketSearchResults],
  )

  if (!props.trends) return null
  return (
    <FilterContainer>
      <ListItem>
        <ListItemText primary="Smart Filters" />
        <Button onClick={handleResetAllFilters}>Reset All Filters</Button>
      </ListItem>

      <Divider />

      <ListItemButton onClick={handlAllTrendsOpen}>
        <ListItemText primary="All Trends" />
        {allTrendsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={allTrendsOpen} timeout="auto">
        <SectionContainer>
          <SearchInput
            value={allTrendsSearchQuery}
            onChange={handleAllTrendsSearch}
            onClick={() => {
              const ampiEvent = `Clicked_Search_Box_Advanced_Search`
              const ampiUserData = {
                Username: props.email,
                Country_Name: props.country,
                Category: props.category,
              }
              amplify(ampiEvent, ampiUserData, props.email)
            }}
          />

          <FixedSizeList
            height={224}
            itemCount={
              (allTrendsDebouncedQuery ? trendSearchResults : filtredTrends)
                .length
            }
            itemData={{
              items: allTrendsDebouncedQuery
                ? trendSearchResults
                : filtredTrends,
              handleSelectTrend,
            }}
            itemSize={32}
            width="100%"
            style={{ marginTop: 8 }}
          >
            {(props) => <FilterTrendItem {...props} />}
          </FixedSizeList>
        </SectionContainer>
      </Collapse>

      {matchedCtx && !!matchedCtx.length && (
        <ErrorHandler>
          <>
            {ctxTitleButton()}
            <Collapse in={contextSrchOpen} timeout="auto">
              <SectionContainer>
                <FixedSizeList
                  height={32 * Math.min(matchedCtx.length, 4)}
                  itemCount={
                    matchedCtx.length > 1
                      ? matchedCtx.length + 1
                      : matchedCtx.length
                  }
                  itemData={{
                    items: matchedCtx,
                    handleSelectTrend: (e) => handleSelectTrend(e, null, true),
                  }}
                  itemSize={32}
                  width="100%"
                >
                  {contextSrchItem}
                </FixedSizeList>
              </SectionContainer>
            </Collapse>
          </>
        </ErrorHandler>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 0.7,
          py: 1,
        }}
      >
        <Typography variant="caption">Match</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={props.selectedOperation}
            onChange={props.handleOperationSelect}
            fullWidth
            defaultValue="any"
          >
            <MenuItem value="any">any</MenuItem>
            <MenuItem value="all">all</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="caption">of the above lenses</Typography>
      </Box>

      <Divider />
      <Divider />
      {Boolean(
        bucketIngredientSubCategory.length ||
          bucketThemeSubCategory.length ||
          bucketProductSubCategory.length,
      ) && (
        <>
          <ListItemButton onClick={handleBucketCategoryOpen('main')}>
            <ListItemText primary="Trend Pillar" />
            {activePillarCategories.main ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={activePillarCategories.main} timeout="auto">
            <SectionContainer>
              <SearchInput
                value={subBucketSearchQuery}
                onChange={handleBucketCategorySearch}
              />
            </SectionContainer>
            {Boolean(bucketIngredientSubCategory.length) && (
              <>
                <ListItemButton
                  onClick={handleBucketCategoryOpen('ingredient')}
                >
                  <ListItemText primary="Ingredient Bucket" />
                  {activePillarCategories.ingredient ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse in={activePillarCategories.ingredient} timeout="auto">
                  <Box px={2}>
                    <Box sx={{ height: 145, overflowX: 'auto' }}>
                      {subCategoryBucketItems('ingredient')}
                    </Box>
                  </Box>
                </Collapse>
              </>
            )}
            {Boolean(bucketThemeSubCategory.length) && (
              <>
                <ListItemButton onClick={handleBucketCategoryOpen('theme')}>
                  <ListItemText primary="Theme Bucket" />
                  {activePillarCategories.theme ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse in={activePillarCategories.theme} timeout="auto">
                  <Box px={2}>
                    <Box sx={{ height: 145, overflowY: 'auto' }}>
                      <RadioGroup
                        aria-label="theme subCategory"
                        name="bucketThemeSubcategory"
                      >
                        {subCategoryBucketItems('theme')}
                      </RadioGroup>
                    </Box>
                  </Box>
                </Collapse>
              </>
            )}
            {Boolean(bucketProductSubCategory.length) && (
              <>
                <ListItemButton onClick={handleBucketCategoryOpen('product')}>
                  <ListItemText primary="Product Bucket" />
                  {activePillarCategories.product ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse in={activePillarCategories.product} timeout="auto">
                  <Box px={2}>
                    <Box sx={{ height: 145, overflowX: 'auto' }}>
                      {subCategoryBucketItems('product')}
                    </Box>
                  </Box>
                </Collapse>
              </>
            )}
          </Collapse>
        </>
      )}
      <Divider />
      {!!sliderMarks.length && (
        <SectionContainer>
          <Typography variant="caption">Number of trends to display</Typography>
          <Slider
            aria-label="Custom marks"
            size="small"
            defaultValue={maxTrends}
            value={numberOfTrends}
            step={1}
            onClick={() => {
              const ampiEvent = 'Moved_number_of_trends'
              const ampiUserData = {
                User: props.email,
                Trend_Slider_Moved: true,
              }
              amplify(ampiEvent, ampiUserData, props.email)
            }}
            onChange={(ev, val) => setNumberOfTrends(val)}
            valueLabelDisplay="auto"
            max={maxTrends}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {sliderMarks.map((mark) => (
              <Typography key={mark} variant="caption" color="textSecondary">
                {Math.round(mark)}
              </Typography>
            ))}
          </Box>
        </SectionContainer>
      )}
      <Divider />
      <Grid container spacing={1} p={1} alignItems="stretch">
        {/* Not using because of introduction of My Workspace */}
        {/* <Grid item xs={12}>
          <Button
            sx={{ width: '100%' }}
            size="small"
            disabled={isEmpty(props.savedFilters)}
            onClick={() => handleClickOpen(true)}
          >
            View saved filters
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{ width: '100%', whiteSpace: 'nowrap' }}
            variant="outlined"
            size="small"
            disabled={!props.showSaveSelsBtn}
            onClick={handleSaveFilters}
          >
            Save selections
          </Button>
          <SavedFiltersDialog
            prjId={props.prjId}
            open={savedSelectionOpen}
            onClose={handleClose}
            savedFilters={props.savedFilters}
            highlightFilterId={recentlySavedFilter}
            handleApplySavedFilter={props.handleApplySavedFilter}
            viewSavedAdvancedSearch={props.viewSavedAdvancedSearch}
            numberOfTrends={numberOfTrends}
            selectedOperation={props.selectedOperation}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Button
            sx={{ width: '100%', height: '100%', mt: 0.5 }}
            variant="contained"
            size="small"
            onClick={applyButtonClicked}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </FilterContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    savedFilters: state.projectListBuilder.advSearchFilters,
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewSavedAdvancedSearch: (payload) =>
      dispatch(actions.viewSavedAdvancedSearch(payload)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter))
