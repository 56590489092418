import { globalTrendSearch, trendListForGlobalSearch } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchGlobalTrendListResults = (lens, trend) => {
  return (dispatch) => {
    dispatch(setGlobalSearchLoading(true))
    const request = NetworkUtil.fetchProjectSearchDataRequest()
    const reqBody = {
      search_type: 'Global',
      lens: lens,
      search_term: trend,
    }
    axios
      .post(request, reqBody)
      .then((response) => {
        const { trends } = response.data.data
        dispatch(fetchGTSListSuccess(trends))
      })
      .catch((error) => {
        dispatch(fetchGTSListFailed(error))
      })
  }
}

const fetchGTSListSuccess = (res) => {
  return {
    type:
      globalTrendSearch.type[
        globalTrendSearch.GLOBAL_TREND_SEARCH_RESULTS_SUCCESS
      ],
    res,
  }
}

const fetchGTSListFailed = (error) => {
  return {
    type:
      globalTrendSearch.type[
        globalTrendSearch.GLOBAL_TREND_SEARCH_RESULTS_FAILURE
      ],
    error: error,
  }
}

export const fetchGlobalTrendList = () => {
  return (dispatch) => {
    dispatch(setGlobalSearchListLoading(true))
    const request = NetworkUtil.fetchSearchKeywords() + `?projectId=0`
    axios
      .get(request)
      .then((response) => {
        const { data } = response.data
        dispatch(fetchTrendListSuccess(data))
      })
      .catch((error) => {
        dispatch(fetchTrendListFailure(error))
      })
  }
}

const fetchTrendListSuccess = (trends) => {
  return {
    type:
      trendListForGlobalSearch.type[
        trendListForGlobalSearch.FETCH_GLOBAL_SEARCH_TREND_LIST_SUCCESS
      ],
    trends,
  }
}

const fetchTrendListFailure = (error) => {
  return {
    type:
      trendListForGlobalSearch.type[
        trendListForGlobalSearch.FETCH_GLOBAL_SEARCH_TREND_LIST_FAILURE
      ],
    error: error,
  }
}

export const resetGlobalSearchResults = () => {
  return {
    type:
      globalTrendSearch.type[
        globalTrendSearch.RESET_GLOBAL_TREND_SEARCH_RESULTS
      ],
  }
}

const setGlobalSearchLoading = (status) => ({
  type:
    globalTrendSearch.type[
      globalTrendSearch.SET_GLOBAL_TREND_SEARCH_RESULTS_LOADING
    ],
  loading: status,
})
const setGlobalSearchListLoading = (status) => ({
  type:
    globalTrendSearch.type[
      globalTrendSearch.SET_GLOBAL_TREND_SEARCH_RESULTS_LOADING
    ],
  loadingList: status,
})
