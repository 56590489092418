import { Map } from 'immutable'
import { mapKeys, camelCase, snakeCase, isEmpty } from 'lodash'

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

/**
 * function used to clean the strings for any anchor tags or data enclosed in anchors
 * @param {string} data
 * @returns
 */
export const cleanUpAnchorBrackets = (data) => {
  return data.replace(/<[^>]*>/g, '')
}

export const snakeToCamelObjKeys = (obj) =>
  mapKeys(obj, (value, key) => camelCase(key))

/**
 * Used to rename multiple keys from an object
 * @param {Object} keysMap Object of key value pairs in which, key will be the old key and value will be the new key
 * @param {Object} obj Actual object
 * @returns {Object} renamed keys object
 */
export const renameKeysOfObject = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [keysMap[key] || key]: obj[key],
    }),
    {},
  )

/**
 * Used to convert camelCase object keys to snake_case
 * @param {Object} obj Actual object
 * @param {Object} keysMap Object of key value pairs in which, key will be the old key and value will be the new key
 * @returns {Object} object containing renamed snake_case keys
 */
export const camelToSnakeObjKeys = (obj, keysMap) => {
  let objectToChange = { ...obj }
  if (!isEmpty(keysMap)) {
    objectToChange = renameKeysOfObject(keysMap, objectToChange)
  }
  return mapKeys(objectToChange, (value, key) => snakeCase(key))
}

/**
 * capitalize the first letter of word and all other letters to lowercase
 * @param {*} value
 * @returns
 */
export const convertTextToTitleCase = (value) => {
  return value && value[0].toUpperCase() + value.slice(1).toLowerCase()
}

export const getValueFromURL = (query, val) => {
  return new URLSearchParams(query).has(val)
    ? new URLSearchParams(query).get(val)
    : ''
}
export const amplify = (ampiEvent, ampiUserData, userEmail) => {
  if (userEmail) {
    // Sanitize amplitude event properties and event name
    ampiEvent = capitalCaseFormatter(
      ampiEvent
        .split('_')
        .join(' ')
        .trim(),
      '_',
    )
    ampiUserData = Object.keys(ampiUserData).reduce(
      (acc, key) => ({
        ...acc,
        [capitalCaseFormatter(
          key
            .split('_')
            .join(' ')
            .trim(),
          ' ',
        )]:
          typeof ampiUserData[key] === 'string'
            ? capitalCaseFormatter(ampiUserData[key], ' ')
            : ampiUserData[key],
      }),
      {},
    )
    if ('Maturity Phase' in ampiUserData) {
      ampiUserData['Maturity Phase'] =
        typeof ampiUserData['Maturity Phase'] === 'string'
          ? getCapitalzedText(ampiUserData['Maturity Phase']?.toLowerCase())
          : ampiUserData['Maturity Phase']
    }

    if ('Lens' in ampiUserData) {
      if (ampiUserData.Lens?.toLowerCase().includes('ingredient')) {
        ampiUserData.Lens = 'Ingredient'
      } else if (ampiUserData.Lens?.toLowerCase().includes('product')) {
        ampiUserData.Lens = 'Product'
      } else if (ampiUserData.Lens?.toLowerCase().includes('theme')) {
        ampiUserData.Lens = 'Theme'
      }
    }
    if (isProduction()) {
      // Log non aipalette users on production
      if (!isAipaletteUser(userEmail)) {
        // eslint-disable-next-line
        amplitude.getInstance().logEvent(ampiEvent, ampiUserData)
      }
    } else {
      // Log all users if not production
      amplitude.getInstance().logEvent(ampiEvent, ampiUserData) // eslint-disable-line
    }
  }
}

export const arrayToObject = (obj) =>
  obj.reduce((acc, message) => ({ ...acc, [message.id]: message }), {})

/**
 * Used to compare two arrays of objects
 * @param firstArrOfObjects {Array}
 * @param secondArrOfObjects {Array}
 * @returns {boolean}
 */
export const compareTwoArrayOfObjects = (
  firstArrOfObjects,
  secondArrOfObjects,
) => {
  return (
    firstArrOfObjects.length === secondArrOfObjects.length &&
    firstArrOfObjects.every((objectOfFirst) =>
      secondArrOfObjects.some((objectOfSecond) =>
        Object.keys(objectOfFirst).every(
          (key) => objectOfFirst[key] === objectOfSecond[key],
        ),
      ),
    )
  )
}

export const randomId = () => Math.floor(Date.now() + Math.random())

export function arrToMap(arr, DataModel) {
  return arr.reduce(
    (acc, item) => acc.set(item.id, DataModel ? new DataModel(item) : item),
    new Map({}),
  )
}

// Safari, Chrome, Firefox based classes
export function getColBrowserSpecificClass(
  colBrowserClass = '',
  defaultColVal = '8',
  safariColVal = '6',
) {
  const userAgentString = window.navigator.userAgent
  const chromeAgent = userAgentString.indexOf('Chrome') > -1
  const safariAgent = userAgentString.indexOf('Safari') > -1

  if (chromeAgent) {
    colBrowserClass = colBrowserClass.replace(/\$\$/gi, defaultColVal) // 'col-md-8 col-sm-8';
  } else if (safariAgent) {
    // Chrome, Opera, IE, Firefox
    colBrowserClass = colBrowserClass.replace(/\$\$/gi, safariColVal) // 'col-md-6 col-sm-6';
  } else {
    colBrowserClass = colBrowserClass.replace(/\$\$/gi, defaultColVal) // 'col-md-8 col-sm-8';
  }
  return colBrowserClass
}

export function getUrlSelectedLens(props, titleCase = true) {
  let urlLensSelected = props.match.params.urlLensSelected
  if (titleCase) {
    urlLensSelected =
      urlLensSelected[0].toUpperCase() + urlLensSelected.slice(1)
  }
  return urlLensSelected
}

export const getQueryParams = (props) => {
  const location =
    props && props.location ? props.location.search : window.location.search
  return new URLSearchParams(location)
}

export function getPercentage(percentageScoreCurrent, deepCheck = false) {
  percentageScoreCurrent = (parseFloat(percentageScoreCurrent) || 0.0).toFixed(
    2,
  )
  var displayedPercentageScoreCurrent = percentageScoreCurrent
  if (deepCheck && percentageScoreCurrent == 0.0) {
    displayedPercentageScoreCurrent = '~0.01'
    percentageScoreCurrent = 0.01
  }
  return {
    percentage_score_current: percentageScoreCurrent,
    displayed_percentage_score_current: displayedPercentageScoreCurrent,
  }
}

export function browserIsIE() {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ')
  // eslint-disable-next-line
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    // console.log('You are accessing site on IE')
    return true
  }
  return false
}

export function browserIsSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export default function getScore(el, key = 'percentage_value') {
  var score = el[key] // el.percentage_score_current / el.percentage_value

  if (el.first_week_percentage_score !== undefined) {
    if (
      el.first_week_percentage_score &&
      Number(el.first_week_percentage_score) != 0
    ) {
      score = el.first_week_percentage_score
    }
  } else if (el.second_week_percentage_score !== undefined) {
    if (Number(el.second_week_percentage_score) != 0) {
      score = el.second_week_percentage_score
    }
  }

  if (typeof score === 'string') {
    return Number(Number(score).toFixed(2))
  } else if (typeof score === 'number') {
    return Number(score.toFixed(2))
  }
  return 0 // null, undefined
}

/**
 * Determine if current environment is Production or not
 * @returns {boolean}
 */
export function isProduction() {
  return process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_PRODUCTION
}

/**
 * Determine if current environment is Staging or not
 * @returns {boolean}
 */
export function isStaging() {
  return process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_STAGING
}

/**
 * Determine if current environment is Development or not
 * @returns {boolean}
 */
export function isDevelopment() {
  return process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_DEVELOPMENT
}

/**
 * Determine if user is a member of Ai palette or not
 * @param email {string}
 * @returns {boolean}
 */
export function isAipaletteUser(email) {
  return email?.split('@')[1] === 'aipalette.com'
}

/**
 * Calculate  display width of text
 * @param text {string}
 * @param fontSize {Number}
 * @returns {string}
 */
export function calcDisplayedWidth(
  text,
  fontSize = 16,
  fontFamily,
  fontWeight,
  fontStyle,
) {
  // Calculate width to position the labels correctly next to data points
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.fontSize = `${fontSize}px`
  context.fontFamily = `${fontFamily}`
  context.fontWeight = `${fontWeight}`
  context.fontStyle = `${fontStyle}`
  return context.measureText(text).width
}

export function getAsProductFormatList(productFormatObj) {
  // Product Comparison - Retail & Food Service Products
  productFormatObj = productFormatObj || {}
  const productFormatList = []
  for (const formatName in productFormatObj) {
    productFormatList.push({
      name: formatName,
      percentage_value: productFormatObj[formatName],
    })
  }
  productFormatList.sort((format1, format2) => {
    return format2.percentage_value - format1.percentage_value
  })
  return productFormatList
}

/**
 * Formats input value to string
 * with it's respective tens place
 * @param count {Number}
 * @returns {string}
 */
export function getConciseCount(count) {
  const countFloat = parseFloat(count)
  if (countFloat > 1000000) return (countFloat / 1000000).toFixed(0) + ' Mn'
  else if (countFloat > 1000) return (countFloat / 1000).toFixed(0) + 'k'
  else return parseInt(count) + ' '
}

/**
 * Decodes query parameter for
 * given key
 * @param queryParam {Number}
 * @returns {string}
 */
export const getDecodedQueryParams = (queryParam) => {
  const queryParamVal = getQueryParams().get(queryParam)
  let decodedString = null
  if (queryParam) {
    try {
      decodedString = decodeURIComponent(queryParamVal)
    } catch (er) {
      console.log(er)
    }
  }
  return decodedString
}

/**
 * Formats given string to
 * CapitalCase with given connector
 * @param value {String}
 * @returns {string}
 */
export function capitalCaseFormatter(value, connector = '') {
  return value
    .split(' ')
    .map((name) => getCapitalzedText(name))
    .join(connector)
}

/**
 * Formats string to remove whitespace
 * characters at the beginning
 * and capitalizes it
 * @param value {String}
 * @returns {string}
 */
export function formatString(value, upperCase) {
  const regex = /^\s*/
  const inputValue = value.replace(regex, '')
  return inputValue
    ? upperCase
      ? inputValue[0].toUpperCase() + inputValue.slice(1)
      : inputValue
    : ''
}

/**
 * Capitalize first character of string
 * @param value {String}
 * @returns {string}
 */
export function getCapitalzedText(value) {
  return value ? value[0].toUpperCase() + value.slice(1) : ''
}

/**
 * Checks if enter key was clicked
 * @param {Event object} ev
 */
export function isEnterClicked(ev) {
  return ev.key === 'Enter' || ev.keyCode === 13
}

export function getTextDimensions(text, font) {
  const { size, family } = font
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.font = `${size}px ${family}`
  return ctx.measureText(text)
}

export function dataOverlaps(rect1, rect2) {
  const { x: x1, y: y1 } = rect1
  const { x: x2, y: y2 } = rect2
  const xOverlap = Math.max(Math.min(x1[1], x2[1]) - Math.max(x1[0], x2[0]), 0)
  const yOverlap = Math.max(Math.min(y1[1], y2[1]) - Math.max(y1[0], y2[0]), 0)
  return Boolean(xOverlap * yOverlap)
}

/**
 * Merges all the contexts based on key
 * @param {Array} contxtsList
 * @param {String} order
 * @returns Object
 */
export const mergeContextMaps = (contxtsList, order = 'left') => {
  return (order === 'left' ? contxtsList : [...contxtsList].reverse()).reduce(
    (mergedCtx, contxt) => {
      if (contxt) {
        return {
          ...mergedCtx,
          ...contxt,
          ...Object.entries(contxt).reduce(
            (newMergedCtx, [ctxKey, ctxRelations]) => {
              if (mergedCtx[ctxKey]) {
                newMergedCtx[ctxKey] = [...mergedCtx[ctxKey], ...ctxRelations]
              }
              return newMergedCtx
            },
            {},
          ),
        }
      }
      return { ...mergedCtx }
    },
    {},
  )
}

/**
 * Adds sign and rounds off the number to
 * the nearest decimal value upto the number of
 * digits provided.
 * @param {Number} num
 * @param {int} decimalDigitsCount
 * @returns
 */
export const getSignedFloat = (num, decimalDigitsCount = 0) => {
  const floatVal = Number(num).toFixed(decimalDigitsCount)
  return num >= 0 ? `+${floatVal}` : floatVal
}

/**
 * Formats numberical value to the nearest
 * thousands, or millions.
 * @param {Number} n
 * @returns {string}
 */
export const formatNumber = (n) => {
  const x = Math.abs(n)
  if (x < 1e3) return n
  if (x >= 1e3 && x < 1e6) return `${+(n / 1e3).toFixed(1)}K`
  if (x >= 1e6 && x < 1e9) return `${+(n / 1e6).toFixed(1)}M`
  if (x >= 1e9 && x < 1e12) return `${+(n / 1e9).toFixed(1)}B`
  if (x >= 1e12) return `${+(n / 1e12).toFixed(1)}T`
  return Infinity
}

function descendingComparator(firstVal, secondVal) {
  if (secondVal < firstVal) {
    return -1
  }
  if (secondVal > firstVal) {
    return 1
  }
  return 0
}

export const sortList = (first, second, order) => {
  return (order === 'desc' ? 1 : -1) * descendingComparator(first, second)
}

/**
 * Sorting helper function
 * @param {*} order
 * @param {*} orderBy
 * @returns
 */
export function getComparator(order, orderBy) {
  return (a, b) => sortList(a[orderBy], b[orderBy], order)
}

/**
 *
 * @param {*} rowData
 * @param {*} tableHeadCells
 * @returns
 */
export const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (rowData != null) {
      return func ? func(rowData) : rowData
    }
  })
  return res
}

export const addLabelKey = (list, setGenerator) =>
  Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
    label: item,
    key: id,
  }))

/**
 * Formats number to the number places
 * @param {*} num
 * @returns formatted number
 */
export function signedFormattedNumber(num) {
  const n = Math.abs(num)
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return `${+(num / 1e3).toFixed(1)}K`
  if (n >= 1e6 && n < 1e9) return `${+(num / 1e6).toFixed(1)}M`
  if (n >= 1e9 && n < 1e12) return `${+(num / 1e9).toFixed(1)}B`
  if (n >= 1e12) return `${+(num / 1e12).toFixed(1)}T`
  return Infinity
}

/**
 * Formats array of strings to add comma and an and text with
 * additional words to form a sentence.
 * @param {Array} arr
 * @param {String} singularWord
 * @param {String} pluralWord
 * @returns {String}
 */
export function addCommaAnd(arr, singularWord = '', pluralWord = '') {
  const arrLen = arr.length
  return arrLen > 1
    ? `${arr.slice(0, arrLen - 1).join(',')} and ${arr.slice(
        -1,
      )} ${pluralWord}`.trim()
    : `${arr.join()} ${singularWord}`.trim()
}

/**
 * Get current Operating System
 * @returns {String}
 */
export const currentOS = () => {
  const userAgent = window.navigator.userAgent
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

/**
 * Make An Object Unique By Key
 * @param {Array} arr
 * @param {String} key
 * @returns {Array}
 */
export const makeUniqueByKey = (arr, key) => {
  const uniqueArray = []
  const keys = []

  arr.forEach((item) => {
    if (!keys.includes(item[key])) {
      keys.push(item[key])
      uniqueArray.push(item)
    }
  })

  return uniqueArray
}

/**
 * Extract substring between two words
 * @param {String} str
 * @param {String} start
 * @param {String} end
 * @returns
 */
export const extractSubString = (str, start, end) => {
  const regex = new RegExp(`${start}([\\s\\S]*?)${end}`)
  const result = str.match(regex)
  return result ? result[1] : ''
}

/**
 *
 * @param {*} targetNode // This will be DOM Element
 * @param {String} targetId
 * @param {Int} stopTime
 * @param {String} type
 * @returns
 */
export const domUpdateCheck = (
  targetNode,
  targetId,
  stopTime,
  type = 'childList',
) => {
  return new Promise((resolve, reject) => {
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    }
    const callback = function(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === type && mutation.target.id === targetId) {
          resolve()
        }
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
    setTimeout(() => {
      observer.disconnect()
    }, stopTime)
  })
}

/**
 * Converts a given number to its nearest lower 10s value if it's below 1000
 * and then to its nearest lower 100 value if it's above that.
 * @param {number} num - The number to convert.
 * @returns {number} - The converted number.
 */
export function convertToNearest10s100s(num) {
  if (num < 1000) {
    return Math.floor(num / 10) * 10
  } else {
    return Math.floor(num / 100) * 100
  }
}

/**
 *
 * @param {String} str
 * @returns
 */
export function unabbreviateNumber(str) {
  const unit = ['k', 'm', 'b', 't', 'q']
  var lastLetter = str
    .toString()
    .slice(str.length - 1, str.length)
    .toLowerCase()

  if (isNaN(lastLetter)) {
    str = str.replace(/ /g, '') //  removes all spaces
    str = str.replace(/\$/g, '') // removes dollar sign

    const zOutput = parseFloat(str.slice(0, str.length - 1))
    const zMultiplier = unit.findIndex((x) => x == lastLetter) + 1
    return zOutput * 1000 ** zMultiplier
  } else {
    return str
  }
}

/**
 * Calculates CAGR
 * @param {Number} coefficient
 * @param {Number} intercept
 * @param {Number} numMonths
 * @returns {Number}
 */
export function cagrcalculation(coefficient, intercept, numMonths = 48) {
  let cagr = 0
  let currMonth = numMonths
  try {
    let yMin = intercept
    let yMax = intercept + coefficient * numMonths
    if (yMax && yMin) {
      if (yMin <= 0 || yMax <= 0) {
        let zeroVal = -intercept / (coefficient || 1)
        if (yMin <= 0) {
          zeroVal = Math.ceil(zeroVal)
          yMin = intercept + coefficient * zeroVal
          currMonth = numMonths - zeroVal
        } else {
          zeroVal = Math.floor(zeroVal)
          yMax = intercept + coefficient * zeroVal
          currMonth = zeroVal
        }
      }
      cagr = ((Math.pow(yMax / (yMin || 1), 12 / currMonth) - 1) * 100).toFixed(
        2,
      )
    } else {
      cagr = 0
    }
  } catch (error) {
    console.log('Error', error)
  }
  return `${cagr}`
}

/**
 *
 * @param {Ref} chipsContainerRef
 * @returns
 */
export const detectWrap = (chipsContainerRef) => {
  if (chipsContainerRef?.current !== null) {
    const chipsArray = Array.from(chipsContainerRef.current.children)
    let previousTop = chipsArray[0]?.getBoundingClientRect()?.top
    let wrapped = false

    for (let i = 1; i < chipsArray.length; i++) {
      const currentTop = chipsArray[i]?.getBoundingClientRect()?.top
      if (currentTop > previousTop) {
        wrapped = true
        break
      }
      previousTop = currentTop
    }

    return wrapped
  }
  return false
}
