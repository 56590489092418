import * as AC from '../actions/actionConstants'

const roleReducer = (state = {
  all: [],
  roleList: true,
  roleForm: false,

}, action) => {
  switch (action.type) {
    case AC.ADD_ALL_ROLES:
      return Object.assign({}, state, { all: action.roles })
    case AC.ADD_ROLE:
      return Object.assign({}, state, { all: [...state.all, action.role] })
    case AC.EDIT_ROLE:
      return Object.assign({}, state, { all: state.all.map(item => item.id === action.role.id ? action.role : item) })
    case AC.DELETE_ROLE:
      return Object.assign({}, state, { all: state.all.filter(item => item.id !== action.roleID) })

    case AC.SHOW_ROLE_FORM:
      return Object.assign({}, state, { roleForm: true })

    case AC.HIDE_ROLE_FORM:
      return Object.assign({}, state, { roleForm: false })

    case AC.HIDE_ROLE_LIST:
      return Object.assign({}, state, { roleList: false })

    case AC.SHOW_ROLE_LIST:
      return Object.assign({}, state, { roleList: true })

    default:
      return state
  }
}

export default roleReducer
