import * as React from 'react'
import { useEffect } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { Typography, TextField, InputLabel } from '@mui/material'
import RangeSlider from '../../../../components/RangeSlider'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { convertToNearest10s100s } from '../../../../store/utility'
import formatNumber from '../../../../shared/utils/formatNumber'

const placeHolderColor = {
  color: '#8997A6',
}

const dataPointOptions = ['All', '>=10K', '>=100K', '>=1M']
const cagrOptions = [5, 10, 20, 30]
const defaultOptions = [0, 5, 10, 20, 30, 50]

const generateSegments = (min, max, count) => {
  const options = []

  const stepSize = (max - min) / (count - 1)
  for (let i = 0; i < count; i++) {
    const value = min + i * stepSize
    options.push(convertToNearest10s100s(value))
  }
  options.pop()
  return options
}

const appendFormattedPercent = (value) => {
  return `${formatNumber(value)}%`
}

const appendFormattedGte = (value) => {
  return `>=${formatNumber(value)}`
}

export default function TrendFilter(props) {
  const {
    trends,
    selectedTwoYearDP,
    setSelectedTwoYearDP,
    selectedFourYearDP,
    setSelectedFourYearDP,
    selectedTwoYearCagr,
    setSelectedTwoYearCagr,
    selectedFourYearCagr,
    setSelectedFourYearCagr,
    filterRetailValues: selectedRetailValue,
    setFilterRetailValues: setSelectedRetailValue,
    filterFsValues: selectedFsValue,
    setFilterFSValues: setSelectedFsValue,
    visualisationMode,
    twoYearCagrOptions,
    setTwoYearCagrOptions,
    fourYearCagrOptions,
    setFourYearCagrOptions,
    twoYearDpOptions,
    setTwoYearDpOptions,
    fourYearDpOptions,
    setFourYearDpOptions,
    fsValueOption,
    setFsValueOption,
    retailValueOption,
    setRetailValueOption,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    props.logFilterClick()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls={open ? 'trend-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        startIcon={
          <FilterAltOutlinedIcon
            sx={{ color: ({ palette }) => palette.primary.main }}
          />
        }
        sx={{
          border: ({ palette }) => `1px solid ${palette.primary.main}`,
          marginRight: 2,
        }}
      >
        Filters
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          padding: 8,
          marginLeft: 12,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${
            props.lens === 'Ingredient' ? 3 : 2
          }, 1fr)`}
          gridGap={8}
          alignItems="center"
          sx={{ padding: 2, gridColumnGap: '30px', pr: 4 }}
        >
          <FormControl
            variant="outlined"
            sx={{
              m: 1,
              flex: 1,
              minWidth: 200,
            }}
          >
            <Typography sx={{ paddingBottom: 1 }}>
              4 Year Data Points
            </Typography>
            <Select
              size={'large'}
              displayEmpty
              defaultValue={selectedFourYearDP}
              disableFilter
              sx={{
                '.MuiSelect-select': {
                  marginRight: selectedFourYearDP && '-50px',
                },
              }}
              endAdornment={
                selectedFourYearDP ? (
                  <IconButton
                    onClick={() => setSelectedFourYearDP('')}
                    size="small"
                    sx={{
                      height: '10px',
                      width: '10px',
                      mr: 3,
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }
              value={selectedFourYearDP}
              onChange={(e) => {
                if (e.target.value || e.target.value === 0) {
                  setSelectedFourYearDP(e.target.value)
                }
              }}
            >
              <MenuItem value="" disabled style={{ display: 'none' }}>
                <em style={placeHolderColor}>Filter By 4 Year Data Points</em>
              </MenuItem>

              <MenuItem value={null} onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  type="number"
                  fullWidth
                  placeholder="Hit ⏎ Enter to add value"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      let val = parseInt(e.target.value)
                      if (val || val === 0) {
                        if (val < 0) {
                          val = 'All'
                          setSelectedFourYearDP(val)
                        }
                        setFourYearDpOptions([...twoYearDpOptions, val])
                        setSelectedFourYearDP(val)
                      }
                    }
                  }}
                />
              </MenuItem>
              {[...new Set(fourYearDpOptions)].map((dp) => {
                return (
                  <MenuItem value={dp} key={dp + '-4ydp'}>
                    {dp === 'All' ? 'All' : `>=${formatNumber(dp)}`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              m: 1,
              flex: 1,
              minWidth: 200,
            }}
          >
            <Typography sx={{ paddingBottom: 1 }}>
              2 Year Data Points
            </Typography>
            <Select
              size={'large'}
              displayEmpty
              defaultValue={selectedTwoYearDP}
              disableFilter
              sx={{
                '.MuiSelect-select': {
                  marginRight: selectedTwoYearDP && '-50px',
                },
              }}
              endAdornment={
                selectedTwoYearDP ? (
                  <IconButton
                    onClick={() => setSelectedTwoYearDP('')}
                    size="small"
                    sx={{
                      height: '10px',
                      width: '10px',
                      mr: 3,
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }
              value={selectedTwoYearDP}
              onChange={(e) => {
                if (e.target.value || e.target.value === 0) {
                  setSelectedTwoYearDP(e.target.value)
                }
              }}
            >
              <MenuItem value="" disabled style={{ display: 'none' }}>
                <em style={placeHolderColor}>Filter By 2 Year Data Points</em>
              </MenuItem>
              <MenuItem value={null} onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  type="number"
                  fullWidth
                  placeholder="Hit ⏎ Enter to add value"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      let val = parseInt(e.target.value)
                      if (val || val === 0) {
                        if (val < 0) {
                          val = 0
                          setSelectedTwoYearDP(val)
                        }
                        setTwoYearDpOptions([...twoYearDpOptions, val])
                        setSelectedTwoYearDP(val)
                      }
                    }
                  }}
                />
              </MenuItem>
              {[...new Set(twoYearDpOptions)].map((dp) => {
                return (
                  <MenuItem value={dp} key={dp + '-2ydp'}>
                    {dp === 'All' ? 'All' : `>=${formatNumber(dp)}`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {props.lens === 'Ingredient' && props.projectVariant === 'food' && (
            <FormControl
              variant="outlined"
              sx={{
                m: 1,
                flex: 1,
                minWidth: 200,
              }}
            >
              <Typography sx={{ paddingBottom: 1 }}>
                #Food Service Product
              </Typography>
              <Select
                size={'large'}
                displayEmpty
                endAdornment={
                  selectedFsValue || selectedFsValue === 0 ? (
                    <IconButton
                      onClick={() => setSelectedFsValue(null)}
                      size="small"
                      sx={{ height: '10px', width: '10px', mr: 2 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null
                }
                defaultValue={selectedFsValue}
                value={selectedFsValue}
                onChange={(e) => {
                  if (e.target.value || e.target.value === 0) {
                    setSelectedFsValue(e.target.value)
                  }
                }}
              >
                <MenuItem value={null} style={{ display: 'none' }}>
                  <em style={placeHolderColor}>
                    Filter By #Food Service Product
                  </em>
                </MenuItem>
                <MenuItem value={''} onKeyDown={(e) => e.stopPropagation()}>
                  <TextField
                    type="number"
                    fullWidth
                    placeholder="Hit ⏎ Enter to add value"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        let val = e.target.value
                        if (val || val === 0) {
                          if (val < 0) {
                            val = 0
                          }
                          setSelectedFsValue(val)
                          setFsValueOption([...fsValueOption, parseInt(val)])
                        }
                      }
                    }}
                  />
                </MenuItem>
                {[...new Set(fsValueOption)].map((fsValue) => {
                  return (
                    <MenuItem value={fsValue} key={fsValue + 'fs-value'}>
                      {fsValue ? `>=${fsValue}` : 'All'}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
          <FormControl
            variant="outlined"
            sx={{
              m: 1,
              flex: 1,
              minWidth: 200,
            }}
          >
            <Typography sx={{ paddingBottom: 1 }}>4 Year CAGR</Typography>
            <Select
              size={'large'}
              displayEmpty
              sx={{
                '.MuiSelect-select': {
                  marginRight: selectedFourYearCagr && '-50px',
                },
              }}
              endAdornment={
                selectedFourYearCagr ? (
                  <IconButton
                    onClick={() => setSelectedFourYearCagr('')}
                    size="small"
                    sx={{
                      height: '10px',
                      width: '10px',
                      mr: 3,
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }
              defaultValue={selectedFourYearCagr}
              value={selectedFourYearCagr}
              onChange={(e) => {
                if (e.target.value || e.target.value === 0) {
                  setSelectedFourYearCagr(e.target.value)
                }
              }}
            >
              <MenuItem value="" style={{ display: 'none' }}>
                <em style={placeHolderColor}>Filter By 4 Year CAGR</em>
              </MenuItem>
              <MenuItem value={null} onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  type="number"
                  fullWidth
                  placeholder="Hit ⏎ Enter to add value"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const val = e.target.value
                      if (val || val === 0) {
                        setSelectedFourYearCagr(val)
                        setFourYearCagrOptions([
                          ...fourYearCagrOptions,
                          parseInt(val),
                        ])
                        setSelectedFourYearCagr(val)
                      }
                    }
                  }}
                />
              </MenuItem>
              {[...new Set(fourYearCagrOptions)].map((cagr) => {
                return (
                  <MenuItem value={cagr} key={cagr + '-4ycagr'}>
                    {`>=${cagr}%`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              m: 1,
              flex: 1,
              minWidth: 200,
            }}
          >
            <Typography sx={{ paddingBottom: 1 }}>2 Year CAGR</Typography>
            <Select
              size="large"
              displayEmpty
              sx={{
                '.MuiSelect-select': {
                  marginRight: selectedTwoYearCagr && '-50px',
                },
              }}
              endAdornment={
                selectedTwoYearCagr ? (
                  <IconButton
                    onClick={() => setSelectedTwoYearCagr('')}
                    size="small"
                    sx={{
                      height: '10px',
                      width: '10px',
                      mr: 3,
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }
              defaultValue={selectedTwoYearCagr}
              value={selectedTwoYearCagr}
              onChange={(e) => {
                if (e.target.value || e.target.value === 0) {
                  setSelectedTwoYearCagr(e.target.value)
                }
              }}
            >
              <MenuItem value="" style={{ display: 'none' }}>
                <em style={placeHolderColor}>Filter By 2 Year CAGR</em>
              </MenuItem>
              <MenuItem value={null} onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  type="number"
                  fullWidth
                  placeholder="Hit ⏎ Enter to add value"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const val = parseInt(e.target.value)
                      if (val || val === 0) {
                        setSelectedTwoYearCagr(val)
                        setTwoYearCagrOptions([
                          ...twoYearCagrOptions,
                          parseInt(val),
                        ])
                        setSelectedTwoYearCagr(val)
                      }
                    }
                  }}
                />
              </MenuItem>
              {[...new Set(twoYearCagrOptions)].map((cagr) => {
                return (
                  <MenuItem value={cagr} key={cagr + '-2ycagr'}>
                    {`>=${cagr}%`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {props.lens === 'Ingredient' && (
            <FormControl
              variant="outlined"
              sx={{
                m: 1,
                flex: 1,
                minWidth: 200,
              }}
            >
              <Typography sx={{ paddingBottom: 1 }}>#Retail Product</Typography>
              <Select
                size={'large'}
                displayEmpty
                endAdornment={
                  selectedRetailValue || selectedRetailValue === 0 ? (
                    <IconButton
                      onClick={() => setSelectedRetailValue(null)}
                      size="small"
                      sx={{ height: '10px', width: '10px', mr: 2 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null
                }
                defaultValue={selectedRetailValue}
                value={selectedRetailValue}
                onChange={(e) => {
                  if (e.target.value || e.target.value === 0) {
                    setSelectedRetailValue(e.target.value)
                  }
                }}
              >
                <MenuItem value={null} style={{ display: 'none' }}>
                  <em style={placeHolderColor}>Filter By #Retail Product</em>
                </MenuItem>
                <MenuItem value={''} onKeyDown={(e) => e.stopPropagation()}>
                  <TextField
                    type="number"
                    fullWidth
                    placeholder="Hit ⏎ Enter to add value"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        let val = e.target.value
                        if (val || val === 0) {
                          if (val < 0) {
                            val = 0
                          }
                          setSelectedRetailValue(val)
                          setRetailValueOption([
                            ...retailValueOption,
                            parseInt(val),
                          ])
                        }
                      }
                    }}
                  />
                </MenuItem>
                {[...new Set(retailValueOption)].map((retailValue) => {
                  return (
                    <MenuItem
                      value={retailValue}
                      key={retailValue + 'retail-value'}
                    >
                      {retailValue ? `>=${retailValue}` : 'All'}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ margin: 1, height: '40px', ml: 3 }}
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', margin: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ margin: 1, height: '40px' }}
              onClick={() => {
                handleClose()
                setSelectedFourYearDP('')
                setSelectedTwoYearDP('')
                setSelectedFourYearCagr('')
                setSelectedTwoYearCagr('')
                setSelectedFsValue(null)
                setSelectedRetailValue(null)
                props.onReset({})
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              sx={{ margin: 1, height: '40px' }}
              onClick={() => {
                handleClose()
                props.onFilter({
                  two_year_dp: selectedTwoYearDP,
                  four_year_dp: selectedFourYearDP,
                  two_year_cagr: selectedTwoYearCagr,
                  four_year_cagr: selectedFourYearCagr,
                  food_products_count: selectedFsValue,
                  retail_products_count: selectedRetailValue,
                })
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  )
}
