import { conceptGenie as conceptGenieActionTypes } from './actionTypes'

export const setCGSelectedConcepts = (data, reset) => {
  return (dispatch) => {
    if (reset) {
      dispatch(resetCGSelectedConcepts())
    } else {
      dispatch(updateCGSelectedConcepts(data))
    }
  }
}

export const resetCGSelectedConcepts = () => {
  return {
    type:
      conceptGenieActionTypes.type[
        conceptGenieActionTypes.RESET_CG_SELECTED_CONCEPTS
      ],
  }
}
export const updateCGSelectedConcepts = (selectedConcepts) => {
  return {
    type:
      conceptGenieActionTypes.type[
        conceptGenieActionTypes.CG_SELECTED_CONCEPTS
      ],
    selectedConcepts: selectedConcepts,
  }
}

export const setCGConceptsModified = (data) => {
  return (dispatch) => {
    dispatch(modifyCGSelectedConcepts(data))
  }
}

export const modifyCGSelectedConcepts = (selectedConcepts) => {
  return {
    type:
      conceptGenieActionTypes.type[
        conceptGenieActionTypes.CG_MODIFIED_CONCEPTS
      ],
    selectedConcepts: selectedConcepts,
  }
}
