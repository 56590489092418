export const getFilterLabels = (idOfItem, stateToCheck, appliedFilterIds) => {
  const childIds = stateToCheck[idOfItem].childIds
  if (childIds?.length) {
    const childIdsInAppliedFilters = childIds.filter((idOfChild) =>
      appliedFilterIds.includes(idOfChild),
    )
    if (childIds.length === childIdsInAppliedFilters.length) {
      return `${stateToCheck[idOfItem].label} (All)`
    }
    const MAX_SUB_LABELS = 2
    const overflow = childIdsInAppliedFilters.length > MAX_SUB_LABELS
    const subLabel = overflow
      ? `${childIdsInAppliedFilters
          .slice(0, MAX_SUB_LABELS)
          .map((idOfItem) => stateToCheck[idOfItem].label)
          .join(', ')}...`
      : childIdsInAppliedFilters
          .map((idOfItem) => stateToCheck[idOfItem].label)
          .join(', ')

    return `${stateToCheck[idOfItem].label}: ${subLabel}`
  }
  return stateToCheck[idOfItem].label
}
