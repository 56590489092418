import React from 'react'
import { Box } from '@mui/system'
import { TrendGrowthCardContainer } from './styles'
import MonthlyTrendsMui from '../../../components/Products/ProductDetailsPage/ProductDetailsInfo/Charts/MonthlyTrendsMui/MonthlyTrendsMui'

const TrendGrowthCard = (props) => {
  return (
    <TrendGrowthCardContainer>
      <Box sx={{ p: !props.isTrendInfoPage && 2 }}>
        {props.topicChart && (
          <MonthlyTrendsMui
            noConf={true}
            topicChart={true}
            trendName={props.details.Keyword}
            trendData={props.details.needChart}
            shouldModifyData
            toggleImageDownload={props.toggleImageDownload}
            trendCoefficient={props.details.trendCoefficient}
            trendIntercept={props.details.trendIntercept}
            trendCoefficientIntercept={props.details.trendCoefficientIntercept}
            informationBox={true}
            lens={props.lens}
          />
        )}
        {props.needChart && (
          <MonthlyTrendsMui
            noConf={true}
            needChart={true}
            showGrowthChip={props.showGrowthChip}
            twoYearCagr={props.details.twoYearCagr}
            trendName={props.details.Keyword}
            trendData={props.details.needChart}
            shouldModifyData
            informationBox={true}
            toggleImageDownload={props.toggleImageDownload}
            trendCoefficient={props.details.trendCoefficient}
            trendIntercept={props.details.trendIntercept}
            trendCoefficientIntercept={props.details.trendCoefficientIntercept}
            largeView={props.chartLargeView}
            lens={props.lens}
          />
        )}
        {props.isTrendInfoPage && (
          <MonthlyTrendsMui
            small
            isTrendInfoPage={true}
            noConf={true}
            showTwoYearCagr={true}
            twoYearCagr={props.twoYearCagr}
            trendName={props.trendName}
            nameOfTrend={props.trendName}
            trendData={props.details.social_data}
            shouldModifyData
            informationBox={true}
            dataPoint={props.dataPoint}
            trendCoefficient={props.details.four_year_coef}
            trendIntercept={props.details.four_year_intercept}
            trendCoefficientIntercept={props.details.trendCoefficientIntercept}
            largeView={true}
            lens={props.lensSelected}
            showResponseCagr={false}
            fourYearCagr={props.fourYearCagr}
          />
        )}
        {!props.needChart && !props.topicChart && !props.isTrendInfoPage && (
          <MonthlyTrendsMui
            small={props.small}
            trendName={props.details.trendName}
            nameOfTrend={props.details.name}
            needChart={false}
            topicChart={false}
            yoyGrowthRate={
              isNaN(Math.abs(props.details.yoyGrowthRate))
                ? 0
                : Number(props.details.yoyGrowthRate)
            }
            trendData={props.details.trendChart || []}
            predictiveData={props.details.predictiveTrendChart}
            socialData={props.details.socialData}
            webSearchData={props.details.webSearchData}
            trademediaData={props.details.trademediaData}
            patentData={props.details.patentData}
            salesData={props.details.salesData}
            otherData={props.details.otherData}
            retailCountsData={props.details.retailCountsData}
            dataPoint={props.details.dataPoint}
            youtubeDataChart={props.details.youtubeDataChart}
            predictive_feature={props.details.predictive_feature}
            salesFeature={props.details.salesFeature}
            splitFeature={props.details.splitFeature}
            predictiveTrendGrowthRate={props.details.predictiveTrendGrowthRate}
            overallGrowthRate={props.details.overallGrowthRate}
            shouldModifyData={true}
            canDownload={true}
            showResponseCagr={true}
            showTwoYearCagr
            showSubCategoryData={props.showSubCategoryData || false}
            subCatClassification={props.details.filterClassification}
            trendId={props.details.id}
            fourYearCagr={props.details.fourYearCagr}
            twoYearCagr={props.details.twoYearCagr}
            informationBox={true}
            showConfidence={true}
            showSalesDropdown={true}
            showChartDownloadIcon
            momGrowth={props.details.momGrowthRate}
            yoyGrowth={props.details.yoyGrowthRate}
            trendStatus={props.details.trendStatus}
            trendCoefficient={props.details.trendCoefficient}
            trendIntercept={props.details.trendIntercept}
            trendCoefficientIntercept={props.details.trendCoefficientIntercept}
            predictedCoefficient={props.details.predictedCoefficient}
            predictedIntercept={props.details.predictedIntercept}
            saveFilteredTrendData={props.saveFilteredTrendData}
            toggleImageDownload={props.toggleImageDownload}
            togglePptDownload={props.togglePptDownload}
            largeView={props.largeView}
            handleDownloadExcel={props.handleDownloadExcel}
            lens={props.lens}
          />
        )}
      </Box>
    </TrendGrowthCardContainer>
  )
}

export default TrendGrowthCard
