import { styled } from '@mui/system'
import AppBar from '@mui/material/AppBar'

export default styled(AppBar)(({ theme }) => {
  return {
    background: theme.palette.background.default,
    position: 'fixed',
    right: 'auto',
    top: theme.spacing(8),
    maxWidth: '1536px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  }
})
