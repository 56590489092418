export const getValidDate = (date_string) => {
  const date = new Date(date_string);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
