import React from 'react'
import PropTypes from 'prop-types'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import AppBar from './AppBar'

const HideOnScrollAppbar = (props) => {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar sx={props.sx}>{children}</AppBar>
    </Slide>
  )
}

HideOnScrollAppbar.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}
export default HideOnScrollAppbar
