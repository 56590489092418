import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { renderField } from '../UI/form/index'
import classes from './UserRegistrationForm.css'
import {
  required,
  email,
  passwordLength,
  phoneNumber,
  number,
} from '../../validation'
import { connect } from 'react-redux'
import { reduxFormConst } from '../../const'
import * as actions from '../../store/actions'

const passwordMatch = (value, values) => {
  return value !== values.password ? 'Should match password value' : undefined
}

class UserRegistrationForm extends React.Component {
  componentWillMount() {
    this.props.change('organization.id', this.props.orgID)
  }

  componentDidMount() {
    this.props.hideSpinner()
    this.props.initialize({
      email: this.props.userProfile.email,
      firstName: this.props.userProfile.firstName,
      lastName: this.props.userProfile.lastName,
      department: this.props.userProfile.department,
      designation: this.props.userProfile.designation,
      organization: this.props.userProfile.organization,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userProfile.email !== this.props.userProfile.email ||
      prevProps.userProfile.firstName !== this.props.userProfile.firstName ||
      prevProps.userProfile.lastName !== this.props.userProfile.lastName ||
      prevProps.userProfile.department !== this.props.userProfile.department ||
      prevProps.userProfile.designation !== this.props.userProfile.designation
    ) {
      this.props.initialize({
        email: this.props.userProfile.email,
        firstName: this.props.userProfile.firstName,
        lastName: this.props.userProfile.lastName,
        department: this.props.userProfile.department,
        designation: this.props.userProfile.designation,
        organization: this.props.userProfile.organization,
      })
    }
  }

  render() {
    const { changeCredentials, id } = this.props
    return (
      <>
        <div className="container">
          <div className="user__inner">
            <h4>
              <b>{this.props.title ? 'Admin Settings ' : 'Profile Settings'}</b>
            </h4>
            <form key="userRegistrationForm" onSubmit={this.props.handleSubmit}>
              <div className="col-md-12 pad">
                {/* <button type="button" */}
                {/* className={'glyphicon glyphicon-remove  pull-right left5 ' + classes.crossButton} */}
                {/* onClick={this.props.hide}/> */}
              </div>
              <div className="col-md-12">
                {/* <h4>User Info</h4> */}
                <div>
                  <Field
                    name="company"
                    type="text"
                    component={renderField}
                    label="Company*"
                    className="form-control"
                    placeholder="Company"
                  />

                  <Field
                    name="firstName"
                    type="text"
                    component={renderField}
                    label="Name"
                    className="form-control"
                    validate={[required]}
                    placeholder="Name"
                  />

                  <Field
                    name="country"
                    type="text"
                    component={renderField}
                    label="Country"
                    className="form-control"
                    validate={[required]}
                    placeholder="Country"
                  />

                  <Field
                    name="designation"
                    type="text"
                    component={renderField}
                    label="Designation"
                    className="form-control"
                    placeholder="Designation"
                  />

                  <Field
                    name="department"
                    type="text"
                    component={renderField}
                    label="Department"
                    className="form-control"
                    placeholder="Department"
                  />

                  <Field
                    name="email"
                    type="text"
                    component={renderField}
                    label="Email*"
                    className="form-control"
                    placeholder="Email"
                    validate={[email, required]}
                  />

                  {id && (
                    <div className="checkbox">
                      <label>
                        <Field
                          name="changeCredentials"
                          component="input"
                          type="checkbox"
                        />
                        Change Credentials
                      </label>
                    </div>
                  )}

                  {(!id || changeCredentials) && (
                    <Field
                      name="password"
                      label="New Password"
                      component={renderField}
                      type="password"
                      validate={[passwordLength]}
                    />
                  )}

                  {(!id || changeCredentials) && (
                    <Field
                      name="confirmPassword"
                      label="Confirm Password"
                      component={renderField}
                      type="password"
                      validate={[passwordLength, passwordMatch]}
                    />
                  )}
                </div>
              </div>

              <div
                className={'col-md-12 clearfix text-center ' + classes.saveBtn}
              >
                <button type="submit" className="btn btn__blue">
                  Submit
                </button>
                {/* <button type="submit" className={"btn " + classes.btnCustom} onClick={this.props.hide}> */}
                {/* Hide */}
                {/* </button> */}
              </div>
            </form>
          </div>
        </div>
      </>
    )
  }
}

UserRegistrationForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.registrationForm,
})(UserRegistrationForm)

const selector = formValueSelector('registration-form')

UserRegistrationForm = connect(
  (state) => {
    const { id, changeCredentials } = selector(state, 'id', 'changeCredentials')
    return {
      id,
      changeCredentials,
    }
  },
  (dispatch) => ({
    hideSpinner: () => dispatch(actions.hideSppiner()),
  }),
)(UserRegistrationForm)
export default UserRegistrationForm
