import palette from '../palette';
import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      '& .MuiTableCell-root': {
        paddingLeft: 24,
        paddingRight: 24,
        ...typography.subtitle2,
        borderBottom: `1px solid ${palette.divider}`,
      },
      '& :not(.MuiTableCell-head)': {
        '&:last-child td, &:last-child th': { border: 'none' },
      },
    },
  },
};
