import React, { useMemo } from 'react'
import recentlyVisited from './../../../assets/images/recentlyVisited.svg'
import NoResultFound from '../../../../shared/components/NoResultFound/NoResultFound'
import { FixedSizeList } from 'react-window'
import { Box, Typography, Button, Divider } from '@mui/material'
import TextOverflowTooltip from '../../../../shared/components/TextOverflowTooltip'

const MAX_KEYWORDS = 20

const Row = ({
  index,
  style,
  data,
  addTags,
  removeTags,
  keywordsAdded,
  handleTrendRedirection,
}) => {
  const { country, name, category, image_url: imgSrc } = data[index]
  const isKeywordAdded = !keywordsAdded[name.toLowerCase()]

  const handleKeyWordAddition = (e) => {
    e.stopPropagation()
    addTags(name)
  }

  const handleKeyWordRemoval = (e) => {
    e.stopPropagation()
    removeTags(name)
  }

  return (
    <Box style={style}>
      <Box
        sx={{
          display: 'flex',
          height: '100px',
          width: '100%',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => handleTrendRedirection(data[index])}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            component="img"
            src={imgSrc}
            sx={{
              height: '70px',
              width: '70px',
              minWidth: '70px',
              mr: 2,
              borderRadius: '10px',
              objectFit: 'cover',
            }}
          ></Box>
          <Box>
            <TextOverflowTooltip title={name}>
              <Typography sx={{ fontWeight: 400, maxWidth: '100%' }}>
                {name}
              </Typography>
            </TextOverflowTooltip>
            <Typography py={1} sx={{ fontSize: '11px' }}>
              {country} {category}
            </Typography>
            <Button
              sx={
                isKeywordAdded
                  ? {
                      pl: 0,
                      fontWeight: 500,
                      color: ({ palette }) => palette.primary.main,
                    }
                  : {
                      border: ({ palette }) =>
                        `1px solid ${palette.primary.main}`,
                      fontWeight: 500,
                      color: ({ palette }) => palette.primary.main,
                    }
              }
              onClick={
                isKeywordAdded ? handleKeyWordAddition : handleKeyWordRemoval
              }
            >
              {isKeywordAdded ? 'Add Keyword' : 'Remove Keyword'}
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

const RecentTrendActivity = ({
  recentTrends,
  tags,
  setTags,
  includeError,
  setIncludeError,
  excludeTags,
  handleTrendRedirection,
  setInput,
  logRecentlyVisitedTrend,
}) => {
  const keywordsAdded = useMemo(() => {
    let activeTags = {}
    tags.forEach((tag) => {
      activeTags = { ...activeTags, [tag.toLowerCase()]: true }
    })
    return activeTags
  }, [tags])

  const removeTags = (name) => {
    setTags([...tags.filter((tag) => tag.toLowerCase() !== name.toLowerCase())])
    logRecentlyVisitedTrend(name, 'Remove')
  }

  const addTags = (tagToBeAdded) => {
    if (
      tagToBeAdded !== '' &&
      !tags
        ?.map((tag) => tag.toLowerCase())
        .includes(tagToBeAdded.toLowerCase())
    ) {
      if (
        excludeTags
          .map((tag) => tag.toLowerCase())
          .includes(tagToBeAdded.toLowerCase())
      ) {
        setIncludeError(`Note: ${tagToBeAdded} is Excluded`)
      } else if (tags.length === MAX_KEYWORDS) {
        setIncludeError('Error: Maximum 20 Keywords can be selected.')
      } else {
        setIncludeError('')
        if (tags.map((tag) => tag.toLowerCase().includes(tagToBeAdded))) {
          setTags([
            ...tags.filter(
              (tag) => tag.toLowerCase() !== tagToBeAdded.toLowerCase(),
            ),
            tagToBeAdded,
          ])
          logRecentlyVisitedTrend(tagToBeAdded, 'Add')
        } else {
          setTags([...tags, tagToBeAdded])
          logRecentlyVisitedTrend(tagToBeAdded, 'Add')
        }
      }
    } else {
      setInput('')
    }
  }

  return (
    <Box
      sx={{
        background: 'white',
        p: 2,
        borderRadius: '10px',
        height: '100%',
        minHeight: '650px',
        '& ::-webkit-scrollbar': {
          width: '4px',
        },

        '& ::-webkit-scrollbar-track': {
          backgroundColor: ({ palette }) => palette.grey[100],
          borderRadius: 8,
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: ({ palette }) => palette.grey[500],
          borderRadius: 8,
        },
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: '18px', py: 1 }}>
        Recently Visited Trends
      </Typography>
      <Typography
        sx={{ fontSize: '12px', color: '#8997A6', fontWeight: '500' }}
        pb={1}
      >
        Suggestions for generating concepts
      </Typography>
      {recentTrends.length > 0 ? (
        <FixedSizeList
          itemData={recentTrends}
          height={850}
          itemCount={recentTrends.length}
          itemSize={100}
          width={250}
        >
          {(props) =>
            Row({
              ...props,
              addTags,
              removeTags,
              keywordsAdded,
              handleTrendRedirection,
            })
          }
        </FixedSizeList>
      ) : (
        <Box>
          <NoResultFound
            imgSrc={recentlyVisited}
            imgSx={{ width: '260px', mt: 10 }}
            messageSx={{ width: '260px' }}
            message="No Recent Activity!"
          />
        </Box>
      )}
    </Box>
  )
}

export default RecentTrendActivity
