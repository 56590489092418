import React from 'react'
import {
  reduxForm,
} from 'redux-form'
import {UserRegistrationForm} from '../index'
import Spinner from '../../components/UI/Spinner/Spinner'
import classes from './UserPage.css'
import {reduxFormConst} from '../../const'


class UserProfile extends React.Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
  }

  componentWillMount () {
    this.props.showUserInfo(this.props.loginUserDetails)
    this.props.getUserProfile()
    document.body.classList.add('user__profile__page')

  }

  componentWillUnmount () {
    document.body.classList.remove('user__profile__page')
  }

  submitForm (values) {
    return this.props.updateUserProfile(values).then(() => {
      this.props.history.push('/trend-spotting')
    })
  }

  render () {
    if (!this.props.userProfile) return null
    return (
      <div className="col-md-12 pad user__content">
        {this.props.showSpinner ? <div className="sppinerSection"><Spinner/></div> : null}
        <div className="col-md-12 pad">
          <UserRegistrationForm
            title={this.props.registrationForm}
            hide={this.props.hideForm}
            onSubmit={this.submitForm}
            userProfile={this.props.userProfile}
            show
          />
        </div>
      </div>
    )
  }
}

UserProfile = reduxForm({
  // a unique name for the form
  form: reduxFormConst.userProfile,
})(UserProfile)

export default UserProfile
