import React, { useEffect, useMemo, useState } from 'react'

import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'

import NestedTreeItems from './NestedTreeItems'
import MenuListFooterButtons from '../MenuListFooterButtons'
import SearchInput from '../SearchInput'
import { usePreviousValue } from '../../../hooks'
import { EmptyStateContainer } from './styles'
import { getFilterLabels } from '../DropdownSelectedFiltersTags/utility'
import { getColoredLabelFromPalette } from '../DashboardFavoritesTrendsCardsList'
import palette from '../../../theme/palette'

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

/**
 * This Component is used in NestedMultiSelectDropdownContainer along with useNestedMultiSelectDropdownState hook
 * @param {Object} filterState  is the 1st item from return value of hook
 * @param {Function} setFilterState  is the 2nd item from return value of hook
 *
 */
const NestedMultiSelectDropdown = (props) => {
  const {
    filterState,
    setFilterState,
    dimensions,
    placeHolder,
    disableFooterButtons,
    disableSearchBar,
    emptyStateText,
    isLoggingRequired,
    logDropdownOpened,
    logActionClicked,
    logSearchKeywordModified,
    preLabelText,
  } = props
  const [openDropdown, setOpenDropdown] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [expandedNodes, setExpandedNodes] = React.useState([])
  const prevSearchValue = usePreviousValue(searchValue)

  const MenuProps = {
    PaperProps: {
      style: {
        width: dimensions.width,
      },
    },
  }

  const getDropdownRenderValue = (preLabelText, label, color) => (
    <Typography
      color={color || 'textPrimary'}
      sx={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {preLabelText || label}&nbsp;
      {preLabelText &&
        getColoredLabelFromPalette(label, palette.secondary.main)}
    </Typography>
  )
  const getRelatedParentNodes = (id, expandItems) => {
    let allItemsToExpand = [...expandItems]
    const parentObjectIdArray = Object.keys(
      filterState.all,
    ).filter((keyOfObject) =>
      filterState.all[keyOfObject].childIds.includes(id),
    )
    if (parentObjectIdArray.length) {
      const parentObjectId = parentObjectIdArray[0]
      allItemsToExpand.push(parentObjectId)
      allItemsToExpand = getRelatedParentNodes(parentObjectId, allItemsToExpand)
    }
    return allItemsToExpand
  }
  const getAllParentIds = (stateToCheck) => {
    const items = Object.values(stateToCheck)
    const filterIds = items.reduce(
      (acc, item) => getRelatedParentNodes(item.id, acc),
      [],
    )
    return [...new Set(filterIds)]
  }

  const searchFilteredState = useMemo(() => {
    if (searchValue) {
      return Object.values(filterState.all)
        .filter((itemData) =>
          itemData.label.toLowerCase().includes(searchValue.toLowerCase()),
        )
        .reduce((a, item) => ({ ...a, [item.id]: item }), {})
    } else {
      return filterState.all
    }
  }, [filterState.all, searchValue])

  const topLevelItems = useMemo(() => {
    const idsOfSearchFilteredItems = Object.keys(searchFilteredState)
    return Object.values(filterState.all).filter(
      (item) =>
        item.depth === 0 &&
        (idsOfSearchFilteredItems.includes(`${item.id}`) ||
          item.childIds.some((idOfChildItem) =>
            idsOfSearchFilteredItems.includes(`${idOfChildItem}`),
          )),
    )
  }, [filterState.all, searchFilteredState])

  const appliedFilterIds = useMemo(() => {
    const appliedItemsIds = Object.keys(filterState.applied)
    const filterIds = getAllParentIds(filterState.applied)
    return [...new Set([...appliedItemsIds, ...filterIds])]
  }, [filterState.applied])

  const handleDropdownToggle = (open) => {
    if (open) {
      if (isLoggingRequired) {
        logDropdownOpened()
      }
      setExpandedNodes(getAllParentIds(filterState.selected))
    } else {
      setSearchValue('')
    }
    setOpenDropdown(open)
  }
  const handleDropdownClose = () => {
    // When user clicks outside of dropdown
    if (disableFooterButtons) {
      handleDropdownToggle(false)
    } else {
      setFilterState(filterState.applied, 'selected')
      handleDropdownToggle(false)
    }
  }

  const handleResetFiltersClick = () => {
    if (isLoggingRequired) {
      logActionClicked(filterState, 'Reset')
    }
    setFilterState({}, 'applied') // reset applied filters
    setFilterState({}, 'selected') // reset selected filters
    handleDropdownToggle(false)
  }
  const handleApplyFiltersClick = () => {
    if (isLoggingRequired) {
      logActionClicked(filterState, 'Apply')
    }
    setFilterState(filterState.selected, 'applied') // apply selected filters
    handleDropdownToggle(false)
  }

  const handleCheckboxClick = (e, selectedItem) => {
    e.stopPropagation()
    const isItemInSelectedItems =
      Object.keys(filterState.selected).indexOf(`${selectedItem.id}`) > -1
    setFilterState(selectedItem, 'selected', {
      type: isItemInSelectedItems ? 'delete' : 'update',
    })
  }

  const handleClickedOnTreeItem = (e, nodeId) => {
    // This is used to {Select or Deselect} the item clicked if there are no children. - Default click Behaviour {Expand or Collapse}
    const selectedNodeItem = filterState.all[nodeId]
    if (selectedNodeItem) {
      if (!selectedNodeItem.childIds?.length) {
        handleCheckboxClick(e, selectedNodeItem)
      }
    }
  }
  const handleTreeItemToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds)
  }

  const handleSearchValueChange = (event) => {
    if (isLoggingRequired) {
      logSearchKeywordModified(event.target.value)
    }
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    if (searchValue) {
      setExpandedNodes(getAllParentIds(searchFilteredState))
    } else {
      if (prevSearchValue) {
        setExpandedNodes([])
      }
    }
  }, [searchValue, searchFilteredState])

  return (
    <FormControl sx={{ width: dimensions.width }}>
      <Select
        multiple
        value={appliedFilterIds.filter(
          (idOfObject) => filterState.all[idOfObject].depth === 0,
        )}
        open={openDropdown}
        onOpen={() => handleDropdownToggle(true)}
        onClose={handleDropdownClose}
        displayEmpty
        renderValue={(selected) =>
          selected?.length
            ? getDropdownRenderValue(
                preLabelText,
                `${selected
                  .map((idOfObject) =>
                    getFilterLabels(
                      idOfObject,
                      filterState.all,
                      appliedFilterIds,
                    ),
                  )
                  .join(', ')}`,
              )
            : getDropdownRenderValue(false, placeHolder, 'textSecondary')
        }
        MenuProps={MenuProps}
        sx={{
          '& .MuiSelect-select': {
            py: 1.25,
            pl: 1.25,
          },
        }}
      >
        {!disableSearchBar && (!!topLevelItems?.length || searchValue) && (
          <Box
            onKeyDown={(e) => e.stopPropagation()}
            component="div"
            sx={{ px: 2, py: 1.5 }}
          >
            <SearchInput
              value={searchValue}
              onChange={handleSearchValueChange}
              txtProps={{
                autoFocus: true,
                fullWidth: true,
              }}
            />
          </Box>
        )}
        {topLevelItems?.length ? (
          <Box
            sx={{
              maxHeight:
                ITEM_HEIGHT * dimensions.maxItemsToFitInHeight +
                ITEM_PADDING_TOP,
              overflow: 'auto',
              py: 0.5,
            }}
          >
            {topLevelItems.map((parentItem) => (
              <MenuItem
                sx={{ p: 0 }}
                key={parentItem.id}
                value={parentItem.label}
              >
                <TreeView
                  aria-label={placeHolder}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expandedNodes}
                  onNodeToggle={handleTreeItemToggle}
                  onNodeSelect={handleClickedOnTreeItem}
                  sx={{
                    width: '100%',
                  }}
                >
                  <NestedTreeItems
                    item={parentItem}
                    handleCheckboxClick={handleCheckboxClick}
                    filterState={filterState}
                    searchFilteredState={searchFilteredState}
                  />
                </TreeView>
              </MenuItem>
            ))}
          </Box>
        ) : (
          <EmptyStateContainer
            styleProps={{
              height:
                ITEM_HEIGHT * dimensions.maxItemsToFitInHeight +
                ITEM_PADDING_TOP,
            }}
          >
            {emptyStateText}
          </EmptyStateContainer>
        )}
        {!disableFooterButtons && (!!topLevelItems?.length || searchValue) && (
          <MenuListFooterButtons
            enableResetButton
            handleResetFiltersClick={handleResetFiltersClick}
            enableApplyButton
            handleApplyFiltersClick={handleApplyFiltersClick}
          />
        )}
      </Select>
    </FormControl>
  )
}

NestedMultiSelectDropdown.defaultProps = {
  dimensions: {
    width: 250,
    maxItemsToFitInHeight: 6.5,
  },
  placeHolder: 'Select Ingredient Family',
  disableFooterButtons: false,
  disableSearchBar: false,
  emptyStateText: 'No Ingredient Families Available',
}

export default NestedMultiSelectDropdown
