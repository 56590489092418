import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgressLabelCustom from '../CircularProgressLabelCustom';

const VolumeCard = ({ value }) => (
  <Paper
    sx={{
      flexGrow: 1,
      p: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 4px 12px 0px #272B3108',
    }}
  >
    <Stack spacing={1}>
      <Typography variant="h3">
        Volume
      </Typography>
      <Typography variant="caption" color="textSecondary" maxWidth={200}>
        Percentage of datapoints for this particular topic out of total volume
      </Typography>
    </Stack>
    <Box>
      <CircularProgressLabelCustom value={value} />
    </Box>
  </Paper>
);

VolumeCard.propTypes = {
  value: PropTypes.number.isRequired,
};

export default VolumeCard;
