import * as AC from '../actions/actionConstants'
import { UserSelectionActionTypes } from '../actions/actionTypes'

const initialState = {
  selectedComparisionProduct: [],
  comparisionNotification: false,
  projectid: undefined,
  selectedTrend: null,
  allSelectedTrends: [],
  trendResults: null,
  lensSelected: "",
  selectedProjectId: null
}

const productComparisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PROJECT]:
      // if projectid changes then clear comparison list else ignore
      if (state.projectid !== action.projectid) {
        return Object.assign({}, state, {
          selectedComparisionProduct: [],
          projectid: action.projectid,
        })
      } else { return state }
    case AC.STORE_PRODUCT_DETAILS_FOR_COMPARISION:
      // If lens changes then we reset the selected products list
      if(action.lensSelected && state.lensSelected !== action.lensSelected){
        return Object.assign({}, state, {
          selectedComparisionProduct: [action.productDetails],
          projectid: action.projectid ? action.projectid : state.projectid,
          selectedProjectId: action.productDetails.trendProjectId,
          lensSelected: action.lensSelected,
          allSelectedTrends : [{trendName:action.productDetails.trendName,productId:action.productDetails.id}]
        })
      }
      return Object.assign({}, state, {
        selectedComparisionProduct: [...state.selectedComparisionProduct, action.productDetails],
        projectid: action.projectid ? action.projectid : state.projectid,
        selectedProjectId: action.productDetails.trendProjectId,
        allSelectedTrends : [...state.allSelectedTrends,{trendName:action.productDetails.trendName,productId:action.productDetails.id}]
      })
    case AC.REMOVE_PRODUCT_FROM_COMAPRISION_LIST:
      const comparisonList = [...state.selectedComparisionProduct.filter(product => action.productID != product.id)]
      return Object.assign({}, state, {
        selectedComparisionProduct: comparisonList,
        comparisionNotification: false,
        allSelectedTrends: [...state.allSelectedTrends.filter( trend => trend.productId != action.productID)],
        selectedProjectId: !!comparisonList.length ? comparisonList[comparisonList.length - 1].trendProjectId: null,
      })
    case AC.SHOW_COMPARISION_NOTIFICATION:
      return Object.assign({}, state, { comparisionNotification: true })
    case AC.HIDE_COMPARISION_NOTIFICATION:
      return Object.assign({}, state, { comparisionNotification: false })
    case AC.CLEAR_COMPARISION_LIST:
      return Object.assign({}, state, { selectedComparisionProduct: [], comparisionNotification: false })
    case AC.GET_PRODUCT_LIST_FOR_COMPARE_DROPDOWN:
      return Object.assign({}, state, { selectedTrend: action.trend })
    case AC.SET_SELECTED_PROJECT:
      return Object.assign({}, state, {selectedProjectId: action.projectId})
    break
    default:
      return state
  }
}

export default productComparisionReducer
