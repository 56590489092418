import React from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import CountrySelect from '../CountrySelect'
import ProductSelect from '../ProductSelect'

const ExploreTrendsTop = ({
  projectId,
  countries = [],
  categories = [],
  countrySelected = null,
  categorySelected = null,
  topProjectsList = [],
  handleCountryChange,
  handleCategoryChange,
  handleProjectClick,
  amplitudeFn,
}) => {
  function handleExploreCategoryClick() {
    const ampiEvent = 'Clicked_Explore_Category'
    const ampiUserData = {
      country: countrySelected?.label,
      category: categorySelected?.label,
    }
    amplitudeFn(ampiEvent, ampiUserData)
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        borderRadius: 1,
        border: `1px solid ${grey['300']}`,
        p: 2,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4">
          Select a Country and Product Category
        </Typography>
      </Box>
      <Box display="flex" alignItems="end" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <CountrySelect
            countries={countries}
            value={countrySelected}
            onChange={handleCountryChange}
          />
          <ProductSelect
            disabled={countrySelected === null}
            categories={categories}
            value={categorySelected}
            onChange={handleCategoryChange}
          />
        </Stack>
        {categorySelected && countrySelected && (
          <Button
            variant="contained"
            size="large"
            onClick={() => handleExploreCategoryClick()}
            component={Link}
            href={`/mui/product-category/${projectId}`}
          >
            Explore Category
          </Button>
        )}
      </Box>
      <Box sx={{ py: 2 }}>
        <Divider sx={{ mx: -2 }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4">Dashboards</Typography>
      </Box>
      <Stack
        direction="row"
        sx={{
          mx: -0.5,
          flexWrap: 'wrap',
          maxHeight: '10vh',
          overflow: 'auto',
        }}
      >
        {topProjectsList.map(({ country, category, id }) => (
          <Chip
            key={id}
            sx={{ m: 0.5 }}
            onClick={() => handleProjectClick(country, category)}
            label={
              <Box>
                {category}&nbsp;
                <span>in {country}</span>
              </Box>
            }
          />
        ))}
      </Stack>
    </Box>
  )
}

export default ExploreTrendsTop
