import React, { useState, useEffect, useMemo } from 'react'
import SWScatterPlot from '../ScreenedConceptsGraph/ScreenedConceptsGraph'
import SWObservations from '../SWObservations/SWObservations'

import './SWOrigVRelevance.scss'

const SWOrigVRelevance = ({
  concepts = [],
  scatterPlotData = [],
  country,
  subcategory,
  category,
}) => {
  const [selectedConceptId, setSelectedConceptId] = useState(null)

  const sortedConceptOptions = useMemo(
    () =>
      concepts
        .map(
          ({
            id,
            title,
            originalityRank,
            relevanceRank,
            meta_information: metaInformation,
            originalityScore,
            relevanceScore,
            project,
          }) => ({
            id,
            name: title,
            metaInformation,
            relevanceRank,
            originalityRank,
            originalityScore,
            relevanceScore,
            project,
          }),
        )
        .sort(({ originalityRank: a }, { originalityRank: b }) => a - b),
    [concepts],
  )

  const handleConceptSelect = (conceptId) => {
    setSelectedConceptId(conceptId)
  }

  const handleConceptSelectDropDown = ({ id }) => {
    setSelectedConceptId(id)
  }

  useEffect(() => {
    if (!selectedConcept) {
      setSelectedConceptId(sortedConceptOptions[0]?.id)
    }
  }, [sortedConceptOptions])

  const selectedConcept = sortedConceptOptions.find(
    ({ id }) => id === selectedConceptId,
  )
  return (
    <div className="sw-orig-v-relevance">
      <p className="sw-font-fs--14 sw-orig-v-relevance__note">
        Note: Top 3 concepts, by Originality, are indicated by decreasing plot
        point diameter.{' '}
        <span className="sw-bold--500">Largest = MOST Original</span>
      </p>
      <div style={{ width: 920 }}>
        <SWScatterPlot
          data={scatterPlotData}
          selectedConcept={selectedConcept}
          handleCircleClick={handleConceptSelect}
          showTooltip={!(subcategory === 'All' || !subcategory)}
        />
      </div>
      <div style={{ width: 370, marginTop: 40 }}>
        <SWObservations
          selectedConcept={selectedConcept}
          handleConceptSelect={handleConceptSelectDropDown}
          conceptResults={sortedConceptOptions}
          country={country}
          category={category}
          subcategory={subcategory}
        />
      </div>
    </div>
  )
}

export default SWOrigVRelevance
