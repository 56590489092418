import React, { useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Link,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TextField,
  InputAdornment,
  Grid,
  Box,
} from '@mui/material'
import axios from '../../services/networking/axios'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Search } from '@mui/icons-material'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import RemovePopup from './PopUp/RemovePopup'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { TableStyled, Image, BookmarkIconStyled } from './Styles'
import NoBookmarksAvailable from './NoBookmark'
import NoSearchedAvailable from './NoSearchedAvailable'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import countriesList from '../../mocks/country'
import * as actions from '../../store/actions/index'
import { amplify, getSignedFloat } from './../../store/utility'

const amp = (props, ampiEvent, ampiUserData) => {
  try {
    const removedBookmark = props.bookmarkedList.bookmarkedTrends.filter(
      (bookmarkedTrend) => bookmarkedTrend.id === ampiUserData.removeId,
    )
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: props.email,
      Trend_Name: removedBookmark[0].trendName,
      Country_Name: removedBookmark[0].country,
      Category_Name: removedBookmark[0].category,
      Lens: removedBookmark[0].lens,
      Maturity_Phase: removedBookmark[0].maturityPhase,
    }
    amplify(amplitudeEvent, amplitudeUserData, props.email)
  } catch (err) {
    console.log(err, 'Amplitude error in BookmarkTable')
  }
}

const headCells = [
  {
    id: 'removeBookmark',
    label: '',
  },
  {
    id: 'trendName',
    label: 'Trends',
  },
  {
    id: 'lens',
    label: 'Lens',
  },
  {
    id: 'country',
    label: 'Country',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'maturityPhase',
    label: 'Maturity Phase',
  },
  {
    id: 'rank',
    label: 'Rank',
  },
  {
    id: 'twoYearCagr',
    label: '2 Year CAGR',
  },
  {
    id: 'fourYearCagr',
    label: '4 Year CAGR',
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(a, b, order, orderBy) {
  return order === 'desc'
    ? descendingComparator(a, b, orderBy)
    : -descendingComparator(a, b, orderBy)
}

const BookMarkTable = (props) => {
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)
  const [removeId, setRemoveId] = useState()
  const [bookmarkedList, setBookmarkedList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('trendName')

  const handleRequestSort = (property) => () => {
    const isSameOrderByAsc = orderBy === property && order === 'asc'
    setOrder(isSameOrderByAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setBookmarkedList(finalSorting(isSameOrderByAsc ? 'desc' : 'asc', property))
  }

  const toggleRemovePopup = (id) => {
    setRemoveId(id)
    setIsRemoveOpen(!isRemoveOpen)
  }

  const handleRemoveBookmark = async () => {
    await props.removeBookmarked(removeId)
    setIsRemoveOpen(false)
    props.getBookmarkData()
    const ampiEvent = `Clicked_Remove_Bookmark`
    const ampiUserData = {
      removeId: removeId,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  useEffect(() => {
    props.getBookmarkData()
  }, [])

  useEffect(() => {
    if (props.bookmarkedList) {
      const ingredient = props.bookmarkedList.bookmarkedTrends.filter(
        (d) => d.lens === 'ingredient',
      )
      const product = props.bookmarkedList.bookmarkedTrends.filter(
        (d) => d.lens === 'product',
      )
      const theme = props.bookmarkedList.bookmarkedTrends.filter(
        (d) => d.lens === 'theme',
      )

      ingredient.sort((a, b) => {
        return getComparator(a, b, order, orderBy)
      })

      product.sort((a, b) => {
        return getComparator(a, b, order, orderBy)
      })

      theme.sort((a, b) => {
        return getComparator(a, b, order, orderBy)
      })

      const finalSortedList = [...ingredient, ...product, ...theme]
      setBookmarkedList(finalSortedList)
      handleRequestSort(orderBy)
    }
  }, [props.bookmarkedList])

  useEffect(() => {
    setBookmarkedList(finalSorting(order, orderBy))
  }, [searchValue])

  let listing
  const getImageUrl = (country) => {
    listing = countriesList.find((i) => i.label === country)
    return (
      <img
        width="30"
        height="20"
        src={
          listing.img
            ? listing.img
            : `https://flagcdn.com/${listing.code.toLowerCase()}.svg`
        }
        srcSet={
          listing.img
            ? listing.img
            : `https://flagcdn.com/w40/${listing.code.toLowerCase()}.png 2x`
        }
        alt={listing.label}
      />
    )
  }

  const handleSearch = (val) => {
    if (val !== '') {
      setSearchValue(val)
    } else {
      setSearchValue('')
    }
  }

  const ingredientClicked = (i) => {
    const ampiEvent = `Clicked_Trend_Lens`
    const ampiUserData = {
      User: props.email,
      Maturity_Phase: i.maturityPhase,
      Lens: i.lens,
      Country_Name: i.country,
      Category_Name: i.category,
      Redirected_From: 'Bookmark',
      Trend_Name: i.trendName,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const finalSorting = (order, orderBy) => {
    if (orderBy === 'lens') {
      const list = [...bookmarkedList]
      return list.sort((a, b) => getComparator(b, a, order, orderBy))
    }
    const ingredient = bookmarkedList.filter((d) => d.lens === 'ingredient')
    const product = bookmarkedList.filter((d) => d.lens === 'product')
    const theme = bookmarkedList.filter((d) => d.lens === 'theme')

    ingredient.sort((a, b) => {
      return getComparator(a, b, order, orderBy)
    })
    product.sort((a, b) => {
      return getComparator(a, b, order, orderBy)
    })
    theme.sort((a, b) => {
      return getComparator(a, b, order, orderBy)
    })

    const sorted = [...ingredient, ...product, ...theme]

    return sorted
  }

  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Typography key="3" variant="subtitle2">
      Bookmarks
    </Typography>,
  ]

  const filteredBookmark = bookmarkedList.filter((d) => {
    return d.trendName.toLowerCase().includes(searchValue.toLowerCase())
  })

  return (
    <>
      <Grid container columns={12} my={4}>
        <Grid item xs={6}>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <TextField
            variant="outlined"
            size="large"
            placeholder={'Search by trends'}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography variant="h5">Bookmarked Trends</Typography>
      </Box>
      {filteredBookmark && filteredBookmark.length !== 0 && (
        <TableStyled>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.id !== 'removeBookmark' && (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={handleRequestSort(headCell.id)}
                      sx={{ display: 'flex' }}
                    >
                      <Typography
                        variant="subtitle1"
                        color={(theme) => theme.palette.primary.main}
                        textAlign="left"
                        gutterBottom
                      >
                        {headCell.label}
                      </Typography>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBookmark &&
              filteredBookmark.map((i) => (
                <TableRow>
                  <TableCell>
                    <BookmarkIconStyled
                      onClick={() => toggleRemovePopup(i.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      underline="none"
                      variant="body2"
                      onClick={() => {
                        ingredientClicked(i)
                      }}
                      href={`/mui/details/${i.projectId}/${i.lens}/${i.trendId}`}
                    >
                      <Image>
                        <img src={i.imageUrl} width="30" height="30" gap="1" />
                        {i.trendName}
                      </Image>
                    </Link>
                  </TableCell>
                  <TableCell>{i.lens}</TableCell>
                  <TableCell>
                    <Image>
                      {getImageUrl(i.country)}
                      {i.country}
                    </Image>
                  </TableCell>
                  <TableCell>{i.category}</TableCell>
                  <TableCell>{i.maturityPhase}</TableCell>
                  <TableCell>{i.rank}</TableCell>
                  <TableCell
                    style={{
                      color: i.twoYearCagr >= 0 ? '#22B58B' : '#C91628',
                    }}
                  >
                    <Image>
                      {getSignedFloat(i.twoYearCagr, 2)}%
                      {i.twoYearCagr >= 0 ? (
                        <ArrowDropUpRoundedIcon color="success.dark" />
                      ) : (
                        <ArrowDropDownRoundedIcon color="error.dark" />
                      )}
                    </Image>
                  </TableCell>
                  <TableCell>{getSignedFloat(i.fourYearCagr, 2)}%</TableCell>
                </TableRow>
              ))}
          </TableBody>
          {isRemoveOpen && (
            <RemovePopup
              handlePopup={() => setIsRemoveOpen(false)}
              handleRemoveBookmark={handleRemoveBookmark}
            />
          )}
        </TableStyled>
      )}
      {searchValue === '' && !filteredBookmark.length && (
        <NoBookmarksAvailable />
      )}
      {searchValue !== '' && !filteredBookmark.length && (
        <NoSearchedAvailable />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  bookmarkedList: state.bookmark.bookmarked,
  userName:
    state.user.loggedIn &&
    state.user.loggedIn.user &&
    (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
      ? state.user.loggedIn.user.firstName || state.user.loggedIn.user.username
      : 'User',
  email:
    state.user &&
    state.user.loggedIn &&
    state.user.loggedIn.user &&
    state.user.loggedIn.user.email
      ? state.user.loggedIn.user.email
      : '',
})
const mapDispatchToProps = (dispatch) => {
  return {
    removeBookmarked: (removeId) =>
      dispatch(actions.removeBookmarked(removeId)),
    getBookmarkData: () => dispatch(actions.fetchBookmarks()),
  }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(BookMarkTable, axios)),
)
