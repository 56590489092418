import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';

export default styled(MenuItem)(({ theme, isNew }) => ({
  whiteSpace: 'normal',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1.5),
  boderRadius: theme.spacing(0.5),
  '& .MuiBadge-dot': {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    borderRadius: '50%',
    border: '2px solid white',
    boxShadow: '0px 0px 7px 0px #95959529',
  },
  '& img': {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(0.5),
    objectFit: 'cover',
  },
  '& >.MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
  },
  backgroundColor: isNew ? alpha(theme.palette.secondary.main, 0.1) : 'default',
  '&:hover': {
    backgroundColor: isNew ? alpha(theme.palette.secondary.main, 0.16) : theme.palette.grey[100],
  },
  '&:active': {
    '& .MuiTypography-root': {
      '&:first-child': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));
