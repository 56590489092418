import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

import { useSingleSnackBar } from '../../../hooks'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getHeaders, getBuildWinnerURL } from '../../network/utility'
import { CG_MAX_TITLE_LENGTH } from '../../../shared/utils/constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
}

const errorStyle = {
  color: 'red',
}

const getSubstring = (str, firstSubStr, secondSubStr) => {
  if ([firstSubStr, secondSubStr].every((term) => str.includes(term))) {
    return str
      .split(firstSubStr)
      .pop()
      .split(secondSubStr)[0]
  }
  return ''
}

const getDefaultInput = (generatedConcept) => {
  const titleHeader = 'Product concept:'
  const descriptionHeader = 'Description:'
  return getSubstring(generatedConcept, titleHeader, descriptionHeader).trim()
}

export default function SaveConceptModal({
  conceptDetails,
  active,
  closeConceptModal,
  updatedConceptText,
  logConceptSaved,
  data,
  setData,
  allConceptsUrl,
  fetchApi,
  setConcept,
  refreshState,
  setTags,
}) {
  const { id, title, generated_concept: generatedConcept } = conceptDetails

  const defaultInputText = getDefaultInput(generatedConcept)

  const [open, setOpen] = React.useState(active)
  const [input, setInput] = React.useState(defaultInputText)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()

  const conceptNameExist = data.some((concept) => {
    return concept.title === input.trim()
  })

  const getErrorMessage = () => {
    if (conceptNameExist) {
      return 'Name already exist!'
    } else if (input.length > CG_MAX_TITLE_LENGTH) {
      return `Title can only contain ${CG_MAX_TITLE_LENGTH} characters`
    }
    return ''
  }

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  const handleSnackBar = (message, status) => {
    showSnackBar(message, status, action)
  }
  const handleUpdateConcept = (
    input,
    id,
    updatedConceptText,
    generatedConcept,
  ) => {
    updateConceptName(input.trim(), id, updatedConceptText, generatedConcept)
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)

    closeConceptModal()
  }

  const handleInputChange = (text) => {
    setInput(text)
  }

  const updateConceptName = (
    newName,
    id,
    updatedConceptText,
    generatedConcept,
  ) => {
    if (input.length == 0) {
      setError('Please input a valid name')
      return
    }
    let shouldUpdateConceptDescription = false
    if (updatedConceptText !== generatedConcept) {
      shouldUpdateConceptDescription = true
    }
    setError(undefined)
    const userId = localStorage.getItem('my-secret-key')
    const putUrl = getBuildWinnerURL(`concepts/${id}/?user_id=${userId}`)

    let body = {}
    if (shouldUpdateConceptDescription) {
      body = {
        title: newName,
        generated_concept: updatedConceptText,
      }
    } else {
      body = {
        title: newName,
      }
    }
    setLoading(true)

    fetch(
      putUrl,
      getHeaders(
        {
          body: JSON.stringify(body),
        },
        'PUT',
      ),
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        handleClose()
        closeConceptModal()
        fetchApi(allConceptsUrl)
        refreshState()
        // It is a failed request
        if (data?.detail) {
          handleSnackBar('Error saving concept', 'error')
        } else {
          logConceptSaved(conceptDetails, newName, updatedConceptText)
          handleSnackBar('Your concept is successfully saved', 'success')
        }
      })
      .catch((error) => {
        console.log('error_occured', error)
        setLoading(false)
        setError('Name already exits! please choose a different name')
        handleSnackBar('Error saving concept', 'error')
      })
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{ fontSize: '28px', display: 'block' }}>
            Save the concept
          </Typography>

          <Box
            sx={{
              width: '100%',
              height: '5vh',
            }}
          ></Box>

          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: '14px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Concept name
            </Typography>
            <TextField
              fullWidth
              value={input}
              // label={"Title"}
              variant="outlined"
              onChange={(e) => handleInputChange(e.target.value)}
              id="fullWidth"
              InputProps={{
                style: {
                  height: '35px',
                },
              }}
              sx={{ input: { color: ({ palette }) => palette.black } }}
            />
            <Box>
              {conceptNameExist ||
                (input.length > CG_MAX_TITLE_LENGTH && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: ({ palette }) => palette.error.main,
                      marginTop: '5px',
                    }}
                  >
                    {getErrorMessage()}
                  </Typography>
                ))}
            </Box>
          </Grid>
          <Box
            sx={{
              width: '100%',
              height: '3vh',
            }}
          ></Box>
          {error && error ? (
            <>
              <p style={errorStyle}> {error}</p>
            </>
          ) : null}
          <Grid
            contianer
            spacing={4}
            flexDirection="row"
            justifyContent="flex-end"
            style={{ display: 'flex' }}
          >
            <Grid item>
              <Button variant="outlined" onClick={() => handleClose()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  !input ||
                  conceptNameExist ||
                  input.length > CG_MAX_TITLE_LENGTH
                }
                variant="contained"
                onClick={() =>
                  handleUpdateConcept(
                    input,
                    id,
                    updatedConceptText,
                    generatedConcept,
                  )
                }
                style={{ marginLeft: 12 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
