import React, { useState,useEffect,useRef } from "react";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/system';
import {PopperComponent, StyledPopper} from './style';
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import Button from "@mui/material/Button";
import ShareIcon from '../../../assets/share-icon.svg'
import axios from './../../../services/networking/axios'
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip, Typography} from "@mui/material";
import { useSnackbar } from 'notistack';
import { Dialog } from '@mui/material';
import DialogActions from "@mui/material/DialogActions";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from './../../../store/actions/index'
import { createFilterOptions } from '@mui/material/Autocomplete';
import {amplify} from "./../../../store/utility"
import { AmplitudeValues } from "../../../store/amplitudeValues";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const amp = (props,ampiEvent,ampiUserData={}) => {
  try {
    const ampiInstance = new AmplitudeValues();
    const amplitudeEvent = ampiEvent;
    let amplitudeUserData = ampiUserData;
    switch(ampiEvent) {
      case 'Clicked_Share':
        amplitudeUserData = {
          'User': ampiInstance._email,
          'Lens':props.match.params.urlLensSelected,
          'Country_Name':ampiInstance._country,
          'Category_Name':ampiInstance._category,
          'Trend_Name':ampiInstance._name,
          'Maturity_Phase': ampiInstance._trendName
        }
        amplify(amplitudeEvent, amplitudeUserData,ampiInstance._email)
        break;
      case 'Clicked_Add_Team_Members' : 
        amplitudeUserData = {
          'User': ampiInstance._email,
          'Team_Members': ampiUserData.teamMembers.map((member)=>member.username),
        }
        amplify(amplitudeEvent, amplitudeUserData,ampiInstance._email)
        break;
      case 'Clicked_Share_Cancel' : 
        amplitudeUserData = {
          'User': ampiInstance._email,
        }
        amplify(amplitudeEvent,amplitudeUserData,ampiInstance._email)
        break;
      case 'Clicked_Share_Send' : 
        amplitudeUserData = {
          'User': ampiInstance._email,
          'Notes': amplitudeUserData.note,
          'Lens':props.match.params.urlLensSelected,
          'Country_Name':ampiInstance._country,
          'Category_Name':ampiInstance._category,
          'Trend_Name':ampiInstance._name,
          'Maturity_Phase': ampiInstance._trendName
        }
        amplify(amplitudeEvent,amplitudeUserData,ampiInstance._email)
        break;
    }
  }
  catch(err) {
    console.log(err,'Amplitude error in ShareCard');
  }
}

const centerModifier = {
  name: "computeStyles",
  phase: "write",
  enabled: true,
  fn({ state }) {
    const {
      rects: {
        reference: { width: rW, height: rH, x: rX, y: rY },
        popper: { width: pW, height: pH }
      }
    } = state;
    state.styles.popper = {
      ...state.styles.popper,
      position: "fixed",
      left: `${rX + (rW - pW) * 0.5}px`,
      top: `${rY + (rH - pH) * 0.5}px`
    };

    return state;
  }
};

const ShareCard = (props) => {

  const [users,setUsers] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Val, setVal] = useState([]);
  const [note, setNote] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()


  const handleClick = event => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
    fetchSharingUsersData()
    const ampiEvent = 'Clicked_Share'
    amp(props,ampiEvent)
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.username+'  '+option.email
  });

  const handleClose = () => {
    setIsOpen(!isOpen);
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
    setVal([]);
    setNote('');
  };

  React.useEffect(()=>{
    props.fetchSUList()
  },[])

  const fetchSharingUsersData = () => {
    if(props.sharingUserProfiles) {
      if(props.sharingUserProfiles.data) {
        setUsers(props.sharingUserProfiles.data.users)
      }
    }
  }

   const onSubmit = async() => {

    const ampiEvent = "Clicked_Share_Send"
    const ampiUserData = {
      'note':note
    }
    amp(props,ampiEvent,ampiUserData)

    const userData = [];
    Val.forEach(
      (userName) => {
        const filteredUsers = users.filter(user=>
          {
            if(user.username == userName.username) {
              userData.push(
                {
                  "email": user.email,
                  "username": user.username
                }
              )
            }
          return user;
          }
        )
        
      }
    )

    let raw = {
      "to": userData,
      "notes": `${note}`,
      "trend": {
        "name": `${props.trendName}`,
        "id": `${props.trendId}`,
        "country": `${props.country}`,
        "category": `${props.category}`,
        "url": window.location.href,
        "project_id": `${props.match.params.projectId}`,
        "product_id": `${props.match.params.productId}`,
        "lens": `${props.match.params.urlLensSelected}`,
        "image_url": `${props.productImage}`,
      }
    };
    const result = await props.sendSUList(raw)
    result ? (snackbarShow('The trend is shared successfully','success'),setVal([]),setNote('')) : snackbarShow('The trend could not be sent. Please try again.','error')
  }

  const snackbarShow = (content,muiVariant) => {
    enqueueSnackbar(<span>{content }</span>, { variant: muiVariant});
    setTimeout(() => {
        closeSnackbar()
      },5000)
  };

  const handleCancel = () => {
    handleClose();
    let ampiEvent = "Clicked_Share_Cancel"
    amp(props,ampiEvent)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-id' : undefined;
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        startIcon={<ShareRoundedIcon />}
      >
        Share
      </Button>
      <Dialog
        open={open}
        keepMounted
        fullWidth
        sx={{px:2,display:"flex",justifyContent:"center",alignItems:"center"}}
      >
        <StyledPopper
          id={id}
          open={open}
          // anchorEl={anchorEl}
          modifier={centerModifier}
          placement="bottom"
        >          
            <Box>
              <Box display="flex" p="5px" pb="15px">
                <img src={ShareIcon} />
                <Typography sx={{ px: 2 , alignSelf: "center"}}>
                  {"Share with your Teammates"}
                </Typography>
              </Box>
            <Box>
            <Autocomplete
                PopperComponent={PopperComponent}
                multiple
                clearOnBlur={false}
                multiline
                maxRows={5}
                limitTags={2}
                onKeyDown={(event) => {
                  if(event.keyCode=='37'||event.keyCode=='39') {
                    event.defaultMuiPrevented = true;
                  }
                }}
                disableCloseOnSelect
                value={Val}
                noOptionsText={`No match found `}
                MenuProps={{
                  variant: "menu",
                  getContentAnchorEl: null,
                  }}
                onChange={(event, newVal) => {
                  setVal(newVal);
                  const ampiEvent = "Clicked_Add_Team_Members"
                  const ampiUserData = {
                    teamMembers : newVal,
                  }
                  amp(props,ampiEvent,ampiUserData)
                }}
                id="tags-filled"
                filterOptions={filterOptions}
                options={users.map((option)=>option)}
                filterSelectedOptions
                getOptionLabel={option => option.username}
                renderOption={(props, options, { selected }) =>{  return(
                  <li {...props}>
                    <FormGroup row>
                      <Typography variant="subtitle1">
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...props}
                              icon={icon}
                              disableRipple
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                          }
                          label={options.username}
                        />
                      </Typography>
                    </FormGroup>
                  </li>
                )}}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.username}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={Val.length === 0 ? "Add team members" : "Add more"}
                    required
                  />
                )}
              />
            </Box>       
              <Box>
                <TextField
                  id="standard-multiline-flexible"
                  sx={{ mt: 1, height:'150px' }}
                  fullWidth
                  placeholder="Add notes here"
                  multiline
                  minRows={5}
                  maxRows={6}
                  onChange={(e) => setNote(e.target.value)}
                  inputProps={{ maxLength: 256 }}
                />
              </Box>
              <Box my="20px">
                <DialogActions sx={{ padding: 0 }}>
                  <Button variant="outlined" size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    onClick={()=>{onSubmit(); handleClose(); }
                    }
                    variant={Val.length === 0 ? "outlined" : "contained"}
                    disabled={Val.length === 0 ? true : false}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </StyledPopper>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    sharingUserProfiles: state.fetchSharingUsers.fetchSharingUsersList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSUList: () =>
      dispatch(actions.fetchSharingUsersList()),
    sendSUList: (data) =>
      dispatch(actions.sendSharingUsersList(data)),
  }
}


export default withRouter(
  connect(mapStateToProps,
    mapDispatchToProps
  )(ShareCard)
)


