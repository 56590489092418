import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { Typography, Button, Dialog, DialogContent, Box } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import DialogActions from '@mui/material/DialogActions'
import GenericMainBody from '../GenericTable/GenericMainBody'
import GenericTableHead from './../../shared/components/GenericTableComponent/GenericTableHead'

const UAMDashboardAssignedUI = (props) => {
  const {
    order,
    orderBy,
    handleRequestSort,
    dashboardData,
    adminPanelHeadCells,
    dashboardSubHeadCells,
    removeSubcategoryOpen,
    handleClose,
    dropdownOpen,
    subCategoryRemoval,
    checkBoxChecked,
    setCheckBoxChecked,
    setRemoveSubcategoryOpen,
    checkedData,
    handleState,
    subData,
    deleteSubCategoryDialog,
    setDeleteSubCategoryDialog,
    removeDashboard,
  } = props
  return (
    <>
      <Box mt={3}>
        <TableContainer
          component={Paper}
          sx={{ height: '400px', overflow: 'scroll' }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650, overflow: 'scroll' }}
            aria-label="simple table"
          >
            <GenericTableHead
              headCells={adminPanelHeadCells}
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleRequestSort}
            />
            <GenericMainBody
              row={dashboardData}
              open={dropdownOpen}
              orderBy={orderBy}
              order={order}
              headCells={adminPanelHeadCells}
              subHeadCells={dashboardSubHeadCells}
              heading={`Dashboard Name: `}
              headingOnExpand={'dashboardName'}
              subData={subData}
              handleState={handleState}
            />
          </Table>
        </TableContainer>

        <Dialog
          onClose={() => handleClose(false)}
          aria-labelledby="customized-dialog-title"
          open={removeSubcategoryOpen}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                {!checkedData?.selection ? 'Add' : 'Remove'} Sub Category from{' '}
                {checkedData?.subCategories}.
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setRemoveSubcategoryOpen(false)
                setCheckBoxChecked(checkBoxChecked)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                subCategoryRemoval(!checkedData?.selection)
              }}
            >
              {!checkedData?.selection ? 'Add' : 'Remove'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={() => setDeleteSubCategoryDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={deleteSubCategoryDialog}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                This will delete the dashboard.
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setDeleteSubCategoryDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                removeDashboard(deleteSubCategoryDialog?.id)
                setDeleteSubCategoryDialog(false)
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default UAMDashboardAssignedUI
