import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, SvgIcon } from '@mui/material'
import CreateImportDialogContainer from '../partials/CreateImportDialogContainer'
import { ReactComponent as TrendPillarIcon } from '../../../../assets/icons/favorites-trend_pillar.svg'
import { getPillarsCount } from '../../../../store/actions'
import { amplify } from '../../../../store/utility'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiUserData.email,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiUserData.email)
  } catch (err) {
    console.log(err, 'Amplitude error in Create Pillar Flow')
  }
}
function CreatePillarFlow({ getPillarsCount, email }) {
  const [showModal, setShowModal] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const history = useHistory()
  const ampClickOnTrendPillar = () => {
    const ampiEvent = `Clicked_Trend_Pillar`
    const ampiUserData = {
      email,
    }
    amp(ampiEvent, ampiUserData)
  }
  const buttonClick = () => {
    ampClickOnTrendPillar()
    if (redirect) {
      history.push('/mui/trend-pillar')
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    getPillarsCount().then((res) => {
      if (res) {
        const { ingPillarsCount, themePillarsCount, prodPillarsCount } = res
        if (ingPillarsCount || themePillarsCount || prodPillarsCount) {
          setRedirect(true)
        }
      }
    })
  }, [])

  return (
    <>
      <Button
        data-testid="btn"
        variant="outlineHighlighted"
        onClick={buttonClick}
        startIcon={
          <SvgIcon
            component={TrendPillarIcon}
            sx={{ fontSize: '15px !important' }}
          />
        }
      >
        Trend Pillar
      </Button>
      {showModal && (
        <CreateImportDialogContainer
          open={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPillarsCount: () => dispatch(getPillarsCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePillarFlow)
