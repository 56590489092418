import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Spinner from '../../components/UI/Spinner/Spinner'
import { RoleForm } from '../index'
import classes from './role.css'
import _ from 'lodash'
import Dialog from 'react-bootstrap-dialog'

class RoleList extends Component {
  componentWillMount () {
    this.props.getAllRole()
    this.props.getAllPermission()
  }

  componentWillUnmount () {
    this.props.hideForm()
  }

  editCellButton (cell, row, enumObject, rowIndex) {
    return (<button className="glyphicon glyphicon-pencil pull-left btn customBtn" type="button"
      onClick={() => {
        this.props.editRole(row)
      }}>
    </button>
    )
  }

  deleteCellButton (cell, row, enumObject, rowIndex) {
    return (<button className="glyphicon glyphicon-trash pull-left btn customBtn" type="button"
      onClick={() => {
        this.dialog.show({
          title: 'Manage Detail',
          body: 'Are you sure to remove this detail ?',
          actions: [
            Dialog.DefaultAction('Remove', () => {
              this.props.deleteRole(row.id)
            }, 'btn-custom'),
            Dialog.DefaultAction('Close', () => {
              this.dialog.hide()
            }, 'btn-custom'),
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            this.dialog.hide()
          },
        })
      }}>
    </button>
    )
  }

  formatPermissions (permission, row, enumObject, rowIndex) {
    console.log(permission)
    if (Array.isArray(permission) && permission.length > 0) { return _.join(permission.map(p => p.permission), ', ') }
    return ''
  }

  render () {
    const { permissionOptions, permissionsAdded, selectedPermission, permissionFormValues, permissions, roleSyncErrors } = this.props
    console.log(this.props.roles)
    return (

      <div className="clearfix">
        <Dialog ref={(el) => {
          this.dialog = el
        }}/>

        {this.props.showSpinner ? <div className="sppinerSection"><Spinner/></div> : null}

        {this.props.roleList
          ? <div className="col-md-12">
            <button className={'btn ' + classes.btnCustom} onClick={() => {
              this.props.showRoleForm()
            }}>Add Role
            </button>
            <BootstrapTable options={this.options} data={this.props.roles}
              striped={true}
              hoveNr={true}>
              <TableHeaderColumn isKey dataField='_id' hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='displayName'>Name</TableHeaderColumn>
              <TableHeaderColumn width="15%" dataField='role'>Role Name</TableHeaderColumn>
              <TableHeaderColumn dataField='permissions'
                dataFormat={this.formatPermissions.bind(this)}>Permissions</TableHeaderColumn>
              <TableHeaderColumn width="8%" dataField='button'
                dataFormat={this.editCellButton.bind(this)}><i
                  className="fa fa-pencil"></i></TableHeaderColumn>
              <TableHeaderColumn width="8%" dataField='button'
                dataFormat={this.deleteCellButton.bind(this)}><i
                  className="fa fa-trash"></i></TableHeaderColumn>
            </BootstrapTable>
          </div> : null
        }

        {this.props.roleForm
          ? <div className='col-md-12'>
            <button className={'btn ' + classes.btnCustom} onClick={() => {
              this.props.showRoleList()
            }}><i className="fa fa-arrow-left"></i>
            </button>
            <RoleForm
              permissions={this.props.permissions}
              onSubmit={this.props.addRolePermission}
            />
          </div> : null
        }

      </div>
    )
  }
}

export default RoleList
