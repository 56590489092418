import React from 'react'
import {
  ButtonGroup,
  Radio,
  Box,
  Typography,
  Grid,
  SvgIcon,
  Tabs,
  Tab,
  Button,
  FormControlLabel,
  Tooltip,
} from '@mui/material'
import SearchBox from '../../../../components/SearchBox/SearchBox'
import FilterComponent from '../../../../shared/components/FilterComponent'
import { DEMOGRAPHIC_AGE_RANGES } from '../../../../shared/utils/constants'

const AgeGroupButtonsUI = ({
  viewMode,
  setChart,
  setTable,
  ageGroupTab,
  handleClickMillennials,
  handleClickGenZ,
  handleClickGenX,
  searchValue,
  handleSearch,
  handleChange,
  handleSelectChange,
  allDropdownsList,
  applySelectedFilters,
  resetSelectedFilterValues,
  handleFilterClickOpen,
  handleCloseFilter,
  open,
  anchorEl,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
  ageGroupGenX,
  ageGroupGenZ,
  ageGroupMillanials,
}) => {
  return (
    <>
      <Grid sx={{ mx: 2, mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ButtonGroup aria-label="Table chart button group">
            <Tooltip
              title={`Age range: ${DEMOGRAPHIC_AGE_RANGES.ageGroupGenX}`}
            >
              <FormControlLabel
                checked={ageGroupTab === ageGroupGenX}
                value={ageGroupGenX}
                onClick={handleClickGenX}
                label={ageGroupGenX}
                control={
                  <Radio
                    sx={{ color: (theme) => theme.palette.primary.dark }}
                  />
                }
              />
            </Tooltip>
            <Tooltip
              title={`Age range: ${DEMOGRAPHIC_AGE_RANGES.ageGroupMillennials}`}
            >
              <FormControlLabel
                checked={ageGroupTab === ageGroupMillanials}
                value={ageGroupMillanials}
                label={ageGroupMillanials}
                control={
                  <Radio
                    sx={{ color: (theme) => theme.palette.primary.light }}
                  />
                }
                onClick={handleClickMillennials}
              />
            </Tooltip>
            <Tooltip
              title={`Age range: ${DEMOGRAPHIC_AGE_RANGES.ageGroupGenZ}`}
            >
              <FormControlLabel
                checked={ageGroupTab === ageGroupGenZ}
                value={ageGroupGenZ}
                label={ageGroupGenZ}
                control={
                  <Radio
                    sx={{ color: (theme) => theme.palette.secondary.light }}
                  />
                }
                onClick={handleClickGenZ}
              />
            </Tooltip>
          </ButtonGroup>
          {viewMode === 'table' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingBottom: '10px',
              }}
            >
              <FilterComponent
                handleSelectChange={handleSelectChange}
                allDropdownsList={allDropdownsList}
                applySelectedFilters={applySelectedFilters}
                resetSelectedFilterValues={resetSelectedFilterValues}
                handleFilterClickOpen={handleFilterClickOpen}
                handleCloseFilter={handleCloseFilter}
                open={open}
                anchorEl={anchorEl}
                selectedDataPoints={selectedDataPoints}
                selected2YearCAGR={selected2YearCAGR}
                selected4YearCAGR={selected4YearCAGR}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  )
}
export default AgeGroupButtonsUI
