import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export const SentimentCardContainer = styled(Paper)(({ theme }) => ({
  height: theme.spacing(19.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  boxShadow: '0px 4px 12px 0px #272B3108',
  '& > .MuiBox-root:first-of-type': {
    marginRight: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const sentimentColors = theme => ({
  positive: theme.palette.success.main,
  negative: theme.palette.error.main,
  neutral: theme.palette.secondary.light,
});

export const SentimentLabel = styled(({ sentiment, value, ...rest }) => (
  <Box {...rest}>
    <Box />
    <Typography variant="subtitle2">
      {value}
      %
    </Typography>
    <Typography
      variant="subtitle2"
      color="textSecondary"
      sx={{ textTransform: 'capitalize' }}
    >
      {sentiment}
    </Typography>
  </Box>
))(({ theme, sentiment }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& > .MuiTypography-root:first-of-type': {
    color: sentimentColors(theme)[sentiment],
    width: 48,
  },
  '& >.MuiBox-root': {
    width: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: sentimentColors(theme)[sentiment],
    borderRadius: '2px',
  },
}));
