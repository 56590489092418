import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

import { calcDisplayedWidth } from '../../store/utility'
import { screenWinnerMessages as swMsgs } from '../../const'

import './ScreenedConceptsGraph.scss'
import OutsideClickHandler from 'react-outside-click-handler'

import { ReactComponent as Iicon } from '../../assets/images/screenWinner/sw-i-icon.svg'
import Tooltip from '../SWTooltip/SWTooltip'

/**
 *
 * @param {function} xAxisFunc plotting function for x-axis
 * @param {function} yAxisFunc plotting function for y-axis
 * @param {[{x, y, name}]} data
 * @param {{xAxis: [min, max],yAxis: [min, max]}} drawingRange
 */
const handleOverlap = (xAxisFunc, yAxisFunc, data, drawingRange) => {
  const labelArray = data.map(({ x, y, id, name, originalityRank }) => {
    const txtWidth = Math.ceil(calcDisplayedWidth(name, 14))
    return {
      x: Math.round(xAxisFunc(x)) + 8,
      y: Math.round(yAxisFunc(y)) + 5,
      cirX: Math.round(xAxisFunc(x)),
      cirY: Math.round(yAxisFunc(y)),
      width: Math.min(185, txtWidth),
      data: { x, y, name, id, originalityRank },
      name: name,
      height: txtWidth > 155 ? 40 : 20,
      dir: {
        left: null,
        right: null,
        top: null,
        bottom: null,
      },
    }
  })
  const dataPointArray = data.map(({ x, y }) => ({
    x: Math.round(xAxisFunc(x)) - 8,
    y: Math.round(yAxisFunc(y)) - 8,
    width: 16,
    height: 16,
  }))

  for (let i = 0; i < labelArray.length; i++) {
    // Check with all points
    for (let j = 0; j < labelArray.length; j++) {
      // Check all data points other than itseflf
      if (i !== j) {
        // Check for both left and right direction
        for (let dirCheck = 0; dirCheck < 2; dirCheck++) {
          const iDirection = dirCheck === 0 ? 'left' : 'right'
          const iDirectionParam = labelArray[i].dir
          const iEl = labelArray[i]
          const jEl = labelArray[j]
          // Break out of loop if it's already added to right or left
          if (iDirectionParam.left || iDirectionParam.right) {
            break
          }
          let iRectX1 = dataPointArray[i].x + (dirCheck === 0 ? 0 : 1) * 16
          // Depending on direction we try to add subtract or add label width
          let iRectX2 =
            dataPointArray[i].x +
            (dirCheck === 0 ? -1 : 1) * (labelArray[i].width + 10)
          let jRectX1 = dataPointArray[j].x
          // Depending on direction subtract or add label width for comparing point
          let mulFac = 0
          const dir = labelArray[j].dir
          if (dir.left === true) {
            mulFac = -1
          } else if (dir.right === true) {
            mulFac = 1
          }
          let jRectX2 =
            dataPointArray[j].x +
            mulFac * (labelArray[j].width + 25) +
            (mulFac === 0 ? 16 : 0)
          const iRectY1 = labelArray[i].y - 10
          const iRectY2 = labelArray[i].y + labelArray[i].height
          const jRectY1 = labelArray[j].y - 10
          const jRectY2 = labelArray[j].y + labelArray[j].height
          let overlapX = 0
          let overlapY = 0
          if (iRectX1 > iRectX2) {
            const tmp = iRectX2
            iRectX2 = iRectX1
            iRectX1 = tmp
          }
          if (jRectX1 > jRectX2) {
            const tmp = jRectX2
            jRectX2 = jRectX1
            jRectX1 = tmp
          }
          if (
            iRectX1 < drawingRange.xAxis[0] - 12 ||
            iRectX1 > drawingRange.xAxis[1]
          ) {
            labelArray[i].dir.left = false
          }
          if (
            iRectX2 > drawingRange.xAxis[1] ||
            iRectX2 < drawingRange.xAxis[0] - 12
          ) {
            labelArray[i].dir.right = false
          }
          // check overlapping area on horizontal axis
          overlapX = Math.max(
            0,
            Math.min(jRectX2, iRectX2) - Math.max(iRectX1, jRectX1),
          )
          overlapY = Math.max(
            0,
            Math.min(iRectY2, jRectY2) - Math.max(iRectY1, jRectY1),
          )
          const overlapArea = overlapX * overlapY

          if (overlapArea !== 0) {
            labelArray[i].dir[iDirection] = false
          }
        }
        // If there's any overlap on both sides end loop for this datapoint
        if (
          labelArray[i].dir.left === false &&
          labelArray[i].dir.right === false
        ) {
          break
        }
      }
    }
    // If thers's no overlap on left add to left else to right
    // or set to false for both sides
    if (labelArray[i].dir.left !== false) {
      labelArray[i].x = labelArray[i].x - labelArray[i].width - 16
      labelArray[i].dir.left = true
      labelArray[i].dir.right = false
    } else if (labelArray[i].dir.right !== false) {
      labelArray[i].dir.right = true
      labelArray[i].dir.left = false
    } else {
      labelArray[i].dir.right = false
      labelArray[i].dir.left = false
    }
  }
  return labelArray
}

const removeLnPath = (svgElem) => {
  svgElem.select('path').remove()
  svgElem.selectAll('line').remove()
}

const ScreenedConceptsGraph = ({
  data = [],
  selectedConcept,
  handleCircleClick,
  showTooltip,
}) => {
  const hoverTimeout = useRef(null)
  const svgRef = useRef(null)

  const removeExpandedPoints = () => {
    d3.select('.hover-layer')
      .selectAll('circle')
      .remove()
    d3.select('.hover-layer')
      .selectAll('foreignObject')
      .remove()
  }

  const onCircleClick = (circleIndex, allCircles) => {
    const circNode = d3.select(allCircles[circleIndex])
    const conceptId = Number(circNode.attr('data-id'))
    handleCircleClick(conceptId)
  }

  useEffect(() => {
    const { offsetWidth: width, offsetHeight } = document.getElementById(
      'Custom-Compnt__Screened-Results-Graph__Block',
    )
    const height = 700
    d3.select('#Custom-Compnt__Screened-Results-Graph')
      .select('svg')
      .remove()

    svgRef.current = d3
      .select('#Custom-Compnt__Screened-Results-Graph')
      .append('svg')
      .attr('version', 1.1)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
    // svg.on("click", removeExpandedPoints);
    const svg = svgRef.current
    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'white')
      .on('click', removeExpandedPoints)

    const r = 12 // radius
    const rankLimit = 4
    const xMax = 100
    const yMax = 100
    const xMin = 0
    const yMin = 0
    const paddingH = 100
    const paddingV = 40
    const titleSpace = 40

    const circPtOffset = (x, vert) => x
    const nameOffset = (x, vert) => (vert ? x - 8 : x + 5)
    const drawingRange = {
      xAxis: [paddingH, width - 20],
      yAxis: [height - paddingV - 70 - titleSpace, paddingV + titleSpace],
    }

    const xLabelPos = {
      x: width / 2 + 15,
      y: drawingRange.yAxis[0] + titleSpace + 40,
    }

    const yLabelPos = {
      x: -height / 2,
      y: 30,
    }

    svg
      .append('g')
      .append('text')
      .attr('class', 'sw-axes')
      .attr('x', xLabelPos.x)
      .attr('y', xLabelPos.y)
      .text('Originality')
      .on('click', removeExpandedPoints)

    svg
      .append('g')
      .append('text')
      .attr('class', 'sw-axes')
      .attr('x', yLabelPos.x)
      .attr('y', yLabelPos.y)
      .attr('style', 'transform: rotate(270deg);')
      .text('Relevance')
      .on('click', removeExpandedPoints)
    const dataPlot = svg
      .append('g')
      .attr('class', 'data-plot')
      .attr('clip-path', 'url(#clip)')
      .on('click', removeExpandedPoints)

    // Setup yScale and yAxis, xScale and xAxis
    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range(drawingRange.yAxis)
    const xScale = d3
      .scaleLinear()
      .domain([xMin, xMax])
      .range(drawingRange.xAxis)
    const offsetX = xScale.invert(r) - xScale.invert(0)
    const offsetY = yScale.invert(0) - yScale.invert(r)
    const circXScale = d3
      .scaleLinear()
      .domain([xMin - offsetX, xMax + offsetX])
      .range(drawingRange.xAxis)
    const circYScale = d3
      .scaleLinear()
      .domain([yMin - offsetY, yMax + offsetY])
      .range(drawingRange.yAxis)

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(5)
      // .tickSize(yScale(yMax) - yScale(yMin))
      .tickValues([0, 25, 50, 75, 100])
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(5)
      // .tickSize(xScale(xMin) - xScale(xMax))
      .tickValues([0, 25, 50, 75, 100])

    // Clipping boundaries defs
    const defs = svg.append('g').attr('class', 'clip-defs')
    // // Clip path for data points and sections
    // defs
    //   .append("defs")
    //   .append("clipPath")
    //   .attr("id", "clip")
    //   .append("rect")
    //   .attr("width", xScale(xMax) - xScale(xMin))
    //   .attr("height", yScale(yMin) - yScale(yMax))
    //   .attr("transform", `translate(${xScale(xMin)},${yScale(yMax)})`);

    // // Clip path for x-axis
    defs
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-x-axis')
      .append('rect')
      .attr('width', xScale(xMax) - xScale(xMin) + 25)
      .attr('height', yScale(yMin) - yScale(5))
      .attr(
        'transform',
        `translate(${xScale(xMin) + 10},${drawingRange.yAxis[0] + titleSpace})`,
      )

    // Clip path for y-axis
    // defs
    //   .append("defs")
    //   .append("clipPath")
    //   .attr("id", "clip-y-axis")
    //   .append("rect")
    //   .attr("width", 40)
    //   .attr("height", yScale(yMin) - yScale(yMax) + 5)
    //   .attr(
    //     "transform",
    //     `translate(${xScale(xMin) - 40},${yScale(yMax) - 10})`
    //   );

    // x-axis plot
    const xAxisSvg = svg
      .append('g')
      .attr('clip-path', 'url(#clip-x-axis)')
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${drawingRange.yAxis[0] + titleSpace})`)
      .attr(
        'style',
        'stroke-width: 3; font-size: 16px; font: normal normal normal 14px/19px Avenir, Source Sans Pro;',
      )
      .call(xAxis)
    removeLnPath(xAxisSvg)
    xAxisSvg.on('click', removeExpandedPoints)
    // y-axis plot
    const yAxisSvg = svg
      .append('g')
      .attr('clip-path', 'url(#clip-y-axis)')
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('transform', `translate(${drawingRange.xAxis[0] - 5},0)`)
      .attr(
        'style',
        'stroke-width: 3; font-size: 16px; font: normal normal normal 14px/19px Avenir, Source Sans Pro;',
      )
      .call(yAxis)
    removeLnPath(yAxisSvg)
    yAxisSvg.on('click', removeExpandedPoints)
    // Bifurcation lines plot
    const bif = svg
      .append('g')
      .attr('class', 'bif-line')
      .attr('stroke-width', 1)
      .attr('stroke', 'rgb(0,0,0)')
      .attr('clip-path', 'url(#clip)')

    const xBif = bif
      .append('line')
      .attr('x1', xScale(xMin))
      .attr('y1', yScale(50))
      .attr('x2', xScale(xMax))
      .attr('y2', yScale(50))

    const yBif = bif
      .append('line')
      .attr('x1', xScale(50))
      .attr('y1', yScale(yMin) + titleSpace)
      .attr('x2', xScale(50))
      .attr('y2', yScale(yMax) - titleSpace)

    svg
      .append('g')
      .attr('class', 'brush')
      .on('click', removeExpandedPoints)
    svg
      .append('g')
      .attr('class', 'data-points-plot')
      .attr('clip-path', 'url(#clip)')
    svg
      .append('g')
      .attr('class', 'hover-layer')
      .attr('clip-path', 'url(#clip)')
    d3.select('.hover-layer')
    // .append('circle').attr('class','')
    // Plot sections and points
    data.forEach(
      ({ category, data: plotData, bgColor, cirColor, x, y, txtColor }) => {
        const plot = dataPlot
          .append('g')
          .attr('fill', cirColor)
          .attr('class', () => `${category} category`)

        // Plot quadrant
        plot
          .selectAll('rect')
          .data([{ x, y, category }])
          .enter()
          .append('rect')
          .attr('fill', bgColor)
          .attr('x', xScale(x))
          .attr('y', yScale(y))
          .attr('width', xScale(50) - xScale(xMin))
          .attr('height', yScale(yMin) - yScale(50))

        // Plot name section
        plot
          .append('rect')
          .attr('fill', bgColor)
          .attr('x', xScale(x))
          .attr('y', () => (y === 100 ? yScale(y) - titleSpace : yScale(0)))
          .attr('width', xScale(50) - xScale(xMin))
          .attr('height', titleSpace)

        plot
          .append('rect')
          .attr('width', xScale(50) - xScale(xMin))
          .attr('height', 2)
          .attr('x', xScale(x))
          .attr('y', () => (y === 100 ? yScale(y) : yScale(0)))
          .attr('fill', txtColor)

        // Plot name
        plot
          .selectAll('text')
          .data([{ x, y, category }])
          .enter()
          .append('text')
          .attr(
            'x',
            ({ x }) =>
              xScale(x) +
              (xScale(50) - xScale(xMin) - calcDisplayedWidth(category, 23)) /
                2,
          )
          .attr(
            'y',
            () => 30 + (y === 100 ? yScale(y) - titleSpace : yScale(0)),
          )
          .attr(
            'style',
            `color: ${txtColor}; font: normal normal normal 22px/30px Avenir, Source Sans Pro; letter-spacing: 0px;`,
          )
          .text(({ category }) => category)

        // Marker Circle group
        d3.select('.data-points-plot')
          .append('g')
          .attr('id', 'marker-circle-group')

        const plotGroup = d3
          .select('.data-points-plot')
          .append('g')
          .attr('class', `block-${category.replace(' ', '')}`)
          .selectAll('g')
          .data(handleOverlap(circXScale, circYScale, plotData, drawingRange))
          .enter()
          .append('g')

        const circle = plotGroup
          .append('circle')
          .attr('cx', ({ cirX }) => circPtOffset(cirX))
          .attr('cy', ({ cirY }) => circPtOffset(cirY, true))
          .attr('r', ({ data: { originalityRank } }) =>
            originalityRank >= rankLimit
              ? 0.5 * r
              : r - (originalityRank - 1) * 2,
          )
          .attr('fill', cirColor)
          .style('cursor', 'pointer')
          .attr('data-cx', ({ data }) => data.x)
          .attr('data-cy', ({ data }) => data.y)
          .attr('data-id', ({ data }) => data.id)
          .attr('data-clicked', false)

        const showDetails = (
          { cirX, cirY, name, data },
          cirIndex,
          circles,
          expanded,
        ) => {
          const circNode = d3.select(circles[cirIndex])
          if (circNode.attr('data-clicked') === 'false') {
            const hoverRadius = 80
            const tooltip = {
              width: 200,
              height: 110,
            }
            cirX = circXScale(Number(circNode.attr('data-cx')))
            cirY = circYScale(Number(circNode.attr('data-cy')))
            const hoverTitle = d3.select('.hover-layer').append('foreignObject')

            const pElem = hoverTitle
              .attr('style', `width: 0px; height: 0px;`)
              .attr('x', cirX)
              .attr('y', cirY)
              .append('xhtml:body')
              .attr('style', `position: relative; background: transparent;`)
              .append('p')
              .classed('sw-text__info', true)
              .attr(
                'style',
                `cursor: default; border: 1px solid ${circNode.attr(
                  'fill',
                )}; overflow: auto; border-radius: 30px; background: rgba(255,255,255,0.9); width: ${
                  tooltip.width
                }px; height: ${
                  tooltip.height
                }px; font: normal normal normal 14px/19px Avenir, Source Sans Pro; text-align: center; letter-spacing: 0px;`,
              )

            if (cirX + hoverRadius > xScale(100)) {
              cirX = Math.min(xScale(100), cirX + r) - hoverRadius
            } else if (cirX - hoverRadius < xScale(0)) {
              cirX = Math.max(xScale(0), cirX - r) + hoverRadius
            }

            pElem
              .append('span')
              .attr('class', 'sw-text__info__meta')
              .attr(
                'style',
                `display: block; margin: 24px auto; margin-bottom: 6px;`,
              )
              .text(name)
            pElem
              .append('span')
              .attr('class', 'sw-text__info__meta-tag')
              .attr('style', `display: block; margin-bottom: 3px;`)
              .text('Originality: ')
              .append('span')
              .attr('class', 'sw-text__info__meta')
              .text(data.x)

            pElem
              .append('span')
              .attr('class', 'sw-text__info__meta-tag')
              .attr('style', `display: block;`)
              .text('Relevance: ')
              .append('span')
              .attr('class', 'sw-text__info__meta')
              .text(data.y)

            hoverTitle
              .transition(1000)
              .attr('x', cirX - tooltip.width / 2)
              .attr('y', cirY - tooltip.height / 2)
              .style('width', `${tooltip.width}px`)
              .style('height', `${tooltip.height}px`)

            const toggleHoverCircle = function() {
              if (!hoverTitle.classed('clicked')) {
                hoverTitle.remove()
              }
            }
            const toggleClick = () => {
              hoverTitle.classed('clicked', true)
            }
            if (expanded) {
              toggleClick()
            }
            hoverTitle.on('mouseleave', toggleHoverCircle)
          }
        }
        const higlightCircle = (
          circle,
          circleIndex,
          allCircles,
          show = false,
        ) => {
          const circNode = d3.select(allCircles[circleIndex])
          if (show) {
            circNode.attr('stroke', 'black').attr('stroke-width', 2)
          } else {
            circNode.attr('stroke', 'transparent').attr('stroke-width', 0)
          }
        }

        circle.on('mouseenter', (d, i, c) => {
          higlightCircle(d, i, c, true)
          if (hoverTimeout && hoverTimeout.current) {
            clearTimeout(hoverTimeout.current)
          }
          if (showTooltip) {
            hoverTimeout.current = setTimeout(
              () => showDetails(d, i, c, false),
              1000,
            )
          }
        })

        circle.on('mouseout', (d, i, c) => {
          higlightCircle(d, i, c, false)
          if (hoverTimeout && hoverTimeout.current) {
            clearTimeout(hoverTimeout.current)
          }
        })

        circle.on('click', (circle, circleIndex, allCircles) => {
          if (showTooltip) {
            showDetails(circle, circleIndex, allCircles, true)
            onCircleClick(circleIndex, allCircles)
          } else {
            onCircleClick(circleIndex, allCircles)
          }
        })
      },
    )

    // Zooming
    let idleTimeout
    const idleDelay = 350
    const idled = () => {
      idleTimeout = null
    }

    const zoom = (drawBtn) => {
      if (drawBtn && !document.getElementById('reset-btn')) {
        // Draw reset Zoom
        const zoomBtn = svg
          .append('g')
          .attr('id', 'reset-btn')
          .attr('cursor', 'pointer')
        const leftOff = 50
        const topOff = 5
        const resetZoomBtn = zoomBtn
          .append('rect')
          .attr('width', 30)
          .attr('height', 30)
          .attr('fill', '#06B0F1')
          .attr('x', width - leftOff)
          .attr('y', 5)
          .attr('rx', 12)
          .append('title')
          .text('Reset zoom')
        zoomBtn
          .append('rect')
          .attr('x', width - leftOff + 9)
          .attr('width', 13)
          .attr('height', 3)
          .attr('y', topOff + 13)
          .attr('fill', 'white')
          .attr('rx', 2)

        const zoomButtonHover = (hover) => {
          resetZoomBtn.attr('fill', hover ? '#f1f1f1' : '#efefef')
        }
        // zoomBtn.on("mouseover", () => zoomButtonHover(true));
        // zoomBtn.on("mouseout", () => zoomButtonHover(false));
        zoomBtn.on('click', () => brushended(true))
      }
      const transition = svg.transition().duration(150)
      xBif
        .attr('x1', xScale(xMin))
        .attr('y1', yScale(50))
        .attr('x2', xScale(xMax))
        .attr('y2', yScale(50))
      yBif
        .attr('x1', xScale(50))
        .attr('y1', yScale(yMin))
        .attr('x2', xScale(50))
        .attr('y2', yScale(yMax))
      svg
        .select('.axis--x')
        .transition(transition)
        .call(xAxis)
      svg
        .select('.axis--y')
        .transition(transition)
        .call(yAxis)
      removeLnPath(xAxisSvg)
      removeLnPath(yAxisSvg)
      svg
        .select('.data-plot')
        .selectAll('g.category')
        .each(function(d, i) {
          d3.select(this)
            .select('g > rect')
            .transition(transition)
            .attr('x', ({ x }) => xScale(x))
            .attr('y', ({ y }) => yScale(y))
            .attr('width', xScale(50) - xScale(xMin))
            .attr('height', yScale(yMin) - yScale(50))
          const newOverlapData = handleOverlap(
            circXScale,
            circYScale,
            data[i].data,
            drawingRange,
          )
          // handleOverlap(
          //   xScale,
          //   yScale,
          //   data[i].data,
          //   drawingRange
          // );

          d3.select('.data-points-plot')
            .select(`.block-${data[i].category.replace(' ', '')}`)
            .selectAll('circle')
            .transition(transition)
            .attr('cx', (d, i) => circPtOffset(newOverlapData[i].cirX))
            .attr('cy', (d, i) => circPtOffset(newOverlapData[i].cirY, true))

          d3.select(this)
            .select('g > text')
            .transition(transition)
            .attr('x', ({ x }) =>
              x >= 50
                ? xScale(98) - calcDisplayedWidth(data[i].category, 23)
                : xScale(1),
            )
            .attr('y', ({ y }) => yScale(y - 47))

          // d3.select(this)
          //   .selectAll("g > foreignObject")
          //   .transition(transition)
          //   .attr("x", (d, i) => nameOffset(newOverlapData[i].x))
          //   .attr("y", (d, i) => nameOffset(newOverlapData[i].y, true))
          //   .select("body")
          //   .style("display", (d, i) => {
          //     const { dir } = newOverlapData[i];
          //     return dir.left || dir.right ? "block" : "none";
          //   });
        })
    }

    // Position info icon
    d3.select('.sw-chart-tooltip__x-axis')
      .style('top', `${xLabelPos.y - 15}px`)
      .style('left', `${xLabelPos.x + 70}px`)

    // d3.select('.sw-chart-tooltip__x-axis')
    // .style('top', `${xLabelPos.x + 51}px`)
    // .style('left', `${xLabelPos.y}px`)

    d3.select('.sw-chart-tooltip__y-axis')
      .style('top', `${-(yLabelPos.x + 87)}px`)
      .style('left', `${yLabelPos.y - 15}px`)

    const brushended = (reset) => {
      const selectionArea = d3.event.selection
      if (!selectionArea || reset) {
        // On double click
        if (!idleTimeout && !reset)
          return (idleTimeout = setTimeout(idled, idleDelay))
        xScale.domain([xMin, xMax])
        yScale.domain([yMin, yMax])
        circXScale.domain([xMin - offsetX, xMax + offsetX])
        circYScale.domain([yMin - offsetY, yMax + offsetY])
        d3.select('#reset-btn').remove()
        zoom()
      } else {
        removeExpandedPoints()
        xScale.domain(
          [selectionArea[0][0], selectionArea[1][0]].map(xScale.invert, xScale),
        )
        yScale.domain(
          [selectionArea[1][1], selectionArea[0][1]].map(yScale.invert, yScale),
        )
        circXScale.domain(
          [selectionArea[0][0], selectionArea[1][0]].map(
            circXScale.invert,
            circXScale,
          ),
        )
        circYScale.domain(
          [selectionArea[1][1], selectionArea[0][1]].map(
            circYScale.invert,
            circYScale,
          ),
        )
        svg.select('.brush').call(brush.move, null)
        zoom(true)
      }
    }

    // Zooming layer
    const brush = d3.brush().on('end', brushended)
    // const brushLayer = d3.select(".brush").call(brush);
    // brushLayer
    //   .select(".overlay")
    //   .attr(
    //     "style",
    //     `width: ${xScale(xMax) - xScale(xMin)}px; height: ${
    //       yScale(yMin) - yScale(yMax)
    //     }px; transform: translate(${xScale(xMin)}px,${yScale(yMax)}px);`
    //   );
  }, [data])

  useEffect(() => {
    if (selectedConcept) {
      const { id } = selectedConcept
      const matchedCircle = svgRef.current.select(`circle[data-id="${id}"]`)
      const cx = matchedCircle.attr('cx')
      const cy = matchedCircle.attr('cy')
      const r = matchedCircle.attr('r')
      let markerCircle = svgRef.current.select('#marker-circle')
      const setMarker = (ref) => {
        return ref
          .attr('cx', cx)
          .attr('fill', 'transparent')
          .attr('stroke-width', 2)
          .attr('stroke', '#6F6f6f')
          .attr('stroke-dasharray', 3)
          .attr('cy', cy)
          .attr('r', Number(r) + 4)
      }
      if (markerCircle.empty()) {
        markerCircle = setMarker(
          svgRef.current
            .select('#marker-circle-group')
            .append('circle')
            .attr('id', 'marker-circle'),
        )
      } else {
        markerCircle = setMarker(markerCircle)
      }
    }
  }, [selectedConcept, data])

  return (
    <div
      className="screened-cncpts-grph"
      style={{ width: '100%', height: '100%' }}
      id="Custom-Compnt__Screened-Results-Graph__Block"
    >
      <div className="sw-chart-tooltip">
        <div className="sw-chart-tooltip__x-axis">
          <Tooltip
            relChildPos
            offsetTop={-15}
            offsetLeft={10}
            text={swMsgs.SCATTER_PLOT_ORIGINALITY_LABEL}
            ignoreTextWidth
          >
            <Iicon width={20} height={20} fill="#999" />
          </Tooltip>
        </div>
        <div className="sw-chart-tooltip__y-axis">
          <Tooltip
            relChildPos
            offsetTop={-15}
            offsetLeft={10}
            text={swMsgs.SCATTER_PLOT_RELEVANCE_LABEL}
            ignoreTextWidth
          >
            <Iicon width={20} height={20} fill="#999" />
          </Tooltip>
        </div>
      </div>
      <OutsideClickHandler onOutsideClick={removeExpandedPoints}>
        <div id="Custom-Compnt__Screened-Results-Graph"></div>
      </OutsideClickHandler>
    </div>
  )
}

export default ScreenedConceptsGraph
