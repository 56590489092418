import React, { useState, useMemo, useEffect } from 'react'
import { Box } from '@mui/material'
import _ from 'lodash'

import SearchOptsSingleSelect from '../SearchOptionsDropdown/SearchOptsSingleSelect'
import SWTab from '../Tab/Tab'

import { snakeToCamelObjKeys } from '../../store/utility'
import { RANK_MAPPING } from '../../const'

import './SWObservations.scss'
import SWRelatedProducts from '../../shared/components/SWRelatedProducts'
import { SWTooltipMUI } from '../SWTooltip/SWTooltipMUI'
import NetworkUtil from '../../services/networking/SWNetworkingUtil'
import NetworkUtilFE from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/swAxios'
import axiosFE from '../../services/networking/axios'
import SWTrendCard from '../../shared/components/SWTrendCard'
import { INGREDIENT, THEME } from '../../shared/utils/constants'

const priorityResolver = (rank, length) => {
  if (rank === length) {
    return 'LEAST'
  }
  if (rank === 1) {
    return 'MOST'
  }
  let pos = ''
  switch (rank % 10) {
    case 1:
      pos = RANK_MAPPING[1]
      break
    case 2:
      pos = RANK_MAPPING[2]
      break
    case 3:
      pos = RANK_MAPPING[3]
      break
    default:
      pos = RANK_MAPPING.DEFAULT_MAP
  }

  return `${rank + pos} ${'MOST'}`
}

const SWObservations = ({
  handleConceptSelect,
  selectedConcept,
  conceptResults = [],
  country,
  category,
  subcategory,
}) => {
  const [tabSelected, setSelectedTab] = useState(0)
  const [retailProductsAll, setRetailProductsAll] = useState({})
  const [trendsDetails, setTrendsDetails] = useState({})
  const showObservations = category && (subcategory === 'All' || !subcategory)

  const fetchRetailProducts = async (projectId, conceptId) => {
    const url = NetworkUtil.conceptRetailProducts(projectId, conceptId)
    const response = await axios.get(url)
    if (response?.data) {
      setRetailProductsAll(response.data.data)
    }
  }

  const fetchTrendsDetails = async (trends, country, category) => {
    const data = {
      country,
      category,
      trends,
    }
    const url = NetworkUtilFE.fetchTrendsDetailsByName()
    const response = await axiosFE.post(url, data)
    if (response?.data) {
      setTrendsDetails((value) => ({ ...value, ...response.data.data }))
    }
  }

  const getRetailProducts = () => {
    const projectId = selectedConcept?.project
    const conceptId = selectedConcept?.id
    if (projectId && conceptId && (subcategory === 'All' || !subcategory)) {
      setRetailProductsAll({})
      fetchRetailProducts(projectId, conceptId).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (conceptResults && country && category) {
      const allIngs = conceptResults.reduce((acc, { metaInformation }) => {
        snakeToCamelObjKeys(
          metaInformation,
        ).ingredientScores?.forEach(({ name }) => acc.add(name))
        return acc
      }, new Set())
      const allThemes = conceptResults.reduce((acc, { metaInformation }) => {
        snakeToCamelObjKeys(metaInformation).themeScores?.forEach(({ name }) =>
          acc.add(name),
        )
        return acc
      }, new Set())

      if (allIngs.size) {
        fetchTrendsDetails(
          { ingredient: [...allIngs] },
          country,
          category,
        ).catch((err) => {
          console.log(err)
          setTrendsDetails((value) => ({ ...value }))
        })
      }
      if (allThemes.size) {
        fetchTrendsDetails({ theme: [...allThemes] }, country, category).catch(
          (err) => {
            console.log(err)
            setTrendsDetails((value) => ({ ...value }))
          },
        )
      }
    }
  }, [conceptResults, country, category])

  useEffect(() => {
    getRetailProducts()
  }, [selectedConcept])

  const getProductsTextWithTooltip = (numOfProducts, retailProducts) => (
    <>
      {numOfProducts > 0 && retailProducts?.length ? (
        <SWTooltipMUI
          title={
            <Box sx={{ m: 1 }} display="flex" flexDirection="column">
              <h1
                className="sw-bold--500 sw-font-fs--18"
                style={{ marginTop: '8px' }}
              >
                Sample Retail Products
              </h1>
              <SWRelatedProducts retailProducts={retailProducts} />
            </Box>
          }
          leaveDelay={300}
          placement="left"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                maxWidth: 630,
              },
            },
          }}
        >
          <a>
            <span className="sw-bold--500">{numOfProducts}</span> products
          </a>
        </SWTooltipMUI>
      ) : (
        <>
          <span className="sw-bold--500">{numOfProducts}</span> products
        </>
      )}
    </>
  )
  const getRelevanceScoreWithTooltip = (
    score,
    name,
    trendsDetails,
    lensSelected,
  ) => {
    const lens = lensSelected.toLowerCase()
    const trendsList = trendsDetails[lens]?.details
    const lensSubscription = trendsDetails[lens]?.subscription
    const trendDetails = trendsList?.filter((obj) => obj.name === name)[0]
    return (
      <>
        {Boolean(score) &&
          (_.isEmpty(trendDetails) ? (
            <>
              <span className="sw-bold--500">{name}</span>: {score.toFixed(0)}
            </>
          ) : (
            <SWTooltipMUI
              title={
                <SWTrendCard
                  trendDetails={trendDetails}
                  subscription={lensSubscription}
                  relevanceScore={score.toFixed(0)}
                  lens={lens}
                />
              }
              leaveDelay={150}
              placement="left"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: 'max-content',
                    p: 1.5,
                  },
                },
              }}
            >
              <a>
                <span className="sw-bold--500">{name}</span>: {score.toFixed(0)}
              </a>
            </SWTooltipMUI>
          ))}
      </>
    )
  }
  const tabs = useMemo(() => {
    const matchedConcept = conceptResults.find(
      ({ id }) => id === selectedConcept?.id,
    )

    if (matchedConcept && showObservations) {
      const {
        ingredientScores,
        similarCount60To80,
        similarCount80To100,
        themeScores,
      } = snakeToCamelObjKeys(matchedConcept.metaInformation)
      const {
        originalityRank,
        relevanceRank,
        originalityScore,
        relevanceScore,
      } = matchedConcept

      const totalProductsCount = similarCount60To80 + similarCount80To100

      const originalityRationale = (
        <div className="sw-observations__info sw-font-fs--14">
          <p className="my-1">
            Concept scores{' '}
            <span className="sw-bold--500">
              {originalityScore} in Originality
            </span>{' '}
            and is the{' '}
            <span className="sw-bold--500">
              {priorityResolver(originalityRank, conceptResults.length)}{' '}
              Original
            </span>{' '}
            compared to others within{' '}
            <span className="sw-bold--500">{category}</span>.
          </p>
          <p className="my-1">
            It has a total of{' '}
            <span className="sw-bold--500">{totalProductsCount}</span> similar
            products with
          </p>
          <p className="my-1">
            {getProductsTextWithTooltip(
              similarCount80To100,
              retailProductsAll.products_80_to_100,
            )}
            <span className="sw-bold--500"> EXTREMELY</span> similar{' '}
            <span className="sw-bold--500">(80 - 100%) </span>
            and
          </p>
          <p className="my-1">
            {getProductsTextWithTooltip(
              similarCount60To80,
              retailProductsAll.products_60_to_80,
            )}
            <span className="sw-bold--500"> CLOSELY</span> similar{' '}
            <span className="sw-bold--500">(60 - 80%)</span>
          </p>
        </div>
      )

      const relevanceRationale = (
        <div className="sw-observations__info sw-font-fs--14">
          <p className="my-1">
            Concept scores{' '}
            <span className="sw-bold--500">{relevanceScore} in Relevance</span>{' '}
            and is the{' '}
            <span className="sw-bold--500">
              {priorityResolver(relevanceRank, conceptResults.length)} Relevant
            </span>{' '}
            compared to others within{' '}
            <span className="sw-bold--500">{category}</span>.
          </p>
          <p className="my-1">It has the following attributes and scores:</p>
          <p className="my-1 sw-text-decoration--underline sw-bold--500">
            INGREDIENTS:
          </p>
          {ingredientScores?.map(({ name, score }) => {
            return (
              <p className="my-1" key={`${name}-${score}`}>
                {getRelevanceScoreWithTooltip(
                  score,
                  name,
                  trendsDetails,
                  INGREDIENT,
                )}
              </p>
            )
          })}
          <p className="my-1 sw-text-decoration--underline sw-bold--500">
            THEMES:
          </p>
          {themeScores?.map(({ name, score }) => {
            return (
              <p className="my-1" key={`${name}-${score}`}>
                {getRelevanceScoreWithTooltip(
                  score,
                  name,
                  trendsDetails,
                  THEME,
                )}
              </p>
            )
          })}
        </div>
      )

      return [
        {
          id: 0,
          heading: 'Originality',
          content: originalityRationale,
        },
        {
          id: 1,
          heading: 'Relevance',
          content: relevanceRationale,
        },
      ]
    }
  }, [
    selectedConcept,
    conceptResults,
    category,
    retailProductsAll,
    trendsDetails,
  ])

  const dropdownOptions = useMemo(
    () => conceptResults?.map(({ name, id }) => ({ id, name })),
    [conceptResults],
  )

  return (
    <div className="sw-observations-container">
      <div className="sw-observations">
        <h2 className="title--22 sw-observations__title">Observations</h2>
        <div className="sw-observations__dropdown">
          <SearchOptsSingleSelect
            placeholder="Select Concept"
            data={dropdownOptions}
            value={selectedConcept}
            handleSelect={handleConceptSelect}
          />
        </div>
        {showObservations ? (
          <div className="sw-observations__tabs">
            {tabs && (
              <SWTab
                currActiveTab={tabSelected}
                tabs={tabs}
                setSelectedTab={setSelectedTab}
              />
            )}
          </div>
        ) : (
          <div className="sw-observations__no-observations">
            <p className="sw-font-fs--16">
              Observations are not available at sub-category level
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SWObservations
