import React, { useState, useEffect } from 'react'
import { Typography, Tabs, Tab, Box, Grid, ToggleButton } from '@mui/material'

import { useLoader } from '../../../hooks'

import OverlayLoader from '../../../shared/components/OverlayLoader'

import BasicInfoTab from '../BasicInfoTab'
import AssignedDashBoardUI from '../AssiganedDasboardTab/AssignedDashBoardUI'
import POCDashboard from '../POCDashboard'
import TabPanel from '../../../shared/components/TabPanel'
import FeaturesTab from '../FeaturesTab'
import ToggleButtonGroup from '../../../shared/components/ToggleButtonGroup'

import axios from '../../../services/networking/axios'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DASHBOARD_TOGGLE = ['Premium', 'POC']

const ContainerTab = ({
  disabledTab,
  setDisablesTab,
  edit,
  setClientModification,
  selectedRow,
  setSelectedRow,
  setAnchorEl,
  setEdit,
  getClientData,
}) => {
  const [tab, setTab] = useState(0)
  const [dashboardToggle, setDashboardToggle] = useState(DASHBOARD_TOGGLE[0])
  const [redirectionTodo, setRedirectionTodo] = useState(true)
  const [enablePOCToggle, setEnablePOCToggle] = useState(false)
  const [showLoader, setShowLoader] = useLoader()

  setAnchorEl(null)

  const handleChange = (event, newTab) => {
    setTab(newTab)
  }

  const handleToggleSelection = (event, toggle) => {
    if (toggle) {
      setDashboardToggle(toggle)
    }
  }

  const setPOCFeatureToggle = () => {
    if (selectedRow) {
      const request = NetworkingUtil.userAccessManagementFeatures(
        selectedRow?.clientId,
      )
      setShowLoader(true)
      axios
        .get(request)
        .then(({ data }) => {
          const enablePOCToggle = data.data.features
            .find(({ column_name: columnName }) => columnName === 'foresight')
            .sub_features.find(
              ({ column_name: columnName }) =>
                columnName === 'cognitive_search',
            ).value
          setShowLoader(false)
          setEnablePOCToggle(enablePOCToggle)
        })
        .catch((error) => {
          console.log(error)
          setShowLoader(false)
        })
    } else {
      setEnablePOCToggle(false)
    }
  }

  useEffect(() => {
    setPOCFeatureToggle()
    setDashboardToggle(DASHBOARD_TOGGLE[0])
  }, [selectedRow, tab])

  useEffect(() => {
    if (selectedRow?.status !== 'DRAFT' && edit === true) {
      setTab(0)
      setDisablesTab([])
    } else if (edit === true) {
      setTab(1)
    }
  }, [])

  return (
    <>
      {showLoader && <OverlayLoader />}
      <Grid sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="client Information tabs"
            sx={{ '& .MuiButtonBase-root.MuiTab-root': { boxShadow: 'none' } }}
          >
            <Tab label="Basic Info" {...a11yProps(0)} />
            <Tab
              disabled={disabledTab.includes('Features')}
              label="Features"
              {...a11yProps(1)}
            />
            <Tab
              disabled={disabledTab.includes('Dashboard assigned')}
              label="Dashboard assigned"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <BasicInfoTab
            setDisablesTab={setDisablesTab}
            setTab={setTab}
            setClientModification={setClientModification}
            selectedRow={selectedRow}
            edit={edit}
            setSelectedRow={setSelectedRow}
            tab={tab}
            setEdit={setEdit}
            getClientData={getClientData}
          />
        </TabPanel>
        <Typography></Typography>
        <TabPanel value={tab} index={1}>
          {selectedRow?.clientId && (
            <FeaturesTab
              setDisablesTab={setDisablesTab}
              setTab={setTab}
              setClientModification={setClientModification}
              selectedRow={selectedRow}
              edit={edit}
              redirectionTodo={redirectionTodo}
              setRedirectionTodo={setRedirectionTodo}
            />
          )}
        </TabPanel>
        <Typography></Typography>
        <TabPanel value={tab} index={2}>
          <Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <ToggleButtonGroup
                value={dashboardToggle}
                exclusive
                disabled={!enablePOCToggle}
                onChange={handleToggleSelection}
                aria-label="dashboard mode"
                sx={{
                  background: ({ palette }) => palette.background.paper,
                }}
              >
                {DASHBOARD_TOGGLE.map((mode) => (
                  <ToggleButton value={mode} aria-label={mode}>
                    {mode}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            {dashboardToggle === DASHBOARD_TOGGLE[0] ? (
              <AssignedDashBoardUI
                setClientModification={setClientModification}
                selectedRow={selectedRow}
                getClientData={getClientData}
              />
            ) : (
              <POCDashboard
                clientId={selectedRow?.clientId}
                setClientModification={setClientModification}
              />
            )}
          </Box>
        </TabPanel>
      </Grid>
    </>
  )
}
export default ContainerTab
