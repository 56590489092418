import React from 'react'

import { Backdrop } from '@mui/material'

import CircularProgressCustom from '../CircularProgressCustom'
import { CommonCardContainer } from './styles'

const CommonCardComponent = (props) => {
  const { boxStyles, loaderComponent, ...other } = props

  return (
    <CommonCardContainer
      sx={{
        position: loaderComponent?.loading && 'relative',
        ...boxStyles,
        ...(loaderComponent?.loading &&
          loaderComponent?.boxStyles &&
          loaderComponent.boxStyles),
      }}
      {...other}
    >
      {loaderComponent?.loading && (
        <Backdrop
          sx={{
            backgroundColor: 'rgba(250, 250, 250, 0.1)',
            color: '#ececed',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
          }}
          open={loaderComponent.loading}
        >
          <CircularProgressCustom />
        </Backdrop>
      )}
      {props.children}
    </CommonCardContainer>
  )
}

export default CommonCardComponent
