import React from "react";

import Infochips from "./InfoChips";

import conceptBulb from '../../assets/images/screenWinner/sw-concept-bulb.svg'
import calendarIcon from '../../assets/images/screenWinner/sw-calendar-icon.svg';

const InfoChipsContainer = ({data}) => {
  const chipTypes = {
    date: {
      text: "",
      image: ""
    },
    concept: {
      text: "",
      image: ""
    },
    screeningStatus: {
      text: "",
      image: ""
    }
  }
  return data ? (
    <div>
      {data.map((info, index) => (
        <div key={index} className={`${index === data.length - 1 ? "no-line" : "line"}`}>
          <Infochips text={"Unscreened"} image={""} />
        </div>
      ))}
    </div>
  ) : null;
}
  

export default InfoChipsContainer;
