import React, { useState, useEffect } from 'react'
import {
  Stack,
  Grid,
  Box,
  Button,
  TextField,
  Radio,
  Typography,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePickerTextField } from '../../../../components/DateRangePickerChart/styles'

const schema = yup.object({
  clientName: yup
    .string()
    .required('Client Name is required')
    .matches(
      /^[ A-Za-z0-9_@./#&+-]*$/,
      'Client Name should contain only alphanumeric and special characters',
    )
    .max(64, 'Client Name Should not be exceed 64 characters'),
  numberOfUsers: yup
    .string()
    .required('Please enter number of users')
    .matches(
      /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
      'Number of users must be only digits and it should be greater than 0',
    ),
})
function BasicInfoUI({
  data,
  clientName,
  numberOfUsers,
  accountType,
  endDate,
  handleSaveClientInfo,
  onSubmit,
  handleExit,
  handleDate,
  handleTrialAccount,
  handleLicensedAccount,
  maxDateLimit,
  dateError,
  accountTypeError,
  handleClientNameChange,
  handleUser,
  minDate,
  user,
  handleNext,
  isEndDateEmpty,
}) {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientName: clientName,
      numberOfUsers: numberOfUsers,
      accountType: accountType,
    },
  })

  const title = 'Please fill all the details in order to proceed'
  const disableHoverListener = () => {
    if (clientName && numberOfUsers && accountType && endDate) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Stack sx={{ width: '100%', padding: '20px' }}>
        <form style={{ maxWidth: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ width: '600px' }}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <Typography variant="subtitle1"> Client Name</Typography>
              {data ? (
                <TextField
                  disabled={data}
                  sx={{
                    width: '400px',
                  }}
                  name="clientName"
                  value={data ? data?.name : clientName}
                  defaultValue={data ? data?.name : clientName}
                  variant="outlined"
                  size="large"
                  placeholder="Client Name"
                  fullWidth
                />
              ) : (
                <Controller
                  control={control}
                  name="clientName"
                  render={({
                    field: { onChange, clientName, value, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled={data}
                      sx={{
                        width: '400px',
                      }}
                      inputRef={ref}
                      name="clientName"
                      value={data ? data?.name : clientName}
                      defaultValue={data ? data?.name : clientName}
                      onChange={(e) => {
                        handleClientNameChange(e, onChange)
                      }}
                      error={!!error}
                      helperText={error ? error.message : ''}
                      variant="outlined"
                      size="large"
                      placeholder="Client Name"
                      fullWidth
                    />
                  )}
                />
              )}
            </Stack>
            <Stack
              mt={5}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <Typography variant="subtitle1">
                No. of Users Permitted
              </Typography>
              {data ? (
                <TextField
                  disabled={data}
                  sx={{
                    width: '400px',
                  }}
                  value={data ? data?.max_authorised_users : numberOfUsers}
                  defaultValue={numberOfUsers}
                  variant="outlined"
                  size="large"
                  placeholder="No. of Users Permitted"
                  fullWidth
                />
              ) : (
                <Controller
                  control={control}
                  name="numberOfUsers"
                  render={({
                    field: { onChange, numberOfUsers, name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled={data}
                      sx={{
                        width: '400px',
                      }}
                      inputRef={ref}
                      name={name}
                      value={data ? data?.max_authorised_users : numberOfUsers}
                      defaultValue={numberOfUsers}
                      onChange={(e) => {
                        handleUser(e, onChange)
                      }}
                      error={!!error}
                      helperText={error ? error.message : ''}
                      variant="outlined"
                      size="large"
                      placeholder="No. of Users Permitted"
                      fullWidth
                    />
                  )}
                />
              )}
            </Stack>
            <Stack
              mt={5}
              ml={0}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '380px',
              }}
            >
              <Typography variant="subtitle1">Account Type</Typography>{' '}
              <Grid>
                <Grid display="inline" style={{ marginLeft: '70px' }}>
                  <Radio
                    checked={accountType === 'TRIAL'}
                    onClick={(e) => {
                      handleTrialAccount()
                    }}
                  />
                  Trial{' '}
                  <Radio
                    checked={accountType === 'LICENSED'}
                    onClick={(e) => {
                      handleLicensedAccount()
                    }}
                  />{' '}
                  Licensed
                </Grid>
              </Grid>
            </Stack>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.error.main,

                marginLeft: '200px',
              }}
            >
              {accountTypeError ? 'Please select Account Type' : ''}
            </Typography>

            <Stack
              mt={5}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '340px',
              }}
            >
              <Typography variant="subtitle1">End Date</Typography>

              <DatePicker
                value={endDate}
                components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
                onChange={handleDate}
                renderInput={(params) => (
                  <DatePickerTextField {...params} size="large" />
                )}
                minDate={new Date()}
                maxDate={maxDateLimit}
              />
            </Stack>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.error.main,
                marginLeft: '200px',
                marginTop: '5px',
              }}
            >
              {dateError && endDate < minDate
                ? 'End Date should not be less than current date'
                : dateError && endDate > maxDateLimit
                ? 'End Date should not be exceed more than 10 years'
                : ''}

              {isEndDateEmpty === true ? 'Please choose end date' : ''}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            mt={5}
          >
            <Grid>
              {' '}
              <Button
                alignItems="center"
                variant="contained"
                size="large"
                onClick={handleExit}
              >
                Exit Without Saving
              </Button>
            </Grid>
            <Grid>
              <Tooltip
                title={title}
                disableHoverListener={disableHoverListener()}
              >
                <Grid display="inline">
                  <Button
                    alignItems="center"
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={
                      !(clientName && numberOfUsers && accountType && endDate)
                    }
                    sx={{
                      marginRight: '100px',
                    }}
                    onClick={handleSaveClientInfo}
                  >
                    Save and Exit
                  </Button>
                </Grid>
              </Tooltip>
              <Tooltip
                title={title}
                disableHoverListener={disableHoverListener()}
              >
                <Grid display="inline">
                  <Button
                    alignItems="center"
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={
                      !(clientName && numberOfUsers && accountType && endDate)
                    }
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </Box>
        </form>
      </Stack>
    </>
  )
}

export default BasicInfoUI
