import * as AC from './actionConstants'

/**
 *
 * @param action
 * @param productName - name page
 * @param section {object}
 * @param section.data {blob}
 * @param section.name {string}
 * @returns {{section: *, type: *}}
 */
export const sectionAdd = (productName, section) => {
  return {
    type: AC.DOWNLOAD_SECTION_ADD,
    section,
    productName,
  }
}
export const sectionClear = () => {
  return {
    type: AC.DOWNLOAD_SECTION_CLEAR,
  }
}

export const sectionRemove = (nameSection) => {
  return {
    type: AC.DOWNLOAD_SECTION_REMOVE,
    nameSection,
  }
}

export const sectionDownload = () => {
  return {
    type: AC.DOWNLOAD_SECTION_DOWNLOAD,
  }
}

export const singleDownload = (name) =>{
  return {
    type: AC.DOWNLOAD_SINGLE_DOWNLOAD,
    name
  }
}

export const sectionDownloadStatus = (nameSection, status) => {
  return {
    type: AC.DOWNLOAD_SECTION_STATUS,
    nameSection,
    status,
  }
}

export const sectionDownloadSelectAll = (status) => {
  return {
    type: AC.DOWNLOAD_SECTION_SELECT_ALL,
    status,
  }
}

export const downloadAll = () => {
  return {
    type: AC.DOWNLOAD_ALL,
  }
}


export const spinnerDownload = (isShow) => ({
  type: AC.SPINNER_DOWNLOAD,
  payload: { isShow },
})

export const addRequest = (request) => ({
  type: AC.ADD_REQUEST,
  request,
})

export const doneRequest = (request) => ({
  type: AC.DONE_REQUEST,
  request,
})

export const showSingleLoader = (show) => ({
  type: AC.SINGLE_SPINNER_DOWNLOAD,
  show
})