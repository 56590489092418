import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Moment from "moment";
import NotificationCardContainer from './styles';

const calculateTime = (timestamp) => {
  const timeDiff = Moment().diff(Moment(timestamp), "seconds");
    let formattedTime = Moment(timestamp).fromNow();
    if (timeDiff > 3600 * 24 && timeDiff < 3600 * 34) {
      formattedTime = Moment(timestamp).calendar();
    } else if (timeDiff >= 3600 * 34) {
      formattedTime = Moment(timestamp).format("Do MMM YYYY");
    }
    return formattedTime
}
const NotificationItem = ({ 
  name, 
  image, 
  isNew, 
  timestamp, 
  content,
  openNotification, 
}) => {

  return(
  <NotificationCardContainer 
    isNew={isNew} 
    onClick={openNotification}>
    <Badge color="secondary" variant="dot" invisible={!isNew}>
      <img src={image} alt={name} />
    </Badge>
    <Box>
      {content}
      <Typography variant="caption" color="textSecondary">
      {calculateTime(timestamp)}
      </Typography>
    </Box>
  </NotificationCardContainer>
)};

NotificationItem.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default NotificationItem;
