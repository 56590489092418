import styled from '@emotion/styled'
import { Table, Box, Grid, Link } from '@mui/material'
import Carousel from 'react-elastic-carousel'
import theme from '../../theme'

export const StyledTable = styled(Table)(({ theme }) => ({
  display: 'block !important',
  overflowX: 'auto',
  paddingLeft: '15px',
  borderLeft: `solid 2px ${theme.palette.divider}`,
  '& > tbody tr:last-child td': {
    borderBottom: 'none !important',
  },
  '& > tbody tr td': {
    borderRight: `2px solid ${theme.palette.divider} !important`,
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& > tbody tr:first-child td': {
    fontWeight: '600',
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  '& > tbody tr:first-child td:first-child': {
    fontWeight: 'normal',
    "&:hover": {
      color: theme.palette.black,
    }
  },
  '& > tbody tr:nth-child(2) td': {
    color: `${theme.palette.secondary.main}`,
  },
  '& > tbody tr:nth-child(2) td:first-child': {
    color: `${theme.palette.text.primary}`,
  },
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
  },
}))

export const StyledCarousel = styled(Carousel)(({ theme, filterProductLength }) => ({
  '& .rec.rec-arrow-left': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-right': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-left:hover:enabled .rec.rec-arrow-right:hover:enabled': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 5px 3px ${theme.palette.secondary.dark}`,
  },

  '& .rec-dot_active': {
		visibility: !filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },

  '& .rec-dot_disable': {
		visibility: filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },
}))

export const CardContainer = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: theme.spacing(0.5),
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  border: '1px solid transparent',
  cursor: "pointer"
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.black,
  textDecorationLine: 'none',
  fontWeight: 600,
}))

export const PositiveRate = styled(Box)(({ theme }) => ({
  color: theme.palette.success.dark,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

export const NegativeRate = styled(Box)(({ theme }) => ({
  color: theme.palette.error.dark,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

