import { Box, Typography } from '@mui/material'
import React from 'react'

const NoResultFound = ({
  imgAlt,
  message,
  imgSrc,
  messageVariant,
  messageSx,
  imgSx,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box>
          <Box sx={imgSx} component="img" src={imgSrc} alt={imgAlt} />
          <Typography
            variant={messageVariant || 'subtitle1'}
            textAlign="center"
            sx={messageSx}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default NoResultFound
