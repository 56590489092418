import React from 'react'
import { connect } from 'react-redux'

import TrendPageAgeDistributionChartUI from './TrendPageAgeDistributionChartUI'

import { amplify } from '../../../store/utility'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
      Lens: ampiUserData.lens,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      SubCategory_Name: ampiUserData.subCategoryName,
    }
    switch (ampiEvent) {
      case 'Clicked_Demography_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Agegroup: ampiUserData.demography,
          Redirected_From: ampiUserData.redirectFrom,
          Trend_Name: ampiUserData.trendName,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch {
    console.log('Amplitude error in Demography Analysis')
  }
}

const TrendPageAgeDistributionChart = ({
  projectId,
  themeId,
  lens,
  country,
  category,
  trendName,
  onDataFetchCallback,
  email,
}) => {
  const logRedirect = (demography) => {
    const ampiEvent = 'Clicked_Demography_Trend'
    const ampiUserData = {
      email,
      lens,
      country,
      category,
      subCategoryName: 'null',
      demography,
      redirectFrom: 'Trend Page',
      trendName,
    }
    amp(ampiEvent, ampiUserData, ampiUserData.email)
  }

  const handleRedirect = (demography) => {
    window.open(
      `/mui/demographic-analytics?trend=${encodeURIComponent(
        trendName,
      )}&projectId=${encodeURIComponent(
        projectId,
      )}&country=${encodeURIComponent(country)}&category=${encodeURIComponent(
        category,
      )}&lens=${encodeURIComponent(lens)}&themeId=${encodeURIComponent(
        themeId,
      )}&demography=${encodeURIComponent(demography)}`,
      '_blank',
    )
    logRedirect(demography)
  }

  const handleDemographyClick = (ev, demography) => {
    ev.preventDefault()
    handleRedirect(demography)
  }

  const handleArcClickTrendPage = (demography) => {
    handleRedirect(demography)
  }

  return (
    <TrendPageAgeDistributionChartUI
      country={country}
      projectId={projectId}
      themeId={themeId}
      trendName={trendName}
      category={category}
      lens={lens}
      handleDemographyClick={handleDemographyClick}
      handleArcClickTrendPage={handleArcClickTrendPage}
      onDataFetchCallback={onDataFetchCallback}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default connect(mapStateToProps)(TrendPageAgeDistributionChart)
