import React, { useState, useEffect } from 'react'
import FavButton from './FavButton'
import SaveAsFavoritePopUpUI from './SaveAsFavoritePopUpUI'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  addToFavoritesOtherFavorites,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  fetchAddedOtherFavCheck,
} from '../../../store/actions/dashboardActions'
import { useLoader, usePreviousValue } from '../../../hooks'
import _ from 'lodash'
import OverlayLoader from '../OverlayLoader'
const SaveAsFavoritePopUp = ({
  projectId,
  productId,
  trendName,
  trendType,
  reDirectionUrl,
  breadcrumbs,
  addToFavoritesOtherFavorites,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  addedOtherFav,
  fetchAddedOtherFavCheck,
  favoriteSectionType,
  additionalInfo,
  favoriteType,
  selectedFilters,
  selectedSubFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [favoriteName, setFavoriteName] = useState('')
  const [error, setError] = useState(false)
  const [showLoader, setShowLoader] = useLoader()

  const regex = /[!@#$%^*,.?/“:’;;+=-]/g
  const containsSpecialChars = regex.test(favoriteName)

  useEffect(() => {
    fetchOtherFavoritesList(setShowLoader)
  }, [])

  const selectedFiltersPrevious = usePreviousValue([...selectedFilters])
  const selectedSubFilterPrevious = usePreviousValue(
    selectedSubFilter ? { ...selectedSubFilter } : selectedSubFilter,
  )
  const isArrayEqual = (x, y) => _.isEmpty(_.xorWith(x, y, _.isEqual))
  useEffect(() => {
    if (
      !isArrayEqual(selectedFilters, selectedFiltersPrevious) ||
      !_.isEqual(selectedSubFilter, selectedSubFilterPrevious)
    ) {
      fetchAddedOtherFavCheck(
        favoriteName,
        trendType,
        favoriteType,
        productId,
        encodeURIComponent(reDirectionUrl.replace(/^\/mui\/details\//, '/')),
        setShowLoader,
        projectId,
      )
    }
  }, [selectedFilters, selectedSubFilter])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickAddToFav = () => {
    if (addedOtherFav) {
      setOpen(false)
      removeFromOtherFavorites(
        favoriteName,
        trendType,
        favoriteType,
        productId,
        encodeURIComponent(reDirectionUrl.replace(/^\/mui\/details\//, '/')),
        setShowLoader,
      ).then(() => {
        fetchAddedOtherFavCheck(
          favoriteName,
          trendType,
          favoriteType,
          productId,
          encodeURIComponent(reDirectionUrl.replace(/^\/mui\/details\//, '/')),
          setShowLoader,
          projectId,
        )
      })
    } else {
      setOpen(true)
    }
  }
  const handleChangeFavoriteName = (e) => {
    setFavoriteName(e.target.value)

    if (regex.test(e.target.value) === false) {
      setError(false)
    }
  }

  const handleClickFavorites = () => {
    if (containsSpecialChars) {
      setError(true)
    }
    if (containsSpecialChars === false) {
      if (addedOtherFav) {
        setOpen(false)
        removeFromOtherFavorites(
          favoriteName,
          trendType,
          favoriteType,
          productId,
          encodeURIComponent(reDirectionUrl.replace(/^\/mui\/details\//, '/')),
          setShowLoader,
        ).then(() => {
          fetchAddedOtherFavCheck(
            favoriteName,
            trendType,
            favoriteType,
            productId,
            encodeURIComponent(
              reDirectionUrl.replace(/^\/mui\/details\//, '/'),
            ),
            setShowLoader,
            projectId,
          )
        })
      } else {
        addToFavoritesOtherFavorites(
          favoriteName,
          trendType,
          projectId,
          favoriteType,
          productId,
          breadcrumbs,
          encodeURIComponent(reDirectionUrl.replace(/^\/mui\/details\//, '/')),
          additionalInfo,
        ).then(() => {
          fetchAddedOtherFavCheck(
            favoriteName,
            trendType,
            favoriteType,
            productId,
            encodeURIComponent(
              reDirectionUrl.replace(/^\/mui\/details\//, '/'),
            ),
            setShowLoader,
            projectId,
          )
        })
      }
      setOpen(false)
      setFavoriteName('')
    }
  }
  return (
    <>
      <SaveAsFavoritePopUpUI
        open={open}
        handleClose={handleClose}
        setFavoriteName={setFavoriteName}
        favoriteName={favoriteName}
        handleClickFavorites={handleClickFavorites}
        error={error}
        containsSpecialChars={containsSpecialChars}
        setError={setError}
        handleChangeFavoriteName={handleChangeFavoriteName}
      />
      <FavButton
        setOpen={setOpen}
        handleClickAddToFav={handleClickAddToFav}
        favoriteSectionType={favoriteSectionType}
        addedFav={addedOtherFav}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return { addedOtherFav: myDashboard.addedOtherFav }
}

const mapDispatchToProps = (dispatch) => ({
  removeFromOtherFavorites: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
  ) =>
    dispatch(
      removeFromOtherFavorites(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
      ),
    ),
  fetchOtherFavoritesList: (setShowLoader) =>
    dispatch(fetchOtherFavoritesList(setShowLoader)),
  fetchAddedOtherFavCheck: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
    projectId,
  ) =>
    dispatch(
      fetchAddedOtherFavCheck(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
        projectId,
      ),
    ),
  addToFavoritesOtherFavorites: (
    name,
    lens,
    projectId,
    favoriteType,
    favoriteId,
    navigation,
    url,
    additionalInfo,
  ) =>
    dispatch(
      addToFavoritesOtherFavorites(
        name,
        lens,
        projectId,
        favoriteType,
        favoriteId,
        navigation,
        url,
        additionalInfo,
      ),
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SaveAsFavoritePopUp),
)
