import React, { useState } from 'react';
import {
  Autocomplete, Paper, TextField, Typography,
} from '@mui/material';
import { trendTypes } from '../../../shared/utils/constants';

const trendTypesWithAll = [{
    id: 'ALL',
    title: 'All',
    description: "All",
    typer: -1,
    icon: null
  }].concat(trendTypes)
const AddToCompareCard = (props) => (
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      boxShadow: '0px 4px 12px 0px #272B3108',
    }}
  >
    <Typography variant="h4" mb={1}>
      Add trend
    </Typography>
    <Autocomplete
      disablePortal
      value={props.comProject}
      options={props.productCountry._list.map(arrayItem => ({
          label: `${arrayItem._criteria} ${arrayItem._name}`,
          id: `${arrayItem._id}`,
          country: arrayItem._criteria,
          category: arrayItem._name,
        })
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Country & Product Category"
          placeholder="Choose Country & Product Category"
        />
      )}
      onChange={props.onProjectChange}
    />

    <Autocomplete
      disablePortal
      value={props.compTT}
      disabled={!props.comProject}
      options={trendTypesWithAll.map(t => `${t.title} Trends`)}
      renderInput={params => (
        <TextField
          {...params}
          label="Trend Maturity Phase"
          placeholder="Choose trend phase"
        />
      )}
      onChange={props.onTypeChange}
    />

    <Autocomplete
      disablePortal
      value={props.compTrend}
      disabled={!props.compTT}
      options={props.projectTrendList ? props.projectTrendList : []}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          label="Trend"
          placeholder="Choose trend"
        />
      )}
      onChange={props.onTrendChange}
    />
  </Paper>
);

export default AddToCompareCard;
