import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { ReactComponent as Xlogo } from '../../../../../assets/social-icons/X.svg'
import { format } from 'date-fns'
import ConsumerQuotesContainer from './styles'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Likes: ampiUserData.likes,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesTwitter')
  }
}

const ConsumerQuotesTwitter = ({
  timestamp,
  text,
  likes,
  tweetUrl,
  lens,
  logConsumerQuotesClicked,
  type,
}) => {
  const linkHandler = () => {
    window.open(tweetUrl, '_blanc')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = 'Clicked_Consumer_Quotes'
      const ampiUserData = {
        platform: 'Twitter',
        likes: likes,
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      <Box
        sx={{
          height: '273px',
          padding: (theme) => theme.spacing(2),
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Typography sx={{ overflowWrap: 'break-word' }} variant="subtitle2">
          {text}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          px: 2,
          py: 1.4,
        }}
      >
        <FavoriteBorderIcon
          sx={{
            color: (theme) => theme.palette.icon,
            height: 17,
            width: 17,
            mr: 1,
          }}
        />
        <Typography variant="subtitle2" color="textSecondary">
          {likes}
        </Typography>
        <Typography ml={2} variant="subtitle2" color="textSecondary">
          {format(new Date(timestamp), 'MMM dd, yyyy')}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          py: 1.05,
          px: 2,
          position: 'relative',
          minHeight: 18,
        }}
      >
        <Xlogo />

        {/* <Typography
        width="100%"
        textAlign="center"
        variant="subtitle2"
        color="textSecondary"
        px={2}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        @
        {username}
      </Typography> */}
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesTwitter.propTypes = {
  timestamp: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  likes: PropTypes.string.isRequired,
  tweetUrl: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesTwitter
