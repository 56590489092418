import NetworkUtil from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/axios'
import {
  camelToSnakeObjKeys,
  renameKeysOfObject,
  snakeToCamelObjKeys,
} from '../utility'

/**
 * @param {Array} trendIds
 * @param {String} lens
 * @param {Number} projectId
 * @returns {Promise}
 */
export const fetchTrendFamilyFilterItems = async (
  trendIds,
  lens,
  projectId,
) => {
  const url = NetworkUtil.fetchTrendFamilyFilter()
  const data = camelToSnakeObjKeys(
    {
      projectId,
      lens,
      trendIds,
    },
    { trendIds: 'trends' },
  )
  try {
    const response = await axios.post(url, data)
    const rawData = response.data?.data
    if (rawData) {
      const dropdownItems = Object.keys(rawData)
        .map((keyOfItem) => {
          const item = renameKeysOfObject(
            { name: 'label' },
            snakeToCamelObjKeys(rawData[keyOfItem]),
          )
          return {
            ...item,
            id: String(keyOfItem),
          }
        })
        .reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
      return dropdownItems
    }
    return {}
  } catch (error) {
    throw Error(`Error Fetching API ${error}`)
  }
}
