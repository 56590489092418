import React from 'react'
import NoSearchedImage from '../../assets/images/Bookmark/NoSearchedImage.svg'
import { Typography, Box } from '@mui/material'
import { Main } from './Styles'

const NoSearchedAvailable = () => {
  return (
    <Main>
      <Box>
        <Box component="img" src={NoSearchedImage} alt="No bookmarks found" />
        <Typography variant="subtitle1">No results found for the searched bookmark</Typography>
      </Box>
    </Main>
  )
}

export default NoSearchedAvailable