import React, { useState } from 'react'
import {
  Button,
  Box,
  ClickAwayListener,
  Popper,
  TextField,
  Autocomplete,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const SingleSelectSearchComponent = ({
  options = [],
  handleOptionClick,
  defaultOption,
  optionSelected,
  btnProps,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const handleOpenPopper = (event) => {
    setAnchorEl(event.currentTarget)
    setPopperOpen(true)
  }

  const handleClosePopper = () => {
    if (anchorEl) anchorEl.focus()
    setAnchorEl(null)
    setPopperOpen(false)
  }

  const handleDashboardSelection = (e, val) => {
    if (val?.label) {
      setPopperOpen(false)
      return handleOptionClick(val)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'escape') handleClosePopper()
  }
  return (
    <Box>
      <Button id="basic-button" onClick={handleOpenPopper} {...btnProps}>
        {optionSelected?.label || defaultOption}
        {popperOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Box>
        <Popper
          id="dashboard"
          open={popperOpen}
          style={{
            width: '300px',
          }}
          sx={{ maxHeight: '140px !important', overflow: 'hidden' }}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClosePopper}>
            <Box>
              <Autocomplete
                open={popperOpen}
                ListboxProps={{ style: { maxHeight: 140 } }}
                value={optionSelected?.label}
                options={options}
                isOptionEqualToValue={(option, val) => option.key === val.key}
                noOptionsText="No results"
                onClose={handleClose}
                onChange={handleDashboardSelection}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    autoComplete="off"
                    ref={params.InputProps.ref}
                    placeholder="Search"
                    defaultValue=""
                    value={searchValue}
                    inputProps={params.inputProps}
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="large"
                  />
                )}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  )
}

export default SingleSelectSearchComponent
