import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Link,
  Typography,
  IconButton,
  Grid,
  Breadcrumbs,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import CustomizationMenu from './CustomizationMenu'
import GenerateWheelModal from './GenerateWheelModal'
import DiscardBucketPopUp from '../PopUp/DiscardBucketPopUp'
import { ReactComponent as SettingIcon } from './../../../assets/icons/setting-icon.svg'
import { useLoader, useSingleSnackBar } from '../../../hooks'
import CloseIcon from '@mui/icons-material/Close'
import { useHistory } from 'react-router-dom'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import WheelConatiner from '../Wheel/WheelConatiner'
import NoResultFound from '../NoResultFound/NoResultFound'
import WheelNotGenerated from './../../../assets/images/EmptyState/WheelNotGenerated.svg'
import CircularLoaderWithText from '../CircularLoaderWithText/CircularLoaderWithText'
import { WHEEL_GENERATE_PROGRESS_MESSAGE } from '../../utils/constants'

const WHEEL_SAVE_SUCCESSFUL_MESSAGE = 'Your Wheel is successfully saved.'
const WHEEL_SAVE_ERROR_MESSAGE = 'Wheel not saved, please try again'
const MIN_COUNT = 1
const MAX_COUNT = 10
const WHEEL_NAME_LIMIT = 64
const MIN_MAX_ERROR = 'Please Select between 1 to 10'

export default function CreateWheelPage({
  wheelData,
  setIsWheelBeingCreated,
  handleCancelWheelCreation,
  logWheelCustomization,
  logSaveWheel,
  postWheel,
  handleGenerateWheelClick,
  noOfThemes,
  setNoOfThemes,
  noOfRelatedTrends,
  setNoOfRelatedTrends,
  THEME_DEFAULT_COUNT,
  RELATED_TREND_DEFAULT_COUNT,
}) {
  const [wheelList, setWheelList] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [discardPopupOpen, setDiscardPopupOpen] = useState(false)
  const [noOfThemesError, setNoOfThemesError] = useState('')
  const [noOfRelatedTrendsError, setNoOfRelatedTrendsError] = useState('')
  const [filteredWheelData, setFilteredWheelData] = useState(wheelData)
  const [nameError, setNameError] = useState(false)
  const [showLoader, setShowLoader] = useLoader()
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const history = useHistory()

  const handleModalClose = () => setOpen(false)

  const handleDiscard = () => {
    setIsWheelBeingCreated(false)
    handleCancelWheelCreation()
  }

  const breadcrumbs = [
    <Link
      variant="inherit"
      key="1"
      to="/mui/main-system"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
      underline="none"
    >
      Explore Trends
    </Link>,
    <Link
      key="2"
      variant="subtitle2"
      to="/mui/trend-pillar"
      underline="none"
      onClick={handleDiscard}
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
    >
      Trend Pillar
    </Link>,
    <Typography key="3" variant="subtitle2">
      Create Wheel
    </Typography>,
  ]

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" color="action" />
    </IconButton>
  )

  const handleSnackBar = (message, status) => {
    showSnackBar(message, status, action)
  }

  const handleCustomizationSubmit = () => {
    if (!noOfThemesError && !noOfRelatedTrendsError) {
      handleGenerateWheelClick(noOfRelatedTrends, noOfThemes)
      logWheelCustomization(wheelData, noOfThemes, noOfRelatedTrends)
      setFilteredWheelData(wheelData)
    }
    setAnchorEl(null)
  }

  const checkForNoResultFound = (filteredWheelData) => {
    const pillarLevel = filteredWheelData?.children
    if (pillarLevel?.length === 0) {
      return true
    }
    for (let i = 0; i < pillarLevel?.length; i++) {
      if (pillarLevel[i]?.children?.length === 0) {
        return true
      }
    }
    return false
  }

  const handleCustomization = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSave = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setDiscardPopupOpen(true)
  }

  const handleDiscardBucketCancel = () => {
    setDiscardPopupOpen(false)
  }

  const handleWheelSave = (wheelName) => {
    const isNameDuplicate = wheelList.find(
      (wheelDetail) =>
        wheelDetail.name.toLowerCase() === wheelName.toLowerCase(),
    )
    const isNameLong = wheelName.length > WHEEL_NAME_LIMIT
    if (!isNameDuplicate && !isNameLong) {
      const isCustomised =
        noOfThemes !== THEME_DEFAULT_COUNT ||
        noOfRelatedTrends !== RELATED_TREND_DEFAULT_COUNT
      logSaveWheel(wheelName, filteredWheelData, isCustomised)
      setNameError(false)

      // remove themes without children
      const themefilteredPillars = filteredWheelData.children.map((pillar) => {
        return {
          ...pillar,
          children: pillar.children.filter(
            (theme) => theme.children.length > 0,
          ),
        }
      })
      // post api call for saving wheel
      const data = {
        ...postWheel,
        category: postWheel.category,
        name: wheelName,
        children: themefilteredPillars,
      }
      const request = NetworkUtil.getAllWheel()
      axios
        .post(request, data)
        .then((response) => {
          console.log('res', response)
          handleSnackBar(WHEEL_SAVE_SUCCESSFUL_MESSAGE, 'success')
          history.push('/mui/trend-pillar/saved-wheels')
        })
        .catch((error) => {
          handleSnackBar(WHEEL_SAVE_ERROR_MESSAGE, 'error')
          console.log('err in post request wheel')
        })
    } else {
      setNameError(
        isNameLong
          ? `Name cannot be more than ${WHEEL_NAME_LIMIT} characters`
          : 'Name already exist!',
      )
    }
  }

  const noOfThemeChange = (e, v) => {
    const newRelatedTrendCount = e.target.value
    if (newRelatedTrendCount > MAX_COUNT || newRelatedTrendCount < MIN_COUNT) {
      setNoOfThemesError(MIN_MAX_ERROR)
    } else {
      setNoOfThemesError('')
    }
    setNoOfThemes(e.target.value)
  }

  const noOfRelatedTrendChange = (e, v) => {
    const newRelatedTrendCount = e.target.value
    if (newRelatedTrendCount > MAX_COUNT || newRelatedTrendCount < MIN_COUNT) {
      setNoOfRelatedTrendsError(MIN_MAX_ERROR)
    } else {
      setNoOfRelatedTrendsError('')
    }
    setNoOfRelatedTrends(e.target.value)
  }

  const getWheelList = () => {
    setShowLoader(true)

    const req = NetworkUtil.getAllWheel()

    axios
      .get(req)
      .then((res) => {
        const resBody = res.data.data.wheels

        // Filtering
        const allWheelList = resBody.map((wheel) => {
          const { id, country, category, name } = wheel
          const data = {
            id,
            country,
            category,
            name,
          }
          return data
        })
        setWheelList(allWheelList)

        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        console.log('Error while requesting wheel list', err)
      })
  }

  useEffect(() => {
    getWheelList()
    // handleCustomizationSubmit()
  }, [])

  if (showLoader) {
    return <CircularLoaderWithText text={WHEEL_GENERATE_PROGRESS_MESSAGE} />
  }

  return (
    <Box
      sx={{
        width: '100%',
        background: ({ palette }) => palette.white,
        height: '100%',
        minHeight: '700px',
        position: 'relative',
      }}
      py={2}
    >
      <Grid item xs={4} display={'flex'} alignItems={'center'} gap={3}>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {!checkForNoResultFound(filteredWheelData) ? (
        <WheelConatiner data={filteredWheelData} />
      ) : (
        <NoResultFound
          imgSx={{ marginTop: '200px' }}
          imgAlt="No Result Found"
          message={`Wheel could not be generated as the related ingredients are not available!`}
          imgSrc={WheelNotGenerated}
          messageSx={{ paddingTop: '10px', maxWidth: '300px' }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          display: 'flex',
        }}
      >
        <Button
          pl={1}
          variant="outlined"
          onClick={() => handleCancel()}
          sx={{
            height: '40px',
            padding: '10px',
            minWidth: '104px',
            marginRight: '10px',
          }}
        >
          Cancel
        </Button>
        <Button
          pl={1}
          variant="contained"
          onClick={() => handleSave()}
          sx={{
            height: '40px',
            padding: '10px',
            minWidth: '104px',
            marginRight: '10px',
          }}
        >
          Save Wheel
        </Button>
        <Box
          sx={{ borderLeft: ({ palette }) => `2px solid ${palette.grey[200]}` }}
        >
          <IconButton
            pl={1}
            size="small"
            aria-label="close"
            pt={0}
            sx={{
              color: ({ palette }) => palette.primary.main,
              paddingTop: '0px',
            }}
            onClick={handleCustomization}
          >
            <SettingIcon />
          </IconButton>
        </Box>
      </Box>
      <CustomizationMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        noOfThemeChange={noOfThemeChange}
        noOfRelatedTrendChange={noOfRelatedTrendChange}
        noOfThemes={noOfThemes}
        noOfThemesError={noOfThemesError}
        noOfRelatedTrendsError={noOfRelatedTrendsError}
        noOfRelatedTrends={noOfRelatedTrends}
        handleCustomizationSubmit={handleCustomizationSubmit}
      />
      <GenerateWheelModal
        open={open}
        nameError={nameError}
        handleModalClose={handleModalClose}
        handleWheelSave={handleWheelSave}
      />
      {discardPopupOpen && (
        <DiscardBucketPopUp
          popUpTabDisable={true}
          heading="Discard Wheel!"
          text={'Are you sure you want to discard ?'}
          handleDiscardBucketCancel={handleDiscardBucketCancel}
          handleDiscard={handleDiscard}
        />
      )}
    </Box>
  )
}
