import palette from './palette'

const typography = {
  fontFamily: ['Roboto', 'Open Sans', 'Arial'].join(','),
  h1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '2rem', // 32px
    letterSpacing: '-0.01562em',
    lineHeight: 1.31,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1.625rem', // 26px
    letterSpacing: '-0.00833em',
    lineHeight: 1.23,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1.375rem', // 22px
    letterSpacing: 0,
    lineHeight: 1.18,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1.125rem', // 18px
    letterSpacing: '0.00735em',
    lineHeight: 1.22,
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem', // 16px
    letterSpacing: '0.00938em',
    lineHeight: 1.25,
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.011em',
    lineHeight: 1.14,
  },
  subtitle3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.011em',
    lineHeight: 1.14,
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem', // 16px
    letterSpacing: '0.00938em',
    lineHeight: 1.12,
  },
  body2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.01071em',
    lineHeight: 1.14,
  },
  body3: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.688rem', // 11px
    letterSpacing: '0.01071em',
    lineHeight: 1.27,
  },
  button: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.14,
    fontWeight: 400,
    letterSpacing: '0.028em',
    textTransform: 'none',
  },
  caption: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    letterSpacing: '0.03333em',
    lineHeight: 1.17,
  },
  caption2: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.8rem',
    letterSpacing: '0.011em',
    lineHeight: 1.17,
  },
  overline: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '0.688rem', // 11px
    letterSpacing: '0.015em',
    lineHeight: 1.27,
    textTransform: 'uppercase',
  },
  error: {
    color: palette.error.main,
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    letterSpacing: '0.03333em',
    lineHeight: 1.17,
  },
}

export default typography
