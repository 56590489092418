import React, { useState } from 'react'
import {
  Box,
  Button,
  Table,
  Typography,
  Tooltip,
  TableRow,
  Grid,
  TableBody,
  Collapse,
  TableCell,
  Link,
  TableContainer,
  IconButton,
} from '@mui/material'
import { ReactComponent as AccordianDown } from '../../../assets/icons/accordian-down.svg'
import { ReactComponent as AccordianRight } from '../../../assets/icons/accordian-right.svg'
import GenericTableHead from '../GenericTableComponent/GenericTableHead'
import RelatedProductCard from '../RelatedRetailProducts/partials/RelatedProductCard'
import FoodServiceCard from '../FoodServiceCard'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import NoResultsFound from '../AdvancedTrendAnalysisComponents/partials/NoResultsFound'
import { getComparator, amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiInstance.country,
      Category_Name: ampiInstance.category,
      Lens: ampiUserData.lens,
    }
    switch (ampiEvent) {
      case 'Clicked_Pairing_Table_Expand':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiInstance.trendName,
          Trend_Name: ampiInstance.name,
        }
        break
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiUserData.maturityPhase,
          Redirected_From: 'Pairing Table View',
        }
        break
      case 'Clicked_Pairing_Table_Sort':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiUserData.maturityPhase,
          Column_Phase: ampiUserData.pairingHeadCells.find(
            (data) => data.id === ampiUserData.sortedBy,
          ).label,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in Pairing Table')
  }
}

const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (rowData != null) {
      return func ? func(rowData) : rowData
    }
  })
  return res
}

const PairingTable = ({
  pairingDetails,
  retailRelatedProducts,
  filteredPairingData,
  name,
  country,
  category,
  pairedIngredientDetails,
  setPairedIngredientDetails,
  projectid,
  lens,
  isTrendChecked,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [retailProduct, setRetailProduct] = useState([])
  const [cafeProduct, setCafeProduct] = useState([])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('probability')

  const pairingHeadCells = [
    {
      id: 'name',
      numeric: false,
      sorting: true,
      disablePadding: true,
      label: 'Ingredient Name',
      func: ({ id, name, trend }) => {
        return (
          <TableCell>
            <Link
              to={`/mui/details/${projectid}/ingredient/${id}`}
              target="_blank"
              underline="none"
              variant="body2"
              onClick={() => trendClickAmplitude(trend)}
            >
              {name}
            </Link>
          </TableCell>
        )
      },
    },
    {
      id: 'twoYearCAGR',
      numeric: false,
      disablePadding: false,
      sorting: true,
      label: '2 Year CAGR',
      func: (data) => <TableCell>{data.twoYearCAGR}%</TableCell>,
    },
    {
      id: 'trend',
      numeric: false,
      disablePadding: false,
      sorting: true,
      label: 'Ingredient Phase',
      func: (data) => (
        <TableCell>
          {data.trend.charAt(0).toUpperCase() +
            data.trend.slice(1).toLowerCase()}
        </TableCell>
      ),
    },
    {
      id: 'probability',
      numeric: false,
      sorting: true,
      disablePadding: false,
      label: 'Strength of Pairing',
      func: (data) => (
        <>
          <TableCell
            sx={{
              maxWidth: '250px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data.probability.toFixed(2)}%
          </TableCell>
        </>
      ),
    },
    {
      id: 'retailProductCount',
      numeric: false,
      sorting: true,
      disablePadding: false,
      label: '# Retail products',
      func: (data) => <TableCell>{data.retailProductCount}</TableCell>,
    },
    {
      id: 'fsProductCount',
      numeric: false,
      sorting: true,
      disablePadding: false,
      label: '# FS products',
      func: (data) => <TableCell>{data.fsProductCount}</TableCell>,
    },
    {
      id: 'arrow',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '30px',
      sorting: false,
      func: (data) => {
        return (
          <TableCell
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleState(data)
            }}
          >
            {
              <IconButton
                aria-label="expand row"
                size="small"
                data-testId="icon"
              >
                {data.id === dropdownOpen ? (
                  <>
                    <AccordianDown fontSize="medium" />
                  </>
                ) : (
                  <>
                    <AccordianRight fontSize="medium" />
                  </>
                )}
              </IconButton>
            }
          </TableCell>
        )
      },
    },
  ]

  const handleRequestSort = (property) => () => {
    const ampiEvent = 'Clicked_Pairing_Table_Sort'
    const ampiUserData = {
      lens,
      maturityPhase: isTrendChecked,
      sortedBy: property,
      pairingHeadCells,
    }
    amp(ampiEvent, ampiUserData)
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getRetailCafe = (row, name) => {
    const expandedIngredient = pairedIngredientDetails.find(
      ({ id }) => id === row.id,
    )
    if (expandedIngredient && expandedIngredient.id) {
      const retailProducts = expandedIngredient.retailProduct
      setRetailProduct(retailProducts)
      const cafeProducts = expandedIngredient.cafeProduct
      setCafeProduct(cafeProducts)
    } else {
      const id = row.id
      const expandedIng = { id }

      const retailProductsRequest = NetworkUtil.fetchRetailProductsPairing(
        id,
        name,
      )
      setShowLoader(true)
      axios
        .get(retailProductsRequest)
        .then((res) => {
          const retailProducts = res.data
          const formattedRetailProducts = retailProducts.map(
            ({
              image_url: image,
              brand_name: brand,
              product_url: url,
              ...product
            }) => {
              return {
                image: image,
                brand: brand,
                url: url,
                ...product,
              }
            },
          )

          setRetailProduct(formattedRetailProducts)
          expandedIng.retailProduct = formattedRetailProducts
        })
        .catch((error) => {
          setShowLoader(false)
          console.log(error)
        })
      const cafeProductsRequest = NetworkUtil.fetchCafeProductsPairing(id, name)
      axios
        .get(cafeProductsRequest)
        .then((res) => {
          setShowLoader(false)
          const cafeProducts = res.data
          const formattedCafeProducts = cafeProducts.map(
            ({
              image_url: image,
              restaurant_url: restaurantURL,
              restaurant_name: restaurantName,
              ...product
            }) => {
              return {
                image: image,
                restaurantURL: restaurantURL,
                restaurantName: restaurantName,
                ...product,
              }
            },
          )
          setCafeProduct(formattedCafeProducts)
          expandedIng.cafeProduct = formattedCafeProducts
        })
        .catch((error) => {
          setShowLoader(false)
          console.log(error)
        })
      setPairedIngredientDetails([...pairedIngredientDetails, expandedIng])
    }
  }

  const handleState = (row) => {
    if (dropdownOpen === row?.id) {
      setDropdownOpen()
      return
    }
    const ampiEvent = 'Clicked_Pairing_Table_Expand'
    const ampiUserData = {
      lens,
      maturityPhase: row.trend,
    }
    amp(ampiEvent, ampiUserData)
    setDropdownOpen(row?.id)
    if (row && row.id) getRetailCafe(row, name)
  }

  const handleRedirect = (productid) => {
    window.open(`/mui/details/${projectid}/ingredient/${productid}`, '_blank')
  }

  const trendClickAmplitude = (phase) => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      lens,
      maturityPhase: phase,
    }
    amp(ampiEvent, ampiUserData)
  }

  return (
    <>
      <Box p={3}>
        <Box
          sx={{
            height: '60px',
            minWidth: 650,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: (theme) => theme.palette.background.default,
          }}
        >
          <Box
            sx={{
              height: 'inherit',
              width: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: (theme) => theme.palette.divider,
            }}
          >
            <Typography>INGREDIENT DETAILS</Typography>
          </Box>
          <Box
            sx={{
              height: 'inherit',
              width: '610px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: (theme) => theme.palette.divider,
            }}
          >
            <Typography>PAIRING DETAILS</Typography>
          </Box>
        </Box>
        <TableContainer sx={{ height: '450px', boxShadow: 'none !important' }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="pairing table">
            <GenericTableHead
              headCells={pairingHeadCells}
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleRequestSort}
              removePadding={true}
            />

            {filteredPairingData.length ? (
              <TableBody>
                {filteredPairingData
                  .sort(getComparator(order, orderBy))
                  .map((row) =>
                    dropdownOpen === row.id ? (
                      <>
                        <TableRow data-testId="tableRow">
                          <GenericTableCell
                            rowData={row}
                            tableHeadCells={pairingHeadCells}
                          />
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: '0px' }} colSpan={11}>
                            <Collapse
                              in={dropdownOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Grid container sx={{ padding: 2 }}>
                                <Box mt={4} mb={2}>
                                  <Typography variant="h2">
                                    Retail Products
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    Sample Products
                                  </Typography>
                                </Box>

                                {retailProduct.length ? (
                                  <Box
                                    sx={{
                                      height: '240px',
                                      overflow: 'scroll',
                                      width: '-webkit-fill-available',
                                    }}
                                  >
                                    <Grid container spacing={1} mt={2}>
                                      {retailProduct.map((item) => (
                                        <Grid item xs={4}>
                                          <RelatedProductCard
                                            image={item.image}
                                            name={item.name}
                                            brand={item.brand}
                                            price={item.price}
                                            currency={item.currency}
                                            url={item.url}
                                            country={item.country}
                                            category={item.category}
                                            ingredientName={name}
                                            lens={lens}
                                            isTrendChecked={isTrendChecked}
                                            esId={
                                              retailProduct?._id ||
                                              retailProduct?.es_id
                                            }
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Box>
                                ) : (
                                  <NoResultsFound
                                    height="auto"
                                    title="Retail Products"
                                  />
                                )}
                              </Grid>
                              <Grid container sx={{ padding: 2 }}>
                                <Box mt={4} mb={2}>
                                  <Typography variant="h2">
                                    Food Service Products
                                  </Typography>
                                  <Typography variant="subtitle1" pt={1}>
                                    Sample Products
                                  </Typography>
                                </Box>
                                {cafeProduct.length ? (
                                  <Box
                                    sx={{
                                      height: '320px',
                                      overflow: 'scroll',
                                      width: '-webkit-fill-available',
                                    }}
                                  >
                                    <Grid container spacing={1} mt={2} mb={2}>
                                      {cafeProduct.map((item) => (
                                        <Grid item xs={4}>
                                          <FoodServiceCard
                                            title={item.name}
                                            description={item.description}
                                            price={item.price}
                                            currency={item.currency}
                                            image={item.url}
                                            restaurantName={item.restaurantName}
                                            url={
                                              item?.restaurantURL
                                                ? item.restaurantURL
                                                : ''
                                            }
                                            lens={lens}
                                            country={country}
                                            category={category}
                                            isTrendChecked={isTrendChecked}
                                            esId={item?._id || item?.es_id}
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Box>
                                ) : (
                                  <NoResultsFound
                                    height="auto"
                                    title="Food Service Products"
                                  />
                                )}
                              </Grid>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow data-testId="tableRow">
                        <>
                          <GenericTableCell
                            rowData={row}
                            tableHeadCells={pairingHeadCells}
                          />
                        </>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            ) : (
              !showLoader && (
                <Box
                  sx={{
                    position: 'relative',
                    left: '45vw',
                    top: '150px',
                  }}
                >
                  <Typography variant="subtitle2">No data found</Typography>
                </Box>
              )
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default PairingTable
