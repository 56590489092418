import { TextField } from '@mui/material'
import { styled } from '@mui/system'

export const ConceptTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: theme.palette.primary.main,
    fontSize: '20px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))
