import React from 'react'
import { ButtonGroup, Tooltip, Box, Button } from '@mui/material'
import { Download } from '@mui/icons-material'
function ButtonGroupComponent({
  handleListDownload,
  handleDownload,
  isChartView,
  hideListDownload,
  setTable,
  setChart,
  viewMode,
  excelDownloadCount,
  visualisationMode,
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      {visualisationMode === 'new' && viewMode !== 'chart' ? (
        <Box pr={1}>
          <Tooltip
            title={`Download ${
              excelDownloadCount < 0 ? 'All' : `Top ${excelDownloadCount}`
            } Trends`}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ padding: '8px 16px' }}
              startIcon={<Download />}
              onClick={handleListDownload}
            >
              Download list
            </Button>
          </Tooltip>
        </Box>
      ) : null}
      <ButtonGroup aria-label="Table chart button group">
        <Button
          variant={viewMode === 'chart' ? 'contained' : 'outlined'}
          onClick={setChart}
        >
          Chart View
        </Button>
        <Button
          variant={viewMode === 'table' ? 'contained' : 'outlined'}
          onClick={setTable}
        >
          List View
        </Button>
      </ButtonGroup>
      {visualisationMode !== 'new' && (
        <Box
          sx={{
            display: 'inline',
            marginLeft: '8px',
          }}
        >
          {!hideListDownload &&
            (isChartView ? (
              <Button
                variant="outlined"
                size="small"
                startIcon={<Download />}
                onClick={handleDownload}
              >
                Download chart
              </Button>
            ) : (
              <Tooltip
                title={`Download ${
                  excelDownloadCount < 0 ? 'All' : `Top ${excelDownloadCount}`
                } Trends`}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Download />}
                  onClick={handleListDownload}
                >
                  Download list
                </Button>
              </Tooltip>
            ))}
        </Box>
      )}
    </Box>
  )
}

export default ButtonGroupComponent
