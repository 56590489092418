import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { LinearProgress, Typography } from '@mui/material';

const LinearProgressWithLabel = ({ value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 2 }}>
      <LinearProgress variant="determinate" value={value} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="subtitle2">
        {`${value.toFixed(2)}%`}
      </Typography>
    </Box>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
