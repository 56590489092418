import React, { useState, useRef, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import downArrow from '../../assets/images/icons/downArrow.svg'
import upArrow from '../../assets/images/icons/up-arrow.svg'
import ImageSafe from '../Image/ImageSafe'

import './SWSingleSelectDropdown.scss'

/**
 * Renders options list and the provided placeholder element and
 * search input element
 */
const SWSingleSelectDropdown = ({
  placeholder,
  data,
  handleFilter,
  errorMessage = false,
  multiSelect = false,
}) => {
  const [showInput, setShowInput] = useState(false)
  const [options, setOptions] = useState(data)
  const [optionElem, setOptionElem] = useState(0)
  const [active, setActive] = useState(0)

  useEffect(() => {
    handleFilter(optionElem)
  }, [optionElem])

  const dropdownRef = useRef(null)

  useEffect(() => {
    if (showInput) {
      setActive(Math.floor(optionElem / 10))
    }
  }, [showInput])

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        setShowInput(false)
        break
      case 'Tab':
        setShowInput(false)
        break
      case 'ArrowUp':
        e.preventDefault()
        setActive(active - 1 >= 0 ? active - 1 : optionsList.length - 1)
        break
      case 'ArrowDown':
        e.preventDefault()
        setActive(active == optionsList.length - 1 ? 0 : active + 1)
        break
      case 'Enter':
        e.preventDefault()
        if (!showInput) {
          setShowInput(true)
        } else {
          setOptionElem(options[active].value)
          setShowInput(false)
        }
        break
      default:
        break
    }
  }

  const optionsList = options.map((option, i) => (
    <li
      role="option"
      onClick={() => setOptionElem(option.value)}
      key={i}
      className={`sw-option-item ${active === i ? 'active' : 'no-active'}`}
      onKeyDown={handleListKeyDown}
    >
      {option.label}
    </li>
  ))
  return (
    <OutsideClickHandler onOutsideClick={() => setShowInput(false)}>
      <div className="sw-search-options-dropdown">
        <>
          <div
            role="listbox"
            aria-haspopup="listbox"
            className={`search-options-dropdown__option-elem ${
              errorMessage ? 'sw-options--error' : ''
            }`}
            tabIndex={0}
            ref={dropdownRef}
            onKeyDown={(e) => {
              if (e.key != 'Tab' && e.key != 'Shift') setShowInput(true)
              handleListKeyDown(e)
            }}
            onClick={() => setShowInput(!showInput)}
          >
            <div
              className={`option-element options-text imgtitle vertical-center`}
            >
              {optionElem <= 0 ? placeholder : `${placeholder} > ${optionElem}`}
              {showInput === true ? (
                <ImageSafe
                  className="option-element__down-arrow"
                  alt="up-arrow"
                  src={upArrow}
                />
              ) : (
                <ImageSafe
                  className="option-element__down-arrow"
                  alt="down-arrow"
                  src={downArrow}
                />
              )}
            </div>
          </div>
          {showInput && (
            <ul
              onClick={() => setShowInput(!!multiSelect)}
              className="search-options-dropdown__list"
            >
              {optionsList}
            </ul>
          )}
        </>
      </div>
    </OutsideClickHandler>
  )
}

export default SWSingleSelectDropdown
