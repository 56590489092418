import React, { Component } from 'react'
import * as d3 from 'd3'
import { regionAnalytics } from './USACoordinate'

class USAGraphUI extends Component {
  state = {
    regionAnalytics: regionAnalytics,
    currRegion: null,
  }

  componentDidMount() {
    window.addEventListener('resize', this.draw)
    window.addEventListener('resize', this.renderRegionsAnalytics)
    window.addEventListener('scroll', this.handleScroll)

    this.renderRegionsAnalytics()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.regionAnalytics.length > 0 &&
      !d3.select('#regions-analytics > svg').node()
    ) {
      if (this.state.currRegion == null) {
        d3.selectAll(`.region-topLayer`).remove()
        d3.selectAll(`.region-stroke`).remove()
      }
    }

    if (this.state.currRegion == null) {
      d3.selectAll(`.region-topLayer`).remove()
      d3.selectAll(`.region-stroke`).remove()
    }
    if (this.state.currRegion != prevState.currRegion) {
      d3.selectAll(`.tooltip_graph`).remove()
      this.renderRegionsAnalytics()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.draw)
    window.removeEventListener('resize', this.renderRegionsAnalytics)
    window.removeEventListener('scroll', this.handleScroll)
  }

  getTooltipHTML = (region, distribution) => {
    return `<div><p style='font-weight:700; color:black;'>${this.props.category} in ${region}</p><p style='font-weight:400;font-size:14px;color:black;'>Social Presence across ${this.props.country} </p><p style='margin-top:8px;margin-bottom:18px;font-size:14px;color:black;'>Engagement:<span style='font-weight:500';color:white;> ${distribution}%</span></p></div>`
  }

  renderRegionsAnalytics = () => {
    function matrix(a, b, c, d, tx, ty) {
      return d3.geoTransform({
        point: function(x, y) {
          this.stream.point(a * x + b * y + tx, c * x + d * y + ty)
        },
      })
    }
    var tooltip = d3
      .select('#regions-analytics')
      .append('div')
      .attr('class', 'tooltip_graph')
      .style('opacity', 0)
      .style('z-index', 1000)
      .style('position', 'absolute')
      .style('display', 'none')
      .style('padding', '10px')
      .style('background', 'rgba(255, 255, 255, 0.25)')
      .style('box-shadow', '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )')
      .style('backdrop-filter', 'blur( 6.5px )')
      .style('-webkit-backdrop-filter', ' blur( 6.5px )')
      .style('border-radius', ' 10px')
      .style('border', ' 1px solid rgba( 255, 255, 255, 0.18 )')

    d3.select('#regions-analytics > svg').remove()
    const { regionAnalytics } = this.state
    let newRegionAnalytics = regionAnalytics
    if (this.state.currRegion) {
      const currRegionTemp = regionAnalytics.find(
        (regional) => regional.region === this.state.currRegion,
      )
      const regionsExcludingCurr = regionAnalytics.filter(
        (regional) => regional.region !== this.state.currRegion,
      )
      newRegionAnalytics = [...regionsExcludingCurr, currRegionTemp]
    }
    if (newRegionAnalytics.length > 0) {
      // Trend-regions-ananlytics initialization
      const height = 488
      const rA = d3.select('#regions-analytics')
      if (rA.node()) {
        const rASvg = rA
          .append('svg')
          .attr('version', 1.1)
          .attr('xmlns', 'http://www.w3.org/2000/svg')
          .attr('width', '100%')
          .attr('height', height)
          .attr('id', 'regions-analytics-svg')

        const svgWidth = rASvg.node().getBoundingClientRect().width
        const moveLeftFactor = (400 * (svgWidth - 1258)) / 1258 - 200
        const path = d3
          .geoPath()
          .projection(
            matrix(10, 0, 0, -12, -400 + moveLeftFactor, height + 180),
          )

        const getDistribution = (region) => {
          const distributionSum = this.props.regionalDistributionData?.reduce(
            (acc, { distribution }) => acc + distribution,
            0,
          )
          return (
            (this.props.regionalDistributionData?.find(
              (data) => data.name === region,
            ).distribution /
              distributionSum) *
            100
          ).toFixed(2)
        }

        newRegionAnalytics.forEach((region, i) => {
          // Draw the region
          const regionGroup = rASvg
            .append('g')
            .attr('class', `regions region-${region.region}`)
          regionGroup
            .selectAll(`.path-${region.region}`)
            .data(region.geo_json.features)
            .join('path')
            .attr('class', `path-${region.region}`)
            .attr('d', path)
            .attr('fill', this.props.colorScheme[region.region])
            .on('mouseover', (d, i) => {
              this.setState({ currRegion: region.region })
            })
            .on('mouseout', (d) => {
              this.setState({ currRegion: null })
            })
          rASvg
            .append('g')
            .attr('class', `regions region-stroke`)
            .selectAll(`.path-${region.region}`)
            .data(region.geo_json.features)
            .join('path')
            .attr('class', `path-${region.region}`)
            .attr('d', path)
            .attr('fill', this.props.colorScheme[region.region])
            .style(
              'stroke',
              `${
                region.region == this.state.currRegion
                  ? '#F5BA41'
                  : 'transparent'
              }`,
            )
            .attr('stroke-width', 4)

          rASvg
            .append('g')
            .attr('class', `regions region-topLayer`)
            .selectAll(`.path-${region.region}`)
            .data(region.geo_json.features)
            .join('path')
            .attr('class', `path-${region.region}`)
            .attr('d', path)
            .attr('fill', this.props.colorScheme[region.region])
            .style('cursor', !this.props.disableClick && 'pointer')
            .style(
              'stroke',
              `${
                region.region == this.state.currRegion
                  ? '#054D83'
                  : 'transparent'
              }`,
            )
            .attr('stroke-width', 0.25)
            .on('click', () => {
              if (this.props.disableClick) {
                return
              }
              this.props.handleRegionClick({ id: 0, label: region.region })
            })
            .on('mouseover', (d, i) => {
              this.setState({ currRegion: region.region })
              tooltip
                .transition()
                .duration(2)
                .style('opacity', 1)
                .style('display', this.props.hideTooltip ? 'none' : 'block')
              tooltip
                .html(
                  this.getTooltipHTML(
                    region.region,
                    getDistribution(region.region),
                  ),
                )
                .style('left', d3.event.pageX + 10 + 'px')
                .style('top', d3.event.pageY + 10 + 'px')
            })
            .on('mouseout', (d) => {
              this.setState({ currRegion: null })
            })

          let txtPosX = 0
          let txtPosY = 0
          let txtFontSize = '14px'
          let txt = ''
          switch (region.region.toLowerCase()) {
            case 'northeast':
              txtPosX = 460
              txtPosY = 170
              txtFontSize = '14px'
              txt = 'North East'
              break
            case 'midwest':
              txtPosX = 240
              txtPosY = 180
              txtFontSize = '24px'
              txt = 'Mid-West'
              break
            case 'south':
              txtPosX = 320
              txtPosY = 280
              txtFontSize = '24px'
              txt = 'South'
              break
            case 'west':
              txtPosX = 120
              txtPosY = 200
              txtFontSize = '24px'
              txt = 'West'
              break
          }

          // Region name
          rASvg
            .append('text')
            .attr('class', `region-title-${region.region}`)
            .attr('x', txtPosX)
            .attr('y', txtPosY)
            .text(`${txt}`)
            .style('font-size', txtFontSize)
            .style('cursor', !this.props.disableClick && 'pointer')
            .style('fill', 'white')
            .on('mouseover', (e) => {
              this.setState({ currRegion: region.region })
              tooltip
                .transition()
                .duration(2)
                .style('opacity', 1)
                .style('display', this.props.hideTooltip ? 'none' : 'block')
                .style('left', d3.event.pageX + 10 + 'px')
                .style('top', d3.event.pageY + 10 + 'px')
              tooltip.html(
                this.getTooltipHTML(
                  region.region,
                  getDistribution(region.region),
                ),
              )
            })
            .on('mouseout', (d) => {
              this.setState({ currRegion: null })
            })

          const txtDim = rASvg
            .select(`.region-title-${region.region}`)
            .node()
            .getBBox()

          const iIconWidth = 10
          const iIconExtraSpace = 5

          rASvg
            .append('g')
            .attr('class', `infoIcon`)
            .append('path')
            .attr(
              'd',
              'M0.322266 3.99981C0.322266 1.8566 2.10798 0.120605 4.32227 0.120605C6.53655 0.120605 8.32227 1.8566 8.32227 3.99981C8.32227 6.13587 6.53655 7.87186 4.32227 7.87186C2.10798 7.87186 0.322266 6.13587 0.322266 3.99981ZM1.17941 3.99981C1.17941 5.66436 2.60798 7.01458 4.32227 7.01458C6.03655 7.01458 7.39369 5.66436 7.39369 3.99981C7.39369 2.33525 6.03655 0.985032 4.32227 0.985032C2.60798 0.985032 1.17941 2.33525 1.17941 3.99981ZM3.89369 4.14269V2.27095C3.89369 2.0352 4.03655 1.84231 4.32227 1.84231C4.53655 1.84231 4.75084 2.0352 4.75084 2.27095V4.14269C4.75084 4.37844 4.53655 4.57133 4.32227 4.57133C4.03655 4.57133 3.89369 4.37844 3.89369 4.14269ZM4.32227 6.15016C4.60798 6.15016 4.89369 5.89297 4.89369 5.57864C4.89369 5.25715 4.60798 4.99997 4.32227 4.99997C3.96512 4.99997 3.67941 5.25715 3.67941 5.57864C3.67941 5.89297 3.96512 6.15016 4.32227 6.15016Z',
            )
            .attr('fill', 'white')
            .attr('transform', 'scale(1.5)')
            .attr(
              'transform',
              `translate(${txtDim.x + txtDim.width + iIconExtraSpace},${
                txtDim.y
              })`,
            )

          if (this.state.currRegion == region.region) {
            rASvg
              .append('rect')
              .attr('x', txtDim.x)
              .attr('y', txtDim.y)
              .attr('width', txtDim.width + iIconWidth + iIconExtraSpace)
              .attr('height', txtDim.height)
              .attr('fill', 'white')
              .attr('opacity', 0.3)
              .style('cursor', !this.props.disableClick && 'pointer')
              .on('click', () => {
                if (this.props.disableClick) {
                  return
                }
                this.props.handleRegionClick({ id: 0, label: region.region })
              })
              .on('mouseover', (e) => {
                this.setState({ currRegion: region.region })
                tooltip
                  .transition()
                  .duration(2)
                  .style('opacity', 1)
                  .style('display', this.props.hideTooltip ? 'none' : 'block')
                  .style('left', d3.event.pageX + 10 + 'px')
                  .style('top', d3.event.pageY + 10 + 'px')
                tooltip.html(
                  this.getTooltipHTML(
                    region.region,
                    getDistribution(region.region),
                  ),
                )
              })
              .on('mouseout', (d) => {
                this.setState({ currRegion: null })
              })
          }
        })
        rASvg.attr(
          'style',
          `background: transparent; ${
            this.props.scale
              ? `transform: scale(${this.props.scale})`
              : `transform: scale(1)`
          }`,
        )
      }
    }
  }

  render() {
    return (
      <>
        {!!this.state.regionAnalytics.length && (
          <div className="trend-regions">
            <h1 className="regions-caption"></h1>
            <div id="regions-analytics" />
          </div>
        )}
      </>
    )
  }
}

export default USAGraphUI
