import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import RequestLimitExceededImg from '../../../assets/images/concept_genie_limit_exceeded.png'

const RequestLimitExceededTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '24px',
  fontWeight: '400',
  margin: '12px 0px',
}))

export const RequestLimitExceededDescription = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.black,
    fontSize: '16px',
    fontWeight: '400',
    margin: '12px 0px',
  }),
)

const RequestLimitExceeded = (props) => {
  return (
    <Grid container justifyContent={'center'} spacing={2} textAlign={'center'}>
      <Grid item xs={12}>
        <img src={RequestLimitExceededImg} height={300} />
      </Grid>

      <Grid item xs={12}>
        <RequestLimitExceededTitle>
          "You have explored the maximum potential of our Beta version!" .
        </RequestLimitExceededTitle>
      </Grid>

      <Grid item xs={12}>
        <RequestLimitExceededDescription>
          Contact your account manager to increase the limit
        </RequestLimitExceededDescription>
      </Grid>
    </Grid>
  )
}

export default RequestLimitExceeded
