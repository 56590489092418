import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import MergePillarTreeViewUI from './MergePillarTreeViewUI'

const MergePillarTreeView = ({
  data,
  allPillars,
  searchValue,
  slectedCheckboxData,
  handleSubTableSort,
  subTableOrder,
  subTableOrderBy,
  tooltipOpenHandle,
  tableComponent,
  defaultExpanded,
  defaultExpandedPillar,
  handleTrendDetailsAddition,
  lensSelected,
  countrySelected,
  categorySelected,
  trendsDetails,
  setData,
  order,
  setOrder,
  setOrderBy,
  orderBy,
  loading,
  expandedNodes,
  setExpandedNodes,
  logAccordianOpen,
  getColWidth,
}) => {
  const [filteredData, setFilteredData] = useState(data)
  const history = useHistory()
  const defaultExpandedRef = useRef(null)

  useEffect(() => {
    if (expandedNodes.length && Object.keys(allPillars).length) {
      handleTrendDetailsAddition(expandedNodes, filteredData)
    }
  }, [expandedNodes])

  useEffect(() => {
    const newPillarList = JSON.parse(JSON.stringify(filteredData))
    if (expandedNodes.length) {
      newPillarList[expandedNodes[0]] = {
        ...newPillarList[expandedNodes[0]],
        trendDetails: trendsDetails,
      }
      setData(newPillarList)
    }
  }, [trendsDetails])

  useEffect(() => {
    if (defaultExpandedPillar) {
      const pillarKeys = Object.keys(allPillars)
      if (pillarKeys.length) {
        let exp = []
        if (allPillars[defaultExpandedPillar]?.pillarName) {
          pillarKeys.forEach((key) => {
            if (
              allPillars[key].searchKey.includes(
                allPillars[defaultExpandedPillar].pillarName.toLowerCase(),
              )
            ) {
              exp = [...exp, key]
            }
          })

          if (allPillars[defaultExpandedPillar]?.level !== 1) {
            exp = [...exp, allPillars[defaultExpandedPillar]?.id]
          }
          if (expandedNodes.length !== exp.length) {
            setExpandedNodes(exp)
          }
        } else {
          history.push({
            search: '',
          })
        }
      }
    }
  }, [defaultExpanded])

  useEffect(() => {
    setExpandedNodes([])
  }, [lensSelected])

  useEffect(() => {
    if (!loading && defaultExpandedPillar && defaultExpandedRef.current) {
      defaultExpandedRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        top: 40,
      })
    }
  }, [loading])

  const handleSearchExpansion = () => {
    if (searchValue === '') {
      if (!defaultExpanded.length) setExpandedNodes([])
      return allPillars
    }
    let newIdsToShow = []
    const pillarKeys = Object.keys(allPillars)
    let filteredPillars = {}
    let newExpandedNodes = []

    pillarKeys.forEach((key) => {
      const pillar = allPillars[key]
      if (
        pillar.searchKey.find((key) =>
          key.toLowerCase().includes(searchValue.toLowerCase()),
        )
      ) {
        if (pillar.level !== 1)
          newExpandedNodes = [...newExpandedNodes, `${pillar.id}`]
        if (pillar.depth === 0) newIdsToShow = [...newIdsToShow, key]
        filteredPillars = { ...filteredPillars, [key]: pillar }
      }
    })

    setExpandedNodes(newExpandedNodes)

    return filteredPillars
  }

  // const removeItemOnce = (arr, value) => {
  //   var index = arr.indexOf(value)
  //   if (index > -1) {
  //     arr.splice(index, 1)
  //   }
  //   return arr
  // }

  const recursiveChildIdRemoval = (ele, nodeIds) => {
    let newNodeId = [...nodeIds]
    if (allPillars[ele].childIds.length) {
      allPillars[ele].childIds.forEach((child) => {
        if (nodeIds.includes(child.id)) {
          newNodeId = recursiveChildIdRemoval(child.id, nodeIds)
          const index = newNodeId.indexOf(child.id)
          if (index > -1) {
            newNodeId.splice(index, 1)
          }
        }
      })
    }
    return newNodeId
  }

  const handleToggle = (event, nodeIds) => {
    // New Accordian opened
    if (nodeIds.length > expandedNodes.length) {
      logAccordianOpen(nodeIds[0])
    }
    setExpandedNodes(nodeIds)

    if (nodeIds.includes(defaultExpandedPillar)) {
      history.push({
        search: '',
      })
    }
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const headers = [
    {
      id: 'level',
      label: 'Level',
      width: '130px',
      justifyContent: 'center',
      sorting: true,
    },
    {
      id: 'pillarName',
      label: 'Pillar Name',
      width: '230px',
      sorting: true,
    },
    {
      id: 'country',
      label: 'Country',
      sorting: true,
    },
    {
      id: 'category',
      label: 'Category',
      sorting: true,
    },
    {
      id: 'trends',
      label: 'Trends',
      sorting: true,
      width: '90px',
    },
    {
      id: 'dataPoints',
      label: 'Data Points',
      sorting: true,
    },
    {
      id: 'fourYearCagr',
      label: '4 Year CAGR',
      sorting: true,
    },
    {
      id: 'twoYearCagr',
      label: '2 Year CAGR',
      sorting: true,
    },
    {
      id: 'noOfRelatedIngredients',
      label:
        lensSelected === 'Theme' ? '# of related ingredients' : '# FS Products',
      sorting: true,
    },
    {
      id: 'noOfRelatedProducts',
      label:
        lensSelected === 'Theme'
          ? '# of related products'
          : '# Retail Products',
      sorting: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      cursor: 'default',
      width: '90px',
    },
  ]

  function sortByKey(array, key) {
    const sortedArr = array.sort(function(a, b) {
      if (allPillars[a] && allPillars[b]) {
        var x = allPillars[a][key]
        var y = allPillars[b][key]
        if (order === 'asc') {
          return x < y ? -1 : x > y ? 1 : 0
        } else {
          return x > y ? -1 : x < y ? 1 : 0
        }
      }
    })

    return sortedArr
  }

  useEffect(() => {
    const searchedData = handleSearchExpansion()
    setFilteredData(searchedData)
  }, [searchValue])

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const pillarKeys = Object.keys(filteredData)
  let parentIds = []
  pillarKeys.forEach((key) => {
    const pillar = filteredData[key]

    if (pillar.depth === 0) parentIds = [...parentIds, pillar.id]
  })

  return (
    <MergePillarTreeViewUI
      allPillars={allPillars}
      headers={headers}
      handleRequestSort={handleRequestSort}
      filteredData={filteredData}
      parentIds={parentIds}
      order={order}
      orderBy={orderBy}
      expandedNodes={expandedNodes}
      handleToggle={handleToggle}
      defaultExpandedPillar={defaultExpandedPillar}
      defaultExpandedRef={defaultExpandedRef}
      slectedCheckboxData={slectedCheckboxData}
      tableComponent={tableComponent}
      handleSubTableSort={handleSubTableSort}
      tooltipOpenHandle={tooltipOpenHandle}
      categorySelected={categorySelected}
      countrySelected={countrySelected}
      lensSelected={lensSelected}
      subTableOrder={subTableOrder}
      subTableOrderBy={subTableOrderBy}
      sortByKey={sortByKey}
      searchValue={searchValue}
      getColWidth={getColWidth}
    />
  )
}

export default MergePillarTreeView
