import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Autocomplete,
  ListSubheader,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import StyledPopper from './styles'
import { VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8

function renderRow(props) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    paddingLeft: 2 * LISTBOX_PADDING,
    top: style.top + LISTBOX_PADDING,
  }

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Tooltip
      title={
        <Typography
          variant="caption"
          sx={{
            textTransform: 'capitalize',
            color: ({ palette }) => palette.primary.contrastText,
          }}
        >
          {`${dataSet[1]}: `}
          <b>{dataSet[2]}</b>
        </Typography>
      }
      placement="top"
    >
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        {dataSet[1]}
      </Typography>
    </Tooltip>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props
  const itemData = []
  children.forEach((item) => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node,
}

const VirtualizedAutocomplete = ({ options, ...rest }) => {
  return (
    <Autocomplete
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      {...rest}
      renderOption={(props, option) => [
        props,
        option.displayValue,
        option.bucket,
      ]}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params}
    />
  )
}

export default VirtualizedAutocomplete
