import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

import BtnMultiSelectDropdown from '../ButtonMultiSelectDropdown'
import countriesList from '../../../mocks/country'
import SingleSelectSearchComponent from '../SingleSelectSearchComponent'

const CognitiveSearchHeader = (props) => {
  const {
    selectedFormatOpts,
    handleUpdateFormatOpts,
    handleResetFormatOpts,
    category,
    country,
    dashboardsList,
    handleSetCountryAndCategory,
  } = props

  const [dashboardSelected, setDashboardSelected] = useState()

  const countryToBeShown =
    country && countriesList.find((item) => item.label === country)

  const options = dashboardsList?.length
    ? dashboardsList
        .map((prj) => ({
          key: `${prj.country}-${prj.category}`,
          category: prj.category,
          country: prj.country,
          label: `${prj.country} ${prj.category}`,
          variant: prj.variant,
          projectId: prj.project_id,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .filter(
          (option) =>
            option.label !==
            (dashboardSelected?.label || `${country} ${category}`),
        )
    : []

  useEffect(() => {
    if (dashboardSelected) {
      const selectedDashboardData = dashboardsList?.length
        ? dashboardsList.filter(
            (obj) =>
              obj.country === dashboardSelected.country &&
              obj.category === dashboardSelected.category,
          )[0]
        : {}
      handleSetCountryAndCategory(selectedDashboardData)
    }
  }, [dashboardSelected])

  return (
    <Stack spacing={1.5} mb={1}>
      <Box
        sx={{
          mt: 6,
          mb: 2.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.75,
            mb: 0.5,
          }}
        >
          <Box>
            <Typography variant="h4" color="text.secondary">
              Dashboard
            </Typography>
          </Box>
          <Box
            sx={{
              '& > img': {
                mr: 1,
                flexShrink: 0,
                overflow: 'hidden',
                objectFit: 'unset',
              },
            }}
          >
            {countryToBeShown && (
              <img
                loading="lazy"
                width="30"
                height="20"
                src={
                  countryToBeShown.img
                    ? countryToBeShown.img
                    : `https://flagcdn.com/${(
                        countryToBeShown || { code: '' }
                      ).code.toLowerCase()}.svg`
                }
                srcSet={
                  countryToBeShown.img
                    ? countryToBeShown.img
                    : `https://flagcdn.com/w40/${(
                        countryToBeShown || { code: '' }
                      ).code.toLowerCase()}.png 2x`
                }
                alt=""
              />
            )}
          </Box>
        </Box>
        {country && category && (
          <SingleSelectSearchComponent
            options={options}
            handleOptionClick={setDashboardSelected}
            defaultOption={`${country} ${category}`}
            optionSelected={dashboardSelected}
            btnProps={{
              sx: {
                paddingLeft: 0,
                fontSize: '1rem',
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">Trends</Typography>
        <Box>
          <BtnMultiSelectDropdown
            useParentAnchor
            buttonContent={{
              content: <>Format</>,
              hasDropDownIcon: true,
              showNumOfFiltersApplied: true,
            }}
            header={{
              content: 'Format',
              showNumOfFiltersApplied: true,
            }}
            optWidth={235}
            options={selectedFormatOpts}
            shouldSortOpts={true}
            buttonProps={{
              variant: 'contained',
            }}
            searchBarEnabled={true}
            applyButton={{
              updateFilterOptions: handleUpdateFormatOpts,
            }}
            resetButton={{
              resetFilterOptions: handleResetFormatOpts,
            }}
            selectAllOption={{
              enabled: false,
            }}
            singleSelect={true}
          />
        </Box>
      </Box>
    </Stack>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CognitiveSearchHeader)
