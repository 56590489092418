import React from 'react';
import { Menu } from '@mui/material';
import { styled } from '@mui/system';

export default styled(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(0.5),
    minWidth: theme.spacing(20),
  },
}));
