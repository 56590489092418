import React, { useMemo } from 'react'
import AnalysedTrendCard from './AnalysedTrendCard'
import { useHistory } from 'react-router-dom'
import { INGREDIENT, THEME } from '../../shared/utils/constants'

const AnalysedTrendCardList = ({
  listData,
  handleCancel,
  tabValue,
  setHoveredCard,
  logAnalysedTrends,
  hoveredCard,
  hoverCardRef,
  clickedCard,
}) => {
  const history = useHistory()

  const ComponentDefaultSx = useMemo(() => {
    return {
      imgSx: {
        width: '100%',
        p: 0,
        height: '100px',
        borderRadius: '10px',
        objectFit: tabValue === 1 ? 'contain' : 'cover',
        background: tabValue === 1 && '#EDEDED',
      },
      imgSxParent: { p: '7px' },
      cardsx: {
        height: 'fit-content',
        position: 'relative',
        width: '135px',
        border: '1px solid #E8E8E8',
        borderRadius: '10px',
        m: '5px',
        '&:hover': {
          borderColor: ({ palette }) => palette.primary.main,
        },
      },
      typographySx: {},
      cardContentSx: { width: '100%', pt: 0, px: 1 },
      nameSx: { fontWeight: 400, fontSize: '12px' },
      labelSx: { color: '#8A97A7', fontSize: '10px' },
      valSx: { fontWeight: 600, fontSize: '10px' },
      firstValSx: { color: '#00BA13' },
    }
  }, [tabValue])

  const handleViewDetail = (data) => {
    if (data.firstValue !== null && data.secondValue !== null) {
      // redirect to trend page
      const redirectLink = `/mui/details/${encodeURIComponent(
        data.project_id,
      )}/${encodeURIComponent(
        (tabValue === 0 ? INGREDIENT : THEME).toLowerCase(),
      )}/${encodeURIComponent(data.id)}`
      window.open(redirectLink, '_blank')
    } else {
      const redirectURL = `/mui/universal-trend-search?lens=${
        tabValue === 0 ? INGREDIENT : THEME
      }&trend=${encodeURIComponent(data.name)}`

      window.open(redirectURL, '_blank')
    }
    logAnalysedTrends(data?.name, 'View Detail')
  }

  return listData.map((cardData) => {
    const formattedData = {
      ...cardData,
      firstValue: cardData.two_year_cagr,
      secondValue: cardData.data_points,
      imageURL: cardData.image,
    }
    return (
      <AnalysedTrendCard
        key={`${cardData.name}-${clickedCard?.name}`}
        styleSx={ComponentDefaultSx}
        cardData={formattedData}
        firstLabel="2 Year CAGR:"
        secondLabel="Data Points:"
        handleCancel={handleCancel}
        buttonDisabled={!cardData.is_view_authorised}
        handleViewDetail={handleViewDetail}
        setHoveredCard={setHoveredCard}
        hoveredCard={hoveredCard}
        hoverCardRef={hoverCardRef}
        clickedCard={clickedCard}
      />
    )
  })
}

export default AnalysedTrendCardList
