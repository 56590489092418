import { myDashboard as myDashboardActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  favoritesTrendsData: {},
  trendHighlightConfig: {},
  trendHighlightTrendsList: [],
  addedFav: false,
  isFavoriteAllowed: false,
  isOtherFavoriteAllowed: false,
  isTrendPillarFavoriteAllowed: false,
  trendPillarFavoriteLoading: false,
  recentActivity: [],
  loading: {
    trendHighlightDataLoading: false,
    favoritesTrendsLoading: false,
    recentActivityLoading: false,
  },
  favoritesTrendPillarsData: {},
  addedFavTrendPillar: false,
  addedOtherFav: false,
  otherFavoritesData: [],
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST
    ]:
      const favoritesTrendsList = action.favoritesTrendsData.data
        ? action.favoritesTrendsData.data
            .map((obj) => {
              return { ...obj.fields }
            })
            .map((updatedObj) => {
              return {
                ...updatedObj,
                analytical_data: JSON.parse(updatedObj.analytical_data),
              }
            })
        : []
      return updateObject(state, {
        favoritesTrendsData: {
          recent_updated_timestamp:
            action.favoritesTrendsData.recent_updated_timestamp,
          data: favoritesTrendsList,
        },
        loading: { ...state.loading, favoritesTrendsLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, favoritesTrendsLoading: true },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TRENDS_LIST_FAILED
    ]:
      return updateObject(state, {
        favoritesTrendsData: initialState.favoritesTrendsData,
        loading: { ...state.loading, favoritesTrendsLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA
    ]:
      const config = action.trendHighlightData.config
      const data = JSON.parse(action.trendHighlightData.data.processed_data)
      return updateObject(state, {
        trendHighlightConfig: config,
        trendHighlightTrendsList: data || [],
        loading: { ...state.loading, trendHighlightDataLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, trendHighlightDataLoading: true },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_TREND_HIGHLIGHT_DATA_FAILED
    ]:
      return updateObject(state, {
        trendHighlightConfig: initialState.trendHighlightConfig,
        trendHighlightTrendsList: initialState.trendHighlightTrendsList,
        loading: { ...state.loading, trendHighlightDataLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_SUCCESS
    ]:
      return updateObject(state, {
        addedFav: action.addedFav?.favorite_exits,
        isFavoriteAllowed: action.addedFav?.is_favouriting_allowed,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECK_ADDED_FAVORITE_LOADING
    ]:
      return updateObject(state, {
        addedFav: initialState.addedFav,
        isFavoriteAllowed: initialState.isFavoriteAllowed,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_TREND_PILLAR_LIST_SUCCESS
    ]:
      const favoritesTrendPillarList = action.favoritesTrendPillarsData.data
        ? action.favoritesTrendPillarsData.data
            .map((obj) => {
              return { ...obj.fields }
            })
            .map((updatedObj) => {
              return {
                ...updatedObj,
              }
            })
        : []

      return updateObject(state, {
        favoritesTrendPillarsData: {
          recent_updated_timestamp:
            action.favoritesTrendPillarsData.recent_updated_timestamp,
          data: favoritesTrendPillarList,
        },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_SUCCESS
    ]:
      return updateObject(state, {
        addedFavTrendPillar: action.addedFavTrendPillar?.favorite_exits,
        isTrendPillarFavoriteAllowed:
          action.addedFavTrendPillar?.is_favouriting_allowed,
        trendPillarFavoriteLoading: false,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECK_ADDED_FAVORITE_TREND_PILLAR_LOADING
    ]:
      return updateObject(state, {
        trendPillarFavoriteLoading: true,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECKED_ADDED_FAVORITE_TREND_PILLAR_FAILED
    ]:
      return updateObject(state, {
        addedFavTrendPillar: initialState.addedFavTrendPillar,
        isTrendPillarFavoriteAllowed: initialState.isTrendPillarFavoriteAllowed,
        trendPillarFavoriteLoading: false,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_RECENT_ACTIVITY_SUCCESS
    ]:
      return updateObject(state, {
        recentActivity: action.recentActivity,
        loading: { ...state.loading, recentActivityLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_RECENT_ACTIVITY_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, recentActivityLoading: true },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_RECENT_ACTIVITY_FAILED
    ]:
      return updateObject(state, {
        recentActivity: initialState.recentActivity,
        loading: { ...state.loading, recentActivityLoading: false },
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECKED_ADDED_OTHER_FAVORITE_SUCCESS
    ]:
      return updateObject(state, {
        addedOtherFav: action.addedOtherFav?.favorite_exits,
        isOtherFavoriteAllowed: action.addedOtherFav?.is_favouriting_allowed,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_CHECK_ADDED_OTHER_FAVORITE_LOADING
    ]:
      return updateObject(state, {
        addedOtherFav: initialState.addedOtherFav,
        isOtherFavoriteAllowed: initialState.isOtherFavoriteAllowed,
      })
    case myDashboardActionTypes.type[
      myDashboardActionTypes.FETCH_DASHBOARD_FAVORITES_OTHER_FAVORITES_SUCCESS
    ]:
      return updateObject(state, {
        otherFavoritesData: action.otherFavoritesData,
      })
    default:
      return state
  }
}

export default dashboardReducer
