import React from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export default styled(Paper)(({ theme }) => ({
  boxShadow: '0px 4px 12px 0px #272B3108',
  padding: theme.spacing(2),
  '& > .MuiGrid-root': {
    minHeight: 32,
    maxHeight: 430,
    overflowY: 'auto',
    paddingRight: theme.spacing(0.5),
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
}));
