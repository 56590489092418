import React, { useState, useMemo } from 'react'
import SWTab from '../Tab/Tab'
import SWResultsTable from '../ResultsTable/ResultsTable'
import SWBarChart from '../ConceptsRankingBarChart/ConceptsRankingBarChart'
import SWOrigVRelevance from '../SWOrigVRelevance/SWOrigVRelevance'

import Button from '../Buttons/ButtonSW'
import LinkButton from '../LinkButton/LinkButton'
import SWSingleSelectDropdown from '../SWDropdown/SWSingleSelectDropdown'

import { screeningStatus as swStatus, screeningResults } from '../../const'

import './SWScreenedResults.scss'

const data = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: '>10',
    value: 10,
  },
  {
    label: '>20',
    value: 20,
  },
  {
    label: '>30',
    value: 30,
  },
  {
    label: '>40',
    value: 40,
  },
  {
    label: '>50',
    value: 50,
  },
  {
    label: '>60',
    value: 60,
  },
  {
    label: '>70',
    value: 70,
  },
  {
    label: '>80',
    value: 80,
  },
]

const headers = [
  { name: 'ID', sort: true, key: 'id' },
  { name: 'Concept name', sort: true, key: 'title' },
  { name: 'Keywords analysed', sort: true, key: 'keywordsAnalysed' },
  // { name: "Rank", sort: true, key: "finalRank" },
  { name: 'Originality', sort: true, key: 'originalityScore' },
  { name: 'Relevance', sort: true, key: 'relevanceScore' },
]
const styles = {
  id: 'sw-results-table__id',
  title: 'sw-results-table__cncpt-name',
  keywordsAnalysed: 'sw-results-table__key-anlsd',
  finalRank: 'sw-results-table__rank',
  originalityScore: 'sw-results-table__orglty',
  relevanceScore: 'sw-results-table__rel',
}

const plotSections = [
  {
    data: [],
    bgColor: 'rgb(255,255,255)',
    cirColor: '#06B0F1',
    txtColor: '#06B0F1',
    category: screeningResults.LESS_COMPELLLING,
    x: 0,
    y: 100,
  },
  {
    data: [],
    bgColor: 'rgb(255,255,255)',
    cirColor: '#FFB236',
    txtColor: '#FFB236',
    category: screeningResults.RETHINK,
    x: 0,
    y: 50,
  },
  {
    data: [],
    bgColor: 'rgb(255,255,255)',
    cirColor: '#FF74EA',
    txtColor: '#FF21DD',
    category: screeningResults.NICHE,
    x: 50,
    y: 50,
  },
  {
    data: [],
    bgColor: 'rgb(255,255,255)',
    cirColor: '#7D43FF',
    txtColor: '#7D43FF',
    category: screeningResults.WINNING,
    x: 50,
    y: 100,
  },
]

const SWScreenedResults = ({
  conceptsList,
  downloadResults,
  projectDetails,
}) => {
  const screenedConcepts = conceptsList.filter(
    (cncpt) => cncpt.screeningStatus === swStatus.SCREENED,
  )
  const [relevanceFilter, setRelevanceFilter] = useState(0)
  const [originalityFilter, setOriginalityFilter] = useState(0)
  const [selectedTab, setSelectedTab] = useState(0)

  const sortedConcepts = useMemo(
    () =>
      [...screenedConcepts]
        .sort((a, b) => {
          return a.finalRank - b.finalRank
        })
        .map((cncpt) => {
          const keywords = []
          if (cncpt.keywordsAnalysed) {
            for (const [key, value] of Object.entries(cncpt.keywordsAnalysed)) {
              keywords.push(value.join('; '))
            }
          }
          return { ...cncpt, keywordsAnalysed: keywords.join('; ') }
        }),
    [conceptsList],
  )

  const sortedData = sortedConcepts
    ? sortedConcepts.filter((val) => {
        const filter =
          val.originalityScore > originalityFilter &&
          val.relevanceScore > relevanceFilter
        return filter
      })
    : []

  const scatterPlotData = useMemo(() => {
    // Deep copy plotSections
    const newPlotSections = [
      ...plotSections.map((plot) => ({ ...plot, data: [...plot.data] })),
    ]
    screenedConcepts.forEach(
      ({ id, originalityScore, title, relevanceScore, originalityRank }) => {
        const data = {
          id,
          x: originalityScore,
          y: relevanceScore,
          name: title,
          originalityRank,
        }
        if (
          originalityScore >= 0 &&
          originalityScore < 50 &&
          relevanceScore >= 0 &&
          relevanceScore < 50
        ) {
          newPlotSections
            .find(({ category }) => category === screeningResults.RETHINK)
            .data.push(data)
        } else if (
          originalityScore >= 50 &&
          originalityScore <= 100 &&
          relevanceScore >= 50 &&
          relevanceScore <= 100
        ) {
          newPlotSections
            .find(({ category }) => category === screeningResults.WINNING)
            .data.push(data)
        } else if (
          originalityScore >= 50 &&
          originalityScore <= 100 &&
          relevanceScore >= 0 &&
          relevanceScore < 50
        ) {
          newPlotSections
            .find(({ category }) => category === screeningResults.NICHE)
            .data.push(data)
        } else {
          newPlotSections
            .find(
              ({ category }) => category === screeningResults.LESS_COMPELLLING,
            )
            .data.push(data)
        }
      },
    )
    return newPlotSections
  }, [conceptsList])

  const resultsTabs = [
    {
      id: 0,
      heading: 'Originality vs Relevance',
      content: (
        <SWOrigVRelevance
          concepts={screenedConcepts}
          scatterPlotData={scatterPlotData}
          country={projectDetails?.country}
          category={projectDetails?.category}
          subcategory={projectDetails?.subcategory}
        />
      ),
    },
    // {
    //   id: 1,
    //   heading: "Originality Ranking",
    //   content: (
    //     <div className="sw-bar-chart__block">
    //       <SWBarChart
    //         id="0"
    //         data={sortedConcepts.map(({ title, rank }) => ({
    //           name: title,
    //           rank,
    //         }))}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   id: 2,
    //   heading: "Relevance Ranking",
    //   content: (
    //     <div className="sw-bar-chart__block">
    //       <SWBarChart
    //         id="1"
    //         data={sortedConcepts.map(({ title, rank }) => ({
    //           name: title,
    //           rank,
    //         }))}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   id: 2,
    //   heading: "Ranking",
    //   content: (
    //     <div className="sw-bar-chart__block">
    //       <SWBarChart
    //         id="1"
    //         data={sortedConcepts.map(({ title, finalRank }) => ({
    //           name: title,
    //           rank: finalRank,
    //         }))}
    //       />
    //     </div>
    //   ),
    // },
    {
      id: 3,
      heading: 'Table view',
      content: (
        <div className="sw-screened-results__sw-results-table__block">
          {sortedData.length ? (
            <SWResultsTable
              headers={headers}
              styles={styles}
              data={sortedData}
            />
          ) : (
            <div className="no_result">
              There is no data available as per the filtering criteria
            </div>
          )}
        </div>
      ),
    },
  ]

  return (
    <div className="sw-screened-results__block">
      <div className="sw-screened-results__btns">
        {selectedTab ? (
          <>
            <div className="sw-screened-results__relevance_filter">
              <SWSingleSelectDropdown
                placeholder="Relevance"
                data={data}
                handleFilter={setRelevanceFilter}
              />
            </div>
            <div className="sw-screened-results__originality_filter">
              <SWSingleSelectDropdown
                placeholder="Originality"
                data={data}
                handleFilter={setOriginalityFilter}
              />
            </div>
          </>
        ) : (
          <div style={{ height: '40px' }}></div>
        )}
        {/* <div className="sw-screened-results__btn-link">
          <LinkButton link={window.location.href} />
        </div> */}
      </div>
      <div className="sw-screened-results">
        <div className="sw-screened-results__download">
          <Button
            textOnly
            name="Download data"
            onClick={() => downloadResults()}
          />
        </div>
        <SWTab tabs={resultsTabs} setSelectedTab={setSelectedTab} />
      </div>
    </div>
  )
}

export default SWScreenedResults
