import { Grid } from '@mui/material'
import React from 'react'

const DashboardCommonCardsList = (props) => {
  const { gridStyles } = props
  return (
    <Grid
      sx={{
        ...gridStyles,
      }}
      container
      spacing={2}
    >
      {props.children}
    </Grid>
  )
}

export default DashboardCommonCardsList
