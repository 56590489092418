class ProductQuoteResponse {
  constructor(response) {
    this._instagramQuotes = response.instagram_quotes
    this._twitterQuotes = response.twitter_quotes
    this._pantipQuotes = response.pantip_quotes
    this._weiboQuotes = response.weibo_quotes
    this._meituanQuotes = response.meituan_quotes
    this._zhihuQuotes = response.zhihu_quotes
    this._JDQuotes = response.jd_quotes
    this._negativeInstagramQuotes = response.negative_instagram_quotes
    this._negativeTwitterQuotes = response.negative_twitter_quotes
    this._negativePantipQuotes = response.negative_pantip_quotes
    this._negativeWeiboQuotes = response.negative_weibo_quotes
    this._negativeMeituanQuotes = response.negative_meituan_quotes
    this._negativeZhihuQuotes = response.negative_zhihu_quotes
    this._negativeJDQuotes = response.negative_jd_quotes
    this._blogsAndWebsites = response.blogs_and_websites

    this._instagramQuotesRelevant = response.instagram_quotes_relevance
    this._twitterQuotesRelevant = response.twitter_quotes_relevance
    this._pantipQuotesRelevant = response.pantip_quotes_relevance
    this._weiboQuotesRelevant = response.weibo_quotes_relevance
    this._meituanQuotesRelevant = response.meituan_quotes_relevance
    this._zhihuQuotesRelevant = response.zhihu_quotes_relevance
    this._JDQuotesRelevant = response.jd_quotes_relevance
    this._negativeInstagramQuotesRelevant =
      response.negative_instagram_quotes_relevance
    this._negativeTwitterQuotesRelevant =
      response.negative_twitter_quotes_relevance
    this._negativePantipQuotesRelevant =
      response.negative_pantip_quotes_relevance
    this._negativeWeiboQuotesRelevant = response.negative_weibo_quotes_relevance
    this._negativeMeituanQuotesRelevant =
      response.negative_meituan_quotes_relevance
    this._negativeZhihuQuotesRelevant = response.negative_zhihu_quotes_relevance
    this._negativeJDQuotesRelevant = response.negative_jd_quotes_relevance
    this._littleRedBookQutes = response.littleredbook_quotes

    this._FacebookQuotes = response.facebook_quotes
    this._negativeFacebookQuotes = response.negative_facebook_quotes
    this._FacebookQuotesRelevant = response.facebook_quotes_relevance
    this._negativeFacebookQuotesRelevant =
      response.negative_facebook_quotes_relevance

    this._ZomatoQuotes = response.zomato_quotes
    this._negativeZomatoQuotes = response.negative_zomato_quotes
    this._ZomatoQuotesRelevant = response.zomato_quotes_relevance
    this._negativeZomatoQuotesRelevant =
      response.negative_zomato_quotes_relevance

    this._YummyQuotes = response.yummy_quotes
    this._negativeYummyQuotes = response.yummy_quotes_relevance
    this._YummyQuotesRelevant = response.yummy_quotes_relevance
    this._negativeYummyQuotesRelevant = response.negative_yummy_quotes_relevance

    this._PepperQuotes = response.pepper_quotes
    this._negativePepperQuotes = response.negative_pepper_quotes
    this._PepperQuotesRelevant = response.pepper_quotes_relevance
    this._negativePepperQuotesRelevant =
      response.negative_pepper_quotes_relevance

    this._PeachQuotes = response.peach_quotes
    this._negativePeachQuotes = response.negative_peach_quotes
    this._PeachQuotesRelevant = response.peach_quotes_relevance
    this._negativePeachQuotesRelevant = response.negative_peach_quotes_relevance

    this._MouthshutQuotes = response.mouthshut_quotes
    this._negativeMouthshutQuotes = response.negative_mouthshut_quotes
    this._MouthshutQuotesRelevant = response.mouthshut_quotes_relevance
    this._negativeMouthshutQuotesRelevant =
      response.negative_mouthshut_quotes_relevance

    this._LazadaQuotes = response.lazada_quotes
    this._negativeLazadaQuotes = response.negative_lazada_quotes
    this._LazadaQuotesRelevant = response.lazada_quotes_relevance
    this._negativeLazadaQuotesRelevant =
      response.negative_lazada_quotes_relevance

    this._AmazonQuotes = response.amazon_quotes
    this._negativeAmazonQuotes = response.negative_amazon_quotes
    this._AmazonQuotesRelevant = response.amazon_quotes_relevance
    this._negativeAmazonQuotesRelevant =
      response.negative_amazon_quotes_relevance

    this._IFTQuotes = response.ift_quotes
    this._negativeIFTQuotes = response.negative_ift_quotes
    this._IFTQuotesRelevant = response.ift_quotes_relevance
    this._negativeIFTQuotesRelevant = response.negative_ift_quotes_relevance

    this._nutraingredientsQuotes = response.nutraingredients_quotes
    this._negativenutraingredientsQuotes = response.nutraingredients_quotes
    this._nutraingredientsQuotesRelevant =
      response.nutraingredients_quotes_relevance
    this._negativenutraingredientsQuotesRelevant =
      response.negative_nutraingredients_quotes_relevance

    this._eatrightproQuotes = response.eat_right_pro_quotes
    this._negativeeatrightproQuotes = response.negative_eat_right_pro_quotes
    this._eatrightproQuotesRelevant = response.eat_right_pro_quotes_relevance
    this._negativeeatrightproQuotesRelevant =
      response.negative_eat_right_pro_quotes_relevance

    this._americansocietyQuotes = response.american_society_quotes
    this._negativeamericansocietyQuotes =
      response.negative_american_society_quotes
    this._americansocietyQuotesRelevant =
      response.american_society_quotes_relevance
    this._negativeamericansocietyQuotesRelevant =
      response.negative_american_society_quotes_relevance

    this._foodnavigatorQuotes = response.food_navigator_quotes
    this._negativefoodnavigatorQuotes = response.negative_food_navigator_quotes
    this._foodnavigatorQuotesRelevant = response.food_navigator_quotes_relevance
    this._negativefoodnavigatorQuotesRelevant =
      response.negative_food_navigator_quotes_relevance

    this._fooddiveQuotes = response.food_dive_quotes
    this._negativefooddiveQuotes = response.negative_food_dive_quotes
    this._fooddiveQuotesRelevant = response.food_dive_quotes_relevance
    this._negativefooddiveQuotesRelevant =
      response.negative_food_dive_quotes_relevance

    this._jpFoodQuotes = response.japan_food_quotes
    this._jpFoodJournalQuotes = response.japan_food_journal_quotes
    this._independentQuotes = response.independent_quotes
    this._hindawiQuotes = response.hindawi_quotes
    this._koreaHeraldQuotes = response.korea_herald_quotes
    this._foodScienceNutritionQuotes =
      response.food_science_and_nutrition_quotes
    this._fMCGMagazineQuotes = response.fmcg_magazine_quotes
    this._fdBusinessQuotes = response.fdbusiness_quotes
    this._fdCanadaQuotes = response.food_in_canada_quotes
    this._foodDrinkNetworkQuotes = response.food_drink_network_quotes
    this._foodDrinkEuropeQuotes = response.food_drink_europe_quotes
    this._fbNewsCanadaQuotes = response.food_and_beverage_news_canada_quotes
    this._fbAsiaQuotes = response.food_beverage_asia_quotes
    this._fdEuropeQuotes = response.food_drink_europe_quotes
    this._fdNetworkQuotes = response.food_drink_network_quotes
    this._euJournalNutritionQuotes =
      response.european_journal_of_nutrition_and_food_safety_quotes
    this._cnfcNewsQuotes = response.confectionery_news_quotes
    this._cnnQuotes = response.cnn_quotes
    this._cnbcQuotes = response.cbc_quotes
    this._candyIndustryQuotes = response.candy_industry_quotes
    this._cafbQuotes = response.canadian_food_business_quotes
    this._bjnQuotes = response.british_journal_of_nutrition_quotes
    this._bbcQuotes = response.bbc_good_food_quotes
    this._bakerySnacksQuotes = response.bakery_and_snacks_quotes
    this._asiaFdScJnQuotes = response.asian_food_science_journal_quotes
    this._acadJnQuotes = response.academic_journal_quotes
    this._abcQuotes = response.abc_news_quotes
    this._longdomQuotes = response.longdom_quotes
    this._mdpiQuotes = response.mdpi_quotes
    this._myFoodResearchQuotes = response.my_food_research_quotes
    this._natureTMQuotes = response.nature_quotes
    this._prNewsWireQuotes = response.pr_newswire_quotes
    this._scienceAlertQuotes = response.science_alert_quotes
    this._scienceDirectQuotes = response.science_direct_quotes
    this._snackFoodWholesaleBakeryQuotes =
      response.snack_food_and_wholesale_bakery_quotes
    this._taylorQuotes = response.taylor_and_francison_line_quotes
    this._unesdaQuotes = response.unesda_quotes

    this._usptoQuotes = response.uspto_quotes
    this._negativeUsptoQuotes = response.negative_uspto_quotes
    this._usptoQuotesRelevant = response.uspto_quotes_relevance
    this._negativeUsptoQuotesRelevant = response.negative_uspto_quotes_relevance
    this._reviews = response.review
  }

  get instagramQuotes() {
    return this._instagramQuotes
  }

  get twitterQuotes() {
    return this._twitterQuotes
  }

  get pantipQuotes() {
    return this._pantipQuotes
  }

  get weiboQuotes() {
    return this._weiboQuotes
  }

  get meituanQuotes() {
    return this._meituanQuotes
  }

  get zhihuQuotes() {
    return this._zhihuQuotes
  }

  get JDQuotes() {
    return this._JDQuotes
  }

  get negativeInstagramQuotes() {
    return this._negativeInstagramQuotes
  }

  get negativeTwitterQuotes() {
    return this._negativeTwitterQuotes
  }

  get negativePantipQuotes() {
    return this._negativePantipQuotes
  }

  get negativeWeiboQuotes() {
    return this._negativeWeiboQuotes
  }

  get negativeMeituanQuotes() {
    return this._negativeMeituanQuotes
  }

  get negativeZhihuQuotes() {
    return this._negativeZhihuQuotes
  }

  get negativeJDQuotes() {
    return this._negativeJDQuotes
  }

  get instagramQuotesRelevant() {
    return this._instagramQuotesRelevant
  }

  get twitterQuotesRelevant() {
    return this._twitterQuotesRelevant
  }

  get pantipQuotesRelevant() {
    return this._pantipQuotesRelevant
  }

  get weiboQuotesRelevant() {
    return this._weiboQuotesRelevant
  }

  get meituanQuotesRelevant() {
    return this._meituanQuotesRelevant
  }

  get zhihuQuotesRelevant() {
    return this._zhihuQuotesRelevant
  }

  get JDQuotesRelevant() {
    return this._JDQuotesRelevant
  }

  get negativeInstagramQuotesRelevant() {
    return this._negativeInstagramQuotesRelevant
  }

  get negativeTwitterQuotesRelevant() {
    return this._negativeTwitterQuotesRelevant
  }

  get negativePantipQuotesRelevant() {
    return this._negativePantipQuotesRelevant
  }

  get negativeWeiboQuotesRelevant() {
    return this._negativeWeiboQuotesRelevant
  }

  get negativeMeituanQuotesRelevant() {
    return this._negativeMeituanQuotesRelevant
  }

  get negativeZhihuQuotesRelevant() {
    return this._negativeZhihuQuotesRelevant
  }

  get negativeJDQuotesRelevant() {
    return this._negativeJDQuotesRelevant
  }

  get FacebookQuotes() {
    return this._FacebookQuotes
  }

  get negativeFacebookQuotes() {
    return this._negativeFacebookQuotes
  }

  get FacebookQuotesRelevant() {
    return this._FacebookQuotesRelevant
  }

  get negativeFacebookQuotesRelevant() {
    return this._negativeFacebookQuotesRelevant
  }

  get ZomatoQuotes() {
    return this._ZomatoQuotes
  }

  get negativeZomatoQuotes() {
    return this._negativeZomatoQuotes
  }

  get ZomatoQuotesRelevant() {
    return this._ZomatoQuotesRelevant
  }

  get negativeZomatoQuotesRelevant() {
    return this._negativeZomatoQuotesRelevant
  }

  get YummyQuotes() {
    return this._YummyQuotes
  }

  get negativeYummyQuotes() {
    return this._negativeYummyQuotes
  }

  get YummyQuotesRelevant() {
    return this._YummyQuotesRelevant
  }

  get negativeYummyQuotesRelevant() {
    return this._negativeYummyQuotesRelevant
  }

  get PepperQuotes() {
    return this._PepperQuotes
  }

  get negativePepperQuotes() {
    return this._negativePepperQuotes
  }

  get PepperQuotesRelevant() {
    return this._PepperQuotesRelevant
  }

  get negativePepperQuotesRelevant() {
    return this._negativePepperQuotesRelevant
  }

  get PeachQuotes() {
    return this._PeachQuotes
  }

  get negativePeachQuotes() {
    return this._negativePeachQuotes
  }

  get PeachQuotesRelevant() {
    return this._PeachQuotesRelevant
  }

  get negativePeachQuotesRelevant() {
    return this._negativePeachQuotesRelevant
  }

  get MouthshutQuotes() {
    return this._MouthshutQuotes
  }

  get negativeMouthshutQuotes() {
    return this._negativeMouthshutQuotes
  }

  get MouthshutQuotesRelevant() {
    return this._MouthshutQuotesRelevant
  }

  get negativeMouthshutQuotesRelevant() {
    return this._negativeMouthshutQuotesRelevant
  }

  get LazadaQuotes() {
    return this._LazadaQuotes
  }

  get negativeLazadaQuotes() {
    return this._negativeLazadaQuotes
  }

  get LazadaQuotesRelevant() {
    return this._LazadaQuotesRelevant
  }

  get negativeLazadaQuotesRelevant() {
    return this._negativeLazadaQuotesRelevant
  }

  get AmazonQuotes() {
    return this._AmazonQuotes
  }

  get negativeAmazonQuotes() {
    return this._negativeAmazonQuotes
  }

  get AmazonQuotesRelevant() {
    return this._AmazonQuotesRelevant
  }

  get negativeAmazonQuotesRelevant() {
    return this._negativeAmazonQuotesRelevant
  }

  get IFTQuotes() {
    return this._IFTQuotes
  }

  get negativeIFTQuotes() {
    return this._negativeIFTQuotes
  }

  get IFTQuotesRelevant() {
    return this._IFTQuotesRelevant
  }

  get negativeIFTQuotesRelevant() {
    return this._negativeIFTQuotesRelevant
  }

  get nutraIngredientsQuotes() {
    return this._nutraingredientsQuotes
  }

  get negativeNutraIngredientsQuotes() {
    return this._negativenutraingredientsQuotes
  }

  get nutraIngredientsQuotesRelevant() {
    return this._nutraingredientsQuotesRelevant
  }

  get negativeNutraIngredientsQuotesRelevant() {
    return this._negativenutraingredientsQuotesRelevant
  }

  get eatRightProQuotes() {
    return this._eatrightproQuotes
  }

  get negativeEatRightProQuotes() {
    return this._negativeeatrightproQuotes
  }

  get eatRightProQuotesRelevant() {
    return this._eatrightproQuotesRelevant
  }

  get negativeEatRightProQuotesRelevant() {
    return this._negativeeatrightproQuotesRelevant
  }

  get americansocietyQuotes() {
    return this._americansocietyQuotes
  }

  get negativeamericanSocietyQuotes() {
    return this._negativeamericansocietyQuotes
  }

  get americansocietyQuotesRelevant() {
    return this._americansocietyQuotesRelevant
  }

  get negativeamericanaocietyQuotesRelevant() {
    return this._negativeamericansocietyQuotesRelevant
  }

  get foodnavigatorQuotes() {
    return this._foodnavigatorQuotes
  }

  get negativeFoodnavigatorQuotes() {
    return this._negativefoodnavigatorQuotes
  }

  get foodnavigatorQuotesRelevant() {
    return this._foodnavigatorQuotesRelevant
  }

  get negativeaFoodnavigatorQuotesRelevant() {
    return this._negativefoodnavigatorQuotesRelevant
  }

  get fooddiveQuotes() {
    return this._fooddiveQuotes
  }

  get negativeFooddiveQuotes() {
    return this._negativefooddiveQuotes
  }

  get fooddiveQuotesRelevant() {
    return this._fooddiveQuotesRelevant
  }

  get negativeaFooddiveQuotesRelevant() {
    return this._negativefooddiveQuotesRelevant
  }

  get usptoQuotes() {
    //alert(this._usptoQuotes)
    return this._usptoQuotes
  }

  get negativeUsptoQuotes() {
    return this._negativeUsptoQuotes
  }

  get usptoQuotesRelevant() {
    return this._usptoQuotesRelevant
  }

  get negativeaUsptoQuotesRelevant() {
    return this._negativeUsptoQuotesRelevant
  }

  get littleRedBookQuotes() {
    return this._littleRedBookQutes
  }

  get reviews() {
    return this._reviews
  }
}

export default ProductQuoteResponse
