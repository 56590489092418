class ProductNeedsDetails {
  constructor(response) {
    this._id = Number(response.id)
    this._keyword = response.keyword
    this._iconURI = response.iconURI
    this._score = response.score
    this._searchCriteria = response.searchCriteria
    this._relatedTerms = response.relatedTerms
    this._needComments = response.needComments
    this._positiveScore = response.positive_score
    this._neutralScore = response.neutral_score
    this._negativeScore = response.negative_score
    this._needChart = (response.needChart || []).map((chartValue) => {
      return new NeedChart(chartValue)
    })
    this._projectId = response.project_id
    this._projectName = response.project_name || response.category // category (Product Format)
    this._category = response.category
    this._countryName = response.country
    this._productId =
      response.product_id || response.theme_id || response.ingredient_id
    this._productName =
      response.product_name || response.theme_name || response.ingredient_name
    this._instagramQuotes = response.instagram_quotes
    this._twitterQuotes = response.twitter_quotes
    this._pantipQuotes = response.pantip_quotes
    this._weiboQuotes = response.weibo_quotes
    this._meituanQuotes = response.meituan_quotes
    this._zhihuQuotes = response.zhihu_quotes
    this._JDQuotes = response.jd_quotes
    this._negativeInstagramQuotes = response.negative_instagram_quotes
    this._negativeTwitterQuotes = response.negative_twitter_quotes
    this._negativePantipQuotes = response.negative_pantip_quotes
    this._negativeWeiboQuotes = response.negative_weibo_quotes
    this._negativeMeituanQuotes = response.negative_meituan_quotes
    this._negativeZhihuQuotes = response.negative_zhihu_quotes
    this._negativeJDQuotes = response.negative_jd_quotes
    this._trendCoefficient = response.trend_four_year_coefficient
    this._trendIntercept = response.trend_four_year_intercept
    this._need_products = response.need_products
    this._formatProducts = response.related_products || response.products || [] // Product Format (Cafe/Retail)
    this._needCategory = response.need_category
    this._relatedNeeds = response.related_category_needs
    this._platforms = response.platforms
    this._productNeeds = response.product_consumer_needs
    this._needFilter = response.need_filter
    this._quotesMediaOptions = (response.platforms || []).map((value) => {
      return {
        id: value,
        value: value,
        displayValue: value === 'Pantip' ? 'Blogs and Forums' : value,
      }
    })
    this._quotesOptions = [
      {
        id: 0,
        value: 'Social',
        displayValue: 'Social Data',
      },
      {
        id: 1,
        value: 'Trade',
        displayValue: 'Trade Media',
      },
      {
        id: 2,
        value: 'Patent',
        displayValue: 'Patent Data',
      },
    ]
    this._yoyGrowthRate = response.yoy_growth
    this._twoYearCagr = response.two_year_cagr
    this._uniqueMentionCount = response.unique_mention_count
  }

  get id() {
    return this._id
  }

  get keyword() {
    return this._keyword
  }

  get trendCoefficient() {
    return this._trendCoefficient
  }

  get trendIntercept() {
    return this._trendIntercept
  }

  get iconURI() {
    return this._iconURI
  }

  get score() {
    return this._score
  }

  get relatedTerms() {
    return this._relatedTerms
  }

  get needComments() {
    return this._needComments
  }

  get positiveScore() {
    return this._positiveScore
  }

  get neutralScore() {
    return this._neutralScore
  }

  get negativeScore() {
    return this._negativeScore
  }

  get needChart() {
    return this._needChart
  }

  get projectName() {
    return this._projectName
  }

  get category() {
    return this._category
  }

  get projectId() {
    return this._projectId
  }

  get productId() {
    return this._productId
  }

  get productName() {
    return this._productName
  }

  get formatProducts() {
    return this._formatProducts
  }

  get instagramQuotes() {
    return this._instagramQuotes
  }

  get twitterQuotes() {
    return this._twitterQuotes
  }

  get pantipQuotes() {
    return this._pantipQuotes
  }

  get weiboQuotes() {
    return this._weiboQuotes
  }

  get meituanQuotes() {
    return this._meituanQuotes
  }

  get zhihuQuotes() {
    return this._zhihuQuotes
  }

  get JDQuotes() {
    return this._JDQuotes
  }

  // eslint-disable-next-line
  get need_products() {
    return this._need_products
  }

  get platforms() {
    return this._platforms
  }

  get quotesOptions() {
    return this._quotesOptions
  }

  get productNeeds() {
    return this._productNeeds
  }

  get relatedNeeds() {
    return this._relatedNeeds
  }

  get needCategory() {
    return this._needCategory
  }

  get needFilter() {
    return this._needFilter
  }

  get countryName() {
    return this._countryName
  }

  get searchCriteria() {
    return this._searchCriteria
  }

  get yoyGrowthRate() {
    return this._yoyGrowthRate
  }

  get twoYearCagr() {
    return this._twoYearCagr
  }

  get uniqueMentionCount() {
    return this._uniqueMentionCount
  }
}

class NeedChart {
  constructor(response) {
    this._period = response.period
    this._engagementScore = response.engagementScore
  }

  get period() {
    return this._period
  }

  get engagementScore() {
    return this._engagementScore
  }
}

export default ProductNeedsDetails
