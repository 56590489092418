import { Box, styled } from '@mui/system';
import { List } from '@mui/material';

export const FilterContainer = styled(List)(({ theme }) => ({
  width: 250,
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '& .MuiListItem-root': {
    padding: theme.spacing(1),
  },
  '& .MuiListItemButton-root': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
  '& *': {
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.7),
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
  },
}));

export const SectionContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));
