import palette from '../palette';

export default {
  styleOverrides: {
    separator: {
      color: palette.icon,
      marginRight: 4,
      marginLeft: 4,
      '& .MuiSvgIcon-root': {
        height: 14,
        width: 14,
      },
    },
  },
};
