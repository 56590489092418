import MuiOutlinedInput from './MuiOutlinedInput'
import MuiAvatar from './MuiAvatar'
import MuiBreadcrumbs from './MuiBreadcrumbs'
import MuiButtonBase from './MuiButtonBase'
import MuiLink from './MuiLink'
import MuiCheckbox from './MuiCheckbox'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiRadio from './MuiRadio'
import MuiInputBase from './MuiInputBase'
import MuiTextField from './MuiTextField'
import MuiInputLabel from './MuiInputLabel'
import MuiFormLabel from './MuiFormLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiInputAdornment from './MuiInputAdornment'
import MuiTypography from './MuiTypography'
import MuiAutocomplete from './MuiAutocomplete'
import MuiPaper from './MuiPaper'
import MuiChip from './MuiChip'
import MuiSelect from './MuiSelect'
import MuiButton from './MuiButton'
import MuiMenuItem from './MuiMenuItem'
import MuiMenu from './MuiMenu'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiTableRow from './MuiTableRow'
import MuiTableHead from './MuiTableHead'
import MuiTableContainer from './MuiTableContainer'
import MuiTableSortLabel from './MuiTableSortLabel'
import MuiLinearProgress from './MuiLinearProgress'
import MuiButtonGroup from './MuiButtonGroup'
import MuiTooltip from './MuiTooltip.js'
import MuiSwitch from './MuiSwitch'

const overrides = {
  MuiButton,
  MuiButtonBase,
  MuiLink,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiRadio,
  MuiTypography,
  MuiOutlinedInput,
  MuiInputBase,
  MuiInputLabel,
  MuiFormLabel,
  MuiTextField,
  MuiInputAdornment,
  MuiFormHelperText,
  MuiChip,
  MuiAutocomplete,
  MuiPaper,
  MuiAvatar,
  MuiMenuItem,
  MuiMenu,
  MuiTab,
  MuiTabs,
  MuiTableRow,
  MuiTableHead,
  MuiTableContainer,
  MuiTableSortLabel,
  MuiSelect,
  MuiBreadcrumbs,
  MuiLinearProgress,
  MuiButtonGroup,
  MuiTooltip,
  MuiSwitch,
}

export default overrides
