import styled from '@emotion/styled';
import { Tabs, Grid } from '@mui/material'

export const StyledTab = styled(Tabs)(({ theme }) => ({
    paddingLeft: '0px !important',
	'& .MuiTabs-indicator': {
		borderBottom: `2px solid ${theme.palette.secondary.main}`,
		backgroundColor:'transparent',
	},
	'& .MuiTab-root': {
		color: `${theme.palette.text.secondary}`,
	},
	'.MuiButtonBase-root': {
		fontSize: theme.spacing(2),
		padding:`${theme.spacing(2)} 0`,
		marginRight:'25px',
	},
	
}))

export const TabGrid = styled(Grid)(() => ({
    paddingLeft: '0px !important'
}));

