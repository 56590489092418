import React from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import {
  Typography,
  TableRow,
  TableBody,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Grid,
  Menu,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { Search } from '@mui/icons-material'
import DownloadButton from '../../shared/components/DownloadButton'
import SingleSelect from '../../shared/components/SingleSelect'
import { SearchBox } from '../../components/TrendBucketTable/styles'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import DialogActions from '@mui/material/DialogActions'
import ClearIcon from '@mui/icons-material/Clear'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import AddIcon from '@mui/icons-material/Add'


const AdminUI = (props) => {
  const {
    showLoader,
    clientTypeFilter,
    handleAddUser,
    orderBy,
    order,
    searchedData,
    dashboardsSubscribed,
    dialogOpen,
    setDialogOpen,
    handleClose,
    anchorEl,
    open,
    handleRequestSort,
    setSearchValue,
    searchValue,
    clientTypeChanged,
    getComparator,
    id,
    GenericTableHead,
    GenericTableCell,
    adminPanelHeadCells,
    deactivateDialogOpen,
    setDeactivateDialogOpen,
    handleDeactivateDialog,
    clientDeactivateSuccess,
    handleEditUser,
    selectedRow,
    setSelectedRow,
    deleteClientDailog,
    setDeleteClientDialog,
    handleDeleteClient,
    clientCount,
    handleDownloadExcel,
    isMigrateDialogOpen,
    setIsMigrateDialogOpen,
    migrateOrgToNewUI,
    handleCheckboxChange,
    featuresSelected,
    handleMigrationDialogClose,
    handleMigrateClick,
    featureList
  } = props
  return (
    <>
      <Grid container px={1} sx={{ marginBottom: '-25px', paddingTop: '15px' }}>
        <Typography variant="subtitle2">Summary</Typography>
      </Grid>
      <Box>
        <Grid container spacing={2} my={2}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography px={1} variant="subtitle2">
              Clients: {clientCount.totalClients}
            </Typography>

            <Typography px={1} variant="subtitle2">
              Trial Clients: {clientCount.trialClient}
            </Typography>

            <Typography px={1} variant="subtitle2">
              Licensed Clients: {clientCount.licensedClient}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            pl={2}
          >
            <Typography variant="subtitle2">Client type</Typography>
            <SingleSelect
              value={clientTypeFilter?.label || 'All'}
              options={[
                { label: 'All', key: 0 },
                { label: 'Licensed', key: 1 },
                { label: 'Trial', key: 2 },
              ]}
              sx={{ minWidth: '300px' }}
              onChange={(e, newVal) => clientTypeChanged(newVal)}
            />

            <Button variant="contained" onClick={handleAddUser}>
              <AddIcon />
              Add New Client
            </Button>

            <SearchBox
              variant="outlined"
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              placeholder={`Search Client`}
              value={searchValue}
              size="large"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <ClearIcon
                        cursor="pointer"
                        onClick={(e) => {
                          setSearchValue('')
                        }}
                        color="secondary"
                        fontSize="small"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="outlined"
              size="small"
              startIcon={<DownloadRoundedIcon />}
              onClick={handleDownloadExcel}
              disabled={!searchedData?.length}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={3}
        sx={{
          maxHeight: '400px',
        }}
      >
        <TableContainer component={Paper} sx={{ height: '400px' }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <GenericTableHead
              headCells={adminPanelHeadCells}
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleRequestSort}
            />
            {searchedData?.length ? (
              <TableBody>
                {searchedData
                  .sort((a, b) => getComparator(order, orderBy))
                  .map((row) => (
                    <TableRow
                      data-testId="tableRow"
                      sx={{ cursor: 'pointer', verticalAlign: 'baseline' }}
                    >
                      <>
                        <GenericTableCell
                          rowData={row}
                          tableHeadCells={adminPanelHeadCells}
                        />
                      </>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              !showLoader && (
                <Typography
                  sx={{
                    position: 'relative',
                    left: '45vw',
                    top: '150px',
                  }}
                >
                  No Result Found
                </Typography>
              )
            )}
          </Table>
        </TableContainer>
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              {
                selectedRow?.status === 'IN-ACTIVE'
                  ? setDeleteClientDialog(true)
                  : setDeactivateDialogOpen(true)
              }
            }}
            disabled={selectedRow?.status === 'DRAFT'}
          >
            {selectedRow?.status === 'IN-ACTIVE'
              ? 'Delete all records'
              : 'Deactivate Client'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              selectedRow?.status === 'IN-ACTIVE'
                ? setDeactivateDialogOpen(true)
                : handleEditUser()
            }}
          >
            {selectedRow?.status === 'IN-ACTIVE'
              ? 'Activate Client'
              : 'Edit Details'}
          </MenuItem>
          <MenuItem
            onClick={handleMigrateClick}
          >
            {'Migrate To New UI'}
          </MenuItem>
        </Menu>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
          sx={{ minWidth: '600px' }}
        >
          <DialogTitle sx={{ m: 0, p: 2, minWidth: '600px' }}>
            <Typography>
              Dashboard Subscribed for {selectedRow?.clientName}
            </Typography>
            {dialogOpen ? (
              <IconButton
                aria-label="close"
                onClick={() => {
                  setDialogOpen(false)
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers sx={{ minHeight: '300px' }}>
            {dashboardsSubscribed.length !== 0 ? (
              dashboardsSubscribed.map((dashboard, index) => {
                return (
                  <Box>
                    <Typography variant="subtitle2">
                      {index + 1}. {dashboard}
                    </Typography>
                  </Box>
                )
              })
            ) : (
              <Box>
                <Typography sx={{ textAlign: 'center' }}>
                  Please assign dashboards by clicking on “Edit Details” under
                  OPTIONS column.
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={deactivateDialogOpen}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                {selectedRow?.status === 'IN-ACTIVE'
                  ? `${selectedRow?.clientName} Will be activated.`
                  : `${selectedRow?.clientName} will no longer have the access to
                platform.`}
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                handleDeactivateDialog()
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                clientDeactivateSuccess(selectedRow)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
                
        <Dialog
          onClose={handleMigrationDialogClose}
          aria-labelledby="org-migration"
          open={isMigrateDialogOpen}
          sx={{ minWidth: '800px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', flexDirection: 'column' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
                pt: 3
              }}
            >
              <Typography pr={2} variant='h6'>
                {`${selectedRow?.clientName} organisation will be migrated to new UI.`}
              </Typography>
              <ErrorIcon />
            </Box>
            <Box sx={{ flex: 1 }} />
            <Typography pr={2}>
              Select features to enable:
            </Typography>
            <Grid container spacing={2}>
              {featureList.map((feature) => (
                <Grid item xs={6}  key={feature.key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={featuresSelected[feature.key]}
                        onChange={handleCheckboxChange}
                        name={feature.key}
                      />
                    }
                    label={feature.displayName}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={handleMigrationDialogClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                migrateOrgToNewUI(selectedRow);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setDeleteClientDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={deleteClientDailog}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                {selectedRow?.clientName} will be deleted.
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setDeleteClientDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                handleDeleteClient()
                setDeleteClientDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default AdminUI
