import '@babel/polyfill'
import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable';
import 'core-js'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/number/is-nan'
import 'core-js/features/string/repeat'
import './store/polyfills' /* Added explicitly */

import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './indexStore'
import NetworkUtil from './services/networking/NetworkingUtil'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import theme from './theme'
import { CompareProvider } from './shared/contexts/CompareContext'
import SnackbarProvider from './shared/contexts/SnackbarProvider'

// Setup localforage config before using it anywhere
// eslint-disable-next-line
localforage.config({
  // driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'aipalette',
  version: 2.0,
  // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'downloads', // Should be alphanumeric, with underscores.
  description: 'Store all the sections added for downloading',
})

const app = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CompareProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LocalizationProvider>
        </CompareProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
)

// Dummy auth key. Will be replaced when actual authentication is implemented
// const authKey = 'A123456234343.4554rvvrhvihvuohroe.445845tu045';
// NetworkUtil.saveAuthKey(authKey);
ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
