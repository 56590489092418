import React from 'react'
import PropTypes from 'prop-types'

import { Box, Chip, Divider } from '@mui/material'

const MenuListHeader = ({ header, numOfFiltersApplied, extraStyles }) => {
  return (
    <Box component="div">
      <Box
        onKeyDown={(e) => e.stopPropagation()}
        component="div"
        sx={{ px: 1.5, py: 1, ...extraStyles }}
      >
        {header.content}
        {header.showNumOfFiltersApplied && (
          <Chip
            sx={{
              ml: 1,
              backgroundColor: ({ palette }) => palette.secondary.main,
              '& .MuiChip-label': {
                color: ({ palette }) => palette.white,
              },
              '&:hover': {
                backgroundColor: ({ palette }) => palette.secondary.main,
                '.MuiChip-label': {
                  color: ({ palette }) => palette.white,
                },
              },
            }}
            label={numOfFiltersApplied}
          />
        )}
      </Box>
      <Divider />
    </Box>
  )
}

MenuListHeader.propTypes = {
  numOfFiltersApplied: PropTypes.number,
  header: PropTypes.shape({
    content: PropTypes.string.isRequired,
    showNumOfFiltersApplied: PropTypes.bool,
  }),
}

export default MenuListHeader
