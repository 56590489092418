import styled from '@emotion/styled'
import { TableContainer, Box } from '@mui/material'

export const StyledTable = styled(TableContainer)(() => ({
	height: '575px',
	marginTop: '5px',
	marginLeft: '20px',
	'.MuiTable-root': {
		minWidth: '680px ',
		width: '100% ',
		maxHeight: '600px',
	},
	'td': {
		paddingLeft: '22px',
		paddingRight: '22px',
	},
	'th': {
		paddingLeft: '22px',
		paddingRight: '22px',
	},
	'& .MuiTableCell-head': {
		'.MuiTypography-root': {
			width: 'min-content',
		},
	},
	'& .MuiTableCell-head: nth-child(4)': {
		'.MuiTypography-root': {
			width: '85px',
		},
	},
	'& .MuiTableCell-head: nth-child(5)': {
		'.MuiTypography-root': {
			width: '91px',
		},
	},
	'& .MuiTableCell-head: nth-child(6)': {
		'.MuiTypography-root': {
			width: '95px',
		},
	},
	'.MuiTableRow-root th:first-child span': {
		justifyContent: 'left'
	}
}))

export const TooltipBox = styled(Box)(({ theme }) => ({
	padding: '12px 16px',
	'.MuiTypography-root': {
		color: `${theme.palette.black}`,
		paddingBottom: '7px',
		fontSize: '12px'
	},
	'.MuiTypography-root: nth-child(1)': {
		color: `${theme.palette.secondary.main}`,
		fontSize: '14px'
	},
	'.MuiTypography-root: nth-child(4)': {
		display: 'flex',
		width: '195px'
	},
	'&.MuiTypography-root': {
		'span': {
			color: 'red'
		}
	},
	'.MuiButton-root': {
		width: '100%',
		textAlign: 'center',
		color: `${theme.palette.black}`,
		borderRadius: '6px',
		marginTop: '10px',
		fontSize: '12px'
	},
	'.MuiButton-root:hover': {
    color:  `${theme.palette.black}`,
}
}))

export const IconBox = styled(Box)(() => ({
	position: 'relative',
}))
