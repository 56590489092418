import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import formatNumber from '../../../shared/utils/formatNumber'
import {
  Breadcrumbs,
  Button,
  Divider,
  Link,
  SvgIcon,
  Typography,
  Paper,
  Grid,
  Stack,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import {
  useCompare,
  useCompareDispatch,
} from '../../../shared/contexts/CompareContext'
import SentimentCard from '../SentimentCard'

import countriesList from '../../../mocks/country'
import { ReactComponent as LineChartIcon } from '../../../assets/icons/line-chart.svg'
import { ReactComponent as AddToCompareIcon } from '../../../assets/icons/add-to-compare.svg'
import { ReactComponent as AddedToCompareIcon } from '../../../assets/icons/added-to-compare.svg'
import { ReactComponent as TransitionFlagIcon } from '../../../assets/icons/transitionFlag.svg'

import DownloadButton from '../DownloadButton'
import TrendGrowthCard from '../TrendGrowthCard'
import HideOnScrollAppbar from './../HideOnScrollAppbar'
import InformationIcon from '../InformationIcon'
import Bookmarked from '../Bookmarked/Bookmarked'
import ShareCard from './../ShareCard/index'
import { amplify, convertTextToTitleCase } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import AddFavoriteButton from '../OtherFavorites/AddFavoriteButton'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'

const breadcrumbs = []

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Trend_Name: ampiInstance._name,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Lens: props.match.params.urlLensSelected,
      Maturity_Phase: ampiInstance._trendName,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ThemeDetailTop')
  }
}

const ThemeDetailTop = (props) => {
  const {
    projectid,
    productid,
    name,
    category,
    rank,
    trendName,
    dataPoint,
    productImage,
    positiveSentiment,
    neutralSentiment,
    negativeSentiment,
    details,
    toggleImageDownload,
    getChartDataExcel,
    getChartDataPpt,
    saveFilteredTrendData,
    togglePptDownload,
    toggleGraphImageDownload,
    toggleGraphPptDownload,
    handleGraphDownloadExcel,
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const inCompareTrends = useCompare()
  const dispatch = useCompareDispatch()
  const isAddedToCompare = inCompareTrends.some(
    (item) => item._id === productid,
  )
  const country = countriesList.find((item) => item.label === props.country)
  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/product-category/${projectid}`}
    >
      {props.country}&nbsp;{props.category}
    </Link>,
    <Typography key="3" variant="subtitle2">
      {name}
    </Typography>,
  ]

  function copy() {
    navigator.clipboard
      .writeText(location.href)
      .then(() => {
        enqueueSnackbar('Link is copied!', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar('Unable to copy link', { variant: 'error' })
      })
  }

  const compareHandler = () => {
    if (
      !localStorage.getItem('febetaProductLens') ||
      localStorage.getItem('febetaProductLens') ===
        props.match.params.urlLensSelected ||
      !localStorage.getItem('febetaComparison') ||
      JSON.parse(localStorage.getItem('febetaComparison')) === 0
    ) {
      localStorage.setItem('febetaProject', projectid)
      localStorage.setItem(
        'febetaProductLens',
        props.match.params.urlLensSelected,
      )
      !isAddedToCompare && dispatch({ type: 'added', payload: details })
      const ampiEvent = `Clicked_Add_To_Compare`
      amp(props, ampiEvent)
    } else {
      dispatch({ type: 'retrieve' })
    }
  }
  const trendTransitioning = details.transitionStatus?.isTransitioning

  useEffect(() => {
    if (props.country && props.category && name && projectid && productid) {
      shouldLogRecentActivity(
        `Explore Trends>${props.country} ${props.category}>${name}`,
        'Platform',
        `/mui/details/${projectid}/theme/${productid}`,
      )
    }
  }, [props.country, props.category, name, projectid, productid])

  return (
    <Stack gap={2}>
      <HideOnScrollAppbar>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </HideOnScrollAppbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 1,
          mb: -1,
        }}
      >
        <Typography variant="h4" color="textSecondary">
          Theme Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <ShareCard
            trendName={name}
            trendId={productid}
            country={props.country}
            category={props.category}
            productImage={props.productImage}
          />
          <AddFavoriteButton
            favoriteSectionType={'Trends under Favorites'}
            projectId={projectid}
            productId={productid}
            trendName={name}
            trendType={'theme'}
            reDirectionUrl={props.location.pathname}
            breadcrumbs={`Foresight Engine, ${props.country} ${props.category}, ${name}`}
          />
          {/* <Bookmarked {...props} /> */}
          <Button
            variant="outlined"
            size="small"
            startIcon={<ContentCopyRoundedIcon />}
            onClick={copy}
          >
            Copy Link to Clipboard
          </Button>
          <DownloadButton
            dowImageType=""
            lensSelected="Theme"
            title="_Trend_Page"
            toggleImageDownload={toggleImageDownload}
            handleDownloadExcel={props.consolidatedExcelDownlaod}
            togglePptDownload={togglePptDownload}
          />
          <Button
            variant="outlined"
            size="small"
            disabled={isAddedToCompare}
            startIcon={
              isAddedToCompare ? (
                <SvgIcon
                  component={AddedToCompareIcon}
                  sx={{ color: 'primary.main' }}
                />
              ) : (
                <SvgIcon component={AddToCompareIcon} />
              )
            }
            onClick={compareHandler}
          >
            {isAddedToCompare ? 'Added to Compare' : 'Add to Compare'}
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            '& hr': {
              height: 20,
            },
          }}
        >
          <Typography variant="h2">{name}</Typography>
          <Divider orientation="vertical" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '& > img': {
                mr: 1,
                flexShrink: 0,
                overflow: 'hidden',
                objectFit: 'unset',
              },
            }}
          >
            <img
              loading="lazy"
              width="30"
              height="20"
              src={
                country.img
                  ? country.img
                  : `https://flagcdn.com/${(
                      country || { code: '' }
                    ).code.toLowerCase()}.svg`
              }
              srcSet={
                country.img
                  ? country.img
                  : `https://flagcdn.com/w40/${(
                      country || { code: '' }
                    ).code.toLowerCase()}.png 2x`
              }
              alt=""
            />
            <Typography variant="subtitle1">{props.country}</Typography>
          </Box>
          <Divider orientation="vertical" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <SvgIcon component={LineChartIcon} />
            <Typography variant="subtitle1">
              {dataPoint}
              &nbsp; Data Points
            </Typography>
          </Box>
          <Divider orientation="vertical" />
        </Box>
        <Grid container alignItems="stretch" spacing={3} pt={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={3} height="100%">
              <Grid container spacing={3} alignItems="stretch" flexGrow={1}>
                <Grid item xs={6}>
                  <ThemeIconCard productImage={productImage} />
                </Grid>
                <Grid item xs={6} display="flex">
                  <InformationPreviewCard
                    details={details}
                    trendTransitioning={trendTransitioning}
                    {...{ category, rank, trendName }}
                  />
                </Grid>
              </Grid>
              <Box>
                <SentimentCard
                  {...{
                    positiveSentiment,
                    neutralSentiment,
                    negativeSentiment,
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <div id="_dowSectionChart">
              <TrendGrowthCard
                small
                details={props.details}
                dowImageType="Chart"
                getChartDataExcel={getChartDataExcel}
                getChartDataPpt={getChartDataPpt}
                saveFilteredTrendData={saveFilteredTrendData}
                toggleImageDownload={toggleImageDownload}
                togglePptDownload={toggleGraphPptDownload}
                handleDownloadExcel={handleGraphDownloadExcel}
                lens="Theme"
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

const ThemeIconCard = ({ productImage }) => (
  <Box
    sx={{
      // bgcolor: 'grey.100',
      borderRadius: 1,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={productImage} alt="product" width={156} height={156} />
  </Box>
)

const InformationPreviewCard = ({
  trendTransitioning,
  category,
  trendName,
  rank,
  details,
}) => {
  let trendTooltipText =
    'Overall maturity of the trend in this particular category. '
  switch (trendName) {
    case 'Growing':
    case 'Emerging':
    case 'Declining':
    case 'Fading':
      trendTooltipText += `For ${trendName} trend, ranking is based on total engagement, number of food service and retail products and growth rate.`
      break
    case 'Dormant':
    case 'Matured':
      trendTooltipText += `For ${trendName} trend, ranking is based on total engagement and number of food service and retail products.`
      break
  }

  return (
    <Paper
      sx={{
        p: 2,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 120,
        justifyContent: 'space-around',
        boxShadow: '0px 4px 12px 0px #272B3108',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="caption" color="textSecondary" align="center">
          Trend Ranking and Maturity
          <InformationIcon
            sx={{
              position: 'absolute',
              width: 15,
              height: 15,
              marginLeft: 0.3,
            }}
            tooltipText={trendTooltipText}
          />
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Typography variant="h1" sx={{ color: 'secondary.main' }}>
          #{rank}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="subtitle2"
            sx={{
              color: 'secondary.main',
              marginLeft: trendTransitioning ? '26px' : 0,
            }}
          >
            {trendName}
          </Typography>
          {trendTransitioning && (
            <Tooltip title={details.transitionStatus.message}>
              <Box
                sx={{
                  height: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  mt: '10px',
                }}
                width={24}
              >
                <SvgIcon component={TransitionFlagIcon} />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Typography variant="subtitle2">{category}</Typography>
    </Paper>
  )
}

InformationPreviewCard.propTypes = {
  name: PropTypes.string.isRequired,
  trendName: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
}

export default withRouter(connect(null, null)(ThemeDetailTop))
