import React from 'react'
import PropTypes from 'prop-types'

/**
 * Behaves exactly like an <img/> element but
 * fallsback gracefully when the image is broken.
 */
const ImageSafe = ({
    src,
    alt,
    fallbackImg,
    onErrorCallback,
    className,
    onlyHideOnError,
    otherImgAttributes
}) => {
    const onImgError = (ev) => {
        ev.target.onerror = null
        if(fallbackImg){
            ev.target.src = fallbackImg
        }else if(onErrorCallback){
            onErrorCallback()
        }else if(onlyHideOnError){
            ev.target.style.visibility = 'hidden'
        }else{
            ev.target.style.display = 'none'
        }
    }
    return <img src={src} className={className} alt={alt} {...otherImgAttributes} onError={onImgError}/>
}

ImageSafe.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fallbackImg: PropTypes.string,
    otherImgAttributes: PropTypes.objectOf({}),
    onErrorCallback: PropTypes.func,
    onlyHideOnError: PropTypes.bool
}

export default ImageSafe