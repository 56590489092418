import * as AC from '../actions/actionConstants'

const permissionReducer = (state = {
  all: [],
  permissionList: true,
  permissionForm: false,

}, action) => {
  switch (action.type) {
    case AC.ADD_ALL_PERMISSIONS:
      return Object.assign({}, state, { all: action.permissions })
    case AC.ADD_PERMISSION:
      return Object.assign({}, state, { all: [...state.all, action.permission] })
    case AC.EDIT_PERMISSION:
      return Object.assign({}, state, { all: state.all.map(item => item.id === action.permission.id ? action.permission : item) })
    case AC.DELETE_PERMISSION:
      return Object.assign({}, state, { all: state.all.filter(item => item.id !== action.PermissionID) })

    case AC.SHOW_PERMISSION_FORM:
      return Object.assign({}, state, { permissionForm: true })

    case AC.HIDE_PERMISSION_FORM:
      return Object.assign({}, state, { permissionForm: false })

    case AC.HIDE_PERMISSION_LIST:
      return Object.assign({}, state, { permissionList: false })

    case AC.SHOW_PERMISSION_LIST:
      return Object.assign({}, state, { permissionList: true })

    default:
      return state
  }
}

export default permissionReducer
