/**
 * Screen winner network utils
 */

class NetworkUtil {
  static getCategories() {
    return RequestEndpoint.url[RequestEndpoint.GET_CATEGORIES]
  }

  static getCountries() {
    return RequestEndpoint.url[RequestEndpoint.GET_COUNTRIES]
  }

  static projectCRUD() {
    return RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
  }

  static projectCRUDD(projectId) {
    return `${RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]}${projectId}/`
  }

  static conceptCRUD(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/concepts/`
  }

  static conceptClone(projectId, conceptId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/concepts/${conceptId}/clone/`
  }

  static conceptRetailProducts(projectId, conceptId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/concepts/${conceptId}/retail_products/`
  }

  static projectDetails(projectId) {
    return `${RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]}${projectId}/`
  }

  static screeningStatus(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/screening_status/`
  }

  static search() {
    return `${RequestEndpoint.url[RequestEndpoint.SEARCH]}`
  }

  static screenProject(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/running_screen_service/`
  }

  static checkScreeningStatus(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/check_screening_status/`
  }

  static getResultsInExcel(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PROJECT_CRUD]
    }${projectId}/download_results/`
  }
}

const RequestEndpoint = {
  GET_COUNTRIES: 0,
  GET_CATEGORIES: 1,
  PROJECT_CRUD: 2,
  SEARCH: 3,
  url: {
    0: '/api/screenwinner/countries/',
    1: '/api/screenwinner/categories/',
    2: '/api/screenwinner/projects/',
    3: '/api/screenwinner/search/',
  },
}

Object.freeze(RequestEndpoint)

export default NetworkUtil
