import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import * as AC from './actionConstants'
import ProductDetailResponse from '../../services/networking/Models/ProductDetailResponse'
// import sampleData from './sampleProd.js'
export const storeProductDetailsForComparision = (productDetails, projectid, lensSelected = "") => ({
  type: AC.STORE_PRODUCT_DETAILS_FOR_COMPARISION,
  productDetails,
  projectid,
  lensSelected
}
)

// export const storeProductDetailsForComparisionId = (productId, projectid, lensSelected = "") => ({
//   type: AC.STORE_PRODUCT_DETAILS_FOR_COMPARISION_ID,
//   productId,
//   projectid,
//   lensSelected
// }
// )

export const fetchProductDetailsForComparision = (productid, projectid, lensSelected) => {
  return async(dispatch) => {
    let request = "" 
    let cafeProductRequest = ""
    let retailProductRequest = ""
    switch(lensSelected){
      case "Product":
        request = NetworkUtil.formSearchRequestForProductFilterDetail(productid)
        cafeProductRequest = NetworkUtil.fetchCafeProductsForProductFilter(productid); // Product
        retailProductRequest = NetworkUtil.fetchRetailProductsForProductFilter(productid); // Product
        break;
      case "Theme":
        request = NetworkUtil.formSearchRequestForThemeFilterDetail(productid)
        // cafeProductRequest = NetworkUtil.fetchCafeProductsForThemeFilter(productid); // Theme (Need to remove)
        // retailProductRequest = NetworkUtil.fetchRetailProductsForThemeFilter(productid); // Theme 
        break;
      default: // Ingredients
        request = NetworkUtil.formSearchRequestForProduct(productid)
        cafeProductRequest = NetworkUtil.fetchCafeProducts(productid); // Ingredients
        retailProductRequest = NetworkUtil.fetchRetailProducts(productid); // Ingredients
    }
    // const responseObject = new ProductDetailResponse(sampleData)
    // console.log(responseObject,"responseObject")
    // Simulate fetch
    // setTimeout(() => {
    //   dispatch(storeProductDetailsForComparision(responseObject, projectid, lensSelected))
    // },10)
    let cafeData = null
    let retailData = null
    if(lensSelected !== "Theme"){
      cafeData = await axios.get(cafeProductRequest)
      retailData = await axios.get(retailProductRequest)
    }
    axios.get(request)
      .then(response => {
        const responseObject = new ProductDetailResponse(response.data)
        if(cafeData){
          responseObject.cafeProducts = cafeData.data
        }
        if(retailData){
          responseObject.retailProducts = retailData.data
        }
        dispatch(storeProductDetailsForComparision(responseObject, projectid, lensSelected))
      })
      .catch(error => {
        console.log('error in fetch product details')
      })
  }
}

export const removeProductFromComparisionList = (productID) => ({
  type: AC.REMOVE_PRODUCT_FROM_COMAPRISION_LIST,
  productID,
}
)

export const getProductListForCompareDropDown = (trend) => ({
  type: AC.GET_PRODUCT_LIST_FOR_COMPARE_DROPDOWN,
  trend,
}
)

export const showComparisionNotification = () => ({
  type: AC.SHOW_COMPARISION_NOTIFICATION,
}
)

export const hideComparisionNotification = () => ({
  type: AC.HIDE_COMPARISION_NOTIFICATION,
}
)

export const clearComparisionList = () => ({
  type: AC.CLEAR_COMPARISION_LIST,
}
)

export const setSelectedProject = (projectId) => ({
  type: AC.SET_SELECTED_PROJECT,
  projectId
})