import React from 'react'
import PropTypes from 'prop-types'

import { Box, Chip, Typography } from '@mui/material'

const ChipFilters = (props) => {
  const { headerText, chipButtons, selectedChip, onClick } = props

  return (
    <Box>
      {headerText && (
        <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1.25 }}>
          {headerText}
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {chipButtons.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            variant="outlined"
            isSelected={chip === selectedChip}
            onClick={(e) => {
              onClick(chip)
            }}
          />
        ))}
      </Box>
    </Box>
  )
}

ChipFilters.propTypes = {
  headerText: PropTypes.string,
  chipButtons: PropTypes.array,
  selectedChip: PropTypes.string,
  onClick: PropTypes.func,
}

export default ChipFilters
