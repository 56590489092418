import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Circle, Add, CloseRounded } from '@mui/icons-material'

import InformationIcon from '../InformationIcon'
import CircularProgressCustom from '../../../shared/components/CircularProgressCustom'
import TrendPhasesChartMui from '../../../components/TrendPhasesChartMui/TrendPhasesChartMui'
import EmptyIngredientsCard from '../EmptyIngredientsCard'

import { amplify } from './../../../store/utility'
import { trendTypes, chipKeys } from '../../utils/constants'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import ButtonGroupComponent from '../TrendsCard/ButtonGroupComponent'

import ExploreCategoryDistribution from '../ExploreCategoryDistribution/ExploreCategoryDistribution'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.country,
      Category_Name: ampiUserData.category,
      Lens: ampiUserData.lens,
    }
    switch (ampiEvent) {
      case 'Hover_Info_Trend_Maturity_Phase':
        amplitudeUserData = {
          ...amplitudeUserData,
          Location: ampiUserData.amplitudeView,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          Phase_Name: ampiUserData.phase,
          Project: ampiUserData.project,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
    }
  } catch (err) {
    console.log(err, 'Amplitude error in TrendsDistributionChart')
  }
}

const TrendsDistributionChart = React.forwardRef(
  (
    {
      projectId,
      muiChartDownload,
      lensSelected,
      trends,
      compactView = false,
      maxTrendsShown,
      email,
      country,
      category,
      amplitudeView,
      initTrendPhases = chipKeys,
      trendPhasesChangeCallback,
      handleListDownload,
      handleDownload,
      isChartView,
      hideListDownload,
      setTable,
      setChart,
      viewMode,
      showChartviewListviewBtns,
      projectVariant,
      visualisationMode,
      newVisualisationTrends,
      handleTrendClick,
      selectedCagr,
      handleCagrChange,
      selectedChartFsValue,
      setSelectedChartFsValues,
      chartFsValueOption,
      setChartFsValueOption,
      selectedChartRetailValue,
      setSelectedChartRetailValues,
      chartRetailValueOption,
      setChartRetailValueOption,
    },
    ref,
  ) => {
    const buttonGroups = (
      <Box
        sx={{
          m: '0px 24px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {showChartviewListviewBtns && (
          <ButtonGroupComponent
            handleListDownload={handleListDownload}
            handleDownload={handleDownload}
            isChartView={viewMode === 'chart'}
            hideListDownload={hideListDownload}
            viewMode={viewMode}
            setTable={setTable}
            setChart={setChart}
            visualisationMode={visualisationMode}
          />
        )}
      </Box>
    )

    const displayEmptyTrend =
      trends.reduce((acc, curr) => acc + curr.allTrendResults.length, 0) === 0

    const EmptyTrend = (
      <Box sx={{ pt: 1 }}>
        {buttonGroups}
        <EmptyIngredientsCard lens={lensSelected} />
      </Box>
    )

    if (visualisationMode === 'old' && displayEmptyTrend) {
      return EmptyTrend
    }

    const [cross, setCross] = useState(initTrendPhases)
    const handleChipClick = (selId, phase) => {
      setCross((crosses) =>
        crosses.includes(selId)
          ? crosses.filter((id) => id !== selId)
          : [...crosses, selId].sort(),
      )

      const chipRemoved = cross.includes(selId) ? 0 : 1
      const chipClick = chipRemoved == 1 ? 'added' : 'removed'

      const ampiEvent = `Trend_phase_${chipClick}`
      const ampiUserData = {
        phase: phase,
        lens: lensSelected,
        project: projectId,
        country: country,
        category: category,
      }
      amp(ampiEvent, ampiUserData)
    }
    const childRef = useRef()
    const MultiColoredChip = ({ phase, cr, active }) => (
      <Chip
        size="small"
        variant="tag"
        sx={
          active
            ? {}
            : {
                '&:hover': {
                  '.MuiChip-icon': {
                    color: ({ palette }) => palette.colors[phase.toLowerCase()],
                  },
                  '.MuiChip-label': {
                    color: ({ palette }) => palette.text.primary,
                  },
                },
                '& .MuiChip-icon, & .MuiChip-label': {
                  color: ({ palette }) => palette.grey[300],
                },
              }
        }
        icon={<Circle />}
        deleteIcon={active ? <CloseRounded /> : <Add />}
        phase={phase.toLowerCase()}
        inActive
        label={phase}
        onClick={() => handleChipClick(parseInt(cr), phase)}
        onDelete={() => handleChipClick(parseInt(cr), phase)}
      />
    )
    useImperativeHandle(ref, () => ({
      handleDownload() {
        childRef.current.handleSaveChart()
      },
    }))

    const hoverAmplitude = () => {
      const ampiEvent = `Hover_Info_Trend_Maturity_Phase`
      const ampiUserData = {
        lens: lensSelected,
        country: country,
        category: category,
        amplitudeView: amplitudeView,
      }
      amp(ampiEvent, ampiUserData)
    }

    const inActiveChipKeys = chipKeys.filter(
      (chipKey) => !cross.includes(chipKey),
    )
    const filterInActiveChips = (chips) =>
      chips
        .filter((key) => inActiveChipKeys.includes(key))
        .map((key) => trendPhaseChips(key, false))

    const trendPhaseChips = (key, active = true) => {
      const trendChip = ({ title, description }, id, active) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.25,
            // ...(active ? {} :
            //   {
            //     marginLeft: key <= 3 ? 0 : 1,
            //     marginRight: key <= 3 ? 1 : 0,
            //   }),
            alignItems: 'center',
            justifyContent: 'center',
            // maxWidth: active ? "1000px" : "100px",
            // flexGrow: active ? 1 : 0,
          }}
        >
          <MultiColoredChip phase={title} cr={`${id}`} active={active} />
          <Typography
            variant="caption"
            color={
              active ? 'textSecondary' : ({ palette }) => palette.grey[300]
            }
          >
            {description}
          </Typography>
        </Box>
      )

      return trendChip(trendTypes[key - 1], key, active)
    }

    useEffect(() => {
      if (trendPhasesChangeCallback) {
        trendPhasesChangeCallback(cross)
      }
    }, [cross])

    return !trends ? (
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgressCustom />
      </Box>
    ) : (
      <Box>
        <Box sx={{ pt: 1, pb: 1 }}>
          {buttonGroups}
          {visualisationMode !== 'new' && (
            <>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 0.8,
                }}
              >
                <Typography variant="subtitle2" align="center">
                  Trend Maturity Phase
                </Typography>
                <InformationIcon
                  onTooltipOpen={() => hoverAmplitude()}
                  tooltipText={`Trend is classified into maturity phase on the basis of volume of engagement, rate of growth rate over short & long term and presence in ${
                    projectVariant === 'bpc'
                      ? 'retail products.'
                      : 'retail and food service.'
                  }`}
                />
              </Box>
              <Box pl={compactView ? 7.5 : 13.75} pr={compactView ? 1 : 3.75}>
                <Box
                  sx={{
                    display: 'flex',
                    '& .MuiBox-root .MuiTypography-root': {
                      textAlign: 'center',
                      padding: 0.5,
                    },
                  }}
                >
                  {/* Left Inactive chips */}
                  {filterInActiveChips(chipKeys.slice(0, 3))}
                  {cross.map((chipKey) => trendPhaseChips(chipKey))}
                  {/* Right Inactive chips  */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 'auto',
                    }}
                  >
                    {filterInActiveChips(chipKeys.slice(-3))}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
        {visualisationMode === 'new' ? (
          <ExploreCategoryDistribution
            viewMode={viewMode}
            setTable={setTable}
            setChart={setChart}
            newVisualisationData={newVisualisationTrends}
            handleTrendClick={handleTrendClick}
            oldTrends={trends}
            lensSelected={lensSelected}
            selectedCagr={selectedCagr}
            handleCagrChange={handleCagrChange}
            maxTrendsShown={maxTrendsShown}
            selectedChartFsValue={selectedChartFsValue}
            setSelectedChartFsValues={setSelectedChartFsValues}
            chartFsValueOption={chartFsValueOption}
            setChartFsValueOption={setChartFsValueOption}
            selectedChartRetailValue={selectedChartRetailValue}
            setSelectedChartRetailValues={setSelectedChartRetailValues}
            chartRetailValueOption={chartRetailValueOption}
            setChartRetailValueOption={setChartRetailValueOption}
            displayEmptyTrend={displayEmptyTrend}
            projectVariant={projectVariant}
          />
        ) : (
          <TrendPhasesChartMui
            wrappedComponentRef={(ref) => (childRef.current = ref)}
            noc={cross}
            projectId={projectId}
            lensSelected={lensSelected}
            trends={trends}
            maxTrendsShown={maxTrendsShown}
            country={country}
            category={category}
            email={email}
            amplitudeView={amplitudeView}
          />
        )}
      </Box>
    )
  },
)

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    projectVariant: state.projectListBuilder.projectList?.projectVariant,
  }
}

export default withRouter(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    TrendsDistributionChart,
  ),
)
