import React, {useState} from 'react';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { format } from 'date-fns';
import ConsumerQuotesContainer from './styles';
import { ReactComponent as ShareImg } from '../../../../../assets/social-icons/share.svg';
import { ReactComponent as Facebook2Img } from '../../../../../assets/social-icons/facebook2.svg';
import { ReactComponent as MessageImg } from '../../../../../assets/social-icons/message.svg';
import fallbackImg from '../../../../../assets/emptySocialImage.png';
import ImgSafe from '../../../../../components/Image/ImageSafe'
import { amplify } from './../../../../../store/utility'

const ConsumerQuotesFacebook = ({
  timestamp, text, likes, postImageUrl, url, email, country, category, name
}) => {
  const [showImg, setShowImg] = useState(true)
  const handleImgError = () => setShowImg(false)
  return (
    //   <ConsumerQuotesContainer onClick={() => {window.open(url, '_blanc')
    //   let ampiEvent = `Clicked_Consumer_Quotes`
    // let ampiUserData = {
    //   'User': email,
    //   'Ingrediet_Name': name,
    //   'Country_Name': country,
    //   'Category_Name': category,
    //   'Platform': 'Facebook',
    // }
    // amplify(ampiEvent, ampiUserData)}}>
    <ConsumerQuotesContainer sx={{
      cursor: 'auto !important',
      '&:hover': {
        border: (theme) => `1px solid ${theme.palette.divider} !important`,
        backgroundColor: theme => `${theme.palette.background.paper} !important`,
        boxShadow: 'unset !important',
      },
      '&:active': {
        backgroundColor: theme => `${theme.palette.background.paper} !important`,
        boxShadow: 'unset !important',
      },
    }}>
      {/* You need to use the classname to adjust the size of the picture. */}
      {postImageUrl && showImg &&
        <Box
          sx={{
            height: theme => theme.spacing(21.25),
            overflow: 'hidden',
          }}
        >
          <ImgSafe
            src={postImageUrl}
            alt={text}
            className="image"
            onErrorCallback={handleImgError}
          />
        </Box>
      }
      <Typography
        px={2}
        my={1}
        variant="subtitle2"
        sx={{
          height: theme => theme.spacing(11.25),
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          pb: 1,
        }}
      >
        <ThumbUpIcon
          sx={{
            height: 17,
            width: 17,
            mr: 0.5,
            color: theme => theme.palette.info.main,
          }}
        />
        <Typography pr={1} variant="caption">
          {likes}
          {' '}
          Likes
        </Typography>
        <ShareImg />
        <Typography pl={0.5} pr={1} variant="caption">
          Share
        </Typography>
        <MessageImg />
        <Typography pl={1} variant="caption">
          Comment
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2,
        }}
      >
        <Facebook2Img />
        <Typography
          width="100%"
          textAlign="center"
          variant="subtitle2"
          px={2}
        >
          {/* {username} */}
          &ensp;
          <span className="text">
          {format(new Date(timestamp), 'MMM dd, yyyy')}
          </span>
        </Typography>
      </Box>
    </ConsumerQuotesContainer>
  );
    }
ConsumerQuotesFacebook.propTypes = {
  timestamp: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  postImageUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ConsumerQuotesFacebook;
