import React from 'react'

import Button from '../Buttons/ButtonSW'

import conceptDelete from '../../assets/images/screenWinner/sw_delete_concept.svg'

import './SWAlert.scss'

const SWAlert = ({
  title = 'Alert',
  type,
  message = '',
  onCancel,
  onConfirm,
  onConfirmText = 'Confirm',
  showCancel = true,
}) => {
  return (
    <div className="sw-alert__block" onClick={(ev) => ev.stopPropagation()}>
      <div className="sw-alert">
        <h1 className="sw-alert__heading">{title}</h1>
        {/* <img
          className="sw-alert__img"
          src={conceptDelete}
          alt="Delete concept"
        /> */}
        <p className="sw-alert__txt">{message}</p>
        <div className="sw-alert__actions">
          {showCancel && (
            <Button
              className="sw-alert__actions--cancel"
              name="Cancel"
              type="button"
              onClick={onCancel}
            />
          )}
          <Button
            className="sw-alert__actions--delete"
            name={onConfirmText}
            type="button"
            solid
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  )
}

export default SWAlert
