import React, { useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Button,
  Collapse,
  Alert,
  IconButton,
} from '@mui/material'
import LangMenus from '../LangMenus'
import RelatedProductCard from './partials/RelatedProductCard'
import DownloadButton from '../DownloadButton'
import NeedLinearProgress from '../NeedLinearProgress'
import { ErrorOutlineRounded, ErrorRounded, Close } from '@mui/icons-material'
import { ProductCardsContainer, StyledBox } from '../FoodServiceProducts/styles'
import { amplify } from './../../../store/utility'
import DialogWithCheckboxOptions from '../MarkIrrelevant/DialogWithCheckboxOptions'
import { useParams } from 'react-router-dom'
import OverlayLoader from '../OverlayLoader'
import axios from './../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'

const RelatedRetailProducts = (props) => {
  const {
    projectid,
    projectVariant,
    retailDataPoint,
    retailProductFormats,
    retailProducts,
    name,
    country,
    category,
    email,
    noSide,
    lens,
    expand,
    showCount,
    isProductPage,
  } = props
  const [platformList, setPlatformList] = useState()
  const [brandList, setBrandList] = useState()
  const apiFetch = () => {
    const url = NetworkUtil.fetchPlatformAndBrand(country, category, name, lens)
    axios
      .get(url)
      .then((res) => {
        setPlatformList(res?.data?.data?.retail_platforms_and_brands)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    apiFetch()
  }, [])
  const { urlLensSelected } = useParams()
  const [selectedLang, setSelectedLang] = useState('English')
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedIrrelevantDocs, setSelectedIrrelevantDocs] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [loader, setLoader] = useState(false)
  const [irrelevantCount, setIrrelevantCount] = useState(0)
  const dialogElementRetailProducts = useRef(null)
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [selectedBrand, setSelectedBrand] = useState('')
  const [filteredProducts, setFilteredProducts] = useState('')
  const showLoader = () => {
    setLoader(true)
  }

  const hideLoader = () => {
    setLoader(false)
    setIsBackdropOpen(false)
    setSelectedIrrelevantDocs([])
  }

  const toggleShowAlert = () => {
    setIrrelevantCount(selectedIrrelevantDocs.length)
    setShowAlert(!showAlert)
  }

  const hideAlert = () => {
    setShowAlert(false)
    setTimeout(() => {
      setIrrelevantCount(0)
      setSelectedIrrelevantDocs([])
    }, 1500)
  }
  const handleRetailPlatformChange = (platformName) => {
    if (platformName) {
      setSelectedPlatform(platformName)
    }
    const ampiEvent = 'Clicked_Retail_Platform_Dropdown'
    const ampiUserData = {
      User: props.email,
      Trend_Name: props.name,
      Country_Name: props.country,
      Category_Name: props.category,
      Platform: platformName,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }
  const handleRetailBrandChange = (brandName) => {
    if (brandName) {
      setSelectedBrand(brandName)
    }
    const ampiEvent = 'Clicked_Retail_Brand_Dropdown'
    const ampiUserData = {
      User: props.email,
      Trend_Name: props.name,
      Country_Name: props.country,
      Category_Name: props.category,
      Brand: brandName,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }
  const toggleMarkIrrelevantBackdrop = () => {
    setIsBackdropOpen(!isBackdropOpen)
    closeDialog()
    if (showAlert === true && selectedIrrelevantDocs.length >= 1) {
      setShowAlert(false)
    } else {
      setSelectedIrrelevantDocs([])
    }
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const handleEsDocSelection = (e) => {
    if (
      e.target.checked &&
      selectedIrrelevantDocs.indexOf(e.target.value) === -1
    ) {
      setSelectedIrrelevantDocs([...selectedIrrelevantDocs, e.target.value])
    } else {
      setSelectedIrrelevantDocs(
        selectedIrrelevantDocs.filter((r) => r !== e.target.value),
      )
    }
  }

  useEffect(() => {
    if (isDialogOpen && dialogElementRetailProducts.current) {
      console.log(dialogElementRetailProducts.current)
      dialogElementRetailProducts.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      })
    }
  }, [isDialogOpen])

  const handleLangChange = (event) => {
    setSelectedLang(event.target.value)
    const ampiEvent = 'Clicked_Related_Retail_Product_DisplayLanguage'
    const ampiUserData = {
      User: props.email,
      Language_Name: event.target.value,
      Trend_Name: props.name,
      Country_Name: props.country,
      Category_Name: props.category,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }
  const [imgErrorIds, setImgErrorIds] = useState([])
  const validRetailProducts = useMemo(
    () =>
      retailProducts.filter(
        // eslint-disable-next-line
        ({ name, image_url, image_uri }) =>
          !imgErrorIds.find(
            // eslint-disable-next-line
            (id) => `${name}##${image_url || image_uri}` === id,
          ),
      ),
    [imgErrorIds, retailProducts],
  )
  useEffect(() => {
    if (
      selectedPlatform &&
      selectedPlatform !== 'All' &&
      selectedBrand &&
      selectedBrand !== 'All'
    ) {
      const filtered = retailProducts.filter(
        (product) =>
          product.platform === selectedPlatform &&
          product.brand_name === selectedBrand,
      )
      setFilteredProducts(filtered)
    } else if (selectedPlatform && selectedPlatform !== 'All') {
      const filteredByPlatform = retailProducts.filter(
        (product) => product.platform === selectedPlatform,
      )
      setFilteredProducts(filteredByPlatform)
    } else if (selectedBrand && selectedBrand !== 'All') {
      const filteredByBrand = retailProducts.filter(
        (product) => product.brand_name === selectedBrand,
      )
      setFilteredProducts(filteredByBrand)
    } else {
      setFilteredProducts(retailProducts)
    }
  }, [selectedPlatform, selectedBrand, retailProducts])

  useEffect(() => {
    // eslint-disable-next-line
    retailProducts.forEach(({ image_url, image_uri, name }) => {
      const img = new Image()
      // eslint-disable-next-line
      const validImgUrl = image_url || image_uri
      img.onerror = () => {
        img.onerror = null
        setImgErrorIds((imgErrorIds) => [
          ...imgErrorIds,
          `${name}##${validImgUrl}`,
        ])
      }
      img.src = validImgUrl
    })
  }, [retailProducts])


  const platformValue = selectedPlatform

  const platformNames = platformList && Object.keys(platformList)
  const allbrandNames = platformList && Object.values(platformList).flat()
  const uniqueBrandValues =
    platformValue === 'All' || !platformValue
      ? [...new Set(allbrandNames)]
      : platformList[platformValue]

  const hasNativeName = retailProducts.some((product) => product.native_name)

  useEffect(() => {
    const updatedPlatform = selectedPlatform

    if (
      updatedPlatform &&
      !platformList[updatedPlatform]?.includes(selectedBrand)
    ) {
      setSelectedBrand('All')
    }
  }, [selectedPlatform])
  
  return !(!validRetailProducts.length && noSide) && (
    <Box
      sx={{
        background: (theme) => theme.palette.background.paper,
        borderRadius: 1,
        boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
        padding: (theme) => theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h3">Related Retail Products</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'normal',
          }}
        >
          <Typography mr={1} width="100%" variant="subtitle2">
            Display Language:
          </Typography>
          <FormControl
            variant="outlined"
            fullWidth
            size="large"
            sx={{
              minWidth: '120px',
            }}
          >
            <LangMenus
              hasNativeLang={hasNativeName}
              country={country}
              handleChange={handleLangChange}
            />
          </FormControl>
          {showCount && (
            <Box
              sx={{
                ml: 1,
              }}
            >
              <Typography
                component="div"
                variant="subtitle2"
                color="text.secondary"
                sx={{
                  width: 'max-content',
                }}
              >
                {`Count: ${retailDataPoint}`}
              </Typography>
            </Box>
          )}
          {props.projectVariant === 'bpc' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'normal',
              }}
            >
              <Typography mr={1} width="100%" variant="subtitle2" ml={1}>
                Platform
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Select value={selectedPlatform || 'All'}>
                  <MenuItem
                    value="All"
                    onClick={() => handleRetailPlatformChange('All')}
                  >
                    All
                  </MenuItem>
                  {platformNames &&
                    platformNames.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        onClick={() => handleRetailPlatformChange(name)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {projectVariant === 'bpc' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'normal',
              }}
            >
              <Typography mr={1} width="100%" variant="subtitle2" ml={1}>
                Brand
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Select
                  value={selectedBrand || 'All'}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem
                    value="All"
                    onClick={() => handleRetailBrandChange('All')}
                  >
                    All
                  </MenuItem>
                  {uniqueBrandValues &&
                    uniqueBrandValues.filter((name) => (name.trim()!=="-")).map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        onClick={() => handleRetailBrandChange(name)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {!noSide && (
            <Box
              sx={{
                ml: 1,
              }}
            >
              <DownloadButton
                dowImageType="Retail"
                dowPptType={props.productType}
                toggleImageDownload={props.toggleImageDownload}
                handleDownloadExcel={props.rFExcelDownload}
                togglePptDownload={props.togglePptDownload}
                title="_Related_Retail_Product"
              />
            </Box>
          )}
          {!props?.markIrrelevantDisabled && isBackdropOpen ? (
            <Box
              sx={{
                ml: 1,
                minWidth: 'fit-content',
              }}
            >
              <Button
                variant="grayishblue"
                onClick={toggleMarkIrrelevantBackdrop}
                sx={{ marginRight: '8px' }}
              >
                Cancel
              </Button>
              <Button
                variant={
                  selectedIrrelevantDocs.length ? 'contained' : 'grayishblue'
                }
                size="small"
                onClick={openDialog}
                disabled={selectedIrrelevantDocs.length <= 0}
              >
                Confirm
              </Button>
            </Box>
          ) : (
            props.projectVariant === 'food' && (
              <Box
                sx={{
                  ml: 1,
                  minWidth: 'fit-content',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<ErrorOutlineRounded />}
                  onClick={toggleMarkIrrelevantBackdrop}
                >
                  Mark Irrelevant
                </Button>
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mx: -2,
          py: 2,
        }}
      >
        <Divider />
      </Box>
      <Grid
        id="retail-products-dialog-container"
        container
        spacing={4}
        sx={
          expand
            ? {
                maxHeight: 'fit-content !important',
                height: 'fit-content !important',
                overflowY: 'auto !important',
                position: 'relative',
              }
            : { maxHeight: '500px', position: 'relative' }
        }
      >
        {!isProductPage
          ? !noSide && (
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">Formats</Typography>

                  {(selectedPlatform === 'All' || selectedPlatform === '') &&
                  (selectedBrand === 'All' || selectedBrand === '') ? (
                    <Typography variant="subtitle2" color="textSecondary">
                      Products &nbsp;
                      <Typography component="span" variant="subtitle2">
                        {retailDataPoint}
                      </Typography>
                    </Typography>
                  ) : null}
                </Box>

                <StyledBox sx={{ mt: 1, mx: -1 }}>
                  {Object.entries(retailProductFormats || []).map(
                    ([title, value]) => (
                      <NeedLinearProgress
                        {...{ title, value }}
                        link={`/mui/details/productFormat/${projectid}/ingredient/${
                          props.productid
                        }/${props.productType}/${encodeURIComponent(title)}`}
                        country={props.country}
                        category={props.category}
                        ingredientName={props.name}
                        email={props.email}
                        key={title}
                        displayTitle="Related_Retail_Product_Format"
                        heading="Format_Name"
                      />
                    ),
                  )}
                </StyledBox>
              </Grid>
            )
          : null}

        <Grid item xs>
          <Box
            sx={{
              width: 'auto',
              position: 'absolute',
              top: 22,
              right: 10,
            }}
          >
            <Collapse in={showAlert}>
              <Alert
                severity="info"
                icon={<ErrorRounded fontSize="inherit" />}
                action={
                  <IconButton
                    aria-label="close"
                    color="info"
                    size="small"
                    onClick={() => {
                      hideAlert()
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {irrelevantCount}{' '}
                {irrelevantCount > 1
                  ? 'Retail Products have '
                  : 'Retail Product has '}
                been marked as irrelevant.
              </Alert>
            </Collapse>
          </Box>
          {loader ? <OverlayLoader /> : null}
          <DialogWithCheckboxOptions
            isOpen={isDialogOpen}
            closeDialog={() => closeDialog()}
            toggleAlert={() => toggleShowAlert()}
            isOtherOptionSelected={false}
            dialogElement={dialogElementRetailProducts}
            selectedIrrelevantDocs={selectedIrrelevantDocs}
            lens={urlLensSelected}
            country={country}
            category={category}
            name={props.name}
            containerId={'retail-products-dialog-container'}
            showLoader={() => showLoader()}
            hideLoader={() => hideLoader()}
            type="retail"
            onRefresh={() => {
              props.onRefresh()
            }}
          />
          {!noSide && (
            <>
              <Typography mb={2} variant="subtitle1">
                Sample Products
              </Typography>
            </>
          )}
          <ProductCardsContainer
            sx={
              expand
                ? {
                    maxHeight: 'fit-content !important',
                    height: 'fit-content !important',
                    overflowY: 'auto !important',
                  }
                : props.trendInfoPage
                ? { height: 320 }
                : {}
            }
            container
            spacing={1}
            mt={2}
          >
            {selectedLang === 'English' &&
              !filteredProducts &&
              validRetailProducts.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={props.isTrendInfoPage ? 6 : 4}
                    key={`${item.es_id}-${index}`}
                  >
                    <RelatedProductCard
                      // eslint-disable-next-line
                      image={item.image_url}
                      name={item.name}
                      brand={item.brand_name}
                      price={item.price}
                      currency={item.currency}
                      url={item.product_url}
                      country={country}
                      category={category}
                      email={email}
                      ingredientName={name}
                      isBackdropOpen={isBackdropOpen}
                      esId={item?._id || item?.es_id}
                      selectedIrrelevantDocs={selectedIrrelevantDocs}
                      handleEsDocSelection={handleEsDocSelection}
                    />
                  </Grid>
                )
              })}
            {selectedLang !== 'English' &&
              !filteredProducts &&
              validRetailProducts.map((item) => (
                <Grid item xs={4} key={item.es_id}>
                  <RelatedProductCard
                    // eslint-disable-next-line
                    image={item.image_url}
                    name={item?.native_name || item?.name}
                    brand={item?.native_brand_name || item?.brand_name}
                    price={item.price}
                    currency={item.currency}
                    url={item.product_url}
                    isBackdropOpen={isBackdropOpen}
                    esId={item?._id || item?.es_id}
                    selectedIrrelevantDocs={selectedIrrelevantDocs}
                    handleEsDocSelection={handleEsDocSelection}
                  />
                </Grid>
              ))}
            {filteredProducts &&
              filteredProducts.map((item) => (
                <Grid item xs={4} key={item.es_id}>
                  <RelatedProductCard
                    // eslint-disable-next-line
                    image={item.image_url}
                    name={
                      selectedLang !== 'English'
                        ? item?.native_name
                        : item?.name
                    }
                    brand={
                      selectedLang !== 'English'
                        ? item?.native_brand_name
                        : item?.brand_name
                    }
                    price={item.price}
                    currency={item.currency}
                    url={item.product_url}
                    isBackdropOpen={isBackdropOpen}
                    esId={item?._id || item?.es_id}
                    selectedIrrelevantDocs={selectedIrrelevantDocs}
                    handleEsDocSelection={handleEsDocSelection}
                  />
                </Grid>
              ))}
          </ProductCardsContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

RelatedRetailProducts.propTypes = {
  noSide: PropTypes.bool,
}

RelatedRetailProducts.defaultProps = {
  noSide: false,
}

export default RelatedRetailProducts
