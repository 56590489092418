import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

const StyledSnackbarProvider = styled(NotistackSnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    align-items: center;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.colors.alertSuccessBackground};
    ${({ theme }) => theme.typography.subtitle2};
    & .SnackbarItem-message {
      & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.success.main}
      }
    }
    & .MuiButton-root {
      ${({ theme }) => theme.typography.subtitle3};
      color: ${({ theme }) => theme.palette.success.main}
    }
  }

  &.SnackbarItem-variantInfo {
    align-items: center;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.colors.alertInfoBackground};
    ${({ theme }) => theme.typography.subtitle2};
    & .SnackbarItem-message {
      & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.info.main}
      }
    }
    & .MuiButton-root {
      ${({ theme }) => theme.typography.subtitle3};
      color: ${({ theme }) => theme.palette.info.main}
    }
  }
`;

const SnackbarProvider = ({ children }) => (
  <StyledSnackbarProvider
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    maxSnack={3}
  >
    {children}
  </StyledSnackbarProvider>
);

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
