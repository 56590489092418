import { connect } from 'react-redux'
import { UserPage } from '../../components'
import * as A from '../../store/actions'
import { initialize, SubmissionError } from 'redux-form'
import { NotificationManager } from 'react-notifications'

const mapDispatchToProps = (dispatch, ownProps) => ({
  addOrgSubUser: (values) => {
    if (!values.id) {
      dispatch(A.showSppiner())
      return dispatch(A.addOrgSubUserOnServer(values)).then((response) => {
        if (response.responseStatus === 'success') {
          dispatch(A.addOrgSubUser(response.resData))
          dispatch(A.hideUserRegistrationForm())
          dispatch(A.showOrganizationUserList())
          dispatch(A.hideSppiner())
        } else {
          console.log('inside error')
          dispatch(A.hideSppiner())
          throw new SubmissionError({ email: 'User Already Exist' })
        }
      })
    } else {
      dispatch(A.showSppiner())
      return dispatch(A.updateOrgSubUserOnServer(values)).then((response) => {
        if (response.responseStatus === 'success') {
          dispatch(A.editOrgSubUser(response.resData))
          dispatch(A.hideUserRegistrationForm())
          dispatch(A.showOrganizationUserList())
          dispatch(A.hideSppiner())
        } else {
          console.log('inside error')
          dispatch(A.hideSppiner())
          throw new SubmissionError({ email: 'User Already Exist' })
        }
      }
      )
    }
  },
  getAllSubUserOfOrganization: () => {
    dispatch(A.showSppiner())
    return dispatch(A.getAllSubUserOfOrganizationFromServer()).then(() => {
      dispatch(A.hideUserRegistrationForm())
      dispatch(A.showOrganizationUserList())
      dispatch(A.hideSppiner())
    })
  },
  showUserRegistrationForm: () => {
    dispatch(A.showUserRegistrationForm())
    dispatch(A.hideOrganizationUserList())
  },
  hideForm () {
    dispatch(A.hideUserRegistrationForm())
    dispatch(A.showOrganizationUserList())
  },
  showSubUserEditForm: (orgUserDetails) => {
    dispatch(A.showUserRegistrationForm())
    dispatch(A.hideOrganizationUserList())
    dispatch(initialize('registration-form', orgUserDetails))
  },
  showSubUserDelete: (orgUser) => {
    dispatch(A.showSppiner())
    return dispatch(A.deleteOrgSubUserOnServer(orgUser.id)).then(() => {
      dispatch(A.hideSppiner())
    })
  },
})

const mapStateToProps = (state) => {
  return {
    organization: state.organization.list,
    orgUser: state.organization.orgUser,
    orgUserList: state.organization.orgUserList,
    registrationUserForm: state.organization.registrationUserForm,
    registrationForm: state.organization.registrationForm,
    showSpinner: state.application.showSpinner,
    loggedInUser: state.user.loggedIn && state.user.loggedIn.access_token ? state.user.loggedIn.access_token : null,
    loggedInUserRole: state.user.loggedIn && state.user.loggedIn.role ? state.user.loggedIn.role : null,
  }
}

const UserPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage)

export default UserPageContainer
