import React, { Component } from 'react'
import './Layout.css'

class Layout extends Component {
  render() {
    return (
      <>
        <main
          className="content"
          style={{ minHeight: window.innerHeight - 132 }}
        >
          {this.props.children}
        </main>
      </>
    )
  }
}

export default Layout
