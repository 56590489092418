import { WhiteSpaceActionTypes } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchWhiteSpaceData = (country, category, lens) => {
  return (dispatch, getState) => {
    const request = NetworkUtil.fetchWsoData(country, category, lens)
    axios.get(request)
      .then(response => { 
        dispatch(whiteSpaceDataSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(whiteSpaceDataFailed(error))
      })
  }
}

export const downloadWhiteSpaceData = (country, category) => {
  return (dispatch, getState) => {
    const request = NetworkUtil.downloadWsoData(country, category)
    axios.get(request, {
      responseType: 'blob'
    })
      .then(response => {
        window.saveAs(response.data, 'whiteSpaceData.xlsx')
      })
      .catch(error => {
        console.log(error, "after download error")
      })
  }
}

export const subCategoryData = (country, category, lens, subCategory) => {
  return (dispatch, getState) => {
    const request = NetworkUtil.fetchWsoSubCategoryData(encodeURIComponent(country), encodeURIComponent(category), lens, encodeURIComponent(subCategory))
    axios.get(request)
      .then(response => {
        dispatch(whiteSpaceDataSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(whiteSpaceDataFailed(error))
      })
  }
}

const whiteSpaceDataSuccess = (whiteSpaceData) => {
  return {
    type: WhiteSpaceActionTypes.type[WhiteSpaceActionTypes.WHITE_SPACE_SUCCESS],
    whiteSpaceData: whiteSpaceData,
  }
}

const whiteSpaceDataFailed = (error) => {
  return {
    type: WhiteSpaceActionTypes.type[WhiteSpaceActionTypes.WHITE_SPACE_FAILED],
    error: error,
  }
}
