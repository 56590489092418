import { NeedDetailsViewActions, ResetActions, SubNeedDetailActionTypes, NeedQuotesViewActions, SubNeedQuoteActionTypes  } from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  needDetails: null,
  needQuotes: null,
  subNeedDetail: null,
  subNeedQuotes: null,
  loading: false,
  subNeedLoading: false,
  subNeedQuotesloading: false,
  error: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ResetActions.type[ResetActions.RESET_PRODUCT_NEEDS]: return updateObject(state, initialState)
    
    case NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_START]: return updateObject(state, {
      loading: true,
    })
    case NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_SUCCESS]: return updateObject(state, {
      needDetails: action.needDetails,
      loading: false,
      error: false,
    })
    case NeedDetailsViewActions.type[NeedDetailsViewActions.FETCH_NEEDS_FAIL]: return updateObject(state, {
      error: true,
      loading: false,
      needDetails: null,
    })

    case NeedQuotesViewActions.type[NeedQuotesViewActions.FETCH_NEEDS_QUOTES_SUCCESS]: return updateObject(state, {
      needQuotes: action.needQuotes,
    })
    case NeedQuotesViewActions.type[NeedQuotesViewActions.FETCH_NEEDS_QUOTES_FAIL]: return updateObject(state, {
      needQuotes: null,
    })
    
    case SubNeedDetailActionTypes.type[SubNeedDetailActionTypes.FETCH_SUB_NEEDS_START]: return updateObject(state, {
      subNeedLoading: true,
    })
    case SubNeedDetailActionTypes.type[SubNeedDetailActionTypes.FETCH_SUB_NEEDS_SUCCESS]: return updateObject(state, {
      subNeedDetail: action.subNeedDetail,
      subNeedLoading: false,
      error: false,
    })
    case SubNeedDetailActionTypes.type[SubNeedDetailActionTypes.FETCH_SUB_NEEDS_FAIL]: return updateObject(state, {
      error: true,
      subNeedLoading: false,
      subNeedDetail: null,
    })

    case SubNeedQuoteActionTypes.type[SubNeedQuoteActionTypes.FETCH_SUB_NEED_QUOTES_SUCCESS]: return updateObject(state, {
      subNeedQuotes: action.subNeedQuotes,
    })
    case SubNeedQuoteActionTypes.type[SubNeedQuoteActionTypes.FETCH_SUB_NEED_QUOTES_FAIL]: return updateObject(state, {
      subNeedQuotes: null,
    })

    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        needDetails: null,
        loading: false,
        error: null,
      })
    default:
      return state
  }
}

export default reducer
