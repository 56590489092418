import React from 'react'
import { ReactComponent as MinusIcon } from '../../../../../assets/icons/feather-minus-circle.svg'
import Card from '../AdvancedTrendAnalysisCard'
import { Typography, Box, CircularProgress, Grid, SvgIcon } from '@mui/material'
import { StyledCarousel, StyledGrid } from './styles'

const AdvancedTrendAnalysisExpanded = (props) => {
  const {
    lens,
    showSubCat,
    handleTrendClick,
    productComparisonData,
    imageUrl,
    productName,
  } = props

  const trendsList =
    productComparisonData.reduce((acc, trend, i) => {
      const currIndex = Math.floor(i / 4)
      const card = (
        <Card
          showSubCat={showSubCat}
          lens={lens}
          productComparisonData={trend}
          productName={productName}
        />
      )
      if (acc[currIndex]) {
        acc[currIndex].push(card)
      } else {
        acc[currIndex] = [card]
      }
      return acc
    }, []) || []

  return (
    <>
      <StyledGrid
        mt={3}
        sx={{ height: 96, width: '100%' }}
        display="flex"
        justifyContent="start"
        alignItems="center"
        backgroundColor={(theme) => theme.palette.white}
        onClick={handleTrendClick('')}
      >
        <SvgIcon
          sx={{
            borderRadius: 1,
            marginRight: '600px',
            marginLeft: '20px',
            '& path': {
              stroke: '#000',
            },
          }}
          component={MinusIcon}
          width="16"
          height="16"
          cursor="pointer"
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src={imageUrl}
            alt="image not found"
            width="94px"
            height="52px"
            sx={{
              borderRadius: 1,
              objectFit: 'cover',
            }}
          />
          <Typography
            align="center"
            sx={{ transform: 'translate(0, 10px)' }}
            variant="subtitle1"
          >
            {productName}
          </Typography>
        </Box>
      </StyledGrid>
      <StyledCarousel filterProductLength={trendsList.length > 1}>
        {trendsList.length > 0 ? (
          trendsList.map((trend) => (
            <Grid justifyContent="center" container>
              {trend}
            </Grid>
          ))
        ) : (
          <Grid container height="440px" columns={12} alignContent="center">
            <Grid item xs={6} />
            <Grid item xs={6}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </StyledCarousel>
    </>
  )
}

export default AdvancedTrendAnalysisExpanded
