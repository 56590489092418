import { Box, styled } from '@mui/system'

export default styled(Box)(({ theme, disableTopicsPointerEvents }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  padding: `${theme.spacing(1)}`,
  cursor: 'pointer',
  pointerEvents: disableTopicsPointerEvents && 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
  },
}))
