import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { UserProfile } from '../../components'
import * as A from '../../store/actions'
import { initialize } from 'redux-form'
import { NotificationManager } from 'react-notifications'

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateUserProfile: (values) => {
    dispatch(A.showSppiner())
    return dispatch(A.updateUserProfileOnServer(values)).then(() => {
      dispatch(A.hideSppiner())
    })
  },
  getUserProfile: () => {
    dispatch(A.getUserProfileOnServer())
  },
  showUserInfo: (userDetails) => {
    dispatch(initialize('registration-form', userDetails))
  },

})

const mapStateToProps = (state) => {
  return {
    loginUserDetails: state.user.loggedIn.userDetails,
    showSpinner: state.application.showSpinner,
    userProfile: state.user.userProfile,
  }
}

const UserProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile)

export default withRouter(UserProfileContainer)
