import React, { useState, useEffect } from 'react'
import GenericTableHead from './../../shared/components/GenericTableComponent/GenericTableHead'
import AdminUI from './AdminUI'
import { Circle } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { useSnackbar } from 'notistack'
import axios from '../../services/networking/axios'
import { Box, Typography, Tabs, TableCell, Tab, Grid } from '@mui/material'
import ContainerTab from '../ClientManagement/ContainerTab'
import TabPanel from '../../shared/components/TabPanel'
import AddUser from '../../components/UserManagement/AddUser'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import UserManagement from '../../components/UserManagement/UserManagement'
import { useLoader } from '../../hooks'
import OverlayLoader from '../../shared/components/OverlayLoader'
import moment from 'moment'
import ErrorPage from '../../pages/ErrorPage'
import { connect } from 'react-redux'
import { convertListKeysToCamelCase } from '../../shared/utils/helperFunctions'
import getNewUiToken from '../../shared/utils/getNewUiToken'
import { getBaseURL, getRequestOptions } from '../../shared/utils/migrationHelper'

const BASE_URL = getBaseURL()

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const editInfo = { level: 1, status: 'draft' }
const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (id === 'options' || id === 'dashboardSubscribed') {
      return func ? func(rowData) : rowData
    } else if (rowData[id] != null) {
      return func ? func(rowData[id]) : rowData[id]
    }
  })
  return res
}

const Admin = ({ isManager }) => {
  const [clientData, setClientData] = useState([])
  const [clientDetail, setClientDetails] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('clientName')
  const [clientTypeFilter, setClientTypeFilter] = useState({
    key: 0,
    label: 'All',
  })
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false)
  const [dashboardsSubscribed, setDashboardSubscribed] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [tab, setTab] = useState(0)
  const [clientModification, setClientModification] = useState(0)
  const [disabledTab, setDisablesTab] = useState([])
  const [edit, setEdit] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [deleteClientDailog, setDeleteClientDialog] = useState(false)
  const [clientCount, setClientCount] = useState({
    totalClients: 0,
    trialClient: 0,
    licensedClient: 0,
  })
  const [open, setOpen] = useState(null)
  const [isMigrateDialogOpen, setIsMigrateDialogOpen] = useState(false)
  const [featuresSelected, setFeaturesSelected] = React.useState(
    {}
  );
  const [featureList,setFeatureList] = useState([])

  const sortTableData = (array, sortBy) => {
    return array?.sort((a, b) => {
      if (a[sortBy].toLocaleLowerCase() < b[sortBy].toLocaleLowerCase())
        return -1
      if (a[sortBy].toLocaleLowerCase() > b[sortBy].toLocaleLowerCase())
        return 1
    })
  }
  sortTableData(clientData, 'clientName')

  useEffect(() => {
    setOpen(Boolean(anchorEl))
  }, [anchorEl])

  const id = open ? 'simple-popover' : undefined
  const [showLoader, setShowLoader] = useLoader()

  const handleChange = (event, newTab) => {
    setTab(newTab)
    setClientModification(0)
  }

  const snackbarShow = (content, muiVariant) => {
    enqueueSnackbar(<span>{content}</span>, { variant: muiVariant })
    setTimeout(() => {
      closeSnackbar()
    }, 5000)
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFeaturesSelected((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const handleMigrationDialogClose=()=>{
    setIsMigrateDialogOpen(false)
    setAnchorEl(null)
  }

  const migrateOrgToNewUI = async(clientData) => {
    setShowLoader(true)
    const request = {
      org_name: clientData.clientName,
      features:featuresSelected
    }
  const token = await getNewUiToken()
    const options = getRequestOptions(token,true)
    const orgMigrationURL = `${BASE_URL}migration/organisation`
    axios.post(orgMigrationURL, request, options)
    .then((res) => {
      console.log("Request succeeded");
    })
    .catch((error) => {
      console.error(`Request failed: ${JSON.stringify(error)}`);
      setShowLoader(false)
      
    });
    handleMigrationDialogClose()
    setShowLoader(false)
  }

  const getOrgFeatures = async (orgName) => {
    try {
      const token = await getNewUiToken();
      const options = getRequestOptions(token, true);
      const orgMigrationURL = `${BASE_URL}migration/organisation/features?org_name=${orgName}`;
      
      const response = await axios.get(orgMigrationURL, options);
      const features = convertListKeysToCamelCase(response.data)
      setFeatureList(features)
      const featuresObject = features.reduce((acc, feature) => {
        acc[feature.key] = feature.isEnabled;
        return acc;
      }, {});
      return featuresObject;
    } catch (error) {
      console.error("Error fetching organization features:", error);
      return {};
    }
  };


  const handleClose = () => {
    setAnchorEl(null)
    setDeactivateDialogOpen(false)
  }

  const handleDeactivateDialog = () => {
    setAnchorEl(null)
    setDeactivateDialogOpen(!deactivateDialogOpen)
  }

  const clientDeactivateSuccess = (row) => {
    const request = {
      id: row?.clientId,
      status: row?.status == 'ACTIVE' ? 'IN-ACTIVE' : 'ACTIVE',
    }
    axios
      .put(NetworkingUtil.userAccessManagementStatus(), request)
      .then(getClientData())
      .catch()
    row?.status == 'ACTIVE'
      ? snackbarShow('The client is deactivated successfully', 'success')
      : snackbarShow('The client is activated successfully', 'success')

    handleDeactivateDialog()
    getClientData()
  }

  const clientTypeChanged = (newVal) => {
    setClientTypeFilter(newVal)
  }

  const handleAddUser = () => {
    setClientModification(1)
    setEdit(false)
    setDisablesTab(['Features', 'Dashboard assigned'])
  }

  const handleDeleteClient = () => {
    const data = {
      id: selectedRow?.clientId,
    }
    const request = NetworkingUtil.userAccessManagement()
    axios
      .delete(request, { data: data })
      .then(() => {
        setDeleteClientDialog(!deleteClientDailog)
        getClientData()
      })
      .catch()
  }

  const handleEditUser = () => {
    setEdit(true)
    setClientModification(1)
    setDisablesTab(['Dashboard assigned'])
    // api call for handling edit information where the user can navigate through
  }

  const handleRequestSort = (property) => () => {
    const isSameOrderByAsc = orderBy === property && order === 'asc'
    setOrder(isSameOrderByAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setClientData(finalSorting(isSameOrderByAsc ? 'desc' : 'asc', property))
  }

  const finalSorting = (order, orderBy) => {
    return clientData.sort((a, b) => getComparator(b, a, order, orderBy))
  }

  const filteredData = clientTypeFilter
    ? clientTypeFilter.label === 'All'
      ? clientData
      : clientData.filter((client) => {
          return (
            client.clientType.toLowerCase() ===
            clientTypeFilter.label.toLowerCase()
          )
        })
    : clientData

  const searchedData = filteredData.filter((client) => {
    return client.clientName.toLowerCase().includes(searchValue.toLowerCase())
  })

  const handleOptionsClick = (event, data) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(data)
  }

  const handleMigrateClick = async(event, data) => {
    setShowLoader(true)
    const features = await getOrgFeatures(selectedRow.clientName);
    setFeaturesSelected(features);
    setIsMigrateDialogOpen(true)
    setShowLoader(false)
  }


  const handleDashboardSubscription = (dashboardSubscribed) => {
    setDashboardSubscribed(dashboardSubscribed)
    setDialogOpen(true)
  }

  const adminPanelHeadCells = [
    {
      id: 'clientName',
      numeric: false,
      disablePadding: true,
      label: 'Client Name',
      width: '100px',
      sorting: false,
      func: (data) => (
        <TableCell sx={{ alignItems: 'left', width: '150px' }}>
          {data}
        </TableCell>
      ),
    },
    {
      id: 'clientType',
      numeric: false,
      disablePadding: false,
      label: 'Client Type',
      sorting: false,
      func: (data) => (
        <TableCell>
          {data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()}
        </TableCell>
      ),
    },
    {
      id: 'startDate',
      numeric: true,
      disablePadding: false,
      label: 'Start Date',
      sorting: false,
      func: (date) => (
        <TableCell>
          {moment(date).isValid() ? moment(date).format('DD-MMM-YYYY') : '-'}
        </TableCell>
      ),
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'End Date',
      width: '83px',
      sorting: false,
      func: (date) => (
        <TableCell>{moment(date).format('DD-MMM-YYYY')}</TableCell>
      ),
    },
    {
      id: 'noOfUsers',
      numeric: false,
      disablePadding: false,
      label: 'No. of Users Permitted',
      width: '85px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '98px',
      sorting: false,
      func: (data) => (
        <TableCell>
          {data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()}
          <Circle
            sx={{
              width: '15px',
              marginLeft: data ? '15px' : '5px',
              color: ({ palette: { colors } }) =>
                colors[
                  (data === 'ACTIVE'
                    ? 'EMERGING'
                    : data === 'DRAFT'
                    ? 'DORMANT'
                    : 'DECLINING'
                  ).toLowerCase()
                ],
              verticalAlign: 'middle',
            }}
          />
        </TableCell>
      ),
    },
    {
      id: 'dashboardSubscribed',
      numeric: false,
      disablePadding: false,
      label: 'No. of Dashboard Subscribed',
      width: '87px',
      sorting: false,
      func: (data) => {
        return (
          <TableCell
            onClick={() => {
              setSelectedRow(data)
              return handleDashboardSubscription(
                data.dashboardSubscribed.dashboardsSubscribed,
              )
            }}
          >
            {data.dashboardSubscribed.noOfDashboards}
            <OpenInFullIcon
              sx={{
                color: ({ palette }) => palette.icon,
                position: 'relative',
                top: '7px',
              }}
            />
          </TableCell>
        )
      },
    },
    {
      id: 'options',
      numeric: false,
      disablePadding: false,
      label: 'Options',
      sorting: false,
      func: (data) => {
        return (
          <TableCell
            onClick={(event) => {
              handleOptionsClick(event, data)
            }}
          >
            <MoreVertIcon
              sx={{
                color: ({ palette }) => palette.icon,
                position: 'relative',
                top: '7px',
              }}
            />
          </TableCell>
        )
      },
    },
  ]

  useEffect(() => {
    if (!clientTypeFilter) {
      setClientTypeFilter({ label: 'All', key: 0 })
    }
  }, [clientTypeFilter])

  const getClientData = () => {
    setShowLoader(true)
    axios
      .get(NetworkingUtil.userAccessManagement())
      .then((res) => {
        setClientDetails(res.data.data)
        setShowLoader(false)
        function renameKeys(obj, newKeys) {
          const keyValues = Object.keys(obj).map((key) => {
            const newKey = newKeys[key] || key
            return { [newKey]: obj[key] }
          })
          return Object.assign({}, ...keyValues)
        }
        const newKeys = {
          name: 'clientName',
          dashboard_activation_date: 'startDate',
          dashboard_expiration_date: 'endDate',
          max_authorised_users: 'noOfUsers',
          client_type: 'clientType',
          id: 'clientId',
        }
        const camelCaseData = res.data.data.map((client, index) => {
          const modifiedObj = renameKeys(client, newKeys)
          return {
            ...modifiedObj,
            dashboardSubscribed: {
              noOfDashboards: client.dashboards_count,
              dashboardsSubscribed: client.dashboards,
            },
            status: client.status,
            options: true,
          }
        })
        setClientData(camelCaseData)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    const totalClients = clientData?.length
    const trialClient = clientData.filter((client) => {
      return client.clientType === 'TRIAL'
    })?.length
    const licensedClient = clientData.filter((client) => {
      return client.clientType === 'LICENSED'
    })?.length
    const noOfClients = {
      totalClients: totalClients,
      trialClient: trialClient,
      licensedClient: licensedClient,
    }
    setClientCount(noOfClients)
  }, [clientData])

  useEffect(() => {
    getClientData()
  }, [])

  const postData = async (filtered) => {
    const request = NetworkingUtil.downloadClientDetails()
    const details = {
      clients: filtered,
    }
    const excelFileName = `Client Details`
    await axios
      .post(request, details, { responseType: 'blob' })
      .then(({ data }) => {
        saveAs(data, excelFileName) //eslint-disable-line
      })
  }

  const handleDownloadExcel = () => {
    let filtered = []
    if (clientTypeFilter.label === 'Trial') {
      filtered = clientDetail.filter((n) => n.client_type === 'TRIAL')
    } else if (clientTypeFilter.label === 'Licensed') {
      filtered = clientDetail.filter((n) => n.client_type === 'LICENSED')
    } else {
      filtered = clientDetail
    }
    filtered = searchValue
      ? filtered.filter((client) => {
          return client.name?.toLowerCase().includes(searchValue.toLowerCase())
        })
      : filtered
    postData(filtered)
  }

  useEffect(() => {
    getClientData()
  }, [clientModification])

  return (
    <Grid sx={{ width: '100%' }}>
      {showLoader && <OverlayLoader />}
      {isManager ? (
        <Box>
          <Box>
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label="UAM tabs"
              sx={{
                '& .MuiButtonBase-root.MuiTab-root': { boxShadow: 'none' },
              }}
            >
              <Tab label="Client Management" {...a11yProps(0)} />
              <Tab label="User Management" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            {!clientModification ? (
              <AdminUI
                handleDownloadExcel={handleDownloadExcel}
                clientTypeFilter={clientTypeFilter}
                handleAddUser={handleAddUser}
                orderBy={orderBy}
                order={order}
                searchedData={searchedData}
                dashboardsSubscribed={dashboardsSubscribed}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                handleClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                handleRequestSort={handleRequestSort}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                clientTypeChanged={clientTypeChanged}
                getComparator={getComparator}
                id={id}
                GenericTableHead={GenericTableHead}
                GenericTableCell={GenericTableCell}
                adminPanelHeadCells={adminPanelHeadCells}
                deactivateDialogOpen={deactivateDialogOpen}
                setDeactivateDialogOpen={setDeactivateDialogOpen}
                handleDeactivateDialog={handleDeactivateDialog}
                clientDeactivateSuccess={clientDeactivateSuccess}
                handleEditUser={handleEditUser}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                deleteClientDailog={deleteClientDailog}
                setDeleteClientDialog={setDeleteClientDialog}
                handleDeleteClient={handleDeleteClient}
                clientCount={clientCount}
                showLoader={showLoader}
                isMigrateDialogOpen={isMigrateDialogOpen}
                setIsMigrateDialogOpen={setIsMigrateDialogOpen}
                migrateOrgToNewUI={migrateOrgToNewUI}
                handleCheckboxChange={handleCheckboxChange}
                featuresSelected={featuresSelected} 
                setFeaturesSelected={setFeaturesSelected}
                handleMigrationDialogClose={handleMigrationDialogClose}
                getOrgFeatures={getOrgFeatures}
                handleMigrateClick={handleMigrateClick}
                featureList={featureList}
              />
            ) : (
              <ContainerTab
                disabledTab={disabledTab}
                setDisablesTab={setDisablesTab}
                editInfo={editInfo}
                edit={edit}
                setClientModification={setClientModification}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setAnchorEl={setAnchorEl}
                setEdit={setEdit}
                getClientData={getClientData}
              />
            )}
          </TabPanel>
          <Typography></Typography>
          <TabPanel value={tab} index={1}>
            <UserManagement />
          </TabPanel>
          <Typography></Typography>
        </Box>
      ) : (
        <ErrorPage text="You are not authorized to view this page" />
      )}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    isManager:
      state.user.userProfile &&
      state.user.userProfile.user &&
      state.user.userProfile.user.is_manager,
  }
}

export default connect(mapStateToProps, null)(Admin)
