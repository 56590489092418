import React from 'react'
import classes from './sidebar.css'
import { Link } from 'react-router-dom'

class Sidebar extends React.Component {
  render () {
    return (
      <div className={'col-md-2 pad ' + classes.sidebar} id="navigation">
        <div className={'text-center ' + classes.logo}>
          <h4><i className="fa fa-user" aria-hidden="true"></i> Super Admin <i className="fa fa-caret-down"
            aria-hidden="true"></i></h4>
        </div>
        <div className={classes.navi}>
          <ul>
            {this.props.loggedInUserRole === 'ROLE_APP_ADMIN'
              ? <li className={classes.active}><Link to="/organization"><i className="fa fa-users" aria-hidden="true"></i>Organization</Link></li> : null}
            {this.props.loggedInUserRole === 'ROLE_ORG_ADMIN'
              ? <li className={classes.active}><Link to="/build"><i className="fa fa-users" aria-hidden="true"></i>Build Winner</Link></li> : null
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
