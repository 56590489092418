import React from "react";
import PropTypes from "prop-types";

import "./ButtonCard.scss";

const ButtonCard = ({
  className = "",
  imgClassName = "",
  name,
  img,
  imgAlt,
  onClick,
  leftImage,
}) => {
  return (
    <div className={`button-card ${className}`} onClick={onClick} tabIndex="0" role="button">
      {img ? <img className={imgClassName} src={img} alt={imgAlt} /> : null}
      <div className="button-card__content">
        {leftImage && <img className="button-card__left-img" src={leftImage} alt="img"/>}
        <span className="sub-title--14 ls--0">{name}</span>
      </div>
    </div>
  );
};

ButtonCard.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonCard;
