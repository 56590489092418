import React from 'react'
import PropTypes from 'prop-types'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { getSignedFloat } from '../../../store/utility'

const GrowthChip = ({ title, growthValue, sx = {} }) => {
  const growthColor = growthValue >= 0 ? 'success.dark' : 'error.dark'
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'grey.50',
        borderRadius: '4px',
        pl: 1,
        gap: 0.5,
        ...sx,
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="subtitle3" sx={{ color: growthColor }}>
        {getSignedFloat(growthValue, 2)}%
      </Typography>
      {growthValue >= 0 ? (
        <ArrowDropUpRoundedIcon sx={{ color: growthColor }} />
      ) : (
        <ArrowDropDownRoundedIcon sx={{ color: growthColor }} />
      )}
    </Box>
  )
}

GrowthChip.propTypes = {
  title: PropTypes.string.isRequired,
  growthValue: PropTypes.number.isRequired,
}

export default GrowthChip
