import React, { useState, useEffect } from 'react'
import { useSingleSnackBar } from '../../hooks'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  TableCell,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import CloseIcon from '@mui/icons-material/Close'

import {
  addToFavoriteTrendPillar,
  fetchAddedFavChecktrendPillar,
  removeFromFavoritesTrendPillar,
} from '../../store/actions/dashboardActions'
// import { getTrendDetails } from '../../store/actions/createPillarActions'

const RenderActionItem = ({
  pillar,
  setLoading,
  loading,
  lensSelected,
  handleDelete,
  handleEdit,
  handleDownload,
}) => {
  const isTrendPillarFavoriteAllowed = useSelector(
    (state) => state.myDashboard.isTrendPillarFavoriteAllowed,
  )
  const addedFavTrendPillar = useSelector(
    (state) => state.myDashboard.addedFavTrendPillar,
  )
  // const trendDetails = useSelector((state) => state.trendPillar.trendsDetails)

  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const open = Boolean(anchorEl)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    if (pillar.level === 1) {
      setLoader(true)
      dispatch(
        fetchAddedFavChecktrendPillar(
          pillar.id,
          lensSelected,
          pillar.projectId,
        ),
      ).then(() => {
        setLoader(false)
      })
    }
  }
  const handleClickAction = (event) => {
    event.stopPropagation()
  }

  const handleClickEdit = () => {
    // if (!pillar.trendDetails.length)
    //   dispatch(getTrendDetails(lensSelected, pillar.id))
    handleEdit(
      pillar.pillarName,
      pillar.country,
      pillar.category,
      pillar.trendDetails,
      pillar.id,
    )
  }

  const handleAddToFavoriteClick = (
    id,
    lensSelected,
    setLoading,
    projectId,
  ) => {
    if (!loading) {
      if (addedFavTrendPillar) {
        dispatch(
          removeFromFavoritesTrendPillar(
            id,
            lensSelected,
            setLoading,
            projectId,
          ),
        )
      } else {
        setLoading(true)

        dispatch(addToFavoriteTrendPillar(id, lensSelected, projectId)).then(
          (res) => {
            if (res === 403) {
              setLoading(false)
              const action = () => (
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => removeSnackBar()}
                >
                  <CloseIcon fontSize="small" color="action" />
                </IconButton>
              )
              showSnackBar(
                'Only 10 Trend Pillars can be Favorited',
                'info',
                action,
              )
            } else {
              dispatch(
                fetchAddedFavChecktrendPillar(id, lensSelected, projectId),
              ).then(() => {
                setLoading(false)
              })
            }
          },
        )
      }
    }
  }

  useEffect(() => {
    return () => {
      removeSnackBar()
    }
  }, [])

  return (
    <TableCell
      onClick={handleClickAction}
      sx={{ borderBottom: 'none !important' }}
    >
      <MoreHorizIcon
        sx={{
          color: ({ palette }) => palette.icon,
        }}
        onClick={handleClick}
      />
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 170,
          },
        }}
      >
        {pillar.level === 1 && (
          <MenuItem
            sx={{
              fontSize: '14px !important',
              color: ({ palette }) => palette.primary.main,
            }}
            onClick={handleClickEdit}
          >
            {' '}
            <EditOutlinedIcon
              sx={{
                color: ({ palette }) => `${palette.primary.main} !important`,
              }}
            />
            Edit
          </MenuItem>
        )}
        {pillar.level === 1 && (
          <MenuItem
            onClick={() =>
              handleDownload(pillar.id, pillar.country, pillar.category)
            }
            sx={{
              fontSize: '14px !important',
              color: ({ palette }) => palette.primary.main,
            }}
          >
            {' '}
            <FileDownloadOutlinedIcon
              sx={{
                color: ({ palette }) => `${palette.primary.main} !important`,
              }}
            />
            Download
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            handleDelete(
              pillar.id,
              pillar.pillarName,
              pillar.country,
              pillar.category,
            )
          }
          sx={{
            fontSize: '14px !important',
            marginBottom: '0px !important',
            color: ({ palette }) => `${palette.error.dark} !important`,
          }}
        >
          <DeleteOutlinedIcon
            sx={{
              color: ({ palette }) => `${palette.error.dark} !important`,
            }}
          />
          Delete
        </MenuItem>
        {!loader && isTrendPillarFavoriteAllowed && pillar.level === 1 && (
          <Tooltip
            title={`Add${
              addedFavTrendPillar ? 'ed' : ''
            } to Trend Pillar Section under Favorites in My Workspace`}
          >
            <MenuItem
              onClick={() =>
                handleAddToFavoriteClick(
                  pillar.id,
                  lensSelected,
                  setLoading,
                  pillar.projectId,
                )
              }
            >
              {addedFavTrendPillar ? (
                <StarIcon
                  sx={{
                    color: ({ palette }) =>
                      `${palette.primary.main} !important`,
                    marginRight: '5px',
                  }}
                />
              ) : (
                <StarBorderOutlinedIcon
                  sx={{
                    marginRight: '6px',
                    color: ({ palette }) => palette.text.secondary,
                  }}
                />
              )}

              <Typography
                sx={{
                  color: ({ palette }) => `${palette.primary.main} !important`,
                  fontSize: '14px !important',
                }}
              >
                Add{addedFavTrendPillar && 'ed'} to Favorites
              </Typography>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
    </TableCell>
  )
}

export default RenderActionItem
