import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Stack, Typography, Button, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { format } from 'date-fns'

const TradeMedia = ({
  title,
  content,
  description,
  date,
  url,
  platform,
  logConsumerQuotesClicked,
  type,
}) => {
  const clickHandler = () => {
    window.open(url, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    }
  }
  return (
    <Paper
      variant="outlined"
      component={Stack}
      spacing={1.5}
      p={2}
      sx={{
        height: '365px',
        width: '100%',
      }}
    >
      {title && (
        <Tooltip title={title} disableHoverListener={title.length < 30}>
          <Typography variant="subtitle1">
            {title.length > 30 ? title.slice(0, 30).trim() + '...' : title}
          </Typography>
        </Tooltip>
      )}
      <Stack spacing={1}>
        <Typography variant="subtitle2">{description}</Typography>
        <Box>
          <Button onClick={clickHandler}>{platform}</Button>
        </Box>
      </Stack>
      <Box
        sx={{
          overflow: 'auto',
        }}
      >
        <Typography variant="subtitle2">{content}</Typography>
      </Box>
      <Box
        sx={{
          marginTop: 'auto',
        }}
      >
        <Typography variant="caption" color="textSecondary">
          {format(new Date(date), 'MMM dd, yyyy')}
        </Typography>
      </Box>
    </Paper>
  )
}

TradeMedia.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default TradeMedia
