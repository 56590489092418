import React, { useState, useEffect, useMemo } from 'react'
import { useLoader, useSingleSnackBar } from '../../hooks'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Checkbox,
  Grid,
  Box,
  Stack,
  IconButton,
  Typography,
  ToggleButton,
  Breadcrumbs,
  Button,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ToggleButtonGroup from '../../shared/components/ToggleButtonGroup'
import {
  themeHeadCells,
  ingProdHeadCells,
  trendBucketIngProdSubHeadCells,
  trendBucketThemeSubHeadCells,
} from './tableColumnsConst'
import SingleSelect from '../../shared/components/SingleSelect'
import * as actions from './../../store/actions/index'
import OverlayLoader from '../../shared/components/OverlayLoader'
import DeletePopUp from '../../shared/components/PopUp/DeletePopUp'
import TrendEdit from '../../shared/components/TrendEdit'
import CreateImportDialogContainer from '../../shared/components/TrendBucket/partials/CreateImportDialogContainer'
import formatNumber from '../../shared/utils/formatNumber'
import {
  pillarBasicDetails,
  mergePillar,
  getTrendDetails,
} from './../../store/actions/createPillarActions'
import {
  LENSES,
  pillarNameExistsError,
  pillarNameLimitError,
  pillarNameRequiredError,
  WHEEL_GENERATE_PROGRESS_MESSAGE,
} from '../../shared/utils/constants'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import DiscardBucketPopUp from './../../../src/shared/components/PopUp/DiscardBucketPopUp'
import { Item } from './styles'
import ErrorPage from '../../pages/ErrorPage'
import { amplify, getQueryParams, getCapitalzedText } from '../../store/utility'
import MergeTrendPillarPopUp from './MergeTrendPillarPopUp'
import SearchBox from '../SearchBox/SearchBox'
import CloseIcon from '@mui/icons-material/Close'
import TooltipOnOverflow from '../Tooltip/TooltipOnOverflow'
import RenderActionItem from './RenderActionItem'
import MergePillarTreeView from './TreeView/MergePillarTreeView'
import _ from 'lodash'
import TextOverflowTooltip from '../../shared/components/TextOverflowTooltip'
import CustomisedDropdownButton from '../../shared/components/CustomisedDropdownButton/CustomisedDropdownButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CreateWheelPage from '../../shared/components/WheelView/CreateWheelPage'
import CircularLoaderWithText from '../../shared/components/CircularLoaderWithText/CircularLoaderWithText'

const defaultSelection = {
  id: -1,
  label: 'All',
}

const L1_WHEEL_PILLAR_LIMIT = 10
const THEME_DEFAULT_COUNT = 3
const RELATED_TREND_DEFAULT_COUNT = 4

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const getUniquePillarLevels = (pillars) => {
      const uniquePillars = [
        ...new Set(pillars.map((pillar) => `L${pillar.level}`)),
      ]
      return uniquePillars.length ? uniquePillars : 'NULL'
    }
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.props.email,
    }
    switch (ampiEvent) {
      case 'Clicked_Wheel_View':
        amplitudeUserData = {
          ...amplitudeUserData,
        }
        break
      case 'Clicked_Generate_Wheel':
        const { wheelDetail } = ampiUserData
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: wheelDetail.country,
          Category_Name: wheelDetail.name,
          Number_Of_Trend_Pillars: wheelDetail.children.length,
        }
        
        break
      case 'Clicked_Customize_Wheel':
        const { wheelDetails, noOfTrend, noOfRelatedTrends } = ampiUserData
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: wheelDetails.country,
          Category_Name: wheelDetails.name,
          Number_of_Trend_Pillars: wheelDetails.children.length,
          Themes_in_Layer_2: noOfTrend,
          Related_Ingredients_in_Layer_3: noOfRelatedTrends,
        }
        break
      case 'Clicked_Save_Wheel':
        const { wheelName, filteredWheelData, isCustomised } = ampiUserData
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: filteredWheelData.country,
          Category_Name: filteredWheelData.name,
          Number_of_Trend_Pillars: filteredWheelData.children.length,
          Customize: isCustomised ? 'Yes' : 'No',
          Wheel_Name: wheelName,
        }
        break
      case 'Clicked_TP_Delete_Pillar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lensSelected,
          Option: ampiUserData.option,
          Pillar_Name: ampiUserData.selectedPillarInfo.pillarName,
          Country_Name: ampiUserData.selectedPillarInfo.country,
          Category_Name: ampiUserData.selectedPillarInfo.category,
        }
        break
      case 'Clicked_TP_Sort_PillarBar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Sort:
            (ampiUserData.lens !== 'Theme'
              ? ingProdHeadCells
              : themeHeadCells
            ).find((data) => data.id === ampiUserData.sort)?.label ||
            ampiUserData.sort,
        }
        break
      case 'Clicked_TP_Sort_TrendBar':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Sort:
            (ampiUserData.lens !== 'Theme'
              ? trendBucketIngProdSubHeadCells
              : trendBucketThemeSubHeadCells
            ).find((data) => data.id === ampiUserData.sort)?.label ||
            ampiUserData.sort,
        }
        break
      case 'Clicked_TP_Lens':
        amplitudeUserData = {
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          ...amplitudeUserData,
        }
        break
      case 'Clicked_TP_Dashboard':
        amplitudeUserData = {
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          ...amplitudeUserData,
        }
        break
      case 'Clicked_TP_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Search_Term: ampiUserData.searchTerm,
        }
        break
      case 'Clicked_TP_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Pillar_Name: ampiUserData.pillarName,
          Action: ampiUserData.action,
        }
        break
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Redirected_From: ampiUserData.redirectedFrom,
        }
        break
      case 'Clicked_TP_Info_Button':
        amplitudeUserData = {
          ...amplitudeUserData,
          Info_Button: ampiUserData.columnName,
          Type: ampiUserData.type,
        }
        break
      case 'Clicked_TP_MergePillar':
        amplitudeUserData = {
          ...amplitudeUserData,
        }
        break
      case 'Clicked_TP_MergePillar_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Pillar_Level_Selected: getUniquePillarLevels(
            ampiUserData.slectedCheckboxData,
          ),
        }
        break
      case 'Clicked_TP_MergePillar_Action_Merge':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Lens: ampiUserData.lens,
          Pillar_Level: ampiUserData.slectedCheckboxData.find(
            (pillar) => pillar.level === 2,
          )
            ? 'L3'
            : 'L2',
        }
        break
      case 'Clicked_TP_Pillar_Level':
        const pillar = ampiUserData.data[ampiUserData.pillarId]
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: pillar.country,
          Category_Name: pillar.category,
          Lens: ampiUserData.lens,
          Pillar_Level: `L${pillar.level}`,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Trend Bucket Table')
  }
}
const tableCellWidth = (pillarDepth, showCheckBox) => {
  if (pillarDepth === 0) {
    return showCheckBox ? '50px' : '90px !important'
  } else if (pillarDepth === 1) {
    return '70px'
  } else {
    return '50px'
  }
}

const FIXED_WIDTH = '540px'
const VARIABLE_WIDTH_COLUMNS = 7
const MIN_COL_WIDTH = '100px'
const EXTRA_SPACE = '43px'

const getColWidth = (pillarDepth) => {
  if (!_.isNil(pillarDepth)) {
    const paddingLeft = `${pillarDepth * 20}px`
    const rowWidth = `calc(100% + ${paddingLeft})`
    const variableWidth = `calc(${rowWidth} - ${FIXED_WIDTH})`
    return `calc(${variableWidth} / ${VARIABLE_WIDTH_COLUMNS})`
  }
  const variableWidth = `calc(100% - calc(${FIXED_WIDTH} + ${EXTRA_SPACE}))`
  return `calc(${variableWidth} / ${VARIABLE_WIDTH_COLUMNS})`
}

const TrendPillar = (props) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('pillarName')
  const [subTableOrder, setSubTableOrder] = useState('asc')
  const [subTableOrderBy, setSubTableOrderBy] = useState('trend_Name')
  const [data, setData] = useState(props.trendPillarList)
  const [lensSelected, setLensSelected] = useState(
    getQueryParams({ location }).get('lensSelected') || LENSES[0],
  )
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useLoader()
  const [deletePillarId, setDeletePillarId] = useState(null)
  const [countrySelected, setCountrySelected] = useState(defaultSelection)
  const [categorySelected, setCategorySelected] = useState(defaultSelection)
  const [showCreatPillarDialog, setShowCreatPillarDialog] = useState(false)
  const [showImportPillarDialog, setShowImportPillarDialog] = useState(false)
  const [popUpTab, setPopUpTab] = useState('')
  const [slectedCheckboxData, setSlectedCheckboxData] = useState([])
  const [popUp, setPopUp] = useState(false)
  const [bucketName, setBucketName] = useState('')
  const [showCheckBox, setShowCheckBox] = useState(false)
  const [mergePillarPopupError, setMergePillarPopupError] = useState(false)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const [expandedNodes, setExpandedNodes] = useState([])
  const [selectedWheelView, setSelectedWheelView] = useState(null)
  const [isWheelBeingCreated, setIsWheelBeingCreated] = useState(false)
  const [wheelData, setWheelData] = useState(null)
  const [postWheel, setPostWheel] = useState(null)
  const [customLoaderWithLabel, setCustomLoaderWithLabel] = useState(false)
  const [noOfThemes, setNoOfThemes] = useState(THEME_DEFAULT_COUNT)
  const [noOfRelatedTrends, setNoOfRelatedTrends] = useState(
    RELATED_TREND_DEFAULT_COUNT,
  )

  const history = useHistory()
  const query = getQueryParams()

  const pillars = data
  let filteredPillars = pillars

  const selectedCountryForMerging = slectedCheckboxData[0]?.country
  const selectedCategoryForMerging = slectedCheckboxData[0]?.category

  const breadcrumbs = [
    <Link
      variant="inherit"
      key="1"
      to="/mui/main-system"
      style={{ color: '#8A97A7', marginLeft: '10px', fontSize: '14px' }}
    >
      Explore Trends
    </Link>,
    <Typography key="2" variant="subtitle2">
      Trend Pillar
    </Typography>,
  ]

  const logButtonClick = () => {
    const ampiEvent = 'Clicked_Wheel_View'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logWheelGenerated = (wheelDetail) => {
    const ampiEvent = 'Clicked_Generate_Wheel'
    const ampiUserData = {
      props,
      wheelDetail,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logWheelCustomization = (
    wheelDetails,
    noOfTrend,
    noOfRelatedTrends,
  ) => {
    const ampiEvent = 'Clicked_Customize_Wheel'
    const ampiUserData = {
      props,
      wheelDetails,
      noOfTrend,
      noOfRelatedTrends,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logSaveWheel = (wheelName, filteredWheelData, isCustomised) => {
    const ampiEvent = 'Clicked_Save_Wheel'
    const ampiUserData = {
      props,
      wheelName,
      filteredWheelData,
      isCustomised,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logMergePillarClicked = () => {
    const ampiEvent = 'Clicked_TP_MergePillar'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logMergePillarActionClicked = (action) => {
    const ampiEvent = 'Clicked_TP_MergePillar_Action'
    const ampiUserData = {
      props,
      action,
      slectedCheckboxData,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logMergePillarPopupActionClicked = (action) => {
    const ampiEvent = 'Clicked_TP_MergePillar_Action_Merge'
    const ampiUserData = {
      props,
      action,
      lens: lensSelected,
      country: selectedCountryForMerging,
      category: selectedCategoryForMerging,
      slectedCheckboxData,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logAccordianOpen = (pillarId) => {
    const ampiEvent = 'Clicked_TP_Pillar_Level'
    const ampiUserData = {
      props,
      lens: lensSelected,
      pillarId,
      data,
    }
    amp(ampiEvent, ampiUserData)
  }

  const action = () => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => removeSnackBar()}
    >
      <CloseIcon fontSize="small" color="action" />
    </IconButton>
  )

  const handleSnackBar = (message, status) => {
    showSnackBar(message, status, action)
  }

  const pillarKeys = Object.keys(pillars)
  let allCountries = []
  pillarKeys.forEach((key) => {
    const pillar = pillars[key]
    const countryCategory = {
      country: pillar.country,
      category: pillar.category,
    }
    allCountries = [...allCountries, countryCategory]
  })

  const uniqueCountries = [...new Set(allCountries.map((item) => item.country))]

  const countries = uniqueCountries.map((country, index) => {
    return { id: index, label: country }
  })
  if (countries.length > 0 && countries[0]?.id !== -1) {
    countries.unshift(defaultSelection)
  }

  const uniqueCategories = [
    ...new Set(allCountries.map((item) => item.category)),
  ]
  const categories = uniqueCategories.map((category, index) => {
    return { id: index, label: category }
  })

  if (categories.length > 0 && categories[0]?.id !== -1) {
    categories.unshift(defaultSelection)
  }

  if (categorySelected?.id === -1 && countrySelected?.id === -1) {
    filteredPillars = pillars
  } else if (countrySelected?.id !== -1 && categorySelected?.id !== -1) {
    const pillarKeys = Object.keys(data)
    let filteredData = {}
    pillarKeys.forEach((key) => {
      const pillar = data[key]
      if (
        pillar.country === countrySelected?.label &&
        pillar.category === categorySelected?.label
      ) {
        filteredData = { ...filteredData, [pillar.id]: pillar }
      }
    })
    filteredPillars = filteredData
  } else if (countrySelected?.id !== -1) {
    const pillarKeys = Object.keys(data)
    let filteredData = {}
    pillarKeys.forEach((key) => {
      const pillar = data[key]
      if (pillar.country === countrySelected?.label) {
        filteredData = { ...filteredData, [pillar.id]: pillar }
      }
    })
    filteredPillars = filteredData
  } else if (categorySelected?.id !== -1) {
    const pillarKeys = Object.keys(data)
    let filteredData = {}
    pillarKeys.forEach((key) => {
      const pillar = data[key]
      if (pillar.category === categorySelected?.label) {
        filteredData = { ...filteredData, [pillar.id]: pillar }
      }
    })
    filteredPillars = filteredData
  }

  if (showCheckBox) {
    const pillarKeys = Object.keys(filteredPillars)
    let filteredData = {}
    if (selectedWheelView) {
      if (slectedCheckboxData[0]?.level) {
        if (slectedCheckboxData[0].level === 2) {
          filteredData = { [slectedCheckboxData[0].id]: slectedCheckboxData[0] }
        } else {
          pillarKeys.forEach((key) => {
            const pillar = filteredPillars[key]
            if (pillar.level !== 3 && pillar.level !== 2) {
              filteredData = { ...filteredData, [pillar.id]: pillar }
            }
          })
        }
      } else {
        pillarKeys.forEach((key) => {
          const pillar = filteredPillars[key]
          if (pillar.level !== 3) {
            filteredData = { ...filteredData, [pillar.id]: pillar }
          }
        })
      }
    } else {
      pillarKeys.forEach((key) => {
        const pillar = filteredPillars[key]
        if (pillar.level !== 3) {
          filteredData = { ...filteredData, [pillar.id]: pillar }
        }
      })
    }

    filteredPillars = filteredData
  }

  useEffect(() => {
    if (slectedCheckboxData.length) {
      setCountrySelected({ id: 0, label: slectedCheckboxData[0].country })
      setCategorySelected({ id: 0, label: slectedCheckboxData[0].category })
    } else {
      setCountrySelected({ id: -1, label: 'All' })
      setCategorySelected({ id: -1, label: 'All' })
    }
  }, [slectedCheckboxData])

  const addLens = (pillarArray, lens) => {
    return pillarArray.map((pillar) => {
      return { ...pillar, lens: lens }
    })
  }

  const allPillarsList = useMemo(() => {
    let allPillars = []
    if (props.importPillarList[0]) {
      const {
        ingredient_pillars: ingPillars,
        theme_pillars: themePillars,
        product_pillars: prodPillars,
      } = props.importPillarList[0]
      if (ingPillars?.length) {
        const ingredientPillarList = addLens([...ingPillars], 'ingredient')
        allPillars = [...ingredientPillarList]
      }
      if (themePillars?.length) {
        const themePillarList = addLens([...themePillars], 'theme')
        allPillars = [...allPillars, ...themePillarList]
      }
      if (prodPillars?.length) {
        const productPillarList = addLens([...prodPillars], 'product')
        allPillars = [...allPillars, ...productPillarList]
      }
    }
    return allPillars
  }, [props.importPillarList[0]])

  const handleTrendDetailsAddition = (expandedNodes, filteredData) => {
    const newPillarList = JSON.parse(JSON.stringify(filteredData))

    if (expandedNodes.length) {
      const currPillar = newPillarList[expandedNodes[0]] // Assumption newly expanded will always get pushed in front of array
      if (
        currPillar &&
        !currPillar.trendDetails.length &&
        currPillar.level === 1
      ) {
        setLoading(true)

        props
          .getTrendDetails(lensSelected.toLowerCase(), currPillar.id)
          .then(() => {
            setLoading(false)
          })
      }
    }
  }

  const fetchTrendPillars = () => {
    setLoading(true)
    props.fetchTrendPillarList(lensSelected.toLowerCase()).then(() => {
      setLoading(false)
    })
  }

  const refetchTrendPillarsList = (lens) => {
    if (lens !== lensSelected) {
      setLensSelected(lens)
    } else {
      fetchTrendPillars()
    }
  }

  const mergePillarNameCheck = (pillarName) => {
    if (pillarName.trim().length > 64) {
      setMergePillarPopupError(pillarNameLimitError)
      return true
    } else if (pillarName === '') {
      setMergePillarPopupError(pillarNameRequiredError)
      return true
    } else if (
      allPillarsList.some((pillar) => {
        return (
          pillar.name === (pillarName ? pillarName.trim() : pillarName) &&
          pillar.user === props.userId &&
          pillar.country === countrySelected?.label &&
          pillar.category === categorySelected?.label &&
          pillar.lens === lensSelected?.toLowerCase()
        )
      })
    ) {
      setMergePillarPopupError(pillarNameExistsError)

      return true
    } else {
      setMergePillarPopupError('')
      return false
    }
  }

  const handleBucketNameChange = (e) => {
    const value = e.target.value.trimStart()
    mergePillarNameCheck(value)
    setBucketName(value && getCapitalzedText(value))
  }

  const handleSubmitClick = (e) => {
    const pillarIDs = slectedCheckboxData.map((i) => i.id)
    let maxPillarLevel = 0
    slectedCheckboxData.forEach((pillar) => {
      if (pillar.level > maxPillarLevel) {
        maxPillarLevel = pillar.level
      }
    })
    const mergePillarData = {
      name: bucketName,
      pillar_ids: pillarIDs,
      project_id: slectedCheckboxData[0].projectId,
    }

    if (!mergePillarNameCheck(bucketName)) {
      logMergePillarPopupActionClicked('Merge')
      props.mergePillar(lensSelected, mergePillarData).then((data) => {
        const snackBarMessage = `'L${maxPillarLevel +
          1} ${bucketName}' created successfully.`
        handleSnackBar(snackBarMessage, 'success')
        fetchTrendPillars()
      })
      setSlectedCheckboxData([])
      setPopUp(false)
      setShowCheckBox(false)
      setBucketName('')
    }
  }

  const handleEdit = (pillarName, country, category, trends, id) => {
    const ampiEvent = 'Clicked_TP_Action'
    const ampiUserData = {
      props,
      pillarName,
      country,
      category,
      lens: lensSelected,
      action: 'Edit',
    }
    amp(ampiEvent, ampiUserData)
    props.getTrendDetails(lensSelected, id)
    props.pillarBasicDetails(
      pillarName,
      country,
      category,
      lensSelected,
      trends,
      id,
    )
  }

  const handleSearch = (search) => {
    setSearchValue(search)
  }

  const handleDownload = (pillarId, country, category) => {
    const request = NetworkUtil.downloadTrendPillarTrends(
      lensSelected,
      pillarId,
    )
    const pillar = data[pillarId]
    const ampiEvent = 'Clicked_TP_Action'
    const ampiUserData = {
      props,
      pillarName: pillar?.pillarName,
      country,
      category,
      lens: lensSelected,
      action: 'Download',
    }
    amp(ampiEvent, ampiUserData)
    const fileName = `Trend Level Analysis_${pillar.pillarName}.xlsx`
    setLoading(true)
    axios
      .get(request, { responseType: 'blob' })
      .then(({ data }) => {
        window.saveAs(data, fileName)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error downlaoding trend pillar trends!')
        setLoading(false)
      })
  }

  useEffect(() => {
    if (props.pillarDetails[0]?.lens) {
      props.pillarBasicDetails(
        props.pillarDetails[0].trendPillarName,
        props.pillarDetails[0].country,
        props.pillarDetails[0].category,
        props.pillarDetails[0].lens,
        props.trendsDetails,
        props.pillarDetails[0].id,
      )
    }
  }, [props.trendsDetails])

  const handleSubTableSort = (property) => () => {
    const isAsc = subTableOrderBy === property && subTableOrder === 'asc'
    setSubTableOrder(isAsc ? 'desc' : 'asc')
    setSubTableOrderBy(property)
    const ampiEvent = 'Clicked_TP_Sort_TrendBar'
    const ampiUserData = {
      props,
      lens: lensSelected,
      country: countrySelected,
      category: categorySelected,
      sort: property,
    }
    amp(ampiEvent, ampiUserData)
  }

  const setImportMode = () => {
    const ampiEvent = `Clicked_TP_Import`
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
    if (
      (props.importPillarInfo[0] && props.importPillarInfo[0]?.lens) ||
      (props.pillarDetails[0] && props.pillarDetails[0]?.lens)
    ) {
      setPopUpTab('import')
      setShowCreatPillarDialog(false)
      setShowImportPillarDialog(false)
    } else {
      setPopUpTab('')
      setShowCreatPillarDialog(true)
      setShowImportPillarDialog(true)
    }
  }

  const handleShowDeleteWarning = (pillarId, pillarName, country, category) => {
    const ampiEvent = 'Clicked_TP_Action'
    const ampiUserData = {
      props,
      pillarName,
      country,
      category,
      lens: lensSelected,
      action: 'Delete',
    }
    amp(ampiEvent, ampiUserData)
    setDeletePillarId(pillarId)
  }

  const closeDeleteWarning = () => {
    setDeletePillarId(null)
  }

  const handleDelete = () => {
    const ampiEvent = `Clicked_TP_Delete_Pillar`
    const ampiUserData = {
      props,
      lensSelected: lensSelected,
      option: 'Delete',
      selectedPillarInfo: props.trendPillarList[deletePillarId],
    }
    amp(ampiEvent, ampiUserData)
    const data = {
      id: deletePillarId,
    }
    const pillarToBeDeleted = props.trendPillarList[deletePillarId]
    setLoading(true)
    props.deleteTrendPillar(data, lensSelected).then((success) => {
      if (success) {
        const snackBarMessage = `Trend Pillar 'L${pillarToBeDeleted.level} ${pillarToBeDeleted.pillarName}' has been deleted successfully.`
        handleSnackBar(snackBarMessage, 'info')
        fetchTrendPillars()
      }
      // Hacky patch, should remove the below else statement after doing the proper RSA and fix from backend.
      else {
        fetchTrendPillars()
      }
      setLoading(false)
      closeDeleteWarning()
    })
  }

  const handleToggleSelection = () => (event, toggle) => {
    if (toggle !== null) {
      query.set('lensSelected', toggle)
      history.replace(`?${query.toString()}`)
      setLensSelected(toggle)
      const ampiEvent = 'Clicked_TP_Lens'
      const ampiUserData = {
        props,
        lens: toggle,
        country: countrySelected,
        category: categorySelected,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  const handleCountryChange = (val) => {
    val ? setCountrySelected(val) : setCountrySelected(defaultSelection)
    const ampiEvent = 'Clicked_TP_Dashboard'
    const ampiUserData = {
      props,
      lens: lensSelected,
      country: val || defaultSelection,
      category: categorySelected,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleCategoryChange = (val) => {
    val ? setCategorySelected(val) : setCategorySelected(defaultSelection)
    const ampiEvent = 'Clicked_TP_Dashboard'
    const ampiUserData = {
      props,
      lens: lensSelected,
      country: countrySelected,
      category: val || defaultSelection,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleDiscardBucketCancel = () => {
    setPopUpTab('')
    setShowCreatPillarDialog(false)
    setShowImportPillarDialog(false)
  }

  const handleCreatePillar = () => {
    const ampiEvent = `Clicked_TP_Create`
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
    if (
      (props.importPillarInfo[0] && props.importPillarInfo[0]?.lens) ||
      (props.pillarDetails[0] && props.pillarDetails[0]?.lens)
    ) {
      setPopUpTab('create')
      setShowCreatPillarDialog(false)
      setShowImportPillarDialog(false)
    } else {
      setPopUpTab('')
      setShowCreatPillarDialog(true)
      setShowImportPillarDialog(false)
    }
  }

  const tooltipOpenHandle = ({ label }) => {
    const ampiEvent = 'Clicked_TP_Info_Button'
    const ampiUserData = {
      props,
      columnName: label,
      type: 'Pillar_Bar',
    }
    amp(ampiEvent, ampiUserData)
  }
  const subTooltipOpenHandle = ({ label }) => {
    const ampiEvent = 'Clicked_TP_Info_Button'
    const ampiUserData = {
      props,
      columnName: label,
      type: 'Trend_Bar',
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleDiscard = () => {
    setPopUpTab('')
    if (!popUpTab == 'merge') {
      props.clearPillarDetailsData()
    }
    if (popUpTab == 'import') {
      setShowImportPillarDialog(true)
      setShowCreatPillarDialog(true)
    }
    if (popUpTab == 'create') {
      setShowCreatPillarDialog(true)
      setShowImportPillarDialog(false)
    }
    if (popUpTab == 'merge') {
      setSlectedCheckboxData([])
      setShowCheckBox(false)
    }
  }

  const logSearch = () => {
    if (searchValue) {
      const ampiEvent = 'Clicked_TP_Search'
      const ampiUserData = {
        props,
        country: countrySelected,
        category: categorySelected,
        lens: lensSelected,
        searchTerm: searchValue,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  const getPopup = () => {
    setPopUpTab('merge')
    setShowCheckBox(true)
  }

  const pillarLimitCheck = (checkedPillars) => {
    let secondLevelPillarCount = 0
    let firstLevelPillarCount = 0
    checkedPillars.forEach((pillar) => {
      if (pillar.level === 2) {
        secondLevelPillarCount += 1
      } else if (pillar.level === 1) {
        firstLevelPillarCount += 1
      }
    })

    if (secondLevelPillarCount === 0) {
      if (firstLevelPillarCount > 6) {
        const snackBarMessage =
          'Pillar limit reached. Maximum 6 L1 pillars can be added.'
        handleSnackBar(snackBarMessage, 'info')
        return true
      }
    }

    if (secondLevelPillarCount + firstLevelPillarCount / 6 > 6) {
      const snackBarMessage =
        'Pillar limit reached. Maximum 6 L2 pillars or 36 L1 can be added.'
      handleSnackBar(snackBarMessage, 'info')
      return true
    }

    return false
  }

  const wheelLimitCheck = (checkedPillars) => {
    let secondLevelPillarCount = 0
    let firstLevelPillarCount = 0
    checkedPillars.forEach((pillar) => {
      if (pillar.level === 2) {
        secondLevelPillarCount += 1
      } else if (pillar.level === 1) {
        firstLevelPillarCount += 1
      }
    })

    if (secondLevelPillarCount === 0) {
      if (firstLevelPillarCount > L1_WHEEL_PILLAR_LIMIT) {
        const snackBarMessage = `Maximum ${L1_WHEEL_PILLAR_LIMIT} pillars can be added.`
        handleSnackBar(snackBarMessage, 'info')
        return true
      }
    }

    return false
  }

  const handleCheckBoxSelection = (e, pillar) => {
    if (slectedCheckboxData.find((data) => data.id === pillar.id)) {
      const filteredData = slectedCheckboxData.filter(
        (pillars) => pillars.id !== pillar.id,
      )
      setSlectedCheckboxData(filteredData)
    } else {
      const checkedPillars = [...slectedCheckboxData, pillar]
      if (selectedWheelView) {
        if (!wheelLimitCheck(checkedPillars)) {
          setSlectedCheckboxData(checkedPillars)
        }
      } else {
        if (!pillarLimitCheck(checkedPillars)) {
          setSlectedCheckboxData(checkedPillars)
        }
      }
    }
  }

  const handleCancelSelection = () => {
    setShowCheckBox(false)
    setSlectedCheckboxData([])
  }

  const handleClickMergePillar = (isMergePillarClicked) => {
    handleCancelSelection()
    if (isMergePillarClicked) {
      logMergePillarClicked()
    } else {
      logMergePillarActionClicked('Cancel')
    }
    setShowCheckBox(!showCheckBox)
    if (slectedCheckboxData?.length > 1) {
      getPopup()
    }
  }

  const handleMergePopupOpen = () => {
    logMergePillarActionClicked('Merge Selected Pillar')
    setPopUp(true)
  }

  useEffect(() => {
    props.fetchTrendPillarList(lensSelected.toLowerCase())
  }, [lensSelected])

  useEffect(() => {
    setCountrySelected(defaultSelection)
    setCategorySelected(defaultSelection)
  }, [lensSelected])

  const handleCountryCategoryAddition = () => {
    const keys = Object.keys(data)
    let newData = {}
    keys.forEach((key) => {
      const projectId = data[key].projectId
      const filteredProject = props.projectsList?.find(
        (project) => project._id === projectId,
      )
      const pillarDetails = {
        ...data[key],
        country: filteredProject._criteria,
        category: filteredProject._name,
      }
      newData = { ...newData, [key]: pillarDetails }
    })
    setData(newData)
  }

  const handleRowExpansion = (pillar) => {
    history.push({
      search: '',
    })
    const id = `${pillar.id}`
    if (expandedNodes.includes(id)) {
      const tempExpandedNodes = [...expandedNodes]
      const index = expandedNodes.indexOf(id)
      if (index > -1) {
        tempExpandedNodes.splice(index, 1)
      }
      setExpandedNodes(tempExpandedNodes)
    } else {
      setExpandedNodes([...expandedNodes, id])
    }
  }

  const handleWheelCreation = () => {
    setSelectedWheelView(true)
    setShowCheckBox(true)
  }

  const handleViewSavedWheels = () => {
    history.push('/mui/trend-pillar/saved-wheels')
  }

  const wheelMenuItemList = [
    {
      id: 0,
      label: 'Create Wheel',
      onClick: handleWheelCreation,
    },
    {
      id: 1,
      label: 'Saved Wheels',
      onClick: handleViewSavedWheels,
    },
  ]

  const calculatePercentage = (data) => {
    if (!data) {
      return []
    }
    const dataPointTotal = data.reduce(
      (total, child) => total + child.data_point,
      0,
    )
    data.forEach((child) => {
      child.percentage =
        Math.round((child.data_point / dataPointTotal) * 10000) / 100
      calculatePercentage(child.children)
    })
  }

  const updatedTrends = (trends) => {
    const sortedTrends = trends.sort((s1, s2) => {
      return s2.data_point - s1.data_point
    })
    return sortedTrends
  }

  const updatedRelatedThemes = (relatedThemes) => {
    const sortedRelatedThemes = relatedThemes.sort((s1, s2) => {
      return s2.data_point - s1.data_point
    })

    const newRelatedTheme = sortedRelatedThemes.map((relatedTheme) => {
      return { ...relatedTheme, children: updatedTrends(relatedTheme.children) }
    })
    return newRelatedTheme
  }

  const handleGenerateWheelClick = (maxIngCount, maxThemeCount) => {
    const pillarIdList = slectedCheckboxData.map((pillar) => pillar.id)
    setCustomLoaderWithLabel(true)

    let reqString = ''
    pillarIdList.forEach((id) => {
      reqString += `pillar_id=${id}&`
    })
    if (maxIngCount && maxThemeCount) {
      reqString += `max_ing_count=${maxIngCount}&max_theme_count=${maxThemeCount}&`
    }

    const request = NetworkUtil.getCreatedWheel(reqString.slice(0, -1))
    axios
      .get(request)
      .then((response) => {
        const res = response.data.data.wheel
        const data = {
          name: res.category,
          level: res.level,
          country: res.country,
          children: res.children,
        }
        const updatedChildren = data.children.map((pillar) => {
          return { ...pillar, children: updatedRelatedThemes(pillar.children) }
        })

        calculatePercentage(updatedChildren)

        const filteredWheelData = {
          ...data,
          children: updatedChildren,
        }

        setWheelData(filteredWheelData)
        setPostWheel(res)
        logWheelGenerated(filteredWheelData)
        setCustomLoaderWithLabel(false)
      })
      .catch((error) => {
        console.log('err in post request wheel', error)
        setCustomLoaderWithLabel(false)
      })
    setIsWheelBeingCreated(true)
  }

  const handleCancelWheelCreation = () => {
    handleCancelSelection()
    setSelectedWheelView(false)
    setWheelData(null)
    setNoOfRelatedTrends(RELATED_TREND_DEFAULT_COUNT)
    setNoOfThemes(THEME_DEFAULT_COUNT)
  }

  const tableComponent = (pillar, pillarLevel, slectedCheckboxData) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '60px',
        }}
        onClick={() => handleRowExpansion(pillar)}
      >
        <Box onClick={(e) => e.stopPropagation()}>
          {pillar.depth === 0 && showCheckBox && (
            <Checkbox
              checked={
                slectedCheckboxData.find((data) => data.id === pillar.id) !==
                undefined
              }
              onChange={(e) => handleCheckBoxSelection(e, { ...pillar })}
            />
          )}
        </Box>
        <Box
          sx={{
            width: tableCellWidth(pillar.depth, showCheckBox),
            minWidth: tableCellWidth(pillar.depth, showCheckBox),
          }}
        >{`L${pillar.level}`}</Box>
        <TextOverflowTooltip title={pillar.pillarName} maxLineCount={2}>
          <Typography
            sx={{
              width: '230px',
              minWidth: '230px',
              paddingRight: '20px',
            }}
          >
            {pillar.pillarName}
          </Typography>
        </TextOverflowTooltip>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {pillar.country}
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {pillar.category}
        </Box>
        <Box
          sx={{
            width: '90px',
            minWidth: '90px',
          }}
        >
          {pillar.trends}
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {' '}
          {formatNumber(pillar.dataPoints)}
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {pillar.fourYearCagr.toFixed(2)}%
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {pillar.twoYearCagr.toFixed(2)}%
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {formatNumber(pillar.noOfRelatedIngredients)}
        </Box>
        <Box
          sx={{
            width: getColWidth(pillar.depth),
            minWidth: MIN_COL_WIDTH,
          }}
        >
          {formatNumber(pillar.noOfRelatedProducts)}
        </Box>

        <Box
          sx={{
            width: '90px',
            minWidth: '90px',
          }}
        >
          {!showCheckBox && (
            <RenderActionItem
              pillar={pillar}
              slectedCheckboxData={slectedCheckboxData}
              setLoading={setLoading}
              loading={loading}
              lensSelected={lensSelected}
              handleDelete={handleShowDeleteWarning}
              handleEdit={handleEdit}
              handleDownload={handleDownload}
            />
          )}
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (!props.projectsList?.length) {
      setLoading(true)
      props.requestProjectsList().then(() => {
        setLoading(false)
      })
    }
    return () => {
      props.resetTrendPillarData()
    }
  }, [])

  useEffect(() => {
    setData(props.trendPillarList)
  }, [props.trendPillarList])

  useEffect(() => {
    if (
      props.projectsList &&
      !_.isEmpty(data) &&
      !Object.values(data)[0].country
    )
      handleCountryCategoryAddition()
  }, [props.projectsList, data])

  useEffect(() => {
    if (!showCreatPillarDialog) {
      setShowImportPillarDialog(false)
    }
  }, [showCreatPillarDialog])

  useEffect(() => {
    props.fetchSubscriptionsDetails()
  }, [])

  useEffect(() => {
    logSearch()
  }, [searchValue])

  useEffect(() => {
    props.getTrendPillarforImporting()
  }, [
    (props.importPillarInfo[0] && props.importPillarInfo[0]?.lens) ||
      (props.pillarDetails[0] && props.pillarDetails[0]?.lens),
  ])

  const defaultExpanded = []

  if (customLoaderWithLabel) {
    return <CircularLoaderWithText text={WHEEL_GENERATE_PROGRESS_MESSAGE} />
  }

  return !props.subscriptionDetails ? (
    <OverlayLoader />
  ) : (
    <>
      {popUp && (
        <MergeTrendPillarPopUp
          logMergePillarPopupActionClicked={logMergePillarPopupActionClicked}
          handleSubmitClick={handleSubmitClick}
          open={popUp}
          setPopUp={setPopUp}
          lensSelected={lensSelected}
          selectedCountryForMerging={selectedCountryForMerging}
          selectedCategoryForMerging={selectedCategoryForMerging}
          handleBucketNameChange={handleBucketNameChange}
          bucketName={bucketName}
          mergePillarPopupError={mergePillarPopupError}
          setBucketName={setBucketName}
        />
      )}
      {loading && <OverlayLoader />}
      {!props.subscriptionDetails.trend_pillar ? (
        <ErrorPage text="You are not authorized to view this page" />
      ) : isWheelBeingCreated ? (
        <>
          {wheelData && (
            <CreateWheelPage
              wheelData={wheelData}
              setIsWheelBeingCreated={setIsWheelBeingCreated}
              handleCancelWheelCreation={handleCancelWheelCreation}
              logWheelCustomization={logWheelCustomization}
              logSaveWheel={logSaveWheel}
              postWheel={postWheel}
              handleGenerateWheelClick={handleGenerateWheelClick}
              noOfRelatedTrends={noOfRelatedTrends}
              noOfThemes={noOfThemes}
              setNoOfRelatedTrends={setNoOfRelatedTrends}
              setNoOfThemes={setNoOfThemes}
              THEME_DEFAULT_COUNT={THEME_DEFAULT_COUNT}
              RELATED_TREND_DEFAULT_COUNT={RELATED_TREND_DEFAULT_COUNT}
            />
          )}
        </>
      ) : (
        <>
          {(popUpTab == 'create' ||
            popUpTab == 'import' ||
            popUpTab == 'merge') && (
            <DiscardBucketPopUp
              popUpTab={popUpTab}
              text={
                popUpTab == 'merge'
                  ? 'Are you sure you want to discard the selected pillars?'
                  : 'Are you sure you want to discard the changes?'
              }
              handleDiscardBucketCancel={handleDiscardBucketCancel}
              handleDiscard={handleDiscard}
            />
          )}
          {deletePillarId !== null && (
            <DeletePopUp
              textBody=" Deleting this pillar will delete all the information inside of it. Once deleted the pillar can not be recovered."
              textHead="Pillar"
              handleCancel={closeDeleteWarning}
              handleDelete={handleDelete}
            />
          )}
          {showCreatPillarDialog && (
            <CreateImportDialogContainer
              open={showCreatPillarDialog}
              showImport={showImportPillarDialog}
              setShowModal={setShowCreatPillarDialog}
            />
          )}
          <Box>
            <Grid
              container
              columns={16}
              my={1}
              py={1}
              display={'flex'}
              flexWrap={'nowrap'}
              height={64}
              sx={{
                background: 'transparent',
                paddingRight: '7px',
              }}
            >
              <Grid item xs={4} display={'flex'} alignItems={'center'} gap={3}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon />}
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                display="flex"
                alignItems="center"
              >
                <Box display="flex" justifyContent="flex-end">
                  {!props.pillarDetails[0]?.lens && (
                    <SearchBox
                      placeholder="Search..."
                      searchValue={searchValue}
                      handleSearch={handleSearch}
                      sx={{
                        marginRight: '5px',
                        height: 'auto',
                        '.MuiOutlinedInput-root': {
                          height: '40px',
                        },
                      }}
                    />
                  )}
                  {!props.pillarDetails[0]?.lens && (
                    <Button
                      variant="outlined"
                      onClick={handleCreatePillar}
                      sx={{
                        color: ({ palette }) => palette.text.primary,
                        marginRight: '7px',
                      }}
                      disabled={showCheckBox}
                    >
                      Create new Pillar
                    </Button>
                  )}

                  {allPillarsList?.length > 0 && (
                    <Button
                      sx={{
                        color: ({ palette }) => palette.text.primary,
                        marginRight: '7px',
                      }}
                      variant="outlined"
                      onClick={setImportMode}
                      disabled={showCheckBox}
                    >
                      Import Pillar
                    </Button>
                  )}
                  {!props.pillarDetails[0]?.lens && (
                    <Button
                      variant="outlined"
                      onClick={() => handleClickMergePillar(true)}
                      sx={{
                        border: ({ palette }) =>
                          `1px solid ${palette.primary.main}`,
                        height: '100%',
                        padding: '10px',
                        minWidth: '104px',
                      }}
                      disabled={showCheckBox}
                    >
                      Merge Pillar
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {props.pillarDetails[0]?.lens ||
          (props.importPillarInfo[0] && props.importPillarInfo[0]?.lens) ? (
            <TrendEdit
              handleFetchTrendPillars={refetchTrendPillarsList}
              handleSnackBar={handleSnackBar}
            />
          ) : (
            <>
              <Item
                sx={{
                  background: 'transparent !important',
                }}
              >
                <Grid
                  container
                  columns={16}
                  pt={2}
                  pb={2}
                  display={'flex'}
                  flexWrap={'nowrap'}
                  justifyContent="space-between"
                >
                  <Grid item xs={12} display={'flex'} gap={3}>
                    <SingleSelect
                      text={'Country:'}
                      options={countries}
                      onChange={(e, newVal) => handleCountryChange(newVal)}
                      value={countrySelected}
                      sx={{ width: '200px !important' }}
                      disabled={slectedCheckboxData.length}
                    />
                    <SingleSelect
                      text={'Category:'}
                      onChange={(e, newVal) => {
                        handleCategoryChange(newVal)
                      }}
                      sx={{ width: '200px !important' }}
                      options={categories}
                      value={categorySelected}
                      disabled={slectedCheckboxData.length}
                    />
                    <Stack spacing={1}>
                      <Stack direction="row">
                        <ToggleButtonGroup
                          value={lensSelected}
                          exclusive
                          onChange={handleToggleSelection()}
                          aria-label="lens"
                          sx={{
                            border: `3px solid ${({ palette }) =>
                              palette.divider} !important`,
                            backgroundColor: ({ palette }) => palette.white,
                          }}
                        >
                          {LENSES.map((lens) => (
                            <ToggleButton
                              value={lens}
                              aria-label={`${lens} lens`}
                              sx={{
                                textTransform: 'capitalize',
                                border: `3px solid ${({ palette }) =>
                                  palette.divider} !important`,
                                borderLeft: `1px solid ${({ palette }) =>
                                  palette.divider} !important`,
                              }}
                            >
                              {`${lens}s`}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center"
                    xs={4}
                    container
                    sx={{ marginRight: '0px !important' }}
                  >
                    {!selectedWheelView &&
                      !showCheckBox &&
                      lensSelected === 'Theme' && (
                        <CustomisedDropdownButton
                          menuItemList={wheelMenuItemList}
                          buttonLabel="Wheel View"
                          buttonEndIcon={<KeyboardArrowDownIcon />}
                          logButtonClick={logButtonClick}
                        />
                      )}

                    {showCheckBox && (
                      <Button
                        variant={'outlined'}
                        onClick={() =>
                          selectedWheelView
                            ? handleCancelWheelCreation()
                            : handleClickMergePillar(false)
                        }
                        sx={{
                          height: 'auto',
                          padding: '10px',
                          minWidth: '104px',
                          color: ({ palette }) => palette.text.hint,
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    {showCheckBox && (
                      <Button
                        variant={selectedWheelView ? 'contained' : 'outlined'}
                        onClick={() =>
                          selectedWheelView
                            ? handleGenerateWheelClick(
                                THEME_DEFAULT_COUNT,
                                RELATED_TREND_DEFAULT_COUNT,
                              )
                            : handleMergePopupOpen()
                        }
                        sx={{ marginLeft: '5px', height: '37px' }}
                        disabled={
                          selectedWheelView
                            ? slectedCheckboxData.length < 1
                            : slectedCheckboxData.length < 2
                        }
                      >
                        {selectedWheelView
                          ? 'Generate Wheel'
                          : 'Merge Selected Pillars'}
                          
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box pt={5} sx={{ overflow: 'auto' }}>
                  {defaultExpanded !== '' && (
                    <MergePillarTreeView
                      data={filteredPillars}
                      setData={setData}
                      allPillars={data}
                      searchValue={searchValue}
                      handleCheckBoxClick={handleCheckBoxSelection}
                      showCheckBox={showCheckBox}
                      slectedCheckboxData={slectedCheckboxData}
                      handleSubTableSort={handleSubTableSort}
                      subTableOrder={subTableOrder}
                      subTableOrderBy={subTableOrderBy}
                      tooltipOpenHandle={tooltipOpenHandle}
                      tableComponent={tableComponent}
                      defaultExpanded={defaultExpanded}
                      defaultExpandedPillar={getQueryParams({ location }).get(
                        'pillarId',
                      )}
                      handleTrendDetailsAddition={handleTrendDetailsAddition}
                      lensSelected={lensSelected}
                      countrySelected={countrySelected}
                      categorySelected={categorySelected}
                      trendsDetails={props.trendsDetails}
                      order={order}
                      setOrder={setOrder}
                      setOrderBy={setOrderBy}
                      orderBy={orderBy}
                      loading={loading}
                      expandedNodes={expandedNodes}
                      setExpandedNodes={setExpandedNodes}
                      logAccordianOpen={logAccordianOpen}
                      getColWidth={getColWidth}
                    />
                  )}
                </Box>
              </Item>
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    pillarDetails: state.trendPillar.pillarDetails,
    importPillarInfo: state.trendPillar.importPillarInfo,
    trendPillarList: state.trendPillar.bucketList,
    projectsList: state.savedTrendRequester.savedTrendList?._list,
    importPillarList: state.trendPillar.importDetails,
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    userId: state.user?.loggedIn?.user?.user_id,

    trendsDetails: state.trendPillar.trendsDetails,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTrendPillarList: (lens) => dispatch(actions.fetchTrendPillarList(lens)),
  mergePillar: (lens, data) => dispatch(mergePillar(lens, data)),
  downloadTrendPillarList: (trendId) =>
    dispatch(actions.DownloadTrendPillarList(trendId)),
  deleteTrendPillar: (data, lens) =>
    dispatch(actions.deleteTrendPillar(data, lens)),
  requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
  pillarBasicDetails: (
    pillarName,
    countrySelected,
    categorySelected,
    lensSelected,
    trends,
    id,
  ) => {
    dispatch(
      pillarBasicDetails(
        pillarName,
        countrySelected,
        categorySelected,
        lensSelected,
        trends,
        id,
      ),
    )
  },
  resetTrendPillarData: () => dispatch(actions.resetTrendPillarData()),
  clearPillarDetailsData: () => dispatch(actions.clearPillarDetailsData()),
  getTrendPillarforImporting: () =>
    dispatch(actions.getTrendPillarforImporting()),
  fetchSubscriptionsDetails: () =>
    dispatch(actions.fetchSubscriptionsDetails()),
  getTrendDetails: (lens, pillarId) =>
    dispatch(getTrendDetails(lens, pillarId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrendPillar)
