import { Box } from '@mui/material'

import React from 'react'
import Chart from 'react-apexcharts'

const DashboardGraphPreview = (props) => {
  const { graphData } = props

  const getNormalizedData = (value, maxScore) => {
    let newValue
    if (maxScore) {
      newValue =
        parseInt((value / maxScore) * 100) ||
        ((value / maxScore) * 100).toFixed(2)
    } else {
      newValue = value
    }
    return newValue
  }
  const getGraphReqData = (trendData) => {
    const dataObj = [
      {
        name: 'Historical Growth',
        data: [],
        type: 'line',
      },
    ]

    const maxScore = trendData.reduce(
      (max, arr) => (max < arr.engagementScore ? arr.engagementScore : max),
      -1,
    )
    let key

    for (const element in trendData) {
      const value = trendData[element].engagementScore
      // If engagement score is non-0, then only pick the timestamp
      key = trendData[element].period
      const newValue = getNormalizedData(value, maxScore)
      const row = [new Date(key).getTime(), newValue]
      dataObj[0].data.push(row)
    }

    var options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        height: 80,
        type: 'line',
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Historical Growth',
          data: dataObj.data,
        },
      ],
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: false,
      },
    }
    return {
      dataObj: dataObj,
      options: options,
    }
  }
  return (
    <Box
      sx={{
        flex: 1,
        mb: -2,
      }}
    >
      <Chart
        options={getGraphReqData(graphData).options}
        series={getGraphReqData(graphData).dataObj}
        type="line"
        height={80}
      />
    </Box>
  )
}

export default DashboardGraphPreview
