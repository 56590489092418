import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../../../../shared/utils/formatNumber';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCompareDispatch } from '../../../../../shared/contexts/CompareContext';
import CompareCardContainer from './styles';

const CompareCard = ({
  id, name, rank, image, dataPoint,
}) => {
  const dispatch = useCompareDispatch();
  return (
    <CompareCardContainer>
      <IconButton
        aria-label="close"
        onClick={() => dispatch({ type: 'deleted', payload: { id } })}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          color: 'icon',
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <img src={image} alt={name} />
      <Box>
        <Typography variant="subtitle1">{name}</Typography>
        <Box>
          <Box>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              Rank -
            </Typography>
            <Typography variant="caption">
              #
              {rank}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              Data points -
            </Typography>
            <Typography
              variant="caption"
            >
              {formatNumber(dataPoint)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CompareCardContainer>
  );
};

CompareCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  dataPoint: PropTypes.string.isRequired,
};

export default CompareCard;
