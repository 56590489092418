import React, { Component } from 'react'
import * as d3 from 'd3'

import { Box } from '@mui/system'

import pairing from '../../../assets/mock/pairing.json'
import PairingHoverCard from '../PairingHoverCard'
import InformationIcon from '../InformationIcon'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { amplify, getTextDimensions } from '../../../store/utility'

const getAxesLabel = (num) => {
  return num % 1 ? num.toFixed(2) : num + '%'
}

const filterTickVals = (
  arr,
  posFunc,
  fontSize,
  fontStyle,
  crossAxis = false,
) => {
  const getTextSize = (text) =>
    getTextDimensions(getAxesLabel(text), {
      size: fontSize,
      family: fontStyle,
    })

  const generatedArr = arr.reduce((acc, curr) => {
    if (acc.length > 0) {
      const prevPoint = acc[acc.length - 1]
      const { width: prevW } = getTextSize(prevPoint)
      const { actualBoundingBoxAscent, actualBoundingBoxDescent } = getTextSize(
        curr,
      )
      const currHeight = actualBoundingBoxAscent - actualBoundingBoxDescent
      const padding = 5
      if (crossAxis) {
        if (posFunc(prevPoint) - padding >= posFunc(curr) + currHeight) {
          return [...acc, curr]
        } else {
          return acc
        }
      } else {
        if (posFunc(prevPoint) + prevW + padding <= posFunc(curr)) {
          return [...acc, curr]
        } else {
          return acc
        }
      }
    } else {
      return [curr]
    }
  }, [])
  return generatedArr
}

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Pairing_Reset_Zoom':
        amplitudeUserData = {
          User: ampiInstance._email,
          Lens_Name: props.lens,
          Country_Name: ampiInstance._country,
          Category_Name: ampiInstance._category,
          Trend_Name: ampiInstance._name,
          Maturity_Phase: ampiInstance._trendName,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          User: ampiInstance._email,
          Lens_Name: props.lens,
          Country_Name: ampiInstance._country,
          Category_Name: ampiInstance._category,
          Redirected_From: 'Pairing Graph View',
          Trend_Name: ampiUserData.title,
          Paired_From: ampiInstance._name,
          Maturity_Phase: ampiInstance._trendName,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in PairingChart')
  }
}

const pairExample = pairing.nodes[1]
class PairingChart extends Component {
  toggleTimer = null
  minChartHeight = 540
  appliedZoom = []
  state = {
    tooltip: {
      productid: 0,
      isShow: false,
      left: 291,
      top: 83,
      imgUrl:
        'https://s3-ap-southeast-1.amazonaws.com/aipaletteimagesbucket/Thailandbeverage/Bael.png',
      title: 'Bell Bool Mand',
      tag: 'Pairing',
      twoYearCAGR: 20,
      pairingStrength: 20,
      trend: 'Dormant',
      leftTooltip: false,
      topTooltip: false,
    },
  }

  /**
   * Close tooltip
   */
  closeTooltip = () => {
    const uptTooltip = { ...this.state.tooltip }
    if (uptTooltip.isShow) {
      uptTooltip.isShow = false
      const oldHoverCircle = document.getElementById('removeCircle')
      if (oldHoverCircle) {
        oldHoverCircle.remove()
      }
      this.setState({ tooltip: uptTooltip })
    }
  }

  viewDetailsClicked = () => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      title: this.state.tooltip.title,
    }
    amp(this.props, ampiEvent, ampiUserData)
  }

  componentDidMount() {
    this.draw()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.xyPairingData !== this.props.xyPairingData) {
      this.draw()
    }
  }

  draw = () => {
    const pairingData = this.props.pairingDetails // numberOfPaires
    const pairingDiv = document.getElementById('trend-pairing')

    if (pairingData && pairingDiv) {
      const pairingSvg = d3.select('#pairing-svg')
      if (pairingSvg) {
        pairingSvg.remove()
      }

      this.trendPairing = d3
        .select('#trend-pairing')
        .append('svg')
        .attr('version', 1.1)
        .attr('xmlns', 'http://www.w3.org/2000/svg')
        .attr('width', '100%')
        .attr('height', this.minChartHeight)
        .attr('style', 'background: white')
        .attr('id', 'pairing-svg')
      const chartWidth = this.trendPairing.node().getBoundingClientRect().width
      const chartHeight = this.trendPairing.node().getBoundingClientRect()
        .height
      const drawingRange = {
        xAxis: [120, chartWidth - 50],
        yAxis: [40, chartHeight - 100],
      }

      this.trendPairingChart = this.trendPairing.append('g')

      let xyPairingData = this.props.xyPairingData

      const yDiff = Math.floor(
        (this.props.modifiedY.yMax - this.props.modifiedY.yMin) / 10,
      )
      const yTickVal = [...new Array(10)].map((d, i) =>
        i == 9
          ? Math.floor(this.props.modifiedY.yMin + i * yDiff)
          : Math.floor(this.props.modifiedY.yMin + i * yDiff),
      )

      const findNextMultiple = (val) => {
        if (val < 0.1) {
          return 0.1
        } else if (val < 1) {
          return 1
        } else if (val < 10) {
          return 10
        } else if (val < 100) {
          return 100
        } else {
          return 1000
        }
      }

      const modifiedXMaxFormatted = findNextMultiple(this.props.modifiedXMax)

      // Chart plotting
      this.xAxis = d3
        .scaleLog()
        .domain([0.01, modifiedXMaxFormatted])
        .range(drawingRange.xAxis)
        .base(10)
        .nice()

      const xLine = d3
        .axisBottom(this.xAxis)
        .ticks(modifiedXMaxFormatted.toString().length + 1)
        .tickFormat((num) => getAxesLabel(num))
        .tickValues(
          filterTickVals(this.xAxis.ticks(), this.xAxis, 16, 'sans-serif'),
        )

      // .tickPadding(6)
      this.trendPairing
        .append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0,${chartHeight - 100})`)
        .attr('style', 'stroke-width: 3; font-size: 16px;')
        .call(xLine)

      this.yAxis = d3
        .scaleLinear()
        .domain([this.props.modifiedY.yMax, this.props.modifiedY.yMin])
        .range(drawingRange.yAxis)
      const yLine = d3
        .axisRight(this.yAxis)
        .ticks(10)
        .tickFormat((x) => x + '%')
        .tickPadding(-35)
      this.trendPairing
        .append('g')
        .attr('class', 'axis axis--y')
        .attr('transform', 'translate(120,0)')
        .attr('style', 'font-size: 16px;')
        .call(yLine)
      this.trendPairing
        .append('defs')
        .append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('width', chartWidth - 165)
        .attr('height', chartHeight - 133)
        .attr(
          'transform',
          `translate(${Math.abs(drawingRange.yAxis[1] - chartHeight) +
            15},${drawingRange.yAxis[0] - 8})`,
        )
      const xAxisGrid = d3
        .axisBottom(this.xAxis)
        .tickSize(-(chartHeight - 40) + 100)
        .tickFormat('')
        .ticks(modifiedXMaxFormatted.toString().length + 1)
        .tickValues(
          filterTickVals(this.xAxis.ticks(), this.xAxis, 16, 'sans-serif'),
        )

      const yAxisGrid = d3
        .axisRight(this.yAxis)
        .tickSize(-chartWidth + 170)
        .tickFormat('')
        .ticks(10)

      this.trendPairing
        .append('g')
        .attr('class', 'x axis-grid--x')
        .attr('transform', 'translate(0,' + (chartHeight - 100) + ')')
        .attr('style', 'stroke: #282830; opacity: 0.34;')
        // .attr("clip-path", "url(#clip)")
        .call(
          xAxisGrid.tickValues(
            filterTickVals(this.xAxis.ticks(), this.xAxis, 16, 'sans-serif'),
          ),
        )

      this.trendPairing
        .append('g')
        .attr('class', 'y axis-grid--y')
        .attr('transform', 'translate(' + (chartWidth - 50) + ',0)')
        .attr('style', 'stroke-dasharray: 3; opacity: 0.34;')
        // .attr("clip-path", "url(#clip)")
        .call(yAxisGrid)

      this.trendPairing.selectAll('line').style('display', 'block')

      // Remove y axis line
      const axisY = this.trendPairing.select('.axis--y')
      const gridsY = this.trendPairing.select('.axis-grid--y')

      this.trendPairing
        .select('.axis-grid--y')
        .append('line')
        .attr('x1', drawingRange.xAxis[0] - drawingRange.xAxis[1])
        .attr('x2', 0)
        .attr('y1', 40)
        .attr('y2', 40)
        .attr('stroke-dasharray', '3')
        .attr('stroke', '#000000')

      // Remove redundant y axis lines
      axisY.select('path').remove()
      axisY.selectAll('line').remove()
      gridsY.select('path').remove()

      // Change x axis color
      const axisX = this.trendPairing.select('.axis--x')
      axisX.select('path').attr('style', 'opacity: 0.24;')
      axisX.selectAll('line').attr('style', 'display: block; opacity: 0.34;')

      var zoomIn = (selectionArea, noAnimate) => {
        if (noAnimate) {
          selectionArea.forEach((area) => {
            this.xAxis.domain(
              [
                Math.max(drawingRange.xAxis[0], area[0][0]),
                Math.min(drawingRange.xAxis[1], area[1][0]),
              ].map(this.xAxis.invert, this.xAxis),
            )
            this.yAxis.domain(
              [
                Math.max(drawingRange.yAxis[0], area[0][1]),
                Math.min(drawingRange.yAxis[1], area[1][1]),
              ].map(this.yAxis.invert, this.yAxis),
            )
          })
        } else {
          this.xAxis.domain(
            [
              Math.max(drawingRange.xAxis[0], selectionArea[0][0]),
              Math.min(drawingRange.xAxis[1], selectionArea[1][0]),
            ].map(this.xAxis.invert, this.xAxis),
          )
          this.yAxis.domain(
            [
              Math.max(drawingRange.yAxis[0], selectionArea[0][1]),
              Math.min(drawingRange.yAxis[1], selectionArea[1][1]),
            ].map(this.yAxis.invert, this.yAxis),
          )
        }
        zoom(true, noAnimate)
        if (!noAnimate) {
          this.appliedZoom.push(selectionArea)
        }
      }

      // Brush Zoom
      var brushended = (reset) => {
        var selectionArea = d3.event.selection
        if (!selectionArea || reset) {
          if (!idleTimeout && !reset)
            return (idleTimeout = setTimeout(idled, idleDelay))
          this.xAxis.domain([0.01, modifiedXMaxFormatted])
          this.yAxis.domain([
            this.props.modifiedY.yMax,
            this.props.modifiedY.yMin,
          ])
          this.trendPairing.select('#pairing-zoom-reset-btn').remove()
          zoom()
          this.appliedZoom = []
        } else {
          zoomIn(selectionArea)
          this.trendPairing.select('.brush').call(brush.move, null)
        }
        this.closeTooltip()
      }

      let idleTimeout
      const idleDelay = 350

      var idled = () => {
        idleTimeout = null
      }

      var resetZoomHandler = () => {
        brushended(true)
        const ampiEvent = 'Clicked_Pairing_Reset_Zoom'
        amp(this.props, ampiEvent)
      }

      var zoom = (drawBtn, noAnimate = false) => {
        if (drawBtn && !document.getElementById('pairing-zoom-reset-btn')) {
          // Draw reset Zoom
          const zoomBtn = this.trendPairing
            .append('g')
            .attr('id', 'pairing-zoom-reset-btn')
            .attr('cursor', 'pointer')
          const resetZoomBtn = zoomBtn
            .append('rect')
            .attr('width', 110)
            .attr('height', 30)
            .attr('fill', '#efefef')
            .attr('x', chartWidth - 180)
            .attr('y', 10)
            .attr('rx', 5)
          zoomBtn
            .append('text')
            .attr('x', chartWidth - 170)
            .attr('y', 30)
            .attr('fill', 'black')
            .attr('font-size', 16)
            .text('Reset Zoom')

          const zoomButtonHover = (hover) => {
            resetZoomBtn.attr('fill', hover ? '#f1f1f1' : '#efefef')
          }
          zoomBtn.on('mouseover', () => zoomButtonHover(true))
          zoomBtn.on('mouseout', () => zoomButtonHover(false))
          zoomBtn.on('click', () => resetZoomHandler())
        }
        const transition = this.trendPairing
          .transition()
          .duration(noAnimate ? 0 : 150)
        this.trendPairing
          .select('.axis--x')
          .transition(transition)
          .call(
            xLine.tickValues(
              filterTickVals(this.xAxis.ticks(), this.xAxis, 16, 'sans-serif'),
            ),
          )
        this.trendPairing
          .select('.axis--y')
          .transition(transition)
          .call(yLine)
        this.trendPairing
          .select('.axis-grid--y')
          .transition(transition)
          .call(yAxisGrid)
        this.trendPairing
          .select('.axis-grid--x')
          .transition(transition)
          .call(
            xAxisGrid.tickValues(
              filterTickVals(this.xAxis.ticks(), this.xAxis, 16, 'sans-serif'),
            ),
          )
        this.trendPairing.selectAll('line').style('display', 'block')

        // Remove redundant y axis lines after redraw
        axisY.select('path').remove()
        axisY.selectAll('line').remove()
        gridsY.select('path').remove()
        this.trendPairing
          .selectAll('.drawn-data-points')
          .transition()
          .duration(noAnimate ? 0 : 150)
          .attr('cx', (d) => {
            return Math.round(this.xAxis(d.probability))
          })
          .attr('cy', (d) => {
            return Math.round(this.yAxis(d.twoYearCAGR))
          })

        // Zoom horizontal names
        const data = handleOverlap(this.xAxis, this.yAxis)
        const dataLabels = this.trendPairing
          .select('.data-point-names-x')
          .selectAll('text')
        dataLabels
          .transition(transition)
          .attr('x', (d, i) => {
            return data[i].x
          })
          .attr('y', (d, i) => {
            return data[i].y
          })
          .style('display', (d, i) => {
            return data[i].dir.left || data[i].dir.right ? 'block' : 'none'
          })
      }

      const brush = d3.brush().on('end', brushended)

      this.trendPairing
        .append('g')
        .attr('class', 'brush')
        .call(brush)
      this.trendPairing
        .select('.brush')
        .select('.overlay')
        .attr(
          'style',
          `width: ${chartWidth - 170}px; height: ${drawingRange.yAxis[1] -
            20}px; transform: translate(120px,40px);`,
        ) // set same dimensions as x axis-grid--x
      const pairingChart = this.trendPairingChart
      const plato = pairingChart.append('g').classed('paring_plato', true)

      // const xDiff = (xMax - xMin) / 10
      const halfChartW = chartWidth / 2

      // Draw legend
      // x axis
      plato
        .append('circle')
        .attr('cx', halfChartW - 75)
        .attr('cy', chartHeight - 30)
        .attr('r', 5)
        .style('fill', '#192b8d')

      // y axis
      plato
        .append('circle')
        .attr('cx', 30)
        .attr('cy', chartHeight / 2 + 30)
        .attr('r', 5)
        .style('fill', '#a3b8e3')

      xyPairingData = xyPairingData.sort(
        (a, b) => a.probability - b.probability,
      )
      // .slice(5,9)
      this.trendPairing.append('g').classed('data-point-anchor', true)
      // Drawing circles
      const pairingDP = this.trendPairing
        .append('g')
        .classed('pairing-data-points', true)
        .attr('clip-path', 'url(#clip)')
      const cyrcles = pairingDP
        .selectAll('.pairing-data-points')
        .data(xyPairingData)
        .enter()
        .append('circle')
        .attr('class', 'drawn-data-points')
        .attr('cx', (d, i, ar) => {
          return Math.round(this.xAxis(d.probability))
        })
        .attr('cy', (d, i, ar) => {
          return Math.round(this.yAxis(d.twoYearCAGR))
        })
        .attr('r', 8)
        .style('fill', (d) => this.props.phaseColor[d.trend.toUpperCase()])
        .style('opacity', 0.9)
        .attr('data-pairing-data-point-name', (d) => d.name)

      // Show name next to data point horizontally
      const handleOverlap = (xAxisFunc, yAxisFunc) => {
        const labelArray = xyPairingData.map((d) => ({
          x: Math.round(xAxisFunc(d.probability)) + 8,
          y: Math.round(yAxisFunc(d.twoYearCAGR)) + 5,
          width: Math.ceil(
            getTextDimensions(d.name, { family: 'Roboto', size: 14 }).width,
          ),
          name: d.name,
          height: 8,
          dir: {
            left: null,
            right: null,
            top: null,
            bottom: null,
          },
        }))
        const dataPointArray = xyPairingData.map((d) => ({
          x: Math.round(xAxisFunc(d.probability)) - 8,
          y: Math.round(yAxisFunc(d.twoYearCAGR)) - 8,
          width: 16,
          height: 16,
        }))

        for (let i = 0; i < labelArray.length; i++) {
          // Check with all points
          for (let j = 0; j < labelArray.length; j++) {
            // Check all data points other than itseflf
            if (i !== j) {
              // Check for both left and right direction
              for (let dirCheck = 0; dirCheck < 2; dirCheck++) {
                const iDirection = dirCheck === 0 ? 'left' : 'right'
                const iDirectionParam = labelArray[i].dir
                const iEl = labelArray[i]
                const jEl = labelArray[j]
                // Break out of loop if it's already added to right or left
                if (iDirectionParam.left || iDirectionParam.right) {
                  break
                }
                let iRectX1 =
                  dataPointArray[i].x + (dirCheck === 0 ? 0 : 1) * 16
                // Depending on direction we try to add subtract or add label width
                let iRectX2 =
                  dataPointArray[i].x +
                  (dirCheck === 0 ? -1 : 1) * (labelArray[i].width + 10)
                let jRectX1 = dataPointArray[j].x
                // Depending on direction subtract or add label width for comparing point
                let mulFac = 0
                const dir = labelArray[j].dir
                if (dir.left === true) {
                  mulFac = -1
                } else if (dir.right === true) {
                  mulFac = 1
                }
                let jRectX2 =
                  dataPointArray[j].x +
                  mulFac * (labelArray[j].width + 25) +
                  (mulFac === 0 ? 16 : 0)
                const iRectY1 = labelArray[i].y - 10
                const iRectY2 = labelArray[i].y + labelArray[i].height
                const jRectY1 = labelArray[j].y - 10
                const jRectY2 = labelArray[j].y + labelArray[j].height
                let overlapX = 0
                let overlapY = 0
                if (iRectX1 > iRectX2) {
                  const tmp = iRectX2
                  iRectX2 = iRectX1
                  iRectX1 = tmp
                }
                if (jRectX1 > jRectX2) {
                  const tmp = jRectX2
                  jRectX2 = jRectX1
                  jRectX1 = tmp
                }
                if (
                  iRectX1 < drawingRange.xAxis[0] - 12 ||
                  iRectX1 > drawingRange.xAxis[1]
                ) {
                  labelArray[i].dir.left = false
                }
                if (
                  iRectX2 > drawingRange.xAxis[1] ||
                  iRectX2 < drawingRange.xAxis[0] - 12
                ) {
                  labelArray[i].dir.right = false
                }
                // check overlapping area on horizontal axis
                overlapX = Math.max(
                  0,
                  Math.min(jRectX2, iRectX2) - Math.max(iRectX1, jRectX1),
                )
                overlapY = Math.max(
                  0,
                  Math.min(iRectY2, jRectY2) - Math.max(iRectY1, jRectY1),
                )
                const overlapArea = overlapX * overlapY
                // debugger
                if (overlapArea !== 0) {
                  labelArray[i].dir[iDirection] = false
                }
              }
              // If there's any overlap on both sides end loop for this datapoint
              if (
                labelArray[i].dir.left === false &&
                labelArray[i].dir.right === false
              ) {
                break
              }
            }
          }
          // If thers's no overlap on left add to left else to right
          // or set to false for both sides
          if (labelArray[i].dir.left !== false) {
            const offSetLeft = 2
            labelArray[i].x =
              labelArray[i].x - labelArray[i].width - 16 - offSetLeft
            labelArray[i].dir.left = true
            labelArray[i].dir.right = false
          } else if (labelArray[i].dir.right !== false) {
            labelArray[i].dir.right = true
            labelArray[i].dir.left = false
          } else {
            labelArray[i].dir.right = false
            labelArray[i].dir.left = false
          }
        }
        // console.log(labelArray,'called')
        return labelArray
      }

      this.trendPairing.select('.data-point-names-x').remove()
      this.trendPairing
        .append('g')
        .classed('data-point-names-x', true)
        .attr('clip-path', 'url(#clip)')
        .selectAll('text')
        // .data(xyPairingData)
        .data(handleOverlap(this.xAxis, this.yAxis))
        .enter()
        .append('text')
        .attr('x', (d) => {
          return d.x
        })
        .attr('y', (d) => {
          return d.y
        })
        .attr('font-size', 14)
        .style('display', (d) => (d.dir.left || d.dir.right ? 'block' : 'none'))
        .text((d) => d.name)

      /**
       * Cursor to dot interactions.
       */
      cyrcles.on('mouseover', (data, index, listCyrcle) => {
        // Check for opening and closing tooltip.
        if (this.state.tooltip.isShow && this.state.tooltip.selectId === index)
          return
        if (this.timeCloseTooltip) {
          clearInterval(this.timeCloseTooltip)
        }
        this.timeCloseTooltip = setTimeout(() => {
          this.closeTooltip()
        }, 15000)

        // Calculate the coordinates for the tooltip.
        const cy = listCyrcle[index].getAttribute('cy')
        const cx = listCyrcle[index].getAttribute('cx')
        let top = Math.floor(Number(cy))
        let left = Math.floor(cx) + 10
        const uptTooltip = { ...this.state.tooltip }
        const { width: divWidth, height: divHeight } = document
          .getElementById('trend-pairing')
          .getBoundingClientRect()
        uptTooltip.leftTooltip = false
        if (left + 240 > divWidth) {
          left = left - 270
          uptTooltip.leftTooltip = true
        }
        uptTooltip.topTooltip = false
        if (top + 300 > divHeight) {
          top = top - 290
          uptTooltip.topTooltip = true
        }

        // Updating the State
        uptTooltip.left = left
        uptTooltip.top = top
        uptTooltip.twoYearCAGR = data.twoYearCAGR.toFixed(2)
        uptTooltip.probability = data.probability.toFixed(2)
        uptTooltip.pairingStrength = data.probability.toFixed(2)
        uptTooltip.imgUrl = data.image
        uptTooltip.title = data.name
        uptTooltip.selectId = index
        uptTooltip.trend = data.trend
        uptTooltip.isShow = true
        uptTooltip.productid = data.id
        this.setState({ tooltip: uptTooltip })
      })

      // Drawing axis names
      plato
        .append('text')
        .attr('x', chartWidth / 2)
        .attr('y', chartHeight - 25)
        .attr('class', 'trend-phase-section-text')
        .attr('style', 'font-size: 16px;')
        .text(`Strength of Pairing`)
      plato
        .append('text')
        .attr('x', -(chartHeight / 2 - 30))
        .attr('y', '35')
        .attr('style', 'transform: rotate(-90deg); font-size: 16px;')
        .attr('class', 'trend-phase-section-text')
        .text(`2 Year CAGR`)

      d3.select('#pairing-hover-tooltip-x').attr(
        'style',
        `left: ${chartWidth / 2 + 73}px; top: ${chartHeight -
          47}px; position: absolute;`,
      )
      const yTooltip = d3
        .select('#pairing-hover-tooltip-y')
        .attr(
          'style',
          `left: 22px; top: ${chartHeight / 2 -
            105}px; position: absolute; transform: rotate(270deg);`,
        )
      yTooltip
        .select('.tooltiptext')
        .attr('style', 'transform: rotate(90deg); top: 120px;')
    }

    if (this.appliedZoom.length) {
      zoomIn(this.appliedZoom, true)
    }
  }

  render() {
    return (
      <Box
        sx={{
          height: this.minChartHeight,
          bgcolor: 'grey.100',
        }}
      >
        <div
          id="trend-pairing"
          style={{
            width: '100%',
            minHeight: this.minChartHeight,
            font: '22pt arial',
            position: 'relative',
            top: '-7px',
          }}
        >
          <div id="pairing-hover-tooltip-x">
            <InformationIcon
              tooltipText={`Percentage of products that pair the ingredient combination vs. sum of all products that contain each ingredient in the pairing combination`}
            />
          </div>
          <div id="pairing-hover-tooltip-y" className="tooltip">
            <InformationIcon
              tooltipText={`Growth of the ingredient over the last 2 Years`}
            />
          </div>
          {this.state.tooltip.isShow && (
            <PairingHoverCard
              leftTooltip={this.state.tooltip.leftTooltip}
              topTooltip={this.state.tooltip.topTooltip}
              projectid={
                this.props.pairingDetails.nodes
                  ? this.props.pairingDetails.nodes[0].project
                  : 0
              }
              productid={this.state.tooltip.productid}
              left={this.state.tooltip.left}
              top={this.state.tooltip.top}
              x={this.state.tooltip.twoYearCAGR}
              y={this.state.tooltip.pairingStrength}
              title={this.state.tooltip.title.slice(0, 12)}
              classification={this.state.tooltip.trend}
              image={this.state.tooltip.imgUrl}
              handleClose={this.props.handleClose}
              viewDetailsClicked={this.viewDetailsClicked}
              closeTooltip={this.closeTooltip}
              twoYearCagr={this.props.twoYearCagr}
              name={this.props.name}
            />
          )}
        </div>
      </Box>
    )
  }
}

export default PairingChart
