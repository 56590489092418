import React, { useEffect } from 'react'

import {
  Box,
  Typography,
  Grid,
  Link,
  Stack,
  Breadcrumbs,
  Divider,
} from '@mui/material'

import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import HideOnScrollAppbar from '../../shared/components/HideOnScrollAppbar'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DetailedConsumerInsights from '../../shared/components/DetailedConsumerInsights'
import FoodServiceProducts from '../../shared/components/FoodServiceProducts'
import RelatedRetailProducts from '../../shared/components/RelatedRetailProducts'
import RelatedTrends from '../../shared/components/RelatedTrends'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import USAGraph from '../../shared/components/Graphs/USAGraph'
import SentimentCard from '../../shared/components/SentimentCard'
import SingleSelectSearchComponent from '../../shared/components/SingleSelectSearchComponent'
import MonthlyTrendsMui from '../../components/Products/ProductDetailsPage/ProductDetailsInfo/Charts/MonthlyTrendsMui/MonthlyTrendsMui'
import RelatedIngredients from '../../shared/components/RelatedIngredients'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { TrendGrowthCardContainer } from '../../shared/components/TrendGrowthCard/styles'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'
import { useHistory } from 'react-router-dom'
import StyledLink from '../../shared/components/StyledLink'
import RegionalAnalyticsGraphLegend from './RegionalAnalyticsGraphLegend'
import { TrendPageGraphComp } from './styles'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import FixedSnackbar from '../../components/FixedSnackbar/FixedSnackbar'
import { INSIGNIFICANT_DATA_MESSAGE } from './../../shared/utils/constants'

const RELATED_TRENDS_TOOLTIP = `The data shown represents the overall country-level spread across maturity phases.`

const RegionalAnalyticsTrendPageUI = ({
  subscriptionDetails,
  projectid,
  productid,
  projectVariant,
  loader,
  graphData,
  cagrData,
  sentimentData,
  consumerInsightData,
  dataPoint,
  fsData,
  fsFormatData,
  retailProductData,
  retailProductFormatData,
  relatedTrendData,
  lens,
  filterDuplicates,
  consumerQuote,
  toggleImageDownload,
  togglePptDownload,
  cIExcelDownload,
  saveSectionToPpt,
  region,
  trendGrowthExtraInfo,
  trendName,
  handleRegionClick,
  country,
  category,
  getChartDataPpt,
  saveGraphPpt,
  regionalDistributionData,
  breadCrumbLink,
  regionSelectionOpt,
  getRelatedTrendsUrlOnClick,
  logTrendClick,
  logConsumerQuotesClicked,
  logRegionalTrendClick,
  fetchRegionalConsumerQuotes,
  isDataInsignificant = false,
  handleSnackBarRemove,
  onRefresh,
}) => {
  const history = useHistory()
  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href={breadCrumbLink}>
      Regional Analytics
    </Link>,
    <Typography key="3" variant="subtitle2">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {trendName}
        <SingleSelectSearchComponent
          options={regionSelectionOpt || null}
          defaultOption={region}
          handleOptionClick={handleRegionClick}
        />
      </Box>
    </Typography>,
  ]

  useEffect(() => {
    if (country && region && trendName && category) {
      shouldLogRecentActivity(
        `Regional Analytics>${country}>${region}>${trendName}`,
        'Platform',
        `${history.location.pathname}${history.location.search}`,
      )
    }
  }, [country, region, trendName, category])

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '50px',
          zIndex: 100,
          width: '100%',
          background: 'white',
        }}
      >
        <MainCommonTabs subscriptionDetails={subscriptionDetails} />
      </Box>
      {loader ? (
        <OverlayLoader />
      ) : (
        <>
          <Box sx={{ position: 'relative', top: '100px' }}>
            <HideOnScrollAppbar sx={{ top: '120px' }}>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon />}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </HideOnScrollAppbar>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            pt={14}
          >
            <Typography variant="h3">
              <StyledLink
                target="_blank"
                to={`/mui/details/${projectid}/${lens}/${productid}`}
                fontSize="inherit"
                onClick={logTrendClick}
              >
                {trendName}
              </StyledLink>{' '}
              in {region} {country}
            </Typography>
            {isDataInsignificant && (
              <FixedSnackbar
                preIcon={
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: ({ palette }) => palette.warning.light }}
                  />
                }
                postIcon={<CloseIcon fontSize="small" color="action" />}
                message={INSIGNIFICANT_DATA_MESSAGE}
                postIconAction={handleSnackBarRemove}
                postIconSx={{ paddingLeft: '30px' }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex' }} pt={2}>
            <Box mr={2}>
              {trendGrowthExtraInfo && (
                <Box
                  component="img"
                  src={trendGrowthExtraInfo.img}
                  alt={trendName}
                  sx={{ borderRadius: '4px', objectFit: 'cover' }}
                  width="100%"
                  height={200}
                />
              )}
              <Box pt={3} width={350}>
                {sentimentData && (
                  <SentimentCard
                    positiveSentiment={sentimentData.positive_sentiment}
                    neutralSentiment={sentimentData.neutral_sentiment}
                    negativeSentiment={sentimentData.negative_sentiment}
                    paperStyles={{
                      height: 190,
                      '& > .MuiBox-root:first-of-type': {
                        marginRight: 0,
                        marginLeft: (theme) => theme.spacing(3),
                      },
                    }}
                    pieChartWidth={112}
                    flippedSentimentCard
                  />
                )}
              </Box>
            </Box>
            <TrendPageGraphComp>
              <Typography variant="h3" p={2}>
                {trendName} across {country}
              </Typography>
              <Divider />
              <Grid container>
                <Grid item xs={true}>
                  <Box
                    sx={{
                      width: 800,
                      mr: -29,
                      overflow: 'hidden',
                      mb: -10.5,
                      mt: -7,
                    }}
                  >
                    <USAGraph
                      regionalDistributionData={regionalDistributionData}
                      country={country}
                      category={category}
                      region={region}
                      disableClick
                      scale={0.75}
                    />
                  </Box>
                </Grid>
                <Grid item display="flex" alignItems="center" xs={true}>
                  <Box sx={{ px: 4 }}>
                    <RegionalAnalyticsGraphLegend
                      regionalDistributionData={regionalDistributionData}
                      trendName={trendName}
                    />
                  </Box>
                </Grid>
              </Grid>
            </TrendPageGraphComp>
          </Box>
          {graphData && (
            <>
              <Stack spacing={3}>
                <Box pt={3}>
                  <Box id="_dowSectionChart">
                    <TrendGrowthCardContainer>
                      <MonthlyTrendsMui
                        noConf={true}
                        topicChart={true}
                        showDataPoints
                        graphSubText="Data Source: Search and Social Data"
                        dataPoint={dataPoint}
                        showGrowthChip={true}
                        largeView
                        informationBox={true}
                        trendData={graphData}
                        yLabel={'Engagement score'}
                        trendCoefficient={cagrData.four_year_coefficient}
                        trendIntercept={cagrData.four_year_intercept}
                        shouldModifyData={true}
                        toggleImageDownload={toggleImageDownload}
                        togglePptDownload={saveGraphPpt}
                        getChartDataPpt={getChartDataPpt}
                        showTwoYearCagr={true}
                        twoYearCagr={cagrData.two_year_cagr}
                      />
                    </TrendGrowthCardContainer>
                  </Box>
                </Box>
                <Box id="_dowSectionCati">
                  {consumerInsightData && (
                    <DetailedConsumerInsights
                      projectid={projectid}
                      productid={productid}
                      // expand={loader}
                      lensSelected={lens}
                      name={trendName}
                      country={country}
                      category={category}
                      productType="consumerNeeds"
                      categoryData={consumerInsightData}
                      toggleImageDownload={toggleImageDownload}
                      cIExcelDownload={cIExcelDownload}
                      togglePptDownload={saveSectionToPpt}
                      type="regionalAnalytics"
                      region={region}
                    />
                  )}
                </Box>
                {/* TODO Should Uncomment after */}
                {/* <Box id="_dowSectionCafePdct">
                  {trendGrowthExtraInfo.cafeDataPoint !== 0 &&
                    fsData &&
                    fsData?.length !== 0 &&
                    projectVariant === 'food' && (
                      <FoodServiceProducts
                        // {...noSideCPFormastsProps}
                        projectid={projectid}
                        productid={productid}
                        // expand={loader}
                        productType="cafe_product"
                        name={trendName}
                        country={country}
                        category={category}
                        cafeDataPoint={trendGrowthExtraInfo.cafeDataPoint}
                        cafeProductFormats={fsFormatData}
                        cafeProducts={fsData}
                        toggleImageDownload={toggleImageDownload}
                        cIExcelDownload={cIExcelDownload}
                        togglePptDownload={saveSectionToPpt}
                      />
                    )}
                </Box> */}
                <Box id="_dowSectionRetailPdct">
                  {trendGrowthExtraInfo.retailDataPoint !== 0 &&
                    retailProductData &&
                    retailProductData.length !== 0 && (
                      <RelatedRetailProducts
                        // {...noSideRPFormatsProps}
                        projectid={projectid}
                        productid={productid}
                        // expand={loader}
                        productType="retail_product"
                        name={trendName}
                        country={country}
                        category={category}
                        retailDataPoint={trendGrowthExtraInfo.retailDataPoint}
                        retailProductFormats={retailProductFormatData}
                        retailProducts={retailProductData}
                        toggleImageDownload={toggleImageDownload}
                        cIExcelDownload={cIExcelDownload}
                        togglePptDownload={saveSectionToPpt}
                        onRefresh={() => {
                          onRefresh()
                        }}
                      />
                    )}
                </Box>
                {lens !== 'theme' && (
                  <Box id="_dowSectionThemeBlock">
                    <RelatedTrends
                      relatedTrends={relatedTrendData.related_themes}
                      parentTrendName={trendName}
                      lens="Ingredient"
                      blockName="Theme"
                      getRedirectionUrl={getRelatedTrendsUrlOnClick}
                      disableSubCategory
                      type="regionalAnalytics"
                      logTrendClick={logRegionalTrendClick}
                      tooltip={RELATED_TRENDS_TOOLTIP}
                    />
                  </Box>
                )}
                {lens === 'theme' && (
                  <>
                    {' '}
                    <Box id="_dowSectionThemeBlock">
                      <RelatedIngredients
                        expand={loader}
                        dowImageType="RelatedThemes"
                        productType="relatedProducts"
                        projectid={projectid}
                        blockName="Product"
                        lens="Theme"
                        relatedTrends={filterDuplicates(
                          relatedTrendData.related_products,
                        )}
                        toggleImageDownload={toggleImageDownload}
                        togglePptDownload={saveSectionToPpt}
                        link={{
                          section: 'regional',
                          country: country,
                          category: category,
                          region: region,
                        }}
                        type="regionalAnalytics"
                        logRegionalTrendClick={logRegionalTrendClick}
                        tooltip={RELATED_TRENDS_TOOLTIP}
                      />
                    </Box>
                    <Box id="_dowSectionIngredientBlock">
                      <RelatedIngredients
                        expand={loader}
                        projectid={projectid}
                        dowImageType="RelatedIngredients"
                        blockName="Ingredients"
                        lens="Theme"
                        productType="relatedIngredients"
                        relatedTrends={filterDuplicates(
                          relatedTrendData.related_ingredients,
                        )}
                        toggleImageDownload={toggleImageDownload}
                        togglePptDownload={saveSectionToPpt}
                        link={{
                          section: 'regional',
                          country: country,
                          category: category,
                          region: region,
                        }}
                        type="regionalAnalytics"
                        logRegionalTrendClick={logRegionalTrendClick}
                        tooltip={RELATED_TRENDS_TOOLTIP}
                      />
                    </Box>
                  </>
                )}
                <ConsumerQuotesCardComponent
                  consumerQuotes={consumerQuote}
                  category={category}
                  lens={lens}
                  sourceShow={true}
                  logConsumerQuotesClicked={logConsumerQuotesClicked}
                  type="regionalAnalytics"
                  onRefresh={() => {
                    fetchRegionalConsumerQuotes()
                  }}
                  country={country}
                  name={trendName}
                  markIrrelevantDisabled={false}
                  details={{
                    type: 'Regional',
                    region: region,
                  }}
                />
              </Stack>
            </>
          )}
        </>
      )}
    </>
  )
}

export default RegionalAnalyticsTrendPageUI
