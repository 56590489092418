import React from 'react'
import TooltipOnOverflow from '../../../components/Tooltip/TooltipOnOverflow.js'
import { StTextField } from './styles.js'

const StyledTextFeild = ({
  text,
  disabled = false,
  value = '',
  onChange,
  handleClick,
  error,
  sx = {},
}) => {
  return (
    <TooltipOnOverflow title={value} text={text} extraSpace={100}>
      <StTextField
        data-testid="styledInput"
        id="outlined-basic"
        text={text}
        name="styledInput"
        value={value}
        disabled={disabled}
        variant="outlined"
        size="large"
        onClick={handleClick}
        onChange={onChange}
        error={Boolean(error)}
        helperText={error}
        sx={sx}
      />
    </TooltipOnOverflow>
  )
}

export default StyledTextFeild
