import { UserSelectionActionTypes } from './actionTypes'

export const userSelectedProject = (projectID) => {
  return {
    type: UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PROJECT],
    projectid: projectID,
  }
}

export const userSelectedProduct = (productID) => {
  return {
    type: UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PRODUCT],
    productid: productID,
  }
}

export const userSelectedProjectNeeds = (needID) => {
  return {
    type: UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PROJECT_NEEDS],
    needsid: needID,
    bubbleid: null,
  }
}

export const userSelectedBubbleNeeds = (bubbleid) => {
  return {
    type: UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_BUBBLE_NEEDS],
    bubbleid: bubbleid,
    needsid: null,
  }
}

export const userSelectedProductNeed = (productNeedId) => {
  return {
    type: UserSelectionActionTypes.USER_SELECTED_PRODUCT_NEED_ID,
    productNeedId: productNeedId,
  }
}

export const userSelectedTopicId = (topicId) => {
  return {
    type: UserSelectionActionTypes.USER_SELECTED_TOPIC_ID,
    topicId,
  }
}

export const shouldUserWait = (shouldWait) => {
  return {
    type: UserSelectionActionTypes.type[UserSelectionActionTypes.USER_WAIT_ANIMATION_DISPLAYED],
    userShouldWait: shouldWait,
  }
}
export const userSelected = (payload) => {
  return {
    type: UserSelectionActionTypes.USER_SELECTED,
    payload,
  }
}
