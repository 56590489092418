import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ConsumerQuotesContainer from './styles'
import { ReactComponent as WeiboImg } from '../../../../../assets/social-icons/weibo.svg'
import { ReactComponent as ShareImg } from '../../../../../assets/social-icons/share.svg'
import { ReactComponent as MessageImg } from '../../../../../assets/social-icons/message.svg'
import fallbackImg from '../../../../../assets/emptySocialImage.png'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesWeibo')
  }
}

const ConsumerQuotesWeibo = ({
  timestamp,
  postUrl,
  text,
  postImageUrl,
  city,
  lens,
  logConsumerQuotesClicked,
  type,
}) => {
  const linkHandler = () => {
    window.open(postUrl, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = 'Clicked_Consumer_Quotes'
      const ampiUserData = {
        platform: 'Weibo',
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      {/* You need to use the classname to adjust the size of the picture. */}
      {postImageUrl && (
        <Box
          sx={{
            height: (theme) => theme.spacing(21.25),
            overflow: 'hidden',
          }}
        >
          <img
            src={postImageUrl}
            alt="product"
            className="image"
            onError={(e) => {
              e.currentTarget.style.display = 'none'
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          pt: 1,
        }}
      >
        <LocationOnIcon
          sx={{
            color: (theme) => theme.palette.icon,
            height: 17,
            width: 17,
            mr: 0.5,
          }}
        />
        <Typography variant="subtitle2" color="textSecondary">
          {city}
        </Typography>
      </Box>
      <Typography
        px={2}
        my={1}
        variant="subtitle2"
        sx={{
          height: (theme) => theme.spacing(8),
          overflow: 'hidden',
          flexGrow: 1,
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          pb: 1,
        }}
      >
        <FavoriteBorderIcon
          sx={{
            height: 17,
            width: 17,
            mr: 0.5,
          }}
        />
        <Typography pr={1} variant="caption">
          Likes
        </Typography>
        <ShareImg />
        <Typography pl={0.5} pr={1} variant="caption">
          Share
        </Typography>
        <MessageImg />
        <Typography pl={1} variant="caption">
          Comment
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2,
        }}
      >
        <WeiboImg />
        <Typography
          width="100%"
          textAlign="center"
          variant="subtitle2"
          color="textSecondary"
          px={2}
        >
          {format(new Date(timestamp), 'MMM dd, yyyy')}
        </Typography>
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesWeibo.defaultProps = {}
ConsumerQuotesWeibo.propTypes = {
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesWeibo
