import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AddFavButtonUI from '../../OtherFavorites/AddFavoriteButton/AddFavButtonUI'

const FavButton = ({
  handleClickAddToFav,
  favoriteSectionType,
  addedFav,
  isOtherFavoriteAllowed,
}) => {
  return (
    <>
      {isOtherFavoriteAllowed && (
        <AddFavButtonUI
          handleClickAddToFav={handleClickAddToFav}
          favoriteSectionType={favoriteSectionType}
          addedFav={addedFav}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return {
    isOtherFavoriteAllowed: myDashboard.isOtherFavoriteAllowed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FavButton),
)
