import { ProductTopicDetailActionTypes,ProductTopicQuotesActionTypes } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import SubNeedDetails from '../../services/networking/Models/SubNeedDetailResponse'
import SubNeedQuoteResponse from '../../services/networking/Models/SubNeedQuoteResponse'


export const fetchProductTopicDetails = (productId, topicId, lensSelected) => {
    return (dispatch, getState) => {
        dispatch(fetchProductTopicDetailsStart())

        // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
        let request;
        switch(lensSelected) {
          case 'Product':
            request = NetworkUtil.fetchProductFilterTopicDetails(productId, topicId) // Product
            break;
          case 'Theme':
            request = NetworkUtil.fetchThemeFilterTopicDetails(productId, topicId)// Theme
            break;
          case 'Ingredient':
            request = NetworkUtil.fetchProductTopicDetails(productId, topicId) // Ingredients
            break;
          default:
            dispatch(fetchProductTopicDetailsFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }

        axios.get(request)
        .then(response => {
            const responseObject = new SubNeedDetails(response.data)
            dispatch(fetchProductTopicDetailsSuccess(responseObject))
        })
        .catch(error => {
            dispatch(fetchProductTopicDetailsFailed(error))
        })
    }
}

export const fetchProductSubFormatDetails = (productId, formatType, formatName, subFormatName, lensSelected) => {
    return (dispatch, getState) => {
        dispatch(fetchProductTopicDetailsStart())
        console.log(lensSelected, 'fetchProductSubFormatDetails')
        // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
        let request;
        switch(lensSelected) {
          case 'Product':
            // request = NetworkUtil.fetchProductSubFormatDetails(productId, topicId) // Product
            return;
          case 'Theme':
            // request = NetworkUtil.fetchProductSubFormatDetails(productId, topicId)// Theme
            return;
          case 'Ingredient':
            request = NetworkUtil.fetchProductSubFormatDetails(productId, formatType, encodeURIComponent(formatName), encodeURIComponent(subFormatName)) // Ingredients
            break;
          default:
            dispatch(fetchProductTopicDetailsFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }

        axios.get(request)
        .then(response => {
            response.data.score = response.data.sub_formats[formatName][subFormatName];
            const subFormatNames = response.data.sub_formats[formatName] || {};
            response.data.relatedTerms = Object.keys(subFormatNames).map((subFormatName) =>
              ({name: subFormatName, percentage_score_current: subFormatNames[subFormatName]})
            );
            const responseObject = new SubNeedDetails(response.data)
            dispatch(fetchProductTopicDetailsSuccess(responseObject))
        })
        .catch(error => {
            console.log('Error -:::> ', error)
            dispatch(fetchProductTopicDetailsFailed(error))
        })
    }
}


const fetchProductTopicDetailsStart = () => {
    return {
        type: ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_START],
    }
}

export const fetchProductTopicDetailsSuccess = (productTopic) => {
    return {
        type: ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_SUCCESS],
        productTopic: productTopic,
    }
}

const fetchProductTopicDetailsFailed = (error) => {
    return {
        type: ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_FAILED],
        error: error,
    }
}

export const fetchProductTopicQuotes = (productId, topicId, lensSelected) => {
    return async (dispatch, getState) => {
        // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
        let request, tMRequest;
        switch(lensSelected) {
          case 'Product':
            request = NetworkUtil.fetchProductFilterTopicQuotes(productId, topicId); // Product
            tMRequest = NetworkUtil.fetchProductFilterTopicTMQuotes(productId, topicId);
            break;
          case 'Theme':
            request = NetworkUtil.fetchThemeFilterTopicQuotes(productId, topicId);// Theme
            tMRequest = NetworkUtil.fetchThemeFilterTopicTMQuotes(productId, topicId);
            break;
          case 'Ingredient':
            request = NetworkUtil.fetchProductTopicQuotes(productId, topicId); // Ingredients
            tMRequest = NetworkUtil.fetchProductTopicTMQuotes(productId, topicId);
            break;
          default:
            dispatch(fetchProductTopicQuoteFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }
        try{
          const quotes = await axios.get(request)
          const tmQuotes = await axios.get(tMRequest)
          let consolidatedQuotes = {}
          if(tmQuotes && tmQuotes){
            consolidatedQuotes = {...tmQuotes.data}
          }
          if(quotes && quotes.data){
            consolidatedQuotes = {...consolidatedQuotes,...quotes.data}
          }
          const responseObject = new SubNeedQuoteResponse(consolidatedQuotes)
          dispatch(fetchProductTopicQuoteSuccess(responseObject))
        }catch(error){
          dispatch(fetchProductTopicQuoteFailed(error))
        }
    }
}

const fetchProductTopicQuoteSuccess = (productTopicQuotes) => {
    return {
        type: ProductTopicQuotesActionTypes.type[ProductTopicQuotesActionTypes.FETCH_PRODUCT_TOPIC_QUOTE_SUCCESS],
        productTopicQuotes: productTopicQuotes,
    }
}

const fetchProductTopicQuoteFailed = (error) => {
    return {
        type: ProductTopicQuotesActionTypes.type[ProductTopicQuotesActionTypes.FETCH_PRODUCT_TOPIC_QUOTE_FAILED],
        error: error,
    }
}
