import React from 'react'
import {
  SvgIcon,
  Typography,
  Stack,
  Grid,
  Box,
  Divider,
  LinearProgress,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import formatNumber from '../../../../utils/formatNumber'
import { trendTypes } from '../../../../utils/constants'
import countriesList from '../../../../../mocks/country'

import ExpandableChipsGroup from '../../../ExpandableChipsGroup'
import GrowthChip from '../../../GrowthTag'
import ConsumerCard from '../../../ConsumerCard'

import { ReactComponent as LineChartIcon } from '../../../../../assets/icons/line-chart.svg'
import { ReactComponent as ParingIcon } from '../../../../../assets/icons/paring.svg'
import { ReactComponent as EmptySpaceFoodCupSvg } from '../../../../../assets/emptySpaceFoodCup.svg'
import { ReactComponent as EmptyPairing } from '../../../../../assets/emptyPairing.svg'

import { CardContainer, ContainerBox } from './styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { getSignedFloat } from '../../../../../store/utility'

const AdvancedTrendAnalysisCard = ({
  productComparisonData,
  lens,
  showSubCat,
}) => {
  const {
    id,
    country,
    category,
    name,
    filterClassification,
    unFormatedDataPoint,
    rank,
    trendName,
    twoYearCagr,
    category_data: categoryData,
    fourYearCagr,
    pairing,
    trendProjectId,
    cafeDataPoint,
    retailDataPoint,
    relatedIngProResponse,
  } = productComparisonData
  const getImage = (country) => {
    const countryFinded = countriesList.find((c) => c.label === country)
    return (
      <img
        loading="lazy"
        width="30"
        height="20"
        style={{ marginRight: '5px' }}
        src={
          countryFinded.img ||
          `https://flagcdn.com/w20/${countryFinded.code.toLowerCase()}.png`
        }
        alt={countryFinded.label}
      />
    )
  }

  const relatedTrendComponent = (headingLens, relatedTrend) => {
    return (
      relatedTrend && (
        <ContainerBox>
          <Stack width="100%" spacing={1}>
            <Box>
              <Typography
                sx={{ fontWeight: 800 }}
                variant="subtitle2"
                color="textSecondary"
              >
                Top Related {headingLens}
              </Typography>
              <Box mt={0.62}>
                {relatedTrend.length ? (
                  <List sx={{ padding: 0 }}>
                    {relatedTrend.map((trend, index) => {
                      return (
                        <ListItem key={`${index}`} sx={{ padding: 0 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              py: 0,
                            }}
                          >
                            {`${index + 1}. ${trend.name}`}
                          </Typography>
                        </ListItem>
                      )
                    })}
                  </List>
                ) : (
                  <Box
                    sx={{
                      width: '230px',
                      height: '63px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlign: 'center' }}
                    >
                      No Result Found
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
        </ContainerBox>
      )
    )
  }

  const getTrendIcon = (key) => {
    const trend = trendTypes.find((d) => d.id === key)
    return <SvgIcon fontSize="small" component={trend.icon} color="secondary" />
  }

  const isFilterClassificationEmpty = isEmpty(filterClassification)

  return (
    <Grid
      item
      xs={2.5}
      mx={1}
      my={2}
      key={id}
      backgroundColor={({ palette }) => palette.white}
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        '&:hover': {
          border: ({ palette }) => `1px solid ${palette.primary.main}`,
        },
      }}
    >
      <Link target="_blank" to={`/mui/details/${trendProjectId}/${lens}/${id}`}>
        <Stack divider={<Divider orientation="horizontal" flexItem />}>
          <ContainerBox>
            {getImage(country)}
            <Typography variant="subtitle2">{country}</Typography>
          </ContainerBox>
          <ContainerBox>
            <Stack>
              <Typography variant="subtitle2" color="textSecondary">
                Category
              </Typography>
              <Typography variant="subtitle2" mt={0.62}>
                {category}
              </Typography>
            </Stack>
          </ContainerBox>
          {showSubCat && (
            <ContainerBox
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Sub-category types
              </Typography>
              <CardContainer
                mt={1}
                sx={
                  isFilterClassificationEmpty
                    ? {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        border: ({ palette }) =>
                          `1px dashed ${palette.divider}`,
                        borderRadius: 0.5,
                      }
                    : {}
                }
              >
                <Stack spacing={2}>
                  {isFilterClassificationEmpty ? (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: '100%',
                      }}
                    >
                      <EmptySpaceFoodCupSvg />
                      <Typography
                        align="center"
                        pt={1.25}
                        px={1}
                        variant="caption"
                        color="textSecondary"
                      >
                        {name} doesn't fall into any sub-category
                      </Typography>
                    </Stack>
                  ) : (
                    Object.entries(filterClassification).map(([key, value]) => (
                      <Box key={key}>
                        <Box display={'flex'}>
                          {getTrendIcon(key)}
                          <Typography
                            variant="subtitle2"
                            color="secondary.dark"
                            textTransform="capitalize"
                          >
                            {key.toLowerCase()}
                          </Typography>
                        </Box>
                        <ExpandableChipsGroup trends={value.split(';')} />
                      </Box>
                    ))
                  )}
                </Stack>
              </CardContainer>
            </ContainerBox>
          )}
          <ContainerBox>
            <SvgIcon sx={{ mb: '-5px' }} component={LineChartIcon} />
            <Typography variant="subtitle2">
              {`${formatNumber(unFormatedDataPoint)} Data Points`}
            </Typography>
          </ContainerBox>
          <ContainerBox
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              Trend Ranking and Maturity
            </Typography>
            <Box mt={0.62}>
              <Typography
                display="inline"
                variant="subtitle2"
                color="secondary.main"
              >
                {`#${rank} ${trendName}`}
              </Typography>
            </Box>
          </ContainerBox>
          <ContainerBox sx={{ paddingRight: '9px' }}>
            <GrowthChip
              sx={{
                width: '100%',
                background: 'transparent',
                justifyContent: 'space-between',
                paddingLeft: 0,
                '& .MuiTypography-root.MuiTypography-subtitle3': {
                  marginLeft: 'auto',
                },
              }}
              title="2 Year CAGR"
              growthValue={twoYearCagr.toFixed(2)}
            />
          </ContainerBox>
          <ContainerBox sx={{ justifyContent: 'space-between' }}>
            <Typography display="inline" variant="subtitle2">
              4 Year CAGR{' '}
            </Typography>
            <Typography display="inline" variant="subtitle2">
              {getSignedFloat(fourYearCagr, 2)}%
            </Typography>
          </ContainerBox>
          {lens === 'ingredient' && (
            <ContainerBox sx={{ alignItems: 'flex-start', height: 200 }}>
              {pairing.length ? (
                <Stack sx={{ width: '100%', mb: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <SvgIcon
                      component={ParingIcon}
                      sx={{ color: 'secondary.main' }}
                    />
                    Paired Ingredients
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="subtitle2">Ingredient</Typography>
                    <Typography variant="subtitle2">
                      Pairing strength
                    </Typography>
                  </Box>
                  {pairing.map(({ name, strength }) => (
                    <Box
                      sx={{
                        mt: 1.5,
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          mb: 0.75,
                        }}
                      >
                        <Typography
                          variant="subtitle3"
                          sx={{ color: 'primary.main' }}
                        >
                          {name}
                        </Typography>
                        <Typography variant="subtitle2">
                          {strength.toFixed(2)}%
                        </Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={strength} />
                    </Box>
                  ))}
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <EmptyPairing />
                  <Typography
                    mt={1.25}
                    align="center"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Pairing data is not available for this trend
                  </Typography>
                </Stack>
              )}
            </ContainerBox>
          )}
          {!!categoryData.length && (
            <ContainerBox sx={{ paddingTop: '16px' }}>
              <Stack sx={{ width: '100%' }}>
                <Typography variant="subtitle2">Consumer Insights</Typography>
                {categoryData.map(
                  ({ category, data_point: dataPoint, needs, scn }) => {
                    return (
                      <ConsumerCard
                        projectid
                        selectedLang="English"
                        expand
                        category={category}
                        disableLink
                        dataPoint={dataPoint}
                        needs={needs}
                        scn={scn}
                        emptyCnSx={{ height: '565px' }}
                        ccContainerSx={{
                          height: '565px',
                          border: 'none',
                          paddingTop: '5px',
                          paddingBottom: '0px !important',
                          '& .MuiBox-root': {
                            padding: 0,
                            '& .MuiSvgIcon-root': {
                              fontSize: '16px',
                              marginLeft: '-5px',
                            },
                            '& .MuiTypography-subtitle1': {
                              fontSize: '14px',
                            },
                          },
                        }}
                        ccItemSx={{ padding: '0px !important' }}
                      />
                    )
                  },
                )}
              </Stack>
            </ContainerBox>
          )}
          {lens !== 'theme' ? (
            <ContainerBox>
              <Stack width="100%" spacing={1}>
                <Typography variant="subtitle2">Products</Typography>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Food Service Products:&nbsp;
                  </Typography>
                  <Typography component="span" variant="subtitle2">
                    {cafeDataPoint || '--'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Retail Products:&nbsp;
                  </Typography>
                  <Typography component="span" variant="subtitle2">
                    {retailDataPoint || '--'}
                  </Typography>
                </Box>
              </Stack>
            </ContainerBox>
          ) : (
            <>
              {relatedTrendComponent(
                'Ingredients',
                relatedIngProResponse.related_ingredients,
              )}
              {relatedTrendComponent(
                'Products',
                relatedIngProResponse.related_products,
              )}
            </>
          )}
        </Stack>
      </Link>
    </Grid>
  )
}

export default AdvancedTrendAnalysisCard
