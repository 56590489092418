import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/networking/axios'
import { Box, Grid, Stack } from '@mui/material'
import CircularProgressCustom from '../../shared/components/CircularProgressCustom'
import CategoryInsightDetailsTop from '../../shared/components/CategoryInsightDetailsTop'
import RelatedIngredients from '../../shared/components/RelatedIngredients'
import DriverCard from '../../shared/components/DriverCard'
import ConsumerQuotesCardComponent from '../../shared/components/ConsumerQuotesCardComponent'
import OverlayLoader from '../../shared/components/OverlayLoader'
import { downloadGraph, downloadSection, downloadFullPage } from '../../const'
import '../muiStyle.css'
class CategoryInsightPage extends Component {
  state = {
    loader: false,
  }

  componentDidMount() {
    // test
    // this.props.removeBreadCrumb(2)
    const needID = Number(this.props.match.params.needId)
    // refresh page
    const projectID = Number(this.props.match.params.projectId)

    this.props.fetchNeedsDetails(projectID, needID)
    this.props.fetchNeedQuotes(projectID, needID)
  }

  componentDidUpdate(prevProps) {
    // test
    // this.props.removeBreadCrumb(2)
    const needID = Number(this.props.match.params.needId)
    // refresh page
    const projectID = Number(this.props.match.params.projectId)
    if (needID !== prevProps.needID) {
      this.props.resetNeedDetails()
      this.props.fetchNeedsDetails(projectID, needID)
      this.props.fetchNeedQuotes(projectID, needID)
    }
  }

  fetchCQForNeeds() {
    const needID = Number(this.props.match.params.needId)
    const projectID = Number(this.props.match.params.projectId)
    this.props.fetchNeedQuotes(projectID, needID)
  }

  componentWillUnmount() {
    this.props.resetNeedDetails()
  }

  imageDownload = (section) => {
    this.setState({ loader: true }, () => {
      const name = this.props.needDetails.keyword
      const [
        ,
        country,
        category,
      ] = this.props.needDetails.searchCriteria[0].split(';')
      if (section === 'Chart') {
        downloadGraph(
          document.getElementById('_dowSectionChart'),
          `${name}_Monthly_Trend_Graph.jpg`,
        )
          .then(() => {
            this.setState({ loader: false })
          })
          .catch((er) => {
            console.log(er)
            this.setState({ loader: false })
          })
      } else {
        let dowSection = document.getElementById('_dowSectionDesc')
        let fileName = `${name}_${country}_${category}_Complete_Report.jpg`
        if (!section) {
          downloadFullPage(
            dowSection,
            '#_dowSectionChart',
            { top: 300, left: 0 },
            document.getElementById('downloadCanvas'),
            `height: 500px; background: white;`,
            fileName,
          )
            .then(() => {
              this.setState({ loader: false })
            })
            .catch((er) => {
              this.setState({ loader: false })
              console.log(er)
            })
        }

        if (section === 'RelatedIngredients') {
          dowSection = document.getElementById('_dowSectionIngredientBlock')
          fileName = `${name}_Related_Ingredients.jpg`
          downloadSection(dowSection, fileName)
            .then(() => {
              this.setState({ loader: false })
            })
            .catch(() => {
              this.setState({ loader: false })
            })
        }
      }
    })
  }

  render() {
    const { loader } = this.state
    const needDetailscheck =
      !this.props.needDetails || !this.props.needDetails.relatedNeeds
    const [, country, category] = !needDetailscheck
      ? this.props.needDetails.searchCriteria[0].split(';')
      : ''

    const projectVariant = this.props.dashboardList?.find(
      (dashboard) =>
        dashboard.criteria === country && dashboard.name === category,
    )?._projectVariant

    if (needDetailscheck) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              mt: 2,
              pt: 32,
              px: 88,
              flexGrow: 2,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <CircularProgressCustom />
          </Box>
        </Grid>
      )
    }

    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        {loader && <OverlayLoader />}
        <Box id="_dowSectionDesc">
          <Stack spacing={3}>
            <CategoryInsightDetailsTop
              expand={loader}
              showGrowthChip={true}
              needChart={true}
              catInsight={true}
              projectid={this.props.projectid}
              needid={this.props.needID}
              details={this.props.needDetails}
              positiveSentiment={this.props.needDetails.positiveScore}
              neutralSentiment={this.props.needDetails.negativeScore}
              negativeSentiment={this.props.needDetails.neutralScore}
              imageDownload={this.imageDownload}
              country={country}
              category={category}
              chartLargeView
            />
            <Box id="_dowSectionIngredientBlock">
              <RelatedIngredients
                expand={loader}
                dowImageType="RelatedIngredients"
                blockName="Ingredients"
                projectid={this.props.projectid}
                relatedTrends={this.props.needDetails.need_products}
                toggleImageDownload={this.imageDownload}
                country={country}
                category={category}
                amplitudeRedirect="Related Consumer Insight"
              />
            </Box>
            {this.props.needDetails.relatedNeeds &&
              this.props.needDetails.relatedNeeds.length !== 0 && (
                <DriverCard
                  expand={loader}
                  categoryPreviewNeed={true}
                  projectid={this.props.projectid}
                  needsList={[...this.props.needDetails.relatedNeeds].sort(
                    (needA, needB) => Number(needB.score) - Number(needA.score),
                  )}
                  category={this.props.needDetails.needCategory}
                />
              )}
          </Stack>
        </Box>
        {this.props.needQuotes && (
          <Box>
            <ConsumerQuotesCardComponent
              quotesOptions={this.props.needDetails.quotesOptions}
              consumerQuotes={this.props.needQuotes}
              sourceShow
              onRefresh={() => {
                this.fetchCQForNeeds()
              }}
              country={country}
              category={category}
              name={this.props.needDetails.keyword}
              markIrrelevantDisabled={false}
              projectVariant={projectVariant}
            />
          </Box>
        )}
        <Box id="downloadCanvas"></Box>
      </Stack>
    )
  }
}

const mapStateToProps = (state, props) => {
  const projectID = Number(props.match.params.projectId)
  const needID = Number(props.match.params.needId)
  return {
    projectid:
      state.userSelections.projectID === projectID
        ? state.userSelections.projectID
        : projectID,
    needID:
      state.userSelections.projectNeedID === needID
        ? state.userSelections.projectNeedID
        : needID,
    needDetails: state.needDetails.needDetails,
    needQuotes: state.needDetails.needQuotes,
    needsList: state.projectListBuilder.needsList,
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNeedsDetails: (projectid, needid) =>
      dispatch(actions.fetchNeedsDetails(projectid, needid)),
    fetchNeedQuotes: (projectid, needid) =>
      dispatch(actions.fetchNeedQuotes(projectid, needid)),
    fetchKeywordNeedsDetails: (projectid, keyword) =>
      dispatch(actions.fetchKeywordNeedsDetails(projectid, keyword)),
    resetNeedDetails: () => dispatch(actions.fetchNeedtDetailsSuccess(null)),
    userSelectedNeeds: (needid) =>
      dispatch(actions.userSelectedProjectNeeds(needid)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(CategoryInsightPage, axios)),
)
