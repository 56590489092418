import React, { useState, useEffect } from 'react'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack';

import Modal from './styles'

import * as actions from '../../../store/actions/index'

import popupbackground from '../../../assets/images/popupimage/popupbackground.jpg'

let snackbarId = null

const BannerPrivacyPolicy = ({
  setPolicy,
  acceptedPolicy,
  acceptedUpdatedPolicy,
}) => {
  const [showBackground, setShowBackground] = useState(false)
  const location = useLocation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handlePolicy = () => {
    const acceptPolicy = {
      accepted_policy: acceptedPolicy,
      accepted_updated_policy: acceptedUpdatedPolicy,
    }
    if (!acceptedPolicy) {
      acceptPolicy.accepted_policy = true
    }
    if (!acceptedUpdatedPolicy) {
      acceptPolicy.accepted_updated_policy = true
    }
    document.body.style.overflow = 'auto'
    setPolicy(acceptPolicy)
  }

  const handleModalClose = () => {
    if(snackbarId){
      closeSnackbar(snackbarId)
    }
    snackbarId = enqueueSnackbar('Please accept Terms of Services and Privacy Policy to continue', { variant: 'info'});
  }

  useEffect(() => {
    if (!(acceptedPolicy && acceptedUpdatedPolicy)) {
      if (
        location.pathname === '/mui/terms-of-service' ||
        location.pathname === '/mui/privacy-policy'
      ) {
        document.body.style.overflow = 'auto'
        setShowBackground(true)
      } else {
        document.body.style.overflow = 'hidden'
        setShowBackground(false)
      }
    }
  }, [location, acceptedPolicy, acceptedUpdatedPolicy])

  useEffect(() => {
    if (acceptedPolicy && acceptedUpdatedPolicy) {
      document.body.style.overflow = 'auto'
    }
  }, [acceptedPolicy, acceptedUpdatedPolicy])

  useEffect(() => {
    return () => (document.body.style.overflow = 'auto')
  }, [])

  const termsAndPolicy = (
    <>
      <Link href="/mui/terms-of-service" underline="none">
        {' '}
        Terms of Services{' '}
      </Link>
      and
      <Link href="/mui/privacy-policy" underline="none">
        {' '}
        Privacy Policy{' '}
      </Link>
    </>
  )

  const acceptButton = (
    <Button variant="contained" sx={{ left: '30px' }} onClick={handlePolicy}>
      {' '}
      Accept
    </Button>
  )

  return (
    <>
      <Modal
        onClose={handleModalClose}
        showBackground={showBackground}
        disableScrollLock
        open={!acceptedPolicy || !acceptedUpdatedPolicy}
      >
        <Stack spacing={2} direction="row">
          <Box
            sx={{
              width: '100%',
              height: '65px',
              boxShadow: ({ palette }) => `5px 0px 8px ${palette.divider}`,
              padding: '20px',
              backgroundImage: `url(${popupbackground})`,
              position: 'fixed',
              bottom: '0',
              left: '0',
            }}
          >
            {!acceptedPolicy && (
              <Typography>
                By logging in you agree to Ai Palette's
                {termsAndPolicy}
                {acceptButton}
              </Typography>
            )}
            {acceptedPolicy && !acceptedUpdatedPolicy && (
              <Typography>
                We have updated our
                {termsAndPolicy}, please accept to continue
                {acceptButton}
              </Typography>
            )}
          </Box>
        </Stack>
      </Modal>
    </>
  )
}

BannerPrivacyPolicy.propTypes = {
  setPolicy: PropTypes.func.isRequired,
  acceptedPolicy: PropTypes.bool.isRequired,
  acceptedUpdatedPolicy: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  acceptedPolicy:
    Object.keys(state.user.loggedIn).length > 0
      ? state.user.loggedIn.accepted_policy
      : null,
  acceptedUpdatedPolicy:
    Object.keys(state.user.loggedIn).length > 0
      ? state.user.loggedIn.accepted_updated_policy
      : null,
})

const mapDispatchToProps = (dispatch) => ({
  setPolicy: (payload) => dispatch(actions.setPolicy(payload)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerPrivacyPolicy)
)
