import { ResetActions, ProductTopicDetailActionTypes, 
    ProductTopicQuotesActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'
  
const initialState = {
    productTopicDetails: null,
    productTopicQuotes: null,
    error: null,
    loading: false,
}
  
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ResetActions.type[ResetActions.RESE_PRODUCT_TOPICS]:
            return updateObject(state, initialState)
        
        case ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_START]: 
            return updateObject(state, {
                loading: true,
            }
        )
        case ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_SUCCESS]: 
            return updateObject(state, {
                productTopicDetails : action.productTopic,
                error : false,
                loading: false,
            })
        case ProductTopicDetailActionTypes.type[ProductTopicDetailActionTypes.FETCH_PRODUCT_TOPIC_DETAIL_FAILED]:
            return updateObject(state, {
                productTopicDetails : null,
                error : true,
                loading: false,
            })
        
        case ProductTopicQuotesActionTypes.type[ProductTopicQuotesActionTypes.FETCH_PRODUCT_TOPIC_QUOTE_SUCCESS]: 
            return updateObject(state, {
                productTopicQuotes : action.productTopicQuotes,
            })
        case ProductTopicQuotesActionTypes.type[ProductTopicQuotesActionTypes.FETCH_PRODUCT_TOPIC_QUOTE_FAILED]:
            return updateObject(state, {
                productTopicQuotes : null,
            })
    
        default:
            return state
    }
}
  
export default reducer
  