import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      borderRadius: 8,
      backgroundColor: palette.grey[100],
    },
    bar: {
      borderRadius: 8,
    },
  },
};
