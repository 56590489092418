import React, { useEffect, useState } from 'react'

import upDown from '../../assets/images/screenWinner/sw-sort-up-down.svg'
import upDownUpActive from '../../assets/images/screenWinner/sw-up-down-upActive.svg'
import upDownDownActive from '../../assets/images/screenWinner/sw-up-down-downActive.svg'

import './ResultsTable.scss'

const ResultsTable = ({ headers, data, styles }) => {
  const [sortBy, setSortBy] = useState('')
  const [ascendingOrder, toggleAscendingOrder] = useState(true)
  const headerKeys = headers.map(({ key }) => key)
  if (sortBy) {
    data.sort((data1, data2) => {
      let item1 = data1[sortBy]
      let item2 = data2[sortBy]
      if (!ascendingOrder) {
        ;[item1, item2] = [item2, item1]
      }
      if (typeof item1 === 'string') {
        return item1.toLowerCase().localeCompare(item2.toLowerCase())
      } else if (typeof item1 === 'number') {
        return item1 - item2
      }
      return 0
    })
  }

  const setSortingOrder = (key) => {
    if (key === sortBy) {
      toggleAscendingOrder((order) => !order)
    } else {
      setSortBy(key)
    }
  }

  useEffect(() => {
    toggleAscendingOrder(true)
  }, [sortBy])

  return (
    <div className="sw-results-table__block">
      <div className="sw-results-table">
        <div className="sw-results-table__thead">
          {headers.map(({ sort, name, key }) => (
            <div
              className={`sw-results-table__thead__th${
                styles ? ` ${styles[key]}` || '' : ''
              }`}
            >
              {sort ? (
                <span role="button" onClick={() => setSortingOrder(key)}>
                  {name}{' '}
                  <img
                    src={
                      sortBy === key
                        ? ascendingOrder
                          ? upDownUpActive
                          : upDownDownActive
                        : upDown
                    }
                    alt="Sort arrow icons"
                  />
                </span>
              ) : (
                <span>{name}</span>
              )}
            </div>
          ))}
        </div>
        <div className="sw-results-table__tbody">
          {data.map((row) => (
            <div className="sw-results-table__tbody__tr">
              {headerKeys.map((key) => (
                <div
                  className={`sw-results-table__tbody__td${
                    styles ? ` ${styles[key]}` || '' : ''
                  }`}
                >
                  {row[key]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ResultsTable

// return (
//   <div className="sw-results-table__block">
//     <table className="sw-results-table">
//       <thead className="sw-results-table__thead">
//         <tr className="sw-results-table__thead__tr">
//           {headers.map(({ sort, name, key }) => (
//             <th className="sw-results-table__thead__th">
//               {sort ? (
//                 <span role="button" onClick={() => setSortingOrder(key)}>
//                   {name} ^
//                 </span>
//               ) : (
//                 <span>{name}</span>
//               )}
//             </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody className="sw-results-table__tbody">
//         {sortedData.map((row) => (
//           <tr className="sw-results-table__tbody__tr">
//             {headerKeys.map((key) => (
//               <td className="sw-results-table__tbody__td">{row[key]}</td>
//             ))}
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
// );
