import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  Grid,
  Stack,
  Box,
  SvgIcon,
  Tabs,
  ButtonGroup,
  Button,
} from '@mui/material'

import SingleSelect from '../../../shared/components/SingleSelect'
import SearchNotSelected from '../../../components/WhiteSpaceComponent/SearchNotSelected'
import TrendTable from '../../../shared/components/TrendsTable'
import ScatteredGraph from '../../../shared/components/Graphs/ScatteredGraph'
import AgeGroupButtons from '../AgeGroupButtons'
import MainCommonTabs from '../../../shared/components/MainCommonTabs/MainCommonTabs'
import OverlayLoader from '../../../shared/components/OverlayLoader'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { fetchSubscriptionsDetails } from '../../../store/actions'
import SearchBox from '../../../components/SearchBox/SearchBox'
import { shouldLogRecentActivity } from '../../../shared/components/DashboardRecentActivity'
import TabPanel from '../../../shared/components/TabPanel'
const DemographicAnalysisUI = ({
  demographyDashboards,
  subscriptionDetails,
  handleClickMillennials,
  handleClickGenZ,
  handleClickGenX,
  ageGroupTab,
  category,
  categorySelected,
  countrySelected,
  searchFiltreData,
  searchFiltredData,
  handleSearchTheme,
  demographicData,
  searchValue,
  showLoader,
  handleCategoryChange,
  setTrendGraphDetails,
  setShowLoader,
  setdemographicData,
  viewMode,
  setChart,
  setTable,
  handleHover,
  scatteredPlotTooltip,
  graphHelperData,
  email,
  lensToShow,
  handleChange,
  lens,
  tabs,
  tab,
  handleSelectChange,
  allDropdownsList,
  applySelectedFilters,
  resetSelectedFilterValues,
  handleFilterClickOpen,
  handleCloseFilter,
  open,
  anchorEl,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
  medianXVal,
  medianYVal,
  ageGroupGenX,
  ageGroupGenZ,
  ageGroupMillanials,
  viewDetailsClicked,
  resetBtn,
  setResetBtn,
  resetBtnId,
}) => {
  const tabsPanel = lensToShow.map((arr, i) => {
    return (
      <TabPanel key={arr.value} value={tab} index={arr.value}>
        {!showLoader ? (
          viewMode === 'chart' ? (
            searchFiltreData && (
              <>
                {resetBtn && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: '100%', px: 2 }}
                  >
                    <Button
                      id={resetBtnId}
                      variant="grey"
                      sx={{ mb: -2, zIndex: 10 }}
                    >
                      Reset Zoom
                    </Button>
                  </Box>
                )}
                <ScatteredGraph
                  resetZoomOnGraphDataUpdate={false}
                  email={email}
                  viewMode={viewMode}
                  filteredData={searchFiltreData}
                  lensSelected={lens}
                  graphHelperData={graphHelperData}
                  phaseColor={'blue'}
                  tooltipShow={'true'}
                  demographyComponent
                  handleHover={handleHover}
                  scatteredPlotTooltip={scatteredPlotTooltip}
                  medianXVal={medianXVal}
                  medianYVal={medianYVal}
                  viewDetailsClicked={viewDetailsClicked}
                  resetBtn={resetBtn}
                  setResetBtn={setResetBtn}
                  resetBtnId={resetBtnId}
                />
              </>
            )
          ) : (
            <Box sx={{ p: 2 }}>
              <TrendTable
                orderKey="data_point"
                sortOrder="desc"
                email={email}
                data={searchFiltredData}
                openDemographyThemePage
                noRankAvailable
                lensSelected={lens}
                countrySelected={countrySelected}
                categorySelected={categorySelected && categorySelected}
                viewMode={viewMode}
                demographyComponent
              />
            </Box>
          )
        ) : (
          <Box width="100%" height="500px"></Box>
        )}
      </TabPanel>
    )
  })
  const [dataPoint, setDataPoint] = useState(null)
  const history = useHistory()

  const getDemoghaphyData = (countrySelected, categorySelected, lens) => {
    const request = NetworkUtil.demographicAnalyticsDistribution(
      countrySelected,
      categorySelected,
      lens,
    )
    setShowLoader(true)
    return axios
      .get(request)
      .then((res) => {
        setShowLoader(false)

        setDataPoint(res.data.data.data_point)
        const sum = res.data.data.distribution.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.value
          },
          0,
        )

        const AgeDistributionData = res.data.data.distribution.map((data) => {
          return {
            name: data.name,
            value: ((data.value / sum) * 100).toFixed(2),
          }
        })
        setdemographicData(AgeDistributionData)
      })
      .catch(() => {
        setShowLoader(false)
      })
  }

  const getAllTrends = (
    countrySelected,
    categorySelected,
    ageGroupTab,
    lens,
  ) => {
    const request = NetworkUtil.demographicAnalyticsAllTrends(
      countrySelected,
      categorySelected,
      ageGroupTab,
      lens,
    )
    setShowLoader(true)
    return axios
      .get(request)
      .then((res) => {
        setShowLoader(false)

        setTrendGraphDetails(res.data.data.trend_data)
      })
      .catch(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    if (categorySelected != null || category != null) {
      getDemoghaphyData(
        countrySelected,
        categorySelected || category,
        lens,
      ).then(() => {
        history.push(
          `?country=${encodeURIComponent(
            countrySelected,
          )}&category=${encodeURIComponent(
            categorySelected || category,
          )}&demography=${encodeURIComponent(ageGroupTab)}`,
        )
      })

      getAllTrends(
        countrySelected,
        categorySelected || category,
        ageGroupTab,
        lens.toLowerCase(),
      )
    }
  }, [categorySelected, ageGroupTab, history, lens])

  useEffect(() => {
    shouldLogRecentActivity(
      'Demographic Analytics',
      'Platform',
      `/mui/demographic-analytics`,
    )
  }, [])

  const allCategories = demographyDashboards?.map((i) => i.Category)

  return (
    <>
      <Grid>
        <MainCommonTabs subscriptionDetails={subscriptionDetails} />
        {showLoader && <OverlayLoader />}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            mb: 1.25,
            py: 1.5,
            px: 2,
            backgroundColor: ({ palette }) => palette.background.paper,
          }}
        >
          <SingleSelect
            label=" Country"
            sx={{ width: '180px', mr: 4 }}
            placeholder="USA"
            options={[]}
            value={countrySelected || null}
            disabled
          />

          <SingleSelect
            label="Product Category"
            sx={{ width: '180px' }}
            placeholder="Select Category"
            options={allCategories}
            value={categorySelected || null || category}
            onChange={(e, newVal) => {
              handleCategoryChange(e, newVal)
            }}
          />
        </Stack>

        {!categorySelected ? (
          <SearchNotSelected
            msg={'Select category to view Demographic Analysis'}
          />
        ) : (
          <Box
            sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                pb: 0.5,
              }}
            >
              <Typography sx={{ fontSize: '24px' }}>Trends</Typography>
              <Box>
                <SearchBox
                  sx={{ width: '343px' }}
                  demographyComponent
                  searchValue={searchValue}
                  handleSearch={handleSearchTheme}
                  placeholder="Search Trends"
                />
                <Typography
                  variant="subtitle1"
                  display="inline"
                  marginLeft={'30px'}
                >
                  Select view :{' '}
                </Typography>
                <ButtonGroup aria-label="Table chart button group">
                  <Button
                    variant={viewMode === 'chart' ? 'contained' : 'outlined'}
                    onClick={setChart}
                  >
                    Chart
                  </Button>
                  <Button
                    variant={viewMode === 'table' ? 'contained' : 'outlined'}
                    onClick={setTable}
                  >
                    List
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>

            <Tabs value={tab} onChange={handleChange} aria-label="Lens Tabs">
              {tabs}
            </Tabs>
            <AgeGroupButtons
              selectedDataPoints={selectedDataPoints}
              selected2YearCAGR={selected2YearCAGR}
              selected4YearCAGR={selected4YearCAGR}
              handleChange={handleChange}
              lensToShow={lensToShow}
              viewMode={viewMode}
              setChart={setChart}
              setTable={setTable}
              ageGroupTab={ageGroupTab}
              handleClickMillennials={handleClickMillennials}
              handleClickGenZ={handleClickGenZ}
              handleClickGenX={handleClickGenX}
              searchValue={searchValue}
              handleSearch={handleSearchTheme}
              placeholder="Search Themes"
              handleSelectChange={handleSelectChange}
              allDropdownsList={allDropdownsList}
              applySelectedFilters={applySelectedFilters}
              resetSelectedFilterValues={resetSelectedFilterValues}
              handleFilterClickOpen={handleFilterClickOpen}
              handleCloseFilter={handleCloseFilter}
              open={open}
              anchorEl={anchorEl}
              ageGroupGenX={ageGroupGenX}
              ageGroupGenZ={ageGroupGenZ}
              ageGroupMillanials={ageGroupMillanials}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingBottom: '10px',
              }}
            ></Box>

            {tabsPanel}
          </Box>
        )}
      </Grid>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionsDetails: () => dispatch(fetchSubscriptionsDetails()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemographicAnalysisUI)
