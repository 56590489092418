import React from 'react'
import {
  Box,
  Table,
  Typography,
  TableRow,
  TableBody,
  TableContainer,
} from '@mui/material'
import GenericTableHead from '../../shared/components/GenericTableComponent/GenericTableHead'
import { GenericTableCell, getComparator } from '../../store/utility'
import FilterComponent from '../../shared/components/FilterComponent'

const RegionalAnalyticsTableUI = ({
  tableData,
  tableHeadCells,
  order,
  orderBy,
  handleRequestSort,
  handleSelectChange,
  allDropdownsList,
  applySelectedFilters,
  resetSelectedFilterValues,
  handleFilterClickOpen,
  handleCloseFilter,
  open,
  anchorEl,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
}) => {
  const showLoader = true
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{ py: 2 }}
      >
        <FilterComponent
          handleSelectChange={handleSelectChange}
          allDropdownsList={allDropdownsList}
          applySelectedFilters={applySelectedFilters}
          resetSelectedFilterValues={resetSelectedFilterValues}
          handleFilterClickOpen={handleFilterClickOpen}
          handleCloseFilter={handleCloseFilter}
          open={open}
          anchorEl={anchorEl}
          selectedDataPoints={selectedDataPoints}
          selected2YearCAGR={selected2YearCAGR}
          selected4YearCAGR={selected4YearCAGR}
        />
      </Box>
      <TableContainer sx={{ height: '450px', boxShadow: 'none !important' }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          aria-label="generalised table"
        >
          <GenericTableHead
            headCells={tableHeadCells}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            removePadding={false}
          />

          {tableData.length ? (
            <TableBody>
              {tableData.sort(getComparator(order, orderBy)).map((row) => (
                <TableRow data-testId="tableRow">
                  <>
                    <GenericTableCell
                      rowData={row}
                      tableHeadCells={tableHeadCells}
                    />
                  </>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            !showLoader && (
              <Box
                sx={{
                  position: 'relative',
                  left: '45vw',
                  top: '150px',
                }}
              >
                <Typography variant="subtitle2">No data found</Typography>
              </Box>
            )
          )}
        </Table>
      </TableContainer>
    </>
  )
}

export default RegionalAnalyticsTableUI
