import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  SvgIcon,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'

import {
  useCompare,
  useCompareDispatch,
} from '../../../shared/contexts/CompareContext'
import SentimentCard from '../SentimentCard'
import { DetailsContainer, IngredientCardContainer, BoxScroll } from './styles'
import { ReactComponent as DormantIcon } from '../../../assets/icons/trend-dormant.svg'
import { ReactComponent as EmergingIcon } from '../../../assets/icons/trend-emerging.svg'
import { ReactComponent as GrowingIcon } from '../../../assets/icons/trend-growing.svg'
import { ReactComponent as MatureIcon } from '../../../assets/icons/trend-mature.svg'
import { ReactComponent as DecliningIcon } from '../../../assets/icons/trend-declining.svg'
import { ReactComponent as LineChartIcon } from '../../../assets/icons/line-chart.svg'
import { ReactComponent as ParingIcon } from '../../../assets/icons/paring.svg'
import { ReactComponent as AddToCompareIcon } from '../../../assets/icons/add-to-compare.svg'
import { ReactComponent as AddedToCompareIcon } from '../../../assets/icons/added-to-compare.svg'
import { ReactComponent as TransitionFlagIcon } from '../../../assets/icons/transitionFlag.svg'

import countriesList from '../../../mocks/country'
import InformationIcon from '../InformationIcon'
import PairingDialogLink from '../PairingDialogLink'
import DownloadButton from '../DownloadButton'
import ExpandableChipsGroup from '../ExpandableChipsGroup'
import { amplify, convertTextToTitleCase } from './../../../store/utility'
import HideOnScrollAppbar from './../HideOnScrollAppbar'
import ShareCard from './../ShareCard/index'

import { trendTypes } from '../../../shared/utils/constants'
import Bookmarked from '../Bookmarked/Bookmarked'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import AddFavoriteButton from '../OtherFavorites/AddFavoriteButton'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'

const amp = (props, ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Add_To_Compare':
        amplitudeUserData = {
          User: ampiInstance._email,
          Trend_Name: ampiInstance._name,
          Country_Name: ampiInstance._country,
          Category_Name: ampiInstance._category,
          Lens: props.match.params.urlLensSelected,
          Maturity_Phase: ampiInstance._trendName,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
      case 'Clicked_Copy':
        amplitudeUserData = {
          User: ampiInstance._email,
          Trend_Name: ampiInstance._name,
          Country_Name: ampiInstance._country,
          Category_Name: ampiInstance._category,
          Link_Copied: ampiUserData.linkCopied,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in IngredientDetailsTop')
  }
}

const IngredientDetailsTop = (props) => {
  const {
    projectid,
    productid,
    name,
    trendName,
    type,
    rank,
    dataPoint,
    productImage,
    filterClassfn,
    positiveSentiment,
    neutralSentiment,
    negativeSentiment,
    pairingDetails,
    details,
    toggleImageDownload,
    consolidatedExcelDownlaod,
    togglePptDownload,
    projectVariant,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const inCompareTrends = useCompare()
  const dispatch = useCompareDispatch()

  const isAddedToCompare = inCompareTrends.some(
    (item) => item._id === productid,
  )
  const numberOfPaires =
    pairingDetails && pairingDetails.nodes && pairingDetails.nodes.length > 0
      ? pairingDetails.nodes.length - 1
      : 0
  const country = countriesList.find((item) => item.label === props.country)
  const breadcrumbs = [
    <Link variant="breadcrumbsLink" key="1" href="/">
      Foresight Engine
    </Link>,
    <Link
      variant="breadcrumbsLink"
      key="2"
      href={`/mui/product-category/${projectid}`}
    >
      {props.country}&nbsp;{props.category}
    </Link>,
    <Typography key="3" variant="subtitle2">
      {name}
    </Typography>,
  ]

  function copy() {
    navigator.clipboard
      .writeText(location.href)
      .then(() => {
        enqueueSnackbar('Link is copied!', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar('Unable to copy link', { variant: 'error' })
      })
    const ampiEvent = 'Clicked_Copy'
    const ampiUserData = {
      linkCopied: location.href,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  let trendTooltipText =
    'Overall maturity of the trend in this particular category. '
  switch (trendName) {
    case 'Growing':
    case 'Emerging':
    case 'Declining':
    case 'Fading':
      trendTooltipText += `For ${trendName} trend, ranking is based on total engagement, number of food service and retail products and growth rate.`
      break
    case 'Dormant':
    case 'Matured':
      trendTooltipText += `For ${trendName} trend, ranking is based on total engagement and number of food service and retail products.`
      break
  }

  const compareHandler = () => {
    if (
      !localStorage.getItem('febetaProductLens') ||
      localStorage.getItem('febetaProductLens') ===
        props.match.params.urlLensSelected ||
      !localStorage.getItem('febetaComparison') ||
      JSON.parse(localStorage.getItem('febetaComparison')) === 0
    ) {
      localStorage.setItem('febetaProject', projectid)
      localStorage.setItem(
        'febetaProductLens',
        props.match.params.urlLensSelected,
      )
      !isAddedToCompare && dispatch({ type: 'added', payload: details })
      const ampiEvent = `Clicked_Add_To_Compare`
      amp(props, ampiEvent)
    } else {
      dispatch({ type: 'retrieve' })
    }
  }

  const trendTransitioning = details.transitionStatus?.isTransitioning

  useEffect(() => {
    if (props.country && props.category && name && projectid && productid) {
      shouldLogRecentActivity(
        `Explore Trends>${props.country} ${props.category}>${name}`,
        'Platform',
        `/mui/details/${projectid}/ingredient/${productid}`,
      )
    }
  }, [props.country, props.category, name, projectid, productid])

  return (
    <IngredientCardContainer>
      <HideOnScrollAppbar>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </HideOnScrollAppbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 1,
          mb: -1,
        }}
      >
        <Typography variant="h4" color="textSecondary">
          Ingredient Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <ShareCard
            trendName={name}
            trendId={productid}
            country={props.country}
            category={props.category}
            location={props.match.url}
            productImage={props.productImage}
          />
          <AddFavoriteButton
            favoriteSectionType={'Trends under Favorites'}
            projectId={projectid}
            productId={productid}
            trendName={name}
            trendType={'Ingredient'}
            reDirectionUrl={props.location.pathname}
            breadcrumbs={`Foresight Engine, ${props.country} ${props.category}, ${name}`}
          />
          {/* <Bookmarked {...props} /> */}
          <Button
            variant="outlined"
            size="small"
            startIcon={<ContentCopyRoundedIcon />}
            onClick={copy}
          >
            Copy Link to Clipboard
          </Button>
          <DownloadButton
            handleDownloadExcel={consolidatedExcelDownlaod}
            toggleImageDownload={toggleImageDownload}
            lensSelected="Ingredient"
            title="_Trend_Page"
            dowImageType=""
            togglePptDownload={togglePptDownload}
          />
          <Button
            variant="outlined"
            size="small"
            disabled={isAddedToCompare}
            startIcon={
              isAddedToCompare ? (
                <SvgIcon
                  component={AddedToCompareIcon}
                  sx={{ color: 'primary.main' }}
                />
              ) : (
                <SvgIcon component={AddToCompareIcon} />
              )
            }
            onClick={compareHandler}
          >
            {isAddedToCompare ? 'Added to Compare' : 'Add to Compare'}
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            '& hr': {
              height: 20,
            },
          }}
        >
          <Typography variant="h2">{name}</Typography>
          <Divider orientation="vertical" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '& > img': {
                mr: 1,
                flexShrink: 0,
                overflow: 'hidden',
                objectFit: 'unset',
                borderRadius: 0,
              },
            }}
          >
            <img
              loading="lazy"
              width="30"
              height="20"
              src={
                country?.img
                  ? country?.img
                  : `https://flagcdn.com/${(
                      country || { code: '' }
                    ).code.toLowerCase()}.svg`
              }
              srcSet={
                country?.img
                  ? country?.img
                  : `https://flagcdn.com/w40/${(
                      country || { code: '' }
                    ).code.toLowerCase()}.png 2x`
              }
              alt=""
            />
            <Typography variant="subtitle1">{props.country}</Typography>
          </Box>
          <Divider orientation="vertical" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <SvgIcon component={LineChartIcon} />
            <Typography variant="subtitle1">
              {dataPoint}
              &nbsp; Data Points
            </Typography>
          </Box>
          {numberOfPaires > 0 && projectVariant === 'food' && (
            <>
              <Divider orientation="vertical" />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <SvgIcon
                  component={ParingIcon}
                  sx={{ color: 'secondary.main' }}
                />
                <Typography variant="subtitle1">
                  Paired with &nbsp;
                  <PairingDialogLink
                    numberOfPairs={numberOfPaires}
                    pairingDetails={pairingDetails}
                    lens="Ingredient"
                    name={name}
                    country={props.country}
                    category={props.category}
                    twoYearCagr={details.twoYearCagr}
                    projectid={projectid}
                    productId={productid}
                    breadcrumbs={`Foresight Engine, ${props.country} ${props.category}, ${name}`}
                  />
                </Typography>
                <InformationIcon
                  tooltipText={`${name} is paired together with ${numberOfPaires} other ingredients in Foodservice and Retail products`}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, height: (t) => t.spacing(19.5) }}>
        <img src={productImage} alt="product" width={156} height={156} />
        <DetailsContainer>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              minWidth: 200,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="caption" color="textSecondary">
                Trend Ranking and Maturity
              </Typography>
              <InformationIcon tooltipText={trendTooltipText} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
            >
              <Typography variant="h1" sx={{ color: 'secondary.main' }}>
                #{rank}
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'secondary.main',
                    marginLeft: trendTransitioning ? '26px' : 0,
                  }}
                >
                  {trendName}
                </Typography>
                {trendTransitioning && (
                  <Tooltip title={details.transitionStatus.message}>
                    <Box
                      sx={{
                        height: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        mt: '10px',
                      }}
                      width={24}
                    >
                      <SvgIcon component={TransitionFlagIcon} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
              <Typography mt={1} variant="subtitle2">
                {props.category}
              </Typography>
            </Box>
          </Box>

          <Divider orientation="vertical" />
          {trendTypes.find((t) =>
            Object.keys(filterClassfn).includes(t.id),
          ) && (
            <BoxScroll sx={{ p: 2, flexGrow: 1, overflowY: 'hidden' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'sticky',
                  left: '0px',
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  Presence in Sub-Category Trends &emsp;
                </Typography>
                <InformationIcon
                  subCat
                  tooltipText="Sub-category is defined from the Consumer’s point of view. This is sometimes slightly different from a retailer’s category definition, as it takes into account the driver and consumption occasion according to the consumer to define the boundaries of a category and sub-category. Find sub-category definitions here: https://premium.aipalette.com/faq   "
                />
              </Box>
              <Grid
                container
                sx={{ py: 2, height: '100%', flexWrap: 'nowrap' }}
              >
                {trendTypes
                  .filter((t) => Object.keys(filterClassfn).includes(t.id))
                  .map(({ id, title, icon }, i) => (
                    <Grid
                      item
                      key={id}
                      sx={{ display: 'flex', width: '200px' }}
                    >
                      {!!i && <Divider orientation="vertical" sx={{ mx: 1 }} />}
                      <Box>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <SvgIcon
                            fontSize="small"
                            component={icon}
                            color="secondary"
                          />
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'secondary.dark' }}
                          >
                            {title}
                          </Typography>
                        </Box>
                        <ExpandableChipsGroup
                          maxHeight={60}
                          trends={filterClassfn[id].split(';')}
                          addTooltip
                        />
                        {/* <ExpandableChips trends={Object.values(filterClassfn)[i].split(';')} /> */}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </BoxScroll>
          )}
        </DetailsContainer>
        <Box sx={{ pl: 1 }}>
          <SentimentCard
            positiveSentiment={positiveSentiment}
            neutralSentiment={neutralSentiment}
            negativeSentiment={negativeSentiment}
          />
        </Box>
      </Box>
    </IngredientCardContainer>
  )
}

const ExpandableChips = ({ trends }) => {
  const [shownTrends, setShownTrends] = useState(trends.slice(0, 3))
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        pt: 2,
      }}
    >
      {shownTrends.map((item) => (
        <Chip variant="outlined" label={item} />
      ))}
      {shownTrends.length < trends.length && (
        <Chip
          variant="outlined"
          label={`+${trends.length - shownTrends.length}`}
          onClick={() => setShownTrends(trends)}
        />
      )}
    </Box>
  )
}

ExpandableChips.propTypes = {
  trends: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default withRouter(connect(null, null)(IngredientDetailsTop))
