import { Box, styled } from '@mui/system'

export const PopupBox = styled(Box)(() => ({
  position: 'fixed',
  background: '#00000050',
  width: '100%',
  height: '100vh',
  top: '0',
  left: '0',
}))

export const Boxes = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '412px',
  margin: '0 auto',
  height: 'auto',
  maxHeight: '373px',
  marginTop: 'calc(115vh - 85vh - 20px)',
  backgroundColor: `${theme.palette.white}`,
  borderRadius: '4px',
  border: '1px solid #D8D8D83C',
  overflow: 'auto',
  textAlign: 'center',
  '.container': {
    paddingBottom: '30px',
  },
}))
