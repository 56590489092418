import { updateObject } from '../utility'
import { SubscriptionDetailsAction } from '../actions/actionTypes'

const initialState = { organizationSubscriptionDetails: null }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionDetailsAction.type[
      SubscriptionDetailsAction.FETCH_SUBSCRIPTION_DETAILS_SUCCESS
    ]:
      return updateObject(state, {
        organizationSubscriptionDetails: action.subscriptionDetails,
      })

    case SubscriptionDetailsAction.type[
      SubscriptionDetailsAction.FETCH_SUBSCRIPTION_DETAILS_FAIL
    ]:
      return updateObject(state, {
        organizationSubscriptionDetails: null,
      })

    default:
      return state
  }
}

export default reducer
