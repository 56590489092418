import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLoader } from '../../hooks'

import RegionalAnalyticsUI from './RegionalAnalyticsUI'
import AnalyticsHoverCard from './AnalyticsHoverCard'
import SavedProjectsList from '../../services/networking/Models/SavedProjectsList'

import NetworkingUtil from '../../services/networking/NetworkingUtil'
import axios from './../../services/networking/axios'

import { LENSES } from '../../shared/utils/constants'
import { getCapitalzedText, amplify } from './../../store/utility'
import { AmplitudeValues } from '../../store/amplitudeValues'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

const defaultCountrySelected = {
  key: 0,
  label: 'USA',
}

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
    }
    switch (ampiEvent) {
      case 'Clicked_Regional_Filter':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Region_Name: ampiUserData.region.label,
          Data_Points: ampiUserData.dataPoints || 'NULL',
          '2_Year_CAGR': ampiUserData.twoYearCagr || 'NULL',
          '4_Year_CAGR': ampiUserData.fourYearCagr || 'NULL',
        }
        break
      case 'Clicked_Regional_Trend':
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Region_Name: ampiUserData.region.label,
          Trend_Name: ampiUserData.trendName,
          Redirected_From: ampiUserData.redirectedFrom,
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          ...ampiUserData,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
  } catch (err) {
    console.log(err, 'Amplitude error in RegionalAnalytics')
  }
}

const calculateMedian = (array) => {
  if (!array?.length) {
    return null
  }
  const sortedArray = [...array].sort((a, b) => b - a)
  const arrLength = sortedArray.length
  if (arrLength % 2 === 0) {
    const medianVal =
      (sortedArray[arrLength / 2] + sortedArray[arrLength / 2 - 1]) / 2
    return medianVal
  }
  return sortedArray[Math.floor(arrLength / 2)]
}

const RegionalAnalytics = ({ subscriptionDetails }) => {
  const resetBtnId = 'zoom-reset-btn'
  const [lensSelected, setLensSelected] = useState(LENSES[0])
  const [search, setSearch] = useState('')
  const [viewTab, setViewTab] = useState('graph')
  const [countrySelected, setCountrySelected] = useState(defaultCountrySelected)
  const [regionSelected, setRegionSelected] = useState(null)
  const [productCategorySelected, setProductCategorySelected] = useState(null)
  const [tooltipData, setTooltipData] = useState({})
  const [regionSelectionOptions, setRegionSelectionOptions] = useState([])
  const [regionalTrends, setRegionalTrends] = useState()
  const [projectList, setProjectList] = useState()
  const [needCategories, setNeedCategories] = useState()
  const [projectId, setProjectId] = useState()
  const [loader, setLoader] = useLoader()
  const [resetBtn, setResetBtn] = useState(false)

  const history = useHistory()

  // Amplitude logging
  const logRegionSelection = (newVal) => {
    if (newVal?.label) {
      const event = 'Selected_Region'
      const ampData = {
        Region: newVal?.label,
      }
      amp(event, ampData)
    }
  }

  const logViewToggleClick = (view) => {
    const event =
      view === 'graph'
        ? 'Clicked_Regional_Chart_View'
        : 'Clicked_Regional_List_View'
    const ampData = {
      Lens: lensSelected,
      'Category Name': productCategorySelected.label,
      Region: regionSelected.label,
    }
    amp(event, ampData)
  }

  const logConsumerInsightClick = (needCategory, keyword) => {
    const event = 'Clicked_Regional_Consumer_Insights'
    const ampData = {
      Lens: lensSelected,
      'Category Name': productCategorySelected.label,
      Region: regionSelected.label,
      'Consumer Insight': keyword,
      'Consumer Insight Category': needCategory,
    }
    amp(event, ampData)
  }

  const logAppliedFilters = (dataPoints, twoYearCagr, fourYearCagr) => {
    const ampiEvent = 'Clicked_Regional_Filter'
    const ampiUserData = {
      lens: lensSelected,
      country: countrySelected,
      category: productCategorySelected,
      region: regionSelected,
      dataPoints,
      twoYearCagr,
      fourYearCagr,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logRegionalTrendClick = (redirectedFrom, trendName) => {
    const ampiEvent = 'Clicked_Regional_Trend'
    const ampiUserData = {
      lens: lensSelected,
      country: countrySelected,
      category: productCategorySelected,
      region: regionSelected,
      redirectedFrom,
      trendName,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleToggleSelection = (event, toggle) => {
    if (toggle !== null) {
      setLensSelected(toggle)
    }
  }

  const handleViewTabChange = (newViewTab) => {
    setViewTab(newViewTab)
    logViewToggleClick(newViewTab)
  }

  const handleRegionChange = (e, newVal) => {
    setRegionSelected(newVal)
    setProductCategorySelected('')
    logRegionSelection(newVal)
  }

  const handleCountryChange = (e, newVal) => {
    setCountrySelected(newVal)
  }

  const handleProductCategoryChange = (e, newVal) => {
    setProductCategorySelected(newVal)
  }

  const countrySelectionOpt =
    regionSelectionOptions &&
    [...new Set(regionSelectionOptions.map((opt) => opt.country))].map(
      (country, i) => {
        return { key: i, label: country }
      },
    )

  const regionSelectionOpt =
    regionSelectionOptions &&
    [
      ...new Set(
        regionSelectionOptions
          .filter((opt) => {
            return opt.country === countrySelected?.label
          })
          .map((opt) => opt.region),
      ),
    ].map((region, i) => {
      return { key: i, label: region }
    })

  const categorySelectionOpt =
    regionSelectionOptions &&
    [
      ...new Set(
        regionSelectionOptions
          .filter((opt) => {
            return (
              opt.country === countrySelected?.label &&
              opt.region === regionSelected?.label
            )
          })
          .map((opt) => opt.category),
      ),
    ].map((category, i) => {
      return { key: i, label: category }
    })

  const modifiedData = useMemo(
    () =>
      regionalTrends?.map((trend) => {
        return {
          ...trend,
          xScaleData: trend.final_engagement_score,
          yScaleData: trend.two_year_cagr,
        }
      }),
    [regionalTrends, projectId],
  )

  const minYDomain =
    modifiedData &&
    modifiedData.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.yScaleData < curr.yScaleData ? prev : curr
    })

  const maxYDomain =
    modifiedData &&
    modifiedData.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.yScaleData > curr.yScaleData ? prev : curr
    })

  const minXDomain =
    modifiedData &&
    modifiedData.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.xScaleData < curr.xScaleData ? prev : curr
    })

  const maxXDomain =
    modifiedData &&
    modifiedData.length &&
    modifiedData?.reduce(function(prev, curr) {
      return prev.xScaleData > curr.xScaleData ? prev : curr
    })

  const graphHelperData = {
    xAxisText: 'Engagement',
    yAxisText: 'Growth Rate ',
    scaleX: 'scaleLog',
    scaleY: 'scaleSymlog',
    domain: [
      Math.round(minXDomain?.xScaleData / 10) * 10,
      Math.round(maxXDomain?.xScaleData / 10) * 10 + 10,
    ],
    yAxisTextis: 'The growth rate is measured by 2 Year CAGR',
    xAxisTextis: 'This represents the engagement score',
    yDomain: [
      Math.round(minYDomain?.yScaleData / 10) * 10 - 10,
      Math.round(maxYDomain?.yScaleData / 10) * 10 + 10,
    ],
  }

  const medianXVal = useMemo(
    () =>
      calculateMedian(modifiedData?.map((data) => data.final_engagement_score)),
    [modifiedData],
  )
  const medianYVal = useMemo(
    () => calculateMedian(modifiedData?.map((data) => data.two_year_cagr)),
    [modifiedData],
  )

  const searchFiltreData = useMemo(
    () =>
      search !== ''
        ? modifiedData.filter((d) =>
            d.name.toLowerCase().includes(search.toLowerCase()),
          )
        : modifiedData,
    [modifiedData, search, projectId],
  )

  const getProjectId = (country, category) => {
    return projectList?.find(
      (data) => data._name === category && data._criteria === country,
    )?._id
  }

  const hanleOpenTrendPage = (trendName, trendId) => {
    logRegionalTrendClick('Graph View', trendName)
    const link = viewTableNameClicked(trendName, trendId)
    window.open(link, '_blank')
  }

  const viewDetailsClicked = (trend) => {
    logRegionalTrendClick('Graph View', trend.name)
    const link = `/mui/regional-analytics/?projectId=${encodeURIComponent(
      projectId,
    )}&lens=${encodeURIComponent(lensSelected)}&country=${encodeURIComponent(
      countrySelected.label,
    )}&category=${encodeURIComponent(
      productCategorySelected.label,
    )}&region=${encodeURIComponent(
      regionSelected.label,
    )}&trend=${encodeURIComponent(trend.name)}&trendId=${encodeURIComponent(
      trend.trend_id,
    )}`
    window.open(link, '_blank')
  }

  const viewTableNameClicked = (name, id) => {
    const link = `/mui/regional-analytics/?projectId=${encodeURIComponent(
      projectId,
    )}&lens=${encodeURIComponent(lensSelected)}&country=${encodeURIComponent(
      countrySelected.label,
    )}&category=${encodeURIComponent(
      productCategorySelected.label,
    )}&region=${encodeURIComponent(
      regionSelected.label,
    )}&trend=${encodeURIComponent(name)}&trendId=${encodeURIComponent(id)}`
    return link
  }

  const handleHover = (graphPointData) => {
    const tooltip = {
      image: graphPointData.image,
      name: graphPointData.name,
      twoYearCagr: graphPointData.two_year_cagr,
      fourYearCagr: graphPointData.four_year_cagr,
      trendId: graphPointData.trend_id,
      engagementScore: graphPointData.final_engagement_score,
    }
    setTooltipData(tooltip)
  }

  const scatteredPlotTooltip = (
    <AnalyticsHoverCard
      image={tooltipData.image}
      name={tooltipData.name}
      engagementScore={tooltipData.engagementScore}
      twoYearCagr={tooltipData.twoYearCagr}
      fourYearCagr={tooltipData.fourYearCagr}
      viewDetailsLink={() =>
        hanleOpenTrendPage(tooltipData.name, tooltipData.trendId)
      }
    />
  )

  const getRegionalGraphData = () => {
    if (
      productCategorySelected?.label &&
      countrySelected?.label &&
      regionSelected?.label
    ) {
      setLoader(true)
      const allTrendRequest = NetworkingUtil.regionalScatteredGraph(
        countrySelected.label,
        productCategorySelected.label,
        lensSelected.toLowerCase(),
        regionSelected.label,
      )
      axios
        .get(allTrendRequest)
        .then((res) => {
          setLoader(false)
          const graphResponse = res.data.data
          setRegionalTrends(graphResponse.trend_data)
        })
        .catch(() => {
          setLoader(false)
          console.log('error in getting regional scattered graph data')
        })
    }
  }

  const fetchRegionalDashboardList = () => {
    setLoader(true)
    const request = NetworkingUtil.regionalDashboardList()
    axios
      .get(request)
      .then((res) => {
        setLoader(false)
        const dashboardListResponse = res.data.data
        const formattedOptions = dashboardListResponse.map((data) => {
          return {
            country: data.Country,
            category: data.Category,
            region: data.Region,
          }
        })
        setRegionSelectionOptions(formattedOptions)
      })
      .catch(() => {
        setLoader(false)
        console.log('error in getting regional dashboard list')
      })
  }

  const fetchProjectList = () => {
    setLoader(true)
    const request = NetworkingUtil.formSavedProjectListRequest()
    return axios
      .get(request)
      .then((response) => {
        setLoader(false)
        const projects = new SavedProjectsList(response.data)
        setProjectList(projects.list)
      })
      .catch((error) => {
        setLoader(false)
        console.log('error in fetching project list')
      })
  }

  const fetchOverallCN = () => {
    if (
      countrySelected?.label &&
      regionSelected?.label &&
      productCategorySelected?.label &&
      projectList
    ) {
      const projectId = getProjectId(
        countrySelected.label,
        productCategorySelected.label,
      )
      const request = NetworkingUtil.formRequestForRegionalProjectNeedCategoriesOverall(
        projectId,
        regionSelected.label,
      )
      return axios
        .get(request)
        .then((response) => {
          const cn = response.data
          setNeedCategories(cn.need_categories)
        })
        .catch((error) => {
          console.log('error in fetching overall consumer nedd')
        })
    }
  }

  const setStateFromRoute = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const lens = queryParams.get('lens')
    const region = { key: 0, label: queryParams.get('region') }
    const category = { key: 0, label: queryParams.get('category') }
    if (lens && LENSES.find((l) => l.toLowerCase() === lens.toLowerCase())) {
      setLensSelected(getCapitalzedText(lens))
    }
    if (region.label && category.label) {
      const matchedRegion = regionSelectionOptions.find(
        ({ region: r, category: c }) =>
          r === region.label && c === category.label,
      )
      if (matchedRegion) {
        setRegionSelected(region)
        setProductCategorySelected(category)
      }
    }
  }

  useEffect(() => {
    fetchProjectList()
    fetchRegionalDashboardList()
    shouldLogRecentActivity(
      'Regional Analytics',
      'Platform',
      `/mui/regional-analytics`,
    )
  }, [])

  useEffect(() => {
    setStateFromRoute()
  }, [regionSelectionOptions])

  useEffect(() => {
    if (projectList) {
      setNeedCategories('')
      fetchOverallCN()
      if (countrySelected?.label && productCategorySelected?.label) {
        const id = getProjectId(
          countrySelected.label,
          productCategorySelected.label,
        )
        setProjectId(id)
      } else {
        setProjectId()
      }
    }
  }, [projectList, productCategorySelected])

  useEffect(() => {
    getRegionalGraphData()
    setSearch('')
    if (regionSelected?.label && productCategorySelected?.label) {
      const link = `?lens=${encodeURIComponent(
        lensSelected,
      )}&category=${encodeURIComponent(
        productCategorySelected?.label,
      )}&region=${encodeURIComponent(regionSelected?.label)}`
      history.push(link)
    }
  }, [productCategorySelected, lensSelected])

  return (
    <RegionalAnalyticsUI
      loader={loader}
      subscriptionDetails={subscriptionDetails}
      handleToggleSelection={handleToggleSelection}
      lensSelected={lensSelected}
      // regionSelectionOpt={regionSelectionOpt}
      regionSelected={regionSelected}
      productCategorySelected={productCategorySelected}
      handleRegionChange={handleRegionChange}
      handleProductCategoryChange={handleProductCategoryChange}
      trendGraphData={searchFiltreData}
      graphHelperData={graphHelperData}
      viewTab={viewTab}
      handleViewTabChange={handleViewTabChange}
      needCategories={needCategories}
      viewDetailsClicked={viewDetailsClicked}
      handleSearch={setSearch}
      scatteredPlotTooltip={scatteredPlotTooltip}
      search={search}
      handleHover={handleHover}
      countrySelected={countrySelected}
      handleCountryChange={handleCountryChange}
      countrySelectionOpt={countrySelectionOpt}
      regionSelectionOpt={regionSelectionOpt}
      logConsumerInsightClick={logConsumerInsightClick}
      categorySelectionOpt={categorySelectionOpt}
      projectId={projectId}
      viewTableNameClicked={viewTableNameClicked}
      medianXVal={medianXVal}
      medianYVal={medianYVal}
      resetBtn={resetBtn}
      setResetBtn={setResetBtn}
      resetBtnId={resetBtnId}
      logAppliedFilters={logAppliedFilters}
      logRegionalTrendClick={logRegionalTrendClick}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

export default connect(mapStateToProps, null)(RegionalAnalytics)
