import React, { useState, useEffect } from 'react'
import { addDays, addYears } from 'date-fns'
import BasicInfoUI from './BasicInfoUI'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { useLoader } from '../../../hooks'
import OverlayLoader from '../../../shared/components/OverlayLoader'

const BasicInfoTab = ({
  setDisablesTab,
  setTab,
  setClientModification,
  selectedRow,
  edit,
  setSelectedRow,
  setEdit,
  getClientData,
}) => {
  const [data, setData] = useState()
  const [clientName, setClientName] = useState('')
  const [numberOfUsers, setNumberOfUsers] = useState()
  const [accountType, setaccountType] = useState('')
  const [endDate, setEndDate] = useState(null)
  const [accountTypeError, setAccountTypeError] = useState(false)
  const [dateError, setDateError] = useState()
  const [savedData, setSavedData] = useState(false)
  const [isEndDateEmpty, setEmptyDateError] = useState(false)
  const [showLoader, setShowLoader] = useLoader()
  const getData = async () => {
    const request = NetworkUtil.userAccessManagementid(selectedRow?.clientId)
    setShowLoader(true)
    await axios.get(request).then((res) => {
      setData(res.data.data)
      setClientName(res.data.data.name)
      setNumberOfUsers(res.data.data.max_authorised_users)
      setaccountType(res.data.data.client_type)
      setEndDate(res.data.data.dashboard_expiration_date)
      setShowLoader(false)
    })
  }
  useEffect(() => {
    if (edit === true) {
      getData()
    }
  }, [edit])

  const postData = () => {
    const request = NetworkUtil.userAccessManagement()
    const clientDetails = {
      name: clientName,
      max_users: numberOfUsers,
      client_type: accountType,
      end_date: endDate,
    }
    setShowLoader(true)
    axios.post(request, clientDetails).then((res) => {
      setData(clientDetails)
      setSelectedRow({ clientId: res.data.data.id })
      setEdit(true)
      setShowLoader(false)
      setDisablesTab(['Dashboard assigned'])
      setTab(1)
    })
  }

  const updatedInfo = async () => {
    const req = NetworkUtil.userAccessManagementid(selectedRow?.clientId)
    const clientDetails = {
      id: selectedRow?.clientId,
      name: clientName,
      max_users: numberOfUsers,
      client_type: accountType,
      end_date: endDate,
    }
    setShowLoader(true)
    await axios.put(req, clientDetails).then((res) => {
      setData(clientDetails)
      setShowLoader(false)
      getClientData()
      setTab(1)
    })
  }

  const handleClientName = (e) => {
    setClientName(e.target.value)
  }

  const handleChangeNumberofUsers = (e) => {
    setNumberOfUsers(e.target.value)
  }

  const settingErrorState = () => {
    if (accountType === '') {
      setAccountTypeError(true)
    } else {
      setAccountTypeError(false)
    }
    if (endDate === null) {
      setEmptyDateError(true)
    } else {
      setEmptyDateError(false)
    }
  }
  const handleNext = () => {
    settingErrorState()
    if (!dateError && accountType && endDate && edit) {
      updatedInfo()
    }
  }
  const handleSaveClientInfo = () => {
    setSavedData(true)
    settingErrorState()
    if (!dateError && accountType && endDate && edit) {
      updatedInfo()

      setClientModification(0)
    }
  }
  const onSubmit = () => {
    if (clientName && numberOfUsers && accountType && endDate && !edit) {
      if (savedData) {
        postData()
        setClientModification(0)
      } else postData()
    }
  }
  const handleExit = () => {
    setClientModification(0)
  }
  const maxDateLimit = addYears(new Date(), 10)
  const minDate = addDays(new Date(), -1)
  const handleDate = (newValue) => {
    setEndDate(newValue)
    newValue > maxDateLimit || newValue < minDate
      ? setDateError(true)
      : setDateError(false)
    setEmptyDateError(false)
  }
  const handleTrialAccount = () => {
    setaccountType('TRIAL')
    setAccountTypeError(false)
  }

  const handleLicensedAccount = () => {
    setaccountType('LICENSED')
    setAccountTypeError(false)
  }
  const handleClientNameChange = (e, onChange) => {
    onChange(e)
    handleClientName(e)
  }
  const handleUser = (e, onChange) => {
    handleChangeNumberofUsers(e)
    onChange(e)
  }
  return (
    <>
      {showLoader && <OverlayLoader />}
      <BasicInfoUI
        handleLicensedAccount={handleLicensedAccount}
        handleTrialAccount={handleTrialAccount}
        handleDate={handleDate}
        onSubmit={onSubmit}
        handleSaveClientInfo={handleSaveClientInfo}
        handleChangeNumberofUsers={handleChangeNumberofUsers}
        handleClientName={handleClientName}
        handleExit={handleExit}
        clientName={clientName}
        numberOfUsers={numberOfUsers}
        accountType={accountType}
        endDate={endDate}
        maxDateLimit={maxDateLimit}
        accountTypeError={accountTypeError}
        dateError={dateError}
        handleClientNameChange={handleClientNameChange}
        handleUser={handleUser}
        minDate={minDate}
        data={data}
        handleNext={handleNext}
        isEndDateEmpty={isEndDateEmpty}
      />
    </>
  )
}

export default BasicInfoTab
