import {
  maturityPhaseCell,
  bucketNameCell,
  arrowIcon,
  returnAsItIs,
  formatDataPoints,
  roundOffFloat,
  renderActionIcon,
  returnTrends,
} from './CellFunctions'

const tooltipTextForSubTableCagr = `This is calculated for social data and search data`
const tooltipTextForMainTableCagr = `This is calculated for social data only`
const TrendBucketHeadCells = [
  {
    id: 'arrow',
    numeric: false,
    disablePadding: true,
    label: '',
    sorting: false,
    func: arrowIcon,
  },
  {
    id: 'pillarName',
    numeric: false,
    disablePadding: true,
    label: 'Pillar Name',
    width: '100px',
    sorting: true,
    func: bucketNameCell,
  },
  {
    id: 'country',
    numeric: true,
    disablePadding: false,
    label: 'Country',
    sorting: true,
    func: returnAsItIs,
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Category',
    sorting: true,
    func: returnAsItIs,
  },
  {
    id: 'ofTrends',
    numeric: false,
    disablePadding: false,
    label: '# Trends',
    width: '83px',
    sorting: true,
    func: returnAsItIs,
  },
  {
    id: 'dataPoints',
    numeric: false,
    disablePadding: false,
    label: 'Data points',
    width: '85px',
    sorting: true,
    func: formatDataPoints,
  },
  {
    id: 'fourYearCagr',
    numeric: false,
    disablePadding: false,
    label: '4 Year CAGR',
    width: '98px',
    sorting: true,
    // tooltipText: tooltipTextForMainTableCagr,
    func: roundOffFloat,
  },
  {
    id: 'twoYearCagr',
    numeric: false,
    disablePadding: false,
    label: '2 Year CAGR',
    width: '87px',
    sorting: true,
    // tooltipText: tooltipTextForMainTableCagr,
    func: roundOffFloat,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sorting: false,
    func: ({ action }) => action,
  },
]

export const themeHeadCells = (() => {
  const headCell = [...TrendBucketHeadCells]
  headCell.splice(
    8,
    0,
    {
      id: 'ofRelatedIngredients',
      numeric: false,
      disablePadding: false,
      label: '# of related ingredients',
      width: '87px',
      sorting: true,
      func: formatDataPoints,
    },
    {
      id: 'ofRelatedProducts',
      numeric: false,
      disablePadding: false,
      label: '# of related products',
      width: '87px',
      sorting: true,
      func: formatDataPoints,
    },
  )
  return headCell
})()

export const ingProdHeadCells = (() => {
  const headCell = [...TrendBucketHeadCells]
  headCell.splice(
    8,
    0,
    {
      id: 'ofRelatedIngredients',
      numeric: false,
      disablePadding: false,
      label: '# FS Products',
      width: '87px',
      sorting: true,
      func: formatDataPoints,
    },
    {
      id: 'ofRelatedProducts',
      numeric: false,
      disablePadding: false,
      label: '# Retail Products',
      width: '87px',
      sorting: true,
      func: formatDataPoints,
    },
  )
  return headCell
})()

export const TrendBucketSubHeadCells = [
  {
    id: 'trend_Name',
    numeric: false,
    disablePadding: false,
    label: 'Trend Name',
    sorting: true,
    width: '150px',
    func: returnTrends,
  },
  {
    id: 'data_points',
    numeric: false,
    disablePadding: false,
    label: 'Data Points',
    sorting: true,
    width: '150px',
    func: formatDataPoints,
  },
  {
    id: 'rank',
    numeric: false,
    disablePadding: false,
    label: 'Rank',
    sorting: true,
    width: '100px',
    func: returnAsItIs,
  },
  {
    id: 'maturity_Phase',
    numeric: false,
    disablePadding: false,
    label: 'Maturity Phase',
    sorting: false,
    width: '150px',
    func: maturityPhaseCell,
  },
  {
    id: 'four_year_cagr',
    numeric: false,
    disablePadding: false,
    label: '4 Year CAGR',
    width: '100px',
    sorting: true,
    tooltipText: tooltipTextForSubTableCagr,
    func: roundOffFloat,
  },
  {
    id: 'two_year_cagr',
    numeric: false,
    disablePadding: false,
    label: '2 Year CAGR',
    sorting: true,
    width: '100px',
    tooltipText: tooltipTextForSubTableCagr,
    func: roundOffFloat,
  },
]

export const trendBucketIngProdSubHeadCells = (() => {
  const headCell = [...TrendBucketSubHeadCells]
  headCell.splice(
    6,
    0,
    {
      id: 'fsProducts',
      numeric: false,
      disablePadding: false,
      label: '# FS Products',
      width: '100px',
      sorting: true,
      func: formatDataPoints,
    },

    {
      id: 'retailProduct',
      numeric: false,
      disablePadding: false,
      label: '# Retail Products',
      width: '100px',
      sorting: true,
      func: formatDataPoints,
    },
  )
  return headCell
})()

export const trendBucketThemeSubHeadCells = (() => {
  const headCell = [...TrendBucketSubHeadCells]
  headCell.splice(
    6,
    0,
    {
      id: 'relatedIngredients',
      numeric: false,
      disablePadding: false,
      label: '# of related ingredients',
      sorting: true,
      width: '87px',
      func: formatDataPoints,
    },
    {
      id: 'relatedProducts',
      numeric: false,
      disablePadding: false,
      label: '# of related products',
      sorting: true,
      width: '87px',
      func: formatDataPoints,
    },
  )
  return headCell
})()
