import styled from '@emotion/styled'
import { Box, Grid } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflow: 'scroll',
  maxWidth: '900px',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    height: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.white,
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}))

export const BoxStyle = styled(Box)(() => ({
  minHeight: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'white',
  padding: '1px 10px 1px 10px',
  borderBottom: '1px solid #e5eaf0',
  borderTop: 'transparent ! important',
  borderLeft: '1px solid #e5eaf0',
  fontSize: ' 0.875rem',
}))

export const StyledBoxToDisplayCountry = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  minHeight: '200px',
  marginLeft: '20px',
}))

export const BorderStyle = {
  borderLeft: 'transparent !important',
}

export const StyledGrid = styled(Grid)(() => ({
  borderRight: '1px solid #e5eaf0',
  paddingRight: '5px',
}))

export const GridStyling = styled(Grid)(() => ({
  borderLeft: '1px solid #e5eaf0',
  paddingLeft: '20px',
}))
