import React from 'react'
import {
  Field, reduxForm, formValueSelector,
} from 'redux-form'
import {
  renderField,
} from '../UI/form/index'
import { required, email } from '../../validation'
import { connect } from 'react-redux'
import { reduxFormConst } from '../../const'
import logo from '../../assets/images/TrendIcon/ai-palette--logo.svg'

class ForgetPasswordForm extends React.Component {
  componentDidMount (){
    this.props.initialize({
      origin: window.location.origin,
    })
  }

  render () {
    return (
      <div className='login__wrapper'>
        <div className='logo__wrapper'>
          <div className="col-md-1 logo">
            <img src={logo} />
          </div>
        </div>
        <div className='container'>
          <div className='container__inner'>
            <div className='img__block'>
            </div>
            <div className='loginbox__block forget__password'>
              <div className='loginbox__inner'>
                <div className='login__box'>
                  <div className='box__heading'>
                    <span className="caption">Password assistance</span>
                    <p className="sub__caption">Enter your username or email to recover your password. <br />
                                        You will receive an email with instructions.</p>
                  </div>
                  <div className='loginBoxBody'>
                    <form key="forgetPasswordForm" onSubmit={this.props.handleSubmit}>
                      <div>
                        <Field
                          name="email"
                          type="text"
                          component={renderField}
                          label="Email Address or Username"
                          className="form-control"
                          placeholder="Email"
                          validate={[email, required]}
                        />
                        <Field
                          name="origin"
                          type="text"
                          component={renderField}
                          className="form-control mt-30"
                          placeholder="Origin"
                          hidden
                        />
                      </div>

                      <div className={'col-xs-12  text-center '}>
                        <button type="submit" className="btn btn__blue">
                                                Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

ForgetPasswordForm = reduxForm({
  // a unique name for the form
  form: reduxFormConst.forgetPassword,
})(ForgetPasswordForm)

ForgetPasswordForm = connect()(ForgetPasswordForm)
export default ForgetPasswordForm
