import React, { useMemo, useState } from 'react'

import _ from 'lodash'
import { Box, Chip, Typography } from '@mui/material'
import { ShowMoreChip } from './styles'
import { getFilterLabels } from './utility'

/**
 * This Component can be used along with useNestedMultiSelectDropdownState hook where
 * @param {Object} filterState will be in the form of { all: {}, applied: {}, selected: {} }
 *
 * Usage - Can be used to show applied filter tags in all the places where we use NestedMultiSelectDropdownContainer
 */
const DropdownSelectedFiltersTags = (props) => {
  const {
    maxFilterTagsToShow,
    filterState,
    handleRemoveAppliedFilters,
    headerText,
    outlinedShowMoreButton,
    containerProps,
  } = props

  const [showAllFilterTags, setShowAllFilterTags] = useState(false)

  const filterData = useMemo(() => {
    if (_.isEmpty(filterState)) {
      return {
        all: {},
        applied: {},
        selected: {},
      }
    }
    return filterState
  }, [filterState])

  const getRelatedParentNodes = (id, filterIds) => {
    let allParentIds = [...filterIds]
    const parentObjectIdArray = Object.keys(
      filterData.all,
    ).filter((keyOfObject) => filterData.all[keyOfObject].childIds.includes(id))
    if (parentObjectIdArray.length) {
      const parentObjectId = parentObjectIdArray[0]
      allParentIds.push(parentObjectId)
      allParentIds = getRelatedParentNodes(parentObjectId, allParentIds)
    }
    return allParentIds
  }
  const getRelatedChildNodes = (parentId) => {
    const allChildIds = []
    const appliedChildIds = filterData.all[
      parentId
    ].childIds.filter((idOfChild) =>
      Object.keys(filterData.applied).includes(idOfChild),
    )
    for (const appliedChildId of appliedChildIds) {
      allChildIds.push(appliedChildId)
      allChildIds.concat(getRelatedChildNodes(appliedChildId))
    }
    return allChildIds
  }
  const getAllParentIds = (stateToCheck) => {
    const items = Object.values(stateToCheck)
    const filterIds = items.reduce(
      (acc, item) => getRelatedParentNodes(item.id, acc),
      [],
    )
    return [...new Set(filterIds)]
  }

  const getLabelOfChip = (idOfItem, stateToCheck, appliedFilterIds) => {
    return getFilterLabels(idOfItem, stateToCheck, appliedFilterIds)
  }

  const appliedFilterIds = useMemo(() => {
    const appliedItemsIds = Object.keys(filterData.applied)
    const filterIds = getAllParentIds(filterData.applied)
    return [...new Set([...appliedItemsIds, ...filterIds])]
  }, [filterData.applied])

  const topLevelItems = useMemo(
    () =>
      appliedFilterIds.filter(
        (idOfItem) => filterData.all[idOfItem].depth === 0,
      ),
    [appliedFilterIds],
  )

  const handleRemoveSelectedFilterTag = (idOfParent) => {
    const appliedItemsIds = Object.keys(filterData.applied)
    const allIdsToRemove = [
      idOfParent,
      ...getRelatedChildNodes(idOfParent),
    ].filter((idOfItem) => appliedItemsIds.includes(idOfItem))
    handleRemoveAppliedFilters(allIdsToRemove)
  }

  const getChipFromItem = (idOfItem, allItems, appliedFilterIds) => (
    <Chip
      key={idOfItem}
      label={getLabelOfChip(idOfItem, allItems, appliedFilterIds)}
      variant="outlinedTag"
      onDelete={() => handleRemoveSelectedFilterTag(idOfItem)}
    />
  )

  const getFilterTags = (showAllFilterTags) => {
    if (!showAllFilterTags) {
      return (
        <React.Fragment>
          {topLevelItems
            .slice(0, maxFilterTagsToShow)
            .map((idOfItem) =>
              getChipFromItem(idOfItem, filterData.all, appliedFilterIds),
            )}
          <ShowMoreChip
            key={'show-more-dropdown-filter-tags'}
            label={`+${topLevelItems.slice(maxFilterTagsToShow).length} More`}
            variant="outlinedTag"
            onClick={() => setShowAllFilterTags(true)}
            styleProps={{ outlinedShowMoreButton: outlinedShowMoreButton }}
          />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {topLevelItems.map((idOfItem) =>
          getChipFromItem(idOfItem, filterData.all, appliedFilterIds),
        )}
        <ShowMoreChip
          key={'show-less-dropdown-filter-tags'}
          label={`Show Less`}
          variant="outlinedTag"
          onClick={() => setShowAllFilterTags(false)}
          styleProps={{ outlinedShowMoreButton: outlinedShowMoreButton }}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!!topLevelItems?.length && (
        <Box py={2} display="flex" {...containerProps}>
          {headerText && (
            <Typography sx={{ mt: 0.25 }}>{headerText}&nbsp;</Typography>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, flex: 1 }}>
            {maxFilterTagsToShow &&
            topLevelItems.length > maxFilterTagsToShow ? (
              getFilterTags(showAllFilterTags)
            ) : (
              <React.Fragment>
                {topLevelItems.map((idOfItem) =>
                  getChipFromItem(idOfItem, filterData.all, appliedFilterIds),
                )}
              </React.Fragment>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

DropdownSelectedFiltersTags.defaultProps = {
  maxFilterTagsToShow: 5,
  filterState: {
    all: {},
    applied: {},
    selected: {},
  },
  outlinedShowMoreButton: false,
}

export default DropdownSelectedFiltersTags
