import { Box, Typography, Link } from '@mui/material'
import React, { useState, useEffect } from 'react'
import RestoreIcon from '@mui/icons-material/Restore'
import { getQueryParams, getCapitalzedText } from './../../../store/utility'
import { useHistory } from 'react-router-dom'
import ReportDialogLink from '../ReportDialogLink'
import { getHighlightedText } from '../../utils/highlightText'

const buildLink = (p, l, i) => {
  return `/mui/details/${p}/${l.toLowerCase()}/${i}`
}

const GlobalSearchTabCard = ({
  needIcon,
  searchedText,
  cardDetails,
  projectId,
  sendRecentHistory,
  setSearchedText,
  activeReport,
  setActiveReport,
  logEntityClicked,
}) => {
  const history = useHistory()
  const query = getQueryParams()

  const closeReport = () => {
    setActiveReport(null)
    query.delete('activeReport')
    history.replace(`?${query.toString()}`)
    setSearchedText(cardDetails.entityName)
  }

  const handleEntityRedirection = (e, cardDetails) => {
    if (cardDetails.type !== 'report') {
      sendRecentHistory(cardDetails)
    }
  }
  return (
    <Box onClick={(e) => handleEntityRedirection(e, cardDetails)}>
      <Box
        sx={{
          width: '100%',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          border: ({ palette }) => `1px solid ${palette.divider}`,
          justifyContent: 'space-between',
          '&:hover': {
            background: ({ palette }) => palette.action.hover,
          },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ padding: '5px', paddingRight: '10px' }}>
            {' '}
            <img
              src={cardDetails.img}
              height={36}
              width={36}
              style={{ borderRadius: 4, objectFit: 'cover' }}
            />
          </Box>
          <Box>
            {cardDetails.type === 'report' ? (
              <ReportDialogLink
                initOpen={activeReport === cardDetails.id}
                id={cardDetails.id}
                thumbnailUrl={cardDetails.img}
                name={getHighlightedText(cardDetails.entityName, searchedText)}
                report={cardDetails.reportURL}
                handleClose={closeReport}
                globalSearch={true}
              />
            ) : (
              <Typography
                sx={{
                  fontSize: '12px',
                  paddingTop: '5px',
                }}
              >
                {getHighlightedText(cardDetails.entityName, searchedText)}
              </Typography>
            )}

            <Typography
              pt={1}
              pb={0.7}
              sx={{
                fontSize: '10px',
                color: ({ palette }) => palette.grey[900],
              }}
            >
              {getCapitalzedText(cardDetails.type)}
            </Typography>
          </Box>
        </Box>
        {needIcon && (
          <Box>
            <RestoreIcon sx={{ color: ({ palette }) => palette.icon }} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default GlobalSearchTabCard
