import React, { useEffect, useState } from 'react'
import AddFavButtonUI from './AddFavoriteButton/AddFavButtonUI'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useLoader } from '../../../hooks'
import OverlayLoader from '../OverlayLoader'
import {
  removeFromOtherFavorites,
  fetchOtherFavoritesList,
  fetchAddedOtherFavCheck,
  addToFavoritesOtherFavorites,
} from '../../../store/actions'
const AddFavButtonParing = ({
  projectId,
  productId,
  trendName,
  trendType,
  reDirectionUrl,
  breadcrumbs,
  addToFavoritesOtherFavorites,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  addedOtherFav,
  fetchAddedOtherFavCheck,
  favoriteSectionType,
  pairing,
  additionalInfo,
  favoriteType,
  isOtherFavoriteAllowed,
}) => {
  const [showLoader, setShowLoader] = useLoader()

  useEffect(() => {
    fetchOtherFavoritesList(setShowLoader)
    if (favoriteType === 'FS_PRODUCTS' || favoriteType === 'RETAIL_PRODUCTS') {
      fetchAddedOtherFavCheck(
        encodeURIComponent(trendName),
        trendType,
        favoriteType,
        productId,
        reDirectionUrl.replace(/^\/mui\/details\//, '/'),
        setShowLoader,
        projectId,
      )
    } else {
      fetchAddedOtherFavCheck(
        encodeURIComponent(trendName),
        trendType,
        favoriteType,
        productId,
        reDirectionUrl.replace(/^\/mui\/details\//, '/'),
        setShowLoader,
        projectId,
      )
    }
  }, [])

  const handleClickAddToFav = () => {
    if (addedOtherFav) {
      removeFromOtherFavorites(
        encodeURIComponent(trendName),
        trendType,
        favoriteType,
        productId,
        reDirectionUrl.replace(/^\/mui\/details\//, '/'),
        setShowLoader,
      ).then(() => {
        fetchAddedOtherFavCheck(
          encodeURIComponent(trendName),
          trendType,
          favoriteType,
          productId,
          reDirectionUrl.replace(/^\/mui\/details\//, '/'),
          setShowLoader,
          projectId,
        )
      })
    } else {
      addToFavoritesOtherFavorites(
        encodeURIComponent(trendName),
        trendType,
        projectId,
        favoriteType,
        productId,
        breadcrumbs,
        reDirectionUrl.replace(/^\/mui\/details\//, '/'),
        additionalInfo,
        setShowLoader,
      ).then(() => {
        fetchAddedOtherFavCheck(
          encodeURIComponent(trendName),
          trendType,
          favoriteType,
          productId,
          reDirectionUrl.replace(/^\/mui\/details\//, '/'),
          setShowLoader,
          projectId,
        )
      })
    }
  }
  return (
    <>
      {showLoader && <OverlayLoader />}
      {isOtherFavoriteAllowed && (
        <AddFavButtonUI
          favoriteSectionType={favoriteSectionType}
          pairing={pairing}
          projectId={projectId}
          productId={productId}
          trendName={trendName}
          trendType={trendType}
          reDirectionUrl={reDirectionUrl}
          breadcrumbs={breadcrumbs}
          handleClickAddToFav={handleClickAddToFav}
          addedFav={addedOtherFav}
          favoriteType={favoriteType}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return {
    addedFav: myDashboard.addedFav,
    addedOtherFav: myDashboard.addedOtherFav,
    isOtherFavoriteAllowed: myDashboard.isOtherFavoriteAllowed,
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeFromOtherFavorites: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
  ) =>
    dispatch(
      removeFromOtherFavorites(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
      ),
    ),
  fetchOtherFavoritesList: (setShowLoader) =>
    dispatch(fetchOtherFavoritesList(setShowLoader)),
  fetchAddedOtherFavCheck: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
    projectId,
  ) =>
    dispatch(
      fetchAddedOtherFavCheck(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
        projectId,
      ),
    ),
  addToFavoritesOtherFavorites: (
    name,
    lens,
    projectId,
    favoriteType,
    favoriteId,
    navigation,
    url,
    additionalInfo,
    setShowLoader,
  ) =>
    dispatch(
      addToFavoritesOtherFavorites(
        name,
        lens,
        projectId,
        favoriteType,
        favoriteId,
        navigation,
        url,
        additionalInfo,
        setShowLoader,
      ),
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddFavButtonParing),
)
