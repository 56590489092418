import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ButtonGroup,
  Checkbox,
  Button,
  Link,
  SvgIcon,
  Tab,
  Typography,
} from '@mui/material'
import { ReactComponent as ParingIcon } from '../../../assets/icons/paring.svg'

import PairingChart from '../PairingChart'
import PairingTable from '../PairingTable'
import { amplify, getQueryParams } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'
import { StyledLink } from './style'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import AddFavoriteButtonParing from '../OtherFavorites/AddFavoriteButtonParing'
import NestedMultiSelectDropdownContainer from '../NestedMultiSelectDropdownContainer'
import DropdownSelectedFiltersTags from '../DropdownSelectedFiltersTags'

import { fetchTrendFamilyFilterItems } from '../../../store/actions'
import { useSingleSnackBar } from '../../../hooks'
import NoResultFound from './../NoResultFound/NoResultFound'
import Searchdata from './../../../assets/images/testImages/Searchdata.svg'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const getSelectedFilterLabel = (ingredientFamilyList, depth) => {
      const ingFamilyFilterKeys = Object.keys(ingredientFamilyList)
      return ingFamilyFilterKeys
        .filter(
          (filterApplied) =>
            ingredientFamilyList[filterApplied].depth === depth,
        )
        .map(
          (filteredIngredientFilter) =>
            ingredientFamilyList[filteredIngredientFilter].label,
        )
    }
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiInstance.country,
      Category_Name: ampiInstance.category,
    }
    switch (ampiEvent) {
      case 'Clicked_Pairing':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiInstance.trendName,
          Trend_Name: ampiInstance.name,
          Lens: ampiUserData.lens,
        }
        break
      case 'Clicked_Pairing_Ing_Fam':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.name,
        }
        break
      case 'Clicked_Pairing_Ing_Fam_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keyword: ampiUserData.searchKeyword,
          Trend_Name: ampiUserData.name,
        }
        break
      case 'Clicked_Pairing_Ing_Fam_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Ingredient_Family: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            0,
          ),
          Ingredient_SubFamily: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            1,
          ),
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lens,
          Maturity_Phase: ampiUserData.isTrendChecked,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in PairingDialogLink')
  }
}

const phaseColor = {
  DORMANT: '#ECE55F',
  EMERGING: '#A7DE4C',
  GROWING: '#6EB647',
  MATURED: '#E2873D',
  DECLINING: '#DD7066',
  FADING: '#EABAB6',
}

const isTrendCheckedInitial = {
  DORMANT: true,
  EMERGING: true,
  GROWING: true,
  MATURED: true,
  DECLINING: true,
  FADING: true,
}

const PairingDialogLink = ({
  numberOfPairs,
  pairingDetails,
  lens,
  retailRelatedProducts,
  name,
  country,
  category,
  twoYearCagr,
  projectid,
  productId,
  breadcrumbs,
}) => {
  const [open, setOpen] = React.useState(false)
  const [viewMode, setViewMode] = useState('chart')
  const [isTrendChecked, setIsTrendChecked] = useState(isTrendCheckedInitial)
  const [pairedIngredientDetails, setPairedIngredientDetails] = useState([])
  const [ingFamilyFilterAPIData, setIngFamilyFilterAPIData] = useState({})
  const [ingFamilyFilter, setIngFamilyFilter] = useState({})
  const [updatedAppliedIngFamily, setUpdatedAppliedIngFamily] = useState({})
  const { showSnackBar } = useSingleSnackBar()
  const location = useLocation()
  const history = useHistory()

  const logDropdownOpened = () => {
    const ampiEvent = 'Clicked_Pairing_Ing_Fam'
    const ampiUserData = {
      name,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logSearchKeywordModified = (searchKeyword) => {
    const ampiEvent = 'Clicked_Pairing_Ing_Fam_Search'
    const ampiUserData = {
      searchKeyword,
      name,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logActionClicked = (ingredientFamilyList, action) => {
    const ampiEvent = 'Clicked_Pairing_Ing_Fam_Action'
    const ampiUserData = {
      ingredientFamilyList,
      action,
    }
    amp(ampiEvent, ampiUserData)
  }

  const ingFamilyStateChange = (state) => {
    setIngFamilyFilter(state)
  }

  const handleRemoveAppliedIngFamily = (idsToRemove) => {
    setUpdatedAppliedIngFamily(_.omit(ingFamilyFilter.applied, idsToRemove))
  }

  const fetchIngredientFamilies = () => {
    const trends = pairingDetails.nodes.reduce(
      (acc, { _id }) => (_id !== productId ? [...acc, _id] : acc),
      [],
    )
    fetchTrendFamilyFilterItems(trends, lens, projectid)
      .then((ingredientFamilies) => {
        setIngFamilyFilterAPIData(ingredientFamilies)
      })
      .catch((error) => {
        showSnackBar('Error fetching Ingredient Families', 'error')
      })
  }

  const handleTrendChecked = (obj) => {
    let count = 0
    Object.values(isTrendChecked).forEach((value) => {
      if (value === false) {
        count++
      }
    })
    if (count !== 5 || !isTrendChecked[obj]) {
      setIsTrendChecked({ ...isTrendChecked, [obj]: !isTrendChecked[obj] })
    }
  }

  function sortByKey(array, key) {
    return array.sort(function(a, b) {
      const x = a[key]
      const y = b[key]
      return x < y ? -1 : x > y ? 1 : 0
    })
  }

  const xyPairingData = []
  pairingDetails.edges.forEach((edge) => {
    pairingDetails.nodes.find((node) => {
      if (node.id === edge.to && node.ingredient_info) {
        try {
          let trend = node.ingredient_info.classification
          trend = trend[0].toUpperCase() + trend.slice(1).toLowerCase()
          xyPairingData.push({
            id: node._id,
            probability: edge.probability,
            twoYearCAGR: node.ingredient_info.two_year_cagr,
            image: node.image,
            fsProductCount: node.fs_products,
            retailProductCount: node.retail_products,
            trend,
            name: node.title.replace('<p>', '').replace('</p>', ''),
          })
        } catch (error) {
          console.log(error)
        }
        return true
      }
    })
  })

  const minMaxDomain = xyPairingData.reduce(
    (minMax, dp) => {
      const xValue = dp.probability
      const yValue = dp.twoYearCAGR
      let { xMinMax } = minMax
      let { yMinMax } = minMax
      if (xValue > xMinMax[1]) {
        xMinMax = [xMinMax[0], xValue]
      } else if (xValue < xMinMax[0]) {
        xMinMax = [xValue, xMinMax[1]]
      }
      if (yValue > yMinMax[1]) {
        yMinMax = [yMinMax[0], yValue]
      } else if (yValue < yMinMax[0]) {
        yMinMax = [yValue, yMinMax[1]]
      }
      return { xMinMax, yMinMax }
    },
    {
      xMinMax: [xyPairingData[0].probability, xyPairingData[0].probability],
      yMinMax: [xyPairingData[0].twoYearCAGR, xyPairingData[0].twoYearCAGR],
    },
  )
  const probabilitySum = xyPairingData.reduce(
    (sum, data) => data.probability + sum,
    0,
  )
  const xMin = (minMaxDomain.xMinMax[0] / probabilitySum) * 100
  const xMax = (minMaxDomain.xMinMax[1] / probabilitySum) * 100
  const yMin = minMaxDomain.yMinMax[0]
  const yMax = minMaxDomain.yMinMax[1]
  let modifiedXMax = Math.ceil(xMax + 2)
  if (xMax == xMin) {
    modifiedXMax = 100
  }
  const modifiedY = { yMin: yMin - 10, yMax: yMax + 10 }

  const getTrendIdFilteredPairingData = (pairingData) => {
    const ingFamilyTrends =
      !_.isEmpty(ingFamilyFilter.applied) &&
      Object.values(ingFamilyFilter.applied).reduce(
        (acc, { trends }) => [...acc, ...trends],
        [],
      )
    return pairingData
      .filter(({ trend, id }) => {
        const phase = trend.toUpperCase()
        return ingFamilyTrends
          ? isTrendChecked[phase] && ingFamilyTrends.includes(id)
          : isTrendChecked[phase]
      })
      .map((data) => ({
        ...data,
        probability: (data.probability / probabilitySum) * 100,
      }))
  }

  const filteredPairingData = getTrendIdFilteredPairingData(xyPairingData)
  const isFilterApplied = !_.isEmpty(ingFamilyFilter.applied)

  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true)
    const query = getQueryParams()
    query.set('openPopUp', true)
    history.replace(`?${query.toString()}`)
    const ampiEvent = `Clicked_Pairing `
    const ampiUserData = {
      lens: lens,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleClose = () => {
    setOpen(false)
    const query = getQueryParams()
    query.delete('openPopUp')
    history.replace(`?${query.toString()}`)
  }

  useEffect(() => {
    setOpen(getQueryParams({ location }).get('openPopUp'))
  }, [location.search])

  const setChart = () => {
    setViewMode('chart')
    const ampiEvent = `Clicked_Pairing_Graph`
    const ampiUserData = {
      isTrendChecked,
      lens,
    }
    amp(ampiEvent, ampiUserData)
  }

  const setTable = () => {
    const ampiEvent = `Clicked_Pairing_Table`
    const ampiUserData = {
      isTrendChecked,
      lens,
    }
    amp(ampiEvent, ampiUserData)
    setViewMode('table')
  }

  const downloadPairingListHandler = async () => {
    const ampiEvent = 'Clicked_Pairing_Table_Download'
    const ampiUserData = {
      isTrendChecked,
      lens,
    }
    amp(ampiEvent, ampiUserData)

    const formattedFilteredPairingData = filteredPairingData
      .slice(0, 20)
      .map((pairingData) => {
        return {
          probability: pairingData.probability,
          two_year_cagr: pairingData.twoYearCAGR,
          fs_products: pairingData.fsProductCount,
          retail_products: pairingData.retailProductCount,
          classification: pairingData.trend,
          name: pairingData.name,
        }
      })

    const request = NetworkingUtil.downloadPairingDetails(projectid)
    const details = {
      pairing_data: formattedFilteredPairingData,
    }
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0 so added 1
    const yyyy = today.getFullYear()
    const formattedDate = `${yyyy}_${mm}_${dd}`
    const excelFileName = `${country}_${category}_${name}_${formattedDate}`
    await axios
      .post(request, details, { responseType: 'blob' })
      .then(({ data }) => {
        saveAs(data, excelFileName) // eslint-disable-line
      })
  }

  useEffect(() => {
    fetchIngredientFamilies()
  }, [])

  return (
    <React.Fragment>
      <StyledLink href="/#" onClick={handleClickOpen}>
        {numberOfPairs}
        &nbsp; ingredients
      </StyledLink>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: '2px 2px 14px 0px #24242429',
          },
        }}
      >
        <DialogTitle sx={{ mt: 2 }}>
          <Box pl="70px">
            <Typography variant="h3">Pairing</Typography>
            <Typography variant="subtitle2" color="textSecondary" mt={0.5}>
              Pairing of ingredients
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 24,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pl: '70px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <SvgIcon
                  component={ParingIcon}
                  sx={{ color: 'secondary.main' }}
                />
                <Typography sx={{ fontWeight: 500 }} variant="h4">
                  &nbsp; Total Paired Ingredients of {name} : {numberOfPairs}
                </Typography>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Box pl="70px">
                <Box>
                  <Typography mb={1}>Ingredient Family:</Typography>
                  <NestedMultiSelectDropdownContainer
                    updatedFilterData={ingFamilyFilterAPIData}
                    filterTagsAppliedFilters={updatedAppliedIngFamily}
                    stateChangeCallback={ingFamilyStateChange}
                    dimensions={{
                      width: 250,
                      maxItemsToFitInHeight: 6.5,
                    }}
                    placeHolder="Select Ingredient Family"
                    isLoggingRequired
                    logDropdownOpened={logDropdownOpened}
                    logActionClicked={logActionClicked}
                    logSearchKeywordModified={logSearchKeywordModified}
                  />
                </Box>
                {isFilterApplied && (
                  <Typography variant="caption" color="colors.growing">
                    Note: Total Paired Ingredients of {name} with Selected
                    Ingredient Family: {filteredPairingData.length}
                  </Typography>
                )}
              </Box>
              <Box
                display="flex"
                alignSelf="flex-end"
                mb={isFilterApplied ? 2 : 0}
              >
                <Box alignSelf="flex-end">
                  <AddFavoriteButtonParing
                    favoriteSectionType={'Other Favorites'}
                    pairing
                    projectId={projectid}
                    productId={productId}
                    trendName={`Pairing: Paired with ${name}`}
                    trendType={lens}
                    reDirectionUrl={location.pathname + location.search}
                    breadcrumbs={breadcrumbs}
                    favoriteType={'PAIRING'}
                    additionalInfo={null}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  {viewMode === 'table' && (
                    <Link
                      pb={2}
                      alignSelf="flex-end"
                      onClick={downloadPairingListHandler}
                    >
                      Download List
                    </Link>
                  )}
                  <ButtonGroup
                    sx={{ height: '35px' }}
                    aria-label="Table chart button group"
                  >
                    <Button
                      variant={viewMode === 'chart' ? 'contained' : 'outlined'}
                      onClick={setChart}
                    >
                      Chart View
                    </Button>
                    <Button
                      variant={viewMode === 'table' ? 'contained' : 'outlined'}
                      onClick={setTable}
                    >
                      List View
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
            <Box pl="70px">
              <DropdownSelectedFiltersTags
                maxFilterTagsToShow={5}
                filterState={ingFamilyFilter}
                handleRemoveAppliedFilters={handleRemoveAppliedIngFamily}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              pt={2}
            >
              {Object.keys(phaseColor).map((obj, i) => {
                return (
                  <Box
                    component="span"
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={obj}
                  >
                    <Checkbox
                      style={{ color: phaseColor[obj] }}
                      checked={isTrendChecked[obj]}
                      onChange={() => handleTrendChecked(obj)}
                    />
                    <Typography variant="subtitle2" display={'inline'}>
                      {obj}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
            {filteredPairingData.length ? (
              viewMode === 'chart' ? (
                <PairingChart
                  handleClose={handleClose}
                  lens={lens}
                  pairingDetails={pairingDetails}
                  xyPairingData={filteredPairingData}
                  isTrendChecked={isTrendChecked}
                  phaseColor={phaseColor}
                  modifiedY={modifiedY}
                  modifiedXMax={modifiedXMax}
                  probabilitySum={probabilitySum}
                  xMin={xMin}
                  xMax={xMax}
                  yMin={yMin}
                  yMax={yMax}
                  twoYearCagr={twoYearCagr}
                  name={name}
                />
              ) : (
                <PairingTable
                  pairingDetails={pairingDetails}
                  retailRelatedProducts={retailRelatedProducts}
                  filteredPairingData={sortByKey(filteredPairingData, 'name')}
                  name={name}
                  country={country}
                  category={category}
                  pairedIngredientDetails={pairedIngredientDetails}
                  setPairedIngredientDetails={setPairedIngredientDetails}
                  projectid={projectid}
                  lens={lens}
                  isTrendChecked={isTrendChecked}
                />
              )
            ) : (
              <Box sx={{ height: '400px', width: '100%' }}>
                <NoResultFound
                  imgSrc={Searchdata}
                  message="Please select more Trend Maturity Phase to see the Trends!"
                  messageSx={{ width: '300px' }}
                  imgSx={{ width: '300px' }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

PairingDialogLink.propTypes = {
  numberOfPairs: PropTypes.number.isRequired,
}

export default PairingDialogLink
