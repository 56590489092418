import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Checkbox,
  Tooltip,
  Button,
} from '@mui/material'
import { Box } from '@mui/system'
import { Search } from '@mui/icons-material'
import {
  PopperComponent,
  StyledMainTextField,
  StyledTextField,
  StyledPopper,
  StyledMenu,
} from './styles'
import TooltipOnOverflow from '../../../components/Tooltip/TooltipOnOverflow.js'
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const popperModifiers = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: true,
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: false,
    options: {
      altAxis: false,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
      padding: 8,
    },
  },
]
const Multiselect = ({
  placeholder,
  searchPlaceholder,
  options = [],
  enableSelectAll,
  enableSearch,
  selectAllLabel,
  initialValue,
  value,
  text = null,
  onChange,
  textFieldProps = {},
  tooltipShow = false,
  disable = false,
  myDashBoard,
  width,
}) => {
  const selectAllOpt = { label: selectAllLabel, key: 'all' }
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectOptions, setSelectOptions] = useState(
    // enableSelectAll ? [selectAllOpt, ...options] : options
    initialValue || [],
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) anchorEl.focus()
    setAnchorEl(null)
  }

  const handleOptClick = (opt, selected) => (event) => {
    event.preventDefault()
    let options = [opt, ...selectOptions]
    if (selected) {
      options = selectOptions.filter(({ key }) => key !== opt.key)
    }
    setSelectOptions(options)
    if (onChange) {
      onChange(options)
    }
  }

  const handleChange = (event, newValue, reason, { option }) => {
    event.preventDefault()
    if (
      event.type === 'keydown' &&
      event.key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return
    }
    let val = newValue.filter(({ key }) => key !== 'all')
    if (enableSelectAll) {
      if (option.key === 'all') {
        if (reason === 'removeOption') {
          val = []
        } else {
          val = [selectAllOpt, ...options]
        }
      } else if (val.length === options.length) {
        val = [selectAllOpt, ...val]
      }
    }
    setSelectOptions(val)
    if (onChange) {
      onChange(val)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'multi-select-id' : undefined
  const selectedOptions = selectOptions
    .filter(({ key }) => key !== 'all')
    .map((v) => v.label)
    .join(', ')

  const multiSelectDropDown = (
    <StyledMenu
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          width:
            width || (anchorEl ? anchorEl.getBoundingClientRect().width : 0),
          maxHeight: 250,
        },
      }}
      open={!disable && open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.map((option) => {
        const selected = !!selectOptions.find(({ key }) => key === option.key)
        return (
          <MenuItem
            onClick={handleOptClick(option, selected)}
            sx={{
              width: '100%',
              ...(selected
                ? {
                    background: ({ palette }) => palette.option.selected,
                  }
                : {}),
            }}
          >
            <FormControlLabel
              value={option.key}
              sx={{ mx: 0 }}
              label={option.label}
              checked={
                option.key === 'all'
                  ? options.length + 1 === selectOptions.length
                  : selected
              }
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  sx={{
                    padding: 0,
                    marginRight: 1,
                    ...(selected
                      ? {
                          color: ({ palette }) =>
                            `${palette.secondary.main} !important`,
                        }
                      : {}),
                  }}
                />
              }
            />
          </MenuItem>
        )
      })}
    </StyledMenu>
  )

  useEffect(() => {
    if (value) {
      setSelectOptions(value)
    }
  }, [value])
  return (
    <>
      {tooltipShow && !myDashBoard ? (
        <TooltipOnOverflow
          title={selectedOptions}
          text={text}
          data-testId="tip"
        >
          <StyledMainTextField
            placeholder={placeholder}
            open={open}
            text={text}
            contentEditable={false}
            disabled={disable}
            value={
              selectOptions.length ===
              options.length + (enableSelectAll ? 1 : 0)
                ? !enableSelectAll
                  ? selectedOptions
                  : 'All'
                : selectedOptions
            }
            onClick={handleClick}
            {...textFieldProps}
          />
        </TooltipOnOverflow>
      ) : (
        !myDashBoard && (
          <StyledMainTextField
            placeholder={placeholder}
            open={open}
            text={text}
            contentEditable={false}
            disabled={disable}
            value={
              selectOptions.length ===
              options.length + (enableSelectAll ? 1 : 0)
                ? !enableSelectAll
                  ? selectedOptions
                  : 'All'
                : selectedOptions
            }
            onClick={handleClick}
            {...textFieldProps}
          />
        )
      )}
      {myDashBoard && (
        <Button
          open={open}
          onClick={handleClick}
          variant="outlined"
          sx={{ height: '30px ' }}
        >
          <FilterAltTwoToneIcon sx={{ color: ({ palette }) => palette.icon }} />
          Filter
        </Button>
      )}
      <StyledPopper
        id={id}
        open={open}
        style={{
          width:
            width || (anchorEl ? anchorEl.getBoundingClientRect().width : 0),
        }}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={popperModifiers}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {enableSearch || enableSelectAll ? (
              <Autocomplete
                open
                multiple
                disableCloseOnSelect
                value={selectOptions}
                options={enableSelectAll ? [selectAllOpt, ...options] : options}
                isOptionEqualToValue={(option, val) => option.key === val.key}
                PopperComponent={PopperComponent}
                renderTags={() => null}
                noOptionsText="No results"
                getOptionLabel={(option) => option.label}
                onClose={(event, reason) =>
                  reason === 'escape' && handleClose()
                }
                onChange={handleChange}
                renderOption={(props, option, { selected }) => (
                  <FormControlLabel
                    {...props}
                    value={option.key}
                    sx={{ mx: 0 }}
                    label={option.label}
                    checked={
                      option.key === 'all'
                        ? options.length + 1 === selectOptions.length
                        : selected
                    }
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                        sx={{
                          padding: 0,
                          marginRight: 1,
                          ...(selected
                            ? {
                                color: ({ palette }) =>
                                  `${palette.secondary.main} !important`,
                              }
                            : {}),
                        }}
                        style={{ marginRight: 8 }}
                      />
                    }
                  />
                )}
                // renderInput={() => null}
                renderInput={(params) => (
                  <StyledTextField
                    autoFocus
                    autoComplete="off"
                    ref={params.InputProps.ref}
                    placeholder={searchPlaceholder}
                    inputProps={params.inputProps}
                    size="large"
                    startAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                  />
                )}
              />
            ) : (
              multiSelectDropDown
            )}
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

Multiselect.defaultProps = {
  placeholder: 'Select options',
  searchPlaceholder: 'Search for somthing',
  selectAllLabel: 'All',
}

Multiselect.propTypes = {
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  selectAllLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    }),
  ).isRequired,
  enableSelectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Multiselect
