import { Radio } from '@mui/material'
import React from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

const RadioCustom = (props) => {
  const { variant, sx, size = 'small', ...radioProps } = props
  const iconVariant = () => {
    switch (variant) {
      case 'tickMark':
        return <CheckCircleRoundedIcon />
      default:
        return null
    }
  }

  return (
    <Radio
      sx={{ padding: 0, ...sx }}
      size={size}
      {...(iconVariant() && { checkedIcon: iconVariant() })}
      {...radioProps}
    />
  )
}

export default RadioCustom
