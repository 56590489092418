import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default {
  styleOverrides: {
    root: {
      height: 'unset',
    },
    select: {
      minHeight: '14px',
    },
  },
  defaultProps: {
    IconComponent: ExpandMoreIcon,
  },
};
