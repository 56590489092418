import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import { ReactComponent as MessageImg } from '../../../../../assets/social-icons/message.svg'
import { ReactComponent as SendImg } from '../../../../../assets/social-icons/send.svg'
import { ReactComponent as AmazonColorImg } from '../../../../../assets/social-icons/amazonColor.svg'
import { ReactComponent as BookmarkImg } from '../../../../../assets/social-icons/bookmark.svg'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { format } from 'date-fns'
import ConsumerQuotesContainer from './styles'
import fallbackImg from '../../../../../assets/emptySocialImage.png'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiInstance._email,
      Country_Name: ampiInstance._country,
      Category_Name: ampiInstance._category,
      Trend_Name: ampiInstance._name,
      Maturity_Phase: ampiInstance._trendName,
      Platform: ampiUserData.platform,
      Likes: ampiUserData.likes,
      Lens: ampiUserData.lens,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)
  } catch (err) {
    console.log(err, 'Amplitude error in ConsumerQuotesAmazon')
  }
}

const ConsumerQuotesAmazon = ({
  timestamp,
  content,
  likes,
  link,
  lens,
  logConsumerQuotesClicked,
  // type,
}) => {
  const linkHandler = () => {
    window.open(link, '_blank')
    if (type === 'regionalAnalytics' || type === 'demographyAnalytics') {
      logConsumerQuotesClicked()
    } else {
      const ampiEvent = `Clicked_Consumer_Quotes`
      const ampiUserData = {
        platform: 'Amazon',
        likes: likes,
        lens: lens,
      }
      amp(ampiEvent, ampiUserData)
    }
  }
  return (
    <ConsumerQuotesContainer onClick={linkHandler}>
      <Box pt={2} px={2} mb={1} height={'290px'} overflow="auto">
        <Typography
          variant="body3"
          sx={{
            flexGrow: 1,
            overflowX: 'hidden',
          }}
        >
          {content}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: '10px',
          marginTop: '5px',
          marginLeft: '20px',
          display: 'flex',
          justifyContent: 'flex-end !important',
          height: '40px',
        }}
      >
        <AmazonColorImg />
      </Box>
    </ConsumerQuotesContainer>
  )
}

ConsumerQuotesAmazon.propTypes = {
  timestamp: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  logConsumerQuotesClicked: PropTypes.func,
  type: PropTypes.string,
}

export default ConsumerQuotesAmazon
