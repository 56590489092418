import React from 'react';
import { Box } from '@mui/system';
import { SvgIcon, Typography } from '@mui/material';
import { ReactComponent as AddToCompareIcon } from '../../../../../assets/icons/add-to-compare.svg';

const EmptyCompareCard = () => (
  <Box sx={{
    border: t => `1px dashed ${t.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    p: 1,
  }}
  >
    <Box>
      <SvgIcon component={AddToCompareIcon} sx={{ color: 'icon', mt: 0.5 }} fontSize="small" />
    </Box>
    <Typography variant="caption" textAlign="center" color="textSecondary">
      Add more items to compare by
      <br />
      clicking on “Add to compare” button
    </Typography>
  </Box>
);

export default EmptyCompareCard;
