import { BookmarkActionTypes } from '../actions/actionTypes'
import { updateObject, snakeToCamelObjKeys } from '../utility'

const initialState = {
  bookmarked: null,
  isBookmarked: false,
  error: false,
  loading: false,
  isBookmarkSwing: false,
}

const bookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case BookmarkActionTypes.type[BookmarkActionTypes.FETCH_BOOKMARK_SUCCESS]:
      return updateObject(state, {
        bookmarked: {
          bookmarkedTrends: action.bookmarked.bookmarkedTrends
            .map((obj) => snakeToCamelObjKeys(obj))
            .filter(({ trendAvailable }) => trendAvailable),
        },
        error: false,
        loading: false,
      })
    case BookmarkActionTypes.type[BookmarkActionTypes.FETCH_BOOKMARK_FAIL]:
      return updateObject(state, {
        error: true,
        loading: false,
      })
    case BookmarkActionTypes.type[
      BookmarkActionTypes.FETCH_CHECKED_BOOKMARK_SUCCESS
    ]:
      return updateObject(state, {
        isBookmarked: action.isChecked,
      })
    case BookmarkActionTypes.type[
      BookmarkActionTypes.FETCH_CHECKED_BOOKMARK_FAIL
    ]:
      return updateObject(state, {
        error: true,
        loading: false,
      })

    case BookmarkActionTypes.type[BookmarkActionTypes.SET_BOOKMARK_SWING]:
      return updateObject(state, {
        isBookmarkSwing: action.isBookmarkSwing,
        error: true,
        loading: false,
      })
    default:
      return state
  }
}

export default bookmarkReducer
