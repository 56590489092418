import React, { useState } from 'react'
import {
  Typography,
  TableRow,
  TableBody,
  Button,
  InputAdornment,
  Grid,
  Box,
} from '@mui/material'

import GenericTableHead from '../../../../shared/components/GenericTableComponent/GenericTableHead'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import SingleSelect from '../../../../shared/components/SingleSelect'
import { Search } from '@mui/icons-material'
import { SearchBox } from '../../../TrendBucketTable/styles'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'

const UserManagementTableUI = ({
  searchValue,
  clientTypeFilter,
  setSearchValue,
  adminPanelHeadCells,
  GenericTableCell,
  searchedData,
  clientTypeChanged,
  clientCount,
  handleAddUser,
  showLoader,
  handleDownloadUser,
}) => {
  return (
    <>
      <Grid container px={1} sx={{ marginBottom: '-25px', paddingTop: '15px' }}>
        <Typography variant="subtitle2">Summary</Typography>
      </Grid>{' '}
      <Box mt={3}>
        <Grid container spacing={2} my={2}>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography px={1} variant="subtitle2">
              Number of Users: {clientCount.totalClients}
            </Typography>
            <Typography px={1} variant="subtitle2">
              Licensed Users: {clientCount.licensedClient}
            </Typography>
            <Typography px={1} variant="subtitle2">
              Trial Users: {clientCount.trialClient}
            </Typography>
          </Grid>
          <Grid
            item
            xs={true}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            pl={1}
          >
            <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
              <Typography variant="subtitle2">Client type</Typography>
              <SingleSelect
                value={clientTypeFilter?.label || 'All'}
                options={[
                  { label: 'All', key: 0 },
                  { label: 'Licensed', key: 2 },
                  { label: 'Trial', key: 3 },
                ]}
                sx={{ minWidth: '300px' }}
                onChange={(e, newVal) => clientTypeChanged(newVal)}
              />
            </Box>
            <SearchBox
              variant="outlined"
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              placeholder={`Search users`}
              value={searchValue}
              size="large"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <ClearIcon
                        cursor="pointer"
                        onClick={(e) => {
                          setSearchValue('')
                        }}
                        color="secondary"
                        fontSize="small"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" onClick={handleAddUser}>
              <AddIcon />
              Add New User
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<DownloadRoundedIcon />}
              onClick={handleDownloadUser}
              disabled={!searchedData?.length}
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <TableContainer sx={{ height: '450px' }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <GenericTableHead headCells={adminPanelHeadCells} />

            {searchedData?.length ? (
              <TableBody>
                {searchedData?.map((row) => (
                  <TableRow
                    data-testId="tableRow"
                    sx={{ cursor: 'pointer', verticalAlign: 'baseline' }}
                  >
                    <>
                      <GenericTableCell
                        rowData={row}
                        tableHeadCells={adminPanelHeadCells}
                      />
                    </>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              !showLoader && (
                <Box
                  sx={{
                    position: 'relative',
                    left: '45vw',
                    top: '150px',
                  }}
                >
                  <Typography variant="subtitle2">No data found</Typography>
                </Box>
              )
            )}
          </Table>
        </TableContainer>
      </Box>{' '}
    </>
  )
}

export default UserManagementTableUI
