import React from 'react'

import {
  Box,
  Grid,
  ButtonGroup,
  Button,
  Typography,
  Tabs,
  SvgIcon,
  Tab,
} from '@mui/material'

import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import SingleSelect from '../../shared/components/SingleSelect'
import ScatteredGraph from '../../shared/components/Graphs/ScatteredGraph'
import RegionalAnalyticsTable from './RegionalAnalyticsTable'
import ConsumerInsightsCard from '../../shared/components/ConsumerInsightesCard'
import SearchNotSelected from '../../components/WhiteSpaceComponent/SearchNotSelected'
import OverlayLoader from '../../shared/components/OverlayLoader'
import StyledLink from '../../shared/components/StyledLink'

import { LENSES } from '../../shared/utils/constants'
import SearchBox from '../../components/SearchBox/SearchBox'
import TabPanel from '../../shared/components/TabPanel'
import InformationIcon from '../../shared/components/InformationIcon'

import { ReactComponent as IngredientsIcon } from './../../assets/icons/ingredients.svg'
import { ReactComponent as ProductsIcon } from './../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from './../../assets/icons/themes.svg'

const RegionalAnalyticsUI = ({
  subscriptionDetails,
  handleToggleSelection,
  lensSelected,
  regionSelected,
  productCategorySelected,
  handleRegionChange,
  handleProductCategoryChange,
  trendGraphData,
  graphHelperData,
  viewTab,
  handleViewTabChange,
  needCategories,
  viewDetailsClicked,
  scatteredPlotTooltip,
  handleSearch,
  search,
  handleHover,
  projectId,
  countrySelected,
  handleCountryChange,
  countrySelectionOpt,
  regionSelectionOpt,
  categorySelectionOpt,
  viewTableNameClicked,
  logConsumerInsightClick,
  loader,
  medianXVal,
  medianYVal,
  resetBtn,
  setResetBtn,
  resetBtnId,
  logAppliedFilters,
  logRegionalTrendClick,
}) => {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const tabs = LENSES.map((lens, i) => {
    let icon = IngredientsIcon
    if (lens === 'Product') {
      icon = ProductsIcon
    }
    if (lens === 'Theme') {
      icon = ThemesIcon
    }
    return (
      <Tab
        key={lens}
        value={lens}
        disabled={productCategorySelected === null}
        icon={<SvgIcon component={icon} />}
        label={`${lens}s`}
        {...a11yProps(i)}
      />
    )
  })

  const tabsPanel = LENSES.map((lens, i) => {
    return (
      <TabPanel key={lens} value={lensSelected} index={lens}>
        {!productCategorySelected ? (
          <SearchNotSelected msg="Select category to view Regional Analytics" />
        ) : (
          <Box>
            {trendGraphData && (
              <Box>
                {!loader ? (
                  search || trendGraphData.length ? (
                    <Box>
                      {viewTab === 'graph' && (
                        <>
                          {resetBtn && (
                            <Box
                              sx={{ width: '100%', mt: 2 }}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                sx={{ mx: 2 }}
                                id={resetBtnId}
                                variant="grey"
                              >
                                Reset Zoom
                              </Button>
                            </Box>
                          )}
                          <ScatteredGraph
                            filteredData={trendGraphData}
                            resetZoomOnGraphDataUpdate={false}
                            lensSelected={lensSelected}
                            graphHelperData={graphHelperData}
                            viewDetailsClicked={viewDetailsClicked}
                            scatteredPlotTooltip={scatteredPlotTooltip}
                            handleHover={handleHover}
                            medianXVal={medianXVal}
                            medianYVal={medianYVal}
                            resetBtn={resetBtn}
                            setResetBtn={setResetBtn}
                            resetBtnId={resetBtnId}
                          />
                        </>
                      )}
                      {viewTab === 'table' && (
                        <RegionalAnalyticsTable
                          tableData={trendGraphData}
                          viewTableNameClicked={viewTableNameClicked}
                          searchValue={search}
                          logAppliedFilters={logAppliedFilters}
                          logRegionalTrendClick={logRegionalTrendClick}
                        />
                      )}
                    </Box>
                  ) : (
                    <SearchNotSelected msg="No Data available for above selected region and category" />
                  )
                ) : (
                  <Box width="100%" height="500px"></Box>
                )}
              </Box>
            )}
            <Box pt={3} pb={3}>
              {needCategories && (
                <ConsumerInsightsCard
                  projectid={projectId}
                  needCategories={needCategories}
                  type="regionalAnalytics"
                  logConsumerInsightClick={logConsumerInsightClick}
                  lens={lensSelected}
                  region={regionSelected?.label}
                  //   category={category}
                  //   country={country}
                />
              )}
            </Box>
          </Box>
        )}
      </TabPanel>
    )
  })

  return (
    <>
      <MainCommonTabs subscriptionDetails={subscriptionDetails} />
      <>
        {(loader || (!needCategories && productCategorySelected)) && (
          <OverlayLoader />
        )}

        <Grid
          container
          columns={16}
          display={'flex'}
          flexWrap={'nowrap'}
          sx={{
            backgroundColor: ({ palette }) => palette.background.paper,
            mb: 1.25,
            pt: 1,
            pb: 1.25,
            px: 2,
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Grid
              container
              columns={16}
              display={'flex'}
              flexWrap={'nowrap'}
              // justifyContent="space-between"
            >
              <Box p={1} sx={{ mr: 2 }}>
                <SingleSelect
                  label="Country"
                  options={countrySelectionOpt}
                  onChange={handleCountryChange}
                  value={countrySelected}
                  disabled={true}
                  sx={{ width: '200px !important' }}
                />
              </Box>
              <Box p={1} sx={{ mr: 2 }}>
                <SingleSelect
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        pr={0.5}
                        sx={{
                          color: ({ palette }) => palette.text.secondary,
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        Region{' '}
                      </Typography>
                      <InformationIcon
                        sx={{
                          fontSize: '14px',
                        }}
                        tooltipText={
                          <Box>
                            <Typography
                              sx={{ color: ({ palette }) => palette.white }}
                            >
                              Please Click below to know more
                            </Typography>
                            <StyledLink
                              target="_blank"
                              to="/mui/docs?RedirectPath=/docs/region-coverage-for-usa"
                              sx={{ color: ({ palette }) => palette.white }}
                            >
                              Region Coverage for USA
                            </StyledLink>
                          </Box>
                        }
                      />
                    </Box>
                  }
                  // "Region"
                  placeholder="Select Region"
                  options={regionSelectionOpt || null}
                  onChange={handleRegionChange}
                  value={regionSelected}
                  sx={{
                    width: '200px !important',
                  }}
                />
              </Box>
              <Box p={1}>
                <SingleSelect
                  label="Product Category"
                  placeholder="Select Category"
                  options={categorySelectionOpt || null}
                  onChange={handleProductCategoryChange}
                  value={productCategorySelected}
                  sx={{ width: '200px !important' }}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Box pt={2} sx={{ background: ({ palette }) => palette.white }}>
          <Box
            px={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pb: 0.5,
            }}
          >
            <Box>
              <Typography variant="h2">Trends</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box pr={2}>
                <SearchBox
                  searchValue={search}
                  handleSearch={handleSearch}
                  placeholder="Search Trends"
                  disabled={productCategorySelected === null}
                />
              </Box>
              <Box>
                Select View :
                <ButtonGroup
                  aria-label="Table chart button group"
                  disabled={productCategorySelected === null}
                  sx={{ ml: 1 }}
                >
                  <Button
                    variant={viewTab === 'graph' ? 'contained' : 'outlined'}
                    onClick={() => handleViewTabChange('graph')}
                  >
                    Chart
                  </Button>
                  <Button
                    variant={viewTab === 'table' ? 'contained' : 'outlined'}
                    onClick={() => handleViewTabChange('table')}
                  >
                    List
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs
                value={lensSelected}
                onChange={handleToggleSelection}
                disabled={productCategorySelected === null}
                aria-label="Lens Tabs"
              >
                {tabs}
              </Tabs>
              {tabsPanel}
            </Box>
          </Box>
        </Box>
      </>
    </>
  )
}

export default RegionalAnalyticsUI
