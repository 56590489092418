import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  static getDerivedStateFromError() {
    return { error: true }
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.catchCallback) {
      this.props.catchCallback(error, errorInfo)
    }
  }

  render() {
    if (this.state.error) {
      return this.props.fallbackUI || null
    }
    return this.props.children
  }
}

ErrorHandler.propTypes = {
  catchCallback: PropTypes.func,
  fallbackUI: PropTypes.node,
  children: PropTypes.node,
}

export default ErrorHandler
