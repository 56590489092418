import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Chip,
  ListItem,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import React from 'react'
import { currentOS } from '../../../store/utility'
import GlobalSearchPaper from './GlobalSearchPaper'
import GlobalSearchTabCard from './GlobalSearchTabCard'

const GlobalSearchUI = ({
  searchBoxRef,
  isFocused,
  searchedText,
  clearSearchedText,
  selectedTab,
  handleTabSelection,
  listOfTabs,
  entityLoader,
  options,
  projectId,
  sendRecentHistory,
  togglePopper,
  handleScroll,
  handleKeyUp,
  handleOnChange,
  autocompleteOpen,
  setSearchedText,
  setIsOptionFocused,
  smallSize,
  activeReport,
  setActiveReport,
  logEntityClicked,
  handleHighlightOption,
}) => {
  return (
    <>
      <Box
        sx={
          smallSize
            ? {
                position: 'relative',
                minWidth: '220px',
              }
            : {
                width: '450px',
                marginLeft: '20px',
              }
        }
      >
        <Autocomplete
          id="global-search"
          freeSolo={true}
          onOpen={() => togglePopper(true)}
          onClose={() => togglePopper(false)}
          onBlur={() => togglePopper(false)}
          openOnFocus={true}
          autoSelect={false}
          value={searchedText}
          clearOnEscape={true}
          getOptionDisabled={(option) => option}
          disableClearable={true}
          open={autocompleteOpen}
          onHighlightChange={handleHighlightOption}
          sx={
            isFocused && smallSize
              ? {
                  position: 'absolute',
                  top: '-20px',
                  right: '0px',
                  width: '450px',
                }
              : {}
          }
          ListboxProps={{
            style: {
              height: '400px',
              maxHeight: '400px',
              padding: 0,
            }, // maxHeight required to overwrite mui default maxHeight
            onScroll: handleScroll,
          }}
          PopperComponent={GlobalSearchPaper}
          componentsProps={{
            popper: {
              searchedText,
              selectedTab,
              handleTabSelection,
              listOfTabs,
              entityLoader,
              options,
              key: selectedTab,
              isFocused,
              autocompleteOpen,
            },
          }}
          ref={searchBoxRef}
          filterOptions={(opt, state) => {
            return opt
          }}
          options={options}
          getOptionLabel={(option) => {
            if (option.entityName) {
              return option.entityName
            }
            if (typeof option === 'string') return option
            return ''
          }}
          renderOption={(props, option, { selected }) => (
            <ListItem
              key={
                selectedTab === 0
                  ? `all-${option.id}-list`
                  : `${option.type}-${option.id}-list`
              }
              sx={{
                padding: 0,
                border: 'none',
                '&.MuiAutocomplete-option': {
                  padding: '0px !important',
                },
                '&.Mui-focused': {
                  background: ({ palette }) =>
                    `${palette.action.hover} !important`,
                },
              }}
              {...props}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <GlobalSearchTabCard
                  needIcon={searchedText === ''}
                  searchedText={searchedText}
                  cardDetails={option}
                  projectId={projectId}
                  sendRecentHistory={sendRecentHistory}
                  setSearchedText={setSearchedText}
                  activeReport={activeReport}
                  setActiveReport={setActiveReport}
                  logEntityClicked={logEntityClicked}
                />
              </Box>
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={() => {
                setIsOptionFocused(true)
                togglePopper(true)
              }}
              value={searchedText}
              variant="outlined"
              placeholder="Search for Trends and Reports"
              size="large"
              sx={{
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  padding: '5px !important',
                  paddingRight: '5px !important',
                  '& .MuiOutlinedInput-notchedOutline ': {
                    border: ({ palette }) =>
                      `1px solid ${palette.secondary.dark} !important`,
                  },
                },
                ...(isFocused && {
                  boxShadow: ({ palette }) =>
                    `-34px 0px 16px ${palette.shadow.overlay}`,
                }),
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {!isFocused ? (
                      <Chip
                        label={currentOS() === 'Mac' ? '⌘ + F' : 'Ctrl + F'}
                        clickable={false}
                        sx={{
                          background: ({ palette }) => palette.grey[200],
                          '& .MuiChip-label': {
                            color: ({ palette }) => palette.text.hint,
                          },
                          ':hover': {
                            background: ({ palette }) => palette.grey[200],
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          paddingRight: '5px',
                          color: ({ palette }) => palette.secondary.dark,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={clearSearchedText}
                      >
                        Clear
                      </Typography>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      sx={{
                        color: ({ palette }) =>
                          !isFocused ? palette.icon : palette.secondary.dark,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          onChange={(e, v) => {
            handleOnChange(e, v)
          }}
          onKeyUp={(e, v) => {
            handleKeyUp(e, v)
          }}
        />
      </Box>
    </>
  )
}

export default GlobalSearchUI
