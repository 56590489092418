import React from 'react'
import { Box, Typography, IconButton } from '@mui/material'

const FixedSnackbar = ({
  preIcon,
  postIcon,
  message,
  postIconAction,
  postIconSx,
  messageProps,
  containerSx,
}) => {
  return (
    <Box
      px={3}
      py={1}
      sx={{
        display: 'flex',
        background: ({ palette }) => palette.colors.snackbarBackground,
        alignItems: 'center',
        ...containerSx,
      }}
    >
      <IconButton size="small" aria-label="close" color="inherit">
        {preIcon}
      </IconButton>
      <Typography {...messageProps}>{message}</Typography>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={postIconAction}
        sx={postIconSx}
      >
        {postIcon}
      </IconButton>
    </Box>
  )
}

export default FixedSnackbar
