import React, { useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import { Box, Link, ListItem, Typography } from '@mui/material'

import store from '../../../indexStore'
import { fetchRecentActivity, logRecentActivity } from '../../../store/actions'
import palette from '../../../theme/palette'
import { getColoredLabelFromPalette } from '../DashboardFavoritesTrendsCardsList'
import TextOverflowTooltip from '../TextOverflowTooltip'
import UnorderedListRound from '../UnorderedListRound'

export const shouldLogRecentActivity = (
  title,
  activityType,
  redirectionUrl,
) => {
  store.dispatch(logRecentActivity(title, activityType, redirectionUrl))
}

const DashboardRecentActivity = (props) => {
  const { recentActivity, fetchRecentActivity, email, amplitudeFn } = props

  const handleRecentActivityClick = (activityName) => {
    const ampiEvent = 'Clicked_MW_RecentActivity'
    const ampiUserData = {
      User: email,
      RecentActivity_Name: activityName,
    }
    amplitudeFn(ampiEvent, ampiUserData)
  }

  useEffect(() => {
    fetchRecentActivity()
  }, [])

  return (
    <Box sx={{ height: '100%', py: 1, overflow: 'auto' }}>
      {recentActivity && recentActivity.length ? (
        recentActivity.map((obj) => (
          <Box
            sx={{
              px: 3,
              py: 1,
            }}
          >
            <UnorderedListRound>
              <ListItem>
                <Box>
                  <Link
                    href={obj.url}
                    underline="none"
                    target="_blank"
                    onClick={() =>
                      handleRecentActivityClick(
                        `${obj.activity_type}: ${obj.title}`,
                      )
                    }
                  >
                    <TextOverflowTooltip
                      title={`${obj.activity_type}: ${obj.title}`}
                      maxLineCount={1}
                    >
                      <Typography
                        sx={{
                          '&:hover': {
                            color: ({ palette }) =>
                              `${palette.primary.dark} !important`,
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {getColoredLabelFromPalette(
                          obj.activity_type,
                          palette.primary.main,
                          'body1',
                        )}
                        : {obj.title}
                      </Typography>
                    </TextOverflowTooltip>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mt: 1,
                      color: ({ palette }) => palette.text.secondary,
                    }}
                  >
                    {moment(obj.created_at).fromNow()}
                  </Typography>
                </Box>
              </ListItem>
            </UnorderedListRound>
          </Box>
        ))
      ) : (
        <Box
          display="flex"
          sx={{ pt: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          No Recent Activity
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return {
    recentActivity: myDashboard.recentActivity,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchRecentActivity: () => dispatch(fetchRecentActivity()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardRecentActivity)
