import Popper from '@mui/material/Popper'
import { styled } from '@mui/system'
import { autocompleteClasses } from '@mui/material/Autocomplete'

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export default StyledPopper
