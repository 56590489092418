function snakeToCamel(snakeStr) {
  return snakeStr.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
}
    
export const convertListKeysToCamelCase = (data) => {
  return data.map(obj => {
    const newObj = {};
    for (const key in obj) {
      const newKey = snakeToCamel(key);
      newObj[newKey] = obj[key];
    }
    return newObj;
  });
}