import React, { useState, useMemo } from 'react'
import {
  Box,
  Typography,
  ListItemButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import TextOverflowTooltip from '../TextOverflowTooltip'
import MenuListFooterButtons from '../MenuListFooterButtons'
const SingleSelectDropdown = ({
  onApply,
  placeHolderText,
  headerText,
  onSelect,
  dropDownListItems,
  logSubCategoryOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    logSubCategoryOpen()
    setAnchorEl(event.currentTarget)
  }

  const handleApplyClick = (selectedItemName) => {
    onApply(selectedItemName)
    handleClose()
  }
  const selectedItem = useMemo(() => {
    const checkedItems = dropDownListItems.find((obj) => obj.checked)
    return checkedItems?.displayValue
  }, [dropDownListItems])

  return (
    <>
      <ListItemButton
        sx={{
          width: '250px',
          border: ({ palette }) => `1px solid ${palette.divider}`,
          borderRadius: '5px',
          height: '35px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={(e) => handleClick(e)}
      >
        {selectedItem ? (
          <Typography
            variant="subtitle2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis !important',
            }}
          >
            {headerText}&nbsp;
            <Typography
              display="inline"
              sx={{
                color: ({ palette }) => `${palette.secondary.dark}`,
              }}
              variant="subtitle2"
            >
              {selectedItem}
            </Typography>
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            sx={{
              color: ({ palette }) => ` ${palette.text.secondary}`,
            }}
          >
            {placeHolderText}
          </Typography>
        )}
        {open ? (
          <ExpandLess
            sx={{
              color: ({ palette }) => palette.icon,
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              color: ({ palette }) => palette.icon,
            }}
          />
        )}
      </ListItemButton>
      <Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '250px',
            },
          }}
        >
          <Box sx={{ height: '250px', overflowY: 'auto' }}>
            {dropDownListItems.length &&
              dropDownListItems.map(({ id, name, checked }) => (
                <MenuItem>
                  <FormControlLabel
                    sx={{ height: '20px' }}
                    value={id}
                    key={id}
                    label={
                      <TextOverflowTooltip title={name} checkWidth={true}>
                        <Typography variant="subtitle2" sx={{ width: '170px' }}>
                          {name}
                        </Typography>
                      </TextOverflowTooltip>
                    }
                    control={
                      <Radio
                        checked={checked}
                        inputProps={{ 'aria-labelledby': id }}
                        onClick={() => {
                          onSelect(id)
                        }}
                        color="secondary"
                        size="small"
                        sx={{ ml: -1, '&:hover': { bgcolor: 'transparent' } }}
                      />
                    }
                  />
                </MenuItem>
              ))}
          </Box>

          <MenuListFooterButtons
            enableApplyButton
            handleApplyFiltersClick={() => handleApplyClick(selectedItem)}
          />
        </Menu>
      </Box>
    </>
  )
}
export default SingleSelectDropdown
