import React from 'react'
import { Grid, Typography, Link, IconButton } from '@mui/material'

import { ReactComponent as Logo } from '../../../assets/logo_v2.svg'
import { FBIcon, InstagramIcon, LinkedInIcon, MediumIcon } from './styles'
import { amplify } from './../../../store/utility'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const PublicFooter = ({ screenwinner, email, location }) => {
  const footerAmplitude = (ampiEventName) => {
    const ampiEvent = `${ampiEventName}`
    const ampiUserData = {
      User: email,
    }
    amplify(ampiEvent, ampiUserData, email)
  }
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Logo width={104} height={32} />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <Link
              href={
                screenwinner
                  ? '/screen-winner/faq'
                  : '/mui/docs?RedirectPath=/docs/faqs-1'
              }
              variant="black"
              onClick={() => {
                const ampiEvent = 'Clicked_FAQ'
                const ampiUserData = {
                  Clicked: true,
                  User: email,
                  Location: location ? location.pathname : null,
                }
                amplify(ampiEvent, ampiUserData, email)
              }}
            >
              FAQs
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="/mui/contact-us"
              variant="black"
              onClick={() => {
                footerAmplitude('Clicked_Contact_Us')
              }}
            >
              Contact Us
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="/mui/terms-of-service"
              variant="black"
              onClick={() => {
                footerAmplitude('Clicked_Terms_of_Service')
              }}
            >
              Terms of Service
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="https://www.jotform.com/aipalette/feedback-form"
              component="a"
              target="_blank"
              variant="black"
              onClick={() => {
                footerAmplitude('Clicked_Feedback')
              }}
            >
              Feedback
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="/mui/privacy-policy"
              variant="black"
              onClick={() => {
                footerAmplitude('Clicked_Privacy_Policy')
              }}
            >
              Privacy Policy
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" color="text.secondary">
              Follow us:
            </Typography>
          </Grid>
          <Grid item>
            <Link
              component="a"
              target="_blanc"
              href="https://medium.com/ai-palette"
              onClick={() => {
                footerAmplitude('Clicked_Medium')
              }}
            >
              <IconButton>
                <MediumIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              component="a"
              target="_blanc"
              href="https://www.linkedin.com/company/aipalette/"
              onClick={() => {
                footerAmplitude('Clicked_Linkedin')
              }}
            >
              <IconButton>
                <LinkedInIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              component="a"
              target="_blanc"
              href="https://www.facebook.com/aipalette/"
              onClick={() => {
                footerAmplitude('Clicked_Facebook')
              }}
            >
              <IconButton>
                <FBIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              component="a"
              target="_blanc"
              href="https://www.instagram.com/aipalette/"
              onClick={() => {
                footerAmplitude('Clicked_Instagram')
              }}
            >
              <IconButton>
                <InstagramIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
  }
}

export default withRouter(connect(mapStateToProps)(PublicFooter))
