import { WhiteSpaceActionTypes, ResetActions } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  wsoData : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case ResetActions.type[ResetActions.RESET_WSO_DATA]:
      return updateObject(state, initialState)
    case WhiteSpaceActionTypes.type[WhiteSpaceActionTypes.WHITE_SPACE_SUCCESS]: return updateObject(state, {
      wsoData : action.whiteSpaceData,
      error : false,
      loading : false,
    })
    case WhiteSpaceActionTypes.type[WhiteSpaceActionTypes.WHITE_SPACE_FAILED]: return updateObject(state, {
      wsoData : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer