import { alpha } from '@mui/material'
import palette from '../palette'

export default {
  variants: [
    {
      props: { variant: 'grayishblue' },
      style: {
        backgroundColor: '#EFF2F7',
        color: '#B1C1D2',
        ':hover': {
          color: '#B1C1D2',
          backgroundColor: '#EFF2F7',
        },
        fontSize: '0.813rem',
        lineHeight: 1.23,
        fontWeight: 400,
        letterSpacing: '0.029em',
        padding: '8px 16px',
      },
    },
    {
      props: { variant: 'grey' },
      style: {
        background: palette.grey['200'],
        color: palette.text.primary,
        borderRadius: '4px',
        boxShadow: 'none',
        padding: '8px 16px',
        '&.MuiButton-containedError': {
          background: palette.error.main,
        },
        '& .MuiButton-endIcon': {
          transition: '250ms',
          color: palette.text.primary,
        },
        '&:hover': {
          background: palette.grey['100'],
          boxShadow: 'none',
          '& .MuiButton-endIcon': {
            color: palette.text.primary,
          },
          '&.MuiButton-containedError': {
            background: palette.error.dark,
          },
        },
        '&:active': {
          background: palette.grey['300'],
          boxShadow: 'none',
          '& .MuiButton-endIcon': {
            color: palette.text.primary,
          },
        },
        '&:disabled': {
          background: palette.grey['200'],
          color: palette.text.disabled,
          boxShadow: 'none',
          filter: 'none',
          '& .MuiButton-endIcon': {
            color: palette.icon,
          },
        },
      },
    },
    {
      props: { variant: 'outlineHighlighted' },
      style: {
        border: `1px solid ${palette.secondary.main}`,
        color: alpha(palette.text.primary, 0.5),
        background: palette.background.paper,
        borderRadius: '4px',
        padding: '8px 16px',
        '& .MuiButton-startIcon': {
          transition: '250ms',
          color: palette.secondary.main,
        },
        '&:hover': {
          border: `1px solid ${palette.primary.light}`,
          background: palette.background.paper,
          color: palette.text.primary,
          '& .MuiButton-startIcon': {
            color: palette.primary.light,
          },
          '&.MuiButton-outlinedError': {
            border: `1px solid ${palette.error.main}`,
          },
        },
        '&:active': {
          border: `1px solid ${palette.primary.light}`,
          background: palette.background.paper,
          color: palette.primary.main,
          '& .MuiButton-startIcon': {
            color: palette.primary.main,
          },
        },
        '&:disabled': {
          border: `1px solid ${palette.divider}`,
          color: palette.text.hint,
          boxShadow: 'none',
          filter: 'none',
          '& .MuiButton-startIcon': {
            color: palette.icon,
          },
        },
      },
    },
  ],
  styleOverrides: {
    contained: {
      background: palette.primary.main,
      color: 'white',
      borderRadius: '4px',
      boxShadow: 'none',
      padding: '8px 16px',
      '&.MuiButton-containedError': {
        background: palette.error.main,
      },
      '& .MuiButton-endIcon': {
        transition: '250ms',
        color: palette.white,
      },
      '&:hover': {
        background: palette.primary.dark,
        boxShadow: 'none',
        '& .MuiButton-endIcon': {
          color: palette.white,
        },
        '&.MuiButton-containedError': {
          background: palette.error.dark,
        },
      },
      '&:active': {
        background: palette.primary.light,
        boxShadow: 'none',
        '& .MuiButton-endIcon': {
          color: palette.white,
        },
      },
      '&:disabled': {
        background: palette.grey['200'],
        color: palette.text.disabled,
        boxShadow: 'none',
        filter: 'none',
        '& .MuiButton-endIcon': {
          color: palette.icon,
        },
      },
    },
    containedSizeLarge: {
      fontSize: '1rem',
      lineHeight: 1.12,
      fontWeight: 400,
      letterSpacing: '0.029em',
      padding: '11px 16px',
      borderRadius: '4px',
    },
    containedSizeSmall: {
      fontSize: '0.813rem',
      lineHeight: 1.23,
      fontWeight: 400,
      letterSpacing: '0.029em',
      padding: '6px 16px',
      borderRadius: '4px',
    },
    outlined: {
      border: `1px solid ${palette.divider}`,
      color: palette.text.primary,
      background: palette.background.paper,
      borderRadius: '4px',
      padding: '8px 16px',
      '& .MuiButton-startIcon': {
        transition: '250ms',
        color: palette.icon,
      },
      '&:hover': {
        border: `1px solid ${palette.primary.light}`,
        background: palette.background.paper,
        color: palette.text.primary,
        '& .MuiButton-startIcon': {
          color: palette.icon,
        },
        '&.MuiButton-outlinedError': {
          border: `1px solid ${palette.error.main}`,
        },
      },
      '&:active': {
        border: `1px solid ${palette.primary.light}`,
        background: palette.background.paper,
        color: palette.primary.main,
        '& .MuiButton-startIcon': {
          color: palette.primary.main,
        },
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
        color: palette.text.hint,
        boxShadow: 'none',
        filter: 'none',
        '& .MuiButton-startIcon': {
          color: palette.icon,
        },
      },
    },
    outlinedSizeLarge: {
      fontSize: '1rem',
      lineHeight: '1.12',
      fontWeight: 400,
      letterSpacing: '0.029em',
      padding: '11px 16px',
      borderRadius: '4px',
    },
    outlinedSizeSmall: {
      fontSize: '0.813rem',
      lineHeight: '1.23',
      fontWeight: 400,
      letterSpacing: '0.006em',
      padding: '6px 16px',
      borderRadius: '4px',
    },
    text: {
      color: palette.primary.dark,
      '&:hover': {
        color: palette.primary.main,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '&:active': {
        color: palette.primary.light,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '&:disabled': {
        color: palette.text.hint,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
    textSizeLarge: {
      fontSize: '1rem',
      lineHeight: 1.12,
      fontWeight: 400,
      letterSpacing: '0.029em',
    },
    textSizeSmall: {
      fontSize: '0.813rem',
      lineHeight: 1.23,
      fontWeight: 400,
      letterSpacing: '0.029em',
    },
  },
}
