
import axios from './../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const handleNewUIRedirect = (blankPage=true) => {
    const BASE_URL = `${process.env.REACT_APP_NEW_UI_URL}redirect-page`
    const request = NetworkUtil.fetchRedirectToken()
    const authKey = localStorage.getItem('aiPaletteUserAuthKey')
    const options = {
      headers: {
        Authorization: `${authKey}`,
      },
    }
    axios
      .get(request, options)
      .then((response) => {
        const authKey = response.data.token
        if (blankPage) {
          window.open(`${BASE_URL}?token=${authKey}`, '_blank')
        }
        else {
          window.location = `${BASE_URL}?token=${authKey}`
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }