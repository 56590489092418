import * as React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { StyledBox } from './style'

const PairingHoverCard = ({
  projectid,
  productid,
  left,
  top,
  x,
  y,
  title,
  classification,
  image,
  viewDetailsClicked,
  closeTooltip,
  leftTooltip,
  topTooltip,
  twoYearCagr,
  name,
}) => (
  <StyledBox
    left={left}
    top={top}
    leftTooltip={leftTooltip}
    topTooltip={topTooltip}
    onMouseLeave={closeTooltip}
  >
    <Card sx={{ width: 240 }}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt="green iguana"
      />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            gutterBottom
            variant="subtitle3"
            sx={{ color: 'primary.main', fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Typography variant="caption" sx={{ color: 'primary.main' }}>
            <Typography
              component="span"
              variant="caption"
              sx={{ color: 'primary.main' }}
            >
              Trend
            </Typography>
            &nbsp; : &nbsp;
            {classification}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 1,
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">
                Strength of {name} and {title}
              </Typography>
              <Typography variant="caption" sx={{ color: 'primary.main' }}>
                {parseFloat(y).toFixed(2)}%
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">2 Year CAGR of {title}</Typography>
              <Typography variant="caption" sx={{ color: 'primary.main' }}>
                {parseFloat(x).toFixed(2)}%
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">2 Year CAGR of {name}</Typography>
              <Typography variant="caption" sx={{ color: 'primary.main' }}>
                {parseFloat(twoYearCagr).toFixed(2)}%
              </Typography>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{ width: '100%', mt: 1, mb: -1 }}
            size="small"
            onClick={() => {
              viewDetailsClicked()
              window.open(
                `/mui/details/${projectid}/ingredient/${productid}`,
                '_blank',
              )
            }}
          >
            View Details
          </Button>
        </Box>
      </CardContent>
    </Card>
  </StyledBox>
)

PairingHoverCard.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  classification: PropTypes.oneOf([
    'Dormant',
    'Emerging',
    'Growing',
    'Matured',
    'Declining',
    'Fading',
  ]).isRequired,
  image: PropTypes.string.isRequired,
}

export default PairingHoverCard
