import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ErrorPage from '../../pages/ErrorPage'

const FeatureAuthorizer = ({
  subscriptionDetails,
  children,
  authAttr,
  disableForFeat,
}) => {
  // Check if the only_enabled matches the give disableForFeat
  const checkDisableForFeat = () => {
    return subscriptionDetails?.only_enabled?.only_enabled?.includes(
      disableForFeat,
    )
  }

  // Check if the authAttr is TRUE
  const checkAuthAttr = () => {
    return subscriptionDetails[authAttr]
  }

  if (subscriptionDetails) {
    // Render children only if the disableForFeat DOESN'T EXIST and the authAttr EXISTS
    if (disableForFeat && authAttr) {
      return checkAuthAttr() && !checkDisableForFeat() ? (
        children
      ) : (
        <ErrorPage text="You are not authorized to view this page" />
      )
    }
    // Render children only if the disableForFeat DOESN'T EXIST
    if (disableForFeat) {
      return !checkDisableForFeat() ? (
        children
      ) : (
        <ErrorPage text="You are not authorized to view this page" />
      )
    }
    // Render children only if the authAttr EXISTS
    if (authAttr) {
      return checkAuthAttr() ? (
        children
      ) : (
        <ErrorPage text="You are not authorized to view this page" />
      )
    }
  }
  return null
}

const mapStateToProps = ({ projectListBuilder }) => {
  return { subscriptionDetails: projectListBuilder.subscriptionDetails }
}

FeatureAuthorizer.propTypes = {
  subscriptionDetails: PropTypes.object,
  children: PropTypes.node,
  authAttr: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(FeatureAuthorizer)
