import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { getValidDate } from '../../helpers/DateHelper'
import { styled } from '@mui/system'
import ClearIcon from '@mui/icons-material/Clear'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  maxHeight: 'calc(100% - 50px)',
  overflow: 'hidden',
  display: 'flex',
}

export const TitleComponent = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  display: 'inline-block',
  // borderLeft: "4px solid #0274CA"
  color: theme.palette.primary.main,
}))

export const DescriptionComponent = styled(Typography)(({ theme }) => ({
  margin: '16px 0px',
  fontSize: '14px',
}))

export const EditComponent = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export const DateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black,
  display: 'inline-block',
}))

export default function ConceptDetailsViewModel({
  conceptDetails,
  active,
  onClose,
  onEdit,
}) {
  const {
    id,
    title,
    created_at: createdAt,
    generated_concept: generatedConcept,
    description,
  } = conceptDetails
  const [open, setOpen] = React.useState(active)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)

    onClose()
  }

  const handleEdit = () => {
    setOpen(false)

    onClose()
    onEdit()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box sx={{ overflow: 'auto', p: 4, flexGrow: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TitleComponent>Concept Name</TitleComponent>
                <Box display="flex" alignItems={'center'} gap={1}>
                  <Box
                    sx={{
                      mr: 1,
                      display: 'flex',
                      alignItems: 'center',
                      color: ({ palette }) => palette.primary.main,
                      gap: 0.5,
                      cursor: 'pointer',
                    }}
                    onClick={handleEdit}
                  >
                    <EditOutlinedIcon
                      sx={{
                        color: ({ palette }) =>
                          `${palette.primary.main} !important`,
                      }}
                      fontSize="medium"
                    />{' '}
                    <Typography color={'inherit'}>Edit</Typography>
                  </Box>

                  <Box display="flex">
                    <ClearIcon
                      onClick={handleClose}
                      sx={{
                        color: ({ palette }) => palette.black,
                        cursor: 'pointer',
                      }}
                      fontSize="medium"
                    />
                  </Box>
                </Box>
              </Box>

              <DescriptionComponent style={{ fontSize: '14px' }}>
                {title}
              </DescriptionComponent>
              <TitleComponent>Concept Description</TitleComponent>

              <DescriptionComponent style={{ fontSize: '14px' }}>
                {generatedConcept}
              </DescriptionComponent>

              <TitleComponent>Keywords</TitleComponent>

              <DescriptionComponent style={{ fontSize: '14px' }}>
                {description.split(',').join(', ')}
              </DescriptionComponent>

              <TitleComponent>Created on - &nbsp; </TitleComponent>
              <DateText>{getValidDate(createdAt)}</DateText>
            </Box>
          </Box>
        </>
      </Modal>
    </div>
  )
}
