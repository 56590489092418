import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { MenuList, Typography } from '@mui/material'
import { Box } from '@mui/system'

import SearchInput from '../SearchInput'
import MenuListHeader from '../MenuListHeader'
import MultiSelectMenuItems from '../MultiSelectMenuItems'
import MenuListFooterButtons from '../MenuListFooterButtons'
import AdvSearchEmptyState from '../../../assets/images/testImages/AdvSearchEmptyState.svg'
import { compareTwoArrayOfObjects } from '../../../store/utility'
import SearchNotSelected from '../SearchNotSelected'

const MultiSelectMenuList = ({
  options,
  shouldSortOpts,
  onOptClick,
  searchBarEnabled,
  header,
  applyButton,
  resetButton,
  selectAllButton,
  selectAllOption,
  shouldRemindApplyingFilters,
  shouldShowTooltip,
  emptyState,
}) => {
  const inputRef = useRef()
  const numOfFiltersApplied = options.filter(
    (option) => option.checked === true,
  ).length
  const [settingOptions, setSettingOptions] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [reqOptions, setReqOptions] = useState(
    shouldSortOpts
      ? options.sort((a, b) => (a.label > b.label ? 1 : -1))
      : options,
  )
  const [beforeSearchOptions, setBeforeSearchOptions] = useState(
    shouldSortOpts
      ? options.sort((a, b) => (a.label > b.label ? 1 : -1))
      : options,
  )
  const [allChecked, setAllChecked] = useState(false)
  useEffect(() => {
    setAllChecked(
      options.length ===
        options.filter((option) => option.checked === true).length,
    )
    if (shouldSortOpts) {
      setReqOptions(options.sort((a, b) => (a.label > b.label ? 1 : -1)))
    } else {
      setReqOptions(options)
    }
  }, [options, settingOptions])

  useEffect(() => {
    if (settingOptions) {
      const allOptionsChecked =
        options.length === options.filter((option) => option.checked).length
      setAllChecked(allOptionsChecked)
      setSettingOptions(false)
    }
  }, [settingOptions])

  const handleOptionsSet = () => {
    if (searchBarEnabled) {
      setSearchValue('')
    }
    setSettingOptions(true)
  }

  const getCheckedOptionsLength = (allOptions) => {
    return allOptions.filter((option) => option.checked === true).length
  }

  const handleOptClick = (opt) => (ev) => {
    ev.preventDefault()
    if (applyButton) {
      if (searchValue !== '') {
        const updatedOptions = beforeSearchOptions.map((option) =>
          option.key === opt.key
            ? { ...option, checked: !option.checked }
            : { ...option },
        )
        setReqOptions(updatedOptions)

        setAllChecked((checkboxStatus) =>
          checkboxStatus === true
            ? !checkboxStatus
            : getCheckedOptionsLength(updatedOptions) ===
              beforeSearchOptions.length
            ? !checkboxStatus
            : checkboxStatus,
        )
        setSearchValue('')
      } else {
        const updatedOptions = reqOptions.map((option) =>
          option.key === opt.key
            ? { ...option, checked: !option.checked }
            : { ...option },
        )
        setReqOptions(updatedOptions)
        setAllChecked((checkboxStatus) =>
          checkboxStatus === true
            ? !checkboxStatus
            : getCheckedOptionsLength(updatedOptions) === reqOptions.length
            ? !checkboxStatus
            : checkboxStatus,
        )
      }
    } else if (onOptClick) {
      onOptClick(opt)
      setSearchValue('')
    }
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const strCheck = (str, subStr) =>
    str.toLowerCase().includes(subStr.toLowerCase())

  const handleSearchBarChange = (event) => {
    if (searchValue === '' && event.target.value !== '') {
      setBeforeSearchOptions(reqOptions)
    }
    setSearchValue(event.target.value)
    if (event.target.value !== '') {
      if (beforeSearchOptions?.length) {
        setReqOptions(
          beforeSearchOptions.filter((option) =>
            strCheck(option.label, event.target.value),
          ),
        )
      } else {
        setReqOptions((filterOpts) =>
          filterOpts.filter((option) =>
            strCheck(option.label, event.target.value),
          ),
        )
      }
    } else {
      setReqOptions(beforeSearchOptions)
    }
    if (
      event.target.value === '' &&
      !(inputRef.current === document.activeElement)
    ) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleApplyFiltersClick = () => {
    if (searchValue !== '') {
      setSearchValue('')
      applyButton.updateFilterOptions(beforeSearchOptions)
    } else {
      applyButton.updateFilterOptions(reqOptions)
    }
    handleOptionsSet()
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleResetFiltersClick = () => {
    resetButton.resetFilterOptions()
    setSearchValue('')
    handleOptionsSet()
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleSelectAllClick = () => {
    if (applyButton) {
      if (searchValue !== '') {
        setReqOptions(
          beforeSearchOptions.map((option) => ({ ...option, checked: true })),
        )
        setSearchValue('')
      } else {
        setReqOptions((filterOpts) =>
          filterOpts.map((option) => ({ ...option, checked: true })),
        )
      }
    } else {
      if ('updateParentOptions' in selectAllButton) {
        selectAllButton.updateParentOptions()
      }
    }
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleAllCheckboxClick = (ev) => {
    ev.preventDefault()
    if (applyButton) {
      if (!allChecked) {
        if (searchValue !== '') {
          setReqOptions(
            beforeSearchOptions.map((option) => ({ ...option, checked: true })),
          )
          setSearchValue('')
        } else {
          setReqOptions((filterOpts) =>
            filterOpts.map((option) => ({ ...option, checked: true })),
          )
        }
      } else {
        if (searchValue !== '') {
          setReqOptions(
            beforeSearchOptions.map((option) => ({
              ...option,
              checked: false,
            })),
          )
          setSearchValue('')
        } else {
          setReqOptions((filterOpts) =>
            filterOpts.map((option) => ({ ...option, checked: false })),
          )
        }
      }
    } else {
      if ('updateParentOptions' in selectAllOption) {
        selectAllOption.updateParentOptions(!allChecked)
      }
    }
    setAllChecked((checkboxStatus) => !checkboxStatus)
    if (searchBarEnabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  return (
    <>
      {header && (
        <MenuListHeader
          header={header}
          numOfFiltersApplied={numOfFiltersApplied}
          extraStyles={{
            py: 1.5,
          }}
        />
      )}
      {emptyState && (
        <SearchNotSelected
          icon={AdvSearchEmptyState}
          boxStyles={{
            height: 564,
            justifyContent: 'flex-start',
            pt: 4,
          }}
          text="Please select Format to populate the Trends"
          textProps={{
            variant: 'subtitle2',
          }}
        />
      )}
      {!emptyState && (
        <MenuList>
          {searchBarEnabled && (
            <Box
              onKeyDown={(e) => e.stopPropagation()}
              component="div"
              sx={{ px: 2, py: 1.5 }}
            >
              <SearchInput
                value={searchValue}
                onChange={handleSearchBarChange}
                txtProps={{
                  autoFocus: true,
                  fullWidth: true,
                  inputRef: inputRef,
                }}
              />
            </Box>
          )}
          <MultiSelectMenuItems
            selectAllOption={selectAllOption}
            searchBarEnabled={searchBarEnabled}
            searchValue={searchValue}
            handleAllCheckboxClick={handleAllCheckboxClick}
            allChecked={allChecked}
            menuOptions={reqOptions}
            handleOptClick={handleOptClick}
            extraBoxStyles={{
              height: 430,
            }}
            shouldShowTooltip={shouldShowTooltip}
          />
        </MenuList>
      )}
      {!emptyState &&
        (applyButton || resetButton || selectAllButton?.enabled) && (
          <MenuListFooterButtons
            enableResetButton={Boolean(resetButton)}
            handleResetFiltersClick={handleResetFiltersClick}
            enableApplyButton={Boolean(applyButton)}
            handleApplyFiltersClick={handleApplyFiltersClick}
            selectAllButton={selectAllButton}
            handleSelectAllClick={handleSelectAllClick}
          />
        )}
      {shouldRemindApplyingFilters &&
        options &&
        !compareTwoArrayOfObjects(options, reqOptions) &&
        (!searchBarEnabled || searchValue === '') && (
          <Box
            sx={{
              width: '100%',
              fontSize: '0.75rem',
              px: 1.5,
              pb: 1,
              color: ({ palette }) => palette.text.hint,
            }}
          >
            Please click on apply to see changes
          </Box>
        )}
    </>
  )
}

MultiSelectMenuList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    }),
  ).isRequired,
  onOptClick: PropTypes.func,
  header: PropTypes.shape({
    content: PropTypes.string.isRequired,
    showNumOfFiltersApplied: PropTypes.bool,
  }),
  searchBarEnabled: PropTypes.bool,
  applyButton: PropTypes.shape({
    updateFilterOptions: PropTypes.func.isRequired,
  }),
  resetButton: PropTypes.shape({
    resetFilterOptions: PropTypes.func.isRequired,
  }),
  selectAllButton: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
  selectAllOption: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
  shouldRemindApplyingFilters: PropTypes.bool,
}

export default MultiSelectMenuList
