import { connect } from 'react-redux'
import { UserRegistrationForm } from '../../components'
import * as A from '../../store/actions'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values) => {
    console.log('hello', values)
    dispatch(A.addOrgAdminOnServer(values))
  },
})

const mapStateToProps = (state) => ({
  all: state.user.all,
})

const UserRegistrationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationForm)

export default UserRegistrationFormContainer
