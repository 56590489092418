import * as AC from '../actions/actionConstants'

const buildWinnerReducer = (state = {
  latestInputID: undefined,
  list: [],
  all: [],
  foodCategories: [],
  winningIdeas: [],
  personalityKeywords: ['Adaptable', 'Alluring', 'Ambitious', 'Amusing', 'Boundless', 'Brave', 'Bright', 'Calm', 'Charming', 'Cheerful', 'Confident', 'Cultured', 'Dashing', 'Debonair', 'Decisive', 'Determined', 'Dynamic', 'Eager', 'Efficient', 'Encouraging', 'Energetic', 'Entertaining', 'Excellent', 'Fabulous', 'Fair', 'Faithful', 'Family friendly', 'Funny', 'Gentle', 'Good', 'Happy', 'Harmonious', 'Health Oriented', 'Helpful', 'Hilarious', 'Impartial', 'Joyous', 'Kind', 'Long Lasting', 'Loving', 'Mature', 'Modern', 'Natural', 'Novelty', 'Peaceful', 'Productive', 'Proud', 'Responsible', 'Romantic', 'Selective', 'Sensitive', 'Simple', 'Sophisticated', 'Stimulating', 'Thoughtful', 'Trustworthy', 'Unbiased', 'Unusual', 'Vigorous', 'Vivacious', 'Wise', 'Witty', 'Wonderful', 'Zealous'],
  emotionKeywords: ['Comforting', 'Confident', 'Connected', 'Curious', 'Enthusiastic', 'Free', 'Grateful', 'Hip', 'Hipster', 'Hopeful', 'Joyful', 'Loving', 'Nostalgia', 'Peaceful', 'Prosperous', 'Purposeful', 'Relieved', 'Social', 'Spiritual'],
  functionalKeywords: ['Awake', 'Breakfast', 'Clean Food', 'Digestion', 'Dinner', 'Energy', 'Fitness', 'Fun', 'Gift', 'Hand Crafted', 'Healthy', 'Homemade', 'Keto', 'Local', 'Low Fat', 'Lunch', 'Morning', 'Natural', 'Organic', 'Pairing', 'Protein', 'Rejuvenated', 'Sleep', 'Snack', 'Versatile', 'Weight Gain', 'Weight Loss', 'Workout'],
  sensoryKeywords: ['Acidic', 'Aftertaste', 'Bitter', 'Bland', 'Bouncy', 'Brittle', 'Bubbly', 'Burnt', 'Cellular', 'Chewy', 'Coarse', 'Cold', 'Colorful', 'Creamy', 'Crisp', 'Crumbly', 'Crunchy', 'Crystalline', 'Dry', 'Elastic', 'Fatty', 'Fibrous', 'Firm', 'Flabby', 'Flaky', 'Flat', 'Foamy', 'Fresh', 'Grained', 'Greasy', 'Gritty', 'Hard', 'Metallic', 'Moist', 'Old', 'Opaque', 'Powdery', 'Pungent', 'Salty', 'Sharp', 'Shiny', 'Slimy', 'Smokey', 'Smooth', 'Soggy', 'Solid', 'Sour', 'Spicy', 'Stale', 'Sweet', 'Syrupy', 'Tangy', 'Tasteless', 'Tasty', 'Umami'],
}, action) => {
  switch (action.type) {
    case AC.ADD_BUILD_WINNER:
      return Object.assign({}, state, {
        list:
                    [...state.list,
                      action.buildWinner,
                    ],
        all:
                    [...state.all,
                      action.buildWinner,
                    ],
        latestInputID: action.buildWinner.reportMappingID,
      })

    case AC.ADD_FOOD_CATEGORIES:
      const foodCategories = action.foodCategories
      return Object.assign({}, state, {
        foodCategories: foodCategories.map(category => {
          const categoryWithoutSemicolon = category.name.split(';')
          if (categoryWithoutSemicolon.length >= 2) {
            category.name = categoryWithoutSemicolon[0] + ', ' + categoryWithoutSemicolon[1]
            return category
          } else { return category }
        }
        ),
      })
    case AC.ADD_WINNING_IDEAS:
      return Object.assign({}, state, {
        winningIdeas: [...action.winningIdeas],
      })
    case AC.RESET_WINNING_IDEAS:
      return Object.assign({}, state, {
        winningIdeas: [],
      })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        latestInputID: undefined,
        list: [],
        all: [],
        foodCategories: [],
        winningIdeas: [],
      })
    default:
      return state
  }
}

export default buildWinnerReducer
