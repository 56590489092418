import React, { useMemo } from 'react'
import DashboardCommonCard from '../../DashboardCommonCard'
import { Box, Typography, List, ListItem, Grid, Link } from '@mui/material'
import FavoriteTrendPillarChart from '../FavoriteTrendPillarChart'
import {
  getColoredLabelFromPalette,
  getColoredLabel,
} from '../../DashboardFavoritesTrendsCardsList'
import _ from 'lodash'
import palette from '../../../../theme/palette'
import Divider from '@mui/material/Divider'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import formatNumber from '../../../utils/formatNumber'
import UnorderedListRound from '../../UnorderedListRound'
import DashboardTooltip from '../../DashboardTooltip'

const numOfMonths = {
  Month: 1,
  Quarter: 3,
  '6 Months': 6,
  Year: 12,
}

const FAVORITES_TREND_PILLAR_HIDE_FS_AND_RETAIL = {
  TIMEFRAMES: ['Month', 'Quarter'],
  LENSES: ['Theme'],
}

const FavoriteTrendPillarsUI = ({
  lensIconMap,
  pillarName,
  country,
  category,
  trendAnalytics,
  totalDatapoint,
  total2YearCagr,
  totalTrends,
  selectedTimeFrame,
  HandleRemoveFromFavorite,
  projectId,
  trendPillarType,
  pillarId,
  graphData,
  twoYearCAGRChange,
  dashboardUpdatedTimestamp,
  email,
  amplitudeFn,
}) => {
  const allGraphData = useMemo(() => {
    if (graphData) {
      return Object.keys(graphData).map((key) => ({
        period: key,
        ...graphData[key],
      }))
    }
    return []
  }, [graphData])

  const handleTrendPillarCardClick = (e) => {
    if (!e.defaultPrevented) {
      const ampiEvent = 'Clicked_MW_Favorites_TrendPillar_PillarName'
      const ampiUserData = {
        User: email,
        Lens: trendPillarType,
        Pillar_Name: pillarName,
        Country: country,
        Category: category,
      }
      amplitudeFn(ampiEvent, ampiUserData)
    }
  }

  const handleHoverOpen = (hoveredOn) => {
    const ampiEvent = 'Clicked_MW_Favorites_TrendPillar_PillarName_Hover'
    const ampiUserData = {
      User: email,
      Lens: trendPillarType,
      Pillar_Name: pillarName,
      Country: country,
      Category: category,
      Hover: hoveredOn,
    }
    amplitudeFn(ampiEvent, ampiUserData)
  }

  const getGraphDataAverage = (data, timeBuffer) => {
    let reqData = []
    const numOfValues = 4
    let numOfValuesCounter = 0
    if (timeBuffer !== 1) {
      while (numOfValuesCounter < numOfValues) {
        let timeBufferCounter = 0
        let twoYearCAGRValue = 0
        let dataToIterate
        if (timeBuffer * numOfValuesCounter === 0) {
          dataToIterate = data.slice(
            -1 * (timeBuffer * (numOfValuesCounter + 1)),
          )
        } else {
          dataToIterate = data.slice(
            -1 * (timeBuffer * (numOfValuesCounter + 1)),
            -1 * (timeBuffer * numOfValuesCounter),
          )
        }
        while (timeBufferCounter < timeBuffer) {
          twoYearCAGRValue += dataToIterate[timeBufferCounter].two_year_cagr
          timeBufferCounter += 1
        }
        reqData.push({
          two_year_cagr: (twoYearCAGRValue / timeBuffer).toFixed(2),
          period: `${new Date(dataToIterate[0].period).toLocaleString('en-US', {
            year: '2-digit',
            month: 'short',
          })}-${new Date(dataToIterate[timeBuffer - 1].period).toLocaleString(
            'en-US',
            {
              year: '2-digit',
              month: 'short',
            },
          )}`
            .split(' ')
            .join(`'`),
        })
        twoYearCAGRValue = 0
        timeBufferCounter = 0
        numOfValuesCounter += 1
      }
      reqData.reverse()
    } else {
      reqData = _.takeRight(data, numOfValues).map((obj) => ({
        two_year_cagr: obj.two_year_cagr,
        period: new Date(obj.period).toLocaleString('en-US', {
          year: '2-digit',
          month: 'short',
        }),
      }))
    }
    return reqData
  }

  const reqGraphData = useMemo(() => {
    if (allGraphData.length) {
      return getGraphDataAverage(allGraphData, numOfMonths[selectedTimeFrame])
    }
    return []
  }, [allGraphData, selectedTimeFrame])

  return (
    <>
      <DashboardCommonCard
        boxStyles={{ p: 0, overflow: 'hidden' }}
        cardProps={{
          xs: 6,
        }}
      >
        <Link
          target="_blank"
          underline="none"
          href={`/mui/trend-pillar?lensSelected=${trendPillarType}&pillarId=${pillarId}`}
          onClick={(e) => handleTrendPillarCardClick(e)}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              height: 300,
            }}
          >
            <Box display="flex" sx={{ width: '100%' }}>
              <Box sx={{ width: '50%' }}>
                {' '}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                  }}
                >
                  <DashboardTooltip title={trendPillarType}>
                    <Box>{lensIconMap[trendPillarType.toLowerCase()]}</Box>
                  </DashboardTooltip>
                  <Typography variant="h4" paddingLeft={'5px'}>
                    {pillarName}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: ({ palette }) => palette.text.secondary,
                    paddingLeft: '55px',
                  }}
                >
                  {country}, {category}
                  {dashboardUpdatedTimestamp &&
                    ` (${new Date(dashboardUpdatedTimestamp).toLocaleString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                      },
                    )})`}
                </Typography>
                <FavoriteTrendPillarChart graphData={reqGraphData} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  width: '50%',
                  paddingLeft: '5px',
                  paddingRight: '24px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <StarRoundedIcon
                    color="primary"
                    sx={{ fontSize: '2em', cursor: 'pointer' }}
                    onClick={(e) => {
                      HandleRemoveFromFavorite(
                        pillarId,
                        trendPillarType,
                        pillarName,
                        projectId,
                        e,
                      )
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <UnorderedListRound>
                    <ListItem>
                      <Typography variant="subtitle2">
                        #Trends:
                        {getColoredLabelFromPalette(
                          ` ${totalTrends} Trends`,
                          palette.primary.main,
                        )}
                      </Typography>
                    </ListItem>
                    {!_.isNil(total2YearCagr) && !_.isNil(twoYearCAGRChange) && (
                      <ListItem>
                        <Typography variant="subtitle2">
                          2 Year CAGR(Change):{' '}
                          {total2YearCagr >= 0
                            ? getColoredLabelFromPalette(
                                `${total2YearCagr}%`,
                                palette.success.dark,
                                'subtitle2',
                              )
                            : getColoredLabelFromPalette(
                                `-${Math.abs(total2YearCagr)}%`,
                                palette.error.dark,
                                'subtitle2',
                              )}{' '}
                          (
                          {twoYearCAGRChange >= 0
                            ? getColoredLabelFromPalette(
                                `+${twoYearCAGRChange}%`,
                                palette.success.dark,
                                'subtitle2',
                              )
                            : getColoredLabelFromPalette(
                                `-${Math.abs(twoYearCAGRChange)}%`,
                                palette.error.dark,
                                'subtitle2',
                              )}
                          )
                        </Typography>
                      </ListItem>
                    )}
                    <ListItem>
                      <Typography variant="subtitle2">
                        Data Points added:{' '}
                        {getColoredLabel(
                          formatNumber(trendAnalytics.data_points),
                          '-',
                        )}
                      </Typography>
                    </ListItem>
                    {!FAVORITES_TREND_PILLAR_HIDE_FS_AND_RETAIL.LENSES.includes(
                      trendPillarType,
                    ) &&
                      !FAVORITES_TREND_PILLAR_HIDE_FS_AND_RETAIL.TIMEFRAMES.includes(
                        selectedTimeFrame,
                      ) && (
                        <>
                          <ListItem>
                            <Typography variant="subtitle2">
                              New Food Service Product added:{' '}
                              {getColoredLabel(
                                formatNumber(
                                  trendAnalytics.products.food_service,
                                ),
                                '-',
                              )}
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="subtitle2">
                              New Retail Products added:{' '}
                              {getColoredLabel(
                                `${trendAnalytics.products.retail}`,
                              )}
                            </Typography>
                          </ListItem>
                        </>
                      )}
                  </UnorderedListRound>
                </Box>
                <Divider />
                <Grid container spacing={1} columns={14} sx={{ pt: 1 }}>
                  <Grid item xs={5} sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="body3"
                      sx={{ color: ({ palette }) => palette.text.secondary }}
                    >
                      Data Point(Change)
                    </Typography>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{ color: palette.primary.main }}
                      >
                        {formatNumber(totalDatapoint)}
                      </Typography>
                      {(trendAnalytics.data_points ||
                        trendAnalytics.data_points === 0) && (
                        <DashboardTooltip
                          title={`Compare to last ${selectedTimeFrame ||
                            'Month'} Data Points of the Trend Pillar has ${
                            String(
                              formatNumber(trendAnalytics.data_points),
                            ).includes('-')
                              ? 'decreased'
                              : 'increased'
                          } by ${formatNumber(trendAnalytics.data_points)}`}
                          handleOpen={() => handleHoverOpen('Data Points')}
                        >
                          <Typography
                            variant="body3"
                            sx={{ color: palette.success.dark }}
                          >
                            &nbsp;(
                            {String(
                              formatNumber(trendAnalytics.data_points),
                            ).includes('-')
                              ? formatNumber(trendAnalytics.data_points)
                              : `+${formatNumber(trendAnalytics.data_points)}`}
                            )
                          </Typography>
                        </DashboardTooltip>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    {' '}
                    <Typography
                      variant="body3"
                      sx={{ color: ({ palette }) => palette.text.secondary }}
                    >
                      # Trends
                    </Typography>
                    <Box>
                      <DashboardTooltip
                        title={`Number of Trends inside ${pillarName} in ${country} ${category}`}
                        handleOpen={() => handleHoverOpen('# Trends')}
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: palette.primary.main }}
                        >
                          {totalTrends}
                        </Typography>
                      </DashboardTooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={5} sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="body3"
                      sx={{ color: ({ palette }) => palette.text.secondary }}
                    >
                      2 Yr CAGR(Change)
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: ({ palette }) =>
                            total2YearCagr >= 0
                              ? palette.success.dark
                              : palette.error.dark,
                        }}
                      >
                        {total2YearCagr}%
                      </Typography>

                      {(twoYearCAGRChange || twoYearCAGRChange === 0) && (
                        <DashboardTooltip
                          title={`Compare to last ${selectedTimeFrame} the Social 2 Yr CAGR of the Trend Pillar has ${
                            twoYearCAGRChange >= 0 ? 'increased' : 'decreased'
                          } by ${Math.abs(twoYearCAGRChange)}`}
                          handleOpen={() => handleHoverOpen('2 Yr CAGR')}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              color: ({ palette }) =>
                                twoYearCAGRChange >= 0
                                  ? palette.success.dark
                                  : palette.error.dark,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            &nbsp;(
                            {twoYearCAGRChange >= 0 ? (
                              <ArrowDropUpRoundedIcon sx={{ mx: '-4px' }} />
                            ) : (
                              <ArrowDropDownRoundedIcon sx={{ mx: '-4px' }} />
                            )}
                            {Math.abs(twoYearCAGRChange)}
                            %)
                          </Typography>
                        </DashboardTooltip>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Link>
      </DashboardCommonCard>
    </>
  )
}
export default FavoriteTrendPillarsUI
