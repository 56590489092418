import React from 'react'

import { Grid, Box, Typography, Divider } from '@mui/material'
import USAGraph from '../../../../shared/components/Graphs/USAGraph'
import { REGIONAL_ANALYTICS_COLORS } from '../../../../shared/utils/constants'
import { ReactComponent as RegionalTrendPageEmptyState } from '../../../../assets/images/EmptyState/RegionalTrendPageEmptyState.svg'

const TrendPageRegionalDistributionChartUI = ({
  regionalDistributionData,
  trendName,
  country,
  category,
  handleRegionClick,
}) => {
  const sortedRegionalDistributionData = regionalDistributionData?.sort(
    (a, b) => {
      return b.distribution - a.distribution
    },
  )

  const colorScheme = {}
  const updatedRegionalDistributionData = sortedRegionalDistributionData?.forEach(
    (region, i) => {
      colorScheme[region.name] = REGIONAL_ANALYTICS_COLORS[i]
    },
  )

  const getDistribution = (region) => {
    if (sortedRegionalDistributionData?.length) {
      const distributionSum = sortedRegionalDistributionData?.reduce(
        (acc, { distribution }) => acc + distribution,
        0,
      )
      return (
        (sortedRegionalDistributionData?.find((data) => data.name === region)
          .distribution /
          distributionSum) *
        100
      ).toFixed(2)
    }
  }
  let regionWisePercentage = [
    {
      code: 'West',
    },
    {
      code: 'Midwest',
    },
    {
      code: 'South',
    },
    {
      code: 'Northeast',
    },
  ]
  regionWisePercentage = regionWisePercentage.map((obj, index) => ({
    ...obj,
    regionName: obj.code.concat('ern Region'),
    percentage: getDistribution(obj.code),
    color: colorScheme[obj.code],
  }))

  return (
    <>
      <Grid sx={{ height: '360px' }} xs={6} item>
        <Box sx={{ background: 'white', mr: 1 }}>
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Regional distribution</Typography>
          </Box>{' '}
          <Divider orientation="horizontal" />
          {regionalDistributionData?.length ? (
            <Grid container>
              <Grid item xs={7} sx={{ height: 306 }}>
                <Box
                  sx={{
                    width: '800px',
                    mr: -30.375,
                    ml: -17.5,
                    mt: -10.25,
                    overflow: 'hidden',
                    pl: 4,
                  }}
                >
                  <USAGraph
                    regionalDistributionData={regionalDistributionData}
                    country={country}
                    category={category}
                    handleRegionClick={handleRegionClick}
                    scale={0.6}
                    hideTooltip
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box>
                  {regionWisePercentage.map((obj) => (
                    <Box key={obj.code}>
                      <Typography variant="subtitle3">
                        {obj.regionName}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: obj.color,
                          mt: 1,
                          mb: 1.5,
                        }}
                      >
                        {obj.percentage}%
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{ height: 306, p: 2, flexDirection: 'column' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ pt: 1.5, mb: 0.75 }}>
                <RegionalTrendPageEmptyState />
              </Box>
              <Box>
                Regional Distribution is not available for you right now
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  )
}
export default TrendPageRegionalDistributionChartUI
