const storage = window.localStorage

class NetworkUtil {
  static saveAuthKey(authKey) {
    storage.setItem('aiPaletteUserAuthKey', authKey)
  }

  static getAuthKey() {
    const key = storage.getItem('aiPaletteUserAuthKey')
    return key
  }

  static clearAuthKey() {
    storage.removeItem('aiPaletteUserAuthKey')
  }

  static saveBWAuthKey(authKey) {
    storage.setItem('aiPaletteBWUserAuthKey', authKey)
  }

  static getBWAuthKey() {
    const key = storage.getItem('aiPaletteBWUserAuthKey') || undefined
    return key
  }

  static hasAccessToBW() {
    if (NetworkUtil.getBWAuthKey()) {
      return true
    } else {
      return false
    }
  }

  static saveSWAuthKey(authKey) {
    storage.setItem('aiPaletteSWUserAuthKey', authKey)
  }

  static getSWAuthKey() {
    const key = storage.getItem('aiPaletteSWUserAuthKey')
    return key
  }

  static clearSWAuthKey() {
    storage.removeItem('aiPaletteSWUserAuthKey')
  }

  static optOutRedirect(){
    storage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_OPT_OUT, 'true')
  }

  static isOptedOut(){
    return storage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_OPT_OUT) === 'true'
  }

  static clearOptOut(){
    storage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_OPT_OUT)
  }

  static formSearchRequest() {
    return RequestEndpoint.url[RequestEndpoint.SEARCH]
  }

  static formSavedProjectListRequest() {
    return RequestEndpoint.url[RequestEndpoint.PROJECTS]
  }

  static fetchSubscriptionsDetailsRequest() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.SUBSCRIPTION_DETIALS]}`,
    )
  }

  static formSearchRequestForProject(projectid) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PROJECTS]}${projectid}/`,
    )
  }

  // Product Filter
  static formSearchRequestForProductFilter(productId) {
    // /api/project/product_classification/9/
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER]}${productId}/`,
    )
  }

  // Theme Filter
  static formSearchRequestForThemeFilter(themeId) {
    // /api/project/theme_classification/9/
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.THEME_FILTER]}${themeId}/`,
    )
  }

  static formSearchRequestForNeedsList(projectid) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PROJECTS]}${projectid}${
        RequestEndpoint.url[RequestEndpoint.NEEDSLIST]
      }`,
    )
  }

  static fetchCafeProducts(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/cafeProduct/`,
    )
  }

  static fetchCafeProductsPairing(productID, ingredientName) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/cafeProduct/?ingredient=${ingredientName}`,
    )
  }

  static fetchCafeProductFormatsUrl(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/product_format_list/?product_type=cafe_product`,
    )
  }

  static fecthWhiteSpaceOpportunityGraphDataRequest(projectId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PROJECTS]
      }${projectId}/white_spaces/`,
    )
  }

  static fecthFeaturesDetailsRequest(projectId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PROJECTS]}features/${projectId}/`,
    )
  }

  static fetchCafeProductsForProductFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_CAFE_PRODUCTS]
      }${productID}/cafeProduct/`,
    )
  }

  static fetchCafeProductsForThemeFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_CAFE_PRODUCTS]
      }${productID}/cafeTheme/`,
    )
  }

  static fetchRetailProducts(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/retailProduct/?page_no=1&page_size=1000`,
    )
  }

  static fetchRetailProductsPairing(productID, ingredientName) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/retailProduct/?ingredient=${ingredientName}`,
    )
  }

  static fetchRetailProductFormatsUrl(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/product_format_list/?product_type=retail_product`,
    )
  }

  static fetchPairingDetails(productID) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCTS]}${productID}/pairing/`,
    )
  }

  static downloadPairingDetails(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/pairing/download/`,
    )
  }

  static fetchCountrySpecificPairingDetails(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/pairing_data/`,
    )
  }

  static fetchTrendsDetailsByName() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]}summary/`,
    )
  }

  static fetchRelatedTrendsForIngredients(productid) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }ingredients/${productid}/`,
    )
  }

  static fetchRelatedTrendsForProducts(productid) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }products/${productid}/`,
    )
  }

  static fetchRelatedTrendsForThemes(productid) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }themes/${productid}/`,
    )
  }

  static fetchRelatedTrends(lens, trendId, subCategories, include) {
    let includeParam = ''
    include.forEach((value) => {
      includeParam = includeParam.concat(`&include=${value}`)
    })
    let subCatList = ''
    include.forEach((value) => {
      const preText = subCatList ? '&' : '?'
      switch (value) {
        case 'ingredient':
          subCatList = subCatList.concat(
            `${preText}ing_sub_cat=${encodeURI(
              subCategories.ingredient || 'All',
            )}`,
          )
          break
        case 'product':
          subCatList = subCatList.concat(
            `${preText}prod_sub_cat=${encodeURI(
              subCategories.product || 'All',
            )}`,
          )
          break
        case 'theme':
          subCatList = subCatList.concat(
            `${preText}theme_sub_cat=${encodeURIComponent(
              subCategories.theme || 'All',
            )}`,
          )
          break
        default:
        // Do nothing
      }
    })

    return `${
      RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS_V2]
    }${lens}/${trendId}/${subCatList}${includeParam}`
  }

  static fetchRetailProductsForProductFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_RETAIL_PRODUCTS]
      }${productID}/retailProduct/`,
    )
  }

  static fetchRetailProductsForThemeFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_RETAIL_PRODUCTS]
      }${productID}/retailTheme/`,
    )
  }

  static formRequestForConsumerNeeds(projectId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDS]}${
        RequestEndpoint.url[RequestEndpoint.NEED]
      }${needId}/project/${projectId}/`,
    )
  }

  static formRequestForConsumerNeedQuotes(projectId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDS]}${
        RequestEndpoint.url[RequestEndpoint.NEED]
      }${needId}${RequestEndpoint.url[RequestEndpoint.QUOTES]}`,
    )
  }

  static formRequestForConsumerNeedTMQuotes(projectId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDS]}${
        RequestEndpoint.url[RequestEndpoint.NEED]
      }${needId}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static formRequestForConsumerNeedsWithKeyword(projectid, keyword) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.TRENDREPORT]}${projectid}${
        RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDSKEY]
      }${keyword}`,
    )
  }

  static formSearchRequestForProduct(id) {
    // Ingredient Filter Detail
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.PRODUCTS]}${id}/`)
  }

  static formSearchRequestForProductFilterDetail(id) {
    // Product Filter Detail
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_DETAIL]}${id}/`,
    )
  }

  static formSearchRequestForThemeFilterDetail(id) {
    // Theme Filter Detail
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.THEME_FILTER_DETAIL]}${id}/`,
    )
  }

  static formSearchRequestForProductForCommonTrend(
    id,
    cn = false,
    socialData = false,
    subCategory,
    trendLine,
    noSales = false,
    noAbsolute = false,
    noMarketTrend = false,
  ) {
    // Ingredient Filter Detail fro common trend
    let route = `${
      RequestEndpoint.url[RequestEndpoint.PRODUCTS]
    }${id}/?consumer_need_data_req=${cn ? 'YES' : 'NO'}&social_graph_data_req=${
      socialData ? 'YES' : 'NO'
    }&search_trend_graph_data_req=NO&retail_data_req=NO&food_service_data_req=NO&prediction_data_req=NO&youtube_graph_data_req=NO`
    if (trendLine) {
      route += '&trend_line_data_req=YES'
    }
    if (noAbsolute) {
      route += '&absolute_graph_data_req=NO'
    }
    if (noSales) {
      route += '&sales_graph_data_req=NO'
    }
    if (noMarketTrend) {
      route += '&market_trend_graph_data_req=NO'
    }
    if (subCategory) {
      return route + `&subcategory=${encodeURIComponent(subCategory)}`
    }

    return encodeURI(route)
  }

  static formSearchRequestForProductFilterDetailForCommonTrend(
    id,
    cn = false,
    fsRsCount = false,
  ) {
    // Product Filter Detail fro common trend
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_DETAIL]
      }${id}/?consumer_need_data_req=${
        cn ? 'YES' : 'NO'
      }&social_graph_data_req=NO&search_trend_graph_data_req=NO&retail_data_req=NO&food_service_data_req=NO&prediction_data_req=NO&youtube_graph_data_req=NO&food_service_data_count_req=${
        fsRsCount ? 'YES' : 'NO'
      }&retail_data_count_req=${fsRsCount ? 'YES' : 'NO'}`,
    )
  }

  static formSearchRequestForThemeFilterDetailForCommonTrend(id, cn = false) {
    // Theme Filter Detail fro common trend
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_DETAIL]
      }${id}/?consumer_need_data_req=${
        cn ? 'YES' : 'NO'
      }&social_graph_data_req=NO&search_trend_graph_data_req=NO&retail_data_req=NO&food_service_data_req=NO&prediction_data_req=NO&youtube_graph_data_req=NO`,
    )
  }

  static formNewTrendRequest() {
    return RequestEndpoint.url[RequestEndpoint.PROJECTREQUEST]
  }

  static formLoginUser() {
    return RequestEndpoint.url[RequestEndpoint.LOGIN]
  }

  static formLoginUserGet() {
    return RequestEndpoint.url[RequestEndpoint.LOGIN_GET_USER]
  }

  static formAddOrgAdmin() {
    return RequestEndpoint.url[RequestEndpoint.ADD_ORG_ADMIN]
  }

  static formAddOrgUser() {
    return RequestEndpoint.url[RequestEndpoint.ADD_ORG_USER]
  }

  static formAddBuildWinner() {
    return RequestEndpoint.url[RequestEndpoint.ADD_BUILD_WINNER]
  }

  static getFoodCategories() {
    return RequestEndpoint.url[RequestEndpoint.GET_FOOD_CATEGORIES]
  }

  static formAddOrganization() {
    return RequestEndpoint.url[RequestEndpoint.ADD_ORGANIZATION]
  }

  static formGetOrganizationUser() {
    return RequestEndpoint.url[RequestEndpoint.GET_ORGANIZATION_USER]
  }

  static formGetAllFeature() {
    return RequestEndpoint.url[RequestEndpoint.GET_ALL_FEATURE]
  }

  static formGetOrganizationProject() {
    return RequestEndpoint.url[RequestEndpoint.GET_ORGANIZATION_PROJECT]
  }

  static formGetOrganizationFeature() {
    return RequestEndpoint.url[RequestEndpoint.GET_ORGANIZATION_FEATURE]
  }

  static formOrganizationProject(project, orgID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.ORGANIZATION_PROJECT]
      }/${orgID}/project/${project.id}`,
    )
  }

  static formOrganizationFeature(feature, orgID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.ORGANIZATION_FEATURE]
      }/${orgID}/feature/${feature.id}`,
    )
  }

  static formUserProfile() {
    return RequestEndpoint.url[RequestEndpoint.USER_PROFILE]
  }

  static formAddPermission() {
    return RequestEndpoint.url[RequestEndpoint.PERMISSION]
  }

  static formAddRole() {
    return RequestEndpoint.url[RequestEndpoint.ROLE]
  }

  static getWinningIdeas(inputID) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_WINNING_IDEAS]}/${inputID}`,
    )
  }

  static getWinningIdeasCount(inputID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.GET_WINNING_IDEAS]
      }/count/${inputID}`,
    )
  }

  static getAllProjects() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_ALL_PROJECTS]}`)
  }

  static fetchCountriesRequest() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_ALL_COUNTRIES]}`,
    )
  }

  static fetchProductTypesFillterRequest(projectID) {
    return encodeURI(`/api/project/${projectID}/ingredient_filter/`)
  }

  static formRequestForProjectNeedCategories(projectID) {
    return encodeURI(`/api/project/${projectID}/need_categories/`)
  }

  static fetchSearchCategoriesRequest() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_SEARCH_CATEGORIES]}`,
    )
  }

  static fetchLookForRequest() {
    return RequestEndpoint.url[RequestEndpoint.GET_SEARCH_LOOK_FOR]
  }

  static fetchIngredientQuotes(productID) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCTS]}${productID}/quotes/`,
    )
  }

  static fetchIngredientTradeMediaQuotes(productID) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCTS]}${productID}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }`,
    )
  }

  static fetchProductTradeMediaQuotes(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_QUOTES]
      }${productID}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static fetchThemeTradeMediaQuotes(productID) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.THEME_FILTER_QUOTES]}${productID}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }`,
    )
  }

  static fetchIngredientQuotesForProductFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_QUOTES]
      }${productID}/quotes/`,
    )
  }

  static fetchBPCReviewPlatforms(){
    return encodeURI(`${
      RequestEndpoint.url[RequestEndpoint.BPC_REVIEW_PLATFORMS]
    }`)
  }

  static fetchIngredientQuotesForThemeFilter(productID) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_QUOTES]
      }${productID}/quotes/`,
    )
  }

  static getUserProfileRequest() {
    return RequestEndpoint.url[RequestEndpoint.USER_PROFILE]
  }

  static formForgetPassword() {
    return RequestEndpoint.url[RequestEndpoint.FORGET_PASSWORD]
  }

  static formResetPassword() {
    return RequestEndpoint.url[RequestEndpoint.RESET_PASSWORD]
  }

  static checkTokenExpiry() {
    return RequestEndpoint.url[RequestEndpoint.CHECK_TOKEN_EXPIRY] // Password Reset Token Expiry Check
  }

  static formRequestForSubConsumerNeeds(subNeedId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.SUB_CONSUMER_NEED]}${subNeedId}/`,
    )
  }

  static formRequestForSubConsumerNeedQuotes(subNeedId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.SUB_CONSUMER_NEED]}${subNeedId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }`,
    )
  }

  static formRequestForSubConsumerNeedTMQuotes(subNeedId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.SUB_CONSUMER_NEED]}${subNeedId}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }`,
    )
  }

  static fetchProductNeedDetails(productId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_NEED]}/${needId}/`,
    )
  }

  static fetchProductFormatDetails(productId, formatType, formatName) {
    // /api/ingredients/ingredient/38108/product_format_detail/?product_type=cafe_product&product_format=Grain Snacks
    // return encodeURI(`${RequestEndpoint.url[RequestEndpoint.INGREDIENT_FORMAT]}/${formatId}/`)
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productId}/product_format_detail/?product_type=${formatType}&product_format=${formatName}`,
    )
  }

  static fetchProductSubFormatDetails(
    productId,
    formatType,
    formatName,
    subFormatName,
  ) {
    // return encodeURI(`${RequestEndpoint.url[RequestEndpoint.INGREDIENT_SUB_FORMAT]}/${subFormatId}/`)
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productId}/product_sub_format_detail/?product_type=${formatType}&product_format=${formatName}&product_sub_format=${subFormatName}`,
    )
  }

  static fetchProductFilterNeedDetails(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_NEED_DETAIL]
      }/${needId}/`,
    )
  }

  static fetchThemeFilterNeedDetails(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_NEED_DETAIL]
      }/${needId}/`,
    )
  }

  static fetchProductNeedQuotes(productId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_NEED]}/${needId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }`,
    )
  }

  static fetchProductFilterNeedQuotes(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_NEED_QUOTES]
      }${needId}${RequestEndpoint.url[RequestEndpoint.QUOTES]}`,
    )
  }

  static fetchThemeFilterNeedQuotes(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_NEED_QUOTES]
      }${needId}${RequestEndpoint.url[RequestEndpoint.QUOTES]}`,
    )
  }

  static fetchIngredientFilterNeedTradeMediaPatentQuotes(productId, needId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_NEED]}/${needId}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }`,
    )
  }

  static fetchProductFilterNeedTradeMediaPatentQuotes(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_NEED_QUOTES]
      }${needId}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static fetchThemeFilterNeedTradeMediaPatentQuotes(productId, needId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_NEED_QUOTES]
      }${needId}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static formNewTrendRequestPostData(
    projectname,
    lookForID,
    locationID,
    topicID,
  ) {
    const request = {
      name: projectname,
      searchQuery: [lookForID, locationID, topicID],
    }
    const data = JSON.stringify(request)
    return data
  }

  static setPolicy() {
    return RequestEndpoint.url[RequestEndpoint.ACCEPT_POLICY]
  }

  static fetchProjectSearchDataRequest() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.SEARCH]}`)
  }

  static fetchProjectSearchQuotesRequest() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.SEARCH]}${
        RequestEndpoint.url[RequestEndpoint.SEARCH_QUOTES]
      }`,
    )
  }

  static fetchProductTopicDetails(productId, topicId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_SUBNEED]}/${topicId}/`,
    )
  }

  static fetchProductFilterTopicDetails(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_SUB_NEED_DETAIL]
      }${topicId}/`,
    )
  }

  static fetchThemeFilterTopicDetails(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_SUB_NEED_DETAIL]
      }${topicId}/`,
    )
  }

  static fetchProductTopicQuotes(productId, topicId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_SUBNEED]}/${topicId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }`,
    )
  }

  static fetchProductFilterTopicQuotes(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_SUB_NEED_QUOTES]
      }${topicId}${RequestEndpoint.url[RequestEndpoint.QUOTES]}`,
    )
  }

  static fetchThemeFilterTopicQuotes(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_SUB_NEED_QUOTES]
      }${topicId}${RequestEndpoint.url[RequestEndpoint.QUOTES]}`,
    )
  }

  static fetchProductTopicTMQuotes(productId, topicId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_SUBNEED]}/${topicId}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }`,
    )
  }

  static fetchProductFilterTopicTMQuotes(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_SUB_NEED_QUOTES]
      }${topicId}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static fetchThemeFilterTopicTMQuotes(productId, topicId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_SUB_NEED_QUOTES]
      }${topicId}${RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]}`,
    )
  }

  static fetchSearchKeywords() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.SEARCH_KEYWORDS]}`)
  }

  static fetchReports(reportName) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.DOWNLOAD_REPORT]}/${reportName}`,
    )
  }

  static saveReportMeta() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.SAVE_REPORT_META]}`)
  }

  static getReportsList() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_REPORTS_LIST]}`)
  }

  static getProductDetailsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_PRODUCT_DETAILS_PPT]}`,
    )
  }

  static getMonthlyTrendsGraphPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_MONTHLY_TRENDS_GRAPH_PPT]}`,
    )
  }

  static getConsumerInsightsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_CONSUMER_INSIGHTS_PPT]}`,
    )
  }

  static getBlogsAndWebsites() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_BLOGS_AND_WEBSITES]} `,
    )
  }

  static getPlatformAndBrand() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_PLATFORM_AND_BRANDS]}`,
    )
  }

  static getRetailProductsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_RETAIL_PRODUCTS_PPT]}`,
    )
  }

  static getCafeProductsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_CAFE_PRODUCTS_PPT]}`,
    )
  }

  static getCompletePagePpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_COMPLETE_PAGE_PPT]}`,
    )
  }

  static getWholePagePpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_WHOLE_PAGE_PPT]}`,
    )
  }

  static getRelatedProductsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_RELATED_PRODUCTS_PPT]}`,
    )
  }

  static getRelatedThemesPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_RELATED_THEMES_PPT]}`,
    )
  }

  static getRelatedIngredientsPpt() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_RELATED_INGREDIENTS_PPT]}`,
    )
  }

  static getTrendExcel() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_TREND_EXCEL]}`)
  }

  static getCIExcel() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_CI_EXCEL]}`)
  }

  static getRFExcel() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_RF_EXCEL]}`)
  }

  static getFSFExcel() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_FSF_EXCEL]}`)
  }

  static getAllExcel() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.GET_ALL_EXCEL]}`)
  }

  static getAllTrends(projectId, image, subCategory) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PROJECTS]
      }${projectId}/trends/?image=${image ? 'YES' : 'NO'}&theme_sub_cat=${
        subCategory ? 'YES' : 'NO'
      }`,
    )
  }

  static getFilteredTrends() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_ADVANCED_SEARCH_DATA]}`,
    )
  }

  static saveAdvancedSearch(projectId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.SAVE_ADVANCED_SEARCH_FILTER]
      }?projectId=${projectId}`,
    )
  }

  static getRegionAnalyticsIngredients(trendId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${trendId}/regional_overview/`,
    )
  }

  static getRegionAnalyticsProducts(trendId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_DETAIL]
      }${trendId}/regional_overview/`,
    )
  }

  static getRegionAnalyticsThemes(trendId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_DETAIL]
      }${trendId}/regional_overview/`,
    )
  }

  static getUnreadNotifications() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_UNREAD_NOTIFICATIONS]}`,
    )
  }

  static getAllNotifications() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.GET_ALL_NOTIFICATIONS]}`,
    )
  }

  static markNotificationAsRead(slug) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.MARK_NOTIFICATION_READ]}${slug}/`,
    )
  }

  static markNotified() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.MARK_NOTIFIED]}`)
  }

  static microServicesSubscriptionCheck(serviceName) {
    return `${
      RequestEndpoint.url[RequestEndpoint.MICROSERVICES_SUBSCRIPTION_CHECK]
    }${encodeURIComponent(serviceName)}`
  }

  static fetchWsoData(country, category, lens) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.GET_WSO_DATA]
      }?country=${country}&category=${encodeURIComponent(category)}&lens=${lens}`,
    )
  }

  static fetchBlogsAndWebsites(country, category) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.GET_BLOGS_AND_WEBSITES]
      }?country=${country}&category=${category}`,
    )
  }

  static fetchPlatformAndBrand(country, category, trendName, trendType) {
    trendType = trendType?.toLowerCase()
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.GET_PLATFORM_AND_BRANDS]
      }?country=${country}&category=${category}&trend_name=${trendName}&trend_type=${trendType}`,
    )
  }

  static downloadWsoData(country, category) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.DOWNLOAD_WSO_DATA]
      }?country=${country}&category=${encodeURIComponent(category)}&lens=ingredient`,
    )
  }

  static fetchWsoSubCategoryData(country, category, lens, subCategory) {
    return `${
      RequestEndpoint.url[RequestEndpoint.GET_WSO_DATA]
    }?country=${country}&category=${encodeURIComponent(category)}&lens=${lens}&subcategory=${subCategory}`
  }

  static getBookmarkTrends(requestOptions) {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.BOOKMARK_TRENDS]}`)
  }

  static getBookmarkedData() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.BOOKMARK_TRENDS]}`)
  }

  static getBookmarkedCheckedData(lens, trendId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.BOOKMARK_CHECK]
      }?lens=${lens}&trend_id=${trendId}`,
    )
  }

  static getBookmarkNotification(removeId, pauseNotification) {
    const pause = pauseNotification ? 'NO' : 'YES'
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.BOOKMARK_TRENDS]
      }${removeId}/notifications/?turn_on=${pause}`,
    )
  }

  static getRemoveBookmark(removeId) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.BOOKMARK_TRENDS]}${removeId}/`,
    )
  }

  static commonTrendAnalysisAcross(lans, maturityPhase, across, lookup) {
    return `${
        RequestEndpoint.url[RequestEndpoint.COMMON_TREND_ANALYSIS_ACROSS]
    }?across=${across}&lookup=${lookup}&lens=${lans}&trendType=${maturityPhase}`
  }

  static advancedTrendAnalysis(lens, category, requestURI) {
    return `${
      RequestEndpoint.url[RequestEndpoint.COMMON_TREND_ANALYSIS_ACROSS]
    }?lens=${lens}&category=${category}&${requestURI}version=2.0`
  }

  static getSharingUsers() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.SHARING_USERS]}`)
  }

  static postSharingUsers() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.POST_SHARING_USERS]}`,
    )
  }

  static getCtxSearchMapping(country, category, lens, column) {
    return `${
      RequestEndpoint.url[RequestEndpoint.GET_ADVANCED_SEARCH_DATA]
    }assosiated_trends/?country=${encodeURIComponent(
      country,
    )}&category=${encodeURIComponent(
      category,
    )}&lens=${lens}&consumer_need_set=${column}`
  }

  static downloadHelpDoc() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.DOWNLOAD_HELP_DOC]}`,
    )
  }

  static getSubcategories(category, countriesRequest, lens) {
    return `${
      RequestEndpoint.url[RequestEndpoint.SUBCATEGORIES_LIST]
    }?category=${category}${countriesRequest}&lens=${lens}`
  }

  static getSubcategoriesSW() {
    return `${RequestEndpoint.url[RequestEndpoint.SUBCATEGORIES_LIST_SW]}`
  }

  static downloadExcel(version = '1.0') {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.DOWNLOAD_EXCEL]
      }?version=${version}`,
    )
  }

  static aTAdownloadExcel() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.ATA_DOWNLOAD_EXCEL]}`,
    )
  }

  static updateUserPassword() {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.UPDATE_PASSWORD]}`)
  }

  static documentation() {
    return `${RequestEndpoint.url[RequestEndpoint.DOCUMENTATION]}`
  }

  static trendPillar(lens) {
    return `${RequestEndpoint.url[RequestEndpoint.TREND_PILLAR]}/${lens}`
  }

  static mergeTrendPillar(lens) {
    return `${RequestEndpoint.url[RequestEndpoint.TREND_PILLAR]}/${lens}/merge`
  }

  static trendPillarGeneral() {
    return `${RequestEndpoint.url[RequestEndpoint.TREND_PILLAR]}/`
  }

  static downloadTrendPillarTrends(lens, pillarId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.TREND_PILLAR]
    }/download/${lens}/${pillarId}`
  }

  static trendPillarImport() {
    return `${RequestEndpoint.url[RequestEndpoint.IMPORT_TREND_PILLAR]}/`
  }

  static trendPillarTrendDetails(lens, pillarId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.TREND_PILLAR]
    }/${lens}/${pillarId}/trends_details`
  }

  static fetchFeaturesToHighlight() {
    return `${RequestEndpoint.url[RequestEndpoint.FETCH_FEATURE]}`
  }

  static featuresToHighlightUpdate() {
    return `${
      RequestEndpoint.url[RequestEndpoint.FETCH_FEATURE]
    }?feat_type=update`
  }

  static userAccessManagement() {
    return `${RequestEndpoint.url[RequestEndpoint.USER_ACCESS_MANAGEMENT]}`
  }

  static userAccessManagementid(id) {
    return `${
      RequestEndpoint.url[RequestEndpoint.USER_ACCESS_MANAGEMENT]
    }?client_id=${id}`
  }

  static userAccessManagementStatus() {
    return `${
      RequestEndpoint.url[RequestEndpoint.USER_ACCESS_MANAGEMENT]
    }/status`
  }

  static userAccessManagementEdit(id) {
    return `${
      RequestEndpoint.url[RequestEndpoint.USER_ACCESS_MANAGEMENT]
    }/${id}`
  }

  static userAccessManagementFeatures(id) {
    return `${
      RequestEndpoint.url[RequestEndpoint.USER_ACCESS_MANAGEMENT_FEATURES]
    }/${id}`
  }

  static userAccessManagementDashboardAssigned(id) {
    return `${
      RequestEndpoint.url[
        RequestEndpoint.USER_ACCESS_MANAGEMENT_DASHBOARD_ASSIGNED
      ]
    }/${id}`
  }

  static userAccessManagementDashboardAssignedFetch(id, dashboardIds) {
    return `${
      RequestEndpoint.url[
        RequestEndpoint.USER_ACCESS_MANAGEMENT_DASHBOARD_ASSIGNED
      ]
    }/dashboard_details/${id}?${dashboardIds}`
  }

  static userAccessManagementDashboardAssignedSubCategory(
    id,
    country,
    category,
  ) {
    return `${
      RequestEndpoint.url[
        RequestEndpoint.USER_ACCESS_MANAGEMENT_DASHBOARD_ASSIGNED
      ]
    }/${id}?country=${country}&category=${category}`
  }

  static userManagement() {
    return `${RequestEndpoint.url[RequestEndpoint.USER_MANAGEMENT]}`
  }

  static userManagementDeleteOp(id) {
    return `${RequestEndpoint.url[RequestEndpoint.USER_MANAGEMENT]}${id}/`
  }

  static userManagementSendingEmail() {
    return `${RequestEndpoint.url[RequestEndpoint.SENDING_EMAIL_UAM]}`
  }

  static userManagementDownloadAllUsers() {
    return `${RequestEndpoint.url[RequestEndpoint.USER_MANAGEMENT]}download/`
  }

  static downloadClientDetails() {
    return `${RequestEndpoint.url[RequestEndpoint.DOWNLOAD_CLIENT_DETAILS]}`
  }

  static downloadRelatedThemes() {
    return `${RequestEndpoint.url[RequestEndpoint.DOWNLOAD_RELATED_THEMES]}`
  }

  static regionalDashboardList() {
    return `${
      RequestEndpoint.url[RequestEndpoint.REGIONAL_ANALYTICS]
    }/get_dashboards/`
  }

  static regionalDistributionForCountryGraph(
    country,
    category,
    trendName,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.REGIONAL_ANALYTICS]
    }/distribution/?country=${country}&category=${category}&trend=${trendName}&lens=${lens}`
  }

  static regionalTrendGraph(country, category, trendName, lens, region) {
    return `${
      RequestEndpoint.url[RequestEndpoint.REGIONAL_ANALYTICS]
    }/get_trend_graph/?country=${country}&category=${category}&trend=${trendName}&lens=${lens}&region=${region}`
  }

  static regionalScatteredGraph(country, category, lens, region) {
    return `${
      RequestEndpoint.url[RequestEndpoint.REGIONAL_ANALYTICS]
    }/get_all_trends/?country=${country}&category=${category}&lens=${lens}&region=${region}`
  }

  static regionalConsumerInsight(trendId, lens, region) {
    return `${
      RequestEndpoint.url[RequestEndpoint.REGIONAL_ANALYTICS]
    }/consumer_insights/?trend_id=${trendId}&lens=${lens}&region=${region}`
  }

  static fetchRegionalFoodServiceProduct(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_CAFE_PRODUCTS]
      }${productID}/cafeProduct/?region=${region}`,
    )
  }

  static fetchRegionalFoodServiceIngredient(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/cafeProduct/?region=${region}`,
    )
  }

  static fetchRegionalIngredientFilterNeedDetails(needId, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.INGREDIENT_NEED]
      }/${needId}/?region=${region}`,
    )
  }

  static fetchRegionalProductFilterNeedDetails(needId, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_NEED_DETAIL]
      }/${needId}/?region=${region}`,
    )
  }

  static fetchRegionalThemeFilterNeedDetails(needId, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_NEED_DETAIL]
      }/${needId}/?region=${region}`,
    )
  }

  static fetchRegionalIngredientFilterNeedQuotes(needId, region) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.INGREDIENT_NEED]}/${needId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }?region=${region}`,
    )
  }

  static fetchRegionalProductFilterNeedQuotes(needId, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_NEED_QUOTES]
      }${needId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }?region=${region}`,
    )
  }

  static fetchRegionalThemeFilterNeedQuotes(needId, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_NEED_QUOTES]
      }${needId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }?region=${region}`,
    )
  }

  static fetchRegionalFoodServiceFormat(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/product_format_list/?product_type=cafe_product&region=${region}`,
    )
  }

  static fetchRegionalRetailProductsIngredient(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/retailProduct/?region=${region}`,
    )
  }

  static fetchRegionalRetailProductsForProduct(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_RETAIL_PRODUCTS]
      }${productID}/retailProduct/?region=${region}`,
    )
  }

  static fetchRegionalRetailProductFormats(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/product_format_list/?product_type=retail_product&region=${region}`,
    )
  }

  static fetchRegionalRelatedTrendsForIngredients(productid, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }ingredients/${productid}/?region=${region}`,
    )
  }

  static fetchRegionalRelatedTrendsForProducts(productid, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }products/${productid}/?region=${region}`,
    )
  }

  static fetchRegionalRelatedTrendsForThemes(productid, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }themes/${productid}/?region=${region}`,
    )
  }

  static fetchRegionalIngredientQuotes(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${productID}/quotes/?region=${region}`,
    )
  }

  static fetchRegionalIngredientQuotesForProduct(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_QUOTES]
      }${productID}/quotes/?region=${region}`,
    )
  }

  static fetchRegionalIngredientQuotesForTheme(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_QUOTES]
      }${productID}/quotes/?region=${region}`,
    )
  }

  static fetchRegionalIngredientTradeMediaQuotes(productID, region) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.PRODUCTS]}${productID}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }/?region=${region}`,
    )
  }

  static fetchRegionalProductTradeMediaQuotes(productID, region) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_QUOTES]
      }${productID}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }/?region=${region}`,
    )
  }

  static fetchRegionalThemeTradeMediaQuotes(productID, region) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.THEME_FILTER_QUOTES]}${productID}${
        RequestEndpoint.url[RequestEndpoint.TRADE_MEDIA_QUOTES]
      }/?region=${region}`,
    )
  }

  static formRequestForRegionalConsumerNeeds(projectId, needId, region) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDS]}${
        RequestEndpoint.url[RequestEndpoint.NEED]
      }${needId}/project/${projectId}/?region=${region}`,
    )
  }

  static formRequestForRegionalConsumerNeedQuotes(needId, region) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.CONSUMERNEEDS]}${
        RequestEndpoint.url[RequestEndpoint.NEED]
      }${needId}${
        RequestEndpoint.url[RequestEndpoint.QUOTES]
      }?region=${region}`,
    )
  }

  static formRequestForRegionalProjectNeedCategoriesOverall(projectID, region) {
    return encodeURI(
      `/api/project/${projectID}/need_categories/?need_filter=All&region=${region}`,
    )
  }

  static getCSDashboards() {
    return `${RequestEndpoint.url[RequestEndpoint.GET_CS_DASHBOARDS]}`
  }

  static demographicAnalyticsDistribution(
    countrySelected,
    categorySelected,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_DISTRIBUTION]
    }?country=${countrySelected}&category=${categorySelected}&lens=${lens}`
  }

  static demographicTrendDistribution(
    countrySelected,
    categorySelected,
    trend,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_DISTRIBUTION]
    }?country=${countrySelected}&category=${categorySelected}&trend=${trend}&lens=${lens}`
  }

  static demographicAnalyticsDashboard() {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_DASHBOARD]
    }`
  }

  static demographicAnalyticsTrendGraph() {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_TREND_GRAPH]
    }`
  }

  static demographicAnalyticsAllTrends(
    countrySelected,
    categorySelected,
    ageGroupTab,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_ALL_TRENDS]
    }?country=${countrySelected}&category=${categorySelected}&demography=${ageGroupTab}&lens=${lens.toLowerCase()}`
  }

  static demographicAnalyticsTrendData(
    countrySelected,
    categorySelected,
    trend,
    ageGroupTab,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.DEMOGRAPHIC_ANALYTICS_TREND]
    }/?country=${countrySelected}&category=${categorySelected}&trend=${trend}&demography=${ageGroupTab}&lens=${lens}`
  }

  static demographicAnalyticsConsumerQuotesForThemes(
    themeId,
    ageGroupTab,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.THEME_FILTER_QUOTES]
    }${themeId}/quotes/?demography_type=${ageGroupTab}&lens=${lens}`
  }

  static demographicAnalyticsConsumerQuotesForProducts(
    themeId,
    ageGroupTab,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_DETAIL]
    }${themeId}/quotes/?demography_type=${ageGroupTab}&lens=${lens}`
  }

  static demographicAnalyticsConsumerQuotesForIngredients(
    themeId,
    ageGroupTab,
    lens,
  ) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PRODUCTS]
    }${themeId}/quotes/?demography_type=${ageGroupTab}&lens=${lens}`
  }

  static demographicRelatedTrends(themeId, ageGroupTab) {
    return `${
      RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
    }themes/${themeId}/?demographic_type=${ageGroupTab}`
  }

  static demographicConsumerInsights(themeId, ageGroupTab, lens) {
    return `${
      RequestEndpoint.url[
        RequestEndpoint.DEMOGRAPHIC_ANALYTICS_CONSUMER_INSIGHTS
      ]
    }?trend_id=${themeId}&demographic_type=${ageGroupTab}&lens=${lens}`
  }

  static getCSFormatsList(country, category) {
    return `${
      RequestEndpoint.url[RequestEndpoint.GET_CS_FORMATS_LIST]
    }?country=${country}&category=${category}`
  }

  static getCSAdvFiltersList() {
    return `${RequestEndpoint.url[RequestEndpoint.GET_CS_ADV_FILTERS_LIST]}`
  }

  static getCSAdvFiltersAppliedTrendsList() {
    return `${
      RequestEndpoint.url[
        RequestEndpoint.GET_CS_ADV_FILTERS_APPLIED_TRENDS_LIST
      ]
    }`
  }

  static getCSFilteredTrends() {
    return `${RequestEndpoint.url[RequestEndpoint.GET_CS_FILTERED_TRENDS]}`
  }

  static getSCN() {
    return `${RequestEndpoint.url[RequestEndpoint.GET_CS_CN]}`
  }

  static getCSTrendInfoDetailsIngredients(trendID, typeOfData) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCTS]
      }${trendID}/${typeOfData}/`,
    )
  }

  static getCSTrendInfoDetailsProducts(trendID, typeOfData) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_DETAIL]
      }${trendID}/${typeOfData}/`,
    )
  }

  static getCSTrendInfoDetailsThemes(trendID, typeOfData) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.THEME_FILTER_DETAIL]
      }${trendID}/${typeOfData}/`,
    )
  }

  static getCSUAMDashboardsList() {
    return `${RequestEndpoint.url[RequestEndpoint.GET_CS_UAM_DASHBAORDS_LIST]}/`
  }

  static getCSUAMDashboardsDetails() {
    return `${
      RequestEndpoint.url[RequestEndpoint.GET_CS_UAM_DASHBAORDS_DETAILS]
    }/`
  }

  static getCSUAMSubscribedDashboards() {
    return `${
      RequestEndpoint.url[RequestEndpoint.GET_CS_UAM_SUBSCRIBED_DASHBOADS]
    }`
  }

  static bulkCreateCSUAMDashboards() {
    return `${
      RequestEndpoint.url[RequestEndpoint.BULK_CREATE_CS_UAM_DASHBOADS]
    }`
  }

  static bulkEditCSUAMDashboards() {
    return `${RequestEndpoint.url[RequestEndpoint.BULK_EDIT_CS_UAM_DASHBOADS]}`
  }

  static checkForAddedFavorite(trendType, trendId, projectId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.ADDED_FAVORITE_CHECK]
      }?trend_type=${trendType}&trend_id=${trendId}&project_id=${projectId}`,
    )
  }

  static dashboardFavoritesTrends() {
    return `${RequestEndpoint.url[RequestEndpoint.DASHBOARD_FAVORITES_TRENDS]}`
  }

  static dashboardTrendHighlightData(
    projectId,
    lensSelected,
    subCategorySelected,
  ) {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.DASHBOARD_TREND_HIGHLIGHT_DATA]}${
        projectId && lensSelected
          ? `?project_id=${projectId}&lens=${lensSelected}`
          : ''
      }${
        projectId &&
        lensSelected &&
        subCategorySelected &&
        subCategorySelected !== 'All'
          ? `&sub_category=${encodeURIComponent(subCategorySelected)}`
          : ''
      }`,
    )
  }

  static dashboardFavoritesTrendPillars() {
    return `${
      RequestEndpoint.url[RequestEndpoint.DASHBOARD_FAVORITE_TREND_PILLAR]
    }`
  }

  static checkForAddedFavoriteTrendPillar(pillerId, pillarType, projectId) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.ADDED_FAVORITE_CHECK_TREND_PILLAR]
      }?trend_piller_id=${pillerId}&trend_piller_type=${pillarType}&project_id=${projectId}`,
    )
  }

  static dashboardRecentActivity() {
    return `${RequestEndpoint.url[RequestEndpoint.DASHBOARD_RECENT_ACTIVITY]}`
  }

  static dashboardFavoritesOtherFavorites() {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.DASHBOARD_FAVORITES_OTHER_FAVORITES]
      }`,
    )
  }

  static checkForAddedOtherFavorite(
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    projectId,
  ) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.ADDED_OTHER_FAVORITES_CHECK]
      }?name=${name}&project_id=${projectId}&lens=${lens}&favourite_type=${favouriteType}&favourite_id=${favouriteId}&url=${urlLink}`,
    )
  }

  static fetchDemographyRelatedTrendsForIngredients(productID, ageGroup) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }ingredients/${productID}/?demographic_type=${ageGroup}`,
    )
  }

  static fetchDemographyRelatedTrendsForProducts(productID, ageGroup) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.RELATED_TRENDS]
      }products/${productID}/?demographic_type=${ageGroup}`,
    )
  }

  static getMarkIrrelevantCQUrl() {
    return encodeURI(
      `${RequestEndpoint.url[RequestEndpoint.MARK_IRRELEVANT_CONSUMER_QUOTES]}`,
    )
  }

  // static fetchDemographyFoodServiceProduct(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_CAFE_PRODUCTS]
  //     }${productID}/cafeProduct/?demography=${ageGroup}`,
  //   )
  // }

  // static fetchDemographyCafeProducts(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCTS]
  //     }${productID}/cafeProduct/?demography=${ageGroup}`,
  //   )
  // }

  // static fetchDemographyFoodServiceFormat(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCTS]
  //     }${productID}/product_format_list/?product_type=cafe_product?demography=${ageGroup}`,
  //   )
  // }

  // static fetchDemographyRetailProducts(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCTS]
  //     }${productID}/retailProduct/?demography=${ageGroup}`,
  //   )
  // }

  // static fetchDemographyRetailProductsForProduct(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCT_FILTER_RETAIL_PRODUCTS]
  //     }${productID}/retailProduct/?demography=${ageGroup}`,
  //   )
  // }

  // static fetchDemographyRetailProductFormats(productID, ageGroup) {
  //   return encodeURI(
  //     `${
  //       RequestEndpoint.url[RequestEndpoint.PRODUCTS]
  //     }${productID}/product_format_list/?product_type=retail_product?demography=${ageGroup}`,
  //   )
  // }

  static fetchTrendTransitionDetails(projectId, subCategory, lens) {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.TREND_IS_TRANSITIONING]
      }${projectId}/?subcategory=${subCategory}&lens=${lens}`,
    )
  }

  static fetchRecentHistory = (projectId) => {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.SEARCH]
      }history/?project_id=${projectId}`,
    )
  }

  static postRecentHistory = () => {
    return encodeURI(`${RequestEndpoint.url[RequestEndpoint.SEARCH]}history/`)
  }

  static fetchSearchResult = (projectId, searchTerm, type) => {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.SEARCH]
      }results/?project_id=${projectId}&search_term=${searchTerm}&category=${type}`,
    )
  }

  static fetchSearchSuggestions = (projectId, searchTerm, type, pageSize) => {
    return encodeURI(
      `${
        RequestEndpoint.url[RequestEndpoint.SEARCH]
      }suggestions/?project_id=${projectId}&search_term=${searchTerm}&category=${type}&page_size=${pageSize}`,
    )
  }

  static fetchTrendFamilyFilter() {
    return `${RequestEndpoint.url[RequestEndpoint.TREND_FAMILY_FILTER]}`
  }

  static getAllWheel() {
    return `${RequestEndpoint.url[RequestEndpoint.WHEELS]}`
  }

  static getWheel(id) {
    return `${RequestEndpoint.url[RequestEndpoint.WHEELS]}${id}`
  }

  static getCreatedWheel(param) {
    return `${RequestEndpoint.url[RequestEndpoint.WHEELS]}?${param}`
  }

  static fetchVisualisationPreference(projectId) {
    return `${
      RequestEndpoint.url[RequestEndpoint.PREFERENCES]
    }?project_id=${projectId}`
  }

  static feedback(featureCode) {
    return `${RequestEndpoint.url[RequestEndpoint.FEEDBACK]}/${featureCode}`
  }

  static feedbackStatus(featureCode) {
    return `${
      RequestEndpoint.url[RequestEndpoint.FEEDBACK]
    }/${featureCode}/status`
  }

  static fetchValidatedToken() {
    return `${RequestEndpoint.url[RequestEndpoint.VALIDATE_TOKEN]}`
  }

  static fetchRedirectToken() {
    return `${RequestEndpoint.url[RequestEndpoint.REDIRECT_TOKEN]}`
  }
}


const LOCAL_STORAGE_KEYS = {
  REDIRECT_OPT_OUT: 'aiPaletteOptOutRedirect',
}
const RequestEndpoint = {
  SEARCH: 1,
  PROJECTREQUEST: 2,
  TRENDREPORT: 3,
  PRODUCTS: 4,
  NEEDSLIST: 5,
  CONSUMERNEEDS: 6,
  CONSUMERNEEDSKEY: 7,
  LOGIN: 8,
  ADD_ORG_ADMIN: 9,
  ADD_BUILD_WINNER: 10,
  ADD_ORGANIZATION: 11,
  GET_FOOD_CATEGORIES: 2,
  ADD_ORG_USER: 12,
  GET_ORGANIZATION_USER: 13,
  GET_WINNING_IDEAS: 14,
  USER_PROFILE: 15,
  PERMISSION: 16,
  ROLE: 17,
  ORGANIZATION_PROJECT: 18,
  GET_ORGANIZATION_PROJECT: 19,
  ORGANIZATION_FEATURE: 20,
  GET_ORGANIZATION_FEATURE: 21,
  GET_ALL_FEATURE: 22,
  GET_ALL_PROJECTS: 23,
  LOGIN_GET_USER: 24,
  GET_ALL_COUNTRIES: 25,
  GET_SEARCH_CATEGORIES: 26,
  PROJECTS: 27,
  GET_SEARCH_LOOK_FOR: 28,
  GET_SEARCH_REQUESTED: 29,
  FORGET_PASSWORD: 30,
  RESET_PASSWORD: 31,
  SUB_CONSUMER_NEED: 32,
  ACCEPT_POLICY: 33,
  NEED: 34,
  INGREDIENT_NEED: 35,
  INGREDIENT_SUBNEED: 36,
  QUOTES: 37,
  SEARCH_QUOTES: 38,
  CHECK_TOKEN_EXPIRY: 39,

  PRODUCT_FILTER: 40,
  THEME_FILTER: 41,
  PRODUCT_FILTER_DETAIL: 42,
  THEME_FILTER_DETAIL: 43,

  PRODUCT_FILTER_RETAIL_PRODUCTS: 44,
  THEME_FILTER_RETAIL_PRODUCTS: 45,

  PRODUCT_FILTER_CAFE_PRODUCTS: 46,
  THEME_FILTER_CAFE_PRODUCTS: 47,

  PRODUCT_FILTER_QUOTES: 48,
  THEME_FILTER_QUOTES: 49,

  PRODUCT_FILTER_NEED_DETAIL: 50,
  THEME_FILTER_NEED_DETAIL: 51,

  PRODUCT_FILTER_NEED_QUOTES: 52,
  THEME_FILTER_NEED_QUOTES: 53,

  PRODUCT_FILTER_SUB_NEED_DETAIL: 54,
  THEME_FILTER_SUB_NEED_DETAIL: 55,

  PRODUCT_FILTER_SUB_NEED_QUOTES: 56,
  THEME_FILTER_SUB_NEED_QUOTES: 57,

  SUBSCRIPTION_DETIALS: 58,

  INGREDIENT_FORMAT: 59,

  INGREDIENT_SUB_FORMAT: 60,

  SEARCH_KEYWORDS: 61,

  SAVE_REPORT_META: 62,

  DOWNLOAD_REPORT: 63,

  RELATED_TRENDS: 64,

  GET_REPORTS_LIST: 65,

  // PPT
  GET_PRODUCT_DETAILS_PPT: 66,
  GET_MONTHLY_TRENDS_GRAPH_PPT: 67,
  GET_CONSUMER_INSIGHTS_PPT: 68,
  GET_RETAIL_PRODUCTS_PPT: 69,
  GET_CAFE_PRODUCTS_PPT: 70,
  GET_COMPLETE_PAGE_PPT: 71,
  GET_WHOLE_PAGE_PPT: 77,
  GET_RELATED_PRODUCTS_PPT: 81,
  GET_RELATED_THEMES_PPT: 80,
  GET_RELATED_INGREDIENTS_PPT: 82,

  // Excel
  GET_TREND_EXCEL: 72,
  GET_CI_EXCEL: 73,
  GET_RF_EXCEL: 74,
  GET_FSF_EXCEL: 75,
  GET_ALL_EXCEL: 76,

  // ADVANCED SEARCH
  GET_ADVANCED_SEARCH_DATA: 78,
  SAVE_ADVANCED_SEARCH_FILTER: 79,
  TRADE_MEDIA_QUOTES: 85,

  // NOTIFICATIONS
  GET_ALL_NOTIFICATIONS: 91,
  GET_UNREAD_NOTIFICATIONS: 93,
  MARK_NOTIFICATION_READ: 94,
  MARK_NOTIFIED: 95,

  MICROSERVICES_SUBSCRIPTION_CHECK: 96,

  // white space opportunity
  GET_WSO_DATA: 97,
  DOWNLOAD_WSO_DATA: 98,

  // BOOKMARK
  BOOKMARK_TRENDS: 99,
  BOOKMARK_CHECK: 100,

  // COMMON TREND ANALYSIS
  COMMON_TREND_ANALYSIS_ACROSS: 101,

  // Sharing card
  SHARING_USERS: 103,
  POST_SHARING_USERS: 104,

  // hepldoc download
  DOWNLOAD_HELP_DOC: 110,

  SUBCATEGORIES_LIST: 120,
  SUBCATEGORIES_LIST_SW: 121,
  DOWNLOAD_EXCEL: 111,
  ATA_DOWNLOAD_EXCEL: 112,
  DOCUMENTATION: 130,

  UPDATE_PASSWORD: 114,

  FETCH_FEATURE: 135,

  TREND_PILLAR: 140,

  IMPORT_TREND_PILLAR: 141,

  USER_ACCESS_MANAGEMENT: 142,

  USER_ACCESS_MANAGEMENT_FEATURES: 143,
  USER_ACCESS_MANAGEMENT_DASHBOARD_ASSIGNED: 144,

  USER_MANAGEMENT: 145,

  SENDING_EMAIL_UAM: 146,

  DOWNLOAD_CLIENT_DETAILS: 147,

  DOWNLOAD_RELATED_THEMES: 148,

  REGIONAL_ANALYTICS: 149,
  // Demographic analytics
  DEMOGRAPHIC_ANALYTICS_DISTRIBUTION: 156,
  DEMOGRAPHIC_ANALYTICS_DASHBOARD: 157,
  DEMOGRAPHIC_ANALYTICS_TREND_GRAPH: 158,
  DEMOGRAPHIC_ANALYTICS_ALL_TRENDS: 159,
  DEMOGRAPHIC_ANALYTICS_TREND: 160,
  DEMOGRAPHIC_ANALYTICS_CONSUMER_INSIGHTS: 161,

  // Cognitive search
  GET_CS_FORMATS_LIST: 150,
  GET_CS_FILTERED_TRENDS: 151,
  GET_CS_CN: 152,
  GET_CS_ADV_FILTERS_LIST: 153,
  GET_CS_ADV_FILTERS_APPLIED_TRENDS_LIST: 154,
  GET_CS_DASHBOARDS: 155,

  GET_CS_UAM_DASHBAORDS_LIST: 170,
  GET_CS_UAM_DASHBAORDS_DETAILS: 171,
  GET_CS_UAM_SUBSCRIBED_DASHBOADS: 172,
  BULK_CREATE_CS_UAM_DASHBOADS: 173,
  BULK_EDIT_CS_UAM_DASHBOADS: 174,

  ADD_TO_FAVORITE: 175,
  ADDED_FAVORITE_CHECK: 176,
  // My Workspace
  DASHBOARD_FAVORITES_TRENDS: 180,
  DASHBOARD_TREND_HIGHLIGHT_DATA: 181,
  DASHBOARD_FAVORITE_TREND_PILLAR: 182,
  ADDED_FAVORITE_CHECK_TREND_PILLAR: 183,
  DASHBOARD_RECENT_ACTIVITY: 184,

  DASHBOARD_FAVORITES_OTHER_FAVORITES: 185,
  ADDED_OTHER_FAVORITES_CHECK: 186,

  TREND_IS_TRANSITIONING: 187,

  TREND_FAMILY_FILTER: 188,

  MARK_IRRELEVANT_CONSUMER_QUOTES: 189,

  RELATED_TRENDS_V2: 190,

  WHEELS: 191,

  PREFERENCES: 192,

  FEEDBACK: 193,

  GET_BLOGS_AND_WEBSITES: 194,
  GET_PLATFORM_AND_BRANDS: 195,
  VALIDATE_TOKEN: 200,
  REDIRECT_TOKEN: 201,

  BPC_REVIEW_PLATFORMS: 202,

  url: {
    27: '/api/project/',
    4: '/api/ingredients/ingredient/',
    32: '/api/sub-need/',
    29: '/api/project/request/',
    25: '/api/project/country/',
    2: '/api/project/request/',
    26: '/api/project/category/',
    28: '/api/project/lookfor/',
    24: '/api/users/user-profile/',
    30: '/api/users/forget-password/',
    31: '/api/users/reset-password/',
    33: '/api/users/accept-policy/',
    39: '/api/users/token/expiry-check/',
    34: '/need/',
    6: '/api/consumer-need',
    35: '/api/ingredients/need',
    36: '/api/ingredients/sub-need',
    37: '/quotes/',
    38: 'quotes/',
    85: '/trade_media_patent_quotes/',

    1: '/api/search/',
    3: '/api/trends/projects/',
    5: '/needs/',
    7: '/needs/keyword/',
    8: '/api/users/login/',
    9: '/api/users/org-admin',
    10: '/api/build-winner',
    11: '/api/organizations',
    12: '/api/users/org-user',
    13: '/api/users/org-users',
    14: '/api/build-winner/results',
    15: '/api/users/user-profile/',
    16: '/api/permission',
    17: '/api/role',
    18: '/api/organizations',
    19: '/api/trends/projects/organization',
    20: '/api/organizations',
    21: '/api/feature/organization',
    22: '/api/feature',
    23: '/api/trends/allprojects',

    /* Product & Theme filter (detail page) */
    40: '/api/project/product_classification/',
    41: '/api/project/theme_classification/',

    // DETAIL PAGE
    42: '/api/products/product/',
    43: '/api/themes/theme/',

    // RETAIL PRODUCTS
    44: '/api/products/product/',
    45: '/api/themes/theme/',

    // CAFE PRODUCTS
    46: '/api/products/product/',
    47: '/api/themes/theme/',

    // QUOTES ON DETAIL PAGE
    48: '/api/products/product/',
    49: '/api/themes/theme/',

    /* NEED DETAIL */
    50: '/api/products/need' /* `/api/products/need/13096/` */,
    51: '/api/themes/need' /* `/api/products/need/13096/quotes/` */,

    /* NEED QUOTES */
    52: '/api/products/need/' /* `/api/themes/need/13096/` */,
    53: '/api/themes/need/' /* `/api/themes/need/13096/quotes/` */,

    /* SUB NEED DETAIL */
    54: '/api/products/sub-need/',
    55: '/api/themes/sub-need/',

    /* SUB NEED QUOTES */
    56: '/api/products/sub-need/',
    57: '/api/themes/sub-need/',

    58: '/api/organization/subscriptions/check/',

    // Product Format
    59: '/api/ingredients/product-format',
    60: '/api/ingredients/product-sub-format',

    // Search keywords api
    61: '/api/search/keywords/',

    // Logging for downloaded reports
    62: '/api/reports/log_report/',

    // Related Trends
    64: '/api/trends/',

    // Get Reports List
    65: '/api/reports/get_report/',

    // Get PPT
    // Product Details ppt
    66: '/api/format/saveProductDetailsToPptx',
    // Monthly trends graph ppt
    67: '/api/format/saveGraphToPptx',
    // Consumer Insights
    68: '/api/format/saveCnInsightsToPptx',
    // Retail products
    69: '/api/format/saveRelatedRetailProductsToPptx',
    // Cafe products
    70: '/api/format/saveOnPremiseToPptx',
    // Complete page
    71: '/api/format/saveMultiplyToPptx',
    // Whole page
    77: '/api/format/saveWholeLenseToPptx',
    // Related Themes
    80: '/api/format/SaveRelatedThemesToPptx',
    // Related Products
    81: '/api/format/SaveRelatedProductsToPptx',
    // Related Ingredients
    82: '/api/format/SaveRelatedIngredientsToPptx',

    // Excel Download
    72: '/api/export/SaveGraphsToExcel',
    73: '/api/export/SaveConsumerInsightsToExcel',
    74: '/api/export/SaveRetailFormatToExcel',
    75: '/api/export/SaveFoodServiceFormatToExcel',
    76: '/api/export/SaveOverallToExcel',

    // advanced search
    78: '/api/search/advanced_search/',
    79: '/api/search/saved-user-queries/',

    // notifications
    90: '/api/notifications/all_count',
    91: '/api/notifications/all_list/',
    92: '/api/notifications/unread_count',
    93: '/api/notifications/unread_list/',
    94: '/api/notifications/mark-as-read/',
    95: '/api/notifications/mark_notification/',

    // micro-service subscriptions chec
    96: '/api/organization/subscriptions/microservices/check/?service_name=',

    // white space opportunity
    97: '/api/project/whitespace',
    98: '/api/project/whitespace/download',

    // Bookmark
    99: '/api/organization/users/bookmarks/',
    100: '/api/organization/users/bookmarks/check/',
    // common trend analysis
    101: '/api/project/common-trends/list/',
    // sharing card users
    103: 'api/organization/users/',
    104: 'api/organization/users/share-trends/',
    // hepldoc download
    110: '/api/reports/help-docs/',

    // Get sub-categories list
    120: '/api/project/subcategories/list/',
    121: '/api/project/subscribed-subcategories/list/',

    111: '/api/search/advanced_search/classifications/results/download/',
    112: '/api/project/common-trends/list/download/',

    // update-password
    114: 'api/users/update-password/',
    // Document360 apis
    130: '/api/users/auth/code/?external_platform=Document360',

    // Highlight Feature apis
    135: 'api/features/',

    // Trend Pillar apis
    140: '/api/trend_pillars',

    // Import Trend Pillar
    141: '/api/trend_pillars/import_pillar',
    142: '/api/organization/client_management',
    143: '/api/organization/features_config',
    144: '/api/dashboard_subscription',
    145: '/api/organization/users/management/',
    146: '/api/organization/users/management/mail/',
    147: 'api/organization/client_management/download',

    // Download Excel (Related Themes)
    148: '/api/trends/related_trends/download/',

    // Regional Analytics
    149: '/api/analytics/regional',
    // Demographic Analytics

    156: '/api/analytics/demographic/distribution/',
    157: '/api/analytics/demographic/get_dashboards/',
    158: '/api/analytics/demographic/get_trend_graph/',
    159: '/api/analytics/demographic/get_all_trends/',
    160: 'api/analytics/demographic/get_trend_graph',
    161: 'api/analytics/demographic/consumer_insights/',

    // Cognitive search
    150: '/api/search/cognitive-search/formats/',
    151: '/api/search/cognitive-search/trends/',
    152: '/api/search/cognitive-search/consumer_needs/',
    153: '/api/search/cognitive-search/advance_filter/',
    154: '/api/search/cognitive-search/advance_filter_apply/',
    155: '/api/search/cognitive-search/dashboards/',

    // Cognitive search UAM
    170: '/api/dashboard_subscription/poc_dashboard/project',
    171: '/api/dashboard_subscription/poc_dashboard/formats',
    172: '/api/dashboard_subscription/poc_dashboard/',
    173: '/api/dashboard_subscription/poc_dashboard/bulk_create/',
    174: '/api/dashboard_subscription/poc_dashboard/bulk_update/',

    // Personalised Dashboard - Favourites Section
    175: 'api/favourites/trends/',
    176: 'api/favourites/trends/check/',

    // My Workspace
    180: '/api/favourites/trends/',
    181: '/api/personalized_dashboard/saved/',

    // Trend Pillar Favorite  Section
    182: 'api/favourites/trends/trend_piller/',
    183: 'api/favourites/trends/trend_piller/check/',

    184: '/api/personalized_dashboard/recent_activity/',
    // Other Favorite Section
    185: 'api/favourites/trends/other_favourites/',
    186: 'api/favourites/trends/other_favourites/check/',

    // Trend Transition
    187: '/api/project/trend-transitioning/',

    // Trend Family Filter
    188: '/api/trend_family/',

    // Mark Irrelevant Section
    189: 'api/reports/mark-irrelevant/consumer-quotes',

    // Related Trends v2
    190: '/api/trends/v2/',

    // Wheel
    191: '/api/wheels/',

    // Preference
    192: '/api/users/preferences',

    //  Feedback
    193: '/api/users/feedback',

    194: '/api/project/blogs-and-websites/list/',
    195: '/api/project/platforms-and-brands/list/',
    // Redirection to old and new UI
    200: '/api/users/validate/token',
    201: '/api/users/login-redirect/token',

    // BPC Review Platforms
    202: '/api/project/review-platforms/list/',
  },
}

Object.freeze(RequestEndpoint)

export default NetworkUtil
