import React, { useState, useContext, useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import SingleSelect from '../../../../shared/components/SingleSelect'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '../../../assets/icons/search.svg'
import { DataContext } from '../../../contexts/data-context'
import BackdropLoader from '../../generic-components/partials/BackdropLoader'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { Tooltip } from '@mui/material'
import { getHeaders, getBuildWinnerURL } from '../../../network/utility'
import { useSingleSnackBar } from '../../../../hooks'
import { BlueCaptionHiglight, NoteCaption } from './styles'
import { cleanUpAnchorBrackets, detectWrap } from './../../../../store/utility'
import Reset from './../../../assets/icons/reset.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB } from '../../../../../src/shared/utils/constants'

const MIN_KEYWORDS = 3
const MAX_KEYWORDS = 20
const CONCEPT_TAB = 'Text'
const AI_IMAGE_TAB = 'Image'

const SearchBarWithButton = ({
  setMonthlyLimitExceeded,
  setConceptLimitExceeded,
  logGenerateConceptClicked,
  tags,
  imageUrl,
  imagePkgImgUrl,
  resolutionSelected,
  promptConcept,
  promptImage,
  generativeAIDropdownOption,
  onConceptImageGenerated,
  isImageGenerated,
  setInput,
  setTags,
  input,
  analysedTrends,
  setAnalysedTrends,
  excludeTags,
  data,
  setData,
  includeError,
  setIncludeError,
  handleReset,
  projectSelected,
  regenerateConcept,
  setRegenerateConcept,
  monthlyUserRequestCount,
  setMonthlyUserRequestCount,
  monthlyUserRequestLimit,
  setMonthlyUserRequestLimit,
  currentUserRequestCount,
  setCurrentUserRequestCount,
  currentUserRequestLimit,
  setCurrentUserRequestLimit,
  setIsAccordianExpanded,
  setExcludeTags,
  projectVariant,
  resetImageUrls,
}) => {
  const chipsContainerRef = useRef(null)
  const [showMore, setShowMore] = useState(null)
  const Resolutions = ['1024x1024', '1024x1792', '1792x1024']
  const LimitNumberContainer = styled(Typography)(({ theme }) => ({
    background: theme.palette.grey[250],
    padding: '4px 8px',
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    fontSize: 12,
    display: 'inline-block',
    marginLeft: '4px',
  }))
  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(1),
    display: 'inline-block',
  }))

  const ChipItem = styled('span')(({ theme }) => ({
    padding: '5px 20px 5px 20px',
    borderRadius: '32px',
    border: '1px solid rgb(49,170,220)',
    color: 'rgb(49,170,220)',
    cursor: 'pointer',
  }))

  const InvisibleDiv = styled('div')(({ theme }) => ({
    height: '10vh',
    display: 'hidden',
  }))

  const HelperTextComponent = styled('div')(({ theme }) => ({
    color: 'grey',
    width: '470px',
    borderRadius: '24px',
    fontSize: '12px',
    boxShadow: 'none',
  }))

  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isPromptChanged, setIsPromptChanged] = useState(false)
  const { showSnackBar } = useSingleSnackBar()
  const [overflowChipCount, setOverflowChipCount] = useState(0)
  const [promptValue, setPromptValue] = useState('')
  const [sizeSelected, setSizeSelected] = useState('1024x1024')
  const [newPrompt, setNewPrompt] = useState(false)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = (event) => {
    if (event.target.value !== '') {
      const currentTag = event.target.value
      const newCurrentTags = currentTag.split(',')
      const filteredTags = newCurrentTags.filter(
        (item) => !!item.replace(/\s+/g, '').length,
      )
      if (
        filteredTags.some((item) =>
          tags.map((tag) => tag.toLowerCase()).includes(item.toLowerCase()),
        )
      ) {
        setInput('')
      } else if (filteredTags.some((item) => excludeTags.includes(item))) {
        const intersection = filteredTags
          .filter((x) => excludeTags.indexOf(x) !== -1)
          .toString()

        setIncludeError(`Note: ${intersection} is Excluded`)
      } else if (tags.length === MAX_KEYWORDS) {
        setIncludeError('Error: Maximum 20 Keywords can be selected.')
      } else {
        setIncludeError('')
        setTags([...tags, ...filteredTags.map((item) => item.trim())])
        event.target.value = ''
      }
    }
    setInput('')
  }

  const context = useContext(DataContext)

  const open = Boolean(anchorEl)

  const userId = localStorage.getItem('my-secret-key')
  const sendImage = (e) => {
    setNewPrompt(false)
    isImageGenerated(true, e)
  }
  const sendData = () => {
    logGenerateConceptClicked(tags)
    setLoading(true)
    const postUrl = getBuildWinnerURL(`concepts/`)
    const body = {
      title: `DEFAULT_${tags.toString()}_${new Date().getTime()}`,
      description: tags.toString(),
      user_id: userId,
      excluded_keywords: excludeTags,
      country: projectSelected?.country || '',
      category: projectSelected?.category || '',
      project_variant: projectVariant,
    }
    fetch(
      postUrl,
      getHeaders(
        {
          body: JSON.stringify(body),
        },
        'POST',
      ),
    )
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error Generating Concept! ${res?.statusText}`, 'error')
          setAnalysedTrends(null)
        }
        return res.json()
      })
      .then((data) => {
        promptConcept(data.original_generated_concept)
        // It's a failed request
        if (data && data.detail) {
          setLoading(false)
          context.handleErrors(data.detail)
          setAnalysedTrends(null)
          throw new Error(data.detail)
        }
        // It's a failed request
        else if (data && data.message == 'Forbidden') {
          setLoading(false)
          context.handleErrors(data.message)
          setAnalysedTrends(null)
          throw new Error(data.message)
        } else {
          const ing = data.ingredients.map((ing) => {
            return { ...ing, type: 'ingredient' }
          })
          const themes = data.themes.map((theme) => {
            return { ...theme, type: 'theme' }
          })
          setAnalysedTrends({
            ingredient: ing,
            theme: themes,
          })

          const newData = { ...data }
          newData.generated_concept = cleanUpAnchorBrackets(
            newData.generated_concept,
          )

          setData(newData)
          setLoading(false)
          context.handleUpdateData(newData, 'current_concept')
        }
      })
      .catch((error) => {
        console.log('error_occured', error)
        setAnalysedTrends(null)
        setLoading(false)
      })
  }

  const handleInput = (input, id) => {
    setNewPrompt(true)
    setInput(input)
    if (includeError !== '') {
      setIncludeError('')
    }
    if (id === 'image-prompt') {
      setPromptValue(input)
    }
  }
  promptImage(promptValue)
  const fetchUserConceptlimit = () => {
    const userId = localStorage.getItem('my-secret-key')
    const getUserCurrentLimitUrl = getBuildWinnerURL(
      `concepts/${userId}/concepts_count`,
    )
    fetch(getUserCurrentLimitUrl, getHeaders({}, 'GET'))
      .then((res) => {
        if (!res.ok) {
          showSnackBar(`Error getting daily limit! ${res?.statusText}`, 'error')
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setCurrentUserRequestCount(data.current_request_count)
        setCurrentUserRequestLimit(data.request_limit_count)
      })
      .catch((error) => {
        console.log('error_occured', error)
      })
  }
  const fetchUserMonthlylimit = () => {
    const userId = localStorage.getItem('my-secret-key')
    const getUserCurrentLimitUrl = getBuildWinnerURL(
      `concepts/${userId}/monthly_count`,
    )
    fetch(getUserCurrentLimitUrl, getHeaders({}, 'GET'))
      .then((res) => {
        console.log('response', res)
        if (!res.ok) {
          showSnackBar(
            `Error getting monthly limit! ${res?.statusText}`,
            'error',
          )
          throw new Error(`${res?.status} - ${res?.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        setMonthlyUserRequestCount(data.remaining_requests)
        setMonthlyUserRequestLimit(data.max_limit)
      })
      .catch((error) => {
        console.log('error_occured', error)
      })
  }
  const handleSizeChange = (val) => {
    if (!val) return
    setSizeSelected(val)
  }
  resolutionSelected(sizeSelected)
  const onKeywordEnter = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      addTags(event)
    }
  }

  const checkOverflow = () => {
    const isWrapped = detectWrap(chipsContainerRef)
    if (isWrapped) {
      setShowMore(false)
    } else {
      if (tags.length < overflowChipCount) {
        setOverflowChipCount(0)
        setShowMore(null)
      }
    }
    if (isWrapped && overflowChipCount === 0 && showMore === null) {
      setOverflowChipCount(tags.length)
    }
  }

  const handleResetConcept = () => {
    resetImageUrls()
    handleReset(true)
  }

  useEffect(() => {
    setIsPromptChanged(true)
  }, [input])
  useEffect(() => {
    checkOverflow()
    setIncludeError('')
  }, [tags])

  useEffect(() => {
    fetchUserConceptlimit()
  }, [data])
  useEffect(() => {
    fetchUserMonthlylimit()
  }, [imageUrl, imagePkgImgUrl])
  useEffect(() => {
    if (currentUserRequestCount >= currentUserRequestLimit) {
      setConceptLimitExceeded(true)
    }
  }, [currentUserRequestCount])
  useEffect(() => {
    if (monthlyUserRequestCount >= monthlyUserRequestLimit) {
      setMonthlyLimitExceeded(true)
    }
  }, [monthlyUserRequestCount])

  useEffect(() => {
    // API call if regenerate button at parent level is clicked
    if (regenerateConcept) {
      sendData()
      setRegenerateConcept(false)
    }
  }, [regenerateConcept])

  useEffect(() => {
    if (excludeTags.length > 0) {
      setIsAccordianExpanded(true)
    }
  }, [excludeTags])
  return (
    <>
      <Box
        sx={{
          pt: 1,
        }}
        ref={chipsContainerRef}
      >
        {tags &&
          tags
            .slice(
              0,
              showMore !== null && showMore === false
                ? overflowChipCount - 2
                : tags.length,
            )
            .map((tag, index) => {
              return (
                <ListItem key={index}>
                  {generativeAIDropdownOption === CONCEPT_TAB && (
                    <Chip
                      key={`${index}_${tag}`}
                      label={tag}
                      variant="contained"
                      onDelete={() => removeTags(index)}
                      color="primary"
                      sx={{ borderRadius: 18 }}
                    />
                  )}
                </ListItem>
              )
            })}
        {showMore !== null && (
          <Button
            variant="contained"
            sx={{ borderRadius: '20px', height: '24px' }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore
              ? 'View Less'
              : `${tags.length - overflowChipCount + 2} More`}
            {showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
        )}
      </Box>

      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {generativeAIDropdownOption === CONCEPT_TAB ? (
            <Box>
              {includeError !== '' ? (
                <Typography variant="error" sx={{ fontSize: '12px' }}>
                  {includeError}
                </Typography>
              ) : (
                <NoteCaption sx={{ pt: 1 }}>
                  Enter{' '}
                  <BlueCaptionHiglight>3-20 keywords;</BlueCaptionHiglight> Hit{' '}
                  <BlueCaptionHiglight>enter</BlueCaptionHiglight> or type a{' '}
                  <BlueCaptionHiglight>comma</BlueCaptionHiglight> to add a
                  keyword.
                </NoteCaption>
              )}
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button onClick={handleResetConcept} sx={{ color: '#079CD6' }}>
              <img src={Reset} />
              Reset
            </Button>
            {generativeAIDropdownOption === CONCEPT_TAB ? (
              <Typography
                style={{
                  display: 'inline-block',
                  color: 'black',
                  fontSize: '12px',
                }}
              >
                Today’s Limit:
                <LimitNumberContainer
                  sx={{ background: '#EFEFEF', color: 'black' }}
                >
                  {currentUserRequestCount}/{currentUserRequestLimit}
                </LimitNumberContainer>
              </Typography>
            ) : (
              <Typography
                style={{
                  display: 'inline-block',
                  color: 'black',
                  fontSize: '12px',
                }}
              >
                Month's Limit:
                <LimitNumberContainer
                  sx={{ background: '#EFEFEF', color: 'black' }}
                >
                  {monthlyUserRequestCount}/{monthlyUserRequestLimit}
                </LimitNumberContainer>
              </Typography>
            )}
          </Box>
        </Box>
        {generativeAIDropdownOption === CONCEPT_TAB ? (
          <TextField
            fullWidth
            id="concept-prompt"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            variant="outlined"
            value={input}
            placeholder="Please enter keywords here to include claims, trends, category, format"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box>
                    <Button
                      style={{
                        boxShadow: 'none',
                        fontSize: 16,
                        height: '30px',
                      }}
                      variant="contained"
                      onClick={() => sendData()}
                      disabled={tags.length < MIN_KEYWORDS || includeError}
                    >
                      Generate Concept
                    </Button>
                  </Box>
                </InputAdornment>
              ),
              style: {
                height: '40px',
                paddingRight: '5px',
              },
            }}
            onKeyUp={(event) => onKeywordEnter(event)}
            onChange={(e) => handleInput(e.target.value, e.target.id)}
            size="small"
          />
        ) : (
          <TextField
            fullWidth
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            id="image-prompt"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            variant="outlined"
            value={input}
            placeholder="Describe what you want to see...  "
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box>
                    {imagePkgImgUrl === '' ? (
                      <Button
                        name={SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB}
                        style={{
                          boxShadow: 'none',
                          fontSize: 16,
                          height: '30px',
                        }}
                        variant="contained"
                        onClick={(e) => sendImage(e)}
                        disabled={!input.trim()}

                        // disabled={tags.length < MIN_KEYWORDS || includeError}
                      >
                        Generate Image
                      </Button>
                    ) : !newPrompt ? (
                      <Button
                        name={SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB}
                        style={{
                          boxShadow: 'none',
                          fontSize: 16,
                          height: '30px',
                        }}
                        variant="contained"
                        onClick={(e) => sendImage(e)}
                        disabled={!input.trim()}
                        // disabled={tags.length < MIN_KEYWORDS || includeError}
                      >
                        Regenerate Image
                      </Button>
                    ) : (
                      <Button
                        name={SEARCH_BAR_GENERATE_IMAGE_IN_IMG_TAB}
                        // id="searchBarGenerateImgInImgTab"
                        style={{
                          boxShadow: 'none',
                          fontSize: 16,
                          height: '30px',
                        }}
                        variant="contained"
                        onClick={(e) => sendImage(e)}
                        disabled={!input.trim()}

                        // disabled={tags.length < MIN_KEYWORDS || includeError}
                      >
                        Generate Image
                      </Button>
                    )}
                  </Box>
                </InputAdornment>
              ),
              style: {
                height: '40px',
                paddingRight: '5px',
              },
            }}
            onChange={(e) => handleInput(e.target.value, e.target.id)}
            size="small"
          />
        )}

        {generativeAIDropdownOption === AI_IMAGE_TAB && (
          <FormControl
            variant="outlined"
            fullWidth
            height="40px"
            size="large"
            // sx={{
            //   minWidth: '120px',
            // }}
          >
            <SingleSelect
              placeholder="Select Resolution"
              options={Resolutions}
              onChange={(e, newVal) => handleSizeChange(newVal)}
              value={sizeSelected}
            ></SingleSelect>
          </FormControl>
        )}
        {/* <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <HelperTextComponent sx={{ p: 1, boxShadow: 0 }}>
                  Hit <strong>enter</strong> to add the trend
                </HelperTextComponent>
              </Popover> */}
        {/* </Grid> */}
        <>{/* <InvisibleDiv>hello</InvisibleDiv> */}</>
        {loading ? <BackdropLoader loading={loading} /> : null}
      </>
    </>
  )
}

export default SearchBarWithButton
