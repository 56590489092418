import React from 'react';
import { Box, styled } from '@mui/system';
import { CircularProgress, Menu, Typography } from '@mui/material';

export const TrendCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  // cursor: 'pointer',
  '& img': {
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(7),
    height: theme.spacing(7),
    objectFit: 'cover',
  },
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
  },
  '& > .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(0.5),
    '& > .MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));

export const DownloadMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: theme.spacing(20),
  },
}));

export const DownloadLoader = styled(({
  variant, value, size = 15, thickness = 7, ...rest
}) => (
  <Box {...rest}>
    <CircularProgress variant="determinate" value={100} {...{ size, thickness }} />
    <CircularProgress {...{
      variant, value, size, thickness,
    }}
    />
    <Typography>Downloading ...</Typography>
  </Box>
))(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& .MuiTypography-root': {
    ...theme.typography.button,
    fontSize: '0.813rem',
    color: theme.palette.secondary.main,
  },
  '& .MuiCircularProgress-root': {
    '&:first-of-type': {
      position: 'absolute',
      left: 0,
      opacity: 0.25,
    },
    color: theme.palette.secondary.main,
    '& .MuiCircularProgress-circle': {
      strokeLinecap: 'round',
    },
  },
}));
