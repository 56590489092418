import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import { resetPasswordOnServer, checkTokenExpiry } from '../../store/actions'
import {
  Box,
  Button,
  CssBaseline,
  InputAdornment,
  IconButton,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import palette from '../../theme/palette'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LayoutLoginV3 from '../../layouts/LayoutLoginV2'

import { formatString } from '../../store/utility'

import '../muiStyle.css'

const schema = yup
  .object({
    password: yup
      .string()
      .required('Required')
      .min(6, 'Must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .required('Required')
      .min(6, 'Must be at least 6 characters')
      .oneOf([yup.ref('password'), null], "Passwords don't match."),
  })
  .required()

const ResetPasswordPage = (props) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [resetToken, setResetToken] = useState(props.match.params.token)
  const [redirect, setRedirect] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data) => {
    setError('')
    await Object.assign(data, { token: resetToken })
    const response = await props.resetPassword(data)
    if (response.data && response.data.result) {
      localStorage.setItem('__ai_pass_sent_notif', 'Reset password successful.')
      history.push('/')
    } else {
      setError(response.data ? response.data.message : 'Reset Password failed.')
    }
  }

  useEffect(() => {
    NetworkingUtil.clearAuthKey()
    setSpinner(true)
    props
      .checkTokenExpiry({
        token: resetToken,
      })
      .then((response) => {
        setRedirect(true)
      })
    return function cleanup() {
      setSpinner(false)
    }
  })

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    confirmPassword: false,
  })
  const handleClickShowPassword = (key) => () =>
    setShowPassword({
      ...showPassword,
      [key]: !showPassword[key],
    })
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <LayoutLoginV3>
      <CssBaseline />
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ width: 400 }}
      >
        <Stack spacing={1} textAlign="center">
          <Typography variant="h2">Reset Password</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Enter new password and confirm password.
          </Typography>
        </Stack>
        <Stack spacing={2}>
          {error && (
            <Typography color="error.main" variant="subtitle2">
              {error}
            </Typography>
          )}
          <form style={{ maxWidth: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="password"
              render={({
                field: { onChange, value, name, ref },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : ''}
                  type={showPassword.password ? 'text' : 'password'}
                  variant="outlined"
                  size="large"
                  label="New Password*"
                  placeholder="New Password"
                  sx={{ width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              render={({
                field: { onChange, value, name, ref },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  inputRef={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : ''}
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  size="large"
                  label="Confirm Password*"
                  placeholder="Confirm Password"
                  sx={{ width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('confirmPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box sx={{ mt: 4, mb: 1 }}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={isSubmitting}
                fullWidth
              >
                {!isSubmitting ? 'Reset' : <CircularProgress size={16} />}
              </Button>
            </Box>
          </form>
        </Stack>
      </Stack>
    </LayoutLoginV3>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (values) => dispatch(resetPasswordOnServer(values)),
    checkTokenExpiry: (data) => dispatch(checkTokenExpiry(data)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ResetPasswordPage))
