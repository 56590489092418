import React from 'react'

import DashboardTableUI from './DashboardTableUI'
import OverlayLoader from '../../../../../shared/components/OverlayLoader'

const DashboardTable = ({
  subData,
  expandRow,
  setExpandRow,
  deleteSubCategoryDialog,
  selectedFormat,
  setDeleteSubCategoryDialog,
  showLoader,
  handleExpandRow,
  dashboardSubHeadCells,
  dashboardsMainHeadCells,
  removeDashboard,
  dashboardData,
  removeSubcategoryOpen,
  setRemoveFormatOpen,
  handleRemoveFormat,
}) => {
  return (
    <>
      {showLoader && <OverlayLoader />}
      {dashboardData && (
        <DashboardTableUI
          dashboardData={dashboardData}
          adminPanelHeadCells={dashboardsMainHeadCells}
          dashboardSubHeadCells={dashboardSubHeadCells}
          removeSubcategoryOpen={removeSubcategoryOpen}
          dropdownOpen={expandRow}
          setDropdownOpen={setExpandRow}
          handleRemoveFormat={handleRemoveFormat}
          setRemoveFormatOpen={setRemoveFormatOpen}
          selectedFormat={selectedFormat}
          handleState={handleExpandRow}
          subData={subData}
          deleteSubCategoryDialog={deleteSubCategoryDialog}
          setDeleteSubCategoryDialog={setDeleteSubCategoryDialog}
          removeDashboard={removeDashboard}
        />
      )}
    </>
  )
}

export default DashboardTable
