import React, { useState, useEffect, useRef } from 'react'
import { useHistory, withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import * as actions from '../../../store/actions/index'
import axios from '../../../services/networking/axios'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import { amplify } from './../../../store/utility'
import {
  AppBar,
  Avatar,
  Autocomplete,
  Divider,
  IconButton,
  createSvgIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  InputAdornment,
  TextField,
  Container,
  CssBaseline,
  Link,
} from '@mui/material'
import { Box } from '@mui/system'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { Search, QuestionMarkRounded } from '@mui/icons-material'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg'
import { ReactComponent as Logo } from '../../../assets/logo_v2.svg'
import NotificationsButton from '../NotificationsButton'
import {
  HeaderButton,
  HeaderUserMenuMenu,
  VerticalDevider,
  BookMarkICon,
} from './styles'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CreatePillarFlow from '../TrendBucket/CreatePillarFlow'
import TourProvider from '../TourProvider'

import { URLS } from '../../../shared/utils/constants'
import GlobalSearch from '../GlobalSearch/GlobalSearch'
import { IconButtonRoot } from '../NotificationsButton/styles'

const searchHiddenRoutes = [
  URLS.COGNITIVE_SEARCH,
  URLS.PRIVACY_POLICY,
  URLS.PROFILE,
  URLS.COMMON_TREND_ANALYSIS,
  URLS.WSO,
  URLS.TERMS_OF_SERVICE,
  URLS.CONTACT_US,
  URLS.TREND_PILLAR,
  URLS.UNIVERSAL_TREND_SEARCH,
  URLS.BUILD_WINNER,
  URLS.ADMIN,
  URLS.DEMOGRAPHIC_ANALYTICS,
  URLS.MY_WORKSPACE,
  URLS.REGIONAL_ANALYTICS,
  URLS.CONCEPT_GENIE,
  URLS.COMPARE,
]

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData
    switch (amplitudeEvent) {
      case 'Clicked_Screen_Winner':
        amplitudeUserData = {
          User: ampiUserData.props.email,
        }
        break
      case 'Clicked_Cognitive_Search':
        amplitudeUserData = {
          User: ampiUserData.props.email,
        }
        break
      case 'Clicked_Concept_Genie':
        amplitudeUserData = {
          User: ampiUserData.props.email,
        }
        break
      default:
        amplitudeUserData = {
          User: ampiUserData.props.email,
          Location: ampiUserData.props.location
            ? ampiUserData.props.location.pathname
            : null,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Public Header')
  }
}

const PublicHeader = (props) => {
  const userActions = [
    {
      item: 'View profile',
      Icon: createSvgIcon(<ProfileIcon />),
      onClick: () => handleProfileLogout('View profile'),
    },
    {
      item: 'Logout',
      Icon: LogoutIcon,
      onClick: () => handleProfileLogout('Logout'),
    },
  ]

  const getAuthorisedPages = () => {
    const { isManager, organizationSubscriptionDetails } = props

    // Build winner means Concept Genie
    const hasAccessToConceptGenie = NetworkUtil.hasAccessToBW()

    const AccessablePages = []

    if (!organizationSubscriptionDetails?.only_enabled?.only_enabled) {
      AccessablePages.push(
        ...[
          {
            name: 'Foresight Engine',
            route: URLS.MAIN_SYSTEM,
            isInBeta: false,
          },
        ],
      )
    }

    if (hasAccessToConceptGenie) {
      const conceptGenie = {
        name: 'Concept Genie',
        route: URLS.CONCEPT_GENIE,
        isInBeta: false,
      }
      AccessablePages.push(conceptGenie)
    }

    // Now add screen winner
    const screenWinner = {
      name: 'Screen Winner',
      route: URLS.SCREEN_WINNER,
      isInBeta: false,
    }
    AccessablePages.push(screenWinner)

    if (organizationSubscriptionDetails?.cognitive_search) {
      const cognitiveSearch = {
        name: 'Cognitive Search',
        route: URLS.COGNITIVE_SEARCH,
        isInBeta: false,
      }
      AccessablePages.push(cognitiveSearch)
    }

    if (isManager) {
      const admin = {
        name: 'Admin',
        route: URLS.ADMIN,
        isInBeta: false,
      }
      AccessablePages.push(admin)
    }
    return AccessablePages
  }

  const pages = getAuthorisedPages()

  const history = useHistory()
  const path = useLocation().pathname
  const query = useLocation().search
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [pageSelected, setPageSelected] = useState(pages[0])
  const [notifications, setNotifications] = useState(null)
  const fetchNotificationTimeout = useRef(null)
  const [overallUnread, setOverallUnread] = useState(false)
  const [initialBookmarkCount, setInitialBookmarkCount] = useState(0)
  const [animationClass, setAnimationClass] = useState(false)
  const options = []

  if (props.searchKeywordsList)
    props.searchKeywordsList.forEach((option) => {
      const bucket = option.name
      option.list.map((value) => {
        options.push({
          bucket: bucket,
          ...value,
        })
      })
    })

  const menuItemRef = React.useRef(null)
  const userMenuDropdownRef = React.useRef(null)

  const markAsRead = (slug) => {
    axios
      .get(NetworkUtil.markNotificationAsRead(slug))
      .then(() => fetchNotifications(true))
      .catch((er) => console.log(er))
  }

  const fetchNotifications = (overRide) => {
    if (!fetchNotificationTimeout.current || overRide) {
      localStorage.setItem('refreshNotification', new Date().toString())
      axios
        .get(NetworkUtil.getAllNotifications())
        .then((res) => {
          // eslint-disable-next-line
          const { all_list } = res.data
          setNotifications(all_list)
          setOverallUnread(
            !!all_list.find((notification) => notification.overall_unread),
          )
        })
        .catch((er) => console.log(er))
    }
    if (fetchNotificationTimeout.current) {
      clearTimeout(fetchNotificationTimeout.current)
      fetchNotificationTimeout.current = null
    }
    fetchNotificationTimeout.current = setTimeout(() => {
      clearTimeout(fetchNotificationTimeout.current)
      fetchNotificationTimeout.current = null
    }, 400)
  }

  const checkNotifications = () => {
    fetchNotifications()
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
    if (event.isTrusted) {
      const ampiEvent = 'Clicked_Profile_Icon'
      const ampiUserData = {
        User: props.email,
      }
      amplify(ampiEvent, ampiUserData, props.email)
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleProfileLogout = (item) => {
    const ampiEvent = 'Clicked_Profile'
    const ampiUserData = {
      User: props.email,
      View_Profile: item == 'View profile',
      Logout: item == 'Logout',
    }
    amplify(ampiEvent, ampiUserData, props.email)
    let route = ''
    if (item === 'View profile') {
      route = URLS.PROFILE
      history.push(route)
    } else if (item === 'Logout') {
      localStorage.removeItem('febetaComparison')
      localStorage.removeItem('febetaProject')
      localStorage.removeItem('febetaProductLens')
      route = URLS.LOGOUT
      history.replace(route)
    } else {
      return null
    }
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleNaveMenuClick = (route) => {
    handleCloseNavMenu()
    handleRedirect(route)
  }

  const handleRedirect = (route) => {
    let ampiEvent
    const ampiUserData = {
      props,
    }
    switch (route) {
      case URLS.SCREEN_WINNER:
        ampiEvent = `Clicked_Screen_Winner`
        amp(ampiEvent, ampiUserData)
        break
      case URLS.COGNITIVE_SEARCH:
        ampiEvent = `Clicked_Cognitive_Search`
        amp(ampiEvent, ampiUserData)
        break
      case URLS.CONCEPT_GENIE:
        ampiEvent = `Clicked_Concept_Genie`
        amp(ampiEvent, ampiUserData)
        break
    }

    history.replace(route)
  }

  const handleSearchValue = (e, v) => {
    if (
      e.keyCode === 13 ||
      e.type === 'click' ||
      e.buttons === 1 ||
      e.which === 1
    ) {
      const value = (e.target && e.target.value) || (v && v.value)
      if (!!value && value.length >= 3) {
        const projectid = Number(
          new URLSearchParams(query).has('id')
            ? new URLSearchParams(query).get('id')
            : props.match.params.projectId,
        )
        if (projectid) {
          props.history.push(
            `/mui/search/${projectid}/${encodeURIComponent(value)}`,
          )
          e.target.blur()
        }
      }
    }
  }

  const setUserNotified = () => {
    if (overallUnread) {
      axios
        .post(NetworkUtil.markNotified())
        .then(() => {
          setOverallUnread(true)
          fetchNotifications()
        })
        .catch((er) => console.log(er))
    }
  }

  const handleBookmark = () => {
    history.push(URLS.BOOKMARK)
    const ampiEvent = 'Clicked_Bookmark_Icon'
    const ampiUserData = {
      User: props.email,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const isPathHiddenForSearch = () => {
    let hasSubstring = false
    for (let i = 0; i < searchHiddenRoutes.length; i++) {
      if (path.includes(searchHiddenRoutes[i])) {
        hasSubstring = true
        break
      }
    }
    if (path === URLS.MAIN_SYSTEM) {
      hasSubstring = true
    }
    return hasSubstring
  }

  useEffect(() => {
    props.highlightFeatureFetch()
  }, [])

  useEffect(() => {
    props.getBookmarkData()
    fetchNotifications()
    window.addEventListener('storage', checkNotifications)
    return () => {
      window.removeEventListener('storage', checkNotifications)
      clearTimeout(fetchNotificationTimeout.current)
    }
  }, [])

  useEffect(() => {
    fetchNotifications()
    props.fetchSearchKeywordsList(
      props.match.params.projectId || localStorage.getItem('febetaProject'),
    )
  }, [path])

  useEffect(() => {
    setAnimationClass(true)
    props.bookmarkList &&
      props.isBookmarkSwing &&
      setInitialBookmarkCount(props.bookmarkList.bookmarkedTrends.length)
    setTimeout(() => {
      setAnimationClass(false)
    }, 5000)
  }, [props.bookmarkList, props.isBookmarkSwing])

  const handleViewDocs = () => {
    window.open(URLS.DOCS, '_blank', 'noopener')
    const ampiEvent = 'Clicked_Help_Doc'
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  // To be optimised later
  useEffect(() => {
    if (
      props.highlightFeature.code == 'HELPDOC' &&
      (!localStorage.getItem('tourList') ||
        localStorage.getItem('tourList') == 'HELPDOC')
    )
      userMenuDropdownRef.current && userMenuDropdownRef.current.click()
  }, [props.highlightFeature])

  useEffect(() => {
    props.fetchSubscriptions()
  }, [])

  useEffect(() => {
    const currPage =
      pages.find(({ route }) => route === path)?.name || pages[0].name
    setPageSelected(currPage)
  }, [path, props.isManager, props.organizationSubscriptionDetails])

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          bgcolor: 'background.paper',
          filter: 'drop-shadow(0px 4px 12px rgba(39, 43, 49, 0.03))',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Desktop Logo */}
            <Box
              sx={{ display: { xs: 'none', md: 'flex' }, marginRight: '10px' }}
            >
              <Link href="/">
                <Logo width={140} height={32.5} />
              </Link>
            </Box>
            {/* mobile menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {pages.map(({ name, route }) => (
                  <MenuItem
                    key={name}
                    onClick={() => handleNaveMenuClick(route)}
                    selected={pageSelected === name}
                  >
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* mobile logo */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Link href="/">
                <Logo />
              </Link>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                alignSelf: 'end',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <VerticalDevider />
              <Box sx={{ display: 'flex', gap: 3 }}>
                {pages.map(({ name, route, isInBeta }, idx) => (
                  <>
                    {/* {"Should optimise the below code"} */}
                    {isInBeta ? (
                      <Typography
                        sx={{
                          color: ({ palette }) => palette.secondary.main,
                          fontSize: '8px',
                          position: 'relative',
                          left: '129px',
                          top: '-7px',
                          marginLeft: '-45px',
                          fontWeight: 500,
                        }}
                      >
                        BETA
                      </Typography>
                    ) : null}
                    <HeaderButton
                      key={name}
                      onClick={() => handleRedirect(route)}
                      isSelected={pageSelected === name}
                    >
                      {name}
                    </HeaderButton>
                  </>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexGrow: 0,
                mt: '10px',
              }}
            >
              {!isPathHiddenForSearch() && (
                <GlobalSearch smallSize={pages.length > 3} />
              )}
              {/* eslint-disable-next-line */}
              {props.organizationSubscriptionDetails?.trend_pillar && (
                <CreatePillarFlow />
              )}
              {/* Removed Bookmark after Personalised Dashboard Implementation */}
              {/* <BookMarkICon
                animationApply={initialBookmarkCount && animationClass}
                startIcon={<BookmarkBorderIcon />}
                isBookmarkPage={path === URLS.BOOKMARK}
                onClick={handleBookmark}
              ></BookMarkICon> */}

              <NotificationsButton
                setUserNotified={setUserNotified}
                notifications={notifications}
                markAsRead={markAsRead}
                email={props.email}
              />
              <IconButtonRoot
                size="medium"
                fontSize="medium"
                sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
                onClick={handleViewDocs}
                title="Help Doc"
              >
                <QuestionMarkRounded fontSize="small" />
              </IconButtonRoot>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ padding: 0 }}
                ref={userMenuDropdownRef}
                size="medium"
                fontSize="medium"
                title="Profile"
              >
                <Avatar
                  sx={{
                    width: 34,
                    height: 34,
                    '.MuiSvgIcon-root': { padding: '0px !important' },
                  }}
                  isSelected={Boolean(anchorElUser)}
                  src="/path/to/avatar.jpg"
                />
              </IconButton>

              <HeaderUserMenuMenu
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Typography variant="subtitle3" color="textSecondary">
                    {' '}
                    Welcome, {props.userName}!
                  </Typography>
                </MenuItem>
                <Divider />
                {userActions.map(({ item, Icon, onClick }) =>
                  item == 'Help doc' ? (
                    <MenuItem key={item} ref={menuItemRef} onClick={onClick}>
                      <>
                        <Icon /> {item}
                      </>
                    </MenuItem>
                  ) : (
                    <MenuItem key={item} onClick={onClick}>
                      <>
                        <Icon /> {item}
                      </>
                    </MenuItem>
                  ),
                )}
              </HeaderUserMenuMenu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {!isEmpty(props.highlightFeature) &&
        props.highlightFeature.code == 'HELPDOC' &&
        (!localStorage.getItem('tourList') ||
          localStorage.getItem('tourList') == 'HELPDOC') &&
        menuItemRef.current && (
          <TourProvider feat="HELPDOC" modalRef={menuItemRef} />
        )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    userName:
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      (state.user.loggedIn.user.firstName || state.user.loggedIn.user.username)
        ? state.user.loggedIn.user.firstName ||
          state.user.loggedIn.user.username
        : 'User',
    searchKeywordsList: state.search.searchKeywordsList,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    isBookmarkSwing: state.bookmark.isBookmarkSwing,
    bookmarkList: state.bookmark.bookmarked,
    highlightFeature: state.highlightFeature.highlightList,
    organizationSubscriptionDetails:
      state.subscriptions.organizationSubscriptionDetails,
    isManager:
      state.user.userProfile &&
      state.user.userProfile.user &&
      state.user.userProfile.user.is_manager,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSearchKeywordsList: (projectId) =>
      dispatch(actions.fetchSearchKeywordsData(projectId)),
    getBookmarkData: () => dispatch(actions.fetchBookmarks()),
    highlightFeatureFetch: () =>
      dispatch(actions.fetchListOfHighlightFeatures()),
    fetchSubscriptions: () => dispatch(actions.fetchSubscriptions()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublicHeader),
)
