import palette from '../palette'
import typography from '../typography'

export default {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    arrow: {
      color: palette.primary.main,
    },
    tooltip: {
      backgroundColor: palette.primary.main,
      boxShadow: '2px 2px 14px 0px #24242429',
      padding: '4px 8px',
      ...typography.caption,
      color: palette.primary.contrastText,
    },
  },
}