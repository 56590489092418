import React, { useEffect, useState, useMemo } from 'react'
import { Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'

// Mock data imports
import needResopnse from '../../../assets/mock/need-filter.json'
import TrendTable from '../TrendsTable'
import EmptyIngredientsCard from '../EmptyIngredientsCard'
import ButtonGroupComponent from '../TrendsCard/ButtonGroupComponent'
import { amplify, unabbreviateNumber } from '../../../store/utility'
import _ from 'lodash'
import { PHASES, phaseColor, THEME, PRODUCT } from '../../utils/constants'

import CircleIcon from '@mui/icons-material/Circle'

const lowKeyPhaseColor = Object.keys(phaseColor).reduce((acc, key) => {
  acc[key.toLowerCase()] = phaseColor[key]
  return acc
}, {})

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiUserData.props.email,
      Lens: ampiUserData.props.lensSelected,
      Location: 'List View',
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in TrendsDistributionTable')
  }
}
const trendResponse = needResopnse.trends.find(
  (trend) => trend.type === 'Emerging Trends',
).allTrendResults

const phases = [...PHASES]

const indexToRemove = phases.indexOf('Matured')
if (indexToRemove !== -1) {
  phases.splice(indexToRemove, 1, 'Mature')
}

const LOW_GROWTH = 'Low Growth'
const LOW_ENGAGEMENT = 'Low Engagement'
const HIGH_GROWTH = 'High Growth'
const HIGH_ENGAGEMENT = 'High Engagement'

export const newPhases = [
  { alias: 'Opportunity', label: `${HIGH_GROWTH} & ${HIGH_ENGAGEMENT}` },
  { alias: 'Safe Bet', label: `${LOW_GROWTH} & ${HIGH_ENGAGEMENT}` },
  { alias: 'Niche', label: `${LOW_GROWTH} & ${LOW_ENGAGEMENT}` },
  { alias: 'Upcoming', label: `${HIGH_GROWTH} & ${LOW_ENGAGEMENT}` },
]

const getDpValue = (v) => {
  return v
}

const getCagrValue = (v) => {
  if (v) {
    const val = v.toString().replace('>=', '')
    val.replace('%', '')
    return parseFloat(val)
  }
  return v
}

const TrendsDistributionTable = (props) => {
  const [usPhase, setUsPhase] = React.useState(phases[3])
  const ampPhaseChange = (phase) => {
    props.setMaturityPhase(phase)
    const ampiEvent = `Clicked_${phase}`
    const ampiUserData = {
      props,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleListDownload = () => {
    if (updatedData) {
      const phaseSegregatedData = updatedData.reduce((acc, trend) => {
        const phase = trend.type
        if (!acc[phase]) {
          acc[phase] = []
        }
        acc[phase].push(trend)
        return acc
      }, {})

      const data = Object.keys(phaseSegregatedData).map((phase) => {
        return {
          type: newPhases.find(
            (newPhase) => newPhase.alias.toLowerCase() === phase.toLowerCase(),
          )?.label,
          allTrendResults: phaseSegregatedData[phase],
        }
      })
      props.handleListDownload(data, newPhases)
    } else {
      props.handleListDownload()
    }
  }

  const phaseChange = (phase) => {
    setUsPhase(phase)
    ampPhaseChange(phase)
  }

  const logTrendClick = (trendData) => {
    props.logTrendRedirectionNewVis(trendData, 'List')
  }

  const type =
    props.visualisationMode === 'new' ? props.phase : props.phase + ' Trends'
  const data =
    props.trends &&
    props.trends.find(
      (trend) => trend.type.toLowerCase() === type.toLowerCase(),
    )?.allTrendResults

  const filteredData =
    props.projectVariant === 'bpc'
      ? data?.map((trendData) => {
          const newTrendData = { ...trendData }
          delete newTrendData.fs_products
          return newTrendData
        })
      : data

  const getOldTrendsId = (oldTrends) => {
    if (!oldTrends) return []
    let allTrendList = []
    for (const trend of oldTrends) {
      allTrendList = [...allTrendList, ...trend.allTrendResults]
    }
    return allTrendList.map((trend) => trend.id)
  }

  const updatedData = useMemo(() => {
    if (
      props.visualisationMode === 'new' &&
      props.newVisualisationTrends &&
      props.trends
    ) {
      let trendsData = []
      const addType = (arr, type) => {
        return arr.map((val) => {
          return { ...val, type }
        })
      }

      for (const trend of props.newVisualisationTrends) {
        trendsData = [
          ...trendsData,
          ...addType(trend.allTrendResults, trend.type),
        ]
      }
      const formattedOldTrendsId = getOldTrendsId(props.trends)
      const filteredTrends = trendsData.filter((trend) => {
        return formattedOldTrendsId.includes(trend.id)
      })
      return filteredTrends
    }
  }, [props.trends, props.newVisualisationTrends, type, props.lensSelected])

  const appliedSubCategory =
    props?.appliedFilters?.ingredientSubCategory?.name ||
    props?.appliedFilters?.productSubCategory?.name ||
    props?.appliedFilters?.themeSubCategory?.name

  let modifiedUpdatedData =
    props.visualisationMode === 'new'
      ? updatedData?.filter(
          (data) => data.type.toLowerCase() === props.phase.toLowerCase(),
        )
      : filteredData

  if (
    props.visualisationMode === 'new' &&
    modifiedUpdatedData?.length > 0 &&
    !_.isEmpty(props.trendFilters)
  ) {
    const e = props.trendFilters
    modifiedUpdatedData = modifiedUpdatedData?.filter((t) => {
      return (
        (e.two_year_dp !== ''
          ? t.two_year_data_points >=
            parseInt(e.two_year_dp === 'All' ? 0 : getDpValue(e.two_year_dp))
          : true) &&
        (e.four_year_dp !== ''
          ? t.four_year_data_points >=
            parseInt(e.four_year_dp === 'All' ? 0 : getDpValue(e.four_year_dp))
          : true) &&
        (e.two_year_cagr !== ''
          ? (!appliedSubCategory ||
            props.lensSelected === THEME ||
            props.lensSelected === PRODUCT
              ? t.two_year_cagr
              : t.two_year_cagr_all[appliedSubCategory]) >=
            getCagrValue(e.two_year_cagr)
          : true) &&
        (e.four_year_cagr !== ''
          ? (!appliedSubCategory ||
            props.lensSelected === THEME ||
            props.lensSelected === PRODUCT
              ? t.four_year_cagr
              : t.four_year_cagr_all[appliedSubCategory]) >=
            getCagrValue(e.four_year_cagr)
          : true) &&
        (e.retail_products_count
          ? t.retail_products >= e.retail_products_count
          : true) &&
        (e.food_products_count ? t.fs_products >= e.food_products_count : true)
      )
    })
  }

  return (
    <Box>
      <Box
        sx={{
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          pt: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{ display: 'inline' }}
          >
            {props.visualisationMode === 'new'
              ? 'Trend distribution as per Growth Rate and Engagement:'
              : 'Trend Maturity Phase:'}
          </Typography>

          <ButtonGroupComponent
            excelDownloadCount={props.excelDownloadCount}
            handleListDownload={handleListDownload}
            handleDownload={props.handleDownload}
            isChartView={props.viewMode === 'chart'}
            hideListDownload={props.hideListDownload}
            viewMode={props.viewMode}
            setTable={props.setTable}
            setChart={props.setChart}
            visualisationMode={props.visualisationMode}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {props.visualisationMode === 'new'
            ? newPhases.map(({ label, alias }) => (
                <Chip
                  key={alias}
                  sx={{
                    ' .MuiChip-icon': {
                      margin: 0,
                      mr: 0.5,
                    },
                  }}
                  icon={
                    <CircleIcon
                      sx={{
                        width: '15px',
                        fill: lowKeyPhaseColor[alias.toLowerCase()],
                      }}
                    />
                  }
                  label={label}
                  variant="outlined"
                  isSelected={alias.toLowerCase() === props.phase.toLowerCase()}
                  onClick={(e) => {
                    phaseChange(alias)
                  }}
                />
              ))
            : phases.map((phase) => (
                <Chip
                  key={phase}
                  label={phase === phases[3] ? 'Matured' : phase}
                  variant="outlined"
                  isSelected={phase === props.phase}
                  onClick={(e) => {
                    phaseChange(phase)
                  }}
                />
              ))}
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        {modifiedUpdatedData?.length > 0 ? (
          <TrendTable
            showTransitionIcon={props.showTransitionIcon}
            data={modifiedUpdatedData}
            projectid={props.projectid}
            lensSelected={props.lensSelected}
            country={props.country}
            category={props.category}
            email={props.email}
            amplitudeView={props.amplitudeView}
            usPhase={usPhase}
            projectVariant={props.projectVariant}
            appliedFilters={props.appliedFilters}
            visualisationMode={props.visualisationMode}
            logTrendClick={logTrendClick}
          />
        ) : (
          <EmptyIngredientsCard lens={props.lensSelected} />
        )}
      </Box>
    </Box>
  )
}

export default TrendsDistributionTable
