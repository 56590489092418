import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const RemoveFavPopUp = ({
  text,
  sectionName,
  headerText,
  dialogWidth,
  open,
  handleClose,
  handleConfirmClick,
}) => {
  // For future scope we can make it more generic
  const buttons = [
    {
      text: 'Cancel',
      onclick: handleClose,
      variant: 'outlined',
    },
    {
      text: 'Confirm',
      onclick: handleConfirmClick,
      variant: 'contained',
    },
  ]

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: dialogWidth || 300,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          width: '100%',
        }}
      >
        <Box display="flex">
          <ClearIcon
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.primary.dark,
              cursor: 'pointer',
            }}
            fontSize="0.75rem"
          />
        </Box>
        {headerText && <Box>{headerText}</Box>}
      </DialogTitle>

      <DialogContent sx={{ py: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Are you sure to Remove '{text}' from{' '}
          {sectionName || `Other Favorites`}?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
          justifyContent: 'space-between',
        }}
      >
        {buttons.map((obj) => (
          <Button
            sx={{
              width: dialogWidth ? dialogWidth / buttons.length - 32 : 118,
            }}
            variant={obj.variant}
            onClick={obj.onclick}
          >
            {obj.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}
export default RemoveFavPopUp
