import React, { useEffect } from 'react'
import AddFavButtonUI from './AddFavButtonUI'
import { useLoader } from '../../../../hooks'
import OverlayLoader from '../../OverlayLoader'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  addToFavoritesTrends,
  fetchAddedFavCheck,
  removeFromFavoritesTrends,
  addToFavoritesOtherFavorites,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  fetchAddedOtherFavCheck,
} from '../../../../store/actions'

const AddFavoriteButton = ({
  projectId,
  productId,
  trendName,
  trendType,
  reDirectionUrl,
  breadcrumbs,
  addToFavoritesTrends,
  fetchAddedFavCheck,
  removeFromFavoritesTrends,
  addedFav,
  isFavoriteAllowed,
  productFormat,
  pairing,
  favoriteSectionType,
  consumerInsightSection,
  favoriteType,
  additionalInfo,
  addToFavoritesOtherFavorites,
  fetchOtherFavoritesList,
  removeFromOtherFavorites,
  addedOtherFav,
  fetchAddedOtherFavCheck,
}) => {
  const [showLoader, setShowLoader] = useLoader()

  useEffect(() => {
    fetchAddedFavCheck(trendType, productId, projectId)
  }, [trendType, productId, projectId])

  const handleClickAddToFav = () => {
    if (addedFav) {
      setShowLoader(true)
      removeFromFavoritesTrends(
        productId,
        trendName,
        trendType,
        projectId,
      ).then(() => {
        setShowLoader(false)
        fetchAddedFavCheck(trendType, productId, projectId)
      })
    } else {
      setShowLoader(true)
      addToFavoritesTrends(
        productId,
        trendName,
        trendType,
        projectId,
        breadcrumbs,
        reDirectionUrl.replace(/^\/mui\/details\//, '/'),
      ).then(() => {
        setShowLoader(false)
        fetchAddedFavCheck(trendType, productId, projectId)
      })
    }
  }

  return (
    <>
      {showLoader && <OverlayLoader />}
      {isFavoriteAllowed && (
        <AddFavButtonUI
          consumerInsightSection={consumerInsightSection}
          favoriteSectionType={favoriteSectionType}
          pairing={pairing}
          productFormat={productFormat}
          projectId={projectId}
          productId={productId}
          trendName={trendName}
          trendType={trendType}
          reDirectionUrl={reDirectionUrl}
          breadcrumbs={breadcrumbs}
          handleClickAddToFav={handleClickAddToFav}
          addedFav={addedFav}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return {
    addedFav: myDashboard.addedFav,
    isFavoriteAllowed: myDashboard.isFavoriteAllowed,
    addedOtherFav: myDashboard.addedOtherFav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToFavoritesTrends: (
    trendId,
    trendName,
    trendType,
    projectId,
    frontEndPath,
    redirectionUrl,
  ) =>
    dispatch(
      addToFavoritesTrends(
        trendId,
        trendName,
        trendType,
        projectId,
        frontEndPath,
        redirectionUrl,
      ),
    ),
  fetchAddedFavCheck: (trendType, trendId, projectId) =>
    dispatch(fetchAddedFavCheck(trendType, trendId, projectId)),
  removeFromFavoritesTrends: (trendId, trendName, trendType, projectId) =>
    dispatch(
      removeFromFavoritesTrends(trendId, trendName, trendType, projectId),
    ),
  removeFromOtherFavorites: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
  ) =>
    dispatch(
      removeFromOtherFavorites(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
      ),
    ),
  fetchOtherFavoritesList: (setShowLoader) =>
    dispatch(fetchOtherFavoritesList(setShowLoader)),
  fetchAddedOtherFavCheck: (
    name,
    lens,
    favouriteType,
    favouriteId,
    urlLink,
    setShowLoader,
  ) =>
    dispatch(
      fetchAddedOtherFavCheck(
        name,
        lens,
        favouriteType,
        favouriteId,
        urlLink,
        setShowLoader,
      ),
    ),
  addToFavoritesOtherFavorites: (
    name,
    lens,
    projectId,
    favoriteType,
    favoriteId,
    navigation,
    url,
    additionalInfo,
  ) =>
    dispatch(
      addToFavoritesOtherFavorites(
        name,
        lens,
        projectId,
        favoriteType,
        favoriteId,
        navigation,
        url,
        additionalInfo,
      ),
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddFavoriteButton),
)
