import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material'

const getCheckboxesData = (checkboxesList) =>
  checkboxesList.reduce(
    (acc, item) => ({
      ...acc,
      [item.label.toLowerCase()]: {
        label: item.label,
        id: item.label.toLowerCase(),
      },
    }),
    {},
  )

const MultiSelectCheckboxGroup = (props) => {
  const {
    allCheckboxes,
    headerText,
    onChange,
    minRequiredItems,
    errorText,
    outerContainerProps,
    innerContainerProps,
    headerProps,
    checkboxProps,
  } = props
  const checkboxes = useMemo(() => getCheckboxesData(allCheckboxes), [
    allCheckboxes,
  ])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    getCheckboxesData(allCheckboxes.filter((obj) => obj.checked)),
  )

  const handleCheckboxClick = (event, item) => {
    if (event.target.checked) {
      const updatedSelectedCheckboxes = {
        ...selectedCheckboxes,
        [item.id]: item,
      }
      setSelectedCheckboxes(updatedSelectedCheckboxes)
      onChange(updatedSelectedCheckboxes)
    } else {
      const updatedSelectedCheckboxes = _.omit(selectedCheckboxes, item.id)
      setSelectedCheckboxes(updatedSelectedCheckboxes)
      onChange(updatedSelectedCheckboxes)
    }
  }

  const error = useMemo(() => {
    if (minRequiredItems) {
      return Object.keys(selectedCheckboxes)?.length < minRequiredItems
    }
    return false
  }, [minRequiredItems, selectedCheckboxes])

  return (
    <FormControl
      error={error}
      component="fieldset"
      variant="standard"
      sx={{ flexDirection: 'row', alignItems: 'center' }}
      {...outerContainerProps}
    >
      {headerText && (
        <Typography variant="caption" color="textSecondary" {...headerProps}>
          {headerText}
        </Typography>
      )}
      <FormGroup sx={{ flexDirection: 'row' }} {...innerContainerProps}>
        {Object.keys(checkboxes).map((keyOfItem) => (
          <FormControlLabel
            key={keyOfItem}
            control={
              <Checkbox
                checked={Object.keys(selectedCheckboxes).includes(keyOfItem)}
                onChange={(e) => handleCheckboxClick(e, checkboxes[keyOfItem])}
                name={keyOfItem}
                color="secondary"
                size="small"
                {...checkboxProps}
              />
            }
            label={checkboxes[keyOfItem].label}
          />
        ))}
      </FormGroup>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

MultiSelectCheckboxGroup.propTypes = {
  allCheckboxes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      defaultChecked: PropTypes.bool,
    }),
  ).isRequired,
  headerText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minRequiredItems: PropTypes.number,
  errorText: PropTypes.string,
  outerContainerProps: PropTypes.object,
  innerContainerProps: PropTypes.object,
  headerProps: PropTypes.object,
  checkboxProps: PropTypes.object,
}

MultiSelectCheckboxGroup.defaultProps = {
  errorText: 'Requirements not met',
}

export default MultiSelectCheckboxGroup
