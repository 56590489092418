import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { Box, Grid, Typography } from '@mui/material'

import ExploreTrendsTop from '../../componentsmui/HomePagePartials/partials/ExploreTrendsTop'
import EmptyChart from '../../componentsmui/HomePagePartials/partials/EmptyChart'
import TrendReportCard from '../../shared/components/TrendReportsCard'
import CategoryPreview from '../../shared/components/Category Preview'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'

import '../muiStyle.css'

import * as actions from '../../store/actions/index'
import {
  getQueryParams,
  getDecodedQueryParams,
  amplify,
} from '../../store/utility'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import OverlayLoader from '../../shared/components/OverlayLoader'
import countriesList from '../../mocks/country'

import { URLS } from '../../shared/utils/constants'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'
import { AmplitudeValues } from '../../store/amplitudeValues'

import { handleNewUIRedirect } from '../../services/networking/commonRequests'
import NetworkUtil from '../../services/networking/NetworkingUtil'

const INGREDIENT = 'Ingredient'
const PRODUCT = 'Product'
const THEME = 'Theme'

const formatOpts = (list, setGenerator) =>
  Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
    label: item,
    id,
  }))

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const amplitudeUserData = {
      User: ampiUserData.email,
      Lens_Name: ampiUserData.lens,
      Country_Name: ampiUserData.country,
      Category: ampiUserData.category,
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiUserData.email)
  } catch (err) {
    console.log(err, 'Amplitude error in TrendSpottingPage')
  }
}

const categoryPreviewAmplitude = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = ampiUserData
    switch (ampiEvent) {
      case 'Clicked_Lens':
        amplitudeUserData = {
          User: ampiInstance._email,
          Project: ampiUserData.projectId,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Lens: ampiUserData.lens,
        }
        amplify(ampiEvent, amplitudeUserData, ampiInstance._email)
        break
      case 'Clicked_Explore_Category':
        amplitudeUserData = {
          User: ampiInstance._email,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
        }
        amplify(ampiEvent, amplitudeUserData, ampiInstance._email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in CategoryPreview')
  }
}

const getAvailableLens = (lensesAvailable) => {
  const lenses = []
  if (lensesAvailable) {
    const {
      ingredient_feature, // eslint-disable-line
      ingredient_classification, // eslint-disable-line
      theme_classification, // eslint-disable-line
      theme_feature, // eslint-disable-line
      product_feature, // eslint-disable-line
      product_classification, // eslint-disable-line
    } = lensesAvailable
    // eslint-disable-next-line
    if (ingredient_feature && ingredient_classification) {
      lenses.push(INGREDIENT)
    }
    // eslint-disable-next-line
    if (product_classification && product_feature) {
      lenses.push(PRODUCT)
    }
    // eslint-disable-next-line
    if (theme_classification && theme_feature) {
      lenses.push(THEME)
    }
  }
  return lenses
}

const TrendSpottingPage = ({
  redirectUrl,
  requestProjectsList,
  projectsList,
  lensesAvailable,
  fetchLensesAvailable,
  ingredientsList,
  themesList,
  productsList,
  fetchIngredientTrendList,
  fetchThemeTrendList,
  fetchProductTrendList,
  userSelectedProject,
  email,
  subscriptionDetails,
  fetchSubscriptionsDetails,
  setRedirectionUrl,
  loading,
  projectVariant,
}) => {
  const location = useLocation()
  const history = useHistory()
  const [countrySelected, setCountrySelected] = useState(null)
  const [categorySelected, setCategorySelected] = useState(null)

  // const projectId = Number(`${getDecodedQueryParams("id")}`);
  const search = useLocation().search
  const projectId = Number(new URLSearchParams(search).get('id'))

  const categories = useMemo(() => {
    let prjList = projectsList ? projectsList.list : []
    if (countrySelected) {
      prjList = prjList.filter((prj) => prj.criteria === countrySelected.label)
    }
    return formatOpts(prjList, (prj) => prj.name).sort((a, b) =>
      a.label > b.label ? 1 : -1,
    )
  }, [projectsList, countrySelected])

  const countries = useMemo(
    () =>
      formatOpts(
        projectsList ? projectsList.list : [],
        (prj) => prj.criteria,
      ).map(({ label, id }) => {
        return {
          label,
          id,
          code: (
            countriesList.find((country) => country.label === label) || {
              code: '',
            }
          ).code,
          img:
            (
              countriesList.find((country) => country.label === label) || {
                img: '',
              }
            ).img || '',
        }
      }),
    [projectsList],
  )

  const lenses = getAvailableLens(lensesAvailable)

  const setCountryCategory = (country, category) => {
    const { label, id, img, code } = countries.find(
      ({ label }) => country === label,
    )
    setCountrySelected(() => ({ label, id, img, code }))
    setCategorySelected(() => {
      const { label, id, img } = formatOpts(
        projectsList.list.filter((prj) => prj.criteria === country),
        (prj) => prj.name,
      ).find(({ label }) => label === category)
      return { label, id, img }
    })
    const defaultProject = Number(
      projectsList.list.find((prj) => {
        return prj.name === category && prj.criteria === country
      }).id,
    )
    const defaultSelectedProject = isNaN(
      Number(sessionStorage.getItem('defaultSelectedProject')),
    )
      ? 0
      : Number(sessionStorage.getItem('defaultSelectedProject'))
    const ampiEvent = 'Selected_Dashboard_Name'
    const lens = 'Ingredients'
    const ampiUserData = {
      email: email,
      lens: lens,
      country: country,
      category: category,
    }
    amp(ampiEvent, ampiUserData)

    if (defaultProject !== defaultSelectedProject) {
      sessionStorage.setItem('defaultSelectedProject', Number(defaultProject))
      sessionStorage.setItem('defaultSelectedLens', 'Ingredient')
      sessionStorage.setItem(
        'defaultTrendMaturity',
        '["Dormant","Emerging","Growing","Mature","Declining","Fading"]',
      )
      sessionStorage.setItem('defaultTabTrends', '0')
      sessionStorage.setItem('defaultSelectCategory', 'All')
      sessionStorage.setItem('defaultThemeCategory', 'All')
      sessionStorage.setItem('defaultProductCategory', 'All')
      sessionStorage.removeItem('filterRequest')
    }
  }

  const handleCountryChange = (ev, val) => {
    setCountrySelected(val)
    setCategorySelected(null)
  }

  const handleCategoryChange = (ev, val) => {
    setCategorySelected(val)
    const ampiEvent = ' Clicked_Country_and_Category'
    const lens = 'Ingredients'
    if (val) {
      const ampiUserData = {
        email: email,
        lens: lens,
        country: countrySelected.label,
        category: val.label,
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  useEffect(() => {
    if (countrySelected && categorySelected) {
      const prjSelectedId = projectsList.list.find((prj) => {
        return (
          prj.name === categorySelected.label &&
          prj.criteria === countrySelected.label
        )
      }).id
      const queryParams = getQueryParams()
      queryParams.set('id', encodeURIComponent(prjSelectedId))
      history.replace(`?${queryParams.toString()}`)
    }
  }, [countrySelected, categorySelected])

  const redirectUser = () => {
    // Preventing foresight home-page access checks
    if (subscriptionDetails) {
      // Redirect user to new platform if they have access
      if(subscriptionDetails.switch_to_new_platform_enabled && !NetworkUtil.isOptedOut()) {
        handleNewUIRedirect(false)
        return
      }
      if (
        subscriptionDetails?.only_enabled?.only_enabled?.includes(
          'cognitive_search',
        )
      ) {
        // Only redirect if it's under cognitive search route
        if (
          redirectUrl.slice(0, URLS.COGNITIVE_SEARCH.length) ===
          URLS.COGNITIVE_SEARCH
        ) {
          history.replace(redirectUrl)
        } else {
          history.replace(URLS.COGNITIVE_SEARCH)
        }
      } else if (redirectUrl) {
        history.replace(redirectUrl)
        setRedirectionUrl('')
      } else {
        shouldLogRecentActivity(
          'Explore Trends',
          'Platform',
          history.location.pathname,
        )
      }
    }
  }

  useEffect(() => {
    if (!subscriptionDetails) {
      fetchSubscriptionsDetails()
    }
  }, [subscriptionDetails])

  useEffect(() => {
    if (subscriptionDetails) {
      redirectUser()
    }
  }, [subscriptionDetails])

  useEffect(() => {
    requestProjectsList()
  }, [])

  useEffect(() => {
    if (projectId && projectsList) {
      const projectSearched = projectsList.list.find(
        (prj) => prj.id === projectId,
      )
      if (projectSearched) {
        setCountryCategory(projectSearched.criteria, projectSearched.name)
      }
    }
  }, [projectsList])

  useEffect(() => {
    if (projectsList) {
      if (projectId) {
        fetchLensesAvailable(projectId)
      }
    }
  }, [location])

  useEffect(() => {
    if (projectId) {
      const lenses = getAvailableLens(lensesAvailable)
      if (lenses.includes(INGREDIENT)) {
        fetchIngredientTrendList(projectId)
      }
      if (lenses.includes(THEME)) {
        fetchThemeTrendList(projectId)
      }
      if (lenses.includes(PRODUCT)) {
        fetchProductTrendList(projectId)
      }
      userSelectedProject(projectId)
    }
  }, [lensesAvailable])

  return redirectUrl || !subscriptionDetails ? (
    <OverlayLoader />
  ) : (
    <FeatureAuthorizer disableForFeat="cognitive_search">
      <Box>
        {loading && <OverlayLoader />}
        <MainCommonTabs subscriptionDetails={subscriptionDetails} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box sx={{ my: 3 }}>
              <Typography variant="h2">Explore Trends</Typography>
            </Box>
            <ExploreTrendsTop
              projectId={projectId}
              countries={countries}
              countrySelected={countrySelected}
              handleCountryChange={handleCountryChange}
              categories={categories}
              categorySelected={categorySelected}
              handleCategoryChange={handleCategoryChange}
              handleProjectClick={setCountryCategory}
              topProjectsList={
                projectsList
                  ? projectsList.list.map((prj) => ({
                      id: prj.id,
                      category: prj.name,
                      country: prj.criteria,
                    }))
                  : []
              }
              amplitudeFn={categoryPreviewAmplitude}
            />
            {categorySelected && countrySelected ? (
              <CategoryPreview
                projectId={projectId}
                ingredientsList={ingredientsList}
                themesList={themesList}
                productsList={productsList}
                lensToShow={lenses}
                countrySelected={countrySelected.label}
                categorySelected={categorySelected.label}
                projectVariant={projectVariant}
                amplitudeFn={categoryPreviewAmplitude}
              />
            ) : (
              <EmptyChart />
            )}
          </Grid>
          <Grid sx={{ my: 3 }} item xs={12} md={4}>
            <TrendReportCard email={email} />
          </Grid>
        </Grid>
      </Box>
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  const { savedTrendRequester, projectListBuilder, downloadSections } = state
  return {
    redirectUrl: state.user.redirectUrl,
    lensesAvailable: projectListBuilder.featuresDetails,
    projectsList: savedTrendRequester.savedTrendList,
    ingredientsList: projectListBuilder.projectList,
    themesList: projectListBuilder.themeList,
    productsList: projectListBuilder.productList,
    subscriptionDetails: projectListBuilder.subscriptionDetails,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    loading: downloadSections.loadingRequests,
    projectVariant: state.projectListBuilder.projectList?.projectVariant,
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
  fetchLensesAvailable: (projectId) =>
    dispatch(actions.fecthFeaturesDetails(projectId)),
  fetchIngredientTrendList: (projectid, defaultSelectCategory) =>
    dispatch(
      actions.fetchIngredientTrendList(projectid, defaultSelectCategory),
    ),
  fetchThemeTrendList: (projectid, defaultSelectCategory) =>
    dispatch(actions.fetchThemeTrendList(projectid, defaultSelectCategory)),
  fetchProductTrendList: (projectid, defaultSelectCategory) =>
    dispatch(actions.fetchProductTrendList(projectid, defaultSelectCategory)),
  userSelectedProject: (projectid) =>
    dispatch(actions.userSelectedProject(projectid)),
  fetchSubscriptionsDetails: () =>
    dispatch(actions.fetchSubscriptionsDetails()),
  setRedirectionUrl: (url) => dispatch(actions.setRedirectionUrl(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrendSpottingPage)
