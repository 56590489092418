import es6Promise from 'es6-promise'
import * as AC from './actionConstants'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import { NotificationManager } from 'react-notifications'
import { LAMBDA_URL } from '../../conf'
import { fetchNeedsList } from '../actions'

es6Promise.polyfill()

export const addBuildWinner = (buildWinner) => ({
  type: AC.ADD_BUILD_WINNER,
  buildWinner,
})

export const addFoodCategory = (foodCategories) => ({
  type: AC.ADD_FOOD_CATEGORIES,
  foodCategories,
})

export const addWinningIdeas = (winningIdeas) => ({
  type: AC.ADD_WINNING_IDEAS,
  winningIdeas,
})

export const getWinningIdeas = (reportMappingID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.getWinningIdeas(reportMappingID)
    return axios.get(request).then(function (response) {
      dispatch(addWinningIdeas(response.data))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const getWinningIdeasCount = (reportMappingID, secondsPassed) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.getWinningIdeasCount(reportMappingID)
    return axios.get(request).then(function (response) {
      if (response.data > 0 || secondsPassed > 30) {
        return dispatch(getWinningIdeas(reportMappingID))
      } else {
        return new Promise(resolve => setTimeout(() => resolve(), 3000)).then(() => {
          return dispatch(getWinningIdeasCount(reportMappingID, secondsPassed + 3))
        })
      }
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const makeCallToLambdaFunction = (reportMappingID) => {
  return function (dispatch, getState) {
    return axios.get(LAMBDA_URL).then(function (response) {
      // since response is now received we will make another call to get details of winning ideas
      return dispatch(getWinningIdeasCount(reportMappingID, 0))
    }).catch(function (error) {
      NotificationManager.error('Error occurred in Lambda Function Calling')
    })
  }
}

export const addBuildWinnerOnServer = (formInput) => {
  return function (dispatch, getState) {
    fetchNeedsList(dispatch, formInput.trendProjectID)
    const request = NetworkUtil.formAddBuildWinner()
    return axios.post(request, formInput).then(function (response) {
      dispatch(addBuildWinner(response.data))
      return dispatch(makeCallToLambdaFunction(response.data.reportMappingID))
    }).catch(function (error) {
      console.log(error)
      NotificationManager.error('Build Winner Addition Failed')
    })
  }
}

export const getAllFoodCategoryFromServer = () => {
  return function (dispatch, getState) {
    const request = NetworkUtil.getFoodCategories()
    return axios.get(request).then(function (response) {
      dispatch(addFoodCategory(response.data))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}
