import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      '& .MuiButtonBase-root': {
        color: palette.colors.switchOff,
      },
      '& .Mui-checked': {
        color: ({ palette }) => palette.primary.main,
      },
    },
  },
}
