import React from 'react'
import {
  reduxForm,
} from 'redux-form'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import classes from './UserPage.css'
import { UserRegistrationForm } from '../index'
import Spinner from '../../components/UI/Spinner/Spinner'
import Dialog from 'react-bootstrap-dialog'
import { reduxFormConst } from '../../const'

class UserPage extends React.Component {
  componentWillMount () {
    this.props.getAllSubUserOfOrganization()
  }

  editOrgSubUserButton (cell, row, enumObject, rowIndex) {
    return (<button className=" btn btn-custom" type="button" onClick={() => {
      this.props.showSubUserEditForm(row)
    }}>
      <i className="fa fa-pencil"></i>
    </button>

    )
  }

  deleteOrgSubUserButton (cell, row, enumObject, rowIndex) {
    return (<button className=" btn btn-custom " type="button" onClick={() => {
      this.dialog.show({
        title: 'Manage Detail',
        body: 'Are you sure to remove this detail ?',
        actions: [
          Dialog.DefaultAction('Remove', () => {
            this.props.showSubUserDelete(row)
          }, 'btn-custom'),
          Dialog.DefaultAction('Close', () => {
            this.dialog.hide()
          }, 'btn-custom'),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          this.dialog.hide()
        },
      })
    }}>
      <i className="fa fa-trash"></i>
    </button>
    )
  }

  render () {
    return (
      <div className="col-md-12 pad">
        <Dialog ref={(el) => {
          this.dialog = el
        }}/>
        <div className="col-md-12">
          {this.props.showSpinner ? <div className="sppinerSection"><Spinner/></div> : null}
          {this.props.orgUserList
            ? <div className="clearfix">
              <button className={'btn ' + classes.btnCustom} onClick={() => {
                this.props.showUserRegistrationForm()
              }}>Add User
              </button>
              <BootstrapTable data={this.props.orgUser}
                striped={true}
                hover={true}>
                <TableHeaderColumn columnTitle isKey
                  hidden dataField='id'>ID</TableHeaderColumn>
                <TableHeaderColumn columnTitle dataField='firstName'>
                                    Name</TableHeaderColumn>

                <TableHeaderColumn width="8%" dataField='editButton'
                  dataFormat={this.editOrgSubUserButton.bind(this)}>Edit
                </TableHeaderColumn>
                <TableHeaderColumn width="8%" dataField='deleteButton'
                  dataFormat={this.deleteOrgSubUserButton.bind(this)}>Delete
                </TableHeaderColumn>
              </BootstrapTable>
            </div> : null
          }
          <div className="col-md-12 pad">
            {this.props.registrationUserForm
              ? <UserRegistrationForm
                title={this.props.registrationForm}
                hide={this.props.hideForm}
                onSubmit={this.props.addOrgSubUser}
              /> : null
            }
          </div>

        </div>
      </div>

    )
  }
}

UserPage = reduxForm({
  // a unique name for the form
  form: reduxFormConst.userPage,
})(UserPage)

export default UserPage
