import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import { Box } from '@mui/system'
import { LinearProgress, Typography } from '@mui/material'
import ConsumerItem from './styles'
import { amplify } from './../../../store/utility'

const NeedLinearProgress = ({
  title,
  value,
  link,
  country,
  category,
  ingredientName,
  email,
  displayTitle,
  heading,
  type,
  disableTopicsPointerEvents,
}) =>
  type !== 'regionalAnalytics' ? (
    <Link
      to={link}
      onClick={() => {
        if (!type) {
          const ampiEvent = `Clicked_${displayTitle}`
          const ampiUserData = {
            User: email,
            Ingrediet_Name: ingredientName,
            Country_Name: country,
            [heading]: title,
            Category_Name: category,
          }
          amplify(ampiEvent, ampiUserData, email)
        }
      }}
    >
      <ConsumerItem key={title}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle3" sx={{ color: 'primary.main' }}>
            {title}
          </Typography>
          <Typography variant="subtitle2">{value.toFixed(2)}%</Typography>
        </Box>
        <LinearProgress variant="determinate" value={value} />
      </ConsumerItem>
    </Link>
  ) : (
    <ConsumerItem
      disableTopicsPointerEvents={disableTopicsPointerEvents}
      key={title}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle3" sx={{ color: 'primary.main' }}>
          {title}
        </Typography>
        <Typography variant="subtitle2">{value.toFixed(2)}%</Typography>
      </Box>
      <LinearProgress variant="determinate" value={value} />
    </ConsumerItem>
  )

NeedLinearProgress.defaultProps = {
  link: null,
}

NeedLinearProgress.propTypes = {
  title: propTypes.string.isRequired,
  value: propTypes.number.isRequired,
}

export default NeedLinearProgress
