import React from 'react'
import { PopupBox, Boxes, Img } from './Styled'
import { Box, Typography, Button } from '@mui/material'
import RemovebookmarkImage from '../../../assets/images/testImages/removebookmark.svg'
import { Grid } from 'react-bootstrap'

const RemovePopup = ({ handlePopup, handleRemoveBookmark }) => {
  return (
    <PopupBox>
      <Boxes>
        <Box
          component="img"
          src={RemovebookmarkImage}
          alt="No bookmarks found"
        />
        <Typography variant="subtitle1" pt={2}>
          Remove Bookmark ?
        </Typography>{' '}
        <Typography
          variant="subtitle2"
          pt={1}
          pb={1}
          color={(theme) => theme.palette.text.secondary}
        >
          If you remove it from Bookmark, it won’t appear here and you’ll stop
          receiving notification for it.
        </Typography>{' '}
        <Grid container columns={12}>
          <Button variant="outlined" onClick={handlePopup}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: '12px' }}
            onClick={handleRemoveBookmark}
          >
            REMOVE
          </Button>
        </Grid>
      </Boxes>
    </PopupBox>
  )
}

export default RemovePopup
