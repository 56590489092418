import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Input from '../Input/Input'
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'

import './ConceptCreateEdit.scss'
import './ConceptForm.scss'

const fileTypes = [
  '.jpg',
  '.txt',
  '.docx',
  '.ppt',
  '.pptx',
  '.csv',
  '.pdf',
  '.doc',
]

const ConceptForm = ({
  projectView = false,
  conceptTitle,
  conceptDescription,
  conceptRawDescription,
  revalidate,
  revalidateCallback,
  handleTitleChange,
  handleDescriptionChange,
  handleRawDescChange,
  handleFileChange,
  id = '',
  conceptCount,
}) => {
  const [filesChosen, setFileChosen] = useState([])
  const validateAttachments = (ev) => {
    let fileValid = true
    for (const file of ev.target.files) {
      // type check
      const { type, size } = file
      console.log(file, 'file upload')
      if (!fileTypes.includes(type)) {
        console.log('File type invalid')
        fileValid = false
        break
      }
      // size check
      if (!size > 1024) {
        console.log('File size error')
        fileValid = false
        break
      }
    }
  }

  return (
    <>
      <div className="concept-create-edit__view__input">
        <label
          className="sub-title--14 concept-create-edit__view__input-title"
          htmlFor={`conceptTitle${id}`}
        >
          {conceptCount ? `${conceptCount}. Concept title` : 'Concept title'}
        </label>
        <Input
          initialValue={conceptTitle}
          revalidate={revalidate}
          revalidateCallback={revalidateCallback}
          required
          type="text"
          id={`conceptTitle${id}`}
          autoComplete="true"
          errorMessage={'This field is required'}
          onChange={handleTitleChange}
          capitalize
        />
      </div>
      <div>
        <label className="sub-title--14 concept-create-edit__view__desc">
          Description
        </label>
        <div className="concept-create-edit__wysiwyg">
          <WysiwygEditor
            rteOn
            initialValue={conceptDescription}
            initialRawValue={conceptRawDescription}
            handleOnChange={handleDescriptionChange}
            handleRawTextChange={handleRawDescChange}
            revalidate={revalidate}
            revalidateCallback={revalidateCallback}
            required
            errorMessage={'This field is required'}
          />
        </div>
      </div>
      {/* <div className="concept-form__attachments">
        <label
          htmlFor={`attachments${id}`}
          className="sub-title--14 attachment-label"
        >
          <span className="attachment-title">Attachments (optional)</span>
          <span className="attachment-sub-title">
            drop additional files here
          </span>
          <input
            onChange={validateAttachments}
            className="attachment-input"
            id={`attachments${id}`}
            type="file"
            multiple
            accept={fileTypes.join(",")}
          />
        </label>
        {filesChosen.map(() => [])}
      </div> */}
    </>
  )
}

ConceptForm.propTypes = {
  projectView: PropTypes.bool,
}

export default ConceptForm
