import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import products from "../../../../mocks/products";
import { WsoDropdown } from "../../../../containers/WhiteSpace/Styled";

const ProductSelect = ({ categories, value, onChange, disabled, noLabel, label, component, placeholder}) => (
  <Autocomplete
    disablePortal
    disabled={disabled}
    value={value}
    onChange={onChange}
    id="combo-box"
    options={categories}
    sx={{
      width: 250,
    }}
    renderInput={(params) => (
      component ? 
      <WsoDropdown
        {...params}
        label={!noLabel && (label || "Product Category")}
        placeholder={placeholder}
      /> :
      <TextField
        {...params}
        label={!noLabel && (label || "Product Category")}
        placeholder="Select product category"
      />
    )}
  />
);

export default ProductSelect;
