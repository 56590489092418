import React, { useState, useEffect } from 'react'
import { Button, Grid, Link, Typography, Breadcrumbs, Box } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { ReactComponent as ReturnIcon } from './../../../assets/icons/return-icon.svg'
import { useHistory, useParams } from 'react-router-dom'
import axios from './../../../services/networking/axios'

import { useLoader } from '../../../hooks'
import OverlayLoader from '../OverlayLoader'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'
import SavedProjectsList from '../../../services/networking/Models/SavedProjectsList'
import WheelConatiner from '../Wheel/WheelConatiner'
import { THEME } from '../../utils/constants'

export default function WheelViewPage() {
  const [wheelData, setWheelData] = useState(null)
  const [projectList, setProjectList] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [wheelDetail, setWheelDetail] = useState(null)
  const { wheelId } = useParams()
  const [showLoader, setShowLoader] = useLoader()
  const history = useHistory()

  const breadcrumbs = [
    <Link
      variant="inherit"
      key="1"
      to="/mui/main-system"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
      underline="none"
    >
      Explore Trends
    </Link>,
    <Link
      to={`/mui/trend-pillar/?lensSelected=${THEME}`}
      underline="none"
      key="2"
      variant="subtitle2"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
    >
      Trend Pillar
    </Link>,
    <Link
      to="/mui/trend-pillar/saved-wheels"
      underline="none"
      key="3"
      variant="subtitle2"
      sx={{
        color: ({ palette }) => palette.text.secondary,
        marginLeft: '10px',
        fontSize: '14px',
      }}
    >
      Saved Wheel
    </Link>,
    <Typography key="4" variant="subtitle2">
      {wheelDetail?.wheelName}
    </Typography>,
  ]

  const handleRedirection = () => {
    history.push('/mui/trend-pillar/saved-wheels')
  }

  const getProjectId = (country, category) => {
    return projectList?.find(
      (data) => data._name === category && data._criteria === country,
    )?._id
  }

  const calculatePercentage = (data) => {
    if (!data) {
      return
    }
    const dataPointTotal = data.reduce(
      (total, child) => total + child.data_point,
      0,
    )
    data.forEach((child) => {
      child.percentage =
        Math.round((child.data_point / dataPointTotal) * 10000) / 100
      calculatePercentage(child.children)
    })
  }

  const updatedTrends = (trends) => {
    return trends.map((trend) => {
      return {
        value: 1,
        ...trend,
      }
    })
  }

  const updatedRelatedThemes = (relatedThemes) => {
    const newRelatedTheme = relatedThemes.map((relatedTheme) => {
      return {
        value: 1,
        ...relatedTheme,

        children: updatedTrends(relatedTheme.children),
      }
    })
    return newRelatedTheme
  }

  const handleCustomizationSubmit = (data) => {
    let filteredWheelData = data

    const updatedChildren = data.children.map((pillar) => {
      return {
        value: 1,
        ...pillar,

        children: updatedRelatedThemes(pillar.children),
      }
    })

    calculatePercentage(updatedChildren)

    filteredWheelData = { ...data, children: updatedChildren }

    return filteredWheelData
  }

  useEffect(() => {
    setShowLoader(true)
    const req = NetworkingUtil.getWheel(wheelId)
    axios
      .get(req)
      .then((res) => {
        const resBody = res.data.data.wheel
        const data = {
          name: resBody.category,
          children: resBody.children,
          country: resBody.country,
          level: resBody.level,
        }
        setWheelDetail({
          id: resBody.id,
          category: resBody.category,
          wheelName: resBody.name,
          country: resBody.country,
        })

        setWheelData(handleCustomizationSubmit(data))
        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setShowLoader(true)
    const request = NetworkingUtil.formSavedProjectListRequest()
    return axios
      .get(request)
      .then((response) => {
        setShowLoader(false)
        const projects = new SavedProjectsList(response.data)
        setProjectList(projects.list)
      })
      .catch((error) => {
        setShowLoader(false)
        console.log('error in fetching project list')
      })
  }, [])

  useEffect(() => {
    if (wheelDetail && projectList) {
      const { country, category } = wheelDetail
      const projectId = getProjectId(country, category)
      setProjectId(projectId)
    }
  }, [wheelDetail, projectList])

  if (showLoader) return <OverlayLoader />

  return (
    <Box
      sx={{ position: 'relative', background: ({ palette }) => palette.white }}
    >
      <Grid item xs={4} display={'flex'} alignItems={'center'} gap={3} mt={3}>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <Button
        pl={1}
        variant="contained"
        onClick={handleRedirection}
        sx={{
          height: 'auto',
          padding: '10px',
          minWidth: '104px',
          position: 'absolute',
          right: '20px',
          top: '30px',
        }}
      >
        <ReturnIcon />
        <Typography sx={{ color: ({ palette }) => palette.white }} pl={1}>
          Go back to saved wheels
        </Typography>
      </Button>
      {wheelData && projectId && (
        <WheelConatiner data={wheelData} projectId={projectId} />
      )}
    </Box>
  )
}
