import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import axios from '../../services/networking/axios'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import { withRouter, useHistory } from 'react-router-dom'
import { Typography, Box, TextField, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import GlobalTrendSearchTable from '../../components/GlobalTrendSearchComponent/GlobalTrendSearchTable'
import {
  fetchGlobalTrendListResults,
  fetchGlobalTrendList,
  resetGlobalSearchResults,
} from '../../store/actions/globalTrendSearch'
import { getQueryParams, amplify } from '../../store/utility'
import SearchNotSelected from '../../shared/components/AdvancedTrendAnalysisComponents/partials/SearchNotSelected'
import NoResultsFound from '../../shared/components/AdvancedTrendAnalysisComponents/partials/NoResultsFound'
import OverlayLoader from '../../shared/components/OverlayLoader'
import VirtualizedAutocomplete from '../../shared/components/VirtualizedAutocomplete'
import { AmplitudeValues } from './../../store/amplitudeValues'
import ErrorPage from '../../pages/ErrorPage'

import FeatureAuthorizer from '../../components/FeatureAuthorizer'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    let amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiInstance.email,
      Lens: ampiUserData.selectedLens,
      Trend_Name: ampiUserData.selectedTrend,
    }
    switch (ampiEvent) {
      case 'Clicked_Universal_Trend_Search_Bar':
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        break
      case 'Clicked_Universal_Trend_Search_Results_Detail':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.categoryName,
          Maturity_Phase: ampiUserData.maturityPhase,
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        amplitudeEvent = 'Clicked_Trend_Lens'
        amplitudeUserData = {
          ...amplitudeUserData,
          Redirected_From: 'Universal Trend Search',
        }
        amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
        break
    }
  } catch (err) {
    console.log(err, 'Amplitude error in Universal_Trend_Search')
  }
}

const GlobalTrendSearch = ({
  fetchGlobalTrendListResults,
  resetGlobalSearchResults,
  globalTrendSearchInfo,
  fetchGlobalTrendList,
  subscriptionDetails,
}) => {
  const {
    searchKeywordsList,
    results,
    loading,
    loadingList,
  } = globalTrendSearchInfo
  const history = useHistory([])
  const [selectedTrend, setSelectedTrend] = useState('')
  const [selectedLens, setSelectedLens] = useState('')

  const handleTrendClicked = (maturityPhase, categoryName, country) => {
    const ampiEvent = 'Clicked_Universal_Trend_Search_Results_Detail'
    const ampiUserData = {
      selectedLens,
      selectedTrend,
      maturityPhase,
      categoryName,
      country,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleSearchValue = (e, v) => {
    if (
      (e.keyCode === 13 ||
        e.type === 'click' ||
        e.buttons === 1 ||
        e.which === 1) &&
      v
    ) {
      setSelectedLens(v.bucket)
      setSelectedTrend(v && v.name)
    }
  }

  useEffect(() => {
    if (selectedTrend && selectedLens) {
      const ampiEvent = 'Clicked_Universal_Trend_Search_Bar'
      const ampiUserData = {
        selectedLens,
        selectedTrend,
      }
      amp(ampiEvent, ampiUserData)
      fetchGlobalTrendListResults(selectedLens.toLowerCase(), selectedTrend)
      resetGlobalSearchResults()
      const query = getQueryParams()
      const lens = query.get('lens')
      let trend = query.get('trend')
      trend = trend && decodeURIComponent(trend)
      if (lens !== selectedLens || trend !== selectedTrend) {
        history.push(
          `?lens=${selectedLens}&trend=${encodeURIComponent(selectedTrend)}`,
        )
      }
    }
  }, [selectedTrend, selectedLens])

  useEffect(() => {
    try {
      const query = getQueryParams()
      const lens = query.get('lens')
      let trend = query.get('trend')
      trend = trend && decodeURIComponent(trend)
      if (trend && lens) {
        setSelectedLens(lens)
        setSelectedTrend(trend)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchGlobalTrendList()
    shouldLogRecentActivity(
      'Universal Trend Search',
      'Platform',
      `/mui/universal-trend-search`,
    )
    return () => resetGlobalSearchResults()
  }, [])

  return (
    <FeatureAuthorizer
      authAttr="universal_search"
      disableForFeat="cognitive_search"
    >
      <MainCommonTabs subscriptionDetails={subscriptionDetails} />
      <VirtualizedAutocomplete
        sx={{
          display: { xs: 'none', md: 'flex', width: '400px' },
        }}
        autoSelect
        filterSelectedOptions
        id="bucket-search"
        options={searchKeywordsList}
        groupBy={(option) => option.bucket}
        getOptionLabel={(option) => option.displayValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search for Ingredient, Product and Theme trends"
            size="large"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
        onKeyDown={(e, option) => {
          handleSearchValue(e, option)
        }}
        onChange={(e, option) => {
          handleSearchValue(e, option)
        }}
      />
      {selectedTrend ? (
        loading ? (
          <OverlayLoader />
        ) : results.length ? (
          <>
            <Box
              sx={{
                paddingTop: 2,
                paddingBottom: 3,
                position: 'sticky',
                top: '60px',
                background: ({ palette }) => palette.background.default,
              }}
            >
              <Typography>
                Search results for {selectedLens} {selectedTrend}:
              </Typography>
            </Box>
            <GlobalTrendSearchTable
              globalTrendSearchInfo={globalTrendSearchInfo}
              selectedLens={selectedLens}
              handleTrendClicked={handleTrendClicked}
            />
          </>
        ) : !loading ? (
          <NoResultsFound title={`${selectedLens} ${selectedTrend}`} />
        ) : (
          <OverlayLoader />
        )
      ) : loadingList ? (
        <OverlayLoader />
      ) : (
        <SearchNotSelected text={'Your search results will appear here'} />
      )}
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  return {
    globalTrendSearchInfo: state.globalSearchTrends,
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalTrendListResults: (lens, trend) =>
      dispatch(fetchGlobalTrendListResults(lens, trend)),
    fetchGlobalTrendList: () => dispatch(fetchGlobalTrendList()),
    resetGlobalSearchResults: () => dispatch(resetGlobalSearchResults()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalTrendSearch, axios),
)
