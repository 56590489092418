import React from 'react'
import { TableRow, TableBody } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import GenericTableHead from '../../GenericTableComponent/GenericTableHead'
import { GenericTableCell } from '../../../../store/utility'

const OtherFavUI = ({
  otherFavoritesHeadCells,
  data,
  orderBy,
  order,
  handleRequestSort,
}) => {
  return (
    <>
      <TableContainer sx={{ height: '450px' }}>
        <Table stickyHeader aria-label="Other fav table">
          <GenericTableHead
            otherFavorites
            headCells={otherFavoritesHeadCells}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            tableCellVariant="subtitle3"
          />

          {
            <TableBody>
              {data?.map((row) => (
                <TableRow
                  data-testId="tableRow"
                  sx={{ cursor: 'pointer', verticalAlign: 'baseline' }}
                >
                  <>
                    <GenericTableCell
                      rowData={row}
                      tableHeadCells={otherFavoritesHeadCells}
                    />
                  </>
                </TableRow>
              ))}
            </TableBody>
          }
        </Table>
      </TableContainer>
    </>
  )
}
export default OtherFavUI
