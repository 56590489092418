import { useState, useRef, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const useLoader = (loaderCount = 0) => {
  if (typeof loaderCount !== 'number') {
    throw TypeError(
      `useLoader expected a number as an argument instead received a ${typeof loaderCount}.`,
    )
  }

  const [loaderQueue, setLoaderQueue] = useState(
    Array(loaderCount)
      .fill()
      .map(() => true),
  )

  const manageQueue = (loading) => {
    if (loading) {
      setLoaderQueue((queue) => [...queue, true])
    } else {
      setLoaderQueue((queue) => {
        const tmp = [...queue]
        tmp.pop()
        return tmp
      })
    }
  }

  return [Boolean(loaderQueue.length), manageQueue]
}

useLoader.PropTypes = {
  loaderCount: PropTypes.number,
}

export const useSingleSnackBar = () => {
  const ref = useRef(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const showSnackBar = (message, variant, action) => {
    if (ref.current) {
      closeSnackbar(ref.current)
    }
    if (action) {
      ref.current = enqueueSnackbar(message, { variant, action })
    } else {
      ref.current = enqueueSnackbar(message, { variant })
    }
  }
  const removeSnackBar = () => {
    if (ref.current) {
      closeSnackbar(ref.current)
    }
  }

  return { showSnackBar, removeSnackBar }
}

export const usePreviousValue = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * @description Used to maintain all, applied and selected list items states of a NestedMultiSelectDropdown separately.
 * @param {Object} initialState
 * @returns {Array} Array consists of two items
 * 1. Object consists of all the Original, Applied and Selected states in the form of {all: {}, applied: {}, selected: {}}
 * 2. Function used to set any of the above mentioned state
 */
export const useNestedMultiSelectDropdownState = (
  initialState = {
    all: {},
    applied: {},
    selected: {},
  },
) => {
  const [allItems, setAllItems] = useState(initialState.all)
  const [appliedItems, setAppliedItems] = useState(initialState.applied)
  const [selectedItems, setSelectedItems] = useState(initialState.selected)

  const setAppliedAndSelected = (allUpdatedItems) => {
    if (!_.isEmpty(allUpdatedItems)) {
      if (!_.isEmpty(appliedItems)) {
        const newItems = Object.values(allUpdatedItems).filter((item) =>
          Object.keys(appliedItems).includes(`${item.id}`),
        )
        setAppliedItems(
          newItems.reduce((a, item) => ({ ...a, [item.id]: item }), {}),
        )
      }
      if (!_.isEmpty(selectedItems)) {
        const newItems = Object.values(allUpdatedItems).filter((item) =>
          Object.keys(selectedItems).includes(`${item.id}`),
        )
        setSelectedItems(
          newItems.reduce((a, item) => ({ ...a, [item.id]: item }), {}),
        )
      }
    } else {
      setAppliedItems({})
      setSelectedItems({})
    }
  }

  const updateParentStatus = (id, currentState) => {
    let updatedState = { ...currentState }
    const parentObjectIdArray = Object.keys(allItems).filter((keyOfObject) =>
      allItems[keyOfObject].childIds.includes(id),
    )
    if (!_.isEmpty(parentObjectIdArray)) {
      const parentObjectId = parentObjectIdArray[0]
      const allChildIdsOfParent = allItems[parentObjectId].childIds.filter(
        (idOfChild) => idOfChild !== id,
      )
      let allChildrenSelected = true
      for (let i = 0; i < allChildIdsOfParent.length; i++) {
        allChildrenSelected =
          allChildrenSelected &&
          Object.keys(updatedState).includes(`${allChildIdsOfParent[i]}`)
      }
      if (allChildrenSelected) {
        if (!Object.keys(updatedState).includes(`${parentObjectId}`)) {
          updatedState = {
            ...updatedState,
            ...{ [parentObjectId]: allItems[parentObjectId] },
          }
          updateParentStatus(parentObjectId, updatedState)
        }
      } else {
        if (Object.keys(updatedState).includes(`${parentObjectId}`)) {
          updatedState = _.omit(updatedState, [parentObjectId])
        }
      }
    }
    return updatedState
  }
  const deleteParentStatus = (id, currentState) => {
    let updatedState = { ...currentState }
    const parentObjectIdArray = Object.keys(allItems).filter((keyOfObject) =>
      allItems[keyOfObject].childIds.includes(id),
    )
    if (!_.isEmpty(parentObjectIdArray)) {
      const parentObjectId = parentObjectIdArray[0]
      if (Object.keys(updatedState).includes(`${parentObjectId}`)) {
        updatedState = _.omit(updatedState, [parentObjectId])
        deleteParentStatus(parentObjectId, updatedState)
      }
    }
    return updatedState
  }

  const updateChildStatus = (newState, currentState) => {
    let updatedState = { ...currentState }
    const childIds = newState.childIds
    for (let i = 0; i < childIds.length; i++) {
      if (!Object.keys(updatedState).includes(`${childIds[i]}`)) {
        updatedState = {
          ...updatedState,
          ...{ [childIds[i]]: allItems[childIds[i]] },
        }
        updateChildStatus(allItems[childIds[i]], currentState)
      }
    }
    return updatedState
  }
  const deleteChildStatus = (newState, currentState) => {
    let updatedState = { ...currentState }
    const childIds = newState.childIds
    for (let i = 0; i < childIds.length; i++) {
      if (Object.keys(updatedState).includes(`${childIds[i]}`)) {
        updatedState = _.omit(updatedState, [childIds[i]])
        deleteChildStatus(allItems[childIds[i]], updatedState)
      }
    }
    return updatedState
  }

  const setItemsBasedOnOptions = (options, newState, currentState) => {
    let updatedState = { ...currentState }
    if (_.has(options, 'type')) {
      switch (options.type) {
        case 'update':
          updatedState = updateParentStatus(newState.id, updatedState)
          updatedState = {
            ...updatedState,
            ...{ [newState.id]: newState },
          }
          updatedState = updateChildStatus(newState, updatedState)
          break
        case 'delete':
          updatedState = deleteParentStatus(newState.id, updatedState)
          updatedState = _.omit(updatedState, [newState.id])
          updatedState = deleteChildStatus(newState, updatedState)
          break
        default:
          updatedState = { ...newState }
      }
    } else {
      updatedState = { ...newState }
    }
    return updatedState
  }

  /**
   * @description Change any of - all, applied or selected states
   * @param {Object} newState
   * @param {String} stateKey   "all", "applied", or "selected"
   * @param {Object} options
   * @param {String} options.type   "create", "update", or "delete"
   */
  const changeItems = (newState, stateKey, options) => {
    const stateToUse = {
      all: allItems,
      applied: appliedItems,
      selected: selectedItems,
    }
    switch (stateKey) {
      case 'all': // Used to set all items
        setAllItems(newState)
        setAppliedAndSelected(newState)
        break
      case 'applied': // Used to set applied items
        setAppliedItems(
          setItemsBasedOnOptions(options, newState, stateToUse[stateKey]),
        )
        break
      case 'selected': // Used to set selected items
        setSelectedItems(
          setItemsBasedOnOptions(options, newState, stateToUse[stateKey]),
        )
        break
      default:
      // do nothing
    }
  }

  return [
    {
      all: allItems,
      applied: appliedItems,
      selected: selectedItems,
    },
    changeItems,
  ]
}
