import { Paper, Box } from '@mui/material';
import { styled } from '@mui/system';

export const  ConsumerInsightsItemContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 4px 12px 0px #272B3114',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const ConsumerInsightsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flexDirection: 'column',
  gap: theme.spacing(1),
  '& >.MuiBox-root:nth-child(1)': {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))
