import React from 'react'
import Wheel from './Wheel'

const colorDark = [
  '#4DA561',
  '#F7C744',
  '#C77F3F',
  '#E25933',
  '#8C52F6',
  '#739CF0',
  '#C5476E',
  '#FF8C00',
  '#708090',
  '#707955',
]

const colorLight = [
  '#A4E09D',
  '#FBE989',
  '#E3C28F',
  '#F5C0B0',
  '#E2C1FB',
  '#B6D0FB',
  '#E85381',
  '#FFA07A',
  '#C0C0C0',
  '#9BA776',
]

const colorMapping = colorDark.reduce((accumulator, key, index) => {
  return { ...accumulator, [key]: colorLight[index] }
}, {})

const WheelConatiner = ({ data, projectId }) => {
  let colorIndex = 0
  console.log('data', data)

  const updatedTrends = (trends, color) => {
    return trends.map((trend) => {
      return {
        value: 1,
        ...trend,
        color: colorMapping[color],
      }
    })
  }

  const updatedRelatedThemes = (relatedThemes, color) => {
    const newRelatedTheme = relatedThemes.map((relatedTheme) => {
      return {
        value: 1,
        color: color,
        ...relatedTheme,

        children: updatedTrends(relatedTheme.children, color),
      }
    })
    return newRelatedTheme
  }

  let filteredWheelData = data

  const updatedChildren = data.children.map((pillar) => {
    const colorPillar = colorDark[colorIndex++ % colorDark.length]
    return {
      value: 1,
      color: colorPillar,
      ...pillar,

      children: updatedRelatedThemes(pillar.children, colorPillar),
    }
  })

  filteredWheelData = { ...data, children: updatedChildren }

  console.log('filteredWheelData', filteredWheelData)

  return <Wheel data={filteredWheelData} projectId={projectId} />
}

export default WheelConatiner
