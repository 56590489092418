import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { required } from '../../validation'
import {
  renderField,
} from '../UI/form/index'
import classes from './permission.css'
import { reduxFormConst } from '../../const'

class PermissionForm extends Component {
  render () {
    const { handleSubmit } = this.props
    return [
      <form key="PermissionForm" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <Field name="displayName" placeholder={'Name'} component={renderField}
            label={'Name'} validate={[required]}/>
          <Field name="permission" placeholder={'Name of permission'} component={renderField}
            label={'Permission'} validate={[required]}/>
          <button type="submit" className={'btn ' + classes.btnCustom}>
                        Save
          </button>
        </div>
      </form>]
  }
}

PermissionForm = reduxForm({
  form: reduxFormConst.permission,
})(PermissionForm)

export default PermissionForm
