import React from 'react'
import { Breadcrumbs, Typography, Link, Box } from '@mui/material'
import { Link as RouterLink} from 'react-router-dom'
import ScrollToTop from '../../hoc/ScrollToTop'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
const breadcrumbs = [
  <Link variant="breadcrumbsLink" key="1" href="/">
    Foresight Engine
  </Link>,
  <Typography variant="subtitle2" key="2">
    Contact Us
  </Typography>,
]

const ContactUs = () => {
  return (
    <ScrollToTop>
      <Box m={2}>
        <Breadcrumbs
          m={1}
          aria-label="breadcrumb"
          separator={<NavigateNextIcon />}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Box m={1} mt={2}>
          <Typography variant="h2" component="h2">
            Contact Us
          </Typography>
          <br />
          <Typography variant="body1" component="p">
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the platform, please contact us
            at:
          </Typography>
          <br />
          <Typography variant="body1" component="p">
            Ai Palette Pte Ltd<br></br>
            75 Ayer Rajah Crescent, #02-10<br></br>
            Singapore 139953<br></br>
            <br></br>
            info@aipalette.com
          </Typography>
          <br></br>
          <Typography variant="body1" component="p">
            Alternatively, you can also give us your feedback
            <a
              target="_blank"
              href="https://www.jotform.com/aipalette/feedback-form"
            >
              {' '}
              here
            </a>
          </Typography>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default ContactUs
