import { Tooltip } from '@mui/material'
import React from 'react'

const DashboardTooltip = (props) => {
  const { title, backgroundColor, textColor, placement, handleOpen } = props
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement || 'bottom'}
      componentsProps={{
        arrow: {
          sx: {
            color: backgroundColor || (({ palette }) => palette.primary.main),
          },
        },
        tooltip: {
          sx: {
            bgcolor: backgroundColor || (({ palette }) => palette.primary.main),
            color: textColor || (({ palette }) => palette.primary.contrastText),
          },
        },
      }}
      onOpen={handleOpen}
    >
      {props.children}
    </Tooltip>
  )
}

export default DashboardTooltip
