import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'

import ImgSafe from '../../components/Image/ImageSafe'

import { formatString, isEnterClicked } from '../../store/utility'
import {
  searchConcepts,
  searchProjects,
} from '../../store/actions/screenWinnerActions'

import aipaletteLogo from '../../assets/images/TrendIcon/ai-palette--logo.svg'
import userIcon from '../../assets/images/screenWinner/sw_user-icon.svg'
import bellIcon from '../../assets/images/icons/bell_icon.svg'
import searchIcon from '../../assets/images/screenWinner/sw_search-icon.svg'
import closeIcon from '../../assets/images/screenWinner/swClose.svg'
import logoutIcon from '../../assets/images/screenWinner/sw-logout.svg'
import profileIcon from '../../assets/images/screenWinner/sw-user-profile.svg'
import { ReactComponent as Logo } from '../../assets/logo_v2.svg'

import { URLS } from '../../shared/utils/constants'

import './SWHeader.scss'
import { Typography } from '@mui/material'

const SWHeader = ({
  projectsList,
  conceptsList,
  highlight,
  location,
  match,
  searchConcepts,
  searchProjects,
  history,
  isManager,
  subscriptionDetails,
}) => {
  const { pathname } = location
  const [searchInput, setSearchInput] = useState('')
  const [showUserOpts, setShowUserOpts] = useState(false)

  let searchPlaceholder = 'concepts'
  // eslint-disable-next-line
  if (/^\/screen\-winner$/.test(pathname)) {
    searchPlaceholder = 'projects'
  }

  const navLinks = [
    { name: 'Foresight Engine', link: URLS.MAIN_SYSTEM, isInBeta: false },
    { name: 'Concept Genie', link: URLS.CONCEPT_GENIE, isInBeta: false },
    { name: 'Screen Winner', link: URLS.SCREEN_WINNER, isInBeta: false },
  ]

  // if (subscriptionDetails?.cognitive_search) {
  //   navLinks.push({ name: 'Cognitive Search', link: URLS.COGNITIVE_SEARCH })
  // }

  if (isManager) {
    navLinks.push({ name: 'Admin', link: URLS.ADMIN })
  }

  const handleInput = ({ target }) => {
    setSearchInput(formatString(target.value, false))
  }

  const handleKeyUp = (ev) => {
    if (isEnterClicked(ev) && searchInput) {
      let route = `/screen-winner/search-results/`
      if (searchPlaceholder === 'concepts') {
        route = `/screen-winner/projects/${match.params.projectId}/search-results/`
      }
      history.push(`${route}${searchInput}`)
    }
  }

  useEffect(() => {
    if (highlight.length) {
      history.push(highlight[0].route)
    }
  }, [highlight])

  return (
    <header className="sw-header__block">
      <div className="sw-header">
        <Link className="image_wrapper" to="/">
          <Logo width={140} height={32.5} />
        </Link>
        <div className="sw-header__divider mx--30"></div>
        <div className="nav-links-grp">
          {navLinks.map(({ name, link, isInBeta }) => (
            <Link
              key={name}
              className={`nav-links ${
                pathname.includes(link) ? 'nav-links--active' : ''
              }`}
              to={link}
              style={{ position: isInBeta ? 'relative' : '' }}
            >
              <>
                {isInBeta ? (
                  <Typography
                    sx={{
                      color: ({ palette }) => palette.secondary.main,
                      fontSize: '8px',
                      position: 'absolute',
                      left: '135px',
                      top: '2px',
                      marginLeft: '-45px',
                      fontWeight: 500,
                      display: 'inline-block',
                    }}
                  >
                    BETA
                  </Typography>
                ) : null}
              </>
              <span>{name}</span>
            </Link>
          ))}
        </div>
        {(!!projectsList.length || !!conceptsList.length) && (
          <>
            <div className="sw-header__divider mx--30"></div>
            <div className="sw-search">
              <label htmlFor="search-input" onClick={() => setSearchInput('')}>
                <img
                  style={!searchInput ? { display: 'none' } : {}}
                  src={closeIcon}
                  title="Clear"
                  alt="Close icon"
                />
                <img
                  src={searchIcon}
                  title="Search"
                  style={searchInput ? { display: 'none' } : {}}
                  alt="Search icon"
                />
              </label>
              <input
                id="search-input"
                className="search-input"
                value={searchInput}
                placeholder={`Search ${searchPlaceholder} here`}
                type="text"
                onChange={handleInput}
                onKeyUp={handleKeyUp}
              />
            </div>
          </>
        )}
        <div className="sw-header__right">
          <div className="sw-header__divider mr--10"></div>
          {/* <div className="sw-header__notification">
            notification--active
            <div className="notification--activ">
              <img
                className="notification__icon"
                src={bellIcon}
                alt="Notification icon"
              />
            </div>
          </div> */}
          <OutsideClickHandler onOutsideClick={() => setShowUserOpts(false)}>
            <div
              className="sw-header__user"
              onClick={() => setShowUserOpts((show) => !show)}
            >
              <img className="user" src={userIcon} alt="User icon" />
              {showUserOpts && (
                <div className="sw-header__user-opts__block">
                  <Link
                    to="/mui/profile"
                    className="sw-header__user-opts__link"
                  >
                    <div className="sw-header__user-opts">
                      <ImgSafe
                        className="sw-header__user-opts-img"
                        src={profileIcon}
                        alt="Profile"
                      />
                      Profile
                    </div>
                  </Link>
                  <Link to="/logout" className="sw-header__user-opts__link">
                    <div className="sw-header__user-opts">
                      <ImgSafe
                        className="sw-header__user-opts-img"
                        src={logoutIcon}
                        alt="Logout"
                      />
                      Logout
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = ({
  screenWinner,
  application,
  user,
  projectListBuilder,
}) => {
  const { projectsList, conceptsList } = screenWinner
  const { highlight } = application
  return {
    projectsList,
    conceptsList,
    highlight,
    isManager:
      user.userProfile &&
      user.userProfile.user &&
      user.userProfile.user.is_manager,
    subscriptionDetails: projectListBuilder.subscriptionDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchProjects: (query) => dispatch(searchProjects(query)),
    searchConcepts: (query) => dispatch(searchConcepts(query)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SWHeader),
)
