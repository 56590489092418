import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Checkingbox from '../checkbox'
import { FixedSizeGrid } from 'react-window'
import './trendCheckBoxList.scss'

const CHECKBOX_WIDTH = 165

const TrendCheckBoxList = ({ selectedList, data, handleCheckBoxClick }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const row = ({ columnIndex, rowIndex, style }) => {
    const width = Math.floor(screenWidth / CHECKBOX_WIDTH)
    const isChecked = selectedList.find((val) => {
      if (val && data[width * rowIndex + columnIndex])
        return val.name === data[width * rowIndex + columnIndex].name
    })
    return (
      width * rowIndex + columnIndex < data.length && (
        <div style={style}>
          <Checkingbox
            isChecked={isChecked}
            option={data[width * rowIndex + columnIndex]}
            handleCheckBoxClick={handleCheckBoxClick}
          />
        </div>
      )
    )
  }

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const rowCount = Math.ceil(
    data.length / Math.floor(screenWidth / CHECKBOX_WIDTH),
  )
  const rowHeight = 45
  const FixedSizeListHeight = rowHeight * rowCount

  return (
    <>
      {/* <Grid
				container
				columns={16}
				pt={2}
				pb={2}
				display={'flex'}
				flexWrap={'wrap'}
			>
				{data.map(data => (
					<Box m={1} sx={{ p: 1 }}>
						<Checkingbox
							options={data}
							handleCheckBoxClick={handleCheckBoxClick}
						/>
					</Box>
				))}
			</Grid> */}
      <Grid
        container
        columns={16}
        pt={2}
        pb={2}
        display={'flex'}
        flexWrap={'wrap'}
      >
        <FixedSizeGrid
          className="checkBoxList"
          columnCount={Math.floor(screenWidth / CHECKBOX_WIDTH)}
          columnWidth={160}
          height={Math.min(FixedSizeListHeight, 300)}
          rowCount={rowCount}
          rowHeight={rowHeight}
          width={screenWidth}
        >
          {row}
        </FixedSizeGrid>
      </Grid>
    </>
  )
}

export default TrendCheckBoxList
