import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { resetPasswordOnServer, checkTokenExpiry } from '../../store/actions'
import { reduxFormConst } from '../../const'
import {
  reduxForm,
} from 'redux-form'
import { ResetPasswordForm } from '../index'

class ResetPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {token: props.match.params.token, redirect: false}
    this.submitForm = this.submitForm.bind(this)
  }

  submitForm (values) {
    return this.props.resetPassword(values).then(() => {
      this.props.history.push('/trend-spotting')
    })
  }

  componentDidMount() {
    this.props.checkTokenExpiry({
      token: this.state.token
    }).then((response) => {
      this.setState({
        redirect: this.state.redirect
      })
    })
  }
  
  render () {
      if(this.state.redirect) {
          return <Redirect to='/' />
      } else {
        return (
          <div className="col-md-12 pad">
            <div className="col-md-12">
              <div className='col-md-12 pad ' >
                <ResetPasswordForm
                  onSubmit={this.submitForm}
                  token={this.state.token}
                />
              </div>
            </div>
          </div>    
        )
    }
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (values) => dispatch(resetPasswordOnServer(values)),
    checkTokenExpiry: (data) => dispatch(checkTokenExpiry(data))
  }
}

ResetPassword = reduxForm({
  // a unique name for the form
  form: reduxFormConst.resetPassword,
})(ResetPassword)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))

