import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Download } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Typography,
  Tooltip,
  ButtonGroup,
} from '@mui/material'
import { Box } from '@mui/system'
import SaveAsFavoritePopUp from '../SaveAsFavoritePopUp'
import { useLocation } from 'react-router-dom'
import MultiSelectCheckboxGroup from '../MultiSelectCheckboxGroup'

const MAX_FORMATS_LENGTH = 5
const MAX_TRENDS_LENGTH = 5

const SelectedFiltersTags = ({
  handleListDownload,
  selectedFormats,
  selectedFilters,
  handleSelectAssocLvl,
  isChartView,
  handleDownload,
  assocLvl,
  setAssocStrength,
  handleRemoveSelectedFormat,
  handleRemoveSelectedTrend,
  lens,
  showAssocLvl,
  selectedSubFilter,
  subFilterLabel,
  handleRemoveCategory,
  hideListDownload,
  setTable,
  setChart,
  viewMode,
  trends,
  isCS,
  showMoreButton,
  projectId,
  country,
  category,
  showAddToFav,
  hideSubcategoryChips,
}) => {
  const [showMoreFormats, setShowMoreFormats] = useState(false)
  const [showMoreTrends, setShowMoreTrends] = useState(false)
  const location = useLocation()
  const paramsWithoutCtxId =
    showAddToFav &&
    (!!selectedFilters?.length || selectedSubFilter) &&
    new URLSearchParams(location.search)
  if (paramsWithoutCtxId && paramsWithoutCtxId.toString().includes('ctxId')) {
    paramsWithoutCtxId.delete('ctxId')
  }

  const checkedLevels = assocLvl
    ?.filter((obj) => obj.checked === true)
    .map((obj) => obj.name)

  const filterIds = selectedFilters.map((filters) => filters.id)
  const filtersSelectedIds = filterIds.map((str) => {
    const match = str?.match(/\d+$/)
    return match ? match[0] : null
  })
  const selectedSubFilterId = selectedSubFilter?.id?.match(/\d+$/)

  const selectedFiltersId = selectedSubFilterId
    ? [...filtersSelectedIds, selectedSubFilterId[0]]
    : [...filtersSelectedIds]

  const filters = {
    association: checkedLevels,
    subcategory: selectedFiltersId,
  }

  const handleAssocLvlChange = (selectedItems) => {
    const keysOfSelectedItems = Object.keys(selectedItems)
    const allItems = {
      0: 'low',
      1: 'medium',
      2: 'high',
    }
    for (const reqKey of Object.keys(allItems)) {
      if (
        assocLvl[reqKey].checked !==
        keysOfSelectedItems.includes(allItems[reqKey])
      ) {
        setAssocStrength(allItems[reqKey])
      }
    }
  }

  return (
    <>
      {(!!selectedFilters.length || !!selectedFormats?.length) && trends && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!!selectedFormats?.length && (
            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Filtering by Formats:
              </Typography>
              {showMoreButton && selectedFormats.length > MAX_FORMATS_LENGTH ? (
                !showMoreFormats ? (
                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}
                  >
                    {selectedFormats
                      .slice(0, MAX_FORMATS_LENGTH)
                      .map((filter) => (
                        <Chip
                          key={filter.key}
                          label={filter.label}
                          variant="outlinedTag"
                          onDelete={() =>
                            handleRemoveSelectedFormat(filter.label)
                          }
                        />
                      ))}
                    <Chip
                      key={'show-more'}
                      label={`+${
                        selectedFormats.slice(MAX_FORMATS_LENGTH).length
                      } More`}
                      variant="outlinedTag"
                      onClick={() => setShowMoreFormats(true)}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}
                  >
                    {selectedFormats.map((filter) => (
                      <Chip
                        key={filter.key}
                        label={filter.label}
                        variant="outlinedTag"
                        onDelete={() =>
                          handleRemoveSelectedFormat(filter.label)
                        }
                      />
                    ))}
                    <Chip
                      key={'show-less'}
                      label={`Show Less`}
                      variant="outlinedTag"
                      onClick={() => setShowMoreFormats(false)}
                    />
                  </Box>
                )
              ) : (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {selectedFormats.map((filter) => (
                    <Chip
                      key={filter.key}
                      label={filter.label}
                      variant="outlinedTag"
                      onDelete={() => handleRemoveSelectedFormat(filter.label)}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
          {!!selectedFilters?.length && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    Filtering by Trends:
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {showAssocLvl && (
                    <MultiSelectCheckboxGroup
                      headerText="Association level of results"
                      allCheckboxes={['Low', 'Medium', 'High'].map(
                        (label, idx) => ({
                          label: label,
                          checked: assocLvl[idx].checked,
                        }),
                      )}
                      onChange={handleAssocLvlChange}
                    />
                  )}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {showMoreButton &&
                selectedFilters.length > MAX_TRENDS_LENGTH ? (
                  !showMoreTrends ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        mt: 1,
                        width: '80%',
                      }}
                    >
                      {selectedFilters
                        .slice(0, MAX_TRENDS_LENGTH)
                        .map((filter) => (
                          <Chip
                            key={filter.id || filter.key}
                            label={filter.name || filter.label}
                            variant="outlinedTag"
                            onDelete={() =>
                              handleRemoveSelectedTrend(
                                filter.id || filter.key,
                                filter.type,
                              )
                            }
                          />
                        ))}
                      <Chip
                        key={'show-more'}
                        label={`+${
                          selectedFilters.slice(MAX_TRENDS_LENGTH).length
                        } More`}
                        variant="outlinedTag"
                        onClick={() => setShowMoreTrends(true)}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        mt: 1,
                        width: '75%',
                      }}
                    >
                      {selectedFilters.map((filter) => (
                        <Chip
                          key={filter.id || filter.key}
                          label={filter.name || filter.label}
                          variant="outlinedTag"
                          onDelete={() =>
                            handleRemoveSelectedTrend(
                              filter.id || filter.key,
                              filter.type,
                            )
                          }
                        />
                      ))}
                      <Chip
                        key={'show-less'}
                        label={`Show Less`}
                        variant="outlinedTag"
                        onClick={() => setShowMoreTrends(false)}
                      />
                    </Box>
                  )
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      mt: 1,
                      width: '75%',
                    }}
                  >
                    {selectedFilters.map((filter) => (
                      <Chip
                        key={filter.id || filter.key}
                        label={filter.name || filter.label}
                        variant="outlinedTag"
                        onDelete={() =>
                          handleRemoveSelectedTrend(
                            filter.id || filter.key,
                            filter.type,
                          )
                        }
                      />
                    ))}
                  </Box>
                )}
                {showAddToFav &&
                  (!!selectedFilters?.length || selectedSubFilter) && (
                    <SaveAsFavoritePopUp
                      favoriteType={'ADVANCE_SEARCH'}
                      favoriteSectionType={'Other Favorites'}
                      additionalInfo={filters}
                      projectId={projectId}
                      trendType={lens}
                      productId={projectId + lens}
                      reDirectionUrl={
                        location.pathname +
                        '?' +
                        (paramsWithoutCtxId?.toString() || location.search)
                      }
                      breadcrumbs={`Foresight Engine, ${country} ${category}`}
                      selectedSubFilter={selectedSubFilter}
                      selectedFilters={selectedFilters}
                    />
                  )}
              </Box>
            </>
          )}
          {!hideSubcategoryChips && selectedSubFilter && (
            <Box mt={2}>
              <Typography variant="caption" color="textSecondary">
                {subFilterLabel}
              </Typography>
              <Box mt={1}>
                <Chip
                  key={selectedSubFilter.id}
                  label={selectedSubFilter.displayValue}
                  variant="outlinedTag"
                  onDelete={() =>
                    handleRemoveCategory(lens, selectedSubFilter.id)
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!isCS && <Divider />}
    </>
  )
}

SelectedFiltersTags.propTypes = {
  isChartView: PropTypes.bool,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}

SelectedFiltersTags.defaultProps = {
  isChartView: false,
  selectedFilters: [],
}

export default SelectedFiltersTags
