import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import SentimentDonutChart from '../SentimentDonutChart'

import ingredient from '../../../assets/mock/ingredient.json'
import { SentimentCardContainer, SentimentLabel } from './styles'

const SentimentCard = ({
  positiveSentiment,
  neutralSentiment,
  negativeSentiment,
  flippedSentimentCard,
  paperStyles,
  pieChartWidth,
}) => (
  <SentimentCardContainer
    sx={{
      ...paperStyles,
      flexDirection: flippedSentimentCard && 'row-reverse',
    }}
  >
    {/* TODO: Should remove below condition and make it generic after */}
    {flippedSentimentCard ? (
      <>
        <Box flex={1} sx={{ pb: 1 }}>
          <Typography variant="h4" color="textPrimary" sx={{ mb: 1 }}>
            Sentiment
          </Typography>
          <SentimentLabel sentiment="positive" value={positiveSentiment} />
          <SentimentLabel sentiment="neutral" value={neutralSentiment} />
          <SentimentLabel sentiment="negative" value={negativeSentiment} />
        </Box>
        <SentimentDonutChart
          data={[
            { value: positiveSentiment, label: 'Positive' },
            { value: negativeSentiment, label: 'Negative' },
            { value: neutralSentiment, label: 'Neutral' },
          ]}
          width={pieChartWidth}
        />
      </>
    ) : (
      <>
        <Box>
          <Typography variant="h3" color="textPrimary" sx={{ mb: 3 }}>
            Sentiment
          </Typography>
          <SentimentLabel sentiment="positive" value={positiveSentiment} />
          <SentimentLabel sentiment="neutral" value={neutralSentiment} />
          <SentimentLabel sentiment="negative" value={negativeSentiment} />
        </Box>
        <SentimentDonutChart
          data={[
            { value: positiveSentiment, label: 'Positive' },
            { value: negativeSentiment, label: 'Negative' },
            { value: neutralSentiment, label: 'Neutral' },
          ]}
        />
      </>
    )}
  </SentimentCardContainer>
)

SentimentCard.propTypes = {
  positiveSentiment: PropTypes.number.isRequired,
  neutralSentiment: PropTypes.number.isRequired,
  negativeSentiment: PropTypes.number.isRequired,
}

export default SentimentCard
