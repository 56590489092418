import { Link } from '@mui/material'
import { styled } from '@mui/system'

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'underLine',
  marginRight: '25px',
  fontSize: '1rem',
  '&:focus': {
    color: `${theme.palette.colors.hoverLink} !important`,
    textDecorationColor: `${theme.palette.colors.hoverLink} !important`,
    textDecorationLine: 'underline !important',
  },
  '&:hover': {
    color: `${theme.palette.black} !important`,
    textDecorationColor: 'none !important',
    textDecorationLine: 'none !important',
  },
}))
