import { ResetActions, 
    ProductNeedDetailActionTypes, 
    ProductNeedQuotesActionTypes } from '../actions/actionTypes'
  import { updateObject } from '../utility'
  
  const initialState = {
    productNeedDetails: null,
    productNeedQuotes: null,
    error: null,
    loading: false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case ResetActions.type[ResetActions.RESET_PRODUCT_NEEDS]:
        return updateObject(state, initialState)
      
      case ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_START]: 
        return updateObject(state, {
          loading: true,
        }
      )
      case ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_SUCCESS]: 
        return updateObject(state, {
          productNeedDetails : action.productNeed,
          error : false,
          loading: false,
        })
      case ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_FAILED]:
        return updateObject(state, {
          productNeedDetails : null,
          error : true,
          loading: false,
        })
  
      case ProductNeedQuotesActionTypes.type[ProductNeedQuotesActionTypes.FETCH_PRODUCT_NEED_QUOTES_SUCCESS]: 
        return updateObject(state, {
          productNeedQuotes : action.productNeedQuotes,
        })
      case ProductNeedQuotesActionTypes.type[ProductNeedQuotesActionTypes.FETCH_PRODUCT_NEED_QUOTES_FAILED]:
        return updateObject(state, {
          productNeedQuotes : null,
        })
      
      default:
        return state
    }
  }
  
  export default reducer
  