import React from 'react';
import styled from '@emotion/styled';

import {
  autocompleteClasses, Popper,
} from '@mui/material';


export const WIDTH = 412;

export const PopperComponent = styled(props => {
  const {
    disablePortal, anchorEl, open, ...other
  } = props;
  return <div {...other} />;
})(({ theme }) => ({
  [`&.${autocompleteClasses.popper}`]: {
    width: '100% !important',
  },
  [`& .${autocompleteClasses.paper}`]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    paddingRight: 2,
    background: 'white',
    position: 'absolute',
    zIndex: theme.zIndex.tooltip + 1,
    width: "352px",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight:'208px',
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.option}`]: {

    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

export const StyledPopper = styled(Popper)(({theme}) => ({
  width: WIDTH,
  padding: "15px 30px",
  backgroundColor:'white',
  top: 'calc(50% - 202px) !important',
  left: 'calc(50% - 200px) !important',
  zIndex: theme.zIndex.tooltip + 1,
  fontsize: '14px',
  '& .MuiChip-label': {
    fontSize: '14px !important',
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '14px !important',
  },
  '& .MuiAutocomplete-root': {
    maxHeight: '100px',
    overflowY: 'scroll',
  },
  '& .MuiOutlinedInput-root': {
    minHeight: "47px",
    paddingTop: "8px 5px !important",
    height: "inherit",
  },
  '& .MuiAutocomplete-root::-webkit-scrollbar': {
    width: theme.spacing(0.7),
  },

  '& .MuiAutocomplete-root::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '& .MuiAutocomplete-root::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));
