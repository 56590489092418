import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import ProductComparisonChartMui from '../../../components/Products/Comparison/ProductComparisonChartMui';
import DownloadButton from '../DownloadButton';
import { DatePickerTextField } from '../TrendGrowthCard/styles';
import { ReactComponent as EmptyChart } from '../../../assets/emptyChart.svg';

const TrendOverCard = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const validateTrends = (trends) => {
    if(trends.length > 1){
      let {_trendProjectId: initId, _trendName: initTrendPhase} = trends[0]
      for(const {_trendProjectId, _trendName} of trends){
        if(initId !== _trendProjectId || initTrendPhase !== _trendName){
          return false
        }
      }
    }
    return true
  }
  return (
    <Paper
      sx={{
        boxShadow: '0px 4px 12px 0px #272B3108',
      }}
    >
      {/*
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="h3" mr={2}>
            Trend Over
          </Typography>
          <DatePicker
            value={startDate}
            components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
            onChange={newValue => setStartDate(newValue)}
            renderInput={params => <DatePickerTextField {...params} size="large" />}
          />
          <Box
            sx={{
              height: 2,
              width: 8,
              bgcolor: 'icon',
              borderRadius: 4,
            }}
          />
          <DatePicker
            value={endDate}
            components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
            onChange={newValue => setEndDate(newValue)}
            renderInput={params => <DatePickerTextField {...params} size="large" />}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <DownloadButton title="Download Chart" />
        </Box>
      </Box>
      <Divider /> */}
      <Box p={2}>
      { ((Array.isArray(props.trends) && props.trends.length )
      ? 
      (validateTrends(props.trends) ?
          <ProductComparisonChartMui products={props.trends}/>
        : (<Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: t => t.palette.grey[100], 
            height: 307, 
          }}>
            <Box sx={{
              height: 'fit-content',
              p: 2
            }}>
              <Stack 
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <EmptyChart width={120} height={156}/>
                <Typography variant="h4" align="center" color="textSecondary">
                  Trend graph is only visible when you are comparing trend within same Country, Category and Classification phase.
                </Typography>
              </Stack>
            </Box>
          </Box>
        ))
        : <Box sx={{ bgcolor: t => t.palette.grey[100], height: 307 }} /> )
      }
      </Box>
    </Paper>
  );
};

export default TrendOverCard;
