import { styled } from '@mui/system'
import { List } from '@mui/material'

export const UnorderedListStyled = styled(List)(({ theme }) => ({
  marginTop: 4,
  padding: 0,
  '& .MuiListItem-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 6,
    '> *': {
      flex: 1,
    },
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '8px',
      marginTop: '4px',
      height: '8px',
      width: '8px',
    },
    alignItems: 'start',
  },
}))
