import NetworkUtil from '../../services/networking/NetworkingUtil'
import axios from '../../services/networking/axios'
import { getBaseURL, getRequestOptions } from './migrationHelper'
const BASE_URL = getBaseURL()
const getNewUiToken = async () => {
  const request = NetworkUtil.fetchRedirectToken()
  const authKey = localStorage.getItem('aiPaletteUserAuthKey')
  const options = getRequestOptions(authKey)

  try {
    const response = await axios.get(request, options)
    const authKey = response.data.token
    const tokenUrl = `${BASE_URL}users/validate/token`
    const tokenValidateOptions = getRequestOptions(authKey)
    const tokenResponse = await axios.get(tokenUrl, tokenValidateOptions)
    return tokenResponse.data.access_token
  } catch (error) {
    console.error('Error fetching new UI token:', error)
    return null
  }
}
export default getNewUiToken
