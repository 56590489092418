import React from "react";
import { connect } from "react-redux";

import { notifications as notificationConst } from "../../const";
import { dequeNotification } from "../../store/actions/screenWinnerActions";

import SWNotifications from "./SWNotifications";

import successNotif from '../../assets/images/screenWinner/sw-success.svg';
import infoNotif from '../../assets/images/screenWinner/sw-info.svg';
import warningNotif from '../../assets/images/screenWinner/sw-warning.svg';
import errorNotif from '../../assets/images/screenWinner/sw-error.svg';

const SWNotificationsContainer = ({ notifications, dequeNotification }) => {
  const { SUCCESS, ERROR, INFO, WARNING } = notificationConst;
  const resolveNotification = ({ message, type, id }) => {
    let img = "",
      style = "";
    switch (type) {
      case SUCCESS:
        img = successNotif;
        style = "success";
        break;
      case INFO:
        img = infoNotif;
        style = "info";
        break;
      case WARNING:
        img = warningNotif;
        style = "warning";
        break;
      case ERROR:
        img = errorNotif;
        style = "error";
        break;
    }
    return (
      <SWNotifications
        key={id}
        img={img}
        imgAlt={type}
        style={style}
        message={message}
        onClear={() => dequeNotification(id)}
      />
    );
  };

  return notifications.map((notification) => resolveNotification(notification));
};

const mapStateToProps = (state) => {
  const { notifications } = state.screenWinner
  return {
    notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dequeNotification: (id) => dispatch(dequeNotification(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SWNotificationsContainer);
