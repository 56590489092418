import React from 'react';
import {
  Divider, Grid, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import DriverItem from '../DriverItem';
import relatedThemes from '../../../assets/mock/related-themes.json';
import DriverCardContainer from './styles';

const DriverCard = (props) => (
  <DriverCardContainer>
    <Box>
      <Typography variant="h3">
        { props.category }
      </Typography>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        pt={0.5}
        pb={2}
      >
        Topics associated with this particular topic based on consumer conversations
      </Typography>
      <Box
        sx={{
          mx: -2,
          pb: 2,
        }}
      >
        <Divider />
      </Box>
    </Box>
    <Grid container spacing={1} sx={props.expand ? {height: 'fit-content !important', overflowY: 'auto !important'} : {}}>
      {props.needsList.map(el => ( parseFloat(el.score).toFixed(2) > 0 ?
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <DriverItem
            categoryPreviewNeed={props.categoryPreviewNeed}
            urllens={props.urllens}
            productid={props.productid}
            projectid={props.projectid}
            title={el.keyword}
            icon={el.iconURI}
            percent={parseFloat(el.score)}
            needid={el.mappingID}
          />
        </Grid>
        : null
      ))}
    </Grid>
  </DriverCardContainer>
);

export default DriverCard;
