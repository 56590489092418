import { Box, TextField, Slider } from '@mui/material'
import styled from '@emotion/styled'

// TODO: THEME COLORS
export const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '0px 0px 1px 0.75px rgba(27, 59, 119, 0.3)',
    border: '1px solid rgba(5, 175, 241, 1)',
    '&:active, &:hover, &:focus': {
      boxShadow: '0px 0px 1px 0.75px rgba(27, 59, 119, 0.3)',
    },
  },
  '& .MuiSlider-rail': {
    background: 'rgba(107, 107, 107, 0.29)',
  },
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    border: '1px solid rgba(5, 175, 241, 1)',
    borderRadius: '40px',
    padding: 0,
  },
  '.MuiInputBase-input': {
    padding: '6px 0px',
    textAlign: 'center',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  'input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}))

const BORDER_ARROW = 8
const BORDER_ARROW_BOTTOM_OFFSET = -8

export const ToolTipArrow = () => (
  <Box
    sx={{
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: `${BORDER_ARROW_BOTTOM_OFFSET}px`,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: 0,
        borderTop: `${BORDER_ARROW}px solid rgba(5, 175, 241, 1)`,
        borderLeft: `${BORDER_ARROW}px solid transparent`,
        borderRight: `${BORDER_ARROW}px solid transparent`,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: `${BORDER_ARROW_BOTTOM_OFFSET + 2}px`,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: 0,
        borderTop: `${BORDER_ARROW - 1}px solid white`,
        borderLeft: `${BORDER_ARROW - 1}px solid transparent`,
        borderRight: `${BORDER_ARROW - 1}px solid transparent`,
      },
    }}
  />
)
