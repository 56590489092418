import React from 'react'

import Chart from 'react-apexcharts'

const FavoriteTrendPillarChart = (props) => {
  const { graphData } = props

  const getGraphReqData = (trendData) => {
    const dataObj = [
      {
        name: '2 Yr CAGR',
        data: [],
        type: 'line',
      },
    ]
    const xAxisLabels = []
    let key

    for (const element in trendData) {
      const value = trendData[element].two_year_cagr
      key = trendData[element].period
      dataObj[0].data.push(value)
      xAxisLabels.push(key)
    }

    var options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        type: 'line',
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: '2 Yr CAGR',
          data: dataObj[0].data,
        },
      ],
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        show: true,
        tooltip: {
          enabled: false,
        },
        categories: xAxisLabels,
        labels: {
          show: true,
          style: {
            fontSize: '10px',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          formatter: function(value) {
            return Number(Number(value).toFixed(2)) + '%'
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#ddd',
        strokeDashArray: 6,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
    }
    return {
      dataObj: dataObj,
      options: options,
    }
  }

  return (
    <Chart
      options={getGraphReqData(graphData).options}
      series={getGraphReqData(graphData).dataObj}
      type="line"
      height={180}
    />
  )
}
export default FavoriteTrendPillarChart
