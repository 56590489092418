import React, { useEffect, useState } from 'react'
import SingleSelect from '../../shared/components/SingleSelect'
import {
  Box,
  Typography,
  Button,
  TextField,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tabs,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Tab,
  Grid,
  Checkbox,
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import StyledTextFeild from '../../shared/components/StyledTextFeild'
import { DatePicker } from '@mui/x-date-pickers'
import DeleteIcon from '@mui/icons-material/Delete'
import { DatePickerTextField } from '../DateRangePickerChart/styles'
import GenericTableHead from '../../shared/components/GenericTableComponent/GenericTableHead'
import axios from './../../services/networking/axios'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import addYears from 'date-fns/addYears'
import moment from 'moment'
import addDays from 'date-fns/addDays'
const minDate = addDays(new Date(), +1)
const GenericTableCell = ({ rowData, tableHeadCells }) => {
  const res = tableHeadCells.map(({ id, func }) => {
    if (id === 'options') {
      return func ? func(rowData) : rowData
    } else if (rowData[id] != null) {
      return func ? func(rowData[id]) : rowData[id]
    }
  })
  return res
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const dummyData = []

const clientDataLabel = [
  {
    label: 'Account Type: ',
    id: 'accountType',
  },
  { label: 'No. Of Users Added: ', id: 'noOfUsersAdded' },
  { label: 'No. Of Users Permitted: ', id: 'noOfUsersPermitted' },
  { label: 'Start Date: ', id: 'startDate' },
  { label: 'End Date: ', id: 'endDate' },
]

const AddUser = ({
  selectedClient,
  userList,
  handleBack,
  setActiveStep,
  setFillUserDetailView,
  setAddUserView,
  clientData,
}) => {
  const [addedUsers, setAddedUsers] = useState([])
  const [selectedDate, setSelectedDate] = useState(minDate)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('clientName')
  const [isAddingBulk, setIsAddingBulk] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [bulkUploadData, setBulkUploadData] = useState()
  const [bulkUploadIndex, setBulkUploadIndex] = useState(0)
  const [maxIndexReached, setMaxIndexReached] = useState(0)
  const [usersLimitError, setUsersLimitError] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [checkedStatus, setCheckedStatus] = useState(true)

  const userFormValidation = () => {
    const firstNamePattern = /^[a-zA-Z\-]+$/ //eslint-disable-line
    const isErrorInFirstName = !firstNamePattern.test(firstName)
    if (isErrorInFirstName) setFirstNameError('First Name format do not match')
    const emailPattern = /^\S+@\S+\.\S+$/ //eslint-disable-line
    const emailMatchError =
      (userList && userList.find((data) => data.email === email)) ||
      addedUsers.find((user) => user.emailId === email)
    if (emailMatchError) setEmailError('E-mail match with already added user')
    const isErrorInEmail = !emailPattern.test(email)
    if (isErrorInEmail) setEmailError('Email format do not match')
    const isErrorInDate =
      new Date(selectedDate).getTime() >
      new Date(selectedClient?.endDate).getTime()
    if (isErrorInDate)
      setDateError('Date added is more than end date of client')
    const isErrorInvalid = !moment(selectedDate).isValid()
    if (isErrorInvalid) setDateError('Date format is not matching')
    const isUsersMoreThanAllowed =
      clientData.noOfUsersPermitted <=
      clientData.noOfUsersAdded + addedUsers.length
    if (isUsersMoreThanAllowed) setUsersLimitError('User number exceeded.')
    const formSubmissionError =
      isErrorInFirstName ||
      isErrorInEmail ||
      emailMatchError ||
      isErrorInDate ||
      isUsersMoreThanAllowed ||
      isErrorInvalid
    return formSubmissionError
  }

  const onBulkSubmit = () => {}

  const userAdditionFormSubmitted = (e) => {
    e.preventDefault()

    const isFormValidateError = userFormValidation()
    if (!isFormValidateError) !isAddingBulk ? onSubmit() : onBulkSubmit()
  }

  const removeUserHandler = (data) => {
    const removedUserList = addedUsers.filter(
      (user) => user.emailId !== data.emailId,
    )
    setAddedUsers(removedUserList)
  }

  const bulkUploadBegin = (index) => {
    const user = bulkUploadData[index]
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
    setSelectedDate(user.endDate)
  }

  // useEffect(() => {
  //   bulkUploadBegin(0)
  // }, [])

  const UsersListHeadCells = [
    {
      id: 'Name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'emailId',
      numeric: false,
      disablePadding: false,
      label: 'Email Id',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'endDate',
      numeric: true,
      disablePadding: false,
      label: 'End Date',
      sorting: false,
      func: (data) => (
        <TableCell>{moment(data).format('DD-MMM-YYYY')}</TableCell>
      ),
    },
    {
      id: 'options',
      numeric: true,
      disablePadding: false,
      label: ' ',
      sorting: false,
      func: (data) => (
        <TableCell onClick={() => removeUserHandler(data)}>
          <DeleteIcon
            sx={{
              color: ({ palette }) => palette.icon,
            }}
          />
        </TableCell>
      ),
    },
  ]

  const handleRequestSort = (property) => () => {
    // const isSameOrderByAsc = orderBy === property && order === 'asc'
    // setOrder(isSameOrderByAsc ? 'desc' : 'asc')
    // setOrderBy(property)
    // setClientData(finalSorting(isSameOrderByAsc ? 'desc' : 'asc', property))
  }

  const resetError = () => {
    setFirstNameError('')
    setDateError('')
    setEmailError('')
    setUsersLimitError('')
  }

  const onSubmit = () => {
    const data = {
      clientId: selectedClient.clientId,
      Name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      emailId: email,
      endDate: selectedDate,
      options: true,
    }
    setAddedUsers([...addedUsers, data])
    setFirstName('')
    setLastName('')
    setEmail('')
    resetError()
  }

  const saveHandler = () => {
    const modifiedKeyData = addedUsers.map((user) => {
      const userInfo = {
        client_id: user.clientId,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.emailId,
        user_expiration_date: `${moment(user.endDate).format(
          'YYYY-MM-DDTHH:mm:ss',
        )}.0000Z`,
      }
      return userInfo
    })
    const request = {
      users: modifiedKeyData,
    }
    axios
      .post(NetworkingUtil.userManagement(), request)
      .then((res) => {
        setFillUserDetailView(false)
        setAddUserView(false)
      })
      .catch()

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBulkAdd = () => {
    setIsAddingBulk(true)
  }

  const updateUser = () => {
    const allUsers = [...bulkUploadData]
    const updatedUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      date: selectedDate,
    }
    allUsers[bulkUploadIndex] = updatedUser
    setBulkUploadData(allUsers)
  }

  const handleLeftClick = () => {
    resetError()
    if (!userFormValidation()) {
      updateUser()
    }
    if (bulkUploadIndex > 0) {
      bulkUploadBegin(bulkUploadIndex - 1)

      setBulkUploadIndex(bulkUploadIndex - 1)
    }
  }

  const handleRightClick = () => {
    resetError()
    if (!userFormValidation()) {
      updateUser()
    }
    if (bulkUploadIndex < dummyData.length) {
      bulkUploadBegin(bulkUploadIndex + 1)
      if (maxIndexReached < bulkUploadIndex + 1)
        setMaxIndexReached(bulkUploadIndex + 1)

      setBulkUploadIndex(bulkUploadIndex + 1)
    }
  }

  const handleCancel = () => {
    setFillUserDetailView(false)
    setAddUserView(false)
  }

  const handleChangeSameClientDate = (e) => {
    if (e.target.checked == true) {
      setCheckedStatus(true)
      setSelectedDate(clientData.endDate)
    }
  }

  const handleDateChange = (e) => {
    setSelectedDate(e)
    setCheckedStatus(false)
  }

  useEffect(() => {
    setSelectedDate(clientData.endDate)
  }, [])

  return (
    <Box>
      <Box sx={{ display: 'flex', padding: '10px' }}>
        <Box sx={{ width: '50%' }}>
          <form sx={{ width: '50%' }} onSubmit={userAdditionFormSubmitted}>
            <Box>
              <Typography pt={2} variant="subtitle1">
                First Name:{' '}
              </Typography>
              <TextField
                sx={{ width: '60% !important' }}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                variant="outlined"
                size="large"
                placeholder="First Name"
                fullWidth
              />
              {firstNameError && (
                <Typography
                  variant="error"
                  sx={{ display: 'block', marginTop: '5px' }}
                >
                  {firstNameError}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography pt={2} variant="subtitle1">
                Last Name:{' '}
              </Typography>
              <TextField
                sx={{ width: '60% !important' }}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
                variant="outlined"
                size="large"
                placeholder="Last Name"
                fullWidth
              />
            </Box>
            <Box>
              <Typography pt={2} variant="subtitle1">
                Email:{' '}
              </Typography>
              <TextField
                sx={{ width: '60% !important' }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                variant="outlined"
                size="large"
                placeholder="Email"
                fullWidth
              />
              {emailError && (
                <Typography
                  variant="error"
                  sx={{ display: 'block', marginTop: '5px' }}
                >
                  {emailError}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'end' }}>
              <Box>
                <Typography pt={2} variant="subtitle1">
                  End Date:
                </Typography>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <DatePickerTextField {...params} size="large" />
                  )}
                  minDate={minDate}
                  maxDate={new Date(selectedClient?.endDate)}
                />
                {dateError && (
                  <Typography
                    variant="error"
                    sx={{ display: 'block', marginTop: '5px' }}
                  >
                    {dateError}
                  </Typography>
                )}
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChangeSameClientDate}
                        defaultChecked={true}
                        checked={checkedStatus}
                      />
                    }
                    label="Same as client end date"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box>
              {usersLimitError && (
                <Typography variant="error">{usersLimitError}</Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                paddingTop: '20px',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {isAddingBulk && (
                <>
                  <Typography variant="subtitle2">
                    {bulkUploadIndex + 1}/5
                  </Typography>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={!bulkUploadIndex}
                    onClick={handleLeftClick}
                  >
                    Left
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={bulkUploadIndex === dummyData.length - 1}
                    onClick={handleRightClick}
                  >
                    Right
                  </Button>
                </>
              )}
              {/* <Button
                variant="contained"
                onClick={handleBulkAdd}
                disabled={isAddingBulk}
              >
                Add in Bulk*
              </Button> */}
              <Button
                type="submit"
                variant="contained"
                disabled={
                  isAddingBulk && maxIndexReached < bulkUploadData.length
                }
              >
                {isAddingBulk ? 'Add All' : 'Add'}
              </Button>
            </Box>
          </form>
        </Box>
        <Box sx={{ border: '1px solid black', padding: '30px', width: '50%' }}>
          <Typography variant="subtitle1" pb={2}>
            Account Added ({addedUsers?.length || '0'}/
            {clientData.noOfUsersPermitted - clientData.noOfUsersAdded})
          </Typography>
          {addedUsers?.length ? (
            <TableContainer component={Paper} sx={{ height: '300px' }}>
              <Table stickyHeader aria-label="simple table">
                <GenericTableHead
                  headCells={UsersListHeadCells}
                  orderBy={orderBy}
                  order={order}
                  handleRequestSort={handleRequestSort}
                />
                {addedUsers?.length ? (
                  <TableBody>
                    {addedUsers
                      .sort((a, b) => getComparator(order, orderBy))
                      .map((row) => (
                        <TableRow
                          data-testId="tableRow"
                          sx={{ cursor: 'pointer', verticalAlign: 'baseline' }}
                        >
                          <>
                            <GenericTableCell
                              rowData={row}
                              tableHeadCells={UsersListHeadCells}
                            />
                          </>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                      width: '100%',
                    }}
                  >
                    No Result Found
                  </Typography>
                )}
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1">
                Please add Users to Preview.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '30px',
        }}
      >
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" sx={{ marginRight: '30px' }}>
            Download
          </Button>
          <Typography>Template for Bulk upload</Typography>
        </Box> */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Button
            variant="contained"
            sx={{ marginRight: '30px' }}
            onClick={handleBack}
          >
            Back
          </Button>

          <Button
            variant="outlined"
            sx={{ marginRight: '30px' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={saveHandler}
            disabled={!addedUsers.length}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddUser
