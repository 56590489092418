import { Box } from '@mui/material';
import styled from '@emotion/styled'
export const StyledBox = styled(Box)(
    ({ leftTooltip, topTooltip, theme }) => ({
      position: 'absolute',
      paddingLeft: '5px',
      paddingRight: '5px',
      zIndex: 1000000,
      boxShadow: `0px 0px 700px 0px ${theme.palette.shadow.light}`,
      '& .MuiPaper-root': {
        '&::before': {
          position: 'absolute',
          margin: ' 0 auto',
          marginTop: '8px',
          content: '"\\2610"',
          fontSize: 24,
          left: '-20px',
          top: topTooltip ? '89%' : '-25px',
          opacity: 0,
        },
        '&::after': {
          position: 'absolute',
          margin: ' 0 auto',
          marginTop: '8px',
          content: '"\\2610"',
          width: '1em',
          display: 'inline-block',
          fontSize: 24,
          left: '100%',
          top: leftTooltip && topTooltip ? '89%' : '-25px',
          opacity: 0,
        },
      },
    })
  )