import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'

const TabPanel = props => {
  const {
    children, value, index, innerBoxProps, ...other
  } = props

  return (
    <Box component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box {...innerBoxProps}>
          {children}
        </Box>
      )}
    </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default TabPanel
