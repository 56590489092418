import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { saveStore } from './store/middleware/downloadSection'
import { isProduction, isStaging } from './store/utility.js'
import { ResetActions } from './store/actions/actionTypes'
import appReducer from './store/reducers/index'

const composeEnhancers =
  isProduction() || isStaging()
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducers = (state, action) => {
  if (action.type === ResetActions.type[ResetActions.RESET_APP]) {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(thunk, saveStore)),
)

export default store
