import { Grid } from '@mui/material';
import { styled } from '@mui/system';

export default styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  paddingBottom: theme.spacing(1),
  height: 420,
  alignItems: 'stretch',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: 4,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  },
}));

