import { Typography, Tab, Tabs } from '@mui/material'
import React from 'react'

const GenericTab = ({
  tabList,
  selectedTab,
  handleTabSelection,
  component,
}) => {
  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: ({ palette }) => palette.primary.main,
          },
        }}
        value={selectedTab}
        onChange={(e, v) => {
          handleTabSelection(v)
        }}
        aria-label="global search tab"
      >
        {tabList.map(({ id, label, labelQuantity }) => {
          return (
            <Tab
              key={`${id}-${label}-tab`}
              sx={{
                padding: '0px !important',
                marginRight: '5px !important',
                height: '47px',
                minWidth: '60px',
                '& .MuiTab-labelIcon': {
                  padding: '0px !important',
                },
              }}
              icon={
                (labelQuantity || labelQuantity === 0) && (
                  <Typography
                    sx={{
                      fontSize: '10px',
                      borderRadius: '3px',
                      background: ({ palette }) => palette.grey[50],
                      color: ({ palette }) => palette.grey[900],
                    }}
                    px={1}
                    py={0.5}
                  >
                    {labelQuantity}
                  </Typography>
                )
              }
              iconPosition="end"
              label={
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: ({ palette }) =>
                      id === selectedTab
                        ? palette.text.primary
                        : palette.text.secondary,
                  }}
                >
                  {label}
                </Typography>
              }
            />
          )
        })}
      </Tabs>

      {component}
    </>
  )
}

export default GenericTab
