import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

import SWBreadCrumbs from './SWBreadCrumbs'

import { fetchProjectDetails } from '../../store/actions/screenWinnerActions'
import FixedSnackbar from '../FixedSnackbar/FixedSnackbar'

const MIN_GOOD_TO_HAVE_CNCPTS = 15

const SWBreadCrumbsContainer = ({
  location,
  fetchProjectDetails,
  conceptsList,
  match,
  projectName,
  screenWinnerSubscription,
}) => {
  const [showInfo, setShowInfo] = useState(
    Boolean(conceptsList?.length < MIN_GOOD_TO_HAVE_CNCPTS),
  )
  const { pathname } = location
  let { projectId, query } = match.params
  if (!projectId) {
    projectId = (/\d*(?=\/search-results\/)/.exec(pathname) || [''])[0]
  }
  if (!query) {
    query = pathname.slice(pathname.search('/search-results/') + 16)
  }
  const breadCrumbs = []
  if (pathname.includes('/screen-winner')) {
    if (pathname.includes('/screen-winner')) {
      breadCrumbs.push({ name: 'Projects', route: '/screen-winner' })
    }

    if (pathname.includes('/projects')) {
      breadCrumbs.push({
        name: projectName,
        route: `/screen-winner/projects/${projectId}/concepts`,
      })
    }

    if (pathname.includes('/screened-results')) {
      breadCrumbs.push({
        name: 'Screened Results',
        route: `/screen-winner/projects/${projectId}/screened-results`,
      })
    }
    if (pathname.includes('/faq')) {
      breadCrumbs.push({ name: 'FAQs', route: '/screen-winner/faq' })
    }
    if (pathname.includes('/search-results')) {
      if (pathname.includes('/projects')) {
        breadCrumbs.push({
          name: 'Searched Results',
          route: `/screen-winner/projects/${projectId}/search-results/${query}`,
        })
      } else {
        breadCrumbs.push({
          name: 'Searched Results',
          route: `/screen-winner/search-results/${query}`,
        })
      }
    }
  } else {
    if (pathname.includes('/')) {
      breadCrumbs.push({ name: 'Foresight Engine', route: '/mui/main-system' })
    }
    if (pathname.includes('/faq')) {
      breadCrumbs.push({ name: 'FAQs', route: '/faq' })
    }
  }
  useEffect(() => {
    if (projectId && screenWinnerSubscription) {
      fetchProjectDetails(projectId)
    }
  }, [projectId, screenWinnerSubscription])

  useEffect(() => {
    if (pathname.includes('/screened-results')) {
      setShowInfo(
        Boolean(conceptsList?.length) &&
          Boolean(conceptsList?.length < MIN_GOOD_TO_HAVE_CNCPTS),
      )
    }
  }, [pathname, conceptsList])

  const toasterMessage = (
    <>
      You have added {conceptsList?.length} concepts, this will yield
      directional results. To get accurately
      <br />
      data-backed results, please screen 15 or more concepts
    </>
  )

  return (
    breadCrumbs.length > 1 && (
      <div className="sw-bread-crumb__section">
        <SWBreadCrumbs breadCrumbs={breadCrumbs} />
        {pathname.includes('/screened-results') && showInfo && (
          <FixedSnackbar
            preIcon={
              <InfoIcon
                fontSize="small"
                sx={{ color: ({ palette }) => palette.warning.light }}
              />
            }
            postIcon={<CloseIcon fontSize="small" color="action" />}
            message={toasterMessage}
            postIconAction={() => setShowInfo(false)}
            messageProps={{ variant: 'subtitle2' }}
            containerSx={{
              px: 1,
              gap: 1,
            }}
          />
        )}
      </div>
    )
  )
}

const mapStateToProps = (state) => {
  const {
    breadCrumbs,
    conceptsList,
    projectDetails,
    subscribed,
  } = state.screenWinner
  return {
    screenWinnerSubscription: subscribed,
    conceptsList,
    breadCrumbs,
    projectName: (projectDetails && projectDetails.title) || '',
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectDetails: (prjId) => dispatch(fetchProjectDetails(prjId)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SWBreadCrumbsContainer),
)
