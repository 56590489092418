import {
  ProjectListViewActions,
  ResetActions,
  NeedCategoriesViewActions,
  AdvancedSearch,
} from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'
import { TrendTypes, dateRange } from '../../const'
import moment from 'moment'

const initialState = {
  filterAssocLvlTrends: null,
  subscriptionDetails: null,
  assocLevl: [
    {
      id: 'low',
      name: 'low',
      checked: true,
      displayValue: 'low',
      value: 'low',
      labelFor: 'low',
    },
    {
      id: 'medium',
      name: 'medium',
      checked: true,
      displayValue: 'medium',
      value: 'medium',
      labelFor: 'medium',
    },
    {
      id: 'high',
      name: 'high',
      checked: true,
      displayValue: 'high',
      value: 'high',
      labelFor: 'high',
    },
  ],
  projectList: null,
  productList: null,
  themeList: null,
  wsoGraphData: null,
  featuresDetails: null,
  flattenedProducts: [],
  needsList: null,
  error: null,
  dataPoints: 0,
  requestStartTime: null,
  shouldDelayUser: false,
  userWaitTime: 0,
  totalWaitTime: 3000,
  needGrowthData: [],
  needCategories: null,
  need_categories_error: false,
  trendsPhasesData: {
    graphPoints: [],
    midYPont: 0,
    maxYPoint: 100,
    sideX: 0,
    otherDetails: {
      dataPoints: 0,
    },
  },
  flags: {
    EmergingTrends: false,
    TrendingThemes: false,
    GrowingTrends: false,
    MatureTrends: false,
    ConsumerNeeds: false,
    DormantTrends: false,
    FadingTrends: false,
    DecliningTrends: false,
  },
  trendTypes: false,
  trendDates: {
    start: dateRange.default.start,
    end: dateRange.default.end,
  },
  allTrendsList: null,
  filteredTrends: null,
  filteredTrendsList: null,
  savedAdvancedSearchId: -1,
  advSearchFilters: -10,
  ctxSrchMapping: {
    error: '',
    ingredient: null,
    product: null,
    theme: null,
  },
}

/**
 * Chart 1 . List of trends and their products.
 * @param state
 * @param action
 */

const getUpdatedState = (state, action, projectList, trendsKey) => {
  const { end: selectDateEnd, start: selectDateStart } = action.date
    ? action.date
    : { start: state.trendDates.start, end: state.trendDates.end }

  let delayUser = false
  let waitTime = 0
  if (state.requestStartTime) {
    const currentTime = new Date().getTime()
    const timeDiff = currentTime - state.requestStartTime
    if (timeDiff < state.totalWaitTime) {
      const timeDiff = currentTime - state.requestStartTime
      if (action.projectList && state.needsList) {
        waitTime = state.totalWaitTime - timeDiff
        delayUser = true
      }
    }
  }
  var dataPoints = 0
  if ('data_points' in projectList) {
    dataPoints = projectList.data_points
  }

  if (projectList && projectList.trends && projectList.trends.length) {
    for (const trend of projectList.trends) {
      // Rank according to mom
      trend.allTrendResults = trend.allTrendResults
        // eslint-disable-next-line
        .sort((trend1, trend2) => trend2['mom_growth'] - trend1['mom_growth'])
        .map((trend, index) => ({ ...trend, momRank: index + 1 }))
      // Rank according to yoy
      trend.allTrendResults = trend.allTrendResults
        // eslint-disable-next-line
        .sort((trend1, trend2) => trend2['yoy_growth'] - trend1['yoy_growth'])
        .map((trend, index) => ({ ...trend, yoyRank: index + 1 }))
      // Rank according to volume
      trend.allTrendResults = trend.allTrendResults
        .sort((trend1, trend2) => trend2.volume - trend1.volume)
        .map((trend, index) => ({ ...trend, volumeRank: index + 1 }))
      // Rank according to score
      if (
        trend.type.indexOf('Fading') !== -1 ||
        trend.type.indexOf('Declining') !== -1
      ) {
        trend.allTrendResults = trend.allTrendResults
          .sort((trend1, trend2) => {
            if (trend1.score === trend2.score) {
              return trend1.name.localeCompare(trend2.name)
            }
            return trend1.score - trend2.score
          })
          .map((trend, index) => ({ ...trend, scoreRank: index + 1 }))
      } else {
        trend.allTrendResults = trend.allTrendResults
          .sort((trend1, trend2) => {
            if (trend1.score === trend2.score) {
              return trend1.name.localeCompare(trend2.name)
            }
            return trend2.score - trend1.score
          })
          .map((trend, index) => ({ ...trend, scoreRank: index + 1 }))
      }
    }
  }

  const filteredTrendsList = filterTrendsData(
    { ...state, [trendsKey]: projectList },
    state.filteredTrends,
  )
  const filterAssocLvlTrendsData = filterAssocLvlTrendsFunc(
    filteredTrendsList,
    state.assocLevl,
    {
      projectList: state.projectList,
      productList: state.productList,
      themeList: state.themeList,
      [trendsKey]: projectList,
    },
  )

  const updatedProjectList = {
    ...projectList,
    projectVariant: projectList.project_variant,
  }

  delete updatedProjectList.project_variant

  const updatedState = {
    filterAssocLvlTrends: filterAssocLvlTrendsData,
    [trendsKey]: updatedProjectList,
    error: false,
    dataPoints: dataPoints,
    shouldDelayUser: delayUser,
    userWaitTime: waitTime,
    filteredTrendsList,
  }

  return updatedState
}

const projectListRecieved = (state, action) => {
  const updatedState = getUpdatedState(
    state,
    action,
    action.projectList,
    'projectList',
  )
  return updateObject(state, updatedState)
}
/**
 * Chart 2. Get needs
 * @param state
 * @param action
 */
const projectListNeedsRecieved = (state, action) => {
  let delayUser = false
  let waitTime = 0
  if (state.requestStartTime) {
    const currentTime = new Date().getTime()
    const timeDiff = currentTime - state.requestStartTime
    if (timeDiff < state.totalWaitTime) {
      const timeDiff = currentTime - state.requestStartTime
      if (state.projectList && action.needsList) {
        waitTime = state.totalWaitTime - timeDiff
        delayUser = true
      }
    }
  }
  const updatedState = {
    needsList: action.needsList,
    error: false,
    shouldDelayUser: delayUser,
    userWaitTime: waitTime,
  }
  return updateObject(state, updatedState)
}

const wsoDataReceived = (state, action) => {
  const updatedState = {
    wsoGraphData: action.wsoGraphData,
    error: false,
  }
  return updateObject(state, updatedState)
}

const featuresDetailsReceived = (state, action) => {
  const updatedState = {
    featuresDetails: action.featuresDetails,
    error: false,
  }
  return updateObject(state, updatedState)
}

const productListRecieved = (state, action) => {
  const updatedState = getUpdatedState(
    state,
    action,
    action.productList,
    'productList',
  )
  return updateObject(state, updatedState)
}

const themeListRecieved = (state, action) => {
  const updatedState = getUpdatedState(
    state,
    action,
    action.themeList,
    'themeList',
  )
  return updateObject(state, updatedState)
}

const subscriptionDetailsReceived = (state, action) => {
  const updatedState = {
    subscriptionDetails: action.subscriptionDetails,
  }
  return updateObject(state, updatedState)
}

const ctxSrchDataFormatter = (themeCtx) => {
  return Object.keys(themeCtx).reduce(
    (acc, curr) => ({ ...acc, [curr.toUpperCase()]: themeCtx[curr] }),
    {},
  )
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AdvancedSearch.type[AdvancedSearch.VIEW_ADVANCED_SEARCH_SUCCESS]:
      return updateObject(state, {
        advSearchFilters: action.advSearchFilters.data.savedUserQueries,
      })
    case AdvancedSearch.type[AdvancedSearch.VIEW_ADVANCED_SEARCH_FAILED]:
      return updateObject(state, { advSearchFilters: null })
    case AdvancedSearch.type[AdvancedSearch.SAVE_ADVANCED_SEARCH_SUCCESS]:
      return updateObject(state, {
        savedAdvancedSearchId: action.filteredTrends,
      })
    case AdvancedSearch.type[AdvancedSearch.SAVE_ADVANCED_SEARCH_FAILED]:
      return updateObject(state, { savedAdvancedSearchId: null })
    case ResetActions.type[ResetActions.RESET_PROJECT_DETAILS]:
      return updateObject(state, initialState)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PROJECT_LIST_SUCCESS
    ]:
      return projectListRecieved(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PROJECT_LIST_FAIL
    ]:
      return updateObject(state, {
        error: true,
        projectList: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PRODUCT_LIST_SUCCESS
    ]:
      return productListRecieved(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PRODUCT_LIST_FAIL
    ]:
      return updateObject(state, {
        error: true,
        productList: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_THEME_LIST_SUCCESS
    ]:
      return themeListRecieved(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_THEME_LIST_FAIL
    ]:
      return updateObject(state, {
        error: true,
        themeList: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_WHITE_SPACE_OPPORTUNITY_SUCCESS
    ]:
      return wsoDataReceived(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_WHITE_SPACE_OPPORTUNITY_FAILED
    ]:
      return updateObject(state, {
        error: true,
        wsoGraphData: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_FEATURES_SUCCESS
    ]:
      return featuresDetailsReceived(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_FEATURES_FAILED
    ]:
      return updateObject(state, {
        error: true,
        featuresDetails: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_SUBSCRIPTION_DETAILS_SUCCESS
    ]:
      return subscriptionDetailsReceived(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_SUBSCRIPTION_DETAILS_FAIL
    ]:
      return updateObject(state, {
        error: true,
        subscriptionDetails: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PROJECT_LIST_NEEDS_SUCCESS
    ]:
      return projectListNeedsRecieved(state, action)
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_PROJECT_LIST_NEEDS_FAIL
    ]:
      return updateObject(state, {
        needsList: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.RECORD_REQUEST_START_TIME
    ]:
      return updateObject(state, {
        requestStartTime: action.time,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.USER_DELAY_COMPLETE
    ]:
      return updateObject(state, {
        shouldDelayUser: false,
        userWaitTime: 0,
      })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        projectList: null,
        needsList: null,
        subscriptionDetails: null,
        error: null,
        requestStartTime: null,
        shouldDelayUser: false,
        userWaitTime: 0,
        totalWaitTime: 8000,
      })
    case AC.CONSUMERNEED_SCORES_FETCH_SUCCESS:
      const start = action.trendDates
        ? action.trendDates.start
        : state.trendDates.start
      const end = action.trendDates
        ? action.trendDates.end
        : state.trendDates.end
      let maxNeedGrowth = 0

      var scoreList = action.scoreList
      /**
       * Need to create data to create a consumer need graph which is basically a graph between growth rate
       * and score for chart 2
       */
      const needGrowthData = []

      if (scoreList) {
        if (
          scoreList.consumerNeedReportDetails &&
          scoreList.consumerNeedReportDetails.length
        ) {
          scoreList.consumerNeedReportDetails.forEach((details) => {
            const needChart = details.needChart.filter((d) => {
              return (
                moment(d.period).isBefore(end) &&
                moment(d.period).isAfter(start)
              )
            })
            if (needChart && needChart.length) {
              const lastValue = needChart[needChart.length - 1]
              const maxPeriod = moment(lastValue.period)
              if (maxPeriod.isValid()) {
                // This is maximum period this will form basis of other calculations
                const dateInBetween = maxPeriod.clone().add(-1, 'year')
                const minPeriodDate = maxPeriod.clone().add(-2, 'year')
                // process details in reverse order as period is in ascending order
                let firstYearItemCount = 0
                let secondYearItemCount = 0
                let firstYearScore = 0
                let secondYearScore = 0
                needChart.forEach((t) => {
                  const detailPeriod = moment(t.period)
                  if (detailPeriod.isValid()) {
                    if (
                      !detailPeriod.isAfter(dateInBetween) &&
                      detailPeriod.isAfter(minPeriodDate)
                    ) {
                      firstYearItemCount++
                      firstYearScore += t.engagementScore
                    } else if (detailPeriod.isAfter(dateInBetween)) {
                      secondYearItemCount++
                      secondYearScore += t.engagementScore
                    }
                  }
                })

                if (firstYearItemCount === 0) {
                  const syc = secondYearItemCount
                  firstYearItemCount = 0
                  secondYearItemCount = 0
                  secondYearScore = 0
                  firstYearScore = 0
                  // This means that we don't have enough points to calculate average, so we will split
                  if (syc === 1) {
                    // Theere is only one point so splitting is not possible growth rate would be 0
                    firstYearItemCount = 0
                    secondYearItemCount = 1
                    firstYearScore = 0
                    secondYearScore = lastValue.engagementScore
                  } else {
                    // split array by half and calculate growth rate
                    const splitNo = parseInt(syc / 2)
                    for (
                      let j = needChart.length - 1;
                      j >= needChart.length - syc;
                      j--
                    ) {
                      if (j >= splitNo) {
                        secondYearItemCount++
                        secondYearScore +=
                          details.trendCharts[j].engagementScore
                      } else {
                        firstYearItemCount++
                        firstYearScore += details.trendCharts[j].engagementScore
                      }
                    }
                  }
                }
                let growthRate = 0
                if (firstYearScore !== 0) {
                  const avgFirstYear = firstYearScore / firstYearItemCount
                  const avgSecondYear = secondYearScore / secondYearItemCount
                  growthRate =
                    ((avgSecondYear - avgFirstYear) * 100) / avgFirstYear
                }

                if (growthRate > 0 && maxNeedGrowth < growthRate) {
                  maxNeedGrowth = growthRate
                }

                // add only those graph points that are present in trend types filter

                const graphPoint = {
                  yData: details.score,
                  label:
                    details.projectNeed && details.projectNeed.keyword
                      ? details.projectNeed.keyword
                      : '',
                  xData: growthRate,
                  needID: details.needID,
                }
                needGrowthData.push(graphPoint)
              }
            }
          })
        }
      }

      needGrowthData.forEach((gp) => {
        if (gp.xData > 0) {
          gp.xData = parseInt((gp.xData * 100) / maxNeedGrowth)
        }
      })

      return Object.assign({}, state, {
        needGrowthData,
      })
    /**
     * Chart 1
     * The topmost page /projectList
     */
    case AC.TRENDS_PHASES_DATA_FETCH_SUCCESS:
      // debugger
      return state
      const { end: selectDateEnd, start: selectDateStart } = action.trendDates
        ? action.trendDates
        : { start: state.trendDates.start, end: state.trendDates.end }
      // debugger
      const allData = action.allData
      var trendTypesFilter =
        action.trendTypes && action.trendTypes.length
          ? action.trendTypes.map((t) => {
              switch (t.value) {
                case 'EMG':
                  return 1
                case 'GRW':
                  return 2
                case 'MTR':
                  return 3
                case 'DCT':
                  return 5
                case 'DOR':
                  return 6
                case 'FAD':
                  return 7
                default:
                  return 0
              }
            })
          : []

      let dataPoints = 0
      const graphPoints = []
      let maxDate = null
      let minDate = null
      // Y points would be made of summing of each engagement score
      let highestScore = 0 // highest score overall
      let highestScoreEFD = 0 // highest score among emerging/fading/dormant
      let lowestScoreMDG = 1000000000 // lowest score among mature/declining/growing
      let minDF = 100000000 // Minimum of Growing/Declining/Emerging/Fading in absolute terms
      let minEG = 100000000 // Minimum of Growing/Declining/Emerging/Fading in absolute terms
      let maxMDP = 0 // maximum of dormant/mature positive
      let maxMDN = 0 // maximum of dormant/mature negative
      let maxGrowth = 0

      if (allData) {
        if (allData.trendReportDetails && allData.trendReportDetails.length) {
          allData.trendReportDetails.forEach((details) => {
            if (highestScore < details.score) {
              highestScore = details.score
            }
            if (
              [
                TrendTypes.EMERGING,
                TrendTypes.DORMANT,
                TrendTypes.FADING,
              ].indexOf(details.trendTypeID) > -1 &&
              highestScoreEFD < details.score
            ) {
              highestScoreEFD = details.score
            }
            if (
              [
                TrendTypes.GROWING,
                TrendTypes.DECLINING,
                TrendTypes.MATURE,
              ].indexOf(details.trendTypeID) > -1 &&
              lowestScoreMDG > details.score
            ) {
              lowestScoreMDG = details.score
            }

            const trendCharts = details.trendCharts.filter((d) => {
              return (
                moment(d.period, 'YYYY-MM-DD').isBefore(selectDateEnd) &&
                moment(d.period).isAfter(selectDateStart)
              )
            })

            if (trendCharts && trendCharts.length) {
              const lastValue = trendCharts[trendCharts.length - 1]
              const maxPeriod = moment(lastValue.period)
              if (maxPeriod.isValid()) {
                // This is maximum period this will form basis of other calculations
                const dateInBetween = maxPeriod.clone().add(-1, 'year')
                const minPeriodDate = maxPeriod.clone().add(-2, 'year')
                // process details in reverse order as period is in ascending order
                let firstYearItemCount = 0
                let secondYearItemCount = 0
                let firstYearScore = 0
                let secondYearScore = 0
                trendCharts.forEach((t) => {
                  dataPoints += t.engagementScore
                  const detailPeriod = moment(t.period)
                  if (detailPeriod.isValid()) {
                    if (
                      !detailPeriod.isAfter(dateInBetween) &&
                      detailPeriod.isAfter(minPeriodDate)
                    ) {
                      firstYearItemCount++
                      firstYearScore += t.engagementScore
                    } else if (detailPeriod.isAfter(dateInBetween)) {
                      secondYearItemCount++
                      secondYearScore += t.engagementScore
                    }
                  }
                })

                if (firstYearItemCount === 0) {
                  const syc = secondYearItemCount
                  firstYearItemCount = 0
                  secondYearItemCount = 0
                  secondYearScore = 0
                  firstYearScore = 0
                  // This means that we don't have enough points to calculate average, so we will split
                  if (syc === 1) {
                    // Theere is only one point so splitting is not possible growth rate would be 0
                    firstYearItemCount = 0
                    secondYearItemCount = 1
                    firstYearScore = 0
                    secondYearScore = lastValue.engagementScore
                  } else {
                    // split array by half and calculate growth rate
                    const splitNo = parseInt(syc / 2)
                    for (
                      let j = trendCharts.length - 1;
                      j >= trendCharts.length - syc;
                      j--
                    ) {
                      if (j >= splitNo) {
                        secondYearItemCount++
                        secondYearScore += trendCharts[j].engagementScore
                      } else {
                        firstYearItemCount++
                        firstYearScore += trendCharts[j].engagementScore
                      }
                    }
                  }
                }
                let growthRate = 0

                if (firstYearScore !== 0) {
                  const avgFirstYear = parseInt(
                    firstYearScore / firstYearItemCount,
                  )
                  const avgSecondYear = parseInt(
                    secondYearScore / secondYearItemCount,
                  )
                  growthRate =
                    ((avgSecondYear - avgFirstYear) * 100) / avgFirstYear
                }

                if (growthRate > 0 && maxGrowth < growthRate) {
                  maxGrowth = growthRate
                }

                // add only those graph points that are present in trend types filter

                if (trendTypesFilter.indexOf(details.trendTypeID) > -1) {
                  graphPoints.push({
                    xData: growthRate,
                    label: details.product && details.product.name ? '' : '',
                    productID: details.product ? details.product.id : 0,
                    yData: details.score, // score would now again be taken from report details and not trend chart,
                    trendTypeID: details.trendTypeID,
                  })
                }

                if (
                  [TrendTypes.EMERGING, TrendTypes.GROWING].indexOf(
                    details.trendTypeID,
                  ) > -1 &&
                  minEG > Math.abs(growthRate)
                ) {
                  minEG = Math.abs(growthRate)
                }

                if (
                  [TrendTypes.DECLINING, TrendTypes.FADING].indexOf(
                    details.trendTypeID,
                  ) > -1 &&
                  minDF > Math.abs(growthRate)
                ) {
                  minDF = Math.abs(growthRate)
                }

                if (
                  [TrendTypes.DORMANT, TrendTypes.MATURE].indexOf(
                    details.trendTypeID,
                  ) > -1
                ) {
                  if (growthRate > 0 && maxMDP < growthRate) {
                    maxMDP = Math.abs(growthRate)
                  } else if (growthRate < 0 && maxMDN < Math.abs(growthRate)) {
                    maxMDN = Math.abs(growthRate)
                  }
                }
              }

              trendCharts.forEach((chart) => {
                var period = moment(chart.period)
                if (period.isValid()) {
                  if (!minDate || period.isBefore(minDate)) {
                    minDate = period
                  }

                  if (!maxDate || period.isAfter(maxDate)) {
                    maxDate = period
                  }
                }
              })
            }
          })
        }
      }

      const midYPoint = parseInt((highestScoreEFD + lowestScoreMDG) / 2) // this will help draw mid horizontal line dividing y axis into 2 (may be in non-equal parts)

      // scale positive growths as per max growth scale
      minEG = (minEG * 100) / maxGrowth
      maxMDP = (maxMDP * 100) / maxGrowth
      if (minDF === 100000000) minDF = 0
      if (minEG === 100000000) minEG = 0

      const maxMD = maxMDP > maxMDN ? maxMDP : maxMDN
      let minDEFG = 0

      if (minDF === 0 && minEG === 0) {
        minDEFG = 0
      } else if (minDF > minEG) {
        minDEFG = minEG
      } else {
        minDEFG = minDF
      }

      let sideX = 0
      if (minDEFG !== 0 && maxMD !== 0) {
        sideX = (minDEFG + maxMD) / 2
      } else if (minDEFG !== 0) {
        sideX = minDEFG
      } else if (maxMD !== 0) {
        sideX = maxMD
      } else {
        sideX = 33
      }

      graphPoints.forEach((gp) => {
        if (gp.xData > 0) {
          gp.xData = parseInt((gp.xData * 100) / maxGrowth)
        }
      })
      //
      // const needChart = state.projectList.trends.filter(d => {
      //   return moment(d.period).isBefore(end) && moment(d.period).isAfter(start)
      // })

      if (state.projectList) {
        state.projectList.trends.map((tr) => {
          tr.trendResults.map((dot) => {
            const amount = dot.trendChart
              .reduce((a, b) => a + b.engagementScore, 0)
              .toFixed()
            const avg = Number(amount) / dot.trendChart.length
          })
        })
      }

      return Object.assign({}, state, {
        trendsPhasesData: {
          graphPoints,
          midYPoint,
          maxYPoint: highestScore,
          sideX,
          otherDetails: {
            dataPoints: dataPoints,
            minDate:
              minDate && minDate.isValid() ? minDate.format('MMM YYYY') : '',
            maxDate:
              maxDate && maxDate.isValid() ? maxDate.format('MMM YYYY') : '',
          },
        },
        ...action,
        trendDates: action.trendDates ? action.trendDates : dateRange.default,
      })

    case AC.SET_PROJECT_LIST_HIDDEN_CONF:
      switch (action.value) {
        case 'Emerging Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              EmergingTrends: !state.flags.EmergingTrends,
            }),
          })
        case 'Trending Themes':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              TrendingThemes: !state.flags.TrendingThemes,
            }),
          })

        case 'Growing Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              GrowingTrends: !state.flags.GrowingTrends,
            }),
          })

        case 'Mature Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              MatureTrends: !state.flags.MatureTrends,
            }),
          })

        case 'Declining Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              DecliningTrends: !state.flags.DecliningTrends,
            }),
          })

        case 'Fading Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              FadingTrends: !state.flags.FadingTrends,
            }),
          })

        case 'Dormant Trends':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              DormantTrends: !state.flags.DormantTrends,
            }),
          })

        case 'Consumer Needs' || 'Consumer Insights':
          return Object.assign({}, state, {
            flags: Object.assign({}, state.flags, {
              ConsumerNeeds: !state.flags.ConsumerNeeds,
            }),
          })
        default:
          return state
      }
    case NeedCategoriesViewActions.type[
      NeedCategoriesViewActions.FETCH_NEED_CATEGORIES_SUCCESS
    ]:
      return updateObject(state, {
        needCategories: action.needCategories,
      })
    case NeedCategoriesViewActions.type[
      NeedCategoriesViewActions.FETCH_NEED_CATEGORIES_FAILED
    ]:
      return updateObject(state, {
        needCategories: null,
        need_categories_error: true,
      })
    case AdvancedSearch.type[AdvancedSearch.FETCH_ALL_TRENDS_SUCCESS]:
      return updateObject(state, {
        allTrendsList: action.allTrends,
      })
    case AdvancedSearch.type[AdvancedSearch.FETCH_FILTERED_TRENDS_SUCCESS]: {
      const filteredTrendsList = filterTrendsData(state, action.filteredTrends)
      const filterAssocLvlTrendsData = filterAssocLvlTrendsFunc(
        filteredTrendsList,
        state.assocLevl,
        {
          projectList: state.projectList,
          productList: state.productList,
          themeList: state.themeList,
        },
      )
      return updateObject(state, {
        filteredTrends: action.filteredTrends,
        filteredTrendsList,
        filterAssocLvlTrends: filterAssocLvlTrendsData,
      })
    }
    case AdvancedSearch.type[AdvancedSearch.RESET_FILTERED_TRENDS]:
      return updateObject(state, {
        filteredTrends: null,
        filteredTrendsList: null,
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.SELECT_ASSOC_LEVEL
    ]: {
      const assocLevl = state.assocLevl.map((lvl) => {
        let checked = lvl.checked
        if (lvl.id === action.id) checked = !lvl.checked
        return { ...lvl, checked }
      })
      const filterAssocLvlTrendsData = filterAssocLvlTrendsFunc(
        state.filteredTrendsList,
        assocLevl,
        {
          projectList: state.projectList,
          productList: state.productList,
          themeList: state.themeList,
        },
      )
      return updateObject(state, {
        assocLevl,
        filterAssocLvlTrends: filterAssocLvlTrendsData,
      })
    }
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_CTX_SRC_MAPPING_SUCCESS
    ]:
      const { ctxSrchMapping: themeCtx } = action
      const { theme } = state.ctxSrchMapping
      const newCtxMap = ctxSrchDataFormatter(themeCtx)
      let finalObj = {
        [action.lens]: newCtxMap,
      }
      if (theme) {
        const newMergedObj = Object.keys(newCtxMap).reduce((obj, key) => {
          let mergedObj = {}
          if (theme[key]) {
            mergedObj = { [key]: [...newCtxMap[key], ...theme[key]] }
          }
          return { ...obj, ...mergedObj }
        }, {})
        finalObj = {
          [action.lens]: { ...theme, ...newCtxMap, ...newMergedObj },
        }
      }
      return updateObject(state, {
        ctxSrchMapping: {
          ...state.ctxSrchMapping,
          ...finalObj,
        },
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.FETCH_CTX_SRC_MAPPING_FAILED
    ]:
      return updateObject(state, {
        ctxSrchMapping: { ...state.ctxSrchMapping, error: action.error },
      })
    case ProjectListViewActions.type[
      ProjectListViewActions.RESET_PROJECTLISTBUILDER_EXCEPT_SUBSCRIPTION
    ]:
      return updateObject(state, {
        ...initialState,
        subscriptionDetails: state.subscriptionDetails,
      })

    default:
      return state
  }
}

export default reducer

const filterTrendsData = (projectListBuilder, filteredTrends) => {
  const { ingredient, product, theme } = filteredTrends || {}
  const strengthLevels = { low: null, high: null, medium: null }
  const filteredTrendsList = {
    projectList: { ...strengthLevels },
    productList: { ...strengthLevels },
    themeList: { ...strengthLevels },
  }
  if (ingredient && projectListBuilder.projectList) {
    const newfilteredTrends = {
      low: {
        trends: [],
      },
      high: {
        trends: [],
      },
      medium: {
        trends: [],
      },
    }
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      projectListBuilder.projectList.trends &&
        projectListBuilder.projectList.trends.forEach((trendType) => {
          const newTrend = {
            allTrendResults: [],
          }
          ingredient[assocLvl].forEach((filteredTrend) => {
            Object.keys(newTrend).forEach((reqTrends) => {
              const matchedItem = trendType[reqTrends].find(
                (item) => item.name === filteredTrend,
              )
              if (matchedItem) {
                newTrend[reqTrends].push(matchedItem)
              }
            })
          })
          newTrend.type = trendType.type
          newfilteredTrends[assocLvl].trends.push(newTrend)
        })
    })
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      filteredTrendsList.projectList[assocLvl] = {
        ...projectListBuilder.projectList,
        trends: newfilteredTrends[assocLvl].trends,
      }
    })
  }
  if (product && projectListBuilder.productList) {
    const newfilteredTrends = {
      low: {
        trends: [],
      },
      high: {
        trends: [],
      },
      medium: {
        trends: [],
      },
    }
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      projectListBuilder.productList.trends &&
        projectListBuilder.productList.trends.forEach((trendType) => {
          const newTrend = {
            allTrendResults: [],
          }
          product[assocLvl].forEach((filteredTrend) => {
            Object.keys(newTrend).forEach((reqTrends) => {
              const matchedItem = trendType[reqTrends].find(
                (item) => item.name === filteredTrend,
              )
              if (matchedItem) {
                newTrend[reqTrends].push(matchedItem)
              }
            })
          })
          newTrend.type = trendType.type
          newfilteredTrends[assocLvl].trends.push(newTrend)
        })
    })
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      filteredTrendsList.productList[assocLvl] = {
        ...projectListBuilder.productList,
        trends: newfilteredTrends[assocLvl].trends,
      }
    })
  }
  if (theme && projectListBuilder.themeList) {
    const newfilteredTrends = {
      low: {
        trends: [],
      },
      high: {
        trends: [],
      },
      medium: {
        trends: [],
      },
    }
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      projectListBuilder.themeList.trends &&
        projectListBuilder.themeList.trends.forEach((trendType) => {
          const newTrend = {
            allTrendResults: [],
          }
          theme[assocLvl].forEach((filteredTrend) => {
            Object.keys(newTrend).forEach((reqTrends) => {
              const matchedItem = trendType[reqTrends].find(
                (item) => item.name === filteredTrend,
              )
              if (matchedItem) {
                newTrend[reqTrends].push(matchedItem)
              }
            })
          })
          newTrend.type = trendType.type
          newfilteredTrends[assocLvl].trends.push(newTrend)
        })
    })
    Object.keys(newfilteredTrends).forEach((assocLvl) => {
      filteredTrendsList.themeList[assocLvl] = {
        ...projectListBuilder.themeList,
        trends: newfilteredTrends[assocLvl].trends,
      }
    })
  }
  if (
    !Object.keys(filteredTrendsList.projectList).find(
      (lvl) => filteredTrendsList.projectList[lvl],
    ) &&
    !Object.keys(filteredTrendsList.productList).find(
      (lvl) => filteredTrendsList.productList[lvl],
    ) &&
    !Object.keys(filteredTrendsList.themeList).find(
      (lvl) => filteredTrendsList.themeList[lvl],
    )
  ) {
    return null
  }
  return filteredTrendsList
}

const filterAssocLvlTrendsFunc = (filteredTrendsList, assocStrgth, props) => {
  const newTrendsList = {}
  if (filteredTrendsList && props) {
    const selectedLevels = assocStrgth.filter((lvl) => lvl.checked)
    const consolidatedTrends = {}
    Object.keys(props).forEach((prj) => {
      if (props[prj]) consolidatedTrends[prj] = []
    })
    if (selectedLevels.length === 0) {
      Object.keys(consolidatedTrends).forEach((prj) => {
        consolidatedTrends[prj] = props[prj].trends
          ? props[prj].trends.map((tr) => ({ ...tr, allTrendResults: [] }))
          : null
      })
    }
    selectedLevels.forEach((lvl) => {
      Object.keys(consolidatedTrends).forEach((prj) => {
        if (filteredTrendsList[prj][lvl.name]) {
          if (consolidatedTrends[prj].length === 0) {
            consolidatedTrends[prj] =
              filteredTrendsList[prj][lvl.name].trends || []
          } else {
            const combinedTrendsArr = []
            consolidatedTrends[prj].forEach((trendMaturity) => {
              const match = filteredTrendsList[prj][lvl.name].trends.find(
                (trend) => trend.type === trendMaturity.type,
              )
              const newConsolidatedTrends = {
                allTrendResults: [],
              }
              if (match) {
                Object.keys(match).forEach((trendRes) => {
                  if (trendRes !== 'type') {
                    const concatArr = [
                      ...match[trendRes],
                      ...trendMaturity[trendRes],
                    ].sort(
                      (a, b) => parseInt(a.rank, 10) - parseInt(b.rank, 10),
                    )
                    newConsolidatedTrends[trendRes] = concatArr
                  }
                })
                newConsolidatedTrends.type = trendMaturity.type
                combinedTrendsArr.push(newConsolidatedTrends)
              }
            })
            consolidatedTrends[prj] = combinedTrendsArr
          }
        }
      })
    })

    newTrendsList.projectList = {
      ...(props.projectList || props.productList || props.themeList || {}),
      trends: consolidatedTrends.projectList,
    }
    newTrendsList.productList = {
      ...(props.productList || props.projectList || props.themeList || {}),
      trends: consolidatedTrends.productList,
    }
    newTrendsList.themeList = {
      ...(props.themeList || props.productList || props.projectList || {}),
      trends: consolidatedTrends.themeList,
    }
  }
  return newTrendsList
}
