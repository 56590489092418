import { useRef, useEffect } from 'react'
import * as d3 from 'd3v7'
import {
  convertTextToTitleCase,
  getTextDimensions,
} from '../../../store/utility'
import formatNumber from '../../utils/formatNumber'

const getTooltipHTML = (d) => {
  const name = d.data.name
  const percentageScore = d.data.percentage
  const dataPoint = d.data.data_point
  return `
  <div style="color:white">
    <div style="font-size:16px;">${name}</div>
    <div style="display:flex;font-size:12px;padding-top:5px;">
        <div style="width:110px;">Percentage Share:</div>
        <div>${percentageScore}%</div>
    </div>
    <div style="display:flex;font-size:12px;padding-top:5px;">
        <div style="width:110px;">Data Points:</div>
        <div>${formatNumber(dataPoint)}</div>
    </div>
</div>
`
}

const width = 500
const radius = width / 6 // will effect size as well as ellipsis of labels
const format = d3.format(',d')

const getTextWidth = (text) => {
  const textWidth = getTextDimensions(text, {
    size: 13,
    family: 'sans-serif',
  }).width
  return textWidth
}

const wrapEllipsis = (text, isCenter) => {
  const textWidth = getTextWidth(text)
  if (isCenter) {
    if (textWidth > 2 * radius - 25) {
      const newText = text.slice(0, 20)
      return `${newText}...`
    }
  } else {
    if (textWidth - 10 > radius) {
      const newText = text.slice(0, 11)
      return `${newText}...`
    }
  }
  return text
}

const arc = d3
  .arc()
  .startAngle((d) => d.x0) // Angles here are in radians
  .endAngle((d) => d.x1) // Angles here are in radians
  .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
  .padRadius(radius * 1.5)
  .innerRadius((d) => d.y0 * radius)
  .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))

export default function Wheel({ data, projectId }) {
  const chart = useRef(null)

  const partition = (data) => {
    const root = d3.hierarchy(data).sum((d) => {
      if (d.level === 4) {
        return 1
      }
      return undefined
    })

    return d3
      .partition()
      .size([2 * Math.PI, root.height + 1])
      .padding(0)(root)
  }

  const drawChart = () => {
    const root = partition(data)
    root.each((d) => (d.current = d)) // Add a new property 'current' to the Node and assign the Node to it

    const svg = d3
      .create('svg')
      .attr('viewBox', [0, 0, width, width])
      .style('font', '10px sans-serif')
      .style('overflow', 'visible')

    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${width / 2})`)

    const path = g
      .append('g')
      .selectAll('path')
      .data(root.descendants().slice(1)) // descendants converts the tree to an array of flattened Nodes
      .join('path')
      .attr('fill', (d) => {
        return d.data.color
      })
      .attr('fill-opacity', (d) => (arcVisible(d.current) ? 1 : 0))
      .attr('pointer-events', (d) => (arcVisible(d.current) ? 'auto' : 'none'))

      .attr('d', (d) => arc(d.current))

    path
      .filter((d) => d.children)
      .style('cursor', 'pointer')
      .on('click', clicked)

    path
      .filter((d) => !d.children)
      .style('cursor', () => (projectId ? 'pointer' : 'auto'))
      .on('click', (event, d) => redirectToTrendPage(d))

    function redirectToTrendPage(d) {
      const { data } = d
      const { type, id } = data
      if (projectId) {
        const link = `/mui/details/${projectId}/${convertTextToTitleCase(
          type,
        )}/${id}`
        window.open(link, '_blank')
      }
    }

    function addCenterTextTooltip(event, text, isCategory) {
      d3.select(chart.current)
        .append('div')
        .attr('class', isCategory ? 'tooltip-text-category' : 'tooltip-text')
        .style('z-index', 1000)
        .style('position', 'absolute')
        .style('padding', '10px')
        .style('background', 'rgba(255, 255, 255, 0.25)')
        .style('box-shadow', '1px 1px 1px 0px rgba(255,255,255,0.6)')
        .style('backdrop-filter', 'blur( 6.5px )')
        .style('-webkit-backdrop-filter', ' blur( 6.5px )')
        .style('border-radius', ' 5px')
        .style('border', ' 1px solid rgba( 255, 255, 255, 0.18 )')
        .html(`<div>${text}</div>`)
        .style('display', 'block')
        .style('left', event.pageX - 10 + 'px')
        .style('top', event.pageY - 100 + 'px')
        .style('color', 'black')
        .style('background', 'white')
    }

    path
      .on('mouseover', (event, d) => {
        d3.select(chart.current)
          .append('div')
          .attr('class', 'tooltip-X')
          .style('z-index', 1000)
          .style('position', 'absolute')
          .style('padding', '10px')
          .style('background', 'rgba(255, 255, 255, 0.25)')
          .style('box-shadow', '1px 1px 1px 0px rgba(255,255,255,0.6)')
          .style('backdrop-filter', 'blur( 6.5px )')
          .style('-webkit-backdrop-filter', ' blur( 6.5px )')
          .style('border-radius', ' 5px')
          .style('border', ' 1px solid rgba( 255, 255, 255, 0.18 )')
          .html(getTooltipHTML(d))
          .style('display', 'block')
          .style('left', event.pageX - 10 + 'px')
          .style('top', event.pageY - 100 + 'px')
          .style('background', '#0274CA')

        d3.select('.tooltip-X')
          .append('div')
          .attr('class', 'triangle')
          .style('position', 'absolute')
          .style('top', '50%')
          .style('right', '100%')
          .style('margin-top', '-5px')
          .style('border-width', '5px')
          .style('border-style', 'solid')
          .style('border-color', 'transparent #0274CA transparent transparent')
      })
      .on('mouseout', (d) => {
        d3.select(chart.current)
          .selectAll('.tooltip-X')
          .remove()
        d3.select(chart.current)
          .selectAll('.triangle')
          .remove()
      })

    const label = g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(root.descendants().slice()) // Slice out the parent "flare"
      .join('text')
      .attr('font-weight', 700)
      .attr('dy', '0.35em')
      .attr('fill-opacity', (d) => +labelVisible(d.current))
      .attr('transform', (d) => labelTransform(d.current))
      .text((d) => wrapEllipsis(d.data.name, false))

    const parent = g
      .append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')

    d3.select(chart.current)
      .selectAll('.center-text')
      .remove()

    svg
      .append('text')
      .datum(root)
      .attr('class', 'center-text-country')
      .text(wrapEllipsis(root.data.country, true))
      .attr('x', function(d, i) {
        const textWidth = getTextDimensions(
          wrapEllipsis(root.data.country, true),
          {
            size: 13,
            family: 'sans-serif',
          },
        ).width

        return (width - textWidth) / 2
      })
      .attr('y', '49%')
      .attr('fill', 'black')
      .attr('font-size', 13)
      .attr('font-weight', 700)
      .on('mouseover', (event, d) => {
        const textWidth = getTextDimensions(root.data.country, {
          size: 13,
          family: 'sans-serif',
        }).width
        if (textWidth > 2 * radius - 25) {
          addCenterTextTooltip(event, root.data.country, true)
        }
      })
      .on('mouseout', (d) => {
        d3.select(chart.current)
          .selectAll('.tooltip-text')
          .remove()
      })

    svg
      .append('text')
      .datum(root)
      .attr('class', 'center-text')
      .text(wrapEllipsis(root.data.name, true))
      .attr('x', function(d, i) {
        const textWidth = getTextDimensions(
          wrapEllipsis(root.data.name, true),
          {
            size: 13,
            family: 'sans-serif',
          },
        ).width

        return (width - textWidth) / 2
      })
      .attr('y', '53%')
      .attr('fill', 'black')
      .attr('font-size', 13)
      .attr('font-weight', 700)
      .on('click', clicked)
      .on('mouseover', (event, d) => {
        const textWidth = getTextDimensions(root.data.name, {
          size: 13,
          family: 'sans-serif',
        }).width
        if (textWidth > 2 * radius - 25) {
          addCenterTextTooltip(event, root.data.name)
        }
      })
      .on('mouseout', (d) => {
        d3.select(chart.current)
          .selectAll('.tooltip-text')
          .remove()
      })

    function clicked(event, p) {
      parent.datum(p.parent || root)
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          }),
      )

      d3.select(chart.current)
        .selectAll('.center-text')
        .text(wrapEllipsis(p.data.name, true))
        .style('cursor', (d) => {
          return p.data.level === 1 ? 'auto' : 'pointer'
        })
        .on('click', p.data.level !== 1 && clicked)
        .attr('x', function(d, i) {
          const textWidth = getTextDimensions(wrapEllipsis(p.data.name, true), {
            size: 13,
            family: 'sans-serif',
          }).width

          return (width - textWidth) / 2
        })
        .on('mouseover', (event, d) => {
          const textWidth = getTextDimensions(p.data.name, {
            size: 13,
            family: 'sans-serif',
          }).width
          if (textWidth > 2 * radius - 25) {
            addCenterTextTooltip(event, p.data.name)
          }
        })

      d3.select(chart.current)
        .selectAll('.center-text-country')
        .style('cursor', (d) => {
          return p.data.level === 1 ? 'auto' : 'pointer'
        })
        .on('click', p.data.level !== 1 && clicked)

      parent
        .style('cursor', (d) => {
          return p.data.level === 1 ? 'auto' : 'pointer'
        })
        .on('click', p.data.level !== 1 && clicked)

      const t = g.transition().duration(500)

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween('data', (d) => {
          const i = d3.interpolate(d.current, d.target)
          return (t) => (d.current = i(t))
        })
        .filter(function(d) {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target)
        })
        .attr('fill-opacity', (d) => (arcVisible(d.target) ? 1 : 0))
        .attr('pointer-events', (d) => (arcVisible(d.target) ? 'auto' : 'none'))

        .attrTween('d', (d) => () => arc(d.current))

      label
        .filter(function(d) {
          return +this.getAttribute('fill-opacity') || labelVisible(d.target)
        })
        .transition(t)
        .attr('fill-opacity', (d) => +labelVisible(d.target))
        .attrTween('transform', (d) => () => labelTransform(d.current))
    }

    function arcVisible(d) {
      return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0 // As per depth y0 (Contd. next line)
      // of 1 equals depth of 1 so here if the depth is greater than equal to one or less than equal to 3 for y1 show the arcs and in case of x1 and x0 it's the angle in radians
    }

    function labelVisible(d) {
      return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI
      const y = ((d.y0 + d.y1) / 2) * radius
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`
    }

    return svg.node()
  }

  useEffect(() => {
    const svg = drawChart()
    // Remove child before redrawing
    d3.select(chart.current)
      .selectAll('*')
      .remove()
    chart.current.append(svg)
  }, [data])

  return (
    <div
      className="Wheel"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '700px',
      }}
    >
      <div style={{ width }} ref={chart}></div>
    </div>
  )
}
