import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { Circle } from '@mui/icons-material'

const SearchTrendCard = ({ cardData, country, category, dashboardList }) => {
  const projectVariant = dashboardList?.find(
    (dashboard) =>
      dashboard.criteria === country && dashboard.name === category,
  )?._projectVariant
  return (
    <Box
      sx={{
        display: 'flex',
        boxShadow: ({ palette }) => `0px 2px 4px ${palette.shadow.secondary}`,
        position: 'relative',
      }}
    >
      <Box sx={{ padding: '5px', paddingRight: '10px' }}>
        {' '}
        <img
          src={cardData.imageURL}
          height={94}
          width={94}
          style={{ borderRadius: 4, objectFit: 'cover' }}
        />
      </Box>
      <Box>
        <Typography sx={{ fontSize: '18px', paddingTop: '5px' }}>
          {cardData.name}
        </Typography>
        {cardData?.twoYearCagr != undefined && (
          <Box sx={{ display: 'flex', paddingTop: '5px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: ({ palette }) => palette.text.secondary,
              }}
            >
              2 Yr CAGR:
            </Typography>
            <Typography
              sx={{
                color: ({ palette }) =>
                  cardData.twoYearCagr >= 0
                    ? palette.success.dark
                    : palette.error.dark,
                fontSize: '12px',
                paddingLeft: '5px',
              }}
            >
              {cardData.twoYearCagr >= 0
                ? `+ ${cardData.twoYearCagr}`
                : `- ${Math.abs(cardData.twoYearCagr)}`}
              %
            </Typography>
          </Box>
        )}
        {projectVariant === 'food' && cardData?.fsProducts != undefined && (
          <Box sx={{ display: 'flex', paddingTop: '5px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: ({ palette }) => palette.text.secondary,
              }}
            >
              Food Service Products:
            </Typography>
            <Typography sx={{ fontSize: '12px', paddingLeft: '5px' }}>
              {cardData.fsProducts}
            </Typography>
          </Box>
        )}
        {cardData?.retailProducts != undefined && (
          <Box sx={{ display: 'flex', paddingTop: '5px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: ({ palette }) => palette.text.secondary,
              }}
            >
              Retail Products:
            </Typography>
            <Typography sx={{ fontSize: '12px', paddingLeft: '5px' }}>
              {cardData.retailProducts}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <Chip
          size="small"
          sx={{
            '& .MuiChip-icon': {
              color: ({ palette }) =>
                palette.colors[cardData.classification.toLowerCase()],
            },
            '& .MuiChip-label': {
              paddingLeft: '5px',
              paddingRight: '5px',
            },
          }}
          variant="outlined"
          icon={<Circle />}
          label={cardData.classification}
        />{' '}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}
export default connect(mapStateToProps)(SearchTrendCard)
