import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  SvgIcon,
  Box,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Link,
} from '@mui/material'
import { ReactComponent as LineChartIcon } from '../../../../assets/icons/line-chart.svg'
import { ReactComponent as GenX } from '../../../../assets/icons/gen_x.svg'
import { ReactComponent as Millennials } from '../../../../assets/icons/gen_z.svg'
import { ReactComponent as GenZ } from '../../../../assets/icons/millennials.svg'
import { ReactComponent as DemographicTrendPageEmptyState } from '../../../../assets/images/EmptyState/DemographicTrendPageEmptyState.svg'
import InformationIcon from '../../../../shared/components/InformationIcon'
import formatNumber from '../../../../shared/utils/formatNumber'
import PieChart from '../../PieChart'
import NetworkUtil from '../../../../services/networking/NetworkingUtil'
import axios from '../../../../services/networking/axios'
import OverlayLoader from '../../../../shared/components/OverlayLoader'
import { useLoader } from '../../../../hooks'

import {
  AGE_GROUP_GEN_X,
  AGE_GROUP_GEN_Z,
  AGE_GROUP_MILLENNIALS,
  DEMOGRAPHIC_AGE_RANGES,
} from '../../../../shared/utils/constants'

const TrendPageAgeDistributionChartUI = ({
  handleArcClickTrendPage,
  projectId,
  lens,
  themeId,
  country,
  category,
  trendName,
  handleDemographyClick,
  onDataFetchCallback,
}) => {
  const [distributionData, setTrendDistributionData] = useState([])
  const [dataPoint, setDataPoint] = useState('')
  const [loader, setShowLoader] = useLoader()
  const ageGroupGenX = AGE_GROUP_GEN_X
  const ageGroupGenZ = AGE_GROUP_GEN_Z
  const ageGroupMillanials = AGE_GROUP_MILLENNIALS

  const ageGroupIcons = {
    Millennials: Millennials,
    'Gen Z': GenZ,
    'Gen X': GenX,
  }

  const getColor = (row) => {
    if (row.name === ageGroupMillanials) {
      return (theme) => theme.palette.primary.light
    } else if (row.name === ageGroupGenZ) {
      return (theme) => theme.palette.secondary.light
    } else if (row.name === ageGroupGenX) {
      return (theme) => theme.palette.primary.dark
    } else {
      return ''
    }
  }
  const getDemographicTrendDistributionData = (
    countrySelected,
    categorySelected,
    trend,
    lens,
  ) => {
    const request = NetworkUtil.demographicTrendDistribution(
      countrySelected,
      categorySelected,
      trend,
      lens,
    )
    setShowLoader(true)
    axios
      .get(request)
      .then((res) => {
        setShowLoader(false)

        const sum = res.data.data.distribution.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.value
          },
          0,
        )

        const AgeDistributionData = res.data.data.distribution.map((data) => {
          return {
            name: data.name,
            value: ((data.value / sum) * 100).toFixed(2),
          }
        })

        const filteredData = AgeDistributionData?.filter(
          (ageGroup) => ageGroup.value !== '0.00',
        )
        onDataFetchCallback(AgeDistributionData)
        setTrendDistributionData(filteredData)
        setDataPoint(res.data.data.data_point)
      })
      .catch(() => {
        onDataFetchCallback([])
        setShowLoader(false)
      })
  }

  useEffect(() => {
    getDemographicTrendDistributionData(country, category, trendName, lens)
  }, [])

  const currentYear = new Date().getFullYear()

  const maxAgeDistributionRage = 76
  const middleAgeDistributionRage = 42
  const youngAgeDistributionRage = 27

  const updatedDistributionData = distributionData?.map((item) => {
    let generationYear = ''
    let age = ''

    if (item?.name === ageGroupGenX) {
      generationYear =
        currentYear -
        maxAgeDistributionRage +
        '-' +
        (currentYear - middleAgeDistributionRage)
      age = DEMOGRAPHIC_AGE_RANGES.ageGroupGenX
    } else if (item?.name === ageGroupMillanials) {
      generationYear =
        currentYear -
        (middleAgeDistributionRage - 1) +
        '-' +
        (currentYear - youngAgeDistributionRage)
      age = DEMOGRAPHIC_AGE_RANGES.ageGroupMillennials
    } else if (item?.name === ageGroupGenZ) {
      generationYear =
        currentYear - (youngAgeDistributionRage - 1) + '-' + `Onwards`
      age = DEMOGRAPHIC_AGE_RANGES.ageGroupGenZ
    }

    return { ...item, generationYear, age }
  })

  const styledTableCell = {
    fontSize: '12px !important',
    fontWeight: ` 500 !important`,
    color: (theme) => `${theme.palette.black} !important`,
    border: 'none !important',
    padding: '0px !important',
  }

  const tableCellStyling = {
    fontSize: '14px',
    border: 'none !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  }

  return (
    <>
      {loader && <OverlayLoader />}
      <Grid sx={{ height: '360px', width: '688px' }} item>
        <Box
          sx={{ ml: 1, flexDirection: 'column', height: '100%' }}
          display="flex"
        >
          <Box
            sx={{
              height: '57px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: (theme) => theme.palette.white,
              paddingLeft: '15px',
              paddingRight: '25px',
            }}
          >
            <Typography variant="h4">Age group distribution</Typography>{' '}
            {distributionData.length >= 1 && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: ({ palette }) => palette.grey[900],
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SvgIcon component={LineChartIcon} />
                {formatNumber(dataPoint)} Data Points
              </Typography>
            )}
          </Box>
          <Divider orientation="horizontal" />

          {distributionData.length >= 1 ? (
            <Grid
              sx={{
                height: '302px',
                display: 'flex',
                justifyContent: 'space-between',
                background: (theme) => theme.palette.white,
              }}
            >
              <Grid sx={{ width: '240px' }}>
                {
                  <Box
                    sx={{
                      flex: 1,
                      marginTop: '35px',
                      marginLeft: '20px',
                    }}
                  >
                    {' '}
                    {
                      <PieChart
                        width="208"
                        height="208"
                        data={distributionData}
                        fontSizeText={'14px'}
                        fontSizeValue={'25px'}
                        thickness={'35'}
                        handleArcClickTrendPage={handleArcClickTrendPage}
                        trendPage
                      />
                    }
                  </Box>
                }
              </Grid>
              <Divider
                orientation="vertical"
                sx={{ height: '230px', marginTop: '34px' }}
              />
              {
                <Grid sx={{ width: '443px', marginTop: '34px' }}>
                  <Box sx={{ paddingLeft: '16px' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: ({ palette }) => palette.grey[900] }}
                    >
                      Age Range by Generation
                    </Typography>
                    <Typography
                      variant="body3"
                      sx={{ color: ({ palette }) => palette.grey[900] }}
                    >
                      Generation names are based on when members of that
                      generation become adults (18-21).
                    </Typography>
                  </Box>

                  <Box sx={{ marginTop: '11px' }}>
                    <TableContainer component={Paper} sx={{ border: 'none' }}>
                      <Table
                        sx={{ minWidth: 350 }}
                        aria-label="Age group distribution table"
                      >
                        <TableHead
                          sx={{
                            background: (theme) => theme.palette.white,
                            padding: '0px !important',
                          }}
                        >
                          <TableRow sx={{ border: 'none !important' }}>
                            <TableCell
                              sx={{
                                ...styledTableCell,
                                paddingLeft: '16px !important',
                              }}
                            >
                              Generations
                            </TableCell>
                            <TableCell align="left" sx={styledTableCell}>
                              Distribution
                            </TableCell>
                            <TableCell align="left" sx={styledTableCell}>
                              Born
                            </TableCell>
                            <TableCell align="left" sx={styledTableCell}>
                              Current Ages
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {updatedDistributionData.map(
                            (row) =>
                              row.value && (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    borderBottom: 'none !important',
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      fontSize: '14px !important',
                                      border: 'none !important',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      paddingLeft: '16px !important',
                                      paddingRight: '0px !important',
                                    }}
                                  >
                                    <Link
                                      fontSize="14px !important"
                                      href={`/mui/demographic-analytics?trend=${trendName}&projectId=${projectId}&country=${country}&category=${category}&lens=${lens}&themeId=${themeId}&demography=${row.name}`}
                                      onClick={(ev) =>
                                        handleDemographyClick(ev, row.name)
                                      }
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {' '}
                                        <Box
                                          sx={{
                                            height: '39px',
                                            width: '39px',
                                            background: 'white',
                                            boxShadow:
                                              '0px 2px 4px rgba(158, 158, 158, 0.4)',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            <InformationIcon
                                              sx={{
                                                height: '8px',
                                                width: '8px',
                                              }}
                                              tooltipText={`This is the social presence of ${trendName} in ${row.name}`}
                                            />
                                          </Box>{' '}
                                          <SvgIcon
                                            sx={{
                                              position: 'relative',
                                              top: '5%',
                                              left: '20%',
                                              color: getColor(row),
                                            }}
                                            component={ageGroupIcons[row.name]}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            ml: '8px',
                                            color: getColor(row),
                                          }}
                                        >
                                          {row.name}
                                        </Box>
                                      </Box>
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left" sx={tableCellStyling}>
                                    {row.value}%
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      ...tableCellStyling,
                                    }}
                                  >
                                    {row.generationYear}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      ...tableCellStyling,
                                    }}
                                  >
                                    {row.age}
                                  </TableCell>
                                </TableRow>
                              ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              }
            </Grid>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                flex: 1,
                background: (theme) => theme.palette.white,
                flexDirection: 'column',
              }}
            >
              <Box sx={{ mb: 0.75 }}>
                <DemographicTrendPageEmptyState />
              </Box>
              <Box>
                Age Group Distribution is not available for you right now
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  )
}
export default TrendPageAgeDistributionChartUI
