import React, { useState, useEffect } from 'react'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import TrendTableUI from './TrendsTableUI'
import { useLoader } from '../../../hooks'

const TrendTable = (props) => {
  const [trendTransitionDetails, setTrendTransitionDetails] = useState({})
  const [showLoader, setShowLoader] = useLoader()

  const getTrendTransitionDetails = (projectId, subCategory, lens) => {
    const request = NetworkUtil.fetchTrendTransitionDetails(
      projectId,
      subCategory,
      lens,
    )
    setShowLoader(true)

    axios
      .get(request)
      .then((res) => {
        setTrendTransitionDetails(res.data.data)
        setShowLoader(false)
      })
      .catch((error) => {
        console.log(error)
        setShowLoader(false)
      })
  }

  useEffect(() => {
    if (props.showTransitionIcon === true) {
      let subCategory
      switch (props.lensSelected) {
        case 'Ingredient':
          subCategory =
            props.appliedFilters.ingredientSubCategory !== null
              ? props.appliedFilters.ingredientSubCategory.name
              : 'All'
          break
        case 'Product':
          subCategory = 'All'
          break
        case 'Theme':
          subCategory = 'All'
          break
        default:
          break
      }
      getTrendTransitionDetails(
        props.projectid,
        subCategory,
        props.lensSelected.toLowerCase(),
      )
    }
  }, [props.lensSelected])

  return (
    <>
      <TrendTableUI
        {...props}
        trendTransitionDetails={trendTransitionDetails}
      />
    </>
  )
}
export default TrendTable
