import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from 'axios'

import { Box, Divider, Drawer, Grid, Skeleton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import OverlayLoader from '../OverlayLoader'
import ConsumerQuotesCardComponent from '../ConsumerQuotesCardComponent'
import FoodServiceProducts from '../FoodServiceProducts'
import RelatedRetailProducts from '../RelatedRetailProducts'
import TrendGrowthCard from '../TrendGrowthCard'
import { formatNumber } from '../../../store/utility'
import EmptyIngredients from '../EmptyIngredientsCard'

const TrendInfoDrawer = (props) => {
  const {
    trendInfoLoading,
    openTrendInfo,
    handleTrendInfoClose,
    trendDetail,
    lensSelected,
    dashboardList,
    fsProductsData,
    retailProductsData,
    consumerQuotesData,
    fetchTrendInfoDetails,
    country,
    category,
    selectedFormatOpts,
    trendData,
  } = props

  const noCompIsLoading = !(
    trendInfoLoading.socialGraphData ||
    trendInfoLoading.fsProductsData ||
    trendInfoLoading.retailProductsData ||
    trendInfoLoading.consumerQuotesData
  )

  const fetchData = () => {
    // For social graph data
    fetchTrendInfoDetails(
      country,
      category,
      selectedFormatOpts
        .filter((format) => format.checked === true)
        .map((format) => {
          return format.label
        }),
      lensSelected,
      trendDetail.id,
      'formats_social_data',
      null,
    )

    // For fs products
    fetchTrendInfoDetails(
      country,
      category,
      selectedFormatOpts
        .filter((format) => format.checked === true)
        .map((format) => {
          return format.label
        }),
      lensSelected,
      trendDetail.id,
      'format_products',
      'cafe_product',
    )

    // For retail products
    fetchTrendInfoDetails(
      country,
      category,
      selectedFormatOpts
        .filter((format) => format.checked === true)
        .map((format) => {
          return format.label
        }),
      lensSelected,
      trendDetail.id,
      'format_products',
      'retail_product',
    )

    // For consumer quotes data
    fetchTrendInfoDetails(
      country,
      category,
      selectedFormatOpts
        .filter((format) => format.checked === true)
        .map((format) => {
          return format.label
        }),
      lensSelected,
      trendDetail.id,
      'formats_quotes',
      null,
    )
  }
  useEffect(() => {
    if (trendDetail) {
      fetchData()
    }
  }, [trendDetail])
  const projectVariant = dashboardList?.find(
    (dashboard) =>
      dashboard.criteria === country && dashboard.name === category,
  )?._projectVariant

  return (
    <Drawer
      anchor="right"
      open={openTrendInfo}
      onClose={handleTrendInfoClose}
      sx={{
        zIndex: 1300,
      }}
      PaperProps={{
        sx: {
          width: '75%',
          px: 3.75,
          py: 2.5,
          borderRadius: '4px 0px 0px 4px',
        },
      }}
    >
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Trend Information</Typography>
          <Typography
            variant="h3"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}
          >
            <CloseIcon onClick={handleTrendInfoClose} />
          </Typography>
        </Grid>
        <Divider
          sx={{
            my: 1.75,
          }}
        />
        {noCompIsLoading &&
          !Object.values(trendData).some((v) =>
            !Array.isArray(v) ? v : v.length,
          ) &&
          !(retailProductsData?.products?.length > 0) &&
          !(fsProductsData?.products?.length > 0) &&
          !Object.values(consumerQuotesData).some((v) => v && v.length) && (
            <EmptyIngredients text="Due to less coverage for the trend information is not available" />
          )}
        {trendInfoLoading.socialGraphData ? (
          <Box
            sx={{
              borderRadius: ({ shape }) => shape.borderRadius * 0.25,
              mt: 2,
            }}
          >
            <Skeleton width="100%" height="500px" variant="rounded"></Skeleton>
          </Box>
        ) : (
          Object.values(trendData).some((v) =>
            !Array.isArray(v) ? v : v.length,
          ) && (
            <Box
              sx={{
                border: ({ palette }) => `1px solid ${palette.divider}`,
                borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                mt: 2,
              }}
            >
              <TrendGrowthCard
                isTrendInfoPage={true}
                trendName={trendDetail?.name}
                twoYearCagr={trendDetail?.two_year_cagr}
                dataPoint={trendDetail?.data_point}
                details={trendData}
                lensSelected={lensSelected}
                fourYearCagr={trendDetail?.four_year_cagr}
              />
            </Box>
          )
        )}
        <Grid
          container
          sx={{
            mt: 1,
          }}
          spacing={2}
        >
          {lensSelected !== 'Theme' && (
            <Grid item xs={8}>
              {trendInfoLoading.retailProductsData ? (
                <Box
                  sx={{
                    borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                    mb: 2,
                  }}
                >
                  <Skeleton
                    width="100%"
                    height="250px"
                    variant="rounded"
                  ></Skeleton>
                </Box>
              ) : (
                retailProductsData?.products?.length > 0 && (
                  <Box
                    sx={{
                      border: ({ palette }) => `1px solid ${palette.divider}`,
                      borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                      mb: 2,
                    }}
                  >
                    <RelatedRetailProducts
                      noSide
                      projectid={retailProductsData.project_id}
                      projectVariant={projectVariant}
                      retailDataPoint={formatNumber(
                        trendDetail?.retail_products || 0,
                      )}
                      retailProducts={retailProductsData.products}
                      isTrendInfoPage
                      showCount
                      markIrrelevantDisabled={false}
                    />
                  </Box>
                )
              )}
              {trendInfoLoading.fsProductsData ? (
                <Box
                  sx={{
                    borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                  }}
                >
                  <Skeleton
                    width="100%"
                    height="250px"
                    variant="rounded"
                  ></Skeleton>
                </Box>
              ) : (
                fsProductsData?.products?.length > 0 && (
                  <Box
                    sx={{
                      border: ({ palette }) => `1px solid ${palette.divider}`,
                      borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                    }}
                  >
                    <FoodServiceProducts
                      noSide
                      projectid={fsProductsData.project_id}
                      cafeDataPoint={formatNumber(
                        trendDetail?.fs_products || 0,
                      )}
                      cafeProducts={fsProductsData.products}
                      isTrendInfoPage
                      showCount
                      markIrrelevantDisabled={true}
                    />
                  </Box>
                )
              )}
            </Grid>
          )}
          <Grid
            item
            xs={
              lensSelected !== 'Theme' &&
              (fsProductsData?.products?.length ||
                retailProductsData?.products?.length ||
                trendInfoLoading.consumerQuotesData)
                ? 4
                : 12
            }
          >
            {trendInfoLoading.consumerQuotesData ? (
              <Box
                sx={{
                  borderRadius: ({ shape }) => shape.borderRadius * 0.25,
                }}
              >
                <Skeleton
                  width="100%"
                  height="530px"
                  variant="rounded"
                ></Skeleton>
              </Box>
            ) : (
              <ConsumerQuotesCardComponent
                consumerQuotes={consumerQuotesData}
                category={category}
                projectVariant={projectVariant}
                country={country}
                name={trendDetail?.name}
                lens={lensSelected}
                onRefresh={() => {
                  this.fetchData()
                }}
                sourceShow
                hasFullWidth={
                  lensSelected !== 'Theme' &&
                  (fsProductsData?.products?.length ||
                    retailProductsData?.products?.length)
                }
                isTrendInfoPage
                customHeight={
                  fsProductsData?.products?.length &&
                  retailProductsData?.products?.length
                    ? 910
                    : 455
                }
                disableSubtitle
                markIrrelevantDisabled={false}
                isSidePanelView
              />
            )}
          </Grid>
        </Grid>
      </>
    </Drawer>
  )
}

const mapStateToProps = (state, props) => {
  return {
    dashboardList: state.savedTrendRequester?.savedTrendList?._list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(TrendInfoDrawer, axios)),
)
