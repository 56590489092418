import styled from '@emotion/styled';
import { Box } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  gap: theme.spacing(1.2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 7px 0px #95959529',
  '& >.MuiBox-root': {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    '& img': {
      width: theme.spacing(7),
      height: theme.spacing(7),
      borderRadius: theme.shape.borderRadius,
      ObjectFit: 'cover',
    },
    '& >.MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      '& >.MuiTypography-root': {
        color: theme.palette.primary.main,
        padding: `${theme.spacing(0.5)} 0`,
      },
    },
  },
}));
