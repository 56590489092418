import styled from '@emotion/styled';
import { Box } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(2),
  cursor: 'pointer',
  '& img': {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  '& >.MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
    '& >.MuiTypography-root': {
      color: theme.palette.primary.main,
      padding: `${theme.spacing(0.5)} 0`,
    },
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 12px 0px #272B3114',
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
  },
}));

