import React, { useState } from 'react'
import {
  Divider,
  TextField,
  Button,
  Typography,
  Popover,
  Box,
} from '@mui/material'
import InformationIcon from '../InformationIcon'

const TOOLTIP_TEXT_THEME = `In case a trend pillar has less trends than the one selected, only the available themes will be shown.`
const TOOLTIP_TEXT_INGREDIENT = `In case a trend pillar has less trends than the one selected, only the available ingredients will be shown.`

export default function CustomizationMenu({
  anchorEl,
  handleClose,
  noOfThemeChange,
  noOfRelatedTrendChange,
  noOfThemes,
  noOfThemesError,
  noOfRelatedTrendsError,
  noOfRelatedTrends,
  handleCustomizationSubmit,
}) {
  const open = Boolean(anchorEl)
  const id = open ? 'customization-popper' : undefined
  return (
    <Popover
      id={id}
      sx={{ top: '50px', left: '-30px' }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorPosition={{ top: '100px', left: 0 }}
    >
      <Box
        sx={{
          padding: '20px',
          borderRadius: '5px',
          minWidth: '320px',
        }}
      >
        <Typography pb={2} sx={{ fontSize: '16px' }}>
          Customize Your Wheel
        </Typography>
        <Divider />
        <Box pt={2} pb={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '12px' }}>
            # of Themes in Layer 2:
          </Typography>
          <InformationIcon
            tooltipText={TOOLTIP_TEXT_THEME}
            sx={{
              color: ({ palette }) => palette.primary.dark,
              marginLeft: '10px',
            }}
          />
        </Box>
        <Box>
          <TextField
            id="outlined-number"
            type="number"
            onChange={noOfThemeChange}
            value={noOfThemes}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={{ height: '10px' }}>
            <Typography variant="error">{noOfThemesError}</Typography>
          </Box>
        </Box>
        <Box pt={3} pb={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '12px' }}>
            # of Related Ingredients in Layer 3:
          </Typography>
          <InformationIcon
            tooltipText={TOOLTIP_TEXT_INGREDIENT}
            sx={{
              color: ({ palette }) => palette.primary.dark,
              marginLeft: '10px',
            }}
          />
        </Box>
        <Box pb={3}>
          <TextField
            id="outlined-number"
            type="number"
            onChange={noOfRelatedTrendChange}
            value={noOfRelatedTrends}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={{ height: '10px' }}>
            <Typography variant="error">{noOfRelatedTrendsError}</Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleCustomizationSubmit}
          disabled={noOfThemesError || noOfRelatedTrendsError}
          sx={{
            width: '100%',
            height: 'auto',
            padding: '10px',
            minWidth: '104px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Customize
        </Button>
      </Box>
    </Popover>
  )
}
