import { conceptGenie as conceptGenieActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  selectedCGConcepts: [],
}

const conceptGenieReducer = (state = initialState, action) => {
  switch (action.type) {
    case conceptGenieActionTypes.type[
      conceptGenieActionTypes.RESET_CG_SELECTED_CONCEPTS
    ]:
      return updateObject(state, {
        selectedCGConcepts: initialState.selectedCGConcepts,
      })
    case conceptGenieActionTypes.type[
      conceptGenieActionTypes.CG_SELECTED_CONCEPTS
    ]:
      return updateObject(state, {
        selectedCGConcepts: action.selectedConcepts,
      })
    case conceptGenieActionTypes.type[
      conceptGenieActionTypes.CG_MODIFIED_CONCEPTS
    ]:
      return updateObject(state, {
        selectedCGConcepts: action.selectedConcepts,
      })
    default:
      return state
  }
}

export default conceptGenieReducer
