import React from "react";
import Searchdata from '../../assets/images/testImages/Searchdata.svg';
import { Box, Grid, Typography } from '@mui/material'

const SearchNotSelected = () => {
	return (
		<>
			<Grid container spacing={2} columns={12}>
				<Grid xs={5} />
				<Grid xs={4} pt={20}>
					<Box component="img" src={Searchdata} alt="select opiton to search"/>
				</Grid>
			</Grid>
			<Grid container spacing={2} columns={16}>
				<Grid xs={6} />
				<Grid xs={4} pt={3}>
					<Typography variant="subtitle1" textAlign='center'>
						Select above data to view comparison of trends
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default SearchNotSelected;


