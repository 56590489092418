import { Box, SvgIcon, Tab, Tabs } from '@mui/material'

import React, { useEffect, useState } from 'react'

import CommonCardComponent from '../../../shared/components/CommonCardComponent'
import DashboardRecentActivity from '../../../shared/components/DashboardRecentActivity'
import TabPanel from '../../../shared/components/TabPanel'
import TrendReportCard from '../../../shared/components/TrendReportsCard'

import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import Moment from 'moment'

import { ReactComponent as CustomReportsIcon } from '../../../assets/icons/custom-reports.svg'
import { ReactComponent as RecentActivityIcon } from '../../../assets/icons/recent-activity.svg'
import { amplify } from '../../../store/utility'

const DashboardPersonalisedReportsAndActivity = (props) => {
  const { loading, email } = props

  const amp = (ampiEvent, ampiUserData) => {
    try {
      const amplitudeEvent = ampiEvent
      amplify(amplitudeEvent, ampiUserData, ampiUserData.User)
    } catch (err) {
      console.log(err, 'Amplitude error in My Workspace')
    }
  }

  const [tabSelected, setTabSelected] = useState('Global Reports')
  const [tabsToShow, setTabsToShow] = useState([
    {
      id: 0,
      name: `Global Reports`,
      icon: <SvgIcon component={CustomReportsIcon} />,
    },
    {
      id: 1,
      name: 'Recent Activity',
      icon: <SvgIcon component={RecentActivityIcon} />,
    },
  ])
  const [reportsLoading, setReportsLoading] = useState(false)
  const [allReports, setAllReports] = useState([])

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const handleChangeTab = (e, newValue) => {
    const ampiEvent = `Clicked_MW_${tabsToShow[newValue].name
      .split(' ')
      .join('')}_Tab`
    const ampiUserData = {
      User: email,
    }
    amp(ampiEvent, ampiUserData)
    setTabSelected(tabsToShow[newValue].name)
  }

  const handleSetReportsLoading = (condition) => {
    setReportsLoading(condition)
  }

  useEffect(() => {
    setReportsLoading(true)
    const request = NetworkUtil.getReportsList()
    axios
      .get(request)
      .then((response) => {
        const data = []
        const reqReports = response.data.data
        for (let i = 0; i < reqReports.length; i++) {
          const reportDetail = reqReports[i]
          let gTag = 'Custom'
          if (response.data.gtag.some((e) => e === reportDetail.name))
            gTag = 'Global'
          data.push({
            id: reportDetail.id,
            name: reportDetail.name,
            report: reportDetail.report,
            report_ppt_url: reportDetail.report_ppt_url,
            image: reportDetail.image,
            date: reportDetail.date,
            tag: gTag,
          })
        }
        data.sort(
          (a, b) =>
            Moment(b.date).format('YYYYMMDD') -
            Moment(a.date).format('YYYYMMDD'),
        )
        setAllReports([...data])
        if (data.filter((report) => report.tag === 'Custom').length) {
          const tabsToShowCopy = [...tabsToShow]
          tabsToShowCopy.splice(0, 1, {
            id: 0,
            name: `Custom Reports`,
            icon: <SvgIcon component={CustomReportsIcon} />,
          })
          setTabsToShow(tabsToShowCopy)
          setTabSelected('Custom Reports')
        }
        handleSetReportsLoading(false)
      })
      .catch((err) => {
        handleSetReportsLoading(false)
        console.log('Error fetching reports')
      })
  }, [])

  return (
    <CommonCardComponent
      loaderComponent={{
        loading: loading || reportsLoading,
        boxStyles: {
          height: '100%',
        },
      }}
      display="flex"
      boxStyles={{ height: '100%', flexDirection: 'column' }}
    >
      <Box>
        <Tabs
          value={tabsToShow.findIndex(({ name }) => tabSelected === name)}
          onChange={handleChangeTab}
          aria-label="Reports and Activity Tabs"
        >
          {tabsToShow.map((obj, index) => (
            <Tab
              sx={{
                pt: '22px !important',
                pb: '20px !important',
              }}
              key={obj.name}
              icon={obj.icon}
              label={`${obj.name}`}
              {...tabProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel
        key={tabsToShow[0].name}
        value={tabsToShow.findIndex(({ name }) => tabSelected === name)}
        index={tabsToShow[0].id}
        sx={{ overflow: 'auto' }}
        innerBoxProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <TrendReportCard
          email={email}
          singleType={true}
          loading={reportsLoading}
          reports={allReports}
        />
      </TabPanel>
      <TabPanel
        key={tabsToShow[1].name}
        value={tabsToShow.findIndex(({ name }) => tabSelected === name)}
        index={tabsToShow[1].id}
        sx={{ overflow: 'auto' }}
        innerBoxProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <DashboardRecentActivity email={email} amplitudeFn={amp} />
      </TabPanel>
    </CommonCardComponent>
  )
}

export default DashboardPersonalisedReportsAndActivity
