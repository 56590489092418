import { Paper } from '@mui/material'
import palette from '../palette'

export default {
  defaultProps: {
    component: Paper,
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
      border: `1px solid ${palette.divider}`,
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: palette.grey[100],
        borderRadius: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.grey[500],
        borderRadius: 8,
      },
    },
  },
}
