import React, { useState, useEffect } from 'react'

import { Box, Checkbox, IconButton, TableCell } from '@mui/material'
import UAMDashboardAssignedUI from './UAMDashboardAssignedUI'
import { useSingleSnackBar, useLoader } from '../../hooks'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NetworkingUtil from './../../services/networking/NetworkingUtil'
import axios from './../../services/networking/axios'
import OverlayLoader from '../../shared/components/OverlayLoader'

const UAMDashboardAssigned = ({
  selectedRow,
  dashboardAdded,
  dashboards,
  setDashboards,
  dashboardData,
  setDashboardData,
}) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('clientName')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [removeSubcategoryOpen, setRemoveSubcategoryOpen] = useState(false)
  const { showSnackBar, removeSnackBar } = useSingleSnackBar()
  const [checkBoxChecked, setCheckBoxChecked] = useState(false)
  const [checkedData, setCheckedData] = useState()
  const [subData, setSubData] = useState()
  const [deleteSubCategoryDialog, setDeleteSubCategoryDialog] = useState(false)
  const [showLoader, setShowLoader] = useLoader()

  const handleRequestSort = (property) => () => {
    const isSameOrderByAsc = orderBy === property && order === 'asc'
    setOrder(isSameOrderByAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const removeAllFromSubCategory = (arr) => {
    return arr.filter((subCategory) => subCategory.name !== 'All')
  }

  const handleState = (row) => {
    if (Array.isArray(row)) {
      setDropdownOpen(false)
      return
    }

    //  api call for subdata
    setDropdownOpen(row.id)
    setShowLoader(true)
    axios
      .get(
        NetworkingUtil.userAccessManagementDashboardAssignedSubCategory(
          selectedRow?.clientId,
          row.country,
          row.category,
        ),
      )
      .then((res) => {
        setShowLoader(false)
        const subCategoryData = res.data.data
        const ingSubCategories = removeAllFromSubCategory(
          subCategoryData.ingredient_sub_categories,
        )
        const proSubCategories = removeAllFromSubCategory(
          subCategoryData.product_sub_categories,
        )
        const themeSubCategories = removeAllFromSubCategory(
          subCategoryData.theme_sub_categories,
        )
        const createObj = (lens, data) => {
          return {
            id: {
              id: `${subCategoryData.country},${subCategoryData.category},${lens},${data.name}`,
              country: subCategoryData.country,
              category: subCategoryData.category,
              lens: lens,
              subCategory: data.name,
            },
            trendType: lens,
            subCategories: data.name,
            noOfTrends: {
              dormant: data.declining || '0',
              emerging: data.emerging || '0',
              growing: data.growing || '0',
              matured: data.matured || '0',
              declining: data.declining || '0',
              fading: data.fading || '0',
            },
            selection: data.selected,
          }
        }

        const modifiedIngSubCategories = ingSubCategories.map((data) => {
          return createObj('Ingredient', data)
        })

        const modifiedProSubCategories = proSubCategories.map((data) => {
          return createObj('Product', data)
        })
        const modifiedThemeSubCategories = themeSubCategories.map((data) => {
          return createObj('Theme', data)
        })

        const subCategoriesAll = [
          ...modifiedIngSubCategories,
          ...modifiedProSubCategories,
          ...modifiedThemeSubCategories,
        ]

        setSubData(subCategoriesAll)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeDashboard = (
    id,
    country = deleteSubCategoryDialog.country,
    category = deleteSubCategoryDialog.category,
  ) => {
    const newDashboardData = dashboardData.filter((data) => {
      return data.id != id
    })
    axios
      .delete(
        NetworkingUtil.userAccessManagementDashboardAssignedSubCategory(
          selectedRow?.clientId,
          country,
          category,
        ),
      )
      .then((res) => {})
      .catch()

    setDashboardData(newDashboardData)
  }

  const adminPanelHeadCells = [
    {
      id: 'arrow',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '30px',
      sorting: false,
      func: (data) => {
        return (
          <TableCell
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleState(data)
            }}
          >
            {
              <IconButton
                aria-label="expand row"
                size="small"
                data-testId="icon"
              >
                {data.id === dropdownOpen ? (
                  <>
                    <ExpandMoreIcon fontSize="medium" />
                  </>
                ) : (
                  <>
                    <NavigateNextIcon fontSize="medium" />
                  </>
                )}
              </IconButton>
            }
          </TableCell>
        )
      },
    },
    {
      id: 'dashboardName',
      numeric: false,
      disablePadding: true,
      label: 'Dashboard Name',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'country',
      numeric: false,
      disablePadding: true,
      label: 'Country',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: true,
      label: 'Category',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'noOfIngredients',
      numeric: false,
      disablePadding: true,
      label: 'No of Ingredients sub-categories',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'noOfProducts',
      numeric: false,
      disablePadding: true,
      label: 'No of Products sub-categories',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'noOfThemes',
      numeric: false,
      disablePadding: true,
      label: 'No of Themes sub-categories',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'remove',
      numeric: false,
      disablePadding: true,
      label: 'Remove',
      width: '100px',
      sorting: false,
      func: (data) => (
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setDeleteSubCategoryDialog(data)
          }}
        >
          <RemoveCircleIcon />
        </TableCell>
      ),
    },
  ]

  const dashboardSubHeadCells = [
    {
      id: 'trendType',
      numeric: false,
      disablePadding: true,
      label: 'Trend Type',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'subCategories',
      numeric: false,
      disablePadding: false,
      label: 'Sub - Categories available',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'noOfTrends',
      numeric: true,
      disablePadding: false,
      label: '# of trends',
      sorting: false,
      func: (data) => {
        return (
          <TableCell>
            {Object.keys(data).map((prop) => {
              return (
                <Box>
                  {prop.charAt(0).toUpperCase() + prop.slice(1)}: {data[prop]}
                </Box>
              )
            })}
          </TableCell>
        )
      },
    },
    {
      id: 'selection',
      numeric: false,
      disablePadding: false,
      label: 'Selection',
      width: '83px',
      sorting: false,
      func: (data) => {
        const checkboxHandler = (e) => {
          e.preventDefault()
          setCheckedData(data)
          setRemoveSubcategoryOpen(true)
        }

        return (
          <TableCell>
            <Checkbox
              defaultChecked={data.selection}
              onClick={checkboxHandler}
              value={data.selection}
              checked={data.selection}
            />
          </TableCell>
        )
      },
    },
  ]

  const subCategoryHandler = (val) => {
    setRemoveSubcategoryOpen(val)
  }

  const subCategoryRemoval = (val) => {
    const updatedSubData = subData.map((data) => {
      if (
        data.trendType == checkedData.trendType &&
        data.subCategories == checkedData.subCategories
      ) {
        const updatedObj = { ...data, selection: !data.selection }
        return updatedObj
      }
      return data
    })

    setSubData(updatedSubData)

    const selectedSubCategoriesIng = updatedSubData
      .filter(
        (data) => data.trendType == 'Ingredient' && data.selection == true,
      )
      .map((data) => data.subCategories)
    const selectedSubCategoriesPro = updatedSubData
      .filter((data) => data.trendType == 'Product' && data.selection == true)
      .map((data) => data.subCategories)
    const selectedSubCategoriesTheme = updatedSubData
      .filter((data) => data.trendType == 'Theme' && data.selection == true)
      .map((data) => data.subCategories)

    const newDashboard = dashboardData.map((data) => {
      if (
        data.country === checkedData.id.country &&
        data.category === checkedData.id.category
      ) {
        return {
          ...data,
          ingredientSubCat: selectedSubCategoriesIng,
          productSubCat: selectedSubCategoriesPro,
          themeSubCat: selectedSubCategoriesTheme,
        }
      } else return data
    })
    setDashboardData(newDashboard)

    showSnackBar(
      `The client is ${
        !checkedData.selection ? 'added' : 'removed'
      } successfully`,
      'success',
    )
    setTimeout(() => {
      removeSnackBar()
    }, 5000)

    setRemoveSubcategoryOpen(false)
  }

  return (
    <>
      {showLoader && <OverlayLoader />}
      {dashboardData && (
        <UAMDashboardAssignedUI
          orderBy={orderBy}
          order={order}
          handleRequestSort={handleRequestSort}
          dashboardData={dashboardData}
          adminPanelHeadCells={adminPanelHeadCells}
          dashboardSubHeadCells={dashboardSubHeadCells}
          removeSubcategoryOpen={removeSubcategoryOpen}
          handleClose={subCategoryHandler}
          dropdownOpen={dropdownOpen}
          subCategoryRemoval={subCategoryRemoval}
          checkBoxChecked={checkBoxChecked}
          setCheckBoxChecked={setCheckBoxChecked}
          setRemoveSubcategoryOpen={setRemoveSubcategoryOpen}
          checkedData={checkedData}
          handleState={handleState}
          subData={subData}
          deleteSubCategoryDialog={deleteSubCategoryDialog}
          setDeleteSubCategoryDialog={setDeleteSubCategoryDialog}
          removeDashboard={removeDashboard}
        />
      )}
    </>
  )
}

export default UAMDashboardAssigned
