import React from 'react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';

export const ConsumerCardContainer = styled(props => <Paper variant="outlined" {...props} />)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(0.5)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

export const ConsumerItemsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  overflowY: 'auto',
  height: 333,
  display: 'flex',
  gap: theme.spacing(1),
  paddingRight: theme.spacing(0.5),
  flexDirection: 'column',
  '&::-webkit-scrollbar': {
    width: theme.spacing(0.5),
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));

export const ConsumerItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  padding: `${theme.spacing(1)} 0`,
  paddingLeft: theme.spacing(1.5),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
  },
}));
