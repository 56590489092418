import React, { useEffect, useState } from 'react'

import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  ToggleButton,
  Typography,
  SvgIcon,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material'
import SubjectIcon from '@mui/icons-material/Subject'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'

import CommonCardComponent from '../../../shared/components/CommonCardComponent'
import { StyledToggleButtonGroup } from './styles'
import DashboardFavoritesTrendsCardsList from '../../../shared/components/DashboardFavoritesTrendsCardsList'
import TabPanel from '../../../shared/components/TabPanel'
import OtherFavorites from '../../../shared/components/OtherFavorites'
import FavoriteTrendPillarList from '../../../shared/components/FavoriteTrendPillar'
import EmptyIngredients from '../../../shared/components/EmptyIngredientsCard'
import Multiselect from '../../../shared/components/MultiSelect_v2'
import { ReactComponent as TrendsIcon } from '../../../assets/icons/favorites-trends.svg'
import { ReactComponent as TrendPillarIcon } from '../../../assets/icons/favorites-trend_pillar.svg'
import { ReactComponent as OtherFavoritesIcon } from '../../../assets/icons/favorites-other_favorites.svg'
import { Search } from '@mui/icons-material'
import { amplify } from '../../../store/utility'
import { usePreviousValue } from '../../../hooks'
const favoriteTypes = [
  {
    label: 'Category Consumer Insight',
    key: 0,
    type: 'CATEGORY_CONSUMER_INSIGHT',
  },
  { label: 'Pairing', key: 1, type: 'PAIRING' },
  { label: 'Trend', key: 2, type: 'TREND' },
  { label: 'Trend Consumer Insight', key: 3, type: 'TREND_CONSUMER_INSIGHT' },
  { label: 'FS Products', key: 4, type: 'FS_PRODUCTS' },
  { label: 'Retail Products', key: 5, type: 'RETAIL_PRODUCTS' },
  { label: 'Smart Filters', key: 6, type: 'ADVANCE_SEARCH' },
]

const TOGGLE_BUTTONS = [
  {
    label: 'Summary View',
    icon: <SubjectIcon fontSize="1rem" />,
  },
  {
    label: 'Graph View',
    icon: <GridViewRoundedIcon fontSize="1rem" />,
  },
]

const TABS_TO_SHOW = [
  {
    id: 0,
    name: 'Trends',
    icon: <SvgIcon component={TrendsIcon} />,
  },
  {
    id: 1,
    name: 'Trend Pillar',
    icon: <SvgIcon component={TrendPillarIcon} />,
  },
  {
    id: 2,
    name: 'Other Favorites',
    icon: <SvgIcon component={OtherFavoritesIcon} />,
  },
]

const TIME_FRAMES = ['Month', 'Quarter', '6 Months', 'Year']

const TIME_FRAMES_TO_CHECK = {
  Month: 'monthly',
  Quarter: 'quarterly',
  '6 Months': 'biannually',
  Year: 'annually',
}

const DashboardFavorites = (props) => {
  const {
    subscriptionDetails,
    favoritesTrendsData,
    removeFromFavoritesTrends,
    loading,
    otherFavoritesData,
    email,
  } = props

  const amp = (ampiEvent, ampiUserData) => {
    try {
      const amplitudeEvent = ampiEvent
      amplify(amplitudeEvent, ampiUserData, ampiUserData.User)
    } catch (err) {
      console.log(err, 'Amplitude error in My Workspace')
    }
  }

  // TODO Should remove below
  // const [lastUpdated, setLastUpdated] = useState('')
  const [viewSelected, setViewSelected] = useState(TOGGLE_BUTTONS[0].label)
  const [tabSelected, setTabSelected] = useState(TABS_TO_SHOW[0].name)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TIME_FRAMES[0])
  const [searchValue, setSearchValue] = useState('')

  const [otherFavoritesLoader, setOtherFavoritesLoader] = useState(false)
  const [trendPillarFavoritesLoader, setTrendPillarFavoritesLoader] = useState(
    false,
  )

  const [selectedFilter, setSelectedFilters] = useState([])

  const selectedFilterItem = selectedFilter.map((i) => i.type)

  const tab = 'Other Favorites'

  const handleSearch = (val) => {
    if (val !== '') {
      setSearchValue(val)
    } else {
      setSearchValue('')
    }
  }
  const previousSelectedFilter = usePreviousValue(selectedFilter)

  useEffect(() => {
    if (selectedFilter?.length) {
      if (
        !previousSelectedFilter?.length ||
        selectedFilter.filter((obj) => obj.label).length !==
          previousSelectedFilter.filter((obj) => obj.label).length
      ) {
        const filtersApplied = selectedFilter
          .filter((obj) => obj.label)
          .map((i) => i.label)
        const ampiEvent = 'Clicked_MW_Favorites_Filter'
        const ampiUserData = {
          User: email,
          Filters: filtersApplied,
        }
        amp(ampiEvent, ampiUserData)
      }
    }
  }, [selectedFilter])

  useEffect(() => {
    if (
      selectedFilterItem.includes('CATEGORY_CONSUMER_INSIGHT') &&
      !selectedFilterItem.includes('CATEGORY_SUB_CONSUMER_INSIGHT')
    ) {
      const newFilter = [
        ...selectedFilter,
        { type: 'CATEGORY_SUB_CONSUMER_INSIGHT' },
      ]
      setSelectedFilters(newFilter)
    }

    if (
      selectedFilterItem.includes('TREND_CONSUMER_INSIGHT') &&
      !selectedFilterItem.includes('TREND_SUB_CONSUMER_INSIGHT')
    ) {
      const newFilter = [
        ...selectedFilter,
        { type: 'TREND_SUB_CONSUMER_INSIGHT' },
      ]
      setSelectedFilters(newFilter)
    }
  }, [selectedFilterItem])

  const filteredFavorites =
    selectedFilterItem.length > 0
      ? otherFavoritesData.filter((item) =>
          selectedFilterItem.includes(item.favourite_type),
        )
      : otherFavoritesData

  const filteredSearchResults = filteredFavorites
    .map((obj) =>
      obj.favourite_type === 'ADVANCE_SEARCH'
        ? { ...obj, name: `Smart Filters: `.concat(obj.name) }
        : obj.favourite_type === 'TREND'
        ? { ...obj, name: `Trend: `.concat(obj.name) }
        : { ...obj },
    )
    .filter((d) => {
      return d.name.toLowerCase().includes(searchValue.toLowerCase())
    })

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChangeTimeFrame = (e) => {
    const ampiEvent = 'Clicked_MW_Favorites_Compare_to_Past'
    const ampiUserData = {
      User: email,
      Compare: e.target.value,
    }
    setSelectedTimeFrame(e.target.value)
    amp(ampiEvent, ampiUserData)
  }

  const handleChangeTab = (e, newValue) => {
    const ampiEvent = `Clicked_MW_Favorites_${TABS_TO_SHOW[newValue].name
      .split(' ')
      .join('')}`
    const ampiUserData = {
      User: email,
    }
    setTabSelected(TABS_TO_SHOW[newValue].name)
    amp(ampiEvent, ampiUserData)
  }
  const handleViewSelected = (newValue) => {
    if (newValue !== null) {
      setViewSelected(newValue)
    }
  }

  // TODO Should remove below
  // useEffect(() => {
  //   setLastUpdated(
  //     favoritesTrendsData?.recent_updated_timestamp
  //       ? new Date(favoritesTrendsData.recent_updated_timestamp).toLocaleString(
  //           'en-US',
  //           {
  //             year: 'numeric',
  //             month: 'short',
  //           },
  //         )
  //       : '',
  //   )
  // }, [favoritesTrendsData])

  return (
    <CommonCardComponent
      loaderComponent={{
        loading: loading || otherFavoritesLoader || trendPillarFavoritesLoader,
        boxStyles: {
          height: '600px',
        },
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Favorites</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {/* TODO Should uncomment below if graph view is implemented */}
          {/* <StyledToggleButtonGroup
            value={viewSelected}
            exclusive
            onChange={(e, newVal) => handleViewSelected(newVal)}
            aria-label="view"
          >
            {tabSelected != 'Trend Pillar' &&
              TOGGLE_BUTTONS.map((obj) => (
                <ToggleButton value={obj.label} aria-label={`${obj.label}`}>
                  {obj.icon}
                  &nbsp;
                  {obj.label}
                </ToggleButton>
              ))}
          </StyledToggleButtonGroup> */}
          {/* TODO Should remove below */}
          {/* {lastUpdated && (
            <React.Fragment>
              <SyncIcon color="primary" fontSize="1rem" sx={{ ml: 2 }} />
              <Typography>&nbsp;Last Updated:</Typography>
              <Typography color="primary">&nbsp;{lastUpdated}</Typography>
            </React.Fragment>
          )} */}
          {/* TODO Should uncomment below if graph view is implemented */}
          {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              '& hr': {
                mx: 2,
                height: 20,
              },
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Box> */}
          {tabSelected == tab && (
            <Grid item textAlign="right" display="flex" alignItems="center">
              <TextField
                sx={{
                  width: '394px !important',
                  px: 1,
                  py: 0.5,
                }}
                variant="outlined"
                size="large"
                placeholder={'Search Name'}
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          <Box
            display="flex"
            alignItems="center"
            sx={{
              px: 1,
              py: 0.5,
              background: ({ palette }) => palette.grey[50],
            }}
          >
            <Typography>Compare to past</Typography>
            <FormControl variant="standard" sx={{ ml: 1, minWidth: 120 }}>
              <Select
                defaultValue={selectedTimeFrame}
                value={selectedTimeFrame}
                onChange={handleChangeTimeFrame}
                label="time"
                sx={{
                  '& .MuiSelect-select': {
                    color: ({ palette }) => palette.primary.main,
                  },
                  '& .MuiSelect-icon': {
                    color: ({ palette }) => palette.primary.main,
                  },
                }}
              >
                {TIME_FRAMES.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {tabSelected == tab && (
            <Box sx={{ marginLeft: '10px' }}>
              {' '}
              <Multiselect
                placeholder="Filter"
                options={favoriteTypes}
                width="250px !important"
                fontSize="14px"
                onChange={setSelectedFilters}
                myDashBoard
              />{' '}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Tabs
          value={TABS_TO_SHOW.findIndex(({ name }) => tabSelected === name)}
          onChange={handleChangeTab}
          aria-label="Favorites Tabs"
        >
          {TABS_TO_SHOW.map((obj, index) => (
            <Tab
              key={obj.name}
              icon={obj.icon}
              label={`${obj.name}`}
              {...tabProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel
        key={TABS_TO_SHOW[0].name}
        value={TABS_TO_SHOW.findIndex(({ name }) => tabSelected === name)}
        index={TABS_TO_SHOW[0].id}
      >
        {favoritesTrendsData?.data?.length ? (
          <DashboardFavoritesTrendsCardsList
            selectedView={viewSelected}
            trendsData={favoritesTrendsData?.data}
            timeframeToCheck={TIME_FRAMES_TO_CHECK[selectedTimeFrame]}
            removeFromFavoritesTrends={removeFromFavoritesTrends}
            selectedTimeFrame={selectedTimeFrame}
            email={email}
            amplitudeFn={amp}
          />
        ) : (
          <EmptyIngredients
            text={`Click on "Add to Favorites" on Trend Page to add Trends to Favorites`}
          />
        )}
      </TabPanel>
      <TabPanel
        key={TABS_TO_SHOW[1].name}
        value={TABS_TO_SHOW.findIndex(({ name }) => tabSelected === name)}
        index={TABS_TO_SHOW[1].id}
      >
        <FavoriteTrendPillarList
          timeframeToCheck={TIME_FRAMES_TO_CHECK[selectedTimeFrame]}
          selectedTimeFrame={selectedTimeFrame}
          setShowLoader={setTrendPillarFavoritesLoader}
          email={email}
          amplitudeFn={amp}
        />
      </TabPanel>
      <TabPanel
        key={TABS_TO_SHOW[2].name}
        value={TABS_TO_SHOW.findIndex(({ name }) => tabSelected === name)}
        index={TABS_TO_SHOW[2].id}
      >
        <OtherFavorites
          timeframeToCheck={TIME_FRAMES_TO_CHECK[selectedTimeFrame]}
          selectedTimeFrame={selectedTimeFrame}
          filteredFavorites={filteredSearchResults}
          setShowLoader={setOtherFavoritesLoader}
          email={email}
          amplitudeFn={amp}
        />
      </TabPanel>
    </CommonCardComponent>
  )
}

export default DashboardFavorites
