import React from 'react'

export const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts
  const parts = text.split(
    new RegExp(`(${highlight.replace(/[^a-zA-Z0-9]/g, '\\$&')})`, 'gi'),
  )
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part?.toLowerCase() === highlight?.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  )
}

function splitStringByArrayElements(str, arr) {
  const pattern = arr
    .split('|')
    .map((e) => e.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    .join('|')
  const substrings = str.split(new RegExp(`(${pattern})`, 'i'))
  const result = []
  for (let i = 0; i < substrings.length; i++) {
    const substring = substrings[i]
    if (substring !== '') {
      result.push(substring)
    }
  }
  return result
}

export const getAdvancedHighlightingText = (
  text,
  highlight,
  handleClick,
  handleHover,
) => {
  const updatedText = text.replace(/\n{2,}/g, '\n')

  let joinedArr = highlight
    .map((obj) => obj.arr.join('|'))
    .join('|')
    .replace(/\|+/g, '|')

  joinedArr += '|\n'

  const parts = splitStringByArrayElements(updatedText, joinedArr)
  return (
    <span>
      {' '}
      {parts.map((part, i) => {
        const obj = highlight.find((obj) =>
          obj.arr.map((h) => h.toLowerCase()).includes(part.toLowerCase()),
        )
        if (part.includes('\n')) {
          return (
            <span key={i}>
              {part.split('\n').map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </span>
          )
        }
        if (part === '' || part === undefined) return
        return (
          <span
            key={i}
            onClick={() => handleClick(part)}
            onMouseEnter={() => {
              handleHover(part)
            }}
            onMouseLeave={() => {
              handleHover(null)
            }}
            style={{
              ...(part.length > 1 && { border: '1px solid transparent' }),
              ...(obj?.type === 'bold' && { fontWeight: 'bold' }),
              ...(obj?.type === 'strikeThrough' && {
                textDecoration: 'line-through',
              }),
              ...(obj?.type === 'blue' && {
                color: '#0274CA',
              }),
              ...(obj?.type === 'border' && {
                color: '#0274CA',
                fontWeight: 'bold',
                borderRadius: '5px',
                border: '1px solid #0274CA',
              }),
            }}
          >
            {part}
          </span>
        )
      })}{' '}
    </span>
  )
}
