import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select } from '@mui/material'
import { countryLangs, ENG_LANG } from '../../utils/constants'

const LangMenus = ({ hasNativeLang, country, handleChange }) => {
  const nativeLang = countryLangs[country]

  const hasNativeName = hasNativeLang && nativeLang !== ENG_LANG

  const langMenus =
    hasNativeName && nativeLang ? [ENG_LANG, nativeLang] : [ENG_LANG]

  const opts = langMenus.map((lang) => (
    <MenuItem key={lang} value={lang}>
      {lang}
    </MenuItem>
  ))

  return (
    <Select defaultValue={ENG_LANG} onChange={handleChange}>
      {opts}
    </Select>
  )
}

LangMenus.propTypes = {
  hasNativeLang: PropTypes.bool,
  country: PropTypes.string,
  handleChange: PropTypes.func,
}

export default LangMenus
