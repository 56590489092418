import React, { useMemo } from 'react'

import { Box, Checkbox, Typography } from '@mui/material'
import { FixedSizeList } from 'react-window'

import { TreeItemRoot } from './styles'
import TextOverflowTooltip from '../TextOverflowTooltip'

const HEIGHT_OF_SINGLE_MENUITEM = 42
const MAX_ITEMS_TO_FIT_IN_HEIGHT = 4.5
const HEIGHT_OF_LAST_LEVEL_MENULIST =
  HEIGHT_OF_SINGLE_MENUITEM * MAX_ITEMS_TO_FIT_IN_HEIGHT

const NestedTreeItems = (props) => {
  const {
    item,
    handleCheckboxClick,
    filterState,
    searchFilteredState,
    style,
  } = props

  const selectedChildIds = useMemo(
    () =>
      item.childIds.filter((keyOfObject) =>
        Object.keys(filterState.selected).includes(`${keyOfObject}`),
      ),
    [item, filterState.selected],
  )

  const lengthOfChildIds = item.childIds.filter((id) =>
    Object.keys(searchFilteredState).includes(`${id}`),
  ).length
  const lengthOfEmptyChildIds = useMemo(
    () =>
      item.childIds.filter(
        (childId) => !filterState.all[childId].childIds?.length,
      ).length,
    [item, filterState.all],
  )
  const totalHeightOfChildItems = lengthOfChildIds * HEIGHT_OF_SINGLE_MENUITEM

  const shouldVirtualizeList =
    lengthOfChildIds &&
    lengthOfChildIds <= lengthOfEmptyChildIds &&
    totalHeightOfChildItems > HEIGHT_OF_LAST_LEVEL_MENULIST

  const renderItemsList = ({ data, index, style }) => {
    const idOfChild = data[index]
    return (
      <NestedTreeItems
        item={filterState.all[idOfChild]}
        handleCheckboxClick={handleCheckboxClick}
        filterState={filterState}
        style={style}
      />
    )
  }

  return (
    <TreeItemRoot
      nodeId={`${item.id}`}
      label={
        <Box display="flex" alignItems="center">
          <Box>
            <Checkbox
              onClick={(e) => handleCheckboxClick(e, item)}
              checked={
                Object.keys(filterState.selected).indexOf(`${item.id}`) > -1
              }
              indeterminate={Boolean(
                item.childIds?.length &&
                  selectedChildIds?.length &&
                  selectedChildIds.length !== item.childIds.length,
              )}
            />
          </Box>
          <TextOverflowTooltip
            title={item.label}
            maxLineCount={1}
            checkWidth
            tooltipProps={{ placement: 'right' }}
          >
            <Typography>{item.label}</Typography>
          </TextOverflowTooltip>
        </Box>
      }
      style={style}
    >
      {shouldVirtualizeList ? (
        <FixedSizeList
          height={HEIGHT_OF_LAST_LEVEL_MENULIST}
          itemCount={
            item.childIds.filter((id) =>
              Object.keys(searchFilteredState).includes(`${id}`),
            ).length
          }
          itemSize={HEIGHT_OF_SINGLE_MENUITEM}
          itemData={item.childIds.filter((id) =>
            Object.keys(searchFilteredState).includes(`${id}`),
          )}
          width="100%"
        >
          {renderItemsList}
        </FixedSizeList>
      ) : (
        item.childIds
          .filter((id) => Object.keys(searchFilteredState).includes(`${id}`))
          .map((idOfChild) => (
            <NestedTreeItems
              item={filterState.all[idOfChild]}
              handleCheckboxClick={handleCheckboxClick}
              filterState={filterState}
            />
          ))
      )}
    </TreeItemRoot>
  )
}

export default NestedTreeItems
