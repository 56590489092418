import * as React from 'react'
import PropTypes from 'prop-types'

import { TextField, InputAdornment, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import { StyledPaper } from './styles'
import TooltipOnOverflow from '../../../components/Tooltip/TooltipOnOverflow.js'

const SingleSelect = ({
  options,
  value,
  onChange,
  disabled = false,
  placeholder = '',
  sx = {},
  textFieldProps,
  autoCompleteProps,
  renderOption,
  disableClearable,
  label,
  text = null,
  error,
  helperText,
  tooltipShow = false,
  sxTextfeild,
  loading,
  listBoxProps,
}) => (
  <Autocomplete
    disablePortal
    loading={loading}
    disabled={disabled}
    value={value}
    onChange={onChange}
    options={options}
    ListboxProps={listBoxProps}
    disableClearable={disableClearable}
    renderOption={renderOption}
    sx={sx}
    PaperComponent={StyledPaper}
    renderInput={(params) => {
      return tooltipShow ? (
        <TooltipOnOverflow title={value || ''} text={text} extraSpace={100}>
          <TextField
            {...params}
            label={label}
            sx={sxTextfeild}
            text={text}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    paddingTop: '16px !important',
                    paddingBottom: '16px !important',
                    fontSize: '14px !important',
                  }}
                >
                  <Typography
                    variant="h1"
                    component="h2"
                    sx={{
                      fontSize: '14px !important',
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    {text != '' ? text : null}
                  </Typography>
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            {...textFieldProps}
          />
        </TooltipOnOverflow>
      ) : (
        <TextField
          {...params}
          label={label}
          text={text}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  sxTextfeild,
                  paddingTop: '16px !important',
                  paddingBottom: '16px !important',
                  fontSize: '14px !important',
                }}
              >
                <Typography
                  variant="h1"
                  component="h2"
                  sx={{
                    fontSize: '14px !important',
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {text != '' ? text : null}
                </Typography>
              </InputAdornment>
            ),
          }}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          {...textFieldProps}
        />
      )
    }}
    {...autoCompleteProps}
  />
)

SingleSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  sx: PropTypes.shape({}),
  textFieldProps: PropTypes.shape({}),
  autoCompleteProps: PropTypes.shape({}),
  label: PropTypes.string,
}

export default SingleSelect
