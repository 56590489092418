import React, { useMemo, useState, useEffect } from 'react'
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  Stack,
  FormControlLabel,
  Typography,
  Tabs,
  Tab,
  Box,
  Divider,
} from '@mui/material'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SingleSelect from '../../../SingleSelect'
import ToggleButtonGroup from '../../../ToggleButtonGroup'
import OverlayLoader from '../../../../../shared/components/OverlayLoader'
import TabPanel from '../../../TabPanel'
import ImportTrendPillar from '../../../TrendBucketCard'
// import { Link } from 'react-router-dom'
import { pillarBasicDetails } from '../../../../../store/actions/createPillarActions'
import { Controller, useForm } from 'react-hook-form'
import {
  StyledDialog,
  StyledDialogTitle,
  StyledTabs,
  StyledTab,
  StyledButton,
  StyledStack,
  CreateTabStyling,
  ImportTabStyling,
} from './style'
import { amplify } from './../../../../../store/utility'
import {
  pillarNameExistsError,
  pillarNameLimitError,
  pillarNameRequiredError,
} from '../../../../utils/constants'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {
      User: ampiUserData.email,
    }
    switch (ampiEvent) {
      case 'Clicked_TP_Proceed':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.country.label,
          Category_Name: ampiUserData.category.label,
          Lens: ampiUserData.lens,
          Pillar_Name: ampiUserData.pillarName,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Create Import Dialog')
  }
}

const CreateImportDialog = ({
  open,
  pillarName,
  handleBucketNameChange,
  availableLenses = [],
  lensSelected,
  countrySelected,
  categorySelected,
  handleCountryChange,
  handleCategoryChange,
  handleLensSelected,
  handleSubmitClick,
  handleCancelClick,
  countriesList = [],
  categoriesList = [],
  disableToggleButton = true,
  projectsList,
  lensesAvailable,
  load,
  setShowModal,
  pillarBasicDetails,
  importPillar,
  showImport,
  email,
  userId,
}) => {
  const trends = []
  const [tab, setTab] = useState(showImport ? 1 : 0)
  const [err, setError] = useState(false)
  const [pillarNameError, setPillarNameError] = useState('')
  const { control, handleSubmit } = useForm()
  const ampClickOnCreate = () => {
    const ampiEvent = `Clicked_TP_Create`
    const ampiUserData = {
      email,
    }
    amp(ampiEvent, ampiUserData)
  }
  const ampClickOnImport = () => {
    const ampiEvent = `Clicked_TP_Import`
    const ampiUserData = {
      email,
    }
    amp(ampiEvent, ampiUserData)
  }

  const addLens = (pillarArray, lens) => {
    return pillarArray.map((pillar) => {
      return { ...pillar, lens: lens }
    })
  }

  const allPillarsList = useMemo(() => {
    let allPillars = []
    if (importPillar[0]) {
      const {
        ingredient_pillars: ingPillars,
        theme_pillars: themePillars,
        product_pillars: prodPillars,
      } = importPillar[0]
      if (ingPillars?.length) {
        const ingredientPillarList = addLens([...ingPillars], 'ingredient')
        allPillars = [...ingredientPillarList]
      }
      if (themePillars?.length) {
        const themePillarList = addLens([...themePillars], 'theme')
        allPillars = [...allPillars, ...themePillarList]
      }
      if (prodPillars?.length) {
        const productPillarList = addLens([...prodPillars], 'product')
        allPillars = [...allPillars, ...productPillarList]
      }
    }
    return allPillars
  }, [importPillar])

  const handleChange = (event, newTab) => {
    if (newTab) {
      ampClickOnImport()
    } else {
      ampClickOnCreate()
    }
    setTab(newTab)
  }

  const onSubmit = () => {}

  const pillarNameExists = () => {
    return allPillarsList.some((pillar) => {
      return (
        pillar.name === (pillarName ? pillarName.trim() : pillarName) &&
        pillar.country === countrySelected?.label &&
        pillar.category === categorySelected?.label &&
        pillar.lens === lensSelected?.toLowerCase() &&
        pillar.user == userId
      )
    })
  }

  const handlePillarNameChange = (e, onChange) => {
    onChange(e)
    handleBucketNameChange(e)
  }

  useEffect(() => {
    if (pillarName?.trim().length > 64) {
      setPillarNameError(pillarNameLimitError)
    } else if (
      pillarName &&
      countrySelected?.label &&
      categorySelected?.label
    ) {
      if (pillarNameExists()) {
        setPillarNameError(pillarNameExistsError)
      } else {
        setPillarNameError('')
      }
    } else {
      setPillarNameError('')
    }
  }, [pillarName, countrySelected, categorySelected, lensSelected])

  const handleProceedClick = (e) => {
    if (
      pillarName &&
      !pillarNameError &&
      countrySelected &&
      categorySelected &&
      lensSelected
    ) {
      const ampiEvent = 'Clicked_TP_Proceed'
      const ampiUserData = {
        email,
        lens: lensSelected,
        pillarName,
        country: countrySelected,
        category: categorySelected,
      }
      amp(ampiEvent, ampiUserData)
      setPillarNameError('')
      handleSubmitClick(e)
      pillarBasicDetails(
        pillarName,
        countrySelected?.label,
        categorySelected?.label,
        lensSelected,
        trends,
        -1,
      )
    }
    setErrorFunction(lensSelected)
  }
  const setErrorFunction = (newVal) => {
    if (newVal === null || newVal === '') {
      setError(true)
    } else {
      setError(false)
    }
  }

  const formatOpts = (list, setGenerator) =>
    Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
      label: item,
      id,
    }))

  const allCategories = useMemo(() => {
    let prjList = projectsList ? projectsList.list : []
    if (countrySelected) {
      prjList = prjList.filter((prj) => prj.criteria === countrySelected.label)
    }
    const modifiedPrjList = prjList.map((project, index) => {
      return {
        id: index,
        label: project.name,
        projectVariant: project.projectVariant,
      }
    })

    return [
      ...new Map(
        modifiedPrjList && modifiedPrjList.map((item) => [item.label, item]),
      ).values(),
    ].filter((project) => project.projectVariant === 'food')
  }, [projectsList, countrySelected])

  const allCountries = useMemo(
    () =>
      formatOpts(
        projectsList ? projectsList.list : [],
        (prj) => prj.criteria,
      ).map(({ label, id }) => {
        return {
          label,
          id,
          code: (
            countriesList.find((country) => country.label === label) || {
              code: '',
            }
          ).code,
          img:
            (
              countriesList.find((country) => country.label === label) || {
                img: '',
              }
            ).img || '',
        }
      }),
    [projectsList],
  )
  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>Trend Pillar</StyledDialogTitle>
      <Divider />
      <Box>
        {allPillarsList?.length > 0 && (
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab label="Create" {...a11yProps(0)} />
            <Tab
              label="Import"
              {...a11yProps(1)}
              sx={{
                width: '43px',
                height: '19px',
                boxShadow: 'none !important',
              }}
            />
          </StyledTabs>
        )}
      </Box>
      {load && <OverlayLoader />}

      <DialogContent sx={tab == '1' ? ImportTabStyling : CreateTabStyling}>
        <TabPanel value={tab} index={0}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            sx={{ marginTop: '0px !important ' }}
          >
            <StyledStack spacing={'23px'}>
              <Controller
                control={control}
                name="PillarName"
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    name={name}
                    value={pillarName}
                    onChange={(e) => {
                      handlePillarNameChange(e, onChange)
                    }}
                    defaultValue={pillarName}
                    InputLabelProps={{
                      shrink: false,
                      variant: 'subtitle2',
                    }}
                    label="Pillar Name"
                    placeholder="e.g. Taste"
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
                rules={{ required: pillarNameRequiredError }}
              />
              <Box
                sx={{
                  color: ({ palette }) => palette.error.main,
                  fontSize: '12px',
                  marginTop: '5px !important',
                }}
              >
                {pillarNameError}
              </Box>
              <Controller
                control={control}
                name="Country"
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                }) => (
                  <SingleSelect
                    inputRef={ref}
                    name={name}
                    label="Country"
                    placeholder="e.g. China"
                    options={allCountries}
                    value={countrySelected?.label}
                    onChange={(e, newVal) => {
                      onChange(e)
                      handleCountryChange(e, newVal)
                    }}
                    defaultValue={countrySelected?.label}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
                rules={{ required: 'Country is required' }}
              />
              <Controller
                control={control}
                name="Category"
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                }) => (
                  <SingleSelect
                    disabled={!countrySelected}
                    inputRef={ref}
                    name={name}
                    label="Category"
                    placeholder="e.g. Snacks"
                    options={allCategories}
                    value={categorySelected?.label || null}
                    onChange={(e, newVal) => {
                      onChange(e)
                      handleCategoryChange(e, newVal)
                    }}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
                rules={{ required: 'Category is required' }}
              />

              <FormControlLabel
                labelPlacement="top"
                sx={{
                  label: {
                    marginRight: 'auto',
                  },
                }}
                // disabled
                control={
                  <ToggleButtonGroup
                    exclusive
                    onChange={(e, newVal) => {
                      handleLensSelected(newVal)
                      setErrorFunction(newVal)
                    }}
                    value={lensSelected && lensSelected}
                    ariaLabel="Lens"
                    sx={{
                      '& .MuiButtonBase-root': {
                        width: '125px',
                        marginLeft: '0px',
                      },
                    }}
                  >
                    {availableLenses.map((lens) => (
                      <ToggleButton
                        // disabled={disableToggleButton}
                        value={lens}
                        aria-label={`${lens}s`}
                      >
                        {`${lens}s`}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                }
                label={
                  <Typography
                    marginRight="auto"
                    mb={1}
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{
                      color: ({ palette }) => palette.black,
                    }}
                  >
                    Lens
                  </Typography>
                }
              />

              <Box
                sx={{
                  color: ({ palette }) => palette.error.main,
                  fontSize: '12px',
                  marginTop: '5px !important',
                }}
              >
                {err ? 'Lens is required' : ''}
              </Box>

              <Stack justifyContent="flex-end" direction="row" spacing={1.25}>
                <StyledButton
                  width="120px !important"
                  onClick={() => {
                    setShowModal(false)
                  }}
                  variant="outlined"
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  width="120px !important"
                  sx={{
                    '&.MuiButtonBase-root': {
                      marginRight: '-2px',
                    },
                  }}
                  type="submit"
                  onClick={handleProceedClick}
                  variant="contained"
                >
                  Proceed
                </StyledButton>
              </Stack>
            </StyledStack>
          </form>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <ImportTrendPillar
            countries={allCountries}
            countrySelected={countrySelected}
            handleCountryChange={handleCountryChange}
            categories={allCategories}
            categorySelected={categorySelected}
            handleCategoryChange={handleCategoryChange}
            setShowModal={setShowModal}
            pillarName={pillarName}
            handleSubmitClick={handleSubmitClick}
          />
        </TabPanel>
      </DialogContent>
    </StyledDialog>
  )
}

CreateImportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  availableLenses: PropTypes.arrayOf(PropTypes.string),
  disableToggleButton: PropTypes.bool,
  countriesList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  categoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  countrySelected: PropTypes.any.isRequired,
  categorySelected: PropTypes.any.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  handleLensSelected: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { savedTrendRequester, projectListBuilder } = state
  return {
    projectsList: savedTrendRequester.savedTrendList,
    lensesAvailable: projectListBuilder.featuresDetails,
    ingredientsList: projectListBuilder.projectList,
    themesList: projectListBuilder.themeList,
    productsList: projectListBuilder.productList,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    userId: state.user?.loggedIn?.user?.user_id,
  }
}
const mapDispatchToProps = (dispatch) => ({
  pillarBasicDetails: (
    pillarName,
    countrySelected,
    categorySelected,
    lensSelected,
    trends,
    id,
  ) => {
    dispatch(
      pillarBasicDetails(
        pillarName,
        countrySelected,
        categorySelected,
        lensSelected,
        trends,
        id,
      ),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateImportDialog)
