import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchAllTrends,
  fetchFavoritesTrendsList,
  fetchSavedTrends,
  removeFromFavoritesTrends,
  fetchTrendHighlightData,
  fetchOtherFavoritesList,
} from '../../store/actions'
import FeatureAuthorizer from '../FeatureAuthorizer'
import DashboardUI from './DashboardUI'

const Dashboard = (props) => {
  const {
    subscriptionDetails,
    fetchFavoritesTrendsList,
    favoritesTrendsData,
    removeFromFavoritesTrends,
    requestProjectsList,
    projectsList,
    fetchAllTrends,
    allTrendsList,
    fetchTrendHighlightData,
    trendHighlightConfig,
    trendHighlightTrendsList,
    loading,
    email,
    otherFavoritesData,
  } = props
  return (
    <FeatureAuthorizer
      authAttr="personalised_dashboard_access"
      disableForFeat="cognitive_search"
    >
      <DashboardUI
        subscriptionDetails={subscriptionDetails}
        fetchFavoritesTrendsList={fetchFavoritesTrendsList}
        favoritesTrendsData={favoritesTrendsData}
        removeFromFavoritesTrends={removeFromFavoritesTrends}
        requestProjectsList={requestProjectsList}
        projectsList={projectsList}
        fetchAllTrends={fetchAllTrends}
        allTrendsList={allTrendsList}
        fetchTrendHighlightData={fetchTrendHighlightData}
        trendHighlightConfig={trendHighlightConfig}
        trendHighlightTrendsList={trendHighlightTrendsList}
        loading={loading}
        email={email}
        otherFavoritesData={otherFavoritesData}
      />
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state

  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
    projectsList: state.savedTrendRequester.savedTrendList,
    favoritesTrendsData: myDashboard.favoritesTrendsData,
    allTrendsList: state.projectListBuilder.allTrendsList,
    trendHighlightConfig: myDashboard.trendHighlightConfig,
    trendHighlightTrendsList: myDashboard.trendHighlightTrendsList,
    loading: myDashboard.loading,
    email: state.user?.loggedIn?.user?.email || '',
    otherFavoritesData: myDashboard.otherFavoritesData,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllTrends: (projectId) => dispatch(fetchAllTrends(projectId)),
  fetchFavoritesTrendsList: () => dispatch(fetchFavoritesTrendsList()),
  removeFromFavoritesTrends: (trendId, trendName, trendType, projectId) =>
    dispatch(
      removeFromFavoritesTrends(trendId, trendName, trendType, projectId),
    ),
  requestProjectsList: () => dispatch(fetchSavedTrends()),
  fetchTrendHighlightData: (projectId, lensSelected, subCategorySelected) =>
    dispatch(
      fetchTrendHighlightData(projectId, lensSelected, subCategorySelected),
    ),
  fetchOtherFavoritesList: () => dispatch(fetchOtherFavoritesList()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
)
