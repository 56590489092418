import React from 'react'
import { Typography, Grid, Button } from '@mui/material'
import SingleSelect from '../../SingleSelect'
const FilterComponentUI = ({
  allDropdownsList,
  handleSelectChange,
  applySelectedFilters,
  resetSelectedFilterValues,
  selectedDataPoints,
  selected2YearCAGR,
  selected4YearCAGR,
}) => {
  return (
    <>
      <Grid
        sx={{
          width: '244px',
          height: '330px',
          boxShadow: ({ palette }) => `0px 2px 4px ${palette.grey[500]}`,
          background: (theme) => theme.palette.white,
          padding: '15px 20px 15px 20px',
        }}
      >
        {allDropdownsList.map((obj) => (
          <>
            <Grid sx={{ marginTop: obj.marginTop }}>
              {' '}
              <Typography variant="subtitle2">{obj.label}</Typography>
              <SingleSelect
                placeholder={obj.placeholder}
                label={obj.label}
                options={obj.options.map((i) => i.label) || []}
                value={obj.value || null}
                onChange={(ev, val) => handleSelectChange(obj.index, val)}
                sx={{
                  width: '100%',
                  height: 35,
                  '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                    height: '100%',
                  },
                }}
              />
            </Grid>
          </>
        ))}
        <Grid
          sx={{
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: '89px' }}
            onClick={resetSelectedFilterValues}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{ width: '89px' }}
            onClick={() =>
              applySelectedFilters(
                allDropdownsList,
                selectedDataPoints,
                selected2YearCAGR,
                selected4YearCAGR,
              )
            }
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
export default FilterComponentUI
