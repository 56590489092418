import React, { useState } from 'react'

import {
  screeningStatus as swStatus,
  screenWinnerMessages as swMsgs,
  subCategory as swSubCat,
} from '../../const'
import { formatString } from '../../store/utility'

import InfoChips from '../InfoChips'
import ImgSafe from '../Image/ImageSafe'
import FlyOutOptions from '../FlyOutOptions/FlyOutOptions.js'
import SWScreenedResultsChips from '../SWScreenedResultsChips/SWScreenedResultsChips'
import DeleteModal from '../SWDeleteModal/SWDeleteModal'
import Tooltip from '../SWTooltip/SWTooltip'
import SWTooltip from '../SWTooltip/SWTooltip_v2'

import deleteIcon from '../../assets/images/screenWinner/sw-red-delete.svg'
import copyIcon from '../../assets/images/screenWinner/sw-copy.svg'
import iIcon from '../../assets/images/screenWinner/sw-i-icon.svg'

import './ConceptCard.scss'

const ConceptCard = ({
  id,
  title,
  description,
  originalityScore,
  finalRank,
  relevanceScore,
  screeningStatus,
  deleteConcept,
  cloneConcept,
  hideOpts,
  keywordsAnalysed,
  subcategory,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleDeleteConcept = () => {
    deleteConcept(id)
    setShowDeleteModal(false)
  }
  const handleCloneConcept = () => {
    cloneConcept(id)
  }
  const [showOpts, setShowOpts] = useState(false)

  const keywords = []
  for (const [key, value] of Object.entries(keywordsAnalysed || {})) {
    if (key !== 'error' && Array.isArray(value)) {
      keywords.push({
        name: `${formatString(key, true)}s`,
        value: value.join('; '),
      })
    }
  }
  const keywordsErrorMsg =
    keywords.some(({ value }) => !value) && swMsgs.CONCEPT_KEYWORDS_MISSING
  const subCategoryText = `Sub-category: ${subcategory}`
  return (
    <div
      className={`concept-card ${
        keywordsErrorMsg ? ' concept-card--error' : ''
      }`}
      // tabIndex="0"
      role="button"
      onMouseEnter={() => setShowOpts(true)}
      onMouseLeave={() => setShowOpts(false)}
    >
      <div
        className="concept-card__opts__block"
        onClick={(ev) => ev.stopPropagation()}
      >
        {!hideOpts && (
          <FlyOutOptions
            show={showOpts}
            options={[
              {
                element: (
                  <span className="concept-card__opts__clone">
                    <ImgSafe src={copyIcon} alt="Clone" />
                    Clone
                  </span>
                ),
                onClick: handleCloneConcept,
              },
              {
                element: (
                  <span className="concept-card__opts__del">
                    <ImgSafe src={deleteIcon} alt="Delete" />
                    Delete
                  </span>
                ),
                onClick: () => setShowDeleteModal(true),
              },
            ]}
            className="concept-card__fl-opts"
            optsClassName="concept-card__fl-opts__list"
          >
            <span className="concept-card__opts">...</span>
          </FlyOutOptions>
        )}
      </div>
      <div className="concept-card__meta-data">
        <SWTooltip title={subcategory === swSubCat.ALL ? '' : subCategoryText}>
          <span className="concept-card__meta-data__sub-category">
            {subcategory && subcategory !== swSubCat.ALL && subCategoryText}
          </span>
        </SWTooltip>
        <span>Concept ID: {id}</span>
      </div>
      <div className="concept-card__info-card">
        <h3 className="info-title title--14">{title}</h3>
        <p className="info-description para--12">{description}</p>
        <div className="info-keywords para--12">
          <div className="info-keywords__heading__block">
            <h4 className="info-keywords__heading">Keywords analysed</h4>
            {keywordsErrorMsg && (
              <Tooltip
                relChildPos
                offsetTop={-15}
                offsetLeft={10}
                text={keywordsErrorMsg}
                ignoreTextWidth
              >
                <div className="concept-card__error-msg">
                  <img src={iIcon} alt="info" />
                </div>
              </Tooltip>
            )}
          </div>
          {keywords.map(({ name, value }) => (
            <p key={name} className="info-keywords__items para--12">
              {name}: {value}
            </p>
          ))}
        </div>
      </div>
      <div className="concept-card__info-chips">
        {screeningStatus === swStatus.SCREENED ? (
          <SWScreenedResultsChips
            cardDisplay={true}
            relevanceScore={relevanceScore}
            originalityScore={originalityScore}
            finalRank={finalRank}
          />
        ) : (
          <InfoChips data={['unscreened']} />
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          modalTitle="Delete Concept"
          modalBody="Are you sure you want to delete this concept?"
          onCancelClick={() => setShowDeleteModal(false)}
          onDeleteClick={handleDeleteConcept}
        />
      )}
    </div>
  )
}

export default ConceptCard
