import React, { useState, useEffect, useMemo } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Grid,
  Stack,
  Typography,
  ToggleButton,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import { Download } from '@mui/icons-material'
import { ReactComponent as CrossIcon } from '../../../assets/icons/feather-cross-circle.svg'

import ToggleButtonGroup from '../../../shared/components/ToggleButtonGroup'
import Multiselect from '../../../shared/components/MultiSelect_v2'
import SingleSelect from '../../../shared/components/SingleSelect'
import BtnMultiSelectDropdown from '../../../shared/components/ButtonMultiSelectDropdown'
import AdvancedTrendAnalysisTable from '../../../shared/components/AdvancedTrendAnalysisComponents/partials/AdvancedTrendAnalysisTable'
import SearchNotSelected from '../../../shared/components/AdvancedTrendAnalysisComponents/partials/SearchNotSelected'
import NoResultsFound from '../../../shared/components/AdvancedTrendAnalysisComponents/partials/NoResultsFound'

import OverlayLoader from '../../../shared/components/OverlayLoader'

import axios from '../../../services/networking/axios'
import axiosClass from 'axios'
import * as actions from '../../../store/actions/index'

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import NetworkingUtil from '../../../services/networking/NetworkingUtil'

import { amplify } from './../../../store/utility'
import { AmplitudeValues } from '../../../store/amplitudeValues'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const ampiInstance = new AmplitudeValues()
    const getLabelArray = (properties) => {
      return properties.map((property) => property.label)
    }
    const allFilters = getLabelArray(
      ampiUserData.filters.filter((appliedFilter) => {
        return appliedFilter.checked
      }),
    )
    const getCNCategory = (cnList) => {
      const selectedCN = cnList.map((cn) => cn.label)
      return selectedCN.length ? selectedCN : 'NULL'
    }
    let amplitudeUserData = {
      User: ampiInstance.email,
      Lens: ampiUserData.lens,
      'Maturity Phase': getLabelArray(ampiUserData.maturityPhase),
      'Country Name': getLabelArray(ampiUserData.country),
      'Category Name': ampiUserData.category.label,
    }
    const amplitudeEvent = ampiEvent
    switch (amplitudeEvent) {
      case 'Clicked_Advanced_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Clicked_Add: allFilters.length ? allFilters : 'NULL',
          SubCategory:
            ampiUserData.appliedFilterOpts.selectedSubCategory || 'NULL',
          Clicked_Operator:
            ampiUserData.appliedFilterOpts.analysisType || 'NULL',
          Clicked_Include_Consumer_Needs: getCNCategory(
            ampiUserData.selectedCN,
          ),
          Action: ampiUserData.trendName ? 'Expand' : 'Collapse',
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          'Add Filter': allFilters.length ? allFilters : 'null',
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in AdvancedTrendAnalysis')
  }
}

const formatOpts = (list, setGenerator) =>
  Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
    label: item,
    key: id,
  }))

const getLabel = ({ label }) => label

const advOptChecked = (optsArr, optName) =>
  optsArr.find(({ label, checked }) => label === optName && checked)

const operationResolver = (val, optTxt, valTxt) => {
  let resolvedVal = ''
  if (val) {
    resolvedVal += `${optTxt}=`
    if (val.includes('<')) {
      resolvedVal += 'lt'
    } else if (val.includes('>=')) {
      resolvedVal += 'gte'
    }
    resolvedVal += `&${valTxt}=${val.match(/\d+/)[0]}`
  }
  return resolvedVal
}

const isUrlCategorySet = false

let source = axiosClass.CancelToken.source()

const trendMaturityPhases = [
  { label: 'Dormant', key: 0 },
  { label: 'Emerging', key: 1 },
  { label: 'Growing', key: 2 },
  { label: 'Matured', key: 3 },
  { label: 'Declining', key: 4 },
  { label: 'Fading', key: 5 },
]

const consumerNeeds = [
  {
    label: 'Driver',
    key: 0,
    checked: false,
  },
  {
    label: 'Product Attribute',
    key: 1,
    checked: false,
  },
  {
    label: 'Expressed Persona',
    key: 2,
    checked: false,
  },
  {
    label: 'Occasion',
    key: 3,
    checked: false,
  },
]

const FOUR_YEAR_CAGR = '4 Year CAGR'
const TWO_YEAR_CAGR = '2 Year CAGR'
const SUB_CATEGORY = 'Sub-category'
const advancedFilterOptions = [
  {
    label: SUB_CATEGORY,
    key: 0,
    checked: false,
  },
  {
    label: FOUR_YEAR_CAGR,
    key: 1,
    checked: false,
  },
  {
    label: TWO_YEAR_CAGR,
    key: 2,
    checked: false,
  },
]
const yoyCagrOpts = [
  {
    label: '< 5%',
    key: 0,
    labelTooltip: 'Less than 5%',
  },
  {
    label: '>= 5%',
    key: 1,
    labelTooltip: 'Greater than or equal to 5%',
  },
  {
    label: '>= 10%',
    key: 2,
    labelTooltip: 'Greater than or equal to 10%',
  },
  {
    label: '>= 20%',
    key: 3,
    labelTooltip: 'Greater than or equal to 20%',
  },
]

const availableLenses = ['Ingredient', 'Product', 'Theme']

const urlParamsKeys = {
  lens: 'lens',
  country: 'cntries',
  trendPhases: 'tP',
  category: 'ctgry',
  subCategory: 'sbct',
  fourYearCAGR: 'cagr',
  twoYearCAGR: 'twoycagr',
  analysisType: 'aType',
}

const andOrAnalysis = {
  and: 'and',
  or: 'or',
}

const AdvancedTrendAnalysis = ({
  advancedTrendAnalysisList,
  advancedTrendsComparisonList,
  loadingRequests,
  subCategories,
  getProductDetails,
  resetATAComparisonList,
  resetATAList,
  getSubcategoriesList,
  setCurrentTrend,
  ...props
}) => {
  const [lensSelected, setLensSelected] = useState(availableLenses[0])
  const [trendPhases, setTrendPhases] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [twoYearCAGR, setTwoYearCAGR] = useState('')
  const [fourYearCAGR, setFourYearCAGR] = useState('')
  const [selectedFilterOpts, setSelectedFilterOpts] = useState(
    advancedFilterOptions,
  )
  const [selectedCategory, setSelectedCategory] = useState('')
  const [analysisType, setAnalysisType] = useState(andOrAnalysis.or)
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const [selectedCN, setSelectedCN] = useState([])
  const [expandedTrend, setExpandedTrend] = useState('')
  const [appliedFilterOpts, setAppliedFilterOpts] = useState({
    twoycagr: null,
    cagr: null,
    analysisType: null,
    lens: availableLenses[0],
    selectedSubCategory: null,
  })
  const [loader, setLoader] = useState(false)
  const [showOverlayLoader, setShowOverlayLoader] = useState(false)
  // const history = useHistory()

  const allFilterSelected =
    selectedFilterOpts.filter(({ checked }) => checked).length === 3
  const handleToggleSelection = (func) => (event, toggle) => {
    if (toggle !== null) {
      func(toggle)
    }
  }

  const logTrendExpansion = (trendName) => {
    const ampiEvent = 'Clicked_Advanced_Action'
    const ampiUserData = {
      lens: lensSelected,
      maturityPhase: trendPhases,
      country: selectedCountries,
      category: selectedCategory,
      filters: selectedFilterOpts,
      trendName,
      appliedFilterOpts,
      selectedCN,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleFilterClick = (option) => {
    switch (option.label) {
      case SUB_CATEGORY:
        setSelectedSubCategory('')
        break
      case FOUR_YEAR_CAGR:
        setFourYearCAGR('')
        break
      case TWO_YEAR_CAGR:
        setTwoYearCAGR('')
        break
    }
    setSelectedFilterOpts((filterOpts) =>
      filterOpts.map((opt) =>
        opt.key === option.key ? { ...opt, checked: !opt.checked } : { ...opt },
      ),
    )
  }

  const handleRemoveFilter = (option) => () => {
    handleFilterClick({ ...option })
  }

  // const handleYoYInputChange = (type) => (event) => {
  //   let value = parseInt(event.target.value || 0, 10) // Need to take care of cases for negative numbers
  //   let { min, max } = yoyRange
  //   if (type === 'min') {
  //     if (value > max) max = value + 1
  //   } else {
  //     if (value < min) min = value - 1
  //   }
  //   setYoyRange('')
  // }

  const advancedFilterComponents = {
    0: (
      <SingleSelect
        placeholder="e.g. Target Group"
        options={subCategories || []}
        value={selectedSubCategory || null}
        onChange={(ev, val) => setSelectedSubCategory(val)}
        sx={{
          width: '100%',
          height: 35,
          '& .MuiOutlinedInput-root, .MuiFormControl-root': {
            height: '100%',
          },
        }}
      />
    ),
    1: (
      <SingleSelect
        placeholder="e.g. < 5%"
        disableClearable
        options={yoyCagrOpts}
        renderOption={(props, option, { selected }) => (
          <Tooltip title={option.labelTooltip} placement="right">
            <li {...props}>{option.label}</li>
          </Tooltip>
        )}
        value={fourYearCAGR || null}
        onChange={(ev, val) => setFourYearCAGR(val)}
        sx={{
          '.MuiOutlinedInput-root': {
            paddingRight: '30px !important',
          },
          width: '100%',
          height: 35,
          '& .MuiOutlinedInput-root, .MuiFormControl-root': {
            height: '100%',
          },
        }}
      />
    ),
    2: (
      <SingleSelect
        placeholder="e.g. < 5%"
        disableClearable
        options={yoyCagrOpts}
        renderOption={(props, option, { selected }) => (
          <Tooltip title={option.labelTooltip} placement="right">
            <li {...props}>{option.label}</li>
          </Tooltip>
        )}
        value={twoYearCAGR || null}
        onChange={(ev, val) => setTwoYearCAGR(val)}
        sx={{
          '.MuiOutlinedInput-root': {
            paddingRight: '30px !important',
          },
          width: '100%',
          height: 35,
          '& .MuiOutlinedInput-root, .MuiFormControl-root': {
            height: '100%',
          },
        }}
      />
    ),
    // 2: (
    //   <Stack direction="row" alignItems="center" spacing={1}>
    //     <TextField
    //       value={yoYMinMax.min}
    //       inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    //       onChange={handleYoYInputChange('min')}
    //       sx={{
    //         '& .MuiOutlinedInput-root': {
    //           height: 35,
    //           width: 35,
    //         },
    //       }}
    //     />
    //     <Typography> - </Typography>
    //     <TextField
    //       inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    //       value={yoYMinMax.max}
    //       onChange={handleYoYInputChange('max')}
    //       sx={{
    //         '& .MuiOutlinedInput-root': {
    //           height: 35,
    //           width: 35,
    //         },
    //       }}
    //     />
    //   </Stack>
    // ),
  }
  const isDualGrowthSelections = [
    FOUR_YEAR_CAGR,
    TWO_YEAR_CAGR,
  ].every((label) =>
    selectedFilterOpts
      .filter(({ checked }) => checked)
      .find((opt) => opt.label === label),
  )
  const displayFilterOpts = ({ label, key }, enlarge) => (
    <Grid item xs={6} md={enlarge ? 4 : label === TWO_YEAR_CAGR ? 1 : 1.5}>
      <Stack xs={12} spacing={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle2">{label}</Typography>
          <CrossIcon
            style={{ width: '14px', height: '14px', cursor: 'pointer' }}
            onClick={handleRemoveFilter({ label, key })}
          />
        </Box>
        {advancedFilterComponents[key]}
      </Stack>
    </Grid>
  )

  const setCategoryFromUrl = () => {
    // if (isUrlCategorySet) return
    // const query = getQueryParams()
    // const categoryParam = query.get(urlParamsKeys.category)
    // const category = categoryParam && decodeURIComponent(categoryParam)
    // if (category) {
    //   setSelectedCategory(category)
    // }
    // isUrlCategorySet = true
  }

  useEffect(() => {
    if (!selectedCountries.length) return
    const { projectsList } = props
    if (projectsList) {
      const matchedCategories = projectsList.list.reduce(
        (acc, { criteria, name, projectVariant }) => {
          if (
            selectedCountries.find(
              ({ label }) => label === criteria ,
            )
          ) {
            if (acc.has(name)) {
              acc.set(name, acc.get(name) + 1)
            } else {
              acc.set(name, 1)
            }
          }
          return acc
        },
        new Map(),
      )
      setCategoriesList(
        formatOpts(
          [...matchedCategories].reduce(
            (acc, [key, value]) =>
              value === selectedCountries.length ? [...acc, key] : acc,
            [],
          ),
          (val) => val,
        ),
      )
      setSelectedCategory('')
    }
    setCategoryFromUrl()
  }, [selectedCountries])

  useEffect(() => {
    const { projectsList } = props

    // Set countries
    let formattedCountries = []
    // eslint-disable-next-line
    ;(formattedCountries = formatOpts(
      projectsList ? projectsList.list : [],
      (prj) => prj.criteria,
    ).map(({ label, key }) => {
      return {
        label,
        key,
        code: (
          countriesList.find((country) => country.label === label) || {
            code: '',
          }
        ).code,
        img:
          (
            countriesList.find((country) => country.label === label) || {
              img: '',
            }
          ).img || '',
      }
      // eslint-disable-next-line
    })),
      formattedCountries &&
        formattedCountries.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
    setCountriesList(formattedCountries)

    // Populate already applied data from URL
    // try {
    //   const countryParam = getQueryParams().get(urlParamsKeys.country)
    //   const countries = countryParam && decodeURIComponent(countryParam)
    //   if (countries) {
    //     setSelectedCountries(
    //       countries.split(';').reduce((acc, ctry) => {
    //         const country = formattedCountries.find(
    //           ({ label }) => ctry === label
    //         )
    //         return country ? [...acc, country] : acc
    //       }, [])
    //     )
    //   }
    // } catch (error) {
    //   console.log('ERROR IN COUNTRY URL', error)
    // }

    // Set categories
    let formatedCategories = []
    formatedCategories =
      projectsList &&
      projectsList.list.map((project, index) => {
        return {
          id: index,
          label: project.name,
          projectVariant: project.projectVariant,
        }
      })

    formatedCategories &&
      formatedCategories.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })

    const filteredCategoryUniqueByLabel = [
      ...new Map(
        formatedCategories &&
          formatedCategories.map((item) => [item.label, item]),
      ).values(),
    ]

    setCategoriesList(filteredCategoryUniqueByLabel)
  }, [props.projectsList])

  // On mount populate the state using params from URL
  useEffect(() => {
    // const query = getQueryParams()
    // const lens = query.get(urlParamsKeys.lens)
    // try {
    //   const trendPhasesParam = query.get(urlParamsKeys.trendPhases)
    //   const trendPhases =
    //     trendPhasesParam && decodeURIComponent(trendPhasesParam)
    //   if (trendPhases) {
    //     setTrendPhases(
    //       trendPhases.split(';').reduce((acc, id) => {
    //         const phase = trendMaturityPhases[Number(id)]
    //         return phase ? [...acc, phase] : acc
    //       }, [])
    //     )
    //   }
    // } catch (error) {
    //   console.log('ERROR IN TP URL', error)
    // }
    // if (availableLenses.includes(lens)) setLensSelected(lens)
    return () => {
      cancelRequest()
      resetATAComparisonList([])
      resetATAList()
    }
  }, [])

  // Fetch sub-categories when country or category changes
  // and the sub-category is active
  useEffect(() => {
    const subCategoryActive = selectedFilterOpts.filter(
      ({ label, checked }) => checked && label === SUB_CATEGORY,
    )
    if (
      subCategoryActive.length &&
      selectedCategory &&
      selectedCountries.length
    ) {
      ;(async () => {
        setShowOverlayLoader(true)
        const requestDone = await getSubcategoriesList(
          selectedCategory.label,
          selectedCountries.map(({ label }) => label),
          lensSelected.toLowerCase(),
        )
        setSelectedSubCategory('')
        if (requestDone) setShowOverlayLoader(false)
      })()
    }
  }, [selectedFilterOpts, selectedCountries, selectedCategory, lensSelected])

  // useEffect(() => {
  // const query = getQueryParams()
  // if (appliedFilterOpts.yoy) {
  //   query.set(urlParamsKeys.yoyMinMax, `${yoyRange.min}to${yoyRange.max}`)
  // } else {
  //   query.delete(urlParamsKeys.yoyMinMax)
  // }
  // if (appliedFilterOpts.cagr) {
  //   query.set(urlParamsKeys.fourYearCAGR, fourYearCAGR.label.replace(' ', ''))
  // } else {
  //   query.delete(urlParamsKeys.fourYearCAGR)
  // }
  // if (appliedFilterOpts.yoy && appliedFilterOpts.cagr) {
  //   query.set(urlParamsKeys.analysisType, analysisType)
  // } else {
  //   query.delete(urlParamsKeys.analysisType, analysisType)
  // }
  // history.replace(`?${query.toString()}`)
  // }, [appliedFilterOpts])

  const updateQueryParams = () => {
    // const query = getQueryParams()
    // query.set(urlParamsKeys.lens, lensSelected)
    // query.set(
    //   urlParamsKeys.trendPhases,
    //   encodeURIComponent(trendPhases.map(({ key }) => key).join(';'))
    // )
    // query.set(
    //   urlParamsKeys.country,
    //   encodeURIComponent(selectedCountries.map(getLabel).join(';'))
    // )
    // query.set(
    //   urlParamsKeys.category,
    //   encodeURIComponent(selectedCategory.label)
    // )
    // if (
    //   advOptChecked(selectedFilterOpts, SUB_CATEGORY) &&
    //   selectedSubCategory
    // ) {
    //   query.set(
    //     urlParamsKeys.subCategory,
    //     encodeURIComponent(selectedSubCategory.label)
    //   )
    // }
    // history.replace(`?${query.toString()}`)
  }

  const handleApply = async () => {
    cancelRequest()
    setExpandedTrend('')
    resetATAList()
    resetATAComparisonList([])
    setLoader(true)
    await props.fetchATAList(
      lensSelected,
      trendPhases.map(getLabel),
      selectedCountries.map(getLabel),
      selectedCategory.label,
      selectedSubCategory && selectedSubCategory.label,
      advOptChecked(selectedFilterOpts, FOUR_YEAR_CAGR) &&
        fourYearCAGR &&
        operationResolver(
          fourYearCAGR.label,
          'four_year_cagr_operation',
          'four_year_cagr',
        ),
      advOptChecked(selectedFilterOpts, TWO_YEAR_CAGR) &&
        twoYearCAGR &&
        operationResolver(
          twoYearCAGR.label,
          'two_year_cagr_operation',
          'two_year_cagr',
        ),
      analysisType,
      { cancelToken: source.token },
    )
    updateQueryParams()
    setAppliedFilterOpts({
      trendType: trendPhases.map(getLabel),
      cnCategory: selectedCN.map(getLabel),
      country: selectedCountries.map(getLabel),
      category: selectedCategory.label,
      twoycagr: advOptChecked(selectedFilterOpts, TWO_YEAR_CAGR)
        ? twoYearCAGR
        : null,
      cagr:
        advOptChecked(selectedFilterOpts, FOUR_YEAR_CAGR) && fourYearCAGR
          ? fourYearCAGR
          : null,
      analysisType:
        advOptChecked(selectedFilterOpts, TWO_YEAR_CAGR) &&
        advOptChecked(selectedFilterOpts, FOUR_YEAR_CAGR) &&
        fourYearCAGR
          ? analysisType
          : null,
      lens: lensSelected,
      selectedSubCategory: selectedSubCategory && selectedSubCategory.label,
    })
    setLoader(false)
    const ampiEvent = 'Clicked_Advanced_Apply'
    const ampiUserData = {
      lens: lensSelected,
      maturityPhase: trendPhases,
      country: selectedCountries,
      category: selectedCategory,
      filters: selectedFilterOpts,
    }
    amp(ampiEvent, ampiUserData)
  }

  const handleTrendClick = (trendName) => () => {
    logTrendExpansion(trendName)
    setExpandedTrend(trendName)
    if (trendName) {
      handleFetchTrends(trendName, appliedFilterOpts.lens)
    }
  }

  const cancelRequest = () => {
    source.cancel()
    source = axiosClass.CancelToken.source()
  }

  const handleFetchTrends = (trendName, lensSelected) => {
    resetATAComparisonList([])
    cancelRequest()
    setCurrentTrend(trendName)
    advancedTrendAnalysisList.commonTrends[trendName].forEach(({ id }) => {
      getProductDetails(
        { trendID: id, trendName },
        lensSelected,
        selectedCN.sort((a, b) => a.key - b.key).map(getLabel),
        selectedSubCategory && selectedSubCategory.label,
        { cancelToken: source.token },
      )
    })
  }

  const handleExcelDownload = () => {
    setShowOverlayLoader(true)
    const requestBody = {
      order: advancedTrendAnalysisList.order,
      commonTrends: advancedTrendAnalysisList.order.reduce((acc, trend) => {
        return {
          ...acc,
          [trend]: advancedTrendAnalysisList.commonTrends[trend].map(
            (trendObj) => {
              return {
                ...trendObj,
                yoy_growth: `${trendObj.yoy_growth.toFixed(2)}%`,
                four_year_cagr: `${trendObj.four_year_cagr.toFixed(2)}%`,
              }
            },
          ),
        }
      }, {}),
      filters: {
        trend_type: appliedFilterOpts.trendType,
        cn_category: appliedFilterOpts.cnCategory,
        country: appliedFilterOpts.country,
        lens: appliedFilterOpts.lens,
        category: appliedFilterOpts.category,
        subcategory: appliedFilterOpts.selectedSubCategory || null,
        cagr_value: appliedFilterOpts.cagr && appliedFilterOpts.cagr.label,
        two_year_cagr_value: appliedFilterOpts.twoycagr
          ? appliedFilterOpts.twoycagr.label
          : null,
        analysis_type: appliedFilterOpts.analysisType,
      },
    }
    const request = NetworkingUtil.aTAdownloadExcel()
    const excelFileName = `Advanced common trends data.xlsx`
    axios
      .post(request, requestBody, { responseType: 'blob' })
      .then(({ data }) => {
        // eslint-disable-next-line
        saveAs(data, excelFileName)
        setShowOverlayLoader(false)
      })
      .catch((err) => {
        setShowOverlayLoader(false)
      })
  }

  // const filteredTrendsList = useMemo(() => {
  //   const { yoy, cagr, analysisType } = appliedFilterOpts

  //   if (advancedTrendAnalysisList && (yoy || cagr)) {
  //     const filteredKeys = []
  //     const commonTrends = Object.keys(
  //       advancedTrendAnalysisList.commonTrends
  //     ).reduce((acc, key) => {
  //       if (yoy && cagr) {
  //         // Remove all even if one doesn't match
  //         const trends = advancedTrendAnalysisList.commonTrends[key]
  //         const trueForAll = trends.every(({ yoyGrowth, fourYearCagr }) => {
  //           const { min, max } = yoy
  //           const [minCagr, maxCagr] = cagr.label
  //             .split(' to ')
  //             .map((num) => Number(num))
  //           return analysisType === andOrAnalysis.and
  //             ? yoyGrowth >= min &&
  //                 yoyGrowth <= max &&
  //                 fourYearCagr >= minCagr &&
  //                 fourYearCagr <= maxCagr
  //             : (yoyGrowth >= min && yoyGrowth <= max) ||
  //                 (fourYearCagr >= minCagr && fourYearCagr <= maxCagr)
  //         })
  //         if (trueForAll) {
  //           filteredKeys.push(key)
  //           return { ...acc, [key]: trends }
  //         }
  //         return acc
  //       } else if (yoy) {
  //         // Remove all even if one yoy doesn't satisfy the condition
  //         const trends = advancedTrendAnalysisList.commonTrends[key]
  //         const trueForAll = trends.every(({ yoyGrowth }) => {
  //           const { min, max } = yoy
  //           return yoyGrowth >= min && yoyGrowth <= max
  //         })

  //         if (trueForAll) {
  //           filteredKeys.push(key)
  //           return { ...acc, [key]: trends }
  //         }
  //         return acc
  //       } else {
  //         // Remove all even if one cagr doesn't satisfy the condition
  //         const trends = advancedTrendAnalysisList.commonTrends[key]
  //         const trueForAll = trends.every(({ fourYearCagr }) => {
  //           const [minCagr, maxCagr] = cagr.label
  //             .split(' to ')
  //             .map((num) => Number(num))
  //           return fourYearCagr >= minCagr && fourYearCagr <= maxCagr
  //         })

  //         if (trueForAll) {
  //           filteredKeys.push(key)
  //           return { ...acc, [key]: trends }
  //         }
  //         return acc
  //       }
  //     }, {})

  //     const order = advancedTrendAnalysisList.order.reduce(
  //       (acc, trendName) =>
  //         filteredKeys.includes(trendName) ? [...acc, trendName] : acc,
  //       []
  //     )
  //     return {
  //       order,
  //       commonTrends,
  //     }
  //   }
  //   return advancedTrendAnalysisList
  // }, [appliedFilterOpts, advancedTrendAnalysisList])

  return (
    <>
      {(showOverlayLoader || loadingRequests) && <OverlayLoader />}
      <Grid
        container
        mb={isDualGrowthSelections ? 0 : 1.1}
        spacing={2}
        columns={12}
        mt={0.3}
      >
        <Grid item xs={6} md={2.3}>
          <Stack spacing={0.7}>
            <Typography variant="subtitle2">Lens</Typography>
            <Stack direction="row">
              <ToggleButtonGroup
                value={lensSelected}
                exclusive
                onChange={handleToggleSelection(setLensSelected)}
                aria-label="lens"
              >
                {availableLenses.map((lens) => (
                  <ToggleButton value={lens} aria-label={`${lens} lens`}>
                    {`${lens}s`}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} md={1.2}>
          <Stack xs={12} spacing={0.7}>
            <Typography variant="subtitle2">Maturity Phase</Typography>
            <Multiselect
              textFieldProps={{ autoComplete: 'off' }}
              // enableSelectAll
              placeholder="e.g. Dormant"
              value={trendPhases}
              onChange={setTrendPhases}
              options={trendMaturityPhases}
            />
          </Stack>
        </Grid>
        <Grid item xs={6} md={1.2}>
          <Stack xs={12} spacing={0.7}>
            <Typography variant="subtitle2">Country</Typography>
            <Multiselect
              enableSearch
              searchPlaceholder="Search countries"
              placeholder="e.g. China"
              textFieldProps={{ autoComplete: 'off' }}
              value={selectedCountries}
              onChange={setSelectedCountries}
              options={countriesList}
            />
          </Stack>
        </Grid>
        <Grid item xs={6} md={1.2}>
          <Stack xs={12} spacing={0.7}>
            <Typography variant="subtitle2">Category</Typography>
            <SingleSelect
              placeholder="e.g. Snacks"
              options={categoriesList || []}
              value={selectedCategory || null}
              onChange={(ev, val) => setSelectedCategory(val)}
              sx={{
                width: '100%',
                height: 35,
                '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                  height: '100%',
                },
              }}
            />
          </Stack>
        </Grid>
        {!isDualGrowthSelections ? (
          selectedFilterOpts
            .filter(({ checked }) => checked)
            .map((data) => displayFilterOpts(data))
        ) : (
          <>
            {selectedFilterOpts
              .filter(({ checked, key }) => checked && key === 0)
              .map((data) => displayFilterOpts(data))}
            <Grid item xs={12} md={3.2}>
              <Grid
                container
                xs={12}
                spacing={1}
                sx={{
                  backgroundColor: ({ palette }) => `${palette.primary.main}08`,
                  pb: '10px',
                  pr: '10px',
                  borderRadius: '4px',
                }}
              >
                {displayFilterOpts(advancedFilterOptions[1], true)}
                <Grid item xs={6} md={4}>
                  <Stack xs={12} spacing={0.7}>
                    <Typography variant="subtitle2">Analysis type</Typography>
                    <Stack direction="row">
                      <ToggleButtonGroup
                        value={analysisType}
                        exclusive
                        onChange={handleToggleSelection(setAnalysisType)}
                        aria-label="ananlysis type"
                        sx={{
                          width: '100%',
                          background: ({ palette }) => palette.background.paper,
                        }}
                      >
                        {Object.keys(andOrAnalysis).map((key) => (
                          <ToggleButton
                            sx={{ width: '100%' }}
                            value={andOrAnalysis[key]}
                            aria-label={`${andOrAnalysis[key]} analysis`}
                          >
                            {andOrAnalysis[key].toUpperCase()}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>
                </Grid>
                {displayFilterOpts(advancedFilterOptions[2], true)}
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          sx={{
            marginTop: 'auto',
            marginBottom: isDualGrowthSelections ? '10px' : 0,
          }}
          item
          xs={4}
          md={0.7}
        >
          <Button
            onClick={handleApply}
            sx={{ marginBottom: '1px', height: 35 }}
            variant="contained"
            size="medium"
            disabled={
              !trendPhases.length ||
              !selectedCountries.length ||
              !selectedCategory
            }
          >
            Apply
          </Button>
        </Grid>
        <Grid
          sx={{
            marginTop: 'auto',
            marginBottom: isDualGrowthSelections ? '10px' : 0,
          }}
          item
          xs={6}
          md={allFilterSelected ? 0.5 : 2}
        >
          <BtnMultiSelectDropdown
            useParentAnchor
            buttonContent={{
              content: (
                <>
                  <AddIcon />
                  {!allFilterSelected && ' Add advanced filters'}
                </>
              ),
            }}
            optWidth={177}
            options={selectedFilterOpts}
            onOptClick={handleFilterClick}
            buttonProps={{
              sx: {
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <Grid item xs={6} md={2.25}>
          <Multiselect
            placeholder="Include consumer needs category"
            // enableSelectAll
            textFieldProps={{ autoComplete: 'off' }}
            onChange={setSelectedCN}
            options={consumerNeeds}
          />
        </Grid>
        {/* <Grid sx={{ marginLeft: 'auto' }}>
          {advancedTrendAnalysisList &&
            !!advancedTrendAnalysisList.order.length && (
              <Tooltip
                title={`Download ${
                  props.subscriptionDetails.common_trends_download_count < 0
                    ? 'All'
                    : `Top ${props.subscriptionDetails.common_trends_download_count}`
                } Trends based on Datapoints`}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ height: '38px' }}
                  startIcon={<Download />}
                  onClick={handleExcelDownload}
                >
                  Download Excel
                </Button>
              </Tooltip>
            )}
        </Grid> */}
      </Grid>
      {loader ? (
        <Grid
          container
          sx={{ height: 456 }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      ) : !advancedTrendAnalysisList ? (
        <SearchNotSelected />
      ) : advancedTrendAnalysisList &&
        advancedTrendAnalysisList.order.length ? (
        <AdvancedTrendAnalysisTable
          advancedTrendAnalysisList={advancedTrendAnalysisList}
          showSubCat={!!appliedFilterOpts.selectedSubCategory}
          expandedTrend={expandedTrend}
          lensApplied={appliedFilterOpts.lens.toLowerCase()}
          handleTrendClick={handleTrendClick}
          productComparisonData={advancedTrendsComparisonList}
        />
      ) : (
        <NoResultsFound title="the selected filters" />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    advancedTrendAnalysisList:
      state.advancedTrendAnalysis.advancedTrendAnalysisList,
    advancedTrendsComparisonList:
      state.advancedTrendAnalysis.advancedTrendsComparisonList,
    projectsList: state.savedTrendRequester.savedTrendList,
    subCategories: state.advancedTrendAnalysis.subCategories,
    loadingRequests: state.downloadSections.loadingRequests,
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchATAList: (
      lens,
      maturityPhase,
      countries,
      category,
      subCategory,
      cagr4Year,
      twoYearCAGR,
      analysisType,
      cancelToken,
    ) =>
      dispatch(
        actions.fetchAdvancedTrendAnalysisList(
          lens,
          maturityPhase,
          countries,
          category,
          subCategory,
          cagr4Year,
          twoYearCAGR,
          analysisType,
          cancelToken,
        ),
      ),
    getProductDetails: (trend, lens, fetchCN, subCategory, cancelToken) =>
      dispatch(
        actions.fetchProductDetailsForAdvancedTrend(
          trend,
          lens,
          fetchCN,
          subCategory,
          cancelToken,
        ),
      ),
    getSubcategoriesList: (category, countries, lens) =>
      dispatch(actions.getSubcategoriesList(category, countries, lens)),
    requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
    resetATAList: () => dispatch(actions.fetchATAListSuccess()),
    resetATAComparisonList: () => dispatch(actions.resetATAComparisonList()),
    setCurrentTrend: (trend) => dispatch(actions.setCurrentTrend(trend)),
  }
}

AdvancedTrendAnalysis.defaultProps = {
  isProductCategory: false,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(AdvancedTrendAnalysis, axios)),
)
