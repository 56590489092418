import React, { useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'

import { useNestedMultiSelectDropdownState } from '../../../hooks'
import NestedMultiSelectDropdown from '../NestedMultiSelectDropdown'

/**
 * This Component is used as a Filter dropdown (single level or nested)
 * @param {Object} updatedFilterData  is the state in parent which changes only when API is called
 * @param {Function} stateChangeCallback  is the callback which gets called when current filterState changes
 * @param {Object} filterTagsAppliedFilters  is the state in parent that contains applied filters and will change only when we delete any chip from DropdownSelectedFilteredTags
 *
 */
const NestedMultiSelectDropdownContainer = (props) => {
  const {
    initialFilterState,
    updatedFilterData,
    stateChangeCallback,
    filterTagsAppliedFilters,
    ...childProps
  } = props

  const updatedInitialFilterState = useMemo(() => {
    if (_.isEmpty(initialFilterState)) {
      return {
        all: {},
        applied: {},
        selected: {},
      }
    }
    return initialFilterState
  }, [initialFilterState])

  const [filterState, setFilterState] = useNestedMultiSelectDropdownState(
    updatedInitialFilterState,
  )
  const firstRender = useRef(true)

  const handleFilterStateChange = (updatedFilterState) =>
    stateChangeCallback(updatedFilterState)

  useEffect(() => {
    setFilterState(updatedFilterData, 'all')
  }, [updatedFilterData])

  useEffect(() => {
    handleFilterStateChange(filterState)
  }, [filterState.all, filterState.applied, filterState.selected])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      setFilterState(filterTagsAppliedFilters, 'applied')
      setFilterState(filterTagsAppliedFilters, 'selected')
    }
  }, [filterTagsAppliedFilters])

  return (
    <NestedMultiSelectDropdown
      filterState={filterState}
      setFilterState={setFilterState}
      {...childProps}
    />
  )
}

NestedMultiSelectDropdownContainer.defaultProps = {
  initialFilterState: {
    all: {},
    selected: {},
    applied: {},
  },
}

export default NestedMultiSelectDropdownContainer
