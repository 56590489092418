import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgressCustom from '../CircularProgressCustom'

const OverlayLoader = ({ size }) => (
  <Backdrop
    open
    sx={{
      backgroundColor: 'rgba(250, 250, 250, 0.1)',
      color: '#ececed',
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
  >
    <CircularProgressCustom size={size} />
  </Backdrop>
)

export default OverlayLoader
