import React from 'react'
import Searchdata from '../../../assets/images/testImages/Searchdata.svg'
import { Box, Typography } from '@mui/material'

const SearchNotSelected = (props) => {
  const { boxStyles, text, icon, textProps } = props
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: 400,
          gap: 3,
          flexDirection: 'column',
          ...boxStyles,
        }}
      >
        <Box
          component="img"
          src={icon || Searchdata}
          alt="select opiton to search"
        />
        <Typography
          variant={textProps?.variant || 'subtitle1'}
          textAlign="center"
          {...textProps}
        >
          {text || `Select above data to view comparison of trends`}
        </Typography>
      </Box>
    </>
  )
}

export default SearchNotSelected
