import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import RegionalAndAgeGroupDistributionGraphUI from '../RegionalAndAgeGroupDistributionGraph/RegionalAndAgeGroupDistributionGraphUI'
const RegionalAndAgeGroupDistributionGraph = ({
  projectId,
  themeId,
  lens,
  country,
  category,
  ageGroupTab,
  trendName,
  subscriptionDetails,
}) => {
  const [isDemographicEnabled, setIsDemographicEnabled] = useState(true)
  const [isRegionalEnabled, setIsRegionalEnabled] = useState(true)
  const [hasDemographyData, setHasDemographyData] = useState(true)
  const [hasRegionalData, setHasRegionalData] = useState(true)

  const handleDemographyDataChange = (data) => {
    setHasDemographyData(!!data?.length)
  }
  const handleRegionalDataChange = (data) => {
    setHasRegionalData(!!data?.length)
  }

  useEffect(() => {
    if (subscriptionDetails && Object.keys(subscriptionDetails).length) {
      setIsDemographicEnabled(subscriptionDetails?.demographic_analytics)
      setIsRegionalEnabled(subscriptionDetails?.regional_analytics)
    }
  }, [subscriptionDetails])

  return (
    <>
      {(isDemographicEnabled || isRegionalEnabled) && (
        <RegionalAndAgeGroupDistributionGraphUI
          country={country}
          trendName={trendName}
          category={category}
          ageGroupTab={ageGroupTab}
          projectId={projectId}
          lens={lens}
          themeId={themeId}
          hasDemographyData={hasDemographyData}
          hasRegionalData={hasRegionalData}
          demographyDataCallback={handleDemographyDataChange}
          regionalDataCallback={handleRegionalDataChange}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

export default connect(
  mapStateToProps,
  null,
)(RegionalAndAgeGroupDistributionGraph)
