class ProductConsumerNeeds {
  constructor (response) {
    this._list = response.map(list => {
      return new ConsumerNeedsList(list)
    })
  }

  get list () {
    return this._list
  }
}

class ConsumerNeedsList {
  constructor (response) {
    this._mappingID = response.mappingID
    this._keyword = response.keyword
    this._iconURI = response.iconURI
    this._searchCriteria = response.searchCriteria
    this._score = response.score
    this._growthCoefficient = response.growth_coefficient
    this._positiveScore = response.positive_score
    this._neutralScore = response.neutral_score
    this._negativeScore = response.negative_score
  }

  get mappingID () {
    return this._mappingID
  }

  get searchCriteria () {
    return this._searchCriteria
  }

  get keyword () {
    return this._keyword
  }

  get iconURI () {
    return this._iconURI
  }

  get score () {
    return this._score
  }

  get positiveSentiment () {
    return this._positiveScore
  }

  get neutralSentiment () {
    return this._neutralScore
  }

  get negativeSentiment () {
    return this._negativeScore
  }
}

export default ProductConsumerNeeds
