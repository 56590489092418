import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import InformationIcon from '../InformationIcon'

const GenericTableHead = ({
  headCells,
  orderBy,
  order,
  handleRequestSort,
  headHeight,
  border,
  removePadding,
  tooltipOpenHandle,
  otherFavorites,
  tableCellVariant,
  tableCellSX,
}) => {
  return (
    <TableHead height={headHeight && headHeight}>
      <TableRow sx={{ border: border }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={tableCellSX}
          >
            {headCell.sorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={handleRequestSort(headCell.id)}
                sx={{
                  display: 'flex',
                  '& .MuiSvgIcon-root': {
                    marginLeft: removePadding
                      ? 0
                      : (headCell.id === 'name' && !otherFavorites) ||
                        headCell.id === 'trend_Name' ||
                        headCell.id === 'data_points'
                      ? '-50px'
                      : headCell.id === 'rank' && '-40px',
                  },
                  marginRight: headCell.id === 'rank' && '30px',
                }}
              >
                <Typography
                  variant={tableCellVariant || 'subtitle2'}
                  width={`${headCell.width} !important`}
                  color={
                    !otherFavorites ? (theme) => theme.palette.primary.main : ''
                  }
                  textAlign="left"
                  padding="7px"
                >
                  {headCell.label}
                </Typography>
                {headCell.tooltipText ? (
                  <InformationIcon
                    onTooltipOpen={() =>
                      tooltipOpenHandle && tooltipOpenHandle(headCell)
                    }
                    tooltipText={headCell.tooltipText}
                    sx={{
                      marginLeft: '-3px !important',
                      display: 'inline',
                    }}
                  />
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography
                variant={tableCellVariant || 'subtitle2'}
                width={`${headCell.width} !important`}
                color={
                  !otherFavorites ? (theme) => theme.palette.primary.main : ''
                }
                textAlign="left"
                padding="7px"
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

GenericTableHead.propTypes = {
  headCell: PropTypes.arrayOf(PropTypes.object),
  orderBy: PropTypes.string,
  order: PropTypes.string,
  handleRequestSort: PropTypes.func,
  headHeight: PropTypes.number,
  border: PropTypes.string,
}

export default GenericTableHead
