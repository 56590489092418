import * as React from 'react'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import Moment from 'moment'
import {
  Divider,
  Typography,
} from '@mui/material';
import DateRangePickerChartMui from '../../DateRangePickerChart/DateRangePickerChartMui'
import { connect } from 'react-redux'
import _ from 'lodash'

import { dateRange } from '../../../const'
import * as actions from '../../../store/actions'
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
// chart 3

export class ProductComparisonChartMui extends React.Component {
  state = {
    intervalMonths: true,
    focusedInput: null,
    datePicker: [],
    startDate: Moment(),
    endDate: Moment(),
  }

  componentDidMount () {
    const { products } = this.props
    let predictiveEndDate = dateRange.default.end
    if(products && products.length){
      // Find maximum end date
      products.forEach((product) => {
        product._predictiveTrendChart.forEach( predictionData => {
          if(Moment(predictionData._period).isAfter(predictiveEndDate)){
            predictiveEndDate = Moment(predictionData._period)
          }
        })
      })
    }
    this.setState({
      startDate: dateRange.default4yearsBack.start,// dateRange.calendarDefault.start,
      endDate: predictiveEndDate,
    })

    document.querySelectorAll('.chart-9999 svg .apexcharts-xaxis text')
    // If the year mark, then make it bold
      .forEach(el => {
        if (el.getAttribute('font-weight') == 600) {
          el.setAttribute('fill', '#192b8d')
        } else {
          el.setAttribute('fill', '#959595')
        }
      })
  }

  componentDidUpdate () {
    const { products } = this.props
    //Removing the prediction label in the legend
    const labels = document.getElementsByClassName("apexcharts-legend-series")
    products.forEach( (product,i) => {
      if(product._predictiveTrendChart.length > 0){
        if(labels && labels[i + 1]){
          labels[i + 1].remove()
        }
      }
    })
  }


  saveGraphSection = () => {
    this.props.spinnerDownload(true)
    // Set html2canvas dataset ignore to true to prevent it from being rendered in the final download
    const dateDropDown = document.querySelector(".monthly_trends__tools")
    if(dateDropDown)
      dateDropDown.dataset.html2canvasIgnore = true
    const graphElem = document.querySelector(".chart-9999")

    // Get the canvas of Monthly trend graph section
    html2canvas(graphElem).then(canvas => {
      canvas.toBlob( (blob) => window.saveAs(blob,`Compared trends graph.png`));
      this.props.spinnerDownload(false)
  }).catch(err => {
    this.props.spinnerDownload(false)
  })
    if(dateDropDown)
      dateDropDown.dataset.html2canvasIgnore = false
  }

  /**
   * Change date
   * @param ev {string}
   * @param date {Moment}
   */
  handleChangeDate = (ev, date) => {
    this.setState({ [ev]: date })
  }

  /**
   * Get options chart
   * @returns {{dataLabels: {enabled: boolean}, xaxis: {tooltip: {enabled: boolean}, type: string, title: {text: *}, lines: {show: boolean}, axisBorder: {show: boolean}, labels: {show: boolean, style: {fontFamily: string, color: string, cssClass: string, fontSize: string}}}, grid: {xaxis: {lines: {show: boolean}}, borderColor: string, strokeDashArray: number, column: {opacity: number, colors: [string, string]}, row: {opacity: number, colors: [string, string]}}, legend: {showForNullSeries: boolean, show: boolean, labels: {useSeriesColors: boolean, colors: *}, formatter: *, offsetX: number, itemMargin: {horizontal: number, vertical: number}, tooltipHoverFormatter: *, fontFamily: string, offsetY: number, showForSingleSeries: boolean, horizontalAlign: string, floating: boolean, width: *, fontSize: string, showForZeroSeries: boolean, position: string, height: *}, tooltip: {shared: boolean, x: {formatter: (function(*=, {series: *, seriesIndex: *, dataPointIndex: *, w: *}): string), show: boolean}, enabled: boolean}, title: {text: *}, fill: {gradient: {inverseColors: boolean, gradientToColors: *, shadeIntensity: number, shade: string, stops: number[], colorStops: [], type: string, opacityTo: number, opacityFrom: number}, opacity: number, type: string, colors: [string]}, stroke: {lineCap: string, curve: string, show: boolean, width: number, dashArray: number, colors: *}, chart: {toolbar: {autoSelected: string, show: boolean, tools: {download: boolean, selection: boolean, customIcons: [], zoomin: boolean, reset: boolean, zoom: boolean, zoomout: boolean, pan: boolean}}, zoom: {enabled: boolean}}, yaxis: {tickAmount: number, min: number, decimalsInFloat: number, max: number, title: {text: string}, lines: {color: string, show: boolean}, labels: {show: boolean, style: {fontFamily: string, color: string, cssClass: string, fontSize: string}}}, colors: [string, string, string, string]}}
   */
  getOptions = () => {
    const colors = ['#192b8d', '#c95b5b', '#F66E62', '#FFC901']
    const colorApplied = []
    const markerDimension = []
    const { startDate, endDate } = this.state
    const { products } = this.props
    let wholeData = []
    let series = []
    const dashArray = []

    if (products && products.length) {
      const leastDPCount = products.reduce((least, product)=> least > product.unFormatedDataPoint? product.unFormatedDataPoint : least, products[0].unFormatedDataPoint)
      products.forEach((product, i) => {
        colorApplied.push(colors[i])
        markerDimension.push(12)

        const data = product._trendChart.map(t => ([
          new Date(t._period).getTime(),
          t._engagementScore * (product.unFormatedDataPoint/leastDPCount),
        ]));

        dashArray.push(0)
        const predictiveData = product._predictiveTrendChart.map(t => ([
          new Date(t._period).getTime(),
          t._engagementScore * (product.unFormatedDataPoint/leastDPCount),
        ]))

        // wholeData.push([...data,...predictiveData])
        wholeData = wholeData.concat([...data,...predictiveData])
        // const maxScore = wholeData[i].reduce((max, d) => max < d[1] ? d[1] : max, -1)
        // series.push({name: product.name,data,maxScore})
        series.push({name: product._name,data})
        if(predictiveData.length > 0){
          series.push({name: product._name+" Prediction",
          data:[data[data.length - 1],...predictiveData]}) // To join the prediction line with the actual data line
          // data:[data[data.length - 1],...predictiveData],maxScore}) // To join the prediction line with the actual data line
          dashArray.push(2)
          colorApplied.push(colors[i])
          markerDimension.push(0)
          // colorApplied.push("")
        }
      })
    }
    const maxScore = wholeData.reduce((max, d) => max < d[1] ? d[1] : max, -1)
    // const maxScore = wholeData.map( combinedData => combinedData.reduce((max, d) => max < d[1] ? d[1] : max, 0))
    let maxDataPointDate = dateRange.calendarDefault.start;
    series = series.map(seriesItem => {
      return {
        ...seriesItem,
        data: seriesItem.data.map(itemData => {
          const value = ((itemData[1]/ maxScore) * 100)
          // const value = ((itemData[1]/ seriesItem.maxScore) * 100)
          // console.log(((itemData[1]/ seriesItem.maxScore)*100).toFixed(2),"Item")  || value.toFixed(2)
          return [itemData[0], parseInt(value)]
        })
          .filter(point => {
            const date = new Date(point[0])
            if(Moment(date).isAfter(maxDataPointDate)){
              maxDataPointDate = Moment(date)
            }
            return (Moment(date).isBefore(endDate) && Moment(date).isAfter(startDate)) || Moment(date).isSame(startDate,'day') || Moment(date).isSame(endDate,'day')
          }),
      }
    })
    let maxY = 0
    for ( let i = 0; i < series.length; i++) {
      // debugger;
      if (series[i].data.length === 0) continue
      const max = _.maxBy(series[i].data, item => item[1])
      if(max && max[1] >= maxY)
        maxY = max[1];
    }

    const options = {
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: dashArray,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: 'pan',
        },
        zoom: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return Moment(value).format('MMM \'YY')
          },
        },
        // custom: ({series, seriesIndex, dataPointIndex, w}) => {
        //   debugger
        //   const seriesName = w.globals.seriesNames[seriesIndex];
        //   const value = Math.round(series[seriesIndex][dataPointIndex]);
        //   const color = w.globals.colors[seriesIndex];
        //   const date = w.globals.seriesX[seriesIndex][dataPointIndex]
        //   const textDate = Moment(date).format('MMMM D');
        //   return `
        //       <div class="marker">
        //         <div class="arrow_box">
        //           <span class="arrow_box__title">${textDate}</span>
        //           <div class="marker_data">
        //             <div class="marker_data-row">
        //               ${seriesName} <span class="marker_data-row-circle" style="color: ${color}"></span> ${value}
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     `
        // }
      },
      colors: colorApplied,
      dataLabels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#ced0d5',
        strokeDashArray: 12,
        row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0,
        },
        column: {
          colors: ['#f8f8f8', 'transparent'],
          opacity: 0,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Sep','Sep'],
        type: 'datetime',
        title: {
          text: undefined,
        },
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        min: 0,
        max: maxY,
        title: {
          text: 'Engagement score',
        },
        lines: {
          show: false,
          color: '#0f0',
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-yaxis-label',
          },
        },
      },
      fill: {
        colors: ['#fefefe'],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'right',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        width: undefined,
        height: undefined,
        formatter: function (seriesName,opts) {
          return seriesName.includes("Prediction") ? "" : seriesName
        },
        tooltipHoverFormatter: undefined,
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers:{// To make the prediction name label hidded
            width: markerDimension,
            height: markerDimension,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 5,
        },
      },
    }

    return { options, series, maxDataPointDate }
  };

  getOptionsMarketTrend = () => {
    const colors = ['#192b8d', '#c95b5b', '#F66E62', '#FFC901']
    const colorApplied = []
    const markerDimension = []
    const { startDate, endDate } = this.state
    const { products } = this.props
    let wholeData = []
    let series = []
    const dashArray = []

    if (products && products.length) {
      // const leastDPCount = products.reduce((least, product)=> least > product.unFormatedDataPoint? product.unFormatedDataPoint : least, products[0].unFormatedDataPoint)
      products.forEach((product, i) => {
        colorApplied.push(colors[i])
        markerDimension.push(12)

        const data = product._trendChart.map(t => ([
          new Date(t._period).getTime(),
          // t.engagementScore * (product.unFormatedDataPoint/leastDPCount),
          t._engagementScore
        ]));

        dashArray.push(0)
        const predictiveData = product._predictiveTrendChart.map(t => ([
          new Date(t._period).getTime(),
          // t.engagementScore * (product.unFormatedDataPoint/leastDPCount),
          t._engagementScore
        ]))
        // console.log(predictiveData,product._predictiveTrendChart,product.name,"PredictivetrendChart")

        wholeData.push([...data,...predictiveData])
        // wholeData = wholeData.concat([...data,...predictiveData])
        const maxScore = wholeData[i].reduce((max, d) => max < d[1] ? d[1] : max, -1)
        // series.push({name: product.name,data,maxScore})
        series.push({ name: `${product._name}: ${product._country}. ${product._category.slice(0,5)}.` ,data, country: product._country, category: product.category, maxScore: maxScore })
        if(predictiveData.length > 0){
          series.push({name: product._name+" Prediction",
          data:[data[data.length - 1],...predictiveData], maxScore: maxScore}) // To join the prediction line with the actual data line
          // data:[data[data.length - 1],...predictiveData],maxScore}) // To join the prediction line with the actual data line
          dashArray.push(2)
          colorApplied.push(colors[i])
          markerDimension.push(0)
          // colorApplied.push("")
        }
      })
    }
    // const maxScore = wholeData.reduce((max, d) => max < d[1] ? d[1] : max, -1)
    // console.log(wholeData,"maxScore",maxScore)
    // const maxScore = wholeData.map( combinedData => combinedData.reduce((max, d) => max < d[1] ? d[1] : max, 0))
    let maxDataPointDate = dateRange.calendarDefault.start;

    series = series.map(seriesItem => {
      // let maxScore = seriesItem.data.reduce((max, d) => max < d[1] ? d[1] : max, -1)
      let maxScore = seriesItem['maxScore'];

      return {
        ...seriesItem,
        data: seriesItem.data.map(itemData => {
          const value = ((itemData[1]/ maxScore) * 100);
          return [itemData[0], parseInt(value)];
        })
          .filter(point => {
            const date = new Date(point[0])
            if(Moment(date).isAfter(maxDataPointDate)){
              maxDataPointDate = Moment(date);
            }
            return (Moment(date).isBefore(endDate) && Moment(date).isAfter(startDate)) || Moment(date).isSame(startDate,'day') || Moment(date).isSame(endDate,'day')
          }),
      }
    })
    let maxY = 0;
    for ( let i = 0; i < series.length; i++) {
      // debugger;
      if (series[i].data.length === 0) continue
      const max = _.maxBy(series[i].data, item => item[1])
      if(max && max[1] >= maxY)
        maxY = max[1];
    }

    const options = {
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: dashArray,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: 'pan',
        },
        zoom: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return Moment(value).format('MMM \'YY')
          },
        },
        // custom: ({series, seriesIndex, dataPointIndex, w}) => {
        //   debugger
        //   const seriesName = w.globals.seriesNames[seriesIndex];
        //   const value = Math.round(series[seriesIndex][dataPointIndex]);
        //   const color = w.globals.colors[seriesIndex];
        //   const date = w.globals.seriesX[seriesIndex][dataPointIndex]
        //   const textDate = Moment(date).format('MMMM D');
        //   return `
        //       <div class="marker">
        //         <div class="arrow_box">
        //           <span class="arrow_box__title">${textDate}</span>
        //           <div class="marker_data">
        //             <div class="marker_data-row">
        //               ${seriesName} <span class="marker_data-row-circle" style="color: ${color}"></span> ${value}
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     `
        // }
      },
      colors: colorApplied,
      dataLabels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#ced0d5',
        strokeDashArray: 12,
        row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0,
        },
        column: {
          colors: ['#f8f8f8', 'transparent'],
          opacity: 0,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Sep','Sep'],
        type: 'datetime',
        title: {
          text: undefined,
        },
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        min: 0,
        max: maxY,
        title: {
          text: 'Engagement score',
        },
        lines: {
          show: false,
          color: '#0f0',
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-yaxis-label',
          },
        },
      },
      fill: {
        colors: ['#fefefe'],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        width: undefined,
        height: undefined,
        formatter: function (seriesName,opts) {
          return seriesName.includes("Prediction") ? "": seriesName
        },
        tooltipHoverFormatter: undefined,
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers:{// To make the prediction name label hidded
            width: markerDimension,
            height: markerDimension,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 5,
        },
      },
    }

    return { options, series, maxDataPointDate }
  };

  // ---
  getCalculatedCharts(products) {
        return products.map((product) => {
            product._trendChart = (product._trendChart || []).map(data => ({
                    _period: data._period,
                    _confidence: data._confidence,
                    _engagementScore: data._engagementScore * product._overallEngagementScore
                })
            );
            product._predictiveTrendChart = (product._predictiveTrendChart || []).map(data => ({
                    _period: data._period,
                    _confidence: data._confidence,
                    _engagementScore: data._engagementScore * product._overallEngagementScore
                })
            );
            return product;
        });
    };

    getMax(products, chartType) {
        if(chartType == 'trendChart') {
            return products.map(product => product._trendChart.reduce((data1, data2) => data1._engagementScore > data2._engagementScore? data1._engagementScore: data2._engagementScore)).reduce((score1, score2) => score1 > score2? score1: score2);
        }
        return products.map(product => product._predictiveTrendChart.reduce((data1, data2) => data1._engagementScore > data2._engagementScore? data1._engagementScore: data2._engagementScore)).reduce((score1, score2) => score1 > score2? score1: score2);
    };

    getMin(products, chartType) {
        if(chartType == 'trendChart') {
            return products.map(product => product._trendChart.reduce((data1, data2) => data1._engagementScore < data2._engagementScore? data1._engagementScore: data2._engagementScore)).reduce((score1, score2) => score1 < score2? score1: score2);
        }
        return products.map(product => product._predictiveTrendChart.reduce((data1, data2) => data1._engagementScore < data2._engagementScore? data1._engagementScore: data2._engagementScore)).reduce((score1, score2) => score1 < score2? score1: score2);
    };

    compareAcrossGeography(products) {
        let projects = (products || []).map((product) => {
            return `${product._country} ${product._category}`; // `${product.country} ${product.category}`
        }).filter((project, index, arr) => arr.indexOf(project) === index);

        if(projects.length > 1) {
            return true;
        }
        return false;
    };

    getCalculatedCharts_v2(products) {
        return products.map((product) => {
            return {
                _name: product._name,
                _trendChart: (product._trendChart || []).map(data => ({
                        _period: data._period,
                        _confidence: data._confidence,
                        _engagementScore: data._engagementScore * product._overallEngagementScore
                    })
                ),
                _predictiveTrendChart: (product._predictiveTrendChart || []).map(data => ({
                        _period: data._period,
                        _confidence: data._confidence,
                        _engagementScore: data._engagementScore * product._overallEngagementScore
                    })
                )
            }

        });
    };

    /*
    getMax_v2(products, chartType) {
        console.log('products--max', products);
        if(chartType == 'trendChart') {
            let max = products.map(product => product.trendChart.reduce((data1, data2) => data1.engagementScore > data2.engagementScore? data1.engagementScore: data2.engagementScore)).reduce((score1, score2) => score1 > score2? score1: score2);
            console.log('Max1', max)
            return max;
        }
        let max = products.map(product => product._predictiveTrendChart.reduce((data1, data2) => data1.engagementScore > data2.engagementScore? data1.engagementScore: data2.engagementScore)).reduce((score1, score2) => score1 > score2? score1: score2);
        console.log('Max2', max)
        return max;
    }

    getMin_v2(products, chartType) {
        console.log('products--min', products);
        if(chartType == 'trendChart') {
            let min = products.map(product => product.trendChart.reduce((data1, data2) => data1.engagementScore < data2.engagementScore? data1.engagementScore: data2.engagementScore)).reduce((score1, score2) => score1 < score2? score1: score2);
            console.log('Min1', min);
            return min;
        }
        let min = products.map(product => product._predictiveTrendChart.reduce((data1, data2) => data1.engagementScore < data2.engagementScore? data1.engagementScore: data2.engagementScore)).reduce((score1, score2) => score1 < score2? score1: score2);
        console.log('Min1', min);
        return min;
    }
    */

    getMax_v3(products, chartType) { // Name will be changed later once finalised
        var arr = [];
        if(chartType == 'trendChart') {
            arr = products.map(product =>  {
                return Math.max.apply(null, product._trendChart.map(function(item) {
                    return item._engagementScore;
                }));
            })
        } else {
            arr = products.map(product =>  {
                return Math.max.apply(null, product._predictiveTrendChart.map(function(item) {
                    return item._engagementScore;
                }));
            });
        }

        const max = Math.max.apply(null, arr.map(function(value) {
            return value;
        }));
        return max;
    }

    getMin_v3(products, chartType) {
        let arr = [];
        if(chartType == 'trendChart') {
            arr = products.map(product =>  {
                return Math.min.apply(null, product._trendChart.map(function(item) {
                    return item._engagementScore;
                }));
            });
        } else {
            arr = products.map(product =>  {
                return Math.min.apply(null, product._predictiveTrendChart.map(function(item) {
                    return item._engagementScore;
                }));
            });
        }

        const min = Math.min.apply(null, arr.map(function(value) {
            return value;
        }));
        return min;
    }

  getOptions_v2 = () => {
    const colors = ['#192b8d', '#c95b5b', '#F66E62', '#FFC901'];
    const colorApplied = [];
    const markerDimension = [];
    const { startDate, endDate } = this.state;

    var { products } = this.props;
    let wholeData = [];
    let series = [];
    const dashArray = [];

    // debugger;
    if (products && products.length) {
      products = this.getCalculatedCharts_v2(products);

      let minTrendChartScoreVal = this.getMin_v3(products, 'trendChart');
      let maxTrendChartScoreVal = this.getMax_v3(products, 'trendChart');
      let minPredictiveChartScoreVal = this.getMin_v3(products, 'predictiveTrendChart');
      let maxPredictiveChartScoreVal = this.getMax_v3(products, 'predictiveTrendChart');

      if(minTrendChartScoreVal < minPredictiveChartScoreVal) {
          minPredictiveChartScoreVal = minTrendChartScoreVal;
      } else {
          minTrendChartScoreVal = minTrendChartScoreVal;
      }

      if(maxTrendChartScoreVal < maxPredictiveChartScoreVal) {
          maxTrendChartScoreVal = maxPredictiveChartScoreVal;
      } else {
          maxPredictiveChartScoreVal = maxTrendChartScoreVal;
      }
      let diffTrendChart = maxTrendChartScoreVal - minTrendChartScoreVal;
      let diffPredictiveChart = maxPredictiveChartScoreVal - minPredictiveChartScoreVal;

      // debugger
      products.forEach((product, i) => {
        colorApplied.push(colors[i])
        markerDimension.push(12)

        const data = product._trendChart.map(t => ([
          new Date(t._period).getTime(),
          (t._engagementScore - minTrendChartScoreVal) / (diffTrendChart || 1),
        ]));

        dashArray.push(0)
        const predictiveData = product._predictiveTrendChart.map(t => {
            return ([
              new Date(t._period).getTime(),
              (t._engagementScore - minPredictiveChartScoreVal) / (diffPredictiveChart || 1),
            ])
        })

        wholeData.push([...data,...predictiveData])
        // wholeData = wholeData.concat([...data,...predictiveData])
        const maxScore = wholeData[i].reduce((max, d) => max < d[1] ? d[1] : max, -1)
        series.push({name: product._name,data, maxScore: maxScore})
        // series.push({name: product.name,data})
        if(predictiveData.length > 0){
            series.push({
                name: product._name + " Prediction",
                data:[data[data.length - 1],...predictiveData],
                maxScore: maxScore
            }); // To join the prediction line with the actual data line
            dashArray.push(2);
            colorApplied.push(colors[i]);
            markerDimension.push(0);
        }
      })
    };
    // const maxScore = wholeData.reduce((max, d) => max < d[1] ? d[1] : max, -1) || 1
    // const maxScore = wholeData.map( combinedData => combinedData.reduce((max, d) => max < d[1] ? d[1] : max, 0))
    let maxDataPointDate = dateRange.calendarDefault.start;
    series = series.map((seriesItem, i) => {
      let maxScore = seriesItem['maxScore'];
      return {
        ...seriesItem,
        data: seriesItem.data.map(itemData => {
          const value = ((itemData[1]/ maxScore) * 100)
          return [itemData[0], parseInt(value)]
        })
          .filter(point => {
            const date = new Date(point[0])
            if(Moment(date).isAfter(maxDataPointDate)){
              maxDataPointDate = Moment(date)
            }
            return (Moment(date).isBefore(endDate) && Moment(date).isAfter(startDate)) || Moment(date).isSame(startDate,'day') || Moment(date).isSame(endDate,'day')
          }),
      }
    })
    let maxY = 0
    for ( let i = 0; i < series.length; i++) {
      // debugger;
      if (series[i].data.length === 0) continue
      const max = _.maxBy(series[i].data, item => item[1])
      if(max && max[1] >= maxY)
        maxY = max[1];
    }

    const options = {
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: dashArray,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: 'pan',
        },
        zoom: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return Moment(value).format('MMM \'YY')
          },
        }
      },
      colors: colorApplied,
      dataLabels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#ced0d5',
        strokeDashArray: 12,
        row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0,
        },
        column: {
          colors: ['#f8f8f8', 'transparent'],
          opacity: 0,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Sep','Sep'],
        type: 'datetime',
        title: {
          text: undefined,
        },
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        min: 0,
        max: maxY,
        title: {
          text: 'Engagement score',
        },
        lines: {
          show: false,
          color: '#0f0',
        },
        labels: {
          show: true,
          style: {
            color: '#4e4e4e',
            fontSize: '16px',
            fontFamily: 'inherit',
            cssClass: 'apexcharts-yaxis-label',
          },
        },
      },
      fill: {
        colors: ['#fefefe'],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        width: undefined,
        height: undefined,
        formatter: function (seriesName,opts) {
          return seriesName.includes("Prediction") ? "" : seriesName
        },
        tooltipHoverFormatter: undefined,
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers:{// To make the prediction name label hidded
            width: markerDimension,
            height: markerDimension,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 5,
        },
      },
    }
    return { options, series, maxDataPointDate }
  };

  render () {
    const { startDate, endDate } = this.state;
    const { products } = this.props;
    const { options, series, maxDataPointDate } = this.compareAcrossGeography(products)? this.getOptionsMarketTrend(): this.getOptions_v2();

    return (
      <div className={'chart-9999'} style={{ width: '100%', marginTop: '50px' }}>
        <div className="monthly_trends__tools">
          <Typography variant="h3"> Trend Over </Typography>
          <span className="links">
            <DateRangePickerChartMui onChange={this.handleChangeDate} graphSelectedEndDate={maxDataPointDate}/>
          </span>
          <span className='flex-left'>
          <Button  variant="outlined" size="small"  startIcon={<Download />} onClick={this.saveGraphSection}>
            Download
          </Button>
          </span>
        </div>
        <Chart options={options} series={series} type="line" height="350"/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    spinnerDownload: (isShow) => dispatch(actions.spinnerDownload(isShow)),
  }
}

export default connect(null, mapDispatchToProps)(ProductComparisonChartMui)
