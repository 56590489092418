import React, { useState } from 'react'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadButton from '../DownloadButton'

import { ProductCardsContainer } from '../FoodServiceProducts/styles'
import RelatedIngredientCard from './partials/RelatedIngredientCard'

const RelatedIngredients = (props) => {
  const [sortBy, setSortBy] = useState('Rank')
  const onSortByChange = (e) => {
    setSortBy(e.target.value)
  }
  if (props.relatedTrends && !!props.relatedTrends.length) {
    return (
      <Box
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
          padding: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h3">
                Related{' '}
                {props.blockName === 'Product'
                  ? `${props.blockName}s`
                  : props.blockName}
                &nbsp;
              </Typography>
              {props.tooltip && (
                <Tooltip title={props.tooltip}>
                  <InfoOutlinedIcon
                    sx={{ color: ({ palette }) => palette.text.secondary }}
                    fontSize="small"
                  />
                </Tooltip>
              )}
            </Box>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ mt: 0.5 }}
            >
              Trends associated with this particular topic based on consumer
              conversations
            </Typography>
          </Box>
          {!props.demographyComponent && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 160,
              }}
            >
              <Typography mr={1} width="100%" variant="subtitle2">
                Sort by
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="large"
                sx={{
                  minWidth: '120px',
                }}
              >
                <Select fullWidth defaultValue="Rank" onChange={onSortByChange}>
                  <MenuItem value="Rank">Rank</MenuItem>
                  <MenuItem value="Volume">Volume</MenuItem>
                </Select>
              </FormControl>
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <DownloadButton
                  dowImageType={props.dowImageType}
                  dowPptType={props.productType}
                  toggleImageDownload={props.toggleImageDownload}
                  togglePptDownload={
                    props.togglePptDownload
                      ? (dowPptType) =>
                          props.togglePptDownload(
                            dowPptType,
                            sortBy === 'Rank' ? 'Rank' : 'Volume',
                          )
                      : null
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mx: -2,
            py: 2,
          }}
        >
          <Divider />
        </Box>
        <Grid container spacing={4}>
          <Grid item xs>
            <ProductCardsContainer
              sx={
                props.expand
                  ? {
                      maxHeight: 'fit-content !important',
                      height: 'fit-content !important',
                      overflowY: 'auto !important',
                    }
                  : {}
              }
              container
              spacing={1}
            >
              {props.demographyComponent &&
                (
                  [...props.relatedTrends].sort(
                    (a, b) => b.datapoints - a.datapoints,
                  ) || []
                ).map((item, i) => (
                  <Grid item xs={3}>
                    <RelatedIngredientCard
                      image={item.image_url}
                      name={item.name}
                      demographyComponent={props.demographyComponent}
                      dataPoint={item.datapoints}
                    />
                  </Grid>
                ))}
              {!props.demographyComponent &&
                (
                  (sortBy === 'Rank' &&
                    [...props.relatedTrends].sort((a, b) => a.rank - b.rank)) ||
                  []
                ).map((item, i) => (
                  <Grid item xs={3} key={item.es_id}>
                    <RelatedIngredientCard
                      image={item.image_url}
                      rank={1 + i}
                      name={item.name}
                      projectid={props.projectid}
                      productid={item.id}
                      trend={item.trend}
                      lens={props.lens}
                      blockName={props.blockName}
                      country={props.country}
                      category={props.category}
                      amplitudeRedirect={props.amplitudeRedirect}
                      link={props.link}
                      type={props.type}
                      logRegionalTrendClick={props.logRegionalTrendClick}
                    />
                  </Grid>
                ))}
              {!props.demographyComponent &&
                (
                  (sortBy === 'Volume' &&
                    [...props.relatedTrends].sort(
                      (a, b) => b.volume - a.volume,
                    )) ||
                  []
                ).map((item, i) => (
                  <Grid item xs={3} key={item.es_id}>
                    <RelatedIngredientCard
                      image={item.image_url}
                      rank={1 + i}
                      name={item.name}
                      projectid={props.projectid}
                      productid={item.id}
                      trend={item.trend}
                      lens={props.lens}
                      blockName={props.blockName}
                      country={props.country}
                      category={props.category}
                      amplitudeRedirect={props.amplitudeRedirect}
                      link={props.link}
                      type={props.type}
                      logRegionalTrendClick={props.logRegionalTrendClick}
                    />
                  </Grid>
                ))}
            </ProductCardsContainer>
          </Grid>
        </Grid>
      </Box>
    )
  }
  return <></>
}

export default RelatedIngredients
