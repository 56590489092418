import React,{useEffect} from 'react'
import { connect } from 'react-redux'

import { showSingleLoader} from '../../../store/actions/downloadSections'
import classes from './Spinner.css'
import trendLoadIcon from '../../../assets/images/LoadingIcon/TrendLoader.gif'
// import mainLoadIcon from '../../../assets/images/LoadingIcon/Loader2.gif'
import mainLoadIcon from '../../../assets/images/LoadingIcon/whiteish-loader.gif';

const spinner = (props) => {
  let loadicon = mainLoadIcon
  if (props.showTrendIcon) {
    loadicon = trendLoadIcon
  }
  useEffect(() => {
    if(props.showTrendIcon){
      props.showSingleLoader(false)
      return () => props.showSingleLoader(true)
    }
  }, [])
  const style = props.style || {}
  return (
    <div style={{...(loadicon === mainLoadIcon ? {width: 500} : {}) , ...style}}className={classes.centerSpinner}>
      <div className={classes.ImageContainer}>
        <img src={loadicon} alt='Spinner Icon' className={classes.Image} />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSingleLoader: (show) => dispatch(showSingleLoader(show)),
  }
}

export default connect(null, mapDispatchToProps)(spinner)
