import styled from '@emotion/styled'
import { Chip } from '@mui/material'

export const ShowMoreChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})(({ styleProps }) => ({
  ...(!styleProps.outlinedShowMoreButton && {
    border: 'none',
    background: 'transparent',
    '&:hover': {
      border: 'none',
    },
    '&:active': {
      border: 'none',
    },
  }),
}))
