import { TreeItem, treeItemClasses } from '@mui/lab'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const TreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    flexDirection: 'row-reverse',
  },
  '& .MuiTreeItem-content:hover': {
    background: `${theme.palette.action.hover} !important`,
  },
  '& .Mui-selected': {
    background: `none !important`,
  },
  '& .Mui-focused': {
    background: `none !important`,
  },
  '& .MuiTreeItem-iconContainer': {
    color: theme.palette.icon,
  },
}))

export const EmptyStateContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})(({ styleProps }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: styleProps.height,
  textAlign: 'center',
  fontSize: 14,
  padding: '8px',
}))
