import * as AC from '../actions/actionConstants'
import Moment from 'moment'
import trendTypes from '../../faker/trendTypes'
import { optionsTypeProduct } from '../../const'

const initialState = {
  date: {
    start: Moment(`${Moment().format('YYYY')}01`, 'YYYYMM').startOf('month'),
    end: Moment(`${Moment().format('YYYY')}12`, 'YYYYMM').endOf('month'),
  },
  filter: [],             // Selected Ingredient Categories
  theme_filter: [],       // Selected Theme Categories
  product_filter:[],      // Selected Product Catgeories
  allFilter: [],          // Ingredient Category List
  allThemeFilter: [],     // Theme Catgeory List
  allProductFilter: [],   // Product Catgeory List
  trendTypes: trendTypes, // Selected trends for the main chart
}

const chartReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case AC.SAVE_FILTER:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default chartReducer
