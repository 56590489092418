import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Popover,
  Typography,
} from '@mui/material'

import React, { useMemo, useState } from 'react'

import SingleSelect from '../SingleSelect'

const PopoverDropdownsList = (props) => {
  const {
    anchorOrigin,
    transformOrigin,
    buttonContent,
    headingText,
    handleSaveButtonClick,
    allDropdownsList,
    setAllDropdownsList,
    email,
    amplitudeFn,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const isAnyOptionLoading = useMemo(() => {
    let checkIfOptionLoading = false
    for (let i = 0; i < allDropdownsList.length; i++) {
      checkIfOptionLoading = isAnyOptionLoading || allDropdownsList[i].loading
    }
    return checkIfOptionLoading
  }, [allDropdownsList])

  const handleFilterClickOpen = (event) => {
    const ampiEvent = 'Clicked_MW_TrendHighlight_Configure'
    const ampiUserData = {
      User: email,
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setAnchorEl(event.currentTarget)
  }
  const handleCloseFilter = () => {
    if (
      !allDropdownsList.filter((obj) => !obj.disableEmptyCheck && !obj.value)
        .length &&
      !isAnyOptionLoading
    ) {
      const ampiEvent = 'Clicked_MW_TrendHighlight_Configure_Action'
      const ampiUserData = {
        User: email,
        Country_Name: allDropdownsList[0]?.value || '',
        Category_Name: allDropdownsList[1]?.value || '',
        Lens: allDropdownsList[2]?.value || '',
        SubCategory_Name: allDropdownsList[3]?.value || '',
        Action: 'Cancel',
      }
      amplitudeFn(ampiEvent, ampiUserData)
      setAnchorEl(null)
    }
  }
  const handleSaveFilters = () => {
    if (
      !allDropdownsList.filter((obj) => !obj.disableEmptyCheck && !obj.value)
        .length &&
      !isAnyOptionLoading
    ) {
      if (allDropdownsList) {
        const ampiEvent = 'Clicked_MW_TrendHighlight_Configure_Action'
        const ampiUserData = {
          User: email,
          Country_Name: allDropdownsList[0]?.value || '',
          Category_Name: allDropdownsList[1]?.value || '',
          Lens: allDropdownsList[2]?.value || '',
          SubCategory_Name: allDropdownsList[3]?.value || '',
          Action: 'Save',
        }
        amplitudeFn(ampiEvent, ampiUserData)
      }
      handleSaveButtonClick(allDropdownsList)
      setAnchorEl(null)
    }
  }

  const handleDropdownChange = (objLabelToFind, newVal) => {
    setAllDropdownsList(
      allDropdownsList.map((obj) =>
        obj.label === objLabelToFind
          ? {
              ...obj,
              value: newVal?.label,
            }
          : { ...obj },
      ),
    )
  }

  const isSelectDisabled = (objLabel) => {
    const index = allDropdownsList.findIndex(
      (objToFind) => objToFind.label === objLabel,
    )
    let isDisabled = false
    for (let i = 0; i < index; i++) {
      isDisabled = isDisabled || !allDropdownsList[i].value
    }
    return isDisabled
  }

  const openTrendHighlightFilter = Boolean(anchorEl)

  return (
    <React.Fragment>
      <Button
        sx={{
          padding: 0,
          minWidth: 0,
        }}
        onClick={handleFilterClickOpen}
      >
        {buttonContent}
      </Button>
      <Popover
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }
        }
        open={openTrendHighlightFilter}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
      >
        <Typography
          variant="h3"
          sx={{
            p: 1.5,
          }}
        >
          {headingText}
        </Typography>
        <Divider />
        <Box
          display="flex"
          sx={{
            flexWrap: 'wrap',
            width: '450px',
            p: 2,
            gap: 2,
          }}
        >
          {allDropdownsList.map((obj) =>
            !obj.shouldHideCondition ||
            (obj.shouldHideCondition.showWhenOptions.includes(
              allDropdownsList.filter(
                (object) => object.label === obj.shouldHideCondition.dependency,
              )[0].value,
            ) &&
              !obj.shouldHideCondition.condition) ? (
              <Box sx={{ flexBasis: 'calc(50% - 8px)' }}>
                <SingleSelect
                  disabled={
                    obj.label !== 'Country' && isSelectDisabled(obj.label)
                  }
                  label={obj.label}
                  placeholder={`Select a ${obj.label}`}
                  options={obj.options}
                  onChange={(e, newVal) =>
                    handleDropdownChange(obj.label, newVal)
                  }
                  value={obj.value}
                  loading={obj.loading}
                  listBoxProps={{
                    style: {
                      maxHeight: '112px',
                    },
                  }}
                />
                {!obj.disableEmptyCheck && !obj.value && (
                  <Typography variant="error">
                    Please select a {obj.label}
                  </Typography>
                )}
              </Box>
            ) : null,
          )}
        </Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Button
            onClick={handleSaveFilters}
            variant="contained"
            fullWidth
            disabled={
              isAnyOptionLoading ||
              allDropdownsList.filter(
                (obj) => !obj.disableEmptyCheck && !obj.value,
              ).length
            }
          >
            {isAnyOptionLoading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Popover>
    </React.Fragment>
  )
}

export default PopoverDropdownsList
