import { TableCell, Typography, MenuItem, Tabs } from '@mui/material'
import { styled } from '@mui/system'

export const TableColName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})(({ theme, styleProps }) => ({
  color: `${theme.palette.primary.main} !important`,
  cursor: styleProps.disableCursor ? 'not-allowed' : 'pointer',
  fontSize: '14px',
  display: 'inline-block',
}))
export const TableHeaderRow = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '500 !important',
  fontSize: '16px !important',
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderTop: '1px solid #ECECEC',
  padding: '15px',
  fontSize: '12px',
  // height: '38px',
}))

export const BlueCaptionHiglight = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  fontSize: 14,
}))

export const NoteCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}))

export const MonthlyLimitTypography = styled(Typography)(() => ({
  display: 'flex',
  color: 'black',
  fontSize: '12px',
  paddingTop: '10px',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

export const CountryCategoryTypography = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
}))

export const ConceptGenieDashboardContainer = styled(Typography)(
  ({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.white,
    marginTop: '20px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  }),
)

export const ConceptGenieMainContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  height: '90vh',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
}))

export const ConceptGenieHeadline = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  paddingLeft: '10px',
  paddingRight: '10px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
}))

export const DashboardOptionsContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  paddingLeft: '10px',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
}))

export const ImageTypeTabsContainer = styled(Tabs)(({ theme }) => ({
  height: '34px',
  minHeight: '34px',
  borderRadius: '5px',
  borderBottom: 'none',
  border: `1px solid ${theme.palette.grey[300]}`,
  '& .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.white} !important`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    background: 'none !important',
  },
}))

export const SingleSelectInput = styled(Typography)(({ theme }) => ({
  width: '200px',
  height: '34px',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '5px',
}))
