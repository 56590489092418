import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import FeaturesTabUI from './FeaturesTabUI'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { useLoader } from '../../../hooks'
import OverlayLoader from '../../../shared/components/OverlayLoader'

const defaultFeats = {
  foresight: true,
  whitespace: true,
  common_trends_analysis_advanced: true,
  ingredient_feature: true,
  theme_feature: true,
  product_feature: true,
  pairing: true,
  consumer_quotes: 5,
  show_two_by_two_visualization: false,
}

const getFeatureDefaultVals = (data) => {
  const feats = _.cloneDeep(data)
  feats.features.forEach((feat) => {
    feat.value =
      feat.column_name in defaultFeats && defaultFeats[feat.column_name]
    feat.sub_features.forEach((subFeat) => {
      subFeat.value =
        subFeat.column_name in defaultFeats && defaultFeats[subFeat.column_name]
    })
  })
  return feats
}

const resolveDisabled = (data) => {
  let onlyEnabled = false
  const feats = _.cloneDeep(data)
  feats.features.forEach((feat) => {
    feat.sub_features.forEach((subFeat) => {
      if (subFeat.only_enabled !== undefined && subFeat.only_enabled) {
        onlyEnabled = true
      }
    })
    feat.sub_features = feat.sub_features.map((obj) => ({
      ...obj,
      disabled: onlyEnabled,
    }))
  })
  feats.features.forEach((feat) => {
    feat.disabled = onlyEnabled
  })
  return feats
}

const FeaturesTab = ({
  setDisablesTab,
  setTab,
  setClientModification,
  selectedRow,
  edit,
  redirectionTodo,
  setRedirectionTodo,
}) => {
  const [showLoader, setShowLoader] = useLoader()
  const [numberOfConsumerQuotes, setNumberOfConsumerQuotes] = useState()
  const [featureList, setFeatureList] = useState()
  const [subfeaturesList, setSubfeatureList] = useState()

  const getData = async () => {
    const request = NetworkUtil.userAccessManagementFeatures(
      selectedRow?.clientId,
    )
    setShowLoader(true)
    await axios.get(request).then((res) => {
      setShowLoader(false)
      const { data } = res.data
      if (data?.draft === false && edit && redirectionTodo) {
        setRedirectionTodo(false)
        selectedRow?.status === 'DRAFT' && setTab(2)
        setDisablesTab([])
      }
      if (data?.draft === true) {
        const defaultValues = resolveDisabled(getFeatureDefaultVals(data))
        setFeatureList(defaultValues)
        setSubfeatureList(defaultValues.features?.map((a) => a.sub_features))
      } else {
        const updatedData = resolveDisabled(data)
        setFeatureList(updatedData)
        setSubfeatureList(updatedData.features?.map((a) => a.sub_features))
      }
    })
  }

  const updatedInfo = async () => {
    const req = NetworkUtil.userAccessManagementFeatures(selectedRow?.clientId)
    const onlyEnabled = []
    const subfeatures = {}
    subfeaturesList.forEach((subFeatures) => {
      return subFeatures.forEach((data) => {
        if (data.only_enabled) {
          onlyEnabled.push(data.column_name)
        }
        subfeatures[data.column_name] =
          data.column_name === 'consumer_quotes'
            ? numberOfConsumerQuotes || data.value
            : data.value
      })
    })
    const feature = {}
    featureList.features.forEach((f) => {
      if (f.only_enabled) {
        onlyEnabled.push(f.column_name)
      }
      feature[f.column_name] = f.value
    })
    const featureDetails = {
      features: { ...feature, ...subfeatures },
      only_enabled: onlyEnabled.length ? onlyEnabled : undefined,
    }
    setShowLoader(true)
    await axios.put(req, featureDetails).then((res) => {
      setShowLoader(false)
      setDisablesTab([])
      if (edit === true) {
        setDisablesTab([])
      }
      setTab(2)
    })
  }

  const handleFeatureTab = () => {
    updatedInfo()
  }

  const handleNumberOfConsumerQuotes = (event) => {
    setNumberOfConsumerQuotes(event.target.value)
  }

  const handleSelectMainFeatures = (id) => {
    const newState = featureList.features.map((obj) => {
      if (obj.column_name === id) {
        return {
          ...obj,
          value: !obj.value,
        }
      }
      return obj
    })
    const updatedState = { features: newState, draft: false }
    setFeatureList(updatedState)
  }

  const handleSelectsubFeatures = (id) => {
    const updatedfeature = subfeaturesList?.map((obj) => {
      const updatedsubFeature = obj.map((i) => {
        if (i.column_name === id) {
          return {
            ...i,
            value: !i.value,
          }
        }
        return i
      })
      return updatedsubFeature
    })
    setSubfeatureList(updatedfeature)
  }

  const handleEnableOnly = (colName, currStatus) => {
    // Set main-feat to false and disabled
    const newState = featureList.features.map((obj) => {
      const newObj = {
        ...obj,
        value:
          obj.type === 'radio'
            ? colName === obj.column_name ||
              obj.column_name === 'foresight' ||
              false
            : obj.value,
        disabled: !currStatus,
      }
      if (obj.only_enabled !== undefined) {
        newObj.only_enabled = !currStatus
      }
      return newObj
    })
    const updatedState = { features: newState, draft: false }
    setFeatureList(updatedState)

    // Set sub-feat to false and disabled
    const updatedfeature = subfeaturesList?.map((obj) => {
      const updatedsubFeature = obj.map((i) => {
        const newObj = {
          ...i,
          value:
            i.type === 'radio'
              ? (colName === i.column_name && !currStatus) || false
              : i.value,
          disabled: !currStatus,
        }
        if (i.only_enabled !== undefined) {
          newObj.only_enabled = !currStatus
        }
        return newObj
      })
      return updatedsubFeature
    })
    setSubfeatureList(updatedfeature)
  }

  const handleExit = () => {
    setClientModification(0)
  }

  const handleFeatures = () => {
    updatedInfo()
    setClientModification(0)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {showLoader && <OverlayLoader />}
      <FeaturesTabUI
        handleNumberOfConsumerQuotes={handleNumberOfConsumerQuotes}
        handleSelectMainFeatures={handleSelectMainFeatures}
        handleSelectsubFeatures={handleSelectsubFeatures}
        handleEnableOnly={handleEnableOnly}
        handleFeatureTab={handleFeatureTab}
        featureList={featureList}
        subfeaturesList={subfeaturesList}
        numberOfConsumerQuotes={numberOfConsumerQuotes}
        handleExit={handleExit}
        handleFeatures={handleFeatures}
      />
    </>
  )
}
export default FeaturesTab
