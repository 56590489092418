import Moment from 'moment'
import Bowser from 'bowser'
import * as htmlToImage from '@rigocorp/html-to-image'
// import * as htmlToImage from 'html-to-image'
import ribbonimg from './assets/images/TrendIcon/TrendIcon.png'
import { saveAs } from 'file-saver'
import { browserIsIE } from './store/utility'

export const ROLE_APP_ADMIN = 'ROLE_APP_ADMIN'
export const ROLE_ORG_ADMIN = 'ROLE_ORG_ADMIN'
export const ROLE_ORG_USER = 'ROLE_ORG_USER'
export const FEATURE_TREND_SPOTTING = 'Trend Spotting'
export const FEATURE_BUILD_WINNER = 'Build Winner'

export const TrendTypes = {
  EMERGING: 1,
  GROWING: 2,
  MATURE: 3,
  TRENDING: 4,
  DECLINING: 5,
  DORMANT: 6,
  FADING: 7,
}

export const lensTypes = ['Ingredient', 'Theme', 'Product']

export const PROJECT_VARIANT = {
  FOOD: 'food',
  BPC: 'bpc',
}

export const dateRange = {
  default: {
    start: Moment(`201601`, 'YYYYMM').startOf('month'),
    end: Moment(
      `${Moment().format('YYYY')}${Moment().format('MM')}${Moment().format(
        'DD',
      )}`,
      'YYYYMMDD',
    )
      .subtract(1, 'months')
      .endOf('month'),
  },
  calendarDefault: {
    start: Moment(`201401`, 'YYYYMM').startOf('month'),
    end: Moment(
      `${Moment().format('YYYY')}${Moment().format('MM')}${Moment().format(
        'DD',
      )}`,
      'YYYYMMDD',
    )
      .subtract(1, 'months')
      .endOf('month'),
  },
  default4yearsBack: {
    start: Moment()
      .subtract(4, 'years')
      .startOf('month'),
  },
  inputData: [
    // { date: { start: '01', end: '12' }, label: 'Full Year', text: 'Jan to Dec' },
    // { date: { start: '01', end: '06' }, label: 'Half Year 1', text: 'Jan to June' },
    // { date: { start: '07', end: '12' }, label: 'Half Year 2', text: 'July to Dec' },
    {
      date: {
        start: '01',
        end: '10',
        favorite: true,
      },
      label: 'Full Time',
      text: '2014 to ' + Moment().format('YYYY'), // '2014 to 2019'
    },
  ],
}

export const colors = {
  circle: {
    negative: '#ea554e',
    positive: '#37b100',
  },
}

export const reduxFormConst = {
  permission: 'permission',
  needGrowthFilter: 'need-growth-filter',
  trendsPhasesFilter: 'trends-phases-filter',
  projectForm: 'project-form',
  loginForm: 'login-form',
  role: 'role',
  organizationPage: 'organization-page',
  registrationForm: 'registration-form',
  userPage: 'user-page',
  buildWinnerPage: 'build-winner-page',
  userProfile: 'user-profile',
  forgetPassword: 'forget-password',
  resetPassword: 'reset-password',
  downloadReport: 'download-report',
}

export const optionsTypeProduct = [
  {
    id: '01',
    value: 'Hydration(Core)',
    displayValue: 'Hydration (Core)',
  },
  {
    id: '02',
    value: 'Carbonates',
    displayValue: 'Carbonates',
  },
  {
    id: '03',
    value: 'Juice',
    displayValue: 'Juice',
  },
  {
    id: '04',
    value: 'Coffee',
    displayValue: 'Coffee',
  },
  {
    id: '10',
    value: 'Tea',
    displayValue: 'Tea',
  },
  {
    id: '11',
    value: 'Energy Drinks',
    displayValue: 'Energy Drink',
  },
]

export const tweets = {
  tweets: [
    '1175923713952235520',
    '1175924464116097024',
    '1175779048846319616',
    '1175795391310155782',
    // '1175766532590145536',
    // '1175766528743968769',
  ],
  photoTweet: '1175151567265247233',
}

export const currencySymbol = {
  THB: '฿',
  CNY: '¥',
  INR: '₹',
  SGD: '$',
  HKD: 'HK$',
  PHP: '₱',
  IDR: 'Rp',
  EUR: '€',
  USD: '$', // USA
  VND: '₫', // Vietnam
  GBP: '£', // UK
  CHF: 'Fr.', // Swiss
  CAD: '$', // Canada
  JPY: '¥', // Japan
  MYR: 'RM',
  AUD: '$',
}
export const tweetsData = Array(4).fill({
  city: 'Bangkok',
  country: 'Thailand',
  date: '2014-04-12',
  food_category: 'Beverage',
  food_product_ingredient: 'Apple',
  from_date: '2014-01-01',
  from_query:
    '\u0e19\u0e49\u0e33\u0e41\u0e2d\u0e1b\u0e40\u0e1b\u0e34\u0e49\u0e25 near:"Bangkok" within:50mi since:2014-01-01 until:2019-10-09',
  fullname: 'Donald J. Trump',
  html:
    '<p>Rachel Campos-Duffy has written a wonderful book for children, “Paloma Wants To Be Lady Freedom.” She and her husband, Sean Duffy, have done so much for our Country. Buy this book - great for the kids!</p>',
  is_retweet: 0,
  likes: '52.8K',
  lookup_type: 'ingredient',
  native_term:
    '\u0e19\u0e49\u0e33\u0e41\u0e2d\u0e1b\u0e40\u0e1b\u0e34\u0e49\u0e25',
  platform: 'twitter',
  post_image_url: '',
  profile_image_url:
    'https://pbs.twimg.com/profile_images/874276197357596672/kUuht00m_normal.jpg',
  replies: 0,
  retweet_id: '',
  retweeter_userid: '',
  retweeter_username: '',
  retweets: 0,
  text:
    'Rachel Campos-Duffy has written a wonderful book for children, “Paloma Wants To Be Lady Freedom.” She and her husband, Sean Duffy, have done so much for our Country. Buy this book - great for the kids!',
  timestamp: '2014-04-12T03:15:07',
  timestamp_epochs: 1397272507,
  to_date: '2019-10-09',
  tweet_id: '1175151567265247233',
  tweet_url: '/realDonaldTrump/status/1175151567265247233',
  user_id: '425232139',
  username: 'realDonaldTrump',
})

const browser = Bowser.getParser(window.navigator.userAgent)

export const isMobile = browser.getPlatform().type !== 'desktop'

export const socialLinks = {
  linkedin: 'https://www.linkedin.com/company/aipalette/',
  facebook: 'https://www.facebook.com/aipalette/',
  blog: 'https://medium.com/ai-palette',
  instagram: 'https://www.instagram.com/aipalette/',
}

export const platformsCombinations = {
  /* Hide Barrier feature etc. */
  pair1: [
    'https://develop-aipalette.netlify.app' /* location.origin */,
    'https://aipalette-staging-new.netlify.app',
  ],
  pair2: [
    // 'https://develop-aipalette.netlify.app', /* location.origin */
    // 'https://aipalette-staging-new.netlify.app',
    // 'http://localhost:3000'
  ],
  pair3: [
    'https://develop-aipalette.netlify.app' /* location.origin */,
    'https://aipalette-staging-new.netlify.app',
    'https://premium.aipalette.com',
  ],
}

export const downloadGraph = (node, fileName) => {
  return new Promise((resolve, reject) => {
    const { width, height } = node.getBoundingClientRect()
    // eslint-disable-next-line
    html2canvas(node, { width, height })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          saveAs(blob, fileName)
          resolve()
        })
      })
      .catch((er) => {
        reject(er)
      })
  })
}

export const downloadFullPage = (
  node,
  graphNodeSelector,
  graphOffset = {
    top: 0,
    left: 0,
  },
  drawingCanvas,
  placeholderCss = `height: 200px; background: white;`,
  fileName,
) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    const fullPage = node.cloneNode(true)
    try {
      fullPage.id = 'downloadCanvas-elem'
      const { top, left } = graphOffset
      const graphNode = document.querySelector(graphNodeSelector)
      const { width, height } = graphNode.getBoundingClientRect()
      const placeholderElem = document.createElement('div')
      placeholderElem.style.cssText = `top:0; left:0; position:absolute`
      fullPage.querySelector('header').replaceWith(placeholderElem)

      drawingCanvas.appendChild(fullPage)
      const canvas = await htmlToImage.toCanvas(drawingCanvas, {
        backgroundColor: '#fff',
        imagePlaceholder: ribbonimg,
        cacheBust: true,
      })
      // eslint-disable-next-line
      const graphCanvas = await html2canvas(graphNode, { width, height })
      // const copyCtx = canvas.getContext('2d')
      // copyCtx.drawImage(graphCanvas, left, top, width, height)
      canvas.toBlob((blob) => {
        window.saveAs(blob, fileName)
        fullPage.remove()
        resolve()
      })
    } catch (er) {
      reject(er)
      fullPage.remove()
    }
  })
}

export const downloadSection = (node, name, opts = {}) => {
  // console.log('Now Here 2nd')
  return new Promise((resolve, reject) => {
    // console.log('Now Here 3rd')

    if (!browserIsIE()) {
      /* Chrome, Mozilla etc. */
      htmlToImage
        .toJpeg(node, {
          backgroundColor: '#fff',
          imagePlaceholder: ribbonimg,
          cacheBust: true,
          ...opts,
        })
        .then((blob) => {
          window.saveAs(blob, name)
          resolve(true)
        })
        .catch((er) => {
          console.log(er)
          // eslint-disable-next-line
          reject(false)
        })
    } else {
      /* IE FIX */
      /* 1st way ================================================================ */
      // htmlToImage.toJpeg(node, {
      //   backgroundColor: '#fff',
      //   imagePlaceholder: ribbonimg,
      //   cacheBust: true
      // })
      // .then((blob) => {
      //   console.log('Now Here 4th')
      //   window.saveAs(blob, name)
      //   console.log('Now Here 5th')
      //   resolve(true)
      // })
      // .catch((er) => {
      //   console.log('Now Here 6th')
      //   console.log(er)
      //   console.log('Now Here 7th')
      //   reject(false)
      //   console.log('Now Here 8th')
      // })

      /* 2nd way================================================================ */
      // function filter (node) {
      //   return (node.tagName !== 'i');
      // }
      // cosnole.log('Trying 2nd way!')
      // htmlToImage.toSvg(node, { filter: filter })
      //   .then(function (dataUrl) {
      //     /* do something */
      //     console.log('Worked!', dataUrl)
      // }).catch(error => {
      //   console.log('Error', error)
      // });

      /* 3rd */
      // var container = document.getElementById('prod_des') //document.body; // full page
      // // var container = document.getElementByClassNames('bar__row')[0]
      // console.log('Using html2canvas', container)
      // html2canvas(container).then(function(canvas) {
      //     console.log('Worked this one!')
      //     var link = document.createElement("a");
      //     document.body.appendChild(link);
      //     link.download = "html_image.png";
      //     link.href = canvas.toDataURL("image/png");
      //     link.target = '_blank';
      //     link.click();

      //     console.log("href => ", link.href)
      //     resolve(true)
      // }).catch(error => {
      //   console.log('Error', error)
      //   reject(false)
      // });

      /* 4th way ================================================================== */
      const container = document.getElementById('prod_des')
      localStorage.setItem('html', container.outerHTML)
      // eslint-disable-next-line
      html2canvas(container)
        .then(function(canvas) {
          // console.log('Worked this one!')
          // var link = document.createElement("a");
          // document.body.appendChild(link);
          // link.download = "html_image.png";
          const uri = canvas.toDataURL('image/png')
          // link.target = '_blank';
          // link.click();

          const byteString = window.atob(uri.split(',')[1])
          const mimeString = uri
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
          // console.log('mimeString', mimeString)
          const buffer = new ArrayBuffer(byteString.length)
          const intArray = new Uint8Array(buffer)

          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i)
          }

          const blob = new Blob([buffer], { type: mimeString })
          // window.navigator.msSaveOrOpenBlob(blob, 'classification_ingredients.svg')
          // console.log('Downloading..')
          window.saveAs(blob, 'Product_Details.png')
          // console.log("href => ", link.href)
          resolve(true)
        })
        .catch((error) => {
          // console.log('Error', error)
          // eslint-disable-next-line
          reject(false)
        })
      /* Ends Here */
    }
  })
}

export const optionsQuotesSources = [
  {
    id: '01',
    value: 'instagram',
    displayValue: 'Instagram',
  },
  {
    id: '02',
    value: 'twitter',
    displayValue: 'Twitter',
  },
  {
    id: '03',
    value: 'pantip',
    displayValue: 'Blogs and Forums',
  },
]

export const notifications = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

export const subCategory = {
  NOT_APPLICABLE: 'NA',
  ALL: 'All',
}

Object.freeze(subCategory)

export const RANK_MAPPING = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  DEFAULT_MAP: 'th',
}

Object.freeze(RANK_MAPPING)

export const screeningStatus = {
  SCREENED: 'SCREENED',
  UNSCREENED: 'UNSCREENED',
  IN_PROGRESS: 'IN PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}

export const screeningResults = {
  RETHINK: 'Ordinary',
  WINNING: 'Sweet Spot',
  NICHE: 'Niche',
  LESS_COMPELLLING: 'Competitive',
}

export const screenWinnerMessages = {
  CONCEPT_EDIT_DISABLED:
    'Editing concepts is not allowed when screening is in progress.',
  CONCEPT_COPY_DISABLED:
    'Cloning concepts is not allowed when screening is in progress.',
  CONCEPT_DELETE_DISABLED:
    'Deleting concepts is not allowed when screening is in progress.',
  PROJECT_DELETE_DISABLED:
    'Deleting projects is not allowed when screening is in progress.',
  CONCEPT_ADD_DISABLED:
    'Concepts cannot be added when screening is in progress.',
  CONCEPT_CRUD_WARNING:
    'Last screened results would be nullified if you Delete/Edit/Add a concept in the Project',
  PROJECT_CRUD_WARNING: 'All the content of the Project will be deleted',
  CONCEPT_KEYWORDS_MISSING:
    'Concept does not have Theme/Ingredient in title/description, please add it to proceed with Screening',
  SCREEN_ON_CONCEPT_FIX:
    'Please add details in red highlighted concepts to Screen the projects',
  SCREEN_ON_MIN_CONCEPTS:
    'Add 15 or more concepts to get accurately data-backed results',
  PROJECT_SCREENING_MSG:
    'It may take upto 30 mins to complete the screening, you will receive an email once the screening is completed.',
  SUB_CATEGORY_INFO: `Concept scoring weightage
  Sub-category: 60% and 
  Category: 40%. 
  If Sub-category is “NA”  
  weightage on Category: 100%`,
  SCATTER_PLOT_ORIGINALITY_LABEL: `Originality is a relative value, between 1 and 100, measured by AI Palette's intelligent proprietary algorithm, by comparing your concept title and description against those of products in our ecosystem. Higher the number of similarities in attributes between those products and your concept, lower the originality score and vice versa.`,
  SCATTER_PLOT_RELEVANCE_LABEL: `Relevance is an absolute value measured by AI Palette's intelligent proprietary algorithm, based on 2 yr/4 yr engagement/growth metadata of individual ingredients and themes, and their effectiveness of usage in concepts. Note: Both originality and relevance are measured in normalized absolute scores ranging from 0-100.`,
}

export const loadingStatus = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

export const unAuthorizedPages = {
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/',
  FOUR_O_FOUR: '/404',
  LOGOUT: '/logout',
}
