import React, { useState } from 'react'
import { Button, Menu, Box, MenuItem } from '@mui/material'

export default function CustomisedDropdownButton({
  buttonLabel,
  buttonEndIcon,
  menuItemList,
  logButtonClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    logButtonClick()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        id="customized-dropdown-button"
        aria-controls={open ? 'customized-dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={buttonEndIcon}
      >
        {buttonLabel}
      </Button>
      <Menu
        id="customized-dropdown-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-dropdown-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 140,
            paddingLeft: 10,
          },
        }}
      >
        {menuItemList.map((menuItem) => {
          return (
            <MenuItem
              key={`menu-item-${menuItem.id}`}
              onClick={() => menuItem.onClick()}
              disableRipple
            >
              {menuItem.icon}
              {menuItem.label}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
