import styled from '@emotion/styled'
import { Table, Box } from '@mui/material'
import Carousel from 'react-elastic-carousel'

export const ContainerBox = styled(Box)(({ sx = {}}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 16px',
  ...sx,
}))

export const StyledTable = styled(Table)(({ theme }) => ({
  display: 'block !important',
  overflowX: 'auto',
  paddingLeft: '15px',
  borderLeft: `solid 2px ${theme.palette.divider}`,
  '& > tbody tr:last-child td': {
    borderBottom: 'none !important',
  },
  '& > tbody tr td': {
    borderRight: `2px solid ${theme.palette.divider} !important`,
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& > tbody tr td:last-child': {
    borderRight: 'none !important',
  },
  '& > tbody tr:first-child td': {
    fontWeight: '600',
  },
  '& > tbody tr:first-child td:first-child': {
    fontWeight: 'normal',
  },
  '& > tbody tr:nth-child(2) td': {
    color: `${theme.palette.secondary.main}`,
  },
  '& > tbody tr:nth-child(2) td:first-child': {
    color: `${theme.palette.text.primary}`,
  },
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
  },
}))

export const StyledCarousel = styled(Carousel)(({ theme, filterProductLength }) => ({
  '& .rec.rec-arrow-left': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-right': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark}`,
    display: 'block',
    color: `${theme.palette.secondary.dark}`,
		visibility: !filterProductLength && 'hidden',
  },

  '& .rec.rec-arrow-left:hover:enabled .rec.rec-arrow-right:hover:enabled': {
    backgroundColor: `${theme.palette.white}`,
    boxShadow: `0 0 5px 3px ${theme.palette.secondary.dark}`,
  },

  '& .rec-dot_active': {
		visibility: !filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },

  '& .rec-dot_disable': {
		visibility: filterProductLength && 'hidden',
    backgroundColor: `${theme.palette.white} !important`,
    boxShadow: `0 0 1px 3px ${theme.palette.secondary.dark} !important`,
  },
}))

export const CardContainer = styled(Box)(({ theme }) => ({
   height: 100, 
   overflow: "auto",
   width: '100%',
  '&::-webkit-scrollbar': {
    width: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 8,
  },
}));

