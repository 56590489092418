import React from 'react'
import { CircularProgress, Box, Typography } from '@mui/material'

const CircularLoaderWithText = ({ text }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <CircularProgress />
      </Box>
      <Typography pt={2}>{text}</Typography>
    </Box>
  )
}

export default CircularLoaderWithText
