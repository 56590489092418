import React from "react";

import ImageSafe from "../Image/ImageSafe";

import "./InfoChips.scss";

/**
 * Displays chips with images based on the type of data
 * to be displayed
 */
const InfoChips = ({image, text}) => {
  return (
    <div className="info-chips__block">
      {image && <ImageSafe className="info-chips__img" src={image} alt={`${text} icon`}/>}
      <span className="info-chips__text">{text}</span>
    </div>
  );
};

export default InfoChips;
