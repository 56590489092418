import { styled } from '@mui/system'
import { Fab } from '@mui/material'

export const StyledFab = styled(Fab)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: `0px 10px 20px ${theme.palette.shadow.fab.main}`,
  backgroundColor: theme.palette.white,
  color: theme.palette.primary.main,
  '&:active': {
    boxShadow: `0px 10px 20px ${theme.palette.shadow.fab.active}`,
  },
}))
