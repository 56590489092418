import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import TrendCard from '../TrendCard';
import EmergingCardContainer from './styles';
import { amplify } from '../../../store/utility';
import { AmplitudeValues } from '../../../store/amplitudeValues';


const amp = (ampiEvent, ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues();
    const amplitudeEvent = ampiEvent;
    const amplitudeUserData = {
      'User': ampiInstance._email,
      'Lens_Name': ampiUserData.lens,
      'Country_Name': ampiUserData.country,
      'Category_Name': ampiUserData.category,
      'Trend_Name':ampiUserData.name,
      'Maturity_Phase': ampiUserData.maturityPhase.toLowerCase(),
      'Redirected_From': `${ampiUserData.maturityPhase.toLowerCase()} Preview`
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance._email)

  }
  catch (err) {
    console.log(err, 'Amplitude error in EmergingTrendsCard');
  }
}

const buildLink = (p, l, i) => {
  return `/mui/details/${p}/${l.toLowerCase()}/${i}`
}

const EmergingTrendsCard = ({ tab, projectid, title, trends, ampiCountryCategory }) => {
  const { country, category } = ampiCountryCategory;

  const trendCardClickHandler = (name, trendType) => {
    const ampiEvent = "Clicked_Trend_Lens"
    const ampiUserData = {
      lens: tab,
      category: category,
      country: country,
      name: name,
      maturityPhase: trendType,
    }
    amp(ampiEvent, ampiUserData)
  }

  return (
    <EmergingCardContainer>
      <Typography variant="h4">
        {title}
      </Typography>
      <Box>
        <Box>
          {trends.map(({
            id, name, rank, image, data_point: dataPoint, trendType
          }) => (
            <Link target='_blank' onClick={() => trendCardClickHandler(name, trendType)} to={() => buildLink(projectid, tab, id)}>
              <TrendCard
                {...{ id, name, rank, image, dataPoint }}
              />
            </Link>
          ))}
        </Box>
      </Box>
    </EmergingCardContainer>
  )
};

EmergingTrendsCard.propTypes = {
  title: PropTypes.string.isRequired,
  trends: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    rank: PropTypes.number,
    image: PropTypes.string,
    data_point: PropTypes.string,
  })).isRequired,
};

export default EmergingTrendsCard;
