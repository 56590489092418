import React from "react"
import { Redirect, Route, withRouter } from "react-router-dom"
import Layout from './hoc/Layout/Layout'
import MuiLayout from './layouts/LayoutHeaderFooter'
import SWLayout from './hoc/SWLayout/SWLayout'

const PrivateRoute = ({ screenWinner, component: Component, mui, ...rest }) => {
  const RenderLayout = screenWinner ? SWLayout : Layout
  const PageLayout = rest.path.slice(0,4) === '/mui' ? MuiLayout : RenderLayout
  return (
    <Route
      {...rest}
      render = {props =>
        localStorage.getItem("aiPaletteUserAuthKey") ? (
          <PageLayout>
            <Component {...props} />
          </PageLayout>
        ) : (
          <Redirect
            to= {'/'}
          />
        )
      }
    />
  )
}

export default PrivateRoute
