import { ProductNeedDetailActionTypes, ProductNeedQuotesActionTypes } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'
import ProductNeedsDetails from '../../services/networking/Models/ProductNeedsDetails'
import NeedQuotesResponse from '../../services/networking/Models/NeedQuotesResponse'


export const fetchProductNeedDetails = (productid, needId, lensSelected) => {
    return (dispatch, getState) => {
        dispatch(fetchProductNeedDetailsStart())

        // let lensSelected = localStorage.getItem('defaultSelectedLens') || 'Ingredient';
        let request;
        switch(lensSelected) {
          case 'Product':
            request = NetworkUtil.fetchProductFilterNeedDetails(productid, needId) // Product
            break;
          case 'Theme':
            request = NetworkUtil.fetchThemeFilterNeedDetails(productid, needId)// Theme
            break;
          case 'Ingredient':
            request = NetworkUtil.fetchProductNeedDetails(productid, needId) // Ingredients
            break;
          default:
            dispatch(fetchProductNeedDetailsFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }

        axios.get(request)
        .then(response => {
            const responseObject = new ProductNeedsDetails(response.data)
            dispatch(fetchProductNeedDetailsSuccess(responseObject))
        })
        .catch(error => {
            dispatch(fetchProductNeedDetailsFailed(error))
        })
    }
}

export const fetchProductFormatDetails = (productid, formatType, formatName, lensSelected) => {
    return (dispatch, getState) => {
        dispatch(fetchProductNeedDetailsStart())

        let request;
        switch(lensSelected) {
          case 'Product':
            // request = NetworkUtil.fetchProductFilterNeedDetails(productid, needId) // Product
            return;
          case 'Theme':
            // request = NetworkUtil.fetchThemeFilterNeedDetails(productid, needId)// Theme
            return;
          case 'Ingredient':
            request = NetworkUtil.fetchProductFormatDetails(productid, formatType, encodeURIComponent(formatName)); // Ingredients
            break;
          default:
            dispatch(fetchProductNeedDetailsFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }

        axios.get(request)
        .then(response => {
            response.data.score = response.data.formats[formatName];
            const subFormatNames = response.data.sub_formats[formatName] || {};
            response.data.relatedTerms = Object.keys(subFormatNames).map((subFormatName) =>
              ({name: subFormatName, percentage_score_current: subFormatNames[subFormatName]})
            );
            const responseObject = new ProductNeedsDetails(response.data)
            console.log('Product Format Detail -> Response Object', responseObject)
            dispatch(fetchProductNeedDetailsSuccess(responseObject))
        })
        .catch(error => {
            console.log('Error ---', error)
            dispatch(fetchProductNeedDetailsFailed(error))
        })
    }
}

const fetchProductNeedDetailsStart = () => {
    return {
        type: ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_START],
    }
}

const fetchProductNeedDetailsSuccess = (productNeed) => {
    return {
        type: ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_SUCCESS],
        productNeed: productNeed,
    }
}

const fetchProductNeedDetailsFailed = (error) => {
    return {
        type: ProductNeedDetailActionTypes.type[ProductNeedDetailActionTypes.FETCH_PRODUCT_NEED_DETAIL_FAILED],
        error: error,
    }
}

export const fetchProductNeedQuotes =  (productid, needId, lensSelected) => {
    return async (dispatch, getState) => {

        let request, tradeMediaRequest;
        switch(lensSelected) {
          case 'Product':
            request = NetworkUtil.fetchProductFilterNeedQuotes(productid, needId) // Product
            tradeMediaRequest = NetworkUtil.fetchProductFilterNeedTradeMediaPatentQuotes(productid, needId)
            break;
          case 'Theme':
            request = NetworkUtil.fetchThemeFilterNeedQuotes(productid, needId)// Theme
            tradeMediaRequest = NetworkUtil.fetchThemeFilterNeedTradeMediaPatentQuotes(productid, needId)
            break;
          case 'Ingredient':
            request = NetworkUtil.fetchProductNeedQuotes(productid, needId) // Ingredients
            tradeMediaRequest = NetworkUtil.fetchIngredientFilterNeedTradeMediaPatentQuotes(productid, needId)
            break;
          default:
            dispatch(fetchProductNeedQuoteFailed("Invalid Actions Detected")); // In case if someone modifies value localStorage in to useless one
            return;
        }
        const quotesRes = await axios.get(request)
        const tMPQuotes = await axios.get(tradeMediaRequest)
        try{
          let allQuotes = {}
          if(tMPQuotes && tMPQuotes.data){
            allQuotes = {...tMPQuotes.data}
          }
          if(quotesRes && quotesRes.data){
            allQuotes = {...allQuotes, ...quotesRes.data}
          }
          const responseObject = new NeedQuotesResponse(allQuotes)
          dispatch(fetchProductNeedQuoteSuccess(responseObject))
        }catch(error){
          dispatch(fetchProductNeedQuoteFailed(error))
        }
    }
}

const fetchProductNeedQuoteSuccess = (productNeedQuotes) => {
    return {
        type: ProductNeedQuotesActionTypes.type[ProductNeedQuotesActionTypes.FETCH_PRODUCT_NEED_QUOTES_SUCCESS],
        productNeedQuotes: productNeedQuotes,
    }
}

const fetchProductNeedQuoteFailed = (error) => {
    return {
        type: ProductNeedQuotesActionTypes.type[ProductNeedQuotesActionTypes.FETCH_PRODUCT_NEED_QUOTES_FAILED],
        error: error,
    }
}
