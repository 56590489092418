import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/networking/axios'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import {
  Box,
  Tab,
  Grid,
  Typography,
  Checkbox,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
  Stack,
  ToggleButton,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import WhiteSpaceTable from '../../components/WhiteSpaceComponent/WhiteSpaceTable'
import WhiteSpaceGraph from '../../components/WhiteSpaceComponent/WhiteSpaceGraph'
import MainCommonTabs from '../../shared/components/MainCommonTabs/MainCommonTabs'
import { Item, StyledTab, SearchBox } from './Styled'
import CountrySelect from '../../componentsmui/HomePagePartials/partials/CountrySelect'
import ProductSelect from '../../componentsmui/HomePagePartials/partials/ProductSelect'
import SearchNotSelected from '../../components/WhiteSpaceComponent/SearchNotSelected'
import OverlayLoader from '../../shared/components/OverlayLoader'
import ToggleButtonGroup from '../../shared/components/ToggleButtonGroup'
import NoResultsFound from '../../shared/components/AdvancedTrendAnalysisComponents/partials/NoResultsFound'
import FeatureAuthorizer from '../../components/FeatureAuthorizer'

import { amplify, convertTextToTitleCase } from './../../store/utility'
import { shouldLogRecentActivity } from '../../shared/components/DashboardRecentActivity'

import { useLoader } from '../../hooks'

import DropdownSelectedFiltersTags from '../../shared/components/DropdownSelectedFiltersTags'
import NestedMultiSelectDropdownContainer from '../../shared/components/NestedMultiSelectDropdownContainer'
const amp = (props, ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const subCategory =
      ampiUserData.lensSelected === 'ingredient'
        ? ampiUserData.selectedSubCategoryIngredient
        : ampiUserData.selectedSubCategoryTrend
    const selectedCountry =
      ampiUserData.lensSelected === 'ingredient'
        ? ampiUserData.selectedIngredientCountry
        : ampiUserData.selectedTrendCountry
    const selectedCategory =
      ampiUserData.lensSelected === 'ingredient'
        ? ampiUserData.selectedIngredientCategory
        : ampiUserData.selectedTrendCategory
    const getSelectedFilterLabel = (ingredientFamilyList, depth) => {
      const ingFamilyFilterKeys = Object.keys(ingredientFamilyList)
      return ingFamilyFilterKeys
        .filter(
          (filterApplied) =>
            ingredientFamilyList[filterApplied].depth === depth,
        )
        .map(
          (filteredIngredientFilter) =>
            ingredientFamilyList[filteredIngredientFilter].label,
        )
    }
    let amplitudeUserData = {
      User: props.email,
      Country_Name: (selectedCountry && selectedCountry.label) || null,
      Category_Name: (selectedCategory && selectedCategory.label) || null,
    }
    switch (amplitudeEvent) {
      case 'Clicked_WSO_Ing_Fam':
        break
      case 'Clicked_WSO_Ing_Fam_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keyword: ampiUserData.searchKeyword,
        }
        break
      case 'Clicked_WSO_Ing_Fam_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Ingredient_Family: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            0,
          ),
          Ingredient_SubFamily: getSelectedFilterLabel(
            ampiUserData.ingredientFamilyList.selected,
            1,
          ),
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          Lens: ampiUserData.lensSelected,
          Sub_Category: (subCategory && subCategory.label) || 'NULL',
          Maturity_Phase: ampiUserData.isTrendChecked,
          Search: ampiUserData.searchValue || 'NULL',
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, props.email)
  } catch (err) {
    console.log(err, 'Amplitude error in WhiteSpace')
  }
}

const phaseColor = {
  DORMANT: '#ECE55F',
  EMERGING: '#A7DE4C',
  GROWING: '#6EB647',
  MATURED: '#E2873D',
  DECLINING: '#DD7066',
  FADING: '#EABAB6',
}

const isTrendCheckedInitial = {
  DORMANT: true,
  EMERGING: true,
  GROWING: true,
  MATURED: true,
  DECLINING: true,
  FADING: true,
}
const availableLenses = ['ingredient', 'theme']

const WhiteSpace = (props) => {
  const { projectsList, allTrendsList, wsoData } = props
  const [activeNavTab, setActiveNavTab] = useState('Graph View')
  const [selectedIngredientCountry, setSelectedIngredientCountry] = useState(
    null,
  )
  const [selectedTrendCountry, setSelectedTrendCountry] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedIngredientCategory, setSelectedIngredientCategory] = useState(
    null,
  )
  const [selectedTrendCategory, setSelectedTrendCategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const [
    selectedSubCategoryIngredient,
    setSelectedSubCategoryIngredient,
  ] = useState(null)
  const [selectedSubCategoryTrend, setSelectedSubCategoryTrend] = useState(null)
  const [countrySelectList, setCountrySelectList] = useState([])
  const [
    categoryIngredientSelectList,
    setCategoryIngredientSelectList,
  ] = useState([])
  const [categoryTrendSelectList, setCategoryTrendSelectList] = useState([])
  // const [categorySelectList, setCategorySelectList] = useState([])
  const [subCategoryIngredientList, setSubCategoryIngredientList] = useState([])
  const [subCategoryTrendtList, setSubCategoryTrendList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [lensSelected, setLensSelected] = useState(availableLenses[0])
  const [filteredData, setFilteredData] = useState([])
  const [isTrendChecked, setIsTrendChecked] = useState(isTrendCheckedInitial)
  const [tooltipShow, setToolTipShow] = useState(false)
  const [isCheckedTrendAll, setIsCheckedTrend] = useState(true)
  const [ingFamilyFilterAPIData, setIngFamilyFilterAPIData] = useState({})
  const [ingFamilyFilter, setIngFamilyFilter] = useState({})
  const [updatedAppliedIngFamily, setUpdatedAppliedIngFamily] = useState({})

  const [loading, setLoading] = useLoader()

  // Logging Amplitude Events

  const ampCountryCategory = {
    lensSelected,
    selectedIngredientCountry,
    selectedIngredientCategory,
    selectedTrendCountry,
    selectedTrendCategory,
  }

  const logDropdownOpened = () => {
    const ampiEvent = 'Clicked_WSO_Ing_Fam'
    const ampiUserData = {
      ampCountryCategory,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  const logSearchKeywordModified = (searchKeyword) => {
    const ampiEvent = 'Clicked_WSO_Ing_Fam_Search'
    const ampiUserData = {
      ...ampCountryCategory,
      searchKeyword,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  const logActionClicked = (ingredientFamilyList, action) => {
    const ampiEvent = 'Clicked_WSO_Ing_Fam_Action'
    const ampiUserData = {
      ...ampCountryCategory,
      ingredientFamilyList,
      action,
    }
    amp(props, ampiEvent, ampiUserData)
  }

  const appliedFilters = useMemo(() => {
    if (_.isEmpty(ingFamilyFilter)) {
      return []
    }
    return Object.values(ingFamilyFilter.applied).reduce(
      (acc, obj) => [...acc, ...obj.trends],
      [],
    )
  }, [ingFamilyFilter.applied])

  const updatedWsoData = useMemo(() => {
    if (!_.isEmpty(ingFamilyFilter.applied) && wsoData) {
      const allTrendsList = wsoData.trends.filter((trend) =>
        appliedFilters.includes(trend.trend_id),
      )
      return { ...wsoData, trends: allTrendsList }
    }
    return wsoData
  }, [appliedFilters, wsoData])

  const trendIds = wsoData?.trends?.map((trend) => trend.trend_id)
  const selectedLens = convertTextToTitleCase(lensSelected) + 's'

  const { enqueueSnackbar } = useSnackbar()

  const formatOpts = (list, setGenerator) =>
    Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
      label: item,
      id,
    }))

  useEffect(() => {
    setLoading(true)
    if (wsoData?.project_id && selectedLens && trendIds) {
      if (_.isEmpty(trendIds)) {
        setIngFamilyFilterAPIData({})
      } else {
        actions
          .fetchTrendFamilyFilterItems(
            trendIds,
            selectedLens,
            wsoData.project_id,
          )
          .then((result) => {
            setIngFamilyFilterAPIData(result)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      }
    }
  }, [wsoData])

  const ingFamilyStateChange = (state) => {
    setIngFamilyFilter(state)
  }

  const handleRemoveAppliedIngFamily = (ids) => {
    setUpdatedAppliedIngFamily(_.omit(ingFamilyFilter.applied, ids))
  }
  const countriesFormatting = () => {
    let formattedCountries = []
    formattedCountries =
      projectsList &&
      formatOpts(
        projectsList ? projectsList.list : [],
        (prj) => prj.criteria,
      ).map(({ label, id }) => {
        return {
          label,
          id,
        }
      })
    formattedCountries &&
      formattedCountries.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    return formattedCountries
  }

  const searchClicked = () => {
    const ampiEvent = `Clicked_WSO_SearchBar`
    const subCategory =
      lensSelected === 'ingredient'
        ? selectedSubCategoryIngredient
        : selectedSubCategoryTrend
    const selectedCountry =
      lensSelected === 'ingredient'
        ? selectedIngredientCountry
        : selectedTrendCountry
    const selectedCategory =
      lensSelected === 'ingredient'
        ? selectedIngredientCategory
        : selectedTrendCategory

    const ampiUserData = {
      User: props.email,
      Lens: lensSelected,
      Country_Name: (selectedCountry && selectedCountry.label) || null,
      Category_Name: (selectedCategory && selectedCategory.label) || null,
      Sub_Category: (subCategory && subCategory.label) || null,
      Maturity_Phase: isTrendChecked,
    }
    amplify(ampiEvent, ampiUserData, props.email)
  }

  const categoriesFormatting = (countryToMatch) => {
    let formatedCategories = []
    formatedCategories = projectsList
      ? countryToMatch
        ? projectsList.list.filter((prj) => prj.criteria === countryToMatch)
        : projectsList.list
      : []
    formatedCategories = formatedCategories.map((project, index) => {
      return {
        id: index,
        label: project.name,
        projectVariant: project.projectVariant,
      }
    })
    formatedCategories.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

    const filteredCategoryUniqueByLabel = [
      ...new Map(
        formatedCategories &&
          formatedCategories.map((item) => [item.label, item]),
      ).values(),
    ].filter(
      (project) =>
        project.projectVariant === 'food' || project.projectVariant === 'bpc',
    )

    return filteredCategoryUniqueByLabel
  }

  const handleTrendChecked = (obj) => {
    let count = 0
    Object.values(isTrendChecked).forEach((value) => {
      if (value === false) {
        count++
      }
    })
    if (count !== 5 || !isTrendChecked[obj]) {
      setIsTrendChecked({ ...isTrendChecked, [obj]: !isTrendChecked[obj] })
    } else {
      enqueueSnackbar('At least one classification phase should be selected', {
        variant: 'info',
      })
    }
  }

  /* useEffect(() => {
    const subCategory = lensSelected === 'ingredient' ? selectedSubCategoryIngredient : selectedSubCategoryTrend
    if (selectedCountry && selectedCategory && lensSelected && !subCategory) {
      props.getWSOData(selectedCountry.label, selectedCategory.label, lensSelected)
      //lensSelected === 'ingredient' ? setSelectedSubCategoryIngredient(null) : setSelectedSubCategoryTrend(null)
      lensSelected === 'ingredient' ? setSubCategoryIngredientList([]) : setSubCategoryTrendList([])
      //setSubCategoryList([])
      props.resetWsoData()
    }
  }, [selectedCountry, selectedCategory, lensSelected]) */

  useEffect(() => {
    const selectedCountry =
      lensSelected === 'ingredient'
        ? selectedIngredientCountry
        : selectedTrendCountry
    if (selectedCountry) {
      lensSelected === 'ingredient'
        ? setCategoryIngredientSelectList(
            categoriesFormatting(selectedCountry.label),
          )
        : setCategoryTrendSelectList(
            categoriesFormatting(selectedCountry.label),
          )
      // setCategorySelectList(categoriesFormatting(selectedCountry.label))
      if (
        selectedCategory &&
        !projectsList.list.find(
          (prj) =>
            prj.criteria === selectedCountry.label &&
            prj.name === selectedCategory.label,
        )
      ) {
        lensSelected === 'ingredient'
          ? setSelectedCategory(null)
          : setSelectedCategory(null)
      }
    }
  }, [selectedIngredientCountry, selectedTrendCountry])

  useEffect(() => {
    const countrySelectList = projectsList && countriesFormatting()
    const categorySelectList = projectsList && categoriesFormatting()
    setCountrySelectList(countrySelectList)
    lensSelected === 'ingredient'
      ? setCategoryIngredientSelectList(categorySelectList)
      : setCategoryTrendSelectList(categorySelectList)

    // setCategorySelectList(categorySelectList)
  }, [projectsList])

  useEffect(() => {
    let subCategoryList =
      allTrendsList &&
      (lensSelected === 'ingredient'
        ? allTrendsList.ingredient_sub_category
        : allTrendsList.theme_sub_category)
    subCategoryList = formatOpts(subCategoryList || [], (prj) => prj.name)
    subCategoryList = subCategoryList.filter((d) => d.label !== 'All')
    // lensSelected === 'ingredient' ? allTrendsList.ingredient_sub_category : allTrendsList.theme_sub_category

    lensSelected === 'ingredient'
      ? setSubCategoryIngredientList(subCategoryList)
      : setSubCategoryTrendList(subCategoryList)
    // setSubCategoryList(subCategoryList)
  }, [allTrendsList])

  useEffect(() => {
    props.requestProjectsList()
    props.fetchSubscriptionsDetails()
    shouldLogRecentActivity(
      'White Space Opportunity',
      'Platform',
      `/mui/white-space`,
    )
  }, [])

  const handleSearch = () => {
    let searchFiltreData = []
    if (updatedWsoData) {
      const checkboxFilteredData = updatedWsoData.trends.filter((d) => {
        if (isTrendChecked[d.classification]) {
          return d
        }
      })

      if (searchValue !== '') {
        searchFiltreData = checkboxFilteredData.filter((d) =>
          d.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      } else {
        searchFiltreData = checkboxFilteredData
      }
      setFilteredData([...searchFiltreData])
    }
  }

  useEffect(() => {
    handleSearch()
    setToolTipShow(false)
  }, [searchValue, isTrendChecked, updatedWsoData])

  useEffect(() => {
    if (wsoData) {
      props.fetchSubCategories(wsoData.project_id)
    }
  }, [wsoData])

  useEffect(() => {
    if (wsoData) {
      const ampiEvent = `Clicked_WSO_${
        activeNavTab === 'Graph View' ? 'Graph' : 'Table'
      }`
      const ampiUserData = {
        isTrendChecked: isTrendChecked,
        activeNavTab: activeNavTab,
        searchValue: searchValue,
        lensSelected: lensSelected,
        selectedSubCategoryIngredient: selectedSubCategoryIngredient,
        selectedIngredientCountry: selectedIngredientCountry,
        selectedIngredientCategory: selectedIngredientCategory,
        selectedSubCategoryTrend: selectedSubCategoryTrend,
        selectedTrendCountry: selectedTrendCountry,
        selectedTrendCategory: selectedTrendCategory,
      }
      amp(props, ampiEvent, ampiUserData)
    }
  }, [wsoData, isTrendChecked, searchValue, activeNavTab])

  useEffect(() => {
    props.resetWsoData()
    const subCategory =
      lensSelected === 'ingredient'
        ? selectedSubCategoryIngredient
        : selectedSubCategoryTrend
    const selectedCountry =
      lensSelected === 'ingredient'
        ? selectedIngredientCountry
        : selectedTrendCountry
    const selectedCategory =
      lensSelected === 'ingredient'
        ? selectedIngredientCategory
        : selectedTrendCategory

    if (selectedCountry && selectedCategory && lensSelected && subCategory) {
      props.fetchSubCategoriesGraphData(
        selectedCountry.label,
        encodeURIComponent(selectedCategory.label),
        lensSelected,
        subCategory.label,
      )
    } else if (
      selectedCountry &&
      selectedCategory &&
      lensSelected &&
      !subCategory
    ) {
      props.getWSOData(
        selectedCountry.label,
        encodeURIComponent(selectedCategory.label),
        lensSelected,
      )
    }
  }, [
    selectedSubCategoryIngredient,
    selectedSubCategoryTrend,
    lensSelected,
    selectedCountry,
    selectedIngredientCategory,
    selectedTrendCategory,
  ])

  useEffect(() => {
    lensSelected === 'ingredient'
      ? setSelectedCountry(selectedIngredientCountry)
      : setSelectedCountry(selectedTrendCountry)
    lensSelected === 'ingredient'
      ? setSelectedCategory(selectedIngredientCategory)
      : setSelectedCategory(selectedTrendCategory)
  }, [
    lensSelected,
    selectedIngredientCountry,
    selectedIngredientCategory,
    selectedTrendCountry,
    selectedTrendCategory,
  ])

  const handleCategoriesList = () => {
    if (lensSelected === 'ingredient') {
      return (
        subCategoryIngredientList.sort((a, b) => {
          return a.label.localeCompare(b.label)
        }) || []
      )
    } else {
      return (
        subCategoryTrendtList.sort((a, b) => {
          return a.label.localeCompare(b.label)
        }) || []
      )
    }
  }

  useEffect(() => {
    const areTrue = Object.values(isTrendChecked).every(
      (value) => value === false,
    )
    setIsCheckedTrend(areTrue)
  }, [isTrendChecked])

  useEffect(() => {
    if (!wsoData?.projectId) {
      setIngFamilyFilterAPIData({})
    }
  }, [wsoData?.project_id])
  const handleCountrySelect = (val) => {
    lensSelected === 'ingredient'
      ? setSelectedIngredientCountry(val)
      : setSelectedTrendCountry(val)
    if (lensSelected === 'ingredient') {
      setSelectedSubCategoryIngredient(null)
      setSelectedIngredientCategory(null)
    } else {
      setSelectedSubCategoryTrend(null)
      setSelectedTrendCategory(null)
    }
  }
  const handleToggleSelection = () => (event, toggle) => {
    if (toggle !== null) {
      setLensSelected(toggle.toLowerCase())
    }
  }
  const enableWSOSubCat =
    (projectsList &&
      projectsList.list.find(
        ({ criteria, name }) =>
          criteria === selectedCountry?.label &&
          name === selectedCategory?.label,
      )?.enableWSOSubCat) ||
    false
  return (
    <FeatureAuthorizer disableForFeat="cognitive_search" authAttr="whitespace">
      <MainCommonTabs subscriptionDetails={props.subscriptionDetails} />
      <Item>
        <Grid
          container
          columns={16}
          pt={2}
          pb={2}
          display={'flex'}
          flexWrap={'nowrap'}
        >
          <Grid item xs={12} display={'flex'} gap={3}>
            <Stack spacing={1}>
              <Typography variant="body1">Lens :</Typography>
              <Stack direction="row">
                <ToggleButtonGroup
                  value={lensSelected}
                  exclusive
                  onChange={handleToggleSelection()}
                  aria-label="lens"
                >
                  {availableLenses.map((lens) => (
                    <ToggleButton
                      value={lens}
                      aria-label={`${lens} lens`}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {`${lens}s`}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            <Box sx={{ width: '180px' }} ml={-1}>
              <CountrySelect
                countries={countrySelectList || []}
                onChange={(ev, val) => handleCountrySelect(val)}
                value={
                  lensSelected === 'ingredient'
                    ? selectedIngredientCountry
                    : selectedTrendCountry
                }
                noCountryFlag={true}
                component="WsoDesign"
              />
            </Box>
            <Box sx={{ width: '180px' }} ml={-1}>
              <ProductSelect
                categories={
                  lensSelected === 'ingredient'
                    ? categoryIngredientSelectList || []
                    : categoryTrendSelectList || []
                }
                onChange={(ev, val) => {
                  lensSelected === 'ingredient'
                    ? setSelectedIngredientCategory(val)
                    : setSelectedTrendCategory(val)
                  lensSelected === 'ingredient'
                    ? setSelectedSubCategoryIngredient(null)
                    : setSelectedSubCategoryTrend(null)
                }}
                value={
                  lensSelected === 'ingredient'
                    ? selectedIngredientCategory
                    : selectedTrendCategory
                }
                label={'Category :'}
                component="WsoDesign"
                placeholder="e.g. Snacks"
              />
            </Box>
            {lensSelected === 'ingredient' &&
              enableWSOSubCat &&
              selectedCountry &&
              selectedCategory && (
                <Box sx={{ width: '180px' }} ml={-1}>
                  <ProductSelect
                    categories={handleCategoriesList()}
                    label="Sub-category :"
                    component="WsoDesign"
                    onChange={(ev, val) =>
                      setSelectedSubCategoryIngredient(val)
                    }
                    value={selectedSubCategoryIngredient}
                    placeholder={'e.g. Ready Meals'}
                    // onChange={(ev, val) => lensSelected === 'ingredient' ? setSelectedSubCategoryIngredient(val) : setSelectedSubCategoryTrend(val)}
                    // value={lensSelected === 'ingredient' ? selectedSubCategoryIngredient : selectedSubCategoryTrend}
                    // placeholder={lensSelected === "ingredient" ? "e.g. Ready Meals" : "e.g. Target Group"}
                  />
                </Box>
              )}
            {lensSelected === 'ingredient' &&
              wsoData &&
              wsoData.project_variant === 'food' && (
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    Ingredient Family :
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <NestedMultiSelectDropdownContainer
                      updatedFilterData={ingFamilyFilterAPIData}
                      filterTagsAppliedFilters={updatedAppliedIngFamily}
                      stateChangeCallback={ingFamilyStateChange}
                      dimensions={{
                        width: 250,
                        maxItemsToFitInHeight: 6.5,
                      }}
                      placeHolder="Select Ingredient Family"
                      isLoggingRequired
                      logDropdownOpened={logDropdownOpened}
                      logActionClicked={logActionClicked}
                      logSearchKeywordModified={logSearchKeywordModified}
                    />
                  </Box>
                </Stack>
              )}
          </Grid>

          <Grid
            item
            xs={4}
            justifyContent="flex-end"
            display="flex"
            alignItems="end"
          >
            <SearchBox
              variant="outlined"
              onChange={(e) => setSearchValue(e.target.value)}
              onClick={() => {
                searchClicked()
              }}
              placeholder={`Search ${lensSelected}`}
              value={searchValue}
              size="large"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {lensSelected === 'ingredient' && (
          <DropdownSelectedFiltersTags
            maxFilterTagsToShow={5}
            filterState={ingFamilyFilter}
            handleRemoveAppliedFilters={handleRemoveAppliedIngFamily}
            headerText="Ingredient family: "
          />
        )}
      </Item>
      <Grid container spacing={2} columns={12} my={1} mt={-4}>
        <Grid item xs={3}>
          <Box>
            <StyledTab
              value={activeNavTab}
              onChange={(event, tab) => setActiveNavTab(tab)}
              aria-label="basic tabs example"
            >
              <Tab value="Graph View" label="Graph View" />
              <Tab value="Table View" label="Table View" />
            </StyledTab>
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          alignSelf="right"
          display="flex"
          justifyContent="flex-end"
        >
          <span style={{ display: 'flex', justifyContent: 'end' }}>
            {selectedCountry &&
              selectedCategory &&
              Object.keys(phaseColor).map((obj, i) => {
                return (
                  <Box
                    component="span"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Checkbox
                      style={{ color: phaseColor[obj] }}
                      checked={isTrendChecked[obj]}
                      onChange={() => handleTrendChecked(obj)}
                    />
                    <Typography variant="subtitle2" display={'inline'}>
                      {obj}
                    </Typography>
                  </Box>
                )
              })}
          </span>

          {/* <span className="link_download with_icon" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', }} onClick={this.handleDownloadData}>
            {' '}
            <i className="icon-ai-download" style={{ top: '0px' }} />
            <span>Download data</span>
          </span> */}
        </Grid>
      </Grid>
      {selectedCountry && selectedCategory && wsoData && isCheckedTrendAll && (
        <SearchNotSelected
          msg={'Select maturity phase to view White Space opportunities'}
        />
      )}
      {(!selectedCountry || !selectedCategory) && <SearchNotSelected />}
      {selectedCountry && selectedCategory && wsoData && !isCheckedTrendAll && (
        <Grid container spacing={2} columns={12}>
          {/* {filteredData.length > 0 ? ( */}
          <>
            {activeNavTab === 'Graph View' && (
              <Grid item xs={12}>
                <WhiteSpaceGraph
                  wsoTableData={filteredData}
                  phaseColor={phaseColor}
                  lensSelected={lensSelected}
                  {...props}
                  tooltipShow={tooltipShow}
                  handleTooltipShow={setToolTipShow}
                  maturityPhase={isTrendChecked}
                />
              </Grid>
            )}
            {activeNavTab === 'Table View' && (
              <WhiteSpaceTable
                wsoTableData={filteredData}
                phaseColor={phaseColor}
                email={props.email}
                Sub_Category={
                  (lensSelected === 'ingredient'
                    ? selectedSubCategoryIngredient &&
                      selectedSubCategoryIngredient.label
                    : selectedSubCategoryTrend &&
                      selectedSubCategoryTrend.label) || null
                }
                maturityPhase={isTrendChecked}
                lensSelected={lensSelected}
                {...props}
              />
            )}
          </>
          {/* ) 
          : (
            <NoResultsFound
              title={`${selectedCountry.label} ${
                selectedCategory.label
              } ${(lensSelected == 'ingredient' &&
                selectedSubCategoryIngredient?.label) ||
                ''}`}
            />
          )} */}
        </Grid>
      )}
      {props.loading && <OverlayLoader />}
      {selectedCountry && selectedCategory && !wsoData && (
        <Grid
          container
          spacing={2}
          justifyContent={'center'}
          mt={30}
          columns={12}
        >
          <CircularProgress />
        </Grid>
      )}
    </FeatureAuthorizer>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.downloadSections.loadingRequests,
    wsoData: state.whiteSpace.wsoData,
    projectsList: state.savedTrendRequester.savedTrendList,
    allTrendsList: state.projectListBuilder.allTrendsList,
    email:
      state.user &&
      state.user.loggedIn &&
      state.user.loggedIn.user &&
      state.user.loggedIn.user.email
        ? state.user.loggedIn.user.email
        : '',
    subscriptionDetails: state.projectListBuilder.subscriptionDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWSOData: (country, category, lens) =>
      dispatch(actions.fetchWhiteSpaceData(country, category, lens)),
    downloadWsoData: (country, category) =>
      dispatch(actions.downloadWhiteSpaceData(country, category)),
    requestProjectsList: () => dispatch(actions.fetchSavedTrends()),
    fetchSubCategories: (projectId) =>
      dispatch(actions.fetchAllTrends(projectId)),
    fetchSubCategoriesGraphData: (
      country,
      category,
      lensSelected,
      subCategory,
    ) =>
      dispatch(
        actions.subCategoryData(country, category, lensSelected, subCategory),
      ),
    resetWsoData: () => dispatch(actions.resetWsoData()),
    fetchSubscriptionsDetails: () =>
      dispatch(actions.fetchSubscriptionsDetails()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withErrorHandler(WhiteSpace, axios)),
)
