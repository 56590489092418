import { advancedTrendAnalysis } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  advancedTrendAnalysisList : null,
  advancedTrendsComparisonList: [],
  subCategories: [],
  error : false,
  loading : false,
  currentTrend: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_SUCCESS]: return updateObject(state, {
      advancedTrendAnalysisList : action.advancedTrend,
      error : false,
      loading : false,
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_FAILED]: return updateObject(state, {
      advancedTrendAnalysisList : null,
      error : true,
      loading : false,
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_LIST_START]: return updateObject(state, {
      advancedTrendAnalysisList : null,
      error : false,
      loading : false,
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_RESET_COMPARISON_LIST]: return updateObject(state, {
      advancedTrendsComparisonList: [],
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_CMP_LIST_SUCCESS]: 
      return (action.advancedTrend.name === state.currentTrend) 
      ? updateObject(state, {
        advancedTrendsComparisonList: [...state.advancedTrendsComparisonList, action.advancedTrend],
        error: false,
      }) : state
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_CMP_LIST_FAILED]: return updateObject(state, {
      error: true,
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_SUB_CATEGORIES_SUCCESS]: return updateObject(state, {
      subCategories: action.subCategories.filter(label => label !== 'All').map((label, index) => ({label, key: index}) )
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_FETCH_SUB_CATEGORIES_FAILED]: return updateObject(state, {
      subCategories: [],
      error: true,
    })
    case advancedTrendAnalysis.type[advancedTrendAnalysis.ATA_SET_CURRENT_TREND]: return updateObject(state, {
      currentTrend: action.currentTrend
    })
    default:
      return state
  }
} 
  
export default reducer