import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

const InformationIcon = ({ tooltipText, onTooltipOpen, sx = {} }) => (
  <Tooltip title={tooltipText} onOpen={onTooltipOpen}>
    <InfoRoundedIcon sx={{ color: 'icon', ...sx }} fontSize="small" />
  </Tooltip>
)

InformationIcon.propTypes = {
  tooltipText: PropTypes.string.isRequired,
}

export default InformationIcon
