import React from 'react';
import {isEmpty} from 'lodash';
import {
  IconButton, Link, Paper,
  Stack, SvgIcon, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import formatNumber from '../../../shared/utils/formatNumber';
import { useCompareDispatch } from '../../../shared/contexts/CompareContext';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LineChartIcon } from '../../../assets/icons/line-chart.svg';
import {amplify} from "./../../../store/utility"
import ConsumerCard from '../ConsumerCard';
import ExpandableChipsGroup from '../ExpandableChipsGroup';
import { AmplitudeValues } from '../../../store/amplitudeValues';

import { FilterClassificationBox } from  './styles'

import { ReactComponent as EmptySpaceFoodCupSvg } from '../../../assets/emptySpaceFoodCup.svg'; 


const amp = (props,ampiEvent,ampiUserData) => {
  try {
    const ampiInstance = new AmplitudeValues();
    const amplitudeEvent = ampiEvent;
    const amplitudeUserData = {
      'User': ampiInstance._email,
      'Lens': ampiUserData.lens,
      'Country_Name':ampiUserData.country,
      'Category_Name':ampiUserData.category,
      'Maturity_Phase':ampiUserData.trendName,
      'Trend_Name':ampiUserData.name,
      'Compared_Country':(((ampiUserData.trends).filter((trend)=>trend._id!==props.id)).map((trend)=>trend._country)),
      'Compared_Category':((ampiUserData.trends).filter((trend)=>trend._id!==props.id)).map((trend)=>trend._category),
      'Compared_Trend_Name':((ampiUserData.trends).filter((trend)=>trend._id!==props.id)).map((trend)=>trend._name),
      'Compared_Maturity_Phase':((ampiUserData.trends).filter((trend)=>trend._id!==props.id)).map((trend)=>trend._trendName)
    }
    amplify(amplitudeEvent, amplitudeUserData,ampiInstance._email)
  }
  catch(err) {
    console.log(err,'Amplitude error in DetailedCompareCard');
  }
}

const DetailedCompareCard = props => {
  const dispatch = useCompareDispatch();
  const {
    id,
    projectid,
    country,
    lens,
    name,
    rank,
    // type,
    trendName,
    dataPoint,
    category,
    productImage,
    categoryData,
    filterClassification,
    trends,
    isBPC = false,
  } = props;
  // if (isEmpty(categoryData))
  //   return null
  const categoryDataVisible = [ "Driver",
      "Product Attribute",
      "Occasion",
      "Expressed Persona"
    ]
    if(isBPC){
      categoryDataVisible.unshift('Concerns')
    }
    const sortedcategoryData =categoryDataVisible.reduce((acc, curr) => {
      const catData = categoryData.find(({category}) => category === curr);
      return catData ? [...acc, catData ] : acc
  }, [])

  const handleClose = () => {
    dispatch({ type: 'deleted', payload: { id } })
    const ampiEvent = "Clicked_Compare_Card_Close"
    const ampiUserData = {
      'Lens': lens,
      'country':country,
      'category':category,
      'trendName':trendName,
      'name':name,
      'trends':trends,
    }
    amp(props,ampiEvent,ampiUserData)
  }

  const align = (projectid,lens,id,sortedcategoryData) => {
    let cccBox = []
    for (let i = 0; i < sortedcategoryData.length; i++) {
      let item=sortedcategoryData[i]
      cccBox.push(isEmpty(item) ?
        <ConsumerCard
          projectid={null}
          lensSelected={null}
          productid={null}
          selectedLang="English"
          sx={{height:'333px'}}
          category={null}
          dataPoint={null}
          needs={null}
        />
      :
        <ConsumerCard
          projectid={projectid}
          lensSelected={lens}
          productid={id}
          selectedLang="English"
          sx={{height:'333px'}}
          category={item.category}
          dataPoint={item.data_point}
          needs={item.needs}
        />
      )
    }
      return cccBox
  }
  const isFilterClassificationEmpty = isEmpty(filterClassification)
  return (
    <Paper
      sx={{
        p: 2,
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        boxShadow: '0px 4px 12px 0px #272B3108',
      }}
    >
      {/* Remove button */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'icon',
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Link target='_blank' href={`/mui/details/${projectid}/${lens}/${id}`}>
          <img
          src={productImage}
          alt={name}
          style={{
            height: 76,
            width: 76,
            borderRadius: 4,
            objectFit: 'cover',
          }}
          />
        </Link>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h3">
            {name}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <SvgIcon component={LineChartIcon} />
            <Typography variant="subtitle1">
              {formatNumber(dataPoint)}
              &nbsp;
              Data Points
            </Typography>
          </Box>
        </Box>
      </Box>

      <Stack spacing={1} mt={2} mb={3}>
        <Typography variant="subtitle2" color="textSecondary">
          Trend Ranking and Maturity
        </Typography>
        <Box>
          <Typography variant="h1" sx={{ color: 'secondary.main' }}>
            #
            {rank}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'secondary.main' }}>
            {trendName}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          {country} {category}
        </Typography>
      </Stack>
      
      <Typography variant="subtitle2" color="textSecondary">
        Presence in Sub-Category Types
      </Typography>
      <FilterClassificationBox sx={ isFilterClassificationEmpty ? 
        { 
          border: ({ palette }) => `1px dashed ${palette.divider}`,
          borderRadius: '4px'
        } : {}}>
      {isFilterClassificationEmpty ? 
        (<Stack sx={{ height: '100%', alignItems: 'center', justifyContent: 'center'}}>
          <EmptySpaceFoodCupSvg/>
          <Typography align="center" pt={1.25} px={1} variant="caption" color="textSecondary">{name} doesn't fall into any sub-category</Typography>
        </Stack>)
         : 
        (<Stack spacing={2}>
          {Object.entries(filterClassification).map(([key, value]) => (
            <Box key={key}>
              <Typography variant="subtitle2" sx={{ color: 'secondary.dark', textTransform: 'capitalize', mb: 1 }}>
                {key.toLowerCase()}
              </Typography>
              <ExpandableChipsGroup trends={value.split(';')} />
            </Box>
          ))}
        </Stack>)}
      </FilterClassificationBox>


      <Stack spacing={1.2} mt={2}>
        {
          align(projectid,lens,id,sortedcategoryData)
        }
      </Stack>
    </Paper>
  );
};

DetailedCompareCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  // type: PropTypes.string.isRequired,
  trendName: PropTypes.string.isRequired,
  dataPoint: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
  filterClassification: PropTypes.shape({
    DORMANT: PropTypes.string,
    EMERGING: PropTypes.string,
    GROWING: PropTypes.string,
    MATURE: PropTypes.string,
    DECLINING: PropTypes.string,
  }).isRequired,
  categoryData: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    data_point: PropTypes.number.isRequired,
    needs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      percentage_value: PropTypes.number.isRequired,
    })).isRequired,
  })).isRequired,
};

export default DetailedCompareCard;
