import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import BackdropLoader from '../../generic-components/partials/BackdropLoader'
import RenameConceptModal from '../../generic-components/RenameConceptModal'
import ConceptDetailsViewModel from '../../generic-components/ConceptDetailsViewModel'
import DeleteConfirmationDialog from '../../generic-components/DeleteConceptDialog'
import { TableHeaderRow } from './styles'
import { TableSortLabel } from '@mui/material'
import { sortList } from '../../../../store/utility'
import NoResultFound from '../../../../shared/components/NoResultFound/NoResultFound'
import WheelNotGenerated from '../../../../assets/images/EmptyState/WheelNotGenerated.svg'

const SavedConceptsTable = ({
  loading,
  data,
  isEditOn,
  selectedRow,
  isViewOn,
  isDeleteDialogOpen,
  handleDeleteDialogClose,
  handleDelete,
  resetIsEditOn,
  resetViewOn,
  handleEdit,
  refreshData,
  tableData,
  order,
  setOrder,
  setOrderBy,
  orderBy,
}) => {
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortByKey = (array, key) => {
    const sortedArr = array.sort(function(a, b) {
      const first = a[key]?.toLowerCase().trim()
      const second = b[key]?.toLowerCase().trim()
      return sortList(first, second, order, key)
    })
    return sortedArr
  }

  if (loading)
    return <BackdropLoader loading={loading} shouldRenderText={false} />

  return (
    <>
      {data?.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableData.map((item) => (
                  <TableHeaderRow
                    align="left"
                    key={item.id}
                    {...(item.sorting && {
                      onClick: () => handleRequestSort(item.id),
                    })}
                  >
                    {item.header}
                    {item.sorting && (
                      <TableSortLabel
                        active={orderBy === item.id}
                        direction={orderBy === item.id ? order : 'asc'}
                        sx={{
                          display: 'inline-flex',
                          right: '10px',
                        }}
                      />
                    )}
                  </TableHeaderRow>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                sortByKey(data, orderBy).map((row, idx) => (
                  <TableRow
                    key={row.title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {tableData.map((item) => item.cell(row))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoResultFound
          imgSx={{ marginTop: '75px' }}
          imgAlt="No Result Found"
          message={`No Saved Concepts found!`}
          imgSrc={WheelNotGenerated}
          messageSx={{ paddingTop: '10px', maxWidth: '400px' }}
        />
      )}
      {isEditOn ? (
        <RenameConceptModal
          conceptDetails={selectedRow}
          active={isEditOn}
          onClose={resetIsEditOn}
          onUpdate={refreshData}
        />
      ) : isViewOn ? (
        <ConceptDetailsViewModel
          conceptDetails={selectedRow}
          active={isViewOn}
          onClose={resetViewOn}
          onEdit={handleEdit}
        />
      ) : isDeleteDialogOpen ? (
        <DeleteConfirmationDialog
          headerText="Delete Concept"
          open={isDeleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleConfirmClick={handleDelete}
        />
      ) : null}
    </>
  )
}

export default SavedConceptsTable
