import React from 'react'
import { Backdrop, Checkbox } from '@mui/material'

/*
  This is only specific to mark irrelevant feature
*/
function BackdropWithCheckbox(props) {
  return (
    <Backdrop
      opacity="0.3"
      sx={{
        backgroundColor: '#000000',
        position: 'absolute',
        opacity: '0.3 !important',
        borderRadius: '4px',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.isOpen}
    >
      <Checkbox
        sx={{
          justifyContent: 'flex-start',
          position: 'absolute',
          left: '16px',
          right: '16px',
          bottom: '16px',
        }}
        labelStyle={{
          color: 'white',
        }}
        iconStyle={{
          fill: 'white',
        }}
        inputStyle={{
          color: 'white',
        }}
        style={{
          color: 'white',
        }}
        value={props.esId}
        checked={props?.selectedIrrelevantDocs?.indexOf(props.esId) !== -1}
        onChange={props.handleEsDocSelection}
      />
    </Backdrop>
  )
}

export default BackdropWithCheckbox
