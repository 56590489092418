import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SingleSelect from '../../../shared/components/SingleSelect'
import NetworkingUtil from '../../../services/networking/SWNetworkingUtil'
import axios from '../../../services/networking/swAxios'
import {
  addRequest,
  doneRequest,
} from '../../../store/actions/downloadSections'
import _ from 'lodash'
import TextOverflowTooltip from '../../../shared/components/TextOverflowTooltip'
import { Search } from '@mui/icons-material'
import RadioCustom from '../../../shared/components/RadioCustom'
import SWNoProjectsFound from '../../../assets/images/EmptyState/SWNoProjectsFound.svg'
import NoResultFound from '../../../shared/components/NoResultFound/NoResultFound'

const DEFAULT_DROPDOWN_LIST = [
  {
    label: 'Country',
    value: null,
    options: [],
    loading: true,
  },
  {
    label: 'Category',
    value: null,
    options: [],
    loading: true,
  },
]

const SWProjectsListDialog = ({
  listItems,
  headerText,
  open,
  handleClose,
  handleConfirmClick,
  onGoBackClick,
  radioSelected,
  handleRadioChange,
  loading,
}) => {
  const dialogActions = [
    {
      children: 'Cancel',
      onclick: handleClose,
      variant: 'outlined',
    },
    {
      children: (
        <>
          {loading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            'Save Export'
          )}
        </>
      ),
      onclick: handleConfirmClick,
      variant: 'contained',
      disabled: !radioSelected,
    },
  ]
  const [dropdownsList, setDropdownsList] = useState(DEFAULT_DROPDOWN_LIST)
  const [searchValue, setSearchValue] = useState('')

  const filteredListItems = useMemo(
    () =>
      listItems.filter(
        (obj) =>
          (dropdownsList[0].value
            ? obj.country === dropdownsList[0].value
            : true) &&
          (dropdownsList[1].value
            ? obj.category === dropdownsList[1].value
            : true) &&
          (searchValue
            ? obj.title.toLowerCase().includes(searchValue.toLowerCase())
            : true),
      ),
    [dropdownsList[0].value, dropdownsList[1].value, searchValue, listItems],
  )

  const handleDropdownChange = (objLabelToFind, newVal) => {
    setDropdownsList(
      dropdownsList.map((obj) =>
        obj.label === objLabelToFind
          ? {
              ...obj,
              value: newVal?.label,
            }
          : { ...obj },
      ),
    )
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  const getListItemLabel = (data) => {
    const screenedPrjNote =
      'NOTE: Adding Concepts to Screened project will nullify the screened results.'
    const isPrjScreened = data.screeningStatus === 'SCREENED'
    return (
      <Box display="flex" alignItems="center" sx={{ gap: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <TextOverflowTooltip title={data.title} maxLineCount={1}>
            <Typography>{data.title}</Typography>
          </TextOverflowTooltip>
          {isPrjScreened && (
            <TextOverflowTooltip title={screenedPrjNote} maxLineCount={1}>
              <Typography
                variant="caption"
                fontSize="10px"
                color="text.hint"
                sx={{ mt: 0.5 }}
              >
                {screenedPrjNote}
              </Typography>
            </TextOverflowTooltip>
          )}
        </Box>
        <Box display="flex" alignItems="center" sx={{ gap: 1, height: 20 }}>
          <Typography variant="caption" color="textSecondary">
            {`${data.country || ''}, ${data.category || ''}`.toUpperCase()}
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ m: 0 }}
          />
          <Typography
            variant="caption"
            color={isPrjScreened ? 'success.main' : 'error'}
            sx={{
              width: 68,
              textAlign: 'end',
            }}
          >
            {_.startCase(data.screeningStatus.toLowerCase())}
          </Typography>
        </Box>
      </Box>
    )
  }

  const updateDropdownsList = (prevList, label, newItems) => {
    const dropdownOptions = newItems?.map(({ id, name: label }) => ({
      id,
      label,
    }))
    return prevList.map((obj) =>
      obj.label === label
        ? {
            ...obj,
            ...(dropdownOptions && { options: dropdownOptions }),
            loading: false,
          }
        : obj,
    )
  }

  useEffect(() => {
    if (!open) {
      // Reset Filters on Close
      setDropdownsList(DEFAULT_DROPDOWN_LIST)
      setSearchValue('')
    } else {
      {
        const url = NetworkingUtil.getCountries()
        const requestFrom = {
          name: 'fetchSWCountries',
          requestTime: new Date(),
        }
        addRequest(requestFrom)
        axios
          .get(url)
          .then(({ data }) => {
            doneRequest(requestFrom)
            setDropdownsList((prevDropdownsList) =>
              updateDropdownsList(
                prevDropdownsList,
                'Country',
                data.data.countries,
              ),
            )
          })
          .catch((err) => {
            doneRequest(requestFrom)
            console.log(err)
            setDropdownsList((prevDropdownsList) =>
              updateDropdownsList(prevDropdownsList, 'Country'),
            )
          })
      }
      {
        const url = NetworkingUtil.getCategories()
        const requestFrom = {
          name: 'fetchSWCategories',
          requestTime: new Date(),
        }
        addRequest(requestFrom)
        axios
          .get(url)
          .then(({ data }) => {
            doneRequest(requestFrom)
            setDropdownsList((prevDropdownsList) =>
              updateDropdownsList(
                prevDropdownsList,
                'Category',
                data.data.categories,
              ),
            )
          })
          .catch((err) => {
            doneRequest(requestFrom)
            console.log(err)
            setDropdownsList((prevDropdownsList) =>
              updateDropdownsList(prevDropdownsList, 'Category'),
            )
          })
      }
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '700px',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          width: '100%',
        }}
      >
        <Box display="flex">
          <ClearIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
            }}
            fontSize="0.75rem"
          />
        </Box>
        {headerText && <Box>{headerText}</Box>}
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          m: 3,
          mt: 0,
          textAlign: 'center',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          {dropdownsList.map((obj) => (
            <Box sx={{ flexBasis: 'calc(50% - 8px)' }}>
              <SingleSelect
                placeholder={`Select a ${obj.label}`}
                options={obj.options}
                onChange={(e, newVal) =>
                  handleDropdownChange(obj.label, newVal)
                }
                value={obj.value || ''}
                loading={obj.loading}
                listBoxProps={{
                  style: {
                    maxHeight: '112px',
                  },
                }}
              />
            </Box>
          ))}
        </Box>
        <TextField
          sx={{
            width: '100%',
            py: 2,
          }}
          value={searchValue}
          variant="outlined"
          size="large"
          placeholder={'Search by project name'}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ width: '100%', py: 2, gap: 1 }}>
          {filteredListItems?.length ? (
            <RadioGroup
              sx={{
                maxHeight: '200px',
                overflow: 'auto',
                flexWrap: 'nowrap',
              }}
              aria-labelledby="project-list"
              name="project-list"
              value={radioSelected}
              onChange={(e) => handleRadioChange(e.target.value)}
            >
              {filteredListItems.map((obj) => (
                <FormControlLabel
                  sx={{
                    py: 1.5,
                    alignItems: 'start',
                    '& .MuiFormControlLabel-label': {
                      flex: 1,
                      textAlign: 'start',
                    },
                  }}
                  value={obj.id}
                  control={<RadioCustom sx={{ mr: 1 }} variant="tickMark" />}
                  label={getListItemLabel(obj)}
                  key={obj.id}
                />
              ))}
            </RadioGroup>
          ) : (
            <Box>
              <NoResultFound
                imgAlt="No Projects"
                message="No Projects Found. Please try applying different filters"
                imgSrc={SWNoProjectsFound}
                messageVariant="subtitle2"
                imgSx={{ width: '50%', maxWidth: 200, mb: 1 }}
              />
            </Box>
          )}
        </FormControl>
        <DialogActions
          sx={{
            p: 0,
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={onGoBackClick}>Go back to Previous Screen</Button>
          <Box sx={{ flex: 1, gap: 1 }} display="flex" justifyContent="end">
            {dialogActions.map((obj) => (
              <Button
                variant={obj.variant}
                onClick={obj.onclick}
                disabled={obj.disabled}
              >
                {obj.children}
              </Button>
            ))}
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
export default SWProjectsListDialog
