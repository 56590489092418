import { styled } from '@mui/system'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const SWTooltipMUI = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.white,
    fontSize: '2em',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.white,
    color: theme.palette.text.primary,
    borderRadius: '10px',
    boxShadow: '0px 6px 16px #2A385827',
  },
}))
