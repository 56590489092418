import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";

import Input from "../Input/Input";

import "./SearchOptionsDropdown.scss";

/**
 * Renders options list and the provided placeholder element and
 * search input element
 */
const SearchOptionsDropdown = ({
  options,
  inputStyle = "",
  inputWrapperStyle = "",
  optionElem,
  beforeInputElem,
  searchInputCallback,
  inputActiveCallback,
  inputInActiveCallback,
  errorMessage,
  multiSelect,
}) => {
  const [input, setInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null);

  const handleOnBlur = () => {
    inputInActiveCallback && inputInActiveCallback();
  };

  const optionsList = options.map((option, i) => (
    <li key={i} className="sw-option" role="option">
      {option}
    </li>
  ));

  useEffect(() => {
    if (searchInputCallback) {
      searchInputCallback(input);
    }
  }, [input]);

  useEffect(() => {
    if (showInput) {
      inputRef.current.focus();
      inputActiveCallback && inputActiveCallback(showInput);
    } else {
      setInput("");
    }
  }, [showInput]);

  return (
    <OutsideClickHandler onOutsideClick={() => setShowInput(false)}>
      <div className="search-options-dropdown">
        {!showInput ? (
          <>
            <div
              role="listbox"
              className={`search-options-dropdown__option-elem ${
                errorMessage ? "sw-options--error" : ""
              }`}
              onClick={() => setShowInput(true)}
            >
              {optionElem}
            </div>
            {errorMessage && (
              <span className="sw-error para--12">{errorMessage}</span>
            )}
          </>
        ) : (
          <>
            <div className={inputWrapperStyle}>
              {beforeInputElem}
              <input
                ref={inputRef}
                className={`search-options-dropdown__input ${inputStyle} ${
                  errorMessage ? "sw-input--error" : ""
                }`}
                onChange={({ target }) => setInput(target.value)}
                value={input}
                onBlur={handleOnBlur}
              />
            </div>
            <ul
              onClick={() => setShowInput(!!multiSelect)}
              className="search-options-dropdown__list"
            >
              {optionsList}
            </ul>
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

SearchOptionsDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node), // Set of options to be displayed when clicked on element
  optionElem: PropTypes.node, // Option element to be clicked on to show options
  inputStyle: PropTypes.string, // className to apply style input element
  beforeInputElem: PropTypes.node, // Element to be placed before input element
  searchInputCallback: PropTypes.func, // Callback when input is provided in input field
  inputActiveCallback: PropTypes.func, // Callback when the user toggles the options
};

export default SearchOptionsDropdown;
