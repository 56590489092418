import React, { Component, createRef } from 'react'
import ReactDOM from 'react-dom'
import * as d3 from 'd3'
import saveSvgAsPng from 'save-svg-as-png'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import 'core-js/features/string/repeat'

import trendTypes from '../../faker/trendTypes'
import {
  browserIsIE,
  browserIsSafari,
  calcDisplayedWidth,
  getConciseCount,
  amplify,
} from '../../store/utility'
import ProductCard from '../../shared/components/ProductCard'
import InformationIcon from '../../shared/components/InformationIcon'
import {
  saveFilter,
  spinnerDownload,
  userSelectedProduct,
  showFilterPanel,
  setAssocStrength,
} from '../../store/actions/index'
import { isMobile } from '../../const'
import { AmplitudeValues } from '../../store/amplitudeValues'
import './TrendPhasesChartMui.scss'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    const {
      lensSelected,
      category,
      country,
      email,
      amplitudeView,
    } = ampiUserData.props
    const amplitudeUserData = {
      User: email,
      Country_Name: country,
      Category_Name: category,
      Lens: lensSelected,
      Location: amplitudeView,
    }
    amplify(amplitudeEvent, amplitudeUserData, email)
  } catch (err) {
    console.log(err, 'Amplitude error in TrendPhasesChartMui')
  }
}

// chart 1

/**
 * This component uses the following data to display a graph:
 * @param props.data - Gets out of the reducer projectListReducers.js. Here is a list of points
 * @param trendTypes - Taken from files.  It indicates the description, name, color of trends.
 * @param props.chart.trendTypes - List of selected trends.
 * @param props.trends - List of trends and their products.
 */
class TrendPhasesChartMui extends Component {
  constructor(props) {
    super(props)
    // Save array ranks to filter according to ranks
    this.ranksData = {}
    // Indentation of the chart.
    this.margin = {
      left: 0,
      top: 5,
      bottom: 0,
      right: 10,
    }
    this.controlsTrend = createRef()
    // Width and height of the chart when first rendering
    this.settings = {
      width: 5140,
      height: 420,
    }
    this.widthMiniTrend = null
    this.state = {
      tooltip: {
        isShow: false,
        left: 291,
        top: 83,
        imgUrl:
          'https://s3-ap-southeast-1.amazonaws.com/aipaletteimagesbucket/Thailandbeverage/Bael.png',
        title: 'Bell Bool Mand',
        tag: 'Sentiment',
        pos: '15',
        neg: '95',
        neutral: 10,
        rank: 0,
        dataPoint: '0',
      },
      date: {
        start: 0,
        end: 0,
      },
      onlyTrend: trendTypes[0].value, // The trend that will be displayed on mobile devices
      maxShownDataPointsCount: 0,
      shownDataPointsCount: null,
      lastRangeSelection: null,
    }

    // Depending on the number of trends, the values for x will be different.
    // This is necessary so that the description of the trend is in the center.
    this.marginDesc = {
      1: isMobile ? 20 : 40,
      2: 25,
      3: 20,
      4: 15,
      5: 5,
      6: 0,
    }
    this.activeTrends = { lft: [1, 2, 3], rgt: [4, 5, 6] } // left and right
    let totalTrends = [...this.activeTrends.lft, ...this.activeTrends.rgt]
    this.inActiveTrends = { lft: [], rgt: [] }
    const activeTrends = null
    // const activeTrends = JSON.parse(
    //   sessionStorage.getItem("defaultTrendMaturity")
    // );
    // if (activeTrends) {
    // const actTrnds = activeTrends.map(
    //   (actTrnd) =>
    //     1 +
    //     trendTypes.findIndex((trendPhase) => trendPhase.display === actTrnd)
    // );
    const actTrnds = this.props.noc
    const left = []
    const right = []
    actTrnds.forEach((id) => {
      if (id <= 3) left.push(id)
      else right.push(id)
    })
    this.activeTrends = { lft: left, rgt: right }
    totalTrends = totalTrends.filter((id) => !actTrnds.includes(id))
    totalTrends.forEach((id) => {
      if (id <= 3) this.inActiveTrends.lft.push(id)
      else this.inActiveTrends.rgt.push(id)
    })
    // }
    this.newChartingFuncs = {}
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('resize', this.resizeChart)
  }

  /**
   * Adapt graph to screen size
   */
  resizeChart = () => {
    this.settings.width =
      (document.getElementsByClassName('trends-phases-parent')[0] || {})
        .offsetWidth || this.settings.width
    if (this.settings.width <= 748) {
      this.margin.left = 30
    }
    const trend = isMobile ? this.state.onlyTrend : null
    this.redraw(trend)
  }

  handleChangeTrend = (trendId) => {
    const actTrn = this.activeTrends
    const inActTrn = this.inActiveTrends
    if (trendId) {
      if ([...actTrn.lft, ...actTrn.rgt].includes(trendId)) {
        if (trendId <= 3) {
          inActTrn.lft.push(trendId)
          actTrn.lft = actTrn.lft.filter((id) => id !== trendId)
        } else {
          inActTrn.rgt.push(trendId)
          actTrn.rgt = actTrn.rgt.filter((id) => id !== trendId)
        }
      } else {
        if (trendId <= 3) {
          actTrn.lft.push(trendId)
          inActTrn.lft = inActTrn.lft.filter((id) => id !== trendId)
        } else {
          actTrn.rgt.push(trendId)
          inActTrn.rgt = inActTrn.rgt.filter((id) => id !== trendId)
        }
      }
    }
    // })
    const combinedActiveTrends = [...actTrn.lft, ...actTrn.rgt]
    const newVal = combinedActiveTrends.map((id) => trendTypes[id - 1])
    sessionStorage.setItem(
      'defaultTrendMaturity',
      JSON.stringify(newVal.map((item) => item.display)),
    )
    this.props.saveFilter({
      trendTypes: newVal.map((item) => ({
        ...item,
        displayValue: item.display,
        value: item.display,
      })),
    })
    this.toggleTrends()
  }

  toggleTrends = (trendId, trendTypesToSave) => {
    const inActiveTrendsWidth = this.xGlobal(100) / 6
    const actTrn = this.activeTrends
    const inActTrn = this.inActiveTrends
    // Move data points
    const drawingRange = [this.xGlobal(0), this.xGlobal(100) + this.xGlobal(0)]
    const newActiveRange = [
      drawingRange[0] + inActTrn.lft.length * inActiveTrendsWidth,
      drawingRange[1] - inActTrn.rgt.length * inActiveTrendsWidth,
    ]
    const combinedActiveTrends = [...actTrn.lft, ...actTrn.rgt].sort(
      (a, b) => a - b,
    )
    const combinedInActiveTrends = [
      ...inActTrn.lft.sort((a, b) => a - b),
      ...inActTrn.rgt.sort((a, b) => a - b).reverse(),
    ]

    combinedActiveTrends.forEach((id, trendNum) => {
      const diff =
        (newActiveRange[1] - newActiveRange[0]) / combinedActiveTrends.length
      const startPoint = newActiveRange[0] + trendNum * diff
      const endPoint = startPoint + diff
      const newXScale = d3
        .scaleLinear()
        .domain([-7, 104])
        .range([startPoint, endPoint])

      this.newChartingFuncs[id] = newXScale

      const block = this.chart.select(
        `.chart__trend-block.chart__trend-block--${id}`,
      )
      block
        .selectAll(`circle`)
        .style(
          'fill',
          trendTypesToSave
            ? trendTypesToSave[0].color
            : trendTypes[id - 1].color,
        )
        // .transition(transition)
        .attr('cx', (data, index, arr) => newXScale((index / arr.length) * 100))
        .style('pointer-events', 'auto')

      const fObj = block
        .select('foreignObject')
        // .transition(transition)
        .attr(
          'x',
          30 + (newXScale(0) + newXScale(100) - this.widthMiniTrend) / 2,
        )
        .style('width', '125px')

      if (browserIsSafari()) {
        fObj.select('body').style('width', '125px')
      }

      fObj
        .select('.mini-chart__sub')
        // .transition(transition)
        .style('font-size', '12px')

      const title = fObj
        .select('.mini-chart__title')
        // .transition(transition)
        .style('font-size', '14px')
        .style('width', '120px')
        .style('border-color', trendTypes[id - 1].color)
      title
        .select('i')
        .style('transform', 'rotate(0deg)')
        .style('margin-left', '20px')
    })

    combinedInActiveTrends.forEach((id, trendNum) => {
      let startPoint = 0
      let endPoint = 0
      let linePos = 0
      if (id <= 3) {
        startPoint = drawingRange[0] + trendNum * inActiveTrendsWidth
        endPoint = startPoint + inActiveTrendsWidth
        linePos = 104
      } else {
        startPoint =
          drawingRange[1] -
          (trendNum - inActTrn.lft.length + 1) * inActiveTrendsWidth
        endPoint = startPoint + inActiveTrendsWidth
        linePos = -5
      }
      const newXScale = d3
        .scaleLinear()
        .domain([-7, 104])
        .range([startPoint, endPoint])
      this.newChartingFuncs[id] = newXScale

      const block = this.chart.select(
        `.chart__trend-block.chart__trend-block--${id}`,
      )

      block
        .selectAll('circle')
        .style('fill', trendTypesToSave ? trendTypesToSave[0].color : '#F2F2F2')
        // .transition(transition)
        .attr('cx', (data, index, arr) => newXScale((index / arr.length) * 100))
        .style('pointer-events', 'none')

      const fObj = block
        .select('foreignObject')
        // .transition(transition)
        .attr(
          'x',
          60 + (newXScale(0) + newXScale(100) - this.widthMiniTrend) / 2,
        )
        .style('width', '90px')

      fObj
        .select('.mini-chart__sub')
        // .transition(transition)
        .style('font-size', '9px')

      if (browserIsSafari()) {
        fObj.select('body').style('width', '90px')
      }

      const title = fObj
        .select('.mini-chart__title')
        // .transition(transition)
        .style('font-size', '14px')
        .style('border-color', '#C6C6C6')

      title
        .select('i')
        .style('transform', 'rotate(45deg)')
        .style('margin-left', '10px')
    })
    // this.chart
    //   .selectAll(".y-grids")
    //   .attr("x1", (d, i) =>
    //     this.newChartingFuncs[i + 1](
    //       combinedInActiveTrends.length === 6 && i >= 3 ? -5 : 104
    //     )
    //   )
    //   .attr("x2", (d, i) =>
    //     this.newChartingFuncs[i + 1](
    //       combinedInActiveTrends.length === 6 && i >= 3 ? -5 : 104
    //     )
    //   );
    this.showTopDataPoints(!!trendTypesToSave)
  }

  /**
   * Open product page in new tab on
   * clicking trend points
   */
  trendPointClickHandler = (productId, name, phase) => {
    const {
      projectId,
      lensSelected,
      category,
      country,
      email,
      amplitudeView,
    } = this.props
    const ampiEvent = `Clicked_Trend_Lens`
    const ampiUserData = {
      User: email,
      'Maturity Phase': phase,
      Trend_Name: name,
      Lens: lensSelected,
      Country_Name: country,
      Category_Name: category,
      Redirected_From: amplitudeView,
    }
    amplify(ampiEvent, ampiUserData, email)
    const link = `/mui/details/${projectId}/${lensSelected.toLowerCase()}/${productId}`
    window.open(link)
  }

  hoverAmplitude = () => {
    const ampiEvent = `Hover_Info_Volume_of_Engagement`
    const ampiUserData = {
      props: this.props,
    }
    amp(ampiEvent, ampiUserData)
  }

  /**
   * Close tooltip
   * @param ev
   */
  handleClickOutside = (ev) => {
    return null
    const path = ev.path || (ev.composedPath && ev.composedPath())
    const uptTooltip = { ...this.state.tooltip }
    const tooltipElement = document.getElementById('chart1-tooltip')

    if (
      path &&
      !path.includes(tooltipElement) &&
      ev.target.getAttribute('class') !== 'chart__trend-block__circle' &&
      uptTooltip.isShow === true
    ) {
      uptTooltip.isShow = false
      const t = document.getElementById('removeCircle')
      if (t) {
        t.remove()
      }
      this.setState({ tooltip: uptTooltip })
    }
  }

  /**
   * Close tooltip
   */
  closeTooltip = () => {
    const uptTooltip = { ...this.state.tooltip }
    if (uptTooltip.isShow) {
      uptTooltip.isShow = false
      const oldHoverCircle = document.getElementById('removeCircle')
      if (oldHoverCircle) {
        oldHoverCircle.remove()
      }
      this.setState({ tooltip: uptTooltip })
    }
  }

  /**
   * Redraw chart
   * @param onlyTrend {string|boolean} - name trend
   */
  redraw = () => {
    this.chart.selectAll('g').remove()
    this.renderChartTrend()
    this.painTrend(null, true)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.div = ReactDOM.findDOMNode(this)
    this.svg = d3
      .select('.trends-phases-parent')
      .append('svg')
      .attr('version', 1.1)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('class', 'trend-phases-chart')
      .attr('id', 'chart1')
      .attr('width', '100%')
      .attr('height', this.settings.height)
      .attr('style', 'background: white')
    this.brushPlane = this.svg.append('g')
    this.chart = this.svg.append('g')
    this.settings.width =
      (document.getElementsByClassName('trends-phases-parent')[0] || {})
        .offsetWidth || this.settings.width
    window.addEventListener('resize', this.resizeChart)
    this.resizeChart()
    this.renderChartTrend()
    this.painTrend(null, true)
    this.props.showFilterPanel(true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.trends !== this.props.trends) {
      this.resizeChart()
    }
    if (prevProps.expandedFilterPanel !== this.props.expandedFilterPanel) {
      this.resizeChart()
    }
    if (prevProps.maxTrendsShown !== this.props.maxTrendsShown) {
      this.resizeChart()
    }
    if (prevState.shownDataPointsCount !== this.state.shownDataPointsCount) {
      //   this.showTopDataPoints();
    }
    if (this.props.noc !== prevProps.noc) {
      let selId = 0
      const prevNoc = prevProps.noc
      const { noc } = this.props
      if (noc.length > prevNoc.length) {
        selId = noc.filter((id) => !prevNoc.includes(id))[0]
      } else {
        selId = prevNoc.filter((id) => !noc.includes(id))[0]
      }
      this.handleChangeTrend(selId)
    }
  }

  /**
   * Spread data points
   * Calculate value by which to spread data points on the
   * graph when filtered
   */
  dataPointsSpreadCalculator = (arr, index, func) => {
    // const { shownDataPointsCount } = this.state;

    const shownDataPointsCount = this.props.maxTrendsShown
    const denom =
      shownDataPointsCount < arr.length ? shownDataPointsCount : arr.length
    if (!denom) {
      return -100
    }
    const step = 100 / denom // The number of points is divided by 100. Since the x axis is from 0 to 100.
    const newDPcount = arr.length - shownDataPointsCount
    return func((index - (newDPcount > 0 ? newDPcount : 0)) * step)
  }

  /**
   * Show top data points
   * Set all points other than the top data points to none
   */
  showTopDataPoints = (isDownloading) => {
    if (!isEmpty(this.ranksData)) {
      const shownDataPointsCount = this.props.maxTrendsShown
      const combinedActiveTrends = [
        ...this.activeTrends.rgt,
        ...this.activeTrends.lft,
      ]
      combinedActiveTrends.forEach((i) => {
        i = isDownloading ? 1 : i
        this.svg
          .selectAll(`.chart__trend-block--${i} > circle`)
          .attr('display', (data, ind, arr) => {
            const allRanks = this.ranksData[i - 1]
            const searchIndex =
              allRanks.length < shownDataPointsCount
                ? -allRanks.length
                : -shownDataPointsCount
            if (!searchIndex) {
              return 'none'
            }
            return data
              ? allRanks.includes(data.rank, searchIndex)
                ? 'inline-block'
                : 'none'
              : 'inline-block'
          })
          .attr('cx', (data, ind, arr) => {
            if (
              [...this.inActiveTrends.lft, ...this.inActiveTrends.rgt].length &&
              !isDownloading
            ) {
              return this.dataPointsSpreadCalculator(
                arr,
                ind,
                this.newChartingFuncs[i],
              )
            }
            return this.dataPointsSpreadCalculator(
              arr,
              ind,
              this.xMiniChart[i - 1].func,
            )
          })
      })
    }
  }

  /**
   * Save chart as png
   * Create a copy of the schedule, add headers, add the schedule to the DOM, save the picture, delete from the DOM
   */
  handleSaveChart = async () => {
    // const { shownDataPointsCount } = this.state;
    const shownDataPointsCount = this.props.maxTrendsShown
    if (shownDataPointsCount > 20) {
      const imagesList = []
      for (let i = 0; i < this.props.chart.trendTypes.length; i++) {
        let toRender = true
        const combinedInActiveTrends = [
          ...this.inActiveTrends.lft,
          ...this.inActiveTrends.rgt,
        ]
        toRender = !combinedInActiveTrends.length
          ? true
          : !combinedInActiveTrends.find((id) => {
              if (
                this.props.chart.trendTypes[i].display ===
                trendTypes[id - 1].display
              ) {
                return true
              }
            })
        if (toRender) {
          const img = await this.renderSingleTrend([
            this.props.chart.trendTypes[i],
          ])
          if (img) {
            imagesList.push(img)
          }
        }
      }
      if (imagesList.length > 0) {
        const canvasCopy = document.createElement('canvas')
        const svgWidth = imagesList[0].width
        const svgHeight = imagesList[0].height
        canvasCopy.width = imagesList.length === 1 ? svgWidth : svgWidth * 2
        canvasCopy.height = Math.ceil(imagesList.length / 2) * svgHeight
        const ctx = canvasCopy.getContext('2d')
        // Draw a white screen to act as background
        ctx.beginPath()
        ctx.fillStyle = '#FFFFFF'
        ctx.fillRect(0, 0, canvasCopy.width, canvasCopy.height)
        ctx.stroke()
        let line = 0
        imagesList.forEach((img, i) => {
          const x = i % 2 === 0 ? 0 : svgWidth
          const y = svgHeight * (i % 2 === 0 ? line : line++)
          ctx.drawImage(img, x, y, svgWidth, svgHeight)
        })
        canvasCopy.toBlob((blob) =>
          window.saveAs(blob, `Consolidated_classification`),
        )
      }
    } else {
      const circlesTitle = d3.select(
        document.getElementById('chart1').cloneNode(true),
      )

      circlesTitle.selectAll('i').remove()
      circlesTitle
        .selectAll('.mini-chart__title')
        .style('border-color', 'transparent')
      circlesTitle
        .selectAll('circle.chart__trend-block__circle')
        .each(function(d, i) {
          const cx = parseFloat(d3.select(this).attr('cx'))
          const cy = parseFloat(d3.select(this).attr('cy'))
          const displayVal =
            d3
              .select(this)
              .style('fill')
              .trim() === 'rgb(242, 242, 242)'
              ? 'none'
              : d3.select(this).attr('display')
          const name = d3.select(this).attr('data-name')
          const textWidth = Math.ceil(calcDisplayedWidth(name, 15))
          const toRightOfDp = cy - 10
          const toLeftOfDp = cy + textWidth + 10
          let y = i % 2 === 0 ? toRightOfDp : toLeftOfDp
          y = y > 615 ? toRightOfDp : y
          y = cy - textWidth < 30 ? toLeftOfDp : y
          if (!browserIsSafari()) {
            circlesTitle
              .append('foreignObject')
              .attr(
                'style',
                `width: 300px; height: 50px; transform: rotate(-90deg)`,
              )
              .attr('display', displayVal)
              .attr('y', cx - 8)
              .attr('x', -y)
              .attr('class', 'mini-chart__desc deleted-save')
              .append('xhtml:body')
              .attr(
                'style',
                'background: transparent; display: flex; flex-direction: column;',
              )
              .append('p')
              .attr('class', 'deleted')
              .attr('style', 'font-size: 14px; font-weight: normal;')
              .text(name)
          } else {
            circlesTitle
              .append('text')
              .attr(
                'style',
                `width: 300px; height: 50px; transform: rotate(-90deg)`,
              )
              .attr('display', displayVal)
              .attr('y', cx + 6)
              .attr('x', -y)
              .text(name)
          }
        })
      circlesTitle
        .attr('id', 'chart1-save-svg')
        // eslint-disable-next-line
        .attr('width', (chart1.parentElement || chart1.parentNode).offsetWidth)
      document.getElementById('chart1-save').appendChild(circlesTitle.node())
      try {
        if (browserIsIE()) {
          // IE FIX
          var svg = document.getElementById('chart1') // document.querySelector('svg');
          var xml = new XMLSerializer().serializeToString(svg)
          var svg64 = btoa(xml) // for utf8: btoa(unescape(encodeURIComponent(xml)))
          var b64start = 'data:image/svg+xml;base64,'
          var uri = b64start + svg64 // image64
          const byteString = window.atob(uri.split(',')[1])
          const mimeString = uri
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
          const buffer = new ArrayBuffer(byteString.length)
          const intArray = new Uint8Array(buffer)

          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i)
          }

          const blob = new Blob([buffer], { type: mimeString })
          window.saveAs(blob, 'Consolidated_classification')
          return blob
        } else {
          // Chrome, Mozilla, Opera, Safari etc.
          saveSvgAsPng.saveSvgAsPng(
            document.getElementById('chart1-save-svg'),
            'Consolidated_classification',
          )
        }
        if (!browserIsIE()) {
          document.getElementById('chart1-save-svg').remove()
        }
      } catch (error) {}
    }
  }

  /**
   * Produce image of a single trend.
   * @param trend {Array}
   */
  renderSingleTrend = (arr) => {
    return new Promise((resolve, reject) => {
      this.chart.selectAll('.deleted').remove()
      this.painTrend(arr)
      const circlesTitle = d3.select(
        document.getElementById('chart1').cloneNode(true),
      )
      circlesTitle.select('#trend-phases-chart-tooltip').remove()
      circlesTitle.selectAll('.y-grids').remove()
      circlesTitle.selectAll('i').remove()
      circlesTitle
        .selectAll('.mini-chart__title')
        .style('border-color', 'transparent')
      const chartMidPt = this.settings.width / 2
      circlesTitle
        .selectAll('circle.chart__trend-block__circle')
        .each(function(d, i) {
          const cx = parseFloat(d3.select(this).attr('cx'))
          const cy = parseFloat(d3.select(this).attr('cy'))
          const displayVal = d3.select(this).attr('display')
          const name = d3.select(this).attr('data-name')
          const textWidth = Math.ceil(calcDisplayedWidth(name, 15))
          const toRightOfDp = cy - 10
          const toLeftOfDp = cy + textWidth + 10
          let y = i % 2 === 0 ? toRightOfDp : toLeftOfDp
          y = y > 615 ? toRightOfDp : y
          y = cy - textWidth < 30 ? toLeftOfDp : y
          if (!browserIsSafari()) {
            circlesTitle
              .append('foreignObject')
              .attr(
                'style',
                `width: 300px; height: 50px; transform: rotate(-90deg)`,
              )
              .attr('display', displayVal)
              .attr('y', cx - 8)
              .attr('x', -y)
              .attr('class', 'mini-chart__desc deleted-save')
              .append('xhtml:body')
              .attr(
                'style',
                'background: transparent; display: flex; flex-direction: column;',
              )
              .append('p')
              .attr('class', 'deleted')
              .attr('style', 'font-size: 14px; font-weight: normal;')
              .text(name)
          } else {
            circlesTitle
              .append('text')
              .attr(
                'style',
                `width: 300px; height: 50px; transform: rotate(-90deg)`,
              )
              .attr('display', displayVal)
              .attr('y', cx + 6)
              .attr('x', -y)
              .text(name)
          }
        })

      circlesTitle
        .attr('id', 'chart1-save-svg')
        // eslint-disable-next-line
        .attr('width', (chart1.parentElement || chart1.parentNode).offsetWidth)
      document.getElementById('chart1-save').appendChild(circlesTitle.node())
      saveSvgAsPng
        .svgAsDataUri(document.getElementById('chart1-save-svg'))
        .then((dataUri) => {
          const img = new Image()
          img.onload = () => {
            document.getElementById('chart1-save-svg').remove()
            this.chart.selectAll('.deleted').remove()
            this.painTrend()
            resolve(img)
          }
          img.src = dataUri
        })
        // eslint-disable-next-line
        .catch((err) => {
          // eslint-disable-next-line
          reject(false)
        })
    })
  }

  painTrend = (trendTypesToSave, updateMaxDataCount = false) => {
    const { trends } = this.props
    const _trendTypes = [...trendTypes]
    const trend = _trendTypes
      .map((tr) => {
        for (let i = 0; i < trends.length; i++) {
          if (trends[i].type.indexOf(tr.display) > -1) {
            tr.data = [...trends[i].allTrendResults]
              .sort((trend1, trend2) => trend1.scoreRank - trend2.scoreRank)
              .map((tr) => ({ ...tr, rank: tr.scoreRank }))
          }
        }
        return tr
      })
      .filter((tr) => {
        return trendTypesToSave
          ? trendTypesToSave[0].display === tr.display
          : true
      })
    trend.forEach((tr, i) => {
      this.ranksData[i] = tr.data.map((data) => data.rank).sort((a, b) => b - a)
    })
    const rangeGlobal = this.rangeGlobal
    this.trendY = d3
      .scaleLinear()
      .domain([-12, 112])
      .range(rangeGlobal.yGlobal)

    const trendCount = trend.length
    // trendXStep - Step of each trend relative to the main axis
    const trendXStep =
      trendCount === 1 ? this.xGlobal(100) : this.xGlobal(100) / trendCount // maxValue/count mini Chart
    // A mini trend chart is created
    this.listChart = this.chart
      .select('.chart_blocks')
      .selectAll('.chart__trend-block')
      .data(trend)

    this.listChart.exit().remove()
    // Create separate blocks for each trend.
    this.renderChart = this.listChart
      .enter()
      .append('g')
      .attr(
        'class',
        (z, i) => `chart__trend-block chart__trend-block--${i + 1} d3-gen`,
      )

    this.renderChart.exit().remove()
    this.xMiniChart = []
    this.yGlobal.domain([-10, 110])
    const chartPlato = this.chart.select('.chart_plato')
    chartPlato
      .selectAll('.x-grids')
      .attr('y1', (d, i) => this.yGlobal(10 * i))
      .attr('y2', (d, i) => this.yGlobal(10 * i))

    chartPlato
      .selectAll('text.trend-phase-section-text')
      .attr('y', (d, i) => this.yGlobal(10 * i))

    trend.forEach((miniChart, number) => {
      /**
       * Rendering mini charts
       */
      const startRange = number * trendXStep + this.xGlobal(0)
      const endRange = ++number * trendXStep + this.xGlobal(0)
      const xMiniChart = d3
        .scaleLinear()
        .domain([-7, 104])
        .range([startRange, endRange])
      this.xMiniChart.push({ func: xMiniChart, range: [startRange, endRange] })
      if (!this.widthMiniTrend) {
        const startRange = trendXStep + this.xGlobal(0)
        const endRange = trendXStep + this.xGlobal(0)
        const nextMiniChart = d3
          .scaleLinear()
          .domain([-7, 104])
          .range([startRange, endRange])

        this.widthMiniTrend = Math.abs(xMiniChart(-5) - nextMiniChart(-5))
      }
      const renderMiniChart = this.chart.select(
        `.chart__trend-block.chart__trend-block--${number}`,
      )
      const desc = renderMiniChart.append('g')

      // We draw the boundaries of the trend
      this.chart
        .select('.chart_plato')
        .append('line')
        .attr('x1', xMiniChart(-5))
        .attr('y1', this.yGlobal(0))
        .attr('x2', xMiniChart(-5))
        .attr('y2', this.yGlobal(100))
        .attr('class', 'y-grids trend-phases-line deleted gray')
        .attr('style', 'stroke: #282830; opacity: 0.34;')
        .attr('stroke-dasharray', '5')
        .attr(
          'clip-path',
          'url(#clip-y-grids-chart1)',
          // eslint-disable-next-line
        ) /* required for zoom functionality only*/

      // .on("click", () => this.handleChangeTrend(number))

      renderMiniChart.exit().remove()

      /* End of mini chart render */

      /**
       * After creating the boundaries, descriptions and names of the trend.
       * Draw the dots.
       */

      let chartData = [...miniChart.data]
      chartData = chartData.reverse()
      const cxFunc = (d, i, ar) => {
        // debugger
        // return 100
        return this.dataPointsSpreadCalculator(ar, i, xMiniChart)
      }
      const cyFunc = (d) => {
        let cy = Math.abs(d.score)
        if (cy > 100) {
          cy = 100
        }
        return this.trendY(cy)
      }
      const cyrcles = renderMiniChart
        .selectAll('circle.chart__trend-block__circle')
        .data(chartData)
        .enter()
        .append('circle')
        .attr('cx', cxFunc)
        .attr('cy', cyFunc)
        .attr('r', '5')
        .attr('class', 'chart__trend-block__circle deleted')
        .style('fill', miniChart.color)
        .attr('opacity', '0.9')
        .attr('data-rank', (data) => data.rank)
        .attr('data-name', (data) => data.name)
      /**
       * Cursor to dot interactions.
       */
      cyrcles.on('click', (data) =>
        this.trendPointClickHandler(data.id, data.name, miniChart.display),
      )
      cyrcles.on('mouseover', (data, index, listCyrcle) => {
        // Check for opening and closing tooltip.
        if (this.state.tooltip.isShow && this.state.tooltip.title === data.name)
          return
        if (this.timeCloseTooltip) {
          clearInterval(this.timeCloseTooltip)
        }
        this.timeCloseTooltip = setTimeout(() => {
          this.closeTooltip()
        }, 15000)

        // Calculate the coordinates for the tooltip.
        const cy = listCyrcle[index].getAttribute('cy')
        const cx = listCyrcle[index].getAttribute('cx')
        let style = listCyrcle[index].getAttribute('style').split(':')[1]
        if (style.includes('pointer')) {
          style = style.split(';')[0] + ';'
        }
        let top = Math.floor(Number(cy))
        let left = Math.floor(cx)
        const svgWidth = this.svg.node()
          ? this.svg.node().getBoundingClientRect().width
          : 1122
        if (left + this.margin.right + this.margin.left + 230 > svgWidth) {
          left = left - 260
        }
        if (top > 500) {
          top = top - 265
        }
        try {
          // Create a point ring
          const parent =
            listCyrcle[index].parentElement || listCyrcle[index].parentNode // IE11 FIX
          const oldHoverCircle = document.getElementById('removeCircle')

          if (oldHoverCircle) {
            oldHoverCircle.remove()
          }
          const cir1 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'circle',
          )
          cir1.setAttribute('cx', cx)
          cir1.setAttribute('cy', cy)
          cir1.setAttribute('r', 7)

          try {
            // Internally handling this because, it's preventing the outer 'white' circle on hover from appearing
            cir1.setAttribute('style', `stroke:${style.trim()} fill: white`)
          } catch (error) {}

          // prepend(), IIFE - IE11 FIX */
          ;(function(arr) {
            arr.forEach(function(item) {
              // eslint-disable-next-line
              if (item.hasOwnProperty('prepend')) {
                return
              }

              Object.defineProperty(item, 'prepend', {
                configurable: true,
                enumerable: true,
                writable: true,
                value: function prepend() {
                  var argArr = Array.prototype.slice.call(arguments)
                  var docFrag = document.createDocumentFragment()
                  argArr.forEach(function(argItem) {
                    var isNode = argItem instanceof Node

                    docFrag.appendChild(
                      isNode
                        ? argItem
                        : document.createTextNode(String(argItem)),
                    )
                  })

                  this.insertBefore(docFrag, this.firstChild)
                },
              })
            })
          })([
            Element.prototype,
            Document.prototype,
            DocumentFragment.prototype,
          ])

          cir1.setAttribute('id', 'removeCircle')
          parent.prepend(cir1)
        } catch (error) {}

        // Updating the State
        const uptTooltip = { ...this.state.tooltip }
        uptTooltip.top = top
        uptTooltip.left = left + 40
        uptTooltip.top > 260
          ? (uptTooltip.top = top - 150)
          : (uptTooltip.top = top)
        uptTooltip.imgUrl = data.image
        uptTooltip.title = data.name
        uptTooltip.selectId = index
        uptTooltip.isShow = true
        uptTooltip.productID = data.id
        uptTooltip.rank = data.rank
        uptTooltip.dataPoint = getConciseCount(data.data_point)
        // document
        this.setState({ tooltip: uptTooltip })
      })

      cyrcles.exit().remove()
    })
    // Zooming
    let idleTimeout
    const idleDelay = 350

    var brushended = (reset) => {
      var selectionArea = d3.event.selection
      if (!selectionArea || reset) {
        if (!idleTimeout && !reset) {
          return (idleTimeout = setTimeout(idled, idleDelay))
        }
        this.chart.select('#trends-zoom-reset-btn').remove()
        this.xMiniChart = this.xMiniChart.map((miniChartFunc) => {
          const { func, range } = miniChartFunc
          return { range, func: func.domain([-7, 104]).range(range) }
        })
        this.yGlobal.domain([-10, 110])
        this.trendY.domain([-12, 112])
        zoom()
      } else {
        this.yGlobal.domain(
          [selectionArea[1][1], selectionArea[0][1]].map(
            this.yGlobal.invert,
            this.yGlobal,
          ),
        )
        this.trendY.domain(
          [selectionArea[1][1], selectionArea[0][1]].map(
            this.trendY.invert,
            this.trendY,
          ),
        )
        // eslint-disable-next-line
        this.brushPlane.select('.brush').call(brush.move, null)
        this.xMiniChart = this.xMiniChart.map((miniChartFunc) => {
          const { func, range } = miniChartFunc
          return {
            func: func
              .domain(
                [selectionArea[0][0], selectionArea[1][0]].map(
                  func.invert,
                  func,
                ),
              )
              .range([this.xGlobal(0), this.xGlobal(100)]),
            range,
          }
        })
        zoom(true)
      }
      this.closeTooltip()
    }

    var idled = () => {
      idleTimeout = null
    }

    var zoom = (drawBtn) => {
      // Draw Reset Zoom
      if (drawBtn && !document.getElementById('trends-zoom-reset-btn')) {
        const zoomBtn = this.chart
          .append('g')
          .attr('id', 'trends-zoom-reset-btn')
          .attr('cursor', 'pointer')
        const resetZoomBtn = zoomBtn
          .append('rect')
          .attr('width', 100)
          .attr('height', 30)
          .attr('fill', '#efefef')
          .attr('x', this.settings.width - 150)
          .attr('y', 10)
          .attr('rx', 5)
        zoomBtn
          .append('text')
          .attr('x', this.settings.width - 140)
          .attr('y', 30)
          .attr('fill', 'black')
          .text('Reset Zoom')

        const zoomButtonHover = (hover) => {
          resetZoomBtn.attr('fill', hover ? '#f1f1f1' : '#efefef')
        }
        zoomBtn.on('mouseover', () => zoomButtonHover(true))
        zoomBtn.on('mouseout', () => zoomButtonHover(false))
        zoomBtn.on('click', () => brushended(true))
      }
      var transition = this.chart.transition().duration(750)
      const trendY = this.trendY
      const xMiniChart = this.xMiniChart
      // Zooming on circles
      this.svg.selectAll('.chart__trend-block').each(function(data, index) {
        d3.select(this)
          .selectAll('.chart__trend-block__circle')
          // .transition(transition)
          .attr('cx', (d, i, ar) => {
            const step = 100 / ar.length
            return xMiniChart[index].func(i * step)
          })
          .attr('cy', (d) => {
            let cy = Math.abs(d.score)
            if (cy > 100) {
              cy = 100
            }
            return trendY(cy)
          })
        // Zooming description
        d3.select(this)
          .select('.mini-chart__desc')
          // .transition(transition)
          .attr('x', () => {
            const xMiniFunc = xMiniChart[index].func
            return (xMiniFunc(0) + xMiniFunc(100) - 180) / 2
          })
      })
      // .transition(transition);

      // Zooming y axes grids - Vertical
      this.chart
        .select('.chart_plato')
        .selectAll('.y-grids')
        // .transition(transition)
        .attr('x1', (dat, ind) => xMiniChart[ind + 1].func(-5))
        .attr('x2', (dat, ind) => xMiniChart[ind + 1].func(-5))

      // Zooming x axes grids - Horizontal
      this.chart
        .select('.chart_plato')
        .selectAll('.x-grids')
        // .transition(transition)
        .attr('y1', (dat, ind) => this.yGlobal(ind * 10))
        .attr('y2', (dat, ind) => this.yGlobal(ind * 10))

      // Zooming the y values
      this.chart
        .select('.chart_plato')
        .selectAll('text')
        // .transition(transition)
        .attr('y', (dat, ind) => this.yGlobal(10 * ind))
    }
    if (!trendTypesToSave) {
      this.toggleTrends(null, trendTypesToSave)
    } else {
      this.showTopDataPoints(!!trendTypesToSave)
    }
  }

  /**
   * We draw axes, lines
   */
  renderChartTrend = () => {
    this.rangeGlobal = {
      // This is where the graph values are stored.
      xGlobal: [this.margin.left, this.settings.width - this.margin.right],
      yGlobal: [this.settings.height - this.margin.bottom, 0 + this.margin.top],
    }

    this.xGlobal = d3
      .scaleLinear()
      .domain([-10, 110]) // -10 here is added just to ensure that growth point do not touch last parts of graph
      .range(this.rangeGlobal.xGlobal)

    this.yGlobal = d3
      .scaleLinear()
      .domain([-10, 110])
      .range(this.rangeGlobal.yGlobal)

    const chart = this.chart
    this.svg.selectAll('defs').remove()
    // Clip-path for area where the data points are renders
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-chart1')
      .append('rect')
      .attr('width', this.xGlobal(130))
      .attr('height', 580)
      .attr('transform', 'translate(90,40)')
    // Clip-path for Trend Descriptions
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-desc-chart1')
      .append('rect')
      .attr('width', this.xGlobal(130))
      .attr('height', 200)
      .attr('transform', 'translate(90,590)')
    // Clip-path for Y Grids
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-y-grids-chart1')
      .append('rect')
      .attr('width', this.xGlobal(130))
      .attr('height', 700)
      .attr('transform', 'translate(90,0)')
    // Clip-path for Y values
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-y-val-chart1')
      .append('rect')
      .attr('width', 90)
      .attr('height', 580)
      .attr('transform', 'translate(0,40)')
    // main x
    chart.select('.chart_plato').remove()
    chart.select('.chart_blocks').remove()

    const plato = chart.append('g').classed('chart_plato', true)

    chart.append('g').classed('chart_blocks', true)
    this.svg.selectAll('.trend-chart-legend').remove()
    const legend = this.svg.append('g').classed('trend-chart-legend', true)
    legend
      .append('text')
      .attr('x', -220)
      .attr('y', 20)
      .attr('class', 'mini-chart__title trend-phase-section-text')
      .attr('style', 'font-size: 16px; transform: rotate(-90deg);')
      .text('Volume of Engagement')

    d3.select('#trends-chart-hover-tooltip-y').attr(
      'style',
      `position: absolute; top: 110px; z-index: 6; left: 23px; transform: rotate(-90deg);`,
    )
    // Cell
    for (let i = 0; i <= 10; i++) {
      plato
        .append('line')
        .attr('x1', this.xGlobal(0))
        .attr('y1', this.yGlobal(10 * i))
        .attr('x2', this.xGlobal(130))
        .attr('y2', this.yGlobal(10 * i))
        .attr('class', 'x-grids trend-phases-line')
        .attr('stroke-dasharray', '5')
        .attr('style', ' stroke: #282830; opacity: 0.34;')

      plato
        .append('text')
        .attr('x', this.xGlobal(-4))
        .attr('y', this.yGlobal(10 * i))
        .attr('class', 'trend-phase-section-text')
        .text(`${i * 10}%`)
    }
  }

  render() {
    const { tooltip } = this.state
    return (
      <div
        className="trends-chart-container"
        style={{ background: 'white', overflow: 'hidden' }}
      >
        <div className="container chart1-container">
          <div>
            <div id="trends-chart-hover-tooltip-y">
              <InformationIcon
                onTooltipOpen={() => this.hoverAmplitude()}
                tooltipText={`Sum of mentions, likes, comments, shares from social media, the volume of search queries${
                  this.props.projectVariant === 'bpc'
                    ? ' and retail products.'
                    : ', products present in retail and foodservice, and mentions in recipes.'
                }`}
              />
            </div>
          </div>
          <div className="col-md-12 trends-phases-parent"></div>
          <div id="chart1-save" style={{ display: 'none' }} />
          <ProductCard
            id="chart1-tooltip"
            left={tooltip.left}
            top={tooltip.top}
            isPC={tooltip.isShow}
            projectid={this.props.projectId}
            lensSelected={this.props.lensSelected}
            productid={tooltip.productID}
            name={tooltip.title}
            rank={tooltip.rank}
            image={tooltip.imgUrl}
            dataPoint={String(tooltip.dataPoint)}
            closeTooltip={this.closeTooltip}
          />
        </div>
      </div>
    )
  }
}

TrendPhasesChartMui.propTypes = {
  projectListBuilder: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  chart: state.chart,
  expandedFilterPanel: state.application.expandedFilterPanel,
  projectList: state.projectListBuilder.projectList,
  assocLevl: state.projectListBuilder.assocLevl,
  projectVariant: state.projectListBuilder.projectList?.projectVariant,
})

const mapDispatchToProps = (dispatch) => {
  return {
    saveFilter: (payload) => dispatch(saveFilter(payload)),
    spinnerDownload: (isShow) => dispatch(spinnerDownload(isShow)),
    userSelectedProduct: (productid) =>
      dispatch(userSelectedProduct(productid)),
    showFilterPanel: (show) => dispatch(showFilterPanel(show)),
    setAssocStrength: (id) => dispatch(setAssocStrength(id)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
  )(TrendPhasesChartMui),
)
