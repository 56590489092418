import { ScreenWinner } from "../../actions/actionTypes";
import { updateObject } from "../../utility";
import { notifications } from "../../../const";

const initialState = {
  screenStatusMessage: "",
  fetchingStatus: false,
  subscribed: false,
  projectsList: [],
  conceptsList: [],
  projectDetails: null, //{id: 3 , description: '', country: '' , category: '', brand: ''}
  breadCrumbs: [],
  notifications: [], // {   type: "success",   message: "Testing some content in real layouts",   id: new Date().getTime(),   timeStamp: new Date().getTime(), },
  searchResults: {
    conceptsList: [],
    projectsList: [],
  },
};

const notificationDefaults = (notification) => {
  const { message, type } = notification;
  let defaultMessage = message;
  if (!message) {
    switch (type) {
      case notifications.SUCCESS:
        defaultMessage = "Successful";
        break;
      case notifications.ERROR:
        defaultMessage = "Encountered an error";
        break;
      case notifications.INFO:
        defaultMessage = "Info";
        break;
      case notifications.WARNING:
        defaultMessage = "Warning";
        break;
    }
  }
  return { ...notification, message: defaultMessage };
};

const screenWinner = (state = { ...initialState }, action) => {
  switch (action.type) {
    case ScreenWinner.type[ScreenWinner.FETCHING_STATUS]:
      return updateObject(state, { fetchingStatus: action.fetching });
    case ScreenWinner.type[ScreenWinner.SUBSCRIPTION_EXISTS]:
      return updateObject(state, { subscribed: action.status });
    case ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_SUCCESS]:
      return updateObject(state, { projectsList: action.projectsList });
    case ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_DETAILS_SUCCESS]:
      return updateObject(state, { projectDetails: action.projectDetails });
    case ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_SUCCESS]:
      return updateObject(state, { conceptsList: action.conceptsList });
    case ScreenWinner.type[ScreenWinner.NOTIFICATION_ADD]: {
      const notifications = [...state.notifications];
      const lastNotification = notifications.slice(-1)[0];
      const {
        message,
        type,
        action: notifAction,
      } = notificationDefaults(action.notification);
      if (
        lastNotification &&
        lastNotification.message === message &&
        lastNotification.type === type
      ) {
        if (notifAction) {
          if (lastNotification.action.text === notifAction.text) {
            notifications.pop();
          }
        } else {
          notifications.pop();
        }
      }
      notifications.push({ ...action.notification, message });
      return updateObject(state, { notifications });
    }
    case ScreenWinner.type[ScreenWinner.NOTIFICATION_REMOVE]: {
      const notifications = state.notifications.filter(
        ({ id }) => id !== action.id
      );
      return updateObject(state, { notifications });
    }
    case ScreenWinner.type[ScreenWinner.FETCH_PROJECTS_SEARCH_QUERY_SUCCESS]:
      return updateObject(state, {
        searchResults: {
          ...state.searchResults,
          projectsList: action.projectsList,
          conceptsList: action.conceptsList,
        },
      });
    case ScreenWinner.type[ScreenWinner.FETCH_CONCEPTS_SEARCH_QUERY_SUCCESS]:
      return updateObject(state, {
        searchResults: {
          ...state.searchResults,
          conceptsList: action.conceptsList,
        },
      });
    case ScreenWinner.type[ScreenWinner.CLEAR_SEARCH_RESULTS]:
      return updateObject(state, {
        searchResults: {
          projectsList: [],
          conceptsList: [],
        },
      });
    // case ScreenWinner.type[ScreenWinner.UPDATING_CONCEPTS_FAILED]:
    //   return updateObject(state, {
    //     conceptsSavingStatus: action.status// error, success
    //   });
    case ScreenWinner.type[ScreenWinner.SCREENING_ERROR]:
      return updateObject(state, {
        screenStatusMessage: "Error",
      });
    default:
      return state;
  }
};

export default screenWinner;
