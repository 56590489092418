import styled from '@emotion/styled';
import { Box } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  minWidth: '330px',
  minHeight: '354px',
  cursor: 'pointer',
  overflow: 'hidden',
  '& .image': {
    width: '100%',
    height: 170,
    objectFit: 'cover',
    backgroundColor: theme.palette.grey[200],
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
  },
  '&:active': {
    backgroundColor: theme.palette.grey[100],
    boxShadow: '0px 4px 12px rgba(39, 43, 49, 0.03)',
  },
}));

