import styled from '@emotion/styled';
import { ToggleButtonGroup } from '@mui/material'

const ToggleBtnGrp = styled(ToggleButtonGroup)(({ theme }) => ({
    height: 35,
    '& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected' : {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
    }
}))

export default ToggleBtnGrp