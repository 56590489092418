import typography from '../typography';
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      ...typography.subtitle2,
      borderRadius: 4,
      background: 'transparent',
      padding: 0,
      color: palette.text.primary,
      '& .MuiOutlinedInput-input': {
        height: 16,
        padding: '6px 8px',
        '&.MuiSelect-select': {
          height: 16,
        },
      },
      '&::placeholder': {
        opacity: 1,
        color: palette.text.hint,
      },
      '::-ms-input-placeholder': {
        opacity: 1,
        color: palette.text.hint,
      },
      ':-ms-input-placeholder': {
        opacity: 1,
        color: palette.text.hint,
      },
      '&:disabled': {
        pointerEvents: 'none',
        background: palette.background.default,
        color: palette.text.disabled,
      },
    },
    sizeSmall: {
      '& .MuiInputBase-input': {
        padding: '6px 8px',
        fontWeight: 400,
        fontSize: '0.75rem',
        letterSpacing: '0.03333em',
        lineHeight: 1.17,
      },
      '& .MuiOutlinedInput-input': {
        height: 14,
        '&.MuiSelect-select': {
          height: 14,
        },
      },
    },
  },
  variants: [
    {
      props: { size: 'large' },
      style: {
        '& .MuiInputBase-input': {
          padding: 8,
        },
        '& .MuiOutlinedInput-input': {
          height: 16,
        },
      },
    },
  ],
};
