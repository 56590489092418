import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Stack } from '@mui/material'

const MenuListFooterButtons = ({
  enableResetButton,
  handleResetFiltersClick,
  enableApplyButton,
  handleApplyFiltersClick,
  selectAllButton,
  handleSelectAllClick,
}) => {
  const resetButtonElement = enableResetButton && (
    <Button variant="outlined" fullWidth onClick={handleResetFiltersClick}>
      Reset
    </Button>
  )

  const applyButtonElement = enableApplyButton && (
    <Button variant="contained" fullWidth onClick={handleApplyFiltersClick}>
      Apply
    </Button>
  )

  return (
    <Box
      onKeyDown={(e) => e.stopPropagation()}
      component="div"
      sx={{ px: 1.5, pb: 2, pt: 1.5 }}
    >
      {selectAllButton?.enabled && (
        <Button onClick={handleSelectAllClick}>Select All</Button>
      )}
      {(enableApplyButton || enableResetButton) && (
        <Stack direction="row" spacing={1.5}>
          {resetButtonElement}
          {applyButtonElement}
        </Stack>
      )}
    </Box>
  )
}

MenuListFooterButtons.propTypes = {
  handleResetFiltersClick: PropTypes.func,
  handleApplyFiltersClick: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  enableApplyButton: PropTypes.bool,
  enableResetButton: PropTypes.bool,
  selectAllButton: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    updateParentOptions: PropTypes.func,
  }),
}

export default MenuListFooterButtons
