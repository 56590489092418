import React, { useCallback } from 'react'
import { Box, useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

const useDrawChart = (data) => {
  const theme = useTheme()
  const boxSize = 500

  const ref = useCallback(
    (node) => {
      if (node !== null) {
        const colors = [
          theme.palette.success.main,
          theme.palette.error.main,
          theme.palette.secondary.light,
        ]
        d3.select(node)
          .select('svg')
          .remove() // Remove the old svg
        // Create new svg
        const svg = d3
          .select(node)
          .append('svg')
          .attr('preserveAspectRatio', 'xMidYMid meet')
          .attr('height', '100%')
          .attr('width', '100%')
          .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
          .append('g')
          .attr('transform', `translate(${boxSize / 2}, ${boxSize / 2})`)

        const arcGenerator = d3
          .arc()
          .innerRadius(160)
          .outerRadius(250)

        const pieGenerator = d3.pie().value((d) => d.value)

        const arcs = svg
          .selectAll()
          .data(pieGenerator(data))
          .enter()
        arcs
          .append('path')
          .attr('d', arcGenerator)
          .style('fill', (d, i) => colors[i])
      }
    },
    [data, theme],
  )

  return ref
}

const SentimentDonutChart = ({ data, width }) => {
  const ref = useDrawChart(data)

  return (
    <Box sx={{ width: width || 86 }}>
      <div className="graph" ref={ref} />
    </Box>
  )
}

SentimentDonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
}

export default React.memo(SentimentDonutChart)
