import es6Promise from 'es6-promise'
import * as AC from './actionConstants'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

es6Promise.polyfill()

export const showPermissionForm = () => ({
  type: AC.SHOW_PERMISSION_FORM,
})
export const hidePermissionForm = () => ({
  type: AC.HIDE_PERMISSION_FORM,
})
export const showPermissionList = () => ({
  type: AC.SHOW_PERMISSION_LIST,
})
export const hidePermissionList = () => ({
  type: AC.HIDE_PERMISSION_LIST,
})
export const addAllPermission = (permissions) => ({
  type: AC.ADD_ALL_PERMISSIONS,
  permissions,
})
export const addPermission = (permission) => ({
  type: AC.ADD_PERMISSION,
  permission,
})
export const editPermission = (permission) => ({
  type: AC.EDIT_PERMISSION,
  permission,
})
export const deletePermission = (PermissionID) => ({
  type: AC.DELETE_PERMISSION,
  PermissionID,
})

export const getAllPermissionsFromServer = (formInput) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddPermission()
    return axios.get(request).then(function (response) {
      dispatch(addAllPermission(response.data.resData))
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export const addPermissionOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddPermission()
    return axios.post(request, data).then(function (response) {
      dispatch(addPermission(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const updatePermissionOnServer = (data) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddPermission()
    return axios.put(request, data).then(function (response) {
      dispatch(editPermission(response.data.resData))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const deletePermissionOnServer = (permissionID) => {
  return function (dispatch, getState) {
    const request = NetworkUtil.formAddPermission()
    return axios.delete(request + '/' + permissionID).then(function (response) {
      dispatch(deletePermission(permissionID))
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}
