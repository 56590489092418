import React from 'react'
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  SvgIcon,
  Box,
  Divider,
} from '@mui/material'

import { ReactComponent as PlusIcon } from '../../../../../assets/icons/feather-plus-circle.svg'
import AdvancedTrendAnalysisExpanded from '../AdvancedTrendAnalysisExpanded'
import TextOverflowTooltip from '../../../../components/TextOverflowTooltip'
import {
  StyledGrid,
  StyledLinkCountry,
  StyledLink,
  StyledTable,
} from './styles'

const AdvancedTrendAnalysisTable = (props) => {
  const {
    advancedTrendAnalysisList,
    expandedTrend,
    handleTrendClick,
    productComparisonData,
    acrossSelected,
    trendType,
    lensApplied,
    showSubCat,
  } = props

  const preventPropagation = (event) => {
    event.stopPropagation()
  }

  return (
    <>
      {advancedTrendAnalysisList &&
        advancedTrendAnalysisList.order &&
        advancedTrendAnalysisList.order.map((name) =>
          expandedTrend === name ? (
            <AdvancedTrendAnalysisExpanded
              lens={lensApplied}
              handleTrendClick={handleTrendClick}
              productComparisonData={productComparisonData}
              imageUrl={
                advancedTrendAnalysisList.commonTrends[name][0].imageUrl
              }
              productName={name}
              acrossSelected={acrossSelected}
              emergingInCategories={
                advancedTrendAnalysisList.commonTrends[name].length
              }
              trendType={trendType}
              showSubCat={showSubCat}
            />
          ) : (
            <StyledGrid
              container
              spacing={2}
              columns={24}
              backgroundColor={(theme) => theme.palette.white}
              alignItems="center"
              width="100%"
              ml={0}
              mt={3}
              onClick={handleTrendClick(name)}
            >
              <Grid item xs={3} ml={1}>
                <Box display="flex" alignItems="center">
                  <SvgIcon
                    sx={{
                      marginRight: '20px',
                      marginLeft: '10px',
                      transform: `translate(-15px, -6px)`,
                      '& path': {
                        stroke: '#000',
                      },
                    }}
                    component={PlusIcon}
                    width="16"
                    height="16"
                    cursor="pointer"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      component="img"
                      width="95px"
                      height="53px"
                      borderRadius="4px"
                      sx={{
                        objectFit: 'cover',
                      }}
                      src={
                        advancedTrendAnalysisList.commonTrends[name][0].imageUrl
                      }
                    />
                    <TextOverflowTooltip title={name} maxLineCount={2}>
                      <Typography
                        align="center"
                        sx={{ transform: 'translate(0px, 10px)' }}
                        variant="subtitle1"
                      >
                        {name}
                      </Typography>
                    </TextOverflowTooltip>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={20} pt={-1}>
                <Grid container spacing={0} columns={16} alignItems="center">
                  <Grid item xs={15} mb={1}>
                    <StyledTable>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Box
                              sx={{
                                borderRight: ({ palette }) =>
                                  `solid 1px ${palette.divider}`,
                                width: '100%',
                                pl: '40px',
                                height: 34,
                              }}
                              display="flex"
                              alignItems="center"
                            >
                              Country
                            </Box>
                            <Divider
                              orientation="horizontal"
                              sx={{ marginLeft: 'auto', width: '180px' }}
                            />
                          </TableCell>
                          {advancedTrendAnalysisList.commonTrends[name].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <StyledLinkCountry
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${lensApplied}/${ds.id}`}
                                  onClick={preventPropagation}
                                >
                                  {ds.country}
                                </StyledLinkCountry>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box
                              sx={{
                                borderRight: ({ palette }) =>
                                  `solid 1px ${palette.divider}`,
                                width: '100%',
                                pl: '40px',
                                height: 34,
                              }}
                              display="flex"
                              alignItems="center"
                            >
                              Rank
                            </Box>
                            <Divider
                              orientation="horizontal"
                              sx={{ marginLeft: 'auto', width: '180px' }}
                            />
                          </TableCell>
                          {advancedTrendAnalysisList.commonTrends[name].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <StyledLink
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${lensApplied}/${ds.id}`}
                                  onClick={preventPropagation}
                                >
                                  {ds.rank}
                                </StyledLink>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box
                              sx={{
                                borderRight: ({ palette }) =>
                                  `solid 1px ${palette.divider}`,
                                width: '100%',
                                pl: '40px',
                                height: 34,
                              }}
                              display="flex"
                              alignItems="center"
                            >
                              Maturity phase
                            </Box>
                          </TableCell>
                          {advancedTrendAnalysisList.commonTrends[name].map(
                            (ds, i) => (
                              <TableCell key={i}>
                                <StyledLink
                                  target="_blank"
                                  to={`/mui/details/${ds.project_id}/${lensApplied}/${ds.id}`}
                                  onClick={preventPropagation}
                                >
                                  {ds.trendType}
                                </StyledLink>
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableBody>
                    </StyledTable>
                  </Grid>
                </Grid>
              </Grid>
            </StyledGrid>
          ),
        )}
    </>
  )
}

export default AdvancedTrendAnalysisTable
