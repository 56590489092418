import React, { useEffect } from 'react'
import { Breadcrumbs, Grid, Link, Typography, Box, Stack } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DownloadButton from '../DownloadButton'
import VolumeCard from '../VolumeCard'
import TrendGrowthCard from '../TrendGrowthCard'
import TopicsCard from '../TopicsCard'
import SentimentCard from '../SentimentCard'
import HideOnScrollAppbar from './../HideOnScrollAppbar'
import { shouldLogRecentActivity } from '../DashboardRecentActivity'
import { useHistory } from 'react-router-dom'
import AddFavoriteButtonParing from '../OtherFavorites/AddFavoriteButtonParing'
import FixedSnackbar from '../../../components/FixedSnackbar/FixedSnackbar'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import { INSIGNIFICANT_DATA_MESSAGE } from './../../utils/constants'

const CategoryInsightDetailsTop = (props) => {
  const {
    needChart,
    catInsight,
    projectid,
    productid,
    needid,
    urllens,
    topicChart,
    details,
    positiveSentiment,
    neutralSentiment,
    negativeSentiment,
    showGrowthChip,
    chartLargeView,
    imageDownload,
    country,
    category,
    email,
    type,
    lens,
    disableTopicsPointerEvents,
    hideDownload,
    region,
    trendId,
    isDataInsignificant = false,
    handleSnackBarRemove,
  } = props
  const history = useHistory()
  const breadcrumbs = topicChart
    ? [
        <Link
          variant="breadcrumbsLink"
          key="1"
          href={
            type === 'regionalAnalytics'
              ? `?lens=${encodeURIComponent(
                  lens,
                )}&category=${encodeURIComponent(
                  category,
                )}&region=${encodeURIComponent(region)}`
              : '/'
          }
        >
          {type === 'regionalAnalytics'
            ? 'Regional Analytics'
            : 'Foresight Engine'}
        </Link>,
        <Link
          variant="breadcrumbsLink"
          key="2"
          href={`/mui/product-category/${projectid}`}
        >
          {country} {category} {type === 'regionalAnalytics' && `(${region})`}
        </Link>,
        <Link
          variant="breadcrumbsLink"
          key="3"
          href={`/mui/details/${projectid}/${urllens}/${productid}`}
        >
          {details.productName}
        </Link>,
        <Link
          variant="breadcrumbsLink"
          key="4"
          href={`/mui/details/${projectid}/${urllens}/${productid}/need/${needid}`}
        >
          {details.needName}
        </Link>,
        <Typography key="5" variant="subtitle2">
          {details.keyword}
        </Typography>,
      ]
    : details.productName
    ? [
        <Link
          variant="breadcrumbsLink"
          key="1"
          href={
            type === 'regionalAnalytics'
              ? `?lens=${encodeURIComponent(
                  lens,
                )}&category=${encodeURIComponent(
                  category,
                )}&region=${encodeURIComponent(region)}`
              : '/'
          }
        >
          {type === 'regionalAnalytics'
            ? 'Regional Analytics'
            : 'Foresight Engine'}
        </Link>,
        type === 'regionalAnalytics' ? (
          <Typography key="2" variant="subtitle2">
            {`${country} ${category} (${region})`}
          </Typography>
        ) : (
          <Link
            variant="breadcrumbsLink"
            key="2"
            href={`/mui/product-category/${projectid}`}
          >
            {country} {category}
          </Link>
        ),
        type === 'regionalAnalytics' ? (
          <Typography key="3" variant="subtitle2">
            {`${details.productName}`}
          </Typography>
        ) : (
          <Link
            variant="breadcrumbsLink"
            key="3"
            href={`/mui/details/${projectid}/${urllens}/${productid}`}
          >
            {details.productName}
          </Link>
        ),
        <Typography key="4" variant="subtitle2">
          {details.keyword}
        </Typography>,
      ]
    : [
        <Link
          variant="breadcrumbsLink"
          key="1"
          href={
            type === 'regionalAnalytics'
              ? `?lens=${encodeURIComponent(
                  lens,
                )}&category=${encodeURIComponent(
                  category,
                )}&region=${encodeURIComponent(region)}`
              : '/'
          }
        >
          {type === 'regionalAnalytics'
            ? 'Regional Analytics'
            : 'Foresight Engine'}
        </Link>,
        type === 'regionalAnalytics' ? (
          <Typography key="2" variant="subtitle2">
            {`${country} ${category} (${region})`}
          </Typography>
        ) : (
          <Link
            variant="breadcrumbsLink"
            key="2"
            href={`/mui/product-category/${projectid}`}
          >
            {country} {category}
          </Link>
        ),
        <Typography key="3" variant="subtitle2">
          {details.keyword}
        </Typography>,
      ]

  if (!details) return null

  useEffect(() => {
    if (type !== 'regionalAnalytics') {
      let title = ''
      if (country && category) {
        title = `Explore Trends>${country} ${category}>`
      }
      if (details.productName) {
        if (title && details.keyword && needid) {
          shouldLogRecentActivity(
            `${title}${details.productName}>${details.keyword}`,
            'Platform',
            `/mui/details/${projectid}/${urllens}/${productid}/need/${needid}`,
          )
        }
      } else {
        if (title && details.keyword && needid) {
          shouldLogRecentActivity(
            `${title}${details.keyword}`,
            'Platform',
            `/mui/details/${projectid}/category/${needid}`,
          )
        }
      }
    } else {
      if (country && category && details.productName && details.keyword) {
        shouldLogRecentActivity(
          `Regional Analytics>${country} ${category}>${details.productName}>${details.keyword}`,
          'Platform',
          `${history.location.pathname}${history.location.search}`,
        )
      }
    }
  }, [type, details.productName, country, category, needid])

  return (
    <Stack gap={2}>
      {type === 'regionalAnalytics' ? (
        <Box sx={{ position: 'relative', top: '100px' }}>
          <HideOnScrollAppbar sx={{ top: '120px' }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </HideOnScrollAppbar>
        </Box>
      ) : (
        <HideOnScrollAppbar>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            {breadcrumbs}
          </Breadcrumbs>
        </HideOnScrollAppbar>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '20px',
        }}
      >
        <Typography variant="h4" color="textSecondary">
          Consumer Insight{' '}
          {details.needFilter &&
            (details.needFilter !== 'All' ? (
              <Typography component="span" variant="h4">
                for {details.needFilter}
              </Typography>
            ) : (
              <Typography component="span" variant="h4">
                for {category}
              </Typography>
            ))}
        </Typography>
        {!hideDownload && (
          <>
            <Box>
              <AddFavoriteButtonParing
                additionalInfo={null}
                favoriteType={
                  !details.needFilter
                    ? 'TREND_CONSUMER_INSIGHT'
                    : 'CATEGORY_CONSUMER_INSIGHT'
                }
                trendName={
                  !details.needFilter
                    ? `Trend Consumer Insight(Consumer Need): ${country} ${category}_${details.productName}_${details.keyword}`
                    : details.keyword
                }
                projectId={projectid}
                productId={needid}
                trendType={
                  (urllens &&
                    urllens.charAt(0).toUpperCase() + urllens.slice(1)) ||
                  'Ingredient'
                }
                reDirectionUrl={location.pathname}
                breadcrumbs={
                  details.productName
                    ? `Foresight Engine, ${country} ${category},${details.productName},${details.keyword}`
                    : `Foresight Engine, ${country} ${category},${details.keyword}`
                }
                consumerInsightSection
                favoriteSectionType={'Other Favorites'}
              />

              <DownloadButton
                dowImageType={''}
                toggleImageDownload={imageDownload}
              />
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2">{details.keyword}</Typography>
          {isDataInsignificant && (
            <FixedSnackbar
              preIcon={
                <InfoIcon
                  fontSize="small"
                  sx={{ color: ({ palette }) => palette.warning.light }}
                />
              }
              postIcon={<CloseIcon fontSize="small" color="action" />}
              message={INSIGNIFICANT_DATA_MESSAGE}
              postIconAction={handleSnackBarRemove}
              postIconSx={{ paddingLeft: '30px' }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Stack spacing={3}>
              {positiveSentiment !== -99999 && (
                <Stack direction="row" spacing={3}>
                  <Box sx={{ display: 'grid', flexGrow: 1 }}>
                    <VolumeCard value={parseFloat(details.score)} />
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    {type === 'regionalAnalytics' && trendId ? (
                      <Box />
                    ) : (
                      <SentimentCard
                        positiveSentiment={parseFloat(
                          positiveSentiment,
                        ).toFixed(2)}
                        neutralSentiment={parseFloat(neutralSentiment).toFixed(
                          2,
                        )}
                        negativeSentiment={parseFloat(
                          negativeSentiment,
                        ).toFixed(2)}
                      />
                    )}
                  </Box>
                </Stack>
              )}
              {needChart && (
                <div id="_dowSectionChart">
                  <TrendGrowthCard
                    dowImageType="Chart"
                    needChart
                    chartLargeView={chartLargeView}
                    toggleImageDownload={imageDownload}
                    showGrowthChip={showGrowthChip}
                    details={details}
                  />
                </div>
              )}
              {!needChart && !topicChart && (
                <TrendGrowthCard
                  details={details}
                  dowImageType="Chart"
                  toggleImageDownload={imageDownload}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {positiveSentiment === -99999 && (
              <Box sx={{ display: 'grid', mb: 3, flexGrow: 1 }}>
                <VolumeCard value={parseFloat(details.score)} />
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <TopicsCard
                disableTopicsPointerEvents={disableTopicsPointerEvents}
                expand={props.expand}
                catInsight={catInsight}
                projectid={projectid}
                productid={productid}
                needid={needid}
                urllens={urllens}
                country={country}
                category={category}
                email={email}
                topicChart={topicChart}
                maxHeight={catInsight ? '648.44' : '222'}
                topics={details.relatedTerms}
                type={type}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default CategoryInsightDetailsTop
