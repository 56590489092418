import { AmplitudeValues } from '../../../store/amplitudeValues'
import { amplify, extractSubString } from '../../../store/utility'
import { SW_PRJ_TYPES } from '../../../shared/utils/constants'

const toneMapping = {
  LESSDESCRIPTIVE: 'Less Descriptive',
  MOREDESCRIPTIVE: 'More Descriptive',
  DEFAULT: 'Generic',
  MARKETING: 'Marketing',
}

export const logCGAmplitude = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = { User: ampiInstance.email }
    switch (amplitudeEvent) {
      case 'Clicked_Generate_Concept':
        amplitudeUserData = {
          ...amplitudeUserData,
          ...ampiUserData,
        }
        break
      case 'Clicked_Concept_Genie_Reset':
        amplitudeUserData = {
          ...amplitudeUserData,
          ...ampiUserData,
        }
        break
      case 'Clicked_Concept_Genie_Recently_Visited_Trends':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.name,
          Action: ampiUserData.action,
        }
        break
      case 'Clicked_Concept_Genie_Analysed_Trends':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Name: ampiUserData.trendName,
          Action: ampiUserData.action,
          Dashboard: ampiUserData.projectSelected?.country
            ? `${ampiUserData.projectSelected.country} ${ampiUserData.projectSelected.category}`
            : 'Global',
        }
        break
      case 'Clicked_Concept_Genie_Modify':
        amplitudeUserData = {
          ...amplitudeUserData,
          Modify: toneMapping[ampiUserData.tone],
          Country_Name: ampiUserData.projectSelected?.country || 'Global',
          Category_Name: ampiUserData.projectSelected?.category || 'Global',
        }
        break
      case 'Clicked_Concept_Genie_Dashboard':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country_Name: ampiUserData.project.country,
          Category_Name: ampiUserData.project.category,
        }
        break
      case 'Clicked_Concept_Genie_Regenerate':
        amplitudeUserData = {
          ...amplitudeUserData,
          Regenerate: true,
        }
        break
      case 'Clicked_Concept_Genie_Exclude':
        amplitudeUserData = {
          ...amplitudeUserData,
          Exclude: ampiUserData.excludeKeywords,
        }
        break
      case 'Clicked_Save_Concept':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keywords: ampiUserData.concept.description.split(','),
          Concept_Name: ampiUserData.conceptName,
          Description: extractSubString(
            ampiUserData.conceptText,
            'Description: ',
            'Claims:',
          ),
        }
        break
      case 'Clicked_Like_Concept':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keywords: ampiUserData.concept.description.split(','),
          Description: extractSubString(
            ampiUserData.concept.generated_concept,
            'Description: ',
            'Claims:',
          ),
        }
        break
      case 'Clicked_Dislike_Concept':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keywords: ampiUserData.concept.description.split(','),
          Description: extractSubString(
            ampiUserData.concept.generated_concept,
            'Description: ',
            'Claims:',
          ),
        }
        break
      case 'Clicked_Save_Concept_Kebab_Menu':
        amplitudeUserData = {
          ...amplitudeUserData,
          Option_Selected: ampiUserData.action,
          Keywords: ampiUserData.concept.description.split(','),
          Description: extractSubString(
            ampiUserData.concept.generated_concept,
            'Description: ',
            'Claims:',
          ),
          Concept_Name: ampiUserData.concept.title,
        }
        break
      case 'Clicked_Concept_Genie_SW_Project':
        amplitudeUserData = {
          ...amplitudeUserData,
          Action: ampiUserData.action,
          Project:
            SW_PRJ_TYPES[
              SW_PRJ_TYPES.findIndex(
                (obj) => obj.value === ampiUserData.selectedPrjType,
              )
            ].label,
          Concept_Name: ampiUserData.concepts,
        }
        break
      case 'Clicked_CG_SW_Existing_Project_Action':
        amplitudeUserData = {
          ...amplitudeUserData,
          Country: ampiUserData.country,
          Category: ampiUserData.category,
          Subcategory: ampiUserData.subcategory,
          Project_Name: ampiUserData.title,
          Concept_Name: ampiUserData.concepts,
          Action: ampiUserData.action,
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log('Error in amplitude-cg', err)
  }
}
