import React from 'react'

import Button from '../Buttons/ButtonSW'

import conceptDelete from '../../assets/images/screenWinner/sw_delete_concept.svg'

import './SWDeleteModal.scss'

const SWDeleteModal = ({
  onDeleteClick,
  onCancelClick,
  modalTitle = 'Delete',
  modalBody = 'Are you sure you want to delete?',
}) => {
  return (
    <div
      className="sw-delete-modal__block"
      onClick={(ev) => ev.stopPropagation()}
    >
      <div className="sw-delete-modal">
        <h1 className="sw-delete-modal__heading">{modalTitle}</h1>
        <img
          className="sw-delete-modal__img"
          src={conceptDelete}
          alt="Delete concept"
        />
        <p className="sw-delete-modal__txt">{modalBody}</p>
        <div className="sw-delete-modal__actions">
          <Button
            className="sw-delete-modal__actions--cancel"
            name="Cancel"
            type="button"
            onClick={onCancelClick}
          />
          <Button
            className="sw-delete-modal__actions--delete"
            name="Delete"
            type="button"
            solid
            onClick={onDeleteClick}
          />
        </div>
      </div>
    </div>
  )
}

export default SWDeleteModal
