import React from "react"
import Dashboard from "./pages/dashboard/dashboard";
import { DataContextProvider } from "./contexts/data-context";
function BuildWinner() {
  return (
    <DataContextProvider>
      <Dashboard />
    </DataContextProvider>
  );
}

export default BuildWinner;
