import React, { useState, useEffect } from 'react'
import { Grid, Box, Button, Typography, Stack } from '@mui/material'
import Multiselect from '../../../../shared/components/MultiSelect_v2'
import UAMDashboardAssigned from '../../../../components/UAMDashboardAssigned/UAMDashboardAssigned'
import NetworkingUtil from '../../../../services/networking/NetworkingUtil'
import axios from './../../../../services/networking/axios'
import SavedProjectsList from '../../../../services/networking/Models/SavedProjectsList'

function AssignedDashBoardUI(props) {
  const [countriesList, setCountriesList] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [projectsList, setProjectsList] = useState()
  const [dashboards, setDashboards] = useState([])
  const [dashboardAdded, setDashboardAdded] = useState(false)
  const [dashboardData, setDashboardData] = useState()

  useEffect(() => {
    const request = NetworkingUtil.formSavedProjectListRequest()
    axios
      .get(request)
      .then((response) => {
        const responseObject = new SavedProjectsList(response.data)
        const obj = response.data.results.map((data) => {
          return {
            _criteria: data.country.name,
            _id: data.id,
            _name: data.category.name,
          }
        })
        setProjectsList({
          list: obj,
        })
      })
      .catch((error) => {})
  }, [])

  const formatOpts = (list, setGenerator) =>
    Array.from(new Set(list.map(setGenerator))).map((item, id) => ({
      label: item,
      key: id,
    }))

  const getDashboardId = (selectedDashboards) => {
    return selectedDashboards.map((dashboards) => {
      return projectsList?.list.find((project) => {
        return (
          project._criteria == dashboards.country.label &&
          project._name == dashboards.category.label
        )
      })._id
    })
  }

  const getClientData = () => {
    axios
      .get(
        NetworkingUtil.userAccessManagementDashboardAssigned(
          props.selectedRow?.clientId,
        ),
      )
      .then((res) => {
        const response = res.data.data.map((client) => {
          return {
            country: client.country,
            category: client.category,
            noOfIngredients: client.ingredient_sub_cat.length,
            noOfProducts: client.product_sub_cat.length,
            noOfThemes: client.theme_sub_cat.length,
            ingredientSubCat: client.ingredient_sub_cat,
            themeSubCat: client.theme_sub_cat,
            productSubCat: client.product_sub_cat,
          }
        })

        const modifiedResponse = [...dashboards, ...response]

        const unique = Array.from(
          new Set(modifiedResponse.map(JSON.stringify)),
        ).map(JSON.parse)

        const indexedData = unique.map((data, index) => {
          return {
            ...data,
            id: index,
            remove: true,
            dashboardName: `${data.country} ${data.category}`,
          }
        })
        setDashboardData(indexedData)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const dashboardAddition = () => {
    const selectedDashboards = []
    selectedCountries.map((country) => {
      selectedCategory.map((category) => {
        selectedDashboards.push({
          country,
          category,
        })
      })
    })
    const dashboardIds = getDashboardId(selectedDashboards)

    let dashboardsFetchRequest = ''
    dashboardIds.map((ids) => {
      dashboardsFetchRequest += `id=${ids}&&`
    })
    if (dashboardIds.length)
      dashboardsFetchRequest = dashboardsFetchRequest.slice(0, -2)

    axios
      .get(
        NetworkingUtil.userAccessManagementDashboardAssignedFetch(
          props.selectedRow?.clientId,
          dashboardsFetchRequest,
        ),
      )
      .then((res) => {
        const updateDashboards = res.data.data.subscriptions.map(
          (subscription, index) => {
            return {
              category: subscription.category,
              country: subscription.country,
              noOfIngredients: subscription.ingredient_sub_cat.length,
              noOfProducts: subscription.product_sub_cat.length,
              noOfThemes: subscription.theme_sub_cat.length,
              ingredientSubCat: subscription.ingredient_sub_cat,
              productSubCat: subscription.product_sub_cat,
              themeSubCat: subscription.theme_sub_cat,
            }
          },
        )
        setDashboards([...dashboards, ...updateDashboards])
        getClientData()
      })
      .catch()
    setDashboardAdded(!dashboardAdded)
    setSelectedCategory([])
    setSelectedCountries([])
  }

  useEffect(() => {
    getClientData()
  }, [])

  useEffect(() => {
    getClientData()
  }, [dashboards])

  useEffect(() => {
    if (!selectedCountries.length) return
    if (projectsList) {
      const matchedCategories = projectsList.list.reduce(
        (acc, { _criteria, _name }) => {
          if (selectedCountries.find(({ label }) => label === _criteria)) {
            if (acc.has(_name)) {
              acc.set(_name, acc.get(_name) + 1)
            } else {
              acc.set(_name, 1)
            }
          }
          return acc
        },
        new Map(),
      )
      setCategoriesList(
        formatOpts(
          [...matchedCategories].reduce(
            (acc, [key, value]) =>
              value === selectedCountries.length ? [...acc, key] : acc,
            [],
          ),
          (val) => val,
        ),
      )
    }
  }, [selectedCountries])
  useEffect(() => {
    let formattedCountries = []

    // eslint-disable-next-line
    ;(formattedCountries = formatOpts(
      projectsList ? projectsList.list : [],
      (prj) => prj._criteria,
    ).map(({ label, key }) => {
      return {
        label,
        key,
        code: (
          countriesList.find((country) => country.label === label) || {
            code: '',
          }
        ).code,
        img:
          (
            countriesList.find((country) => country.label === label) || {
              img: '',
            }
          ).img || '',
      }
      // eslint-disable-next-line
    })),
      formattedCountries &&
        formattedCountries.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
    setCountriesList(formattedCountries)
    let formatedCategories = []
    formatedCategories = formatOpts(
      projectsList ? projectsList.list : [],
      (prj) => prj._name,
    )
    formatedCategories &&
      formatedCategories.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    setCategoriesList(formatedCategories)
  }, [projectsList])

  useEffect(() => {
    setSelectedCategory([])
  }, [selectedCountries])
  const handleExit = () => {
    props.setClientModification(0)
  }

  const updateRequestSave = () => {
    const currentDashboardsList = dashboardData.map((data) => {
      return {
        country: {
          label: data.country,
        },
        category: { label: data.category },
      }
    })
    const currentProjectIds = getDashboardId(currentDashboardsList)

    const tempAddedDashboards = dashboards.map((data) => {
      return {
        country: {
          label: data.country,
        },
        category: { label: data.category },
      }
    })
    const tempAddedProjectIds = getDashboardId(tempAddedDashboards)
    const allDashboards = currentProjectIds.map((id, index) => {
      return {
        country: dashboardData[index].country,
        category: dashboardData[index].category,
        id: id,
        ingredientSubCat: dashboardData[index].ingredientSubCat.includes('All')
          ? dashboardData[index].ingredientSubCat
          : [...dashboardData[index].ingredientSubCat, 'All'],
        themeSubCat: dashboardData[index].themeSubCat.includes('All')
          ? dashboardData[index].themeSubCat
          : [...dashboardData[index].themeSubCat, 'All'],
        productSubCat: dashboardData[index].productSubCat.includes('All')
          ? dashboardData[index].productSubCat
          : [...dashboardData[index].productSubCat, 'All'],
      }
    })

    const newlyAddedDashboards = allDashboards.filter((data) => {
      return tempAddedProjectIds.includes(data.id)
    })

    const updatedNewlyAddedDashboards = newlyAddedDashboards.map((data) => {
      return {
        id: data.id,
        ingredient_sub_cat: data.ingredientSubCat,
        theme_sub_cat: data.themeSubCat,
        product_sub_cat: data.productSubCat,
      }
    })
    const updateDashboards = allDashboards.filter((data) => {
      return !tempAddedProjectIds.includes(data.id)
    })

    updateDashboards.map((data) => {
      const req1 = {
        category: data.category,
        country: data.country,
        lens: 'Ingredient',
        selected_sub_categories: data.ingredientSubCat,
      }
      axios
        .put(
          NetworkingUtil.userAccessManagementDashboardAssigned(
            props.selectedRow?.clientId,
          ),
          req1,
        )
        .then((res) => {})
        .catch()
      const req2 = {
        category: data.category,
        country: data.country,
        lens: 'Product',
        selected_sub_categories: data.productSubCat,
      }
      axios
        .put(
          NetworkingUtil.userAccessManagementDashboardAssigned(
            props.selectedRow?.clientId,
          ),
          req2,
        )
        .then((res) => {})
        .catch()
      const req3 = {
        category: data.category,
        country: data.country,
        lens: 'Theme',
        selected_sub_categories: data.themeSubCat,
      }
      axios
        .put(
          NetworkingUtil.userAccessManagementDashboardAssigned(
            props.selectedRow?.clientId,
          ),
          req3,
        )
        .then((res) => {})
        .catch()
    })

    const dashboardsAddRequest = {
      dashboards: updatedNewlyAddedDashboards,
    }

    axios
      .post(
        NetworkingUtil.userAccessManagementDashboardAssigned(
          props.selectedRow?.clientId,
        ),
        dashboardsAddRequest,
      )
      .then((res) => {
        props.getClientData()
      })
      .catch()
  }

  const handleDashboardSave = () => {
    props.setClientModification(0)
    updateRequestSave()
    setDashboardAdded(!dashboardAdded)
  }
  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px',
          height: '100px',
        }}
      >
        <Grid marginRight="140px">
          <Stack sx={{ width: '300px' }}>
            <Typography variant="subtitle2" marginBottom="8px">
              Country
            </Typography>
            <Multiselect
              enableSearch
              searchPlaceholder="Search countries"
              placeholder="e.g. China"
              textFieldProps={{ autoComplete: 'off' }}
              value={selectedCountries}
              onChange={setSelectedCountries}
              options={countriesList}
            />
          </Stack>
        </Grid>
        <Grid item xs={6} md={1.3}>
          <Stack sx={{ width: '300px' }}>
            <Typography variant="subtitle2" marginBottom="8px">
              Category
            </Typography>
            <Multiselect
              placeholder="e.g. Snacks"
              options={categoriesList || []}
              disable={!selectedCountries.length}
              value={selectedCategory || null}
              onChange={setSelectedCategory}
              sx={{
                width: '100%',
                height: 35,
                '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                  height: '100%',
                },
              }}
            />
          </Stack>
        </Grid>
        <Stack mt={2.5} sx={{ marginLeft: '90px' }}>
          <Box>
            <Button
              alignItems="center"
              variant="contained"
              size="large"
              mt={5}
              sx={{ padding: '10px' }}
              onClick={dashboardAddition}
            >
              Add dashboard for the selected country & category
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Box sx={{ height: '400px' }}>
        <UAMDashboardAssigned
          selectedRow={props.selectedRow}
          dashboardAdded={dashboardAdded}
          dashboards={dashboards}
          setDashboards={setDashboards}
          dashboardData={dashboardData}
          setDashboardData={setDashboardData}
        />
      </Box>
      <Box
        sx={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        mt={3}
      >
        <Grid>
          {' '}
          <Button
            alignItems="center"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleExit}
          >
            Exit Without Saving
          </Button>
        </Grid>
        <Grid>
          <Button
            alignItems="center"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleDashboardSave}
            sx={{
              marginRight: '100px',
            }}
          >
            Save and Exit
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default AssignedDashBoardUI
