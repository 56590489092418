import { commonTrendAnalysis } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  countryList : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_SUCCESS]: return updateObject(state, {
      commonTrendAnalysisList : action.commonTrend,
      error : false,
      loading : false,
    })
    case commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_FAILED]: return updateObject(state, {
      commonTrendAnalysisList : null,
      error : true,
      loading : false,
    })
    case commonTrendAnalysis.type[commonTrendAnalysis.CTA_FETCH_LIST_START]: return updateObject(state, {
      commonTrendAnalysisList : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer