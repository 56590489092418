import { UserSelectionActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  userShouldWait: false,
  projectID: null,
  productID: null,
  projectNeedID: null,
  bubbleNeedID: null,
  productNeedId: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PROJECT]:
      return updateObject(state, {
        projectID: action.projectid,
      })
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PRODUCT]:
      return updateObject(state, {
        productID: action.productid,
      })
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_PROJECT_NEEDS]:
      return updateObject(state, {
        projectNeedID: action.needsid,
        bubbleNeedID: action.bubbleid,
      })
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_SELECTED_BUBBLE_NEEDS]:
      return updateObject(state, {
        projectNeedID: action.needsid,
        bubbleNeedID: action.bubbleid,
      })
    case UserSelectionActionTypes.type[UserSelectionActionTypes.USER_WAIT_ANIMATION_DISPLAYED]:
      return updateObject(state, {
        userShouldWait: action.userShouldWait,
      })
    case UserSelectionActionTypes.USER_SELECTED_TOPIC_ID:
      return updateObject(state, {
        topicID: Number(action.topicId),
      })
    case UserSelectionActionTypes.USER_SELECTED_PRODUCT_NEED_ID:
      return updateObject(state, {
        productNeedId: action.productNeedId,
      })
    case AC.SET_USER_LOGGED_OUT:
      return Object.assign({}, state, {
        userShouldWait: false,
        projectID: null,
        productID: null,
        projectNeedID: null,
        bubbleNeedID: null,
      })
    default:
      return state
  }
}

export default reducer
