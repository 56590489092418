class SubNeedDetails {
  constructor(response) {
    this._keyword = response.keyword
    this._iconURI = response.iconURI
    this._percentage_value = response.percentage_value //eslint-disable-line
    this._score = response.score
    this._positiveSentiment = response.positive_sentiment
    this._neutralSentiment = response.neutral_sentiment
    this._negativeSentiment = response.negative_sentiment
    this._relatedTerms = response.related_terms || response.relatedTerms
    this._projectId = response.project_id
    this._projectName = response.project_name || response.category
    this._countryName = response.country
    this._productId =
      response.product_id || response.theme_id || response.ingredient_id
    this._productName =
      response.product_name || response.theme_name || response.ingredient_name
    this._needId = response.need_id
    this._needName = response.need_name
    this._needChart = (response.need_chart || []).map((chartValue) => {
      return new NeedChart(chartValue)
    })
    this._trendCoefficient = response.trend_four_year_coefficient
    this._trendIntercept = response.trend_four_year_intercept
    this._instagramQuotes = response.instagram_quotes
    this._twitterQuotes = response.twitter_quotes
    this._pantipQuotes = response.pantip_quotes
    this._weiboQuotes = response.weibo_quotes
    this._meituanQuotes = response.meituan_quotes
    this._zhihuQuotes = response.zhihu_quotes
    this._JDQuotes = response.jd_quotes
    this._subNeedProducts = response.sub_need_products
    this._subFormatProducts =
      response.related_products || response.products || [] // Product Format (Cafe/Retail)
    this._needCategory = response.need_category
    this._relatedNeeds = response.related_category_needs
    this._platforms = response.platforms
    this._productNeeds = response.product_consumer_needs
    this._needFilter = response.need_filter
    this._quotesMediaOptions = (response.platforms || []).map((value) => {
      return {
        id: value,
        value: value,
        displayValue: value === 'Pantip' ? 'Blogs and Forums' : value,
      }
    })
    this._quotesOptions = [
      {
        id: 0,
        value: 'Social',
        displayValue: 'Social Data',
      },
      {
        id: 1,
        value: 'Trade',
        displayValue: 'Trade Media',
      },
      {
        id: 2,
        value: 'Patent',
        displayValue: 'Patent Data',
      },
    ]
  }

  get keyword() {
    return this._keyword
  }

  get iconURI() {
    return this._iconURI
  }

  // eslint-disable-next-line
  get percentage_value() {
    return this._percentage_value
  }

  get score() {
    return this._score
  }

  get relatedTerms() {
    return this._relatedTerms
  }

  get needComments() {
    return this._needComments
  }

  get positiveSentiment() {
    return this._positiveSentiment
  }

  get neutralSentiment() {
    return this._neutralSentiment
  }

  get negativeSentiment() {
    return this._negativeSentiment
  }

  get needChart() {
    return this._needChart
  }

  get projectId() {
    return this._projectId
  }

  get projectName() {
    return this._projectName
  }

  get productId() {
    return this._productId
  }

  get productName() {
    return this._productName
  }

  get needId() {
    return this._needId
  }

  get needName() {
    return this._needName
  }

  get instagramQuotes() {
    return this._instagramQuotes
  }

  get twitterQuotes() {
    return this._twitterQuotes
  }

  get pantipQuotes() {
    return this._pantipQuotes
  }

  get weiboQuotes() {
    return this._weiboQuotes
  }

  get meituanQuotes() {
    return this._meituanQuotes
  }

  get zhihuQuotes() {
    return this._zhihuQuotes
  }

  get JDQuotes() {
    return this._JDQuotes
  }

  get trendCoefficient() {
    return this._trendCoefficient
  }

  get trendIntercept() {
    return this._trendIntercept
  }

  get subNeedProducts() {
    return this._subNeedProducts
  }

  get subFormatProducts() {
    return this._subFormatProducts
  }

  get platforms() {
    return this._platforms
  }

  get quotesOptions() {
    return this._quotesOptions
  }

  get productNeeds() {
    return this._productNeeds
  }

  get relatedNeeds() {
    return this._relatedNeeds
  }

  get needCategory() {
    return this._needCategory
  }

  get needFilter() {
    return this._needFilter
  }

  get countryName() {
    return this._countryName
  }
}

export class NeedChart {
  constructor(response) {
    this._period = response.period
    this._engagementScore = response.engagementScore
  }

  get period() {
    return this._period
  }

  get engagementScore() {
    return this._engagementScore
  }
}

export default SubNeedDetails
