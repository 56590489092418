import React from 'react'
import { ButtonBase, Menu, Button } from '@mui/material'
import { styled } from '@mui/system'

export const HeaderButton = styled(ButtonBase)(({ theme, isSelected }) => ({
  ...theme.typography.body1,
  borderRadius: 0,
  padding: 0,
  paddingBottom: theme.spacing(2.75),
  borderBottom: isSelected && `2px solid ${theme.palette.primary.main}`,
  color: isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
}))

export const VerticalDevider = styled('div')(({ theme }) => ({
  height: theme.spacing(2.25),
  backgroundColor: theme.palette.divider,
  width: 1,
  margin: `0 ${theme.spacing(2)}`,
}))

export const HeaderUserMenuMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    minWidth: 180,
    '& .MuiMenuItem-root': {
      '&:first-of-type': {
        '&:hover': {
          cursor: 'default',
          backgroundColor: 'transparent',
        },
      },
    },
  },
}))

export const BookMarkICon = styled(Button)(
  ({ isBookmarkPage, animationApply, theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    minWidth: '35px',
    minHeight: '35px',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      color: isBookmarkPage
        ? `${theme.palette.primary.main}`
        : `${theme.palette.icon}`,
      marginRight: '-12px',
    },

    '-webkit-animation': animationApply && 'swing 1s ease',
    animation: animationApply && 'swing 1s ease',
    '-webkit-animation-iteration-count': 2,
    'animation-iteration-count': 2,
    position: 'relative !important',
    transition: 'all 0.3s ease',
    '@-webkit-keyframes swing': {
      '15%': {
        '-webkit-transform': 'translateX(5px)',
        transform: 'translateX(5px)',
      },

      '30%': {
        '-webkit-transform': 'translateX(-5px)',
        transform: 'translateX(-5px)',
      },

      '50%': {
        '-webkit-transform': 'translateX(3px)',
        transform: 'translateX(3px)',
      },

      '65%': {
        '-webkit-transform': 'translateX(-3px)',
        transform: 'translateX(-3px)',
      },

      '80%': {
        '-webkit-transform': 'translateX(2px)',
        transform: 'translateX(2px)',
      },

      '100%': {
        '-webkit-transform': 'translateX(0)',
        transform: 'translateX(0)',
      },
    },

    '@keyframes swing': {
      '15%': {
        '-webkit-transform': 'translateX(5px)',
        transform: 'translateX(5px)',
      },

      '30%': {
        '-webkit-transform': 'translateX(-5px)',
        transform: 'translateX(-5px)',
      },

      '50%': {
        '-webkit-transform': 'translateX(3px)',
        transform: 'translateX(3px)',
      },

      '65%': {
        '-webkit-transform': 'translateX(-3px)',
        transform: 'translateX(-3px)',
      },

      '80%': {
        '-webkit-transform': 'translateX(2px)',
        transform: 'translateX(2px)',
      },

      '100%': {
        '-webkit-transform': 'translateX(0)',
        transform: 'translateX(0)',
      },
    },
  }),
)
