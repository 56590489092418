import React from 'react'
import { Link, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import RelatedIngredientContainer, {
  RelatedIngredientContainerForDemography,
} from './styles'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'
import formatNumber from '../../../../utils/formatNumber'
import { LENSES } from '../../../../utils/constants'
const buildLink = (p, t, blockName, name, link) => {
  const lens = `/${blockName.toLowerCase().replace('s', '')}/`
  if (link) {
    if (link.section === 'regional') {
      const lensSelected = LENSES.filter((val) => blockName.includes(val))[0]
      return (
        '/mui/regional-analytics/?projectId=' +
        encodeURIComponent(p) +
        '&lens=' +
        encodeURIComponent(lensSelected) +
        '&country=' +
        encodeURIComponent(link.country) +
        '&category=' +
        encodeURIComponent(link.category) +
        '&region=' +
        encodeURIComponent(link.region) +
        '&trend=' +
        encodeURIComponent(name) +
        '&trendId=' +
        encodeURIComponent(t)
      )
    }
  }
  return '/mui/details/' + p + lens + t
}

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues(
      !ampiUserData.phase ? 'needDetail' : 'productDetails',
    )
    let amplitudeUserData = {
      User: ampiInstance.email,
      Trend_Name: ampiUserData.name,
      Maturity_Phase: ampiUserData.phase,
    }
    if (!ampiUserData.phase) {
      amplitudeUserData = {
        ...amplitudeUserData,
        Lens: 'Ingredient',
        Country_Name: ampiUserData.country,
        Category_Name: ampiUserData.category,

        Redirected_From: `Related ${ampiUserData.blockName} from ${ampiUserData.amplitudeRedirect}`,
      }
    } else {
      amplitudeUserData = {
        ...amplitudeUserData,
        Lens:
          ampiUserData.blockName === 'Ingredients'
            ? ampiUserData.blockName.slice(0, -1)
            : ampiUserData.blockName,
        Country_Name: ampiInstance.country,
        Category_Name: ampiInstance.category,
        Redirected_From: `Related ${ampiUserData.blockName} from ${ampiUserData.lens}`,
      }
    }
    amplify(amplitudeEvent, amplitudeUserData, ampiInstance.email)
  } catch (err) {
    console.log(err, 'Amplitude error in RelatedIngredientCard')
  }
}

const RelatedIngredientCard = ({
  dataPoint,
  demographyComponent,
  image,
  rank,
  name,
  productid,
  projectid,
  trend,
  lens,
  blockName,
  country, // Only from CN and SCN Page
  category, // Only for CN and SCN Page
  amplitudeRedirect = '', // Only for CN and SCN Page
  link,
  type,
  logRegionalTrendClick,
}) => {
  const relatedIngredientsClickHandler = () => {
    if (type === 'regionalAnalytics') {
      logRegionalTrendClick(name, `Related ${blockName} from ${lens}`)
    } else {
      const ampiEvent = 'Clicked_Trend_Lens'
      let ampiUserData = {
        lens: lens,
        name: name,
        blockName: blockName,
        phase: trend,
      }
      if (amplitudeRedirect) {
        ampiUserData = { ...ampiUserData, country, category, amplitudeRedirect }
      }
      amp(ampiEvent, ampiUserData)
    }
  }

  return (
    <>
      {demographyComponent ? (
        <RelatedIngredientContainerForDemography>
          <>
            <img src={image} alt={name} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Typography
                my={1}
                variant="subtitle3"
                sx={{ color: (theme) => theme.palette.secondary.dark }}
              >
                {name}
              </Typography>

              <Typography variant="caption">
                Data Point: {formatNumber(dataPoint)}
              </Typography>
            </Box>
          </>
        </RelatedIngredientContainerForDemography>
      ) : (
        <Link
          target="_blank"
          underline="none"
          variant="body2"
          onClick={relatedIngredientsClickHandler}
          href={() => buildLink(projectid, productid, blockName, name, link)}
        >
          <RelatedIngredientContainer>
            <>
              <img src={image} alt={name} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography variant="subtitle1">
                  Rank: <span className="text">#{rank}</span>
                </Typography>

                <Typography
                  my={1}
                  variant="subtitle3"
                  sx={{ color: (theme) => theme.palette.secondary.dark }}
                >
                  {name}
                </Typography>

                {trend && (
                  <Typography variant="caption" color="textSecondary">
                    Maturity Phase:{' '}
                    <span className="text">
                      {trend[0] + trend.slice(1).toLowerCase()}
                    </span>
                  </Typography>
                )}
              </Box>
            </>
          </RelatedIngredientContainer>
        </Link>
      )}
    </>
  )
}

RelatedIngredientCard.defaultProps = {
  image: null,
  name: null,
  productid: null,
  projectid: null,
  trend: null,
}

RelatedIngredientCard.propTypes = {
  image: PropTypes.node,
  name: PropTypes.string,
  productid: PropTypes.string,
  projectid: PropTypes.string,
  trend: PropTypes.string,
}

export default RelatedIngredientCard
