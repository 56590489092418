import React, { useState, useEffect } from 'react'

import SearchOptionsDropdown from './SearchOptionsDropdown'
import ImageSafe from '../Image/ImageSafe'
import Tooltip from '../SWTooltip/SWTooltip_v2'

import downArrow from '../../assets/images/icons/downArrow.svg'

import './SearchOptionsDropdown.scss'

const SearchOptsSingleSelect = ({
  data = [],
  placeholder = '',
  setDefaultVal,
  value,
  handleSearchInput,
  handleSelect,
  required,
  errorMessage = '',
  revalidate,
  revalidateCallback,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [optSelected, setOptSelected] = useState(null)
  const [error, setError] = useState(false)

  const optionItem = (id, name, img) => (
    <div
      key={id}
      className={`options-text imgtitle vertical-center ${
        optSelected && optSelected.id === id ? 'sw-option--active' : ''
      }`}
      style={{ width: '100%', height: 40 }}
      onClick={() => setOptSelected({ id, name, img })}
    >
      <div>
        {img && (
          <ImageSafe className="option-element__img-blk" src={img} alt={name} />
        )}
      </div>
      <span className="options-text">{name}</span>
    </div>
  )
  const optionElem = (
    <div
      className={`option-element options-text imgtitle vertical-center ${
        error ? 'sw-options--error' : ''
      }`}
    >
      <div>
        {optSelected ? (
          <>
            {optSelected.img && (
              <ImageSafe
                className="option-element__img-blk"
                src={optSelected.img}
                alt={optSelected.name}
              />
            )}
          </>
        ) : null}
      </div>
      <Tooltip title={optSelected ? optSelected.name : ''}>
        <span className="option-element__selected-opt">
          {optSelected ? optSelected.name : placeholder}
        </span>
      </Tooltip>
      <ImageSafe
        className="option-element__down-arrow"
        alt="down-arrow"
        src={downArrow}
      />
    </div>
  )

  const optionsList = data
    .filter(({ name }) =>
      name.toLowerCase().includes(searchInput.toLowerCase()),
    )
    .map(({ id, name, img }) => optionItem(id, name, img))

  const errorCheck = () => {
    if (!optSelected && required) {
      setError(true)
    } else {
      setError(false)
    }
  }

  useEffect(() => {
    handleSearchInput && handleSearchInput(searchInput)
  }, [searchInput])

  useEffect(() => {
    if (setDefaultVal) {
      data && setOptSelected(data[0])
    }
  }, [setDefaultVal, data])

  useEffect(() => {
    if (optSelected) {
      handleSelect && handleSelect(optSelected)
      errorCheck()
    }
  }, [optSelected])

  useEffect(() => {
    setOptSelected(value)
  }, [value])

  useEffect(() => {
    if (revalidate) {
      errorCheck(revalidateCallback)
    }
  }, [revalidate])

  useEffect(() => {
    if (error && revalidate) {
      revalidateCallback && revalidateCallback()
    }
  }, [error])

  return (
    <SearchOptionsDropdown
      inputStyle={'sw-input option-element'}
      optionElem={optionElem}
      options={optionsList}
      searchInputCallback={(val) => setSearchInput(val)}
      required
      errorMessage={error && errorMessage}
      inputInActiveCallback={errorCheck}
    />
  )
}

export default SearchOptsSingleSelect
