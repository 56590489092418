import NetworkUtil from '../../services/networking/NetworkingUtil'
import { buildWinnerBaseUrl } from './api'

export const getBuildWinnerURL = (url) => `${buildWinnerBaseUrl}${url}`

export const getHeaders = (
  obj = {},
  method = null,
  accept = 'application/json',
) => {
  const customAuth = NetworkUtil.getBWAuthKey()
  return {
    ...obj,
    ...(method && { method: method }),
    headers: {
      ...obj.headers,
      Accept: accept,
      'Content-Type': 'application/json',
      custom_auth: customAuth,
    },
  }
}
