import React, { useEffect, useState } from 'react'
import SingleSelect from '../../shared/components/SingleSelect'
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material'
import AddUser from './AddUser'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import axios from './../../services/networking/axios'
import moment from 'moment'

const clientDataLabel = [
  {
    label: 'Account Type: ',
    id: 'accountType',
  },
  { label: 'No. Of Users Added: ', id: 'noOfUsersAdded' },
  { label: 'No. Of Users Permitted: ', id: 'noOfUsersPermitted' },
  { label: 'Start Date: ', id: 'startDate' },
  { label: 'End Date: ', id: 'endDate' },
]

const ClientInfo = ({
  userList,
  fillUserHandle,
  fillUserDetailView,
  setAddUserView,
  steps,
  activeStep,
  handleBack,
  setActiveStep,
  setFillUserDetailView,
}) => {
  const [selectedClient, setSelectedClient] = useState()
  const [clientDetail, setClientDetail] = useState([])
  const [clientData, setClientData] = useState([])

  const options = clientDetail.map((client) => {
    return {
      key: client.clientId,
      label: client.clientName,
    }
  })

  const handleClientSelection = (val) => {
    if (!val) {
      setSelectedClient()
      return
    }
    const client = clientDetail.find((data) => data.clientId == val?.key)
    setSelectedClient(client)

    const obj = {
      accountType: client.clientType,
      noOfUsersAdded: userList.filter((data) => data.org_id === client.clientId)
        .length,
      noOfUsersPermitted: client.noOfUsers,
      startDate: moment(client.startDate).format('DD-MMM-YYYY'),
      endDate: moment(client.endDate).format('DD-MMM-YYYY'),
    }

    setClientData(obj)
  }

  const getClientData = () => {
    axios
      .get(NetworkingUtil.userAccessManagement())
      .then((res) => {
        function renameKeys(obj, newKeys) {
          const keyValues = Object.keys(obj).map((key) => {
            const newKey = newKeys[key] || key
            return { [newKey]: obj[key] }
          })
          return Object.assign({}, ...keyValues)
        }
        const newKeys = {
          name: 'clientName',
          dashboard_activation_date: 'startDate',
          dashboard_expiration_date: 'endDate',
          max_authorised_users: 'noOfUsers',
          client_type: 'clientType',
          id: 'clientId',
        }
        const camelCaseData = res.data.data.map((client, index) => {
          const modifiedObj = renameKeys(client, newKeys)
          return {
            ...modifiedObj,
            dashboardSubscribed: {
              noOfDashboards: client.dashboards_count,
              dashboardsSubscribed: client.dashboards,
            },
            status: client?.status === 'IN-ACTIVE' ? 'IN-ACTIVE' : 'ACTIVE',
            options: true,
          }
        })
        setClientDetail(camelCaseData)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getClientData()
  }, [])
  const handleCancel = () => {
    setAddUserView(false)
  }
  return (
    <>
      <Stepper activeStep={activeStep} sx={{ marginTop: '20px' }}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {fillUserDetailView ? (
        <AddUser
          selectedClient={selectedClient}
          userList={userList}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          setAddUserView={setAddUserView}
          setFillUserDetailView={setFillUserDetailView}
          clientData={clientData}
        />
      ) : (
        <Box>
          <Box sx={{ display: 'flex', padding: '10px', height: '500px' }}>
            <Box sx={{ width: '50%' }}>
              <SingleSelect
                sx={{ width: '300px' }}
                value={selectedClient?.clientName}
                options={options}
                placeholder="Select Client"
                onChange={(e, val) => handleClientSelection(val)}
              />
            </Box>
            <Box
              sx={{
                border: '1px solid black',
                padding: '10px',
                width: '50%',
                height: '400px',
              }}
            >
              {selectedClient ? (
                clientDataLabel.map((labelData) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                      pt={2}
                      pb={2}
                    >
                      <Box sx={{ width: '300px' }}>
                        <Typography variant="subtitle1">
                          {labelData.label}
                        </Typography>
                      </Box>
                      <Box sx={{ width: '300px' }}>
                        <Typography variant="subtitle1">
                          {clientData[labelData.id]}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '400px',
                  }}
                >
                  <Typography>
                    Please select a client in order to add the users.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              sx={{ marginRight: '30px' }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedClient}
              variant="contained"
              onClick={fillUserHandle}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ClientInfo
