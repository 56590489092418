import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../utils/formatNumber';
import { Box, Typography } from '@mui/material';
import TrendCardContaniner from './styles';

const TrendCard = ({
  name, rank, image, dataPoint,
}) => (
  <TrendCardContaniner>
    <img src={image} alt={name} />
    <Box>
      <Typography variant="subtitle3">{name}</Typography>
      <Box>
        <Box>
          <Typography variant="caption" color="textSecondary">Rank - </Typography>
          <Typography variant="caption">
            #
            {rank}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">Data points - </Typography>
          <Typography variant="caption">{formatNumber(dataPoint)}</Typography>
        </Box>
      </Box>
    </Box>
  </TrendCardContaniner>
);

TrendCard.propTypes = {
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  dataPoint: PropTypes.string.isRequired,
};

export default TrendCard;
