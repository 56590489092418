import React from "react";
import PropTypes from "prop-types";

import "./SWAccordian.scss";

const SWAccordian = ({ heading, content, onHeadingClick }) => {
  return (
    <div className="sw-accordian">
      <div className="sw-accordian__heading">
        <button className="sw-accordian__heading__btn" onClick={onHeadingClick}>
          {heading}
        </button>
        <div className="sw-accordian__content">{content}</div>
      </div>
    </div>
  );
};

SWAccordian.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
};

export default SWAccordian;
