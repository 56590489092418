import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

const MountDetect = ({children, onMountCallback}) => {
    useEffect(() => {
      if(onMountCallback){
        onMountCallback()
      }
    }, [])
    return children
}

MountDetect.propTypes = {
    children: PropTypes.node.isRequired,
    onMountCallback: PropTypes.func.isRequired
}

export default MountDetect
