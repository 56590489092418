import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Box } from '@mui/material'

import { ReactComponent as IngredientsIcon } from '../../../assets/icons/ingredients.svg'
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products.svg'
import { ReactComponent as ThemesIcon } from '../../../assets/icons/themes.svg'

import FavoriteTrendPillarsUI from './FavoriteTrendPillarsUI'
import DashboardCommonCardsList from '../DashboardCommonCardsList'

import {
  fetchFavoritesTrendPillarList,
  removeFromFavoritesTrendPillar,
} from '../../../store/actions/dashboardActions'
import RemoveFavPopUp from '../OtherFavorites/RemoveFavPopup'
import palette from '../../../theme/palette'
import EmptyIngredients from '../EmptyIngredientsCard'

const lensIconMap = {
  ingredient: (
    <IngredientsIcon
      fill={`${palette.secondary.main}`}
      height={30}
      width={30}
    />
  ),
  product: (
    <ProductsIcon fill={`${palette.secondary.main}`} height={30} width={30} />
  ),
  theme: (
    <ThemesIcon fill={`${palette.secondary.main}`} height={30} width={30} />
  ),
}

const FavoriteTrendPillarList = ({
  fetchFavoritesTrendPillarList,
  favoritesTrendPillarsData,
  timeframeToCheck,
  selectedTimeFrame,
  removeFromFavoritesTrendPillar,
  setShowLoader,
  email,
  amplitudeFn,
}) => {
  const [trendPillarToDelete, setTrendPillarToDelete] = useState(null)
  const [pillarId, setPillarId] = useState(null)
  const [pillarType, setPillarType] = useState(null)
  const [pillarName, setPillarName] = useState(null)
  const [projectId, setProjectId] = useState(null)

  useEffect(() => {
    fetchFavoritesTrendPillarList(setShowLoader)
  }, [])

  const handleClose = () => {
    const ampiEvent = 'Clicked_MW_Favorites_TrendPillar_PillarName_Star'
    const ampiUserData = {
      User: email,
      Lens: pillarType,
      Pillar_Name: pillarName,
      Action: 'Cancel',
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setTrendPillarToDelete(null)
  }

  const handleConfirmClick = () => {
    removeFromFavoritesTrendPillar(
      pillarId,
      pillarType,
      setShowLoader,
      projectId,
    )
    const ampiEvent = 'Clicked_MW_Favorites_TrendPillar_PillarName_Star'
    const ampiUserData = {
      User: email,
      Lens: pillarType,
      Pillar_Name: pillarName,
      Action: 'Confirm',
    }
    amplitudeFn(ampiEvent, ampiUserData)
    setTrendPillarToDelete(null)
  }

  const HandleRemoveFromFavorite = (
    pillarId,
    pillarType,
    pillarName,
    projectID,
    e,
  ) => {
    e.preventDefault()
    setTrendPillarToDelete(true)
    setPillarId(pillarId)
    setPillarType(pillarType)
    setPillarName(pillarName)
    setProjectId(projectID)
  }

  return (
    <>
      <DashboardCommonCardsList gridStyles={{ p: 2 }}>
        {' '}
        {favoritesTrendPillarsData?.data?.length ? (
          favoritesTrendPillarsData.data.map((obj) => (
            <FavoriteTrendPillarsUI
              lensIconMap={lensIconMap}
              pillarName={
                obj.analytical_data.basic_info_trend_piller.pillar_name
              }
              country={obj.analytical_data.basic_info_trend_piller.country}
              category={obj.analytical_data.basic_info_trend_piller.category}
              timeframeToCheck={timeframeToCheck}
              trendAnalytics={obj.analytical_data[timeframeToCheck]}
              totalDatapoint={obj.analytical_data.overall.total_data_points}
              total2YearCagr={obj.analytical_data.overall.total_two_year_cagr}
              totalTrends={obj.analytical_data.overall.pillar_length}
              selectedTimeFrame={selectedTimeFrame}
              trendPillarType={obj.trend_piller_type}
              pillarId={
                obj.trend_piller_type === 'Product'
                  ? obj.product_trend_piller
                  : obj.trend_piller_type === 'Ingredient'
                  ? obj.ingredient_trend_piller
                  : obj.trend_piller_type === 'Theme'
                  ? obj.theme_trend_piller
                  : null
              }
              projectId={obj.analytical_data.basic_info_trend_piller.project_id}
              HandleRemoveFromFavorite={HandleRemoveFromFavorite}
              graphData={obj.analytical_data?.graph_data}
              twoYearCAGRChange={obj.analytical_data[
                timeframeToCheck
              ].two_year_cagr?.toFixed(2)}
              dashboardUpdatedTimestamp={obj.dashboard_updated_timestamp}
              email={email}
              amplitudeFn={amplitudeFn}
            />
          ))
        ) : (
          <Box sx={{ width: '100%' }}>
            <EmptyIngredients
              text={`Click on "Add to Favorites" on Trend Pillar to add Trend Pillars to Favorites`}
            />
          </Box>
        )}
        <RemoveFavPopUp
          open={trendPillarToDelete}
          text={pillarName}
          sectionName="Trend Pillar  Favorites"
          handleClose={handleClose}
          handleConfirmClick={handleConfirmClick}
        />
      </DashboardCommonCardsList>
    </>
  )
}

const mapStateToProps = (state) => {
  const { myDashboard } = state
  return { favoritesTrendPillarsData: myDashboard.favoritesTrendPillarsData }
}

const mapDispatchToProps = (dispatch) => ({
  fetchFavoritesTrendPillarList: (setShowLoader) =>
    dispatch(fetchFavoritesTrendPillarList(setShowLoader)),
  removeFromFavoritesTrendPillar: (
    pillarId,
    pillarType,
    setShowLoader,
    projectId,
  ) => {
    dispatch(
      removeFromFavoritesTrendPillar(
        pillarId,
        pillarType,
        setShowLoader,
        projectId,
      ),
    )
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FavoriteTrendPillarList),
)
