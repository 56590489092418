import { fetchHighlightFeatureList } from './actionTypes'
import axios from '../../services/networking/axios'
import NetworkUtil from '../../services/networking/NetworkingUtil'

export const fetchListOfHighlightFeatures = () => {
  return async (dispatch, getState) => {
    const request = NetworkUtil.fetchFeaturesToHighlight()
    axios
      .get(request)
      .then((response) => {
        dispatch(fetchHighlightFeatureSuccess(response.data.data))
      })
      .catch((error) => {
        dispatch(fetchHighlightFeatureFailed(error))
      })
  }
}

const fetchHighlightFeatureSuccess = (highlightFeatureList) => {
  return {
    type:
      fetchHighlightFeatureList.type[
        fetchHighlightFeatureList.FETCH_HIGHLIGHT_FEATURE_LIST_SUCCESS
      ],
    highlightFeatureList: highlightFeatureList,
  }
}

const fetchHighlightFeatureFailed = (error) => {
  return {
    type:
      fetchHighlightFeatureList.type[
        fetchHighlightFeatureList.FETCH_HIGHLIGHT_FEATURE_LIST_FAILED
      ],
    error: error,
  }
}

export const putHighlightFeature = (data) => {
  return async (dispatch, getState) => {
    const request = NetworkUtil.fetchFeaturesToHighlight(data)
    return axios
      .put(request, data)
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })
  }
}
