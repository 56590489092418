import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { calcDisplayedWidth } from '../../store/utility'
import './SWTooltip.scss'

const maxWidth = 320
const TooltipTitle = ({
  offsetLeft = 0,
  offsetTop = 0,
  text = '',
  showOnOverflow,
  CustomTooltip,
  show,
  follow,
  children,
  ignoreTextWidth,
  style,
  relChildPos,
  classes = [],
  enableTranslation = false,
  toolTipStyle = {},
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const tooltipDiv = useRef(null)
  const [tooltipPos, setTooltipPos] = useState({ left: 0, top: 0 })
  const [autoPos, setAutoPos] = useState(true)
  const setTooltip = (ev) => {
    const {
      x,
      y,
    } = tooltipDiv.current.firstElementChild.getBoundingClientRect()
    const textWidth = calcDisplayedWidth(ignoreTextWidth ? '' : text, 18)
    let {
      right,
      left,
    } = tooltipDiv.current.parentElement.getBoundingClientRect()

    // Place tooltip relative to children else place
    // relatvie to the parent of TooltipTitle
    if (relChildPos) {
      const newPos = tooltipDiv.current.firstElementChild.getBoundingClientRect()
      right = newPos.right
      left = newPos.left
    }

    // Tooltip will render on left if it's
    // out of bounds of screen
    if (!CustomTooltip) {
      if (maxWidth + right + 30 > window.innerWidth) {
        right = left - maxWidth - 30 // reduce by padding units
        setAutoPos(false)
      } else {
        setAutoPos(true)
      }
    }

    if (CustomTooltip) {
      const newPos = tooltipDiv.current.firstElementChild.getBoundingClientRect()
      const width = newPos.width
      right = newPos.right
      left = newPos.left

      if (width + right > window.innerWidth) {
        right = left - maxWidth - 30 // reduce by padding units
        setAutoPos(false)
      } else {
        setAutoPos(true)
      }
    }

    // Tooltip will follow cursor
    if (follow) {
      // const rightPos = x + maxWidth + 20
      // if(ev.clientX < rightPos)
      //   right = rightPos + 30
      // else
      right = ev.clientX + 40
    }

    // Only show tooltip when the element overflows
    if (showOnOverflow) {
      const child = tooltipDiv.current.firstElementChild
      if (child.offsetWidth < child.scrollWidth) {
        setShowTooltip(true)
        setTooltipPos({ left: right, top: y })
      }
    } else {
      setShowTooltip(true)
      setTooltipPos({ left: right, top: y })
    }
  }

  useEffect(() => {
    if (!showTooltip && show) {
      setShowTooltip(true)
    }
  }, [showTooltip, show])

  return (
    <div
      className={`sw-tooltip-title ${classes.join(' ')}`}
      ref={tooltipDiv}
      onMouseEnter={setTooltip}
      onMouseLeave={() => setShowTooltip(false)}
      style={style}
    >
      {children}
      {(enableTranslation ? true : showTooltip) && (
        <div
          style={{
            left: tooltipPos.left + offsetLeft,
            top: tooltipPos.top + offsetTop,
            ...(enableTranslation
              ? { display: showTooltip ? 'inline-block' : 'none' }
              : {}),
          }}
          className="tooltip-title__content"
        >
          <span
            style={{ ...toolTipStyle, maxWidth }}
            role="tooltip"
            className={`tooltip-title__txt  tooltip-title__txt--${
              autoPos ? 'right' : 'left'
            }`}
          >
            {CustomTooltip ? <CustomTooltip /> : text}
          </span>
        </div>
      )}
    </div>
  )
}

TooltipTitle.propTypes = {
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  text: PropTypes.string,
  showOnOverflow: PropTypes.bool,
  CustomTooltip: PropTypes.func,
  show: PropTypes.bool,
  follow: PropTypes.bool, // Over rides other position calculations
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  relChildPos: PropTypes.bool,
  classes: PropTypes.arrayOf(PropTypes.string),
  enableTranslation: PropTypes.bool,
  toolTipStyle: PropTypes.objectOf({}),
}

export default TooltipTitle
