import styled from '@emotion/styled';
import { Box } from '@mui/system';

export default styled(Box)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  '& img': {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.shape.borderRadius,
    ObjectFit: 'cover',
  },
  '& >.MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

