import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { Checkbox, FormControlLabel, Typography, Tooltip } from '@mui/material'
import { areEqual } from 'react-window'

const TrendItem = ({ data, index, style, hideLens }) => {
  const { id, name, type, checked = false } = data.items[index]
  return (
    <Box key={id} sx={{ display: 'flex', alignItems: 'center' }} style={style}>
      {type === 'header' ? (
        <Typography variant="subtitle3">{name}</Typography>
      ) : type === 'empty' ? (
        <Typography color="text.secondary" variant="subtitle3">
          {name}
        </Typography>
      ) : (
        <Tooltip
          title={
            <Typography
              variant="caption"
              sx={{
                textTransform: 'capitalize',
                color: ({ palette }) => palette.primary.contrastText,
              }}
            >
              {name}
              {hideLens ? null : (
                <>
                  : &nbsp;
                  <b>{type}</b>
                </>
              )}
            </Typography>
          }
          placement="right"
        >
          <FormControlLabel
            label={name.length > 16 ? `${name.slice(0, 16)}...` : name}
            value={id}
            onChange={data.handleSelectTrend}
            checked={checked}
            control={
              <Checkbox
                inputProps={{ 'aria-labelledby': id }}
                color="secondary"
                size="small"
                disableRipple
                sx={{ ml: -1 }}
              />
            }
          />
        </Tooltip>
      )}
    </Box>
  )
}

TrendItem.propTypes = {
  data: PropTypes.shape({
    handleSelectTrend: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        checked: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(['ingredient', 'theme', 'header']).isRequired,
      }),
    ),
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
}

export default memo(TrendItem, areEqual)
