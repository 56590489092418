class ProductResponse {
    constructor (response) {
      this._id = response.id
      this._name = response.name
      this._description = response.short_description
      this._imageUrl = response.image_url
    }
  
    get id () {
      return this._id
    }
  
    get name () {
      return this._name
    }
  
    get description () {
      return this._description
    }
  
    get imageUrl () {
      return this._imageUrl
    }
  
  }
  
export default ProductResponse
  