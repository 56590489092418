import React, { useState, useRef } from 'react'
import { Typography, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { currencies } from '../../../../../shared/utils/constants'
import RelatedProductCardContainer from './styles'
import { amplify } from './../../../../../store/utility'
import { AmplitudeValues } from '../../../../../store/amplitudeValues'
import BackdropWithCheckbox from '../../../MarkIrrelevant/BackdropWithCheckbox'

const amp = (ampiEvent, ampiUserData = {}) => {
  try {
    const amplitudeEvent = ampiEvent
    let amplitudeUserData = {}
    switch (ampiEvent) {
      case 'Clicked_Pairing_Retail_Products':
        const ampiInstance = new AmplitudeValues()
        amplitudeUserData = {
          ...amplitudeUserData,
          User: ampiInstance.email,
          Lens: ampiUserData.lens,
          Trend_Name: ampiUserData.trendName,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Maturity_Phase: ampiUserData.maturityPhase,
        }
        break
      default:
        amplitudeUserData = {
          ...amplitudeUserData,
          User: ampiUserData.email,
          Trend_Name: ampiUserData.trendName,
          Country_Name: ampiUserData.country,
          Category_Name: ampiUserData.category,
          Sample_Products_Name: ampiUserData.sampleProductName,
        }
        break
    }

    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Related Product Card')
  }
}

const RelatedProductCard = ({
  image,
  name,
  brand,
  price,
  currency,
  url,
  country,
  category,
  ingredientName,
  email,
  lens,
  isTrendChecked,
  type,
  logRetailProductClicked,
  isBackdropOpen,
  esId,
  handleEsDocSelection,
  selectedIrrelevantDocs,
  disableLog,
}) => {
  const currencySymbol = currencies[currency ? currency.toUpperCase() : '']
  const [showtooltip, setTooltip] = useState(false)
  const textref = useRef(null)
  const hoverEvent = () => {
    const compare = textref.current.scrollHeight > textref.current.clientHeight
    setTooltip(compare)
  }

  const productCardClickHandler = (url) => {
    window.open(url, '_blank')
    if (disableLog) {
    } else if (type === 'searchResult') {
      logRetailProductClicked(name)
    } else {
      if (lens) {
        const ampiEvent = 'Clicked_Pairing_Retail_Products'
        const ampiUserData = {
          lens,
          trendName: ingredientName,
          country,
          category,
          sampleProductName: name,
          maturityPhase: isTrendChecked,
        }
        amp(ampiEvent, ampiUserData)
      } else {
        const ampiEvent = 'Clicked_Related_Retail_Product_Sample_Products'
        const ampiUserData = {
          email,
          trendName: ingredientName,
          country,
          category,
          sampleProductName: name,
        }
        amp(ampiEvent, ampiUserData)
      }
    }
  }

  const rpCardContent = (
    <>
      <img style={{ width: 60, height: 80 }} src={image} alt={name} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          pl: 2,
        }}
      >
        {/* {!!Number(price) && (
          <Typography variant="caption">
            {currencySymbol || (currency ? currency.toUpperCase() : '')}
            {` ${Number(price).toFixed(2)}`}
          </Typography>
        )} */}
        <Tooltip title={showtooltip ? name : ''}>
          <Typography
            variant="subtitle3"
            onMouseOver={hoverEvent}
            ref={textref}
            my={1}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              maxHeight: '36px',
              mb: '2px',
            }}
          >
            {name}
          </Typography>
        </Tooltip>
        <Tooltip
          title={brand}
          disableHoverListener={brand && brand.length < 25}
        >
          <Typography variant="caption" color="textSecondary">
            {brand &&
              (brand.length > 25 ? brand.slice(0, 25).trim() + '...' : brand)}
          </Typography>
        </Tooltip>
        {/* <Typography variant="caption" color="textSecondary">
        {brand}
      </Typography> */}
      </Box>
    </>
  )
  const rpCardContainer = (
    <Box sx={{ position: 'relative' }}>
      <RelatedProductCardContainer
        isDisableClick={!url}
        onClick={!url ? () => {} : () => productCardClickHandler(url)}
      >
        {rpCardContent}
      </RelatedProductCardContainer>
      <BackdropWithCheckbox
        isOpen={isBackdropOpen}
        selectedIrrelevantDocs={selectedIrrelevantDocs}
        esId={esId}
        handleEsDocSelection={handleEsDocSelection}
      />
    </Box>
  )
  return rpCardContainer
}

RelatedProductCard.defaultProps = {
  image: null,
  name: null,
  brand: null,
  price: null,
  currency: 'Euro',
}

RelatedProductCard.propTypes = {
  image: PropTypes.node,
  name: PropTypes.string,
  brand: PropTypes.string,
  price: PropTypes.string,
  currency: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default RelatedProductCard
