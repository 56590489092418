import React, { cloneElement, useState } from 'react'
import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

const TextOverflowTooltip = ({
  children,
  title = '',
  maxLineCount = 1,
  checkWidth = false,
  tooltipProps,
}) => {
  const [disableHoverListener, setDisableHoverListener] = useState(true)

  const handleElementHover = (e) => {
    const heightOverflow = e.target.clientHeight < e.target.scrollHeight
    const widthOverflow = e.target.scrollWidth > e.target.clientWidth
    const textExceedsElement = checkWidth
      ? heightOverflow || widthOverflow
      : heightOverflow
    setDisableHoverListener(!textExceedsElement)
  }

  const clonedChildren = cloneElement(children, {
    onMouseEnter: handleElementHover,
    sx: {
      ...children.props.sx,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: maxLineCount,
      WebkitBoxOrient: 'vertical',
      ...(!checkWidth && {
        display: '-webkit-box',
      }),
    },
  })

  return (
    <Tooltip
      disableHoverListener={disableHoverListener}
      title={title}
      {...tooltipProps}
    >
      {clonedChildren}
    </Tooltip>
  )
}

TextOverflowTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  maxLineCount: PropTypes.number,
}

export default TextOverflowTooltip
