import React from 'react'
import { IconButton, Menu } from '@mui/material'
import { styled } from '@mui/system'

export const NotificationMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 360,
    top: '48px',
    maxHeight: '450px',
    padding: '0px !important',
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      '&:first-of-type': {
        position: 'sticky',
        zIndex: 10000,
        top: 0,
        backgroundColor: 'white !important',
        '& > .MuiBox-root': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        height: theme.spacing(5.5),
        '&:hover': {
          cursor: 'default',
          backgroundColor: 'transparent',
        },
      },
    },
  },
}))

export const IconButtonRoot = styled(IconButton)(({ theme, active }) => ({
  '&:hover': {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
  }),
  padding: 6,
  minWidth: 34,
  width: 34,
  height: 34,
  '& .MuiBadge-badge': {
    ...theme.typography.overline,
    color: theme.palette.white,
    minWidth: 18,
    width: 18,
    height: 18,
    borderRadius: '50%',
    marginRight: '-2px',
    marginTop: '-4px',
    boxShadow: '0px 0px 0px 2px #ffffff',
  },
}))
