import React from 'react'
import _ from 'lodash'

import { Box, Typography } from '@mui/material'

import DashboardTooltip from '../DashboardTooltip'

const DashboardDataPointsLabel = (props) => {
  const {
    title,
    boxProps,
    dataPoints,
    dataPointsChange,
    selectedTimeframe,
    hasAmplitudeEvent,
  } = props
  const handleHoverOpen = () => {
    if (hasAmplitudeEvent) {
      hasAmplitudeEvent.handleHoverOpen('Data Points')
    }
  }
  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      {title && (
        <Typography
          variant="subtitle2"
          sx={{
            color: ({ palette }) => palette.text.secondary,
          }}
        >
          {title}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" color="primary">
          {dataPoints}
        </Typography>
        {!_.isNil(dataPointsChange) && (
          <DashboardTooltip
            title={`Compare to last ${selectedTimeframe ||
              'Month'} Data Points has ${
              dataPointsChange.includes('-') ? 'decreased' : 'increased'
            } by ${dataPointsChange}`}
            handleOpen={handleHoverOpen}
          >
            <Typography
              sx={{
                color: ({ palette }) =>
                  !dataPointsChange.includes('-')
                    ? palette.success.dark
                    : palette.error.dark,
                fontSize: '0.675rem',
              }}
            >
              &nbsp;(
              {dataPointsChange.includes('-')
                ? dataPointsChange
                : `+${dataPointsChange}`}
              )
            </Typography>
          </DashboardTooltip>
        )}
      </Box>
    </Box>
  )
}

export default DashboardDataPointsLabel
