import { CountriesActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'
import * as AC from '../actions/actionConstants'

const initialState = {
  countryList : null,
  error : false,
  loading : false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case CountriesActionTypes.type[CountriesActionTypes.FETCH_COUNTRIES_START]: return updateObject(state, {
      loading: true,
    })
    case CountriesActionTypes.type[CountriesActionTypes.FETCH_COUNTRIES_SUCCESS]: return updateObject(state, {
      countryList : action.savedCountries,
      error : false,
      loading : false,
    })
    case CountriesActionTypes.type[CountriesActionTypes.FETCH_SAVED_TRENDS_FAIL]: return updateObject(state, {
      countryList : null,
      error : true,
      loading : false,
    })
    default:
      return state
  }
} 
  
export default reducer