import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import CreateImportDialog from '../CreateImportDialog'
import { getCapitalzedText } from '../../../../../store/utility'
import { connect } from 'react-redux'
import NetworkUtil from '../../../../../services/networking/NetworkingUtil'
import * as actions from '../../../../../store/actions'
import axios from '../../../../../services/networking/axios'
import OverlayLoader from '../../../../../shared/components/OverlayLoader'
import { fetchAllTrends } from '../../../../../store/actions/advancedSearchActions'
import { pillarBasicDetails } from '../../../../../store/actions/createPillarActions'
import { LENSES } from '../../../../utils/constants'

const CreateImportDialogContainer = ({
  countriesList,
  categoriesList,
  projectsList,
  prjSelectedId,
  fetchAllTrendsAvailable,
  getTrendPillarforImporting,
  open,
  setShowModal,
  showImport,
  importPillar,
}) => {
  const [pillarName, setBucketName] = useState(null)
  const [countrySelected, setCountrySelected] = useState(null)
  const [categorySelected, setCategorySelected] = useState(null)
  const [lensSelected, setLensSelected] = useState('')
  const [load, setload] = useState(false)

  const [availableLenses, setAvailableLenses] = useState(LENSES)
  const history = useHistory()

  const handleBucketNameChange = (e) => {
    const value = e.target.value.trimStart()
    setBucketName(value && getCapitalzedText(value))
  }

  const handleLensSelected = (newVal) => {
    setLensSelected(newVal)
  }

  const handleCategoryChange = (e, newVal) => {
    setCategorySelected(newVal)
  }

  const handleCountryChange = (e, newVal) => {
    setCountrySelected(newVal)
    setCategorySelected(null)
    setLensSelected(null)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (countrySelected && categorySelected) {
      const prjSelectedId = projectsList.list.find((prj) => {
        return (
          prj.name === categorySelected.label &&
          prj.criteria === countrySelected.label
        )
      })?.id
      fetchAllTrendsAvailable(prjSelectedId)
    }
    history.push('/mui/trend-pillar')
    setShowModal(false)
  }

  const handleCancelClick = () => {
    setCountrySelected(null)
    setCategorySelected(null)
    setLensSelected(null)
    setShowModal(false)
  }

  // Lifecycle methods below here:
  useEffect(() => {
    getTrendPillarforImporting()
  }, [])

  useEffect(() => {
    if (countrySelected && categorySelected) {
      const prjSelectedId = projectsList.list.find((prj) => {
        return (
          prj.name === categorySelected.label &&
          prj.criteria === countrySelected.label
        )
      })?.id
      const url = NetworkUtil.fecthFeaturesDetailsRequest(prjSelectedId)
      setload(true)
      axios
        .get(url)
        .then((response) => {
          const lensesVal = []
          if (response.data.data.ingredient_classification) {
            lensesVal.push(LENSES[0])
          }
          if (response.data.data.product_classification) {
            lensesVal.push(LENSES[1])
          }
          if (response.data.data.theme_classification) {
            lensesVal.push(LENSES[2])
          }
          setAvailableLenses(lensesVal)
          setload(false)
        })
        .catch((error) => {
          console.log('Features Request Error:')
        })
    }
  }, [countrySelected, categorySelected])

  return (
    <CreateImportDialog
      showImport={showImport}
      open={open}
      pillarName={pillarName}
      handleBucketNameChange={handleBucketNameChange}
      availableLenses={availableLenses}
      lensSelected={lensSelected}
      handleLensSelected={handleLensSelected}
      disableToggleButton={true}
      categorySelected={categorySelected}
      categoriesList={categoriesList}
      handleCategoryChange={handleCategoryChange}
      countriesList={countriesList}
      countrySelected={countrySelected}
      handleCountryChange={handleCountryChange}
      handleSubmitClick={handleSubmitClick}
      handleCancelClick={handleCancelClick}
      load={load}
      setShowModal={setShowModal}
      importPillar={importPillar}
    />
  )
}

CreateImportDialogContainer.propTypes = {}

const mapStateToProps = (state) => {
  const { savedTrendRequester } = state
  return {
    projectsList: savedTrendRequester.savedTrendList,
    importPillar: state.trendPillar.importDetails,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllTrendsAvailable: (projectId) =>
    dispatch(fetchAllTrends(projectId, true, true)),
  fetchLensesAvailable: (projectId) =>
    dispatch(actions.fecthFeaturesDetails(projectId)),
  pillarBasicDetails: () => dispatch(pillarBasicDetails()),
  getTrendPillarforImporting: () =>
    dispatch(actions.getTrendPillarforImporting()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateImportDialogContainer)
