import { cognitiveSearch as cognitiveSearchActionTypes } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  trendInfoLoading: {
    socialGraphData: false,
    fsProductsData: false,
    retailProductsData: false,
    consumerQuotesData: false,
  },
  loading: {
    dashboardsList: false,
    filteredTrends: false,
    advFiltersAppliedTrends: false,
    formatsList: false,
    advFiltersList: false,
    consumerNeeds: false,
  },
  formatsList: [],
  advFiltersList: [],
  advFiltersAppliedTrendsList: {},
  consumerNeeds: [],
  filteredTrends: [],
  fsProductsData: {},
  retailProductsData: {},
  consumerQuotesData: {},
  socialGraphData: {},
  dashboardsList: [],
}

const cognitiveSearch = (state = initialState, action) => {
  switch (action.type) {
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FORMATS_LIST
    ]:
      let formatOptions = []
      if (action.formatsListData && action.formatsListData.length) {
        formatOptions = action.formatsListData.map((format) => {
          return {
            label: format,
            key: format,
            checked: false,
          }
        })
      }
      return updateObject(state, {
        formatsList: formatOptions,
        loading: { ...state.loading, formatsList: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FORMATS_LIST_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, formatsList: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FORMATS_LIST_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, formatsList: false },
      })
    case cognitiveSearchActionTypes.type[cognitiveSearchActionTypes.FETCH_CN]:
      return updateObject(state, {
        consumerNeeds: action.consumerNeedsData,
        loading: { ...state.loading, consumerNeeds: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_CN_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, consumerNeeds: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_CN_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, consumerNeeds: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS_LOADING
    ]:
      return updateObject(state, {
        filteredTrends: initialState.filteredTrends,
        loading: { ...state.loading, filteredTrends: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, advFiltersAppliedTrends: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, advFiltersAppliedTrends: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS
    ]:
      return updateObject(state, {
        filteredTrends: action.filteredTrendsData,
        loading: { ...state.loading, filteredTrends: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FILTERED_TRENDS_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, filteredTrends: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FS_PRODUCTS
    ]:
      return updateObject(state, {
        fsProductsData: action.fsProductsData,
        trendInfoLoading: { ...state.trendInfoLoading, fsProductsData: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FS_PRODUCTS_LOADING
    ]:
      return updateObject(state, {
        trendInfoLoading: { ...state.trendInfoLoading, fsProductsData: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_FS_PRODUCTS_FAILED
    ]:
      return updateObject(state, {
        trendInfoLoading: { ...state.trendInfoLoading, fsProductsData: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS
    ]:
      return updateObject(state, {
        retailProductsData: action.retailProductsData,
        trendInfoLoading: {
          ...state.trendInfoLoading,
          retailProductsData: false,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS_LOADING
    ]:
      return updateObject(state, {
        trendInfoLoading: {
          ...state.trendInfoLoading,
          retailProductsData: true,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_RETAIL_PRODUCTS_FAILED
    ]:
      return updateObject(state, {
        trendInfoLoading: {
          ...state.trendInfoLoading,
          retailProductsData: false,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES
    ]:
      return updateObject(state, {
        consumerQuotesData: action.consumerQuotesData,
        trendInfoLoading: {
          ...state.trendInfoLoading,
          consumerQuotesData: false,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES_LOADING
    ]:
      return updateObject(state, {
        trendInfoLoading: {
          ...state.trendInfoLoading,
          consumerQuotesData: true,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_CONSUMER_QUOTES_FAILED
    ]:
      return updateObject(state, {
        trendInfoLoading: {
          ...state.trendInfoLoading,
          consumerQuotesData: false,
        },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_UPDATE
    ]:
      return updateObject(state, {
        advFiltersList: action.advFiltersListDataUpdated,
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST
    ]:
      let advanceFilterOptions = []
      if (action.advFiltersListData) {
        Object.keys(action.advFiltersListData).map((uniqueKey) => {
          advanceFilterOptions.push(
            ...action.advFiltersListData[uniqueKey].map((filterLabel) => {
              return {
                label: filterLabel,
                key: `${uniqueKey}-${filterLabel}`,
                checked: false,
              }
            }),
          )
        })
      }
      if (state.advFiltersList && state.advFiltersList.length) {
        const duplicatedObjs = state.advFiltersList.filter((obj) =>
          advanceFilterOptions.some((updated) => obj.key === updated.key),
        )
        const updatedObjs = advanceFilterOptions.filter((updated) =>
          state.advFiltersList.every((obj) => updated.key !== obj.key),
        )
        advanceFilterOptions = duplicatedObjs.concat(updatedObjs)
      }
      return updateObject(state, {
        advFiltersList: advanceFilterOptions,
        loading: { ...state.loading, advFiltersList: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, advFiltersList: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_FILTERS_LIST_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, advFiltersList: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_ADV_SRCH_RESULTS
    ]:
      return updateObject(state, {
        advFiltersAppliedTrendsList: action.advFiltersAppliedTrendsListData,
        loading: { ...state.loading, advFiltersAppliedTrends: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_GRAPH_DATA
    ]:
      return updateObject(state, {
        socialGraphData: action.socialGraphData,
        trendInfoLoading: { ...state.trendInfoLoading, socialGraphData: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_GRAPH_DATA_LOADING
    ]:
      return updateObject(state, {
        trendInfoLoading: { ...state.trendInfoLoading, socialGraphData: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.FETCH_GRAPH_DATA_FAILED
    ]:
      return updateObject(state, {
        trendInfoLoading: { ...state.trendInfoLoading, socialGraphData: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.GET_DASHBOARDS_LIST
    ]:
      return updateObject(state, {
        dashboardsList: action.dashboardsList,
        loading: { ...state.loading, dashboardsList: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.GET_DASHBOARDS_LIST_LOADING
    ]:
      return updateObject(state, {
        loading: { ...state.loading, dashboardsList: true },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.GET_DASHBOARDS_LIST_FAILED
    ]:
      return updateObject(state, {
        loading: { ...state.loading, dashboardsList: false },
      })
    case cognitiveSearchActionTypes.type[
      cognitiveSearchActionTypes.RESET_COGNITIVE_SEARCH_STATE
    ]:
      return updateObject(state, initialState)
    default:
      return state
  }
}

export default cognitiveSearch
