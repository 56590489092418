import React, { useEffect, useState } from 'react'
import SearchResultPageUI from './SearchResultPageUI'
import { useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from './../../services/networking/axios'
import {
  fetchSavedTrends,
  fetchSubscriptionsDetails,
} from '../../store/actions'
import { getQueryParams, amplify } from './../../store/utility'
import NetworkingUtil from '../../services/networking/NetworkingUtil'
import { AmplitudeValues } from '../../store/amplitudeValues'
import { isEmpty } from 'lodash'

const amp = (ampiEvent, ampiUserData) => {
  try {
    const amplitudeEvent = ampiEvent
    const ampiInstance = new AmplitudeValues()
    let amplitudeUserData = {
      User: ampiInstance.email,
      Country_Name: ampiUserData.project.country,
      Category_Name: ampiUserData.project.category,
    }
    switch (amplitudeEvent) {
      case 'Entered_Search_Keyword':
        amplitudeUserData = {
          ...amplitudeUserData,
          Keyword: ampiUserData.searchedKeyword,
          Total_Number_Of_Results: ampiUserData.totalCount,
        }
        break
      case 'Clicked_RP_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          RP: ampiUserData.title,
        }
        break
      case 'Clicked_FSP_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          FSP: ampiUserData.title,
        }
        break
      case 'Clicked_Trend_Report_Search':
        amplitudeUserData = {
          ...amplitudeUserData,
          Trend_Report: ampiUserData.report,
        }
        break
      case 'Clicked_Trend_Lens':
        amplitudeUserData = {
          ...amplitudeUserData,
          Maturity_Phase: ampiUserData.trend.classification,
          Trend_Name: ampiUserData.trend.name,
          Lens: ampiUserData.lens,
          Redirected_From: 'Search',
        }
        break
    }
    amplify(amplitudeEvent, amplitudeUserData, amplitudeUserData.User)
  } catch (err) {
    console.log(err, 'Amplitude error in Search result Page')
  }
}

const availableChips = [
  'Ingredients',
  'Products',
  'Themes',
  'Food Service Products',
  'Retail Products',
  'Trend Reports',
]
const SearchResultPage = ({
  projectId,
  requestProjectsList,
  projectsList,
  searchedKeyword,
  email,
  firstName,
  lastName,
  fetchSubscriptionsDetails,
}) => {
  const [project, setProject] = useState({})
  const [activeChips, setActiveChips] = useState(availableChips)
  const [activeId, setActiveId] = useState('')
  const [ingredientSearchResult, setIngredientSearchResult] = useState([])
  const [productSearchResult, setProductSearchResult] = useState([])
  const [themeSearchResult, setThemeSearchResult] = useState([])
  const [reportSearchResult, setReportSearchResult] = useState([])
  const [fsProductResult, setFsProductResult] = useState([])
  const [retailProductResult, setRetailProductResult] = useState([])
  const [searchTermCorrection, setSearchTermCorrection] = useState({})
  const [ingredientLoader, setIngredientLoader] = useState(false)
  const [productLoader, setProductLoader] = useState(false)
  const [themeLoader, setThemeLoader] = useState(false)
  const [reportLoader, setReportLoader] = useState(false)
  const [fsProductLoader, setFsProductLoader] = useState(false)
  const [retailProductLoader, setretailProductLoader] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)

  const history = useHistory()

  // Logging Amplitude Events

  const logResultPageLoaded = () => {
    const ampiEvent = 'Entered_Search_Keyword'
    const ampiUserData = {
      project,
      searchedKeyword,
      totalCount:
        ingredientSearchResult.length +
        productSearchResult.length +
        themeSearchResult.length +
        fsProductResult.length +
        retailProductResult.length +
        reportSearchResult.length,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logRetailProductClicked = (title) => {
    const ampiEvent = 'Clicked_RP_Search'
    const ampiUserData = {
      title,
      project,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logFoodServiceClicked = (title) => {
    const ampiEvent = 'Clicked_FSP_Search'
    const ampiUserData = {
      title,
      project,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logTrendClicked = (trend, lens) => {
    const ampiEvent = 'Clicked_Trend_Lens'
    const ampiUserData = {
      trend,
      lens,
      project,
    }
    amp(ampiEvent, ampiUserData)
  }

  const logReportClicked = (report) => {
    const ampiEvent = 'Clicked_Trend_Report_Search'
    const ampiUserData = {
      report,
      project,
    }
    amp(ampiEvent, ampiUserData)
  }

  const clearState = () => {
    setProject({})
    setSearchTermCorrection({})
    setRetailProductResult([])
    setFsProductResult([])
    setReportSearchResult([])
    setThemeSearchResult([])
    setProductSearchResult([])
    setIngredientSearchResult([])
  }

  const sendRecentHistory = (entityDetails) => {
    const formattedEntityDetails = {
      entity_id: entityDetails.id,
      entity_name: entityDetails.name,
      entity_category: entityDetails.type,
      image_url:
        entityDetails.type === 'report'
          ? entityDetails.thumbnailURL
          : entityDetails.imageURL,
      project_id: projectId,
      ...(entityDetails.type === 'report' && {
        resource_url: entityDetails.report,
      }),
    }
    const recentHistoryReq = NetworkingUtil.postRecentHistory()
    axios
      .post(recentHistoryReq, formattedEntityDetails)
      .then((res) => {})
      .catch((error) => {
        console.log('post history not working')
      })
  }

  const openReport = (report) => {
    sendRecentHistory({ ...report, type: 'report' })
    const id = report.id
    const query = getQueryParams()
    query.set('activeReport', id)
    history.replace(`?${query.toString()}`)
  }

  const closeReport = () => {
    const query = getQueryParams()
    query.delete('activeReport')
    history.replace(`?${query.toString()}`)
  }

  const handleChipClick = (chip) => {
    const isChipActive = activeChips.includes(chip)
    if (isChipActive) {
      setActiveChips(activeChips.filter((activeChip) => activeChip !== chip))
    } else {
      const newActiveChips = availableChips.filter((availiableChip) => {
        if (activeChips.includes(availiableChip) || availiableChip === chip) {
          return true
        }
        return false
      })
      setActiveChips(newActiveChips)
    }
  }

  const handleTrendCardClick = (trend, lens) => {
    sendRecentHistory({ ...trend, type: lens })
    logTrendClicked(trend, lens)
  }

  const resultExistenceCheck = () => {
    if (
      ingredientLoader ||
      productLoader ||
      themeLoader ||
      reportLoader ||
      fsProductLoader ||
      retailProductLoader ||
      ingredientSearchResult.length ||
      productSearchResult.length ||
      themeSearchResult.length ||
      fsProductResult.length ||
      retailProductResult.length
    ) {
      return true
    }
    return false
  }

  const getCountryCategory = (projectId) => {
    if (projectsList.length) {
      return projectsList.find((project) => `${project.id}` === projectId)
    }
  }

  const formatSearchRes = (type, result) => {
    switch (type) {
      case 'cafe_product':
        return {
          img: result.image_url,
          price: result.price,
          restaurantName: result.restaurant_name,
          title: result.name,
          url: result.restaurant_url,
          currency: result.currency,
        }
      case 'report':
        return {
          id: result.id,
          imageURL: result.image,
          thumbnailURL: result.thumbnail_url,
          name: result.name,
          report: result.report,
          criteria: result.criteria,
          reportPPTURL: result.report_ppt_url,
          date: result.date,
        }
      case 'retail_product':
        return {
          img: result.image_url,
          name: result.name,
          brand: result.brand_name,
          price: result.price,
          currency: result.currency,
          url: result.product_url,
          country: result.country,
          category: result.category,
          ingredientName: result.ingredientName,
        }
      default:
        return {
          classification: result.classification,
          fsProducts: result.fs_products,
          id: result.id,
          imageURL: result.image_url,
          name: result.name,
          retailProducts: result.retail_products,
          twoYearCagr: result.two_year_cagr,
        }
    }
  }

  const fetchSearchResult = (type, setLoader, setSearchResult) => {
    const responseKey =
      type === 'cafe_product' || type === 'retail_product' ? type : `${type}s`
    const searchRequest = NetworkingUtil.fetchSearchResult(
      projectId,
      searchedKeyword,
      type,
    )
    setLoader(true)
    axios
      .get(searchRequest)
      .then((res) => {
        if (
          isEmpty(searchTermCorrection) ||
          (!isEmpty(res.data) &&
            searchTermCorrection.originalTerm === res.data.original_term &&
            !isEmpty(res.data[responseKey]) &&
            (!res.data.type || !res.data.correction))
        ) {
          const searchCorrection = {
            correction: res.data.correction,
            originalTerm: res.data.original_term,
            searchTerm: res.data.search_term,
            language: res.data.language,
            type: res.data.type,
          }
          setSearchTermCorrection(searchCorrection)
        }

        const searchFormattedResponse = res.data[responseKey].map((detail) => {
          return formatSearchRes(type, detail)
        })

        setSearchResult(searchFormattedResponse)
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        console.log(`error in getting ${type} search result`)
      })
  }

  useEffect(() => {
    fetchSubscriptionsDetails()
  }, [])

  useEffect(() => {
    setIsPageLoading(true)
    requestProjectsList().then(() => {
      setIsPageLoading(false)
    })
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    fetchSearchResult(
      'ingredient',
      setIngredientLoader,
      setIngredientSearchResult,
    )
    fetchSearchResult('product', setProductLoader, setProductSearchResult)
    fetchSearchResult('theme', setThemeLoader, setThemeSearchResult)
    fetchSearchResult('report', setReportLoader, setReportSearchResult)
    fetchSearchResult('cafe_product', setFsProductLoader, setFsProductResult)
    fetchSearchResult(
      'retail_product',
      setretailProductLoader,
      setRetailProductResult,
    )
  }, [searchedKeyword])

  useEffect(() => {
    if (projectsList) {
      const mappedProject = getCountryCategory(projectId)
      const newProject = {
        id: mappedProject.id,
        country: mappedProject.criteria,
        category: mappedProject.name,
      }
      setProject(newProject)
    }
  }, [projectId, projectsList?.length])

  useEffect(() => {
    setActiveId(getQueryParams({ location }).get('activeReport'))
  }, [location.search])

  useEffect(() => {
    if (
      !ingredientLoader &&
      !productLoader &&
      !themeLoader &&
      !reportLoader &&
      !fsProductLoader &&
      !retailProductLoader &&
      !isEmpty(project)
    ) {
      logResultPageLoaded()
    }
  }, [
    ingredientLoader,
    productLoader,
    themeLoader,
    reportLoader,
    fsProductLoader,
    retailProductLoader,
    project,
  ])

  return (
    <SearchResultPageUI
      project={project}
      searchedKeyword={searchedKeyword}
      handleChipClick={handleChipClick}
      availableChips={availableChips}
      activeChips={activeChips}
      activeId={activeId}
      history={history}
      email={email}
      firstName={firstName}
      lastName={lastName}
      openReport={openReport}
      closeReport={closeReport}
      ingredientSearchResult={ingredientSearchResult}
      productSearchResult={productSearchResult}
      themeSearchResult={themeSearchResult}
      reportSearchResult={reportSearchResult}
      searchTermCorrection={searchTermCorrection}
      fsProductResult={fsProductResult}
      retailProductResult={retailProductResult}
      ingredientLoader={ingredientLoader}
      productLoader={productLoader}
      themeLoader={themeLoader}
      reportLoader={reportLoader}
      fsProductLoader={fsProductLoader}
      retailProductLoader={retailProductLoader}
      resultExistenceCheck={resultExistenceCheck}
      isPageLoading={isPageLoading}
      logRetailProductClicked={logRetailProductClicked}
      logFoodServiceClicked={logFoodServiceClicked}
      handleTrendCardClick={handleTrendCardClick}
      logReportClicked={logReportClicked}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    projectId: props.match.params.projectId,
    searchedKeyword: props.match.params.search,
    projectsList: state.savedTrendRequester.savedTrendList?._list,
    email: state?.user?.loggedIn?.user?.email
      ? state?.user?.loggedIn?.user?.email
      : '',
    firstName: state?.user?.loggedIn?.user?.firstName,
    lname: state?.user?.loggedIn?.user?.lastName,
    lastName: state?.user?.loggedIn?.user?.username,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestProjectsList: () => dispatch(fetchSavedTrends()),
    fetchSubscriptionsDetails: () => dispatch(fetchSubscriptionsDetails()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResultPage, axios),
)
