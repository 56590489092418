import React, { useState } from 'react'
import ClientInfo from './ClientInfo'
import UserManagementTable from './UserManagementTable'
const steps = ['Client', 'User Details']
const UserManagement = () => {
  const [addUserView, setAddUserView] = useState(false)
  const [userList, setUserList] = useState()
  const [fillUserDetailView, setFillUserDetailView] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const handleAddUser = () => {
    setAddUserView(!addUserView)
  }

  const fillUserHandle = () => {
    setFillUserDetailView(!fillUserDetailView)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    setFillUserDetailView(false)
  }

  return (
    <>
      {addUserView ? (
        <ClientInfo
          userList={userList}
          fillUserHandle={fillUserHandle}
          fillUserDetailView={fillUserDetailView}
          setAddUserView={setAddUserView}
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          setFillUserDetailView={setFillUserDetailView}
        />
      ) : (
        <UserManagementTable
          userList={userList}
          setUserList={setUserList}
          handleAddUser={handleAddUser}
        />
      )}
    </>
  )
}

export default UserManagement
