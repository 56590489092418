import React from 'react'
import {
  Table,
  TableBody,
  Box,
  Typography,
  TableSortLabel,
} from '@mui/material'

import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  trendBucketThemeSubHeadCells,
  trendBucketIngProdSubHeadCells,
} from '../tableColumnsConst'
import GenericTableHead from '../../../shared/components/GenericTableComponent/GenericTableHead'
import SubTableBody from '../SubTableBody'
import { getComparator } from '../../../store/utility'
import { LENSES } from '../../../shared/utils/constants'
import { StyledTreeItem } from '../styles'
import NoPillarList from '../../../assets/images/testImages/noPillar.jpg'
import _ from 'lodash'

const MergePillarTreeViewUI = ({
  allPillars,
  headers,
  handleRequestSort,
  filteredData,
  parentIds,
  order,
  orderBy,
  expandedNodes,
  handleToggle,
  defaultExpandedPillar,
  defaultExpandedRef,
  slectedCheckboxData,
  tableComponent,
  handleSubTableSort,
  tooltipOpenHandle,
  categorySelected,
  countrySelected,
  lensSelected,
  subTableOrder,
  subTableOrderBy,
  sortByKey,
  searchValue,
  getColWidth,
}) => {
  const PlaceTree = ({ id, placesById }) => {
    const place = placesById[id] ? placesById[id] : allPillars[id]
    const childIds = place.childIds

    return (
      <li key={`ListItem-${id}`}>
        {childIds.length > 0 && (
          <ol>
            {sortByKey(childIds, orderBy).map((childId) => {
              return (
                <StyledTreeItem
                  key={`${childId}`}
                  nodeId={`${childId}`}
                  ref={
                    childId == defaultExpandedPillar ? defaultExpandedRef : null
                  }
                  styledProps={{
                    expandedNodes: expandedNodes,
                    allPillars: allPillars,
                    childId: childId,
                  }}
                  label={tableComponent(
                    placesById[childId]
                      ? placesById[childId]
                      : allPillars[childId],
                    allPillars[childId]?.level,
                    slectedCheckboxData,
                  )}
                >
                  <PlaceTree
                    key={`PlaceTreeKey-${childId}`}
                    id={childId}
                    placesById={placesById}
                  />
                </StyledTreeItem>
              )
            })}
          </ol>
        )}
        {!childIds.length && place.trendDetails.length > 0 && (
          <Table
            size="small"
            aria-label="pillars"
            sx={{
              marginBottom: '15px',
              marginTop: '10px',
              boxShadow: ({ palette }) =>
                `-6px 10px 6px -6px ${palette.shadow.pillar}`,
            }}
          >
            <GenericTableHead
              headCells={
                lensSelected === LENSES[2]
                  ? trendBucketThemeSubHeadCells
                  : trendBucketIngProdSubHeadCells
              }
              headHeight={50}
              border={(theme) => `2px solid ${theme.palette.divider}`}
              handleRequestSort={handleSubTableSort}
              order={subTableOrder}
              orderBy={subTableOrderBy}
              tooltipOpenHandle={tooltipOpenHandle}
              tableCellSX={{ paddingLeft: '0px !important' }}
            />
            <TableBody>
              <SubTableBody
                lensSelected={lensSelected}
                row={place.trendDetails.sort(
                  getComparator(subTableOrder, subTableOrderBy),
                )}
                country={countrySelected}
                category={categorySelected}
                handleRequestSort={handleSubTableSort}
              />
            </TableBody>
          </Table>
        )}
      </li>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%', minWidth: '1200px', overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '70px',
            background: ({ palette }) => palette.white,
            color: ({ palette }) => palette.primary.main,
            border: ({ palette }) => `2px solid ${palette.icon}`,
            borderRadius: '5px',
            width: '100%',
          }}
        >
          {headers.map((headCell) => {
            return (
              <Box
                key={headCell.label}
                onClick={() => handleRequestSort(headCell.id)}
                sx={{
                  width: headCell.width ? headCell.width : getColWidth(),
                  minWidth: headCell.width ? headCell.width : '100px',
                  display: 'flex',
                  justifyContent: headCell.justifyContent || 'start',
                  alignItems: 'center',
                  cursor: headCell.cursor || 'pointer',
                }}
              >
                {headCell.label}
                {headCell.sorting && (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    sx={{
                      display: 'flex',
                      right: '10px',
                    }}
                  />
                )}
              </Box>
            )
          })}
        </Box>
        <Box
          sx={{
            background: ({ palette }) => palette.background.paper,
            width: '100%',
          }}
        >
          {!_.isEmpty(filteredData) ? (
            <ol>
              {sortByKey(parentIds, orderBy).map((id) => (
                <React.Fragment key={id}>
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expandedNodes}
                    onNodeToggle={handleToggle}
                  >
                    {id ? (
                      <StyledTreeItem
                        key={`${id}`}
                        ref={
                          id == defaultExpandedPillar
                            ? defaultExpandedRef
                            : null
                        }
                        nodeId={`${id}`}
                        styledProps={{
                          expandedNodes: expandedNodes,
                          allPillars: allPillars,
                          childId: id,
                        }}
                        label={tableComponent(
                          filteredData[id],
                          filteredData[id].level,
                          slectedCheckboxData,
                        )}
                      >
                        <PlaceTree
                          key={`PlaceTreeKey-${id}`}
                          id={id}
                          placesById={filteredData}
                        />
                      </StyledTreeItem>
                    ) : (
                      <PlaceTree
                        key={`PlaceTreeKey-${id}`}
                        id={id}
                        placesById={filteredData}
                      />
                    )}
                  </TreeView>
                </React.Fragment>
              ))}
            </ol>
          ) : (
            <Box
              sx={{
                background: ({ palette }) => palette.background.paper,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: !Object.keys(filteredData).length
                  ? '400px'
                  : Object.keys(filteredData).length < 3
                  ? '200px'
                  : '0',
              }}
            >
              <Box
                component="img"
                src={NoPillarList}
                alt="Pillar list is empty"
                width="214px"
                height="117px"
              />
              <Typography variant="subtitle1" sx={{ marginTop: '28px' }}>
                {searchValue
                  ? 'No result found for the searched data'
                  : 'Your pillars will appear here'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default MergePillarTreeViewUI
