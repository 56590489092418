import { isEmpty } from 'lodash'
import formatNumber from '../../../shared/utils/formatNumber'
import ProductResponse from './ProductResponse'
import { convertTextToTitleCase } from '../../../store/utility'

class ProductDetailResponse {
  constructor(response) {
    this._id = response.id
    this._rank = response.rank
    this._name = response.name
    this._trendProjectId = response.project_id || ''
    this._country = response.country || ''
    this._category = response.category || ''
    this._description = response.description
    this._socialMediaPostID = response.socialMediaPostID
    this._socialMediaType = response.socialMediaType
    this._score = response.score
    this._overallEngagementScore = response.overall_engagement_score
    this._productImage = response.productImage || response.themeImage
    this._data_point = response.data_point
    this._retail_data_point = response.retail_data_point
    this._cafe_data_point = response.cafe_data_point
    this._positiveSentiment = response.positive_sentiment
    this._neutralSentiment = response.neutral_sentiment
    this._negativeSentiment = response.negative_sentiment
    this._searchCriteria = response.searchCriteria
    this._trendName = response.trend_name
    this._platforms = response.platforms
    this._overallGrowthRate = response.overall_growth_rate
    this._momGrowthRate = response.mom_growth
    this._fourYearCagr = response.four_year_cagr
    this._twoYearCagr = response.two_year_cagr
    this._yoyGrowthRate = response.yoy_growth
    this._category_data = response.category_data
    this._projectVariant = response.project_variant
    this._relatedThemeProducts = response.related_products || []
    this._relatedThemeIngredients = response.related_ingredients || []
    this.f = (response.platforms || []).map((value) => {
      return {
        id: value,
        value: value,
        displayValue: value === 'Pantip' ? 'Blogs and Forums' : value,
      }
    })
    this._quotesOptions = [
      {
        id: 0,
        value: 'Social',
        displayValue: 'Social Data',
      },
      {
        id: 1,
        value: 'Trade',
        displayValue: 'Trade Media',
      },
      {
        id: 2,
        value: 'Patent',
        displayValue: 'Patent Data',
      },
    ]
    if (isEmpty(response.trade_media_chart))
      this._quotesOptions = this._quotesOptions.filter((item) => item.id !== 1)
    if (isEmpty(response.patent_source_chart))
      this._quotesOptions = this._quotesOptions.filter((item) => item.id !== 2)
    this._positiveNeed = response.positiveNeed.map((values) => {
      return new ConsumerNeeds(values)
    })
    this._negativeNeed = response.negativeNeed.map((values) => {
      return new ConsumerNeeds(values)
    })
    this._topComments = (response.topComments || []).map((comments) => {
      return new Comments(comments)
    })
    this._trendChart = (response.trend_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._socialData = (response.social_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._webSearchData = (response.search_data || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._trademediaData = (response.trade_media_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._patentData = (response.patent_source_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._otherData = (response.other_data || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._salesData = (response.sales_data || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._retailCountsData = (response.retail_doc_count || []).map((trend) => {
      return new TrendChart(trend)
    })
    this._predictiveTrendChart = (response.predictive_chart || []).map(
      (trend) => {
        return new TrendChart(trend)
      },
    )
    this._predictiveTrendGrowthRate =
      (response.predictive_chart || []).length > 0
        ? response.predictive_chart[0].growth_rate
        : 0
    this._absoluteChart = response.absolute_chart.map((trend) => {
      return new AbsoluteChart(trend)
    })
    this._trendProducts = (
      response.trend_products || response.trend_themes
    ).map((trend) => {
      return new ProductResponse(trend)
    })
    this._needFilters = (response.need_filters || []).map((value) => {
      return {
        id: value,
        value: value,
        displayValue: value,
      }
    })
    this._productFormat = response.format || {}
    this._filterClassification = response.filter_classification
    this._predictive_feature = Boolean(response.predictive_chart)
    this._salesFeature = Boolean(response.sales_data || response.salesData)
    this._splitFeature = !(
      response.youtube_data_chart === null ||
      response.search_data === null ||
      response.social_chart === null ||
      response.other_data === null
    )
    this._productFeature =
      response.product_feature === undefined ? true : response.product_feature
    this._ingredientFeature =
      response.ingredient_feature === undefined
        ? true
        : response.ingredient_feature
    this._cafeProducts = []
    this._retailProducts = []
    this._trendCoefficientIntercept = response.trend_coefficient_intercept
    this._trendCoefficient = response.trend_four_year_coefficient
    this._trendIntercept = response.trend_four_year_intercept
    const {
      transitioning_from: transitioningFrom,
      transitioning_to: transitioningTo,
      transitioning,
    } = response.transition_status
    const transitionStatus = {
      isTransitioning: transitioning,
      message: '',
    }
    if (transitioning) {
      if (!transitioningFrom || !transitioningTo) {
        transitionStatus.message = 'Trend is transitioning'
      } else {
        transitionStatus.message = `Trend is transitioning from ${convertTextToTitleCase(
          transitioningFrom,
        )} to ${convertTextToTitleCase(transitioningTo)}`
      }
    }
    this._transitionStatus = transitionStatus
    this._predictedCoefficient = response.prediction_coefficient
    this._predictedIntercept = response.prediction_intercept
    this._youtubeDataChart = response.youtube_data_chart
  }
  
  get trendCoefficientIntercept(){
    return this._trendCoefficientIntercept
  }

  get cafeProducts() {
    return this._cafeProducts
  }

  get retailProducts() {
    return this._retailProducts
  }

  get id() {
    return this._id
  }

  // eslint-disable-next-line
  get predictive_feature() {
    return this._predictive_feature
  }

  get salesFeature() {
    return this._salesFeature
  }

  get splitFeature() {
    return this._splitFeature
  }

  get ingredientFeature() {
    return this._ingredientFeature
  }

  get productFeature() {
    return this._productFeature
  }

  get productFormat() {
    return this._productFormat
  }

  get rank() {
    return this._rank
  }

  get trendProjectId() {
    return this._trendProjectId
  }

  get country() {
    return this._country
  }

  get category() {
    return this._category
  }

  get overallGrowthRate() {
    return this._overallGrowthRate
  }

  get momGrowthRate() {
    return this._momGrowthRate
  }

  get yoyGrowthRate() {
    return this._yoyGrowthRate
  }

  get fourYearCagr() {
    return this._fourYearCagr
  }

  get twoYearCagr() {
    return this._twoYearCagr
  }

  get name() {
    return this._name
  }

  get description() {
    return this._description
  }

  get socialMediaType() {
    return this._socialMediaType
  }

  get socialMediaPostID() {
    return this._socialMediaPostID
  }

  get score() {
    return this._score
  }

  get overallEngagementScore() {
    return this._overallEngagementScore
  }

  get productImage() {
    return this._productImage
  }

  get dataPoint() {
    return formatNumber(this._data_point)
  }

  get unFormatedDataPoint() {
    return this._data_point
  }

  get retailDataPoint() {
    return this._retail_data_point
  }

  get cafeDataPoint() {
    return this._cafe_data_point
  }

  get positiveSentiment() {
    return this._positiveSentiment
  }

  get neutralSentiment() {
    return this._neutralSentiment
  }

  get negativeSentiment() {
    return this._negativeSentiment
  }

  get positiveNeed() {
    return this._positiveNeed
  }

  get negativeNeed() {
    return this._negativeNeed
  }

  get topComments() {
    return this._topComments
  }

  get trendChart() {
    return this._trendChart
  }

  get socialData() {
    return this._socialData
  }

  get webSearchData() {
    return this._webSearchData
  }

  get trademediaData() {
    return this._trademediaData
  }

  get patentData() {
    return this._patentData
  }

  get otherData() {
    return this._otherData
  }

  get salesData() {
    return this._salesData
  }

  get retailCountsData() {
    return this._retailCountsData
  }

  get absoluteChart() {
    return this._absoluteChart
  }

  get searchCriteria() {
    return this._searchCriteria
  }

  get trendProducts() {
    return this._trendProducts
  }

  get trendName() {
    return this._trendName
  }

  get platforms() {
    return this._platforms
  }

  get quotesOptions() {
    return this._quotesOptions
  }

  get quotesMediaOptions() {
    return this._quotesMediaOptions
  }

  // eslint-disable-next-line
  get category_data() {
    return this._category_data
  }

  get needFilters() {
    return this._needFilters
  }

  get filterClassification() {
    return this._filterClassification
  }

  get predictiveTrendChart() {
    return this._predictiveTrendChart
  }

  get predictiveTrendGrowthRate() {
    return this._predictiveTrendGrowthRate
  }

  get relatedThemeProducts() {
    return this._relatedThemeProducts
  }

  get relatedThemeIngredients() {
    return this._relatedThemeIngredients
  }

  get transitionStatus() {
    return this._transitionStatus
  }

  get trendCoefficient() {
    return this._trendCoefficient
  }

  get trendIntercept() {
    return this._trendIntercept
  }

  get predictedCoefficient() {
    return this._predictedCoefficient
  }

  get predictedIntercept() {
    return this._predictedIntercept
  }

  set cafeProducts(products) {
    this._cafeProducts = products || []
  }

  set retailProducts(products) {
    this._retailProducts = products || []
  }

  // eslint-disable-next-line
  set trendChart(trend_chart) {
    // eslint-disable-next-line
    this._trendChart = (trend_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
  }

  // eslint-disable-next-line
  set predictiveTrendChart(predictive_chart) {
    // eslint-disable-next-line
    this._predictiveTrendChart = (predictive_chart || []).map((trend) => {
      return new TrendChart(trend)
    })
  }

  get youtubeDataChart() {
    return this._youtubeDataChart
  }

  get projectVariant() {
    return this._projectVariant
  }
}

class ConsumerNeeds {
  constructor(response) {
    this._needId = response.id
    this._name = response.name
    this._score = response.percentage_value
  }

  get needId() {
    return this._needId
  }

  get name() {
    return this._name
  }

  get score() {
    return this._score
  }
}

class Comments {
  constructor(response) {
    this._profile = {
      imageURI: response.profile.imageURI,
      name: response.profile.name,
      desc: response.profile.desc,
    }
    this._comment = response.comment
  }

  get profile() {
    return this._profile
  }

  get comment() {
    return this._comment
  }
}

export class TrendChart {
  constructor(response) {
    this._period = response.period || response.timestamp
    this._engagementScore = response.engagementScore || 0.0
    this._confidence = response.confidence || 0.0
    const {
      // eslint-disable-next-line
      monthly_engagement_score_lower,
      // eslint-disable-next-line
      monthly_engagement_score_upper,
      engagementScore,
    } = response
    if (
      // eslint-disable-next-line
      typeof monthly_engagement_score_lower !== 'undefined' &&
      // eslint-disable-next-line
      monthly_engagement_score_lower <= engagementScore &&
      // eslint-disable-next-line
      typeof monthly_engagement_score_upper !== 'undefined' &&
      // eslint-disable-next-line
      monthly_engagement_score_upper >= this.engagementScore
    ) {
      this._engagementErrorMargin = {
        top: response.monthly_engagement_score_upper,
        bottom: response.monthly_engagement_score_lower,
      }
    }
  }

  get period() {
    return this._period
  }

  get engagementScore() {
    return this._engagementScore
  }

  get confidence() {
    return this._confidence
  }

  get engagementErrorMargin() {
    return this._engagementErrorMargin
  }
}

class AbsoluteChart {
  constructor(response) {
    this._period = response.period
    this._engagementScore = response.engagementScore
  }

  get period() {
    return this._period
  }

  get engagementScore() {
    return this._engagementScore
  }
}

export default ProductDetailResponse
