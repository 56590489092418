import * as React from 'react'
import PropTypes from 'prop-types'

import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { Box } from '@mui/system'

import formatNumber from '../../shared/utils/formatNumber'

const AnalyticsHoverCard = ({
  image,
  fourYearCagr,
  twoYearCagr,
  name,
  demographyComponent,
  engagementScore,
  viewDetailsLink,
  ampEventClickOnViewDetails,
}) => (
  <>
    <CardMedia
      component="img"
      height="140"
      sx={{ width: '100px', height: '100px', marginLeft: '70px' }}
      image={image}
      alt="green iguana"
    />

    <CardContent sx={{ width: '180' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          gutterBottom
          variant="subtitle3"
          sx={{ color: 'primary.main', fontWeight: 600 }}
        >
          {name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mt: 1,
          width: demographyComponent && '190px !important',
          height: demographyComponent && '200',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="caption">Engagement</Typography>
            <Typography variant="caption" sx={{ color: 'primary.main' }}>
              {engagementScore < 1e3
                ? engagementScore.toFixed(2)
                : formatNumber(engagementScore)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="caption">2 Year CAGR</Typography>
            <Typography variant="caption" sx={{ color: 'primary.main' }}>
              {parseFloat(twoYearCagr).toFixed(2)}%
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          sx={{ width: '100%', mt: 1, mb: -1 }}
          size="small"
          onClick={viewDetailsLink}
        >
          View Details
        </Button>
      </Box>
    </CardContent>
  </>
)

export default AnalyticsHoverCard
