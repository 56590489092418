import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

import "./FlyOutOptions.scss";
import OutsideClickHandler from "react-outside-click-handler";

const FlyOutOptions = ({ children, options, className = "" , optsClassName="", show}) => {
  const [showOption, setShowOption] = useState(false);
  useEffect(() => {
    if(!show){
      setShowOption(false)
    }
  }, [show])
  return (
    <>
    <span className={className} role="button" onClick={() => setShowOption((showOption) => !showOption)}>
      <OutsideClickHandler onOutsideClick={() => setShowOption(false)}>
          {children}
      </OutsideClickHandler>
      </span>
      <Transition in={showOption} timeout={1}>
        {(transitionState) => {
          return (
            <ul className={`flyout flyout-${transitionState} ${optsClassName ? ` ${optsClassName}` : ''}`}>
              {options.map(({ element, onClick }, i) => (
                <li key={i} onClick={onClick}>
                  {element}
                </li>
              ))}
            </ul>
          );
        }}
      </Transition>
    </>
  );
};

FlyOutOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default FlyOutOptions;
