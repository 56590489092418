import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { forgetPasswordOnServer } from '../../store/actions'
import { reduxFormConst } from '../../const'
import {
  reduxForm,
} from 'redux-form'
import { ForgetPasswordForm } from '../index'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

class ForgetPassword extends React.Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
  }

  submitForm (values) {
    return this.props.forgetPassword(values).then((response) => {
        if(response.data && response.data.result) {
          localStorage.setItem('__ai_pass_sent_notif', 'Reset password instructions have been sent to mail.')
          this.props.history.push('/trend-spotting')
        } else {
          // Alert 
          const MySwal = withReactContent(Swal)
          Swal.fire({
            icon: 'warning',
            title: 'Sorry!',
            text: response.data? response.data.message: 'No Account associated with given email.',
            footer: '<p>Please enter valid email and try.</p>',
            confirmButtonText: 'Okay'
          })
        }
    })
  }
  
  render () {
    return (
      <div className="col-md-12 pad">
        <div className="col-md-12">
          <div className={'col-md-12 pad '} >
            <ForgetPasswordForm
              onSubmit={this.submitForm}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: (values) => dispatch(forgetPasswordOnServer(values)),
  }
}

ForgetPassword = reduxForm({
  // a unique name for the form
  form: reduxFormConst.forgetPassword,
})(ForgetPassword)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword))

