import React, { useState, useEffect } from 'react'

import { useSingleSnackBar, useLoader } from '../../../hooks'

import {
  Grid,
  Box,
  Button,
  Typography,
  Stack,
  Checkbox,
  IconButton,
  TableCell,
} from '@mui/material'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import Multiselect from '../../../shared/components/MultiSelect_v2'
import DashboardTable from './partials/DashboardTable'

import NetworkingUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'

import { addLabelKey } from '../../../store/utility'

// TODO: CONDITION FOR SENDING FORMATS WHEN FORMATS HAVEN'T BEEN FETCHED YET
// TODO: DIFFING
function POCDashboard(props) {
  const { showSnackBar } = useSingleSnackBar()
  const [showLoader, setShowLoader] = useLoader()
  const [projectsList, setProjectsList] = useState()
  const [countriesList, setCountriesList] = useState([])
  const [subData, setSubData] = useState(new Map())
  const [expandRow, setExpandRow] = useState(false)
  const [categoriesList, setCategoriesList] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedCategories, setSelectedCategory] = useState([])
  const [dashboards, setDashboards] = useState([])
  const [deletedDashboards, setDeletedDashboards] = useState([])
  const [selectedFormat, setSelectedFormat] = useState(false)
  const [deleteSubCategoryDialog, setDeleteSubCategoryDialog] = useState(false)
  const [removeSubcategoryOpen, setRemoveFormatOpen] = useState(false)

  const subDataArr = [...subData.values()]

  const dashboardsMainHeadCells = [
    {
      id: 'arrow',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '30px',
      sorting: false,
      func: (data) => {
        return (
          <TableCell
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleExpandRow(data)
            }}
          >
            <IconButton aria-label="expand row" size="small" data-testId="icon">
              {data.id === expandRow ? (
                <>
                  <ExpandMoreIcon fontSize="medium" />
                </>
              ) : (
                <>
                  <NavigateNextIcon fontSize="medium" />
                </>
              )}
            </IconButton>
          </TableCell>
        )
      },
    },
    {
      id: 'dashboardName',
      numeric: false,
      disablePadding: true,
      label: 'Dashboard Name',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'country',
      numeric: false,
      disablePadding: true,
      label: 'Country',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: true,
      label: 'Category',
      width: '100px',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'remove',
      numeric: false,
      disablePadding: true,
      label: 'Remove',
      width: '100px',
      sorting: false,
      func: (data) => (
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setDeleteSubCategoryDialog(data)
          }}
        >
          <RemoveCircleIcon />
        </TableCell>
      ),
    },
  ]

  const dashboardSubHeadCells = [
    // {
    //   id: 'subcategory',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Sub Category',
    //   width: '100px',
    //   sorting: false,
    //   func: (data) => <TableCell>{data}</TableCell>,
    // },
    {
      id: 'format',
      numeric: false,
      disablePadding: false,
      label: 'Format',
      sorting: false,
      func: (data) => <TableCell>{data}</TableCell>,
    },
    {
      id: 'noOfTrends',
      numeric: true,
      disablePadding: false,
      label: '# of trends',
      sorting: false,
      func: (data) => {
        return (
          <TableCell>
            {Object.entries(data).map(([key, value]) => {
              return (
                <Box>
                  {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </Box>
              )
            })}
          </TableCell>
        )
      },
    },
    {
      id: 'selection',
      numeric: false,
      disablePadding: false,
      label: 'Selection',
      width: '83px',
      sorting: false,
      func: (data) => {
        const checkboxHandler = (e) => {
          e.preventDefault()
          setSelectedFormat(data)
          setRemoveFormatOpen(true)
        }

        return (
          <TableCell>
            <Checkbox
              defaultChecked={data.selection}
              onClick={checkboxHandler}
              value={data.selection}
              checked={data.selection}
            />
          </TableCell>
        )
      },
    },
  ]

  // All network requests
  const fetchSubscribedDasboards = () => {
    const url =
      NetworkingUtil.getCSUAMSubscribedDashboards() +
      `?client_id=${props.clientId}`
    setShowLoader(true)
    axios
      .get(url)
      .then(({ data }) => {
        const subscribedData = data.data.map(({ country, category, id }) => {
          const dashboard = `${country} ${category}`
          const dashboardKey = `${country};${category}`
          return {
            id,
            dashboardKey,
            country,
            category,
            dashboardName: dashboard,
          }
        })
        setDashboards(subscribedData)
        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        console.log(err)
      })
  }

  const fetchAvailableDashboards = () => {
    const request = NetworkingUtil.getCSUAMDashboardsList()
    setShowLoader(true)
    axios
      .get(request)
      .then((response) => {
        setShowLoader(false)
        const obj = response.data.data.map((data) => {
          return {
            _criteria: data.country,
            _id: data.id,
            _name: data.category,
          }
        })
        setProjectsList({
          list: obj,
        })
      })
      .catch((error) => {
        setShowLoader(false)
        console.log(error)
      })
  }

  const fetchFormatsDetails = (row) => {
    const { newDash, id, country, category } = row
    for (const [key] of subData) {
      if (
        key
          .split(';')
          .slice(0, 2)
          .join(';') === `${country};${category}`
      )
        return
    }
    setShowLoader(true)
    if (!newDash) {
      const url = NetworkingUtil.getCSUAMSubscribedDashboards() + `${id}/`
      axios
        .get(url)
        .then(({ data }) => {
          setShowLoader(false)
          setFormats(country, category, data.data)
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err)
          showSnackBar('Error fetching Format details', 'error')
        })
    } else {
      const url =
        NetworkingUtil.getCSUAMDashboardsDetails() +
        `?country=${encodeURIComponent(country)}&category=${encodeURIComponent(
          category,
        )}`
      axios
        .get(url)
        .then(({ data }) => {
          setShowLoader(false)
          setFormats(country, category, data.data, true)
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err)
          showSnackBar('Error fetching Format details', 'error')
        })
    }
  }

  const createDashboards = (createData) => {
    setShowLoader(true)
    axios
      .post(NetworkingUtil.bulkCreateCSUAMDashboards(), createData)
      .then(() => {
        setShowLoader(false)
      })
      .catch(() => {
        setShowLoader(false)
        showSnackBar('Error creating POC Dashbaord', 'error')
      })
  }

  const updateDashboards = (updateData) => {
    setShowLoader(true)
    axios
      .put(NetworkingUtil.bulkEditCSUAMDashboards(), updateData)
      .then(() => {
        setShowLoader(false)
      })
      .catch(() => {
        setShowLoader(false)
        showSnackBar('Error creating POC Dashbaord', 'error')
      })
  }

  const saveDashboards = () => {
    const createdDashboards = new Map()
    const updatedDashboards = new Map()
    // WO in newDashboardsWOFormats === 'without'
    const newDashboardsWOFormats = new Set(
      dashboards
        .filter(({ newDash }) => newDash)
        .map(({ dashboardKey }) => dashboardKey),
    )

    const deletedSet = new Set(deletedDashboards)
    for (const [key, value] of subData) {
      const { newDash, selection } = value
      const [country, category, , , id] = key.split(';')
      // Generate key to filter out dashboards added without fetching the formats
      const newKey = country + ';' + category
      if (newDashboardsWOFormats.has(newKey)) {
        newDashboardsWOFormats.delete(newKey)
      }
      // Prevent adding deleted dashboards
      if (!deletedSet.has(newKey)) {
        const reqMap = newDash ? createdDashboards : updatedDashboards
        if (reqMap.has(newKey)) {
          if (selection) {
            reqMap.set(newKey, [...reqMap.get(newKey), id])
          }
        } else {
          if (selection) {
            reqMap.set(newKey, [id])
          } else {
            reqMap.set(newKey, [])
          }
        }
      }
    }
    const newDashboardsWOFormatsArr = [...newDashboardsWOFormats].map((key) => {
      const [country, category] = key.split(';')
      return { country, category, client_id: props.clientId }
    })
    const createData = [...createdDashboards].map(([key, formats]) => {
      const [country, category] = key.split(';')
      return { country, category, formats, client_id: props.clientId }
    })
    createData.push(...newDashboardsWOFormatsArr)

    const updateData = [...updatedDashboards].map(([key, formats]) => {
      const [country, category] = key.split(';')
      const id = dashboards.find(
        (d) => d.country === country && d.category == category,
      ).id
      return { id, formats, client_id: props.clientId }
    })

    if (createData.length) {
      createDashboards(createData)
    }
    if (updateData.length) {
      updateDashboards(updateData)
    }
  }

  // handlers
  const handleExpandRow = (row) => {
    if (Array.isArray(row)) {
      setExpandRow(false)
    } else {
      setExpandRow(row.id)
      fetchFormatsDetails(row)
    }
  }

  const removeDashboard = (id) => {
    let newlyAddedDashboard = null
    const newDashboardData = dashboards.filter(
      ({ newDash, country, category, ...rest }) => {
        const matched = rest.id !== id
        if (!matched) {
          if (newDash) {
            newlyAddedDashboard = true
          }
          setDeletedDashboards((dash) => [
            ...new Set([...dash, `${country};${category}`]),
          ])
        }
        return matched
      },
    )
    if (!newlyAddedDashboard) {
      setShowLoader(true)
      axios
        .delete(NetworkingUtil.getCSUAMSubscribedDashboards() + `${id}/`)
        .then(() => {
          setShowLoader(false)
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err)
        })
    }
    setDashboards(newDashboardData)
  }

  const handleRemoveFormat = () => {
    const { id } = selectedFormat
    const matchedFormat = subData.get(id)
    const { selection, diff } = matchedFormat
    subData.set(id, { ...matchedFormat, selection: !selection, diff: !diff })
    setSubData(new Map(subData))
    setRemoveFormatOpen(false)
    setSelectedFormat(false)
  }

  const setFormats = (country, category, subCategories, setDefault = false) => {
    const formatsList = []
    const uniqueFormats = []
    subCategories.forEach(({ name, formats }) => {
      formats.forEach(
        ({ id, name: fName, ingredients, products, themes, active }) => {
          const newId = `${country};${category};${name};${fName};${id}`
          // Prevent duplicate formats
          if (!uniqueFormats.includes(fName)) {
            uniqueFormats.push(fName)
            formatsList.push({
              id: newId,
              subcategory: name,
              format: fName,
              noOfTrends: { ingredients, products, themes },
              newDash: setDefault,
              selection: setDefault || active,
              diff: false, // To check change of value to prevent unupdated fields from updating
            })
          }
        },
      )
    })
    formatsList.sort(({ format: formatA }, { format: formatB }) =>
      formatA.localeCompare(formatB),
    )
    setSubData((oldSubData) => {
      formatsList.forEach(({ id, ...rest }) => {
        if (!oldSubData.has(id)) {
          oldSubData.set(id, { id, ...rest })
        }
      })
      return new Map(oldSubData)
    })
  }

  // Handler functions
  const handleExit = () => {
    props.setClientModification(0)
  }

  const dashboardAlreadyExists = (dashboard) => {
    return dashboards.some(({ dashboardName }) => dashboard === dashboardName)
  }

  const handleDashboardSave = () => {
    saveDashboards()
    props.setClientModification(0)
  }

  const handleAddDashboard = () => {
    const selectedDashboards = []
    selectedCountries.forEach(({ label }) => {
      selectedCategories.forEach(({ label: catLabel }) => {
        const dashboard = `${label} ${catLabel}`
        const dashboardKey = `${label};${catLabel}`
        if (!dashboardAlreadyExists(dashboard)) {
          selectedDashboards.push({
            id: dashboard,
            dashboardKey,
            country: label,
            category: catLabel,
            dashboardName: dashboard,
            newDash: true,
          })
        }
      })
    })
    setDashboards((dashboards) => [...dashboards, ...selectedDashboards])
    setSelectedCategory([])
    setSelectedCountries([])
  }

  // useEffects
  // Populate common categories in category dropdown
  useEffect(() => {
    if (!selectedCountries.length) return
    if (projectsList) {
      const matchedCategories = projectsList.list.reduce(
        (acc, { _criteria, _name }) => {
          if (selectedCountries.find(({ label }) => label === _criteria)) {
            if (acc.has(_name)) {
              acc.set(_name, acc.get(_name) + 1)
            } else {
              acc.set(_name, 1)
            }
          }
          return acc
        },
        new Map(),
      )
      setCategoriesList(
        addLabelKey(
          [...matchedCategories].reduce(
            (acc, [key, value]) =>
              value === selectedCountries.length ? [...acc, key] : acc,
            [],
          ),
          (val) => val,
        ),
      )
    }
  }, [selectedCountries])

  // Populate country categories dropdown
  useEffect(() => {
    let formattedCountries = []
    formattedCountries = addLabelKey(
      projectsList ? projectsList.list : [],
      (prj) => prj._criteria,
    ).map(({ label, key }) => {
      return {
        label,
        key,
        code: (
          countriesList.find((country) => country.label === label) || {
            code: '',
          }
        ).code,
        img:
          (
            countriesList.find((country) => country.label === label) || {
              img: '',
            }
          ).img || '',
      }
    })

    formattedCountries.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    setCountriesList(formattedCountries)

    let formatedCategories = []
    formatedCategories = addLabelKey(
      projectsList ? projectsList.list : [],
      (prj) => prj._name,
    )
    formatedCategories.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    setCategoriesList(formatedCategories)
  }, [projectsList])

  useEffect(() => {
    setSelectedCategory([])
  }, [selectedCountries])

  useEffect(() => {
    fetchSubscribedDasboards()
    fetchAvailableDashboards()
  }, [])

  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px',
          height: '100px',
        }}
      >
        <Grid marginRight="140px">
          <Stack sx={{ width: '300px' }}>
            <Typography variant="subtitle2" marginBottom="8px">
              Country
            </Typography>
            <Multiselect
              enableSearch
              searchPlaceholder="Search countries"
              placeholder="e.g. China"
              textFieldProps={{ autoComplete: 'off' }}
              value={selectedCountries}
              onChange={setSelectedCountries}
              options={countriesList}
            />
          </Stack>
        </Grid>
        <Grid item xs={6} md={1.3}>
          <Stack sx={{ width: '300px' }}>
            <Typography variant="subtitle2" marginBottom="8px">
              Category
            </Typography>
            <Multiselect
              placeholder="e.g. Snacks"
              options={categoriesList || []}
              disable={!selectedCountries.length}
              value={selectedCategories || null}
              onChange={setSelectedCategory}
              sx={{
                width: '100%',
                height: 35,
                '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                  height: '100%',
                },
              }}
            />
          </Stack>
        </Grid>
        <Stack mt={2.5} sx={{ marginLeft: '90px' }}>
          <Box>
            <Button
              alignItems="center"
              variant="contained"
              size="large"
              mt={5}
              sx={{ padding: '10px' }}
              onClick={handleAddDashboard}
            >
              Add dashboard for the selected country & category
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Box sx={{ height: '400px' }}>
        <DashboardTable
          dashboardData={dashboards}
          subData={subDataArr}
          expandRow={expandRow}
          dashboardsMainHeadCells={dashboardsMainHeadCells}
          dashboardSubHeadCells={dashboardSubHeadCells}
          selectedFormat={selectedFormat}
          selectedRow={props.selectedRow}
          handleExpandRow={handleExpandRow}
          removeDashboard={removeDashboard}
          setExpandRow={setExpandRow}
          removeSubcategoryOpen={removeSubcategoryOpen}
          deleteSubCategoryDialog={deleteSubCategoryDialog}
          handleRemoveFormat={handleRemoveFormat}
          setDeleteSubCategoryDialog={setDeleteSubCategoryDialog}
          setRemoveFormatOpen={setRemoveFormatOpen}
          showLoader={showLoader}
        />
      </Box>
      <Box
        sx={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        mt={3}
      >
        <Grid>
          <Button
            alignItems="center"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleExit}
          >
            Exit Without Saving
          </Button>
        </Grid>
        <Grid>
          <Button
            alignItems="center"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleDashboardSave}
            sx={{
              marginRight: '100px',
            }}
          >
            Save and Exit
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default POCDashboard
