import React, { useState, useEffect, useRef } from 'react'
import {
  TextField,
  InputAdornment,
  ListItem,
  Chip,
  Tooltip,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { detectWrap } from '../../../../store/utility'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const MAX_KEYWORD = 20
const CONCEPT_TAB = 'Text'

const ExcludeKeyword = ({
  excludeTags,
  setExcludeInput,
  setExcludeTags,
  excludeInput,
  excludeError,
  setExcludeError,
  tags,
  logExcludeKeywordAdded,
  generativeAIDropdownOption,
  isConceptPresent,
  concept,
}) => {
  const chipsContainerRef = useRef(null)
  const [showMore, setShowMore] = useState(null)
  const [overflowChipCount, setOverflowChipCount] = useState(0)

  const generatedConcept = concept?.original_generated_concept
  const prevExlcudeKeyword = generatedConcept
    ? JSON.parse(generatedConcept).excluded_keywords
    : generatedConcept
  const newItemExcluded = excludeTags.some(
    (element) => !prevExlcudeKeyword?.includes(element),
  )

  const checkOverflow = () => {
    const isWrapped = detectWrap(chipsContainerRef)
    if (isWrapped) {
      setShowMore(false)
    } else {
      if (excludeTags.length < overflowChipCount) {
        setOverflowChipCount(0)
        setShowMore(null)
      }
    }
    if (isWrapped && overflowChipCount === 0 && showMore === null) {
      setOverflowChipCount(excludeTags.length)
    }
  }

  const removeTags = (indexToRemove) => {
    setExcludeTags([
      ...excludeTags.filter((_, index) => index !== indexToRemove),
    ])
  }

  const addTags = (currentTag) => {
    if (currentTag !== '') {
      if (
        !excludeTags
          ?.map((tag) => tag.toLowerCase())
          .includes(currentTag.toLowerCase())
      ) {
        if (excludeTags.length === MAX_KEYWORD) {
          setExcludeError('Note: Only 20 Keywords can be added')
        } else if (
          tags
            ?.map((tag) => tag.toLowerCase())
            .includes(currentTag.toLowerCase())
        ) {
          setExcludeError(`Note: ${currentTag} is included in Included List `)
        } else {
          setExcludeInput('')
          setExcludeError('')
          setExcludeTags([...excludeTags, currentTag])
          logExcludeKeywordAdded([...excludeTags, currentTag])
        }
      } else {
        setExcludeInput('')
      }
    }
  }

  const onKeywordEnter = (event) => {
    if (event.key === ',') {
      return
    }
    if (event.key === 'Enter') {
      addTags(event.target.value)
    }
  }

  const handleInput = (input) => {
    setExcludeInput(input.replace(',', ''))
    setExcludeError('')
  }

  useEffect(() => {
    checkOverflow()
  }, [excludeTags])

  return (
    <>
      {generativeAIDropdownOption === CONCEPT_TAB ? (
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Exclude claims, trends, category, format, etc."
            InputProps={{
              style: {
                height: '35px',
                paddingRight: '5px',
              },
            }}
            value={excludeInput}
            onKeyUp={(event) => onKeywordEnter(event)}
            onChange={(e) => handleInput(e.target.value)}
            id="fullWidth"
            size="small"
          />
          <Box>
            <Button
              sx={{
                boxShadow: 'none',
                fontSize: 16,
                height: '32px',
                ml: 2,
                color: ({ palette }) => palette.primary.main,
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
              }}
              variant="outlined"
              onClick={() => addTags(excludeInput)}
            >
              Exclude
            </Button>
          </Box>
        </Box>
      ) : null}
      {generativeAIDropdownOption === CONCEPT_TAB ? (
        <Typography variant="error" sx={{ fontSize: '12px' }}>
          {excludeError}
        </Typography>
      ) : null}
      {generativeAIDropdownOption === CONCEPT_TAB ? (
        <Box
          sx={{
            pt: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
          ref={chipsContainerRef}
        >
          {excludeTags &&
            excludeTags
              .slice(
                0,
                showMore !== null && showMore === false
                  ? overflowChipCount - 2
                  : excludeTags.length,
              )
              .map((tag, index) => {
                return (
                  <Chip
                    key={`${index}_${tag}`}
                    label={tag}
                    variant="outlinedTag"
                    onDelete={() => removeTags(index)}
                    color="primary"
                    sx={{ borderRadius: 18, mr: 2, my: 1 }}
                  />
                )
              })}
          {showMore !== null && (
            <Button
              sx={{
                borderRadius: '20px',
                height: '24px',
                border: ({ palette }) => `1px solid ${palette.secondary.dark}`,
                color: ({ palette }) => palette.secondary.dark,
              }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore
                ? 'View Less'
                : `${excludeTags.length - overflowChipCount + 2} More`}
              {showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          )}
        </Box>
      ) : null}
      {!!newItemExcluded && isConceptPresent && (
        <Typography variant="error" sx={{ fontSize: '12px' }}>
          Please regenerate the concept to apply the changes.
        </Typography>
      )}{' '}
    </>
  )
}

export default ExcludeKeyword
